<template>
  <div class=""
       style="height:650px">
    <!-- <common-header :title="vHeadTitle"
                   @comfire="comfireAfter"
                   :vReleaseBtnClass="vReleaseBtnClass"></common-header> -->
    <!-- v-if="this.seletedStudentInfo.StudentKey<=0" -->
    <seleted-list :courseId="releaseData.RelateCourseID"
                  ref="seletedList"
                  :isTeacher="0"
                  :dataList="assignStudentInfo.StudentList"
                  @completeTeacherList="completeTeacherList"></seleted-list>

    <!-- <student-box v-else
                 :studentInfo="seletedStudentInfo"
                 :isShowBtn="false"
                 :btnClassName="'student_info_btn'"></student-box> -->
    <div class="release_content_title">
      <span class="left_side">课后作业</span>
      <!-- <span class="right_side">作业模板</span> -->
    </div>
    <release-content ref="releaseContent"
                     :dataInfo="releaseData"
                     :settingInfo="settingInfo"
                     :addVideoType="3"
                     :isTeacher="0"
                     :formTitle="'作业标题'"
                     :formTitleplaceholder="'请输入作业标题'"
                     :contentTitle="'作业详情'"
                     :placeholder="'请输入作业内容'"
                     :className="'homework_publish_textBox'"></release-content>
    <!-- <div class="bottom_save pr">
      <div class="opt"
           :class="IsSaveKey?'html_view_Bottom':'html_view_save_as'"
           @click.stop="saveClick">
        <span>
          保存为作业题库
        </span>
      </div>
    </div> -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'提交'"
                             :btnClass="vReleaseBtnClass"
                             @confirmClick="comfireAfter"
                             @cancelClick="closeDialog"></save-cancel-btn-group>
    </div>
    <!-- <basics-custom-popup v-if="isShowTextLibrary"  :formTitle="'作业模板'" @closeEvent="doCloseEvent">
		<homework-question-library :typeKey="1" :isPrivateKey="1" :selectKey="warehouseID" :CourseKey="releaseData.RelateCourseID" @onClickEvent="selectCourseLibrary"></homework-question-library>
	</basics-custom-popup> -->
  </div>

</template>

<script>
import studentBox from './student-box';
import seletedList from './seleted-list.vue';
import { getCourseInfoForFSC, StandardHomeworkWarehouse, GetHomeWorkDetails, PublishedHomeWork, EditHomeWork } from '../../../../API/workbench';
// import commonSeletedDate from '../common-component/common-seleted-date';
// import commonHeader from '../common-component/common-header.vue';
import releaseContent from '../../common-component/release-content/index.vue';
// import homeworkQuestionLibrary from '../components/template-library/homework-question-library/index';
export default {
  components: {
    seletedList,
    releaseContent,
    studentBox
    // commonSeletedDate,
    // commonHeader,
    // homeworkQuestionLibrary
  },
  data () {
    return {
      releaseData: {
        essayID: 0,
        Title: '', // 标题,
        Content: '', // 内容,
        RelateCourseID: 0, // 课ID,
        StudentList: [], // 接收学生列表 { StudentKey: 0, // 学生Id, StudentKeyValue: ''// 学生姓名  }
        AttachList: [] // 附件列表 { AttachTypeKey: 1, // 1-图片; 2-视频;3-音频  AttachUrl: '../../../../../public/img/01.jpg'}
      },
      settingInfo: {
        addVideoType: '3',
        isTeacher: 0,     // 是否老师
        showAddAudio: true,  // 是否显示录音
        formTitle: '作业标题',   // 主标题
        formTitleplaceholder: '请输入作业标题',  // 主标题栏水印
        contentTitle: '作业详情',   // 内容标题
        placeholder: '请输入作业内容',  // 内容
        showAddPic: true,   // 显示添加图片
        showRelevance: true,    // 显示添加关联
        showUploadFile: false   // 显示上传文件

      },
      essayInfo: {},
      courseInfo: {},
      searchType: 2,
      seletedStudentInfo: {
        StudentKey: 0
      },
      assignStudentInfo: {
        Title: '布置学生',
        StudentList: []
      },
      warehouseID: 0, // 作业模板ID
      IsSaveKey: 0, // 是否保存为作业题库
      isShowTextLibrary: false
    };
  },
  props: {
    dataInfo: { // 修改的作业信息
      type: Object,
      default: {}
    }
  },
  created () {
    this.releaseData.essayID = Number(this.dataInfo.essayID) || 0; // 作业ID
    this.releaseData.RelateCourseID = this.dataInfo.scheduleCourseID || 0; // 课ID
    this.seletedStudentInfo.StudentKey = this.dataInfo.studentKey || 0;
    this.releaseData.Content = this.dataInfo.Content || '';
    this.releaseData.Title = this.dataInfo.Title || '';
    // this.releaseData.Title = this.dataInfo.courseName; // 课名
    console.log('created', this.dataInfo);
    if (this.dataInfo.WarehouseID) {
      this.warehouseID = this.dataInfo.WarehouseID;
      this.getHomeworkWarehouse();
    }
    // if (this.releaseData.Title) {
    //   const lastStr = this.releaseData.Title.substring(this.releaseData.Title.length - 1);

    //   if (this.releaseData.Title.indexOf('的作业') >= 0) {
    //     this.releaseData.Title = this.releaseData.Title;
    //   } else if (lastStr != '课') {
    //     this.releaseData.Title = this.releaseData.Title + '课的作业';
    //   } else {
    //     this.releaseData.Title = this.releaseData.Title + '的作业';
    //   }
    // }
    // 作业ID大于0表示修改状态 反之 新增
    if (this.releaseData.essayID) {
      this.getEditClassStudentsList();
    } else {
      this.ClassStudentsArrangeQuery();
    }
    if (this.dataInfo.HomeWork) {
      this.releaseData.Content = this.dataInfo.HomeWork.Content || '';
      this.releaseData.Title = this.dataInfo.HomeWork.Title || '';
      this.releaseData.AttachList = this.dataInfo.HomeWork.AttachList || [];
    }
  },
  computed: {
    vHeadTitle () {
      if (this.releaseData.essayID > 0) {
        return '修改作业';
      } else {
        return '布置作业';
      }
    },
    vStudentList () {
      const newArr = [];
      this.assignStudentInfo.StudentList.forEach(o => {
        if (this.searchType == 1) {
          if (o.Status == 0 && o.AttenceStatusKey != 2) { // 待布置
            newArr.push(o);
          }
        } else if (this.searchType == 2) {
          if (o.AttenceStatusKey == 3) {
            newArr.push(o);
          }
        } else if (o.AttenceStatusKey == 4 || o.AttenceStatusKey == 5) {
          if (o.AttenceStatusKey != 3 && o.AttenceStatusKey != 2) {
            newArr.push(o);
          }
        }
      });
      return newArr;
    },
    vReleaseBtnClass () {
      let className = 'no_confirm';
      if (this.releaseData.Content || this.releaseData.AttachList.length > 0) {
        className = '';
      }
      return className;
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog');
    },
    doCloseEvent () {
      this.isShowTextLibrary = false;
    },
    selectCourseLibrary (data) {
      this.warehouseID = data.ID;
      // this.getHomeworkWarehouse();
      this.doCloseEvent();
    },
    getHomeworkWarehouse () {
      StandardHomeworkWarehouse(this.warehouseID).then(res => {
        console.log(res.data, 'this.warehouseID');
        this.essayInfo = res.data;
        this.releaseData.AttachList = res.data.AttachList;
        this.releaseData.Content = res.data.Content;
        this.releaseData.Title = res.data.Title;
        console.log('releaseData.Content', this.releaseData.Content);
        this.$nextTick(o => {
          // this.$refs.releaseContent.initContentValue(this.releaseData.Content);
        });
      }, err => {
        console.log(err.msg);
      });
    },
    saveClick () {
      this.IsSaveKey = !this.IsSaveKey;
      console.log('saveClick', this.IsSaveKey);
    },
    // 修改老师选择.
    completeTeacherList (dataList, isAll) {
      this.assignStudentInfo.StudentList = dataList;
    },
    getEditClassStudentsList () {
      GetHomeWorkDetails(this.releaseData.essayID).then(res => {
        console.log('getCourseInfoForFSC', res.data);
        this.essayInfo = res.data;
        this.releaseData.AttachList = res.data.AttachList;
        this.releaseData.Content = res.data.Content;
        this.releaseData.Title = res.data.Title;
        console.log('releaseData.Content', this.releaseData.Content);
        this.$nextTick(o => {
          // this.$refs.releaseContent.initContentValue(this.releaseData.Content);
        });
        getCourseInfoForFSC(this.releaseData.RelateCourseID).then(res => {
          this.assignStudentInfo.StudentList = [];
          this.courseInfo = res.data;
          res.data.ArrangeStudentList.forEach(o => {
            o.StudentNameKey = o.StudentKey;
            o.StudentNameKeyValue = o.StudentKeyValue;
            this.$set(o, 'isActive', false);
            this.essayInfo.StudentList.forEach(p => {
              if (o.StudentKey == p.StudentKey) {
                if (o.Status == 1) {
                  this.$set(o, 'isActive', true);
                }
              }
            });

            this.assignStudentInfo.StudentList.push(o);
          });
          if (this.seletedStudentInfo.StudentKey > 0) {
            const locaIndex = this.assignStudentInfo.StudentList.findIndex(o => {
              return o.StudentKey == this.seletedStudentInfo.StudentKey;
            });
            if (locaIndex >= 0) {
              this.assignStudentInfo.StudentList[locaIndex].isActive = true;
              this.seletedStudentInfo = this.assignStudentInfo.StudentList[locaIndex];
            }
          }
        }, err => {
          console.log(err);
        });
      }, err => {
        console.log(err);
      });
    },
    // 获取课考勤学生列表.
    ClassStudentsArrangeQuery () {
      getCourseInfoForFSC(this.releaseData.RelateCourseID).then(res => {
        this.assignStudentInfo.StudentList = [];
        this.courseInfo = res.data;
        res.data.ArrangeStudentList.forEach(o => {
          this.$set(o, 'isActive', false);
          o.StudentNameKey = o.StudentKey;
          o.StudentNameKeyValue = o.StudentKeyValue;
          // Status为1时代表布置过该学生,为0代表未布置学生

          this.assignStudentInfo.StudentList.push(o);
        });
        // 如果是指定学生进入发布作业页面时. 将此学生高亮.
        if (this.seletedStudentInfo.StudentKey > 0) {
          const locaIndex = this.assignStudentInfo.StudentList.findIndex(o => {
            return o.StudentKey == this.seletedStudentInfo.StudentKey;
          });
          if (locaIndex >= 0) {
            this.assignStudentInfo.StudentList[locaIndex].isActive = true;
            this.seletedStudentInfo = this.assignStudentInfo.StudentList[locaIndex];
          }
        }
      }, err => {
        console.log(err);
      });
    },
    verifyData () {
      if (this.vReleaseBtnClass) {
        return false;
      }
      if (!this.releaseData.Title) {
        layer.alert('请输入标题');

        return false;
      }
      if (this.releaseData.Content.length > 1000) {
        layer.alert('内容不能超过1000字');
        return false;
      }
      this.assignStudentInfo.StudentList.forEach(obj => {
        if (obj.isActive == true) {
          this.releaseData.StudentList.push({
            StudentKey: obj.StudentKey,
            StudentKeyValue: obj.StudentKeyValue
          });
        }
      });
      if (this.releaseData.StudentList.length == 0) {
        layer.alert('请先选择布置学生');
        return false;
      }

      const uploadingImgList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 1 && obj.AttachUrl.indexOf('data:image/') >= 0;
      });
      if (uploadingImgList.length > 0) {
        layer.alert('图片正在上传中，请稍后提交');
        return false;
      }
      const imgList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 1;
      });
      if (imgList.length > 12) {
        layer.alert('图片不可超过12张');
        return false;
      }
      const uploadingVideoList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 2 && obj.VideoCoverUrl == '';
      });
      if (uploadingVideoList.length > 0) {
        layer.alert('视频正在上传中，请稍后提交');
        return false;
      }
      return true;
    },
    // 选择课评模板
    doModuTitleClick () {
      this.isShowTextLibrary = true;
      // this.$zxrouter.navigateTo({
      //   url: '/pages/home-school-communication/components/template-library/homework-question-library/index' +
      //     '?type=1'
      // });
    },
    comfireAfter (callBack) {
      console.log('submitClick:this.releaseData', this.releaseData, this.IsSaveKey);
      if (!this.verifyData()) {
        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }

      if (this.releaseData.essayID > 0) {
        EditHomeWork(this.releaseData.essayID, this.releaseData).then(result => {
          console.log('EditRediv', result.data);
          this.$emit('afterSuccess');
          this.$bus.emit('AfterReleaseEssay', this.dataInfo.scheduleCourseID);
          this.closeDialog();
        }, error => {
          if (typeof callBack === 'function') {
            callBack();
          }
          this.$store.dispatch('popup/open', {
            content: error.msg
          });
        });
      } else {
        PublishedHomeWork(this.releaseData, this.IsSaveKey).then(result => {
          this.$emit('afterSuccess');
          this.$bus.emit('AfterReleaseEssay', this.dataInfo.scheduleCourseID);
          this.closeDialog();
        }, error => {
          if (typeof callBack === 'function') {
            callBack();
          }
          layer.alert(error.msg);
        });
      }
    }
  }
};
</script>

<style>
</style>
