<template>
  <div class="sign_popup_list"
       @click.stop="clickCourseItem">
    <div class="list_student_info">
      <img :src="vDefaultImg"
           :onerror="vDefaultImg"
           alt="">
      <span>{{courseItem.StudentKeyValue}}</span>
    </div>
    <div class="list_course_info">
      <div class="list_course_name">{{courseItem.CourseNameKeyValue}}</div>
      <div class="list_course_date">
        {{setClassTime(courseItem)}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['courseItem'],
  computed: {
    // 头像
    vDefaultImg () {
      let imgUrl = this.courseItem.HeadImgSupport || '';
      if (imgUrl && imgUrl.indexOf('deflut_studentPhoto') == -1) {
        if (imgUrl.indexOf('http') == -1) {
          return this.$store.getters.CDNURL + imgUrl;
        } else {
          return imgUrl;
        }
      } else {
        if (this.courseItem.SexKeyValue == '男' || Number(this.courseItem.SexKey) == 2) {
          return (
            require('../../../../public/image/deflut_studentPhoto_boy.png')
          );
        } else {
          return (
            require('../../../../public/image/deflut_studentPhoto_gril.png')
          );
        }
      }
    }
  },
  methods: {
    // 设置开始结束时间.
    setClassTime (item) {
      return (
        item.BeginClassTime.substring(10).substring(0, 6) +
        ' -' +
        item.EndClassTime.substring(10).substring(0, 6)
      );
    },
    clickCourseItem () {
      this.$emit('clickCourseItem', this.courseItem);
    }
  }
};
</script>

