<template>
  <div class="activity_drawer_box_list"
       @click.stop="seletedItem">
    <div class="flex">
      <div class="list_icon">
        <img :src="vImg"
             :onerror="vDefaultImg"
             alt="">
      </div>
      <div class="list_info">
        <div class="list_name">{{item.title}}</div>
        <div class="list_text"
             :title="disabled1?item.remark:''">
          <span ref="tooltipBox1">{{item.remark}}</span>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="list_amount">
        <div class="list_amount_red">￥{{Number(item.sellPrice)}}/次</div>
        <div class="text_center_line">原价{{Number(item.originalPrice)}}</div>
      </div>
      <div class="list_btn"
           v-if="coverInfo">

      </div>
    </div>
  </div>
</template>
<script>
import playMethodItem from './play-method-item';
export default {
  data () {
    return {
      disabled1: false
    };
  },
  props: {
    item: Object,
    coverInfo: Object
  },
  components: {
    playMethodItem
  },
  computed: {
    vImg () {
      if (!this.item.icon) {
        this.item.icon = '';
      }
      if (this.item.icon.indexOf('http') > -1) {
        return this.item.icon;
      } else {
        return this.$store.getters.CDNURL + this.item.icon;
      }
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  mounted () {
    if (this.$refs.tooltipBox1) {
      this.disabled1 = this.$refs.tooltipBox1.offsetWidth > this.$refs.tooltipBox1.parentElement.offsetWidth;
    }
  },
  methods: {
    seletedItem () {
      if (this.coverInfo) {
        this.$emit('seletedItem', this.item);
      }
    }
  }
};
</script>
