
<template>
  <!-- 学生班级-->
  <div>
    <!-- 学生信息 -->
    <student-lable class="content_marginTop_30 single_shadow content_marginRL"
                   :studentID="studentInfo.OLAPKey"
                   :readonly="false"
                   :CustStatusKey="1"
                   @alterStudent="doAfterChooseStudent"></student-lable>

    <!-- 班级 -->
    <div class="single_result_list">
      <div class="class_course_rule_title"
           style="background:none">
        <span class="class_icon_show">班</span>
        <span class="class_text_show">{{curSelectedClassInfo.MainDemoName}}</span>
      </div>
    </div>
    <!-- 班级已设置全部一起进班时不显示 -->
    <div v-if="vIsSyncGradeClassKey">
      <!-- 班级排课块 -->
      <div class="class_course_rule_box all_course_table_content"
           style="height:400px"
           @scroll="doAfterEventScrollTOP">
        <div class="content_box">
          <rule-class-list v-for="item in allCourseTableList"
                           :key="item.index"
                           :dataObj="item"
                           :popupsMenu="popUpOperationList"
                           :classType="dataObj.classTitle"
                           @onClickEvent="doOneTopValueEvent"></rule-class-list>
        </div>
        <!-- 无数据展示 -->
        <div v-if="!allCourseTableList.length"
             class="course_block_nodata">
          <div class="monitoring_content_nodata">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
      <!-- 点击学生 展开更多操作 -->
      <div class="course_student_attendance_operation module_content_menuList"
           :style="{'top':topValue+'px','left':'290px'}"
           v-show="isShowItem">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="popUpOperationList"
                       @clickOptionForAlertDialog="doClickPopItem"></drop-down-box>
      </div>
      <!-- type_green -->
      <div v-if="allCourseTableList.length>0">
        <div class="fixed_bottom_tips pr"
             :class="quitoutCourseNum>0?'type_green':''"
             v-if="(dataObj&&dataObj.classTitle=='退班')|| this.titleName == '退班'">
          <span v-if="quitoutCourseNum==0">退班请注意退课</span>
          <span v-else-if="quitoutCourseNum>0">已退循环课{{quitoutCourseNum}}节</span>
        </div>
        <div class="fixed_bottom_tips pr"
             :class="vRetiredCount>0?'type_green':''"
             v-else-if="(dataObj&&dataObj.classTitle=='进班')|| this.titleName == '进班'">
          <span v-if="vRetiredCount==0">请注意给学生排课</span>
          <span v-else-if="vRetiredCount>0">每周已排{{vRetiredCount}}节课</span>
        </div>
      </div>
    </div>
    <div v-else style="height:400px">

    </div>
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="dataObj?dataObj.classTitle:this.titleName"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import ruleClassList from './rule-class-list';
import studentLable from '../../../../common/student-lable';
import dropDownBox from '../../../../common/drop-down-box';
import {
  SearchStructedRule,
  getStudentArrangRuleByGrade,
  // GetCourseAndStudentCourseArrangByRule,
  CancelStudent2MutiSameApplyClassCourseByRule,
  // GetTicketPlanForGradeApplyClass,
  ArrangeStudent2MutiSameApplyClassCourseByRule,
  getStudentRelatedMsg,
  GetTheClassByID,
  studentAddClass,
  QuitStudentFromClass
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      studentInfo: {}, // 学生信息
      paramsForRule: {
        StudentKey: 0,
        StructuredRuleKey: 0,
        StartTime: '',
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      },
      topValue: 0, // 默认第一个top值
      newScrollTop: 0, // 滚动距离
      isShowItem: false,
      titleName: '',
      popUpOperationList: [
        {
          type: 1,
          name: '批量排课',
          eventName: 'batch',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          type: 2,
          name: '自选排课',
          eventName: 'freeChoice',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          type: 3,
          name: '退出已排的课',
          eventName: 'cancelAllArrange',
          isShow: true,
          ModulePowerKey: 47
        }
      ],
      curSelectedCircleCourseData: {}, // 当前选中课信息
      curSelectedClassInfo: {}, // 当前选中班级
      courseTableList: [], // 所有班级排课信息
      allCourseTableList: [],
      studentArrangedCourseList: [], // 学生已安排的课
      scheduleCourseList: [],
      studentTicketPlanForGradeApplyClass: {}, // 获取此学生指定班级所有报课计划
      quitoutCourseNum: 0, // 退课节数
      hasSyncArrangedRuleCourseList: [], // 初始进入界面的已加入循环课的规律课块
      isFirstLoad: true, // 第一次加载
      weekFilter: 1, // 过滤班级显示
      dataObj: {
        'classTitle': '',
        'studentID': '',
        'classNameID': ''
      }
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    ruleClassList,
    studentLable,
    dropDownBox
  },
  computed: {
    // 状态是否已退课
    vRetiredCount () {
      let count = 0;
      this.vCourseList.forEach(o => {
        if (o.IsSyncArrangedKey == 1) {
          count++;
        }
      });
      return count;
    },
    // 是否显示课块
    vIsSyncGradeClassKey () {
      if (this.curSelectedClassInfo.IsSyncGradeClassKey == 1 && ((this.dataObj && this.dataObj.classTitle == '进班') || this.titleName == '进班')) {
        return false;
      } else {
        return true;
      }
    },
    vCourseList () {
      let newList = [];
      this.courseTableList.forEach(obj => {
        let arrangedCourse = this.studentArrangedCourseList.find(item => {
          return obj.CourseNameKey == item.CourseNameKey && obj.WeekKey == item.WeekKey && obj.ClassTimeName == item.ClassTimeName;
        });
        if (arrangedCourse) {
          this.$set(obj, 'alreadyAttenCount', this.$utils.mAmountType(arrangedCourse.ClassCount, 0));
          this.$set(obj, 'PeriodCount', this.$utils.mAmountType(arrangedCourse.ClassCount, 0));// 学生排课节数
          this.$set(obj, 'IsSyncArrangedKey', arrangedCourse.IsSyncArrangedKey);// 是否循环跟排0否1是
          this.$set(obj, 'isOpen', arrangedCourse.IsSyncArrangedKey > 0);// 是否循环跟排0否1是
          if (this.isFirstLoad && arrangedCourse.IsSyncArrangedKey > 0) {
            this.hasSyncArrangedRuleCourseList.push(obj);
          }
        } else {
          this.$set(obj, 'alreadyAttenCount', 0);
        }
        newList.push(obj);
      });
      if (this.courseTableList.length > 0) {
        this.isFirstLoad = false;
      }
      this.weekFilter = 1;// 初始化周X
      // 上课时间排序
      newList.sort((x, y) => {
        if (Number(x.ClassTimeName.substring(0, 5).replace(/\:/g, '')) != Number(y.ClassTimeName.substring(0, 5).replace(/\:/g, ''))) { // 先判断开始上课时间是否相等进行排序
          return Number(x.ClassTimeName.substring(0, 5).replace(/\:/g, '')) - Number(y.ClassTimeName.substring(0, 5).replace(/\:/g, ''));
        } else {
          return Number(x.ClassTimeName.substring(6, 12).replace(/\:/g, '')) - Number(y.ClassTimeName.substring(6, 12).replace(/\:/g, ''));// 开始时间相等再根据结束时间排序
        }
      });
      newList.sort((x, y) => { return x.WeekKey - y.WeekKey; });// 周X排序
       // 学生退班：退班逻辑调整
      if (this.dataObj.classTitle == '退班' && this.vIsSysVersionTY) {
        var newTBList = newList.filter((obj, index) => {
          return obj.isOpen == true;
        });
        return newTBList;
      } else {
        return newList;
      }
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  watch: {
    vCourseList (n, o) {
      this.allCourseTableList = [];
      this.weekFilter = n.length > 0 ? n[0].WeekKey : 1;
      console.log(n, 'weekFilter-123s');
      n.forEach((obj, index) => {
        // 按周X进行分组显示
        if (Number(obj.WeekKey) > Number(this.weekFilter)) { // 防止周X之间有跨度时计算有误的问题
          this.weekFilter = Number(obj.WeekKey);
        }
        if (Number(obj.WeekKey) == Number(this.weekFilter)) {
          this.weekFilter++;
          obj.isWeekShow = true;
        } else {
          obj.isWeekShow = false;
        }
        console.log(this.weekFilter, 'weekFilter');
        this.allCourseTableList.push(obj);
      });
      console.log(this.allCourseTableList, this.weekFilter, 'vCourseList');
    }
  },
  created () {
    this.dataObj = this.$utils.parseJson(this.dataInfo);
    this.initData();
    //  批量排课 、自选排课
    this.$bus.on(this, 'afterArrangeScheduleCourse', (studentKey) => {
      if (this.curSelectedCircleCourseData && this.curSelectedCircleCourseData.StructuredRuleKey > 0) {
        this.doAfterArrangeStudentSuccess();
        console.log(this.dataObj, this.titleName, '学生退班/进班');
      }
    });
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowItem = false;
    }, true);
  },
  methods: {
    initData () {
      if (this.dataObj.studentID) {
        this.isShowMenu = this.$route.query.isShowMenu;
        getStudentRelatedMsg(this.dataObj.studentID).then(result => {
          this.studentInfo = result.data;
          if (this.dataObj.classNameID) {
            GetTheClassByID(this.dataObj.classNameID).then(result => {
              this.curSelectedClassInfo = result.data; // 当前选中班级信息
              console.log('进入');
              // this.getTicketPlanForGradeApplyClass();
              this.loadStructedRule();
            }, error => {
              layer.alert(error.msg);
            });
          }
        }, error => {
          layer.alert(error.msg);
        }
        );
      }
    },
    // 学生退班/进班
    confirmClick (callBack) {
      console.log(callBack, typeof callBack == 'function', '学生退班/进班');
      if (this.studentInfo.OLAPKey && this.curSelectedClassInfo.OLAPKey) {
        if ((this.dataObj && this.dataObj.classTitle == '退班') || this.titleName == '退班') {
          QuitStudentFromClass(this.studentInfo.OLAPKey, this.curSelectedClassInfo.OLAPKey).then(result => { // 退出
            GetTheClassByID(this.curSelectedClassInfo.OLAPKey).then(result => {
              layer.alert(this.studentInfo.MainDemoName + '同学退班成功!');
              this.$emit('afterSucces', this.curSelectedClassInfo.OLAPKey, this.studentInfo.OLAPKey);
              this.$bus.emit('afterQuitOrAddClass', this.curSelectedClassInfo.OLAPKey, this.studentInfo.OLAPKey, 'quit');
              this.cancelClick();
            }).finally(() => {
              if (callBack) {
                callBack();
              }
            });
          }, error => {
            layer.alert(error.msg);
            if (callBack) {
              callBack();
            }
          });
        } else if ((this.dataObj && this.dataObj.classTitle == '进班') || this.titleName == '进班') {
          studentAddClass(this.studentInfo.OLAPKey, this.curSelectedClassInfo.OLAPKey).then(result => { // 加入
            GetTheClassByID(this.curSelectedClassInfo.OLAPKey).then(result => {
              layer.alert(this.studentInfo.MainDemoName + '同学加入班级成功!');
              this.$emit('afterSucces', result.data, this.studentInfo.OLAPKey);
              this.$bus.emit('afterQuitOrAddClass', this.curSelectedClassInfo.OLAPKey, this.studentInfo.OLAPKey, 'add');
              this.cancelClick();
            }).finally(() => {
              if (callBack) {
                callBack();
              }
            });
          }, error => {
            layer.alert(error.msg);
            if (callBack) {
              callBack();
            }
          });
        } else {
          console.log(this.dataObj, this.titleName, '异常');
        }
      } else {
        layer.alert('您的操作太快了!');
        if (callBack) {
          callBack();
        }
      }
    },
    // 关闭模块
    cancelClick () {
      console.log('关闭模块');
      this.isShowItem = false;
      this.curSelectedCircleCourseData = {};
      this.newScrollTop = 0;
      this.$emit('closeStudentQuitOrenterclassDialog');
    },
    // 获取班级下的所有循环课
    loadStructedRule (type) {
      // let CourseNameKey = type == 'refresh' ? this.curSelectedCircleCourseData.CourseNameKey : '';
      SearchStructedRule('', this.curSelectedClassInfo.OLAPKey).then(result => {
        this.getStuArrangRuleByGrade(() => {
          // if (type == 'refresh') {
          let findItem = result.data.find(obj => { return obj.StructuredRuleKey == this.curSelectedCircleCourseData.StructuredRuleKey; });
          if (findItem) {
            if (this.vIsSysVersionTY) {
              this.curSelectedCircleCourseData.Num_SyncArranged = findItem.Num_SyncArranged;
            } else {
              this.curSelectedCircleCourseData.Num_InGrade = findItem.Num_InGrade;
            }
          }
          // } else {
          this.courseTableList = result.data;
          console.log(this.courseTableList, '获取班级下的所有循环课');
          // }
        }, type);
      });
    },
    // 获取学生所有排课规律信息.
    getStuArrangRuleByGrade (callback, type) {
      getStudentArrangRuleByGrade(this.studentInfo.OLAPKey, this.curSelectedClassInfo.OLAPKey).then(result => {
        // 对于排课后 刷新已排节数
        if (type == 'refresh') {
          let findItem = result.data.find(obj => { return obj.OLAPKey == this.curSelectedCircleCourseData.StructuredRuleKey; });
          if (findItem) {
            this.curSelectedCircleCourseData.alreadyAttenCount = findItem.ClassCount;
          } else {
            this.curSelectedCircleCourseData.alreadyAttenCount = 0;
          }
        } else {
          // this.studentArrangedCourseList = result.data;
        }
        this.studentArrangedCourseList = result.data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    // 选中学生后
    doAfterChooseStudent (item, type) {
      console.log('切换学生后', item, this.dataObj);
      this.studentInfo = item;
      this.loadStructedRule();
    },
    // 查询 根据学生ID 排课规律ID 查所有的排课信息
    // getCourseAndStudentCourseArrangByRule () {
    //   GetCourseAndStudentCourseArrangByRule(this.studentInfo.OLAPKey, this.curSelectedCircleCourseData.StructuredRuleKey).then(result => {
    //     if (result.code == 0) {
    //       this.scheduleCourseList = result.data.ScheduleCourseList;
    //     }
    //   });
    // },
    // 点击一节课 弹出层位置计算
    doOneTopValueEvent (courseInfo, e) {
      console.log(courseInfo, 'courseInfo');
         // 学生退班：退班逻辑调整
      if (this.dataObj.classTitle == '退班' && this.vIsSysVersionTY) {
        var findIndex = this.vCourseList.findIndex(obj => {
		    return obj.StructuredRuleKey == courseInfo.StructuredRuleKey;
        });
        this.vCourseList.splice(findIndex, 1);
      }
      this.curSelectedCircleCourseData = courseInfo;
      if (this.vIsSysVersionTY) {
        if (this.curSelectedCircleCourseData.IsSyncArrangedKey > 0) {
          let IsSyncArrangedKey = 0;// 是否循环跟排 0-否;1-是
          this.cancelAllArrangeClick(IsSyncArrangedKey);
        } else {
          this.addToCircleCourseForStudent();
        }
      } else {
        this.doOneTopValueCount(e);
        // this.getCourseAndStudentCourseArrangByRule();
      }
    },
    // 通用版 安排进循环课
    addToCircleCourseForStudent () {
      let params = {
        StudentKey: this.studentInfo.OLAPKey,
        StructuredRuleKey: this.curSelectedCircleCourseData.StructuredRuleKey,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: this.curSelectedCircleCourseData.LastClassTime,
        ClassCount: Number(this.curSelectedCircleCourseData.ClassCount),
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      ArrangeStudent2MutiSameApplyClassCourseByRule(params).then(result => {
        let ruleCourse = this.hasSyncArrangedRuleCourseList.find(obj => { return obj.StructuredRuleKey == this.curSelectedCircleCourseData.StructuredRuleKey; });
        // 首次进入是已加入循环课的，反选时，循环课退课节数要减1
        if (ruleCourse) { this.quitoutCourseNum -= 1; }
        this.$bus.emit('afterArrangeCirculationCourse');
        this.$emit('afterSucces', this.curSelectedClassInfo.OLAPKey, this.studentInfo.OLAPKey);
        this.doAfterArrangeStudentSuccess();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 计算TOP值
    doOneTopValueCount (e) {
      let offsetTop = e.currentTarget.offsetTop;
      this.topValue = offsetTop - this.newScrollTop + 42;
      this.isShowItem = true;
    },
    // 滚动后触发获取弹窗位置
    doAfterEventScrollTOP (e) {
      this.newScrollTop = e.target.scrollTop;
      this.isShowItem = false;
    },
    // 点击弹出层项
    doClickPopItem (menu) {
      if (menu.eventName == 'batch' || menu.eventName == 'cancelAllArrange') {
        this.paramsForRule.StudentKey = this.studentInfo.OLAPKey;
        this.paramsForRule.StructuredRuleKey = this.curSelectedCircleCourseData.StructuredRuleKey;
        this.paramsForRule.StartTime = this.$utils.formatDateToLine(Date.new());
      }
      let paramsData = { studentKey: this.studentInfo.OLAPKey, courseNameKey: this.curSelectedCircleCourseData.CourseNameKey, classGradeKey: this.curSelectedCircleCourseData.GradeClassKey, ruleID: this.curSelectedCircleCourseData.StructuredRuleKey };

      switch (menu.eventName) {
        case 'batch':
          let item = {
            routerName: this.$route.name,
            moduleName: 'batchArrangeScheduleCourse',
            data: { paramsInfo: paramsData }
          };
          this.$bus.emit('needOpenRouter', item);
          break;
        case 'freeChoice':
          let moduleInfo = {
            routerName: this.$route.name,
            moduleName: 'freeChoiceArrangeScheduleCourse',
            data: { paramsInfo: paramsData }
          };
          this.$bus.emit('needOpenRouter', moduleInfo);
          break;
        case 'cancelAllArrange':
          layer.confirm('请确认是否退出已排的课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.cancelAllArrangeClick();
                  layer.close();
                }
              }
            ]
          });
          break;
        default:
          break;
      }
      this.titleName = this.dataObj.classTitle;
      this.isShowItem = false;
    },
    // 取消全部安排
    cancelAllArrangeClick (IsSyncArrangedKey) {
      if (!this.curSelectedCircleCourseData.alreadyAttenCount) {
        return;
      }
      CancelStudent2MutiSameApplyClassCourseByRule(this,this.studentInfo.OLAPKey, this.curSelectedCircleCourseData.StructuredRuleKey, IsSyncArrangedKey).then(result => {
        this.$emit('afterSucces', this.curSelectedClassInfo.OLAPKey, this.studentInfo.OLAPKey);
        this.doAfterArrangeStudentSuccess();
        let ruleCourse = this.hasSyncArrangedRuleCourseList.find(obj => { return obj.StructuredRuleKey == this.curSelectedCircleCourseData.StructuredRuleKey; });
        // 首次进入是已加入循环课的，正选时，循环课退课节数要加1
        if (ruleCourse) { this.quitoutCourseNum += 1; }
        this.$bus.emit('afterArrangeCirculationCourse');
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAfterArrangeStudentSuccess (cancelArr, arrangeArr) {
      this.loadStructedRule('refresh');
    }
  }
};
</script>

<style>
</style>