<template>
  <div>
    <div class="course_time_select "
         :class="{form_info_line:formInfoLine,form_info_required:required}">
      <div class="select_name">
        <span>上课时间</span>
        <span v-if="helpDescription">
          <input-form-tip :tips='helpDescription'></input-form-tip>
        </span>
      </div>
      <div>
        <el-select v-model="vSelectedTimeRange"
                   popper-class="course_time_select_list"
                   placeholder="请选择"
                   @change="selectTimeRange">
          <el-option v-for="item in timeRangeList"
                     :key="item.OLAPKey"
                     :value="item">
            <div class="select_list">
              <div>{{item.SClassTimeName}}-{{item.EClassTimeName}}</div>
              <div class="select_list_delete"
                   @click.stop="delTimeRange(item)"></div>
            </div>
          </el-option>
        </el-select>
      </div>
      <div class="select_add"
           @click.stop="showAddTimeRange">新增</div>
    </div>
    <custom-dialog title="添加上课时间段"
                   class="student_info_dialog defult_dialog" 
                   :visible.sync="isShowAddTimeRange"
                   :before-close="closeTimeRangeDilog">
      <set-class-time-rang @onConfirmClick="addClassTimeRange"
                           @cancelClick="closeTimeRangeDilog"></set-class-time-rang> 
    </custom-dialog>
  </div>
</template>

<script>
import { GetClassTimeRange, DeleteClassTimeRange, AddClassTimeRange } from '../../API/workbench.js';
import setClassTimeRang from '../common/set-class-time-rang';
export default {
  components: {
    setClassTimeRang
  },
  props: {
    classTimeData: {
      type: Object,
      default: function () {
        return {
          SClassTimeName: '',
          EClassTimeName: ''
        };
      }
    },
    formInfoLine: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    helpDescription: String // 表单说明提示
  },
  data () {
    return {
      timeRangeList: [],      // 时间段列表
      isShowAddTimeRange: false,   // 显示新增时间.
      seletedTimeInfo: null
    };
  },
  computed: {
    vSelectedTimeRange: {
      get () {
        if (this.seletedTimeInfo) {
          return this.seletedTimeInfo.SClassTimeName + '-' + this.seletedTimeInfo.EClassTimeName;
        } else {
          return '';
        }
      },
      set (newValue) {
        return this.seletedTimeInfo;
      }
    }
  },

  watch: {
    'classTimeData.SClassTimeName': {
      handler (n, o) {
        if (n != o) {
          this.setSeletedTimeInfo();
        }
      },
      deep: true
    }
  },
  created () {
    this.getClassTimeRange();
  },
  methods: {
    // 获取所有上课时间段
    getClassTimeRange () {
      GetClassTimeRange().then(result => {
        this.timeRangeList = result.data;
        this.setSeletedTimeInfo();
      }, error => {
        console.log(error, 'GetClassTimeRange');
      });
    },
    setSeletedTimeInfo () {
      if (this.classTimeData.SClassTimeName && this.classTimeData.EClassTimeName) {
        this.seletedTimeInfo = {
          ClassTimeName: this.classTimeData.SClassTimeName + '-' + this.classTimeData.EClassTimeName,
          EClassTimeName: this.classTimeData.EClassTimeName,
          OLAPKey: 0,
          SClassTimeName: this.classTimeData.SClassTimeName
        };
        let existsItem = this.timeRangeList.find(obj => { return (obj.SClassTimeName + '-' + obj.EClassTimeName) == (this.classTimeData.SClassTimeName + '-' + this.classTimeData.EClassTimeName); });
        if (!existsItem) {
          this.timeRangeList.push(this.seletedTimeInfo);
          this.timeRangeList.sort((x, y) => { return (Number(x.SClassTimeName.split(':')[0] * 60) + Number(x.SClassTimeName.split(':')[1])) - (Number(y.SClassTimeName.split(':')[0] * 60) + Number(y.SClassTimeName.split(':')[1])); });
        }
      }
    },

    // 选中时间段查询
    selectTimeRange (item) {
      this.seletedTimeInfo = item;
      this.$emit('onSelectTimeRange', this.seletedTimeInfo);
    },
    // 点击显示添加上课时间
    showAddTimeRange () {
      this.isShowAddTimeRange = true;
    },
    // 关闭添加上课时间
    closeTimeRangeDilog () {
      this.isShowAddTimeRange = false;
    },
    // 新增时间段
    addClassTimeRange (startTime, endTime, callBack) {
      AddClassTimeRange(startTime, endTime).then(result => {
        this.closeTimeRangeDilog();
        result.data.ClassTimeName = result.data.SClassTimeName + '-' + result.data.EClassTimeName;
        this.seletedTimeInfo = result.data;
        this.$emit('onSelectTimeRange', this.seletedTimeInfo);
        let isExistsItem = this.timeRangeList.find(obj => { return obj.OLAPKey == result.data.OLAPKey; });
        if (!isExistsItem) {
          this.timeRangeList.push(result.data);
        }
        this.timeRangeList.sort((x, y) => { return (Number(x.SClassTimeName.split(':')[0] * 60) + Number(x.SClassTimeName.split(':')[1])) - (Number(y.SClassTimeName.split(':')[0] * 60) + Number(y.SClassTimeName.split(':')[1])); });
        if (callBack) {
          callBack();
        }
      }).catch(error => {
        if (callBack) {
          callBack();
        }
        console.log(error, 'errorGetClassTimeRange');
      });
    },
    // 删除时间段
    delTimeRange (item) {
      DeleteClassTimeRange(item.OLAPKey).then(result => {
        let locaIndex = this.timeRangeList.findIndex(o => {
          return o.OLAPKey == item.OLAPKey;
        });
        if (locaIndex >= 0) {
          this.timeRangeList.splice(locaIndex, 1);
        }
        // 删除时段是当前选中时段
        if (this.seletedTimeInfo && this.seletedTimeInfo.OLAPKey == item.OLAPKey) {
          this.seletedTimeInfo = null;
          this.$emit('onDelTimeRange', this.seletedTimeInfo);
        }
      }, error => {
        console.log(error, 'DeleteClassTimeRange');
      });
    }

  }
};
</script>

<style>
</style>