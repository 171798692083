<template>
  <!-- 通用列表 norole-->
  <div class="headquarters_info_summarizing authorization_table">
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  :tableData="authorizationList"
                  :queryParams="searchObj"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="loadTableData"></table-list>
    </div>
    <custom-dialog :title="'授权设备'"
                   width="740px"
                   :visible.sync="showAuthList"
                   :before-close="closeAuthListDialog">
      <auth-manager-list :dataInfo="{CoursewarePackageKey:coursewareIndo.CoursewarePackageKey}"
                         @afterSucces="getAllAuthCoursewarePackageList"></auth-manager-list>
    </custom-dialog>
  </div>
</template>
<script>
import { SearchPageAuthCoursewarePackage } from '../../API/workbench.js';
import tableList from '../common/table-list/index';
import authManagerList from '../pop-up-menu/auth-components/auth-manager-list';
export default {
  props: {

  },
  components: {
    tableList,
    authManagerList
  },
  data () {
    return {
      searchObj: {
        pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
        pageSize: 10 //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      },
      showAuthList: false,
      coursewareIndo: {},
      authorizationList: [],
      tableColumns: [
        {
          label: '授权日期',
          width: 120,
          prop: 'AuthorizeTime',
          type: 'date-item'
        },
        {
          label: '授权方',
          prop: 'SaaSClientKeyValue',
          width: 160,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '课件包名称',
          prop: 'CoursewarePackageText',
          width: 'auto',
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '结算方式',
          prop: 'ClearingFormKeyValue',
          width: 140,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '授权设备',
          prop: 'AuthorizeDeviceNum',
          width: 120,
          type: 'number-item',
          extend: {
            className: 'font_blue font_border_blue',
            type: 'expand',
            click: (rowData) => { this.editAuthorizationEquipment(rowData); }
          }
        },
        {
          label: '发放',
          prop: 'ReleasedNum',
          width: 120,
          align: 'left',
          type: 'number-item'
        },
        {
          label: '使用',
          prop: 'UsageNum',
          width: 120,
          align: 'left',
          type: 'number-item'
        },
        {
          label: '剩余',
          width: 120,
          prop: 'ResidueNum',
          type: 'number-item'
        },
        {
          label: '状态',
          prop: 'StatusKeyValueText',
          width: 100,
          align: 'left',
          type: 'text-item',
          extend: {
            setClassName: (rowData) => { // 状态 0-已暂停;1-使用中;2-已用完
              if (rowData.StatusKey == 0) {
                return 'status_spot_red';
              } else if (rowData.StatusKey == 1) {
                return 'status_spot_green';
              } else if (rowData.StatusKey == 2) {
                return 'status_spot_gray';
              } else {
                return '';
              }
            }
          }
        },
        {
          label: '定位授权',
          prop: 'IsLocationLockKeyValue',
          width: 90,
          type: 'text-item'
        }
      ],
      totalNum: 0
    };
  },
  watch: {

  },
  created () {
    this.getAllAuthCoursewarePackageList();
  },
  computed: {

  },
  methods: {
    // 门店-授权课件包列表
    getAllAuthCoursewarePackageList () {
      SearchPageAuthCoursewarePackage(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.authorizationList = result.data.PageDataList;
        this.authorizationList.forEach(o => { // 状态 0-已暂停;1-使用中;2-已用完
          o.CoursewarePackageText = (o.ParentKeyValue ? o.ParentKeyValue + '-' + o.CoursewarePackageKeyValue : o.CoursewarePackageKeyValue);
          if (Number(o.StatusKey) == 0) {
            o.StatusKeyValueText = '已暂停';
          } else if (Number(o.StatusKey) == 1) {
            o.StatusKeyValueText = '使用中';
          } else if (Number(o.StatusKey) == 2) {
            o.StatusKeyValueText = '已用完';
          }
          o.IsLocationLockKeyValue = o.IsLocationLockKey > 0 ? '已开启' : '未开启';
        });
        console.log(result, '校区管理-分页查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 授权列表
    editAuthorizationEquipment (rowData) {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 123)) {
        this.coursewareIndo = rowData;
        this.showAuthList = true;
      } else {
        this.$utils.CheckModulePowerToTips(123);
      }
    },
    // 关闭
    closeAuthListDialog () {
      this.showAuthList = false;
    },
    // 搜索
    search () {
      // console.log('搜索', this.params);
      // this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      // this.$emit('loadTableData', this.params);
    },
    // 清除
    clear () {
      // console.log('清除', this.params);
      // this.params.searchText = '';
      // this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      // this.$emit('loadTableData', this.params);
    },
    initParams () {
      // this.params.pageIndex = 0;
      // this.params.orderBy = '';
      // this.params.IsActiveKey = '';//	整型	可选		数据源：GET	是否活跃校区
      // this.params.IsStopKey = ''; //	整型	可选		数据源：GET	是否停用
      // this.params.IsExpiredKey = '';//	整型	可选		数据源：GET	是否已过期
      // this.params.IsExpired30Key = ''; //	整型	可选		数据源：GET	是否30天内过期
    },
    // 排序
    loadTableData (queryParams) {
      console.log('排序', queryParams);
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      this.getAllAuthCoursewarePackageList();
    }
  }
};
</script>
<style>
/* .authorization_table .el-table__body-wrapper.is-scrolling-none {
  overflow-y: auto;
  max-height: 440px;
} */
.authorization_table .introduce_list .status_spot_red:after {
  width: 7px;
  height: 7px;
}
.authorization_table .introduce_list .status_spot_green:after {
  width: 7px;
  height: 7px;
}
.authorization_table .introduce_list .status_spot_gray:after {
  width: 7px;
  height: 7px;
}
</style>

