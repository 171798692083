<template>
  <div>
    <div v-if="isShowAddBtn">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'register-customer'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='48'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>
    </div>
          <!-- <div v-else-if="CustStatusKey==1">
        <power-button :item="addbtn"
                      :className="''"
                      @onClick="addOldStudent">
          <span class="defult_dialog_header_btn fr">
            <span class="btn_add">+</span>{{'录入老生'}}</span>
        </power-button>
      </div> -->
    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="studenSearchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                @onSearchEvent="searchTextSearch"></input-search-contain-btn>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list"
               @scroll="scrollTOP">
            <single-student-item v-for="item in studentList"
                                 :key="item.OLAPKey"
                                 :isTestStudentItem="item"
                                 @selectedItem="selectedItem"></single-student-item>
            <div v-if="loading"
                 class="students_loading all_list_bottom">
              <span class="el-icon-loading"></span>
              <span class="loading_style">加载中...</span>
            </div>
            <div v-else-if="studentList.length==0">
              <div class="monitoring_content_nodata">
                <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
              </div>
            </div>
            <div v-else
                 class="all_list_bottom">
              <span class="complete">已显示全部</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>
import singleSelectionItem from '../../../common/single-selection-item';
import singleStudentItem from './single-student-item';
import { messageSearchPageForStudent } from '../../../../API/workbench';
export default {

  data () {
    return {
      studentList: [],
      studenSearchText: '',
      pageSize: 20,     // 每次搜索人数.
      pageIndex: 0,      // 每次查询页数
      loading: false,
      noMore: false, // 没有更多数据加载了
      baseInfoNames: null // 基础资料的名字集
    };
  },
  props: {
    formTitle: String, // 自定义标题名
    switchItem: [String, Number, Boolean], // 自定义跳转，不跳转路由的返回.switchItem为任意值
    curModuleName: String,
    isShowAddBtn: {
      type: Boolean,
      default: true
    },
    CustStatusKey: { // 是否在校 0-否; 1-是; 空- 全部
      type: [String, Number],
      default: ''
    }
  },
  components: {
    singleStudentItem,
    singleSelectionItem
  },
  name: 'studentFor',
  watch: {
    studenSearchText () {
      // console.log(this.studenSearchText, 'studenSearchText');
      if (this.studenSearchText == '') {
        this.studentList.length = 0;
        this.pageSize = 20;
        this.pageIndex = 0;
        this.noMore = false;
        this.getStudentList();
      }
      //  else {
      //   this.studentList.length = 0;
      //   this.textSeachergetStudentList();
      // }
    }
  },
  created () {
    this.getStudentList();
    this.baseInfoNames = this.$initJson.baseInfoNames;
  },
  methods: {
    // 新增回调
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      this.$emit('selectedItem', result);
    },
    // 点击搜索
    searchTextSearch () {
      this.studenSearchText = this.studenSearchText.replace(/ /g, '');
      this.pageIndex = 0;
      this.noMore = false;
      this.getStudentList();
    },
    doAfterSelectionItem (item) {
      this.studenSearchText = '';
      this.$emit('selectedItem', item);
    },
    // 自定义返回或取消事件
    returnEvent () {
      if (this.switchItem) {      // 不跳转路由的返回.switchItem为任意值.
        this.$emit('return');
      } else {
        this.$router.push('/indexview');
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      // console.log(this.noMore, 'this.noMore');
       if (parseInt(ETarget.scrollTop)+20 >= parseInt(HeightView) && !this.noMore&&!this.loading) {
        this.getStudentList();
      }
    },
    textSeachergetStudentList () {                      // 根据搜索条件获取学生.
      let that = this;
      messageSearchPageForStudent(0, 0, that.studenSearchText, '', '').then(result => {
        that.studentList.length = 0;
        result.data.PageDataList.forEach(obj => {
          obj.isActive = false;
          that.studentList.push(obj);
        });
        that.pageIndex++;
      }).catch(error => {
        console.log(error);
      });
    },
    getStudentList () {
      this.loading = true;
      messageSearchPageForStudent(this.pageIndex, this.pageSize, this.studenSearchText, '', '', this.CustStatusKey).then(result => {
        if (result.data) {
          // 切换学生类型时 (首页)需清空数据
          if (this.pageIndex == 0) {
            this.studentList = [];
          }
          result.data.PageDataList.forEach(o => {
            o.isActive = false;
            let hasFind = false;
            if (this.studentList.length > 0) {
              hasFind = this.studentList.findIndex(p => p.OLAPKey == o.OLAPKey) >= 0;
            }
            if (!hasFind) {
              this.studentList.push(o);
            }
          });
          if (result.data.PageDataList.length < this.pageSize) {
            this.noMore = true;
          }
          // this.totalNumForStudent = result.data.Total;
          // this.pageIndex++;
        }
        this.loading = false;
        this.pageIndex++;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    // 选择学生
    selectedItem (item) {
      this.studentList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.verifyStudent(item);
      this.scrollBackTop();
    },
    // 验证学生
    verifyStudent (item) {
      console.log(this.switchItem, 'item');
      if (item.IsConfirmKey == 0 && this.switchItem !== 1) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
      } else {
        this.studenSearchText = '';
        this.$emit('selectedItem', item);
      }
    },
    scrollBackTop () {
      this.studenSearchText = '';
      document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_content_for_student')[1]) {
        document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.studenSearchText = '';
    }
  },
  computed: {

  }
};
</script>

