<template>
  <!-- 报名记录 -->
  <div class="application_records_list">
    <div class=""
         style="display: flex;margin-bottom: 20px;">
      <date-range ref="monthPicker"
                  :fromTitle="'报名日期'"
                  :searchObj="searchObj"
                  @changeDate="changeSaveDate"></date-range>

      <div class="table_select_box"
           style="margin-right: 20px;">
        <div class="table_select_title">是否关联报名单</div>
        <el-select v-model="selectedApplicationRecordsState"
                   filterable
                   style="width: 200px;"
                   placeholder="必选"
                   @change="chgSearchApplicationRecordsStateValue"
                   value-key="OLAPKey">
          <el-option v-for="item in ApplicationRecordsStateList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <!-- 已选条件 -->
      <input-search-contain-btn v-model="queryParams.searchText"
                                :placeholder="'搜索学生'"
                                @clearSearch="clearExtendSearchParams"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="ApplicationRecordsList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getMessageSearchPageStudentSignUpList"></table-list>
    </div>
    <custom-dialog :title="'报名记录'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowApplication"
                   :before-close="doShowApplicationHide">
      <apply-grade :threadInfo="threadInfo"
                   :isApplicationRecord="isApplicationRecord"
                   @afterSuccess="closeApplyGrade">
      </apply-grade>
    </custom-dialog>

    <!-- 图1 -->
    <custom-dialog :title="'关联学生档案'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentOne"
                   :before-close="doShowApplicationHide">
      <clew-add-form :threadInfo="threadInfo"
                     :isPopupShow="true"
                     @afterSuccess="afterSuccess">
      </clew-add-form>
    </custom-dialog>
    <!-- 图2 和 图3-->
    <custom-dialog :title="'关联学生档案'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentTwoThree"
                   :before-close="doShowApplicationHide">
      <association-student-form :threadInfo="threadInfo"
                                :isPopupShow="true"
                                :isAssociationTwoOrThree="isAssociationTwoOrThree"
                                @afterSuccess="afterSuccess"
                                @cancelClick="doShowApplicationHide">
      </association-student-form>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageStudentSignUpList, CancelRelevancy, GetBindingStatus } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import applyGrade from '../../pop-up-menu/apply-record';
import clewAddForm from '../../student/clew-add-form';
import associationStudentForm from '../../recruit-students-management/form/association-student-form';

export default {
  data () {
    return {
      threadInfo: {},
      isApplicationRecord: true,
      isShowApplication: false,
      isShowAssociationStudentOne: false, // 关联学生档案 1
      isShowAssociationStudentTwoThree: false, // 关联学生档案 2 3
      isAssociationTwoOrThree: false, // false 图2   ， true 图3
      selectedApplicationRecordsState: '全部', // 最近跟单
      ApplicationRecordsStateList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '是'
        },
        {
          OLAPKey: -1,
          MainDemoName: '否'
        }
      ],
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsRelevancy: '' // 是否关联 -1-否1-是
      },
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      ApplicationRecordsList: [],
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '报名日期',
          width: 100,
          sortable: 'custom',
          prop: 'SignUpTime',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '报名单号',
          width: 130,
          prop: 'SignUpOrderNumber',
          type: 'text-item'
        },
        {
          label: '金额',
          width: 80,
          prop: 'CollectMoneyAmount',
          type: 'text-item'
        },
        {
          label: '报名内容',
          prop: 'applicationContent',
          type: 'text-item'
        },
        {
          label: '操作',
          width: 130,
          prop: 'optionClick',
          align: 'center',
          type: 'text-item'
        }
        // {
        //   label: '操作',
        //   prop: 'option',
        //   width: 200,
        //   align: 'center',
        //   type: 'table-btn',
        //   extend: {
        //     tableBtnGroup: [
        //       {
        //         name: '关联',
        //         extend: {
        //           isHide: false,
        //           click: (rowData) => {
        //             this.editBtn(rowData, 1);
        //           }
        //         }
        //       },
        //       {
        //         name: '清除关联',
        //         extend: {
        //           isHide: (rowData) => {
        //             return Number(rowData.IsRelevancyKey) != 1;
        //           },
        //           click: (rowData) => {
        //             this.editBtn(rowData, 2);
        //           }
        //         }
        //       }
        //     ]
        //   }
        // }
      ],
      totalNum: 0
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    applyGrade,
    clewAddForm,
    associationStudentForm
  },
  computed: {
  },
  created () {
    this.getMessageSearchPageStudentSignUpList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
    window.associationClick = this.associationClick;
    window.closeClick = this.closeClick;
  },
  methods: {
    chgSearchApplicationRecordsStateValue (item) {
      console.log(item, 'item');
      this.queryParams.pageIndex = 0;
      this.selectedApplicationRecordsState = item.MainDemoName;
      this.queryParams.IsRelevancy = item.OLAPKey;
      this.getMessageSearchPageStudentSignUpList();
    },
    associationClick (GenerateTime, OLAPKey, SalesKey, StudentKey, StudentThreadKey, StudentSignUpKey, index) {
      let StudentName = document.getElementsByClassName('students_class');
      console.log(StudentName[index], 'StudentName');
      this.threadInfo = {
        GenerateTime: GenerateTime,
        OLAPKey: OLAPKey,
        SalesKey: SalesKey,
        StudentKey: StudentKey,
        StudentKeyValue: StudentName[index].innerHTML,
        StudentThreadKey: StudentThreadKey,
        StudentSignUpKey: StudentSignUpKey
      };

      console.log('associationClick 关联', this.threadInfo);
      if (Number(StudentKey) == 0) {
        // 未关联学生
        GetBindingStatus(StudentThreadKey).then(result => {
          console.log(result.data, 'GetBindingStatus 未关联学生');
          if (result.data.StatusKey == 1) {
            // 打开关联学生档案 1图
            this.isShowAssociationStudentOne = true;
          } else if (result.data.StatusKey == 2) {
            // 打开关联学生档案 2图
            this.isShowAssociationStudentTwoThree = true;
            this.isAssociationTwoOrThree = false;// 图2
          } else if (result.data.StatusKey == 3) {
            // 打开关联学生档案 2图
            this.isShowAssociationStudentTwoThree = true;
            this.isAssociationTwoOrThree = true;// 图3
          }
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        // 关联学生
        this.isApplicationRecord = true;
        this.isShowApplication = true;
      }
    },
    closeClick (StudentSignUpKey, OLAPKey) {
      console.log(StudentSignUpKey, OLAPKey, '清除关联');
      layer.confirm('确定清除关联吗？', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CancelRelevancy(StudentSignUpKey, OLAPKey).then(result => {
                layer.close();
                layer.alert('清除成功');
                this.getMessageSearchPageStudentSignUpList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });
    },
    // 报名记录 数据查询
    getMessageSearchPageStudentSignUpList () {
      MessageSearchPageStudentSignUpList(this.queryParams).then(result => {
        this.totalNum = result.data.Total;
        this.ApplicationRecordsList = result.data.PageDataList;
        this.ApplicationRecordsList.forEach((o, i) => {
          o.StudentKeyValue = o.StudentKeyValue ? '<div class="students_class">' + o.StudentKeyValue + '</div>' : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.SalesKeyValue = !o.SalesKeyValue ? '未分配' : o.SalesKeyValue;
          let money = '';
          let numbers = '';
          let allContent = '';
          o.optionClick = '';
          if (!o.SignUpSublist && o.SignUpSublist == null) {
            o.SignUpOrderNumber = '-';
            o.CollectMoneyAmount = '-';
            o.applicationContent = '-';
            o.optionClick = `<div style="display:flex;justify-content: space-evenly;" class="sign_up_sublist no_border_bottom"><a onClick="associationClick(
              ${o.GenerateTime},${o.OLAPKey},${o.SalesKey},${o.StudentKey},${o.StudentThreadKey},${o.StudentSignUpKey},${i})" style="color:#3498db;cursor: pointer;">关联</a></div>`;
          } else {
            o.SignUpSublist.forEach((j, ji) => {
              money = Number(j.CollectMoneyAmount) ? money + "<div style='height: " + 40 * (j.CollectMoney ? j.CollectMoney.length : 1) + 'px;line-height: ' + 40 * (j.CollectMoney ? j.CollectMoney.length : 1) + "px;' class='sign_up_sublist " +
                (o.SignUpSublist.length - 1 == ji ? 'no_border_bottom' : '') + "' >" + Number(j.CollectMoneyAmount) + '</div>' : '-';
              numbers = j.SignUpOrderNumber ? numbers + "<div style='height: " + 40 * (j.CollectMoney ? j.CollectMoney.length : 1) + 'px;line-height: ' + 40 * (j.CollectMoney ? j.CollectMoney.length : 1) + "px;' class='sign_up_sublist " + (o.SignUpSublist.length - 1 == ji ? 'no_border_bottom' : '') + "' >" + j.SignUpOrderNumber + '</div>' : '-';
              o.SignUpOrderNumber = numbers || '-';
              o.CollectMoneyAmount = money || '-';// 金额

              // 关联 清除关联
              o.optionClick = o.optionClick + `<div style="display:flex;justify-content: space-evenly;height: ${40 * (j.CollectMoney ? j.CollectMoney.length : 1)}px;line-height: ${40 * (j.CollectMoney ? j.CollectMoney.length : 1)}px;" class="sign_up_sublist ${(o.SignUpSublist.length - 1 == ji ? 'no_border_bottom' : '')}
                  "><a onClick="associationClick(${o.GenerateTime},${o.OLAPKey},${o.SalesKey},${o.StudentKey},${o.StudentThreadKey},${o.StudentSignUpKey},${i}
                  )" style="color:#3498db;cursor: pointer;display:${Number(o.IsRelevancyKey) != 1 ? 'none' : 'block'}">关联</a><a style="display:${Number(o.IsRelevancyKey) != 1 ? 'none' : 'block'};
                  color:#3498db;cursor: pointer;" onClick="closeClick(${o.StudentSignUpKey},${j.OLAPKey})">清除关联</a></div>`;
              //

              if (j.CollectMoney) {
                j.CollectMoney.forEach((k, ki) => {
                  allContent = allContent + '<div class="sign_up_sublist ' + (o.SignUpSublist.length - 1 == ji && j.CollectMoney.length - 1 == ki ? 'no_border_bottom' : '') + '"> <span class=' +
                    (Number(k.ClassUnitTypeKey) == 1 ? 'cyan_class' : Number(k.ClassUnitTypeKey) == 2 ? 'blue_class'
                      : Number(k.ClassUnitTypeKey) == 3 ? 'orange_class' : '') + '>' +
                    (Number(k.ClassUnitTypeKey) == 1 ? '引流' : Number(k.ClassUnitTypeKey) == 2 ? '赠送' : Number(k.ClassUnitTypeKey) == 3 ? '正课' : '') +
                    '</span>' + (k.ClassUnitTypeKey == 4 ? '<span class="grey_class">杂费</span>' : '') + '&nbsp;&nbsp;<span style="color: #dd761f;">|课|</span>' +
                    k.ApplyCorName + '&nbsp;&nbsp; * &nbsp;&nbsp;' + Number(k.NumCount) + k.UnitKeyValue + '</div>';
                  o.applicationContent = allContent;
                });
              } else {
                allContent = "<div class='sign_up_sublist " + (o.SignUpSublist.length - 1 == ji ? 'no_border_bottom' : '') + "' >" + '-</div>';
              }
            });
          }
        });
        console.log(result, '报名记录 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭报名收款   关联学生档案 1图
    doShowApplicationHide () {
      this.isShowApplication = false;
      this.isShowAssociationStudentOne = false;
      this.isShowAssociationStudentTwoThree = false;
    },
    closeApplyGrade () {
      this.isShowApplication = false;
      this.isShowAssociationStudentOne = false;
      this.isShowAssociationStudentTwoThree = false;
      this.getMessageSearchPageStudentSignUpList();
    },
    afterSuccess () {
      this.isShowApplication = false;
      this.isShowAssociationStudentOne = false;
      this.isShowAssociationStudentTwoThree = false;
      // 关联学生
      this.isApplicationRecord = true;
      this.isShowApplication = true;
      this.getMessageSearchPageStudentSignUpList();
    },
    changeSaveDate (dataInfo) {
      console.log(dataInfo, 'dataInfo');
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.StartTime = dataInfo.startTime;
      this.queryParams.EndTime = dataInfo.endTime;
      this.getMessageSearchPageStudentSignUpList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageStudentSignUpList();
    },
    clearExtendSearchParams () {
      this.selectedApplicationRecordsState = '全部';
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        IsRelevancy: ''
      };
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getMessageSearchPageStudentSignUpList();
    }
  }
};
</script>

<style scoped>
/* 未安排 */
.schedule_table_class >>> .el-table .grey_class {
  width: 52px;
  display: inline-block;
  color: #000;
  background: #ccc;
  border-radius: 4px;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
/* 未到 请假*/
.schedule_table_class >>> .el-table .orange_class {
  width: 52px;
  display: inline-block;
  color: #a36717;
  background: #facd91;
  border-radius: 4px;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
/* 改约 已排课 */
.schedule_table_class >>> .el-table .blue_class {
  width: 52px;
  display: inline-block;
  color: #093f7d;
  background: #81d3f8;
  border-radius: 4px;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
/* 已试课 出勤*/
.schedule_table_class >>> .el-table .cyan_class {
  width: 52px;
  display: inline-block;
  color: #63a103;
  background: #caf982;
  border-radius: 4px;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
/* 旷课 */
.schedule_table_class >>> .el-table .yellow_class {
  width: 52px;
  display: inline-block;
  color: #a36717;
  background: #facd91;
  border-radius: 4px;
  text-align: center;
}
.schedule_table_class >>> .el-table .sign_up_sublist {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #efefef;
  margin: 0px -11px;
  padding: 0 11px;
}
</style>

