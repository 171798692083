<template>
  <!-- 套餐项-->
  <div class="package_list"
       :class="{'type_disable':item.StatusKey == 3}"
       @click.stop="doGatherSelectedItemClick(item)">
    <div class="package_list_name">
      {{item.PackageName}}
    </div>
    <div class="package_list_number ">
      <span>￥{{$utils.mMoneyType(item.SalePrice,2)}}</span>
      <!-- <div class="package_list_number_type">引流活动价</div> -->
    </div>
  </div>
</template>  

<script> 

export default {

  data () {
    return {

    };
  },
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  created () {

  },
  computed: {
    vIsSysVersionTY () {
      return !this.$store.getters.isSysVersionTY;
    }
  },
  methods: {
    // 选择套餐项
    doGatherSelectedItemClick (item) {
      this.$emit('doComboSelectClick', item);
    }
  }
};
</script>

<style>

</style>