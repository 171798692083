<template>
  <div class="add_text_box">
    <div style="padding: 20px 15px;">
      <content-text ref="content"
                    v-model="text"
                    :placeholder="'请输入教学步骤'"
                    :maxLength="4000"
                    @input="saveText"></content-text>
    </div>
    <!-- <textarea ref="textareaBox"
              class="text_box"
              placeholder="请输入教学步骤"
              v-model="text"
              maxlength="4000"
              @input="editText"
              @change="changeVal"
              @focus="focusFun"
              @blur.prevent="saveText"></textarea> -->
    <div class="btn_group">
      <div class="menu_list type_06">
        上传视频教材
        <span class="form_info_field "
              style="z-index: 1;">
          <input-form-tip :tips="'上传图片大小不超过500MB，类型格式为.mp4。'"></input-form-tip>
        </span>
        <upload :attachType="'2'"
                :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareTeachingStep"
                :authorizedCode="authorizedCode"
                :coursewareType=2
                @saveAccessory="saveAccessory"></upload>
      </div>
      <div class="menu_list type_07">
        上传pdf教材
        <span class="form_info_field "
              style="z-index: 1;">
          <input-form-tip :tips="'上传pdf大小不超过20MB，类型格式为.pdf。'"></input-form-tip>
        </span>
        <upload :attachType="'5'"
                :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareTeachingStep"
                :authorizedCode="authorizedCode"
                :coursewareType=2
                @saveAccessory="saveAccessory"></upload>
      </div>
      <div class="menu_list type_08">
        其他附件（可下载）
        <span class="form_info_field "
              style="z-index: 1;">
          <input-form-tip :tips="'附件上传大小不能超过100MB'"></input-form-tip>
        </span>
        <upload :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareTeachingStep"
                :authorizedCode="authorizedCode"
                :coursewareType=3
                @saveAccessory="saveAccessory"></upload>
      </div>
    </div>
  </div>
</template>
<script>
import contentText from '../../../home-school-communication/common-component/release-content/content-text';
import upload from '../courseware-view/upload';
export default {
  data () {
    return {
      text: '',
      innerHTMLVal: ''
    };
  },
  props: {
    dataInfo: Object,
    authorizedCode: String,
    coursewareId: [String, Number]
  },
  components: {
    upload, contentText
  },
  created () {
    this.text = this.dataInfo.TeachingProcedure;
    this.innerHTMLVal = this.$utils.parseJson(this.text);
    console.log(this.innerHTMLVal, this.text, '教学步骤-onchange');
  },
  computed: {
    VisitAuthKey () {
      return this.dataInfo.Authorized.OLAPKey || '';
    }
  },
  mounted () {
    // this.$refs.textareaBox.focus();
  },
  methods: {
    focusFun (e) {
      // setTimeout(() => {
      console.log(this.innerHTMLVal, e.target, '聚焦-teaching-step-editor');
      if (this.innerHTMLVal && this.innerHTMLVal !== e.target.value) {
        this.$bus.emit('AftersCoursewareEditorState', 'onchange', Date.new().getTime());
      } else {
        this.$bus.emit('AftersCoursewareEditorState', 'onchange', -Date.new().getTime());
      }
      // }, 100);
    },
    changeVal (e) {
      console.log('changeVal', e.target.value)
    },
    editText (e) {
      this.$emit('editText', this.text);
      console.log(e.target.value, this.innerHTMLVal, '教学步骤-onchange');
      if (this.innerHTMLVal !== e.target.value) {
        this.$bus.emit('AftersCoursewareEditorState', 'onchange', Date.new().getTime());
      } else {
        this.$bus.emit('AftersCoursewareEditorState', 'onchange', -Date.new().getTime());
      }
    },
    saveText () {
      this.$emit('editText', this.text);
      this.$emit('saveText');
    },
    saveAccessory () {
      this.dataInfo.CoursewareTeachingStep.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      console.log(this.dataInfo, '教学步骤1');
      this.$emit('saveAccessory');
    },
    selectAccessory (data) {
      this.dataInfo.CoursewareTeachingStep.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      console.log(data, this.dataInfo, '教学步骤2');
      this.$emit('openCoursewareEdit', '2', data, 'pdf');
    }
  }
};
</script>
<style scoped>
.btn_group {
  width: 100%;
  height: 10%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 4;
  position: absolute;
  display: flex;
  justify-content: space-around;
  background: #f4f5f7;
}
.btn_group .menu_list {
  width: auto;
  font-size: 14px;
  line-height: 100px;
  background-repeat: no-repeat;
  background-position: center 35px;
  background-size: 28px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.btn_group .menu_list {
  background-position: left 35px;
}
.menu_list.type_06 {
  background-image: url(../../../../../public/image/material_icon06.png);
  margin-top: -10px;
  padding-left: 35px;
}
.menu_list.type_07 {
  background-image: url(../../../../../public/image/material_icon07.png);
  margin-top: -10px;
  padding-left: 35px;
}
.menu_list.type_08 {
  background-image: url(../../../../../public/image/material_icon08.png);
  margin-top: -10px;
  padding-left: 35px;
}
</style>