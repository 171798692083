<template>
  <div class="course_dispense_left_top">
    <div class="top_nav_ul">
      <div class="top_nav_list button"
           v-for="(item,index) in distributeType"
           :key="index"
           :class="{opt:item.isOpt}"
           @click="doSelectedDistributeType(item)">{{item.name}}</div>
    </div>

    <div class="top_date_box"
         v-if="distributeType[2].isOpt">
      <div class="top_date_select_title">课件结束日期</div>
      <div class="top_select_box">
        <el-select v-model="defaultListVal"
                   value-key="key"
                   placeholder="选择"
                   @change="doSelectedDateType">
          <el-option v-for="item in optionsDateType"
                     :key="item.key"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="top_select_box">
      <div class="top_select_title">
        <el-select :value="searchType.label"
                   value-key="key"
                   placeholder="选择"
                   @change="doSelectedType">
          <el-option v-for="item in optionsType"
                     :key="item.key"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="top_select_content">
        <el-select v-model="vTypeValue"
                   filterable
                   value-key="OLAPKey"
                   placeholder="选择"
                   @change="doSelectedClassOrCourseItem">

          <el-option v-for="item in optionsList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item">
          </el-option>

        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { SelectTheClassType, SearchClass } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      // 是否分发 1：已分发 2：未分发 0：全部
      distributeType: [
        { name: '全部', key: 0, value: 'QB', isOpt: true },
        { name: '未分发', key: 2, value: 'WFF', isOpt: false },
        { name: '已分发', key: 1, value: 'YFF', isOpt: false }
      ],
      defaultListVal: '全部',
      optionsDateType: [
        {
          key: 0,
          label: '全部'
        },
        {
          key: 1,
          label: '下周'
        },
        {
          key: 2,
          label: '本月'
        },
        {
          key: 3,
          label: '下个月'
        }
      ],
      optionsType: [
        { key: 1, label: '班级' },
        { key: 2, label: '课程' }
      ],
      optionsList: [],
      searchType: { key: 1, label: '班级' },
      searchItem: { OLAPKey: 0, MainDemoName: '全部' },
      switchoverHint: true
    };
  },
  props: {
    selectCourseList: Array
  },
  watch: {

  },
  created () {
    //  默认初始化班级
    this.doSelectedType({ key: 1, label: '班级' });
    console.log(this.optionsList, '选择过滤类型（班级/课程）');
  },

  computed: {
    vTypeValue: {
      get () {
        return this.searchItem.MainDemoName;
      },
      set (newValue) {
        return this.searchItem.MainDemoName;
      }
    }
  },
  methods: {
    loadData () {
      if (this.searchType.key == 2) { // 课程
        this.getCourseNameList();
      } else { // 班级
        this.getValidClassList();
      }
    },
    // 调用课类课名列表接口
    getCourseNameList () {
      SelectTheClassType().then(result => {
        this.optionsList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
        this.optionsList.unshift({ MainDemoName: '全部', OLAPKey: 0 });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 获取有效班级列表
    getValidClassList () {
      SearchClass().then(result => {
        this.optionsList = result.data;
        this.optionsList.unshift({ MainDemoName: '全部', OLAPKey: 0 });
        console.log(this.vValidClassList, this.$store.getters, '获取有效班级列表');
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选择分发类型过滤（ 是否分发 1：已分发 2：未分发 0：全部）
    doSelectedDistributeType (item) {
      console.log(this.selectCourseList, '获取有效班级列表');
      if (this.selectCourseList.length > 0 && this.switchoverHint) {
        layer.confirm('切换后将会清除当前已选中项，请问是否继续?', {
          btn: [{
            name: '确认',
            callBack: () => {
              this.switchoverHint = false;
              this.doSelectedDistributeType(item);
              layer.close();
            }
          }
          ]
        });
        return;
      }
      this.switchoverHint = true;
      this.distributeType.forEach(o => {
        if (o.value == item.value) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.$emit('doSelectedDistributeType', item);
    },
    // 选择已分发日期过滤（0：全部，1：下周，2：本月，3：下个月）
    doSelectedDateType (item) {
      console.log(item, '选择已分发日期过滤（0：全部，1：下周，2：本月，3：下个月）');
      if (this.selectCourseList.length > 0 && this.switchoverHint) {
        layer.confirm('切换后将会清除当前已选中项，请问是否继续?', {
          btn: [{
            name: '确认',
            callBack: () => {
              this.switchoverHint = false;
              this.doSelectedDateType(item);
              layer.close();
            }
          }
          ]
        });
        return;
      }
      this.switchoverHint = true;
      this.$emit('doSelectedDateType', item);
    },
    // 选择过滤类型（1：班级:；2：课程）
    doSelectedType (item) {
      if (this.selectCourseList.length > 0 && this.switchoverHint) {
        layer.confirm('切换后将会清除当前已选中项，请问是否继续?', {
          btn: [{
            name: '确认',
            callBack: () => {
              this.switchoverHint = false;
              this.doSelectedType(item);
              layer.close();
            }
          }
          ]
        });
        return;
      }
      this.switchoverHint = true;
      this.searchType = item;
      if (item.key == 1) {
        this.getValidClassList();
      } else {
        this.getCourseNameList();
      }
      this.searchItem = { MainDemoName: '全部', OLAPKey: 0 };
      // this.optionsList.unshift(this.searchItem);
      this.$emit('doSelectedClassOrCourseItem', this.searchItem, 0);
    },
    // 选择过滤项（班级item/课程item）
    doSelectedClassOrCourseItem (item) {
      console.log(item, '选择过滤项（班级/课程）');
      if (this.selectCourseList.length > 0 && this.switchoverHint) {
        layer.confirm('切换后将会清除当前已选中项，请问是否继续?', {
          btn: [{
            name: '确认',
            callBack: () => {
              this.switchoverHint = false;
              this.doSelectedClassOrCourseItem(item);
              layer.close();
            }
          }
          ]
        });
        return;
      }
      this.switchoverHint = true;
      this.searchItem = item;
      this.$emit('doSelectedClassOrCourseItem', this.searchItem, this.searchType.key);
    }
  }
};
</script>

<style>
/* .el-select .el-input--suffix .el-input__inner {
  border: none;
  background-color: transparent;
  white-space: nowrap ;
  overflow: hidden ;
  text-overflow: ellipsis;
  width: 175px;
}
.el-select .el-input.is-focus .el-input__inner {
  white-space: nowrap ;
  overflow: hidden ;
  text-overflow: ellipsis ;
  width: 175px ;
  border-color: #3498db;
} */
</style>