<template>
  <!-- 基础设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="mendian_setting_content  single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <div>
          <input-text :formTitle="'名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'名称'"
                      :required="true"
                      v-model="dataJson.MainDemoName"></input-text>

          <!-- <input-text :formTitle="'手机号'"
                      :readonly="isReadonly"
                      :formPlaceholder="''"
                      v-model="dataJson.PhoneName"></input-text> -->

          <input-phone :formTitle="'手机号'"
                       :formPlaceholder="'选填'"
                       :required="false"
                       :readonly="isReadonly"
                       v-model="dataJson.PhoneName"></input-phone>

          <input-text :formTitle="'供应范围'"
                      class="no_border_bottom"
                      :readonly="isReadonly"
                      :formPlaceholder="''"
                      v-model="dataJson.SupplyRangeSupport"></input-text>
        </div>

      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else
         class="on_form_footer_btn_group"></div>
  </div>

</template> 

<script>
import { AddSupplier, EditSupplier } from '../../../../API/workbench';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    editItem: {// 修改项
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      newInputText: '', // 默认值
      // 数据对象
      dataJson: {
        MainDemoName: '', // -- 全名
        SimpleName: '', // --简称
        PhoneName: '', // --手机
        SupplyRangeSupport: '', // -- 供应范围
        RemarksScript: '' // --备注
      }
    };
  },

  components: {

  },

  created () {
    if (JSON.stringify(this.editItem) != '{}') {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.MainDemoName = this.editItem.MainDemoName;
      this.dataJson.SimpleName = this.editItem.SimpleName;
      this.dataJson.PhoneName = this.editItem.PhoneName;
      this.dataJson.SupplyRangeSupport = this.editItem.SupplyRangeSupport;
      this.dataJson.RemarksScript = this.editItem.RemarksScript;
    }
  },

  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (!this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入名称');
        flag = true;
      } else if (this.dataJson.PhoneName && this.dataJson.PhoneName.length != 11) {
        layer.alert('请输入11位的手机号码');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (JSON.stringify(this.editItem) != '{}') {
        EditSupplier(this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          layer.alert('修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddSupplier(this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
</style>