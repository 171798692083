<template>
  <div class="">
    <!-- 选择学生 -->
    <div class="select_xu_checkbox_group">
      <select-student-item v-for="(item,index) in studentListInfo"
                           :key="item.StudentNameKey"
                           :dataInfo="item"
                            @showStarList="showStarList"></select-student-item>
      
    </div>
    <div class="confirm_btn btn_fixed_bottom">
        <div class="btn_fixed_blue btn_hover_bg_blue"
             @click.stop="completeStudent">完成并返回</div>
      </div>
      <custom-dialog :title="'课评评分'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowStarList"
                   :before-close="closeStarList">
         <release-student-info 
        :stuInfo="seletedStudentInfo" 
      ></release-student-info>
       <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'static'"
                           @confirmClick="completereleaseStudent"
                           @cancelClick="closeStarList"></save-cancel-btn-group>
    </custom-dialog>
  </div>

</template>

<script>
import { GetScheduleCourseStudentListForComments } from '../../../../../API/workbench';
import selectStudentItem from './select-student-item.vue';
import releaseStudentInfo  from '../release-components/release-student-info'
export default {
  data () {
    return {
      studentListInfo: [],
       isShowStarList:false,
        seletedStudentInfo:{

        }
    };
  },
  components: {
    selectStudentItem,
    releaseStudentInfo
  },
  props: {
    courseId: String,
    studentList: Array
  },
  computed: {
    vStudentList () {
      let arr = [];
      this.studentListInfo.forEach(e => {
        if (e.isActive == true) {
          arr.push(e);
        }
      });
      return arr;
    }
  },
  created () {
    this.getStudentListInfo();
  },
  methods: {
    showStarList(item){
        console.log("showStarList",item)
        // this.$emit('showStarList',item)
        this.seletedStudentInfo=this.$utils.parseJson(item)
        this.isShowStarList=true
      },
      closeStarList(){
         this.isShowStarList=false
      },
      completereleaseStudent(){
        let num=0
        this.seletedStudentInfo.EssayAssessTempSetList.forEach(o=>{
          num+=Number(o.TempSetStarGrade)
        })
        this.seletedStudentInfo.Currency=Math.ceil((num / this.seletedStudentInfo.EssayAssessTempSetList.length) || 0)
        let locaItemIndex=this.studentListInfo.findIndex(o=>{
          return o.StudentKey==this.seletedStudentInfo.StudentKey
        })
        if(locaItemIndex>-1){
          this.studentListInfo.splice(locaItemIndex,1,this.seletedStudentInfo)
        }
        this.closeStarList()
        console.log("this.completereleaseStudent",this.studentListInfo)
      },
    getStudentListInfo () {
      GetScheduleCourseStudentListForComments(this.courseId || '326590').then(res => {
        this.studentListInfo = [];
        res.data.forEach(e => {
          e.isActive = false;
          e.StudentKey = e.StudentNameKey;
          e.StudentKeyValue = e.StudentNameKeyValue;
          this.studentListInfo.push(e);
        });
        this.studentListInfo.forEach(obj => {
          if (this.studentList && this.studentList.length > 0) {
            this.studentList.forEach(e => {
              if (obj.StudentKey == e.StudentKey) {
                obj.isActive = e.isActive;
                obj.Currency = e.Currency;
              }
            });
          }
          // newArr.push(obj);
        });
      }, err => {
        console.log(err.msg);
      });
    },
    completeStudent () {
      console.log(this.vStudentList, '1111111');
      this.$emit('completeStudent', this.vStudentList);
    }
  }
};
</script>

<style>
</style>
