<template>
  <!-- 物品设置管理 -->
  <div class="course_class_manage">
    <div class="course_class_manage_title">
      <div class="manage_title_left">
        <span>物品类型</span>
        <span class="font_blue"
              style="cursor: pointer;"
              @click="onAddGoodsTypeClick">新增类型</span>
      </div>
      <div class="manage_title_right">
        <span>物品名称</span>
        <span class="font_blue"
              style="cursor: pointer;"
              @click="onAddGoodsNameClick">新增物品</span>
      </div>
    </div>
    <div class="course_class_manage_content"
         ref="manageContent"
         style="position: relative;"
         @scroll="scrollTOP"
         v-if="vbuildGoodsTypeNameList.length>0">
      <setting-content v-for="(item,index) in vbuildGoodsTypeNameList"
                       :key="index"
                       :goodsTypeBox="item"
                       :goodsTypeIndex="index"
                       :goodsList="vbuildGoodsTypeNameList"
                       @onTypeOperateClick="onTypeOperateClick"
                       @doGoodsNameItemClick="doOperate"
                       @doMouseEvent="doMouseEvent"></setting-content>
    </div>
    <!-- 物品类型操作菜单 -->
    <div v-if="isGoodsTypeMenu">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:120px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList"
                       @clickOptionForAlertDialog="doGoodsTypeMenuClick"></drop-down-box>
      </div>
    </div>
    <!-- 物品名称操作菜单 -->
    <div v-if="isGoodsMenu"
         @mouseenter.stop="mouseOverNewCourseTable"
         @mouseleave.stop="mouseOutNewCourseTable">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:' +newOffsetLeft+'px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList"
                       @clickOptionForAlertDialog="doGoodsNameItemClick"></drop-down-box>
      </div>
    </div>
    <!-- 新增物品类型 -->
    <custom-dialog :title="goodsDialogTitle"
                   class="student_info_dialog studentInfo_dialog defult_dialog"
                   :visible.sync="isGoodsTypeShow"
                   :before-close="closeGoodsTypeDialog">
      <goods-type-setting-form :dataInfo="selectGoodsInfo"
                               :isReadonly="isReadonly"
                               @afterSuccess="getGoodsTypeList"
                               @closeDialog="closeGoodsTypeDialog"></goods-type-setting-form>
    </custom-dialog>

    <!-- 新增物品名称 -->
    <custom-dialog :title="goodsDialogTitle"
                   class="student_info_dialog studentInfo_dialog defult_dialog"
                   :visible.sync="isGoodsNameShow"
                   :before-close="closeGoodsNameDialog">
      <goods-name-setting-form :dataInfo="selectGoodsInfo"
                               :isReadonly="isReadonly"
                               @afterSuccess="getGoodsNameList"
                               @closeDialog="closeGoodsNameDialog"></goods-name-setting-form>
    </custom-dialog>
  </div>
</template> 

<script>

import {
  GetGoodsList, // 获取物品类型
  GetGoodsTypeList, // 获取物品类型
  StopGoods, // 停用物品
  StopGoodsType // 停用物品类型
} from '../../../../../API/workbench';
import settingContent from './setting-content';
import goodsTypeSettingForm from './goods-type-setting-form';
import goodsNameSettingForm from './goods-name-setting-form';
import dropDownBox from '../../../../common/drop-down-box';

export default {
  data () {
    return {
      goodsNameList: [], // 物品名称列表
      goodsTypeList: [], // 物品类型列表
      isGoodsTypeShow: false,
      isGoodsNameShow: false,
      goodsDialogTitle: '', // 物品弹窗名
      // 操作对象
      options: {
        type: 'add',
        moduleName: '',
        data: {}
      },
      // 操作类型
      menuItemList: [
        { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: '' },
        { type: 3, name: '停用', eventName: 'unActive', isShow: true, ModulePowerKey: '' }
      ],
      newOffsetHeight: 0, // 元素纵向位置
      newOffsetLeft: 0, // 元素横向位置
      scrollETarget: { scrollTop: 0 }, // 滚动元素
      selectGoodsInfo: {}, // 选择操作的物品信息
      isReadonly: false, // 是否只读模式
      isGoodsTypeMenu: false,
      isGoodsMenu: false, // 物品名称操作菜单
      goodsInfo: {} // 物品信息
    };
  },
  props: [

  ],
  components: {
    settingContent,
    goodsTypeSettingForm,
    goodsNameSettingForm,
    dropDownBox
  },

  created () {
    this.getGoodsNameList();
    this.getGoodsTypeList();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isGoodsTypeMenu = false;
    }, false);
  },
  watch: {

  },
  computed: {
    vbuildGoodsTypeNameList () {
      let buildList = [];
      let notGoodsList = {// 未分类的物品项
        OLAPKey: 0,
        MainDemoName: '未分类的物品',
        buildGoodsList: []
      };
      // 未分类的物品名称 构建
      this.goodsNameList.forEach(p => {
        this.$set(p, 'isMenu', false);
        if (p.GoodsTypeKey == 2 || p.GoodsTypeKey == 0) {
          notGoodsList.buildGoodsList.push(p);
        }
      });
      // 物类包括物类下的物品 构建
      this.goodsTypeList.forEach(o => {
        buildList.push(o);
        this.$set(o, 'isMenu', false);
        this.$set(o, 'buildGoodsList', []);
        this.goodsNameList.forEach(i => {
          if (o.OLAPKey == i.GoodsTypeKey) {
            o.buildGoodsList.push(i);
          }
        });
      });
      buildList.unshift(notGoodsList);
      console.log(buildList, '课类列表');
      return buildList;
    }
  },
  methods: {
    // 点击物类-弹出框
    onTypeOperateClick (item, index, locationHeight) {
      let dialogHeight = 78;// 弹出层头部的高度 98
      this.newOffsetHeight = this.getElementHeight(index) + dialogHeight - locationHeight - this.scrollETarget.scrollTop;
      this.selectGoodsInfo = item;
      this.isGoodsMenu = false;
      this.isGoodsTypeMenu = true;
    },
    // 物品移出移入-弹出框
    doMouseEvent (type, index, locationHeight, goodsIndex, goodsItem) {
      this.goodsInfo = goodsItem;
      let dialogHeight = 118; // 弹窗头部到第一个物品的高度
      let goodsHeight = parseInt(goodsIndex / 5) * 38;// 计算物品纵向所在的高度 每列高为38px
      this.newOffsetHeight = this.getElementHeight(index) + dialogHeight - locationHeight - this.scrollETarget.scrollTop + goodsHeight; // 纵向位置
      this.newOffsetLeft = (goodsIndex % 5) * 144 + 228;// 计算物品横向所在的偏移量 第一个默认是228，每个物品相距为144px 横向位置
      this.isGoodsTypeMenu = false;
      if (type == 1) { // 移入
        this.mouseOverNewCourseTable();
      } else { // 离开
        // this.mouseOutNewCourseTable();
      }
    },
    // 移入时物品继续高亮
    mouseOverNewCourseTable () {
      this.isGoodsMenu = true;
    },
    // 移出时物品继续高亮
    mouseOutNewCourseTable () {
      this.isGoodsMenu = false;
    },
    //  物类=>操作
    doGoodsTypeMenuClick (options) {
      if (this.selectGoodsInfo.buildGoodsList.length > 0 && options.eventName == 'unActive') {
        layer.alert('该物品类型下有未停用的物品时不能停用!');
      } else {
        this.selectGoodsInfo.isMenu = false;
        let obj = {
          type: options.eventName,
          moduleName: 'goodsTypeSetting',
          data: this.selectGoodsInfo
        };
        this.doOperate(obj);
      }
    },
    // 物品名称=>操作
    doGoodsNameItemClick (options) {
      let obj = {
        type: options.eventName,
        moduleName: 'goodsNameSetting',
        data: this.goodsInfo
      };
      this.doOperate(obj);
    },
    //  获取物类所在的位置
    getElementHeight (index) {
      let elementHeight = 0; // 元素的高度
      this.vbuildGoodsTypeNameList.forEach((o, i) => {
        if (i == 0) { // 第一条不包括新增操作的高度计算
          if (o.buildGoodsList.length > 10) {
            elementHeight = Math.ceil((o.buildGoodsList.length - 10) / 5) * 36 + 88;
          } else {
            elementHeight = 88;
          }
        }
        if (index >= i && i != 0) { // 其余的+新增操作项的高度计算
          if (o.buildGoodsList.length + 1 > 10) {
            elementHeight += Math.ceil((o.buildGoodsList.length + 1 - 10) / 5) * 36 + 86;
          } else {
            elementHeight += 86;
          }
        }
      });
      return elementHeight;
    },
    // 获取物品名称列表
    getGoodsNameList (type) {
      GetGoodsList().then(result => {
        this.goodsNameList = result.data;
        console.log(this.goodsNameList, '获取物品名称列表');
        if (type) { // 新增修改成功后的回调
          this.$emit('afterSuccessCallBack');
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取物品类型列表
    getGoodsTypeList (type) {
      GetGoodsTypeList().then(result => {
        this.goodsTypeList = result.data;
        if (type) { // 新增修改成功后的回调
          this.$emit('afterSuccessCallBack');
        }
        console.log(this.goodsTypeList, '获取物品类型列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 新增课类项
    onAddGoodsTypeClick () {
      this.options.type = 'add';
      this.options.data = {};
      this.options.moduleName = 'goodsTypeSetting';
      this.doOperate(this.options);
    },
    // 新增课程项
    onAddGoodsNameClick () {
      this.options.type = 'add';
      this.options.data = { GoodsTypeKey: 0 };
      this.options.moduleName = 'goodsNameSetting';
      this.doOperate(this.options);
    },
    doOperate (options) {
      this.isReadonly = false;
      switch (options.type) {
        case 'add':
          console.log(options, 'add');
          this.addGoodsNameSetting(options);// 添加物品类型
          this.showDialog(options.moduleName, '新增');
          break;
        case 'edit':
          this.showDialog(options.moduleName, '修改');
          console.log(options, 'edit');
          this.selectGoodsInfo = options.data;
          break;
        case 'view':
          this.isReadonly = true;
          this.showDialog(options.moduleName, '查看');
          this.selectGoodsInfo = options.data;
          console.log(options, 'view');
          break;
        case 'unActive':
          console.log(options, 'unActive');
          if (options.moduleName == 'goodsTypeSetting') { // 停用物类
            StopGoodsType(options.data.OLAPKey).then(result => {
              layer.alert('停用成功');
              this.getGoodsTypeList(options.data);
            }, error => {
              layer.alert(error.msg);
            });
          } else {
            StopGoods(options.data.OLAPKey).then(result => { // 停用物品
              layer.alert('停用成功');
              this.getGoodsNameList(options.data);
            }, error => {
              layer.alert(error.msg);
            });
          }

          break;
        default:// 超出标准操作，自行处理

          break;
      }
      this.isGoodsTypeMenu = false;
    },
    // 新增物品=》 有父级物类时，添加物品类型
    addGoodsNameSetting (options) {
      this.selectGoodsInfo = {};
      if (options.moduleName == 'goodsNameSetting' && Number(options.data.GoodsTypeKey) > 0) {
        this.selectGoodsInfo.GoodsTypeKey = options.data.GoodsTypeKey;
        this.selectGoodsInfo.GoodsTypeKeyValue = options.data.GoodsTypeKeyValue;
      }
    },
    // 弹窗显示
    showDialog (type, name) {
      if (type == 'goodsTypeSetting') {
        this.isGoodsTypeShow = true;
        this.goodsDialogTitle = name + '物品类型';
      } else if (type == 'goodsNameSetting') {
        this.goodsDialogTitle = name + '物品';
        this.isGoodsNameShow = true;
      }
    },
    // 关闭物品类型弹窗
    closeGoodsTypeDialog () {
      this.isGoodsTypeShow = false;
    },
    // 关闭物品名称弹窗
    closeGoodsNameDialog () {
      this.isGoodsNameShow = false;
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.scrollETarget = e.target;
      if (this.scrollETarget.scrollTop) {
        this.vbuildGoodsTypeNameList.forEach(o => {
          o.isMenu = false;
        });
      }
      this.isGoodsTypeMenu = false;
      this.isGoodsMenu = false;
    }
  }
};
</script>
