<template>
  <div>
    <div class="content_marginRL content_marginTop_30 btn_marginBottom_30 single_shadow overflow_hidden">
      <div>
        <div v-if="scheduleCourseList.length>0"
             class="course_leave_ul"
             @scroll="scrollTOP"
             style="max-height:500px;overflow-y:auto;padding:0">
          <un-arrange-course-item v-for="(courseItem,i) in scheduleCourseList"
                                  :key="i"
                                  :courseInfo="courseItem"
                                  @onClickItem="doAfterClickCourse"></un-arrange-course-item>
        </div>
      </div>
      <div v-if="scheduleCourseList.length==0 && !isLodding"
           class="course_block_nodata ">

        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <!-- 按钮 -->
    <div v-if="scheduleCourseList.length>0">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import {
  MessageSearchPageForAvailableScheduleCourse,
  kqDelete,
  ArrangeStudent2SingleCourse
} from '../../../API/workbench';
import unArrangeCourseItem from './un-arrange-course-item';

export default {
  data () {
    return {
      scheduleCourseList: [],
      selectedCourseItem: {},
      isClassHourLackKey: 0,
      isLodding: false,
      params: {
        pageIndex: 0, //	数据源：GET	页码，0为第一页
        pageSize: 20, //	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名
        orderBy: 'ClassTime', //		数据源：GET	排序字段
        sequence: 'ASC', //		数据源：GET	排序字段
        startTime: this.$utils.formatDateToLine(Date.new()), //		数据源：GET	上课日期开始 eg:2020-01-01
        endTime: '', //		数据源：GET	上课日期结束 eg:2020-01-01
        studentkey: 0, //	数据源：GET	学生id
        courseid: 0
      },
      noMore: false
    };
  },
  props: {
    studentID: [Number, String],
    changeCourseInfo: Object
  },
  components: {
    unArrangeCourseItem
  },
  created () {
    this.params.studentkey = this.studentID;
    this.params.courseid = this.changeCourseInfo.CourseNameKey;
    this.searchStudentUnArrangeScheduleCourse();
  },
  methods: {
    searchStudentUnArrangeScheduleCourse () {
      this.isLodding = true;
      MessageSearchPageForAvailableScheduleCourse(this.params)
        .then(result => {
          if (result.code == 0) {
            this.scheduleCourseList = result.data.PageDataList || [];
            this.isLodding = false;
            if (this.scheduleCourseList.length >= result.data.Total) {
              this.noMore = true;
            }
            this.params.pageSize += 20;
          }
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.noMore) {
        console.log('scrollTOP', this.noMore);
        this.searchStudentUnArrangeScheduleCourse();
      }
    },
    // 选中一节课
    doAfterClickCourse (item) {
      this.scheduleCourseList.forEach(obj => {
        if (obj.OLAPKey != item.OLAPKey) {
          this.$set(obj, 'isActive', false);
        }
      });
      this.selectedCourseItem = item;
    },
    // 确定 先取消 在排入
    confirmClick (callBack) {
      if (this.studentID > 0 && this.changeCourseInfo.CourseId > 0) {
        kqDelete(this,this.studentID, this.changeCourseInfo.CourseId)
        .then(result => {
          this.isClassHourLackKey = 0;
          this.arrangeStudent2SingleCourse();
          if (typeof callBack == 'function') {
            callBack();
          }
        }, error => {
          layer.alert(error.msg);
          if (typeof callBack == 'function') {
            callBack();
          }
        });
      }
    },
    // 排入
    arrangeStudent2SingleCourse (callBack) {
      if (this.selectedCourseItem.OLAPKey) {
        ArrangeStudent2SingleCourse(
        this.studentID,
        this.isClassHourLackKey,
        this.selectedCourseItem.OLAPKey
      ).then(result => {
        if (result.code == 0) {
          layer.alert('调课成功');
          this.$emit(
              'confirmClick',
              this.changeCourseInfo,
              this.selectedCourseItem
            );
        }
      }).catch(error => {
        if (error.code == 60001) {
          let _this = this;
          layer.confirm('该学生没有可用课单，是否无课时安排?', {
            btn: [
              {
                name: '确认',
                callBack: function () {
                  _this.isClassHourLackKey = 1;
                  _this.arrangeStudent2SingleCourse();
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      }).finally(() => {
        if (typeof callBack == 'function') {
          callBack();
        }
      });
      } else {
        layer.alert('请选择一节课。');
      }
    },
    cancelClick () {
      this.$emit('closeDialog');
    }
  }
};
</script>
<style>
</style>