<template>
  <div class="WKT_course_info_popup"
       style="min-height:150px">
    <div class="from_input_list">
      <div class="from_input_title ">
        授权码
      </div>
      <div class="from_input_box">
        <input type="Number"
               v-model="authCode"
               placeholder="请输入">
      </div>
    </div>
    <save-cancel-btn-group :cancelBtnText="'撤销'"
                           :isSingleSubmit="true"
                           :btnText="'提交审核'"
                           @confirmClick="submitAudit"
                           @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
  </div>
</template>
<script>
import { AuthorizationCodeAuditApply } from '../../../../../API/workbench';
export default {
  data () {
    return {
      authCode: ''
    };
  },
  props: {
    dataInfo: Object
  },
  created () {
  },
  methods: {
    // 提交审核
    submitAudit (callback) {
      console.log('submit', this.dataInfo);
      let isPass = true;
      if (this.authCode == '') {
        layer.alert('请输入授权码');
        isPass = false;
      }
      if (!isPass) {
        if (callback) {
          callback();
        }
        return false;
      }
      AuthorizationCodeAuditApply(this.dataInfo.TableID, this.authCode).then(result => {
        layer.alert('审批成功');
        this.doAfterClickCancelBtn();
        this.$emit('afterSuccess');
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        console.log('AuthorizationCodeAuditApply', error);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
