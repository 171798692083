<template>
  <!-- type_warning 签到异常 -->

    <div class="sign_face_list clearfix"
         v-if="recodInfo.ResultTypeKey==0">
      <div class="fl">
        <div class="sign_face_list_name">
          {{recodInfo.StudentKeyValue}}
        </div>
        <div class="sign_face_list_course">
          {{recodInfo.CourseNameKeyValue}}
        </div>
      </div>
      <div class=" fr">
        <div class="sign_face_list_time">
          {{vsignFaceTime}}
        </div>
        <div class="sign_face_list_type">
          签到成功
        </div>
      </div>
    </div>
    <div class="sign_face_list type_warning clearfix"
         v-else>
      <div class="fl">
        <div class="sign_face_list_name">
          {{recodInfo.StudentKeyValue}}
        </div>
        <div class="sign_face_list_course font_red" >
          异常签到
        </div>
      </div>
      <div class=" fr">
        <div class="sign_face_list_time">
          {{vsignFaceTime}}
        </div>
        <div class="sign_face_list_type font_red">
          {{recodInfo.ResultTypeKeyValue}}
        </div>
      </div>
    </div>
  
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    recodInfo: Object
  },
  computed: {
    vsignFaceTime () {
      if (this.recodInfo && this.recodInfo.ScanTime) {
        return this.recodInfo.ScanTime.substring(11, 16);
      }
      return '';
    }
  }

};
</script>

<style>
</style>