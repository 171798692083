<template>
  <div>
    <div class="course_dispense_tips_title">
      分发步骤
    </div>
    <div class="course_dispense_tips_content pr">
      <div class="tips_list">
        <div class="tips_list_name">第一步</div>
        <div class="tips_list_text">选择班级循环课</div>
      </div>
      <div class="tips_list">
        <div class="tips_list_name">第二步</div>
        <div class="tips_list_text">选择课件分发</div>
      </div>
      <div class="tips_list">
        <div class="tips_list_name">第三步</div>
        <div class="tips_list_text">绑定课件课程</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {

  },
  methods: {

  }
};
</script>

<style>
</style>