<template>
  <div class="heads_img pr"
       style="width:100%;height:100%">
    <div id="screanContent"
         style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
    <!-- <img :style="{ width:imgWidth+'px', height:imgHeight+'px', 'position': 'absolute', 'opacity': 0,'left': '-100%','pointer-events':'none'}"> -->
    <img :src="vDefaultImg"
         :onerror="vOnerrorImg"
         style="pointer-events: none"
         :class="vClassName" />
    <div v-if="showUp"
         class="uploading_photo"></div>

    <input v-if="showUp || showUpInput"
           @change="selectImg($event)"
           class="uploading_input"
           ref="uploading"
           type="file"
           title=""
           placeholder="图标"
           accept=".jpg,.jpeg,.png"
           data-type="userIconImg" />
    <!-- 需要裁切 -->
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="closeChgPic">
      <vue-crop-img :selectPicUrl="selectPicUrl"
                    :cutImgWidth="imgWidth"
                    :cutImgHeight="imgHeight"
                    @onConfirmClick="setPicCorp"
                    @onCancelClick="clearPicData"></vue-crop-img>

    </custom-dialog>

  </div>
</template>
<script>
import { UpdateHeadImgSupport } from '../../API/workbench';
export default {
  name: 'headsImg',
  data () {
    return {
      defaultClass: '',
      cdnUrl: '',
      token: null,

      selectPicUrl: '',          // 选中图片url
      crood2: {},           // 截图坐标之类
      isChgPic: false,
      preWHRatio: 1,
      originalImgWidth: 0,
      originalImgHeight: 0,
      selectedCurrentTarget: null,
      picType: '',
    };
  },
  props: {
    imgUrl: String,
    dataInfo: Object,
    className: String,
    showUp: Boolean,
    isCustomer: Boolean, // 是否显示客户头像
    imgHeight: {// 裁切的图片高度，默认210，
      type: Number,
      default: 101
    },
    imgWidth: {// 裁切的图片宽度，默认320，
      type: Number,
      default: 101
    },
    showUpInput: Boolean
  },
  computed: {
    vClassName () {
      return this.className || this.defaultClass;
    },
    vDefaultImg () {
      let imgUrl = this.imgUrl || '';
      if (imgUrl && imgUrl.indexOf('deflut_studentPhoto') == -1 && imgUrl.indexOf('data:image/png;base64') == -1) {
        if (imgUrl.indexOf('http') == -1) {
          return this.cdnUrl + imgUrl;
        } else {
          return imgUrl;
        }
      } else {
        if (this.isCustomer) {
          return (require('../../../public/image/deflut_customer_photo@2x.png'));
        } else if (this.dataInfo.SexKeyValue == '男' || Number(this.dataInfo.SexKey) == 2) {
          return (require('../../../public/image/deflut_studentPhoto_boy.png'));
        } else {
          return (require('../../../public/image/deflut_studentPhoto_gril.png'));
        }
      }
    },
    vOnerrorImg () {
      if (this.dataInfo.SexKeyValue == '男' || Number(this.dataInfo.SexKey) == 2) {
        return 'this.src="' + require('../../../public/image/deflut_studentPhoto_boy.png') + '"';
      } else {
        return 'this.src="' + require('../../../public/image/deflut_studentPhoto_gril.png') + '"';
      }
    }
  },
  created () {
    this.cdnUrl = this.$store.getters.CDNURL;
    this.token = this.$store.getters.token;

  },
  methods: {
    closeChgPic () {
      this.isChgPic = false;
    },
    selectImg (e) {
      let fileName = e.target.files[0].name
      let typeText = '.jpg,.jpeg,.png'
      if (typeText.indexOf(fileName.substring(fileName.toLowerCase().lastIndexOf('.'), fileName.length)) == -1) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false
      } else {
        if (e.target.files.length > 0) {
          // 上传头像
          var reader = new FileReader();	// 实例化一个FileReader对象，用于读取文件
          // 读取File对象的数据
          var that = this;
          reader.onload = function (evt) {
            that.selectPicUrl = evt.target.result;

            that.$nextTick(() => {
              that.isChgPic = true;
            });

          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      this.dataInfo.HeadImgSupport = imgUrl;
      this.updateHeadImgSupport();
      this.clearPicData();
      this.$emit('doSetPicCorp', imgUrl);
    },
    clearPicData () {
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.preWHRatio = 1;
      this.$refs.uploading.value = '';
    },
    updateHeadImgSupport () {
      UpdateHeadImgSupport(this.dataInfo.OLAPKey, this.dataInfo.HeadImgSupport).then(
        res => {
          console.log(res);
        },
        err => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>
