<template>
  <div class=""
       style="margin-top:10px">
    <div class="payment_statistics_bottom clearfix">
      <div class="course_statistics_table_search flex">
        <div class="table_select_box"
             style="margin-right:10px">
          <div class="table_select_title">类型</div>
          <el-select v-model="searchObj.DataTypeKey"
                     style="width:175px;margin-right:20px"
                     placeholder="请选择"
                     @change="typeEvent">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>

          <div style="margin-right:10px">相关</div>
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>

        </div>

      </div>
    </div>

    <div class="course_statistics_table_box table_list_content">
      <table-list ref="multipleTable"
                  class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :footerContent="vFooterContent"
                  :showPagePagination="true"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from "../../common/table-list/index";
// import seletedOption from './seleted-option';
import inputSearchContainBtn from "../common/input-search-contain-btn";
import { GetRemindWorkList, CancelRemindWork } from "../../../API/workbench";
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0, // 门店id
        pageSize: 0,
        searchText: "", // 搜索条件：模糊匹配单号、学生姓名、手机号
        orderBy: "",
        DataTypeKey: 0,
        sequence: "DESC"
      },
      options: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "数据导出"
        }
      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: "0",
      PageCount: 0,
      defaultSort: { prop: "", order: "ascending" },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: "序号",
          prop: "Number",
          width: 55,
          sortable: false,
          align: "left",
          type: "number-item",
          className: "table_index_gray"
        },
        {
          label: "时间",
          prop: "GenerateTime",
          width: 140,
          sortable: false,
          align: "left",
          isShow: true,
          type: "text-item"
        },
        {
          label: "类型",
          prop: "DataTypeKeyValue",
          width: 120,
          sortable: false,
          align: "left",
          isShow: true,
          className: "",
          type: "text-item"
        },
        {
          label: "相关",
          prop: "MainDemoName",
          minWidth: 180,
          sortable: false,
          align: "left",
          isShow: true,
          className: "font_dual",
          type: "text-item"
        },
        {
          label: "状态",
          prop: "StatusKeyValueText",
          width: 120,
          sortable: false,
          align: "left",
          isShow: true,
          className: "font_dual",
          type: "text-item"
        },

        {
          label: "操作",
          prop: "operation",
          width: "auto",
          align: "center",
          type: "table-btn",
          extend: {
            tableBtnGroup: [
              {
                name: rowData => {
                  // 1-进行中 2-已完成 3-取消 4-失效
                  if (rowData.DataTypeKey == 1) {
                    if (rowData.StatusKey == 1) {
                      return "取消";
                    } else if (rowData.StatusKey == 2) {
                      return "下载";
                    }
                  } else {
                    return "查看";
                  }
                },
                extend: {
                  click: rowData => {
                    this.doAfterTableBtnClick(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      dataReportList: []
    };
  },
  components: {
    tableList,
    // seletedOption,
    inputSearchContainBtn
  },
  props: {},
  created () { },
  mounted () {
    this.getDataList(this.searchObj);
  },
  computed: {
    vFooterContent () {
      return "共" + this.totalNum + "条数据";
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.GenerateTime = o.GenerateTime || "";
        o.GenerateTime = o.GenerateTime.substring(0, 16);
        o.StatusKeyValueText = `<span>` + o.StatusKeyValue +
          (o.isreadKey == 0 ? `<span class="red_type">新</span>` : '') +
          `</span>`
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return "共" + this.totalNum + "条数据";
    }
  },
  methods: {
    typeEvent (value) {
      this.searchObj.DataTypeKey = value;
      this.searchObj.pageIndex = 0;
      this.getDataList(this.searchObj);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = "";
      this.searchObj.DataTypeKey = 0
      this.getDataList(this.searchObj);
    },

    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList(this.searchObj);
    },

    getDataList (queryParams) {
      GetRemindWorkList(queryParams)
        .then(result => {
          this.totalNum = result.data.Total;
          this.dataReportList = result.data.PageDataList;
          console.log(result.data, "GetRemindWorkList");
        })
        .catch(error => {
          console.log("errorGetRemindWorkList", error.msg);
        });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, "doAfterTableBtnClick");
      if (rowData.DataTypeKey == 1) {
        if (rowData.StatusKey == 1) {
          this.cancelItem(rowData)
        } else if (rowData.StatusKey == 2) {
          console.log("下载", this.$store.getters.APIURL)
          window.location.href = this.$store.getters.APIURL + rowData.result;
        }
      } else {
        this.viewItem(rowData)
      }

    },
    cancelItem (item) {
      layer.confirm('是否取消当前选中项', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CancelRemindWork(item.OLAPKey).then(result => {
                this.getDataList(this.searchObj)
                console.log(result.data, "GetRemindWorkList");
              })
                .catch(error => {
                  console.log("errorGetRemindWorkList", error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    viewItem (item) {

    }
  }
};
</script>
<style scoped>
.class_btn_delay {
  height: 36px;
  width: 100px !important;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  background: #3498db url(../../../../public/image/batch_distribution_icon.png)
    no-repeat left 10px center;
  background-size: 16px;
  padding-left: 20px;
}
.class_btn_delays {
  height: 36px;
  width: 90px;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
}
.remove_db_btn {
  background-image: url(../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
.tsjj {
  margin-left: 20px;
}
.course_statistics_table_search .mendian_backstage_date {
  margin-right: 0px;
  min-width: 250px;
}
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_table_box >>> .red_type {
  display: inline-block;
  color: red;
  border: 1px solid #ccc;
  padding: 2px;
  margin-left: 5px;
}
/* 
.payment_statistics_bottom .course_statistics_search.width_small .search_box{
  width: 164px!important;
  min-width: 164px!important;
} */
</style>
