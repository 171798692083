<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <!--  :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isShowHistory" -->
      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名、课单号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class=" table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="tableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetRefundCourseList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),

        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '报名日期',
          prop: 'GenerateTime',
          width: ' ',
          minWidth: 112,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'ParPhoneName',
          minWidth: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '退课单号',
          prop: 'NumberName',
          width: ' ',
          minWidth: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            setClassName: (rowData) => {
              return this.$store.getters.SaaSClientEditionKey != 5 ? 'font_blue' : 'font_black';
            },
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              if (this.$store.getters.SaaSClientEditionKey == 5) {
                return false
              }
              // this.changeType(rowData,3);
              this.doAfterTableBtnClick(rowData)
              console.log('触发订单号');
              // }
            }

          }
        },
        {
          label: '支出合计',
          prop: 'ActualBackAmount',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '退课课时',
          prop: 'ClassHourAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '退课天数',
          prop: 'DayAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '杂费支出',
          prop: 'ActualPayAmount',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },


      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchTime: Object,
    searchText: String,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0
    this.searchObj.searchText = this.searchText
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            console.log("searchTime", this.searchObj.searchText)
            this.searchObj.startTime = c.startTime
            this.searchObj.endTime = c.endTime
            this.getDataList();
          }, 100)
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          console.log('schoolItem.OLAPKey', this.schoolItem.OLAPKey)
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.ActualBackAmount = Number(o.ActualBackAmount) == 0 ? '-' : '￥' + Number(Number(o.ActualBackAmount).toFixed(2))
        o.ActualPayAmount = Number(o.ActualPayAmount) == 0 ? '-' : '￥' + Number(Number(o.ActualPayAmount).toFixed(2))
        o.ClassHourAmountValue = Number(o.ClassHourNum) == 0 ? '-' : `<span class="">` + Number(Number(o.ClassHourNum).toFixed(2)) + `课时/￥` + Number(Number(o.ClassHourAmount).toFixed(2)) + `</span>`
        o.DayAmountValue = Number(o.DayNum) == 0 ? '-' : `<span class="">` + Number(Number(o.DayNum).toFixed(2)) + `课时/￥` + Number(Number(o.DayAmount).toFixed(2)) + `</span>`
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      let obj = {
        courseDetailItem: { TableID: rowData.TableID },
        studentInfo: { OLAPKey: rowData.StudentKey },
        readonly: true
      };
      this.$dialog.open(this, {
        name: '课时退款',
        routerName: this.$route.name,
        moduleName: 'refundmoneybackcourse',
        moduleDescription: '单个学生退款操作，可自行修改退款金额。记得要点击课单进行选中哦！',
        data: { dataInfo: obj }
      });
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    changeType (item, type) {
      console.log("changeType", item, type)
      this.$emit("changeType", item, type)
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetRefundCourseList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '退课收入明细',
          clist: [
            { title: '报名日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'ParPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '订单号', cName: 'NumberName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '支出合计', cName: 'ActualBackAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ActualBackAmount::2::0}}"
            },
            {
              title: '退课课时', cName: 'ClassHourNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourNum::2::0}}"
            },
            {
              title: '退课课时总额', cName: 'ClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourAmount::2::0}}"
            },
            {
              title: '退课天数', cName: 'DayNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DayNum::2::0}}"
            },
            {
              title: '退课天数总额', cName: 'DayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DayAmount::2::0}}"
            },
            {
              title: '杂费支出', cName: 'ActualPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ActualPayAmount::2::0}}"
            },
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetRefundCourseList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },


  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>