<template>
  <div>
    <custom-dialog :title="importMoudle.moudleName"
                   :visible.sync="isOpenImportMould"
                   :before-close="closeImportMouldDialog">
      <!-- 文字说明模板 -->
      <explain-module :required="true"
                      :explainText="'导入模板中，红色标题对应的内容必须提供。内容格式请参考示例数据，导入前，请删除示例数据行。'"></explain-module>
      <div class="form_info_detail">
        <div class="import_content content_marginRL">
          <div class="import_content_top content_marginTop_30 single_shadow">
            <div class="import_top_left">
              <span>导入模板</span>
            </div>
            <div class="import_top_right">
              <div class="import_file_name">
                <span class="import_file_download">{{importMoudle.moudleName}}模板.xls</span>
                <a class="import_file_download_btn"
                   :href="downloadFilePath"
                   target="_blank">点击下载</a>
              </div>

              <div class="tip_mark">
                <span class="">*</span>导入模板中，红色标题对应的内容必须提供。内容格式请参考示例数据，导入前，请删除示例数据行。
              </div>
            </div>
          </div>
          <div class="import_main content_marginTop_8 single_shadow">
            <div class="import_main_left">
              <span class="import_main_title">上传文件</span>
            </div>
            <div class="import_main_right">
              <el-upload class="upload-demo "
                         accept=".xls,.xlsx"
                         action="string"
                         :http-request="httpRequest"
                         :on-remove="handleRemove"
                         :before-upload="beforeUpload">
                <el-button slot="trigger"
                           size="small"
                           type="primary"
                           class="btn_light_blue btn_hover_bg_blue">选择...</el-button>
                <img v-if="uploadFileInfo.url"
                     :src="uploadFileInfo.url"
                     class="avatar">
                <div slot="tip"
                     class="el-upload__tip">只允许上传xls/xlsx文件，且不超过500kb</div>
              </el-upload>
            </div>
          </div>
          <div class="import_result content_marginTop_8 single_shadow btn_marginBottom_30 ">
            <div class="import_result_title">
              <span>导入结果</span>
            </div>
            <div class="import_result_content">
              <div v-html="importResult"></div>
            </div>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>

import { AddCustomerByExcel, AddTicketByExcel } from '../../API/workbench.js';

export default {
  'name': 'importMould',
  data () {
    return {
      uploadFileInfo: {},
      apiName: '',
      importResult: ''
    };
  },
  props: ['importMoudle', 'isOpenImportMould'],
  created () {
  },
  methods: {
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    closeImportMouldDialog () {
      this.importResult = '';
      console.log(this.importResult);
      this.$parent.isOpenImportMould = false;
    },
    beforeUpload (file) {
      this.uploadFileInfo = file;
    },
    downloadFile () {
      var elemIF = document.createElement('iframe');
      if (this.importMoudle.type == 1) {
        elemIF.src = '/KPIXSL/Template/客户学生导入模版.xls';
      } else if (this.importMoudle.type == 2) {
        elemIF.src = '/KPIXSL/Template/课单导入模版.xls';
      }
      elemIF.style.display = 'none';
      document.body.appendChild(elemIF);
    },
    httpRequest (param) {
      const formData = new FormData();
      formData.append('EXCEL', param.file);
      if (this.importMoudle.type == 1) {
        AddCustomerByExcel(formData).then(result => {
          if (result.code == 0) {
            this.importResult = '导入成功，共导入' + result.data.total + '条';
            layer.alert('导入成功');
          } else {
            let returnMsg = '';
            for (let i = 0; i < result.msg.length; i++) {
              let item = result.msg[i];
              returnMsg = returnMsg == '' ? item : returnMsg + item;
            }
            this.importResult = returnMsg;
            layer.alert('导入失败，详细问题请查看导入结果');
          }
        }).catch(error => {
          let returnMsg = '';
          for (let i = 0; i < error.msg.length; i++) {
            let item = error.msg[i];
            returnMsg = returnMsg == '' ? item : returnMsg + item;
          }
          this.importResult = returnMsg;
          layer.alert('导入失败，详细问题请查看导入结果');
          param.onError();
        });
      } else if (this.importMoudle.type == 2) {
        AddTicketByExcel(formData).then(result => {
          if (result.code == 0) {
            this.importResult = '导入成功，共导入' + result.data.total + '条';
            layer.alert('导入成功');
          } else {
            let returnMsg = '';
            for (let i = 0; i < result.msg.length; i++) {
              let item = result.msg[i];
              returnMsg = returnMsg == '' ? item : returnMsg + item;
            }
            this.importResult = returnMsg;
            layer.alert('导入失败，详细问题请查看导入结果');
          }
        }).catch(error => {
          let returnMsg = '';
          for (let i = 0; i < error.msg.length; i++) {
            let item = error.msg[i];
            returnMsg = returnMsg == '' ? item : returnMsg + item;
          }
          this.importResult = returnMsg;
          layer.alert('导入失败，详细问题请查看导入结果');
          param.onError();
        });
      }
    }

  },
  computed: {
    downloadFilePath () {
      let name = this.$store.getters.PCAPIURL + '/KPIXSL/Template/客户学生导入模版.xls';
      if (this.importMoudle.type == 1) {
        name = this.$store.getters.PCAPIURL + '/KPIXSL/Template/客户学生导入模版.xls';
      } else if (this.importMoudle.type == 2) {
        name = this.$store.getters.PCAPIURL + '/KPIXSL/Template/课单导入模版.xls';
      }
      return name;
    }
  }
};
</script>

