<template>
  <div class="credit_management_popup">
    <div class="credit_management_table">
      <div class="heard">
        <!-- :maxDate="vMaxDate" -->
        <div style="display: flex;align-items: center;">
          <div style="margin-left: 20px;">
            <date-range ref="saveDate"
                        :searchObj="searchObj"
                        :fromTitle="'日期范围'"
                        :minDate="vMinDate"
                        @changeDate="changeSaveDate"></date-range>
          </div>

          <!-- <input-search-contain-btn v-model="searchObj.searchText"
                                    style="margin-top:7px;margin-right: 80px;width:400px"
                                    :placeholder="'请输入学生名、订单号'"
                                    @clearSearch="clearSearchParams"
                                    @onSearchEvent="searchData"></input-search-contain-btn> -->

          <inputSearchContainBtn v-model="searchObj.searchText"
                                 style="margin-left:20px;width:420px"
                                 :placeholder="'请输入学生名、订单号'"
                                 @onSearchEvent="searchData"
                                 @clearSearch="clearSearchParams"></inputSearchContainBtn>
        </div>

        <div class="heardItem">
          <!-- <function-btn :item="{ name: '设置', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 76px;"
                        @clickItem="openSetting">
          </function-btn> -->
          <function-btn :item="{ name: '导出', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 76px;"
                        @clickItem="doExportInfoClick">
          </function-btn>
        </div>
      </div>
      <table-list class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :queryParams="searchObj"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog title="转化关联"
                   width="410px"
                   :visible.sync="isShowRelevantTable"
                   :before-close="closeRelevantTableDialog">
      <relevanForm :IsRenewalKey="IsRenewalKey"
                   :StudentKey="StudentKey"
                   :TableID="tableID"
                   :studentName="studentName"
                   @afterSuccess="relevantTableSuccess"
                   @cancelClick="closeRelevantTableDialog"></relevanForm>
    </custom-dialog>
    <custom-dialog title="设置"
                   width="400px"
                   :visible.sync="isOpenSetting"
                   :before-close="closeSupplementtarySetting">
      <supplementtarySetting @afterSuccess="supplementtarySettingSuccess"
                             @cancelClick="closeSupplementtarySetting"></supplementtarySetting>
    </custom-dialog>
    <custom-dialog title="修改课单类型"
                   width="400px"
                   :visible.sync="isOpenApplyTypeUpdate"
                   :before-close="closeApplyTypeUpdate">
      <applyTypeUpdate :dataInfo="applyData"
                       :TableID="tableID"
                       @afterSuccess="supplementtarySettingSuccess"
                       @cancelClick="closeApplyTypeUpdate"></applyTypeUpdate>
    </custom-dialog>
  </div>
</template>
<script>
import { getConversionCourseScheduleList, RelevantGrade, GetSalesList, RelevantSales } from '../../../API/workbench';
import tableList from '../../common/table-list/index.vue';
import relevanForm from '../../common/relevant-form.vue';
import supplementtarySetting from './supplementtary-setting.vue';
import applyTypeUpdate from './apply-type-update.vue';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn.vue';
export default {
  data () {
    return {
      StudentKey: '',
      IsRenewalKey: 0,
      studentName: '',
      applyData: {},
      isOpenApplyTypeUpdate: false,
      isOpenSetting: false,
      tableID: '',
      seletedItem: null, // 选中单个
      showAddForm: false,
      CounselorKey: '',
      CounselorKeyValue: '',
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()),
        sequence: 'DESC',
        ClassType: '4'
      },
      isShowRelevantTable: false,
      ids: '',
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '购课日期',
          prop: 'GenerateTime',
          width: 120,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '订单号',
          width: 120,
          prop: 'BCMNumberName',
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openbaoming(rowData);
            }
          }
        },
        {
          label: '课单号',
          prop: 'xykdNumberName',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '手机号',
          prop: 'ParPhoneName',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '适用课程',
          prop: 'ApplyCorNameValue',
          width: 180,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '购买数量',
          prop: 'NumCountText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '实收款',
          prop: 'ActualPayAmountText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '生效日期',
          prop: 'StartTimeText',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '失效日期',
          prop: 'EndTime',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课单类型',
          prop: 'ApplyTypekeyText',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              return Number(rowData.ApplyTypeKey) < 4 && Number(rowData.ActualPayAmount) != 0 ? 'font_blue' : 'font_black';
            },
            type: 'expand',
            click: (rowData) => {
              if (Number(rowData.ApplyTypeKey) < 4 && Number(rowData.ActualPayAmount) != 0) {
                this.openApplyTypeUpdate(rowData);
              }
            }
          }
        },
        {
          label: '关联转化单',
          prop: 'RelationScheduleValue',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              return Number(rowData.ApplyTypeKey) < 4 && Number(rowData.ActualPayAmount) != 0 ? 'font_blue' : 'font_black';
            },
            type: 'expand',
            click: (rowData) => {
              if (Number(rowData.ApplyTypeKey) < 4 && Number(rowData.ActualPayAmount) != 0) {
                this.openRelevantFormDialog(rowData);
              }
            }
          }
        }
        // ，{
        //   label: '关联转化单',
        //   prop: 'option',
        //   type: 'hidden-btn',
        //   minWidth: 200,
        //   align: 'center',
        //   extend: {
        //     tableBtnGroup: [
        //       {
        //         name: `RelevanceContent`,
        //         extend: {
        //           click: (rowData) => {
        //             console.log('rowData', rowData);
        //           }
        //         }
        //       }
        //     ]
        //   }
        // }
      ]
    };
  },
  props: {
  },
  components: {
    relevanForm,
    tableList,
    supplementtarySetting,
    applyTypeUpdate,
    inputSearchContainBtn
  },
  watch: {
    // 优惠金额
    'dataReportList': {
      handler (newValue, oldValue) {

      }
      // deep: true
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vCheckModulePower () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 51)) {
        return true;
      } else {
        return false;
      }
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.StudentCount = Number(o.StudentCount) || 0;
        o.ThisMonthFlow = this.$utils.flowFormate(o.ThisMonthFlow);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vAddFormTitle () {
      return !this.seletedItem ? '新增班级' : '修改班级';
    },
    vSeletedItemKey () {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    }
  },
  created () {
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this, ['cwsClassDetail']);
  },
  methods: {
    unitKeyValue (type) {
      let a = '';
      switch (type) {
        case '2':
          a = '年';
          break;
        case '3':
          a = '月';
          break;
        case '4':
          a = '日';
          break;
        case '5':
          a = '课时';
          break;

        default:
          break;
      }
      return a;
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    openSetting () {
      this.isOpenSetting = true;
    },
    closeSupplementtarySetting () {
      this.isOpenSetting = false;
    },

    openApplyTypeUpdate (data) {
      this.tableID = data.TableID;
      this.applyData = {
        ApplyTypeKey: data.ApplyTypeKey,
        ApplyTypeKeyValue: data.ApplyTypeKey == '1' ? '新生报名' : data.ApplyTypeKey == '2' ? '老生续费' : '老生扩科'
      };
      this.isOpenApplyTypeUpdate = true;
    },
    closeApplyTypeUpdate () {
      this.isOpenApplyTypeUpdate = false;
    },

    clearSearchParams () {
      this.searchObj = {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '', // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()),
        ClassType: '4',
        IsExportKey: 0
      };
      this.getDataList();
    },
    searchData () {
      this.getDataList();
    },
    // 查看详情
    goDetail (item) {
      this.seletedItem = item;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '班级详情',
        moduleName: 'cwsClassDetail',
        data: {
          classKey: this.seletedItem.OLAPKey,   // 班级详情适用， 班级Key
          isTeacher: false   // 班级详情适用， 是否老师， false则为校长
        },
        callBack: {
          afterSuccess: () => { this.getDataList(); }
        }
      });
    },
    // 修改单个
    editItem (item) {
      this.seletedItem = item;
      this.showAddForm = true;
    },
    // 新增按钮
    addItem () {
      this.seletedItem = null;
      this.showAddForm = true;
    },
    closeDialog () {
      this.showAddForm = false;
    },
    updateData () {
      this.getDataList();
    },
    // 重置查询条件
    initParamsForQuery () {
      this.dataReportList = [];
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    // 获取来源方式
    openCounselorList (data) {
      this.CounselorKey = data.SaleManIDsName;
      this.CounselorKeyValue = data.SaleMansName;
      this.tableID = data.TableID;
      GetSalesList().then(
        (result) => {
          this.CounselorList = result.data;
          this.CounselorList.unshift({
            OLAPKey: 0,
            MainDemoName: '不关联'
          });
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择销售顾问',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.CounselorKey,
              dataSourceList: this.CounselorList,
              required: false,
              ModulePowerKey: 0
            },
            callBack: {
              onSelectedItem: (data) => {
                this.CounselorKey = data.OLAPKey;
                this.CounselorKeyValue = data.MainDemoName;
                RelevantSales(this.tableID, this.CounselorKey).then((res) => {
                  console.log('关联销售成功', res.data);
                  this.getDataList();
                });
              }
            }
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    showSingleSelectionDialog (data) {
      let studentInfo = {
        OLAPKey: data.StudentKey,
        MainDemoName: data.StudentKeyValue
      };
      this.tableID = data.TableID;
      // 判断权限,
      if (this.vCheckModulePower) {
        this.$dialog.open(this, {
          name: '加入班级',
          routerName: this.$route.name,
          routertitle: '',
          ModulePowerKey: 51,
          moduleName: 'addStudentToBJ',
          data: { dataInfo: { studentInfo: studentInfo, type: '进班', isPayment: true } },
          callBack: {
            onSelectedItem: (data) => {
              console.log('data', data);
              this.classKey = data.OLAPKey;
              this.className = data.MainDemoName;
              RelevantGrade(this.tableID, this.classKey, this.className).then((res) => {
                console.log('关联班级成功', res.data);
                this.getDataList();
              });
            }
          }
        });
        // this.$emit('closeDialog');
      } else {
        this.$utils.CheckModulePowerToTips(51);
      }
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '关联转化明细',
          clist: [
            { title: '购课日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '订单号', cName: 'BCMNumberName', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '课单号', cName: 'xykdNumberName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'ParPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课程类型', cName: 'IsSingleKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '适用课程', cName: 'ApplyCorName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买数量', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '实收款', cName: 'ActualPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '生效日期', cName: 'StartTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '失效日期', cName: 'EndTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单类型', cName: 'ApplyTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            // { title: '排班进课', cName: 'AttendClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关联转化', cName: 'RelationScheduleValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        getConversionCourseScheduleList(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    openbaoming (data) {
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: data.StudentKey, TableID: data.SourceBillKey, isReadOnly: true } },
        callBack: { afterSucces: () => { this.reload(); } }
      });
    },
    getDataList () {
      this.dataReportList = [];
      getConversionCourseScheduleList(this.searchObj).then(
        (res) => {
          console.log('CWSCWSSearchPageClassList', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.dataReportList.forEach(item => {
            // item.NumCountText = Number(item.NumCount).toFixed(2) + this.unitKeyValue(item.UnitKey);
            item.ActualPayAmountText = Number(item.ActualPayAmount) == 0 ? '-' : '￥' + this.$utils.setMoneyformatToParseInt(Number(item.ActualPayAmount));
            item.AttendClassKeyText = item.AttendClassKey == '-1' ? '未设置' : item.AttendClassKey == '0' ? '无' : item.AttendClassKeyValue;
            item.SaleMansKeyText = item.SaleManIDsName == '-1' ? '未设置' : item.SaleManIDsName == '0' ? '无' : item.SaleMansName;
            item.RelationScheduleKeyText = item.RelationScheduleKey == '-1' ? '未设置' : item.RelationScheduleKey == '0' ? '无' : item.RelationScheduleKey == '1' ? '部分关联' : '已关联';
            item.StartTimeText = item.StartTime == '1900-01-01' ? '-' : item.StartTime;
            item.NumCountText = Number(item.NumCount) + this.unitKeyValue(item.UnitKey);

            if (Number(item.ActualPayAmount) == 0 && item.ApplyTypeKey != '4') {
              item.ApplyTypekeyText = '赠送';
            } else {
              item.ApplyTypekeyText = item.ApplyTypeKey == '1' ? '新生报名' : item.ApplyTypeKey == '2' ? '老生续费' : item.ApplyTypeKey == '3' ? '老生扩科' : '引流';
            }

            item.ApplyCorNameValue = item.IsSingleKeyValue + '|' + item.ApplyCorName;
            item.ParPhoneName = item.ParPhoneName ? item.ParPhoneName : '-';
            if (Number(item.ApplyTypeKey) == 4 || Number(item.ActualPayAmount) == 0) {
              item.RelationScheduleValue = '-';
            }
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    closeRelevantTableDialog () {
      this.isShowRelevantTable = false;
    },
    openRelevantFormDialog (data) {
      this.StudentKey = data.StudentKey;
      this.IsRenewalKey = (data.ApplyTypeKey == 2 && Number(data.ActualPayAmount) > 0) ? 1 : 0;
      this.studentName = data.StudentKeyValue;
      this.tableID = data.TableID;
      this.isShowRelevantTable = true;
    },
    relevantTableSuccess () {
      this.getDataList();
    },
    relevantFormSuccess () {
      console.log('成功ok');
      this.getDataList();
    },
    supplementtarySettingSuccess () {
      this.getDataList();
    }
  }
};
</script>
<style scoped>
.heard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.heardItem {
  display: flex;
  margin-right: 20px;
  height: 60px;
  align-items: center;
}
</style>