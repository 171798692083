<template>
	<div>
		<div class="list_title">
			<div class="list_title_name">
				{{dataInfo.StudentKeyValue}}完成的作业
			</div>
			<!-- <div class="list_title_btn">
				批改
			</div> -->
		</div>
		<remark-content-view :dataInfo='dataInfo.StudentSubHomeWork'></remark-content-view>
	</div>

</template>

<script>
	import remarkContentView from '../../../components/comment-box/remark-content-view';
	export default {
		components: {
			remarkContentView
		},
		data() {
			return {

			};
		},
		props: {
			dataInfo: Object
		},
		created() {
			console.log(this.dataInfo, "dataInfo")
		},
		methods: {
			doWorkCorrectClick() {
				//this.$store.dispatch('popup/open', {content: '进入作业批改页');
			}
		}
	};
</script>

<style>

</style>
