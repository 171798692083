<template>
  <!-- 评论头像 最多显示9个-->
  <!-- <div > -->
  <div v-if="imageMaxIndex<9"
       class="overview_list" @click.stop="clickImageList">
    <img class="img_box"
         :src="vHeadImg"
         alt="">
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    imageHeadItem: {
      type: Object,
      default: {}
    },
    imageMaxIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    vHeadImg (url) {
      if (this.imageHeadItem.ReaderHeadImg) {
        if (this.imageHeadItem.ReaderHeadImg.indexOf('http') > -1) {
          return this.imageHeadItem.ReaderHeadImg;
        } else {
          return this.$store.getters.CDNURL + this.imageHeadItem.ReaderHeadImg;
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    // 点击头像操作
    clickImageList () {
      this.$emit('doClickImageList');
    }
  }
};
</script>

<style>
</style>