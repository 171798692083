<!-- 流量包模块 -->
<template>
  <!-- 授权管理 -->
  <div class="payment_statistics_bottom flow_packet_report">
    <!-- 头部 -->
    <div class="HQflow_packet_head">
      <div class="head">{{FlowPacketList.FlowPackagesKeyValue}}</div>
    </div>
    <div class="HQflow_packet_total">
      <!-- v-if="vmdInfo.EditionKey==5" -->
      <div class="HQmendian_backstage_date">
        <i class="el-icon-time"></i>
        <div class="valid_date">有效期:</div>
        <div class="startAndend">
          <div :title="FlowPacketList.GenerateTime"
               class="start_date">
            {{FlowPacketList.GenerateTime}}</div>
          <span style="font-weight: 400;font-size: 14px;color:#666;float: left;">至</span>
          <div :title="FlowPacketList.ExpiredTime"
               class="end_date">
            {{FlowPacketList.ExpiredTime}}</div>
        </div>
      </div>
      <div class="HQmendian_backstage_shop">
        <div class="buy_Num">购买数量:</div>
        <div class=""
             style="margin-right:10px;font-size: 14px;float:left;color:#666;">{{FlowPacketList.NumCount}}</div>
      </div>
      <div class="HQmendian_backstage_search">
        <div class="surplus_Num">剩余数量:</div>
        <div class=""
             style="margin-right:10px;font-size: 14px;float:left;color:#666;">{{FlowPacketList.ResidueCount}}</div>
      </div>
    </div>
    <div class="course_statistics_table_search flex">
      <span style="width: 105px;height: 37px;line-height:37px;font-family: 'Alibaba PuHuiTi';font-style: normal;
      font-weight: 400;font-size: 14px;color: #666666;">选择时间</span>
      <div class="title_date_select">
        <div class="el-select">
          <div class="el-input el-input--small el-input--suffix is-focus">
            <single-year-picker style="padding-left: 0px;"
                                @chgDate="chgDate"
                                ref="clearSelectedDate"
                                :type="'month'"></single-year-picker>
          </div>
        </div>
      </div>
      <div class="width_small course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="vTableCourseColumns"
                  :key="tableKey"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from './hqsingle-year-picker';
import {
  GetFluxPackageRecordForList, GetFluxPackageDetails
} from '../../../../API/workbench';
export default {
  components: {
    tableList,
    inputSearchContainBtn,
    singleYearPicker
  },
  data () {
    return {
      getDate: new Date(),
      seletedDate: Date.new(),
      FlowPacketList: {
        Number: 123,
        ResidueCount: 0,
        NumCount: 0,
        GenerateTime: '00-00-00 00:00',
        ExpiredTime: '00-00-00 00:00'
      },
      TypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '通用',
          value: 1
        },
        {
          label: '定向',
          value: 2
        }

      ],
      // 查询参数
      searchObj: {
        startTime: '',
        endTime: '',
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        searchDate: '',
        sequence: 'DESC',
        orderBy: '',
        FluxPackageKey: 1,
        ExpendClientKey: 0
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      GeneralFluxCount: 0,
      HQtableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '年月',
          prop: 'StartTime',
          minWidth: 70,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '校区',
          prop: 'ExpendClientKeyValue',
          minWidth: 100,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '扣减流量',
          prop: 'NumberCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 100,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 0
              }

            ]
          }
        }
      ]
    };
  },
  props: {
    dataInfo: Object
  },
  created () {
    console.log(this.dataInfo, 'dataInfo');
    this.searchObj.FluxPackageKey = this.dataInfo.FlowPackagesKey;
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this,
      [
        'flowPacketDetailReport'
      ]);
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.NumCount = Number(o.NumCount) == 0 ? '-' : this.$utils.flowFormate(o.NumCount);
        o.ResidueCount = Number(o.ResidueCount) == 0 ? '-' : this.$utils.flowFormate(o.ResidueCount);
        o.NumberCount = Number(o.NumberCount) != 0 ? this.$utils.flowFormate(o.NumberCount) : '-';
        o.TypeKeyValue = o.TypeKey == 2 ? o.SaaSClientKeyValue : '通用流量';
        this.searchObj.ExpendClientKey = Number(o.ExpendClientKey);
        newArr.push(o);
      });
      console.log(newArr, 'newArr');
      return newArr;
    },
    vTableCourseColumns () {
      // if (this.vmdInfo.EditionKey == 5) {
      return this.HQtableCourseColumns;
      // } else {
      //   return this.tableCourseColumns;
      // }
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vTypeKeyValue () {
      return this.searchObj.FluxPackageType == 0
        ? '全部'
        : this.searchObj.FluxPackageTypeValue;
    }
  },
  methods: {
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      console.log(this.searchObj.startTime, this.searchObj.endTime, 'this.searchObj.endTime');
      this.getDataList();
    },
    setFluxNumber (size) {
      size = Number(size);
      if (size == 0) {
        return 0 + 'KB';
      }
      let kb = 1024;
      let unit = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
      let i = Math.floor(Math.log(size) / Math.log(kb));
      return Number((size / Math.pow(kb, i)).toPrecision(4)) + ' ' + unit[i];
    },
    getDataList () {
      let fn = '';
      fn = GetFluxPackageRecordForList;

      fn(this.searchObj).then(result => {
        console.log(result.data.PageDataList, 'GetFluxPackageRecordForList');
        this.dataReportList = result.data.PageDataList;
        this.dataReportList.Number = this.dataReportList.Number;
        this.dataReportList.ExpiredTime = this.dataReportList.ExpiredTime;
        this.totalNum = result.data.Total;
        // this.searchObj.FluxPackageKey = result.data.FluxPackageKey;
      }).catch(error => {
        console.log('errorGetFluxPackageRecordForList', error.msg);
      });
      GetFluxPackageDetails(this.searchObj.FluxPackageKey).then((result) => {
        this.FlowPacketList = result.data;
        console.log('GetFluxPackageDetails', result.data);
        this.FlowPacketList.FlowPackagesKeyValue = this.dataInfo.FlowPackagesKeyValue;
        this.FlowPacketList.Number = this.dataInfo.Number || 123;
        this.FlowPacketList.ResidueCount = Number(this.dataInfo.ResidueCount.charAt(0)) == 0 ? '-' : this.dataInfo.ResidueCount;
        if (Number(this.dataInfo.ResidueCount.charAt(0)) == 0 && this.dataInfo.ResidueCount.charAt(1) != '.') {
          this.FlowPacketList.ResidueCount = '-';
        } else {
          this.FlowPacketList.ResidueCount = this.dataInfo.ResidueCount;
        }
        if (Number(this.dataInfo.NumCount.charAt(0)) == 0 && this.dataInfo.NumCount.charAt(1) != '.') {
          this.FlowPacketList.NumCount = '-';
        } else {
          this.FlowPacketList.NumCount = this.dataInfo.NumCount;
        }
        this.FlowPacketList.GenerateTime = this.dataInfo.GenerateTime || '00-00-00 00:00';
        this.FlowPacketList.ExpiredTime = this.dataInfo.ExpiredTime || '00-00-00 00:00';
      })
        .catch((error) => {
          console.log('errorGetFluxPackageDetails', error);
        });
    },
    clearSearchCondition () {
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.FluxPackageTypeValue = '全部';
      this.searchObj.FluxPackageType = '0';
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.seletedDate = '';
      this.$refs.clearSelectedDate.cleanTime();
      this.search();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    search () {
      console.log(this.searchObj.PageDataList, 'this.searchObj.PageDataList');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    },
    // table 按钮点击
    doAfterTableBtnClick (item) {
      console.log(item, 'item');
      let obj = {
        StartTime: item.StartTime + '-' + '01',
        EndTime: this.$utils.getday(item.StartTime),
        OLAPKey: item.ExpendClientKey,
        FlowPackagesKeyValue: this.FlowPacketList.FlowPackagesKeyValue,
        FlowPackagesKey: this.searchObj.FluxPackageKey,
        SaaSClientKey: this.dataInfo.SourceClientKey
      };
      console.log(obj, 'obj');
      this.$dialog.open(this, {
        name: item.ExpendClientKeyValue + '-流量明细',
        routerName: this.$route.name,
        moduleName: 'flowPacketDetailReport',
        ModulePowerKey: '',
        data: { dataInfo: obj,searchType:1 }
      });
    }
    // toBuy () {

    // }
  }
};
</script>

<style scoped>
.el-icon-time {
  position: absolute;
  left: 35px;
}
.title_date_select {
  display: inline-block;
  width: 184px;
  height: 36px;
  border-radius: 4px;
  margin-left: 10px;
  vertical-align: top;
  left: -20px;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #ececec;
}

.title_date_select .el-input input {
  padding-left: 10px;
  height: 34px;
  width: 101px;
  vertical-align: top;
  padding-right: -20px;
  font-size: 13px;
}

.title_date_select::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  right: 6px;
  top: 15px;
  border-top-color: #ccc;
  pointer-events: none;
}

.title_date_select.open::after {
  border-top-color: transparent;
  border-bottom-color: #ccc;
  top: 9px;
}

.title_date_select .el-select {
  z-index: 2;
}
.HQflow_packet_head {
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  line-height: 18px;
  color: #333333;
}
.head {
  position: absolute;
  top: 70px;
  left: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.HQflow_packet_total {
  position: absolute;
  top: 96px;
  line-height: 35px;
  background-size: 30px;
  width: 100%;
}
.HQmendian_backstage_date {
  position: absolute;
  width: 276px;
  height: 15px;
  left: -15px;
  top: 14px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #999999;
}
.HQmendian_backstage_shop {
  position: absolute;
  height: 15px;
  left: 380px;
  top: 14px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #999999;
}
.HQmendian_backstage_search {
  position: absolute;
  height: 15px;
  left: 513px;
  top: 14px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #999999;
}
.payment_statistics_bottom {
  height: 760px;
}
.course_statistics_search >>> .search_box {
  margin-right: 20px;
}
.el-input >>> input.el-input__inner {
  border: none !important;
  margin-left: -20px;
}
.valid_date {
  margin: 0 0 0 51px;
  color: #999;
  font-weight: 400;
  font-size: 14px;
}
.startAndend {
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  width: 280px;
  position: absolute;
  left: 100px;
}
.start_date {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.end_date {
  font-weight: 400;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
}
.buy_Num {
  margin-right: 10px;
  color: #999;
  font-size: 14px;
  float: left;
}
.surplus_Num {
  margin-right: 10px;
  color: #999;
  font-size: 14px;
  float: left;
}
</style>

