<template>
  <div class="home_page">
    <!-- :style="{'transform': 'scaleY('+scaleY+')'}" -->
    <div class="home_page_left_contont">
      <div class="left_contont_top">
        <div class="contont_top_left">
          <searchContent @doAfterClickFunctionBtn="doAfterClickFunctionBtn"></searchContent>
          <echart-data-report ref="echartReport"
                              :searchType="searchType"
                              :dataInfo="kqException"
                              @chgSearchType="chgSearchType"
                              @showDialog="showDialog"></echart-data-report>
        </div>
        <div class="contont_top_right">
          <report-item :className="'type_blue'"
                       :dataInfo="applyRecordInfo"
                       :isApply="true"
                       @doAfterClickFunctionBtn="doAfterClickFunctionBtn"
                       @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"></report-item>
          <report-item :className="'type_yellow'"
                       :dataInfo="trialLessonInfo"
                       @doAfterClickFunctionBtn="doAfterClickFunctionBtn"
                       @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"></report-item>
          <report-compare-item :className="'type_pink'"
                               :dataInfo="bukeInfo"
                               :isbuke="true"
                               @doAfterClickFunctionBtn="doAfterClickFunctionBtn"></report-compare-item>
          <report-compare-item :className="'type_purple'"
                               :dataInfo="yuekeInfo"
                               @doAfterClickFunctionBtn="doAfterClickFunctionBtn"></report-compare-item>

        </div>
      </div>
      <div class="workbench_view kq_workbench_view">
        <div class="workbench_view_box">
          <div class="workbench_view_left">
            <attendance-date @showDialog="showDialog"
                             @doAfterClickFunctionBtn="doAfterClickFunctionBtn"
                             @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog">

            </attendance-date>
          </div>
        </div>
      </div>
    </div>
    <right-content></right-content>

    <div>
      <dialog-factory ref="indexViewDialogFactory"
                      :key="'indexView'"
                      :routerName="'indexView'"></dialog-factory>
      <dialog-factory-common ref="indexViewDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import attendanceDate from "./attendance-date";
import coursesign from "./course-sign";
import reportItem from './components/report-item'
import dialogFactory from "../common/dialog-factory";
import reportCompareItem from './components/report-compare-item'
import searchContent from './components/search-content'
import echartDataReport from './components/echart-data-report'
import rightContent from '../home-page/right-content'
import {
  getIndexReportRealtimeData,
  getToDoStatistics,
  GetTheEmployeeByID,
  GetTheEmployeeTeachingPercentage,
  GetAttendanceException,
  GetRegistrastionRecord,
  GetTrialLessonTracking,
  GetMakeUpMissedLessonsManageStatistics,
  GetConcludeStatistics
} from "../../API/workbench";
export default {
  name: "indexView",
  data () {
    return {
      isShow: false,
      realtimeInfo: {},
      isInstructions: false, // 使用问答
      warmingMenuList: [], // 预警统计列表
      WarmingStatisical: {},
      token: {},
      staffTeaching: {}, // 教学业绩
      latelyCourseInfo: {}, // 最后一节课
      staffInfo: {},
      mine: {
        moduleName: "mine"
      },
      isShowNewCourseType: false,
      abnormalSign: false, // 异常签到入口
      isAllShow: true,
      isShowBat: true,
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      searchType: 'month',
      kqException: {   //考勤异常
        ChangeAttendanceCount: 6,//	整型	校正考勤
        TimeOutAttendanceCount: 6,//	整型	超时考勤
        QuitRecurringLessonCount: 6,//	整型	退出循环课
        TrialLessonCancelCount: 6,//	整型	取消排课
      },
      applyRecordInfo: {     //报名记录
        title: '报名记录',
        btnName: '报名收款',
        "ModulePowerKey": 50,
        data: {
          moduleName: '',
          ThisMonthCount: 0,//整型	本月报名
          ThisMonthActualColAmount: 0,//	整型 本月实收
          ThisWeekCount: 0,//	整型	本周报名
          ThisWeekCountActualColAmount: 0,//	整型	本周实收
          TodayCount: 0,//	整型	今天报名
          TodayCountActualColAmount: 0,//	整型 今天实收
          ToBeAddedCount: 0,//	整型	待补充
        }

      },
      trialLessonInfo: {     //试课
        title: '试课追销',
        btnName: '学生试课',
        "ModulePowerKey": 64,
        moduleName: '',
        data: {
          SaleApplyCount: 0,//	整型	销售申请
          ParentsApplyCount: 0,//	整型	家长申请
          NoArrangeCount: 0,//	整型	未安排
          TodayTrialLessonCount: 0,//	整型	今日试课
        }
      },
      bukeInfo: {     //补课记录
        title: '补课管理',
        "routertitle": "补课管理",
        "routerName": "repairCourseManagement",
        btnName: '学生补课',
        moduleName: '',
        data: {
          ThisMonthPendingCount: 0,//	整型	本月待处理
          ThisMonthNoRelevancyCount: 0,//	整型	本月未关联
          LastMonthPendingCount: 0,//	整型 本月待处理
          LastMonthNoRelevancyCount: 0,//	整型本月未关联
        }

      },
      yuekeInfo: {     //约课记录
        title: '约课表',
        "routertitle": "约课表",
        btnName: '',
        moduleName: '',
        routerName: 'appointmentCourse',
        ModulePowerKey: 149,
        tipsText: '课前截止预约小时',
        data: {
          todayReach: 0,//	整型	今天达成数
          todayNotReachNum: 0,//	整型	今天未达成数
          tomorrowReach: 0,//	整型	明天达成数
          tomorrowNotreach: 0,//	整型	明天未达成数
        }

      },
      scaleY: 1
    };
  },
  components: {
    attendanceDate,
    coursesign,
    dialogFactory,
    reportItem,
    reportCompareItem,
    rightContent,
    searchContent,
    echartDataReport
  },
  created () {
    this.token = this.$store.getters.token;
    this.warmingMenuList = this.$initJson.toDoWarningList;
    console.log(
      "$utils.formatDateToLine",
      this.$utils.formatDateToLine(Date.new())
    );
    this.loadData();
  },
  mounted () {
    let screenWidth = document.body.clientWidth
    this.scaleY = screenWidth <= 1360 ? 1 : ((screenWidth - 432) / (1360 - 432))
    if (this.scaleY > 1.2) {
      this.scaleY = 1.2
    }
    this.$bus.on(this, 'screenOnResize', (screenWidth) => {
      console.log("screenOnResize", screenWidth)
      this.scaleY = screenWidth <= 1360 ? 1 : ((screenWidth - 432) / (1360 - 432))
      //432 //padding+员工宽度
      if (this.scaleY > 1.2) {
        this.scaleY = 1.2
      }
    });
    this.registerBusEvent();
  },
  activated () {
    let moduleName = this.$route.params.moduleName;
    if (moduleName) {
      this.openCustomDialogByFactoryDialog(this.$route.params);
    }
  },
  computed: {
    isSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vWarmingMenuList () {
      // let newArr = [];
      for (var i = 0; i < this.warmingMenuList.length; i++) {
        if (this.vHasModulePower(54)) {
          switch (String(this.warmingMenuList[i].type)) {
            case "1":
              this.warmingMenuList[
                i
              ].num = this.WarmingStatisical.CountRenewalNum;
              break;
            case "2":
              this.WarmingStatisical.DebtClassHourInfo =
                this.WarmingStatisical.DebtClassHourInfo || {};
              this.warmingMenuList[
                i
              ].num = this.WarmingStatisical.DebtClassHourInfo.CountDebtClassHourNum;
              break;
            case "3":
              this.warmingMenuList[
                i
              ].num = this.WarmingStatisical.CountLeaveSchoolNum;
              break;
            case "4":
              this.warmingMenuList[
                i
              ].num = this.WarmingStatisical.ArrangeWarningNum;
              break;
            case "5":
              this.warmingMenuList[
                i
              ].num = this.WarmingStatisical.ClassScheduleNum;
              break;
            case "6":
              this.WarmingStatisical.DebtAmountInfo =
                this.WarmingStatisical.DebtAmountInfo || {};
              this.warmingMenuList[
                i
              ].num = this.WarmingStatisical.DebtAmountInfo.DebtAmountNum;
              break;
            default:
              break;
          }
        } else {
          this.warmingMenuList[i].num = 0;
        }
      }
      return this.warmingMenuList;
    },
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = "";
      }
      if (this.staffInfo.XMLIcon.indexOf("http") > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require("../../../public/image/deflut_teacher_photo@2x.png") +
        '"'
      );
    },
    vMendianCreateDateTime () {
      let presentDate = this.$utils.formatDateToLine(Date.new()); // 当天日期
      let newCreateDateTime = this.$store.getters.SaaSClientInfo.CreateDateTime;
      let getDateDiffDay = this.$utils.DateDiffDay(
        newCreateDateTime,
        presentDate
      );
      // console.log(getDateDiffDay, '门店开启日期');
      if (getDateDiffDay >= 0 && getDateDiffDay <= 30) {
        return true;
      } else {
        return false;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },

    vToday () {
      if (this.latelyCourseInfo.CourseNameKey) {
        return (
          this.$utils.formatDatet(
            Date.new(this.latelyCourseInfo.ClassTime),
            "MM"
          ) +
          "月" +
          this.$utils.formatDatet(
            Date.new(this.latelyCourseInfo.ClassTime),
            "dd"
          ) +
          "日 " +
          " " +
          this.$utils.formatDatet(
            Date.new(this.latelyCourseInfo.ClassTime),
            "ddddd"
          )
        );
      }
    }
  },
  methods: {
    // 选择图表时间
    chgSearchType (type) {
      this.searchType = type
      if (type == 'month') {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
      } else if (type == 'week') {
        this.searchObj.startTime = this.$utils.getThisWeekDates().start;
        this.searchObj.endTime = this.$utils.getThisWeekDates().end;
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(new Date().toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateStr(new Date().toLocaleDateString(), 'yyyy-MM-dd');
      }
      console.log(" this.searchObj", this.searchObj)
      this.getAttendanceException()
    },
    loadData () {
      // this.getRealtimeData();
      this.getDataReportInfo()
      this.getAttendanceException()
      this.getStaffInfo(this.token.UserID);
      this.getStaffTeachingPercentage(this.token.UserID);
    },
    getAttendanceException () {
      GetAttendanceException(this.searchObj).then(result => {
        this.kqException = result.data
        if (this.$refs.echartReport) {
          this.$refs.echartReport.initData(this.kqException)
        }
        console.log("GetAttendanceException", result.data);
      })
        .catch(error => {
          console.log("ErrGetAttendanceException", error);
        });
    },
    // 获取统计数据
    getDataReportInfo () {
      // 报名记录统计
      GetRegistrastionRecord().then(result => {
        this.applyRecordInfo.data = result.data
        console.log("GetRegistrastionRecord", result.data);
      })
        .catch(error => {
          console.log("ErrGetRegistrastionRecord", error);
        });
      //试课追销统计
      GetTrialLessonTracking().then(result => {
        this.trialLessonInfo.data = result.data
        console.log("GetTrialLessonTracking", result.data);
      })
        .catch(error => {
          console.log("errGetTrialLessonTracking", error);
        });
      //补课管理统计
      GetMakeUpMissedLessonsManageStatistics().then(result => {
        this.bukeInfo.data = result.data
        console.log("GetMakeUpMissedLessonsManageStatistics", result.data);
      })
        .catch(error => {
          console.log("errGetMakeUpMissedLessonsManageStatistics", error);
        });
      //约课表达成未达成统计
      GetConcludeStatistics().then(result => {
        this.yuekeInfo.data = result.data
        console.log("GetConcludeStatistics", result.data);
      })
        .catch(error => {
          console.log("errGetConcludeStatistics", error);
        });
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log("getStaffInfo", result.data);
        })
        .catch(error => {
          console.log("getStaffInfo", error);
        });
    },
    // 获取员工教学信息
    getStaffTeachingPercentage (OLAPKey) {
      GetTheEmployeeTeachingPercentage(OLAPKey).then(result => {
        console.log("获取员工教学信息", result.data);
        this.staffTeaching = result.data;
        this.latelyCourseInfo = result.data.Today.RecentClass || {};
      });
    },

    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    showDialog (val) {
      this.openCustomDialogByFactoryDialog({ moduleName: val });
    },
    doAfterClickFunctionBtn (item) {
      console.log("doAfterClickFunctionBtn", item)
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog(item);
      } else {
        this.$dialog.openRouter(item, this.$route.name);
      }

    },

    goRouter (item) {
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog(item);
      } else {
        if (item.routerName == "studentManagementReport") {
          item.data = {
            headSummaryData: {
              title: "待排人数",
              className: "font_blue",
              value: 0,
              type: "Num_ToBeArranged",
              dataType: "number",
              decimalPlace: 0,
              filterName: true,
              isSelected: false
            } // 待排课学生
          };
        }
        console.log("item", item);
        this.$bus.emit("needOpenRouter", item);
      }
    },

    registerBusEvent () {
      this.$bus.on(this, "switchHeaderBarToIndexView", () => {
        console.log("switchHeaderBarToIndexView")
        this.loadData();
        // this.$refs.todoList.loadData();
      });

      this.$bus.on(this, "wsAfterUpdateAgentInfo", data => {
        this.staffInfo = data;
      });
      this.$bus.on(this, "openCustomDialog", dialogInfo => {
        console.log("openCustomDialog")
        if (dialogInfo.routerName == "indexView") {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, "openCommonCustomDialog", dialogInfo => {
        if (dialogInfo.routerName == "indexView") {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.indexViewDialogFactory) {
        this.$refs.indexViewDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.indexViewDialogFactoryCommon) {
        this.$refs.indexViewDialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>
<style>
#indexView {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  padding-bottom: 20px;
}
.home_page {
  margin: 0 auto;
  max-width: 1850px;
  min-width: 1296px;
  background: white;
  height: auto;
  flex: 1;
  /* margin-bottom: 35px; */
  padding: 30px;
  display: flex;
  flex-direction: row;
  transform-origin: top left;
}
.home_page_left_contont {
  flex: 1;
  /* background: yellow; */
  padding: 0 30px;
  transform-origin: top left;
  display: flex;
  flex-direction: column;
}
.left_contont_top {
  width: 100%;
  /* height: 100%; */
  /* background: blue; */
  display: flex;
  flex-direction: row;
}
.contont_top_left {
  flex: 1;
  margin-right: 30px;
  /* background: yellow; */
}
.contont_top_right {
  flex: 1;
  /* background: green; */
  display: flex;
  flex-direction: column;
}
.data_content {
  width: 100%;
  height: 159px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 2px 0px #d7d7d733;
  margin-bottom: 20px;
  padding: 15px 20px;
}
.workbench_view {
  padding: 0px !important;
}
.workbench_view_left {
  padding-right: 0px !important;
}
.kq_workbench_view {
  flex: 1;
  margin: 0 auto;
  background: #fff;
  position: relative;
  width: 100%;
}
</style>
