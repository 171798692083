<template>
  <transition name="fade">
    <div class="list_student_list"
        @click="seletedStuAfter(stuItem)"
        :class="[isActive,opt]">
      <!-- AttenceStatusKey==3,出勤, 4请假, 5,旷课 -->
      <el-tooltip popper-class="mendian_block_tooltip" effect="light" visible-arrow="false" :content="stuItem.StudentKeyValue" :disabled="disabled" placement="top">
        <div class="list_text" ><span ref="textBox">{{opt?"":stuItem.StudentKeyValue}}</span></div>
      </el-tooltip>
      <div v-if="attenClass(stuItem)" :class="attenClass(stuItem)" class="fr">{{attenVal(stuItem)}}</div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
      disabled: false
    };
  },
  props: ['stuItem'],
  mounted () {
    this.disabled = this.$refs.textBox.offsetWidth < 76;
  },
  computed: {
    isActive () {
      return this.stuItem.isActive ? 'show_box' : '';
    },
    opt () {
      return this.stuItem.isOpt ? 'opt tick_green' : '';
    }
  },
  methods: {
    attenClass (item) {
      if (item.AttenceStatusKey == 3) {
        return 'list_stundent_type';
      } else if (item.AttenceStatusKey == 4) {
        return 'list_stundent_type type_yellow';
      } else if (item.AttenceStatusKey == 5) {
        return 'list_stundent_type type_red';
      } else {
        return '';
      }
    },
    attenVal (item) {
      if (item.AttenceStatusKey == 4) {
        return '假';
      } else if (item.AttenceStatusKey == 5) {
        return '旷';
      } else {
        return '';
      }
    },
    seletedStuAfter (item) {
      console.log(item, 'item');
      this.$emit('seletedStuAfter', item);
    }
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list_student_list{
  width: 76px!important;
  height: 28px!important;
  line-height: 28px !important;
  text-align: center!important;
  /* margin-right: 5px !important; */
     margin-left: 10px !important;
   margin-bottom: 10px !important;


}
</style>
