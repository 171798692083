<template>
  <!-- 待处理 -->
  <div class="repair_course_view">
    <div class="repair_wait_info">

      <div class=""
           style="display: flex;     padding: 20px;">

        <div class="course_statistics_title">
          <div class="table_select_box">
            <div class="table_select_title">缺勤日期</div>
            <div class="title_date_select">
              <div class="el-select">
                <single-year-picker @chgDate="changeSaveDate"
                                    :defaultDate="StartDate"
                                    ref="monthPicker"
                                    :minDate="vMinDate"
                                    :maxDate="$utils.formatDateToLine(Date.new())"
                                    :isShowRepairCourse="true"
                                    :type="'month'"></single-year-picker>
              </div>
            </div>
          </div>
        </div>

        <input-search-contain-btn v-model="queryParams.searchText"
                                  :placeholder="'搜索学生'"
                                  @onSearchEvent="searchTextSearch"
                                  @clearSearch="clearSearchCondition"></input-search-contain-btn>
      </div>

      <div class="credit_management_table">
        <table-list class="summarizing_list table_list_content"
                    :tableData="dataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :queryParams="queryParams"
                    :footerContent="vFooterTotal"
                    @loadTableData="getMessageSearchPageMakeUpCourseStudensProcessingRecord"></table-list>
      </div>
      <div class="course_student_attendance_operation module_content_menuList"
           :style="{'top':topValue+'px','left':'auto','right': 15+'px'}"
           v-if="isShowOperateBtn">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="operateBtnList"
                       @clickOptionForAlertDialog="onOperateClick"></drop-down-box>
      </div>
    </div>
    <custom-dialog title="补课处理方案"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRepairCourseProgramme"
                   :before-close="closeRepairCourseProgrammePopup">
      <repair-programme-setting :formTitle="'补课处理方案'"
                                :repairProgrammObj="repairProgrammObj"
                                @afterSuccess="closeRepairCourseProgrammePopup">
      </repair-programme-setting>
    </custom-dialog>
    <custom-dialog title="学生补课处理详情"
                   width="1200px"
                   :visible.sync="isShowDetail"
                   :before-close="closeDetail">
      <repair-student-detail :studentobj="dataDetail"></repair-student-detail>
    </custom-dialog>
    <custom-dialog title="安排补课"
                   width="500px"
                   :visible.sync="isShowPlan"
                   :before-close="closePlan">
      <repair-student-plan :planobj="dataPlan"
                           @afterSuccess="successPlan"
                           @cancelClick="closePlan"></repair-student-plan>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageMakeUpCourseStudensProcessingRecord, DealMakeUpForDoNot, DealMakeUpForDeductClassHour, DealMakeUpForRefundClassHour } from '../../../../API/workbench.js';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
import dropDownBox from '../../../common/drop-down-box';
import repairProgrammeSetting from '../repair-programme-setting';
import repairStudentDetail from '../repair-student-detail/index.vue';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import repairStudentPlan from '../repair-student-detail/plan.vue';
export default {
  data () {
    return {
      // 补课详情
      isShowDetail: false,
      dataDetail: {
        key: '',
        name: '',
        tel: '',
        img: '',
        course: '',
        StartTime: '',
        EndTime: ''
      },
      // 安排补课
      isShowPlan: false,
      dataPlan: {
        mode: 1, // 1为已有学生和缺课记录，要选补课课程，2为已有补课课程，要选学生和缺课记录
        studentKey: '', // 学生id
        studentVal: '', // 学生名
        absenceKey: '', // 缺课记录id
        absenceCourseDay: '', // 缺课日期，只传日
        absenceCourseWeek: '', // 缺课日期，周几
        absenceCourseTime: '', // 缺课上课时段
        absenceCourseKey: '', // 缺课课程id
        absenceCourseName: '', // 缺课课程名
        absenceCourseClass: '', // 缺课班级名
        absenceCourseTeacher: '', // 缺课老师名
        absenceCourseType: '', // 缺课原因
        absenceCourseHour: ''// 消耗课时数
      },
      StartDate: '',
      studentsData: {},
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'), //	整型	必须
        EndTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)), //	整型	必须
        ClassTypeKey: '', // 课程ID
        MakeupResultKey: '1' // 补课处理结果 1-待处理 2-已处理 不传是全部
      },
      // 门店操作按钮
      operateBtnList: [],
      isShowOperateBtn: false, // 显示操作项
      isShowRepairCourseProgramme: false, // 补课处理方案
      repairProgrammObj: {}, // 补课处理方案
      topValue: 0, // 默认第一个top值
      dataReportList: [],
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '缺勤日期',
          prop: 'absenteeismTime',
          width: 110,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '学生名',
          prop: 'StudentNameKeyValue',
          width: 110,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openDetail(rowData);
            }
          }
        },
        {
          label: '周几',
          prop: 'WeekKeyValue',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '时间',
          prop: 'ClassTimeName',
          width: 110,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          width: 110,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '考勤',
          prop: 'AttenceStatusKeyV',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '耗课时',
          prop: 'PeriodCount',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '补课处理方案',
          prop: 'handleProgrammeValue',
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.goRepairProgramme(rowData);
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'left',
          width: 180,
          headerAlign: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '安排补课',
                extend: {
                  click: (rowData) => { this.studentPaymentEvent(rowData); }
                }
              },
              {
                name: '更多操作',
                extend: {
                  click: (rowData) => {
                    this.operateBtnEvent(rowData);
                  }

                }
              }

            ]
          }
        }
      ]
    };
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = myDate.getMonth() + 2;
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr, this.$utils.formatDateToLine(Date.new()));
      return dateStr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  components: {
    tableList,
    inputSearchContainBtn,
    dropDownBox,
    singleYearPicker,
    repairProgrammeSetting,
    repairStudentDetail,
    repairStudentPlan
  },
  props: {
    searchObj: Object,
  },
  created () {
    this.StartDate = this.searchObj.startTime;
    this.queryParams.StartTime = this.searchObj.startTime;
    this.queryParams.EndTime = this.searchObj.endTime;
    console.log(this.searchObj, 'searchObj')
    this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
  },
  mounted () {
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowOperateBtn = false;
      },
      false
    );
  },
  activated () {
    if (this.$route.params.data && this.$route.params.data.dataInfo) {
      console.log('activatedactivated23', this.$route.params)
      this.$refs.monthPicker.changeHistoryTime(this.$route.params.data.dataInfo.startTime);
      this.queryParams.StartTime = this.$route.params.data.dataInfo.startTime;
      this.queryParams.EndTime = this.$route.params.data.dataInfo.endTime;
    }
    this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
  },
  methods: {
    // 安排补课
    studentPaymentEvent (obj) {
      this.dataPlan.studentKey = obj.StudentNameKey;
      this.dataPlan.studentVal = obj.StudentNameKeyValue;
      this.dataPlan.absenceKey = obj.ScheduleCourseKey;
      this.dataPlan.absenceCourseDay = obj.absenteeismTime.split('-')[1] + '月' + obj.absenteeismTime.split('-')[2] + '日';
      this.dataPlan.absenceCourseWeek = obj.WeekKeyValue;
      this.dataPlan.absenceCourseTime = obj.ClassTimeName;
      this.dataPlan.absenceCourseKey = obj.CourseNameKey;
      this.dataPlan.absenceCourseName = obj.CourseNameKeyValue;
      this.dataPlan.absenceCourseClass = obj.GradeClassKeyValue;
      this.dataPlan.absenceCourseTeacher = obj.MTeacherKeyValue;
      this.dataPlan.absenceCourseType = obj.AttenceStatusKey;
      this.dataPlan.absenceCourseHour = obj.PeriodCount;
      this.isShowPlan = true;
    },
    closePlan () {
      this.isShowPlan = false;
    },
    successPlan () {
      this.isShowPlan = false;
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    // 学生补课详情
    openDetail (obj, StartTime, EndTime) {
      this.dataDetail.key = obj.StudentNameKey;
      this.dataDetail.name = obj.StudentNameKeyValue;
      this.dataDetail.tel = obj.CustomerPhoneName;
      this.dataDetail.img = obj.HeadImgSupport;
      this.dataDetail.course = obj.CourseNameKey;
      this.dataDetail.start = this.queryParams.StartTime;
      this.dataDetail.end = this.queryParams.EndTime;
      this.isShowDetail = true;
      console.log(this.dataDetail, 'this.dataDetail');
    },
    closeDetail () {
      this.isShowDetail = false;
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    // 关闭补课处理方案
    closeRepairCourseProgrammePopup () {
      this.isShowRepairCourseProgramme = false;
    },
    // 补课处理方案
    goRepairProgramme (rowData) {
      this.repairProgrammObj = rowData;
      console.log('goRepairProgramme this.repairProgrammObj', this.repairProgrammObj);
      if (rowData.handleProgrammeValue != '-') {
        this.isShowRepairCourseProgramme = true;
      }
    },
    // 选择时间
    changeSaveDate (dataInfo, startTime, endTime) {
      this.queryParams.StartTime = startTime;
      this.queryParams.EndTime = endTime;
      this.queryParams.pageIndex = 0;
      console.log(dataInfo, startTime, endTime, 'dataInfo, startTime, endTime', this.searchData);
      // this.searchTextSearch(this.searchData[this.timeIndex].searchText, this.timeIndex, startTime);
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    // 操作菜单
    operateBtnEvent (rowData) {
      console.log(rowData, this.dataReportList, '操作菜单');
      this.studentsData = rowData;
      let findIndex = this.dataReportList.findIndex((o) => {
        // 找到该项菜单点击下标
        console.log(o.CourseNameKey, rowData.CourseNameKey, '0');
        return Number(o.Number) == Number(rowData.Number);
      });
      let index = findIndex + 1;
      this.topValue = 267 + index * 63;
      console.log(this.topValue, 'this.topValue', findIndex);
      this.operateBtnList = [
        { type: 1, name: '不补课', isShow: true },
        { type: 2, name: '退还课时', isShow: Number(rowData.PeriodCount) > 0 }, // 退还课时
        { type: 3, name: '补扣课时', isShow: Number(rowData.PeriodCount) <= 0 } // 补扣课时
      ];
      this.isShowOperateBtn = true;
    },
    // 操作按钮
    onOperateClick (item) {
      console.log(item, 'this', this.studentsData);
      var _this = this;
      this.isShowOperateBtn = false;
      switch (item.type) {
        case 1: // 不补课
          layer.confirm('确定是否不补课?', {
            btn: [{
              name: '确认',
              callBack: function () {
                layer.close();
                DealMakeUpForDoNot(_this.studentsData.StudentNameKey, _this.studentsData.ScheduleCourseKey).then(result => {
                  _this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
                  layer.alert('处理成功');
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }]
          });
          break;
        case 2: // 退还课时
          layer.confirm('确定是否退还课时?', {
            btn: [{
              name: '确认',
              callBack: function () {
                layer.close();
                DealMakeUpForRefundClassHour(_this.studentsData.StudentNameKey, _this.studentsData.ScheduleCourseKey).then(result => {
                  _this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
                  layer.alert('处理成功');
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }]
          });
          console.log('退还课时');
          break;
        case 3: // 补扣课时
          layer.confirm('确定是否补扣课时?', {
            btn: [{
              name: '确认',
              callBack: function () {
                layer.close();
                DealMakeUpForDeductClassHour(_this.studentsData.StudentNameKey, _this.studentsData.ScheduleCourseKey).then(result => {
                  _this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
                  layer.alert('处理成功');
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }]
          });
          console.log('补扣课时');
          break;
        default:
          break;
      }
    },
    searchTextSearch (val) {
      console.log(val, 'val', this.queryParams);
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    clearSearchCondition () {
      this.queryParams = {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        StartTime: this.queryParams.StartTime, //	整型	必须
        EndTime: this.queryParams.EndTime, //	整型	必须
        ClassTypeKey: '', // 课程ID
        MakeupResultKey: '1' // 补课处理结果 1-待处理 2-已处理 不传是全部
      };
      // this.StartDate = '';
      // this.$refs.monthPicker.cleanTime();
      this.$refs.monthPicker.changeHistoryTime(this.queryParams.StartTime);
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    getMessageSearchPageMakeUpCourseStudensProcessingRecord () {
      MessageSearchPageMakeUpCourseStudensProcessingRecord(this.queryParams).then(result => {
        this.totalNum = result.data.Total;
        this.isShowOperateBtn = false;
        result.data.PageDataList.forEach(o => {
          o.PeriodCount = Number(o.PeriodCount) ? Number(o.PeriodCount) : '0';
          o.AttenceStatusKeyV = Number(o.AttenceStatusKey) == 5 ? "<span class='attencea_red'>" + o.AttenceStatusKeyValue + '</span>'
            : Number(o.AttenceStatusKey) == 4 ? "<span class='attencea_yellow'>" + o.AttenceStatusKeyValue + '</span>' : o.AttenceStatusKeyValue;

          // 补课处理方案
          if (o.DealTypeKey == '0') {
            // 待处理
            o.handleProgrammeValue = '-';
          } else if (o.DealTypeKey == '2') {
            if (o.MakeupAttenceStatusKey == '2') {
              // 已排课
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : '<span>') + '已排课</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            } else if (o.MakeupAttenceStatusKey == '3') {
              // 已补课
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span class='attencea_green'> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : "<span class='attencea_green'>") + '已补课&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            } else if (o.MakeupAttenceStatusKey == '4') {
              // 已请假
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span class='attencea_yellow'> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : "<span class='attencea_yellow'>") + '请假</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            } else if (o.MakeupAttenceStatusKey == '5') {
              // 已旷课
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span class='attencea_red'> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : "<span class='attencea_red'>") + '旷课</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            }
          } else {
            o.handleProgrammeValue = '-';
          }
        });
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, '获取课类课名列表 MessageSearchPageMakeUpCourseStudensProcessingRecord');
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>
<style scoped>
.repair_course_view {
  width: 100%;
  background: #fff;
}
.table_select_box .el-select >>> .el-input__inner {
  border: none !important;
  width: 200px;
}
.title_date_select {
  width: 200px !important;
}
.course_statistics_title {
  padding: 0 !important;
  margin-right: 40px;
}
.course_search_div .mendian_backstage_search {
  margin-top: 20px;
  margin-left: 40px;
}
.summarizing_list >>> .el-table .attencea_green {
  color: rgb(115, 174, 72);
}
.summarizing_list >>> .el-table .attencea_red {
  color: rgb(255, 0, 0);
}
.summarizing_list >>> .el-table .attencea_yellow {
  color: rgb(237, 125, 49);
}
.summarizing_list >>> .el-table .round_num {
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
}
</style>