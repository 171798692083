<template>
  <!-- 长假停课 -->
  <div>
    <module-description :textTips="'批量停止一天或多天的课。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/_8k2FGwHjn5q1N4s-U-TPw'"></module-description>
    <div class="form_info_edit form_info_list">

      <input-dialog :formTitle="'停课范围'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    v-model="classInfo.MainDemoName"
                    @showEldilog="chgAdjustScope()"></input-dialog>

      <div :class="{'required_position':startWeek.length > 0}">
        <input-date v-model="startDate"
                    :required="true"
                    :elementId="'adjustADate'"
                    :formTitle="'起始日期'"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :formPlaceholder="'请选择'"
                    @changeDate="startDateEvent">
        </input-date>
        <span class="week_select">{{startWeek}}</span>
      </div>
      <div :class="{'required_position':endWeek.length > 0}">
        <input-date v-model="endDate"
                    :required="true"
                    :elementId="'adjustBDate'"
                    :formTitle="'结束日期'"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :formPlaceholder="'请选择'"
                    @changeDate="endDateEvent">
        </input-date>
        <span class="week_select">{{endWeek}}</span>
      </div>

      <input-dialog :formTitle="'停课日期总计'"
                    :formPlaceholder="''"
                    :required="false"
                    :readonly="true"
                    :className="'font_black'"
                    :value="sumDays+'天'"></input-dialog>

      <div class="pr">
        <input-switch v-model="IsNotify"
                      :formTitle="'是否发送家校通知'"
                      style="padding-right: 23px;"
                      :readonly="false"
                      :required="false"></input-switch>
      </div>
      <input-text :formTitle="'处理原因'"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  :required="false"
                  v-model="Remarks"></input-text>

      <!-- 按钮组 -->
    </div>
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="affirmSave"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import { DateDiff } from '../../../../utils/index.js';
import { StopScheduleCourseForVacation, SearchClass } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      startDate: '', // 开始日期的初始值
      endDate: '', // 结束日期
      startWeek: '', // 开始周
      endWeek: '', // 结束周
      sumDays: 0, // 停课日期 总 计天数
      Remarks: '', // 处理原因--备注
      classInfo: { MainDemoName: '全校', OLAPKey: '0' },
      IsNotify: 0	// 		0 不通知 1通知
    };
  },

  created () {
  },
  methods: {
    // 停课范围
    chgAdjustScope () {
      SearchClass().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择班级',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.classInfo.OLAPKey, dataSourceList: result.data, required: false },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data);
              if (data.OLAPKey > 0) {
                this.classInfo.OLAPKey = data.OLAPKey;
                this.classInfo.MainDemoName = data.MainDemoName;
              } else {
                this.classInfo.OLAPKey = 0;
                this.classInfo.MainDemoName = '全校';
              }
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 判断获取的天数是为负数还是正数
    getSumDays () {
      if (this.startDate && this.endDate) {
        if (
          Date.new(this.startDate).getTime() > Date.new(this.endDate).getTime()
        ) {
          this.sumDays = -DateDiff(this.startDate, this.endDate);
        } else {
          this.sumDays = DateDiff(this.startDate, this.endDate) + 1;
        }
      } else {
        this.sumDays = 0;
      }
    },
    // 选择开始停课 周期
    startDateEvent (e) {
      this.$nextTick(() => {
        if (e) {
          e = e.replace(/\./g, '/');
          this.startDate = e;
          this.startWeek = this.getWeekVal(e);
        } else {
          this.startWeek = '';
        }
        this.getSumDays();
      });
    },
    // 选择结束停课 周期
    endDateEvent (e) {
      this.$nextTick(() => {
        if (e) {
          e = e.replace(/\./g, '/');
          this.endDate = e;
          this.endWeek = this.getWeekVal(e);
        } else {
          this.endWeek = '';
        }
        this.getSumDays();
      });
    },
    // 获取周
    getWeekVal (date) {
      let weekVal = '周' + '日一二三四五六'.charAt(Date.new(
        date.slice(0, 4),
        date.slice(5, 7) - 1,
        date.slice(8, 10)
      ).getDay());
      return weekVal;
    },
    // 确定按钮事件触发
    affirmSave (callBack, IsNotEndKey) {
      let flag = false;
      IsNotEndKey = IsNotEndKey == undefined ? 1 : IsNotEndKey;
      if (!this.startDate || !this.endDate) {
        layer.alert('请检查是否完善日期');
        flag = true;
      } else if (Date.new(this.startDate).getTime() > Date.new(this.endDate).getTime()) {
        layer.alert('起始日期不能大于结束日期');
        this.getSumDays();
        flag = true;
      } else if (Date.new().getTime() > Date.new(this.startDate).getTime() + 86400000) {
        layer.alert('开始日期为已过的时间段不能停课');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      StopScheduleCourseForVacation(1, this.startDate, this.endDate, '', this.Remarks, IsNotEndKey, this.classInfo.OLAPKey, this.IsNotify).then(result => {
        layer.alert('停课成功');
        this.cancel();
        this.$bus.emit('AfterHolidaysStopCourse', this.startDate, this.endDate); // 停课成功后通知到考勤管理模块刷新数据（传递的遍历只做标示，无具体意义）
      }, error => {
        if (error.code == 70003) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.affirmSave(callBack, 0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消按钮事件触发
    cancel () {
      this.sumDays = 0;
      this.Remarks = '';
      this.$emit('cancel');
    }
  }
};
</script>
<style scoped>
.required_position {
  height: 47px;
}
.week_select {
  position: relative;
  left: 320px;
  top: -31px;
  color: #3498db;
}
</style>
