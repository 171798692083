<template>
  <!-- 补课管理 通用设置 修改-->
  <div class="saturationCourse_us">
    <div class="content_marginRL content_marginTop_30">
      <div class="saturation_course_content">
        <div class="saturation_course_top">
          <div class="index_title pr">
            <span>{{ this.updateTitle }}</span>
            <input class="input_num"
                   type="text"
                   v-model="updateNum" />

          </div>
        </div>
      </div>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>

</template> 

<script>
export default {
  props: {
    dateInfo: Object
  },
  data () {
    return {
      updateTitle: '',
      updateNum: ''
    };
  },

  components: {
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.updateTitle = this.dateInfo.CourseKeyValue;
    this.updateNum = Number(this.dateInfo.MinNotDealCount);
    console.log(this.updateTitle, 'updateText', this.dateInfo, this.updateNum);
  },
  mounted () {
  },
  methods: {
    confirmClick () {
      console.log(this.updateNum, this.dateInfo);
      this.$emit('updataCourseInfo', this.dateInfo, this.updateNum);
    },
    cancelClick () {
      this.$emit('afterSuccess');
    }
  }
};
</script>

<style scoped>
/*  */
.saturationCourse_us {
  height: 120px;
}
.saturation_course_content {
  width: 387px;
  margin: 0 auto;
  height: 52px;
}
.index_title {
  font-size: 14px;
  line-height: 50px;
  display: flex;
  width: 380px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(236, 236, 236, 1) !important;
  padding-left: 0px !important;
}
.right_surFlow {
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3498db;
}
.input_num {
  border: 1px solid #ececec;
  height: 40px;
  border-radius: 4px;
  padding-left: 10px;
  color: #3498db;
  margin-top: 5px;
}
</style>