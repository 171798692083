<template>
  <div class="login_page"
       :style="{backgroundImage:vBrandInfo.LoginBackground}">
    <div class="login_page_box">
      <div class="login_words"
           v-if="!vBrandInfo.isOpenBrand">
        <div class="l_w_hide"></div>
        <div class="left_words">一体化教培系统</div>
        <div class="middle_words"></div>
        <div class="right_words">课件·教务·连锁</div>
      </div>
      <div class="login_page_content">
        <!-- <div class="content_title"
             :style="{color:vBrandInfo.MainBodyColor}">用户登录</div> -->
        <div class="clearfix">
          <div class="login_from fl">
            <div class="login_from_tab">
              <div class="opt"
                   :style="{color:vBrandInfo.MainBodyColor,borderColor:vBrandInfo.MainBodyColor,'--borderColor':vBrandInfo.MainBodyColor}"
                   v-if="curLoginType==loginType.scanningCode"
                   @click.stop="switchLoginType(loginType.scanningCode)">扫码登录</div>
              <div v-else
                   @click.stop="switchLoginType(loginType.scanningCode)">扫码登录</div>
              <div class="opt"
                   :style="{color:vBrandInfo.MainBodyColor,borderColor:vBrandInfo.MainBodyColor,'--borderColor':vBrandInfo.MainBodyColor}"
                   v-if="curLoginType==loginType.passWord"
                   @click.stop="switchLoginType(loginType.passWord)">密码登录</div>
              <div v-else
                   @click.stop="switchLoginType(loginType.passWord)">密码登录</div>
              <div class="opt"
                   style="width:35rem !important;"
                   :style="{color:vBrandInfo.MainBodyColor,borderColor:vBrandInfo.MainBodyColor,'--borderColor':vBrandInfo.MainBodyColor}"
                   v-if="curLoginType==loginType.verifCode"
                   @click.stop="switchLoginType(loginType.verifCode)">验证码登录</div>
              <div v-else
                   style="width:35rem !important;"
                   @click.stop="switchLoginType(loginType.verifCode)">验证码登录</div>
            </div>
            <div>
              <!-- 扫码 -->
              <div class="login_QRcode_box fl"
                   v-show="curLoginType==loginType.scanningCode">
                <qrcode-vue :value="codeValue"
                            :size="codeValue?256:0"
                            level="L"
                            id="qrcode_vue" />
                <div class="bj_code_box"
                     @click.stop="refreshCodeClick"
                     v-if="isRefreshCode">
                  <div class="refresh_icon"></div>
                  <div class="expire_code_text">二维码已过期</div>
                </div>
                <div class="login_QRcode">
                  <div class="frame_QRcode">
                    <div id="twocode"
                         :title="''"
                         @mousemove="mousemoveQRcode($event)"></div>
                    <div class="WXorAPP">微信扫码 | APP扫码</div>
                  </div>
                </div>
              </div>
              <!-- ---- -->
              <!-- 密码登录 -->
              <div class="login_from_one"
                   v-if="curLoginType==loginType.passWord">
                <div class="login_from_input"
                     :style="{'--borderColor':vBrandInfo.MainBodyColor,'--inputBackground':vBrandInfo.inputBackground}">
                  <input name="SaaSClientNO"
                         type="text"
                         id="SaaSClientNO"
                         v-model="SaaSClientNO"
                         placeholder="请输入门店ID"
                         onkeypress="if(event.keyCode==13) {document.getElementById('mobile').focus();}"
                         @input="saasInput" />
                </div>
                <div class="login_from_input"
                     :style="{'--borderColor':vBrandInfo.MainBodyColor,'--inputBackground':vBrandInfo.inputBackground}">
                  <input name="mobile"
                         type="number"
                         id="mobile"
                         v-model="mobile"
                         placeholder="请输入手机号码"
                         onkeypress="if(event.keyCode==13) {document.getElementById('password').focus();}" />
                </div>
                <div class="login_from_input"
                     :style="{'--borderColor':vBrandInfo.MainBodyColor,'--inputBackground':vBrandInfo.inputBackground}">
                  <input name="password"
                         type="text"
                         class="passwordTitle"
                         id="password"
                         v-model="passWord"
                         placeholder="请输入密码"
                         @keypress="keypressToLogin($event)"
                         autocomplete='off' />
                </div>
                <div class="forget_pwd"
                     @click.stop="forgetPBtn">忘记密码</div>
              </div>
              <!-- 短信登录 -->
              <div class="login_from_two"
                   v-if="curLoginType==loginType.verifCode">
                <div class="login_from_input"
                     :style="{'--borderColor':vBrandInfo.MainBodyColor,'--inputBackground':vBrandInfo.inputBackground}">
                  <input type="text"
                         placeholder="请输入手机号码"
                         v-model="mobile"
                         @keyup.stop="keyupMobile($event)" />
                  <div class="code_font_red fr"
                       @click.stop="sendVerificationCode"
                       :style="{color:vBrandInfo.MainBodyColor,'--borderColor':vBrandInfo.MainBodyColor}"
                       v-if="vIsShowVerificationCodeBtn">获取验证码</div>
                  <div class="fr code_num"
                       style="position: absolute;right: 30rem;margin-top: 10rem;"
                       :style="{color:vBrandInfo.MainBodyColor}"
                       v-else>{{countdown}}S</div>
                </div>
                <div class="login_from_input"
                     :style="{'--borderColor':vBrandInfo.MainBodyColor,'--inputBackground':vBrandInfo.inputBackground}">
                  <input type="text"
                         id="verificationCode"
                         placeholder="请输入验证码"
                         v-model="verificationCode"
                         @keyup="keyupVerfiCode($event)"
                         @keypress="keypressToLogin($event)" />
                </div>
              </div>
            </div>
            <div v-if="curLoginType!=loginType.scanningCode">
              <div class="login_btn text_show"
                   :style="{backgroundColor:vBrandInfo.MainBodyColor}"
                   v-if="vIsLight"
                   @click.stop="loginEvent">登录</div>
              <div class="login_btn"
                   v-else
                   @click.stop="loginEvent">登录</div>
            </div>
            <div v-else>
              <div class="WXorAPP_text">
                <div style="text-align: center;margin-bottom: 5rem;">微信扫码：使用登录过门店系统的微信，扫码</div>
                <div style="text-align: center;">APP扫码：点击首页右上角扫码</div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- ------------------------ -->
    <div class="mobile_to_login">
      <div class="logo_bnt">
        <img :src="vBrandInfo.LogoUrl" />
      </div>
      <div style="display: flex;justify-content: space-around;font-size: 6.25rem;">

        <div @click.stop="mobileLogin('电脑端')"
             class="button fr">
          <img style="    width: 6.7rem;height: 5.9rem;"
               src="../../../public/image/PC_terminal.png" />
          电脑端
        </div>
        <div @click.stop="vBrandInfo.isOpenBrand?doAppBrandDownUrl('App'):mobileLogin('手机端')"
             class="button fr">
          <img style="width: 5.1rem;"
               src="../../../public/image/MB_terminal.png" />
          手机端
        </div>
        <div @click.stop="mobileLogin('电视端')"
             class="button fr">
          <img style="width: 7.5rem;"
               src="../../../public/image/TV_terminal.png" />
          电视端
        </div>
      </div>

    </div>
    <custom-dialog class="defult_dialog login_phone_box"
                   :visible.sync="isShowMibileLogin"
                   :before-close="closeMibileLogin"
                   :showPosition="'visibleMiddle'">
      <div class="login_phone_box"
           :style="versionsHint=='电脑端'?'height:340px;':versionsHint=='手机端'?'height:560px;':'height:350px;'">
        <!-- desktop_port_hint app_port_hint -->
        <div class="login_phone_title">{{versionsHint}}</div>

        <!-- 手机端 -->
        <div class="login_phone_img tc"
             v-if="versionsHint=='手机端'">
          <div class="app_port_download"
               @click="doPortDownload('App')"></div>
          <div class="download_package_one">
            <span class="zero_one">01</span><span class="download_text">手机应用商店搜索 <span style="color: #333;font-weight: 600;">治学</span> 安装APP。</span>
            <div class="download_APP">
              <div class="APP_text">如果安卓手机应用商店中搜索不到治学APP，请 <span style="color: #3498DB;font-weight: 600;">点击</span> 下载APK。</div>
            </div>
            <img style="position: absolute;top: 108px;left: 188px;"
                 src="../../../public/image/arrow_bottom.png"
                 alt="">
          </div>
          <div class="download_package_two">
            <span class="zero_two">02</span><span class="download_text">安装成功手机桌面会生成 <span style="color: #333;font-weight: 600;">治学APP图标</span>，点击登录。</span>

            <img style="margin-left: -5px;margin-top: 8px;"
                 src="../../../public/image/APP_two.png"
                 alt="">
            <div class="contact_service">安装如遇问题请联系专属客服。</div>
          </div>
        </div>
        <!-- 电脑端 -->
        <div class="login_phone_img tc"
             v-if="versionsHint=='电脑端'">
          <div class="desktop_port_download"
               @click="doPortDownload('Desktop')"></div>

          <div class="download_package_one">
            <span class="zero_one">01</span><span class="download_text">下载桌面端安装包。</span>
            <div class="download_PC">
              <img src="../../../public/image/download_png.png" />
              下载桌面端
            </div>
            <img style="position: absolute;top: 88px;left: 188px;"
                 src="../../../public/image/arrow_bottom.png"
                 alt="">
          </div>
          <div class="download_package_two">
            <span class="zero_two">02</span><span class="download_text">点击<span style="color: #333;font-weight: 600;"><span v-if='!vBrandInfo.isOpenBrand'>ZX</span><span v-else>BC</span>Setup.exe</span>安装桌面端。</span>

            <!-- <img style="margin-left: -5px;margin-top: 8px;"
                 src="../../../public/image/PC_two.png"
                 alt=""> -->
            <div class="contact_service">安装如遇问题请联系专属客服。</div>
          </div>
        </div>
        <!-- 电视端 -->
        <div class="login_phone_img tc"
             v-if="versionsHint=='电视端'">
          <div class="desktop_port_download"
               @click="doPortDownload('MB')"></div>

          <div class="download_package_one">
            <span class="zero_one">01</span><span class="download_text">下载电视端安装包到U盘。</span>
            <div class="download_PC"><img src="../../../public/image/download_png.png" />下载电视端</div>
            <img style="position: absolute;top: 88px;left: 188px;"
                 src="../../../public/image/arrow_bottom.png"
                 alt="">
          </div>
          <div class="download_package_two">
            <span class="zero_two">02</span><span class="download_MB_text">将U盘插到电视或盒子USB接口，打开U盘找到相应的安装包安装即可。</span>

            <div class="contact_service"
                 style="margin-top: 18px;">安装如遇问题请联系专属客服。</div>
          </div>
        </div>
        <div @click.stop="closeMibileLogin"
             class="login_phone_close button"></div>
        <div class="login_phone_button">
          <div @click.stop="closeMibileLogin"
               class="btn_light_blue btn_hover_bg_blue button">
            知道了
          </div>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog :top="220"
                   width="250px"
                   :showPosition="'visibleMiddle'"
                   class="defult_dialog login_phone_box"
                   :visible.sync="isShowAppQRcodeImg"
                   :before-close="closeAppQRcodeImg">
      <div class="login_phone_box"
           v-show="isShowAppQRcodeImg">
        <div class="sign_QRcode_img">
          <qrcode-vue :value="signCodeValue"
                      :size="116"
                      level="L"
                      id="signQRcode" />
        </div>
        <div class="sign_QRcode_text">
          请使用手机浏览器扫码下载
        </div>
        <div @click.stop="closeAppQRcodeImg"
             class="login_phone_close button"></div>
      </div>
    </custom-dialog>
    <!--  -->
    <custom-dialog class="defult_dialog login_phone_box"
                   :showPosition="'visibleMiddle'"
                   :visible.sync="showForgetPwd"
                   :before-close="closeForgetPwd">
      <div class="login_phone_box">
        <div class="login_phone_title">{{ this.alertPwdTitle }}</div>
        <div @click.stop="closeForgetPwd"
             v-if="!isAlertPwd"
             class="login_phone_close button"></div>
        <forget-pwd :isAlertPwd="isAlertPwd"
                    :IdxSaaSClientNO="SaaSClientNO"
                    :IdxMobile="mobile"
                    :vBrandInfo="vBrandInfo"
                    @close="closeForgetPwd"
                    @alertClick="alertClick"></forget-pwd>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import { LoginInByMobile, ApplyLoginDistinguishCode, GetCaptcha, LoginInByPwd, GetBrandDownUrl } from '@/API/workbench.js';
import { doAfterLoginSuccess } from '../../assets/login';
import forgetPwd from './forget-pwd';
import QrcodeVue from 'qrcode.vue';
export default {
  data () {
    return {
      DesktopAPPTVDownUrl: '',
      isDisabled: false,
      signCodeValue: '',
      codeValue: '',
      mdInfo: {},
      NewPassword: '',
      isAlertPwd: false,
      alertPwdTitle: '',
      showForgetPwd: false,
      SaaSClientNO: '123',
      mobile: '',
      passWord: '',
      verificationCode: '',
      isCodeLogin: false, // 扫码登陆
      countdown: 0,
      loginType: {
        scanningCode: 1,
        passWord: 2,
        verifCode: 3
      },
      curLoginType: 1,
      ws: null, // 建立的连接
      websocketParams: {
        lockReconnect: false, // 是否真正建立连接
        timeout: 10 * 1000 * 60, // 10分钟一次心跳
        timeoutObj: null, // 心跳心跳倒计时
        timeoutnum: null, // 断开 重连倒计时
        clientID: 0
      },
      setTimeoutObjForCode: null,
      refreshCodeTimeNum: null,//二维码过期刷新 定时器
      setTimeoutObj: null,
      responseWebsocketData: null,
      isRefreshCode: false,//刷新二维码是否显示
      isSendding: false,
      isShowMibileLogin: false,
      isShowAppQRcodeImg: false,
      versionsHint: ''
    };
  },
  created () {
    console.log(process.env.VUE_APP_PC_BrandCustomSetting, 'this.PC_BrandCustomSetting');
    console.log(document.body.scrollWidth, 'document.documentElement.clientWidth', document.body.scrollHeight);
    let obj = {};
    if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
      obj = JSON.parse(window.brandCustomSettingStr);
    } else {
      obj = {
        MainDemoName: '治学'
      };
    }
    let token = this.$fun.loginInfo.getCookie('token');
    if (token && token != 'null') {
      this.$router.push({ path: '/' });
      document.title = obj.MainDemoName;
    } else {
      document.title = obj.MainDemoName + '系统 | 门店端登录';
    }
    console.log(token, 'tokentokentoken');
    this.$store.commit('SetPopupShade', false);
    this.getLastLoginInfo();
    // console.log(window.brandCustomSettingStr, 'window.brandCustomSettingStr');
  },
  activated () {
    this.getLastLoginInfo();
  },
  destroyed () {
    if (this.setTimeoutObjForCode || this.refreshCodeTimeNum) {
      clearTimeout(this.setTimeoutObjForCode);
      this.setTimeoutObjForCode = null;
      clearTimeout(this.refreshCodeTimeNum);
      this.refreshCodeTimeNum = null;
    }
    this.$store.commit('SetPopupShade', false);
  },
  components: {
    forgetPwd,
    QrcodeVue
  },
  mounted () {
    localStorage.removeItem('apiList');
    localStorage.removeItem('newAPIURL');
    this.$store.commit('selectNewAPIURL');
    this.$websocket.init('login');
    console.log(this.vBrandInfo, 'this.vBrandInfo');
    this.$bus.on(this, 'wsConnectSuceess', data => {
      this.applyLoginDistinguishCode(data);
    });
    this.$bus.on(this, 'wsAfterLoginSuceess', data => {
      this.doAfterLoginSuccess(data);
    });
    this.$bus.on(this, 'LoginReply', data => {
      console.log('LoginReplybus', data);
      this.closeForgetPwd(data);
      layer.alert(data.msg);
    });

    if (this.$store.getters.loginReply) {
      layer.alert(this.$store.getters.loginReply.msg);
      this.$store.commit('setLoginReply', null);
    }
  },
  computed: {
    vIsShowVerificationCodeBtn () {
      return this.countdown == 0;
    },
    vIsLight () {
      if (this.curLoginType == this.loginType.passWord) {
        return this.mobile && this.SaaSClientNO && this.passWord;
      } else {
        return this.mobile && this.verificationCode;
      }
    },
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = 'url(' + this.$store.getters.CDNURL + obj.LoginBackground + ')';
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = '#fff';
      } else {
        obj = {
          isOpenBrand: false,
          BrandSlogan: 'url(' + require('../../../public/image/login_text.png') + ')',
          // LoginBackground: 'url(' + require('../../../public/image/login_banner.png') + ')',
          LoginBackground: '',
          LogoUrl: require('../../../public/image/logo_terminal.png'),
          MainBodyColor: '#f85a59',
          MainDemoName: '治学培训机构',
          QRCode: '',
          inputBackground: '#f7f7f7'
        };
      }
      return obj;
    }
  },
  methods: {
    saasInput (e) {
      this.SaaSClientNo = e.target._value;
      this.SaaSClientNo = this.SaaSClientNo.replace(/[, ]/g, '');
    },
    getLastLoginInfo () {
      if (localStorage.getItem('ZXLoginInfo')) {
        let obj = JSON.parse(localStorage.getItem('ZXLoginInfo'));
        this.SaaSClientNO = obj.SaaSClientNO;
        this.mobile = obj.mobile;
      }
    },
    // 密码输入框 回车登录
    keypressToLogin (event) {
      if (event.keyCode == 13) {
        if (this.curLoginType == this.loginType.passWord) {
          document.getElementById('password').blur();
        } else {
          document.getElementById('verificationCode').blur();
        }
        this.loginEvent();
      }
    },
    switchLoginType (type) {
      this.curLoginType = type;
      this.mobile = '';
      if (this.curLoginType == this.loginType.scanningCode) {
        this.isCodeLogin = true;
      } else if (this.curLoginType == this.loginType.passWord) {
        this.SaaSClientNO = '';
        this.passWord = '';
        this.isCodeLogin = false;
      } else {
        clearTimeout(this.setTimeoutObj);
        this.verificationCode = '';
        this.countdown = 0;
        this.isSendding = false;
        this.isCodeLogin = false;
      }
      this.getLastLoginInfo();
    },
    loginEvent () {
      if (this.vIsLight) {
        if (this.curLoginType == this.loginType.passWord) {
          this.NewPassword = this.passWord;
          this.loginByPassword();
        } else {
          // 防止重复点击
          if (!this.isDisabled) {
            this.loginByVerfiCode();
          }
          this.isDisabled = true;
          setTimeout(() => {
            this.isDisabled = false;
          }, 3000);
        }
      }
    },
    loginByPassword () {
      this.mobile = this.mobile.replace(/(^\s+)|(\s+$)/g, '');
      this.passWord = this.passWord.replace(/(^\s+)|(\s+$)/g, '');
      this.SaaSClientNO = this.SaaSClientNO.replace(/(^\s+)|(\s+$)/g, '');
      if (!this.SaaSClientNO) {
        layer.alert('请填写门店ID，如有疑问，请联系您的客服!');
        return;
      } else if (!this.mobile) {
        layer.alert('请填写您的账号，即您的登录手机号!');
        return;
      } else if (this.mobile.length != 11) {
        layer.alert('请输入11位的手机号码!');
        return;
      } else if (!this.passWord) {
        layer.alert('请填写您的密码!');
        return;
      }
      this.passWord = this.passWord.replace(/\%/g, '%25').replace(/\+/g, '%2B');
      let fromType = this.$zxClient.IsClient ? 'DP' : 'PC';
      LoginInByPwd(this.SaaSClientNO, this.mobile, this.passWord, fromType).then(result => {
        if (result.code == 0) {
          if (this.$zxClient.IsClient) {
            if (result.data.SaaSClientNO.split('-').length > 1) {
              this.$zxClient.SetCustomCode(result.data.SaaSClientNO.split('-')[0]);
            } else if (result.data.SaaSClientEditionKey == 5) {
              this.$zxClient.SetCustomCode(result.data.SaaSClientNO);
            }
          }
          this.$store.commit('SetToken', result.data);
          this.mdInfo = result.data;
          if ((this.NewPassword.length < 6 || this.NewPassword.length > 18) || (!isNaN(this.NewPassword) && this.NewPassword.indexOf('.') == -1)) {
            this.alertPwdTitle = '修改密码';
            this.isAlertPwd = true;
          } else {
            this.isAlertPwd = false;
            this.doAfterLoginSuccess(result.data, this.isAlertPwd);
          }
          // 弹出 强制修改密码
          this.showForgetPwd = this.isAlertPwd;
        }
      }, error => {
        if (error.code == '2001') {
          layer.alert('登录失败:密码不正确', undefined, 0, () => {
            document.getElementById('password').focus();
          });
        } else if (error.code == '2000') {
          error.msg = '登录失败:当前账号不存在';
        } else if (error.data == 'closed') {
          error.msg = '当前用户账号已关闭';
        } else if (error.data == 'noportalright') {
          error.msg = '当前用户不存在，或无权限进入本门户。';
        }
        layer.alert(error.msg);
      });
      this.passWord = '';
    },
    // 通过验证码登录
    loginByVerfiCode () {
      if (!this.mobile) {
        layer.alert('请输入手机号码');
        return;
      } else if (this.mobile.length != 11) {
        layer.alert('请输入11位的手机号码');
        return;
      } else if (!this.verificationCode) {
        layer.alert('验证码不可为空');
        return;
      }
      let fromType = this.$zxClient.IsClient ? 'DP' : 'PC';
      LoginInByMobile(this.mobile, this.verificationCode, fromType).then(
        result => {
          if (result.code == 0) {
            this.isSendding = false;
            if (this.$zxClient.IsClient) {
              if (result.data.SaaSClientNO.split('-').length > 1) {
                this.$zxClient.SetCustomCode(result.data.SaaSClientNO.split('-')[0]);
              } else if (result.data.SaaSClientEditionKey == 5) {
                this.$zxClient.SetCustomCode(result.data.SaaSClientNO);
              }
            }
            this.doAfterLoginSuccess(result.data);
          }
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    doAfterLoginSuccess (token, isAlertPwd) {
      let obj = {
        mobile: this.mobile,
        SaaSClientNO: this.SaaSClientNO || ''
      };
      if (this.curLoginType != this.loginType.passWord) {
        obj.SaaSClientNO = '';
      }
      localStorage.setItem('ZXLoginInfo', JSON.stringify(obj));
      if (this.setTimeoutObjForCode) {
        clearTimeout(this.setTimeoutObjForCode);
        this.setTimeoutObjForCode = null;
      }
      doAfterLoginSuccess(token, isAlertPwd);
    },
    keyupMobile (e) {
      this.mobile = this.mobile.replace(/\D/g, '');
      if (this.mobile.length > 11) {
        this.mobile = this.mobile.substring(0, 11);
      }
    },
    // 发送验证码
    sendVerificationCode () {
      if (!this.mobile) {
        layer.alert('请输入手机号码');
        return;
      } else if (this.mobile.length != 11) {
        layer.alert('请输入11位的手机号码');
        return;
      }
      if (this.isSendding) {
        return false;
      }
      this.isSendding = true;
      GetCaptcha(this.mobile).then(result => {
        if (result.code == 0) {
          this.settime();
        } else {
          layer.alert(result.msg);
          this.isSendding = false;
        }
      }, error => {
        layer.alert(error.msg);
        this.isSendding = false;
      });
    },
    // 发送验证码倒计时（读秒）
    settime () {
      if (this.countdown == 0) {
        this.countdown = 119;
      } else {
        if (this.countdown < 120 && this.countdown > 0) {
          this.countdown--;
        } else {
          this.verificationCode = ''; // 一分钟后，验证码继续重置
          this.isSendding = false;
        }
      }
      if (this.countdown > 0) {
        this.setTimeoutObj = setTimeout(() => {
          this.settime();
        }, 1000);
      } else {
        this.isSendding = false;
      }
    },
    // 输入验证码 最大长度不超过4个
    keyupVerfiCode (e) {
      if (this.verificationCode.length > 4) {
        this.verificationCode = this.verificationCode.substring(0, 4);
      }
    },
    // 隐藏二维码的title
    mousemoveQRcode (e) {
      e.currentTarget.title = '';
    },
    // 过期二维码点击刷新二维码 重连长连接
    refreshCodeClick () {
      if (this.refreshCodeTimeNum) {
        clearTimeout(this.refreshCodeTimeNum);
        this.refreshCodeTimeNum = null;
        this.isRefreshCode = false;
      }
      this.$websocket.init('login');
      this.$bus.on(this, 'wsConnectSuceess', data => {
        this.applyLoginDistinguishCode(data);
      });
    },
    // 登录识别码申请
    applyLoginDistinguishCode (data) {
      console.log(data, 'data', this.setTimeoutObjForCode, this.refreshCodeTimeNum);
      if (this.setTimeoutObjForCode) {
        clearTimeout(this.setTimeoutObjForCode);
        this.setTimeoutObjForCode = null;
      }
      ApplyLoginDistinguishCode(data.newData).then(result => {
        // http://当前域名/scanlogin.php，后面带上相应参数，参数名与申请识别码接口返回的三个字段名相同
        let hostUrl = this.$store.getters.PCWebURL;
        let url = '';
        let fromType = this.$zxClient.IsClient ? 'DP' : 'PC';
        if (this.vBrandInfo.isOpenBrand) {
          let hrefArr = window.location.href.split('/');
          let SaaSClientNo = hrefArr[3];
          console.log(this.$store.getters.PCWebURL, 'this.$store.getters.PCWebURL', hrefArr, SaaSClientNo);
          url =
            hostUrl +
            '/scanlogin.php?client_id=' +
            result.data.client_id +
            '&timeStamp=' +
            result.data.timeStamp +
            '&distinguishCode=' +
            result.data.distinguishCode + '&SaaSClientNo=' + SaaSClientNo + '&fromType=' + fromType;
        } else {
          url =
            hostUrl +
            '/scanlogin.php?client_id=' +
            result.data.client_id +
            '&timeStamp=' +
            result.data.timeStamp +
            '&distinguishCode=' +
            result.data.distinguishCode + '&fromType=' + fromType;
        }

        if (document.getElementById('twocode')) {
          document.getElementById('twocode').innerHTML = '';
          // let qrcode = new QRCode(document.getElementById('twocode'), {
          //   width: 256,  // 二维码宽度
          //   height: 256, // 二维码高度
          //   correctLevel: QRCode.CorrectLevel.L // 容错级别，
          // });
          // qrcode.clear(); // 清除二维码
          // qrcode.makeCode(url);
          this.codeValue = url;
          console.log('applyLoginDistinguishCode:url, data.newData:', url, data.newData);
        }
        // 到达九分钟 显示过期二维码
        this.refreshCodeTimeNum = setTimeout(() => {
          if (this.refreshCodeTimeNum) {
            this.isRefreshCode = true;
          }

        }, 9 * 60 * 1000);//九分钟
        this.setTimeoutObjForCode = setTimeout(() => {
          // this.applyLoginDistinguishCode(data);
          if (this.setTimeoutObjForCode) {
            this.$websocket.wsDispose();
          }

        }, 10 * 60 * 1000);//十分钟
      });
    },
    mobileLogin (title) {
      let hrefArr = window.location.href.split('/');
      let SaaSClientNo = '';
      if (this.vBrandInfo.isOpenBrand) {
        // 品牌定制
        SaaSClientNo = hrefArr[3];
      } else {
        SaaSClientNo = '';
      }
      this.versionsHint = title;
      this.isShowMibileLogin = true;
      this.getGetBrandDownUrl(SaaSClientNo);// 获取下载版本
      console.log(this.vBrandInfo, 'vBrandInfo');
    },
    // 忘记密码弹框
    forgetPBtn () {
      this.alertPwdTitle = '忘记密码';
      this.isAlertPwd = false;
      this.showForgetPwd = true;
    },
    // 关闭忘记密码弹框
    closeForgetPwd () {
      this.showForgetPwd = false;
    },
    // 关闭 修改密码弹框 并 跳转到首页
    alertClick (pwd) {
      this.passWord = pwd;
      this.NewPassword = pwd;
      this.isAlertPwd = false;
      this.showForgetPwd = false;
      this.doAfterLoginSuccess(this.mdInfo, false);
    },
    // 登录页面 获取 电脑 电视 手机下载地址
    getGetBrandDownUrl (OLAPKey) {
      GetBrandDownUrl(OLAPKey).then(result => {
        if (this.versionsHint == '电脑端') {
          this.DesktopAPPTVDownUrl = result.data.DesktopDownUrl;
        } else if (this.versionsHint == '手机端') {
          this.DesktopAPPTVDownUrl = result.data.AppDownUrl;
        } else if (this.versionsHint == '电视端') {
          this.DesktopAPPTVDownUrl = this.vBrandInfo.isOpenBrand ? result.data.TVDownUrl : result.data.NewTVDownUrl;
        }
        console.log(result.data, 'GetBrandDownUrl', this.versionsHint, this.DesktopAPPTVDownUrl);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // App品牌定制下载
    doAppBrandDownUrl (type) {
      let hrefArr = window.location.href.split('/');
      GetBrandDownUrl(hrefArr[3]).then(result => {
        this.isShowAppQRcodeImg = true;
        this.$nextTick(() => {
          if (document.getElementById('signQRcode')) {
            this.signCodeValue = result.data.AppDownUrl;
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    doPortDownload (type) {
      console.log(type, '下载');
      if (type == 'App') {
        this.isShowAppQRcodeImg = true;
        this.$nextTick(() => {
          if (document.getElementById('signQRcode')) {
            // document.getElementById('signQRcode').innerHTML = '';
            // let qrcode = new QRCode(document.getElementById('signQRcode'), {
            //   width: 256,  // 二维码宽度
            //   height: 256, // 二维码高度
            //   correctLevel: QRCode.CorrectLevel.L // 容错级别，
            // });
            // console.log('aaaa');
            // qrcode.clear(); // 清除二维码
            // qrcode.makeCode('http://www.zhixuerj.com/download/app/zx.apk');
            this.signCodeValue = this.DesktopAPPTVDownUrl;
          }
        });
      } else if (type == 'Desktop') {
        let url = this.DesktopAPPTVDownUrl;
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        console.log(link.href);
        link.download = ''; // 重命名文件
        link.click();
        URL.revokeObjectURL(url); // 释放内存
      } else if (type == 'MB') {
        let url = this.DesktopAPPTVDownUrl;
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        console.log(link.href);
        link.download = ''; // 重命名文件
        link.click();
        URL.revokeObjectURL(url); // 释放内存
      }
    },
    closeMibileLogin () {
      this.isShowMibileLogin = false;
    },
    closeAppQRcodeImg () {
      this.isShowAppQRcodeImg = false;
    }
  },
};
</script>
<style scoped>
.login_page {
  background: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 400rem;
}
.login_words {
  position: absolute;
  margin-top: 21rem;
  /* width: 130px;
  height: 370px; */
  width: 52rem;
  height: 185rem;
  margin-left: 178px;
  animation-name: login_words_example;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}
@keyframes login_words_example {
  from {
    margin-left: 178px;
  }
  to {
    margin-left: -145rem;
  }
}
.login_words .left_words {
  /* width: 40px;
  line-height: 37px; 
  font-size: 24px;*/
  width: 10rem;
  line-height: 16.4rem;
  font-size: 9.38rem;
  font-family: 'Malgun Gothic';
  float: left;
  color: #6c6c6c;
  font-weight: 300;
}
.login_words .middle_words {
  float: left;
  width: 1px;
  height: 93%;
  margin-left: 17rem;
  background: linear-gradient(
    180deg,
    rgba(153, 153, 153, 0) 0%,
    #cccccc 52.08%,
    rgba(153, 153, 153, 0) 100%
  );
  opacity: 0.5;
}
.login_words .right_words {
  /* width: 25px;
  line-height: 37px; 
  font-size: 24px;*/
  width: 10rem;
  line-height: 14.6rem;
  font-size: 9.38rem;
  font-family: 江城圆体;
  float: right;
  padding-top: 39rem;
  color: #6c6c6c;
  text-align: center;
  font-weight: 300;
}
.login_page_box {
  /* width: 500px; */
  width: 196rem;
  margin: 0px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 70rem;
}
.login_from {
  width: 168rem !important;
  height: 133rem !important;
  border-right: 0 !important;
  padding-right: 19rem !important;
}
.login_page_content {
  background-color: rgba(255, 255, 255, 0.9) !important;
  height: 202rem !important;
  padding-left: 25rem !important;
  padding-top: 17rem !important;
  box-shadow: 0px 6px 15px rgba(199, 207, 218, 0.35) !important;
  border-radius: 7rem !important;
  opacity: 0;
  animation-name: login_page_example;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}
@keyframes login_page_example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.mobile_to_login {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
  z-index: 5;
  line-height: 30rem;
  padding: 10rem;
}
.mobile_to_login .button {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
}
.logo_bnt {
  margin: 0 auto;
  width: 136rem;
  text-align: center;
}
.logo_bnt img {
  width: 41.8rem;
  height: 41.8rem;
}
.login_btn {
  width: 150rem;
  height: 22rem;
  line-height: 22rem;
  margin-top: 19rem !important;
  font-size: 7rem !important;
}
.login_QRcode {
  border-top: none;
  width: 151rem !important;
  margin-left: 0px !important;
  padding-top: 0px !important;
}
.login_QRcode >>> img {
  width: 256px !important;
  height: 256px !important;
}
.login_QRcode_box {
  width: 0px !important;
  padding-top: 25rem !important;
}
.login_from_tab {
  font-size: 7rem !important;
}
.login_from_tab > div {
  width: 31rem !important;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  color: #666;
}
.login_from_tab > div:hover {
  color: #262626 !important;
  font-weight: 500;
}
.login_from_tab {
  justify-content: space-between;
  border-bottom: none !important;
  margin-bottom: 10px;
  line-height: 20rem !important;
}
.login_from_tab .opt {
  border-bottom: none !important;
  color: #262626 !important;
  font-weight: 500;
}
.login_from_tab .opt::after {
  content: '';
  width: 45%;
  height: 1.5rem;
  display: block;
  margin: 0 auto;
  border-bottom: 2rem solid var(--borderColor);
  border-radius: 20rem;
}
.login_from_input {
  border-bottom: none !important;
  height: 28.6rem !important;
  line-height: 13rem !important;
  font-size: 6rem !important;
  padding-top: 6rem !important;
}
.login_from_input input:focus {
  border: 1px solid var(--borderColor);
}
.login_from_input input {
  width: 150rem !important;
  background: var(--inputBackground);
  border-radius: 3rem;
  padding-left: 8rem;
  line-height: 22rem !important;
  height: 22rem !important;
  margin-bottom: 2rem;
}
.code_font_red {
  position: absolute;
  margin-top: 8rem;
  right: 27rem;
  padding: 1px 16px;
  border: 1px solid var(--borderColor);
  border-radius: 3rem;
  font-size: 5rem;
  font-weight: 400;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  cursor: pointer;
}
.frame_QRcode {
  width: 88rem !important;
  height: 96rem;
  margin-top: -10rem;
  background: #ffffff;
  border: 1px solid #e7e7e9;
  box-shadow: 0px 4px 30px #e7e7e9;
  border-radius: 8rem;
  box-sizing: border-box;
}
#twocode {
  display: inline-block;
  margin-top: 8rem;
  width: 72rem;
  height: 72rem;
}
.WXorAPP {
  margin-top: 2rem;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  line-height: 22px;
  color: #333333;
}
.WXorAPP_text {
  width: 149rem;
  margin: 0 auto;
  color: #333333;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  margin-top: 133rem;
  font-size: 5rem;
}
.forget_pwd {
  margin-top: 5rem;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 6rem;
  color: #0088ff;
  cursor: pointer;
  width: 30rem;
}
.tc {
  text-align: initial !important;
}
.download_package_one,
.download_package_two {
  width: 93%;
  margin: auto;
}
.zero_one,
.zero_two {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #ebedf0;
}
.download_text {
  display: block;
  margin-top: -20px;
  margin-left: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.download_MB_text {
  margin-left: 10px;
  margin-top: 2px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  width: 335px;
  display: block;
  float: right;
}
.download_PC {
  width: 100%;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 8px;
  height: 53px;
  text-align: center;
  line-height: 53px;
  margin-top: 15px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3498db;
  margin-bottom: 40px;
}
.download_APP {
  width: 100%;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 8px;
  height: 72px;
  margin-top: 15px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
}
.contact_service {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-bottom: 20px;
  margin-top: 10px;
}
.APP_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 15px;
  padding-top: 12px;
}
.app_port_download {
  right: 54px !important;
  width: 40px !important;
  height: 26px !important;
  top: 50px !important;
}
.desktop_port_download {
  top: 48px !important;
}
.passwordTitle {
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
#qrcode_vue {
  position: absolute;
  margin-left: 38rem;
  margin-top: -3rem;
}
#qrcode_vue >>> canvas {
  width: 75rem !important;
  height: 75rem !important;
}
.l_w_hide {
  top: 39rem;
  width: 2.3rem;
  height: 6rem;
  left: 2.7rem;
  background: #fff;
  position: absolute;
}
.bj_code_box {
  position: absolute;
  width: 76rem !important;
  height: 76rem !important;
  margin-left: 38rem;
  margin-top: -3rem;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.refresh_icon {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255);
  background-image: url(../../../public/image/refresh_blue.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15rem;
}
.expire_code_text {
  color: #000;
  font-size: 7rem;
  margin-top: 3rem;
}
</style>