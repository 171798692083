<template>
  <div class="defult_dialog_searchform_cw">
    <!-- 搜索框 -->
    <div class="student_search_form_cw">
      <div class="student_search_form_cw_name">陈大全</div>
      <input type="text"
             class="search_input"
             placeholder="请输入课件包名字"
             v-model="searchText"
             @input="updateVal($event)"
             @change="changeVal($event)">
      <span class="remove_btn"
            style="right:70px"
            v-show="searchText"
            @click.stop="clearSearText"></span>
    </div>
    <div @click.stop="search()">
      <button class="btn_light_blue_search">搜索</button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      searchText: ''
    }
  },
  props: {
    value: [String, Number]
  },
  methods: {
    changeVal (e) {
      this.$emit('change', e.target.value);
    },
    updateVal (e) {
      console.log(e.target.value, 'e.target.value')
      this.$emit('input', e.target.value);
    },
    clearSearText () {
      this.searchText = ''
      this.$emit("clearSearch")
    },
    search () {
      this.$emit("search", this.searchText)
    }
  }
}
</script>
<style scoped>
/*---------------------------- 搜索表单弹出层 -----------------------------*/

/* 搜索框 */

.defult_dialog_searchform_cw {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 225.81rem;
  height: 21.84rem;
  opacity: 1;
  border-radius: 3.12rem;
  background: rgba(255, 255, 255, 1), rgba(243, 245, 243, 1), rgba(0, 0, 0, 1);
  background-blend-mode: normalnormalnormal;

  border: 0.39rem solid rgba(255, 96, 0, 1);
  /* padding: 6.24rem 7.8rem 3.9rem; */
  /* box-shadow: 0 2px 4px 0px #e7e7e7; */
  /* background-color: #fff; */
  /* border-radius: 8px; */
  overflow: hidden;
  position: relative;
  background: #fff;
}

/* 学生搜索 */

.student_search_form_cw {
  padding-left: 3.12rem;
  border-radius: 2.34rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  /* border: 0.39rem solid #ececec; */
}
.student_search_form_cw_name {
  font-size: 7.02rem;
  line-height: 21.84rem;
  padding-left: 7.02rem;
  width: 38rem;
  color: rgba(51, 51, 51, 1);
}
.student_search_form_cw_name::after {
  content: '';
  border-right: 1px solid #ccc;
  width: 0.39rem;
  height: 13.26rem;
  margin-left: 4.9rem;
  margin-top: 3.9rem;
}
.student_search_form_cw input {
  font-size: 7.02rem;
  height: 100%;
  width: 85%;
  padding-right: 25.74rem;
  padding-left: 14.7rem;
  background: transparent url(../../../../public/image/search_icon01.png)
    no-repeat 3.9rem center;
  background-size: 6.63rem;
}

.btn_light_blue_search {
  position: absolute;
  border-radius: 3.12rem;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(
      225.5deg,
      rgba(255, 96, 0, 1) 0%,
      rgba(255, 137, 0, 1) 100%
    ),
    rgba(0, 0, 0, 1);
  width: 28.86rem;
  height: 15.6rem;
  font-size: 7.02rem;
  padding-top: 3.4rem;
  line-height: 0;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 3.12rem;
  bottom: 3.12rem;
  background-color: transparent;
}

.defult_dialog_searchform_cw .remove_btn {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
  display: inline-block;
  width: 6.63rem;
  height: 6.63rem;
  background-image: url(../../../../public/image/dialog_del_icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 6.63rem;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>