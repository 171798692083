<template>
  <!-- 编辑行表-->
  <div class="form_list_content">
    <div class="flex">
      <div class="form_list_add"
           @click="editRowTable">
        {{item.rowType =='minus'?'-':'+'}}
      </div>
    </div>
  </div>
</template> 
<script>

export default {
  data () {
    return {
      fieldsList: [], // 整行字段列表信息
      rowTableList: [], // 行表列表
      isFirstRow: true // 第一个行表信息
    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object, // 字段项
    rowWidth: Number // 总行宽
  },
  created () {
    this.loadData();
  },
  watch: {
    'categoryItem.FormFields': {
      handler (n, o) {
        this.loadData();
      },
      deep: true
    }
  },
  computed: {

  },
  methods: {
    editRowTable () {
      console.log(this.item, '编辑行表');
      if (this.item.rowType == 'minus') {
        this.categoryItem.FormFields = this.categoryItem.FormFields.filter((obj, index) => {
          return !obj.rowCount || this.item.rowCount != obj.rowCount;
        });
      } else {
        this.fieldsList.forEach((o, i) => {
          let item = {
            DefaultValue: '',
            FieldDescription: o.FieldDescription,
            FieldName: this.item.FieldName, // 新增时，取行表的字段名
            FieldTypeKey: o.FieldTypeKey,
            FieldsThat: o.FieldsThat,
            FormCategoryName: o.FormCategoryName,
            Length: o.Length,
            OLAPKey: this.categoryItem[this.categoryItem.FormFields.lenth - 1] + i,
            ParentName: o.ParentName,
            ShowTypeKey: o.ShowTypeKey,
            TemplateKey: o.TemplateKey,
            Unit: o.Unit,
            WaterMark: o.WaterMark,
            isOpt: false,
            rowCount: (this.rowTableList.length + 1) + '行',
            type: o.type };
          this.categoryItem.FormFields.push(item);
        });
      }
    },
    loadData () {
      let rowIndex = 0;// 获取第一个默认行表的位置
      this.rowTableList = this.categoryItem.FormFields.filter((obj, index) => {
        if (this.isFirstRow && obj.ShowTypeKey == 6) { // 行表为6
          this.isFirstRow = false;
          rowIndex = index + 1;
        }
        return obj.ShowTypeKey == 6;
      });
      if (rowIndex > 0) {
        this.fieldsList = this.$utils.parseJson(this.categoryItem.FormFields.slice(0, rowIndex));
        this.categoryItem.FormFields.forEach((o, i) => {
          if (o.ShowTypeKey == 6) {
            if (this.rowTableList[0].OLAPKey == o.OLAPKey) {
              o.rowType = 'add';
            } else {
              o.rowType = 'minus';
            }
          }
        });
      }
      // console.log('编辑行表:', rowIndex, this.fieldsList, this.rowTableList, this.categoryItem.FormFields);
    }
  }

};
</script>

<style>
</style>