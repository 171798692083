<template>
  <!-- 作业批改列表 -->
  <div class="" style="height：700px;overflow-y: scroll;">
    <!-- 作业批改头部标题 -->
      <div class="JXT_dynamic_list homework_publish_title_box">
        <div class="JXT_dynamic_content">
          <div class="dynamic_content_body JXT_inform_box pr">
              <browse-item-head :headBtnType="'icon'"
                                :dataInfo="taskCorrectInfo"
                                @doHeadBrowseButClick="doHeadBrowseButClick"></browse-item-head>
          </div>
        </div>
      </div>
    <div>
      <div class="">
        <div class="JXT_homework_nav">
          <tabItem v-for="(item,key) in tabList"
                   :key="key"
                   :item="item"
                   @onClickSwitch="tabClickAfter">
          </tabItem>
        </div>
        <!-- 待批改 -->
        <div v-if="tabList[0].isActive==true">
          <div v-if="tabList[0].num>0" class="JXT_dynamic_ul">
            <await-correct-item v-for="(item,key) in taskCorrectInfo.StudentHomeWorkList"
                                :key="key"
                                :taskCorrectInfo="taskCorrectInfo"
                                :studenttaskItem="item"
                                @doEditTaskClick="doAfterEditTaskClick"></await-correct-item> 
          </div>
          <div class="index_JXT_noData"
               v-else>
            <span class="index_JXT_noData_text">暂无数据</span>
          </div>
        </div>
        <!-- 已批改 -->
        <div v-else-if="tabList[1].isActive==true">
          <div v-if="tabList[1].num>0" class="JXT_dynamic_ul">
            <already-correct-item v-for="(item,key) in taskCorrectInfo.StudentHomeWorkList"
                                :key="key"
                                :taskCorrectInfo="taskCorrectInfo"
                                :studenttaskItem="item"
                                @doEditTaskClick="doAfterEditTaskClick"></already-correct-item>
          </div>
          <div class="index_JXT_noData"
               v-else>
            <span class="index_JXT_noData_text">暂无数据</span>
          </div>
        </div>
        <!-- 未提交 -->
        <div v-else-if="tabList[2].isActive==true">
          <div class="JXT_homework_student"
               v-if="tabList[2].num>0">
            <div class="homework_student_ul">
              <not-submit-item v-for="(item,key) in vNotSubmitStudentList"
                               :key="key"
                               :studentItem="item"></not-submit-item>
              <div class="homework_student_list"></div>
            </div>
          </div>
          <div class="index_JXT_noData"
               v-else>
            <span class="index_JXT_noData_text">暂无数据</span>
          </div>
        </div>
        <!-- 全部学生 -->
        <div v-else-if="tabList[3].isActive==true">
          <div class="JXT_homework_student"
               v-if="tabList[3].num>0">
            <div class="homework_student_ul">
              <all-student-item v-for="(item,key) in taskCorrectInfo.StudentList"
                                :key="key"
                                :studentItem="item"></all-student-item>
              <div class="homework_student_list"></div>
            </div>
          </div>
          <div class="index_JXT_noData"
               v-else>
            <span class="index_JXT_noData_text">暂无数据</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetHomeWorkDetails } from '../../../../API/workbench';
import browseItemHead from '../../common-component/browse-box/browse-item-head';
import tabItem from '../../common-component/receive-student/tab-item';
import awaitCorrectItem from './await-correct-item';
import alreadyCorrectItem from './already-correct-item';
import notSubmitItem from './not-submit-item';
import allStudentItem from './all-student-item';
// import taskDetailItem from './task-detail-item';
export default {
  data () {
    return {
      taskCorrectID: 0,
      taskCorrectInfo: {},
      tabList: [
        {
          title: '待批改', // await-correct-item
          type: '1', // 类型
          num: 0,
          isActive: true

        },
        {
          title: '已批改',  // already-correct-item
          type: '2', // 类型
          num: 0,
          isActive: false
        },
        {
          title: '未提交', // not-submit-item
          type: '3', // 类型
          num: 0,
          isActive: false
        },
        {
          title: '全部学生', // all-student-item
          type: '4', // 类型
          num: 0,
          isActive: false
        }
      ]
    };
  },
  props: {
    taskID: { // 修改的作业信息
      type: [String, Number],
      default: null
    }
  },
  components: {
    browseItemHead,
    tabItem,
    awaitCorrectItem,
    alreadyCorrectItem,
    notSubmitItem,
    allStudentItem
    // taskDetailItem
  },
  watch: {

  },
  computed: {
    // 未提交学生
    vNotSubmitStudentList () {
      let list = this.taskCorrectInfo.StudentList.filter(obj => { return obj.TasksStatus == 0; });
      return list;
    }
  },
  created () {
    if (Number(this.taskID) > 0) {
      this.taskCorrectID = this.taskID;
      this.getHomeWorkDetails(this.taskCorrectID);
    }
  },
  mounted () {
    this.$dialog.register(this, ['taskDetail']);
  },
  activated () {
    // console.log('获取作业批改详情');
  },
  methods: {
    // 获取作业批改详情
    getHomeWorkDetails (taskCorrectID) {
      GetHomeWorkDetails(taskCorrectID).then(result => {
        console.log(result.data, '获取作业批改详情');
        this.taskCorrectInfo = result.data;
        this.tabList[0].num = this.taskCorrectInfo.StudentHomeWorkList.filter(obj => { return obj.TasksStatus == 0; }).length;
        this.tabList[1].num = this.taskCorrectInfo.StudentHomeWorkList.filter(obj => { return obj.TasksStatus == 1; }).length;
        this.tabList[2].num = this.taskCorrectInfo.StudentList.filter(obj => { return obj.TasksStatus == 0; }).length;
        this.tabList[3].num = this.taskCorrectInfo.StudentList.filter(obj => { return obj.TasksStatus != 3; }).length;
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 头部操作按钮(作业详情)
    doHeadBrowseButClick () {
      console.log(this.taskCorrectInfo.ID, '作业详情');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '作业详情',
        moduleName: 'taskDetail',
        data: { taskData: {taskID: this.taskCorrectInfo.ID} }
      });
    },
    taskDetailsEdit (submitBtnName) {
      this.$emit('taskDetailsEdit', submitBtnName);
    },
    // 切换批改作业类型
    tabClickAfter (item) {
      this.tabList.forEach(o => {
        if (item.type == o.type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
    },
    // 批改作业后的回调
    doAfterEditTaskClick () {
      if (Number(this.taskCorrectID) > 0) {
        this.getHomeWorkDetails(this.taskCorrectID);
        this.$bus.emit('AfterUpdateSubmitHomeTask');
      } else {
        console.log('批改作业后的回调异常：', this.taskCorrectID);
      }
    }
  }
};
</script>

<style>
</style>