<template>
  <div class="course_list pr">
    <div class="course_list_sort">{{keyIndex+1}}</div>
    <div class="course_list_text"
         v-html="$utils.translationSchoolText(item.Content)">

    </div>
    <!-- <div class="course_list_btn">
      课评
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number
  }
};
</script>

