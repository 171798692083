<template>
  <div class="WKT_course_info_list">
    <div class="WKT_course_info_block">
      <div class="WKT_course_info_name "
           :class="vHot">
        <span>{{item.CourseKeyValue}}</span>
      </div>
      <div class="WKT_course_info_text"
           :id="vCourseInfoTestID"
           v-if="isShowAll">
        <span class="WKT_course_content"
              :id="vCourseContentID">{{item.CourseIntro}}</span>
        <div class="WKT_course_info_text_btn"
             v-if="isShowOpenBtn"
             @click.stop="isShowOpenBtn=false,isShowPutItAwayBtn = true,isShowAll=false">
          展开
        </div>
      </div>
      <div class="WKT_course_info_text_unfold"
           :id="vCourseInfoTestID"
           v-if="isShowPutItAwayBtn">
        <span class="WKT_course_content"
              :id="vCourseContentID">{{item.CourseIntro}}</span>
        <div class="WKT_course_info_text_btn"
             @click.stop="isShowOpenBtn=true,isShowPutItAwayBtn = false,isShowAll=true">
          收起
        </div>
      </div>
      <div class="WKT_course_info_imgList">
        <enable-item-img v-for="(imgItem,key) in item.ImgList"
                         :key="key"
                         :imgItem="imgItem">
        </enable-item-img>
      </div>
      <div class="WKT_course_info_date">
        <span v-if="item.ClassTime">上课时间：{{item.ClassTime}}</span>
      </div>
    </div>
    <operation-btn :item="item"
                   :dataList="dataList"
                   :isShowSetTop="true"
                   @promote="promote"
                   @decline="decline"
                   @edit="edit"
                   @remove="remove"
                   @setTop="setTop"></operation-btn>
  </div>
</template>
<script>
import operationBtn from '../common/operation-btn';
import enableItemImg from './enable-item-img';
export default {
  data () {
    return {
      isShowAll: true, // 默认显示全部
      isShowOpenBtn: false, // 展开
      isShowPutItAwayBtn: false // 收起
    };
  },
  components: {
    operationBtn, enableItemImg
  },
  props: {
    item: Object,
    index: Number,
    dataList: {
      type: Array,
      default: []
    }
  },
  computed: {
    vHot () {
      return this.index <= 1 ? 'hot_icon' : '';
    },
    vCourseIntro () {
      if (this.item.CourseIntro.length > 60) {
        if (this.showMore) {
          return this.item.CourseIntro;
        } else {
          return this.item.CourseIntro.substring(0, 60);
        }
      } else {
        return this.item.CourseIntro;
      }
    },
    vCourseInfoTestID () {
      return 'WKT_course_info_text_' + this.item.CourseKey + '_' + this.index;
    },
    vCourseContentID () {
      return 'WKT_course_content_' + this.item.CourseKey + '_' + this.index;
    }
  },
  watch: {
    'item.CourseIntro': {
      handler (c, o) {
        if (c) {
          this.caculationContentHeight();
        }
      }
    }
  },
  mounted () {
    this.caculationContentHeight();
  },
  methods: {
    caculationContentHeight () {
      setTimeout(() => {
        if (document.getElementById(this.vCourseInfoTestID)) {
          const contentHeight = document.getElementById(this.vCourseInfoTestID).scrollHeight; // 内容告诉-padding
          const viewHeight = document.getElementById(this.vCourseInfoTestID).offsetHeight; // 可视高度
          const innerText = document.getElementById(this.vCourseContentID).innerText; // 渲染内容
          console.log(contentHeight, viewHeight, 'contentHeight,viewHeight');
          console.log('innerText:' + innerText, 'CourseIntro:' + this.item.CourseIntro);
          if (!innerText) { // 拿到的innerText与实际绑定的内容不等，说明数据渲染还没有完成，则在等200毫秒
            this.caculationContentHeight();
            return;
          }
          if (contentHeight <= viewHeight) {
            this.isShowOpenBtn = false;
          } else {
            console.log(this.vCourseInfoTestID, 'caculationContentHeight', contentHeight, viewHeight);
            this.isShowOpenBtn = true;
          }
        }
      }, 200);
    },
    // 上升
    promote (item, index) {
      this.$emit('moveIndex', item, index);
    },
    // 下降
    decline (item, index) {
      this.$emit('moveIndex', item, index + 3);
    },
    // 修改
    edit (item) {
      this.$emit('edit', item);
    },
    // 删除
    remove (item) {
      this.$emit('remove', item);
    },
    setTop (item, index) {
      this.$emit('moveIndex', item, index);
    }
  }
};
</script>