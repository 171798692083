<template>
  <div class="from_list"
       :class="required?'from_list_required':''">
    <div class="mendian_backstage_date">
      <div class="date_title">{{fromTitle}}</div>
      <div class="single_date_box"
           :class="{border_blue:vIsBorder}">
        <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
          <input type="text"
                 autocomplete="off"
                 name=""
                 placeholder="请选择"
                 v-model="vdateTime"
                 readonly
                 class="el-input__inner">
          <input-date class="mendian_backstage_date_input_date"
                      :formTitle="''"
                      ref="inputDate"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      @blurEvent="blurEvent()"
                      @focusEvent="focusEvent()"
                      @changeDate="changeStartDay"></input-date>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isDate01: false,
      isDate02: false,
      newDateTime: ''
    };
  },
  props: {
    fromTitle: String,
    dateTime: String,
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    required: {//  标题 （选填）
      type: Boolean,
      default: false
    }
  },
  computed: {
    vdateTime () {
      if (this.newDateTime) {
        return this.$utils.formatDateToLine(Date.new(this.newDateTime));
      } else {
        return '';
      }
    },
    vIsBorder () {
      if (this.isDate01 || this.isDate02) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    // 结束时间结束时间
    'dateTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.dateTime) {
          this.resetTime();
        }
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.dateTime, 'dateTime');
    this.newDateTime = this.dateTime;
  },
  methods: {
    resetTime () {
      console.log('resetTime');
      this.newDateTime = '';
      this.$refs.inputDate.resetDate();
    },
    changeStartDay (date) {
      this.newDateTime = date;
      this.$emit('changeDate', this.newDateTime);
    },
    focusEvent (type) {
      this.isDate01 = true;
    },
    blurEvent (type) {
      this.isDate01 = false;
    }

  }
};
</script>