<template>
  <div>
    <!-- 离校预警数据列表 -->
    <div class="">
      <div class="school_warning content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30">
        <div class="population">
          <div class="population_left">
            <span class="">长期未到校上课学生</span>
            <span class="">{{VstatisticsCount}}</span>人
          </div>
        </div>

        <input-search-contain-btn v-model="searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @onSearchEvent="inputSearch"></input-search-contain-btn>

        <div class="new_table_list_box"
             v-if="leaveSchoolWarning.PageDataList.length>0">
          <div class="new_table_list_thead">
            <div style="width: 89px;"
                 class="new_table_list_th style_gray tl">学生</div>
            <div style="width: 126px;padding-left:23px"
                 class="new_table_list_th style_yellow tl">手机号</div>
            <div style="width: 175px;padding-left:45px"
                 class="new_table_list_th style_red tl">未到校天数</div>
          </div>
          <div class="leave_school_warning new_table_list_tbody"
               @scroll="scrollTOP">
            <div class="new_table_list_tr"
                 v-for="(item,index) in leaveSchoolWarning.PageDataList "
                 :key="index"
                 @click.stop="selectAmendEvent(item,$event)">
              <div class="new_table_list_td tl font_blue"
                   style="width: 89px;"
                   @click.stop="goStudentFile(item)">{{item.StudentKeyValue}}</div>
              <div class="new_table_list_td tl"
                   style="width: 126px;padding-left:23px">
                <div class="school_warning_content_center">{{$utils.phoneModulePower(item.CustomerPhoneName)||'--'}}</div>
              </div>
              <div class="new_table_list_td tl"
                   style="width: 175px;padding-left:45px;display: flex;">
                <span class="font_red">{{item.AwaySchoolDays}}</span>天
                <div class=""  @click.stop="doWeChatTemplateShow(item)"
                   :class="Number(item.SubscribeKey)>0?'td_WX_open':'td_WX_close'"
                   style="margin-left:5px"></div>
              </div>
              <div class="new_table_list_arrow"></div>
            </div>
            <div class="new_table_list_bottom"
                 v-if="isLoadEnd">
              <span class="loading_btn">加载中...</span>
            </div>
            <div class="new_table_list_bottom"
                 v-if="noMore">
              <span class="font_gray">已显示全部</span>
            </div>
          </div>

        </div>
        <div class="course_block_nodata content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30"
             style="padding-bottom:20px;"
             v-else>
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
      <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                            :dropDownList="popUpList"
                            :positionLeft="positionLeft"
                            :positionTop="positionTop"
                            @onClick="doAfterClick"></pop-up-drop-down-box>

    </div>
    <!-- 微消息按钮 -->
    <div v-show="tinyMessageShow">
      <tinyMessage :microMessageDialogVisible="tinyMessageShow"
                   :addresseeItem="studentItem"
                   @closeMicroMessage="closeMicroMessage"
                   @ButtonEvent="ButtonEvent"></tinyMessage>
    </div>
  </div>
</template>
<script>
import { getLeaveWarning, getWarningIgnore } from '../../../API/workbench.js';

import tinyMessage from '../../common/tiny-message';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
export default {
  props: ['GOwarningDetailsList', 'callBackFunction'],
  data () {
    return {
      leaveSchoolWarning: {
        PageDataList: [],
        Total: 0
      }, // 离校预警
      PageMax: 0,
      PopUpShow: false, // 弹出层选择菜单
      studentItem: {}, // 操作按钮选择对象   (收件人)
      leaveSchoolWarningShow: true, // 选择离校预警详情列表页面是否显示
      tinyMessageShow: false, // 选择微消息列表页面是否显示
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      searchText: '',
      isLoadEnd: false, // 是否加载完
      noMore: false, // 是否没有数据
      isShowMainForm: false, // 显示。
      popUpList: [
        { name: '微消息', type: 'message', isShow: true, ModulePowerKey: '' },
        { name: '忽略', type: 'ignore', isShow: true }
      ],
      positionLeft: 0,
      positionTop: 0,
      isShowAlertDialog: false
    };
  },
  components: {
    tinyMessage, popUpDropDownBox
  },
  created () {
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlertDialog = false;
      }
    });
    this.doShowPopupShowClick();
  },
  computed:{
    // 门店是否托管
    vWeChatPattern () {
      if (this.$store.getters.SaaSClientInfo.WktInfo && this.$store.getters.SaaSClientInfo.WktInfo.PatternKey == 2 && this.$store.getters.SaaSClientInfo.IsCommonTrusteeshipKey == 1) { // 托管模式
        return true;
      } else {
        return false;
      }
    },
  },
  mounted () {
    this.$dialog.register(this, ['studentFile','weChatTemplate']);
  },
  watch: {},
  methods: {
    // 微信模板通知
    doWeChatTemplateShow (item) {
      if (this.vWeChatPattern) { // 托管模式
        layer.alert('非自有公众号, 不能发送通知');
      } else if (Number(this.$utils.getSaaSClientSet(47)) == 0) {
        layer.alert('该通知开关未开启');
      } else if (item.SubscribeKey == 0) {
        // layer.alert('非自有公众号, 不能发送通知');
      } else {
        this.$dialog.open(this, {
          name: '课时不足提醒',
          routerName: this.$route.name,
          moduleName: 'weChatTemplate',
          data: { StudentKey: item.StudentKey, eventId: 37 }
        });
      }
      console.log(this.$store.getters.SaaSClientInfo, '微信模板显示');
    },
    // 打开学生档案.
    goStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.leaveSchoolWarning.PageDataList = [];
      this.leaveSchoolWarning.Total = 0;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchText = '';
      this.$emit('chgCallBackFunction');
      this.isShowMainForm = false;
      this.isShowAlertDialog = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      document.body.scrollTop = 0;
      this.updatewarningList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.isShowAlertDialog = false;
      let ETarget = e.target;
      console.log(ETarget.scrollHeight, ETarget.clientHeight, ETarget.scrollTop);
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        this.updatewarningList();
      }
    },
    // 点击搜索
    inputSearch () {
      this.noMore = false;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = this.searchText;
      this.leaveSchoolWarning.PageDataList = [];
      this.leaveSchoolWarning.Total = 0;
      this.updatewarningList();
    },
    rewardData () {
      this.leaveSchoolWarning.PageDataList = [];
      this.searchObj.pageIndex = 0;
      // this.searchObj.searchText = '';
      this.noMore = false;
      this.updatewarningList();
      setTimeout(() => {
        this.$emit('afterSucces');
      }, 500);
    },
    LastSignTime (item) {
      return (
        this.$utils.formatDateStr(item, 'MM') +
        '-' +
        this.$utils.formatDateStr(item, 'dd')
      );
    },
    // 返回页面
    returnEvent () {
      this.$router.back(-1);
    },
    //  显示弹出层
    selectAmendEvent (item, e) {
      if (Number(item.SubscribeKey) == 1) {
        this.popUpList[0].isShow = true;
      } else {
        this.popUpList[0].isShow = false;
      }
      let scrollTop = document.getElementsByClassName('leave_school_warning')[0].scrollTop;
      this.positionLeft = 322;
      this.positionTop = e.currentTarget.offsetTop - scrollTop + 32;
      this.isShowAlertDialog = true;
      this.studentItem = item;
      this.PopUpShow = true;
      document.body.scrollTop = 0;
    },
    // 取消弹出层
    cancelAmendEvent () {
      this.PopUpShow = false;
    },
    // 刷新 离校预警
    updatewarningList () {
      this.isLoadEnd = true;
      getLeaveWarning(this.searchObj).then(result => {
        result.data.PageDataList.forEach(o => {
          this.leaveSchoolWarning.PageDataList.push(o);
        });
        this.leaveSchoolWarning.PageDataList.sort((x, y) => {
          return parseInt(x.AwaySchoolDays) - parseInt(y.AwaySchoolDays);
        });
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.leaveSchoolWarning.Total = result.data.Total;
        this.isLoadEnd = false;
      });
    },
    // // 微消息接口
    tinyMessageEvent () {
      this.leaveSchoolWarningShow = false;
      this.tinyMessageShow = true;
      this.microMessageDialogVisible = true;
    },
    // // 关闭微消息
    closeMicroMessage () {
      this.tinyMessageShow = false;
      this.$emit('afterSucces');
    },
    // 推送成功关闭微消息
    ButtonEvent (Val) {
      console.log(Val, 'ButtonEvent');
      if (Val == false) {
        this.tinyMessageShow = false;
        this.PopUpShow = false;
        this.leaveSchoolWarningShow = true;
      } else {
        this.tinyMessageShow = false;
        this.PopUpShow = false;
        this.leaveSchoolWarningShow = true;
        layer.alert('推送成功');
      }
      this.$emit('afterSucces');
    },
    // 忽略接口
    overlookEvent () {
      let title =
        this.studentItem.StudentKeyValue +
        '离校' +
        this.studentItem.AwaySchoolDays +
        '天';
      getWarningIgnore(4, parseInt(this.studentItem.StudentKey), title).then(
        result => {
          var length = this.leaveSchoolWarning.PageDataList.length;
          for (var i = 0; i < length; i++) {
            if (this.leaveSchoolWarning.PageDataList[i] == this.studentItem) {
              if (i == 0) {
                this.leaveSchoolWarning.PageDataList.shift(); // 删除并返回数组的第一个元素
              } else if (i == length - 1) {
                this.leaveSchoolWarning.PageDataList.pop(); // 删除并返回数组的最后一个元素
              } else if (i > 0) {
                this.leaveSchoolWarning.PageDataList.splice(i, 1); // 删除下标为i的元素
              }
            }
          }
          this.cancelAmendEvent();

          this.rewardData();
        }
      );
    },
    doAfterClick (item) {
      switch (item.type) {
        case 'message':
          if (Number(this.$utils.getSaaSClientSet(49)) == 0) {
            layer.alert('该通知开关未开启');
            return;
          }
          this.tinyMessageEvent();
          break;
        case 'ignore':
          this.overlookEvent();
          break;
        case 'cancel':
          break;
        default:
          break;
      }
      this.isShowAlertDialog = false;
    }
  },
  computed: {
    // 离校预警 (人数)
    VstatisticsCount () {
      if (this.vWarmingStatisical) {
        return this.vWarmingStatisical;
      } else {
        return 0;
      }
    },
    vWarmingStatisical () {
      return this.$store.getters.getWarmingStatisical.CountLeaveSchoolNum;
    }
  }
};
</script>
<style scoped>
/* 刷新按钮样式 */
.update_img {
  opacity: 0.5;
  position: relative;
  left: 4rem;
  width: 0.36rem;
  height: 0.36rem;
}
</style>

