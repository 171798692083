<template>
  <div>
    <div class="home_nav">
      <div class="home_nav_back">
        
      </div>
      <div class="home_nav_text">{{dataInfo.Title}}</div>
      <div class="home_nav_tool">
        <div class="home_nav_tool_status">签到 {{Number(courseInfo.Attence3)||0}} | 未到 {{vNoAtten}}</div>
        <div class="home_nav_tool_icon button"
             @click="changeisShowAtten"></div>
        <div class="home_nav_back_icon button"
           @click.stop="reback"></div>
      </div>
    </div>
    
  </div>
</template>
<script>

import { getTeacheCourseDetail, getCourseDetailed, getScheduleCourseOnStudentList } from '../../../API/workbench';

export default {
  data () {
    return {
    };
  },
  components: {
  },
  props: {
    dataInfo: Object,
    ScheduleCourseID: {
      type: [String, Number],
      default: '352089'
    },
    courseInfo: Object,
    studentArrangeList: Array
  },
  computed: {
    vNoAtten () {
      return Number(this.courseInfo.ArrangedNumCount) - Number(this.courseInfo.Attence3);
    }
  },
  created () {

  },
  mounted () {
    this.resgisterBusEvent();
  },
  methods: {
    // 退出教学模式
    reback () {
      this.$emit('reback');
    },
    changeisShowAtten () {
      this.$emit('showPopup');
    },

    resgisterBusEvent () {
      // this.$bus.on(this, 'AfterBatchAttendance', scheduleCourseKey => {
      //   if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
      //     this.getCourseInfo();
      //     this.loadStudentArrangeList();
      //   }
      // });
      // this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
      //   if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
      //     this.getCourseInfo();
      //     this.loadStudentArrangeList();
      //   }
      // });
      // this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
      //   if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
      //     this.getCourseInfo();
      //     this.loadStudentArrangeList();
      //   }
      // });
      // this.$bus.on(this, 'afterArrangeScheduleCourse', (scheduleCourseKey, studentKey) => {
      //   console.log('afterArrangeScheduleCourse');
      //   if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
      //     this.getCourseInfo();
      //   }
      // });
    }
  }
};
</script>