<template>
  <div class="particular_header">
    <!-- 左侧图片 -->
    <div class="header_left">
      <div class="header_left_titleFence"></div>
      <span class="header_left_titleFence_text">{{maxCoursewarePackage.BigCoursewarePackageKeyValue}}</span>
      <img :src="headImg"
           style="width:100%"
           alt="../../../../../public/image/no_logo@2x.png">
    </div>
    <!-- 右侧标题信息 -->
    <div class="header_right">
      <div :class=" role ? 'header_right_title' : 'header_right_title_tea'">
        <div class="header_right_title_textForCut">
          <!-- 大标题 -->
          <el-tooltip effect="light"
                      :content="CoursewarePackageData.MainDemoName"
                      placement="top-end">
            <div :class="TypeKey == 0 ? 'header_right_title_textForCut_text' : 'header_right_title_textForCut_text_c'">
              {{CoursewarePackageData.MainDemoName}}
            </div>
          </el-tooltip>
          <!-- 切换按钮 -->
          <div v-if="TypeKey == 0"
               class="header_right_title_textForCut_cut"
               @click="cut">
            <!-- <el-dropdown trigger="click"> -->
            <i class="el-icon-caret-bottom"
               style="color:#999"></i>
            <span class="el-dropdown-link">
              切换
            </span>
          </div>
          <div v-else-if="maxCoursewarePackage"
               class="threeLevel">

            <!-- <el-select v-model="options" :placeholder="CoursewarePackage.MainDemoName" @change="changeData()">
              <el-option v-for="item,index in maxCoursewarePackage.CoursewarePackageList" :key="item.CoursewarePackageKey"
                :label="item.CoursewarePackageKeyValue" :value="item.CoursewarePackageKey">
              </el-option>
            </el-select> -->

            <elSelectOption :selectTitle="''"
                            :dataSourceList="vChildrenPackageList"
                            :selectionKey="childrenPackageKey"
                            @changeEvent="changeData">
            </elSelectOption>
          </div>
        </div>
        <!-- 当前设备状态 -->
        <div v-if="this.$zxClient.IsClient && role"
             class="header_right_title_type">
          当前设备 ：
          <div v-if="maxCoursewarePackage.IsPCAuthKey == 0"
               class="header_right_title_type_text_err">
            <div style="color: #ff6c67">禁用</div>
          </div>
          <div v-else-if="maxCoursewarePackage.DeviceIsAuth == 0 && maxCoursewarePackage.IsPCAuthKey == 1"
               class="header_right_title_type_text_err">
            <div style="color: #ff6c67">未授权</div>
          </div>
          <div v-else-if="maxCoursewarePackage.DeviceIsAuth == 1 && maxCoursewarePackage.IsPCAuthKey == 1"
               class="header_right_title_type_text">
            <div style="color: #24da7e">已授权</div>
          </div>
        </div>
        <div v-else-if="this.$zxClient.IsClient && !role"
             class="header_right_title_teacher"
             :style="TypeKey == 0 ? 'margin: 20px 0 0' : 'margin:10px 0 0'">
          当前设备 ：
          <div v-if="maxCoursewarePackage.IsPCAuthKey == 0"
               class="header_right_title_type_text_err">
            <div style="color: #ff6c67">禁用</div>
          </div>
          <div v-else-if="maxCoursewarePackage.DeviceIsAuth == 0&& maxCoursewarePackage.IsPCAuthKey == 1"
               class="header_right_title_type_text_err">
            <div style="color: #ff6c67">未授权</div>
          </div>
          <div v-else-if="maxCoursewarePackage.DeviceIsAuth == 1 && maxCoursewarePackage.IsPCAuthKey == 1"
               class="header_right_title_type_text">
            <div style="color: #24da7e">已授权</div>
          </div>
        </div>
        <div v-else
             :class="role ? 'header_right_title_type' : 'header_right_title_teacher'"
             :style="TypeKey == 0 ? 'margin: 20px 0 0' : 'margin:10px 0 0'">
          当前设备：
          <span class="TheDesktopClient">非桌面端</span>
        </div>
        <div v-if="maxCoursewarePackage.DeviceIsAuth == 0 && this.$zxClient.IsClient&&maxCoursewarePackage.IsPCAuthKey == 1"
             :class="role ? 'header_right_title_type_authorization' : 'header_right_title_type_authorization_tea'"
             :style="TypeKey == 0 ? 'top:40px' : 'top48px'"
             @click="applyAuthorization">
          {{ role ? '授权本设备' : '申请设备授权'}}
        </div>
      </div>
      <!-- 老师按钮列表 -->
      <div v-if="role"
           :class="TypeKey == 0 ? 'header_right_teacher' : 'header_right_teacher_c'">
        <div class="header_right_teacher_list">老师：</div>
        <div class="header_right_teacher_name"
             :title="vIsAuthor?'':TeacherKeyValue">
          <!-- <div v-for="(item, index) in teacher"
               :key="index"
               class="header_right_teacher_name_text">
            <el-tooltip effect="light"
                        :content="item.TeacherKeyValue"
                        placement="top-end">
              <span>{{ item.TeacherKeyValue }}</span>
            </el-tooltip>
          </div> -->
          <span>{{vIsAuthor?'全部':TeacherKeyValue }}</span>
        </div>
      </div>
      <classBiginData @registerBusEvent='doViewCoursewareDetails'
                      @getDateRangeCoursewareStat="getDateRangeCoursewareStat"
                      :par="'par'"
                      :BigCoursewarePackageKey="CoursewarePackageData.OLAPKey"
                      :dataInfo="statistics"></classBiginData>
    </div>
  </div>
</template>
<script>
import {
  CWSPrincipalWorkbenchGetCWPTeacherList,
  CWSAuthorizedDeviceManagementAuthorizedTheDevice,
  CWSGetDateRangeCoursewareStat,
  CWSGetSelfDateRangeCoursewareStat,
  CWSTeacherWorkbenchgetDateCourseCountForTeacherCourse,
  AddInterfaceLog
} from '../../../../API/workbench';
import elSelectOption from '../../../report/common/el-select-option';
import classBiginData from '../../right-box-work-bench/home-page-details/class-bigin-data.vue';
export default {
  props: {
    CoursewarePackageData: Object, // 标题
    maxCoursewarePackage: Object, // 子课包详情
    // CoursewarePackageKey: [Number, String]
    CoursewarePackage: Object, // 传入的子课包信息
    role: Boolean, // true为校长 ，false为老师
    TeacherID: [Number, String], // 老师ID
    TypeKey: [Number, String], // 是否有子集 0-没有 2-有
    Source: [String, Number] // 数据来源 1授权课件 2 自研课件
    // particularData: Object // 上课统计
  },

  data () {
    return {
      TeacherKeyValue: '',
      childrenPackageKey: this.CoursewarePackageData.OLAPKey || '',
      childrenPackageList: [],
      teacher: [], // 老师列表
      dataInfo: {
        CoursewareKey: '', // 教案课件ID
        DeviceCode: '' // this.$zxClient.GetMCode() // 设备码
      },
      params: {
        StartTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        EndTime: this.$utils.getCurrentMonthLast(),
        TeacherKey: this.TeacherID || '',
        GradeKey: '',
        CoursewarePackageKey: ''
      },
      statistics: {},
      tipContont: '',
      options: '', // 双向绑定下拉框显示数据
      BigCoursewareKey: ''
    };
  },

  components: {
    classBiginData,
    elSelectOption
  },

  created () {
    if (this.$zxClient && this.$zxClient.IsClient) {
      this.dataInfo.DeviceCode = this.$zxClient.GetMCode();
    } else {
      this.tipContont = '非桌面端';
      this.dataInfo.DeviceCode = '';
    }
    this.gainTeacherList();
  },

  mounted () {
    console.log(this.CoursewarePackageData, 'this.CoursewarePackageData');
    // this.childrenPackageList = this.maxCoursewarePackage.CoursewarePackageList
  },

  computed: {
    vIsAuthor () {
      return this.$utils.getSaaSClientSetInfo(71).SetKey == '1';
    },
    // 大课包封面
    headImg () {
      if (this.maxCoursewarePackage && this.maxCoursewarePackage.CoverUrl) {
        if (this.maxCoursewarePackage.CoverUrl.indexOf('http') > -1) {
          return this.maxCoursewarePackage.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.maxCoursewarePackage.CoverUrl;
        }
      } else {
        return '';
      }
    },
    vChildrenPackageList () {
      console.log('vChildrenPackageList', this.maxCoursewarePackage);
      let newArr = [];
      if (this.maxCoursewarePackage && this.maxCoursewarePackage.CoursewarePackageList.length > 0) {
        this.maxCoursewarePackage.CoursewarePackageList.forEach(o => {
          let obj = {
            MainDemoName: o.CoursewarePackageKeyValue,
            OLAPKey: o.CoursewarePackageKey,
            CoursewarePackageKey: o.CoursewarePackageKey,
            CoursewarePackageKeyValue: o.CoursewarePackageKeyValue,
            ParentKey: o.ParentKey,
            ParentKeyValue: o.ParentKeyValue
          };
          newArr.push(obj);
        });
      }
      return newArr;
    }
  },

  methods: {
    // 切换按钮点击事件
    cut () {
      var list = this.maxCoursewarePackage.CoursewarePackageList;
      list.forEach(o => {
        this.$set(o, 'OLAPKey', o.CoursewarePackageKey);
        this.$set(o, 'MainDemoName', o.CoursewarePackageKeyValue);
      });
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '子课包列表',
        moduleName: 'singleSelectionList',
        data: {
          selectedKey: this.CoursewarePackageData.OLAPKey,
          dataSourceList: list,
          required: true,
          ModulePowerKey: 0
        },
        callBack: {
          onSelectedItem: (data) => {
            this.$emit('cutCourseware', data, this.params.StartTime, this.params.EndTime);
          }
        }
      });
      console.log('切换');
    },
    // 申请按钮点击事件
    async applyAuthorization () {
      console.log(this.CoursewarePackage.OLAPKey, this.dataInfo.DeviceCode, this.params.TeacherKey, '子课包详情111');
      if (this.role) {
        try {
          if (this.$zxClient.GetMInfo() === '{}') {
            layer.alert('设备信息为空');
            let interfaceLogData = {
              InterfaceName: this.$store.getters.APIURL + 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice',
              parameter: { BigCoursewarePackageKey: this.maxCoursewarePackage.BigCoursewarePackageKey },
              PostData: JSON.stringify(this.$store.getters.token),
              LogContents: '设备信息为空',
              LogTitleName: '设备信息为空',
              RefererName: window.location.href
            };
            AddInterfaceLog(interfaceLogData).then(result => {
              console.log('记录日志');
            });
          } else {
            let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
            const res = await CWSAuthorizedDeviceManagementAuthorizedTheDevice(DeviceInfo, this.maxCoursewarePackage.BigCoursewarePackageKey);
            console.log(res, '授权本设备');
            this.$zxClient.SetUCode(res.data.DeviceUCode);
            this.$emit('getCoursewarePackageDetails', this.CoursewarePackage.OLAPKey, this.dataInfo.DeviceCode, this.params.TeacherKey);
          }
        } catch (error) {
          layer.alert(error.msg);
        }
      } else {
        this.dataInfo.CoursewareKey = this.maxCoursewarePackage.BigCoursewarePackageKey;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '申请设备授权',
          moduleName: 'cwsApplyAuth',
          data: {
            dataInfo: this.dataInfo
          },
          callBack: {
            afterSuccess: (data) => {
              this.$emit('getCoursewarePackageDetails', this.CoursewarePackage.OLAPKey, this.dataInfo.DeviceCode, this.params.TeacherKey);
            }
          }
        });
      }
    },
    // 获取课件详情-老师列表
    gainTeacherList () {
      if (this.role) {
        CWSPrincipalWorkbenchGetCWPTeacherList(this.CoursewarePackage.OLAPKey).then(result => {
          console.log('获取课件详情-老师列表', result);
          console.log('this.CoursewarePackage', this.CoursewarePackage);
          console.log(this.maxCoursewarePackage, 'zzzzz');
          this.teacher = result.data;
          this.teacher.forEach((o, index) => {
            if (index == 0) {
              this.TeacherKeyValue += o.TeacherKeyValue;
            } else {
              this.TeacherKeyValue += '、' + o.TeacherKeyValue;
            }
          });
        });
      } else {
        console.log('this.CoursewarePackage', this.CoursewarePackage);
      }
    },
    // 上课统计
    getDateRangeCoursewareStat (startDate, endDate, CoursewarePackageKey) {
      this.params.StartTime = startDate;
      this.params.EndTime = endDate;
      this.params.CoursewarePackageKey = CoursewarePackageKey;
      if (this.role) {
        let api = this.Source == '1' ? CWSGetDateRangeCoursewareStat : CWSGetSelfDateRangeCoursewareStat;
        api(this.params).then(result => {
          console.log(result.data, '获取课件详情的上课统计');
          console.log(this.params, '提交课件详情的上课统计数据');
          this.statistics = result.data;
        });
      } else {
        CWSTeacherWorkbenchgetDateCourseCountForTeacherCourse(this.params).then(result => {
          console.log(result.data, '获取课件详情的上课统计_老师');
          console.log(this.params, '提交课件详情的上课统计数据_老师');
          this.statistics = result.data;
        });
      }
      // this.cwsSummList[2].value = Number(result.data.ArrivedNumCount);// 签到人次
      // if (this.params.StartTime == this.params.EndTime) {
      //   this.cwsSummList[3].value = Number(result.data.CourseCount);// 今日已上课次
      //   this.cwsSummList[4].value = Number(result.data.CourseTimeCount);// 今日上课时长
      // } else {
      //   this.cwsSummList[0].value = Number(result.data.CourseCount) || 0;// 已上课次
      //   this.cwsSummList[1].value = Number(result.data.CourseTimeCount) || 0;// 上课时长
      // }
    },
    // 打开上课统计
    doViewCoursewareDetails () {
      console.log(this.maxCoursewarePackage, this.CoursewarePackage, this.CoursewarePackageData, '数据展示', this.TeacherID, this.Source, 'Source', this.role, this.TypeKey);
      let name = '';
      if (this.TypeKey != 0) {
        if (this.maxCoursewarePackage.CoursewarePackageList.length > 1) {
          name = this.CoursewarePackage.MainDemoName;
        } else {
          name = this.CoursewarePackage.MainDemoName;
        }
      }
      if (this.$store.getters.SaaSClientEditionKey == 6) {
        if (this.role) {
          this.$dialog.open(this, {
            name: '课件使用统计',
            routerName: this.$route.name,
            moduleName: 'coursewareUseStatlist',
            data: {
              dataInfo: {
                TeacherKey: this.params.TeacherKey, //
                StartTime: this.params.StartTime,
                EndTime: this.params.EndTime,
                BigCoursewarePackageKey: this.maxCoursewarePackage.BigCoursewarePackageKey,
                CoursewarePackageKey: this.CoursewarePackageData.OLAPKey,
                BigCoursewarePackageName: this.maxCoursewarePackage.BigCoursewarePackageKeyValue,
                CoursewarePackageName: this.TypeKey == 0 ? this.CoursewarePackageData.MainDemoName : this.CoursewarePackage.ParentKeyValue,
                packageName: name
              }
            }
          });
        } else {
          this.$dialog.open(this, {
            name: '老师课件使用统计',
            routerName: this.$route.name,
            moduleName: 'classStatisticsTeacher',
            data: {
              dataInfo: {
                StartTime: this.params.StartTime,
                EndTime: this.params.EndTime,
                BigCoursewarePackageKey: this.maxCoursewarePackage.BigCoursewarePackageKey,
                CoursewarePackageKey: this.CoursewarePackageData.OLAPKey,
                BigCoursewarePackageName: this.maxCoursewarePackage.BigCoursewarePackageKeyValue,
                CoursewarePackageName: this.TypeKey == 0 ? this.CoursewarePackageData.MainDemoName : this.CoursewarePackage.ParentKeyValue,
                packageName: name
              }
            }
          });
        }
      } else {
        if (!this.TeacherID) {
          this.$dialog.open(this, {
            name: '课件使用统计',
            routerName: this.$route.name,
            moduleName: 'WebvClassStatistics',
            data: {
              dataInfo: {
                TeacherKey: '', // 老师
                TeacherKey: this.params.TeacherKey, //
                StartTime: this.params.StartTime,
                EndTime: this.params.EndTime,
                BigCoursewarePackageKey: this.maxCoursewarePackage.BigCoursewarePackageKey,
                CoursewarePackageKey: this.CoursewarePackageData.OLAPKey,
                BigCoursewarePackageName: this.maxCoursewarePackage.BigCoursewarePackageKeyValue,
                CoursewarePackageName: this.TypeKey == 0 ? this.CoursewarePackageData.MainDemoName : this.CoursewarePackage.ParentKeyValue,
                packageName: name,
                Source: this.$store.getters.AcademicMdCwType
              }
            }
          });
        } else {
          this.$dialog.open(this, {
            name: '老师课件使用统计',
            routerName: this.$route.name,
            moduleName: 'classStatisticsTeacher',
            data: {
              dataInfo: {
                StartTime: this.params.StartTime,
                EndTime: this.params.EndTime,
                BigCoursewarePackageKey: this.maxCoursewarePackage.BigCoursewarePackageKey,
                CoursewarePackageKey: this.CoursewarePackageData.OLAPKey,
                BigCoursewarePackageName: this.maxCoursewarePackage.BigCoursewarePackageKeyValue,
                CoursewarePackageName: this.TypeKey == 0 ? this.CoursewarePackageData.MainDemoName : this.CoursewarePackage.ParentKeyValue,
                packageName: name
              }
            }
          });
        }
      }
    },
    // 级联下拉框
    changeData (item) {
      console.log(item, '下拉框item');
      this.childrenPackageKey = item.OLAPKey;
      this.CoursewarePackage.MainDemoName = item.MainDemoName;
      this.CoursewarePackage.OLAPKey = item.OLAPKey;
      this.$emit('setElSelectID', item, this.params.StartTime, this.params.EndTime);
    }
  }
};
</script>

<style scoped>
.particular_header {
  display: flex;
  width: 884px;
  height: 168px;
  margin: 20px 0 20px;
}

.header_left {
  width: 300px;
  height: 168px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.header_left_titleFence {
  width: 100%;
  height: 30px;
  border-radius: 0 0 8px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
}

.header_left_titleFence_text {
  color: #fff;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
  height: 29px;
  line-height: 29px;
  text-align: center;
}

.header_right {
  flex: 1;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header_right_title {
  display: flex;
  position: relative;
}

.header_right_title_tea {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header_right_title_teacher {
  display: flex;
  align-items: center;
  color: #999;
  margin: 20px 0 0;
}

.header_right_title_textForCut {
  display: flex;
  align-items: center;
}

.header_right_title_textForCut_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}

.header_right_title_textForCut_text_c {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  width: 111px;
  color: #333333;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header_right_title_textForCut_cut {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  margin-left: 22px;
  color: #3498db;
  cursor: pointer;
}

.header_right_title_type {
  font-family: 'Arial';
  font-style: normal;
  display: flex;
  align-items: center;
  color: #999999;
  position: absolute;
  right: 0;
}

.header_right_title_type_text {
  border: 1px solid #24da7e;
  border-radius: 10px 10px 10px 1px;
  width: 48px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  background-color: #d3f8e5;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.header_right_title_type_text_err {
  border: 1px solid #ff6c67;
  border-radius: 10px 10px 10px 1px;
  width: 48px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  background-color: #ffe2e1;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.header_right_title_type_authorization {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 14px;
  color: #3498db;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 0px;
  top: 30px;
}

.header_right_title_type_authorization_tea {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 14px;
  color: #3498db;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  left: 125px;
  top: 48px;
}

.header_right_teacher {
  display: flex;
  /* align-items: center; */
  height: 35px;
  margin-top: 7px;
}

.header_right_teacher_c {
  display: flex;
  /* align-items: center; */
  height: 51px;
  margin-top: 5px;
}

.header_right_teacher_list {
  padding-top: 5px;
  color: #999;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
}

.header_right_teacher_name {
  display: flex;
  /* flex: 1; */
  width: 435px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.header_right_teacher_name_text {
  border: 1px solid #e8ebef;
  border-radius: 3px;
  width: 70px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 12px;
  margin-right: 8px;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.particular_header >>> .class_bigin_box_par {
  margin: 0px 0 0 0px;
}

.threeLevel {
  margin-left: 22px;
}

.threeLevel >>> .el-select > .el-input {
  width: 216px;
}
.threeLevel >>> .el-select > .el-input > .el-input__inner {
  color: #333333 !important;
  font-size: 14px !important;
}

.TheDesktopClient {
  border: 1px solid #ff6c67;
  border-radius: 10px 10px 10px 1px;
  width: 65px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  background-color: #ffe2e1;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
  color: #ff6c67;
}
</style>
