<template>
  <div class="class_detail_header_teacher">
    <div class="class_detail_header_title pr">
      <div class="class_detail_header_name">{{dataInfo.MainDemoName}}</div>
      <div class="class_detail_header_btn font_blue button"
           @click.stop="quitClassForTeacher">退出班级</div>
    </div>
    <div class="class_detail_header_content">
      <teacher-list :title="'老师'"
                    :dataList="dataInfo.BJTeacherList"
                    :showDel="false"
                    :showAdd="false"
                    @addItem="addClassGradeForTeacher"
                    @delItem="delClassGradeForTeacher"></teacher-list>
      <teacher-list :title="'学生'"
                    :dataList="dataInfo.BJStudentList"
                    :showDel="true"
                    :showAdd="true"
                    @addItem="showAddStudentList"
                    @delItem="delClassGradeForStudent"></teacher-list>

    </div>
  </div>
</template>
<script>
import { CWSquitClassGradeForTeacher, CWSAddClassGradeForStudent, CWSQuitClassGradeForStudent, CWSSearchPageStudentList } from '../../../../API/workbench'
import teacherList from './components/teacher-list'
export default {
  data () {
    return {

    }
  },
  components: {
    teacherList
  },
  props: {
    isTeacher: Boolean,
    dataInfo: {
      type: Object,
      default: () => {
        return {
          BJTeacherList: [],
          BJStudentList: []
        }
      }
    }
  },
  created () {

  },

  computed: {
    vToken () {
      return this.$store.getters.token
    }
  },
  methods: {
    // 老师自己退出班级
    quitClassForTeacher () {
      layer.confirm('请问是否退出该班级', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CWSquitClassGradeForTeacher(this.dataInfo.OLAPKey, this.vToken.UserID).then(result => {
                this.$emit("closeDialog")
                // this.$emit("updataData")
              }).catch(error => {
                layer.alert(error.msg)
              })
              layer.close();
            }
          }
        ]
      });
    },
    showAddTeacherList () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '选择老师',
        moduleName: 'selectTeacher',
        data: { selectedKey: 0 },
        callBack: {
          afterSuccess: (data) => { this.addClassGradeForTeacher(data); }
        }
      });
    },
    // 加老师进班
    addClassGradeForTeacher (item) {
      console.log("addClassGradeForTeacher", item)
      CWSAddClassGradeForStudent(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
        layer.alert("添加成功")
        this.$emit("updataData")
      }).catch(error => {
        layer.alert(error.msg)
      })
    },
    showAddStudentList () {
      let searchObj = {
        pageIndex: 0,
        pageSize: 0,
        searchText: "",
        StudentType: 1,
      };
      CWSSearchPageStudentList(searchObj).then((result) => {
        this.studentList = result.data.PageDataList;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: "选择学生",
          moduleName: "singleSelectionList",
          data: {
            selectedKey: 0,
            dataSourceList: this.studentList,
            required: true,
            addModuleName: 'cws-add-student',
            addModuleTitleName: '添加学生',
            ModulePowerKey: 0,
          },
          callBack: {
            onSelectedItem: (data) => {
              this.addClassGradeForStudent(data);
            },
          },
        });
      });
    },
    // 加学生进班
    addClassGradeForStudent (item) {
      console.log("addClassGradeForStudent", item)
      CWSAddClassGradeForStudent(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
        layer.alert("添加成功")
        this.$emit("updataData")
      }).catch(error => {
        layer.alert(error.msg)
      })
    },
    // 老师退班
    delClassGradeForTeacher (item) {
      console.log("delClassGradeForTeacher", item)
      CWSAddClassGradeForStudent(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
        layer.alert("添加成功")
        this.$emit("updataData")
      }).catch(error => {
        layer.alert(error.msg)
      })
    },
    // 学生退班
    delClassGradeForStudent (item) {
      console.log("delClassGradeForStudent", item)
      layer.confirm('是否将该学生退出班级', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CWSQuitClassGradeForStudent(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {

                this.$emit("updataData")
              }).catch(error => {
                layer.alert(error.msg)
              })
              layer.close();
            }
          }
        ]
      });

    }
  }
}
</script>
<style>
.class_detail_header_teacher {
  width: 100%;
  padding: 20px 40px;
  border-bottom: 1px solid #ececec;
}
.class_detail_header_title {
  /* padding: 20px; */
  width: 100%;
}
.class_detail_header_title .class_detail_header_name {
  font-size: 18px;
}
.class_detail_header_btn {
  position: absolute;
  right: 10px;
  top: 5px;
}
.class_detail_header_btn::before {
  content: '';
  width: 15px;
  height: 16px;
  background-image: url('../../../../../public/image/out_class.png');
  background-size: 15px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.class_detail_header_content {
  width: 100%;
  margin-top: 20px;
}
.class_detail_header_content .class_detail_teacher_list {
  width: 100%;
  /* margin-top: 10px; */
  display: flex;
}

.class_detail_teacher_list_title {
  color: #999999;
  width: 40px;
  display: inline-block;
  /* margin-right: 10px; */
  font-size: 14px;
  line-height: 20px;
}
.class_detail_header_teacher .class_detail_teacher_list_content {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}
.class_detail_teacher_list_item {
  position: relative;
  border: 1px solid #e8ebef;
  border-radius: 3px;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 24px;
  line-height: 12px;
  font-size: 12px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
  line-height: 21px;
}
.class_detail_teacher_list_item_btn {
  color: #999999;
}
</style>