<template>
  <!-- 库存调整表单 -->
  <div>
    <div class="content_marginRL border_bottom">
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">

        <input-number :formTitle="'账面库存'"
                      :readonly="true"
                      v-model="dataJson.PaperCount"
                      :decimalPlaces="0"
                      :placeholder="'必填'"
                      :required="true"></input-number>

        <input-number :formTitle="'实际库存'"
                      :min="0"
                      :max='9999'
                      v-model="dataJson.RealityCount"
                      :decimalPlaces="0"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="chgRealityCount"></input-number> 

        <input-rational-number :formTitle="'调整数量'"
                      :min="-9999"
                      :max='9999'
                      v-model="dataJson.AdjustmentCount"
                      :decimalPlaces="0"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="chgAdjustmentCount"></input-rational-number> 

        <input-text :formTitle="'原因说明'"
                    class="no_border_bottom"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="true"
                    v-model="dataJson.RemarkSupport"></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="true"
         style="margin-top: 80px;">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>

</template> 

<script>
import { GoodsInventoryAdjustment } from '../../../../API/workbench';
export default {
  data () {
    return {
      StartTime: '',
      newInputText: '0', // 默认值
      // 数据源
      dataJson: {
        OLAPKey: '', // 物品id
        PaperCount: '', // 账面库存
        RealityCount: '', // 实际库存
        AdjustmentCount: '', // 调整数量
        RemarkSupport: ''// 备注
      }
    };
  },

  computed: {

  },
  props: {
    TypeKey: Number, // 出入库类型1-入库;2-出库
    isReadonly: Boolean,
    dataInfo: Object// 出入库明细数据
  },
  watch: {

  },
  created () {
    if (JSON.stringify(this.dataInfo) != '{}') { // 物品信息
      this.dataJson.OLAPKey = Number(this.dataInfo.OLAPKey); // 物品名称
      this.dataJson.PaperCount = Number(this.dataInfo.CurrentInventoryCount); // 所属物品类型
      this.dataJson.RealityCount = Number(this.dataInfo.CurrentInventoryCount);  // 实际库存
      console.log(this.dataInfo, '修改物品信息');
    } else {
      console.log(this.dataInfo, '暂无数据');
    }
  },
  mounted () {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      GoodsInventoryAdjustment(this.dataJson).then(result => {
        layer.alert('调整成功');
        this.$emit('afterSuccessCallBack');
        this.cancelClick();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 实际库存
    chgRealityCount (count) {
      console.log(count, 'chgRealityCount');
      this.dataJson.RealityCount = count;
      if (Number(this.dataJson.PaperCount) - Number(this.dataJson.RealityCount) > 0) {
        this.dataJson.AdjustmentCount = -(Math.abs(this.dataJson.PaperCount - this.dataJson.RealityCount));
      } else {
        this.dataJson.AdjustmentCount = Math.abs(this.dataJson.PaperCount - this.dataJson.RealityCount);
      }
    },
    // 调整数量
    chgAdjustmentCount (count) {
      // this.dataJson.AdjustmentCount = this.$utils.mMoneyType(count, 0);
      this.dataJson.AdjustmentCount = count;
      this.dataJson.RealityCount = this.$utils.mMoneyType(Number(this.dataJson.PaperCount) + Number(this.dataJson.AdjustmentCount), 0);
      console.log(count, this.dataJson.AdjustmentCount, 'chgAdjustmentCount');
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (!this.dataJson.AdjustmentCount || !Math.abs(this.dataJson.AdjustmentCount) > 0) {
        layer.alert('请输入一个正确的调整数量');
        flag = true;
      } else if (Number(this.dataJson.PaperCount) < 0 && Number(this.dataJson.AdjustmentCount) < 0) {
        layer.alert('账面库存已为负数，不能继续调减');
        flag = true;
      } else if (this.dataJson.AdjustmentCount < 0 && Math.abs(this.dataJson.AdjustmentCount) > Number(this.dataJson.PaperCount)) {
        layer.alert('调减数量不能大于账面库存');
        flag = true;
      } else if (!this.dataJson.RemarkSupport) {
        layer.alert('原因说明不能为空');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>
