<template>
  <div>
    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <div class="defult_dialog_searchform">
        <div class="search_form">
          <input type="text"
                 class="search_input"
                 placeholder=""
                 v-model="searchText">
          <span class="remove_btn"
                v-if="searchText.length > 0"
                @click.stop="clearSearText"></span>
        </div>
      </div>
      <!-- 搜索结果 -->
      <div class="search_result_content nonBtnForList content_marginTop_14">
        <div class="search_result_list">
          <!-- active 选中当前 -->
          <new-single-selection-item v-for="item in vDataSourceLsit"
                                 :key="item.OLAPKey"
                                 :itemInfo="item"
                                 :dataSource="vDataSourceLsit"
                                 @selectedItem="doAfterSelectionItem"></new-single-selection-item>
          
        </div>
        <div v-if="!required&&dataSourceLsit.length>0"
             @click="cancelData"
             class="confirm_btn btn_fixed_bottom pf">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               style="magin:0 auto">清空选择</div>
        </div>
        <div v-if="!required&&dataSourceLsit.length>0"
               class="btn_fixed_bottom_placeholder"></div>

        <div v-if="vDataSourceLsit.length==0">
          <div class="monitoring_content_nodata">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import newSingleSelectionItem from './new-single-selection-item';
export default {
  name: 'singleSelectionList',
  data () {
    return {
      searchText: '',
      baseInfoNames: null, // 基础资料的名字集
      isAdd: false
    };
  },
  props: {
    dataSourceLsit: Array,
    selectionKey: [String, Number],
    formTitle: String,
    curModuleName: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  components: {
    newSingleSelectionItem
  },
  created () {
    this.baseInfoNames = this.$initJson.baseInfoNames;
  },
  methods: {
    cancelData () {
      this.$emit('selectedItem', {});
    },
    // 选中
    doAfterSelectionItem (item) {
      this.$emit('selectedItem', item);
    },
    // 返回
    onBack () {
      this.$emit('onBack');
    },
    // 清除查询条件
    clearSearText () {
      this.searchText = '';
    }
  },
  computed: {
    vDataSourceLsit () {
      let newList = [];
      // const PinyinMatch = require('pinyin-match');// 根据字母搜索
      let trueSearchText = this.searchText.toLowerCase();
      this.dataSourceLsit.forEach(o => {
        // var m = PinyinMatch.match(o.MainDemoName, trueSearchText);
        if (o.MainDemoName && (o.MainDemoName.toLowerCase().indexOf(trueSearchText) >= 0 ||
         (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))) {
          if (o.OLAPKey == this.selectionKey) {
            newList.push({ dataItem: o, isActive: true });
          } else {
            newList.push({ dataItem: o, isActive: false });
          }
        }
      });
      return newList;
    }
  }
};
</script>


