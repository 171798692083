<template>
  <div>
    <!-- 负责显示班级的搜索框 -->
    <div class="detail_info_searchBar single_shadow">
      <div class="detail_info_searchInput">
        <input type="text"
               placeholder="请输入手机号、学生姓名搜索"
               v-model="searchText"
               class="">
      </div>
      <div class="btn_hover_bg_blue btn_light_blue search_btn "
           @click.stop="serch">
        搜索
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'searchContent',
  data () {
    return {
      searchText: '',
    };
  },
  watch: {
    searchText () {
      if (this.searchText == '') {
        this.serch();
      }
    }
  },
  props: [],
  methods: {
    serch () {
      this.$emit('searchStudent', this.searchText);
    }
  }
};
</script>
<style >
</style>
