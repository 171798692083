<template>
  <div class="">
    <div class="general_summarizing_student">
      <div class="general_summarizing_student_navUl_02">
        <div class="title_list flex_1">今天 <span>{{Number(dataInfo.TotalTodayCount)}}</span></div>
        <div class="title_list flex_1">昨天 <span>{{Number(dataInfo.TotalYesterdayCount)}}</span></div>
        <div class="title_list flex_1">本周 <span>{{Number(dataInfo.TotalWeekCount)}}</span></div>
        <div class="title_list flex_1">本月 <span>{{Number(dataInfo.TotalMonthCount)}}</span></div>
      </div>
      <div class="payment_statistics_bottom clearfix">
        <div class="course_statistics_table_box table_list_content">
          <table-list class="summarizing_list"
                      :tableData="vdataReportList"
                      :tableColumns="tableCourseColumns"
                      :totalNum="totalNum"
                      :PageCount="PageCount"
                      :queryParams="searchObj"
                      :footerContent="vFooterTotal"
                      :showPagePagination="false"
                      @loadTableData="getDataList"></table-list>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import tableList from '../../../common/table-list/index';
import { MessageSearchPageForSignedCampus } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))))
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '员工',
          prop: 'DocumentaryKeyValue',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem',
          className: ''
        },
        {
          label: '今天',
          prop: 'TodayCount',
          minWidth: 108,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: ''
          }
        },
        {
          label: '昨天',
          prop: 'YesterdayCount',
          minWidth: 108,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: ''
          }
        },
        {
          label: '本周',
          prop: 'WeekCount',
          minWidth: 108,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: ''
          }
        },
        {
          label: '本月',
          prop: 'MonthCount',
          minWidth: 108,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: ''
          }
        }

      ],
      dataReportList: [],
      dataInfo: {
        TotalTodayCount: 0,
        TotalYesterdayCount: 0,
        TotalWeekCount: 0,
        TotalMonthCount: 0
      },
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList
  },
  props: {
  },
  created () {
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  mounted () {
    this.getDataList();
    this.$bus.on(this, 'switchHeaderBarToManagementAndStatistics', () => {
      this.getDataList();
    });
  },
  methods: {
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchPageForSignedCampus(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.dataInfo = result.data;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      this.$emit('toTableType', rowData.eventName, rowData.showSetUpTime);
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    }

  }
};
</script>