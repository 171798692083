<template>
  <!-- <div v-if="isShowMainForm">
    <custom-dialog title="签到本"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick"> -->
  <div class="form_info_detail text_overflow_hidden">
    <div class="attendance_book">
      <div class="attendance_book_header pr attendance_book_header_bg">

      </div>
      <div class="attendance_book_header pr attendance_book_header_title">
        学生签到本
      </div>
      <div class="attendance_book_header pr"
           style="margin-bottom：10px;display: flex;justify-content: center;">
        <!-- <div class="book_left"></div> -->

        <div class="header_date fl pr">
          <div class="header_prod fl"
               style="position: absolute;left: -15px;"
               @click="preMonth"></div>
          <input-date class="attendance_book_date_box"
                      style="width:37%"
                      :minDate="vMinDate"
                      :maxDate="vMaxDate"
                      :isShowHistory="true"
                      :formTitle="''"
                      :popperClass="'course_date_el'"
                      @changeDate="changeDay"></input-date>
          <span class="attendance_book_date_week_box">{{curSelectedDate}} {{vToday}}</span>
          <div class="header_next fl"
               style="position: absolute;right: -15px;top:0"
               :class="vMaxDateDisable?'':'gray'"
               @click="nextMonth"></div>
        </div>

      </div>
      <div v-if="vList.length != 0"
           id="attendance_content">
        <!-- 左侧导航栏 -->
        <div class="couser_block_ul_left">
          <div class="couser_block_ul_left_item"
               v-for="(item,key) in vTimeList"
               :key="key"
               :class="[setTimeClass(item),item.isDateActive?'opt':'']"
               @click.stop="clickTimescope(item)">
            {{setClassTimeName(item)}}
            <!-- -{{setTimeValue(item)}} -->
          </div>

        </div>
        <div class="couser_block_ul_right"
             :style="{paddingBottom:contentHeight+'px'}"
             @scroll="scrollContent">
          <div class="couser_block_li_item"
               v-for="item in vList"
               :key="item.OLAPKey"
               :class="'couser_book_'+item.OLAPKey">
            <div class="couser_block_li_item_text"
                 style="background:#f4f6fb">
              <span class="list_title_one list_title">
                {{item.ClassTimeName}} {{item.MTeacherKeyValue}}
              </span>
              <span class="list_title_three list_title">
                {{item.GradeClassKeyValue}} 排课{{item.studentList.length}}人
              </span>
            </div>
            <div class="couser_block_li_item_text">
              <span class="list_title_five list_title">
                <span>
                  签到<span class=""
                        style="color:#98DA65">{{item.studentAtten.length}}人</span>，
                </span>
                <span v-if="item.studentNoAtten.length>0">
                  缺勤<span class=""
                        style="color:#feb564">{{item.studentNoAtten.length}}人</span>，
                </span>
                <span v-if="item.studentUnSign.length>0">
                  未考勤<span class="font_red">{{item.studentUnSign.length}}人</span>，
                </span>
                <span>
                  课消<span class="">{{item.DeductCount}}</span>课时
                </span>

              </span>
            </div>
            <div class="couser_block_li_item_list">
              <div class="student_list">
                <div class="student_item type_green"
                     v-for="(pageItem,key) in item.studentAtten"
                     :key="key"
                     :title="pageItem.StudentNameKeyValue">{{pageItem.StudentNameKeyValue}}</div>
              </div>
              <div class="student_list">
                <div class="student_item type_red"
                     v-for="(pageItem,key) in item.studentNoAtten"
                     :key="key"
                     :title="pageItem.StudentNameKeyValue">{{pageItem.StudentNameKeyValue}}</div>
              </div>
            </div>
          </div>
          <!-- <div :style="{height:contentHeight+'px'}">

              </div> -->
        </div>

      </div>
      <div v-else>
        <div class="course_block_nodata"
             style="background-color:#FBF9F4;">
          <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
    <div class="botton_tips"
         v-if="!unshowTips">
      <div class="pr"
           style="text-align: center;">
        <span class="atten_text">出勤</span>
        <span class="noatten_text">缺勤</span>
        <div class="botton_tips_btn"
             @click="closeshowTips">
          知道了，关闭
        </div>
      </div>

    </div>
  </div>
  <!-- </custom-dialog> -->
  <!-- </div> -->
</template>
<script>
import { getAttendanceBook } from '../../../API/workbench';
export default {
  data () {
    return {
      curSelectedDate: this.$utils.formatDateToLine(Date.new()),
      courseList: [],   // 课列表
      studentList: [],     // 签到学生列表
      unshowTips: false,
      contentHeight: 0
      // isShowMainForm: false // 显示
    };
  },
  components: {
  },
  created () {
    this.unshowTips = Number(window.localStorage.unshowTips || '') == 1
    console.log("window.localStorage.unshowTips", window.localStorage.unshowTips, Number(window.localStorage.unshowTips || '') == 1)
    // this.curSelectedDate = this.$route.query.curSelectedDate || this.$utils.formatDateToLine(Date.new())
    // console.log(this.curSelectedDate,'this.curSelectedDate')
    // this.getAttendanceBookList(this.curSelectedDate);
    this.doShowPopupShowClick();
  },
  computed: {
    vTimeList () {
      let newArr = [];
      this.courseList.forEach(o => {
        this.$set(o, 'isDateActive', false)
        let locaIndex = newArr.findIndex((p) => {
          p.ClassTimeName = p.ClassTimeName || '';
          return o.ClassTimeName.substring(0, 2) == p.ClassTimeName.substring(0, 2);
        });
        if (locaIndex == -1) {
          newArr.push(o)
        }
      })
      return newArr
    },
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); //获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = "01";
      let day = "01";
      let hour = "00";
      let minute = '00';
      let second = '01';
      let dateStr = year + "-" + month + "-" + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr)
      return dateStr
    },
    vDataList () {
      let newArr = [];
      for (var i = 0; i < this.courseList.length; i++) {
        var o = this.courseList[i];
        o.studentList = [];
        for (var j = 0; j < this.studentList.length; j++) {
          var k = this.studentList[j];
          if (k.ScheduleCourseKey == o.OLAPKey) {
            o.studentList.push(k);
          }
        }
        newArr.push(o);
      }
      return newArr;
    },
    vList () {
      this.vDataList.forEach(obj => {
        obj.studentGroup = this.$utils.group(obj.studentList, 5);
        obj.studentAtten = []
        obj.studentNoAtten = []
        obj.studentUnSign = []
        obj.studentList.forEach(o => {
          if (o.AttenceStatusKey == 3) {
            obj.studentAtten.push(o)
          } else if (o.AttenceStatusKey == 4 || o.AttenceStatusKey == 5) {
            obj.studentNoAtten.push(o)
          } else {
            obj.studentUnSign.push(o)
          }
        })
      });
      console.log('this.vDataList', this.vDataList);
      return this.vDataList;
    },
    vToday () {
      console.log(this.curSelectedDate, 'this.curSelectedDate');
      if (this.curSelectedDate && Date.new(this.curSelectedDate.replace(/-/g, '/')).getTime() == Date.new(Date.new().toLocaleDateString()).getTime()) {
        return '今天';
      } else {
        return '';
      }
    },
    vMaxDate () {
      console.log(this.curSelectedDate, 'this.curSelectedDate');
      return Date.new().toLocaleDateString()
      // if (this.curSelectedDate && Date.new(this.curSelectedDate.replace(/-/g, '/')).getTime() >= Date.new(Date.new().toLocaleDateString()).getTime()) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    vMaxDateDisable () {
      console.log("Date.new(this.curSelectedDate)", this.curSelectedDate, Date.new(this.curSelectedDate).getTime(), Date.new().getTime())
      if (this.vToday == '今天' || (Date.new(this.curSelectedDate).getTime() >= Date.new().getTime())) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    scrollContent (e) {
      let scrollTop = document.getElementsByClassName('couser_block_ul_right')[0].scrollTop
      let locaIndex = this.vTimeList.findIndex(o => {
        let top = document.getElementsByClassName('couser_block_ul_right')[0].getBoundingClientRect().top - document.getElementsByClassName("couser_book_" + o.OLAPKey)[0].getBoundingClientRect().top
        // console.log(top)
        return top <= 10 && top > -40
      })
      console.log("scrollscroll", locaIndex)
      if (locaIndex > -1) {
        this.vTimeList.forEach((o, index) => {
          if (index == locaIndex) {
            o.isDateActive = true
          } else {
            o.isDateActive = false
          }
        })
      }
      console.log('scrollContent', scrollTop)
    },
    closeshowTips () {
      this.unshowTips = 1
      localStorage.setItem('unshowTips', this.unshowTips);
    },
    clickTimescope (item) {
      console.log("clickTimescope", item)
      //     height: 88px+padding 8px
      this.vTimeList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          o.isDateActive = true
        } else {
          o.isDateActive = false
        }
      })
      this.contentHeight = 0      //初始化空组件的高度
      var overHeight = 0
      let parentHeight = document.getElementsByClassName('couser_block_ul_right')[0].getBoundingClientRect().height
      var parTotalHeight = 0
      let locaIndex = this.vDataList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey
      })
      if (locaIndex > -1) {
        // let parentTop=document.getElementsByClassName("couser_block_ul_right")[0].getBoundingClientRect().top
        let elTop = document.getElementsByClassName("couser_book_" + item.OLAPKey)[0].getBoundingClientRect().top
        let locaIndex = this.vList.findIndex(o => {
          return o.OLAPKey == item.OLAPKey
        })
        let listHeight = 0
        if (locaIndex >= 0) {

          this.vList.forEach((o, index) => {
            if (index < locaIndex) {
              let elHeight = document.getElementsByClassName("couser_book_" + o.OLAPKey)[0].getBoundingClientRect().height

              listHeight += elHeight + 10
            } else {
              let elHeight = document.getElementsByClassName("couser_book_" + o.OLAPKey)[0].getBoundingClientRect().height

              overHeight += elHeight + 10
            }

          })
        }
        // parTotalHeight=listHeight+overHeight
        // 计算空组件应该显示的高度
        console.log("parentHeight", parentHeight)
        console.log("overHeight", overHeight)
        console.log("listHeight", listHeight)
        this.contentHeight = parentHeight - overHeight
        this.$nextTick(o => {
          // 计算滚动条高度
          document.getElementsByClassName('couser_block_ul_right')[0].scrollTop = listHeight
          // document.documentElement.scrollTop= parentTop+heightForParent
        })
        //-parentTop
        // document.documentElement.scrollTop=850+(83*locaIndex)
      }


    },
    setClassTimeName (item) {
      if (item.ClassTimeName) {
        return item.ClassTimeName.substring(0, 2) + ':00';
      }
    },
    setTimeValue (item) {
      if (Date.new(item.BeginClassTime) > Date.new()) {
        return '未开始'
      } else if (Date.new(item.EndClassTime) > Date.new()) {
        return '进行中'
      } else {
        return '已结束'
      }
    },
    setTimeClass (item) {
      if (Date.new(item.BeginClassTime) > Date.new()) {
        return 'red_type'
      } else if (Date.new(item.EndClassTime) > Date.new()) {
        return 'green_type'
      } else {
        return ''
      }
    },
    getSize (dataList) {
      // if (dataList.length == 0) {
      //   return false;
      // }
      // let oHeight = document.getElementsByClassName('form_info_detail')[0].clientHeight;     // 当前屏幕可视高度
      // let headHeight = document.getElementsByClassName('attendance_book_header')[0].clientHeight;
      // let sumHeight = oHeight - 60 - headHeight;     // 当前屏幕可视高度-head高度.  60是margin30*2
      // let contentHeight = document.getElementById('attendance_content').clientHeight;     // 文本区域高度
      // // console.log(contentHeight, 'contentHeight');
      // if (contentHeight < sumHeight) {
      //   // let itemHeight = document.getElementById('attendance_content').clientHeight;     // 单个高度
      //   let length = parseInt((sumHeight - contentHeight) / headHeight);
      //   let obj = { 'OLAPKey': '', 'CourseNameKey': '', 'CourseNameKeyValue': '', 'GradeClassKey': '', 'GradeClassKeyValue': '', 'ClassTime': '', 'BeginClassTime': '', 'EndClassTime': '', 'ArrivedNumCount': '', 'AttenceCount': '', 'ArrangedNumCount': '', 'FullPeopleCount': '', 'StatusKey': '', 'StatusKeyValue': '', 'MTeacherKey': '', 'MTeacherKeyValue': '' };
      //   for (var i = 0; i < length; i++) {
      //     dataList.push(obj);
      //   }
      // }
      console.log(length, 'length');
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.studentList = [];
      this.courseList = [];
      this.$emit('closeDialog');
      // this.oweClasshourWarning.Total=0
      // this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      // this.isShowMainForm = true;
      // document.body.scrollTop = 0;
      this.curSelectedDate = this.$utils.formatDateToLine(Date.new());
      console.log(this.curSelectedDate, 'this.curSelectedDate');
      this.getAttendanceBookList(this.curSelectedDate);
    },
    getAttendanceBookList (seletedate) {
      getAttendanceBook(seletedate).then(result => {

        this.studentList = result.data.StudentList;
        this.courseList = result.data.ScheduleCourseList;
        this.$nextTick(() => {
          this.getSize(this.courseList);

          // 计算滚动条高度
          if (document.getElementsByClassName('couser_block_ul_right') && document.getElementsByClassName('couser_block_ul_right')[0]) {
            document.getElementsByClassName('couser_block_ul_right')[0].scrollTop = 0
          }
          this.contentHeight = 0
          // document.documentElement.scrollTop= parentTop+heightForParent

        });
        console.log('getAttendanceBookList', result.data);
      }).catch(error => {
        console.log('getAttendanceBookListError', error);
      });
    },
    // 显示日期插件
    showDatePicker () {
      this.$refs.eventCalendarBox.showBox = true;
    },
    // 更改日期
    changeDay (date) {
      // 复制一下date.
      this.curSelectedDate = this.$utils.formatDatet(Date.new(date), 'yyyy-MM-dd');
      console.log(date, 'changeDay', this.curSelectedDate);
      this.getAttendanceBookList(this.curSelectedDate);
    },
    preMonth () {
      // if(){

      let currTime = new Date(this.$utils.getPreDay(this.curSelectedDate)).getTime() / 1000
      let minDate = new Date(this.vMinDate).getTime() / 1000
      if (currTime > minDate) {
        this.curSelectedDate = this.$utils.getPreDay(this.curSelectedDate);
        this.getAttendanceBookList(this.curSelectedDate);
      }

      // }
    },
    nextMonth () {
      if (this.vMaxDateDisable) {
        this.curSelectedDate = this.$utils.getNextDay(this.curSelectedDate);
        this.getAttendanceBookList(this.curSelectedDate);
      }

    },
    setHourMin (val) {
      val = val || '';
      return val.substring(11, 16);
    }
  }
};
</script>
<style scoped>
.attendance_book {
  background: none !important;
}
.header_date {
  background: none !important;
  padding-left: 0px !important;
  margin-left: 24px !important;
}
.attendance_book_header {
  border: none;
}
.attendance_book_header_title {
  text-align: center;
  line-height: 36px;
  width: 100%;
  font-size: 16px;
  border-bottom: none;
  /* background: url('../../../../public/image/atten_book_header.png') no-repeat center; */
}
.attendance_book_header_bg {
  height: 50px;
  width: 100%;
  font-size: 16px;
  border-bottom: none;
  background: url('../../../../public/image/atten_book_header.png') no-repeat
    71%;
}
#attendance_content {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.couser_block_ul_left {
  width: 51px;
  height: 100%;
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 6px;
}
.couser_block_ul_left_item {
  width: 51px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #e3e9f0;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.couser_block_ul_left_item.green_type {
  color: #35d780;
  border: 1px solid #35d780;
}
.couser_block_ul_left_item.red_type {
  color: #f27601;
  border: 1px solid #f27601;
}
.couser_block_ul_left_item:hover {
  color: #2d98d9;
  border: 1px solid #2d98d9;
}
.couser_block_ul_right {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 373px;
  padding-right: 3px;
}
.couser_block_li_item {
  width: 100%;
  margin-top: 10px;
  /* padding: 2px 5px; */
  padding-bottom: 2px;
  border: 1px solid #e3e9f0;
  /* background: #F8F9FB; */
  background: white;
  border-radius: 3px;
}
.couser_block_li_item_text {
  padding: 5px;
}

.couser_block_li_item_text .list_title {
  line-height: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 22px;
  flex: 1;
  /* margin-top: 5px; */
  color: #333 !important;
  font-size: 12px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.list_title_one::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../../public/image/list_title_one.png') no-repeat
    center;
  background-size: 11px;
}
.list_title_three::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../../public/image/list_title_three.png') no-repeat
    center;
  background-size: 11px;
}
.list_title_five::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../../public/image/index_data_icon.png') no-repeat
    center;
  background-size: 11px;
}
.couser_block_li_item_list {
  width: 100;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding: 0 5px;
}
.couser_block_li_item_list .student_list {
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  /* justify-content: center; */
  flex-wrap: wrap;
}

.couser_block_li_item_list .student_item {
  width: 58px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 5px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.couser_block_li_item_list .student_item.type_green {
  border: 1px solid #98da65;
  color: #98da65;
}
.couser_block_li_item_list .student_item.type_red {
  background: #feb56433;
  color: #feb564;
}
.botton_tips {
  position: fixed;
  bottom: 0px;
  left: 0%;
  right: 0%;
  height: 40px;
  line-height: 40px;
  background: #00000080;
  z-index: 999;
  margin: auto;
}
.atten_text {
  font-size: 12px;
  position: relative;
  color: #98da65;
}
.noatten_text {
  font-size: 12px;
  position: relative;
  color: #feb564;
  margin-left: 30px;
}
.atten_text::before {
  content: '';
  position: absolute;
  left: -22px;
  top: 0px;
  /* top: 12px; */
  width: 16px;
  height: 16px;
  border: 1px solid #98da65;
}
.noatten_text::before {
  content: '';
  position: absolute;
  left: -22px;
  top: 0px;
  /* top: 12px; */
  width: 16px;
  height: 16px;
  border: 1px solid #feb564;
}
.botton_tips_btn {
  position: absolute;
  right: 20px;
  top: 8px;
  width: 89px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ececec;
  line-height: 24px;
  font-size: 12px;
  background: #ececec;
}
.couser_block_ul_left_item.opt {
  background: linear-gradient(
    180deg,
    #ffccc4 0%,
    rgba(255, 255, 255, 0.0962963) 100%
  );
  border: 1px solid #e64024;
  box-shadow: 0px 2px 4px 0px #e6402480;
  color: #e64024;
}
</style>

