<template>
  <!-- 推荐作品列表 -->
  <div style="z-index: 999;">
    <div class="link_wrapper"
         @click="download">下载</div>
    <img class="img_box"
         id="Imgbox"
         :style="{'--Maxwidth':vFullscreen.Maxwidth,'--Maxheight':vFullscreen.Maxheight}"
         :src="this.$store.getters.CDNURL+ PhotoUrl"
         alt="">
  </div>
</template>
<script>
export default {
  data () {
    return {
      fullscreen: true
    };
  },
  components: {

  },
  props: {
    PhotoUrl: String
  },
  created () {
    console.log(this.PhotoUrl, 'PhotoUrl');
    let that = this;
    window.onresize = function () {
      if (!that.checkFull()) {
        // 退出全屏后要执行的动作
        that.fullscreen = false;
      }
    };
  },
  mounted () {
    document.getElementsByClassName('popup_dialog_box')[1].style.background = '#000';
    let isFullscreen = this.getFullScreenStatus();
    if (this.$zxClient.IsClient) {
      try {
        this.fullScreen();
      } catch (ex) {
        let isFullscreenS = document.body.scrollHeight == window.screen.height && document.body.scrollWidth == window.screen.width;
        console.log('isFullscreenS', isFullscreenS);
      }
    } else {
      if (!isFullscreen) {
        this.fullScreen();
      }
    }

    console.log('isFullscreen', isFullscreen);
  },
  computed: {
    vFullscreen () {
      let obj = {};
      if (this.fullscreen) {
        obj.Maxwidth = '';
        obj.Maxheight = '';
      } else {
        obj.Maxwidth = '1172px';
        obj.Maxheight = '610px';
      };
      return obj;
    }
  },
  methods: {
    getUrlBase64 (url) {
      return new Promise(resolve => {
        let Imgbox = document.getElementById('Imgbox');
        console.log(Imgbox, 'Imgbox', Imgbox.naturalWidth, Imgbox.naturalHeight);
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();
        img.crossOrigin = 'Anonymous'; // 允许跨域
        img.src = url;
        img.onload = function () {
          canvas.width = Imgbox.naturalWidth;
          canvas.height = Imgbox.naturalHeight;
          ctx.drawImage(img, 0, 0, Imgbox.naturalWidth, Imgbox.naturalHeight);
          let dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    // 下载图片
    download () {
      console.log('this.PhotoUrl', this.PhotoUrl);
      this.getUrlBase64(this.$store.getters.CDNURL + this.PhotoUrl).then(base64 => {
        let link = document.createElement('a');
        link.href = base64;
        link.download = 'qrCode.png';
        link.click();
      });
    },
    checkFull () {
      // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      // 火狐浏览器
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        // 谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      this.fullscreen = isFull;
      console.log('isFull', isFull);
      this.$emit('closeFrom', isFull);
      return isFull;
    },
    // 判断当前页面是否全屏
    getFullScreenStatus () {
      return !!(
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
      );
    },
    fullScreen () {
      let isFullscreen = this.getFullScreenStatus();
      var domName = document.getElementsByClassName('popup_dialog_box')[1];
      console.log('isFullscreen', isFullscreen, domName);
      if (!isFullscreen) {
        if (domName.RequestFullScreen) {
          domName.RequestFullScreen();
        }
        // 兼容火狐
        console.log(domName.mozRequestFullScreen);
        if (domName.mozRequestFullScreen) {
          domName.mozRequestFullScreen();
        }
        // 兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
        if (domName.webkitRequestFullScreen) {
          domName.webkitRequestFullScreen();
        }
        // 兼容IE,只能写msRequestFullscreen
        if (domName.msRequestFullscreen) {
          domName.msRequestFullscreen();
        }
      } else {
        console.log('已在全屏状态');
      }
    },
    isFullscreenForNoScroll () {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf('chrome') > 0) { // webkit
        if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
          return true;
        } else {
          return false;
        }
      } else { // IE 9+  fireFox
        if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
          return true;
        } else {
          return false;
        }
      }
    },
    exitFullscreen () {
      let isFullscreen = this.getFullScreenStatus();
      console.log('isFullscreen', isFullscreen);
      if (isFullscreen) {
        if (document.exitFullScreen) {
          document.exitFullscreen();
        }
        // 兼容火狐
        console.log(document.mozExitFullScreen);
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        // 兼容谷歌等
        if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        // 兼容IE
        if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        console.log('不在全屏状态');
      }
    }
  }
};
</script>
<style scoped>
.img_box {
  max-width: var(--Maxwidth);
  max-height: 96%;
  width: auto !important;
  height: auto !important;
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.link_wrapper {
  width: 80px;
  height: 30px;
  border: 1px solid #fff;
  color: #fff;
  position: absolute;
  top: 8px;
  right: 100px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  cursor: pointer;
}
</style>