<template>
  <div class="payment_statistics_bottom ">
    <seleted-option :searchObj="searchObj"
                    style="padding-top:20px;padding-left:20px"
                    :showTeacher="true"
                    :teacherList="teacherList"
                    :gradeClassList="gradeClassList"
                    :courseNameList="courseNameList"
                    @changeSearchObj="updateData"></seleted-option>
    <div class="payment_statistics_bottom course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :minDate="vMinDate"
                  :maxDate="vMaxDate"
                  :isShowHistory="isShowHistory"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="searchData"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog :title="'校正考勤'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowChangeAttendance"
                   :before-close="closeAttendanceReason">
      <change-attendance :curSelectedStudentInfo="curSelectedStudentInfo"
                         :courseInfo="curSelectedStudentInfo"
                         @changeAttendance="changeAttendanceAfter"
                         @cancel="closeAttendanceReason"></change-attendance>
    </custom-dialog>
  </div>
</template>
<script>
import attendanceArrange from '../../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import tableList from '../../../common/table-list/index';
import seletedOption from './seleted-option';
import { MessageSearchClassAwayForAttence, KQ_AdjustHour, MessageSearchClassAwayForAttenceH } from '../../../../API/workbench';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import changeAttendance from '../../../course-table/attendance-arrange/components/change-attendance';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      isShowChangeAttendance: false,
      curSelectedStudentInfo: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        QueryID: 0,
        GradeKey: 0,
        GradeKeyValue: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        CourseKey: 0,
        CourseKeyValue: '',
        CourseTimeTypeValue: '',
        CourseTimeType: 0,
        AttenceStatusKey: '',
        AttenceStatusKeyValue: '',
        StudentKey: 0,
        StudentKeyVal: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item'

        },
        {
          label: '上课时间',
          prop: 'ClassTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => { this.doAfterClickToAtten(rowData); },
            ModulePowerKey: 51
          }
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '主讲',
          prop: 'MTeacherKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '学生',
          prop: 'StudentNameKeyValue',
          width: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_blue',
          type: 'student',
          extend: {
            studentKey: 'StudentNameKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '学生课时',
          prop: 'ClassCount',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'class-hour',
          extend: {
            setClassName: (rowData) => {
              if (rowData.IsMakeupKey == 1) {
                return 'makeup_class';
              } else if (rowData.IsTrialKey == 1) {
                return 'makeup_class';
              } else {
                return rowData.XYKDKey > 0 ? '' : 'no_click';
              }
            },
            setShowOwe: (rowData) => {
              return rowData.ISClassHourDebtKey > 0;
            },
            click: (rowData) => { this.doAfterClickToClassDetailRecord(rowData); }
          }
        },
        {
          label: '考勤状态',
          prop: 'AttenceStatusKeyValue',
          sortable: 'custom',
          width: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '打考勤人',
          prop: 'AttenceOpratorKeyValue',
          sortable: 'custom',
          align: 'left',
          width: 100,
          isShow: true,
          type: 'text-item'
        },
        {
          label: '考勤时间',
          prop: 'AttenceTime',
          sortable: 'custom',
          width: 150,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '校正考勤',
                extend: {
                  click: (rowData) => {
                    console.log('rowData', rowData);
                    this.curSelectedStudentInfo = rowData;
                    this.isShowChangeAttendance = true;
                  }
                }
              }
            ]
          }
        }
      ],
      dataReportList: []
    };
  },
  components: {
    tableList, attendanceArrange, inputSearchContainBtn, seletedOption, changeAttendance
  },
  props: {
    searchInfo: {
      type: Object,
      default: null
    },
    teacherList: Array,
    gradeClassList: Array,
    courseNameList: Array,
    isTeacher: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      // let newArr = [];
      // this.dataReportList.forEach(o => {
      //   // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
      //   // o.classTimeObj = info.split(',');
      //   o.ClassTime = o.ClassTime.substring(5);
      //   o.AttenceTime = o.AttenceTime ? o.AttenceTime.substring(0, 16) : '';
      //   if((o.IsMakeupKey == 1|| o.IsTrialKey == 1)&&o.ClassHourKey=='5'){
      //     o.ClassCountCash = o.ClassCount
      //     console.log('o.ClassCountCash',o.ClassCountCash)
      //   }
      //   o.ClassCount = o.IsMakeupKey == 1 ?'补课': o.IsTrialKey == 1 ? '试课' : o.ClassCount;
      //   if (o.AttenceOpratorKey == 0) {
      //     o.AttenceOpratorKeyValue = '系统';
      //   }
      //   // this.$set(o, 'OLAPKey', o.CourseNameKey);
      //   newArr.push(o);
      // });
      return this.dataReportList;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  created () {
    if (this.searchInfo) {
      if (new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31' >= this.searchInfo.startTime) {
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
        this.isShowHistory = true;
      } else {
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
        this.isShowHistory = false;
      }
    }
    console.log('searchInfo1', this.searchInfo);
    this.showStudentAttendance();
    this.getDataList();
  },
  mounted () {
    console.log('searchInfo2', this.searchInfo);
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('wsAttendanceOrArrange');
      this.getDataList();
    });
    // 还课时
    this.$bus.on(this, 'AfterRepayClassHour', (result, type) => {
      if (!type) {
        this.getDataList(true);
      }
    });
    // 关闭安排考勤刷新
    this.$bus.on(this, 'closeArrangeAttendanceDialog', (data) => {
      console.log('closeArrangeAttendanceDialog');
      this.getDataList();
    });
  },
  activated () {
    console.log('searchInfo3', this.searchInfo);
    this.showStudentAttendance();
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.saveDate.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    // 外部进入考勤模式
    showStudentAttendance () {
      if (this.$route.query.StudentNameKey > 0 || this.$route.query.type == 1) {
        this.searchObj.GradeKey = this.$route.query.GradeKey || 0;
        this.searchObj.GradeKeyValue = this.$route.query.GradeKeyValue || '';
        this.searchObj.TeacherKey = this.$route.query.TeacherKey || 0;
        this.searchObj.TeacherKeyValue = this.$route.query.TeacherKeyValue || '';
        this.searchObj.CourseKey = this.$route.query.CourseKey || 0;

        this.searchObj.CourseTimeType = this.$route.query.CourseTimeType || 0;
        this.searchObj.CourseTimeTypeValue = this.$route.query.CourseTimeTypeValue || '';
        this.searchObj.searchText = this.$route.query.StudentNameKeyValue || '';
        this.searchObj.StudentKey = this.$route.query.StudentNameKey;
        this.searchObj.startTime = this.$route.query.startTime || this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$route.query.endTime || this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
      }
      if (this.searchInfo) { // 弹窗模式
        this.searchObj.searchText = this.searchInfo.searchText || '';
        this.searchObj.StudentKey = this.searchInfo.StudentKey;
        this.searchObj.CourseKey = this.searchInfo.CourseKey || 0;
        this.searchObj.CourseKeyValue = this.searchInfo.CourseKeyValue || '';
        this.searchObj.CourseTimeTypeValue = this.searchInfo.CourseTimeTypeValue || '';
        this.searchObj.CourseTimeType = this.searchInfo.CourseTimeType || 0;
        this.searchObj.GradeKey = this.searchInfo.GradeKey || 0;
        this.searchObj.GradeKeyValue = this.searchInfo.GradeKeyValue || '';
        this.searchObj.startTime = this.searchInfo.startTime;
        this.searchObj.endTime = this.searchInfo.endTime;
        this.searchObj.pageSize = 10;
        this.searchObj.isShowDialog = true;
      }
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      this.searchObj.GradeKey = 0;
      this.searchObj.CourseKey = 0;
      this.searchObj.CourseTimeType = 0;

      this.searchObj.TeacherKey = 0;
      this.searchObj.AttenceStatusKey = '';
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    searchData () {
      this.searchObj.StudentKey = '';
      this.searchObj.pageIndex = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchClassAwayForAttenceH;
        } else {
          fn = MessageSearchClassAwayForAttence;
        }
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '学生考勤明细',
          clist: [
            { title: '上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '主讲', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '学生课时', cName: 'ClassCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ClassCount::1::0}}" },
            { title: '考勤类型', cName: 'CourseTimeTypeValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            // { title: '课消金额', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤状态', cName: 'AttenceStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '打考勤人', cName: 'AttenceOpratorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤时间', cName: 'AttenceTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchClassAwayForAttenceH;
      } else {
        fn = MessageSearchClassAwayForAttence;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeductAmount).toFixed(2));
        result.data.PageDataList.forEach(o => {
          // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
          // o.classTimeObj = info.split(',');
          o.ClassTime = o.ClassTime.substring(5);
          o.AttenceTime = o.AttenceTime ? o.AttenceTime.substring(0, 16) : '';
          if (((o.IsMakeupKey == 1 || o.IsTrialKey == 1) && o.ClassHourKey == 5) || o.ISClassHourDebtKey == 1) {
            o.ClassCountCash = o.ClassCount;
            console.log('o.ClassCountCash', o.ClassCountCash);
          }
          o.ClassCount = o.IsMakeupKey == 1 ? '补课' : o.IsTrialKey == 1 ? '试课' : o.ClassCount;
          if (o.AttenceOpratorKey == 0) {
            o.AttenceOpratorKeyValue = '系统';
          }
        });
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击 去考勤页面
    doAfterClickToAtten (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');
      this.seletedMDInfo = rowData;
      this.seletedCourseInfo = rowData;
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.ScheduleCourseKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 课消详情记录
    doAfterClickToClassDetailRecord (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');
      if ((rowData.XYKDKey > 0 && Number(rowData.ClassCount) > 0) || (rowData.ClassCountCash && Number(rowData.ClassCountCash) > 0 && rowData.ISClassHourDebtKey != 1)) {
        this.seletedMDInfo = rowData;
        this.seletedCourseInfo = rowData;
        let newInfo = {
          studentKey: rowData.StudentNameKey, // 学生ID
          courseBillKey: rowData.XYKDKey // 课单ID
        };
        console.log(rowData, '课消');
        let moduleInfo = {
          name: '购课课消详情',
          routerName: this.$route.name,
          moduleName: 'buyClassDetailRecord',
          data: newInfo
        };
        this.$bus.emit('openCommonCustomDialog', moduleInfo);
      }
    },
    closeAttendanceReason () {
      this.isShowChangeAttendance = false;
    },
    changeAttendanceAfter (dataItem, ISClassHourLackKey, type, callback) {
      this.curSelectedStudentInfo.ISClassHourLackKey = 1;// ISClassHourLackKey || 0;
      KQ_AdjustHour(
        this.curSelectedStudentInfo.OLAPKey,
        this.curSelectedStudentInfo.ISClassHourLackKey,
        this.curSelectedStudentInfo.IsTrialKey,
        dataItem
      )
        .then((result) => {
          if (result.code === 0) {
          }
          layer.alert('校正考勤成功');
          this.closeAttendanceReason();
          this.getDataList();
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentNameKeyValue +
              '课时不足，是否以欠课时的方式变更考勤？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      layer.close();
                      if (callback) {
                        callback();
                      }
                      this.closeAttendanceReason();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.changeAttendanceAfter(
                        dataItem,
                        1
                      );
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.closeAttendanceReason();
          } else {
            layer.alert(err.msg);
            this.closeAttendanceReason();
          }
        });
    }
  }
};
</script>
<style scoped>
.makeup_class {
  width: 28px;
  height: 16px;
  padding: 2px;
  background: #ff9821;
  border-radius: 2px;
  margin: auto;
  color: #fff;
}
.payment_statistics_bottom .course_statistics_search .search_box {
  width: 250px !important;
}
.change_history_data {
  position: absolute;
  right: 105px;
  top: 135px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>