<template>
  <div>
    <div class="form_header_gray">
      <div class="form_header_text">基本信息</div>
    </div>
    <div class="form_information_list">
      <div class="form_information_title">小程序名称</div>
      <div class="form_information_content">
        <span>{{ dataInfo.nickname }}</span>
      </div>
    </div>
    <div class="form_information_list">
      <div class="form_information_title">小程序LOGO</div>
      <div class="form_information_content">
        <img class="logo_img" :src="dataInfo.head_image_info.head_image_url" alt="">
      </div>
    </div>
    <div class="form_information_list">
      <div class="form_information_title">APPID</div>
      <div class="form_information_content">
        <span>{{ dataInfo.appid }}</span>
      </div>
    </div>
    <div class="form_information_list">
      <div class="form_information_title">所属公司</div>
      <div class="form_information_content">
        <span>{{ dataInfo.principal_name }}</span>
      </div>
    </div>
    <!-- <div class="form_header_gray">
      <div class="form_header_text">类目设置</div>
    </div>
    <div class="form_information_list">
      <div class="form_information_title"></div>
      <div class="form_information_content">
        <span></span>
      </div>
    </div> -->
  </div>
</template>
<script>
import { GetAccountBasicInfo } from "../../API/workbench";

export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  props: {
    selectInfo: Object,
  },
  created() {
    this.getDataInfo();
  },
  methods: {
    getDataInfo() {
      GetAccountBasicInfo(this.selectInfo.MiniProgramAppID).then(
        (res) => {
          this.dataInfo = res.data;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
  },
};
</script>
<style scoped>
.form_information_list {
  border-bottom: none;
  line-height: 27px;
  min-height: 27px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
}
.form_information_list .sex_icon {
  display: inline-block;
  vertical-align: top;
  width: 12px;
  height: 27px;
  background-repeat: no-repeat;
  background-position: left center;
}

.form_information_title {
  color: #999;
  width: 96px;
}
.form_information_content {
  padding-right: 16px;
  text-align: left;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  padding-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font_underline_blue {
  border-bottom: 1px solid #3498db;
}

.logo_img {
  width: 27px;
  height: 27px;
}
</style>