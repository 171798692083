<template>
  <div>
    <!-- <custom-dialog title="待报课单报课"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="visShowPhonePopup"
                   :before-close="isShowPhonePopupHide"> -->
    <div class="apply_course_content"
         v-show="visShowPhonePopup">
      <div class="apply_course_swiper">
        <new-tick-item :tickItem="selectedTickItem"
                       :waitPlanCount="waitPlanCount"
                       @showTickList="showCourseList"></new-tick-item>
        <!-- <course-laber
            ref="courseLaber"
            :tickItem="lessonItem"
            @chgCourse="chgCourseAfter"
            @showCourseListBefore="showCourseList"
          ></course-laber> -->

        <!-- <div   v-show="!lessonItem"
                    class="course_block_nodata">
                  <div class="monitoring_content_nodata">
                    <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
                  </div>
          </div>-->
      </div>
      <student-lable class="student_lable"
                     v-if="studentItem.OLAPkey"
                     :studentID="studentItem.OLAPkey"
                     :showMenu="false"
                     :unshowChgStudent="true"></student-lable>
      <div class="apply_course_ul"
           style="margin-top:-4px;">
        <div class="apply_course_ul_box"
             :class="{show_open_btn:planList.length>vplanList.length}">
          <div class="apply_course_ul_title">
            <span>报课详情</span>
            <span class="font_slogan">点报课分解套餐课时到课程，退报课时返还待报课</span>
          </div>
          <planItem v-for="planItem in vplanList"
                    :key="planItem.ApplyClassTypeKey"
                    :planItem="planItem"
                    :planList="planList"
                    :tickItem="selectedTickItem"
                    :studentItem="studentItem"
                    :waitPlanCount="waitPlanCount"
                    @applyCourse="showApplyCourse"
                    @refundCourse="showrRefundCourse"></planItem>
          <div class="apply_course_list_last"
               @click="chgShowMore"
               v-if="planList.length>vplanList.length">
            <span class="list_last_icon"></span>
            <span>展开更多</span>
          </div>
          <div v-if="vplanList.length==0"
               class="course_block_nodata"
               style="margin:20px 0;">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
      <!--  -->

    </div>
    <!-- 提交按钮组 -->
    <!-- <save-cancel-btn-group
        v-if="vplanList.length!=0"
        style="padding-bottom:20px;"
        :isSingleSubmit="true"
        :singleSubmitType="'dynamic'"
        @confirmClick="confirm"
        @cancelClick="cancel"
      ></save-cancel-btn-group> -->
    <!-- <div class="btn_fixed_bottom"
           v-if="vplanList.length!=0">
        <div class="btn_fixed_white btn_hover_white"
             @click="cancel()"
             v-if="vplanList.length!=0">取消</div>
        <div class="btn_fixed_blue btn_hover_bg_blue"
             @click="confirm()"
             v-if="vplanList.length!=0">
          <div class="btn_footer_confirm"
               :class="!showNowApplyCount?'btn_footer_confirm':''">确认</div>
          
          <div class="btn_footer_info"
               v-if="!showNowApplyCount">当前报课{{vTotalApplyCount}}
            <span v-if="vSum_SuperQuote>0">,超报{{vSum_SuperQuote}}课时</span>
          </div>

        </div>
      </div> -->
    <!-- </custom-dialog> -->
    <!-- <custom-dialog title="待报课单列表"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="searchBoxShow"
                   :before-close="closeSearchBoxShow"> -->
    <div v-if="searchBoxShow">
      <courseIdApplyList :formTitleformTitle="'待报课单列表'"
                         :switchItem="1"
                         @return="closeSearchBoxShow"
                         @selectedItem="chgCourseAfter"></courseIdApplyList>
    </div>
    <!-- </custom-dialog> -->
    <!-- 切换时弹出 -->
    <custom-dialog title="待报课单列表"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="searchBoxShowForChange"
                   :before-close="closeSearchBoxShow">
      <courseIdApplyList :formTitleformTitle="'待报课单列表'"
                         :switchItem="1"
                         @return="closeSearchBoxShow"
                         @selectedItem="chgCourseAfter"></courseIdApplyList>
    </custom-dialog>
    <apply-course-dialog :planItem="selectedPlanItem"
                         :tickItem="selectedTickItem"
                         :waitPlanCount="waitPlanCount"
                         ref="applycourse"
                         @confirm="confirmAfter"></apply-course-dialog>
    <!-- 退报课弹窗 -->
    <refund-course-dialog :planItem="selectedPlanItem"
                          :tickItem="selectedTickItem"
                          ref="refundCourse"
                          @confirm="confirmAfter"></refund-course-dialog>
  </div>
</template>
<script>
// import { parseJson } from '../../utils';
import courseLaber from './course-laber';
import planItem from './plan-item';
import courseIdApplyList from './course-id-apply-list/index';
import newTickItem from '../apply-course/new-tick-item';
import applyCourseDialog from '../apply-course/apply-course-dialog/apply-course';
import refundCourseDialog from '../apply-course/apply-course-dialog/refund-course';
import studentLable from '../../common/student-lable';
import {
  getSearchPageForStayClass,
  GetTheCourseDetails,
  submitTicketPlan,
  getTheTicketPlanList
} from '../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,
        pageSize: 0,
        searchText: '',
        orderBy: '',
        sequence: '',
        ID: 0
      },
      searchBoxShow: false,
      visShowPhonePopup: false,
      selectedTickItem: '',
      studentItem: {},
      OLAPkey: '',
      isShowComfirm: true,
      planList: [],
      tickPlanList: [], // 待报课记录
      lessonItem: '',
      swiper: {},
      noApply: [], // 没报课列表
      alreadyApply: [], // 已报课列表
      showMore: false,
      isConfirmed: true, // 防止多次点击.
      selectedPlanItem: {}, // 选中报课课名
      searchBoxShowForChange: false
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    courseLaber,
    planItem,
    courseIdApplyList,
    studentLable,
    newTickItem,
    applyCourseDialog,
    refundCourseDialog
  },
  created () {
    if (this.dataInfo && this.dataInfo.TableID > 0) {
      this.visShowPhonePopup = true;
      this.chgCourseAfter(this.dataInfo);
    } else {
      this.searchBoxShow = true;
    }
  },
  watch: {},
  mounted () {
    // console.log('@111111');
    // if (this.$route.query.TableID > 0) {
    //   this.searchObj.ID = this.$route.query.TableID;
    //   this.getSearchPageForCourse(this.searchObj);
    // } else {
    //   console.log('@35235', this.$refs.courseLaber);
    //   this.$refs.courseLaber.showCourseListSearch();
    // }
  },
  computed: {
    showTotalApplyCount () {
      if (this.vplanList.length == 1 && this.vplanList[0].readOnly) {
        return false;
      } else {
        return true;
      }
    },
    waitPlanCount () {        // 正确的待报课数量
      let num = 0;
      this.planList.forEach(o => {
        num += Number(o.activeCount);
      });
      let waitPlanCount = Number(this.selectedTickItem.CurrentCount) - num;
      return this.$utils.saveDecimal(waitPlanCount < Number(this.selectedTickItem.ToBeArranged) ? waitPlanCount : Number(this.selectedTickItem.ToBeArranged));
    },
    vplanList () {
      let findItem = this.planList.find(o => {
        return o.TableID > 0 && o.IsSuitable != 0;
      });
      if (!this.showMore) {
        if (!findItem) {
          return this.alreadyApply.concat(this.noApply);
        } else {
          return this.alreadyApply;
        }
      } else {
        if (this.alreadyApply.length > 0) {
          this.alreadyApply[this.alreadyApply.length - 1].isLast = true;
        }
        return this.alreadyApply.concat(this.noApply);
      }
    },
    vtickPlanListTotol () {
      let num = 0;
      this.tickPlanList.forEach(o => {
        num += Number(o.PlanCount);
      });
      return num;
    },
    vTotalApplyCount () {       // 总报课时.
      let count = 0;
      this.planList.forEach(o => {
        count += Number(o.activeCount);
      });
      return this.$utils.saveHowMuchDecimal(count, 1);
    },
    // 超报课数量
    vSum_SuperQuote () {
      let applyCount = 0;
      this.planList.forEach(o => {       // 获取所有报课课程报课数量总值.
        if (o.Apply < 0) {
          applyCount += Math.abs(o.Apply) + Number(o.activeCount);
        } else {
          applyCount += Number(o.activeCount);
        }
      });
      if (applyCount - Number(this.selectedTickItem.NumCount) > 0) {
        return applyCount - Number(this.selectedTickItem.NumCount);  // CurrentCount
      } else {
        return 0;
      }
    },
    showNowApplyCount () {        // 判断课当为False时 是否显示当前报课,
      let typeKey = false;
      this.vplanList.forEach(o => {
        if (o.readOnly == false) {
          typeKey = false;
          return false;
        } else {
          typeKey = true;
        }
      });
      return typeKey;
    }
  },
  methods: {
    // 关闭课单列表.
    closeSearchBoxShow () {
      this.searchBoxShowForChange = false;
      this.searchBoxShow = false;
      if (this.selectedTickItem) {
        this.visShowPhonePopup = true;
      } else {
        this.visShowPhonePopup = false;
      }
    },
    // 关闭所有
    isShowPhonePopupHide () {
      this.searchBoxShowForChange = false;
      this.searchBoxShow = false;
      this.visShowPhonePopup = false;
      this.selectedTickItem = '';
      this.searchObj.ID = 0;
      this.$emit('closeDialog');
    },
    // 显示课单列表
    showCourseList () {
      this.searchBoxShowForChange = true;
      // this.visShowPhonePopup = false;
    },
    // 切换课单后.  外部也可以调用这个方法直接进入详细资料中.
    chgCourseAfter (item) {
      this.searchBoxShow = false;
      this.searchBoxShowForChange = false;
      this.visShowPhonePopup = true;
      this.searchObj.ID = item.TableID;
      if (
        this.selectedTickItem &&
        this.selectedTickItem.TableID != item.TableID
      ) {
        this.showMore = false;
      }
      this.getSearchPageForCourse(this.searchObj);
    },
    // 获取课单列表接口.
    getSearchPageForCourse (data) {
      getSearchPageForStayClass(data)
        .then(result => {
          this.showMore = false;
          this.lessonItem = result.data.PageDataList[0];
          this.searchObj.ID = 0;
          if (this.lessonItem.TableID > 0) {
            this.selectedTickItem = this.lessonItem;
            console.log(this.selectedTickItem, 'this.selectedTickItem');
            this.studentItem.OLAPkey = this.selectedTickItem.StudentKey;
            // this.getStudentInfo(this.selectedTickItem.StudentKey);
            this.GetTheCourseDetailsByPlanID(this.selectedTickItem.TableID);
            this.getTicketPlan(this.selectedTickItem.TableID);
          }
          console.log(this.lessonItem, 'getSearchPageForCourse');
        })
        .catch(error => {
          // layer.alert('该课单无法安排报课,请重新选择', {}, 0, () => {
          //   this.$router.go(-1);
          //   layer.close();
          // });
          console.log('error', error);
        });
    },
    // getStudentInfo (OLAPkey) {
    //   getStudentRelatedMsg(OLAPkey).then(result => {
    //     this.studentItem = result.data;
    //     console.log(this.studentItem, 'getStudentInfo');
    //   });
    // },
    getTicketPlan (planID) {
      getTheTicketPlanList(planID).then(result => {
        this.tickPlanList = result.data;
        console.log(result, 'getTicketPlangetTicketPlan');
      });
    },
    // 课单ID获取所有排课计划
    GetTheCourseDetailsByPlanID (planID) {
      GetTheCourseDetails(planID)
        .then(result => {
          this.planList = [];
          this.alreadyApply = [];
          this.noApply = [];
          result.data.forEach(o => {
            o.PlanCount = this.$utils.saveDecimal(Number(o.PlanCount));
            o.Apply = this.$utils.saveDecimal(Number(o.Apply));
            o.activeCount = o.Apply;
            if (o.Apply < 0) {      // 加入当前报课为负数时有可能存在超报
              // o.PlanCount += Math.abs(o.Apply);
              o.activeCount = 0;
            }
            o.SpendCount = this.$utils.saveDecimal(Number(o.SpendCount));
            o.Sum_PlanCount = this.$utils.saveDecimal(Number(o.Sum_PlanCount));
            o.Sum_Apply = this.$utils.saveDecimal(Number(o.Sum_Apply));
            // Sum_Apply-Apply = 未显示的报课数量.
            o.Min = this.$utils.saveDecimal(Number(o.Min));
            o.Max = this.$utils.saveDecimal(Number(o.Max));

            o.adjustCount = 0; // 将要调整的的数.
            if (o.ApplyClassTypeKey == this.selectedTickItem.ApplyCorIDsName) {
              o.readOnly = true;
            } else {
              o.readOnly = false;
            }
            // console.log(o,'forEach')
            o.isLast = true;
            this.planList.push(o);
            if (o.TableID > 0 && o.IsSuitable != 0) {
              this.alreadyApply.push(o);
            } else {
              this.noApply.push(o);
            }
          });
          if (this.alreadyApply.length > 0) {
            this.alreadyApply[this.alreadyApply.length - 1].isLast = false;
          }
          console.log('GetTheCourseDetails', this.planList);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    },
    cancel () {
      this.isShowPhonePopupHide();
    },
    // 确定.
    confirm (content, callback) {
      content = content || '';
      if (this.isConfirmed) {
        this.isConfirmed = false;
        let dataList = [];
        let allAdjustCount = 0;
        let allApplyCount = 0;
        let isChange = 0;     // 防止一加一减刚好为0的情况, 所以特地新增的一个判断
        let dataObj = {
          PlanCount: 0, // float, --计划数量
          ApplyClassKey: 0, // int, --课名ID
          ApplyClassKeyValue: '' // string, --课名
        };
        // console.log(this.planList,dataObj,'dataObj')

        this.vplanList.forEach(o => {
          // 计算减去所有课单排课数后的总共排课数

          if (o.PlanCount || o.adjustCount - o.Apply != 0) {   // o.PlanCount || o.adjustCount - o.Sum_Apply != 0
            allAdjustCount += o.adjustCount;
            if (o.adjustCount != 0) {
              isChange = o.adjustCount;
            }
            allApplyCount += (o.Apply < 0 ? 0 : o.Apply) + o.SpendCount + o.adjustCount;
            console.log(allAdjustCount, 'allAdjustCount', allApplyCount, 'allApplyCount');
            let dataObj = {
              PlanCount: (o.Apply < 0 ? 0 : o.Apply) + o.SpendCount + o.adjustCount, // float, --计划数量
              ApplyClassKey: o.ApplyClassTypeKey, // int, --课名ID
              ApplyClassKeyValue: o.ApplyClassTypeKeyValue // string, --课名
            };
            dataList.push(dataObj);
          }
        });
        // allAdjustCount  为所有课名在此课单中的总报课数量.
        if (isChange == 0) {
          layer.alert('没有变更报课，不需要提交。');
          this.isConfirmed = true;
          // if (callback) {
          //   this.isConfirmed = true;
          //   callback();
          // }
        } else {
          console.log(this.selectedTickItem, allAdjustCount, allApplyCount);
          // let totolCount = Number(this.selectedTickItem.CurrentCount) > Number(this.selectedTickItem.NumCount) ? Number(this.selectedTickItem.CurrentCount) : Number(this.selectedTickItem.NumCount);
          let sumPlan = Number(this.vtickPlanListTotol.toFixed(1)) - allApplyCount;
          console.log(dataList, 'dataList');
          let dataObj = {
            // 计算减去所有排课计划的排课数后 剩余的数量
            PlanCount: sumPlan < 0 ? 0 : sumPlan, // float, --计划数量
            ApplyClassKey: 0, // int, --课名ID
            ApplyClassKeyValue: '复合' // string, --课名
          };
          dataList.push(dataObj);
          submitTicketPlan(this.selectedTickItem.TableID, dataList)
            .then(result => {
              console.log(result, 'submitTicketPlan');
              if (result.code == 0) {
                this.isConfirmed = true;
                //
                layer.alert(content);
                if (dataObj.PlanCount > 0) {
                  this.confirmAfterUpdate();
                } else {
                  this.isShowPhonePopupHide();
                }
                if (callback) {
                  callback();
                }
              } else {
                layer.alert(result.msg);
              }
            })
            .finally(() => {
              this.isConfirmed = true;
              console.log('saveBillingAccept:finally');
              if (callback) {
                callback();
              }
            }).catch(error => {
              this.confirmAfterUpdate();
              layer.alert(error.msg);
            });
        }
      }
    },
    confirmAfterUpdate () {
      this.searchObj.ID = this.lessonItem.TableID;
      this.getSearchPageForCourse(this.searchObj);
    },
    chgShowMore () {
      this.showMore = true;
    },
    // 报课弹窗
    showApplyCourse (item) {
      this.selectedPlanItem = item;
      this.$refs.applycourse.chgisShowMain();
    },
    // 退报课弹窗
    showrRefundCourse (item) {
      this.selectedPlanItem = item;
      this.$refs.refundCourse.chgisShowMain();
    },
    confirmAfter (item, content) {
      console.log(item, 'item', this.planList);
      let locaIndex = this.planList.findIndex(o => {
        return o.ApplyClassTypeKey == item.ApplyClassTypeKey;
      });
      if (locaIndex >= 0) {
        this.planList[locaIndex] = this.$utils.parseJson(item);
      }
      this.confirm(content);
    }
  }
};
</script>
<style>
.course_id_aplly .apply_course_content {
  background-color: #fff;
  margin-top: 0px;
}
.course_id_aplly .apply_course_swiper {
  padding: 0.3rem 0 0.3rem;
  border-bottom: 2.5px solid #e6e6e6;
}
.course_id_aplly .apply_course_swiper .swiper-slide {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(177, 181, 196, 0.5);
  box-shadow: 0px 2px 10px 0px rgba(177, 181, 196, 0.5);
  border-radius: 4px;
  width: 7rem;
  height: 2.55rem;
  position: relative;
  padding-top: 0.19rem;
  margin: auto;
}
.course_id_aplly .student_lable {
  border-bottom: 1px solid #e6e6e6;
}
</style>



