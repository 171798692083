<template>
  <div @click.prevent="seletedItem($event)">
    <img :src="vImgUrl"
         :data-src="item.value"
         mode="">
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vImgUrl () {
      return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/' + this.item.value + '.png';
    }
  },
  methods: {
    seletedItem (event) {
      // event = event || window.event;
      // event.preventDefault();
      this.$emit('seletedItem', this.item);
      console.log('menu-item');
    }
  }
};
</script>

<style>
</style>
