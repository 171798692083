<template>
  <!-- 设置表单 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow">
        <div class="">
          <input-text :formTitle="'角色名称'"
                      :readonly="false"
                      :formPlaceholder="'角色名称'"
                      :required="true"
                      v-model="selectItem.MainDemoName"></input-text>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template> 

<script>

import { hqAddRole, hqEditRole, hqGetRole } from '../../../../API/workbench';
export default {
  data () {
    return {
      newInputText: '', // 默认值
      selectItem: { MainDemoName: this.Item.MainDemoName, OLAPKey: this.Item.OLAPKey },

      dataJson: {
        RoleKey: this.Item.OLAPKey || 0, // --角色id
        MainDemoName: '', // -- 角色名
        RolePermissionList: [] // --角色权限list
      }
    };
  },
  props: [
    'formTitleItem',
    'Item'
  ],
  components: {

  },

  created () {
    if (this.formTitleItem.key == 'add') {
      this.selectItem.MainDemoName = '';
      this.selectItem.OLAPKey = '';
    }
    if (this.Item && this.Item.OLAPKey) {
      this.getRoleList(this.Item.OLAPKey);
    }
  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 获取角色权限List
    getRoleList (OLAPKey) {
      hqGetRole(OLAPKey).then(result => {
        console.log(result, '获取角色所属模块权限1');
        if (result.data.RolePermissionList.length > 0) {
          result.data.RolePermissionList.forEach(r => {
            this.dataJson.RolePermissionList.push({ 'ModulePowerKey': r.ModulePowerKey }); ;
          });
        } else {
          this.dataJson.RolePermissionList = [];
        }
      });
    },
    // 确认按钮
    confirmClick (callBack) {
      this.dataJson.MainDemoName = this.selectItem.MainDemoName;
      if (callBack) {
        callBack();
      }
      switch (this.formTitleItem.key) {
        case 'add': // 新增角色
          delete this.dataJson.RoleKey;
          this.dataJson.RolePermissionList = [];
          if (!this.dataJson.MainDemoName) {
            layer.alert('新增角色名称不能为空');
          } else {
            hqAddRole(this.dataJson).then(result => {
              console.log(result.data, '新增角色');
              let item = {
                MainDemoName: result.data.MainDemoName,
                OLAPKey: result.data.id,
                isActive: true
              };
              this.$emit('cancelClick', item);
            }, error => {
              layer.alert(error.msg);
            });
          }

          break;
        case 'edit': // 修改角色
          if (!this.dataJson.MainDemoName) {
            layer.alert('修改角色名称不能为空');
          } else {
            hqEditRole(this.dataJson).then(result => {
              console.log(result.data, '修改角色');
              let item = {
                MainDemoName: result.data.MainDemoName,
                OLAPKey: result.data.id,
                isActive: true
              };
              this.$emit('cancelClick', item);
            }, error => {
              layer.alert(error.msg);
            });
          }
          break;
        default:
          break;
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }

};
</script>

  <style>
/*  */
</style>