<template>
<!-- 员工列表 -->
  <div class=" table_list_content summarizing_list staff_list">
    <div class="single_result_detail" style="padding-bottom: 40px;">
      <div class="introduce_summarizing_left">
        <span class="font_gray">机构名称：</span>
        <span class="font_number">{{threadInfo.SaaSClientNameKeyValue}}</span>
      </div>
      <table-list ref="tableListRef"
                  :tableData="staffList"
                  :tableColumns="tableColumns"
                  :isSearchText="false"
                  :showPagePagination="false"
                  :rowKey="'Number'"
                  :footerContent="'共'+ totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getAgentForSaaSClient"
                  @clickRow="operateBtnEvent"></table-list>  
    </div>
  </div>
</template>
<script>
import { GetAgentForSaaSClient } from '../../../../../API/workbench.js';
import tableList from '../../../../common/table-list/index';
export default {
  components: {
    tableList
  },
  props: {
    threadInfo: Object, // 线索信息
    mendianId: [Number, String]// 门店ID
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        SaaSClientNameKey: '' //	数据源：GET	 门店id
      },
      staffList: [], // 门店列表
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '员工',
          prop: 'MainDemoName',
          width: 160,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '联系人',
          prop: 'Mobile',
          width: 160,
          align: 'left',
          type: 'text-item',
          sortable: false
        }
      ],
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
  },
  mounted () {
    this.getAgentForSaaSClient();
  },
  computed: {

  },
  methods: {
    // 获取指定门店员工
    getAgentForSaaSClient () {
      console.log('threadInfo', this.threadInfo);
      let SaaSClientNameKey = this.mendianId ? this.mendianId : this.threadInfo.SaaSClientNameKey;
      GetAgentForSaaSClient(SaaSClientNameKey).then(result => {
        console.log('获取指定门店员工', result.data);
        this.totalNum = result.data.length;
        // 分页数
        if (result.data && result.data.length > 0) {
          result.data.forEach((o, i) => {
            o.Number = (i + 1) > 9 ? (i + 1) : '0' + (i + 1);
          });
          this.staffList = result.data;
        } else {
          this.staffList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    operateBtnEvent (rowData) {
      if (rowData.Number) {
        this.$emit('selectStaff', rowData);
      }
      console.log('指定门店员工', rowData);
    }
  }
};
</script>
