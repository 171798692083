<template>
  <div class="swiper-slide" @click="getItem">
                <span class="swiper-list-text "  :class="{'swiper-list-text-choose':(choosekey==item.BigCoursewarePackageKey),'display-table-cell':(item.BigCoursewarePackageKeyValue.length<5)}">{{item.BigCoursewarePackageKeyValue}} </span>
   </div>
</template>
<script>
export default {
  components: {
  },
  props: ['item','choosekey'],
  data () {
    return {
      items:{}
    };
  },
  // watch:{
  //   item:{
  //     handler(c,o){
  //       if(c){
  //        this.items = this.item
  //        console.log('item',this.items)
  //       }
  //     },
  //   },
  // },
  created () {
    
  },
  mounted () {
    // this.items = this.item
  },
  methods: {
    getItem(){
        this.$emit('chgCoureDateAfter', this.item);
    }
  }
};
</script>
<style scoped>
.swiper-slide{
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 160px !important;
  height: 60px !important;
  
}
.display-table-cell{
   display:table-cell !important;
}
.swiper-list-text{
    /* display:table-cell; */
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    color: #333333;
    align-items:center;
    vertical-align:middle;
    text-align: center;
    width: 160px;
    height: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    padding-left: 15px;
    padding-right: 15px;
    text-overflow:-o-ellipsis-lastline;
    overflow:hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow:hidden;  */
    /* text-overflow:ellipsis; */
    /* white-space: nowrap; */
    
}
.swiper-list-text-choose{
  background: linear-gradient(270deg, #1A9AF6 0%, #2CBFF2 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  color: #FFFFFF !important;
}
</style>