import Vue from 'vue';
import store from '../store';
import { outLogin } from '../assets/login';
import { GetRole, GetTheSaaSClientMsg, UpdateUserState } from '../API/workbench';
import asyncTask from './async-task.js';
import bus from '../js/bus.js';
import {formateDateYMRHMS} from '../utils/index.js';
import basicData from './basic-data';

const websocket = new Vue({
  data () {
    return {
      ws: null, // 建立的连接
      type: '',
      lockReconnect: false, // 是否真正建立连接
      timeout: 60 * 1000, // 30秒一次心跳
      timeoutObj: null, // 心跳心跳倒计时
      serverTimeoutObj: null, // 心跳倒计时
      timeoutnum: null, // 断开 重连倒计时
      responseData: null // websocket onmessage返回值
    };
  },
  created () { },
  methods: {
    init (type) {
      let preClientString = '';
      let preClientId = store.getters.clientId;
      if (preClientId) {
        preClientString = '&pre_client_id=' + preClientId;
      }
      console.log('websocket init', type);
      this.type = type;
      this.wsDispose();
      try {
        if (this.type == 'login') {
          this.ws = new WebSocket(store.getters.WSURL);
        } else {
          let signdata = JSON.stringify(store.getters.signData);
          this.ws = new WebSocket(
            store.getters.WSURL +
            '?transferUrl=' +
            encodeURIComponent(store.getters.APIURL) +
            '&signdata=' +
            encodeURIComponent(signdata) +
            preClientString
          );
        }
        this.ws.onopen = this.onopen;
        this.ws.onmessage = this.onmessage;
        this.ws.onclose = this.onclose;
        this.ws.onerror = this.onerror;
      } catch (e) {
        console.log('websocket init catch: ', e.code + ' ' + e.reason + ' ' + e.wasClean, e);
        setTimeout(() => {
          console.log('catch:', '重连');
          this.init(this.type);
        }, 5000);
      }
    },
    wsDispose () {
      this.clearCheck();
      this.timeoutnum && clearTimeout(this.timeoutnum);

      if (this.ws) {
        if (this.ws.readyState == 1) {
          // 如果连接正常
          console.log('wsDispose close');
          this.ws.close();
        }
        this.ws.onopen = null;
        this.ws.onmessage = null;
        this.ws.onWsClose = null;
        this.ws.onWsError = null;
        this.ws = null;
      }
    },
    onclose (e) {
      console.log('websocket 断开 onclose: ', e.code + ' ' + e.reason + ' ' + e.wasClean, e);
      if (this.ws) {
        if (this.ws.readyState == 1) {
          // 如果连接正常
          console.log('onclose close');
          this.ws.close();
        }
      }
    },
    onerror (e) {
      console.log('websocket 断开 onerror: ', e.code + ' ' + e.reason + ' ' + e.wasClean, e);
      setTimeout(() => {
        console.log('onerror:', '重连');
        this.init(this.type);
      }, 5000);
    },
    onopen () {
      // 开启心跳
      this.startCheck();
    },
    onmessage (result) {
      // 收到服务器信息，心跳重置
      this.resetCheck();
      // data ={
      //       code:0, //0 表示成功  80001-签名错误;80002-门店被停用;80003-账号已离职;
      //       msg:"", //异常时，提供错误原因
      //       data:{
      //         eventType:1, // 1-连接成功;2-签名错误; 3-手机掌柜通用设置：班级、课名课类;4-门店设置;5-待办事项;6-当日教务;7-签到异常;8-识别码登录;
      //         sendType:3,  //1-全门店;2-指定用户;3-单点;
      //         sendData:{
      //           dataType:"Agent", //返回数据类型
      //           dataID:0,         //数据关键Key
      //           newData:{}        //具体业务数据
      //         }
      //       }
      //     }
      let data = JSON.parse(result.data);
      console.log(data, '长连接-结果');
      this.responseData = data.data;
      if (
        !store.getters.token &&
        this.responseData.eventType != 1 &&
        this.responseData.eventType != 8
      ) {
        return false;
      }
      if (data.code == 0) {
        switch (this.responseData.eventType) {
          case 1: // 连接成功
            console.log('responseEvent1', this.responseData);
            store.commit('setClientId', this.responseData.sendData.newData);
            bus.emit('wsConnectSuceess', this.responseData.sendData);
            this.openUpdateUserState();
            bus.emit('wsVerifyISEndVisit', this.responseData.sendData);
            // uni.$emit('VerifyISEndVisit');
            break;
          case 3: // 通用基础资料设置
            console.log(
              'responseEvent3:' + this.responseData.sendData.dataType,
              this.responseData
            );
            this.basicDataUpdate();
            break;
          case 4: // 门店设置
            console.log('responseEvent4', this.responseData);
            store.commit(
              'setSaaSClientInfo',
              this.responseData.sendData.newData
            );
            if (this.responseData.sendData.newData.IfDel == 1) { // 门店已删除
              this.wsDispose();
              layer.alert(data.msg);
              outLogin();
            } else if (this.responseData.sendData.newData.IsCloseKey == 1) { // 门店已停关
              this.wsDispose();
              layer.alert(data.msg);
              outLogin();
            } else {
              bus.emit(
                'wsUpdateSaasClientInfo',
                this.responseData.sendData.newData
              );
            }
            break;
          case 5: // 待办事项
            console.log('responseEvent5', this.responseData);
            bus.emit('wsTodoList', this.responseData.sendData);
            break;
          case 6: // 当日教务
            console.log('responseEvent6', this.responseData);
            bus.emit('wsAttendanceOrArrange', this.responseData.sendData);
            break;
          case 7: // 签到异常
            console.log('responseEvent7', this.responseData);
            bus.emit('wsAfterSignWarning', this.responseData.sendData.newData);
            break;
          case 8:
            console.log('responseEvent8', this.responseData);
            bus.emit('wsAfterLoginSuceess', this.responseData.sendData.newData);
            break;
          case 9: // 系统更新
            console.log('responseEvent9', this.responseData);
            if (this.responseData.sendData && this.responseData.sendData.newData.eventName == '开启刷脸签到') {
              console.log('触发开启刷脸');
              bus.emit('wsAfterOpenFace', this.responseData.sendData.newData);
            } else {
              layer.confirm('系统已更新?请问是否立即刷新页面', {
                btn: [
                  {
                    name: '确认',
                    callBack: function () {
                      location.reload();
                      layer.close();
                    }
                  }
                ]
              });
            }
            break;
          case 10: // 批量老生录入打开通知
            console.log('responseEvent10', this.responseData);
            bus.emit('wsQuitBatchAddOldStudent', this.responseData.sendData.newData);
            break;
          // case 2:// 签名错误
          //   console.log('responseEvent2', sendData);
          //   break;
          case 11: // 开启刷脸.
            console.log('responseEvent10', this.responseData);
            break;
          case 14: // 三方公众号托管授权.
            console.log('responseEvent14', this.responseData);
            layer.alert(data.msg);
            GetTheSaaSClientMsg().then(result => {
              store.commit('setSaaSClientInfo', result.data);
              bus.emit('wsUpdateSaasClientInfo', result.data);
            });
            bus.emit(
              'thirdpartyTencentMandate',
              true,
              this.responseData.sendData.newData
            );
            break;
          case 16: // 异步任务执行通知
            console.log('responseEvent16', this.responseData.sendData);
            this.asyncTaskExecuteProgress();
            break;
          case 17: //  清理完门店数据（指基础数据）执行通知
            console.log('responseEvent17', this.responseData.sendData);
            store.commit('setCourseNameList', []);
            store.commit('setGradeClassList', []);
            store.commit('setTeacherList', []);
            layer.alert('基础数据已清理成功!');
            // 待处理，需跳转刷新
            break;
          case 18: //  招生战况长链接
            console.log('responseEvent18', this.responseData.sendData);
            bus.emit('wsZSZKUpData', this.responseData.sendData);
            // 待处理，需跳转刷新
            break;
          case 19: // 异步任务执行通知
            console.log('responseEvent19', this.responseData.sendData);
            this.asyncTaskExecuteProgress();
            break;
          case 20: // 课件编辑排他
            console.log('responseEvent20', this.responseData.sendData);
            bus.emit('wsCoursewareEditor', this.responseData.sendData);
            break;
          case 22: //  //课件互斥
            console.log('responseEvent22', this.responseData.sendData);
            bus.emit('wsCoursewareMutualExclusion', this.responseData.sendData);
            break;
          case 23: // 强制结束上课
            console.log('responseEvent20', this.responseData.sendData);
            bus.emit('wsquitAttendClassPreview', this.responseData.sendData);
            break;
          case 24: // 充值流量
            console.log('wsFlowSendsMessage', this.responseData.sendData);
            bus.emit('wsFlowSendsMessage', this.responseData.sendData);
            break;
          case 25: // 充值余额
            console.log('responseEvent20', this.responseData.sendData);
            bus.emit('wsrechargeTheBalance', this.responseData.sendData);
            break;
          case 26: // 课件授权验证通知。
            console.log('responseEvent26', this.responseData.sendData);
            let dataInfo = this.responseData.sendData.data;
            dataInfo.time = formateDateYMRHMS(new Date());
            console.log('formateDateYMRHMS', dataInfo);
            store.commit('setGeoInfo', dataInfo);
            bus.emit('scanToAuthCourseware', this.responseData.sendData);
            break;
          case 29: // 重复登录
            console.log('responseEvent29', this.responseData.sendData);
            let sendData = this.responseData.sendData;
            // let sendData={
            //   msg:'重复登录'
            // }
            bus.emit('LoginReply', this.responseData.sendData);
            store.commit('setLoginReply', sendData);
            outLogin();
            // bus.emit('scanToAuthCourseware', this.responseData.sendData);
            break;
          case 30: // 视频封面生成
            console.log('responseEvent30', this.responseData.sendData);
            bus.emit('setVedioCoverl', this.responseData.sendData);
            break;
          case 32: // 异步导出
            console.log('responseEvent32', this.responseData.sendData);
            bus.emit('showExportDataList', this.responseData.sendData);
            break;
          default:
            console.log('default', this.responseData);
            break;
        }
      } else {
        if (this.type != 'login') {
          if (data.code == 80002 || data.code == 80003) {
            // 80002 门店被停用 , 80003 账号已离职
            this.wsDispose();
            layer.alert(data.msg);
            outLogin();
          } else if (data.code == 10000) {
            layer.alert(data.msg);
          }
          bus.emit('thirdpartyTencentMandate');
        }
      }
    },
    // 异步任务执行进度通知
    asyncTaskExecuteProgress () {
      let sendData = this.responseData.sendData;
      let asyncTaskID = sendData.AsynTaskID; // 异步任务ID
      if (asyncTaskID) {
        asyncTask.execute(asyncTaskID, sendData);
      }
      if (this.responseData.eventType == 19) {
        if (this.responseData.sendType == 2 && sendData.status == 0) {
          bus.emit('pptVerifyFail', sendData);
        } else if (this.responseData.sendType == 3) {
          bus.emit('pptPageVerify', sendData);
        }
      }
    },
    basicDataUpdate () {
      let token = store.getters.token;
      let newData = this.responseData.sendData.newData;
      switch (this.responseData.sendData.dataType) {
        case 'Agent':
          // 更改了当前登录用户的角色
          if (token && token.UserID == newData.OLAPKey && token.RoleKey != newData.Chose2Key) {
            token.RoleKey = newData.Chose2Key;
            GetRole(newData.Chose2Key).then(result => {
              let newRoleList = result.data.RolePermissionList;
              if (newRoleList.length > 0) {
                this.updateRolePowerList(newRoleList, token);
              } else {
                this.updateRolePowerList([], token);
              }
            });
          } else if (token && token.UserID == newData.OLAPKey && newData.IsLeaveKey == 0) {
            setTimeout(() => {
              layer.alert('员工已离职，不可继续浏览');
              outLogin();
            }, 1000);
          } else {
            bus.emit('wsAfterUpdateAgentInfo', this.responseData.sendData.newData);
          }

          break;
        case 'Role':
          if (Number(newData.IfDel) == 0) {
            // 修改权限
            this.updateRolePowerList(newData.RolePower, token);
            bus.emit('wsAfterUpdateRole', this.responseData.sendData);
          } else {
            // 删除权限
            token.RoleKey = 0;
            this.updateRolePowerList([], token);
            location.reload();
          }
          break;
        case 'ClassType':
          let courseNameList = store.getters.courseNameList;
          let existsIndex = courseNameList.findIndex(obj => {
            return obj.OLAPKey == newData.OLAPKey;
          });
          if (existsIndex >= 0) {
            courseNameList.splice(existsIndex, 1, newData);
            store.commit('setCourseNameList', courseNameList);
          } else {
            store.commit('setCourseNameList', null);
          }
          break;
        case 'GradeClass':
          let gradeClassList = store.getters.gradeClassList || [];
          if (gradeClassList) {
            let gradeClassIndex = gradeClassList.findIndex(obj => {
              return obj.OLAPKey == newData.OLAPKey;
            });
            if (gradeClassIndex >= 0) {
              gradeClassList.splice(gradeClassIndex, 1, newData);
              store.commit('setGradeClassList', gradeClassList);
            } else {
              store.commit('setGradeClassList', null);
            }
          }
          break;
        default:
          break;
      }
    },
    // 变更权限列表
    updateRolePowerList (rolePowerList, token) {
      let newRolePowerList = [];
      rolePowerList.forEach(obj => {
        newRolePowerList.push(obj.ModulePowerKey);
      });
      token.RolePowerList = newRolePowerList;
      store.commit('SetToken', token);
      this.$fun.loginInfo.setCookie('token', JSON.stringify(token));
    },
    onWsClose (e) {
      console.log('websocket 断开 onWsClose: ', e.code + ' ' + e.reason + ' ' + e.wasClean, e);
      this.ws = null;
    },
    onWsError (e) {
      console.log('websocket 断开 onWsError: ', e.code + ' ' + e.reason + ' ' + e.wasClean, e);
    },
    reconnect () {
      // 重新连接
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      // 没连接上会一直重连，设置延迟避免请求过多
      this.timeoutnum && clearTimeout(this.timeoutnum);
      this.timeoutnum = setTimeout(() => {
        this.init(this.type);
        this.lockReconnect = false;
      }, 5000);
    },
    resetCheck () {
      // 重置心跳
      clearTimeout(this.timeoutObj);
      this.startCheck();
    },
    clearCheck () {
      // 清除心跳
      if (this.timeoutObj) {
        clearTimeout(this.timeoutObj);
      }
    },
    openUpdateUserState () {
      if (store.getters.token && store.getters.token.UserID) {
        UpdateUserState(store.getters.token.UserID, this.$utils.formatDateToBias(Date.new()), ['1']).then(res => {
          console.log(res);
        }, err => {
          console.log(err);
        });
      }
    },
    startCheck () {
      // 开启心跳
      store.commit('setWebsocketReadyState', this.ws.readyState);
      this.clearCheck();
      this.timeoutObj = setTimeout(() => {
        try {
          if (this.ws.readyState == 1) {
            // 如果连接正常
            this.ws.send('Check');
            this.startCheck();
          } else {
            console.log('this.ws.readyState', this.ws.readyState);
            // 否则重连
            this.reconnect();
          }
        } catch (e) { /* 你感觉会出错的 错误类型 */
          console.log('websocket startCheck catch: ', e.code + ' ' + e.reason + ' ' + e.wasClean, e);
          this.reconnect();
        }
      }, this.timeout);
    },
     // pm 外部拼好的参数data
    commonWebsocketApi (pm) { // 外部调用websocket接口
      if (this.ws.readyState == 1) { // 如果连接正常
        console.log('发送请求', pm);
        // let a = JSON.parse(pm.data)
        this.ws.send(JSON.stringify(
          pm
        ));
      } else { // 否则提醒
        console.log('发送请求失败gggggg');
      }
    }

  }
});

export default websocket;

// websocket 长连接通知 服务器主动通知前端变更相应数据
