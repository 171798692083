<template>
  <div class="hearbar_mendianInfo massive_bg">
    <div class="mendian_info_top">
      <div class="mendian_info_img">
        <img :src="vMendianLogo"
             alt="" />
      </div>
      <div class="flex_column mendian_info_name pr">
        <div class="mendian_info_name_text"
             :title="vMDInfo.MainDemoName">{{ vMDInfo.MainDemoName }}
          <span class="">
            <!-- v-if="$store.getters.SaaSClientEditionKey == 5" -->
            {{$store.getters.SaaSClientEditionKey == 5?'总部':'校区'}}
          </span>
        </div>
        <div class="mendian_info_name_id">系统账号ID：{{ vMDInfo.SaaSClientNO }}</div>

      </div>
      <div class="mendian_info_general"
           v-if="vMendianGeneral">{{vMendianGeneral}}</div>
    </div>
    <div class="mendian_acount"
         v-if="$store.getters.SaaSClientEditionKey != 6">
      <div class="mendian_acount_title">账户期限</div>
      <div class="mendian_acount_text">
        <span class="IDspan">
          <span v-if="
              Date.new(
                Date.new(vMDInfo.ExpireDateTime).setDate(
                  Date.new(vMDInfo.ExpireDateTime).getDate() + 1
                )
              ) > Date.new()
            ">余</span>
          <span v-else>过期</span>
          <span :class="
              Date.new(vMDInfo.ExpireDateTime) > Date.new()
                ? 'font_black'
                : 'font_red'
            ">
            {{ vExpireDateTime }} </span>天
        </span>
        <!-- <span class="miandian_renew button">
            续费
        </span> -->
      </div>
    </div>
    <div class="mendian_list_title">切换校区</div>
    <div class="mendian_list">
      <mendian-list v-for="(item, index) in allSaasClientList"
                    :key="index"
                    :item="item"
                    @switchSaasClient="switchSaasClient">
      </mendian-list>
    </div>
    <div class="mendian_reback">
      <div @click="outLoginClick">
        退出账号
      </div>
    </div>

  </div>
</template>
<script>
import {
  GetSwitchableSaaSClient,
  GetEmployeeInfo,
  SwitchSaaSClient,
  SpecialLongLink,
} from "../../API/workbench";
import mendianList from './mendian-list'

import { outLogin, doReLogin } from "../../assets/login";
export default {
  data () {
    return {
      allSaasClientList: [],
      token: {},
    };
  },
  props: {},
  components: {
    mendianList
  },
  created () {
    this.token = this.$store.getters.token;
    this.getAllSaaSClient();
  },
  computed: {
    //  2-精准;3-通用;4-高级;5-连锁;
    vMendianGeneral () {
      if (this.vMDInfo.EditionKey == 2) {
        return '精准版';
      } else if (this.vMDInfo.EditionKey == 3) {
        return '旗舰版';
      } else if (this.vMDInfo.EditionKey == 4) {
        return '高级版';
      } else if (this.vMDInfo.EditionKey == 5) {
        return '连锁版';
      }
    },
    vMendianLogo () {
      let img = this.$store.getters.SaaSClientInfo.LogoPath;
      if (!img || img == "/public/image/no_logo@2x.png") {
        return require("../../../public/image/no_logo@2x.png");
      }
      if (img.indexOf("http") > -1) {
        return img;
      } else {
        return (
          this.$store.getters.CDNURL +
          this.$store.getters.SaaSClientInfo.LogoPath
        );
      }
    },
    vMDInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vExpireDateTime () {
      let today = this.$utils.formatDateToLine(Date.new()).replace(/\-/g, "/");
      if (this.vMDInfo.ExpireDateTime) {
        let endDate = this.vMDInfo.ExpireDateTime.replace(/\-/g, "/");
        if (this.$utils.diffTwoTime(endDate, today) == 0) {
          return 1;
        } else {
          if (endDate > today) {

            return this.$utils.diffTwoTime(endDate, today) + 1;
          } else {

            return this.$utils.diffTwoTime(endDate, today) + 1;
          }
        }
      }
    },
  },
  methods: {
    // 获取所有门店
    getAllSaaSClient () {
      this.allSaasClientList = [];
      GetSwitchableSaaSClient().then((result) => {
        result.data.forEach((obj) => {
          // if (obj.SaaSClientKey != this.token.SaaSClientKey) {
          //     this.allSaasClientList.push(obj);
          // }
          if (obj.SaaSClientKey == this.token.SaaSClientKey) {
            obj.isActive = true;
          } else {
            obj.isActive = false;
          }
          this.allSaasClientList.push(obj);
        });
      });
    },

    // 切换门店
    switchSaasClient (item) {
      this.$emit("switchSaasClient", item)

    },
    // 获取员工信息
    loadEmployeeDetailInfo () {
      return GetEmployeeInfo(this.token.UserID);
    },
    // 退出门店
    outLoginClick () {
      this.$nextTick(() => {
        outLogin();
      });
    },
  },
};
</script>
<style scoped>
.hearbar_mendianInfo {
  position: absolute;
  top: 52px;
  right: 18px;
  display: flex;
  flex-direction: column;
  width: 399px;
  /* height: 600px; */
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: white;
  z-index: 3;
  padding: 26px;
  z-index: 2000;
}
.hearbar_mendianInfo .mendian_info_top {
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.hearbar_mendianInfo .mendian_acount .mendian_acount_title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.hearbar_mendianInfo .mendian_acount .mendian_acount_title::before {
  display: block;
  content: '';
  position: absolute;
  top: 1px;
  left: -27px;
  height: 20px;
  width: 20px;
  background: url('../../../public/image/clock_type_1.png') center no-repeat;
  background-size: 19px;
}

.hearbar_mendianInfo .mendian_acount .mendian_acount_text {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  text-align: center;
  align-items: center;
}
.miandian_renew {
  width: 46px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid #0000001a;
  color: #333;
}
.hearbar_mendianInfo .mendian_info_img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}
.hearbar_mendianInfo .mendian_info_img img {
  width: 80px;
  height: 80px;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.hearbar_mendianInfo .mendian_info_name {
  justify-content: center;
  margin-left: 20px;
  height: 80px;
  position: relative;
  overflow: visible;
  max-width: 205px;
}
.mendian_info_general {
  position: absolute;
  left: 15px;
  top: 69px;
  width: 50px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  background: #f276014d;
  color: #f27601;
}
.hearbar_mendianInfo .mendian_info_name_text {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-top: -20px;
  margin-bottom: 10px;
  /* position: relative; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hearbar_mendianInfo .mendian_info_name_text span {
  width: 35px;
  height: 16px;
  background: #f27601;
  color: white;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  display: block;
  text-align: center;
  border-radius: 8px;
  right: -40px;
  top: -6px;
}
.hearbar_mendianInfo .mendian_info_name_id {
  font-size: 14px;
  font-weight: 400;
}

.hearbar_mendianInfo .mendian_acount {
  width: 339px;
  height: 71px;
  border-radius: 8px;
  background: #f6f7f8;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-right: 20px;
}
.hearbar_mendianInfo .mendian_list {
  /* background: #f6f7f8; */
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 20px 0px;
  height: 280px;
  width: 100%;
  padding: 5px;
}
.hearbar_mendianInfo .mendian_list_title {
  font-size: 16px;
  font-weight: 500;
}
.mendian_reback {
  flex: 1;
  display: flex;
  justify-content: center;
  background: url('../../../public/image/back_type.png') 5px center no-repeat;
  background-size: 14px;
  width: 100px;
  margin: auto;
  padding-left: 15px;
  cursor: pointer;
}
.mendian_reback:hover {
  color: #3498db !important;
}
.mendian_list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}
.mendian_list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cdd1d8;
}
.massive_bg {
  border: 2px solid #f2f2f2;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 2px #ececec;
}
</style>
