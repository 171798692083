 <template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL content_marginTop_30">
      <div class="form_info_edit">
        <div class="form_info_line">
          <div class="form_info_field form_info_field_timeInput">时长</div>
          <div class="form_info_value duration">
            <input type="text"
                   class="duration_style"
                   placeholder="可填"
                   :value="mistiming +'分钟'"
                   readonly />
          </div>
        </div>
      </div>
      <div class="form_info_edit">
        <div class="form_info_line form_info_required">
          <div class="form_info_field form_info_field_timeInput">
            <span>上课时间</span>
            <span v-if="helpDescription">
              <input-form-tip :tips='helpDescription'></input-form-tip>
            </span>
          </div>
          <div class="form_info_value_HCQ form_info_timeInput">
            <div>
              <time-picker :timeRange="timeRangeForClassTime"
                           :placeholder="'必填'"
                           :dateNumber="date.startTime"
                           @upDateNumber="chgStartTime"></time-picker>
              至
              <time-picker :timeRange="timeRangeForClassTime"
                           :placeholder="'必填'"
                           :dateNumber="date.endTime"
                           @upDateNumber="chgEndTime"></time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <common-button-group @buttonClickEvent="doConfirmClick"
                         @closeDialog="doCloseShowForm"></common-button-group>
  </div>
</template>

<script>
import timePicker from '../common/time-picker';
export default {
  data () {
    return {
      date: {
        startTime: this.StartTime || '',
        endTime: this.EndTime || '' // 绑定结束周期
      },
      timeRangeForClassTime: '',
      mistiming: 0,
      saasClientInfo: this.$store.getters.SaaSClientInfo
    };
  },
  components: {
    timePicker
  },
  props: {
    StartTime: String,
    EndTime: String,
    helpDescription: String // 表单说明提示
  },
  created () {
    this.timeRangeForClassTime = this.$utils.getSaaSClientSetInfo(64).SetContent + ':00-' + this.$utils.getSaaSClientSetInfo(65).SetContent + ':00';
    this.copyEditData();
  },
  watch: {
    'date.startTime' (n, o) {
      if (this.date.startTime) {
        let latestHour = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[0]);
        let latestMin = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[1]);
        var mins = Number(this.date.startTime.slice(0, 2)) * 60 + Number(this.date.startTime.slice(3, 5)) + this.mistiming; // 结束时间的总分钟数 有异常 会显示NaN (isNaN(this.mistiming) == true ? 60 : this.mistiming)
        var hour = 0;
        var min = 0;
        min = mins % 60;
        hour = (mins - min) / 60;
        if (hour >= latestHour) {
          hour = latestHour;
          min = latestMin;
        }
        this.date.endTime = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min);  // 计算结束时间值
      }
    },
    'date.endTime' (n, o) {
      if (this.date.endTime && this.date.startTime) {
        var EClassTime = this.date.endTime.slice(0, 2) * 60 + Number(this.date.endTime.slice(3, 5));
        var SClassTime = this.date.startTime.slice(0, 2) * 60 + Number(this.date.startTime.slice(3, 5));
        // 开始时间再次切换为10点之前的会导致结束时间的小时部分缺少0导致数据为NaN
        if (!EClassTime) {
          EClassTime = this.date.endTime.slice(0, 1) * 60 + Number(this.date.endTime.slice(2, 4));
        }
        this.mistiming = Number(EClassTime - SClassTime);
      }
    }
  },
  methods: {
    // 传递按钮事件
    doConfirmClick () {
      var a = Number(this.date.startTime.replace(/\:/g, '')); // 开始日期
      var b = Number(this.date.endTime.replace(/\:/g, '')); // 结束日期
      if (this.date.startTime == '' || this.date.endTime == '') {
        layer.alert('请检查是否完善时间段');
      } else if (a == b) {
        layer.alert('开始时间不可等于结束时间');
      } else if (a > b) {
        layer.alert('开始时间不可晚于结束时间');
      } else {
        this.doCloseShowForm(this.date);
        this.$emit('onConfirmClick', this.date);
      }
    },
    // 点击取消
    doCloseShowForm (date) {
      this.$emit('cancel', date);
    },
    copyEditData () {
      if (!this.StartTime) {
        this.date.startTime = this.$utils.getSaaSClientSetInfo(64).SetContent;
      }
      if (!this.EndTime) {
        let startHour = Number(this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0]);
        let endHour = startHour + 1 > 9 ? startHour + 1 : '0' + (startHour + 1);
        let startMin = this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[1];
        this.date.endTime = endHour + ':' + startMin;
      }
      this.timeRangeValue = this.date.startTime + '-' + this.date.endTime;
      this.caculMistiming();
    },
    caculMistiming () {
      if (this.StartTime && this.EndTime) {
        var EClassTime = this.EndTime.slice(0, 2) * 60 + Number(this.EndTime.slice(3, 5));
        var SClassTime = this.StartTime.slice(0, 2) * 60 + Number(this.StartTime.slice(3, 5));
        // 开始时间再次切换为10点之前的会导致结束时间的小时部分缺少0导致数据为NaN
        if (!EClassTime) {
          EClassTime = this.EndTime.slice(0, 1) * 60 + Number(this.EndTime.slice(2, 4));
        }
        this.mistiming = Number(EClassTime - SClassTime);
      }
    },
    chgStartTime (date) {
      this.date.startTime = date;
    },
    chgEndTime (date) {
      this.date.endTime = date;
    }
  },
  computed: {}
};
</script>
