<template>
  <!-- 收款方式表单 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'科目名称'"
                  :maxLength="14"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>

      <input-dialog :formTitle="'上级科目'"
                    :readonly="isReadonly"
                    :required="true"
                    @showEldilog="showSuperiorSubjectList"
                    :value="dataJson.FatherKeyValue"></input-dialog>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonly"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
    <!-- 上级科目 -->
    <custom-dialog :title="dataJson.IncomePayTypeKey==1?'收入科目':'支出科目'"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isShowSuperiorSubjectList"
                   :before-close="closeDialog">
      <div v-if="subjectList.length>0">
        <superior-subject-list :subjectList="subjectList"
                               :dataInfo="dataJson"
                               @selectedItem="chgSuperiorSubjectItem"></superior-subject-list>
      </div>
      <div v-else>
        <div class="monitoring_content_nodata">
          <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import {
  hqMessageSearchPageForIncomePaySubject,
  hqAddIncomePaySubject,
  hqEditIncomePaySubject
} from '../../../../API/workbench.js';
import superiorSubjectList from './superior-subject-list.vue';
export default {
  components: {
    superiorSubjectList
  },
  data () {
    return {
      isShowSuperiorSubjectList: false, //
      accountTypeList: [{
        id: 1,
        name: '收'
      }, {
        id: 2,
        name: '支'
      }],
      subjectList: [], // 上级科目列表
      accountTypeItem: {}, // 收支类型信息
      superiorsubjectItem: {}, // 上级科目信息
      isload: false, // 加载数据完成
      // 数据对象
      dataJson: {
        MainDemoName: '', // -- 科目名称
        FatherKey: '2', // --上级科目id 为全部
        FatherKeyValue: '全部', // --上级科目
        CommentSupport: '', // -- 备注
        IncomePayTypeKey: '1', // --收支类型Key 1-收;2-支
        IncomePayTypeKeyValue: '收' // --收支类型KeyValue 1-收;2-支
      },
      // 查询数据
      parameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0, // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
        IncomePayTypeKey: '' // 	收支类型 1-收;2-支
      }
    };
  },
  props: {
    isReadonly: { // 是否自读
      type: Boolean,
      default: false
    },
    dataInfo: { // 表单信息
      type: Object,
      default: {}
    },
    subjectType: { // 科目类型
      type: [String, Number],
      default: ''
    }
  },
  created () {
    if (this.dataInfo.OLAPKey) {
      Object.assign(this.dataJson, this.dataInfo);
      console.log(this.dataJson, '表单信息2');
    }
    // 上级科目
    if (this.dataInfo.FatherKey) {
      this.dataJson.FatherKey = this.dataInfo.FatherKey;
      this.dataJson.FatherKeyValue = this.dataInfo.FatherKeyValue;
    }
    // 上级科目
    if (this.dataInfo.IncomePayTypeKey) {
      this.dataJson.IncomePayTypeKey = this.dataInfo.IncomePayTypeKey;
      this.dataJson.IncomePayTypeKeyValue = this.dataInfo.IncomePayTypeKeyValue;
    }
    this.getProceedsSubjectList();
  },
  computed: {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (this.dataJson.MainDemoName) {
        this.dataJson.MainDemoName = this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入科目名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.dataJson.OLAPKey) {
        this.editOperation(callBack);
      } else {
        this.addOperation(callBack);
      }
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    showSuperiorSubjectList () {
      console.log('选择上级科目2');
      this.isShowSuperiorSubjectList = true;
    },
    closeDialog () {
      this.isShowSuperiorSubjectList = false;
    },
    // 新增
    addOperation (callBack) {
      hqAddIncomePaySubject(this.dataJson).then(result => {
        this.$emit('afterSuccess', 'Add', result.data);
        layer.alert('新增成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 修改
    editOperation (callBack) {
      delete this.dataJson.TypeKey;
      hqEditIncomePaySubject(this.dataJson).then(result => {
        this.$emit('afterSuccess', 'Edit', result.data);
        layer.alert('修改成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 选择上级科目
    chgSuperiorSubjectItem (item) {
      this.dataJson.FatherKey = item.OLAPKey;
      this.dataJson.FatherKeyValue = item.MainDemoName;
      console.log(item, '选择上级科目2');
      this.isShowSuperiorSubjectList = false;
    },
    getProceedsSubjectList () {
      hqMessageSearchPageForIncomePaySubject(this.parameter).then(result => {
        var proceedsSubjectList = [];
        proceedsSubjectList = result.data.PageDataList.filter(obj => {
          this.$set(obj, 'childSubject', []);
          this.$set(obj, 'isOpt', false);
          return obj.StatusKey == 1 && Number(obj.IncomePayTypeKey) == Number(this.dataJson.IncomePayTypeKey);
        }); // 显示启用的数据
        var dataList = proceedsSubjectList.filter(obj => {
          this.$set(obj, 'level', 1);
          return Number(obj.FatherKey) == 2;
        });
        var childList = proceedsSubjectList.filter(obj => {
          this.$set(obj, 'level', 2);
          return Number(obj.FatherKey) > 2;
        });
        this.subjectList = this.loopData(dataList, childList, this.proceedsSubjectList);
        console.log(this.subjectList, '数据重组');
      }, error => {
        layer.alert(error.msg);
      });
    },
    loopData (fatherList, childrenList, treeData) {
      const arr = [];
      childrenList.forEach(child => {
        let isExist = false;
        fatherList.forEach(father => {
          if (father.OLAPKey == child.FatherKey) {
            isExist = true;
            child.level = father.level + 1;
            father.childSubject.push(child);
            return false;
          }
        });
        if (!isExist) {
          arr.push(child);
        }
      });
      fatherList.forEach(item => {
        if (item.childSubject.length > 0) {
          this.loopData(item.childSubject, arr, treeData);
        }
      });
      return fatherList;
    }
  }
};
</script>

<style>
</style>
