<template>
  <div class="payment_statistics_top">
    <div class="payment_moneyType_ul">
      <div class="payment_moneyType_list "
           @click.stop="changeTypeSearch(0)"
           :class="searchType==0?'opt':''">
        <div class="list_title">本月收支净额</div>
        <div class="list_content">
          ￥{{$utils.setMoneyformatToParseInt(reportDetail.MonthAmount)}}
        </div>
      </div>
      <div class="payment_moneyType_list"
           @click.stop="changeTypeSearch(1)"
           :class="searchType==1?'opt':''">
        <div class="list_title">本年收支净额</div>
        <div class="list_content">
          ￥{{$utils.setMoneyformatToParseInt(reportDetail.YearAmount)}}
        </div>
      </div>
      <div class="payment_moneyType_list"
           @click.stop="changeTypeSearch(2)"
           :class="searchType==2?'opt':''">
        <div class="list_title">累计收支净额</div>
        <div class="list_content ">
          ￥{{$utils.setMoneyformatToParseInt(reportDetail.TotalAmount)}}
        </div>
      </div>
    </div>
    <div class="payment_echarts_box">
      <div class="payment_echarts_dual">
        <div class="payment_echarts_text"
             @click.stop="doAfterClickEchartForReceipt({})">
          收款分析：<span class="font_blue">{{receiptDetail.TotalNum}}</span> 笔共 <span class="font_blue">{{isAdd(receiptDetail.TotalAmount)}}{{$utils.setMoneyformatToParseInt(receiptDetail.TotalAmount)}}</span> 元
        </div>
        <div class="echarts_box pr"
             style="margin-top:40px"
             v-show="Number(receiptDetail.TotalAmount)>0">
          <circleEchart :echartID="'circle_echart_02'"
                        :reqList="monthList"
                        :nowDataList="receiptList"
                        :monthTitle="vtipstitle"
                        @clickItem="doAfterClickEchartForReceipt"
                        ref="receiptEchart"></circleEchart>
          <div class="center_tips"
               :style="setSizeByLength(Number(receiptDetail.TotalAmount))"
               v-if="Number(receiptDetail.TotalAmount)>0">
            <div class="font_gray">收款总金额</div>
            <div>{{$utils.setMoneyformatToParseInt(receiptDetail.TotalAmount)}}</div>
          </div>
        </div>
        <div v-show="Number(receiptDetail.TotalAmount)==0"
             style="width:220px;height:100%">
          <div class="monitoring_content_nodata"
               style="margin-top: 60px;">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
      <div class="payment_echarts_dual">
        <div class="payment_echarts_text"
             @click.stop="doAfterClickEchartForPayment({})">
          付款分析：<span class="font_blue">{{paymentDetail.TotalNum}}</span> 笔共 <span class="font_blue">{{isDebt(paymentDetail.TotalAmount)}}{{$utils.setMoneyformatToParseInt(paymentDetail.TotalAmount)}}</span> 元
        </div>
        <div class="echarts_box pr"
             style="margin-top:40px"
             v-show="Number(paymentDetail.TotalAmount)>0">
          <circleEchart :echartID="'circle_echart_01'"
                        :reqList="monthList"
                        :nowDataList="paymentList"
                        :monthTitle="vtipstitle"
                        @clickItem="doAfterClickEchartForPayment"
                        ref="paymentEchart"></circleEchart>
          <div class="center_tips"
               :style="setSizeByLength(Number(paymentDetail.TotalAmount))"
               v-if="Number(paymentDetail.TotalAmount)>0">
            <div class="font_gray">付款总金额</div>
            <div>{{$utils.setMoneyformatToParseInt(paymentDetail.TotalAmount)}}</div>
          </div>
        </div>
        <div v-show="Number(paymentDetail.TotalAmount)==0"
             style="width:220px;height:100%">
          <div class="monitoring_content_nodata"
               style="margin-top: 60px;">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import circleEchart from '../../base-module/echart-common/circle-echart-money-num';
import { GetInComePayReportForDate, GetInComePayReportForExpend, GetInComePayReportForProceeds } from '../../../API/workbench';
export default {
  data () {
    return {
      searchTime: {      // 收款查询日期
        startTime: '',
        endTime: ''
      },
      reportDetail: {
        MonthAmount: 0,
        YearAmount: 0,
        TotalAmount: 0
      },     // 基本统计数据
      receiptDetail: {
        TotalAmount: 0,
        TotalNum: 0,
        InComeType: []
      },      // 收款统计
      paymentDetail: {
        TotalAmount: 0,
        TotalNum: 0,
        InComeType: []
      },      // 付款统计
      searchType: 0,
      monthList: [],
      paymentList: [],  // 传入echart的付款数据
      receiptList: [],   // 传入echart的收款数据
      echartColor: ['#5A8DF6', '#62DAAB', '#F56E53', '#657798', '#EEB91D']

    };
  },
  components: {
    circleEchart
  },
  created () {
    this.getReceiptAndPaymentReport();
    this.searchTime.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchTime.endTime = this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
    this.getReceiptListReport(this.searchTime);
    this.getPaymentListReport(this.searchTime);
  },
  computed: {
    vtipstitle () {
      if (this.searchTime.startTime) {
        if (this.searchType == 0) {
          return this.$utils.formatDatet(Date.new(this.searchTime.startTime), 'MM') + '月';
        } else if (this.searchType == 1) {
          return '本年';
        } else {
          return '累计';
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    // 根据字数设定字体大小
    setSizeByLength (num) {
      num = num || 0;
      let size = parseInt(num).toString().length <= 6 ? 'font-size:30px' : 'font-size:18px';
      return size;
    },
    isAdd (num) {
      num = num || 0;
      return num > 0 ? '+' : '';
    },
    isDebt (num) {
      num = num || 0;
      return num > 0 ? '-' : '';
    },
    // 点击echart触发事件
    doAfterClickEchartForPayment (item) {
      this.$emit('seletedEchartItem', item, '2');
    },
    doAfterClickEchartForReceipt (item) {
      this.$emit('seletedEchartItem', item, '1');
    },
    // 获取基本统计
    getReceiptAndPaymentReport () {
      GetInComePayReportForDate().then(result => {
        this.reportDetail = result.data;
        console.log(result.data, 'GetInComePayReportForDate');
      }).catch(error => {
        console.log(error, 'errorGetInComePayReportForDate');
      });
    },
    // 获取收款统计数据
    getReceiptListReport (data) {
      GetInComePayReportForProceeds(data).then(result => {
        this.receiptDetail = result.data;
        this.receiptDetail.InComeType.sort((x, y) => {
          return Number(y.InAmount) - Number(x.InAmount);
        });
        this.receiptList = this.setReceiptList();
        this.$nextTick(() => {
          if (this.receiptDetail.TotalAmount > 0) {
            this.$refs.receiptEchart.echartInit();
          }
        });
        console.log(result.data, 'GetInComePayReportForDate');
      }).catch(error => {
        console.log(error, 'errorGetInComePayReportForDate');
      });
    },
    // 获取付款统计数据
    getPaymentListReport (data) {
      GetInComePayReportForExpend(data).then(result => {
        this.paymentDetail = result.data;
        this.paymentDetail.InComeType.sort((x, y) => {
          return Number(y.PayAmount) - Number(x.PayAmount);
        });
        this.paymentList = this.setPaymentList();
        this.$nextTick(() => {
          if (this.paymentDetail.TotalAmount > 0) {
            this.$refs.paymentEchart.echartInit();
          }
        });
        console.log(result.data, 'GetInComePayReportForDate');
      }).catch(error => {
        console.log(error, 'errorGetInComePayReportForDate');
      });
    },
    // 点击切换日期搜索
    changeTypeSearch (type) {
      this.searchType = type;
      if (type == 0) {
        this.searchTime.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchTime.endTime = this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
      } else if (type == 1) {
        this.searchTime.startTime = Date.new().getFullYear() + '-01-01';
        this.searchTime.endTime = this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
      } else {
        console.log('CreateDateTime', this.$store.getters.SaaSClientInfo.CreateDateTime);
        let CreateDateTime = this.$store.getters.SaaSClientInfo.CreateDateTime;
        this.searchTime.startTime = CreateDateTime.substring(0, 10);
        this.searchTime.endTime = this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
      }
      this.$emit('seletedDate', this.searchTime);
      this.getReceiptListReport(this.searchTime);
      this.getPaymentListReport(this.searchTime);
    },
    // 设置付款金额
    setPaymentList () {
      let nowList = [];
      let count = 0;
      let PayAmount = 0;
      let num = 0;
      this.paymentDetail.InComeType.forEach((o, index) => {
        o.IncomePaySubKeyValue = o.IncomePaySubKeyValue || '';
        o.PayAmount = Number(o.PayAmount);
        let IncomePaySubKeyValue = o.IncomePaySubKeyValue.length > 12 ? o.IncomePaySubKeyValue.substring(0, 12) + '...' : o.IncomePaySubKeyValue;
        if (Number(o.PayAmount) > 0 && index < 4) {
          let obj = {
            value: Number(o.PayAmount),
            name: IncomePaySubKeyValue,
            num: o.Num,
            IncomePaySubKey: o.IncomePaySubKey,
            itemStyle: {
              color: this.echartColor[index]
            }
          };
          nowList.push(obj);
        } else {
          count++;
          PayAmount += Number(o.PayAmount) || 0;
          num += Number(o.Num) || 0;
        }
      });
      if (count > 0) {
        console.log(PayAmount, 'count>0');
        let obj = {
          value: PayAmount > 0 ? PayAmount : null,
          name: '其他',
          num: num,
          itemStyle: {
            color: '#EEB91D'
          }
        };
        nowList.push(obj);
      }
      return nowList;
    },
    // 设置收款金额
    setReceiptList () {
      let nowList = [];
      let count = 0;
      let InAmount = 0;
      let num = 0;
      this.receiptDetail.InComeType.forEach((o, index) => {
        o.IncomePaySubKeyValue = o.IncomePaySubKeyValue || '';
        o.InAmount = Number(o.InAmount);
        let IncomePaySubKeyValue = o.IncomePaySubKeyValue.length > 12 ? o.IncomePaySubKeyValue.substring(0, 12) + '...' : o.IncomePaySubKeyValue;
        if (Number(o.InAmount) > 0 && index < 4) {
          console.log('setReceiptList', o.InAmount);
          let obj = {
            value: Math.round(Number(o.InAmount)),
            name: IncomePaySubKeyValue,
            num: o.Num,
            IncomePaySubKey: o.IncomePaySubKey,
            itemStyle: {
              color: this.echartColor[index]
            }
          };
          nowList.push(obj);
        } else {
          count++;
          InAmount += Math.round(Number(o.InAmount));
          num += Number(o.Num) || 0;
        }
      });
      if (count > 0) {
        let obj = {
          value: InAmount > 0 ? InAmount : null,
          name: '其他',
          num: num,
          itemStyle: {
            color: '#EEB91D'
          }
        };
        nowList.push(obj);
      }
      return nowList;
    }

  }
};
</script>
