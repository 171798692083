<template>
  <!-- 搜索 多选 班级结果 -->
  <div class="single_result single_class_info"
       @click.stop="selectedItem(multipleItem)"
       :class="{active:multipleItem.isActive}">
    <div class="single_result_info_detail  ">
      <div class="single_class_name">
        <span>{{multipleName}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'multipleClassroomItem',
  data () {
    return {
      isMultipleSelectInfo: false
    };
  },
  props: ['multipleName', 'multipleItem', 'multipleList'], // 单选数据项 需绑定到界面
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
    selectedItem (item) {
      item.isActive = !item.isActive;
      // this.isMultipleSelectInfo = item.isActive
      var activeTo = item.isActive;
      this.$emit('selectedItem', item, activeTo);
    }
  }
};
</script>

<style>
.single_class_info {
  position: relative;
}

.single_result_CQ {
  margin-top: 7px;
  padding: 20px 40px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 2px #eaeceb;
  box-shadow: 0 0 4px 2px #eaeceb;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
</style>

