<template>
  <div>
    <custom-dialog title="批量加入班级"
                   width="25%"
                   class="batch_join_lesson defult_dialog"
                   :visible.sync="showBatchJoinLesson"
                   :before-close="closeBatchJoinLesson">
      <div class="batch_join_detail">
        <div class="aim_class single_shadow">
          <div class="aim_class_left fl">
            目标班级
          </div>
          <div class="aim_class_center aim_class_center_two fl">
            <div class="aim_class_center_name">
              <span>{{courseDetail.MainDemoName}}</span>
            </div>
            <div class="aim_class_center_number">
              <span class="font_gray">安排学生：</span>
              <span>{{CurrentCount}}人</span>
            </div>
          </div>
          <div class="aim_class_right fr">
            <div class="aim_class_right_icon_change"
                 @click.stop="changeClass"></div>
            <div class="aim_class_right_icon_check"
                 v-if="false"></div>
          </div>
        </div>
        <!-- 搜索框 -->
        <searchContent @searchStudent="chgSearchText"></searchContent>

        <div class="batch_join_content single_shadow">
          <div class="batch_join_content_left fl">
            <div class="batch_join_content_title">
              <div class="batch_join_content_title_icon"></div>
              <span>可选学生</span>
            </div>
            <div class="batch_join_content_list"
                 @scroll="scrollTOP">
              <studentItem v-for="studentItem in noArrangeStudentList"
                           :key="studentItem.OLAPKey"
                           :item="studentItem"
                           @seletedItem="seletedAddItem"></studentItem>
            </div>
          </div>
          <div class="batch_join_content_right fr">
            <div class="batch_join_content_title">
              <div class="batch_join_content_title_icon"></div>
              <span>已加入学生</span>
            </div>
            <div class="batch_join_content_list">
              <studentItem v-for="studentItem in inClassStudentList"
                           :key="studentItem.StuKey"
                           :item="studentItem"
                           @seletedItem="seletedDeleteItem"></studentItem>
            </div>
          </div>
        </div>
        <div class="footer_btn_group form_footer_btn_group">
          <el-button round
                     class="btn_light_red btn_hover_white"
                     @click.stop="closeBatchJoinLesson">取消</el-button>
          <el-button round
                     class="btn_light_blue"
                     @click.stop="AddStuToBj">确认</el-button>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog title="选择班级"
                   class="refund_dialog defult_dialog"
                   :visible.sync="showClassList">

      <grade-list :isShowSearchInput="isShowSearchInput"
                       v-on:selectedItem="selectedClass"
                       ref="classGrade"></grade-list>
    </custom-dialog>

    <custom-dialog title=""
                   class="progress_max progress"
                   width="300px"
                   :visible.sync="showProgress">
      <div class="progress_title">
        正在加入班级 ，{{runCount}}/{{totolPeoCount}}。
      </div>
      <div class="progress_box">
        <el-progress type="circle"
                     :percentage="progressStatus"></el-progress>
      </div>
    </custom-dialog>

  </div>
</template>
<script>
import searchContent from './search-content';
import studentItem from './student-item';
import gradeList from '../../../common/grade-list';
import { GetClassStudentsByID, messageSearchPageForStudent, ArrangeStudentToClass, GetTheClassByID, BatchSyncSubmit } from '../../../../API/workbench';
export default {
  name: 'branthAddStudent',
  data () {
    return {
      showBatchJoinLesson: false,
      courseDetail: {},
      searchText: '',
      allStudentList: [],      // 所有学生列表
      inClassStudentList: [],      // 班内学生列表
      temporaryList: [],       // 临时加入的学生. 还没点击确定在这里显示.
      pageIndex: 0,
      pageSize: 20,
      showClassList: false,
      isShowSearchInput: false,
      showProgress: false,
      totolPeoCount: 0,          // 百分比弹出层总人数
      runCount: 0,         // 进度条.
      isSaving: false // 是否正在保存
    };
  },
  props: [],
  components: {
    studentItem, searchContent, gradeList
  },
  computed: {
    // progressStatus   进度条百分比.
    progressStatus () {
      return Math.floor(((this.runCount / this.totolPeoCount).toFixed(2)) * 100);
    },
    CurrentCount () {
      return Number(this.courseDetail.CurrentPeoCount);
    },
    // 整理出没有安排得学生.
    noArrangeStudentList () {
      let newArr = [];
      this.allStudentList.forEach(o => {
        let hasFind = false;
        if (this.inClassStudentList.length > 0) {
          hasFind = this.inClassStudentList.findIndex(p => p.StuKey == o.OLAPKey) >= 0;
        }
        if (!hasFind) {
          newArr.push(o);
        }
      }
      );
      return newArr;
    }
  },
  watch: {
    // 监听搜索字段.
    searchText () {
      this.pageIndex = 0;
      this.allStudentList.length = 0;
      if (this.searchText != '') {
        this.getAllStudentList(this.pageIndex, 0, this.searchText, (result) => {
          console.log(result, 'searchtext');
          this.allStudentList = result.PageDataList;
        });
      } else {
        this.getAllStudentList(this.pageIndex, this.pageSize, this.searchText);
      }
    },
    // 没在班级里的学生   是为了预防数据低于十条时新增数据.
    noArrangeStudentList () {
      if (this.noArrangeStudentList.length < 10) {
        this.getAllStudentList(this.pageIndex++, this.pageSize, this.searchText);
      }
    }
  },
  created () {
  },
  methods: {
    // 新增回调
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      // 接收选中值.
      // this.customerInfo.FineIdSupport = result.OLAPKey;
      // this.customerInfo.FineSupport = result.MainDemoName;
      // this.showInterested = false;
    },
    // 初始化获取数据
    initGetData () {
      this.pageIndex = 0;
      this.allStudentList.length = 0;  // 初始化时将数组清零
      this.getClassDetailByID(this.courseDetail.OLAPKey);
      this.getAllStudentList(this.pageIndex, this.pageSize, this.searchText);
      this.getInClassStudentList(this.courseDetail.OLAPKey);
    },
    // 根据ID获取班级信息
    getClassDetailByID (OLAPKey) {
      GetTheClassByID(OLAPKey).then(result => {
        console.log(result.data, 'getClassDetailByID');
        this.courseDetail = result.data;
      }).catch(error => {
        console.log('getClassDetailByID', error);
      });
    },
    // 滚动加载
    scrollTOP (e) {        // 滚动加载事件.
      let ETarget = e.target;
      if (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight) {
        this.getAllStudentList(this.pageIndex, this.pageSize, this.searchText);
      }
    },
    // 关闭批量加入页面
    closeBatchJoinLesson () {
      this.temporaryList.length = 0;
      this.allStudentList = [];
      this.searchText = '';
      this.showBatchJoinLesson = false;
      this.isSaving = false;
    },
    // 班级ID获取班级内学生.
    getInClassStudentList (OLAPKey) {
      GetClassStudentsByID(OLAPKey).then(result => {
        this.inClassStudentList = result.data || [];
        if (this.inClassStudentList.length > 0) {
          this.inClassStudentList.forEach(o => {
            o.isActive = true;
          });
        }
        console.log('getInClassStudentList(ID)', this.inClassStudentList);
      }).catch(error => {
        console.log('getClassStudentList', error);
      });
    },
    // 获取所有学生
    getAllStudentList (pageIndex, pageSize, searchText, callback) {
      messageSearchPageForStudent(pageIndex, pageSize, searchText).then(result => {
        if (result.data) {
          if (callback) {
            callback(result.data);
          } else {
            result.data.PageDataList.forEach(o => {
              let hasFind = false;
              if (this.allStudentList.length > 0) {
                hasFind = this.allStudentList.findIndex(p => p.OLAPKey == o.OLAPKey) >= 0;
              }
              if (!hasFind) {
                this.allStudentList.push(o);
              }
            });
            this.pageIndex++;
          }
        }
      }).catch(error => {
        console.log('getAllStudentList', error);
      });
    },
    // 接收查找的字段.
    chgSearchText (val) {
      this.searchText = val;
    },
    // 选择学生加入班级.
    seletedAddItem (studentItem) {
      studentItem.StuKey = studentItem.OLAPKey;
      let locaIndex = this.inClassStudentList.findIndex(o => {
        return o.StuKey == studentItem.OLAPKey;
      });
      console.log(locaIndex);
      if (locaIndex < 0) {
        this.spliceArray(this.allStudentList, studentItem);
        this.inClassStudentList.push(studentItem);   // 显示的组件.
        this.temporaryList.push(studentItem);      // 临时接收的数组. 方便确定时遍历此数组.
      }
      console.log(this.temporaryList, 'temporaryList');
    },
    // 删除临时加入的学生.
    seletedDeleteItem (studentItem) {
      let temlocaIndex = this.temporaryList.findIndex(o => {
        return o.StuKey == studentItem.StuKey;
      });
      if (temlocaIndex >= 0) {
        this.spliceArray(this.inClassStudentList, studentItem);
        this.temporaryList.splice(temlocaIndex, 1);
        this.allStudentList.push(studentItem);
      }
    },
    // 封装----删除数组指定项
    spliceArray (arr, item) {
      let locaIndex = arr.findIndex(o => {
        return o.StuKey == item.StuKey;
      });
      console.log(locaIndex, arr);
      arr.splice(locaIndex, 1);
    },
    // 确定时触发
    AddStuToBj () {
      if (this.temporaryList.length > 0) {
        if (this.isSaving) {
          return false;
        }
        this.isSaving = true;
        this.showProgress = true;
        this.totolPeoCount = this.temporaryList.length;
        this.addStudentToClass(this.temporaryList, 0, this.courseDetail.OLAPKey);
      } else {
        layer.alert('请加入学生');
      }
    },
    // 批量加学生
    addStudentToClass (dataList) {
      let APIPromiseCreator = (obj) => {
        return ArrangeStudentToClass(obj.StuKey, this.courseDetail.OLAPKey);
      };

      let resolve = (r, obj) => {
        this.runCount++;
      };
      let reject = (r, obj) => {
        this.runCount++;
      };
      let resultCallback = (resultList) => {
        let outSchoolStudentList = [];
        let outSchoolStudentNames = '';
        let otherErrorList = [];
        let otherErrorMsg = '';
        resultList.forEach(o => {
          if (o.r.code == 10000) {
            outSchoolStudentList.push(o.data);
            outSchoolStudentNames = outSchoolStudentNames == '' ? o.data.MainDemoName : outSchoolStudentNames + ',' + o.data.MainDemoName;
          } else if (o.r.code != 0) {
            otherErrorList.push(o.data);
            otherErrorMsg = otherErrorMsg == '' ? o.data.MainDemoName : otherErrorMsg + ',' + o.data.MainDemoName;
          }
        });
        this.showProgress = false;
        if (outSchoolStudentList.length > 0) {
          layer.alert(outSchoolStudentNames + '不在校，不可排入班级');
        }
        // 传递给主界面判断是否更新数据.因为这个页面可以自行选择班级.
        this.$emit('updateData', this.courseDetail);
        this.runCount = 0;
        this.pageIndex = 0;
        this.$nextTick(() => {
          this.closeBatchJoinLesson();
        });
      };
      BatchSyncSubmit(
        dataList,
        APIPromiseCreator,
        resultCallback,
        resolve,
        reject);
    },

    // 批量处理.
    // addStudentToClass (dataList, index, ClassID, signInResultList) {
    //   console.log(dataList, 'dataList');
    //   let StudentID = dataList[index].StuKey;
    //   signInResultList = signInResultList || [];
    //   ArrangeStudentToClass(StudentID, ClassID).then(result => {
    //     if (index + 1 == dataList.length) {
    //       this.runCount++;
    //       this.showProgress = false;
    //       // 传递给主界面判断是否更新数据.因为这个页面可以自行选择班级.
    //       this.$emit('updateData', this.courseDetail);
    //       this.runCount = 0;
    //       this.pageIndex = 0;
    //       this.$nextTick(() => {
    //         this.closeBatchJoinLesson();
    //       });
    //     } else {
    //       this.runCount++;
    //       this.addStudentToClass(dataList, ++index, ClassID, signInResultList);
    //     }
    //   }).catch(error => {
    //     console.log('error', error);
    //     signInResultList.push(dataList[index]);
    //     this.runCount++;
    //     if (index + 1 == dataList.length) {
    //       this.showProgress = false;
    //       this.isSaving = false;
    //       this.$emit('updateData', this.courseDetail);
    //     } else {
    //       this.addStudentToClass(dataList, ++index, ClassID, signInResultList);
    //     }
    //   });
    // },
    // 切换班级
    changeClass () {
      this.showClassList = true;
    },
    // 选择班级
    selectedClass (item) {
      this.showClassList = false;
      this.courseDetail = item;
      this.initGetData();
      console.log('selectedClass', this.courseDetail);
    }
  }
};
</script>

<style>
.batch_join_detail {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
