<template>
  <!-- 批量授权课包 -->
  <div class="authorization_courseware_form">
    
 
    <div class="form_title_info">校区信息</div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'授权校区'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    :readonly="true"
                    :value="dataInfo.AuthorizationSaaSClientKeyValue"
                    ></input-dialog>
    </div>
    <div class="form_title_info">授权课包</div>
    <div class="form_info_edit form_info_list">
      
      <!-- 修改或者只读模式下 -->
      <input-dialog :formTitle="'授权课包'"
                    :formPlaceholder="'请输入'"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                     :value="vCampusKeyValue"
                    @showEldilog="selectBantchList"></input-dialog>

      <input-select :formTitle="'结算方式'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    :dataList="sourceType"
                    :selectedInfo="vSourceTypeInfo"
                    @selectedItem="changeSourceType"></input-select>

      <input-number v-if="dataInfo.ClearingFormKey==1"
                    :min="0"
                    :decimalPlaces="0"
                    :formTitle="'授权课次'"
                    :required="true"
                    :readonly="isReadonly"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.ReleasedNum"></input-number>
      <!-- <data-range-tt v-else-if="dataInfo.ClearingFormKey==4"
                     :minDate="$utils.formatDateToLine(Date.new())"
                     :searchObj="vDataTime"
                     :fromTitle="'有效期'"
                     @changeDate="changeDate">
      </data-range-tt> -->
      <div v-else-if="dataInfo.ClearingFormKey==4"
           class="pr">
        <input-dialog :formTitle="'有效期'"
                      :formPlaceholder="'请选择时间'"
                      :required="true"
                      :readonly="isReadonly"
                      v-model="vDataTimeInput"
                      @showEldilog="selectDataTime">
        </input-dialog>
        <span class="help_title">{{vNumberData}}</span>
      </div>

      <input-number v-else-if="dataInfo.ClearingFormKey==2"
                    :min="0"
                    :decimalPlaces="0"
                    :formTitle="'授权时长（年）'"
                    :required="true"
                    :readonly="isReadonly"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.Duration"></input-number>
    </div>
    <div class="form_title_info">设备控制</div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'PC端'"
                    :formPlaceholder="''"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    v-model="vPCLocation"
                    @showEldilog="setLocation('PC')"></input-dialog>
    </div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'APP端'"
                    :formPlaceholder="''"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    v-model="vAPPLocation"
                    @showEldilog="setLocation('APP')"></input-dialog>
    </div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'TV端'"
                    :formPlaceholder="''"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    v-model="vTVLocation"
                    @showEldilog="setLocation('TV')"></input-dialog>
    </div>

    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
     <custom-dialog :title="'批量选择课包'"
                   :visible.sync="showbatchRenewalForm"
                   :width="'595px'"
                   :before-close="closeBantchFrom">
      <batch-list-form :seletedList="dataInfo.CoursewarePackageList"
                    :dataInfo="dataInfo"
                    @close="closeBantchFrom"
                    @afterSuccess="setCampusList"></batch-list-form>
    </custom-dialog>
  </div>
</template>
<script>
import {
  SearchCoursewarePackageForNormal,
  SearchCoursewarePackageForBigPackage,
  Authorization,
  AuthorizationCoursewareRenew,
  AuthorizationCoursewareRenewDetail,
  hqMessageSearchPageForIncomePayAccount,
  GetOnJobEmployees,
  SearchSaaClientList,
  HQSearchCoursewarePackage
} from '../../../../../../API/workbench';
import batchListForm from './batch-list-form'
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        // { type: 1, name: '按次授权' },
        { type: 4, name: '按天授权' },
        { type: 2, name: '按年授权' },
        { type: 3, name: '永久授权' }

      ],
      coursewareTypeList: [
        { key: '1', value: '大课包', isSelection: true, dataList: [] },
        { key: '2', value: '子课包', isSelection: false, dataList: [] }
      ],
      bigCoursewareDataInfo: [],
      parentCoursewareList: [], // 大课包列表
      sonCoursewareList: [], // 子课件列表
      locationInfo: [], // 地址
      dataSourceList: [], // 收款账户
      dataInfo: {
        ClientID: '', // 校区ID
        AuthorizationSaaSClientKey: '', //	整型	必须		数据源：GET	授权校区id
        AuthorizationSaaSClientKeyValue: '', //	字符串	必须		数据源：GET	授权校区名称
        PrincipalName: '', // 联系人
        MobilePhone: '', // 手机号
        CoursewarePackageKey: '', //	整型	必须		数据源：GET	课件包id
        CoursewarePackageKeyValue: '', // 字符串	必须		数据源：GET	课件包名称
        ClearingFormKey: 2, //	整型	必须		数据源：GET	结算方式
        ClearingFormKeyValue: '按年授权',
        FeeAmount: '', //	整型	必须		数据源：GET	费用
        CounselorKey: '', //	整型	必须		数据源：GET	销售顾问id
        CounselorKeyValue: '', //	字符串	必须		数据源：GET	销售顾问
        AuthorizeDeviceNum: '-1', //	整型	必须		数据源：GET	授权硬件设备数量
        ReleasedNum: '', //	整型	可选		数据源：GET	课次数量
        Duration: '', //	整型	可选		数据源：GET	时长
        IncomePayAccountKey: '', //	整型	可选		数据源：GET	收款账户
        IncomePayAccountKeyValue: '', //	字符串	可选		数据源：GET	收款账户value
        IsAPPAuthKey: 1, // 是否开启APP授权
        AuthorizationSaaSClientList: [],
        isAllCourseware: true,
        startTime: '',
        endTime: '',
        IsPCAuthKey: 1,
        PCIsLocationLockKey: 0,
        APPAuthorizeDeviceNum: 0,
        IsLocationLockKey: 1,
        TVIsLocationLockKey: 1,
        IsTVAuthKey: 1,
        TVAuthorizeDeviceNum: 0,
        CoursewarePackageList: [],
        IsPCRecordKey:1,
        IsPCRecordKeyValue:'是',
        IsAPPRecordKey:1,
        IsAPPRecordKeyValue:'是',
        IsTVRecordKey:1,
        IsTVRecordKeyValue:'是',
        PCPositionalAccuracyKey:1,
        APPPositionalAccuracyKey:1,
        TVPositionalAccuracyKey:1,
        IsPCGatewayLogin:0,
        IsTVGatewayLogin:0
      },
      modeType: '',
      showbatchRenewalForm:false,
      campusType: 2// 1-直营店;2-加盟店;3-课程合作
    };
  },
  components: {
    batchListForm
  },
  props: {
    coursewareId: Number,
    isReadonly: Boolean,
    campusID: [String, Number],
    coursewareBagInfo: {
      type: Object,
      default: null
    }
  },
  created () {
    // var date = new Date(this.dataInfo.startTime);  // 当前日期2021-3-9
    // var fff = new Date(date.setDate(date.getDate() + 30)).toLocaleDateString(); // 获取当前日期后加30天    2021/4/8
    // var aaa = fff.replace(/\//g, '-');
    // this.dataInfo.endTime = aaa;
    if (this.coursewareId > 0) { // 续费模式
      this.modeType = '1';
      this.campusType = '';// 初始化
      this.getDetailInfo();
    } else if (this.campusID && this.campusID > 0) { // 新增授权模式，并显示当前门店信息
      this.campusType = '';// 初始化
      this.getSaaClientList();
    } else if (this.coursewareBagInfo && this.coursewareBagInfo.key) { // 读取课包信息
      this.dataInfo.CoursewarePackageKey = this.coursewareBagInfo.key;
      this.dataInfo.CoursewarePackageKeyValue = this.coursewareBagInfo.value;
      this.dataInfo.CoursewarePackageList = [{ CoursewarePackageKey: this.coursewareBagInfo.key, CoursewarePackageKeyValue: this.coursewareBagInfo.value }];
    } else {
      this.dataInfo.CounselorKey = this.$store.getters.token.UserID; //	整型	必须		数据源：GET	销售顾问id
      this.dataInfo.CounselorKeyValue = this.$store.getters.token.UserName; //	字符串	必须		数据源：GET	销售顾问
    }
    this.initDeviceData();
    console.log('campusID：', this.campusID, 'coursewareId:', this.coursewareId, this.$store.getters.token);

    // this.getSearchCoursewarePackageForBigPackage();
  },
  mounted () {
    this.getBigCourseware();
    this.getIncomePayAccount();
    this.$dialog.register(this, ['singleSelectionList', 'campusListMultipleEls', 'chooseCoursewareMultiple', 'locationForm', 'chooseAuthDataTime']);
  },
  computed: {
    vCampusKeyValue(){
      if(this.dataInfo.CoursewarePackageList.length==0){
        return ''
      }else if(this.dataInfo.CoursewarePackageList.length==1){
         return this.dataInfo.CoursewarePackageList[0].CoursewarePackageKeyValue
      }else{
        return '共'+this.dataInfo.CoursewarePackageList.length+'个'
      }
    },
    vIsEdit () {
      if (this.coursewareId > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 结算方式
    vSourceTypeInfo () {
      return {
        type: this.dataInfo.ClearingFormKey,
        name: this.dataInfo.ClearingFormKeyValue
      };
    },
    vDataTime () {
      return {
        startTime: this.dataInfo.startTime,
        endTime: this.dataInfo.endTime
      };
    },
    vDataTimeInput () {
      let text = '';
      if (this.dataInfo.startTime == '' && this.dataInfo.endTime == '') {
        text = '';
      } else {
        text = this.dataInfo.startTime + '  至  ' + this.dataInfo.endTime;
      }
      return text;
    },
    vCoursewareInfo () {
      return {
        id: this.dataInfo.CoursewarePackageKey,
        name: this.dataInfo.CoursewarePackageKeyValue
      };
    },
    vNumberData () {
      if (this.dataInfo.endTime == '') {
        return '';
      }
      var beginDate = new Date(this.dataInfo.startTime);
      var endDate = new Date(this.dataInfo.endTime);
      var day = (endDate - beginDate) / (1000 * 60 * 60 * 24);
      return (Number(day) + 1).toFixed(0) + '天';
    },
    // 收支账户
    vIncomePayAccount () {
      return {
        id: this.dataInfo.IncomePayAccountKey,
        name: this.dataInfo.IncomePayAccountKeyValue
      };
    },
    vIsAllCourseware () {
      return this.dataInfo.isAllCourseware ? '全部' : '部分';
    },
    vPCLocation () {
      let str = '';
      if (Number(this.dataInfo.IsPCAuthKey) == 0) {
        str = '禁用';
        return str;
      } else {
        if (this.dataInfo.AuthorizeDeviceNum == '') {
          str = '设备：未设置   ';
        } else if (this.dataInfo.AuthorizeDeviceNum == '0') {
          str = '设备：不限   ';
        } else {
          str = '设备：' + this.dataInfo.AuthorizeDeviceNum + '   ';
        }
      }
      str += this.dataInfo.PCIsLocationLockKey == 0 ? '定位：不限' : '定位：开启';
      if(this.dataInfo.PCIsLocationLockKey == 1){
        str+=this.dataInfo.PCPositionalAccuracyKey == 1 ? ' 高精度':' 超高精度'
      }
      str += this.dataInfo.IsPCRecordKey == 1 ? '  防录屏':'  不防录屏'
      return str;
    },
    vAPPLocation () {
      let str = '';
      if (Number(this.dataInfo.IsAPPAuthKey) == 0) {
        str = '禁用';
        return str;
      } else {
        if (this.dataInfo.APPAuthorizeDeviceNum == '') {
          str = '设备：未设置   ';
        } else if (Number(this.dataInfo.APPAuthorizeDeviceNum) == 0) {
          str = '设备：不限   ';
        } else {
          str = '设备：' + this.dataInfo.APPAuthorizeDeviceNum + '   ';
        }
      }
      str += this.dataInfo.IsLocationLockKey == 0 ? '定位：不限' : '定位：开启';
       if(this.dataInfo.IsLocationLockKey == 1){
        str+=this.dataInfo.APPPositionalAccuracyKey == 1 ? ' 高精度':' 超高精度'
      }
      str += this.dataInfo.IsAPPRecordKey == 1 ? '  防录屏':'  不防录屏'
      return str;
    },
    vTVLocation () {
      let str = '';
      if (Number(this.dataInfo.IsTVAuthKey) == 0) {
        str = '禁用';
        return str;
      } else {
        if (this.dataInfo.TVAuthorizeDeviceNum == '') {
          str = '设备：未设置   ';
        } else if (Number(this.dataInfo.TVAuthorizeDeviceNum) == 0) {
          str = '设备：不限   ';
        } else {
          str = '设备：' + this.dataInfo.TVAuthorizeDeviceNum + '   ';
        }
      }
      str += this.dataInfo.TVIsLocationLockKey == 0 ? '定位：不限' : '定位：开启';
      if(this.dataInfo.TVIsLocationLockKey == 1){
        str+=this.dataInfo.TVPositionalAccuracyKey == 1 ? ' 高精度':' 超高精度'
      }
      str += this.dataInfo.IsTVRecordKey == 1 ? '  防录屏':'  不防录屏'
      return str;
    }

  },
  methods: {
    selectBantchList(){
      this.showbatchRenewalForm=true
    },
    closeBantchFrom(){
      this.showbatchRenewalForm=false
    },
    setCampusList(dataList){
      console.log("setCampusList",dataList)
      this.dataInfo.CoursewarePackageList = dataList;  
      this.closeBantchFrom()
    },
    initDeviceData () {
      this.dataInfo.IsPCAuthKey = this.$utils.getSaaSClientSetInfo(76).SetContent;   // 是否开启PC授权
      this.dataInfo.IsAPPAuthKey = this.$utils.getSaaSClientSetInfo(77).SetContent; // 是否开启APP授权
      this.dataInfo.IsTVAuthKey = this.$utils.getSaaSClientSetInfo(78).SetContent;     // 是否开启TV授权
      this.dataInfo.AuthorizeDeviceNum = this.$utils.getSaaSClientSetInfo(79).SetContent;  // PC设备数量
      this.dataInfo.APPAuthorizeDeviceNum = this.$utils.getSaaSClientSetInfo(80).SetContent; // APP设备数量
      this.dataInfo.TVAuthorizeDeviceNum = this.$utils.getSaaSClientSetInfo(81).SetContent; // TV设备数量
      this.dataInfo.PCIsLocationLockKey = this.$utils.getSaaSClientSetInfo(82).SetContent;// PC开启位置
      this.dataInfo.IsLocationLockKey = this.$utils.getSaaSClientSetInfo(83).SetContent;  // APP开启位置
      this.dataInfo.TVIsLocationLockKey = this.$utils.getSaaSClientSetInfo(84).SetContent;   // TV开启PC位置
      this.dataInfo.PCPositionalAccuracyKey=this.$utils.getSaaSClientSetInfo(91).SetKey;
      this.dataInfo.APPPositionalAccuracyKey=this.$utils.getSaaSClientSetInfo(92).SetKey;
      this.dataInfo.TVPositionalAccuracyKey=this.$utils.getSaaSClientSetInfo(93).SetKey;
      this.dataInfo.IsPCRecordKey = this.$utils.getSaaSClientSetInfo(88).SetKey;
      this.dataInfo.IsAPPRecordKey = this.$utils.getSaaSClientSetInfo(89).SetKey;
      this.dataInfo.IsTVRecordKey = this.$utils.getSaaSClientSetInfo(90).SetKey;
      this.dataInfo.IsPCGatewayLogin = this.$utils.getSaaSClientSetInfo(98).SetKey;
      this.dataInfo.IsTVGatewayLogin = this.$utils.getSaaSClientSetInfo(99).SetKey;
    },
    // 返回总部门店所有收支账户
    getIncomePayAccount () {
      let queryParams = {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 0, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'asc', // 排序字段
        StatusKey: 1,        // 收款账户状态 1-启用;2-停用
        AccountTypeKey: '', // 	账户类型 1-储蓄卡; 2-信用卡;3-支付宝;4-微信;5-现金;6-其它;
        QueryID: ''
      };
      hqMessageSearchPageForIncomePayAccount(queryParams).then(result => {
        this.dataSourceList = result.data.PageDataList.filter(obj => {
          return obj.OLAPKey > 0 && obj.StatusKey != 2;
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择收支账户
    selectedAccount (obj) {
      console.log('选择收支账户', obj);
      this.dataInfo.IncomePayAccountKey = obj.OLAPKey;
      this.dataInfo.IncomePayAccountKeyValue = obj.MainDemoName;
    },
    // 选择结算方式
    changeSourceType (obj) {
      console.log(obj, '选择结算方式');
      this.dataInfo.ClearingFormKey = obj.type;
      this.dataInfo.ClearingFormKeyValue = obj.name;
    },
    // 获取门店列表 周一处理
    getSaaClientList () {
      let queryParams = {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 0, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'asc', // 排序字段
        HQClientKey: this.campusID
      };
      SearchSaaClientList(queryParams).then(result => {
        let findItem = result.data.PageDataList[0];
        if (findItem) {
          this.dataInfo.ClientID = findItem.ClientID;
          this.dataInfo.AuthorizationSaaSClientKey = findItem.OLAPKey;
          this.dataInfo.AuthorizationSaaSClientList = [{
            AuthorizationSaaSClientKey: findItem.OLAPKey,
            AuthorizationSaaSClientKeyValue: findItem.MainDemoName,
            TypeKey: findItem.TypeKey,
            TypeKeyValue: findItem.TypeKeyValue,
            OLAPKey: findItem.OLAPKey,
            MainDemoName: findItem.MainDemoName,
            isAuthorizationValue: '取消'
          }];
          this.dataInfo.AuthorizationSaaSClientKeyValue = this.dataInfo.AuthorizationSaaSClientList.length == 1 ? this.dataInfo.AuthorizationSaaSClientList[0].MainDemoName : '共 ' + this.dataInfo.AuthorizationSaaSClientList.length + ' 家';
          this.dataInfo.PrincipalName = findItem.Name14;
          this.dataInfo.MobilePhone = findItem.ContactPhoneName;
          this.campusType = findItem.TypeKey;
        }
        console.log(findItem, '选择校区');
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 设置定位
    setLocation (type) {
      let title = 'PC端';
      let obj = {};
      if (type == 'PC') {
        title = 'PC端';
        obj = {
          IsAuthKey: this.dataInfo.IsPCAuthKey,
          IsLocationLockKey: this.dataInfo.PCIsLocationLockKey,
          IsLocationLockKeyValue: this.dataInfo.PCIsLocationLockKey ? '开启' : '不限',
          IsLocationAccuracyKey: this.dataInfo.PCPositionalAccuracyKey,
          AuthorizeDeviceNum: this.dataInfo.AuthorizeDeviceNum,
          IsRecordKeyValue : this.dataInfo.IsPCRecordKey == 1 ? '是' : '否',
          IsRecordKey: this.dataInfo.IsPCRecordKey,
          AuthorizeDeviceType: 1,
          IsGatewayLogin:this.dataInfo.IsPCGatewayLogin||0,
          IsGatewayLoginValue:this.dataInfo.IsPCGatewayLogin== 1 ? '是' : '否',
        };
      } else if (type == 'APP') {
        title = 'APP端';
        obj = {
          IsAuthKey: this.dataInfo.IsAPPAuthKey,
          IsLocationLockKey: this.dataInfo.IsLocationLockKey,
          IsLocationLockKeyValue: this.dataInfo.IsLocationLockKey ? '开启' : '不限',
          IsLocationAccuracyKey: this.dataInfo.APPPositionalAccuracyKey,
          AuthorizeDeviceNum: this.dataInfo.APPAuthorizeDeviceNum,
          IsRecordKeyValue : this.dataInfo.IsAPPRecordKey == 1 ? '是' : '否',
          IsRecordKey: this.dataInfo.IsAPPRecordKey,
          AuthorizeDeviceType: 2,
        };
      } else {
        title = 'TV端';
        obj = {
          IsAuthKey: this.dataInfo.IsTVAuthKey,
          IsLocationLockKey: this.dataInfo.TVIsLocationLockKey,
          IsLocationLockKeyValue: this.dataInfo.TVIsLocationLockKey ? '开启' : '不限',
          IsLocationAccuracyKey: this.dataInfo.TVPositionalAccuracyKey,
          AuthorizeDeviceNum: this.dataInfo.TVAuthorizeDeviceNum,
          IsRecordKeyValue : '是',
          IsRecordKey: 1,
          AuthorizeDeviceType: 3,
          IsGatewayLogin:this.dataInfo.IsTVGatewayLogin||0,
           IsGatewayLoginValue:this.dataInfo.IsTVGatewayLogin== 1 ? '是' : '否',
        };
      }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: title,
        moduleName: 'locationForm',
        data: { isReadonly: false, dataList: obj },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, type, '选择确定定位');
            if (type == 'PC') {
              this.dataInfo.IsPCAuthKey = data.IsAuthKey;
              this.dataInfo.PCIsLocationLockKey = data.IsLocationLockKey;
              this.dataInfo.AuthorizeDeviceNum = data.AuthorizeDeviceNum;
              this.dataInfo.IsPCRecordKey = data.IsRecordKey
              this.dataInfo.PCPositionalAccuracyKey=data.IsLocationAccuracyKey
              this.dataInfo.IsPCGatewayLogin=data.IsGatewayLogin
            } else if (type == 'APP') {
              this.dataInfo.IsAPPAuthKey = data.IsAuthKey;
              this.dataInfo.IsLocationLockKey = data.IsLocationLockKey;
              this.dataInfo.APPAuthorizeDeviceNum = data.AuthorizeDeviceNum;
              this.dataInfo.IsAPPRecordKey = data.IsRecordKey
                this.dataInfo.APPPositionalAccuracyKey=data.IsLocationAccuracyKey
            } else {
              this.dataInfo.IsTVAuthKey = data.IsAuthKey;
              this.dataInfo.TVIsLocationLockKey = data.IsLocationLockKey;
              this.dataInfo.TVAuthorizeDeviceNum = data.AuthorizeDeviceNum;
              this.dataInfo.IsTVRecordKey = data.IsRecordKey
              this.dataInfo.TVPositionalAccuracyKey=data.IsLocationAccuracyKey
              this.dataInfo.IsTVGatewayLogin=data.IsGatewayLogin
            }
          }
        }
      });
    },
    // 选择子课包
    selectCoursewarePackage () {
      var check = [];
      console.log('checkedList', this.dataInfo);
      this.dataInfo.CoursewarePackageList.forEach(item => {
        check.push(item.CoursewarePackageKey);
      });

      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '授权课包',
        moduleName: 'chooseCoursewareMultiple',
        data: {
          bigCoursewareData: [{ OLAPKey: this.dataInfo.CoursewarePackageKey, MainDemoName: this.dataInfo.CoursewarePackageKeyValue }],
          checkedList: check,
          isActive: this.dataInfo.isAllCourseware
        },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, '选择子课包');
            this.dataInfo.isAllCourseware = data.isAllCourseware;
            this.dataInfo.CoursewarePackageList = data.CoursewarePackageList;
          }
        }
      });
    },
    // 选择起始时间和结束时间
    selectDataTime () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '有效期',
        moduleName: 'chooseAuthDataTime',
        data: {
          dataInfo: {
            startTime: this.dataInfo.startTime,
            endTime: this.dataInfo.endTime
          }
        },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, '选择时间后返回');
            this.dataInfo.startTime = data.startTime;
            this.dataInfo.endTime = data.endTime;
          }
        }
      });
    },
    //  获取大课包列表
    getSearchCoursewarePackageForBigPackage () {
      SearchCoursewarePackageForBigPackage().then(result => {
        // this.parentCoursewareList = result.data;
        this.coursewareTypeList[0].dataList = result.data;
        this.getSearchCoursewarePackageForNormal();
      }, error => {
        layer.alert(error.msg);
      });
    },
    //  获取小课包列表
    getSearchCoursewarePackageForNormal () {
      SearchCoursewarePackageForNormal().then(result => {
        // this.sonCoursewareList = result.data;
        this.coursewareTypeList[1].dataList = result.data;
        this.coursewareTypeList[1].dataList.forEach(o => {
          o.MainDemoNameValue = o.MainDemoName;
          o.MainDemoName = o.ParentKeyValue + '-' + o.MainDemoNameValue;
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择课件包
    selectCourseware (obj) {
      console.log(obj, '选择课件包');
      this.dataInfo.CoursewarePackageKey = obj.OLAPKey;
      this.dataInfo.CoursewarePackageKeyValue = obj.MainDemoName;
      this.dataInfo.CoursewarePackageList = [{ CoursewarePackageKey: obj.OLAPKey, CoursewarePackageKeyValue: obj.MainDemoName }];
      this.dataInfo.isAllCourseware = true;
      console.log(this.dataInfo.CoursewarePackageList, 'this.dataInfo.CoursewarePackageListsss');
    },
    // 高亮
    setCoursewareType (data) {
      this.coursewareTypeList.forEach(o => {
        if (o.key == data.key) {
          o.isSelection = true;
        } else {
          o.isSelection = false;
        }
      });
    },
    // 选中
    showCourseware () {
      let parentItem = this.coursewareTypeList[0].dataList.find(o => {
        return o.ID == this.dataInfo.CoursewarePackageKey;
      });
      if (parentItem) {
        this.setCoursewareType(this.coursewareTypeList[0]);
        return this.coursewareTypeList[0].dataList;
      } else {
        this.setCoursewareType(this.coursewareTypeList[1]);
        return this.coursewareTypeList[1].dataList;
      }
    },
    // 选择加盟顾问
    selectCounselor () {
      GetOnJobEmployees().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择加盟顾问',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataInfo.CounselorKey, dataSourceList: result.data, required: false, ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data, '选择招商顾问');
              this.dataInfo.CounselorKey = data.OLAPKey;
              this.dataInfo.CounselorKeyValue = data.MainDemoName;
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取授权详情信息
    getDetailInfo () {
      AuthorizationCoursewareRenewDetail(this.coursewareId).then(result => {
        Object.assign(this.dataInfo, result.data);
        this.dataInfo.CounselorKey = result.data.CounselorKey || this.$store.getters.token.UserID; //	整型	必须		数据源：GET	销售顾问id
        this.dataInfo.CounselorKeyValue = result.data.CounselorKeyValue || this.$store.getters.token.UserName; //	字符串	必须		数据源：GET	销售顾问
        this.campusType = result.data.SchoolType;
        this.dataInfo.startTime = result.data.AuthorizeTime;
        this.dataInfo.endTime = result.data.ExpireTime;
        console.log('续费信息', this.dataInfo);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      // 续费
      if (this.coursewareId > 0) {
        AuthorizationCoursewareRenew(this.dataInfo).then(result => {
          layer.alert('续费成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      } else { // 授权
        Authorization(this.dataInfo).then(result => {
          layer.alert('授权成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      }
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (this.dataInfo.AuthorizationSaaSClientList.length == 0 && this.modeType != '1') {
        layer.alert('请选择校区');
        flag = false;
      } else
        if (this.dataInfo.CoursewarePackageList.length==0) {
          layer.alert('请选择课件包');
          flag = false;
        } else if (this.dataInfo.ClearingFormKey == 1 && !this.dataInfo.ReleasedNum) {
          layer.alert('授权课次不能为空，请输入大于0的数');
          flag = false;
        } else if (this.dataInfo.ClearingFormKey == 2 && !this.dataInfo.Duration) {
          layer.alert('授权时长（年）不能为空，请输入大于0的数');
          flag = false;
        } else if (!this.dataInfo.AuthorizeDeviceNum) {
          layer.alert('PC端设备不能为空，请设置PC端设备');
          flag = false;
        } else if (!this.dataInfo.APPAuthorizeDeviceNum) {
          layer.alert('APP端设备不能为空，请设置APP端设备');
          flag = false;
        } else if (!this.dataInfo.TVAuthorizeDeviceNum) {
          layer.alert('TV端设备不能为空，请设置TV端设备');
          flag = false;
        } else if (!this.dataInfo.IsPCAuthKey && !this.dataInfo.IsTVAuthKey && !this.dataInfo.IsAPPAuthKey) {
          layer.alert('至少需要开放一端设备权限');
          flag = false;
        }
      return flag;
    },
    getBigCourseware () {
      HQSearchCoursewarePackage(0).then(result => {
        this.bigCoursewareDataInfo = result.data.map((item, i) => {
          return {
            OLAPKey: item.OLAPKey,
            MainDemoName: item.MainDemoName
          };
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    cancel () {
      this.$emit('close');
    },
    changeDate (obj) {
      this.dataInfo.startTime = obj.startTime;
      this.dataInfo.endTime = obj.endTime;
    }

  }
};
</script>

<style>
.authorization_courseware_form {
  height: 595px;
}
.authorization_courseware_form .form_info_list .form_info_value {
  width: 300px;
}
</style>
<style scoped>
.help_title {
  width: 64px;
  color: #999;
  position: absolute;
  top: 16px;
  left: 65px;
}
</style>


