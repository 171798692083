<template>
  <div class="signuppayment_course_select_ul bg_white">
    <div class="select_list"
         v-for="(item,index) in cardList"
         :key="index"
         :class="{'opt':item.isOpt}"
         @click="dochgCardClick(item)">
      <span>{{item.cardName}}</span>
    </div>

  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  data () {
    return {
    };
  },
  props: {
    cardList: Array // 标题，必填

  },
  computed: {

  },
  created () {
  },
  methods: {
    dochgCardClick (item) {
      this.cardList.forEach(o => {
        if (o.cardKey == item.cardKey) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.$emit('dochgCardClick', item);
    }
  }
};
</script>
<style>
</style>


