<template>
  <div>
    <div v-if="addModuleName">
      <btn-add-select :addTitleName="addBtnText"
                      :addmoduleName="addModuleName"
                      :addmoduleTitleName="addModuleTitleName"
                      @doAddSuccessEvent="addSucceedClick"></btn-add-select>
    </div>
    <div class="form_info_detail form_info_serach_box">
      <div class="student_info_supplement form_info">
        <input-search-immediately v-model="searchText"
                                  :placeholder="''"
                                  :styleText="'right: 45px;top: 35px;'"
                                  @clearSearch="clearSearText"></input-search-immediately>
      </div>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list"
               style="height: 300px;">
            <div v-for="item in vDataSourceLsit"
                 :key="item.OLAPKey">
              <div class="single_result single_class_info"
                   @click.stop="selectedItem(item)"
                   :class="{active:item.isSelected}">
                <div class="single_result_info_detail">
                  <div class="single_class_name">
                    <span>{{item.MainDemoName}}</span>
                    <span class="single_right_count">当前库存: {{Number(item.CurrentInventoryCount)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!required&&goodsList.length>0"
                 class="btn_fixed_bottom_placeholder"></div>
            <div v-if="vDataSourceLsit.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content"
                     style="padding:0">
                  <div class="has_arrange_course_list">
                    <div class="has_arrange_course_nodata single_shadow">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮组 -->
          <div>
            <save-cancel-btn-group :isSingleSubmit="true"
                                   :singleSubmitType="'dynamic'"
                                   @confirmClick="confirmClick"
                                   @cancelClick="cancelClick"></save-cancel-btn-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetGoodsList } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchText: '',
      goodsItem: {},
      goodsList: []
    };
  },
  props: {
    selectedKey: [String, Number], // 选中项key
    required: { // 是否必选项
      type: Boolean,
      default: false
    },
    placeholder: {// 搜索框水印
      type: String,
      default: ''
    },
    addBtnText: {// 新增按钮 显示文本
      type: String,
      default: '新增'
    },
    addModuleName: {// 新增模块 （例如 教室，老师，班级等）
      type: String,
      require: true
    },
    addModuleTitleName: String// 新增模块 （例如 新增教室，新增老师，新增班级等）

  },
  created () {
    this.getGoodsList();
  },
  watch: {
  },
  methods: {
    getGoodsList () {
      GetGoodsList().then(result => {
        this.goodsList = result.data;
        this.isShowGoodsList = true;
        this.initSelectedItem();
      }, error => {
        layer.alert(error.msg);
      });
    },
    initSelectedItem () {
      console.log(this.selectedKey, this.goodsList, '选中一项');
      this.goodsList.forEach(obj => {
        if (obj.OLAPKey == this.selectedKey) {
          this.$set(obj, 'isSelected', true);
        } else {
          this.$set(obj, 'isSelected', false);
        }
      });
    },
    // 选中一项
    selectedItem (item) {
      console.log(item, this.goodsList, '选中一项');
      this.goodsList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          this.$set(o, 'isSelected', true);
        } else {
          this.$set(o, 'isSelected', false);
        }
      });
      this.goodsItem = item;
    },
    // 确定
    confirmClick () {
      this.searchText = '';
      this.$emit('onSelectedItem', this.goodsItem);
    },
    // 新增
    addSucceedClick (item) {
      console.log(item, '新增');
      if (item) {
        this.goodsItem = item;
        this.searchText = '';
        this.$emit('onSelectedItem', this.goodsItem);
      }
    },
    cancelClick () {
      this.$emit('closeDialog');
    },
    clearSearText () {
      this.searchText = '';
    }
  },
  computed: {
    vDataSourceLsit () {
      let newArr = [];
      if (this.searchText) {
        this.goodsList.forEach(obj => {
          if (obj.MainDemoName.indexOf(this.searchText) >= 0 || (obj.FirstLetterName && obj.FirstLetterName.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)) {
            newArr.push(obj);
          }
        });
      } else {
        newArr = this.goodsList;
      }
      return newArr;
    }
  }
};
</script>
