<template>
  <!-- 销售价值信息 -->
  <div>
    <div class="form_header_gray">
      <div class="form_header_text">销售价值</div>
    </div>
    <div class="form_info_box bg_white">
      <form-seleted-option :formTitle="'报名意向'"
                           :formPlaceholder="'请选择'"
                           :readonly="isReadonly"
                           :dataList="willingnessRateList"
                           :dataKey="customerInfo.WillingnessRateKey"
                           @seletedItem="chgWillingnessRank"></form-seleted-option>

      <input-dialog :formTitle="'感兴趣的课'"
                    :formPlaceholder="'请选择'"
                    :readonly="isReadonly"
                    v-model="customerInfo.FineSupport"
                    @showEldilog="openInterestCourse()"></input-dialog>

      <input-dialog :formTitle="'来源方式'"
                    :formPlaceholder="'请选择'"
                    :readonly="isReadonly"
                    class="no_border_bottom"
                    v-model="customerInfo.SourceTypeKeyValue"
                    @showEldilog="openAdviser()"></input-dialog>
    </div>

    <custom-dialog title="选择感兴趣的课"
                   class="customer_dialog defult_dialog"
                   :visible.sync="isShowInterestCourseList"
                   :before-close="closeshowInterested">

      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>

      <mutil-select-tree :sourceDate="interestedCourse"
                         :selectionDate="applyCourseDatas"
                         @buttonClickEvent="receiveinterestedItem"
                         @cancleClickForMutilSelected="closeshowInterested"></mutil-select-tree>
    </custom-dialog>
  </div>
</template>

<script>
import { MessageSearchPageForSourceType } from '../../../API/workbench';
import mutilSelectTree from '../../common/mutil-select-tree';
export default {
  components: {
    mutilSelectTree
  },
  data () {
    return {
      isShowInterestCourseList: false,
      isShowAdviserList: false,
      salerList: [],
      interestedCourse: [],
      applyCourseDatas: {
        IDs: '',
        Names: ''
      },
      willingnessRateList: [
        {
          key: 1,
          value: 'S 极高'
        },
        {
          key: 2,
          value: 'A 较高'
        },
        {
          key: 3,
          value: 'B 一般'
        },
        {
          key: 4,
          value: 'C 较差'
        }
      ]
    };
  },
  props: {
    customerInfo: Object,
    isReadonly: Boolean
  },
  computed: {

  },
  created () {

  },
  methods: {
    // 选择报名意向级别
    chgWillingnessRank (item) {
      this.customerInfo.WillingnessRateKey = item.key;
      this.customerInfo.WillingnessRateKeyValue = item.value;
    },
    // // 打开感兴趣的课列表
    async openInterestCourse () {
      let result = await this.$basicData.loadCourseNameList();
      this.interestedCourse = [];
      result.forEach(p => {
        if (p.StateKey == 2) {
          this.interestedCourse.push(p);
        }
      });
      this.interestedCourse.splice(0, 0, {
        OLAPKey: '2',
        MainDemoName: '全部课程',
        TypeKey: '1',
        TypeKeyValue: '课类',
        LevelPath: '0.2.',
        FatherKey: '0',
        FatherKeyValue: '',
        StateKey: '2',
        StateKeyValue: '启用',
        UnitPrice: '0.0'
      });

      this.interestedCourse.forEach(o => {
        this.$set(o, 'isActive', false);
      });
      this.isShowInterestCourseList = true;
    },
    // 接收选中值
    receiveinterestedItem (val) {
      this.customerInfo.FineIdSupport = val.selectedIDs.toString().replace(/\,/g, ',');
      this.customerInfo.FineSupport = val.selectedNames.toString().replace(/\,/g, ',');
      this.applyCourseDatas.IDs = this.customerInfo.FineIdSupport;
      this.applyCourseDatas.Names = this.customerInfo.FineSupport;
      this.isShowInterestCourseList = false;
    },
    // 新增回调
    doAddSuccessEvent (result) {
      this.customerInfo.FineIdSupport = result.OLAPKey;
      this.customerInfo.FineSupport = result.MainDemoName;
      this.applyCourseDatas.IDs = this.customerInfo.FineIdSupport;
      this.applyCourseDatas.Names = this.customerInfo.FineSupport;
      this.isShowInterestCourseList = false;
    },
    closeshowInterested () {
      this.isShowInterestCourseList = false;
    },
    // 选择感兴趣的课
    doAfterSelectedCourseName (item) {
      this.customerInfo.FineIdSupport = item.selectedIDs;
      this.customerInfo.FineSupport = item.selectedNames;
      this.isShowInterestCourseList = false;
    },
    // 打开跟销顾问人列表
    openAdviser () {
      // 查询客户来源
      let sourceParam = {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      };
      MessageSearchPageForSourceType(sourceParam).then(result => {
        this.salerList = result.data.PageDataList.filter(o => {
          return o.StateKey == 2;
        });
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择客户来源',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.customerInfo.SourceTypeKey, dataSourceList: this.salerList, required: false, addModuleName: 'sell-source-setting', addmoduleTitleName: '新增来源方式', ModulePowerKey: 45 },
          callBack: {
            onSelectedItem: (data) => {
              this.receiveSaler(data);
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选择跟销顾问
    receiveSaler (item) {
      this.customerInfo.SourceTypeKey = item.OLAPKey;
      this.customerInfo.SourceTypeKeyValue = item.MainDemoName;
    }
  }

};
</script>

<style>
</style>
