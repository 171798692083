<template>
    <span class="class_detail_teacher_list_item button" @click.stop="delItem">{{item.MainDemoName}}
        <span class="class_detail_teacher_list_item_btn " style="margin-right: 8px;" v-if="showDel">x</span>
    </span>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    props:{
        item:Object,
        showDel:Boolean
    },
    methods:{
        seletdItem(){
            this.$emit("seletdItem",this.item)
        },
        delItem(){
            if(this.showDel){
                this.$emit("delItem",this.item)
            }
        }
    }
}
</script>