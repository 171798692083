import { render, staticRenderFns } from "./data-total-list.vue?vue&type=template&id=44f6ef4c&scoped=true"
import script from "./data-total-list.vue?vue&type=script&lang=js"
export * from "./data-total-list.vue?vue&type=script&lang=js"
import style0 from "./data-total-list.vue?vue&type=style&index=0&id=44f6ef4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f6ef4c",
  null
  
)

export default component.exports