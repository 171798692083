<template>
  <div class="massive_bg apply_payment_details_box">
    <div class="apply_payment_box_title">
      {{formTitle}}
      <span>{{SetUpTime}}</span>
    </div>
    <div class="box_dot"
         :class="[secondColor]">
      {{secondTitle}}
    </div>
    <div class="apply_payment_box_number">
      <span>￥</span>{{vAmount}}
    </div>
    <div class="apply_payment_box_singular box_dot dot_blue"
         :class="[lastColor]">
      单数
      <span>{{Count}}</span> 单
    </div>
    <a @click.stop="showDetail"
       class="class_statistics_details_btn">
      {{lastTitle}}
    </a>
  </div>
</template>
<script>
export default {
  name: 'appyStatisticsItem',
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    secondTitle: String,
    dataInfo: Object,           // 数据源
    secondColor: String,      // 绑定颜色.
    lastColor: String,
    lastTitle: String,
    Amount: [Number, String]
  },
  computed: {
    SetUpTime () {
      if (this.dataInfo) {
        if (this.dataInfo.SetUpTime) {
          return this.dataInfo.SetUpTime;
        } else {
          return '';
        }
      }
    },
    vAmount () {
      if (this.Amount && this.Amount != 0) {
        return (Math.round(Number(this.Amount) * 100) / 100).toFixed(2);
      } else {
        return 0;
      }
    },
    Count () {
      if (this.dataInfo) {
        return this.dataInfo.Number || 0;
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>
