<template>
  <div>
    <!-- 完善学生资料 -->
    <div class="single_result_detail form_info_detail">
      <div class="student_info_edit form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow">
        <input-text :formTitle="'申请原因'"
                    :formPlaceholder="'请输入'"
                    v-model="sumitData.Remark"></input-text>

      </div>

      <!-- 提交按钮组 -->
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :fixedBtn="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="doAfterConfirm"
                               @cancelClick="doAfterCancel"></save-cancel-btn-group>
      </div>

    </div>

  </div>
</template>
<script>
import { ApplyAuthorizedDevice } from '../../../API/workbench';
export default {
  data () {
    return {
      sumitData: {
        'CoursewarePackageKey': '',
        'Remark': '',
        'DeviceCode': ''
      }

    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          CoursewareKey: '',    // 教案课件ID
          DeviceCode: ''    // 设备码
        };
      }
    }
  },
  created () {
    console.log(this.dataInfo, 'created');
    this.sumitData.CoursewarePackageKey = this.dataInfo.CoursewareKey;
    let mInfo = JSON.parse(this.$zxClient.GetMInfo());
    this.sumitData.DeviceName = mInfo.MName;
    this.sumitData.DeviceTypeKey = mInfo.MType;
    this.sumitData.DeviceXCode = mInfo.XCode;
    this.sumitData.DeviceUCode = mInfo.UCode;
  },
  methods: {
    doAfterConfirm () {
      if (!this.sumitData.CoursewarePackageKey) {
        layer.alert('课件包未选择');
        return false;
      }
      if (!this.sumitData.DeviceXCode) {
        layer.alert('设备码无法获取');
        return false;
      }
      ApplyAuthorizedDevice(this.sumitData).then(result => {
        console.log(result, 'ApplyAuthorizedDevice');
        this.$zxClient.SetUCode(result.data.DeviceUCode);
        this.$emit('afterSucces');
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAfterCancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>


