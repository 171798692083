<template>
  <div class="alert_dialog">
    <div v-for="(item,index) in optionList"
         :key="index">
      <div v-if="item.isShow"
           class="form_info_field drop_down_list"
           :class="{norole:!vHasModulePower(item.ModulePowerKey)}"
           @click.stop="optionClick(item)">
        <span :class="{left_text:item.explain}">{{item.name}}</span>
        <span v-if="item.explain" class="right_text">{{item.explain}}</span>
        <input-form-tip :tips="item.tips"
                        v-if="item.tips"></input-form-tip>
      </div>

    </div>
  </div>

</template>
<script>
export default {
  name: 'alertDialog',
  data () {
    return {
    };
  },
  props: {
    optionJsonList: {
      type: Array,
      default: function () {
        return [{
          name: '',
          isShow: true,
          ModulePowerKey: 0
        }];
      }
    }
  },
  created () {
  },
  computed: {
    optionList () {
      return this.optionJsonList;
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    optionClick (optionItem) {
      optionItem.ModulePowerKey = optionItem.ModulePowerKey || '';
      if (this.vHasModulePower(optionItem.ModulePowerKey)) {
        this.$emit('clickOptionForAlertDialog', optionItem);
      } else {
        this.$utils.CheckModulePowerToTips(optionItem.ModulePowerKey);
      }
    }
  }

};
</script>

