<template>
  <!-- 调整考勤和课消课时 弹窗主内容 - -->
  <div>
    <div class="form_info_detail">
      <div class="content_marginTop_30 content_marginRL">
        <!-- 课表信息 -->
        <div class="single_result_info single_shadow">
          <div class="single_result_info_left">
            <span>目标考勤</span>
          </div>
          <div class="single_result_info_right">
            <!-- active 选中当前 -->
            <div class="single_result_info_detail">
              <div class="info_detail_name">
                <span>{{studentItem.MainDemoName?studentItem.MainDemoName:studentItem.StudentKeyValue}}</span>
              </div>
              <div class="info_detail_list">
                <span class="info_detail_item">{{classTime}}</span>
                <span :title="item.CourseNameKeyValue || item.CourseName"
                      class="info_detail_item info_detail_item_name">{{item.CourseNameKeyValue || item.CourseName }}</span>
                <span class="info_detail_item">{{vClassCount}}</span>

                <span v-if="IsTrialKey"
                      class="info_detail_green">(试)</span>
                <span class="info_detail_red"
                      v-else-if="oweClassCount">(欠)</span>
                <span class="info_detail_item">{{mClockingState(studentItem.AttenceStatusKeyValue||item.AttenceStatusKeyValue||"无状态")}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 变更考勤 -->
        <div class="form_info_canedit single_shadow"
             style="border-top:1px solid #ececec">
          <!-- 选择课程下拉菜单 -->
          <div class="form_dorp_down form_info_edit">
            <div class="form_info_line form_info_required required_position">
              <div class="form_info_field">
                <span>变更考勤</span>
              </div>
              <div class="form_info_value">
                <el-select v-model="curSelectedStatusKey"
                           placeholder="必选"
                           @change="choseItem">
                  <el-option v-for="item in cAttendanceStatus"
                             :class="{displayNone:!item.isShow}"
                             :key="item.type"
                             :label="item.name"
                             :value="item.type"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form_info_edit"
             style="border-top:5px solid #ececec">
          <div class="form_info_line single_shadow"
               style="padding: 0px 12px">
            <input-text :formTitle="'处理原因'"
                        style="width: 100%;"
                        :readonly="false"
                        :formPlaceholder="'选填'"
                        :required="false"
                        v-model="Remarks"></input-text>
          </div>
        </div>
        <!-- 按钮组 -->
        <div>
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmSave"
                                 @cancelClick="closeDialog"></save-cancel-btn-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { kqChange } from '../../../../API/workbench.js';
// 修改考勤
export default {
  name: 'amendAttendanceItem',
  // studentItem为选中学生. item为选中课信息或者是选中的该条考勤记录.isCouserAmandance主要因为组件不能兼容工作台课考勤模块,故多传个值区分
  props: ['studentItem', 'item', 'isCouserAmandance'],
  data () {
    return {
      curSelectedStatusKey: '',
      attendanceStatus: [
        {
          type: 3,
          name: '签到',
          eventName: 'changeToAttendance',
          isShow: true
        },
        {
          type: 4,
          name: '请假',
          eventName: 'changeToLeave',
          isShow: true
        },
        {
          type: 5,
          name: '旷课',
          eventName: 'changeToAbsence',
          isShow: true
        },
        {
          type: 2,
          name: '未考勤',
          eventName: 'changeToCancel',
          isShow: true
        }
      ],
      cAttendanceStatus: [],
      selectorType: '',
      selectorName: '',
      isSaving: false, // 正在保存
      Remarks: '' // 处理原因--备注
    };
  },
  components: {},
  watch: {
    'studentItem.AttenceStatusKey': {
      // 监听如果选中学生发生改变的话,就初始化默认值.（没见这字段）
      handler (o, n) {
        this.initCurSelectedStatusKey();
      },
      immediate: true
    },
    'item.AttenceStatusKey': {
      // 监听如果选中学生发生改变的话,就初始化默认值.
      handler (o, n) {
        this.initCurSelectedStatusKey();
      },
      immediate: true
    }
  },
  // curSelectedStudentInfo  选中的该学生的所有信息.
  created () {
    this.initCurSelectedStatusKey();
  },
  computed: {
    classTime () {
      if (this.item.ClassTime) {
        return this.$utils.getFormatDateToQuery(this.item.ClassTime).toLocaleDateString();
      } else {
        return '';
      }
    },
    vClassCount () {
      if (this.item.ClassHourKey == 5 || this.item.ISClassHourDebtKey == 1) {
        if (this.item.ClassCount) {
          return Number(this.item.ClassCount) + '课时';
        } else {
          return Number(this.studentItem.PeriodCount) + '课时';
        }
      } else {
        // console.log('studentItem', this.studentItem);
        if (this.studentItem.ClassHourKey == 5 || this.studentItem.ISClassHourDebtKey == 1) {
          return Number(this.studentItem.PeriodCount) + '课时';
        } else {
          return '0课时';
        }
      }
    },
    IsTrialKey () {
      if (this.studentItem.IsTrialKey == 1 || this.item.IsTrialKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    oweClassCount () {
      if (this.isCouserAmandance == true || this.studentItem.ISClassHourDebtKey == 1 || this.item.ISClassHourDebtKey == 1) {
        return true;
      } else {
        return false;
      }
      //       if (this.isCouserAmandance == true){
      //   // 工作台课考勤选中学员考勤时进入.
      //   if (this.studentItem.ISClassHourDebtKey == 1) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   // 异常处理修改考勤. 及学员档案考勤历史时进入.
      //   if (this.item) {
      //     console.log(this.item, '235');
      //     if (this.item.ISClassHourDebtKey == 1) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } else {
      //     if (this.studentItem.ISClassHourDebtKey == 1) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
    }
  },
  methods: {
    mClockingState (val) {
      if (val == '出勤') {
        return '签到';
      } else {
        return val;
      }
    },
    choseItem (val) {
      if (val == 3) {
        this.selectorName = '签到';
      } else if (val == 4) {
        this.selectorName = '请假';
      } else {
        this.selectorName = '旷课';
      }
      if ((this.studentItem.AttenceStatusKeyValue || this.item.AttenceStatusKeyValue) == this.selectorName) {
        val = 0;
      }
      this.selectorType = val;
    },
    initCurSelectedStatusKey () {
      this.cAttendanceStatus = this.$utils.parseJson(this.attendanceStatus);
      if (
        this.studentItem.AttenceStatusKey == 3 ||
        this.item.AttenceStatusKey == 3
      ) {
        this.cAttendanceStatus[0].isShow = false;
      } else if (
        this.studentItem.AttenceStatusKey == 4 ||
        this.item.AttenceStatusKey == 4
      ) {
        this.cAttendanceStatus[1].isShow = false;
      } else if (
        this.studentItem.AttenceStatusKey == 5 ||
        this.item.AttenceStatusKey == 5
      ) {
        this.cAttendanceStatus[2].isShow = false;
      }
    },
    closeDialog () {
      this.isSaving = false;
      this.$emit('closeDialog');
      // this.$emit('isAmendSingleCourseHide');
    },
    // 确认时直接触发父组件的考勤变更接口.
    confirmSave (submitCallBack, ISClassHourLackKey) {
      ISClassHourLackKey = ISClassHourLackKey || 0;
      let locaIndex = this.attendanceStatus.findIndex(o => {
        return o.type == this.selectorType;
      });
      // 兼容 考勤管理
      if (this.curSelectedStatusKey) {
        var that = this;
        kqChange(
          that.studentItem.StudentKey || that.studentItem.OLAPKey,
          that.item.ScheduleCourseKey,
          ISClassHourLackKey,
          that.selectorType,
          this.item.OLAPKey ? this.item.OLAPKey : this.studentItem.StudentArrangeKey,
          this.Remarks
        ).then(result => {
          layer.alert('设置成功');
          this.$bus.emit('AfterChangeAttendanceStatus', this.item.ScheduleCourseKey, that.studentItem.StudentKey || that.studentItem.OLAPKey, this.item.StatusKey);
          that.$emit('sendFatherForChgAttendance', that.attendanceStatus[locaIndex], result.data); // 通知考勤管理模块.
          that.$emit('isAmendSingleCourseHide');
          that.$emit('updateAttenHistory', this.item.ScheduleCourseKey, that.studentItem.StudentKey || that.studentItem.OLAPKey, this.selectorType, result.data); // 学生档案通知更新数据.
          that.curSelectedStatusKey = '';
        }).catch(err => {
          if (err.code == 60001) {
            layer.confirm(
              that.studentItem.StudentKeyValue + '课时不足，是否以欠课时的方式继续' + that.selectorName + '？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.studentItem.ISClassHourLackKey = 1;
                      that.confirmSave(submitCallBack, that.studentItem.ISClassHourLackKey);
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else {
            layer.alert(err.msg);
          }
        })
          .finally(() => {
            if (submitCallBack) {
              submitCallBack();
            }
          });
      } else {
        if (submitCallBack) {
          submitCallBack();
        }
        layer.alert('请选择变更考勤状态再确定');
      }
    }
  }
};
</script>

