<template>
  <div
    class="course_list_info"
    :class="vRolesType"
    @click.stop="showSeletedPupUp($event)"
  >
    <div class="course_list_info_top pr">
      {{ vWeekKeyValue }} {{ vStartClassTime }}
      {{ rulesClassItem.MTeacherKeyValue }}
      <!-- <div class="manage_content_menuBtn"></div> -->
    </div>
    <div class="course_list_info_bottom" v-if="rulesClassItem.IsNotEndKey==1">
      不结束丨
      {{ Number(rulesClassItem.Num_SyncArranged) }}人
    </div>
    <div class="course_list_info_bottom" v-else>
      截止{{ rulesClassItem.LastClassTime }}
      {{ Number(rulesClassItem.ClassCount) }}节 丨
      {{ Number(rulesClassItem.Num_SyncArranged) }}人
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    rulesClassItem: Object,
  },
  computed: {
    vRolesType() {
      // console.log(this.rulesClassItem, 'rulesClassItem');
      // FullPeopleCount   Num_SyncArranged
      if (this.rulesClassItem) {
        if (
          Number(this.rulesClassItem.FullPeopleCount) == 0 ||
          Number(this.rulesClassItem.FullPeopleCount) <=
            Number(this.rulesClassItem.Num_SyncArranged)
        ) {
          return "type_green";
        } else if (
          Number(this.rulesClassItem.WarnLossCount) > 0 &&
          Number(this.rulesClassItem.Num_SyncArranged) <=
            Number(this.rulesClassItem.WarnLossCount)
        ) {
          return "type_red";
        } else {
          return "type_blue";
        }
      }
      return "type_blue";
    },
    vProgress() {
      if (this.vRolesType == "type_blue") {
        return (
          (Number(this.rulesClassItem.Num_SyncArranged) /
            Number(this.rulesClassItem.FullPeopleCount)) *
            100 +
          "%"
        );
      } else {
        return "100%";
      }
    },
    vWeekKeyValue(){
      return this.$utils.getWeekTimeByNum(Number(this.rulesClassItem.WeekKey))
    },
    SaaSClientInfo() {
      return this.$store.getters.SaaSClientInfo;
    },
    vStartClassTime() {
      if (this.rulesClassItem.ClassTimeName) {
        return this.rulesClassItem.ClassTimeName.substring(0, 5);
      } else {
        return "";
      }
    },
    // 剩余不到14天.
    vClassTimeStatu() {
      if (this.rulesClassItem) {
        if (
          (Date.new(this.rulesClassItem.LastClassTime).getTime() -
            Date.new().getTime()) /
            1000 <
          86400 * 14
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  created() {},
  methods: {
    showSeletedPupUp(e) {
      console.log(this.rulesClassItem, "showSeletedPupUp");
      let Rect = this.$el.querySelector(".course_list_info_bottom").getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      console.log(Rect, parentRect, 'this.$parent', this.$parent);
      this.$emit(
        "showPupUp",
        this.$el.querySelector(".course_list_info_bottom"),
        this.rulesClassItem,
        Rect.left - parentRect.left,
        Rect.bottom - parentRect.top,
        parentRect.top
      );
    },
  },
};
</script>
