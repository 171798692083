<template>
  <div class="payment_statistics_bottom "
       style="width:99%">
    <div class="list_title">
      <span class="font_black"
            style="font-size:20px">
        {{seletedItem.MainDemoName}}
      </span>
      <span class="font_gray"
            style="font-size:14px;color:#999">
        分配正课生：{{seletedItem.StudentCount}}人
      </span>

    </div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="statistics_list">
        <span class="statistics_item border_left"
              :class="(!searchObj.StatusType||searchObj.StatusType==0)?'opt':''"
              @click="changeStatusType(0)">全部</span>
        <span class="statistics_item "
              style="border-left:none"
              :class="searchObj.StatusType==1?'opt':''"
              @click="changeStatusType(1)">续费预警:{{Number(dataInfo.RenewalWarningCount)}}</span>
        <span class="statistics_item statistics_item_red"
              :class="searchObj.StatusType==2?'opt':''"
              @click="changeStatusType(2)">失效未续:{{Number(dataInfo.NotRenewalCount)}} </span>
        <span class="statistics_item"
              style="border-left:none"
              :class="searchObj.StatusType==3?'opt':''"
              @click="changeStatusType(3)">出勤率预警:{{Number(dataInfo.AttendanceWarningCount)}}人</span>
        <span class="statistics_item"
              style="border-left:none"
              :title="'周排课次≤1的学生'"
              :class="searchObj.StatusType==4?'opt':''"
              @click="changeStatusType(4)">排课不足:{{Number(dataInfo.StuentNotOneCount)}}人</span>
        <span class="statistics_item "
              style="border-left:none"
              :class="searchObj.StatusType==5?'opt':''"
              @click="changeStatusType(5)">长期未到校预警:{{Number(dataInfo.CampusWarningCount)}}人</span>
        <span class="statistics_item "
              style="border-left:none"
              :class="searchObj.StatusType==6?'opt':''"
              @click="changeStatusType(6)">欠课时学生:{{Number(dataInfo.OweCourseStuentCount)}}人</span>
        <span class="statistics_item border_right"
              :class="searchObj.StatusType==7?'opt':''"
              @click="changeStatusType(7)">未排课学生:{{Number(dataInfo.NoArrangeStuentCount)}}人</span>
      </div>

    </div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="table_select_box"
           style="margin-right: 10px;margin-left: 10px; min-width: 180px">
        <div class="table_select_title">最近跟单</div>
        <el-select v-model="dateType"
                   placeholder="请选择"
                   style="width: 150px;margin-right: 20px;"
                   ref="resetText"
                   @change="selectOverdueType">
          <el-option v-for="item in overdueTypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <div class="table_select_title">学生类型</div>
        <el-select v-model="searchObj.Isformal"
                   placeholder="请选择"
                   style="width: 150px"
                   ref="resetText"
                   @change="selectStudentType">
          <el-option v-for="item in studentTypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>

      </div>
      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"
                                  style="margin-right:10px"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>

    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowForm"
                   :before-close="closeFormDialog">
      <clew-follow-form :studentItem="studentItem"
                        @close="closeFormDialog"
                        @afterSuccess="getDataList"></clew-follow-form>
    </custom-dialog>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';

// import list from '../../class-spend-statistics-analyze/components/table';
import clewFollowForm from './list-components/clew-follow-form'
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import { GetCourseStudentList, GetRenewalWarningCount } from '../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        Isformal: '',
        StatusType: '',
        FollowTime: '',
        startTime: '',//this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: '',//this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        IsExportKey: 0,
      },
      dateType: 0,
      dataInfo: {
        RenewalWarningCount: 0,//续费预警
        NotRenewalCount: 0, //失效未续
        AttendanceWarningCount: 0, //预警出勤率数
        CampusWarningCount: 0, //长期未到校预警数
        StuentNotOneCount: 0, //排课不足预警
        OweCourseStuentCount: 0, //欠课时学生
        NoArrangeStuentCount: 0 //未排课学生
      },
      overdueTypeList: [   //1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
        {
          label: '全部',
          value: 0
        },
        {
          label: '0-30天',
          value: 1
        },
        {
          label: '31-60天',
          value: 2
        },
        {
          label: '61-90天',
          value: 3
        },
        {
          label: '超过90天',
          value: 4
        },
      ],
      studentTypeList: [   //是否正课生 0全部 1-是 2不是
        {
          label: '全部',
          value: 0
        },
        {
          label: '正课生',
          value: 1
        },
        {
          label: '非正课生',
          value: 2
        },
      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          sortable: false,
          align: 'left',
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '可用课单',
          prop: 'XykdCount',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual button',
          type: 'text-item',
          extend: {
            // phone: 'CustomerPhoneName',
            click: (rowData) => {
              let item = {
                routerName: this.$route.name,
                moduleName: 'studentFile',
                name: '学生档案',
                data: { studentID: rowData.OLAPKey, defaultShowType: 4 }
              };
              this.$dialog.open(this, item);
            }
          }
        },
        {
          label: '可用课时',
          prop: 'DeductXykdCount',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'amount-item',
          decimalPlace: 1,
        },
        {
          label: '欠课时数',
          prop: 'ClassHourDebtCount',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'amount-item',
          decimalPlace: 1,
        },
        {
          label: '续费预警',
          prop: 'RenewalWarningCount',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '续费失效',
          prop: 'NotRenewalCount',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '30天出勤率',
          prop: 'AttenceLvText',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,

        },
        {
          label: '周排课次',
          prop: 'StudentArrangeCount',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '最近签到',
          prop: 'LastSignTime',
          sortable: false,
          type: 'text-item',
          width: 100,
          decimalPlace: 0
        },
        {
          label: '最近跟单',
          prop: 'FollowTime',
          sortable: false,
          type: 'text-item',
          width: 100,
        },
        {
          label: '最近跟进记录',
          prop: 'FollowContentValue',
          sortable: false,
          type: 'text-item',
          minWidth: 180,
          extend: {
            type: 'expand',
            click: (rowData) => { this.doAfterTableBtnClick(rowData); }
          }
        },

        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          width: 120,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '跟单',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                }
              }

            ]
          }
        }

      ],
      dataReportList: [],
      isShowForm: false,
      studentItem: null,
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    clewFollowForm,
    inputSearchContainBtn
  },
  props: {
    seletedItem: {
      type: Object,
      default: () => {
        return {
          OLAPKey: 0,
          MainDemoName: '趁',
          StudentCount: 50
        }
      }
    },

  },

  created () {
  },
  computed: {

    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        // o.AttenceLvText=Number(o.AttenceLv)+'%';
        o.AttenceLvText = o.AttenceLv ? Number(o.AttenceLv) + '%' : '-';
        o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        o.FollowContentValue = o.FollowConent ? '<div class="add_documentary_class">' + o.FollowConent + '</div>' : '';

        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
    this.getDataInfo()
  },
  watch: {

    seletedItem: {
      handler (c, o) {
        if (c) {
          this.searchObj.TeacherKey = c.OLAPKey
          this.getDataList();
          this.getDataInfo()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeStatusType (type) {
      this.searchObj.StatusType = type
      this.getDataList();
      this.getDataInfo()
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetCourseStudentList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '在校学生统计',
          clist: [
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '可用课单', cName: 'XykdCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '可用课时', cName: 'DeductXykdCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '续费预警', cName: 'RenewalWarningCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '续费失效', cName: 'NotRenewalCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '30天出勤率', cName: 'AttenceLv', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '周排课次', cName: 'StudentArrangeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近签到', cName: 'LastSignTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟单', cName: 'FollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟进记录', cName: 'FollowConent', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    closeFormDialog () {
      this.isShowForm = false
    },
    getDataInfo () {
      GetRenewalWarningCount(this.searchObj.TeacherKey).then(result => {
        this.dataInfo = result.data
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    selectOverdueType (val) {
      console.log("selectOverdueType", val, this.dateType)
      this.getSeachDate(val)
      this.getDataList();
      this.getDataInfo()
    },
    selectStudentType (val) {
      this.getDataList();
    },
    formatDate (date) {
      date = Date.new(date.replace(/-/g, '/'));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    getSeachDate (key) {
      if (key == 0) {
        this.searchObj.startTime = '';
        this.searchObj.endTime = '';
        return
      }
      let monday = new Date();
      let sunday = new Date();
      const firstDay = key == 1 ? 0 : key == 2 ? 31 : key == 3 ? 61 : 91;
      const LastDay = key == 1 ? 30 : key == 2 ? 60 : key == 3 ? 90 : 0;
      monday.setDate(monday.getDate() - firstDay);


      sunday.setDate(sunday.getDate() - LastDay);

      this.searchObj.endTime = this.formatDate(monday.toLocaleDateString());
      if (LastDay == 0) {
        this.searchObj.startTime = '';
      } else {
        this.searchObj.startTime = this.formatDate(sunday.toLocaleDateString());
      }
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.dateType = 0
      // this.searchObj.TeacherKey=0
      this.searchObj.StatusType = 0
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    getDataList (queryParams) {
      let fn = '';
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      GetCourseStudentList(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      this.studentItem = rowData
      this.isShowForm = true
    },

  }
};
</script>
<style scoped>
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_search {
  width: auto !important;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
@media screen and (max-width: 1360px) {
  .payment_statistics_bottom {
    max-width: 1180px;
  }
}
.payment_statistics_bottom .list_title {
  width: 100%;
  height: 87px;
  margin: auto;
  padding-left: 20px;
  line-height: 87px;
}
.statistics_list {
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
}

.statistics_list .opt {
  background: rgba(52, 152, 219, 0.2);
  border: 1.25px solid #3498db !important;
  color: #3498db !important;
}

.statistics_list .statistics_item {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #ff9800;
  border: 1.25px solid #ff9800;
}
.statistics_list .statistics_item_red {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #f75040;
  border: 1.25px solid #f75040;
}
.statistics_list .statistics_item:hover {
  background: rgba(52, 152, 219, 0.2);
}
.statistics_list .border_left {
  min-width: 68px;
  text-align: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.statistics_list .border_right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}
.table_list_content >>> .el-table .add_documentary_class {
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  color: #333 !important;
}
.table_list_content >>> .el-table .add_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -20.5px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding-right: 17px;
  z-index: 2;
  background: url('../../../../public/image/add_a_job.png') no-repeat center;
  background-size: 20px 20px;
}
</style>