<template>
  <!-- 新增试课申请-->
  <div class="single_result_detail">
    <search-student-box @selectedStudent="doAfterSelectedStudent"
                        :selectedStudentInfo="studentInfo"
                        :switchItem="1"
                        class="form_info_top "></search-student-box>
    <div v-if="vIsSelectionStudent">
      <div class="class_table_form form_info_edit content_marginTop_8 content_marginRL">
        <!-- 课程选择 -->
        <div class="class_table_courseChoose single_shadow">
          <!-- 选择课程下拉菜单 -->
          <!-- 可编辑界面 form_info_canedit -->
          <div class="form_info_canedit">
            <div class="form_info_edit">
              <div class="form_info_line">
                <div class="form_info_field form_info_required">
                  <span>课程</span>
                </div>
                <div class="form_info_value form_info_select">
                  <input type="text"
                         name
                         id
                         readonly
                         v-model="selectionCourseTypeName"
                         @click.stop="chooseCourseTypeName"
                         placeholder="必选" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form_info_list content_marginTop_8 single_shadow">
          <div class="form_info_line">
            <div class="form_info_field">预约要求</div>
            <div class="form_info_value">
              <input type="text"
                     v-model="saveParams.Remarks"
                     placeholder="请输入预约要求" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="confirmSubmitEvent"
                               @cancelClick="cancleBtnClick"></save-cancel-btn-group>
      </div>
    </div>
  </div>
</template>
<script>
import searchStudentBox from '../../common/search-student-box';
import { getCourseTypeNameList, ApplyTrialClass } from '../../../API/workbench.js';

export default {
  name: 'addTryCourse',
  data () {
    return {
      searchDialogInitJson: {
        name: '目标学生',
        roleName: '',
        isShowAddBtn: false
      },
      selectionCourseTypeName: '',
      courseTypeNameList: [], // 适用课程列表
      saveParams: {
        // 保存提交参数
        ID: 0, // 学生ID
        ClassTypeKey: 0, // 课名ID
        SourceKey: 2, //	预约来源 2:代申请 3:微课堂
        Remarks: '' // 预约要求
      },
      studentInfo: {}
    };
  },
  props: {
    selectionCourseNameData: Object // 选中的课
  },
  components: {
    searchStudentBox
  },
  created () {
    this.getCourseTypeNameList();
  },
  mounted () { },
  methods: {
    // 获取课类课名
    getCourseTypeNameList () {
      getCourseTypeNameList()
        .then(result => {
          result.data.forEach(obj => {
            if (obj.StateKey == 2) {
              this.courseTypeNameList.push(obj);
            }
          });
          this.courseTypeNameList.splice(0, 0, {
            OLAPKey: '2',
            MainDemoName: '全部课程',
            TypeKey: '1',
            TypeKeyValue: '课类',
            LevelPath: '0.2.',
            FatherKey: '0',
            FatherKeyValue: '',
            StateKey: '2',
            StateKeyValue: '启用',
            UnitPrice: '0.0'
          });
        })
        .catch(err => {
          console.info(err);
          layer.alert(err.data.msg);
        });
    },
    // 改变课名
    changeCourseName (item) {
      this.saveParams.ClassTypeKey = item.OLAPKey;
      this.selectionCourseTypeName = item.MainDemoName;
    },
    // 确定
    confirmSubmitEvent (callBack) {
      if (this.saveParams.ClassTypeKey > 0) {
        ApplyTrialClass(this.saveParams)
          .then(result => {
            layer.alert('新增成功');
            this.$emit('confirmSubmitEvent');
          })
          .catch(err => {
            layer.alert(err.data.msg);
          })
          .finally(() => {
            console.log('ApplyTrialClass:finally');
            if (callBack) {
              callBack();
            }
          });
      } else {
        if (callBack) {
          callBack();
        }
        layer.alert('课程不可为空');
      }
    },
    // 取消
    cancleBtnClick () {
      this.$emit('closeAddTryCourseDialog');
    },
    // 选中学生
    doAfterSelectedStudent (item) {
      this.studentInfo = item;
    },
    chooseCourseTypeName () {
      this.$emit('showCourseNameList');
    }
  },
  watch: {
    'studentInfo.OLAPKey': {
      handler (n, o) {
        this.saveParams.ID = n;
      },
      deep: true
    },
    selectionCourseNameData (n, o) {
      this.saveParams.ClassTypeKey = n.id;
      this.selectionCourseTypeName = n.label;
    }
  },
  computed: {
    vIsSelectionStudent () {
      return JSON.stringify(this.studentInfo) != '{}';
    }
  }
};
</script>
<style >
</style>
