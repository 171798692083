<template>
  <!-- 作业统计 -->
  <div>
    <div class="school_inspector_statistics_top">
      <!-- 条件过滤 -->
      <div class="top_select">
        <condition-filtration-box :queryParams="queryParams"
                                  :campusList="campusList"
                                  @searchValue="search"></condition-filtration-box>
      </div>
      <!-- 汇总信息 -->
      <div class="top_content">
        <statistics-content-list v-for="(item,key) in statisticsInfoList"
                                 :key="key"
                                 :dataInfo="item"></statistics-content-list>
      </div>
    </div>
    <div class="school_inspector_statistics_bottom">
      <div class="bottom_left scroll_type">
        <div class="left_list_title">老师作业批改排名</div>
        <div class="left_list_ul" :style="leftScrollHeight">
          <teacher-ranking-list v-for="(item,key) in vHomeWorkTeacherRankedData"
                                :key="key"
                                :dataInfo="item"
                                :dataList="vHomeWorkTeacherRankedData"
                                @selectTeacherRanking="selectTeacherRanking"></teacher-ranking-list>
          <div v-if="vHomeWorkTeacherRankedData.length==0"
               style="margin-top: 200px;">
            <basics-nodata-tips></basics-nodata-tips>
          </div>
        </div>
      </div>
      <div class="bottom_right"
           v-if="summarizingInfo">
        <table-list :paramsObj="queryParams"></table-list>
      </div>
    </div>
  </div>
</template>
<script>
import conditionFiltrationBox from '../../common/condition-filtration-box';
import statisticsContentList from '../../common/statistics-content-list';
import teacherRankingList from '../teacher-ranking-list';
import tableList from './table-list';
import { hqInspectHomeWorkStat, InspectHomeWorkStat } from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      statisticsInfoList: [
        { name: '作业批改率', key: 1, type: 'progress', value: 0, className: '' },
        { name: '已布置', key: 2, type: 'count', value: 0, className: '' },
        { name: '已提交', key: 3, type: 'count', value: 0, className: '' },
        { name: '已批改', key: 4, type: 'count', value: 0, className: '' },
        { name: '未批改', key: 5, type: 'count', value: 10, className: 'font_red' }
      ],
      summarizingInfo: null, // 汇总信息查询
      // 查询参数
      queryParams: {
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        SchoolKey: '', //	门店id
        TeacherKey: ''
      },
      leftScrollHeight: '' // 老师排名列表滚动高度
    };
  },
  components: {
    conditionFiltrationBox,
    statisticsContentList,
    teacherRankingList,
    tableList
  },
  props: {
    campusList: {
      type: Array,
      default: []
    },
    schoolInfo: Object
  },
  created () {
    this.queryParams.SchoolKey = this.schoolInfo.SchoolKey;
    this.queryParams.StartDate = this.schoolInfo.StartDate;
    this.queryParams.EndDate = this.schoolInfo.EndDate;
    this.getSearchPageNotCheckPrepareLessons(this.queryParams);
  },
  methods: {
    // 汇总信息查询
    getSearchPageNotCheckPrepareLessons (queryParams) {
      let apiFN = (this.vTypeKey ? hqInspectHomeWorkStat : InspectHomeWorkStat);
      apiFN(queryParams).then(result => {
        console.log('作业汇总信息查询', result.data);
        this.summarizingInfo = result.data;
        if (this.summarizingInfo.HomeWorkTeacherRankedData.length > 0) { // 默认选中第一位老师
          this.queryParams.TeacherKey = this.summarizingInfo.HomeWorkTeacherRankedData[0].MTeacherKey;
        }
        console.log('作业汇总信息查询', this.summarizingInfo);
        if (this.summarizingInfo.HomeWorkStatData) {
          this.statisticsInfoList[0].value = Number(this.summarizingInfo.HomeWorkStatData.CheckRate); // 作业批改率
          this.statisticsInfoList[1].value = this.summarizingInfo.HomeWorkStatData.Publish;// 已布置
          this.statisticsInfoList[2].value = this.summarizingInfo.HomeWorkStatData.Submit;// 已提交
          this.statisticsInfoList[3].value = this.summarizingInfo.HomeWorkStatData.Check;// 已批改
          this.statisticsInfoList[4].value = this.summarizingInfo.HomeWorkStatData.NotCheck;// 未批改
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 根据校区时间搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.$emit('search', queryParams);
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    // 根据老师排名搜索
    selectTeacherRanking (dataInfo) {
      console.log('根据老师排名搜索', dataInfo);
      this.queryParams.TeacherKey = dataInfo.MTeacherKey;
    },
        // 获取动态老师排名列表滚动高度
    teacherListScrollHeight (setHeight) {
      this.leftScrollHeight = 'height:' + setHeight + 'px';
    }
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vHomeWorkTeacherRankedData () {
      let list = [];
      if (this.summarizingInfo && this.summarizingInfo.HomeWorkTeacherRankedData.length > 0) {
        this.summarizingInfo.HomeWorkTeacherRankedData.forEach(o => {
          this.$set(o, 'isOpt', false);
          this.$set(o, 'SubmitName', '已批改');
          this.$set(o, 'Submit', o.HomeWorkChecked);
          this.$set(o, 'NotSubmitName', '未批改');
          this.$set(o, 'NotSubmit', o.HomeWorkNotChecked);
          this.$set(o, 'SubmitRateName', '批改率');
          this.$set(o, 'SubmitRate', o.HomeWorkCheckedRate);
          list.push(o);
        });
        list[0].isOpt = true;
      }
      return list;
    }
  }
};
</script>

<style>
</style>

