<template>
  <!-- 报名欠款学生 -->
  <!-- 欠课时预警数据列表 -->
  <div class="defect_warning content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30">
    <div class="population">
      <div class="population_left">
        <span class="">报名欠款学生</span>
        <span class="">{{VstatisticsCount}}人 | {{vTotalAmount}}元</span>
      </div>
    </div>
    <input-search-contain-btn v-model="searchText"
                              :placeholder="'请输入姓名、姓名首字母、手机号'"
                              @onSearchEvent="inputSearch"></input-search-contain-btn>

    <div class="new_table_list_box"
         v-if="oweClasshourWarning.PageDataList.length>0">
      <div class="new_table_list_thead">
        <div style="width: 160px;"
             class="new_table_list_th style_gray tl">姓名</div>
        <div style="width: 126px;padding-left:23px"
             class="new_table_list_th style_yellow tl">手机号</div>
        <div style="width: 100px;padding-left:20px"
             class="new_table_list_th style_red tl">欠款金额</div>
        <!-- <div style="width: 75px;padding-left:20px"
             class="new_table_list_th style_green tl">微信</div> -->
      </div>
      <div class="apply_debt_warning new_table_list_tbody"
           @scroll="scrollTOP">
        <div class="new_table_list_tr"
             v-for="(item,index) in oweClasshourWarning.PageDataList "
             :key="index"
             @click.stop="selectAmendEvent(item,$event)">
          <div class="new_table_list_td tl font_blue"
               style="width: 160px;"
               @click.stop="goStudentFile(item)">
            {{item.StudentKeyValue}}
            <div class=""
                 style="display:inline-block;margin-left: 10px;"
                 :class="Number(item.SubscribeKey)>0?'td_WX_open':'td_WX_close'"></div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 126px;padding-left:23px">
            <div class="school_warning_content_center ">{{$utils.phoneModulePower(item.CustomerPhoneName)||'--'}}</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 100px;padding-left:20px">
            <span class="font_red">{{CurrentClassHourCount(item.DebtAmount)}}</span>元
          </div>
          <!-- 微信 -->
          <!-- <div class="new_table_list_td tl"
               style="width: 75px;padding-left:20px"
               @click.stop="doWeChatTemplateShow(item)">
            <div class="td_WX_open"
                 :class="Number(item.SubscribeKey)>0?'td_WX_open':'td_WX_close'"></div>
          </div> -->
          <div class="new_table_list_arrow"></div>
        </div>
        <div class="new_table_list_bottom"
             v-if="isLoadEnd">
          <span class="loading_btn">加载中...</span>
        </div>
        <div class="new_table_list_bottom"
             v-if="noMore">
          <span class="font_gray">已显示全部</span>
        </div>
      </div>

    </div>
    <div class="course_block_nodata"
         style="padding-bottom:20px;"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                          :dropDownList="popUpList"
                          :positionLeft="positionLeft"
                          :positionTop="positionTop"
                          @onClick="doAfterClick"></pop-up-drop-down-box>

  </div>
</template>
<script>
import {
  getDebtAmountWarning,
  getWarningIgnore,
  getStudentRelatedMsg
} from '../../../API/workbench.js';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';

export default {
  data () {
    return {
      oweClasshourWarning: {
        PageDataList: [],
        Total: 0,
        TotalAmount: 0
      }, // 欠课时预警
      PageMax: 0,
      PopUpShow: false, // 弹出层选择菜单
      studentItem: {}, // 操作按钮选择对象
      searchText: '',
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      noMore: false, // 判断是否没有数据了.
      isLoadEnd: false, // 判断是否加载完.
      reply: {
        name: '还款',
        eventName: 'payAndWithdraw',
        ModulePowerKey: 42
      },
      isShowMainForm: false, // 显示
      popUpList: [
        { name: '微信提醒', type: 'WeChat', isShow: true },
        { name: '还款', type: 'payAndWithdraw', isShow: true, ModulePowerKey: 42 },
        { name: '销账', type: 'WriteOff', isShow: true },
        { name: '忽略', type: 'ignore', isShow: true }
      ],
      positionLeft: 0,
      positionTop: 0,
      isShowAlertDialog: false
    };
  },
  components: {
    popUpDropDownBox
  },
  created () {
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlertDialog = false;
      }
    });
    this.doShowPopupShowClick();
  },
  mounted () {
    this.$dialog.register(this, ['paymentrepayment', 'writeoffarrears', 'studentFile', 'weChatTemplate']);
  },
  watch: {},
  methods: {
    // 微信模板通知
    doWeChatTemplateShow (item) {
      if (this.vWeChatPattern) { // 托管模式
        layer.alert('非自有公众号, 不能发送通知');
      } else if (Number(this.$utils.getSaaSClientSet(36)) == 0) {
        layer.alert('该通知开关未开启');
      } else if (item.SubscribeKey == 0) {
        // layer.alert('非自有公众号, 不能发送通知');
      } else {
        this.$dialog.open(this, {
          name: '欠费提醒',
          routerName: this.$route.name,
          moduleName: 'weChatTemplate',
          data: { StudentKey: item.StudentKey, eventId: 35 }
        });
      }
      console.log(this.$store.getters.SaaSClientInfo, '微信模板显示');
    },
    // 打开学生档案.
    goStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchText = '';
      this.oweClasshourWarning.PageDataList = [];
      this.oweClasshourWarning.Total = 0;
      this.$emit('chgCallBackFunction');
      this.noMore = false;
      this.isShowMainForm = false;
      this.isShowAlertDialog = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      this.updatewarningList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.isShowAlertDialog = false;
      let ETarget = e.target;
      console.log(ETarget.scrollHeight - ETarget.clientHeight, 'scrollTOP', ETarget.scrollTop);
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        console.log('scrollTOP', this.noMore);
        this.updatewarningList();
      }
    },
    // 点击加载更多
    loadMore () {
      this.updatewarningList();
    },
    // 点击搜索
    inputSearch () {
      this.noMore = false;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = this.searchText;
      this.oweClasshourWarning.PageDataList = [];
      this.oweClasshourWarning.Total = 0;
      this.updatewarningList();
    },
    // 刷新
    rewardData () {
      this.oweClasshourWarning.PageDataList = [];
      this.searchObj.pageIndex = 0;
      // this.searchObj.searchText = '';
      this.noMore = false;
      this.$emit('afterSucces');
      this.updatewarningList();
    },
    CurrentClassHourCount (item) {
      // 排课时间精确到1位小数点.
      return Number(Number(item).toFixed(2));
    },
    // 返回页面
    returnEvent () {
      this.$router.back(-1);
      // this.$router.push('/jiaowudesk');
    },
    //  显示弹出层
    selectAmendEvent (item, e) {
      if (Number(item.SubscribeKey) > 0) {
        this.popUpList[0].isShow = true;
      } else {
        this.popUpList[0].isShow = false;
      }
      let scrollTop = document.getElementsByClassName('apply_debt_warning')[0].scrollTop;
      this.positionLeft = 322;
      this.positionTop = e.currentTarget.offsetTop - scrollTop + 32;
      this.isShowAlertDialog = true;
      this.studentItem = item; // 按钮选择的学生对象
      this.PopUpShow = true;
      document.body.scrollTop = 0;
      console.log(this.vWarmingStatisical, '信息');
    },
    // 取消弹出层
    cancelAmendEvent () {
      this.PopUpShow = false;
    },
    // 刷新 欠课时预警
    updatewarningList () {
      this.isLoadEnd = true;
      getDebtAmountWarning(this.searchObj).then(result => {
        result.data.PageDataList.sort((x, y) => {
          return Number(y.DebtAmount) - Number(x.DebtAmount);
        });
        result.data.PageDataList.forEach(o => {
          this.oweClasshourWarning.PageDataList.push(o);
        });
        this.oweClasshourWarning.Total = result.data.Total;
        this.oweClasshourWarning.TotalAmount = result.data.TotalAmount;

        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      });
    },
    // 忽略按钮
    overlookEvent () {
      let title =
        this.studentItem.StudentKeyValue +
        '欠款' +
        this.CurrentClassHourCount(this.studentItem.DebtAmount) +
        '元';
      console.log("overlookEvent", this.studentItem)
      getWarningIgnore(9, parseInt(this.studentItem.DataKey), title).then(
        result => {
          var length = this.oweClasshourWarning.PageDataList.length;
          for (var i = 0; i < length; i++) {
            if (this.oweClasshourWarning.PageDataList[i] == this.studentItem) {
              if (i == 0) {
                this.oweClasshourWarning.PageDataList.shift(); // 删除并返回数组的第一个元素
              } else if (i == length - 1) {
                this.oweClasshourWarning.PageDataList.pop(); // 删除并返回数组的最后一个元素
              } else if (i > 0) {
                this.oweClasshourWarning.PageDataList.splice(i, 1); // 删除下标为i的元素
              }
            }
          }
          this.cancelAmendEvent();
          this.rewardData();
        }
      );
    },
    // 获取学生个人信息
    getStudentInfo (StuKey, callback) {
      getStudentRelatedMsg(StuKey).then(result => {
        if (callback) {
          callback(result.data);
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 续费按钮
    studentPaymentEvent () {
      this.getStudentInfo(this.studentItem.StudentKey, (data) => {
        this.PopUpShow = false;
        this.$dialog.open(this, {
          name: '客户还款',
          routerName: this.$route.name,
          moduleName: 'paymentrepayment',
          data: { dataInfo: data },
          callBack: { afterSucces: () => { this.rewardData(); } }
        });
      });
    },
    // 销账按钮
    WriteOffEvent () {
      this.getStudentInfo(this.studentItem.StudentKey, (data) => {
        this.PopUpShow = false;
        this.$dialog.open(this, {
          name: '欠款销账',
          routerName: this.$route.name,
          moduleName: 'writeoffarrears',
          data: { dataInfo: data },
          callBack: { afterSucces: () => { this.rewardData(); } }
        });
      });
    },
    doAfterClick (item) {
      switch (item.type) {
        case 'WeChat':
          this.doWeChatTemplateShow(this.studentItem);
          break;
        case 'payAndWithdraw':
          this.studentPaymentEvent();
          break;
        case 'WriteOff':
          this.WriteOffEvent();
          break;
        case 'ignore':
          this.overlookEvent();
          break;
        case 'cancel':
          break;
        default:
          break;
      }
      this.isShowAlertDialog = false;
    }
  },
  computed: {
    // 门店是否托管
    vWeChatPattern () {
      if (this.$store.getters.SaaSClientInfo.WktInfo && this.$store.getters.SaaSClientInfo.WktInfo.PatternKey == 2 && this.$store.getters.SaaSClientInfo.IsCommonTrusteeshipKey == 1) { // 托管模式
        return true;
      } else {
        return false;
      }
    },
    // 欠课时 (人数)
    VstatisticsCount () {
      if (this.vWarmingStatisical) {
        return this.vWarmingStatisical.DebtAmountNum;
      } else {
        return 0;
      }
    },
    vTotalAmount () {
      if (this.vWarmingStatisical) {
        return Number(this.vWarmingStatisical.DebtAmount) || 0;
      } else {
        return 0;
      }
    },
    vBMSKNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'BMSK');
    },
    vXSPKNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'XSPK');
    },
    vWarmingStatisical () {
      return this.$store.getters.getWarmingStatisical.DebtAmountInfo;
    }
  }
};
</script>
<style scoped>
/* 刷新按钮样式 */
.update_img {
  opacity: 0.5;
  position: relative;
  left: 4.64rem;
  width: 0.36rem;
  height: 0.36rem;
}
</style>