<template>
  <div class="form_info_line"
       :class="{form_info_required:required}">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value form_info_select"
         v-if="!readonly">
      <el-select v-model="selectedInfo.name"
                 :filterable="filterable"
                 :value-key="id"
                 @change="changeEvent"
                 @click.native="clickEvent()"
                 :placeholder="required?'必选':'可选'">
        <el-option v-for="item in dataList"
                   :key="item[id]"
                   :label="item[name]"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div v-else
         class="form_info_value"><input type="text"
             disabled="disabled"
             :value="selectedInfo.name?selectedInfo.name:''"
             class="isReadonly readonly_color"></div>
    <!-- <div  class="input_readonly form_info_value"></div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedItem: {}
    };
  },
  props: {
    formTitle: String,
    dataList: Array,
    id: String,
    name: String,
    required: {
      type: Boolean,
      default: false
    },
    selectedInfo: {
      type: Object,
      default: function () {
        return {
          id: 0,
          name: ''
        };
      }
    },
    readonly: Boolean,
    filterable: {// 是否支持过滤
      type: Boolean,
      default: false
    },
    helpDescription: String // 表单说明提示
  },
  created () { },
  methods: {
    changeEvent (data) {
      this.selectedInfo.id = data[this.id];
      this.selectedInfo.name = data[this.name];
      this.$emit('selectedItem', data);
    },
    clickEvent () {
      this.$store.commit('showAlerDialog', false);
      this.$store.commit('setShowTimePicker', false);
    }
  }
};
</script>

<style>
</style>