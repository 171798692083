<template>
  <!-- 老师排名列表 -->
  <div class="left_list"
       :class="{'opt':dataInfo.isOpt}"
       @click="selectTeacherRanking">
    <div class="left_list_teacher"
         :class="{'teacher_center':!vIsshowContent}">{{dataInfo.MTeacherKeyValue}}</div>
    <div class="left_list_content"
         v-if="vIsshowContent">
      <span v-if="Number(dataInfo.Submit)>0">
        {{dataInfo.SubmitName}}
        <span class="">{{dataInfo.Submit}}</span>
      </span>
      <span v-if="Number(dataInfo.NotSubmit)>0">
        <span v-if="Number(dataInfo.Submit)>0"> | </span>
        {{dataInfo.NotSubmitName}}
        <span class="font_red">{{dataInfo.NotSubmit}}</span>
      </span>
      <span v-if="Number(dataInfo.SubmitRate)>0">
        <span v-if="Number(dataInfo.Submit)>0 || Number(dataInfo.NotSubmit)>0"> | </span>
        {{dataInfo.SubmitRateName}}
        <span class="font_blue">{{$utils.mMoneyType(dataInfo.SubmitRate,0)+'%'}}</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object,
    dataList: Array
  },
  computed: {
    vIsshowContent () {
      if (Number(this.dataInfo.Submit) > 0 || Number(this.dataInfo.NotSubmit) > 0 || Number(this.dataInfo.SubmitRate) > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  created () {
    // console.log(this.dataList, this.dataInfo, 'isOpt');
  },
  methods: {
    // 操作菜单
    selectTeacherRanking () {
      this.dataList.forEach(o => {
        if (Number(o.MTeacherKey) == Number(this.dataInfo.MTeacherKey)) {
          this.$set(o, 'isOpt', true);
        } else {
          this.$set(o, 'isOpt', false);
        }
      });
      this.$emit('selectTeacherRanking', this.dataInfo);
    }
  }
};
</script>

<style>
.teacher_center {
  line-height: 45px !important;
  height: 45px !important;
}
</style>

