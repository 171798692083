<template>
  <!-- 全部班级 -->
  <div>
    <!-- 模块重点说明 -->
    <div v-if="!dataInfo.isChildComponent">
      <module-description :textTips="'可按课程设置班级。'"
                          :btnTips="'查看教程'"
                          :btnUrl="'https://mp.weixin.qq.com/s/qe5-e460sx53o1_kZlICAQ'"></module-description>
      <function-btn :item="addbtn"
                    @clickItem="dobanJiAddClick"
                    :className="'defult_dialog_header_btn fr'">
        <span class="btn_add">+</span>开设新班
      </function-btn>
    </div>
    <div>
      <div class="popup_dialog_box_content clearfix">
        <div class="general_class_all"
             @scroll="scrollTOP"
             ref="generalClassScrollTOP">
          <div class="general_class_box"
               v-for="(item,index) in vBanJiList"
               :key="index">
            <div class="general_class_type"
                 v-if="item.etterstr">
              <div class="general_class_type_name">{{item.etterstr}}</div>
            </div>
            <div class="general_class_ul">
              <settingItem v-for="(children,index) in item.children"
                           :key="index"
                           :children="children"
                           @noComboClick="noComboClick"></settingItem>
            </div>
          </div>

          <div v-if="vBanJiList.length == 0"
               class="course_block_nodata"
               style="margin-top: 200px;">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
        <div v-if="dataInfo.required&&vBanJiList.length>0&&!dataInfo.footerButtonTitle"
             @click="cancelData"
             class="confirm_btn btn_fixed_bottom">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               style="magin:0 auto">清空选择</div>
        </div>
        <div v-if="dataInfo.required&&vBanJiList.length>0&&dataInfo.footerButtonTitle"
             @click="seletedFooterBtn"
             class="confirm_btn btn_fixed_bottom">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               style="magin:0 auto">{{dataInfo.footerButtonTitle}}</div>
        </div>
        <!-- 字母导航栏 -->
        <div class="general_class_type_ul">
          <letter-bar v-for="(letter,index) in letterList"
                      :key="index"
                      :item="letter"
                      @onLetterClick="onLetterClick"></letter-bar>
        </div>
        <!-- 操作菜单 -->
        <div class="course_student_attendance_operation module_content_menuList"
             :style="{'top':topValue+'px','left': leftValue+'px'}"
             v-if="isShowItem">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="banJiMenu"
                         @clickOptionForAlertDialog="onItemClick"></drop-down-box>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import settingItem from './setting-item';
import letterBar from './letter-bar';
import dropDownBox from '../../../common/drop-down-box';
import {
  getUsingClass,
  GetTheClassByID,
  FinishedGrade
} from '../../../../API/workbench';
export default {
  data () {
    return {
      isShowItem: false, // 班级操作项
      banJiList: [], // 班级列表
      banJiMenu: [], // 操作菜单项
      topValue: 0, // 默认第一个top值
      leftValue: 0,
      toScrollTop: 0, // 滚动条的高度
      addbtn: {
        name: '',
        type: '',
        ModulePowerKey: 45
      },
      // 首字母
      letterList: [
        { value: '', isOpt: false, lengthNum: 0 },
        { value: 'A', isOpt: false, lengthNum: 0 },
        { value: 'B', isOpt: false, lengthNum: 0 },
        { value: 'C', isOpt: false, lengthNum: 0 },
        { value: 'D', isOpt: false, lengthNum: 0 },
        { value: 'E', isOpt: false, lengthNum: 0 },
        { value: 'F', isOpt: false, lengthNum: 0 },
        { value: 'G', isOpt: false, lengthNum: 0 },
        { value: 'H', isOpt: false, lengthNum: 0 },
        { value: 'I', isOpt: false, lengthNum: 0 },
        { value: 'J', isOpt: false, lengthNum: 0 },
        { value: 'K', isOpt: false, lengthNum: 0 },
        { value: 'L', isOpt: false, lengthNum: 0 },
        { value: 'M', isOpt: false, lengthNum: 0 },
        { value: 'N', isOpt: false, lengthNum: 0 },
        { value: 'O', isOpt: false, lengthNum: 0 },
        { value: 'P', isOpt: false, lengthNum: 0 },
        { value: 'Q', isOpt: false, lengthNum: 0 },
        { value: 'S', isOpt: false, lengthNum: 0 },
        { value: 'T', isOpt: false, lengthNum: 0 },
        { value: 'U', isOpt: false, lengthNum: 0 },
        { value: 'V', isOpt: false, lengthNum: 0 },
        { value: 'W', isOpt: false, lengthNum: 0 },
        { value: 'X', isOpt: false, lengthNum: 0 },
        { value: 'Y', isOpt: false, lengthNum: 0 },
        { value: 'Z', isOpt: false, lengthNum: 0 }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  components: {
    settingItem,
    letterBar,
    dropDownBox
  },

  created () {
    this.loadData(); // 渲染列表数据
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowItem = false;
    }, false);
    this.$dialog.register(this, ['squadClassSetting']);
  },
  watch: {
    $route (to, from) {
      if (to.path != from.path) {
        this.toScrollTop = 0;
      }
      console.log(to.path, from.path, '路由');
    }
  },
  computed: {
    vBanJiList () {
      if (this.banJiList.length > 0) {
        let tempArr = [];
        let endData = [];
        for (let i = 0; i < this.banJiList.length; i++) {
          if (tempArr.indexOf(this.banJiList[i].etterstr) === -1) {
            endData.push({
              etterstr: this.banJiList[i].etterstr,
              children: [this.banJiList[i]]
            });
            tempArr.push(this.banJiList[i].etterstr);
          } else {
            for (let j = 0; j < endData.length; j++) {
              if (endData[j].etterstr == this.banJiList[i].etterstr) {
                endData[j].children.push(this.banJiList[i]);

                break;
              }
            }
          }
        }
        endData.sort((a, b) => a.etterstr.localeCompare(b.etterstr));
        endData.forEach(o => {
          this.letterList.forEach(c => {
            if (c.value == o.etterstr) {
              c.isOpt = true;
              c.lengthNum = o.children.length;
            } else if (!c.value && !o.etterstr) {
              c.lengthNum = o.children.length;
            }
          });
        });
        console.log(endData, '最终输出'); //  转载自 https://www.cnblogs.com/wyhlightstar/p/12957765.html
        return endData;
      } else {
        return this.banJiList;
      }
    }
  },
  methods: {
    cancelData () {
      this.noComboClick({ OLAPKey: 0, MainDemoName: '' });
    },
    seletedFooterBtn () {
      this.noComboClick({ OLAPKey: 0, MainDemoName: this.dataInfo.footerButtonTitle });
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.toScrollTop = 0;
      this.isShowItem = false;
      this.loadData();
      this.$emit('closeBanJiSettingListDialog');
    },
    // 跳转开设新班
    dobanJiAddClick () {
      this.isShowItem = false;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '新增班级',
        moduleName: 'squadClassSetting',
        data: { editItem: {}, isReadonly: false },
        callBack: {
          afterSuccess: (type, data) => { this.addSuccessEvent(type, data); }
        }
      });
    },
    // 新增成功后刷新/页面并向上传递 getUsingClass
    addSuccessEvent (type, data) {
      this.$emit('onClick', data);
      this.isShowItem = false;
      this.loadData();
      this.$bus.emit('AfterClassGradeAddOrEdit', type, data);
    },
    loadData (callBack) {
      getUsingClass().then(result => {
        result.data.forEach(item => {
          if (item.FirstLetterName) { // 有字母时
            item.etterstr = item.FirstLetterName.substr(0, 1).toUpperCase();// 统一转换为大写
          } else { // 无字母时
            item.etterstr = '';
          }
        });
        this.letterList.forEach(c => { c.isOpt = false; });
        this.banJiList = result.data;
        console.log(this.banJiList, 'addSuccessEvent');
        if (callBack) {
          callBack();
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    //  点击字母导航栏
    onLetterClick (item) {
      var lengthNum = 0;// 首字母=>内容高度
      var newZM = 0;// 字母标题=>高度
      let nullLetterCount = 0;

      this.letterList.forEach(o => {
        if (o.value && (item.value > o.value) && o.lengthNum > 0) {
          lengthNum += o.lengthNum;
          newZM++;
        }
        if (!o.value && o.lengthNum > 0) {
          nullLetterCount = o.lengthNum;
        }
      });
      this.$refs.generalClassScrollTOP.scrollTop = (lengthNum * 56) + (nullLetterCount * 56) + (newZM * 40);// 高度相加,定位到所在位置
      console.log(item, this.$refs.generalClassScrollTOP.scrollTop, '点击字母导航栏');
    },
    // 选择班级项,加载最新数据
    noComboClick (item, e, offsetWidth) {
      if (this.dataInfo && this.dataInfo.isChildComponent) {
        // 是否设置页面。
        this.$emit('onClick', item);
        this.doShowPopupHideClick();
        return false;
      } else {
        let offsetTop = e.currentTarget.offsetTop;
        this.topValue = offsetTop - this.toScrollTop + 32;
        this.leftValue = offsetWidth - 35;
        this.onSelectPopupItem(item);
      }
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      // console.log(this.toScrollTop, '滚动');
      if (e.target.scrollTop > 0) {
        this.isShowItem = false;
      }
    },
    // 选择弹出项
    onSelectPopupItem (item) {
      if (item.StutasKey == 4) {
        // 活跃班级
        this.banJiMenu = [
          {
            type: 1,
            name: '班级排课',
            eventName: 'pkhz',
            isShow: true,
            data: item,
            ModulePowerKey: 53
          },
          {
            type: 3,
            name: '修改班级',
            eventName: 'edit',
            isShow: true,
            data: item,
            ModulePowerKey: 45
          },
          {
            type: 4,
            name: '结束班级',
            eventName: 'end',
            isShow: true,
            data: item,
            ModulePowerKey: 45
          }
        ];
      }
      this.isShowItem = true;
    },
    // 菜单操作项
    onItemClick (menu) {
      console.log(menu, '菜单操作项');
      this.isShowItem = false;
      switch (menu.eventName) {
        case 'pkhz':
          // layer.alert('更新阶段，敬请期待');
          let item = {
            ModulePowerKey: 53,
            auth: true,
            name: '班级排课',
            roleName: '',
            routerName: 'classGradeApplycourse',
            routertitle: '班级排课',
            dataKey: menu.data.OLAPKey
          };
          this.$bus.emit('needOpenRouter', item);
          break;
        case 'details':
          this.$emit('openClassDetailDialog', 'classDetail', menu.data.OLAPKey, {}, this.loadData);
          break;
        case 'edit':
          GetTheClassByID(menu.data.OLAPKey).then(result => {
            console.log(result.data, '班级项');
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '修改班级',
              moduleName: 'squadClassSetting',
              data: { editItem: result.data, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(type, data); }
              }
            });
          }, error => {
            layer.alert(error.msg);
          });
          break;
        case 'end':
          var _this = this;
          layer.confirm('请确定是否结束该班级?', {
            btn: [{
              name: '确认',
              callBack: function () {
                layer.close();
                FinishedGrade(menu.data.OLAPKey).then(result => {
                  console.log(result, '结班成功');
                  _this.isShowItem = false;
                  _this.addSuccessEvent('End', menu.data);// 渲染列表数据
                  layer.alert('结班成功', {
                    btn: [{
                      name: '确认',
                      callBack: function () {
                        layer.close();
                      }
                    }]
                  });
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }]
          });
          break;
        default:
          break;
      }
    }
  }

};
</script>

  <style>
/*  */
</style>