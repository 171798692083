<template>
  <div class="search_result_cq search_result single_result_detail">
    <div class="search_result_content content_marginTop_30">
      <div v-if="sourceDate.length > 0"
           class="search_result_list single_shadow content_marginRL">
        <el-tree class="filter-tree"
                 :data="vtreeDate"
                 @check="checkEvent"
                 :show-checkbox="true"
                 :check-strictly="true"
                 :check-on-click-node="true"
                 :default-checked-keys="defaultCheckedKeys"
                 :default-expanded-keys="defaultExpandedKeys"
                 node-key="id"
                 :props="defaultProps"
                 :filter-node-method="filterNode"
                 ref="tree"></el-tree>
      </div>
      <div v-else
           class="course_block_nodata content_marginTop_30 content_marginRL single_shadow">
        <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="footer_btn_group form_footer_btn_group">
      <el-button round
                 class="btn_light_red btn_hover_white btn_light_cq"
                 @click.stop="closeDialog">取消</el-button>
      <el-button round
                 class="btn_light_blue btn_hover_bg_blue btn_light_cq"
                 @click.stop="confirmSelectedValue">确认</el-button>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'singleSelectTree',
  data () {
    return {
      treeDate: [],
      defaultCheckedKeys: [], // 默认选中节点
      defaultExpandedKeys: [ '2' ], // 默认展开节点
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectedItem: {
        id: 0,
        label: ''
      }
    };
  },
  props: ['sourceDate', 'selectionDate', 'disabledType'], // 数据源，已选中的数据
  created () {
    const findItem = this.sourceDate.find(obj => {
      return obj.OLAPKey == this.selectionDate.id;
    });
    console.log(this.sourceDate, findItem, 'this.sourceDate');
    if (findItem) {
      this.defaultCheckedKeys.push(this.selectionDate.id);
      this.defaultExpandedKeys.push(this.selectionDate.id);
      this.selectedItem.id = this.selectionDate.id;
      this.selectedItem.label = this.selectionDate.label;
      this.selectedItem.parentId = this.selectionDate.parentId;
    }
    this.initTreeDate();
  },
  computed: {
    vtreeDate () {
      this.treeDate.forEach(o => {
        if (this.$store.getters.SaaSClientEditionKey == 5) {
          o.disabled = this.disabledType != 1;
          o.children.forEach(p => {
            if (p.children.length > 0) {
              p.disabled = true;
            }
          });
        } else {

        }
      });
      return this.treeDate;
    }
  },
  methods: {
    // 选中课名
    checkEvent (node, data) {
      this.defaultCheckedKeys = [];
      this.$refs.tree.setCheckedKeys([]);
      this.defaultCheckedKeys.push(node.id);
      this.selectedItem.id = node.id;
      this.selectedItem.label = node.label;
      this.selectedItem.parentId = node.parentId;
    },
    confirmSelectedValue () {
      this.$emit('confirmClcik', this.selectedItem);
    },
    closeDialog () {
      this.$emit('cancleClcik');
    },
    initTreeDate () {
      console.log(this.$store.getters.SaaSClientEditionKey, "this.$store.getters.SaaSClientEditionKey", this.disabledType)
      if (!this.sourceDate || this.sourceDate.length == 0) {
        this.$emit('loadSourceData');
        return;
      }
      let initDate = this.sourceDate;
      // 定义一个空数组
      var hash = [];
      var idField = 'OLAPKey';
      var parentField = 'FatherKey';
      var textField = 'MainDemoName';
      var typeKey = 'TypeKey';
      if (!initDate) {
        return hash;
      }

      for (var i = 0; i < initDate.length; i++) {
        var dataitem = initDate[i];
        var item = {
          id: dataitem[idField],
          parentId: dataitem[parentField],
          label: dataitem[textField],
          children: dataitem,
          typeKey: dataitem[typeKey],
          disabled: this.disabledType ? dataitem[typeKey] == this.disabledType : dataitem[typeKey] == 1 // 禁止点击项,1:禁课类，2：禁课名
        };
        // 以id为数组下标，获取值
        hash[item.id] = hash[item.id] || [];
        hash[item.parentId] = hash[item.parentId] || [];
        item.children = hash[item.id];
        hash[item.parentId].push(item);
      }

      if (this.$store.getters.SaaSClientEditionKey == 5) {
        this.treeDate = hash[2] || [];
      } else {
        this.treeDate = hash[0] || [];
      }
      console.log(hash[0], '科类课程-数据');
    },
    filterNode (value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    }
  }
};
</script>


