<template>
  <div class="course_atten">
     <div class="item_menu_bottom" v-if="isshowColumn">
      <div class="item_menu_bottom_info text_overflow_hidden">
        <span class="">
          签到
          <span :class="Number(selectedCourseInfo.Attence3) > 0 ? 'font_green' : ''">{{ Number(selectedCourseInfo.Attence3) || 0 }}</span>人
        </span>
        <span class="text_box">|</span>
        <span class="">
          请假
          <span :class="
              Number(selectedCourseInfo.Attence4) > 0 ? 'font_orange' : ''
            ">{{ Number(selectedCourseInfo.Attence4) || 0 }}</span>人
        </span>
        <span class="text_box"
              v-if="vShowTruant">|</span>
        <span class=""
              v-if="vShowTruant">
          旷课
          <span :class="Number(selectedCourseInfo.Attence5) > 0 ? 'font_red' : ''">{{ Number(selectedCourseInfo.Attence5) || 0 }}</span>人
        </span>
        <span class="text_box">|</span>
        <span class=""
              :title="'课消'+Number(selectedCourseInfo.DeductCount)+'课时'">
          课消
          <span :class="
              Number(selectedCourseInfo.DeductCount) > 0 ? 'font_blue' : ''
            ">{{ Number(selectedCourseInfo.DeductCount) || 0 }}</span>课时
        </span>
        <span class="text_box"
              v-if="vDebtCount>0">|</span>
        <span class=""
              :title="'欠'+Number(vDebtCount)+'课时'"
              v-if="vDebtCount>0">
          欠
          <span class="font_red">{{ vDebtCount || 0 }}</span>课时
        </span>
      </div>
      <div class="item_menu_bottom_attendance button"
           v-if="isAlter"
           @click.stop="showBantchType">
        更多操作
        <div class="module_content_menuList"
             :style="{ top: '30px', left: '-50px' }"
             v-show="isShowdropDownBox"
             @mouseover.stop="isShowdropDownBox = true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="vBantchAttenList"
                         @clickOptionForAlertDialog="clickAttendanceOption"></drop-down-box>
        </div>
      </div>
    </div>
    <div v-if="selectedCourseInfo.IsClassStopKey == 0"
         class="atten_scroll index_tabs_two">
      <!-- 学生块 -->
      <!--   v-if="studentArrangeList.length > 0 " -->
      <div :class="isAlter ? '' : 'no_add'">
        <div class="pr">
          <div class="select_course_student_info"
               ref="selectCourseStudentList"
               @scroll="ListScroll">
            <div class="course_student_info_list"
                 :class="{ paddingBottom: isBatchAttendance }">
              <div v-if="!isshowColumn">
                 <student-item v-for="item in studentArrangeList"
                            :key="item.StudentArrangeKey"
                            :studentItem="item"
                            :isBatchAttendance="isBatchAttendance"
                            :isAlter="isAlter"
                            @selectedStudent="selectedStudent"
                            :isCourseEnd="vIsCourseEnd"
                         
                            @setPosition="getPosition"></student-item>

                <!-- 添加学生 -->
                <add-student-btn v-if="!isBatchAttendance&&isAlter"
                                :isAlter="isAlter"
                                @studentAddCourse="showAddStudentDialog"
                                @studentAddCourseAndAttendance="showAddStudentAttendance"
                                @studentAddCoursePlan="showAddStudentPlan"
                                @setPosition="doAfterClickAddStudentBtn"></add-student-btn>
              </div>
               <div class="row_student_list" v-else>
                 <row-student-item v-for="item in studentArrangeList"
                            :key="item.StudentArrangeKey"
                            :studentItem="item"
                            :isBatchAttendance="isBatchAttendance"
                            :isAlter="isAlter"
                            @selectedStudent="selectedStudent"
                            :isCourseEnd="vIsCourseEnd"
                            @editAtten="editAtten"
                            @setPosition="getPosition"></row-student-item>

                <!-- 添加学生 -->
                <!-- <add-student-btn v-if="!isBatchAttendance&&isAlter"
                                :isAlter="isAlter"
                                @studentAddCourse="showAddStudentDialog"
                                @studentAddCourseAndAttendance="showAddStudentAttendance"
                                @studentAddCoursePlan="showAddStudentPlan"
                                @setPosition="doAfterClickAddStudentBtn"></add-student-btn> -->
              </div>

              <select-all-tip :isBatchAttendance="isBatchAttendance"
                              :bantchType="bantchType"
                              @cancleBatchAttendance="cancelBatchAttendance"
                              @confirmBatchAttendance="bantchAttenAfter"></select-all-tip>
            </div>
            <!-- 添加学生弹框. -->
            <div>
              <student-for ref="studentsList"
                           :formTitle="studentTitle"
                           :classGradeID="selectedCourseInfo.GradeClassKey"
                           :selectCourseID="selectedCourseInfo.CourseNameKey"
                           :selectCourseTime="selectedCourseInfo.ClassTime"
                           :classHoureCount="selectedCourseInfo.PeriodCount"
                           :isTeacher="isTeacher"
                           :delPowerKey="122"
                           @onAddStudent="doAfterSelectedStudent"
                           @onDeleteStudent="deleteClick"
                           @completeData="closeStundetList"></student-for>
            </div>
            <!-- 显示加载的进度条 -->
            <custom-dialog class="progress_max progress"
                           width="300px"
                           :visible.sync="showProgress">
              <div class="progress_title">
                正在批量考勤 ，{{ runCount }}/{{ totolPeoCount }}。
              </div>
              <div class="progress_box">
                <el-progress type="circle"
                             :percentage="batchAttencePercentage"></el-progress>
              </div>
              <div class="progress_exit"
                   @click="Clickonclose"></div>
            </custom-dialog>
          </div>
          <div>
            <!-- 学生考勤操作-->
            <div class="course_student_attendance_option_list
                course_student_attendance_operation
                module_content_menuList
                active
              "
                 :style="{ left: positionLeft + 'px', top: positionTop + 'px' }"
                 v-show="isShowDialog && vIsShowAlerDialog">
              <div class="top_bg"></div>
              <drop-down-box :optionJsonList="vAttendanceStatus"
                             @clickOptionForAlertDialog="clickAttendanceOption"></drop-down-box>
            </div>
          </div>
        </div>
      </div>
      <!-- 无数据展示 -->
      <!-- <div v-else class="course_block_nodata" style="background-color: #fff">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div> -->
      
    </div>
    <div class="item_menu_bottom" v-if="!isshowColumn">
      <div class="item_menu_bottom_info text_overflow_hidden">
        <span class="">
          签到
          <span :class="Number(selectedCourseInfo.Attence3) > 0 ? 'font_green' : ''">{{ Number(selectedCourseInfo.Attence3) || 0 }}</span>人
        </span>
        <span class="text_box">|</span>
        <span class="">
          请假
          <span :class="
              Number(selectedCourseInfo.Attence4) > 0 ? 'font_orange' : ''
            ">{{ Number(selectedCourseInfo.Attence4) || 0 }}</span>人
        </span>
        <span class="text_box"
              v-if="vShowTruant">|</span>
        <span class=""
              v-if="vShowTruant">
          旷课
          <span :class="Number(selectedCourseInfo.Attence5) > 0 ? 'font_red' : ''">{{ Number(selectedCourseInfo.Attence5) || 0 }}</span>人
        </span>
        <span class="text_box">|</span>
        <span class=""
              :title="'课消'+Number(selectedCourseInfo.DeductCount)+'课时'">
          课消
          <span :class="
              Number(selectedCourseInfo.DeductCount) > 0 ? 'font_blue' : ''
            ">{{ Number(selectedCourseInfo.DeductCount) || 0 }}</span>课时
        </span>
        <span class="text_box"
              v-if="vDebtCount>0">|</span>
        <span class=""
              :title="'欠'+Number(vDebtCount)+'课时'"
              v-if="vDebtCount>0">
          欠
          <span class="font_red">{{ vDebtCount || 0 }}</span>课时
        </span>
      </div>
      <div class="item_menu_bottom_attendance"
           v-if="isAlter"
           @click.stop="showBantchType">
        更多操作
        <div class="module_content_menuList"
             :style="{ top: '30px', left: '-50px' }"
             v-show="isShowdropDownBox"
             @mouseover.stop="isShowdropDownBox = true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="vBantchAttenList"
                         @clickOptionForAlertDialog="clickAttendanceOption"></drop-down-box>
        </div>
      </div>
    </div>
     <custom-dialog :title="'备注'"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowQuitCourseReason"
                     :before-close="closeAttendanceReason">
        <quit-course-reason @deleteClick="delReasonHide"
                           @cancel="closeAttendanceReason"></quit-course-reason>
      </custom-dialog>
      <custom-dialog :title="'备注'"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowAttendanceReason"
                     :before-close="closeAttendanceReason">
        <attendance-reason :optionItem="optionItem"
                           @changeAttendance="changeAttendanceAfter"
                           @cancel="closeAttendanceReason"></attendance-reason>
      </custom-dialog>
      <custom-dialog :title="'校正考勤'"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowChangeAttendance"
                     :before-close="closeAttendanceReason">
        <change-attendance :optionItem="optionItem"
                           :courseInfo="selectedCourseInfo"
                           :curSelectedStudentInfo="curSelectedStudentInfo"
                           @changeAttendance="changeAttendanceAfter"
                           @cancel="closeAttendanceReason"></change-attendance>
      </custom-dialog>
      <custom-dialog :title="'学生列表'"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowStudentListDialog"
                     :before-close="closeStudentListDialog">
        <student-list :isShowSearchInput="true"
                      :addTitleName="''"
                      :CustStatusKey="1"
                      :isShowAddBtn="false"
                      @selectedItem="toAddLoop"></student-list>
      </custom-dialog>
      <custom-dialog title="安排补课"
                     width="500px"
                     :visible.sync="isShowPlan"
                     :before-close="closePlan">
        <repair-student-plan :planobj="dataPlan"
                             @afterSuccess="successPlan"
                             @cancelClick="closePlan"></repair-student-plan>
      </custom-dialog>
  </div>
</template>

<script>
import studentFor from '../../common-module/students-list/index';
import studentItem from './student-item';
import rowStudentItem from './row-student-item'
import studentList from '../../common/student-list';
import amendAttendanceItem from '../../pop-up-menu/exception-handle/change-attendance/index';
import addStudentBtn from '../../common/add-student-btn';
import selectAllTip from './select-all-tip';
import dropDownBox from '../../common/drop-down-box';
import changeAttendance from './components/change-attendance';
import attendanceReason from './components/attendance-reason';
import quitCourseReason from './components/quit-course-reason';

import repairStudentPlan from '../../report/repair-course-management/repair-student-detail/plan.vue';
import {
  courseAddStudent,
  setAttendance,
  kqChange,
  kqDelete,
  kqLeave,
  kqTruancy,
  BatchSyncSubmit,
  KQBatchTruancy,
  KQBatchLeave,
  KQBatchDelete,
  KQBatchAttendance,
  ArrangeStudentToPlan,
  CancelStudent2MutiSameApplyClassCourseByRule,
  getRuleByCourseInfo,
  ArrangeStudent2MutiSameApplyClassCourseByRule,
  DeleteStudentForComment,
  DeleteStudentForHomeWork,
  PauseScheduleCourse,
  KQ_AdjustHour,
  RevokeDispose
} from '../../../API/workbench';
export default {
  data () {
    return {
      deleteCount: 1, // 手动防抖工具
      isShowDialog: false,
      isScrollHidden: true,
      isAdd: false,
      isShowAttendanceReason: false,
      isShowQuitCourseReason: false,
      isShowChangeAttendance: false,
      optionItem: {},
      attendanceStatus: [
        {
          type: this.$initJson.attendanceType.view,
          name: '查看档案',
          eventName: 'examine',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.attendance,
          name: '签到',
          eventName: 'changeToAttendance',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.leave,
          name: '请假',
          eventName: 'changeToLeave',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.absence,
          name: '旷课',
          eventName: 'changeToAbsence',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.changeAtten,
          name: '校正考勤',
          eventName: 'changeAtten',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.cancel,
          name: '变更撤销',
          eventName: 'changeToUnAttendance',
          className: '',
          isShow: false
        },

        {
          type: this.$initJson.attendanceType.del,
          name: '退课',
          eventName: 'deleteClick',
          className: 'course_student_del warning',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.secedeLoop,
          name: '退出循环课',
          eventName: 'secedeLoop',
          className: 'course_student_del warning',
          isShow: false
        },

        {
          type: this.$initJson.attendanceType.addToCourse,
          name: '安排学生上课',
          eventName: 'studentAddCourse',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.addToCourseAndAttendance,
          name: '安排学生并签到',
          eventName: 'studentAddCourseAndAttendance',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.studentRepairCourse,
          name: '安排学生补课',
          eventName: 'studentRepairCourse',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.addLoop,
          name: '加入循环课',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false
        },

        {
          type: this.$initJson.attendanceType.courseComment,
          name: '课评',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.homeWork,
          name: '作业',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.cancelcourseComment,
          name: '撤销课评',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.cancelhomeWork,
          name: '撤销作业',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false
        }
      ],
      bantchList: [
        //   {
        //   type: this.$initJson.attendanceType.bantchSign,
        //   name: '批量签到',
        //   key: '3',
        //   eventName: 'allSign',
        //   className: 'list_icon02',
        //   isActive: false,
        //   isShow: true
        // },
        {
          type: this.$initJson.attendanceType.bantchLeave,
          name: '批量请假',
          eventName: 'allLeave',
          className: 'list_icon06',
          isActive: false,
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.bantchAbsence,
          name: '批量旷课',
          eventName: 'allTruant',
          className: 'list_icon04',
          isActive: false,
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.delArrangeCourse,
          name: '批量退课',
          eventName: 'allQuit',
          className: 'list_icon07',
          isActive: false,
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.batchComment,
          name: '批量发布课评',
          eventName: 'allQuit',
          className: 'list_icon38',
          isActive: false,
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.batchWork,
          name: '批量发布作业',
          eventName: 'allQuit',
          className: 'list_icon41',
          isActive: false,
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.changeAttenList,
          name: '考勤课时校正',
          eventName: 'allQuit',
          className: 'list_icon41',
          isActive: false,
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.stopCourse,
          name: '停课',
          eventName: 'allQuit',
          className: 'list_icon41',
          isActive: false,
          isShow: true,
          ModulePowerKey: 47
        }

      ],
      curSelectedStudentInfo: {}, // 选中学生.
      vStudentArrangeList: [],
      positionLeft: '',
      positionTop: '',
      isShowChangeAttendance: false,
      isShowSearchInput: false,
      isShowStudentListDialog: false,
      isBatchAttendanceSucces: false, // 判断批量考勤是否成功过
      isStudentAttendance: '', // 学生签到
      showProgress: false, // 显示批量添加的进度条
      totolPeoCount: 0, // 百分比弹出层总人数
      runCount: 0, // 进度条.
      isShowAddStudentDialog: '',
      courseRule: 0, // 选中课的排课规律id
      studentTitle: '', // 添加学生列表弹窗名
      addStudentMode: 0, // 添加学生列表类型
      addLoopStudentInfo: {}, // 加入循环课选中的学生
      isTheBatchProcessShow: false, // 显示批量排课
      parmsInfo: null,
      batchAttencePercentage: 0, // 批量考勤百分比进度
      bantchType: '',
      isShowdropDownBox: false,
      isShowPlan: false,
      dataPlan: {
        mode: 2, // 1为已有学生和缺课记录，要选补课课程，2为已有补课课程，要选学生和缺课记录
        targetCourseKey: '', // 安排课id
        targetCourseDay: '', // 安排课日期，只传日
        targetCourseWeek: '', // 安排课日期，周几
        targetCourseTime: '', // 安排课上课时段
        targetCourseName: '', // 安排课课程名
        targetCourseClass: '', // 安排课班级名
        targetCourseTeacher: ''// 安排课老师名
      },
      cancelTitle:'del'
    };
  },
  props: {
    studentArrangeList: Array,
    isBatchAttendance: Boolean,
    selectedCourseInfo: Object,
    isshowColumn: Boolean,
    isTeacher: Boolean,
    isAlter: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: {
    studentItem,
    rowStudentItem,
    addStudentBtn,
    studentFor,
    selectAllTip,
    dropDownBox,
    amendAttendanceItem,
    studentList,
    changeAttendance,
    attendanceReason,
    repairStudentPlan,
    quitCourseReason
  },
  created () {
    if (this.selectedCourseInfo.ScheduleCourseKey) {
      this.getRuleByCourse();
    }
    this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
      if (this.parmsInfo) {
        this.$bus.emit(
          'AfterStudentAddToCourse',
          this.selectedCourseInfo.ScheduleCourseKey,
          0
        );
      }
    });
    console.log(this.selectedCourseInfo, 'selectedCourseInfo122');
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowDialog = false;
        this.isShowdropDownBox = false;
        this.$store.commit('showAlerDialog', false);
      },
      true
    );
  },
  watch: {
    selectedCourseInfo: {
      handler (n, o) {
        console.log(n, 'selectedCourseInfo2');
        if (n.ScheduleCourseKey != o.ScheduleCourseKey) {
          this.getRuleByCourse();
        }
      },
      deep: true
    },
    /// 监听父组件是否启用批量考勤 Boolen值
    isBatchAttendance (n, o) {
      // 启用批量考勤
      if (n) {
        this.openBatchAttendance();
      }
    }
  },
  computed: {
    vDebtCount () {
      let num = 0;
      this.studentArrangeList.forEach((o) => {
        if (o.ISClassHourDebtKey > 0) {
          num = num + Number(o.PeriodCount);
        }
      });
      return Number(num.toFixed(1)); // num*Number(this.selectedCourseInfo.PeriodCount)
    },
    // 选中的批量考勤学生
    vbantchStudentList () {
      const newArr = [];
      this.studentArrangeList.forEach((o) => {
        if (o.isSelected == true) {
          newArr.push(o);
        }
      });
      return newArr;
    },
    // 选中的批量考勤学生key list
    vbantchStudentKeyList () {
      const newArr = [];
      this.studentArrangeList.forEach((o) => {
        if (o.isSelected == true) {
          newArr.push(o.StudentKey);
        }
      });
      return newArr;
    },
    // 批量考勤选项
    vBantchAttenList () {
      if (this.vShowTruant) {
        this.bantchList[2].isShow = true;
      } else {
        this.bantchList[2].isShow = false;
      }
      return this.bantchList;
    },
    // 是否显示旷课
    vShowTruant () {
      return this.$utils.getSaaSClientSet(59) == 1;
    },
    vIsCourseEnd () {
      return this.selectedCourseInfo.StatusKey == 4;
    },
    vIsShowAlerDialog () {
      return this.$store.getters.showAlerDialog;
    },
    //  进度条 百分比.
    progressStatus () {
      var percent = Math.floor(
        (this.runCount / this.totolPeoCount).toFixed(2) * 100
      );
      return percent || 0;
    },
    // 是否通用版
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vAttendanceStatus () {
      this.attendanceStatus.forEach((obj) => {
        if (this.isAdd) {
          // 添加学生
          console.log(this.isAdd, 'obj.type == 24', obj.type == 24);
          if (
            obj.type === 8 ||
            obj.type === 9 ||
            obj.type === 10 ||
            obj.type == 12 ||
            obj.type == 24
          ) {
            obj.isShow = true;
          } else {
            obj.isShow = false;
          }
          if (obj.type === 12) {
            if (
              this.selectedCourseInfo.StatusKey == 4 ||
              this.selectedCourseInfo.StatusKey == 5 ||
              this.selectedCourseInfo.StatusKey == 6
            ) {
              obj.isShow = false;
            } else {
              obj.isShow = true;
            }
          }
          if (obj.type == 11) {
            obj.isShow = false;
          }
        } else {
          // 选中学生
          if (
            parseInt(this.curSelectedStudentInfo.AttenceStatusKey) === 3 ||
            parseInt(this.curSelectedStudentInfo.AttenceStatusKey) === 4 ||
            parseInt(this.curSelectedStudentInfo.AttenceStatusKey) === 5
          ) {
            if (
              obj.type === 6 ||
              obj.type === 8 ||
              obj.type === 9 ||
              obj.type === 10 ||
              obj.type === 24
            ) {
              obj.isShow = false;
            } else {
              obj.isShow = true;
            }
            if (obj.type == this.curSelectedStudentInfo.AttenceStatusKey) {
              obj.isShow = false;
            }
            if (obj.type === 3) {
              obj.isShow = false;
              obj.name = '变更签到';
            }
            if (obj.type === 4) {
              obj.isShow = false;
              obj.name = '变更请假';
            }
            if (obj.type === 5) {
              obj.isShow = false;
              obj.name = '变更旷课';
            }
            console.log('校正考勤', obj.type);
            if (obj.type === 25) {
              obj.isShow = true;
            }
          } else {
            if (
              obj.type === 0 ||
              obj.type === 3 ||
              obj.type === 4 ||
              obj.type === 5 ||
              obj.type === 6
            ) {
              obj.isShow = true;
            } else {
              obj.isShow = false;
            }
            if (obj.type === 3) {
              obj.name = '签到';
            }
            if (obj.type === 4) {
              obj.name = '请假';
            }
            if (obj.type === 5) {
              obj.name = '旷课';
            }
          }
          if (obj.type === 12) {
            obj.isShow = false;
          }
          if (obj.type == 11) {
            if (
              this.selectedCourseInfo.StatusKey == 4 ||
              this.selectedCourseInfo.StatusKey == 5 ||
              this.selectedCourseInfo.StatusKey == 6
            ) {
              obj.isShow = false;
            } else if (
              this.curSelectedStudentInfo.AttenceStatusKey == 2 ||
              !this.curSelectedStudentInfo.AttenceStatusKey
            ) {
              obj.isShow = true;
            } else {
              obj.isShow = false;
            }
          }
          if (obj.type == this.$initJson.attendanceType.courseComment) {
            if (this.curSelectedStudentInfo.IsReview == 1) {
              obj.isShow = false;
            } else {
              obj.isShow = true;
            }
          }
          if (obj.type == this.$initJson.attendanceType.homeWork) {
            if (this.curSelectedStudentInfo.IsAssignHomework == 1) {
              obj.isShow = false;
            } else {
              obj.isShow = true;
            }
          }
          if (obj.type == this.$initJson.attendanceType.cancelcourseComment) {
            if (this.curSelectedStudentInfo.IsReview == 1) {
              obj.isShow = true;
            } else {
              obj.isShow = false;
            }
          }
          if (obj.type == this.$initJson.attendanceType.cancelhomeWork) {
            if (this.curSelectedStudentInfo.IsAssignHomework == 1) {
              obj.isShow = true;
            } else {
              obj.isShow = false;
            }
          }
        }
      });
      console.log(this.attendanceStatus, 'vAttendanceStatus');
      this.attendanceStatus.forEach((o) => {
        // 门店设置增加是否开启考勤旷课及其相关应用 开关
        if (
          this.$utils.getSaaSClientSet(59) == 0 &&
          o.type == 5
        ) {
          o.isShow = false;
        }
      });
      return this.attendanceStatus;
    }
  },
  methods: {
    // 取消点评
    deletedCommtent () {
      layer.confirm('是否撤回点评', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              DeleteStudentForComment(
                this.selectedCourseInfo.ScheduleCourseKey,
                this.curSelectedStudentInfo.StudentKey
              )
                .then((result) => {
                  console.log(result.data);
                  this.curSelectedStudentInfo.IsReview = 0;
                  this.$emit('updateCourseInfo', this.selectedCourseInfo.ScheduleCourseKey);
                })
                .catch((error) => { });
              layer.close();
            }
          }
        ]
      });
    },
    // 取消作业
    deletedHomeWork () {
      layer.confirm('是否撤回作业', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              DeleteStudentForHomeWork(
                this.selectedCourseInfo.ScheduleCourseKey,
                this.curSelectedStudentInfo.StudentKey
              )
                .then((result) => {
                  console.log(result.data);
                  this.curSelectedStudentInfo.IsAssignHomework = 0;
                  this.$emit('updateCourseInfo', this.selectedCourseInfo.ScheduleCourseKey);
                })
                .catch((error) => { });
              layer.close();
            }
          }
        ]
      });
    },
    releaseHomeTask (OLAPKey) {
      console.log('releaseHomeTask');
      if (this.selectedCourseInfo.IsClassStopKey == 1) {
        layer.alert('这节课已停课');
      } else if (this.selectedCourseInfo.ArrangedNumCount == 0) {
        layer.alert('这节课没有安排学生');
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '布置作业',
          moduleName: 'releaseHomeTask',
          data: {
            taskData: {
              scheduleCourseID: this.selectedCourseInfo.ScheduleCourseKey,
              courseName: this.selectedCourseInfo.CourseNameKeyValue,
              studentKey: OLAPKey
            }
          },
          callBack: {
            afterSuccess: () => {
              this.$emit(
                'updateCourseInfo',
                this.selectedCourseInfo.ScheduleCourseKey
              );
            }
          }
        });
      }
    },
    releaseComment (OLAPKey) {
      console.log(OLAPKey, 'releaseComment');
      if (this.selectedCourseInfo.IsClassStopKey == 1) {
        layer.alert('这节课已停课');
      } else if (this.selectedCourseInfo.ArrangedNumCount == 0) {
        layer.alert('这节课没有安排学生');
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '发布点评',
          moduleName: 'releaseComment',
          data: {
            taskData: {
              scheduleCourseID: this.selectedCourseInfo.ScheduleCourseKey,
              courseName: this.selectedCourseInfo.CourseNameKeyValue,
              studentKey: OLAPKey
            }
          },
          callBack: {
            afterSuccess: () => {
              this.$emit(
                'updateCourseInfo',
                this.selectedCourseInfo.ScheduleCourseKey
              );
            }
          }
        });
      }
    },
    // 取消批量考勤显示
    closeShowBantchAtten () {
      // this.cancelAllBantchAtten(); // 取消高亮
      this.isShowdropDownBox = false;
    },
    showBantchType () {
      this.isShowdropDownBox = true;
    },
    // 全选批量考勤
    allBantchAtten () {
      this.isSelectAll = !this.isSelectAll;
      this.studentArrangeList.forEach((o) => {
        if (this.isSelectAll) {
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {
            o.isActive = true;
          }
        } else {
          o.isActive = false;
        }
      });
      console.log('allBantchAtten', this.studentArrangeList);
    },
    clickBatchAttendance (type) {
      this.$emit('clickBatchAttendance', type);
    },
    Clickonclose () {
      if (this.$websocket.ws.readyState == 1) {
        this.showProgress = false;
      } else if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
      }
    },
    closeAttendanceReason () {
      this.isShowAttendanceReason = false;
      this.isShowChangeAttendance = false;
      this.isShowQuitCourseReason= false
    },
    getRuleByCourse () {
      getRuleByCourseInfo(this.selectedCourseInfo)
        .then((result) => {
          this.courseRule = result.data;
        })
        .catch((error) => {
          console.log(error, 'getRuleByCourseerror');
        });
    },
    ListScroll () {
      if (this.isScrollHidden) {
        this.isShowDialog = false;
      } else {
        this.isScrollHidden = true;
      }
    },
    // 启用批量考勤
    openBatchAttendance () {
      this.studentArrangeList.forEach((obj) => {
        this.$set(obj, 'isSelected', false);
        if (obj.AttenceStatusKey == 2) {
          this.$set(obj, 'isSelected', true);
        } else {
          this.$set(obj, 'unSelected', true);
        }
      });
    },
    // 关闭批量考勤
    cancelBatchAttendance () {
      this.showProgress = false;
      this.$emit('cancelBatchAttendance');
    },
    // 批量考勤  确认
    confirmBatchAttendance () {
      let selectionStudentKeyList = [];
      let selectionStudentList = [];
      this.studentArrangeList.forEach((obj) => {
        if (obj.isSelected) {
          selectionStudentKeyList.push(obj.StudentKey);
          selectionStudentList.push(obj);
        }
      });
      if (selectionStudentKeyList.length > 0) {
        this.verifyStudent(selectionStudentList, selectionStudentKeyList);
      } else {
        layer.alert('至少选择一位学生，再确认!');
      }
    },
    // 验证学生
    verifyStudent (selectionStudentList, selectionStudentKeyList) {
      if (selectionStudentList.length > 0) {
        let isConfirm = true;
        let names = '';
        selectionStudentList.forEach((o) => {
          if (o.IsConfirmKey == 0 && o.IsTrialKey == 0) {
            isConfirm = false;
            names += o.StudentKeyValue + ',';
            layer.alert(
              names + '该学生待验证确认，请先在学生档案中进行确认操作。'
            );
            return false;
          }
        });
        if (isConfirm) {
          console.log(
            'selectionStudentKeyList',
            selectionStudentKeyList,
            selectionStudentList
          );
          this.batchAttendance(selectionStudentKeyList, selectionStudentList);
        }
      }
    },
    // 批量考勤
    batchAttendance (studentKeyList, selectionStudentList) {
      console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState',
        this.$websocket
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.showProgress = true;
      this.runCount = 0;
      this.batchAttencePercentage = 0;
      this.totolPeoCount = studentKeyList.length; // 百分比弹出层总人数
      studentKeyList = { StudentKeyList: JSON.stringify(studentKeyList) };
      KQBatchAttendance(
        studentKeyList,
        this.selectedCourseInfo.ScheduleCourseKey
      ).then(
        (result) => {
          console.log('新批量考勤', result.data);
          this.$asyncTask.register(result.data.AsynTaskID, (sendData) => {
            console.log('新批量考勤长连接通知:', sendData);
            if (sendData.nodeType == 1 && sendData.status == 1) {
              // nodeType =1循环节点
              if (sendData.number > this.runCount) {
                // 返回的顺序未必按0~n递增方式 ，存在网络传输的快慢问题，所以后面的通知可能会比前面的通知先接收到
                this.runCount = sendData.number; // 进度条.
                this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
              }
              let studentItem = selectionStudentList.find((obj) => {
                return obj.StudentKey == sendData.DataKey;
              });
              Object.assign(studentItem, sendData.remark);
            } else if (sendData.nodeType == 0) {
              // 结束节点
              this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
              this.showProgress = false;
              this.$asyncTask.delete(sendData.AsynTaskID);
              this.cancelBatchAttendance();
              console.log('结束了');
              this.$bus.emit(
                'AfterBatchAttendance',
                this.selectedCourseInfo.ScheduleCourseKey,
                this.selectedCourseInfo
              );
            } else if (sendData.status == 0) {
              // status：1成功 0失败
              console.log('批量考勤某个节点失败-》sendData:', sendData);
            }
          });
        },
        (error) => {
          layer.alert(error.msg);
          this.cancelBatchAttendance();
        }
      );
    },
    // 点击批量考勤按钮触发时间
    bantchAttenAfter () {
      console.log(this.vbantchStudentKeyList, 'bantchAttenAfter');
      if (this.vbantchStudentKeyList.length == 0) {
        return false;
      }
      // if (this.isBantchEnd) {
      //   this.isBantchEnd = false;
      if (this.bantchType == 3) {
        this.confirmBatchAttendance(
          this.vbantchStudentKeyList,
          this.vbantchStudentList
        );
      } else if (this.bantchType == 4) {
        this.batchLeave(this.vbantchStudentKeyList, this.vbantchStudentList);
      } else if (this.bantchType == 5) {
        this.batchTruancy(this.vbantchStudentKeyList, this.vbantchStudentList);
      } else if (this.bantchType == 6) {
        const that = this;
        layer.confirm('是否安排选中的学生批量退课', {
          shadeClose: false,
          btn: [
            {
              name: '取消',
              callBack: function () {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: function () {
                that.batchDelete(
                  that.vbantchStudentKeyList,
                  that.vbantchStudentList
                );
                layer.close();
              }
            }
          ]
        });
      }
      // }
    },
    // 批量旷课
    batchTruancy (studentKeyList, selectionStudentList) {
       console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.runCount = 0;
      this.batchAttencePercentage = 0;
      this.totolPeoCount = studentKeyList.length; // 百分比弹出层总人数
      studentKeyList = {
        StudentKeyList: JSON.stringify(studentKeyList)
      };
      KQBatchTruancy(
        studentKeyList,
        this.selectedCourseInfo.ScheduleCourseKey
      ).then(
        (result) => {
          console.log('新批量旷课', result.data);
          this.commonAsyncTask(result, selectionStudentList);
        },
        (error) => {
          layer.alert(error.msg);
          this.cancelBatchAttendance();
        }
      );
    },
    // 异步队列
    commonAsyncTask (result, selectionStudentList) {
      this.$asyncTask.register(result.data.AsynTaskID, (sendData) => {
        this.showProgress = true;
        console.log('新批量考勤长连接通知:', sendData);
        if (sendData.nodeType == 1 && sendData.status == 1) {
          // nodeType =1循环节点
          if (sendData.number > this.runCount) {
            // 返回的顺序未必按0~n递增方式 ，存在网络传输的快慢问题，所以后面的通知可能会比前面的通知先接收到
            this.runCount = sendData.number; // 进度条.
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          }
          const studentItem = selectionStudentList.find((obj) => {
            return obj.StudentKey == sendData.DataKey;
          });
          Object.assign(studentItem, sendData.remark);
        } else if (sendData.nodeType == 0) {
          // 结束节点
          this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          this.showProgress = false;
          this.$asyncTask.delete(sendData.AsynTaskID);
          this.cancelBatchAttendance();
          console.log('结束了');
          this.$bus.emit(
            'AfterBatchAttendance',
            this.selectedCourseInfo.ScheduleCourseKey,
            this.selectedCourseInfo
          );
        } else if (sendData.status == 0) {
          // status：1成功 0失败
          console.log('批量考勤某个节点失败-》sendData:', sendData);
        }
        // this.cancelBantchAttren();
      });
    },
    // 批量请假
    batchLeave (studentKeyList, selectionStudentList) {
       console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.runCount = 0;
      this.batchAttencePercentage = 0;
      this.totolPeoCount = studentKeyList.length; // 百分比弹出层总人数
      studentKeyList = {
        StudentKeyList: JSON.stringify(studentKeyList)
      };
      KQBatchLeave(
        studentKeyList,
        this.selectedCourseInfo.ScheduleCourseKey
      ).then(
        (result) => {
          console.log('新批量请假', result.data);
          this.commonAsyncTask(result, selectionStudentList);
        },
        (error) => {
          layer.alert(error.msg);
          // this.$store.dispatch('popup/open', {
          //   content: error.msg
          // });
          this.cancelBatchAttendance();
        }
      );
    },
    batchDelete (studentKeyList, selectionStudentList) {
      var StudentKeyList = JSON.stringify(studentKeyList);
      KQBatchDelete(
        StudentKeyList,
        this.selectedCourseInfo.ScheduleCourseKey
      ).then(
        (result) => {
          this.$bus.emit(
            'AfterBatchAttendance',
            this.selectedCourseInfo.ScheduleCourseKey,
            '',
            '',
            () => {
              // this.getStudentListInCourse(this.selectedCourseInfo.ScheduleCourseKey, () => {});
            }
          );
          this.cancelBatchAttendance();
          console.log('批量退课', result.data);
        },
        (error) => {
          layer.alert(error.msg);
          this.cancelBatchAttendance();
        }
      );
    },
    // 签到接口 GOTO
    toSetAttendance (IsClassHourLackKey, callBack) {
      setAttendance(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey
      ).then(
        (result) => {
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.selectedCourseInfo.StatusKey
          );
          if (callBack) {
            callBack();
          }
          Object.assign(this.curSelectedStudentInfo, result.data);
          this.curSelectedStudentInfo.isSelected = false;
        },
        (err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue +
              '课时不足，是否以欠课时的方式继续签到？',
              {
                shadeClose: false,
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      that.isSaving = false;
                      that.$refs.studentsList.isAdding = false;
                      if (callBack) {
                        callBack();
                      }
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.toSetAttendance(1, callBack);
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else {
            layer.alert(err.msg);
            if (callBack) {
              callBack();
            }
          }
        }
      );
    },
    // 添加学生弹出层 isStudentAttendance
    showAddStudentDialog () {
      this.$refs.studentsList.isShowAddStudent = true;
      this.isShowAddStudentDialog = '安排'; // 选择学生签到
      this.studentTitle = '安排学生上课';
      this.addStudentMode = 0;
      this.$nextTick(() => {
        this.$refs.studentsList.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.studentArrangeList);
        this.$refs.studentsList.getStudentList();
      });
    },
    // 添加学生并签到弹出层
    showAddStudentAttendance () {
      this.$refs.studentsList.isShowAddStudent = true;
      this.isShowAddStudentDialog = '签到'; // 选择学生签到
      this.studentTitle = '安排学生并签到';
      this.addStudentMode = 1;
      this.$nextTick(() => {
        this.$refs.studentsList.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.studentArrangeList);
        this.$refs.studentsList.getStudentList();
      });
    },
    // 补课弹出层
    showAddStudentRepairCourse () {
      this.dataPlan.targetCourseKey = this.selectedCourseInfo.ScheduleCourseKey;
      this.dataPlan.targetCourseDay = this.selectedCourseInfo.ClassTime.split('-')[1] + '月' + this.selectedCourseInfo.ClassTime.split('-')[2] + '日';
      this.dataPlan.targetCourseWeek = this.selectedCourseInfo.WeekKeyValue;
      this.dataPlan.targetCourseTime = this.selectedCourseInfo.ClassTimeName;
      this.dataPlan.targetCourseName = this.selectedCourseInfo.CourseNameKeyValue;
      this.dataPlan.targetCourseClass = this.selectedCourseInfo.GradeClassKeyValue;
      this.dataPlan.targetCourseTeacher = this.selectedCourseInfo.MTeacherKeyValue;
      this.isShowPlan = true;
    },
    // 取消循环课
    cancelAllArrangeClick (Reason) {
      layer.confirm(
        '是否安排' + this.curSelectedStudentInfo.StudentKeyValue + '退出循环课',
        {
          shadeClose: false,
          btn: [
            {
              name: '确认',
              callBack: () => {
                CancelStudent2MutiSameApplyClassCourseByRule(this,
                  this.curSelectedStudentInfo.StudentKey,
                  this.courseRule,
                  '',
                  Reason
                ).then(
                  (result) => {
                    let findIndex = this.studentArrangeList.findIndex((o) => {
                      // 遍历数组找到相对应ID.  删除.
                      return (
                        o.StudentArrangeKey ==
                        this.curSelectedStudentInfo.StudentArrangeKey
                      );
                    });
                    if (findIndex > -1) {
                      this.studentArrangeList.splice(findIndex, 1);
                    }
                    this.$refs.studentsList.dataStudentList.splice(
                      findIndex,
                      1
                    );
                    this.$refs.studentsList.getStudentCourse(
                      this.curSelectedStudentInfo
                    );
                    this.$bus.emit(
                      'afterArrangeScheduleCourse',
                      this.curSelectedStudentInfo.ScheduleCourseKey,
                      this.curSelectedStudentInfo.StudentKey
                    );
                    this.$bus.emit(
                      'AfterChangeAttendanceStatus',
                      this.curSelectedStudentInfo.ScheduleCourseKey,
                      this.curSelectedStudentInfo.StudentKey
                    );
                    this.$emit(
                      'changeAttendanceStutus',
                      this.curSelectedStudentInfo.ScheduleCourseKey,
                      this.curSelectedStudentInfo.StudentKey,
                      this.selectedCourseInfo.StatusKey
                    );
                  },
                  (error) => {
                    layer.alert(error.msg);
                  }
                );
                layer.close();
              }
            }
          ]
        }
      );
    },
    // 添加学生到课计划弹出层
    showAddStudentPlan () {
      this.isShowAddStudentDialog = '排课'; // 选择学生排课
      var presentDate = this.$utils.formatTime(Date.new()); // 获取当前日期和时间

      let getDate1 = presentDate.split(' ')[0].replace(/\//g, '-'); // 当前日期
      let getTime1 = presentDate.split(' ')[1]; // 当前时间
      let getDate2 = (
        this.selectedCourseInfo.ClassTime +
        ' ' +
        this.selectedCourseInfo.EndClassTime
      ).split(' ')[0]; // 排课日期
      let getTime2 = (
        this.selectedCourseInfo.ClassTime +
        ' ' +
        this.selectedCourseInfo.EndClassTime
      )
        .split(' ')[1]
        .slice(0, 8); // 排课时间

      // 对比日期;
      if (getDate2 > getDate1) {
      } else if (getDate2 == getDate1) {
        // 对比时间
        if (getTime2 > getTime1) {
        } else {
          layer.alert('只有未开始的课可添加到课计划,该课上课时间已过');
        }
      } else {
        layer.alert('只有未开始的课可添加到课计划,该课上课日期已过');
      }
    },
    closeStundetList () { },
    // 添加完学生 选中学生后
    doAfterSelectedStudent (
      item,
      arrangeCallBack,
      isAllowClassHourLackKey,
      rejectCallBack
    ) {
      this.curSelectedStudentInfo = item;
      var AgentKey = this.$store.getters.signData.UserID; // 临时.
      var IsAllowClassHourLackKey = isAllowClassHourLackKey || this.isShowAddStudentDialog == '补课' ? 1 : 0; // 是否允许无课时 1是0否
      var IsTrialKey = 0; // 是否试课(1是0否)
      var IsMakeupKey = this.isShowAddStudentDialog == '补课' ? 1 : 0; // 是否试课(1是0否)
      this.setStudentHasClass(
        this.selectedCourseInfo.ClassTime,
        this.selectedCourseInfo.ScheduleCourseKey,
        item.OLAPKey,
        AgentKey,
        IsAllowClassHourLackKey,
        IsTrialKey,
        IsMakeupKey,
        (obj) => {
          obj.StudentKeyValue = item.MainDemoName;
          obj.StudentKey = item.OLAPKey;
          this.curSelectedStudentInfo = obj;
          this.$refs.studentsList.addDataStudentList(item);
          if (arrangeCallBack) {
            arrangeCallBack();
          }
          if (this.isShowAddStudentDialog == '安排') {
            this.$bus.emit(
              'AfterStudentAddToCourse',
              this.selectedCourseInfo.ScheduleCourseKey,
              item.OLAPKey,
              this.selectedCourseInfo.StatusKey
            );
          } else if (this.isShowAddStudentDialog == '签到') {
            this.toSetAttendance(obj.IsClassHourLackKey || 0, arrangeCallBack);
          } else if (this.isShowAddStudentDialog == '排课') {
            ArrangeStudentToPlan(
              item.OLAPKey,
              this.selectedCourseInfo.StructuredPlanKey
            )
              .then((result) => {
                if (arrangeCallBack) {
                  arrangeCallBack();
                }
                layer.alert('添加成功');
                this.$bus.emit(
                  'AfterStudentAddToCourse',
                  this.selectedCourseInfo.ScheduleCourseKey,
                  item.OLAPKey,
                  this.selectedCourseInfo.StatusKey
                );
              })
              .catch((error) => {
                this.alertMsg = error.msg;
              });
          } else if (this.isShowAddStudentDialog == '补课') {
            console.log('补课');
            if (arrangeCallBack) {
              arrangeCallBack();
            }
            this.$bus.emit(
              'AfterStudentAddToCourse',
              this.selectedCourseInfo.ScheduleCourseKey,
              item.OLAPKey,
              this.selectedCourseInfo.StatusKey
            );
          }
        },
        () => {
          if (rejectCallBack) {
            rejectCallBack();
          }
        }
      );
    },
    // 课程添加学员接口.
    setStudentHasClass (
      classTime,
      scheduleCourseKey,
      studentKey,
      agentKey,
      isAllowClassHourLackKey,
      isTrialKey,
      IsMakeupKey,
      callBack,
      rejectCallBack
    ) {
      courseAddStudent(
        classTime,
        scheduleCourseKey,
        studentKey,
        agentKey,
        isAllowClassHourLackKey,
        isTrialKey,
        IsMakeupKey
      ).then(
        (result) => {
          result.data.OLAPKey = result.data.StudentNameKey;
          this.$set(result.data, 'isSelected', false);
          this.$set(result.data, 'unSelected', false);
          this.studentArrangeList.push(result.data);
          if (callBack) {
            callBack(result.data);
          }
        },
        (error) => {
          let that = this;
          if (error.code == 60001) {
            console.log(
              'this.curSelectedStudentInfo',
              this.curSelectedStudentInfo
            );
            // layer.confirm(this.curSelectedStudentInfo.MainDemoName + '课时不足,是否继续安排？', {
            //   shadeClose: false,
            //   btn: [
            //     {
            //       name: '取消',
            //       callBack: () => {
            //         this.$refs.studentsList.isAdding = false;

            //         layer.close();
            //       }
            //     },
            //     {
            //       name: '确认',
            //       callBack: function () {
            that.setStudentHasClass(
              classTime,
              scheduleCourseKey,
              studentKey,
              agentKey,
              1,
              isTrialKey,
              IsMakeupKey,
              callBack
            );
            //         layer.close();
            //       }
            //     }
            //   ]
            // });
          }
          if (error.code == 60008 || error.code == 60009) {
            let tips = '';
            if (error.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else if (error.code == 10009) {
            layer.confirm('该学生没有可用课单，是否无课时安排？', {
              shadeClose: false,
              btn: [
                {
                  name: '取消',
                  callBack: () => {
                    this.$refs.studentsList.isAdding = false;
                    layer.close();
                  }
                },
                {
                  name: '确认',
                  callBack: function () {
                    that.setStudentHasClass(
                      classTime,
                      scheduleCourseKey,
                      studentKey,
                      agentKey,
                      1,
                      isTrialKey,
                      IsMakeupKey,
                      callBack
                    );
                    layer.close();
                  }
                }
              ]
            });
          } else {
            this.$refs.studentsList.isAdding = false;
            layer.alert(error.msg);
            if (rejectCallBack) {
              rejectCallBack();
            }
          }
        }
      );
    },
    // 选中学员信息 根据选中学生状态 弹出学生考勤框（变更考勤，未考勤，签到，请假，旷课，删除）根据状态显示哪几个
    selectedStudent (studentData) {
      this.isAdd = false;
      if (this.curSelectedStudentInfo) {
        this.curSelectedStudentInfo.isSelected = false;
      }
      studentData.isSelected = true;
      this.curSelectedStudentInfo = studentData;
      // let StatusKey = this.selectedCourseInfo.StatusKey;

      // let scrollTop = document.getElementsByClassName('course_student_info_list')[0].scrollTop;
      // this.vTop = Math.abs(scrollTop - (seletedEle.offsetTop + 140 + seletedEle.offsetHeight - 18)) + 'px';        // 140为固定高度,52为该元件的高度
    },
    // 点击考情项
    clickAttendanceOption (optionItem) {
      this.optionItem = optionItem;
      switch (optionItem.type) {
        case this.$initJson.attendanceType.view:
          this.examine();
          this.studentArrangeList.forEach(function (obj) {
            obj.isSelected = false;
          });
          break;
        case this.$initJson.attendanceType.change:
          this.changeAttendance();
          break;
        case this.$initJson.attendanceType.cancel:
          if(this.$utils.getSaaSClientSetInfo(124).SetContent==1&&this.isTeacher){
            layer.alert("已开启老师限制，不能操作")
          }else{
            this.isShowAttendanceReason = true;
          }
          break;
        case this.$initJson.attendanceType.attendance:
          if (
            this.curSelectedStudentInfo.AttenceStatusKey !=
            this.$initJson.attendanceType.cancel
          ) {
             if(this.$utils.getSaaSClientSetInfo(124).SetContent==1&&this.isTeacher){
              layer.alert("已开启老师限制，不能操作")
            }else{
              this.isShowChangeAttendance = true;
            }
          } else {
            this.toSetAttendance(
              this.curSelectedStudentInfo.IsClassHourLackKey
            );
          }
          break;
        case this.$initJson.attendanceType.leave:
          if (
            this.curSelectedStudentInfo.AttenceStatusKey !=
            this.$initJson.attendanceType.cancel
          ) {
             if(this.$utils.getSaaSClientSetInfo(124).SetContent==1&&this.isTeacher){
              layer.alert("已开启老师限制，不能操作")
            }else{
              this.isShowChangeAttendance = true;
            }
          } else {
            this.changeToLeave();
          }
          break;
        case this.$initJson.attendanceType.absence:
          if (
            this.curSelectedStudentInfo.AttenceStatusKey !=
            this.$initJson.attendanceType.cancel
          ) {
             if(this.$utils.getSaaSClientSetInfo(124).SetContent==1&&this.isTeacher){
              layer.alert("已开启老师限制，不能操作")
            }else{
              this.isShowChangeAttendance = true;
            }
          } else {
            this.changeToAbsence();
          }
          break;
        case this.$initJson.attendanceType.changeAtten:
          if(this.$utils.getSaaSClientSetInfo(124).SetContent==1&&this.isTeacher){
              layer.alert("已开启老师限制，不能操作")
          }else{
              this.isShowChangeAttendance = true;
          }

          break;
        case this.$initJson.attendanceType.changeAttenList:
          this.$dialog.open(this, {
            routerName: this.$route.name,
            moduleName: 'changeAttenList',
            data: { ScheduleCourseKey: this.selectedCourseInfo.ScheduleCourseKey },
            callBack: { afterSuccess: () => { this.$emit('updateCourseInfo', this.selectedCourseInfo.ScheduleCourseKey); } }

          });

          break;
        case this.$initJson.attendanceType.del:
          console.log("del1",this.isTeacher)
          this.cancelTitle='del'
          if(this.$utils.getSaaSClientSetInfo(122).SetContent==1&&this.isTeacher){
            layer.alert("已开启老师限制，不能操作")
          }else{
            this.deleteClick();
          }
          break;
        case this.$initJson.attendanceType.addToCourse:
          this.showAddStudentDialog();
          break;
        case this.$initJson.attendanceType.addToCourseAndAttendance:
          this.showAddStudentAttendance();
          break;
        case this.$initJson.attendanceType.studentRepairCourse:
          this.showAddStudentRepairCourse();
          break;
        case this.$initJson.attendanceType.addCoursePlan:
          this.showAddStudentPlan();
          break;
        case this.$initJson.attendanceType.secedeLoop: // 退出循环课
          this.cancelTitle='secedeLoop'
          if(this.$utils.getSaaSClientSetInfo(123).SetContent==1&&this.isTeacher){
            layer.alert("已开启老师限制，不能操作")
          }
          // else if(Number(this.$utils.getSaaSClientSet(123))>0){
          //    this.isShowQuitCourseReason = true;
          // }
          else{
            this.cancelAllArrangeClick();
          }
          console.log("this.cancelTitle='secedeLoop'")
          
          break;
        case this.$initJson.attendanceType.addLoop: // 加入循环课
          this.isShowStudentListDialog = true;
          break;
        case this.$initJson.attendanceType.bantchSign: // 批量签到
          this.bantchType = 3;
          this.isSelectAll = false;
          this.closeShowBantchAtten();
          this.allBantchAtten();
          this.clickBatchAttendance(3);
          break;
        case this.$initJson.attendanceType.bantchLeave: // 批量请假
          this.bantchType = 4;
          this.isSelectAll = false;
          this.closeShowBantchAtten(4);
          this.allBantchAtten();
          this.clickBatchAttendance(4);
          break;
        case this.$initJson.attendanceType.bantchAbsence: // 批量旷课
          this.bantchType = 5;
          this.isSelectAll = false;
          this.closeShowBantchAtten(5);
          this.allBantchAtten();
          this.clickBatchAttendance(5);
          break;
        case this.$initJson.attendanceType.delArrangeCourse: // 全部退课
          this.bantchType = 6;
          this.isSelectAll = false;
          this.closeShowBantchAtten(6);
          this.allBantchAtten();
          this.clickBatchAttendance(6);
          break;
        case this.$initJson.attendanceType.courseComment: // 课评
          this.releaseComment(this.curSelectedStudentInfo.StudentKey);
          break;
        case this.$initJson.attendanceType.homeWork: // 作业
          this.releaseHomeTask(this.curSelectedStudentInfo.StudentKey);
          break;
        case this.$initJson.attendanceType.batchComment: // 批量课评
          this.releaseComment(0);
          break;
        case this.$initJson.attendanceType.batchWork: // 批量作业
          this.releaseHomeTask(0);
          break;
        case this.$initJson.attendanceType.cancelcourseComment: // 撤回课评
          this.deletedCommtent();
          break;
        case this.$initJson.attendanceType.cancelhomeWork: // 撤回作业
          this.deletedHomeWork();
          break;
        case this.$initJson.attendanceType.stopCourse: // 停课
         this.cancelTitle='stopCourse'
        //  if(Number(this.$utils.getSaaSClientSet(125))>0){
        //      this.isShowQuitCourseReason = true;
        //   }else{
              this.courseSuspend(0);
          // }
        
          break;
        default:
          break;
      }
      this.isShowDialog = false;
      this.$store.commit('showAlerDialog', false);
    },
    // 询问是否停课
    courseSuspend (type,Reason) {
      let that = this;
      layer.confirm('是否停课', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.setCourseCancel(1,Reason);
            layer.close();
          }
        }]
      });
    },
    // 停课
    setCourseCancel (IsNotEndKey,Reason) {
      if (!this.selectedCourseInfo.ScheduleCourseKey) {
        return false;
      }
      Reason=Reason||''
      PauseScheduleCourse(this,this.selectedCourseInfo.ScheduleCourseKey, IsNotEndKey,Reason).then(result => {
        layer.alert('停课成功');
        this.$emit("closeCourse")
        this.$bus.emit('AfterBatchEditCourses');
      }, error => {
        if (error.code == 70001) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setCourseCancel(0,Reason);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      });
    },

    // 查看学员的学员档案
    examine () {
      let item = {
        routerName: this.$route.name,
        name: '学生档案',
        moduleName: 'studentFile',
        data: { studentID: this.curSelectedStudentInfo.StudentKey }
      };
      this.$dialog.open(this, item);
    },
    // 学生考勤弹出框 选中一项 不同的项对应不同的方法
    clickOptionForAlertDialog (optionItem, detailStudentItem) {
      this.isShowChangeAttendance = false;
      this.isShowChangeAttendance = false;
      if (optionItem.type == 3) {
        this.selectedCourseInfo.CQRS += 1;
        this.curSelectedStudentInfo.AttenceStatusKey = 3;
        this.curSelectedStudentInfo.AttenceStatusKeyValue = '签到';
      } else if (optionItem.type == 4) {
        this.selectedCourseInfo.QJRS += 1;
        this.curSelectedStudentInfo.AttenceStatusKey = 4;
        this.curSelectedStudentInfo.AttenceStatusKeyValue = '请假';
      } else if (optionItem.type == 5) {
        this.selectedCourseInfo.KKRS += 1;
        this.curSelectedStudentInfo.AttenceStatusKey = 5;
        this.curSelectedStudentInfo.AttenceStatusKeyValue = '旷课';
      }
      this.curSelectedStudentInfo.ClassHourKey = detailStudentItem.ClassHourKey; // 消费状态. 5为消费.
      this.curSelectedStudentInfo.ISClassHourDebtKey =
        detailStudentItem.ISClassHourDebtKey;
      this.curSelectedStudentInfo.ISClassHourDebtKeyValue =
        detailStudentItem.ISClassHourDebtKeyValue;
      this.isShowDialog = false;
      this.$store.commit('showAlerDialog', false);
    },

    // 变更考勤
    changeAttendance () {
      let moduleInfo = {
        name: '修改考勤',
        routerName: this.$route.name,
        moduleName: 'changeAttendance',
        data: {
          dataInfo: this.curSelectedStudentInfo,
          courseInfo: this.selectedCourseInfo,
          isShowSearchInput: true
        },
        callBack: {
          afterSucces: (data) => {
            Object.assign(this.curSelectedStudentInfo, data);
          }
        }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 校正考勤
    changeAttendanceAfter (dataItem, ISClassHourLackKey, type, callback) {
      console.log(this.curSelectedStudentInfo, 'dataItem1', this.selectedCourseInfo);
      this.curSelectedStudentInfo.ISClassHourLackKey = 1;// ISClassHourLackKey || 0;
      KQ_AdjustHour(
        this.curSelectedStudentInfo.StudentArrangeKey,
        this.curSelectedStudentInfo.ISClassHourLackKey,
        this.curSelectedStudentInfo.IsTrialKey,
        dataItem

      )
        .then((result) => {
          if (result.code === 0) {
            console.log('kqChange', this.$utils.parseJson(result.data));
            Object.assign(this.curSelectedStudentInfo, result.data);
          }
          this.curSelectedStudentInfo.isSelected = false;
          if (type == 'cancel') {
            layer.alert('撤销考勤成功');
          } else {
            layer.alert('校正考勤成功');
          }
          this.closeAttendanceReason();
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.selectedCourseInfo.StatusKey
          );
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue +
              '课时不足，是否以欠课时的方式变更考勤？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      if (callback) {
                        callback();
                      }
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.changeAttendanceAfter(
                        dataItem,
                        1
                      );
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else if (err.code == 630002) {
            layer.confirm('补课已结束，不可直接变更考勤状态，是否进行撤销补课处理？', {
              btn: [{
                name: '确认',
                callBack: () => {
                  layer.close();
                  RevokeDispose(this.curSelectedStudentInfo.StudentKey, '', this.curSelectedStudentInfo.ScheduleCourseKey, '').then(result => {
                    this.$bus.emit(
                      'afterArrangeScheduleCourse',
                      this.curSelectedStudentInfo.ScheduleCourseKey,
                      this.curSelectedStudentInfo.StudentKey
                    );
                    this.$bus.emit(
                      'AfterChangeAttendanceStatus',
                      this.curSelectedStudentInfo.ScheduleCourseKey,
                      this.curSelectedStudentInfo.StudentKey
                    );
                    this.$emit('updateStudentFileEvent');
                    this.closeAttendanceReason();
                    layer.alert('撤销成功');
                  }, error => {
                    layer.alert(error.msg);
                  });
                }
              }]
            });
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 变更考勤（已考勤的变更成另一种状态）
    changeAttendanceStatus (optionItem, attendanceReason, ISClassHourLackKey) {
      this.curSelectedStudentInfo.ISClassHourLackKey = ISClassHourLackKey || 0;
      kqChange(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        this.curSelectedStudentInfo.ISClassHourLackKey,
        optionItem.type,
        this.curSelectedStudentInfo.StudentArrangeKey,
        attendanceReason
      )
        .then((result) => {
          if (result.code === 0) {
            console.log('kqChange', this.$utils.parseJson(result.data));
            Object.assign(this.curSelectedStudentInfo, result.data);
          }
          this.curSelectedStudentInfo.isSelected = false;
          layer.alert('变更考勤成功');
          this.closeAttendanceReason();
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.selectedCourseInfo.StatusKey
          );
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue +
              '课时不足，是否以欠课时的方式变更考勤？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.changeAttendanceStatus(
                        optionItem,
                        attendanceReason,
                        1
                      );
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 请假
    changeToLeave () {
      this.toLeave(0);
    },
    toLeave (IsClassHourLackKey) {
      // 调用请假接口.
      if (IsClassHourLackKey == 0) {
        IsClassHourLackKey = this.curSelectedStudentInfo.IsMakeupKey == 1 ? '1' : '0';
      }
      kqLeave(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey // 第一次请求IsClassHourLackKey默认为0, 由服务器返回报错是否为课时不足, 再进行操作
      ).then(
        (result) => {
          Object.assign(this.curSelectedStudentInfo, result.data);
          this.selectedCourseInfo.WKQRS -= 1;
          this.selectedCourseInfo.QJRS += 1;
          layer.alert('请假成功');
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.selectedCourseInfo.StatusKey
          );

          this.curSelectedStudentInfo.isSelected = false;
        },
        (err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue +
              '课时不足，是否以欠课时的方式继续请假？',
              {
                btn: [
                  {
                    name: '确认',
                    callBack: function () {
                      that.toLeave(1);
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }

            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else {
            layer.alert(err.msg);
          }
        }
      );
    },
    // 旷课
    changeToAbsence () {
      this.toAbsence(0);
    },
    // 调用旷课接口
    toAbsence (IsClassHourLackKey) {
      console.log('toAbsence', this.selectedCourseInfo);
      if (IsClassHourLackKey == 0) {
        IsClassHourLackKey = this.curSelectedStudentInfo.IsMakeupKey == 1 ? '1' : '0';
      }
      kqTruancy(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey // 第一次请求IsClassHourLackKey默认为0, 由服务器返回报错是否为课时不足, 再进行操作
      )
        .then((result) => {
          Object.assign(this.curSelectedStudentInfo, result.data);
          this.selectedCourseInfo.WKQRS -= 1;
          this.selectedCourseInfo.KKRS += 1;
          layer.alert('设置旷课成功');
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.selectedCourseInfo.StatusKey
          );

          this.curSelectedStudentInfo.isSelected = false;
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            // 60001为课时不足.  课时不足才提示.
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue +
              '课时不足，是否以欠课时的方式继续旷课？',
              {
                btn: [
                  {
                    name: '确认',
                    callBack: function () {
                      that.toAbsence(1);
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }

            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 备注隐藏。
    delReasonHide(Reason){
      console.log("this.cancelTitle",this.cancelTitle)
      if(this.cancelTitle=='del'){
        this.deleteClick(null,null,null,Reason)
      }else if(this.cancelTitle=='secedeLoop'){
        this.cancelAllArrangeClick(Reason)
      }else if(this.cancelTitle=='stopCourse'){
        this.courseSuspend(0,Reason)
      }
    },
    // 删除
    deleteClick (data, index, callBack,Reason) {
      // 调用删除接口.
      console.log("deleteClick Reason",Reason)
      if (this.deleteCount != 1) { // 手动拦截
        console.log('手动防抖成功！重复点击！！');
        return;
      }
      this.deleteCount = 2;// 设置防抖关卡
      if (data) {
        this.curSelectedStudentInfo = data;
        this.curSelectedStudentInfo.StudentKey = data.OLAPKey;
        this.curSelectedStudentInfo.ScheduleCourseKey =
          this.selectedCourseInfo.ScheduleCourseKey;
      }
      if (
        this.curSelectedStudentInfo.StudentKey > 0 &&
        this.curSelectedStudentInfo.ScheduleCourseKey > 0
      ) {
        kqDelete(this,
          this.curSelectedStudentInfo.StudentKey,
          this.curSelectedStudentInfo.ScheduleCourseKey,
          Reason
        ).then(
          (result) => {
            this.curSelectedStudentInfo.AttenceStatusKey = 7;
            this.curSelectedStudentInfo.AttenceStatusKeyValue = '已取消';
            this.selectedCourseInfo.ZRS -= 1;
            this.selectedCourseInfo.WKQRS -= 1;
            let findIndex = this.studentArrangeList.findIndex((o) => {
              return o.StudentKey == this.curSelectedStudentInfo.StudentKey;
            });
            if (findIndex > -1) {
              this.studentArrangeList.splice(findIndex, 1);
            }
            layer.alert('退课成功');
            this.deleteCount = 1;// 取消防抖关卡
            if (callBack) {
              callBack();
            }
            this.$bus.emit(
              'afterArrangeScheduleCourse',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
            this.$emit(
              'changeAttendanceStutus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey,
              this.selectedCourseInfo.StatusKey
            );
          },
          (err) => {
            this.deleteCount = 1;
            layer.alert(err.msg);
          }
        );
      }
    },
    editAtten(type,studentItem){
      this.selectedStudent (studentItem)
      if(type==''){
        this.clickAttendanceOption (this.attendanceStatus[2])
      }else if(type==3){
        this.clickAttendanceOption (this.attendanceStatus[1])
      }else if(type==4){
        this.clickAttendanceOption (this.attendanceStatus[2])
      }else if(type==5){
        this.clickAttendanceOption (this.attendanceStatus[3]) 
      }else if (type=='publishComment'){
        this.clickAttendanceOption (this.attendanceStatus[12]) 
      }else if (type=='commentDetail'){
        let moduleInfo = {
        name: '课评详情',
        moduleName: 'commentDetail',
        routerName: this.$route.name,
        data: { dataInfo: {
          courseId: this.selectedCourseInfo.ScheduleCourseKey,
          showWorkInfo: true
        } }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
        // this.clickAttendanceOption (this.attendanceStatus[4]) 
      }
    },
    getPosition (left, top, clientTop,isRowList) {
      console.log(left, 'positionLeft', clientTop);
      if (top > 280) {
        this.isScrollHidden = false;
        let num = this.$refs.selectCourseStudentList.scrollTop;
        this.$refs.selectCourseStudentList.scrollTop =
          this.$refs.selectCourseStudentList.scrollTop + 80;
        num = this.$refs.selectCourseStudentList.scrollTop - num;
        top -= num;
      }
      if(!isRowList){
        this.positionLeft = left + 8;
        this.positionTop = top;
      }else{
        this.positionLeft = 310;
        this.positionTop = top-50;
      }
      
    },
    doAfterClickAddStudentBtn (left, top, clientTop) {
      this.isAdd = true;
      if (top > 290) {
        this.isScrollHidden = false;
        let num = this.$refs.selectCourseStudentList.scrollTop;
        this.$refs.selectCourseStudentList.scrollTop =
          this.$refs.selectCourseStudentList.scrollTop + 80;
        num = this.$refs.selectCourseStudentList.scrollTop - num;
        top -= num;
      }
      this.positionLeft = left + 8;
      this.positionTop = top - 24;
      // let StatusKey = this.selectedCourseInfo.StatusKey;
      this.attendanceStatus.forEach((obj) => { });
      this.studentArrangeList.forEach(function (obj) {
        obj.isSelected = false;
      });
    },
    closeStudentListDialog () {
      this.isShowStudentListDialog = false;
    },
    // 加入循环课
    toAddLoop (stuItem) {
      this.isShowStudentListDialog = false;
      this.addLoopStudentInfo = stuItem;
      this.isTheBatchProcessShow = true;
      if (this.vIsSysVersionTY) {
        this.addToCircleCourseForStudent();
      } else {
        this.parmsInfo = {
          routerName: this.$route.name,
          moduleName: 'batchArrangeScheduleCourse',
          data: {
            paramsInfo: {
              studentKey: this.addLoopStudentInfo.OLAPKey,
              ruleID: this.courseRule,
              courseNameKey: this.selectedCourseInfo.CourseNameKey,
              classGradeKey: this.selectedCourseInfo.GradeClassKey
            }
          }
        };
        this.$bus.emit('needOpenRouter', this.parmsInfo);
      }
    },
    // 通用版 安排进循环课
    addToCircleCourseForStudent () {
      let params = {
        StudentKey: this.addLoopStudentInfo.OLAPKey,
        StructuredRuleKey: this.courseRule,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      ArrangeStudent2MutiSameApplyClassCourseByRule(params).then(
        (result) => {
          this.$bus.emit(
            'AfterStudentAddToCourse',
            this.selectedCourseInfo.ScheduleCourseKey,
            this.addLoopStudentInfo.OLAPKey,
            this.selectedCourseInfo.StatusKey
          );
          this.isTheBatchProcessShow = false;
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    closePlan () {
      this.isShowPlan = false;
    },
    successPlan () {
      this.isShowPlan = false;
      this.$bus.emit(
        'AfterStudentAddToCourse',
        this.selectedCourseInfo.ScheduleCourseKey,
        0
      );
    }
  }
};
</script>
<style scoped>
.item_menu_bottom_attendance {
  position: absolute;
  right: 12px;
  top: 20px;
  line-height: 30px;
  border-radius: 15px;
  border: 1px solid #ececec;
  width: 90px;
}
.index_course_info_item .item_menu_bottom_attendance {
  background-position: 6px;
  padding-left: 23px;
}
.row_student_list{
  display: flex;
  flex: 1;
  flex-direction: column;
  /* margin: 0 10px; */
  /* background: yellow; */
}
.row_student_item{
  flex: 1;
  display: flex;
  flex-direction: row;
}
</style>
