<template>
  <div class="compile_from_box">
    <div class="compile_from_content">
      <!-- 内容设置 -->
      <div>
        <content-setting ref="contentSetting"
                         :dataInfo="dataInfo"
                         :CouponInfo="CouponInfo"
                         :coverInfo="coverInfo"
                         :PosterInfo="PosterInfo"
                         :contentSetting="contentSetting"
                         @updateData="updateData"
                         @cancelClick="cancelClick"
                         @seletedModule="seletedModule"
                         @verifyAllData="verifyAllData"
                         @setscorllTop="setscorllTop"
                         @success="success"
                         ></content-setting>
      </div>
    </div>
  </div>
</template>
<script>

import contentSetting from './setting-component/content-setting';

import { getCourseTypeNameList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      viewType: 0,
      coursList: [],
      courseTreeList: [],
    

    };
  },
  props: {
    dataInfo: Object,     // 玩法详情及设置.
    coverInfo: Object,
    CouponInfo: Object, // 券绑定需要提交的信息
    PosterInfo: Object,    // 模板信息
    contentSetting: Object
  },
  computed: {
    vSettingInfo () {
      let settingInfo = '';
      if (this.dataInfo) {
        settingInfo = JSON.parse(this.dataInfo.activitiesSetting);
      }
      return settingInfo;
    },
    
  },
  components: {
    contentSetting
  },
  created () {

    // console.log(this.vSettingInfo, 'setting-view-created');
  },
  mounted () {

  },
  methods: {
    seletedTemplate(){
      this.$refs.contentSetting.showModuleDialog()
    },
    cancelClick(){
      this.$emit("cancelClick")
    },
    seletedModule(item,callback){
      this.$emit("seletedModule",item,callback)
    },
    verifyAllData (callback, callback2) {
      callback();
    },
    
    setscorllTop (type) {
      this.$emit('setscorllTop', type);
    },
    // 新增成功
    success () {
      this.$emit('success');
    },
    updateData(){
      this.$emit("updateData")
    }
    
  }

};
</script>
