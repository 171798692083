<template>
  <div>
    <div class="form_info_edit form_info_list">
     <input-dialog :formTitle="'名称'"
                        class=""
                        :formPlaceholder="'请选择'"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showListDialog"
                        :value="dataJson.IncidentalsKeyValue"></input-dialog>
      <input-number
        :formTitle="'数量'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.QuantityCount"
      ></input-number>
      <input-number
        :formTitle="'单价'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请选择'"
        v-model="dataJson.UnitPriceAmount"
      ></input-number>
      <input-number
        :formTitle="'收费金额'"
        :required="true"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="vTotalAmount"
      ></input-number>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group
      v-if="!isReadonly"
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      @confirmClick="confirmClick"
      @cancelClick="cancelClick"
    ></save-cancel-btn-group>
  </div>
</template>
<script>
import {hqAttractInvestmentIncidentals} from '../../../../../API/workbench'
export default {
  data() {
    return {
      dataJson: {
        TotalAmount: '',//"总价",
        UnitPriceAmount: '',//"单价",
        Unit: '',//"单位",
        QuantityCount: '',//"数量",
        CourseKey: '',//"课程",
        CourseKeyValue: '',//"课程",
        IncidentalsKey: '',//"杂费",
        IncidentalsKeyValue: '',//"杂费",
         "StatusKey":'',//"课程收费类型1-终身;2-按年收费;3-按次收费",
          "StatusKeyValue":'',//"课程收费类型",
          "TypeKey":"",//"1-杂费;2-课程",
          "TypeKeyValue":"",//"类别",
      },
      showList:false
    };
  },
  props:{
      dataInfo:Object,
      isReadonly:Boolean
  },
  created(){
      if(this.dataInfo){
          this.dataJson=this.$utils.parseJson(this.dataInfo)
      }
  },
  computed:{
    vTotalAmount(){
      return Number(this.dataJson.UnitPriceAmount)*Number(this.dataJson.QuantityCount)
    }
  },
  mounted(){
      this.$dialog.register(this, ['singleSelectionList']);
  },
  methods:{
      cancelClick(){
          this.$emit("cancelClick")
      },
      verifyData(callback){
          this.dataJson.TotalAmount=this.vTotalAmount
          if(!this.dataJson.IncidentalsKeyValue){
              layer.alert('请选择杂费项目')
              if(callback){
                  callback()
              }
              return false
          }else if(Number(this.dataJson.QuantityCount)==0){
              layer.alert('数量不能为0')
              if(callback){
                  callback()
              }
              return false
          }else if(Number(this.dataJson.UnitPriceAmount)==0){
              layer.alert('单价不能为0')
              if(callback){
                  callback()
              }
              return false
          }else if(Number(this.dataJson.TotalAmount)==0){
              layer.alert('收费金额不能为0')
              if(callback){
                  callback()
              }
              return false
          }else if(Number(this.dataJson.TotalAmount)!=(Number(this.dataJson.UnitPriceAmount)*Number(this.dataJson.QuantityCount))){
              layer.alert('收费金额必须为单价乘以数量的总数')
              if(callback){
                  callback()
              }
              return false
          }else{
              return true
          }
        
      },
      confirmClick(callback){
          if(this.verifyData(callback)){
              this.$emit('afterSuccess',this.dataJson)
              this.$emit("cancelClick")
          }else{
           
          }
      },
      showListDialog(callback){
          this.showList=true
          this.getMessageForList()
      },
      doAfterSelectionData(item){
        this.dataJson.IncidentalsKey= item.OLAPKey;//"杂费",
        this.dataJson.IncidentalsKeyValue=item.MainDemoName//"杂费",
        this.dataJson.Unit=item.UnitName
      },
      // 获取所有财务科目列表
    getMessageForList () {
      hqAttractInvestmentIncidentals().then(result => {
        this.dataList = result.data;
        console.log(result.data, '获取所有启用的数据');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择杂费',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.IncidentalsKey, dataSourceList: this.dataList, required: true, addModuleName: '', addModuleTitleName: '添加杂费', ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectionData(data); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
  }

};
</script>