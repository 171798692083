<template>
  <div>
    <!-- 在校学生 -->
    <div class>
      <!-- 通用版 -->
      <div v-if="vIsSysVersionTY">
        <general-atSchool-student-list></general-atSchool-student-list>
      </div>
      <!-- 精准版 -->
      <div style="border-radius:8px;overflow:hidden;"
           v-else>
        <precise-atSchool-student-list></precise-atSchool-student-list>
      </div>

    </div>
  </div>
</template>
<script>

import generalAtSchoolStudentList from './general-atSchool-student-list';
import preciseAtSchoolStudentList from './precise-atSchool-student-list';
// import myMixin from '../../../assets/mixin/destory-cache';
export default {
  name: 'studentManagementReport',
  data () {
    return {
      // tableType: '通用版'
    };
  },
  components: {
    preciseAtSchoolStudentList,
    generalAtSchoolStudentList
  },
  created () {
  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
