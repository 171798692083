<template>
  <!-- 备课统计 -->
  <div>
    <div class="school_inspector_statistics_top">
      <!-- 条件过滤 -->
      <div class="top_select">
        <condition-filtration-box :queryParams="queryParams"
                                  :campusList="campusList"
                                  @searchValue="search"></condition-filtration-box>
      </div>
      <!-- 汇总信息 -->
      <div class="top_content">
        <statistics-content-list v-for="(item,key) in statisticsInfoList"
                                 :key="key"
                                 :dataInfo="item"></statistics-content-list>
      </div>
    </div>
    <div class="school_inspector_statistics_bottom">
      <div class="bottom_left scroll_type">
        <div class="left_list_title">老师备课排名</div>
        <div class="left_list_ul"
             :style="leftScrollHeight">
          <teacher-ranking-list v-for="(item,key) in vTeacherRanked"
                                :key="key"
                                :dataInfo="item"
                                :dataList="vTeacherRanked"
                                @selectTeacherRanking="selectTeacherRanking"></teacher-ranking-list>
          <div v-if="vTeacherRanked.length==0"
               style="margin-top: 200px;">
            <basics-nodata-tips></basics-nodata-tips>
          </div>
        </div>
      </div>
      <div class="bottom_right">
        <div class="">
          <statistics-tltle-head :className="'right_list_title'"
                                 :titleList="prepareLessonsTitleList"></statistics-tltle-head>
        </div>
        <!-- 已检查 -->
        <div v-if="prepareLessonsTitleList[0].isSelected">
          <already-examine-list :paramsObj="queryParams"></already-examine-list>
        </div>

        <!-- 逾期未备 -->
        <div v-else-if="prepareLessonsTitleList[1].isSelected">
          <overdue-examine-list :paramsObj="queryParams"></overdue-examine-list>
        </div>

        <!-- 待检查 -->
        <div v-else-if="prepareLessonsTitleList[2].isSelected">
          <await-examine-list :paramsObj="queryParams"></await-examine-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import conditionFiltrationBox from '../../common/condition-filtration-box';
import statisticsContentList from '../../common/statistics-content-list';
import statisticsTltleHead from '../../common/statistics-tltle-head';
import teacherRankingList from '../teacher-ranking-list';
import awaitExamineList from './table-list/await-examine-list';
import alreadyExamineList from './table-list/already-examine-list';
import overdueExamineList from './table-list/overdue-examine-list';
import { hqSchoolPrepareLessonsStat, SchoolPrepareLessonsStat } from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      statisticsInfoList: [
        { name: '备课率', key: 1, type: 'progress', value: 0, className: '' },
        { name: '应备课次', key: 2, type: 'count', value: 0, className: '' },
        { name: '逾期课次', key: 4, type: 'count', value: 10, className: '' },
        { name: '已备次数', key: 3, type: 'count', value: 0, className: 'font_red' }

      ],
      prepareLessonsTitleList: [
        { name: '已检查', key: 1, isSelected: true },
        { name: '逾期未备', key: 2, isSelected: false },
        { name: '待检查', key: 3, isSelected: false }
      ],
      summarizingInfo: null, // 汇总信息查询
      // 查询参数
      queryParams: {
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        SchoolKey: '', //	门店id
        TeacherKey: ''
      },
      leftScrollHeight: '' // 老师排名列表滚动高度
    };
  },
  components: {
    conditionFiltrationBox,
    statisticsContentList,
    statisticsTltleHead,
    teacherRankingList,
    awaitExamineList,
    alreadyExamineList,
    overdueExamineList
  },
  props: {
    campusList: {
      type: Array,
      default: []
    },
    schoolInfo: Object
  },
  created () {
    this.queryParams.SchoolKey = this.schoolInfo.SchoolKey;
    this.queryParams.StartDate = this.schoolInfo.StartDate;
    this.queryParams.EndDate = this.schoolInfo.EndDate;
    this.getSearchPageNotCheckPrepareLessons(this.queryParams);
  },
  methods: {
    // 汇总信息查询
    getSearchPageNotCheckPrepareLessons (queryParams) {
      let apiFN = (this.vTypeKey ? hqSchoolPrepareLessonsStat : SchoolPrepareLessonsStat);
      apiFN(queryParams).then(result => {
        console.log('汇总信息查询', result.data);
        this.summarizingInfo = result.data;
        this.queryParams.TeacherKey = '';
        if (this.summarizingInfo.TeacherRanked.length > 0) { // 默认选中第一位老师
          this.queryParams.TeacherKey = this.summarizingInfo.TeacherRanked[0].MTeacherKey;
        }
        if (this.summarizingInfo.Stat) {
          this.statisticsInfoList[0].value = Number(this.summarizingInfo.Stat.SchoolPrepareRate); // 备课率
          this.statisticsInfoList[1].value = this.summarizingInfo.Stat.SchoolOughtToPrepareNum;// 应备课
          this.statisticsInfoList[2].value = this.summarizingInfo.Stat.SchoolOverdueNum;// 逾期未备
          this.statisticsInfoList[3].value = this.summarizingInfo.Stat.SchoolPrepareNum;// 已备课
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 根据校区时间搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.$emit('search', queryParams);
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    // 根据老师排名搜索
    selectTeacherRanking (dataInfo) {
      console.log('根据老师排名搜索', dataInfo);
      this.queryParams.TeacherKey = dataInfo.MTeacherKey;
    },
    // 获取动态老师排名列表滚动高度
    teacherListScrollHeight (setHeight) {
      this.leftScrollHeight = 'height:' + setHeight + 'px';
    }
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vTeacherRanked () {
      let list = [];
      if (this.summarizingInfo && this.summarizingInfo.TeacherRanked.length > 0) {
        this.summarizingInfo.TeacherRanked.forEach(o => {
          this.$set(o, 'isOpt', false);
          this.$set(o, 'SubmitName', '已备次数');
          this.$set(o, 'Submit', o.PrepareLessonsNum);
          this.$set(o, 'NotSubmitName', '逾期课次');
          this.$set(o, 'NotSubmit', o.OverdueNum);
          this.$set(o, 'SubmitRateName', '备课率');
          this.$set(o, 'SubmitRate', o.PrepareLessonsRate);
          list.push(o);
        });
        list[0].isOpt = true;
      }
      return list;
    }
  }
};
</script>

<style>
</style>

