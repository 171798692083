<template>
  <div class="form_item form_info_line"
       :class="{ form_info_required: required }">
    <div :class="formPlaceholder==' '?'form_info_field_init':'form_info_field'">
      <span>{{formTitle}}</span>
    </div>
    <div class="form_info_value form_info_select"
         v-if="!readonly">
      <input type="text"
             readonly
             :disabled="disabled"
             :value="value"
             :placeholder="vPlaceholder"
             :class="className"
             @click.stop="showEldilog"
             @input="updateVal($event.target.value)"
             @change="changeVal($event.target.value)" />
      <div class="formTitleName pa"
           style="top:14px">
        <div v-if="!vHasData && isAddBut"
             class="mendian_input_data_left no_data fl"
             style="padding-left:12px;pointer-events:auto"
             @click.stop="doFormTitleNameClick(formName)"> {{'未设置'}}
        </div>
      </div>
      <div class="formTitleName pa">
        <div v-if="vHasData && formName"
             class="fl"
             @click.stop="doFormTitleNameClick(formName)">{{vformTitleName}}
          <span :class="vHasModulePower(ModulePowerKey)?'':'norole'">{{vdataInfoListLength}}</span>
        </div>
      </div>
    </div>
    <div v-else
         class="input_readonly form_info_value">{{value?value:""}}</div>
  </div>
</template>
<script>
export default {
  name: 'formDialog',
  data () {
    return {
      isShowOpt: true
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    formName: {
      type: String,
      default: ''
    }, // 标题名ID，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    disabled: Boolean, // 是否禁止修改，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    isNumber: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    customPlaceholder: {
      type: Boolean,
      default: false
    },
    isSwitchShowBox: {
      type: Boolean,
      default: true
    },
    dataInfoList: Array,
    ModulePowerKey: [String, Number, Boolean],
    isAddBut: {
      type: Boolean,
      default: true
    }
  },
  components: {

  },
  created () {

  },
  computed: {
    vdataInfoListLength () {
      const list = [];
      this.dataInfoList.forEach(o => {
        if (o.dataKey && o.isActive) {
          list.push(o);
        }
      });
      return '(' + list.length + ')';
    },
    vdataInfoStopListLength () {
      const list = [];
      this.dataInfoList.forEach(o => {
        if (o.dataKey && !o.isActive) {
          list.push(o);
        }
      });
      return list.length;
    },
    vHasData () {
      return this.dataInfoList.length > 0;
    },
    vPlaceholder () {
      let str = '';
      if (!this.customPlaceholder) {
        if (this.required) {
          if (this.isNumber) {
            str = '必填';
          } else {
            str = '必选';
          }
        } else {
          if (this.isNumber) {
            str = '选填';
          } else {
            str = '可选';
          }
        }
      } else {
        str = this.formPlaceholder;
      }
      return str;
    },
    vformTitleName () {
      console.log(this.formName, 'formName');
      if (this.formName == 'DRXS') { // 导入学生
        return '已录入';
      } else if (this.formName == 'JKPK') { // 建课排课
        return '已建立';
      } else if (this.formName == 'JZZC') { // 家长注册
        return '已注册';
      } else {
        return '使用中';
      }
    }
  },
  watch: {
    isSwitchShowBox (n, c) {
      this.isShowOpt = n;
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    },
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    },
    // 点击
    doFormTitleNameClick (opreateType) {
      this.$emit('formTitleNameClick', opreateType);
    }
  }
};
</script>
<style>
.formTitleName {
  top: 20px;
  position: absolute;
  left: 92px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(51, 51, 51);
}
</style>
