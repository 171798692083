<template>
  <div>
    <div class="WX_setting_content_title">
      自动回复内容
    </div>
    <div class="WX_setting_content_textarea_box">
      <textarea class="WX_setting_content_textarea"
                v-model="dataInfo.content"></textarea>
      <div class="WX_setting_content_textarea_number"><span class="font_blue">{{contentLength}}</span>/<span>200</span></div>
    </div>
    <div class="WX_message_submit btn_hover_bg_blue"
         @click.stop="submit">
      提交
    </div>
  </div>
</template>
<script>
import { SetPayAttentionReply, GetPayAttentionReply } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        content: ''
      },
      isSubmit: false
    };
  },
  computed: {
    contentLength () {
      return this.dataInfo.content.length;
    }
  },
  created () {
    this.getAttentionReply();
  },
  props: {

  },
  methods: {
    // 获取关键词回复语
    getAttentionReply () {
      GetPayAttentionReply().then(result => {
        this.dataInfo = result.data || { content: '' };
        console.log(result.data, 'GetPayAttentionReply');
      }).catch(error => {
        console.log(error, 'errorGetPayAttentionReply');
      });
    },
    submit () {
      // if (!this.dataInfo.content) {
      //   layer.alert('请输入内容');
      //   return false;
      // } else
      if (this.contentLength > 200) {
        layer.alert('内容不能超过200字数');
      }
      if (this.isSubmit) {
        return false;
      }
      this.isSubmit = true;
      SetPayAttentionReply(this.dataInfo).then(result => {
        this.isSubmit = false;
        layer.alert('设置关注回复成功');
      }).catch(error => {
        this.isSubmit = false;
        console.log('ErrorSetPayAttentionReply', error);
      });
    }
  }
};
</script>
