<template>
  <div class="mendian_backstage_date form_info_select form_info_required">
    <div class="date_title"
         v-if="fromTitle">{{fromTitle}}</div>
    <div class="help_title">{{vNumberData}}</div>
    <div class="self_data">
      <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date ">
        <span>{{vStartTime}}&nbsp;至</span>
        <input-date class="mendian_backstage_date_input_date"
                    :formTitle="''"
                    ref="inputDate"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    @blurEvent="blurEvent('1')"
                    @focusEvent="focusEvent('1')"
                    @changeDate="changeStartDay"></input-date>
      </div>
      <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date ">
        <span class="">&nbsp;{{vEndTime}}</span>
        <input-date class="mendian_backstage_date_input_date"
                    :formTitle="''"
                    ref="inputDate2"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    @blurEvent="blurEvent('2')"
                    @focusEvent="focusEvent('2')"
                    @changeDate="changeEndDay"></input-date>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isDate01: false,
      isDate02: false,
      dataInfo: {
        startTime: '',
        endTime: ''
      }
    };
  },
  props: {
    fromTitle: String,
    searchObj: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: ''
        };
      }
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    numberData: {
      type: Number,
      default: 1
    }
  },
  computed: {
    vStartTime () {
      if (this.dataInfo.startTime) {
        return this.$utils.formatDateToLine(Date.new(this.dataInfo.startTime));
      } else {
        return '';
      }
    },
    vEndTime () {
      if (this.dataInfo.endTime) {
        return this.$utils.formatDateToLine(Date.new(this.dataInfo.endTime));
      } else {
        return '';
      }
    },
    vIsBorder () {
      if (this.isDate01 || this.isDate02) {
        return true;
      } else {
        return false;
      }
    },
    vNumberData () {
      if (this.dataInfo.endTime == '') {
        return '';
      }
      var beginDate = new Date(this.dataInfo.startTime);
      var endDate = new Date(this.dataInfo.endTime);
      var day = (endDate - beginDate) / (1000 * 60 * 60 * 24);
      return Number(day).toFixed(0) + '天';
    }
  },
  watch: {
    // 结束时间
    'searchObj.endTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.searchObj.startTime) {
          this.resetTime();
        }
      },
      deep: true
    },
    // 开始时间
    'searchObj.startTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.searchObj.endTime) {
          this.resetTime();
        }
      },
      deep: true
    }
  },
  mounted () {
    this.dataInfo = this.searchObj;
  },
  methods: {
    resetTime () {
      this.dataInfo.startTime = '';
      this.dataInfo.endTime = '';
      this.$refs.inputDate.resetDate();
      this.$refs.inputDate2.resetDate();
    },
    changeStartDay (date) {
      if (this.dataInfo.endTime && Date.new(this.dataInfo.endTime) < Date.new(date)) {
        this.$refs.inputDate.resetDate();
        layer.alert('开始时间不能大于结束时间');
      } else {
        this.dataInfo.startTime = date;
        this.$emit('changeDate', this.dataInfo);
      }
    },
    changeEndDay (date) {
      if (this.dataInfo.startTime && Date.new(this.dataInfo.startTime) > Date.new(date)) {
        layer.alert('结束时间不能早于开始时间');
      } else {
        this.dataInfo.endTime = date;
        this.$emit('changeDate', this.dataInfo);
      }
    },
    focusEvent (type) {
      if (type == '1') {
        this.isDate01 = true;
      } else {
        this.isDate02 = true;
      }
    },
    blurEvent (type) {
      if (type == '1') {
        this.isDate01 = false;
      } else {
        this.isDate02 = false;
      }
    }

  }
};
</script>
<style scoped>
.mendian_backstage_date {
  margin-right: 0px;
  border-bottom: 1px solid #ececec;
  line-height: 47px;
}

.mendian_backstage_date .el-input {
  width: 96px !important;
  color: #3498db;
}
.help_title {
  width: 64px;
  color: #999999;
}
.self_data {
  position: relative;
  top: 16px;
  left: 40px;
}
</style>