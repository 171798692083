<template>
  <!-- 新建优惠券 -->
  <div class="WKT_discount_coupon_popup_ul">
    <div class="WKT_discount_coupon_add" @click="doAddCouponClick({name:'特价券',key:3})">
      <div class="add_icon add_icon_01">
        特价券
      </div>
      <div class="add_btn">
        新建
      </div>
    </div>
    <div class="WKT_discount_coupon_add" @click="doAddCouponClick({name:'代金券',key:4})">
      <div class="add_icon add_icon_02">
        代金券 <span class="font_gray">限到店使用</span>
      </div>
      <div class="add_btn">
         新建
      </div>
    </div>
    <div class="WKT_discount_coupon_add" @click="doAddCouponClick({name:'满减券',key:2})">
      <div class="add_icon add_icon_03">
        满减券 <span class="font_gray">限到店使用</span>
      </div>
      <div class="add_btn">
         新建
      </div>
    </div>
  </div>
</template>
<script>
import couponItem from './coupon-item';
export default {
  components: {
    couponItem
  },
  data () {
    return {
      searchText: ''
    };
  },
  props: {
    couponList: Array,
    isShowbtn: {
      type: Boolean,
      default: true
    }
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doAddCouponClick (item) {
      this.$emit('doAddCouponClick', item);
    }
  }
};
</script>

