<template>
  <div class="all_workbench_left_ul">
    <div class="all_workbench_left_list button"
         @click="swtichTableType(1)"
         :class="{opt:tableType == 1}">
      <div class="list_title">待接收</div>
      <div class="list_number">{{dataInfo.ToBeReceiveCount}}</div>
    </div>
    <div class="all_workbench_left_list button"
         @click="swtichTableType(2)"
         :class="{opt:tableType == 2}">
      <div class="list_title">跟进中</div>
      <div class="list_number">{{dataInfo.FollowUpCount}}</div>
    </div>
    <div class="all_workbench_left_list button"
         @click="swtichTableType(3)"
         :class="{opt:tableType == 3}">
      <div class="list_title">已拒绝</div>
      <div class="list_number font_red">{{dataInfo.RefuseCount}}</div>
    </div>
    <div class="all_workbench_left_list button"
         @click="swtichTableType(4)"
         :class="{opt:tableType == 4}">
      <div class="list_title">超时接收</div>
      <div class="list_number font_red">{{dataInfo.ReceiveOvertimeCount}}</div>
    </div>
    <div class="all_workbench_left_list button"
         @click="swtichTableType(5)"
         :class="{opt:tableType == 5}">
      <div class="list_title">已签单</div>
      <div class="list_number">{{dataInfo.SignCount}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tableType: Number,
    dataInfo: Object
  },
  methods: {
    swtichTableType (type) {
      this.$emit('swtichTableType', type);
    }
  }
};
</script>
<style scoped>
.all_workbench_left_ul {
  width: 550px;
  height: 50px;
  border: 1px solid #ececec;
  border-radius: 4px;
  display: flex;
}

.all_workbench_left_list {
  flex: 1;
  text-align: center;
  position: relative;
}

.all_workbench_left_list .list_title {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-top: 5px;
}

.all_workbench_left_list .list_number {
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  font-weight: bold;
}

.all_workbench_left_list.opt {
  border-bottom: 3px solid #3498db;
}

.all_workbench_left_list.opt .list_title,
.all_workbench_left_list.opt .list_number {
  color: #3498db;
}

.all_workbench_left_list::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 10px;
  width: 1px;
  height: 30px;
  background: #e7e9e8;
}
</style>