<template>
  <div>
    <custom-dialog title="异常签到"
                   class="new_popup_box"
                   :visible.sync="abnormalSign"
                   :before-close="closeAll">
      <!-- 选择学生 -->
      <div class="abnormal_sign_popup"
           v-show="publicShow">
        <div class="sign_popup_user clearfix">
          <div class="sign_popup_user_img fl"
               v-show="abnormalInfo.FromTypeKey==1">
            <img :src="abnormalInfo.SignHeadImgName"
                 alt="">
          </div>
          <div class="sign_popup_user_info fl">
            <div class="user_name"
                 v-show="abnormalInfo.FromTypeKey==1">{{abnormalInfo.SignUserName}}</div>
            <div class="user_date">
              {{formTitle}}时间：{{vCreateTime}}
            </div>
          </div>
        </div>
        <div class="sign_popup_type"
             v-if="abnormalInfo.WarningTypeKey==2">
          多节可签到的课，请选择学生和签到的课：
        </div>
        <div class="sign_popup_type"
             v-if="abnormalInfo.WarningTypeKey==4">
          无关联学生，请选择学生和签到的课：
        </div>
        <div class="sign_popup_type"
             v-if="abnormalInfo.WarningTypeKey==3">
          无可签到的课，请选择学生和签到的课：
        </div>
        <div class="sign_popup_ul">
          <!-- 多节未签到课. -->
          <sign-course-list v-for="(courseItem,key) in abnormalInfo.ScheduleCourse"
                            :key="key"
                            :courseItem="courseItem"
                            @clickCourseItem="comfireMutiScheduleCourse">

          </sign-course-list>
          <!-- 未关联课程. -->
          <sign-student-list v-for="stuItem in vSignStudentList"
                             :key="stuItem.OLAPKey"
                             :stuItem="stuItem"
                             @showCourseList="showCourseList"></sign-student-list>
          <!-- <div class="sign_popup_list">
            <div class="list_student_info">
              <img src="../../../../public/image/deflut_studentPhoto_boy.png"
                  alt="">
              <span>小飞侠</span>
            </div>
            <div class="list_course_select fr">
              选择课程并签到
            </div>
          </div> -->
          <!-- 未关联学生 -->
          <div class="sign_popup_list"
               v-show="abnormalInfo.FromTypeKey==1">
            <div class="list_student_info"
                 @click.stop="showDialogForStudentList">
              <img src="../../../../public/image/add_icon_gray2.png"
                   alt="">
              <span>关联学生</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 学生列表选中后显示. -->
      <div class="abnormal_sign_popup"
           v-show="seletedStuShow">
        <div class="sign_popup_user clearfix">
          <div class="sign_popup_user_img fl">
            <img :src="abnormalInfo.SignHeadImgName"
                 alt="">
          </div>
          <div class="sign_popup_user_info fl">
            <div class="user_name">{{abnormalInfo.SignUserName}}</div>
            <div class="user_date">
              {{formTitle}}时间：{{vCreateTime}}
            </div>
          </div>
        </div>
        <div class="sign_popup_type">
          请选择学生和签到的课：
        </div>
        <div class="sign_popup_ul">

          <!-- 未关联课程. -->
          <sign-student-list v-for="stuItem in vSignStudentList"
                             :key="stuItem.OLAPKey"
                             :stuItem="stuItem"
                             @showCourseList="showCourseList"></sign-student-list>

        </div>
      </div>
      <!-- 课程列表. -->
      <div class="abnormal_sign_popup"
           v-show="courseListShow">
        <!-- 学生个人信息 -->
        <studentLable ref="studentLableBox"
                      class="content_marginRL single_shadow"
                      :isExternalAlter="true"
                      :studentID="relatedStuInfo.OLAPKey"
                      :showMenu="false">
        </studentLable>
        <div class="sign_popup_type">
          选择签到的课:
        </div>
        <div class="sign_popup_ul">

          <!-- 选择有效课程列表. -->
          <seleted-course-list v-for="courseItem in courseList"
                               :key="courseItem.OLAPKey"
                               :courseItem="courseItem"
                               @clickCourseItem="seletedCourseItem">
          </seleted-course-list>
          <div v-if="courseList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 选择课程 -->
      <!-- <div class="abnormal_sign_popup">
        <div class="abnormal_sign_course_type">
          选择签到的课：
        </div>
        <div class="abnormal_sign_course_ul">
          <div class="abnormal_sign_course_list">
            <div>
              舞蹈入门基础课
            </div>
            <div class="font_gray">
              08:00-09:00
            </div>
          </div>
        </div>
      </div> -->
      <div class="footer_btn_group form_footer_btn_group ">
        <div class="el-button btn_light_red btn_hover_white"
             v-if="abnormalInfo.FromTypeKey==1"
             @click.stop="goback">
          返回
        </div>
        <div class="el-button btn_light_warning"
             v-else
             @click.stop="comfireIgnore">
          忽略
        </div>
        <!-- btn_light_blue -->
        <!-- 进入页面时显示 -->
        <div class="el-button btn_light_warning"
             v-if="publicShow"
             @click.stop="comfireIgnore">
          忽略
        </div>
        <!-- 未关联学生显示. -->
        <!-- <div class="el-button btn_light_blue" v-if="seletedStuShow" @click.stop="unStuComfire">
          确定
        </div> -->
        <!-- 未选择课程显示. -->
        <div class="el-button btn_light_blue btn_hover_bg_blue"
             v-else
             @click.stop="signComfire">
          确认
        </div>
      </div>
    </custom-dialog>
    <custom-dialog title="学生列表"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowStudentList"
                   :before-close="closeDialogForStudentList">
      <div>
        <student-list :isShowSearchInput="true"
                      @selectedItem="doAfterSelectedStudent"
                      ref="studentListDialog"></student-list>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import { GetTheSignWarningDetail, DoSignInMutiScheduleCourse, DoCommonWarning, DoNoSignInScheduleCourse, GetExceptionTimeSignInCourse, doRelateStudent, IgnoreSignWarning, getStudentRelatedMsg } from '../../../API/workbench';
import signCourseList from './sign-course-list';
import signStudentList from './sign-student-list';
import studentList from '../../common/student-list';

import seletedCourseList from './seleted-course-list';
import studentLable from '../../common/student-lable';
export default {
  data () {
    return {
      abnormalSign: false,  // 异常签到入口
      abnormalInfo: '',   // 异常记录信息.
      isShowStudentList: false, // 显示学生列表
      relatedStuInfo: '', // 关联学生
      seletedCourseInfo: '', // 选中有效课程.
      publicShow: true,
      seletedStuShow: false,
      courseList: '',
      courseListShow: false,
      formTitle: '扫码',
      isSummit: false    // 正在提交中.
    };
  },
  props: ['callBackFunction'],
  components: {
    signCourseList, signStudentList, studentList, studentLable, seletedCourseList
  },
  computed: {
    vCreateTime () {
      if (this.abnormalInfo) {
        this.abnormalInfo.CreateTime = this.abnormalInfo.CreateTime || '';
        return this.abnormalInfo.CreateTime.substring(0, 16);
      }
    },
    // 学生列表.
    vSignStudentList () {
      let newArr = [];
      if (this.abnormalInfo.RelateStudent) {
        if (this.publicShow == true) {
          this.abnormalInfo.RelateStudent.forEach(o => {
            if (this.abnormalInfo.ScheduleCourse.length > 0) {
              let locaItem = this.abnormalInfo.ScheduleCourse.find(object => {
                return o.OLAPKey == object.StudentKey;
              });
              console.log(locaItem, 'locaItem');
              if (!locaItem) {
                newArr.push(o);
              }
            } else {
              newArr.push(o);
            }
          });
        } else {
          newArr.push(this.relatedStuInfo);
        }
      }
      return newArr;
    }
  },
  methods: {
    // 普通关闭时重置数据.
    closeAll () {
      this.seletedStuShow = false;
      this.courseListShow = false;
      this.abnormalSign = false;
      this.relatedStuInfo = ''; // 关联学生
      this.seletedCourseInfo = '';
      this.courseList = [];
    },
    // 关闭弹出层时重置数据.
    closeAbnormalSign () {
      // if (typeof this.callBackFunction == 'function') {
      //   this.callBackFunction(this.abnormalInfo.OLAPKey);
      // }
      this.$bus.emit('updateSignWrongling', this.abnormalInfo.OLAPKey);
      this.seletedStuShow = false;
      this.courseListShow = false;
      this.abnormalSign = false;
      this.relatedStuInfo = ''; // 关联学生
      this.seletedCourseInfo = '';
      this.courseList = [];
    },
    doShowPopupShowClick (data) {
      console.log('doShowPopupShowClick', data);
      if (data.FromTypeKey == 1) {
        this.formTitle = '扫码';
        this.abnormalSign = true;
        this.publicShow = true;
      } else {
        this.abnormalSign = true;
        this.publicShow = false;
        this.seletedStuShow = false;
        this.courseListShow = true;
        this.formTitle = '刷脸';
      }

      this.getSignWarningDetail(data);
    },
    getStudentInfo (OLAPKey, callBack) {
      getStudentRelatedMsg(OLAPKey).then(result => {
        this.relatedStuInfo = result.data;
        console.log('getStudentInfo', this.relatedStuInfo);
        if (callBack) {
          callBack();
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    getSignWarningDetail (data) {
      GetTheSignWarningDetail(data.OLAPKey).then(result => {
        this.abnormalInfo = result.data;
        if (this.abnormalInfo.ScheduleCourse.length > 0) {
          this.abnormalInfo.ScheduleCourse.sort((x, y) => {
            return Date.new(y.BeginClassTime.substring(0, 16)) - Date.new(x.BeginClassTime.substring(0, 16));
          });
        }
        if (data.FromTypeKey != 1) {    // 刷脸
          console.log(data, 'getSignWarningDetail');
          this.showCourseListForFace(data.SignStudentKey);
          // if (data.WarningTypeKey == 2) {       // 多节课签到
          //   let courseList = this.abnormalInfo.ScheduleCourse;
          //   for (var i = 0; i < courseList.length; i++) {
          //     if (courseList[i].StudentKey == data.SignStudentKey) {
          //       courseList.splice(courseList[i], 1);
          //     }
          //   }
          //   // StudentKey;
          // } else if (data.WarningTypeKey == 3) {      // 无课
          // let locaItem = this.abnormalInfo.RelateStudent.find(o => {
          //   return o.OLAPKey == data.SignStudentKey;
          // });
          // this.showCourseList(locaItem);
          // }
        }
        console.log(result.data, 'getSignWarningDetail');
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 刷脸触发
    showCourseListForFace (OLAPKey) {
      this.getStudentInfo(OLAPKey, (relatedStuInfo) => {
        this.publicShow = false;
        this.seletedStuShow = false;
        this.courseListShow = true;
        this.getStuSignCourse(this.relatedStuInfo.OLAPKey, this.abnormalInfo.OLAPKey);
      });
    },
    // 扫码触发 学生列表中几点选择课程.
    showCourseList (item) {
      this.relatedStuInfo = item;
      console.log('showCourseList', this.relatedStuInfo);
      if (this.relatedStuInfo.IsConfirmKey == 0) {
        layer.alert('该学生未验证，不可签到。');
        return false;
      } else if (this.relatedStuInfo.CustStatusKey == 0) {
        layer.alert('该学生非正式学生，不可签到，如需试课，请通过试课追销并签到。');
        return false;
      }
      this.publicShow = false;
      this.seletedStuShow = false;
      this.courseListShow = true;
      this.getStuSignCourse(this.relatedStuInfo.OLAPKey, this.abnormalInfo.OLAPKey);
    },
    // 显示学生列表
    showDialogForStudentList () {
      this.isShowStudentList = true;
    },
    // 关闭学生列表
    closeDialogForStudentList () {
      this.isShowStudentList = false;
    },
    // 选中关联学生
    doAfterSelectedStudent (item) {
      console.log(item, 'doAfterSelectedStudent');
      this.relatedStuInfo = item;
      this.isShowStudentList = false;
      this.publicShow = false;
      this.seletedStuShow = true;
    },
    // 选中有效课程记录高亮.
    seletedCourseItem (item) {
      this.seletedCourseInfo = item;
      this.courseList.forEach(o => {
        if (o.OLAPKey == this.seletedCourseInfo.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
    },
    // 根据学生ID及异常ID获取有效课程
    getStuSignCourse (StudentKey, WarningKey) {
      GetExceptionTimeSignInCourse(StudentKey, WarningKey).then(result => {
        result.data.forEach(o => {
          o.isActive = false;
        });
        this.courseList = result.data;
        console.log('courseList', result.data);
      }).catch(error => {
        console.log('courseList', error);
      });
    },
    // 关闭页面或者返回上一个操作
    goback () {
      console.log('返回 this.abnormalInfo.SignStudentKey', this.abnormalInfo.SignStudentKey);
      if (this.abnormalInfo.SignStudentKey > 0) {
        this.closeAll();
        return false;
      }
      let locaItem = this.abnormalInfo.RelateStudent.find(o => {  // 判断是否未关联学生
        return o.OLAPKey == this.relatedStuInfo.OLAPKey;
      });
      if (this.seletedStuShow) {
        this.publicShow = true;
        this.seletedStuShow = false;
      } else if (!locaItem && this.courseListShow == true) {
        this.seletedStuShow = true;
        this.courseListShow = false;
      } else if (this.courseListShow == true) {
        this.publicShow = true;
        this.courseListShow = false;
      } else {
        this.abnormalSign = false;
        this.seletedStuShow = false;
        this.courseListShow = false;
      }
    },
    // 提交的接口.
    comfireMutiScheduleCourse (item) {
      let signList = [];
      var pushObj = {
        StudentKey: item.StudentKey,
        ScheduleCourseKey: item.OLAPKey
      };
      signList.push(pushObj);
      console.log(item, this.abnormalInfo);
      if (this.abnormalInfo.WarningTypeKey == 2) {      // 处理多节课可签到
        this.mutiCourseSign(signList);
      } else if (this.abnormalInfo.WarningTypeKey == 3) {   // 处理无课可签到
        this.noCourseSign(signList);
      }
    },
    // 选择了课,
    signComfire () {
      if (this.isSummit) {
        return false;
      }
      this.isSummit = true;
      let locaItem = this.abnormalInfo.RelateStudent.find(o => {  // 判断是否未关联学生
        return o.OLAPKey == this.relatedStuInfo.OLAPKey;
      });
      if (!locaItem && !this.seletedCourseInfo) {
        let that = this;
        layer.confirm('当前没有选择课程,是否只关联该学生不签到.', {
          btn: [{
            name: '取消',
            callBack: function () {
              that.isSummit = false;
              layer.close();
            }
          }, {
            name: '确认',
            callBack: function () {
              that.doRelateStudentNoSign();
              layer.close();
            }
          }]
        });
      } else {
        // let noSignItem = this.abnormalInfo.ScheduleCourse.find(obj => {     // 从这个选中学生跟多节课对比
        //   return locaItem.OLAPKey == obj.StudentKey;
        // });
        if (!this.seletedCourseInfo) {
          layer.alert('请选择课程。');
          this.isSummit = false;
          return false;
        }
        let signList = [];
        var pushObj = {
          StudentKey: this.relatedStuInfo.OLAPKey,
          ScheduleCourseKey: this.seletedCourseInfo.OLAPKey
        };
        signList.push(pushObj);
        // if (this.abnormalInfo.WarningTypeKey == 4 || !locaItem) {
        this.doCommonSign(signList);
        // } else if (this.abnormalInfo.WarningTypeKey == 2 && locaItem) {
        // this.mutiCourseSign(signList);
        // } else {
        // this.noCourseSign(signList);
        // }
      }
    },
    // 关联学生不签到.
    doRelateStudentNoSign () {
      doRelateStudent(this.abnormalInfo.SignCustomerKey, this.abnormalInfo.SignOpenIDName, this.relatedStuInfo.OLAPKey).then(result => {
        console.log(result, 'doRelateStudentNoSign');
        layer.alert('关联学生成功');
        this.isSummit = false;
        this.closeAbnormalSign();
      }).catch(error => {
        this.isSummit = false;
        console.log(error, 'error');
        layer.alert(error.msg);
      });
    },
    // 多节课签到
    mutiCourseSign (signList) {
      DoSignInMutiScheduleCourse(this.abnormalInfo.OLAPKey, signList).then(result => {
        console.log(result, 'comfireMutiScheduleCourse');
        layer.alert('异常签到处理成功');
        // this.getSignWarningDetail(this.abnormalInfo.OLAPKey);
        this.closeAbnormalSign();
      }).catch(error => {
        console.log(error, 'error');
        layer.alert(error.msg);
      });
    },
    // 无签到课程签到
    noCourseSign (signList) {
      DoNoSignInScheduleCourse(this.abnormalInfo.OLAPKey, signList).then(result => {
        console.log(result, 'comfireMutiScheduleCourse');
        layer.alert('异常签到处理成功');
        // this.getSignWarningDetail(this.abnormalInfo.OLAPKey);
        this.closeAbnormalSign();
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 未关联学生通用签到
    doCommonSign (signList) {
      DoCommonWarning(this.abnormalInfo.OLAPKey, signList).then(result => {
        console.log(result, 'comfireMutiScheduleCourse');
        layer.alert('异常签到处理成功');
        this.isSummit = false;
        // this.getSignWarningDetail(this.abnormalInfo.OLAPKey);
        this.closeAbnormalSign();
      }).catch(error => {
        console.log(error, 'error');
        this.isSummit = false;
        layer.alert(error.msg);
      });
    },
    // 忽略
    comfireIgnore () {
      let that = this;
      console.log('this.isSummit', this.isSummit);
      // if (this.isSummit) {
      //   return false;
      // }
      this.isSummit = true;
      layer.confirm('是否忽略该异常签到记录?', {
        btn: [{
          name: '取消',
          callBack: function () {
            that.isSummit = false;
            console.log('this.isSummit222', that.isSummit);
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.ignoreWarning();
            layer.close();
          }
        }]
      });
    },
    ignoreWarning () {
      IgnoreSignWarning(this.abnormalInfo.OLAPKey).then(result => {
        layer.alert('忽略成功');
        this.isSummit = false;
        this.closeAbnormalSign();
      }).catch(error => {
        console.log(error, 'error');
        layer.alert(error.msg);
      });
    }

  }
};
</script>

