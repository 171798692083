<template>
  <div class="emoji_module meme_area">
    <div class="all_expressions"
         v-if="vRecentlyUsedMemeList.length>0">
      <span>最近使用</span>
    </div>
    <div class="emoji_module_img2"
         v-if="vRecentlyUsedMemeList.length>0">
      <meme-item v-for="(item,key) in vRecentlyUsedMemeList"
                 :key="key"
                 :item="item"
                 @seletedItem="seletedItemAfter"></meme-item>
    </div>
    <div class="all_expressions">
      <span>全部表情</span>
    </div>
    <div class="emoji_module_img">
      <!-- <div class="delete_emoji_module_img"
           @click.stop="deleteEmoji">
        <img src="../../../../../../public/image/delete_the_emoticon.png"
             mode="">
      </div> -->
      <meme-item v-for="(item,key) in vMemeList"
                 :key="key"
                 :item="item"
                 @seletedItem="seletedItemAfter"></meme-item>

    </div>
  </div>

</template>

<script>
import memeItem from './meme-item.vue';
export default {
  data () {
    return {

    };
  },
  props: {

  },
  created () {

  },
  components: {
    memeItem
  },
  computed: {
    vRecentlyUsedMemeList () {
      return this.$store.getters.recentlyUsedMemeList || [];
    },
    vMemeList () {
      let newArr = [];
      for (var i = 1001; i < 1106; i++) {
        let obj = {
          value: i,
          key: '{{' + i + '}}'
        };
        newArr.push(obj);
      }
      console.log('vMemeList', newArr);
      return newArr;
    }
  },
  methods: {
    seletedItemAfter (item) {
      this.$store.commit('addRecentlyUsedMemeList', item);
      this.$emit('seletedMemeItem', item);
      console.log('meme-list');
    },
    deleteEmoji () {
      this.$emit('deleteEmoji');
    },
    mousedown () {
      console.log('mousedown');
    }
  }
};
</script>

<style>
</style>
