<template>
  <!-- 授权管理 -->
  <div class="authorization_courseware_manage headerBoxCourseware">
    <!-- 头部 -->
    <prepare-lessons-head :titleList="vauthorizationHeadList"
                          @chgTitleList="chgTitleList"></prepare-lessons-head>
    <!-- courseware_content -->
    <div class="">
      <courseware-details :params="params"
                          :cwsSummList="cwsSummList"
                          :bigCoursewarePackageList="bigCoursewarePackageList"
                          @searchDate="searchDate"
                          @afterSuccessupData="getAuthorizationBigCoursewarePackageList"></courseware-details>
    </div>

    <div class="switchBox">
      <span>授权老师所有课件</span>
      <el-switch v-model="isSetModify"
                 @change="changeSwitch"></el-switch>

    </div>
    <div class="switchBox1">
      <span>设备申请自动审批</span>
      <el-switch v-model="isAutoAuth"
                 @change="changeAutoAuthSwitch"></el-switch>

    </div>
    <div class="switchBox2"
         v-if="isAutoAuth">
      <span>自动释放设备</span>
      <el-switch v-model="isAutoReplace"
                 @change="changeAutoReplace"></el-switch>

    </div>
  </div>
</template>
<script>
import prepareLessonsHead from '../../head-quarters/campus-prepare-lessons-examine/common/prepare-lessons-head';
import coursewareDetails from './courseware-details';
import { CWSModifySetting } from '../../../API/workbench';
import {
  GetDateRangeGrantCoursewareStat,
  GetAuthorizationGrantBigCoursewarePackageList,
  GetDateRangeSinceCoursewareStat,
  GetSinceBigCoursewarePackageList,
  ModifySetting
} from '../../../API/workbench';
// import dialogFactory from '../../common/dialog-factory/index';
export default {
  components: {
    prepareLessonsHead,
    coursewareDetails
  },
  data () {
    return {
      isSetModify: false,
      isAutoAuth: false,
      dataInfo: [],
      autoAuthDataInfo: [],
      autoReplaceDataInfo: [],
      authorizationHead: [
        { name: '三方课件', key: 1, isSelected: true, isShow: true },
        { name: '自研课件', key: 2, isSelected: false, isShow: true }
      ],
      cwsSummList: [
        { name: '已上课次', key: 1, type: 'count', value: 0, listClassName: 'cp', unit: '节课', numClass: 'font_blue' },
        { name: '上课时长', key: 2, type: 'count', value: 0, listClassName: 'cp', unit: '分钟', numClass: 'font_blue' },
        { name: '消耗流量', key: 3, type: 'count', value: 0, listClassName: 'cp', unit: 'G', numClass: 'font_blue' },
        // { name: '签到人次', key: 4, type: 'count', value: 0, listClassName: 'cp', unit: '人次', numClass: 'font_blue' },
        { name: '今天已上课次', key: 5, type: 'count', value: 0, listClassName: 'bottom_border_blue cp', unit: '节课', numClass: 'font_blue' },
        { name: '今天上课时长', key: 6, type: 'count', value: 0, listClassName: 'bottom_border_green cp', unit: '分钟', numClass: 'font_blue' }
      ],
      // 查询参数
      params: {
        StartTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        EndTime: this.$utils.getCurrentMonthLast(), //	字符串	必须		数据源：GET	结束时间
        TeacherKey: '', //	整型	可选		数据源：GET	老师key
        GradeKey: '', //	整型	可选		数据源：GET	班级key
        BigCoursewarePackageKey: '', //	整型	可选		数据源：GET	大课包key
        CoursewarePackageKey: '' //	整型	可选		数据源：GET	子课包key
      },
      bigCoursewarePackageList: [],
      isAutoReplace: false
    };
  },
  created () {
    // 没三方授权
    if (Number(this.vMendianInfo.IsAuthorizeCoursewareKey == 0)) {
      this.authorizationHead[0].isSelected = false;
      this.authorizationHead[0].isShow = false;
      this.authorizationHead[1].isSelected = true;
      this.authorizationHead[1].isShow = true;
    }
    if (Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)) {
      // 没开通自研
      this.authorizationHead[1].isSelected = false;
      this.authorizationHead[1].isShow = false;
    }
    this.getDateRangeCoursewareStat();// 按月
    this.getStartDateRangeCoursewareStat();// 按天
    this.getAuthorizationBigCoursewarePackageList();
    console.log(this.authorizationHead, 'this.authorizationHead')
    this.$store.commit('setAcademicMdCwType', this.authorizationHead[0].isSelected ? 1 : 2);
  },
  mounted () {
    setTimeout(() => {
      this.dataInfo = this.$utils.getSaaSClientSetInfo(71);
      this.autoAuthDataInfo = this.$utils.getSaaSClientSetInfo(75);
      this.autoReplaceDataInfo = this.$utils.getSaaSClientSetInfo(94);
      console.log(this.dataInfo, 'this.dataInfo');
      if (this.dataInfo.SetKey == '1') {
        this.isSetModify = true;
      } else {
        this.isSetModify = false;
      }
      if (this.autoAuthDataInfo.SetKey == '1') {
        this.isAutoAuth = true;
      } else {
        this.isAutoAuth = false;
      }
      if (this.autoReplaceDataInfo.SetKey == '1') {
        this.isAutoReplace = true;
      } else {
        this.isAutoReplace = false;
      }
    }, 500);
  },
  computed: {
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vauthorizationHeadList () {
      let newArr = []
      this.authorizationHead.forEach(o => {
        if (o.isShow) {
          newArr.push(o)
        }
      })
      return newArr
    },
    vDateRangeApiName () {
      if (this.authorizationHead[0].isSelected) { // 授权
        return GetDateRangeGrantCoursewareStat;
      } else if (this.authorizationHead[1].isSelected) { // 自研
        return GetDateRangeSinceCoursewareStat;
      }
    },
    vBigCoursewarePackageListApiName () {
      if (this.authorizationHead[0].isSelected) { // 授权
        return GetAuthorizationGrantBigCoursewarePackageList;
      } else if (this.authorizationHead[1].isSelected) { // 自研
        return GetSinceBigCoursewarePackageList;
      }
    }
  },
  methods: {
    // 当月
    getDateRangeCoursewareStat () {
      this.vDateRangeApiName(this.params).then(result => {
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.cwsSummList[0].value = this.setCount(result.data.CourseCount) || 0;// 已上课次
        this.cwsSummList[1].value = this.setCount(result.data.CourseTimeCount) || 0;// 上课时长
        this.cwsSummList[2].value = this.$utils.flowFormate(result.data.ConsumptionFlowCount, 'number') == '--' ? '--' : Math.abs(this.$utils.flowFormate(result.data.ConsumptionFlowCount, 'number')).toFixed(0);// 消耗流量
        this.cwsSummList[2].unit = this.$utils.flowFormate(result.data.ConsumptionFlowCount, 'unit');// 消耗流量
        // this.cwsSummList[3].value = this.setCount(result.data.ArrivedNumCount);// 签到人次
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 当天
    getStartDateRangeCoursewareStat () {
      let data = this.$utils.parseJson(this.params);
      data.StartTime = this.$utils.formatDateToLine(Date.new());
      data.EndTime = this.$utils.formatDateToLine(Date.new());
      this.vDateRangeApiName(data).then(result => {
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.cwsSummList[3].value = this.setCount(result.data.CourseCount);// 今日已上课次
        this.cwsSummList[4].value = this.setCount(result.data.CourseTimeCount);// 今日上课时长
      }, error => {
        layer.alert(error.msg);
      });
    },
    setCount (num) {
      if (Number(num) > 9999) {
        return this.$utils.mAmountType((Number(num) / 10000), 1) + '万';
      } else {
        return Number(num);
      }
    },
    // 大课包列表
    getAuthorizationBigCoursewarePackageList () {
      var DeviceCode = '';
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();
      }
      this.vBigCoursewarePackageListApiName(DeviceCode).then(result => {
        console.log(result.data, '获取授权大课包List');
        this.bigCoursewarePackageList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 日期
    searchDate (startDate, endDate) {
      this.params.StartTime = startDate;
      this.params.EndTime = endDate;
      this.getDateRangeCoursewareStat();// 按月
      this.getStartDateRangeCoursewareStat();// 按天
    },
    // 导航栏切换
    chgTitleList (item) {
      console.log(item, this.authorizationHead, '导航栏切换');
      this.$store.commit('setAcademicMdCwType', item.key);
      this.bigCoursewarePackageList = [];
      this.params.StartTime = this.$utils.getCurrentMonthFirst();
      this.params.EndTime = this.$utils.getCurrentMonthLast();
      this.getDateRangeCoursewareStat();// 按月
      this.getStartDateRangeCoursewareStat();// 按天
      this.getAuthorizationBigCoursewarePackageList();
    },
    changeSwitch () {
      console.log(this.isSetModify, 'this.isSetModify');
      const that = this;
      layer.confirm('确定' + (this.isSetModify ? '开启' : '关闭') + '授权老师所有课件开关吗？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isSetModify = !that.isSetModify;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isSetModify) {
                that.dataInfo.SetKey = '1';
              } else {
                that.dataInfo.SetKey = '0';
              }
              ModifySetting(
                that.dataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    changeAutoAuthSwitch () {
      console.log(this.isAutoAuth, 'this.isAutoAuth');
      const that = this;
      layer.confirm('确定' + (this.isAutoAuth ? '开启' : '关闭') + '设备申请自动审批开关吗？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isAutoAuth = !that.isAutoAuth;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isAutoAuth) {
                that.autoAuthDataInfo.SetKey = '1';
              } else {
                that.autoAuthDataInfo.SetKey = '0';
              }
              ModifySetting(
                that.autoAuthDataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    changeAutoReplace () {
      console.log(this.isAutoReplace, 'this.isAutoAuth');
      const that = this;
      layer.confirm('确定' + (this.isAutoReplace ? '开启' : '关闭') + '自动释放设备？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isAutoReplace = !that.isAutoReplace;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isAutoReplace) {
                that.autoReplaceDataInfo.SetKey = '1';
              } else {
                that.autoReplaceDataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.autoReplaceDataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>

<style>
.headerBoxCourseware {
  position: relative;
}
.switchBox {
  position: absolute;
  top: 20px;
  right: 20px;
}
.switchBox1 {
  position: absolute;
  top: 20px;
  right: 210px;
}
.switchBox2 {
  position: absolute;
  top: 17px;
  right: 395px;
}
.authorization_courseware_manage .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.authorization_courseware_manage .table_fixed_nav_list {
  margin: 0 20px;
  padding: 0px 2px;
}
.authorization_courseware_manage .course_statistics_title {
  padding: 0;
}
.authorization_courseware_manage .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.authorization_courseware_manage .table_select_box .el-select {
  width: 200px;
}
.authorization_courseware_manage
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.authorization_courseware_manage
  .cw_summarizing
  .top_content_list
  .list_content
  .list_num {
  text-overflow: clip;
}
</style>

