<template>
  <div style="width: 860px;margin: 20px;">
    <list class="summarizing_list"
          :key="tableKey"
          :tableData="vDataList"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="tableCourseColumns"
          :isSearchText="false"
          :isNeedInnerScroll="false"
          :defaultSort="defaultSort"
          :queryParams="searchObj"
          :showPagination="false"></list>
  </div>
</template>
<script>
import list from '../../../../../report/class-spend-statistics-analyze/components/table';
export default {
  data () {
    return {
      dataList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      searchObj: {
        pageIndex: 0,
        pageSize: 3,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '模块',
          prop: 'name',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0,
          className: '',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: 'URL',
          prop: 'url',
          minWidth: 450,
          sortable: false,
          align: 'left',
          isShow: true,
          className: '',
          type: 'text',
          decimalPlace: 0
        }
      ]
    };
  },
  components: {
    list
  },
  created () {
    console.log(this.$store.getters.SaaSClientInfo, 'SaaSClientInfo');
  },
  computed: {
    vMendianInfo () {
      console.log(this.$store.getters.SaaSClientInfo, 'this.$store.getters.SaaSClientInfo');
      return this.$store.getters.SaaSClientInfo;
    },
    vDataList () {
      this.dataList = [
        { name: '微网校', url: this.vMendianInfo.WktInfo.sec_website + '/?issysentry=1&targetRouterPath=/pages/index-view/index' },
        { name: '预约', url: this.vMendianInfo.WktInfo.sec_website + '/?issysentry=1&targetRouterPath=/pages/course-name/try-course/index' },
        { name: '个人中心', url: this.vMendianInfo.WktInfo.sec_website + '/?issysentry=1&targetRouterPath=/pages/student-center/index-view/index' }
      ];
      return this.dataList;
    }

  },
  methods: {
    getDataList () {

    }
  }
};
</script>