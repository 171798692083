<template>
	<div class="course_estimate_block" @click="toWorkDetails">
		<div class="course_details_plan_list">
			<div class="list_type type_green">
				作业
			</div>
			<div class="list_info">
				<div class="list_text">
					已布置：{{dataInfo.AssignCount}}人 {{dataInfo.StudentContent}}
				</div>
				<div class="list_text font_gray">
					未批改 {{dataInfo.UnCorrectCount}} 丨未提交 {{dataInfo.UnSubCount}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  data () {
    return {

    };
  },
	  props: {
	    dataInfo: Object
  },
	  methods: {
	    toWorkDetails () {
	      this.$emit('toWorkDetails');
    }
  }
	};
</script>

<style>

</style>
