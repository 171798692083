<template>
  <div class="massive_bg apply_payment_details_box">
    <div class="apply_payment_box_title">
      {{formTitle}} <span>{{vYear}}</span>
    </div>
    <div class="apply_payment_box_singular box_dot dot_green">
      异常处理<span>{{dataInfo.totalCount}}</span>次
    </div>
    <a @click.stop="showDetail"
       class="class_statistics_details_btn">查看详情</a>
  </div>
</template>
<script>
export default {
  data () {
    return {
    };
  },
  props: {
    formTitle: String,
    dataInfo: Object
  },
  computed: {
    vYear () {
      if (this.dataInfo) {
        return this.dataInfo.SetUpTime;
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>

