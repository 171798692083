<template>
  <div class="edit_msg">
    <div class="edit_msg_content">
      <div class="edit_msg_box">
        <input ref="editMsg"
               type="text"
               maxlength="500"
               class="edit_msg_input"
               v-model="ReplyContent"
               :placeholder="contentReply">
      </div>
      <div class="btn_hover_bg_blue send_msg_btn"
           :class="!ReplyContent?'stop_send_msg_btn':''"
           @click="replyEssayContent()">
        <span>发送</span>
      </div>
    </div>
  </div>
</template>
<script>

import {
  getNewsDynamicReply
} from '../../../API/workbench';
export default {
  data () {
    return {
      newReplyData: {},
      ReplyContent: '',
      byReplyDetail: '',
      isResize: false,
      foucuShow: true
    };
  },
  props: ['byReply', 'essayDetail'],
  created () {

  },
  mounted () {
  },
  watch: {
    'essayDetail.ID': {
      handler (n, o) {
        if (n !== o) {
          this.ReplyContent = '';
        }
      }
    }
  },
  computed: {
    contentReply () {
      if (this.byReply) { // 有被回复人信息时.
        if (this.byReply.FromID == this.$store.getters.signData.UserID) {
          if (!this.byReply.ForReplyID) {
            return '评论';
          } else {
            return '回复' + this.byReply.To;
          }
        } else {
          return '回复' + this.byReply.From;
        }
      } else {
        return '评论';
      }
    }
  },
  methods: {
    replyEssayContent () {
      // 回复短文或者回复别人
      if (this.ReplyContent) {
        let data = {
          ReplyContent: this.ReplyContent
        };
        let essayTypeID = this.byReply ? this.byReply.ID : 0;

        console.log(this.byReply, '为0表示对短文进行直接回复');
        getNewsDynamicReply(this.essayDetail.ID, essayTypeID, data).then(result => {
          this.ReplyContent = '';
          console.log(result.data, '回复雷人');
          this.$emit('replyCompleted');
        });
      }
      // this.$emit('replyCompleted', this.essayDetail.ID, 0, this.ReplyContent);
    }
  }

};
</script>

