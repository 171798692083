const basicData = {
  state: {
    courseNameList: null,
    gradeClassList: null,
    teacherList: null
  },
  getters: {
    courseNameList: state => {
      return state.courseNameList;
    },
    gradeClassList: state => {
      return state.gradeClassList;
    },
    teacherList: state => {
      return state.teacherList;
    }
  },
  mutations: {
    setCourseNameList: (state, obj) => {
      state.courseNameList = obj;
    },
    setGradeClassList: (state, obj) => {
      state.gradeClassList = obj;
    },
    setTeacherList: (state, obj) => {
      state.teacherList = obj;
    }
  }
};

export default basicData;
