<template>
  <div class="form_info_line"
       :class="{form_info_required:required}">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value"
         :class="readonly?'':'form_info_select'"
         id="cascader">
      <el-cascader v-model="selectedOptions"
                   ref="cascaderAddr"
                   :disabled="readonly"
                   :class="readonly?'input_readonly':className"
                   :placeholder="readonly?'':'请选择省市区'"
                   :options="options"
                   @active-item-change="getData"
                   @change="seletdItem"
                   :props="{ value:'TableID', label:'name', children:'cities' }">
      </el-cascader>
    </div>

  </div>
</template>

<script>
import { SelectProvince, SelectCityForProvince, SelectAreaForCity } from '../../API/workbench.js';
export default {
  data () {
    return {
      selectedOptions: [],
      options: [],
      adgadg: false
    };
  },
  props: {
    formTitle: String,
    required: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: 'input_blue'
    },
    selectedInfo: Array, // [1,2,3]
    readonly: {
      type: Boolean,
      default: false
    },
    helpDescription: String // 表单说明提示
  },
  created () {

  },
  mounted () {
    this.getData();
  },
  watch: {
    selectedInfo (c, o) {
      console.log(c, 'selectedInfo');
      if (c.length > 0) {
        setTimeout(() => {
          this.getNodes([c[0]], (data) => {
            this.getNodes([c[0], c[1]], (data) => {
              this.selectedOptions = c;
              console.log(data, 'selectedInfo-data');
            });
          });
        }, 150);
      }
    }
  },
  methods: {
    getData (val) {
      this.getNodes(val);
    },
    getNodes (val, callBack) {
      console.log(val, callBack);
      if (!val) {
        SelectProvince().then(result => {
          this.options = result.data.map((item, i) => {
            item.name = item.name.replace('市', '');
            return {
              TableID: item.TableID,
              name: item.name,
              cities: []
            };
          });
        });
      } else if (val.length === 1) {
        this.options.map((value, i) => {
          if (value.TableID === val[0]) {
            SelectCityForProvince(val[0]).then(result => {
              value.cities = result.data.map((value, i) => {
                return {
                  TableID: value.TableID,
                  name: value.name,
                  cities: []
                };
              });
              if (callBack) {
                callBack();
              }
            });
          }
        });
      } else if (val.length === 2) {
        this.options.map((value, i) => {
          if (value.TableID === val[0]) {
            value.cities.map((itec, i) => {
              if (itec.TableID === val[1]) {
                SelectAreaForCity(val[1]).then(result => {
                  // console.log('县', result.data);
                  this.adgadg = true;
                  if (result.data.length > 0) {
                    itec.cities = result.data.map((every, i) => {
                      return {
                        TableID: every.TableID,
                        name: every.name
                      };
                    });
                  }
                  if (callBack) {
                    callBack();
                  }
                });
              }
            });
          }
        });
      }
      // if (this.$refs.cascaderAddr) {
      //     console.log('sfhfyjsrgfjk-33', this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels);
      //   this.$emit('selectedItem', this.selectedOptions, this.$refs.cascaderAddr.currentLabels);
      // }
    },
    seletdItem (item) {
      console.log('seletdItem', item, this.$refs.cascaderAddr.getCheckedNodes()[0]);
      if (this.$refs.cascaderAddr) {
        this.$emit('selectedItem', this.selectedOptions, this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels);
      }
    }
  }
};
</script>
<style>
#cascader {
  height: 30px;
}
#cascader .el-cascader {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 26px;
  text-overflow: clip !important
}
#cascader .el-cascader .el-input {
   width: 300px;
}
#cascader .el-cascader .el-cascader__label {
  text-align: right;
}
.el-icon-arrow-right.el-cascader-node__postfix {
  right: 20px;
}
#cascader .el-cascader .el-input__suffix-inner {
  position: absolute;
  right: -4px;
  bottom: 2px;
  display: none;
}
/* #cascader .el-cascader .el-cascader .el-icon-arrow-down {
  font-size: 16px;
} */
/* 滚动条的宽度 */
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.input_readonly input {
  color: #999 !important;
}
.input_readonly .el-input.is-disabled .el-input__inner {
  background: none;
}

</style>
