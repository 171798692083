<template>
  <div class="thirdparty_tencent_QRcode_box share_QR_banner">
    <div class="thirdparty_tencent_QRcode_title">
      <span>
        <div class="title_icon"></div>
        <span>恭喜您！授权成功，二维码已更新。</span>
      </span>
    </div>
    <div class="thirdparty_tencent_QRcode">
      <img :src="QRCodeImg"/>
    </div>
    <div class="QR_ul">
      <div class="QR_list">小贴士：</div>
      <div class="QR_list"><span>①</span> 扫码关注独立公众号</div>
      <div class="QR_list"><span>②</span>【门店设置→微信设置】可下载最新二维码，分享家长关注公众号</div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    QRCodeImg:String
  }
};
</script>