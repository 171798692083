<template>
  <div class="WKT_activity_drawer index_view_drawer"
       :class="isOpen?'open':''"
       @click.stop="changeIsOpen">
    <div class="drawer_open_btn">
      <div class="open_btn_instructions">
        活动玩法
      </div>
    </div>
    <div class="WKT_activity_drawer_box">
      <div class="activity_drawer_title">
        活动玩法
      </div>
      <div class="activity_drawer_content">
        <play-method-list v-for="(item,key) in vDataList"
                          :key="key"
                          :item="item"
                          :coverInfo="coverInfo"
                          @seletedItem="seletedItemAfter"
                          @showMoreItem="showMoreItemAfter">

        </play-method-list>

      </div>
    </div>
  </div>
</template>
<script>
import playMethodList from './component/play-method-list';
import { SelectEnbleActivitiesPlay } from '../../API/workbench.js';
export default {
  data () {
    return {
      isOpen: false,
      dataList: [],
      offlinePayment: {
        title: '线上0元引流，线下付款报名',
        key: 1,
        isActive: false,
        dataList: []
      },
      allPayment: {
        title: '线上付定金，到店付尾款，用定金分销返现赋能裂变',
        key: 2,
        isActive: false,
        dataList: []
      },
      onlinePayment: {
        title: '线上全款报名，分销返现赋能裂变',
        key: 3,
        isActive: false,
        dataList: []
      }

    };
  },
  props: {
    coverInfo: Object
  },
  components: {
    playMethodList
  },
  created () {
    this.getDataList();
  },
  computed: {
    vDataList () {
      let newArr = [];

      if (this.offlinePayment.dataList.length > 0) {
        newArr.push(this.offlinePayment);
      }
      if (this.allPayment.dataList.length > 0) {
        newArr.push(this.allPayment);
      }
      if (this.onlinePayment.dataList.length > 0) {
        newArr.push(this.onlinePayment);
      }
      return newArr;
    }
  },
  methods: {
    changeIsOpen () {
      this.isOpen = !this.isOpen;
      this.$nextTick(() => {
        if (this.isOpen) {
          this.$store.commit('SetPopupShade', true);
          this.$store.commit('SetPopupMaxZIndex', 18);
        } else {
          this.$store.commit('SetPopupShade', false);
        }
      });
      if (!this.isOpen) {
        this.initDataListIsActive();
      }
    },
    getDataList () {
      SelectEnbleActivitiesPlay().then(result => {
        this.dataList = result.data;
        this.dataList.forEach(o => {
          if (o.type == 1) {
            this.offlinePayment.dataList.push(o);
          } else if (o.type == 2) {
            this.allPayment.dataList.push(o);
          } else {
            this.onlinePayment.dataList.push(o);
          }
        });
        console.log('SelectEnbleActivitiesPlay', result.data);
      });
    },

    seletedItemAfter (item, activity) {
      // 3.活动招生秀-》玩法选择：需开通支付，才可选择 需要线上付款的两大类。如果没有开通支付，点击时提示：未开通支付功能，请联系专属客户开通支付功能。
      if (activity.key != 1 && Number(this.$store.getters.SaaSClientInfo.WktInfo.IsOpenPay) == 0) {
        layer.alert('未开通支付功能，请联系专属客服开通支付功能。');
        return;
      }
      this.$emit('seletedItem', item);
      this.isOpen = false;
      this.initDataListIsActive();
    },
    showMoreItemAfter (item) {
      this.vDataList.forEach(o => {
        if (item.key == o.key) {
          o.isActive = !o.isActive;
        }
      });
      console.log(this.vDataList, 'showMoreItemAfter');
    },
    initDataListIsActive () {
      this.vDataList.forEach(o => {
        o.isActive = false;
      });
    }
  }

};
</script>

