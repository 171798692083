var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.coursewareInfo.MainDemoName)?_c('div',{staticClass:"right_title"},[_c('div',{staticClass:"flex_row"},[_c('div',{staticClass:"title_name"},[_vm._v(" "+_vm._s(_vm.coursewareInfo.MainDemoName)+" ")]),(_vm.publishLogInfo.PublishTime)?_c('div',{staticStyle:{"margin-right":"20px"}},[_c('span',{staticClass:"font_gray"},[_vm._v("最新发布:"+_vm._s(_vm.publishLogInfo.PublishTime)+" "+_vm._s(_vm.publishLogInfo.OperationKeyValue))]),(_vm.publishLogInfo.PublishTime)?_c('span',{class:_vm.vPublishStatus},[_vm._v(_vm._s(_vm.vshowPublishBtn))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flex_row"},[_c('div',{staticClass:"flex_row"},[_c('span',{staticClass:"header_option_box_item"},[_vm._v(" 全部课件 "),_c('span',{staticClass:"font_black"},[_vm._v(_vm._s(Number(_vm.coursewareInfo.CoursewareNum) || 0)+"课次")])]),_c('span',{staticClass:"header_option_box_item"},[_vm._v(" 占用空间 "),_c('span',{staticClass:"font_black"},[_vm._v(_vm._s(this.$utils.capacityFormate( Number(_vm.coursewareInfo.OccupiedCapacity) || 0 )))])]),(
            _vm.coursewareInfo.isPublishInKey == 1 && _vm.coursewareInfo.TypeKey == 0
          )?_c('span',{staticClass:"status_type_orange"},[_vm._v("待审批")]):(
            _vm.coursewareInfo.isPublishInKey == 0 &&
            _vm.coursewareInfo.PublishStatusKey == 1 &&
            _vm.coursewareInfo.IterationPublishStatusKey == 0 &&
            _vm.coursewareInfo.TypeKey == 0
          )?_c('span',{staticClass:"status_type_yellow"},[_vm._v("待发布")]):(
            _vm.coursewareInfo.isPublishInKey == 0 &&
            _vm.coursewareInfo.PublishStatusKey == 0 &&
            _vm.coursewareInfo.TypeKey == 0
          )?_c('span',{staticClass:"status_type_red"},[_vm._v("未发布")]):_vm._e()]),_c('div',{staticClass:"flex_row"},[(_vm.coursewareLength>0)?_c('div',{staticClass:"btn_hover_bg_blue title_btn button",class:_vm.publishLogInfo.PublishStatus==0?'norole':'',on:{"click":_vm.release}},[_vm._v(_vm._s(_vm.vPublishBtn)+" ")]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }