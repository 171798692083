<template>
  <div class="student_file_from_type">
    <div class="form_header_red pr"
         @click.stop="debtCourseHourseDetailsEvent"
         v-if="Number(studentInfo.ClassHourDebtCount)>0">
      <div class="date_left">
        欠课时
      </div>
      <div class="date_right"
           style="padding-right: 10px;">
        <span class="">{{Number(studentInfo.ClassHourDebtCount)}}</span>
      </div>
      <div class="right_btn_02"></div>
    </div>
    <div class="form_header_red pr"
         style="border-top: 1px solid #e3e0e0;"
         @click.stop="showPopup"
         v-if="Number(studentInfo.DebtAmount)>0">
      <div class="date_left">
        欠款
      </div>
      <div class="date_right"
           style="padding-right: 10px;">
        <span class="">{{Number(studentInfo.DebtAmount)}}</span>
      </div>
      <div class="right_btn_02"></div>
      <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                            :dropDownList="popUpList"
                            :positionLeft="positionLeft"
                            :positionTop="positionTop"
                            @onClick="doAfterClick"></pop-up-drop-down-box>
    </div>
    <div class="form_header_gray">
      <div class="form_header_text">
        个人信息
      </div>
      <div class="edit_student_file_btn"
           @click="editStudentFile">修改</div>
    </div>
    <div class="form_info_box">
      <form-text :formTitle="'学生名'"
                 v-model="studentInfo.MainDemoName"></form-text>
      <form-text :formTitle="'性别'"
                 :isSlot="true">
        <span class="sex_icon"
              :class="{boy_icon:studentInfo.SexKey == 2,girl_icon:studentInfo.SexKey != 2}"></span>
        <span>{{studentInfo.SexKeyValue}}</span>
      </form-text>
      <form-text :formTitle="'手机号'"
                 :value="$utils.phoneModulePower(studentInfo.MobileSupport)"></form-text>
      <form-text :formTitle="'关系'"
                 v-model="studentInfo.CustomerRelationshipKeyValue"
                 :className="'no_border_bottom'"></form-text>
    </div>
    <div class="form_header_gray">
      <div class="form_header_text">
        销售价值
      </div>
    </div>
    <div class="form_info_box">
      <form-text :formTitle="'报名意向'"
                 v-model="studentInfo.WillingnessRateKeyValue"></form-text>
      <form-text :formTitle="'感兴趣的课'"
                 v-model="studentInfo.FineSupport"></form-text>
      <form-text :formTitle="'跟销顾问'"
                 v-model="studentInfo.AdviserKeyValue"
                 :className="'no_border_bottom'"></form-text>

    </div>
    <div class="form_header_gray">
      <div class="form_header_text">
        完善信息
      </div>
    </div>
    <div class="form_info_box no_border_bottom">
      <form-text :formTitle="'出生日期'"
                 v-model="studentInfo.BirthdayTime"></form-text>
      <form-text :formTitle="'年龄'"
                 v-model="studentInfo.AgeName"></form-text>
      <form-text :formTitle="'住宅小区'"
                 v-model="studentInfo.VillageKeyValue"></form-text>
      <form-text :formTitle="'就读学校'"
                 v-model="studentInfo.SchoolName"></form-text>
      <form-text :formTitle="'备注事项'"
                 v-model="studentInfo.RemarksSupport"
                 :className="'no_border_bottom'"></form-text>
    </div>
    <div class="form_header_gray">
      <div class="form_header_text">电子合同</div>
    </div>
    <div class="form_info_box no_border_bottom"
         style="cursor: pointer;"
         @click="doAfterContract">
      <form-text :formTitle="'电子合同'"
                 :titleClassName="'fromLeft'"
                 :readonly="true"
                 :readonlyClassName="vHasModulePower?'font_blue font_underline_blue':'font_gray'"
                 :value="studentInfo.ContractNum+'份'"></form-text>
    </div>
    <div>
      <debt-amount-detail ref="debtAmountDetail"
                          :studentItem="studentInfo"
                          :isShowDebtAmountDetails="false"
                          @closeDebtAmountDetail="closeDebtAmountDetail"
                          @backAmountSuccess="doAfterBackAmountSuccess">
      </debt-amount-detail>
    </div>
    <custom-dialog :title="'电子合同'"
                   :visible.sync="isShowElectronicContractPopup"
                   :before-close="closeElectronicContractPopup">
      <contract :StudentKey="Number(studentInfo.OLAPKey)"
                @upContractCount="upContractCount"></contract>
    </custom-dialog>
  </div>
</template>

<script>
import formText from './form-text';
import contract from './contract/index';
import debtAmountDetail from '../../../../common/debt-amount-detail';
import popUpDropDownBox from '../../../../common/pop-up-drop-down-box';
export default {
  components: {
    formText,
    contract,
    popUpDropDownBox,
    debtAmountDetail
  },
  data () {
    return {
      isShowElectronicContractPopup: false,
      isShowAlertDialog: false,
      popUpList: [
        // { name: '还款', type: 'payAndWithdraw', isShow: true, ModulePowerKey: 42 },
        { name: '销账', type: 'WriteOff', isShow: true },
        { name: '欠还款详情', type: 'debtDetail', isShow: true }
      ],
      positionLeft: 0,
      positionTop: 0
    };
  },
  props: {
    studentInfo: Object
  },
  created () {
    console.log(this.studentInfo, 'doAfterContract');
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlertDialog = false;
      }
    });
  },
  computed: {
    vSexKey () {
      return this.studentInfo.SexKey == 2 ? '♂' : '♀';
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vHasModulePower () {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        48
      );
    }
  },
  methods: {
    // 客户还款 成功后
    doAfterBackAmountSuccess () {
      this.$emit('updateStudentFile');
    },
    // 客户还款  关闭
    closeDebtAmountDetail () {
      this.isShowDebtAmountDetails = false;
    },
    showPopup (e) {
      this.positionLeft = 260;
      this.positionTop = 32;
      this.isShowAlertDialog = true;
    },
    doAfterClick (item) {
      switch (item.type) {
        case 'payAndWithdraw':
          this.debtDetailsEvent();
          break;
        case 'WriteOff':
          this.WriteOffEvent();
          break;
        case 'debtDetail':
          this.$refs.debtAmountDetail.isShowDebtDetails = true;
        default:
          break;
      }
      this.isShowAlertDialog = false;
    },
    // 销账按钮
    WriteOffEvent () {
      this.isShowAlertDialog = false;
      this.$dialog.open(this, {
        name: '欠款销账',
        routerName: this.$route.name,
        moduleName: 'writeoffarrears',
        data: { dataInfo: this.studentInfo },
        callBack: { afterSucces: () => { this.$emit('updateStudentFile'); } }
      });
    },
    // 欠课课时详情事件
    debtDetailsEvent () {
      // this.isShowDebtCourseHoursDetails = true;
      this.$dialog.open(this, {
        name: '客户还款',
        routerName: this.$route.name,
        moduleName: 'paymentrepayment',
        data: { dataInfo: this.studentInfo },
        callBack: {
          backClassHourSuccess: (studentID) => { this.$emit('updateStudentFile'); }
        }
      });
    },
    // 欠课课时详情事件
    debtCourseHourseDetailsEvent () {
      // this.isShowDebtCourseHoursDetails = true;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'debtCourseHourseDetails',
        data: { studentItem: this.studentInfo },
        callBack: {
          backClassHourSuccess: (studentID) => { this.$emit('updateStudentFile'); }
        }
      });
    },
    // 查看个人资料
    editStudentFile () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: 48,
        name: '个人资料',
        moduleName: 'registerCustomerDetails',
        data: { dataInfo: { StudentKey: this.studentInfo.OLAPKey, Type: 'view' } },
        callBack: {
          doafterRegisterCustomer: (item) => { this.showEditStudentInfoDetails(); },
          AfterStudentDelete: () => {
            setTimeout(() => {
              this.$parent.$parent.$parent.closeDialog('studentFile');
            }, 200);
          }
        }
      });
    },
    // 修改个人资料
    showEditStudentInfoDetails () {
      this.$nextTick(() => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: 48,
          name: '修改个人资料',
          moduleName: 'registerCustomerDetails',
          data: { dataInfo: { StudentKey: this.studentInfo.OLAPKey, Type: 'submit' } }
        });
      });
    },
    // 打开电子合同列表
    doAfterContract () {
      // if(this.vHasModulePower){
      this.isShowElectronicContractPopup = true;
      // }else{
      //   this.$utils.CheckModulePowerToTips(48);
      // }
    },
    // 关闭电子合同列表
    closeElectronicContractPopup () {
      this.isShowElectronicContractPopup = false;
    },
    // 更新电子合同数
    upContractCount (contractlist) {
      this.studentInfo.ContractNum = contractlist.length;
    }
  }
};
</script>

<style scoped>
.student_file_from_type {
  padding-left: 5px;
}
.student_file_from_type .form_header_gray {
  background-color: #fff;
  color: #333;
  font-size: 16px;
  padding-top: 5px;
  height: 40px;
  margin: 0;
}
.student_file_from_type .form_info_box {
  padding: 0 0 10px;
  margin: 0 15px;
  border-bottom: 1px solid #ececec;
}
.edit_student_file_btn {
  width: 45px;
  height: 25px;
  border-radius: 4px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  border: 1px solid #ff6c67;
  background-color: #ff6c67;
  color: #fff !important;
  cursor: pointer;
}
.edit_student_file_btn:hover {
  border: 1px solid #ffd3d1;
  background-color: #ffd3d1;
  color: #fff;
}
.right_btn {
  width: 20px;
  height: 15px;
  background: url(../../../../../../public/image/next_icon_red_02.png) no-repeat
    100%;
  background-size: 12px;
  top: 10px;
  cursor: pointer;
  position: absolute;
  right: 3px;
  display: inline-block;
  vertical-align: top;
  bottom: 0;
  right: 10px;
  /* background-color: #fff;
  box-shadow: 0px 0px 10px 6px #fff; */
  z-index: 10;
}
</style>
