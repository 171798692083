<template>
  <!-- 历史记录 -->
  <div class="batchEnter_student_history_box">
    <div class="batchEnter_student_history_top">
      <div class="fl">
        <input-search-contain-btn v-model="parameter.searchText"
                                  :width="'280px'"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearText"
                                  @onSearchEvent="searchName"></input-search-contain-btn>
      </div>
      <div class="fr">
        <div class="el-button btn_light_blue btn_hover_bg_blue"
             @click="closeSwitchoverShow">
          批量录入老生
        </div>
        <div class="el-button btn_light_blue btn_hover_bg_blue"
             @click="doExportInfoClick">
          导出
        </div>
      </div>
    </div>

    <div class="batchEnter_student_table">
      <!-- 标题 -->
      <div class="table_theader_box">
        <div class="table_theader">
          <title-box v-for="(item,key,index) in titleInfo"
                     :titleData="item"
                     :key="index"
                     @onCilckEvent="afterCilckTitleInfo"></title-box>
        </div>
      </div>
      <!-- 内容 -->
      <div class="table_tbody_box"
           @scroll="scrollTOP">
        <div class="table_tbody"
             :style="contentList.length>0?'border-left: 1px solid #e7e9e8':'border-left: none;'">
          <content-box v-for="(item,key,index) in contentList"
                       :contentItem="item"
                       :key="index"></content-box>
        </div>
        <div v-if="!parameter.loading&&!parameter.noMore"
             class="students_loading all_list_bottom">
          <span class="el-icon-loading"></span>
          <span class="loading_style">加载中...</span>
        </div>
        <div v-else-if="contentList.length==0">
          <div class="monitoring_content_nodata"
               style="margin-top: 120px;">
            <div class="monitoring_content_nodata_txt2">暂无数据</div>
          </div>
        </div>
        <div v-else-if="contentList.length>0&&parameter.noMore"
             class="all_list_bottom">
          <span class="complete">已显示全部</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from './title-box';
import contentBox from './content-box';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import { MessageSearchPageHistoryForMakeUp } from '../../../../API/workbench';
export default {
  data () {
    return {
      titleInfo: [
        { title: '序号', Key: 'Number', opt: '序号', className: 'table_th table_index', styleName: 'width:55px' },
        { title: '提交时间', Key: 'SubmitTime', className: 'table_th', styleName: 'width:151px' },
        { title: '学生名', Key: 'StudentKeyValue', className: 'table_th', styleName: 'width:108px' },
        { title: '性别', Key: 'SexKeyValue', className: 'table_th', styleName: 'width:60px' },
        { title: '手机号', Key: 'CustomerPhoneName', className: 'table_th ', styleName: 'width:126px' },
        { title: '在读课程', Key: 'ApplyClassKeyValue', className: 'table_th ', styleName: 'width:159px' },
        { title: '购买日期', Key: 'PayTime', className: 'table_th ', styleName: 'width:122px' },
        { title: '失效日期', Key: 'ExpireTime', className: 'table_th ', styleName: 'width:122px' },
        { title: '购买金额', Key: 'ActualPayAmount', className: 'table_th ', styleName: 'width:69px' },
        { title: '购买课时', Key: 'NumCount', className: 'table_th ', styleName: 'width:74px' },
        { title: '剩余课时', Key: 'CurrentCount', className: 'table_th ', styleName: 'width:74px' },
        { title: '操作', className: 'table_th ', styleName: 'width:78px;padding-left: 25px' }
      ],
      ETarget: null
    };
  },
  props: {
    contentList: {
      type: Array,
      default: []
    },
    parameter: {
      type: Object,
      default: {}
    }

  },
  components: {
    titleBox,
    contentBox,
    inputSearchContainBtn
    // table
  },
  created () {

  },
  methods: {
    // 滚动加载事件.
    scrollTOP (e) {
      this.ETarget = e.target;
      let HeightView = this.ETarget.scrollHeight - this.ETarget.clientHeight;
      if (parseInt(this.ETarget.scrollTop)+20 >= parseInt(HeightView) && !this.parameter.noMore && !this.parameter.loading) {
        this.$emit('onCilckSearchName', this.parameter);
        console.log(this.noMore, 'this.noMore');
      }
    },
    // 标题操作项
    afterCilckTitleInfo (titleData) {
      console.log(titleData, 'titleData');
      // this.$emit('onCilckEvent', titleData);
    },
    clearSearText () {
      this.parameter.searchText = '';
      this.searchName();
    },
    // 搜索
    searchName () {
      // console.log(this.ETarget, 'this.noMore');
      if (this.ETarget && this.ETarget.scrollTop) {
        this.ETarget.scrollTop = 0;
      }
      this.parameter.pageIndex = 0;
      this.parameter.noMore = false;
      this.$emit('onCilckSearchName', this.parameter);
    },
    // 切换
    closeSwitchoverShow () {
      this.parameter.pageIndex = 0;
      this.$emit('closeSwitchoverShow');
    },
    // 导出
    doExportInfoClick () {
      if (this.contentList.length > 0) {
        let newParameter = {
          IsExportKey: 1,
          pageIndex: 0, //	整型	必须	0	数据源：GET	 页码，0为第一页
          pageSize: 0, //	整型	必须	0	数据源：GET	  每页记录数，0：表示查询所有
          orderBy: 'SubmitTime', //	字符串	可选		数据源：GET	 排序字段
          sequence: '', //	字符串	可选		数据源：GET	 排序字段
          searchText: this.parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配学生姓名，首字母，手机号
        };
        console.log(newParameter, '导出');
        let jsonFormate = {
          SheetTitle: '老生导出模板',
          clist: [
            { title: '序号', cName: 'Number', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '提交时间', cName: 'SubmitTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '性别', cName: 'SexKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '在读课程', cName: 'ApplyClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买日期', cName: 'PayTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '失效日期', cName: 'ExpireTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买金额', cName: 'ActualPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买课时', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '剩余课时', cName: 'CurrentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchPageHistoryForMakeUp(newParameter, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    }
  }
};
</script>

<style>
</style>