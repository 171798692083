<template>
  <!-- 入库表单 -->
  <div>
    <div class="content_marginRL border_bottom">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">
        <input-date v-model="dataJson.GenerateTime"
                    :formTitle="TypeKey == 1?'入库日期':'出库日期'"
                    :readonly="false"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    :elementId="'BirthdayTime'"
                    :required="true"
                    :formPlaceholder="'必填'"></input-date>

        <input-dialog :formTitle="'经办人'"
                      :required="true"
                      :customPlaceholder="true"
                      :formPlaceholder="'必填'"
                      :readonly="false"
                      @showEldilog="doOperatorListClick"
                      v-model="dataJson.OperatorKeyValue"></input-dialog>

        <input-dialog :formTitle="'物品名称'"
                      :required="true"
                      :customPlaceholder="true"
                      :formPlaceholder="'必填'"
                      :readonly="false"
                      @showEldilog="doGoodsListClick"
                      v-model="dataJson.GoodsKeyValue"></input-dialog>
        <input-number formTitle="平均进货单价"
                      v-if="TypeKey != 1"
                      v-model="dataJson.AvgUnitPriceAmount"
                      :placeholder="'必填'"
                      :readonly="true"
                      :required="true"></input-number>

        <input-number :formTitle="TypeKey == 1?'进货单价':'出库单价'"
                      :min="0"
                      :max='999999999999'
                      v-model="dataJson.UnitPriceAmount"
                      :decimalPlaces="2"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="chgUnitPriceAmount"></input-number>

        <input-number :formTitle="TypeKey == 1?'入库数量':'出库数量'"
                      :min="0"
                      :max='999999999999'
                      v-model="dataJson.AdjustCount"
                      :formInputHint="vformInputHint"
                      :decimalPlaces="0"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="chgAdjustCount"></input-number>

        <input-number :formTitle="TypeKey == 1?'入库总价':'出库总价'"
                      :min="0"
                      :max='99999999'
                      v-model="dataJson.TotalAmount"
                      :decimalPlaces="2"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="chgTotalAmount"></input-number>

        <input-dialog :formTitle="'供应商'"
                      :required="false"
                      :customPlaceholder="true"
                      :formPlaceholder="'选填'"
                      :readonly="false"
                      @showEldilog="doSupplierListClick"
                      v-model="dataJson.SupplierKeyValue"></input-dialog>

        <input-text :formTitle="'备注'"
                    class="no_border_bottom"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="false"
                    v-model="dataJson.RemarkSupport"></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="true"
         style="margin-top: 80px;">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <!-- 选择物品表单-->
    <custom-dialog title="物品列表"
                   :visible.sync="isShowGoodsList"
                   :before-close="closeDialog">
      <goods-list :selectedKey="dataJson.GoodsKey"
                  :addModuleName="'goods-name-setting'"
                  @closeDialog="closeDialog"
                  @onSelectedItem="searchGoodsData"></goods-list>
    </custom-dialog>
  </div>

</template> 

<script>
import goodsList from './goods-list';
import { StockShipmentInventory, MessageSearchPageForEmployee, MessageSearchPageForSupplier } from '../../../../API/workbench';
export default {
  data () {
    return {
      StartTime: '',
      isShowGoodsList: false,
      newInputText: '0', // 默认值
      // 数据源
      dataJson: {
        GenerateTime: this.$utils.formatDateToLine(Date.new()), // 入库日期
        OperatorKey: '', // 经办人
        OperatorKeyValue: '',
        GoodsKey: '', // 物品
        GoodsKeyValue: '',
        UnitPriceAmount: '', // 出入库单价
        AdjustCount: '', // 出入库数量
        TotalAmount: '', // 入库总价
        SupplierKey: '', // 供应商
        SupplierKeyValue: '',
        AvgUnitPriceAmount: '',
        RemarkSupport: ''// 备注
      },
      currentInventoryCount: 0, // 当前库存数量
      // 查询员工
      staffParameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      },
      // 查询供应商
      gongyinshang: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      }
    };
  },
  components: {
    goodsList
  },
  computed: {
    // 当前库存
    vformInputHint () {
      if (Number(this.dataJson.GoodsKey) > 0) { // 选中物品
        return '当前库存 ' + Number(this.currentInventoryCount) || 0;// 物品数量
      } else {
        return '';
      }
    }
  },
  props: {
    TypeKey: Number, // 出入库类型1-入库;2-出库
    isReadonly: Boolean
  },
  created () {

  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  watch: {

  },
  methods: {
    // 经办人 MessageSearchPageForEmployee
    doOperatorListClick () {
      MessageSearchPageForEmployee(this.staffParameter).then(result => {
        console.log(result.data, '经办人');
        let list = result.data.PageDataList.filter(obj => { return Number(obj.IsLeaveKey) == 1; });
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择经办人',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.OperatorKey, dataSourceList: list, addModuleName: 'yuangong-setting', addmoduleTitleName: '经办人', required: true },
          callBack: {
            onSelectedItem: (data) => {
              this.dataJson.OperatorKey = data.OLAPKey;
              this.dataJson.OperatorKeyValue = data.MainDemoName;
              console.log(data, '经办人');
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 显示物品列表
    doGoodsListClick () {
      this.isShowGoodsList = true;
    },
    closeDialog () {
      this.isShowGoodsList = false;
    },
    searchGoodsData (data) {
      this.currentInventoryCount = data.CurrentInventoryCount || 0;
      console.log(data, "'当前库存 '+ 666 ");
      this.dataJson.GoodsKey = data.OLAPKey;
      this.dataJson.AvgUnitPriceAmount = data.AvgUnitPriceAmount;
      this.dataJson.GoodsKeyValue = data.MainDemoName;
      this.closeDialog();
    },
    // 供应商 MessageSearchPageForSupplier
    doSupplierListClick () {
      MessageSearchPageForSupplier(this.gongyinshang).then(result => {
        console.log(result.data, '供应商');
        let list = result.data.PageDataList.filter(obj => { return Number(obj.StatusKey) == 1; });
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择供应商',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.SupplierKey, dataSourceList: list, addModuleName: 'supplier-setting' },
          callBack: {
            onSelectedItem: (data) => {
              this.dataJson.SupplierKey = data.OLAPKey;
              this.dataJson.SupplierKeyValue = data.MainDemoName;
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 新增课程
    doAddCoursSuccessEvent (result) {
      console.log(result, '新增回调');
      // 接收选中值.
      var item = {};
      item.id = result.OLAPKey;
      item.label = result.MainDemoName;
      this.loadData();
    },
    // 新增
    doAddTeacherSuccessEvent (result) {
      console.log(result, '新增回调');
      // 接收选中值.
      this.selectInfo(result);
    },
    // 出入库单价(总价支持自动计算)
    chgUnitPriceAmount (count) {
      this.dataJson.UnitPriceAmount = count;
      this.dataJson.TotalAmount = this.$utils.mMoneyType(Number(this.dataJson.UnitPriceAmount) * Number(this.dataJson.AdjustCount), 2);
    },
    // 出入库数量(总价支持自动计算)
    chgAdjustCount (count) {
      console.log(count, 'newValue');
      this.dataJson.AdjustCount = count;
      this.dataJson.TotalAmount = this.$utils.mMoneyType(Number(this.dataJson.UnitPriceAmount) * Number(this.dataJson.AdjustCount), 2);
    },
    // 出入库总价(单价支持自动计算)
    chgTotalAmount (count) {
      this.dataJson.TotalAmount = count;
      this.dataJson.UnitPriceAmount = this.$utils.mMoneyType(Number(this.dataJson.TotalAmount) / Number(this.dataJson.AdjustCount), 2);
    },
    // 确认按钮
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      StockShipmentInventory(this.TypeKey, this.dataJson).then(result => {
        layer.alert(this.TypeKey == 1 ? '入库成功' : '出库成功');
        this.$emit('afterSuccessCallBack');
        this.cancelClick();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      console.log(this.TypeKey, '类型');
      let typeName = this.TypeKey == 1 ? '入库' : '出库';
      if (!this.dataJson.GenerateTime) {
        layer.alert(typeName + '日期不能为空');
        flag = true;
      } else if (!this.dataJson.OperatorKey) {
        layer.alert('经办人不能为空');
        flag = true;
      } else if (!this.dataJson.GoodsKey) {
        layer.alert('物品名称不能为空');
        flag = true;
      } else if (this.dataJson.UnitPriceAmount <= 0) {
        layer.alert((this.TypeKey == 1 ? '进货' : '出库') + '单价不能小于等于0');
        flag = true;
      } else if (this.dataJson.AdjustCount <= 0) {
        layer.alert(typeName + '数量不能小于等于0');
        flag = true;
      } else if (this.dataJson.TotalAmount <= 0) {
        layer.alert(typeName + '总价不能小于等于0');
        flag = true;
      } else if (this.TypeKey == 2 && Number(this.dataJson.AdjustCount) > Number(this.currentInventoryCount)) {
        layer.alert('出库数量不能大于当前库存');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>
