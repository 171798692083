/* eslint-disable no-alert */
import Vue from 'vue';

const bus = new Vue({
  data: {
    eventList: {},
    eventMapList: [
      'reLoadPage', // emit('reLoadPage',callback); // 重新加载系统,callback为销毁界面后执行逻辑
      'zxsendclientlog', // emit('zxsendclientlog',logtext); // 发送客户端日志,logtext为日志内容，由客户端发送过来
      'upgradedevicecode', // emit('upgradedevicecode'); // 请求升级设备授权码
      'courseTableSelected', // emit('eventName'); // 在课程表 和 timetable-list 插件用到，暂时没改过来？？？
      'updateCourse', // emit('eventName');  // 弹出修改课程.
      'clickShade', // 点击弹出层遮罩层  bus.emit(eventName);
      'needOpenRouter', // 打开路由 emit(eventName, {"routertitle": "工作台", "routerName": "indexView", "moduleName": "taoCanSettingList"});routerName：打开指定路由 moduleName：下的某个弹窗
      'openCustomDialog', // 打开自定义弹窗  bus.emit(eventName, {"routertitle": "工作台", "routerName": "indexView", "moduleName": "taoCanSettingList"});
      'openCommonCustomDialog', // 打开自定义多例弹窗  bus.emit(eventName, {"routertitle": "工作台", "routerName": "indexView", "moduleName": "taoCanSettingList"});
      'wsConnectSuceess', // 长连接连接成功 emit('eventName', data);
      'wsUpdateSaasClientInfo', // 更新門店信息 emit('eventName', mendianInfo);
      'wsTodoList', // 长连接通知 待办事项 emit('eventName', data);
      'wsAfterLoginSuceess', // 登录成功后 emit('eventName', data);
      'wsAttendanceOrArrange', // 长连接通知 今日签到 emit('eventName', data);
      'wsAfterSignWarning', // 签到异常 emit('eventName', data);
      'wsQuitBatchAddOldStudent', // 退出批量录入 emit('eventName', data);
      'wsAfterUpdateRole', // 更改角色后 emit('eventName', data);
      'wsAfterUpdateAgentInfo', // 更改当前登录用户信息后  emit(eventName, agentInfo); agentInfo:员工信息
      'AfterBatchAddOldStudent', // 批量录入学生成功后的回调
      'wsAfterOpenFace', // 开启刷脸签到.  emit('eventName', data);
      'AfterSignUpPaymentSuccess', // 报名收款新增 emit (eventName, TableID), TableID:报名收款新增后的ID
      'AfterStudentAdd', // 学生新增 emit (eventName, fromType, ID)，eg. fromType: new|old, ID:学生ID
      'AfterStudentUpdate', // 修改学生信息后
      'AfterStudentDelete', // 删除学生
      'IncomePayAdd', // 其它收支 新增 emit (eventName)
      'CourseClassNameAddOrEdit', // 课程课类=》 新增或修改，停用,启用 emit (eventName,type,data) , type:'Add新增 、Edit修改',unActive:停用,active:启用，data：新增或修改后返回的对象

      'doconfirmFromClick', // 新增套餐 修改单个课单 确定后联动问题 emit('eventName', data); ？？？无需用bug 属于从属父子组件的关系，应该改为this.$emit
      'updateCourseConsumeEvent', // 新增套餐 修改单个课单 确定后联动问题 emit('eventName', data); '考勤签到后刷新数据'

      'AfterClassGradeAddOrEdit', // 班级 新增或修改 emit (eventName,type,data) , type:'Add新增 、Edit修改' End结束 ，data：新增或修改后返回的对象
      'afterQuitOrAddClass', // 退班或者加入班级   emit (eventName，classID,studentID,type) classID班级ID ,studentID学生ID,add:加入班级，quit：退出班级
      'AfterEditCircleCourse', // 修改循环课emit(eventName, ruleID);
      'AfterAbortCircleCorse', // 终止循环课 emit(eventName, this.circleCourseInfo.StructuredRuleKey)
      'AfterCancelApplyClassCourseByRule', // 学生退出循环课, this.circleCourseInfo.StructuredRuleKey,this.circleCourseInfo.CourseNameKey,this.circleCourseInfo.GradeClassKey,

      'AfterNewCourseTable', // 新建课 emit (eventName, type), type:'mutil循环课，single:单课'
      'AfterBatchEditStructuredPlanList', // 批量修改排课计划 emit (eventName, courseNameID,classGradeID, PlanList)
      'AfterBatchDeleteStructuredPlanList', // 新建循环课 成功后批量删除排课计划 emit (eventName, courseNameID,classGradeID,PlanList)
      'AfterBatchArrangeStudentByPlanList', // 批量 安排 删除 学生 通过排课计划ID   emit (eventName,type,courseNameID, studentInfo, PlanList) , type:'Add新增 、Delete删除'，studentInfo:学生信息，PlanList：添加或删除的排课计划ID
      'AfterBatchStopCourses', // 批量停课 emit (eventName, courseNameID,classGradeID,needStopCourseIDArr) needStopCourseIDArr：可选 1、存在时 已停课ID列表 2、可不传（按规律停课）
      'AfterHolidaysStopCourse', // 长假停课  emit (eventName, this.startDate, this.endDate) startDate开始日期，endDate结束日期
      'AfterBatchEditCourses', // 批量修改课 emit (eventName,courseNameID, classGradeId)
      'AfterEditCourse', // 修改单节课 emit (eventName，courseInfo)
      'AfterStopCourse', // 删除单节课 emit (eventName，ScheduleCourseKey)

      'AfterStudentAddToCourse', // 添加学生进课 emit (eventName，ScheduleCourseKey， studenID) ScheduleCourseKey课ID,studenID学生ID
      'AfterChangeAttendanceStatus', // 变更考勤 emit (eventName，ScheduleCourseKey， studenID,type) ScheduleCourseKey课ID,studenID学生ID,type:考勤attendance
      'AfterBatchAttendance', // 批量考勤 emit (eventName，ScheduleCourseKey) ScheduleCourseKey课ID

      'AfterStudentApplyCourse', // 学生报课 emit (eventName，data) eventName，data：报课数据
      'AfterCourseNameApplyCourse', // 课程报课成功
      'AfterArrangeForSingleStudent', // 单学生排课 emit (eventName，type,courseID,studentID) eventName，type:'cancel取消，arrange：安排,courseID课ID，studentID学生ID

      'afterArrangeScheduleCourse', // 批量排课 、自选排课  emit (eventName，cancelStudentArr,arrangeStudentArr)
      'afterArrangeCirculationCourse', // 安排/退出循环课
      'AfterEditCourseSingle', // 课单内容修改后回调
      'AfterRepayClassHour', // 还课时后 emit (eventName，studenKey)
      'AfterCourseConsumeUpDate', // 购课课消详情记录数据变化后的回调
      'AfterUpdateSubmitHomeTask', // 家校通发布，批改作业成功后的回调
      'updateMendianOperationManagement', // 客户跟单管理 更新.
      'updateSignWrongling', // 异常签到中调用提交接口时触发.
      'switchHeaderBarToTeacherAttenClass', // 切换到老师上课
      'switchHeaderBar', // 切换模块
      'switchHeaderBarToIndexView', // 切换菜单到工作台 emit(eventName);
      'switchHeaderBarToCourseTable', // 切换菜单到课程表 emit(eventName);
      'switchHeaderBarToPresidentKanban', // 切换菜单到校长看板 emit(eventName);
      'switchHeaderBarToMendiansettingform', // 切换菜单到门店设置 emit(eventName);
      'switchHeaderBarToCourseArrangement', // 切换菜单到排课管理 emit(eventName);
      'switchHeaderBarToNewCourseArrangement', // 切换菜单到新排课管理 emit(eventName);
      'updateMendianOperationManagement', // 客户跟单管理 更新.
      'switchHeaderBarToMoreMendianReport', // 切换菜单到连锁门店.
      'switchHeaderBarToManagementAndStatistics', // 切换菜单到管理与统计.
      'deleteRegisterCustomer', // 删除学生
      'updateWarmingStatisical', // 特定情况下通知首页跟校长看板刷新预警记录
      'todayQuitAll', // 首页中今日考勤全部退课时触发
      'thirdpartyTencentMandate',
      'allCourseQuit', // 待办事项中全部退课通知首页跟校长看板更新
      'closeAttendanceDetailDialog', // 关闭考勤页面更新首页数据
      'closeArrangeAttendanceDialog', // 关闭安排考勤更新指定页面数据
      'selectActivitiesCoupon', // 刷新热门活动
      'closeEditorDialog', // 关闭海报券编辑器或者音乐时
      'wsZSZKUpData', // 招生战况数据刷新
      'updateCampusCourseware', // 总部智能预警后更新信息 DataKey:校区ID
      'updateMendianSet', // 更新门店设置
      'wsCoursewareEditor', // 课件编辑用户进入
      'AfterReleaseEssay',   // 发文时候通知相关地方更新学生列表.
      'copyContentAfterUpdate',  // 复制文本时触发，
      'wsquitAttendClassPreview', // 退出上课预览模式
      'AftersCoursewareEditorState', // 课件编辑状态
      'wsrechargeTheBalance', // 余额成功通知
      'wsFlowSendsMessage', // 流量包充值成功通知
      'scanToAuthCourseware', // 课件扫码通知
      'pptVerifyFail', // ppt独立验证异常
      'pptPageVerify',   // ppt每页验证。
      'beginAttachVisit',   // 播放附件
      'endAttachVisit',   // 结束附件
      'openAttachInfo',   // 打开特定附件
      'wsCoursewareMutualExclusion',  // 课件互斥
      'LoginReply', // 重复登录
      'setVedioCoverl',  // 设置视频封面
      'wsVerifyISEndVisit',  // 确定退出访问
      'addAttachLog', // 课件添加
      'updateTemplatelist',  //发布更新模板列表
      "showExportDataList",  // 异步导出
      "screenOnResize"     //屏幕宽度分辨率变更
    ]
  },
  methods: {
    on (vm, eventName, callback) {
      let event = this.findEvent(eventName, 'on');
      if (event) {
        event.push({
          callback: callback,
          vm: vm
        });
      }
    },
    // vm  必有。
    // eventName 可不提供。不提供时，将移除当前el下的所有注册同on 事件。
    // handler 可不提供。为指定移除单个on事件，该值为 注册时的那个callback。
    off (vm, eventName, handler) {
      let uid = vm._uid;
      // eventName 为空，移除该组件所有已注册的事件
      if (!eventName && this.eventList) {
        for (let thisEventName in this.eventList) {
          // 删除对应的事件
          try {
            let event = this.eventList[thisEventName];

            let newEvent = event.filter(call => call.vm._uid != uid);

            if (newEvent.length != event.length) {
              event.splice(0, event.length);
              event.push(...newEvent);
            }
          } catch (e) {
            console.error('bus off', e, event);
          }
        }
      } else {
        let event = this.findEvent(eventName, 'on');
        if (event) {
          // 过滤留下不需移除的事件
          let newEvent = event.filter(
            call =>
              !(call.vm._uid == uid && (!handler || handler == call.callback)) // 排除掉所有需要移除的事件
          );
          event.splice(0, event.length); // 清空
          event.push(...newEvent);
        }
      }
    },
    emit (eventName, arg1, arg2, ...arr) {
      // _isDestroyed
      let event = this.findEvent(eventName, 'emit');
      if (event) {
        let needOfList = [];
        event.forEach(call => {
          if (call.vm && !call.vm._isDestroyed) {
            if (arguments.length > 1) {
              call.callback(arg1, arg2, ...arr);
            } else {
              call.callback();
            }
          } else {
            if (!needOfList.includes(call.vm)) {
              needOfList.push(call.vm);
            }
          }
        });
        needOfList.forEach(vm => {
          console.log('bus off by emit', vm._uid);
          this.off(vm);
        });
      }
    },
    findEvent (eventName, type) {
      let event = this.eventList[eventName];
      if (!event) {
        let findIndex = this.eventMapList.findIndex(name => {
          return eventName == name;
        });
        if (findIndex >= 0) {
          this.eventList[eventName] = [];
          event = this.eventList[eventName];
        } else {
          window.alert('ERROR:' + type + '件不存在' + eventName);
          console.error('ERROR:' + type + '事件不存在' + eventName);
        }
      }
      return event;
    }
  }
});

export default bus;

// bus.update-json event
/*
 **item-click main-show（点击元素编辑）
 **button2-menu-show button2-editor（右键显示编辑选项弹层）
 **animate-change animate-single（触发当前改变动画播放）
 **change-tab modules.vue（设置模版tab：编辑状态）
 **bg-editor-toggle main-show.vue bg-editor.vue
 */

// animate 部分调试总结：this.stopEndCallback是否被animate-change改变为true,是否有顺序关系，比如$on本来会比watch慢执行
