<template>
  <div class="screening_condition_opt fl">
    <span>
       <span v-for="item in unClearParamsArr"
            :key="item.type">
        <un-clear-text v-if="item.type==searchConditionType.unClear"
                    :dataModel="item"
                    v-model="selectedParams">
        </un-clear-text>
      </span>
    </span>
    <span v-if="mergeSearchConditionAndParams.length>0">
      <span class="fl">已选条件 :</span>
      <span v-for="item in mergeSearchConditionAndParams"
            :key="item.type">
        <date-picker v-if="item.type==searchConditionType.dateRange"
                    :dataModel="item"
                    v-model="selectedParams"
                    @change="doAfterChange"></date-picker>
        <select-option v-if="item.type==searchConditionType.selectOption"
                      :dataModel="item"
                      v-model="selectedParams"
                      @change="doAfterChange"></select-option>
        <number-range v-if="item.type==searchConditionType.numberRange"
                      :dataModel="item"
                      v-model="selectedParams"
                      @change="doAfterChange">
        </number-range>
        <number-range-percent v-if="item.type==searchConditionType.numberRangePercent"
                              :dataModel="item"
                              v-model="selectedParams"
                              @change="doAfterChange">
        </number-range-percent>
        <single-tree v-if="item.type==searchConditionType.singleTree"
                    :dataModel="item"
                    v-model="selectedParams"
                    @change="doAfterChange">
        </single-tree>
      </span>
    </span>
  </div>
</template>
<script>
import datePicker from './date-picker';
import selectOption from './select-option';
import numberRange from './number-range';
import numberRangePercent from './number-range-percent';
import singleTree from './single-tree';
import unClearText from './un-clear-text';
import initJson from '../../../../js/initJson.json';
export default {
  data () {
    return {
      searchConditionType: {}, // 扩展查询条件类型
      unClearParamsArr: [], // 不可清除的参数
      mergeSearchConditionAndParams: [] // 合并查询条件和参数
    };
  },
  model: {
    prop: 'selectedParams'
  },
  props: {
    selectedParams: Array, // 已选参数
    extendSearchCondition: Array, // 扩展查询条件
    unClearParams: Array // 不可清除只显示的参数
  },
  components: { selectOption, datePicker, numberRange, numberRangePercent, singleTree, unClearText },
  created () {
    this.searchConditionType = initJson.extendSearchConditionType;
    this.showSelectedParams(this.selectedParams);
    this.showUnClearParams();
  },
  watch: {
    // 通过监听已选参数 改变已选参数栏显示
    selectedParams: {
      handler (n, o) {
        this.showSelectedParams(n);
      },
      deep: true
    },
    unClearParams: {
      handler (n, o) {
        this.showUnClearParams();
      },
      deep: true
    }
  },
  methods: {
    // 显示不可清除只显示的参数
    showUnClearParams () {
      this.unClearParamsArr = [];
      if (this.unClearParams) {
        this.unClearParams.forEach(obj => {
          let condition = {
            type: initJson.extendSearchConditionType.unClear, //  不可清除的条件
            formTitle: obj.name,
            fieldList: [
              { name: obj.name, // 参数名
                defaultValue: '', // 默认值
                value: obj.value, // 绑定值
                validateHandle: '', // 自定义验证函数
                sourceData: []// 数据源
              }
            ]
          };
          this.unClearParamsArr.push(condition);
        });
      }
    },
    // 显示已选参数栏
    showSelectedParams (selectedParams) {
      this.mergeSearchConditionAndParams = [];
      this.extendSearchCondition.forEach(condition => {
        let isSame = true;
        condition.fieldList.forEach((param, index) => {
          let findItem = selectedParams.find(o => { return o.name == param.name; });
          if (findItem) {
            isSame = false;
            param.value = findItem.value;
          } else {
            param.value = param.defaultValue;
          }
        });
        if (!isSame) { this.mergeSearchConditionAndParams.push(condition); }
      });
    },
    doAfterChange () {
      this.$emit('input', this.selectedParams);
      this.$emit('changeExtendSearchParams');
    }
  }
};
</script>