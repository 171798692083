<template>
  <div>
    <div class="attendance_details_box">
      <div class="attendance_details_content workbench_view_todaySign_content clearfix flex"
           v-if="courseList.length>0">
        <div class="attendance_details_content_ul couser_block_list" style="overflow-x: hidden;">
          <courseItem v-for="item in courseList"
                      :key="item.OLAPKey"
                      :item="item"
                      @clickItem="seletedCourseAfter">
          </courseItem>
        </div>
        <div class="content_student_box">
          <!-- <div class="attendance_details_nav">
            <div class="nav_list"
                 :class="{opt:showOpt=='KQAP'}">
              <div class="nav_list_text"
                   @click="showKQAP">安排考勤</div>
            </div>
            <div class="nav_list"
                 :class="{opt:showOpt=='JXT'}">
              <div class="nav_list_text"
                   @click="showJXT">家校通</div>
            </div>
          </div> -->
          <div class=""
               v-if="showOpt=='KQAP'">
            <attendance-arrange v-if="courseInfo.ScheduleCourseKey"
                                :isShowBtn="false"
                                :isAlter="isAlter"
                                :isHistory="!isAlter"
                                :ClassName="'noscroll'"
                                :courseKey="courseInfo.ScheduleCourseKey"></attendance-arrange>
          </div>

          <div class="JXT_homework_title"
               v-if="showOpt=='JXT'">
            <home-task-and-comment-content :scheduleCourseInfo="courseInfo"
                                           :teacherDetailsShow="false"></home-task-and-comment-content>
          </div>
          <!-- <div class="content_student_title clearfix">
            <div class="fl">{{courseInfo.GradeClassKeyValue}}</div>
            <div class="fr">
              签到
              <span class="font_black">{{Number(courseInfo.Attence3)}}人</span>丨请假
              <span class="font_black"><span class="font_red">{{Number(courseInfo.Attence4)}}</span>人</span>丨旷课
              <span class="font_black"><span class="font_red">{{Number(courseInfo.Attence5)}}</span>人</span>
              <span class="font_icon font_red"
                    v-if="setStatusKeyValue()!='未开始'">未考勤 {{Number(courseInfo.Attence2)}} 人 </span>
              <span class="font_icon font_gray"
                    v-else>待考勤 {{Number(courseInfo.Attence2)}} 人 </span>
            </div>
          </div> -->
          <!-- <div v-if="courseInfo.ScheduleCourseKey">
              <student-info :selectedCourseInfo="courseInfo"
                            :isAlter="true"
                            :studentArrangeList="[]"
                            :isSuspend="isSuspend"
                            :isTeacher="isTeacher"
                            :contentClassName="'content_student_ul index_tabs_two attendance_arrange'"
                            @refresh="updateCourseInfo"
                            @closeCourse="cancelAttendanceArrange"></student-info>
            </div> -->
        </div>
      </div>
      <div v-else
           class="course_block_nodata"
           style="padding-top: 100px;background-position-y: 120px;">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>

  </div>
</template>
<script>
import courseItem from './course-item';
import attendanceArrange from '../attendance-arrange/index';
import homeTaskAndCommentContent from '../../home-school-communication/home-task-and-comment-content/index';
import { getKQsummaryForDay, GetMainTeaScheduleCourseListByDate, getScheduleCourseOnStudentList, getCourseDetailed } from '../../../API/workbench';
export default {
  data () {
    return {
      isShow: false,
      courseList: [],
      courseInfo: {},
      studentList: [],
      isSuspend: false,
      selectedDate: '',
      isAlter:true,
      showOpt: 'KQAP' // 默认显示安排考勤
    };
  },
  components: {
    courseItem,
    homeTaskAndCommentContent,
    attendanceArrange
    // studentInfo
  },
  props: {
    formTitle: String,
    queryDate: String,
    isTeacher: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visTeacher () {
      if (this.courseInfo && this.courseInfo.ScheduleCourseKey) {
        if (this.courseInfo.MTeacherKey.indexOf(this.$store.getters.token.UserID) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  created () {
    this.selectedDate = this.queryDate;
    this.getCourseListForDate();
    this.resgisterBusEvent();
  },
  methods: {
    // 显示安排考勤
    showKQAP () {
      this.showOpt = 'KQAP';
    },
    // 显示家校通
    showJXT () {
      this.showOpt = 'JXT';
    },
    setStatusKeyValue () {
      if (this.courseInfo && this.courseInfo.ClassTime) {
        let timer = this.courseInfo.ClassTime.replace(/-/g, '/') + ' ' + this.courseInfo.SClassTimeName;        // 开始时间
        let seconds = Number(Date.new(timer).getTime() / 1000) - Number(this.$utils.getSaaSClientSetInfo(31).SetContent) * 60;  // 获取当前数据的时间
        if (this.courseInfo.StatusKey == 4) {
          return '已上完';
        } else if (this.courseInfo.StatusKey == 3) {
          return '上课中';
        } else if (this.courseInfo.StatusKey == 2 && seconds < (Date.new().getTime()) / 1000) {
          return '可签到';
        } else {
          return '未开始';
        }
      } else {
        return false;
      }
    },
    // 修改课或者更新考勤触发.
    updateCourseInfo (courseKey, selectedCourseInfo) {
      console.log('更新courseKey', courseKey);
      this.getCourseListForDate(() => {
        if (this.courseList.length > 0) {
          console.log('更新列表', this.courseList);
          let locaIndex = this.courseList.findIndex(o => {
            return courseKey == o.OLAPKey;
          });
          console.log('更新Index', locaIndex);
          if (locaIndex >= 0) {
            this.seletedCourseAfter(this.courseList[locaIndex]);
          } else {
            this.seletedCourseAfter(this.courseList[0]);
          }
        }
      });
    },
    // 停课通知
    cancelAttendanceArrange () {
      this.studentList = [];
      this.courseInfo = {};
      this.initData(this.selectedDate);
      document.getElementsByClassName('attendance_details_content_ul')[0].scrollTop = 0;
    },
    // 按天获取所有考勤课列表的信息
    getCourseListForDate (callback) {
      if (!this.isTeacher) {
        getKQsummaryForDay(this.selectedDate).then(result => {
          result.data.forEach(o => {
            o.isActive = false;
          });
          this.courseList = result.data;
          if (this.courseList.length > 0) {
            if (callback) {
              callback();
            } else {
              this.seletedCourseAfter(this.courseList[0]);
            }
          }
        });
      } else {
        GetMainTeaScheduleCourseListByDate(this.selectedDate).then(result => {
          result.data.forEach(o => {
            o.isActive = false;
          });
          this.courseList = result.data;
          if (this.courseList.length > 0) {
            if (callback) {
              callback();
            } else {
              this.seletedCourseAfter(this.courseList[0]);
            }
          }
        });
      }
    },
    // 获取课程学生列表
    getStudentListInCourse (ID, callback) {
      getScheduleCourseOnStudentList(ID).then(result => {
        this.studentList = result.data;
        if (typeof callback == 'function') {
          callback();
        }
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    closeIsShow () {
      this.isShow = false;
    },
    // 变更选中课信息
    seletedCourseAfter (item) {
      this.courseList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      if (this.courseInfo.ScheduleCourseKey != item.OLAPKey) {
        this.getCourseInfo(item.OLAPKey, () => {
          this.showKQAP();
        },item);
      }
    },
    vMinDate(dateTime){
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); //获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month ="01";
      let day = "01";
      let hour = "00";
      let minute = '00';
      let second = '01';
      let dateStr = year + "-" + month + "-" + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate',dateStr)
      console.log("new Date(dateTime)>=new Date(dateStr)",new Date(dateTime)>=new Date(dateStr))
      return new Date(dateTime)>=new Date(dateStr)
    },
    // 获取选中课信息详情
    getCourseInfo (courseKey, callBack,item) {
      this.isAlter=this.vMinDate(item.ClassTime)
      let isHistory =this.isAlter?0:1
      getCourseDetailed(courseKey,isHistory).then(
        res => {
          if (res.code == 0) {
            console.log('getCourseInfo', res.data);
            if (Number(res.data.ArrangedNumCount) <= Number(res.data.FullPeopleCount)) {
              if (Number(res.data.AttenceCount) == 0 && Number(res.data.FullPeopleCount) == 0) {
                res.data.AttencePercentage = 0;
              } else {
                res.data.AttencePercentage = Math.ceil((Number(res.data.AttenceCount) / Number(res.data.FullPeopleCount)) * 100);
              }
              if (Number(res.data.ArrangedNumCount) == 0 && Number(res.data.FullPeopleCount) == 0) {
                res.data.ArrangedPercentage = 0;
              } else {
                res.data.ArrangedPercentage = Math.ceil((Number(res.data.ArrangedNumCount) / Number(res.data.FullPeopleCount)) * 100);
              }
            } else {
              if (Number(res.data.AttenceCount) == 0 && Number(res.data.ArrangedPercentage) == 0) {
                res.data.AttencePercentage = 0;
              } else {
                res.data.AttencePercentage = Math.ceil((Number(res.data.AttenceCount) / Number(res.data.ArrangedPercentage)) * 100);
              }
              res.data.ArrangedPercentage = 100;
            }
            if (res.data.ClassTime) {
              res.data.week = this.$utils.getWeekByTwo(res.data.ClassTime);
            }
            this.courseInfo = res.data;
            console.log("item",item)
     
            console.log(this.courseInfo, 'getCourseInfo this.courseInfo');
            if (typeof callBack == 'function') {
              callBack(this.courseInfo);
            }
          }
          if (this.courseInfo.IsClassStopKey == 1) {
            this.isSuspend = true;
          } else {
            this.isSuspend = false;
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'AfterBatchEditCourses', () => {
        console.log('停课/恢复课');
        this.getCourseListForDate();// 上课记录
      });
      this.$bus.on(this, 'AfterBatchAttendance', scheduleCourseKey => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo(scheduleCourseKey, (data) => {
            let selectedCourse = this.courseList.find(obj => { return obj.OLAPKey == scheduleCourseKey; });
            selectedCourse.ArrivedNumCount = data.ArrivedNumCount;
          },this.courseInfo);
        }
      });
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo(scheduleCourseKey, (data) => {
            let selectedCourse = this.courseList.find(obj => { return obj.OLAPKey == scheduleCourseKey; });
            selectedCourse.ArrangedNumCount = data.ArrangedNumCount;
          },this.courseInfo);
        }
      });
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo(scheduleCourseKey, (data) => {
            let selectedCourse = this.courseList.find(obj => { return obj.OLAPKey == scheduleCourseKey; });
            selectedCourse.ArrangedNumCount = data.ArrangedNumCount;
            selectedCourse.ArrivedNumCount = data.ArrivedNumCount;
            this.$bus.emit('wsAttendanceOrArrange');
          },this.courseInfo);
        }
      });
      this.$bus.on(this, 'afterArrangeScheduleCourse', (scheduleCourseKey, studentKey) => {
        console.log(scheduleCourseKey, studentKey, 'afterArrangeScheduleCourse', this.courseInfo);
        scheduleCourseKey = scheduleCourseKey || this.courseInfo.ScheduleCourseKey;
        this.updateCourseInfo(scheduleCourseKey);
      });
    }
  }

};
</script>
