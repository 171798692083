<template>
  <div>
    <div class="release_edit_content">
      <div class="html_div_select_the_student"
           v-if="settingInfo.formTitle">
        <div class="">
          <div>
            <span class="notification_title">{{settingInfo.formTitle}}</span>
          </div>
          <div>
            <input class="enter_notification_title"
                   type="text"
                   :placeholder="settingInfo.formTitleplaceholder"
                   v-model="dataInfo.Title" />
          </div>
        </div>
      </div>
      <div :class="className"
           class="publish_content_height"
           :style="{}">
        <div v-if="settingInfo.contentTitle"
             class="homework_publish_title">
          {{settingInfo.contentTitle}}
        </div>
        <div v-if="isShowContentText"
             class="homework_publish_textInput">
          <content-text ref="content"
                        v-model="dataInfo.Content"
                        :placeholder="settingInfo.placeholder"
                        :maxLength="maxLength"
                        @focusFun="focusFun"
                        @input="blurText"></content-text>

        </div>
        <div class="homework_publish_accessory">
          <div class="dynamic_content_body">
            <!-- 音频 -->
            <div v-if="vIsShowAudio">
              <audio-show ref="audioShow"
                          :audioUrl="vAudioUrl"
                          :isShowDeleteBtn="true"
                          @onRecorderDuration="setRecorderDuration"
                          @onDeleteAudio="deleteAudio"
                          @onClickAudio="toPlayAudio"></audio-show>
            </div>
            <div class="body_video_box bulk_assessment_students_image flex"
                 :class="{video_type:vVideoCount>0}">
              <video-list :attachList="dataInfo.AttachList"
                          :isCanEdit="true"
                          @onDelete="deleteAttach"></video-list>
            </div>
            <img-list :dataInfo="dataInfo"
                      :isVideo="vVideoCount>0"
                      :isShowEditBtn="true">
              <div class="bulk_assessment_students_image_view pr"
                   :style="{'margin-left':vImgCountNum==0?'114px':'0px'}"
                   v-if="vImgCountNum>0||vVideoCount>0">
                <div class="bulk_assessment_students_image_view_image">
                  <img src="../../../../../public/image/user_icon.png"
                       mode="">
                  <choose-img-mutil class="upload_box"
                                    :maxNum=12
                                    @onUpLoadImgSuccess="doAfterUpLoadImgSuccess"></choose-img-mutil>
                </div>
              </div>
            </img-list>

          </div>

        </div>

        <!-- <div class="homework_publish_menuBox">
        <div class="menuBox_list list_img">
          图片
          <div>
            <choose-img-mutil class="upload_box"
                              :maxNum=12
                              @onUpLoadImgSuccess="doAfterUpLoadImgSuccess"></choose-img-mutil>
          </div>

        </div>
        <div class="menuBox_list list_audio"
             v-if="!vIsShowAudio"
             @click.stop="clickRecordVoice">
          录音
          <record-voice :audioData="vAudioData"
                        @onCancelVoice="toCancelVoice"
                        @onUploadFiles="doAfterUploadAudio"></record-voice>
        </div>
      </div> -->
      </div>

      <footer-operation :value="dataInfo"
                        :settingInfo="settingInfo"
                        @doAfterUpLoadImgSuccess="doAfterUpLoadImgSuccess"
                        :showRelevanceStudent="settingInfo.showRelevance && !settingInfo.isTeacher"
                        :showRelevanceTeacher="settingInfo.showRelevance && !!settingInfo.isTeacher"
                        @deleteEmoji="deleteEmoji"
                        @onUploadFiles="doAfterUploadAudio"
                        @onUploadVideoFiles="doAfterSeletedVideo"
                        @onDeleteAudio="deleteAudio"
                        @seletedMemeItem="seletedMemeItemAfter"
                        @clickRelevance="clickRelevanceAfter"
                        @saveClick="saveClick">
        <slot></slot>
      </footer-operation>
      <custom-dialog :title="'播放音频'"
                     :visible.sync="isPlayAudio"
                     :before-close="toCloseAudio">
        <play-audio :audioData="vAudioData"
                    @onCloseAudio="toCloseAudio"
                    @onRedoRecord="redoRecord"></play-audio>
      </custom-dialog>
      <!-- <div v-if="isPlayAudio">
      <play-audio :audioData="vAudioData"
                  @onCloseAudio="toCloseAudio"
                  @onRedoRecord="redoRecord"></play-audio>
    </div> -->

    </div>
  </div>
</template>
 
<script>
// 发文内容
// 文本内容
// 图片
// 视频
// 语音
import footerOperation from './footer-operation';
import inputContent from './input-content';
import imgList from './img-list/index';
import recordVoice from './record-voice';
import audioShow from '../audio-show';
import playAudio from './play-audio';
import videoList from './video-list/index';
import contentText from './content-text';
import { getVideoCoverImages, UploadFilesShowProgress } from '../../../../API/workbench.js';
export default {
  components: {
    imgList,
    recordVoice,
    audioShow,
    inputContent,
    playAudio,
    footerOperation,
    contentText,
    videoList
  },
  props: {
    dataInfo: {
      type: Object, // 发文的内容
      default: function () {
        return {
          essayID: 0,
          Title: '', // 标题,
          Content: '', // 内容,
          RelateCourseID: 0, // 课ID,
          StudentList: [{ // 接收学生列表
            StudentKey: 0, // 学生Id,
            StudentKeyValue: ''// 学生姓名,
          }],
          AttachList: [
            // { // 附件列表
            //   AttachTypeKey: 1, // 1-图片; 2-视频;3-音频
            //   AudioSize: '',
            //   AttachUrl: '../../../../public/image/01.jpg'// "/Upload/Resource/10145/Image/202007291031397744.jpg",
            // }
          ]

        };
      }
    },
    settingInfo: {
      type: Object,
      default: () => {
        return {
          contentTitle: '',
          formTitle: '',
          formTitleplaceholder: {
            type: String,
            default: ''
          },
          placeholder: {
            type: String,
            default: ''
          },
          isTeacher: {
            type: [String, Number],
            default: 0
          },
          showAddPic: {
            type: Boolean,
            default: true
          },
          // 0关闭 1拍选压 2拍压 3选压 4选不压
          addVideoType: {
            type: Number,
            default: 0
          },
          showRelevance: {
            type: Boolean,
            default: true
          },
          showUploadFile: {
            type: Boolean,
            default: false
          }
        };
      }
    },
    maxLength: {
      type: Number
    },
    className: {
      type: String,
      default: ''
    },
    isShowListContent: {
      type: Boolean,
      default: false
    },
    dataIndex: {
      type: Number
    }

  },
  computed: {
    vVideoCount () {
      const videoList = this.dataInfo.AttachList.filter((obj) => {
        return obj.AttachTypeKey == 2;
      });
      return videoList.length;
    },
    vImgCountNum () {
      let imgList = this.dataInfo.AttachList.filter((obj) => { return obj.AttachTypeKey == 1; });
      return imgList.length;
    },
    vAudioUrl () {
      let audioObj = this.dataInfo.AttachList.find(obj => { return obj.AttachTypeKey == 3; });
      let url = '';
      if (audioObj) {
        url = audioObj.AttachUrl;
      }
      return url;
    },
    vAudioData () {
      let data = null;
      let audioObj = this.dataInfo.AttachList.find(obj => { return obj.AttachTypeKey == 3; });
      if (audioObj) {
        data = audioObj;
      }
      return data;
    },
    vIsShowAudio () {
      let flag = false;
      if (this.vAudioUrl) {
        flag = true;
      }
      return flag;
    }
  },
  data () {
    return {
      isShowRecordVoice: false, // 是否显示录音弹框
      recorderDuration: 0, // 音频时长
      isiOS: false,
      isPlayAudio: false,
      isVideo: false,
      isShowContentText: true,
      vedioAttachData: null    // 上传视频项。
    };
  },
  watch: {
    // 'dataInfo.Content': {
    //   handler (nv, ov) {
    //     if (nv === ov) {
    //       return;
    //     }
    //     this.dataInfo.Content = nv;
    //     console.log('value changed');
    //     this.changeHeight();
    //   }
    // }
  },
  created () {
    var u = navigator.userAgent;
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //
  },
  mounted () {
    this.$bus.on(this, 'setVedioCoverl', data => {
      console.log('setVedioCoverl', data);
      this.vedioAttachData.VideoCoverUrl = data.CoverUrl;
      this.$emit('input', this.dataInfo);
    });
  },
  methods: {
    blurText (text) {
      if (this.maxLength && text.length > this.maxLength) {
        layer.alert('文章内容超出最大长度');
      }
      this.$emit('blurText', text, this.dataInfo.essayID, this.dataIndex);
    },
    editText () {
      this.isShowContentText = false;
      this.$nextTick(() => {
        this.isShowContentText = true;
      });
    },
    // 删除附件
    deleteAttach (attachItem) {
      const attachIndex = this.dataInfo.AttachList.findIndex(obj => {
        if (attachItem.ID) {
          return obj.ID == attachItem.ID;
        } else {
          return obj.tempID == attachItem.tempID;
        }
      });
      if (attachIndex >= 0) {
        let preAttachData = this.dataInfo.AttachList[attachIndex];
        if (preAttachData.uploadTask && preAttachData.uploadTask.abort) {
          preAttachData.uploadTask.abort();
        }
        this.isVideo = false;
        this.dataInfo.AttachList.splice(attachIndex, 1);
        this.$emit('input', this.dataInfo);
      }
    },
    changeHeight () {
      let _this = this;
      this.$nextTick(() => {
        var textArea = _this.$refs.textArea;
        var scrollHeight = textArea.scrollHeight; // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
        var height = textArea.offsetHeight; // 屏幕上显示的高度
        if (height <= scrollHeight) {
          textArea.style.height = 'auto'; // 恢复默认值，这个作用就是根据内容自适应textarea高度
          textArea.style.height = textArea.scrollHeight + 'px'; // 拿到最新的高度改变textarea的高度
        }
      });
    },
    // 点击语音
    clickRecordVoice () {
      this.isShowRecordVoice = true;
    },
    // 取消录音
    toCancelVoice () {
      this.isShowRecordVoice = false;
    },
    // 图片上传成功
    doAfterUpLoadImgSuccess (item) {
      this.dataInfo.AttachList.push(item);
    },
    // 选择完视频之后
    doAfterSeletedVideo (tempData) {
      const attachData = {
        AttachTypeKey: 2,
        AttachUrl: '',
        VideoCoverUrl: '',
        AudioSize: 0,
        tempAttachUrl: '',
        tempID: Date.new().getTime(),
        percent: 0,
        uploadTask: null
      };

      // this.toCancelVoice();
      let videoIndex = this.dataInfo.AttachList.findIndex(obj => {
        return obj.AttachTypeKey == 2;
      });
      // 一份作业只能存在一个视频
      if (videoIndex >= 0) {
        let preAttachData = this.dataInfo.AttachList[videoIndex];
        if (preAttachData.uploadTask && preAttachData.uploadTask.abort) {
          preAttachData.uploadTask.abort();
        }
        this.dataInfo.AttachList.splice(videoIndex, 1);
      }
      this.isVideo = true;
      this.dataInfo.AttachList.push(attachData);
      this.vedioAttachData = attachData;
      console.log(tempData, '上传视频，视频大小：');
      attachData.uploadTask = UploadFilesShowProgress(2, '', tempData, attachData).then(result => {
        getVideoCoverImages(result.data.url).then(coverResult => {
          // attachData.VideoCoverUrl = coverResult.data.url;
          attachData.AttachUrl = result.data.url;
          delete attachData.uploadTask;
        });
      }, error => {
        console.log('doAfterSeletedVideoError');

        let newvideoIndex = this.dataInfo.AttachList.findIndex(obj => {
          return obj.AttachTypeKey == 2;
        });
        let preAttachData = this.dataInfo.AttachList[newvideoIndex];
        if (preAttachData.uploadTask && preAttachData.uploadTask.abort) {
          preAttachData.uploadTask.abort();
        }
        if (newvideoIndex >= 0) {
          this.dataInfo.AttachList.splice(newvideoIndex, 1);
        }
        layer.alert(error.msg);
      });
      this.$emit('input', this.dataInfo);
    },
    // 上传音频后
    doAfterUploadAudio (url, recorderDuration) {
      this.toCancelVoice();
      let audioIndex = this.dataInfo.AttachList.findIndex(obj => { return obj.AttachTypeKey == 3; });
      let audioOobj = { AttachTypeKey: 3, AttachUrl: url, AudioSize: recorderDuration || 0 };
      // 一份作业只能存在一个音频
      if (audioIndex >= 0) {
        this.dataInfo.AttachList.splice(audioIndex, 1, audioOobj);
      } else {
        this.dataInfo.AttachList.push(audioOobj);
      }
    },
    // 播放音频
    toPlayAudio () {
      this.isPlayAudio = true;
    },
    toCloseAudio () {
      this.isPlayAudio = false;
    },
    setRecorderDuration (url, recorderDuration) {
      console.log(url, 'setRecorderDuration', recorderDuration, this.dataInfo.AttachList);
      let audioIndex = this.dataInfo.AttachList.findIndex(obj => { return obj.AttachUrl == url; });
      if (audioIndex >= 0) {
        if (!this.dataInfo.AttachList[audioIndex].AudioSize) {
          this.dataInfo.AttachList[audioIndex].AudioSize = recorderDuration;
        }
      }
    },
    // 重录
    redoRecord (url) {
      this.deleteAudio(url);
      this.clickRecordVoice();
      this.isPlayAudio = false;
    },
    // 删除音频
    deleteAudio (url) {
      let audioIndex = this.dataInfo.AttachList.findIndex(obj => { return obj.AttachUrl == url; });
      if (audioIndex >= 0) {
        this.dataInfo.AttachList.splice(audioIndex, 1);
      }
    },
    hiddenFixed () {
      if (this.isiOS) {
        document.getElementsByClassName('JXT_fixed_btn_box')[0].style.position = 'static';
      }
    },
    showFixed () {
      if (this.isiOS) {
        document.getElementsByClassName('JXT_fixed_btn_box')[0].style.position = '';
      }
    },
    // 选中表情后添加
    seletedMemeItemAfter (item) {
      this.$refs.content.dropRelease(item, 'meme');
    },
    // 删除表情
    deleteEmoji () {
      this.$refs.content.deletedRelease();
      console.log('deleteEmoji');
    },
    // 添加@
    clickRelevanceAfter (value) {
      console.log(value, 'clickRelevance');
      this.$refs.content.dropRelease(value, 'relevance');
    },
    saveClick (type) {
      this.$emit('saveClick', type);
    },
    focusFun () {
      this.$emit('focusFun', this.dataIndex);
    }

  }

};
</script>

<style>
</style>