<template>
  <div>
    <!-- 完善学生资料/登记新生 -->
    <custom-dialog :title="dialogTitle"
                   class="customer_dialog defult_dialog"
                   :visible.sync="showChgPlanCount"
                   :before-close="closePlanCount">
      <div class="single_result_detail form_info_detail">
        <!-- 课单详细信息 -->
        <!-- <div class="student_info_supplement form_info"> -->
        <!-- 1. 默认表单未展开 form_extend
        2. 默认可编辑表单 form_info_edit-->
        <div class="student_info_edit form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow">
          <form-input :formTitle="'班级名称'"
                      :formPlaceholder="'请输入班级名称'"
                      :required="true"
                      v-model="courseDetail.MainDemoName"></form-input>
          <form-input :formTitle="'计划招生人数'"
                      :formPlaceholder="'请输入计划招生人数'"
                      :required="true"
                      v-model="courseDetail.WantPeopleCount"></form-input>
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="confirmEdit"
                               @cancelClick="closePlanCount"></save-cancel-btn-group>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import formInput from '../../form-item/form-input';
import { editClass } from '../../../API/workbench';
export default {
  name: 'chgPlanCount',
  data () {
    return {
      showChgPlanCount: false,
      courseDetail: {},
      courseInfo: {},
      sendToEditJson: {
        GradeClassKey: '', // int, --班级ID（修改）
        GradeClassKeyValue: '', // string, --班级名称（修改）
        WantPeopleCount: '', // int, --计划招生数
        LecturerKeyValue: '', // string, --主讲老师
        LecturerKey: '', // string, --主讲老师ids
        ClassSetKey: '', // string, --开设课程id
        ClassSetKeyValue: '', // string, --开设课程
        IsSyncGradeClassKey: '0'// 是否全班一起上课
      }
    };
  },
  props: ['dialogTitle'],
  components: {
    formInput
  },
  created () { },
  methods: {
    // 取消按钮时触发
    closePlanCount () {
      this.showChgPlanCount = false;
    },
    // 确定按钮时触发
    confirmEdit (callBack) {
      let verify = this.verifyChange();
      if (!verify) {
        if (callBack) {
          callBack();
        }
        layer.alert('没作任何修改.不能保存.');
        return false;
      }
      this.sendToEditJson.GradeClassKey = this.courseDetail.OLAPKey;
      this.sendToEditJson.GradeClassKeyValue = this.courseDetail.MainDemoName;
      this.sendToEditJson.WantPeopleCount = this.courseDetail.WantPeopleCount;
      editClass(this.sendToEditJson)
        .then(
          result => {
            layer.alert('修改班级成功');
            this.$emit('update');
            this.showChgPlanCount = false;
          },
          r => {
            layer.alert(r.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 修改班级接口
    verifyChange () {
      if (
        this.courseDetail.MainDemoName == this.courseInfo.MainDemoName &&
        this.courseDetail.WantPeopleCount == this.courseInfo.WantPeopleCount
      ) {
        return false;
      } else {
        return true;
      }
    },
    openFromOtherPage (classInfo) {
      this.courseInfo = classInfo;
      this.courseDetail = this.$utils.parseJson(classInfo);
      this.courseDetail.WantPeopleCount = Number(
        this.courseDetail.WantPeopleCount
      );
    }
  }
};
</script>
