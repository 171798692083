<!-- 文本 -->
<template>
  <div>
    <div slot="title"
         class="title"
         style="padding:10px">自定义列 <span style="font-size: 14px;">勾选需要显示的列，拖动列名进行排序</span></div>
    <div style="padding:10px">
      <el-checkbox-group v-model="checkedColumns"
                         @change="handleCheckedList">
        <draggable v-model="allColumns"
                   handle=".move"
                   style="border-bottom: 1px solid #ddd;">

          <transition-group>
            <!-- <div style="padding:10px;"> -->
            <div v-for="field in allColumns"
                 :key="field.label"
                 class="move showItem">
              <el-checkbox :label="field.label"
                           class="ancheckbox"
                           :key="field.label">{{field.label}}</el-checkbox>
              <div class="textDiv">
                <span class="inputtext">宽度</span>
                <el-input type="text"
                          class="itemInput"
                          v-model="field.width"></el-input><span class="inputtext">px</span>
              </div>

            </div>
            <!-- </div> -->
          </transition-group>

        </draggable>
      </el-checkbox-group>
    </div>
    <div slot="footer"
         class="dialog-footer"
         style="text-align: center;padding-bottom: 10px;">
      <el-button class="buttonLeft"
                 type="primary"
                 plain
                 @click="recoverChecked">恢复默认</el-button>
      <el-button @click="close"
                 class="buttonRight">取 消</el-button>
      <el-button type="primary"
                 @click="submit"
                 class="buttonRight">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { EditAgentSetting } from '../../../../API/workbench';
import draggable from 'vuedraggable';
export default {
  data () {
    return {
      activeName: 'fieldsChosen',
      isIndeterminate: false,
      checkAll: false,
      checkedColumns: [],
      fieldList: [],
      columns: [],
      allColumns: [],
      fieldsTemp: [], // 存放按指定顺序排列的fields数组
      staticColumns: []
    };
  },
  props: {
    tableKey: {
      type: String,
      default: ''
    },
    allColumnsList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  created () {
    let defualtColumnsList = Object.assign([], this.$initJson.tableColumnsList);
    let tableColumnsList = [];
    defualtColumnsList.forEach(item => {
      if (item.tableKey == this.tableKey) {
        tableColumnsList = item;
      };
    });
    console.log('defualtColumnsList', tableColumnsList);
    if (tableColumnsList.tableColumns && tableColumnsList.tableColumns.length > 0) {
      this.fieldList = Object.assign([], tableColumnsList.tableColumns);  // 用于编辑显示数据
      console.log('this.fieldListthis.fieldList', this.fieldList);
    }

    this.fieldList.forEach(item => {
      this.staticColumns.push(item.label);
    });
    let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
    console.log('his.fieldList ', arr);
    let tempArr = [];

    if (arr && arr.length > 0) {
      arr.forEach(item => {
        if (item.SetKey == this.tableKey) {
          tempArr = item.SetValue;
        }
      });
    }

    if (tempArr.length > 0) {
      console.log('-----------------------', tempArr);
      if (typeof tempArr == 'string') {
        this.fieldList = Object.assign([], JSON.parse(tempArr));
      } else {
        this.fieldList = Object.assign([], tempArr);
      }
    }
    console.log('his.fieldList ', this.fieldList);
    // 更新表格展示列
    let sortArr = [];
    this.fieldList.forEach(item => {
      this.columns.push({ label: item.label, width: item.width });
      if (item.isShow == undefined || item.isShow) {
        this.checkedColumns.push(item.label);
      }
      sortArr.push(item.label);
    });
    //

    this.columnOptions = Object.assign([], this.allColumnsList);
    this.columnOptions.sort((a, b) => {
      const aKey = a.label;
      const bKey = b.label;
      if (sortArr.indexOf(aKey) == -1) {
        return false;
      }
      if (sortArr.indexOf(bKey) == -1) {
        return true;
      }
      return sortArr.indexOf(aKey) - sortArr.indexOf(bKey);
    });
    this.columnOptions.forEach(item => {
      this.allColumns.push({ label: item.label, width: item.width });
    });
    this.allColumns.forEach(item => {
      if (this.checkedColumns.findIndex((o) => { return o == item.label; }) > -1) {
        item.isShow = true;
      } else {
        item.isShow = false;
      }
    });

    console.log('this.staticColumns', this.columns, this.allColumns, this.staticColumns);
  },
  computed: {

  },
  components: {
    draggable
  },
  methods: {
    getTableAgentSetting () {
      let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
      let tempArr = [];
      arr.forEach(item => {
        if (item.SetKey == this.tableKey) {
          tempArr = item.SetValue;
        }
      });
      let columnArr = this.getLocalDataFunc(JSON.parse(tempArr), this.allColumnsList, 1);
      console.log('columnArr.length > 0 ? columnArr : this.tableColumns;', columnArr.length > 0 ? columnArr : this.allColumnsList);
      return columnArr.length > 0 ? columnArr : this.allColumnsList;
    },
    getLocalDataFunc (tempArr, tableColumns, type) {
      if (typeof tempArr == 'string') {
        tempArr = JSON.parse(tempArr);
      }
      let columnArr = [];
      console.log('tempArr', tempArr);
      if (tempArr.length > 0) {
        tableColumns.forEach(item => {
          if (tempArr.findIndex(i => {
            return type == 1 ? i == item.label : i == item.title;
          }) > -1) {
            columnArr.push(item);
          }
        });

        // 更新表格展示列
        columnArr.sort((a, b) => {
          const aKey = type == 1 ? a.label : a.title;
          const bKey = type == 1 ? b.label : b.title;
          console.log('aKey', aKey, bKey, Object.keys(a));
          if (tempArr.indexOf(aKey) == -1) {
            return false;
          }
          if (tempArr.indexOf(bKey) == -1) {
            return true;
          }
          return tempArr.indexOf(aKey) - tempArr.indexOf(bKey);
        });
      }
      return columnArr;
    },
    handleCheckedList (val) {
      this.checkedColumns = val;

      console.log('this.checkedColumns ', val);

      this.allColumns.forEach(item => {
        if (this.checkedColumns.findIndex((o) => { return o == item.label; }) > -1) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
      // let checkedCount = val.length;
      // this.checkAll = checkedCount === this.columns.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.columns.length;
    },
    recoverChecked () {
      this.checkedColumns = this.staticColumns;
      this.allColumns.forEach(item => {
        if (this.checkedColumns.findIndex((o) => { return o == item.label; }) > -1) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    },
    submit () {
      // 更新表格展示列
      // this.fieldList = [];
      let param = {
        SetKey: this.tableKey,
        SetValue: this.allColumns
      };
      let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
      let isHas = false;
      if (arr && arr.length > 0) {
        arr.forEach(item => {
          if (item.SetKey == this.tableKey) {
            item.SetValue = param.SetValue;
            isHas = true;
          }
        });
      }

      if (!isHas) {
        arr.push({
          SetKey: this.tableKey,
          SetValue: param.SetValue
        });
      }
      // 更新数据到本地缓存
      localStorage.setItem('AgentSettingInfor', JSON.stringify(arr));
      // 更新数据到数据库
      EditAgentSetting(param).then(res => {
        console.log('res', res);
      });
      this.$emit('afterSuccess', this.allColumns);
      this.$emit('close');
      // setTimeout(() => {
      //   this.fieldList = Object.assign([], this.getLocalDataFunc(data, this.tableColumns, 1));
      //   this.$forceUpdate();
      // }, 200);
    },
    close () {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.ancheckbox {
  text-align: left;
  padding-left: 10px;
  flex: 1;
}
.showItem {
  border: 1px solid #ddd;
  height: 30px;
  line-height: 29px;
  padding: 0 10px;
  border-bottom: none;
  background: #fff;
  float: none;
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.itemInput {
  display: inline-block;
  width: 50px;
  padding: 0px 5px;
  height: 20px;
  line-height: 20px;
  border-color: transparent;
  margin-top: 6px;
  margin-left: 5px;
  margin-right: 5px;
}
.textDiv {
  display: flex;
  flex-direction: row;
  width: 115px;
}
.inputtext {
  font-size: 12px;
}
.itemInput:hover {
  /* border: 1px solid #ddd; */
}
.itemInput >>> .el-input__inner {
  background-color: transparent;
  margin-top: -10px;
  padding: 0;
}
</style>