<template>
  <div>
    <div class="WX_message_tips">
      <div class="WX_message_tips_title">
        自定义菜单
      </div>
      <div class="WX_message_tips_text">
        1、一级菜单栏只可以加入三个菜单，二级菜单栏可以加入五个菜单。
      </div>
      <div class="WX_message_tips_text">
        2、一级菜单如果想要二级菜单的情况下，请不要填写url链接 如有填写直接视为，就是一级菜单栏目。
      </div>
      <div class="WX_message_tips_text">
        3、一级菜单后有一个添加按钮作为添加 二级菜单用。
      </div>
      <div class="WX_message_tips_text">
        4、已生成菜单，有快慢之分，最慢是一天，如果生成后没有出现在公众号中，请取消关注后重新关注即可马上浏览。
      </div>
      <div class="WX_message_tips_text">
        5、放入新菜单时，请加入正确连接地址否则视为无效.例：http://www.baidu.com。
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vDataList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :showPagination="false"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getDataList"></list>
    </div>
    <div class="WX_setting_bottom_box">
      <div>
        <div class="WX_setting_bottom_btn btn_light_blue btn_hover_bg_blue"
             @click.stop="createMenu">
          生成菜单栏
        </div>
        <div class="WX_setting_bottom_btn btn_light_blue btn_hover_bg_blue"
             style="margin-left: 20px;"
             @click.stop="addNewMenu({pid:0},$initJson.baseFunctionBtnName.speceil)">
          添加一级菜单
        </div>
      </div>
      <div class="WX_setting_bottom_btn btn_light_red btn_hover_white"
           @click.stop="isShowFrequentURL=true">
        常用链接表
      </div>
    </div>
    <!-- 常用链接弹窗宽度 -->
    <custom-dialog :title="'常用链接表'"
                   class=" "
                   :width="'900px'"
                   :visible.sync="isShowFrequentURL"
                   :before-close="closeShowFrequentURL">
      <frequent-url></frequent-url>
    </custom-dialog>
    <custom-dialog :title="vTitle"
                   class=" "
                   :visible.sync="isShowAdd"
                   :before-close="closeShowAdd">
      <add-menu :isEdit="isEdit"
                :required="required"
                :seletedItem="seletedItem"
                @close="closeShowAdd"
                @afterSuccess="getDataList"></add-menu>
    </custom-dialog>
  </div>
</template>
<script>
import addMenu from './common/add-menu';
import frequentUrl from './common/frequent-url';
import list from '../../../../report/class-spend-statistics-analyze/components/table';
import { SelectWeChatMenu, DelWeChatMenu, GenerateWeChatMenu } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataList: [

      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '编号',
          prop: 'num',
          width: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'serial',
          decimalPlace: 0,
          className: 'table_index_gray',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '链接URL',
          prop: 'urlLink',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '一级菜单',
          prop: 'levelOne',
          minWidth: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          className: '',
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '二级菜单',
          prop: 'levelTwo',
          minWidth: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          className: '',
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '',
          headerAlign: 'center',
          sortable: false,
          align: 'right',
          tableBtnGroup: [
            {
              name: '新增',
              eventName: this.$initJson.baseFunctionBtnName.speceil,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: '',
              ModulePowerKey: ''
            },
            {
              name: '修改',
              eventName: this.$initJson.baseFunctionBtnName.edit,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: '',
              ModulePowerKey: ''
            },
            {
              name: '删除',
              eventName: this.$initJson.baseFunctionBtnName.del,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: '',
              ModulePowerKey: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      isShowAdd: false,
      seletedItem: null,
      isEdit: false,   // 是否修改
      isShowFrequentURL: false,   // x显示常用链接
      title: '',
      required: false
    };
  },
  components: {
    list,
    addMenu,
    frequentUrl
  },
  created () {
    this.getDataList();
  },
  computed: {
    vDataList () {
      let mainArr = [];
      let childArr = [];
      let newArr = [];
      let num = 0;
      this.dataList.forEach(o => {
        o.CourseNameKey = 1;
        o.levelOne = '';
        o.levelTwo = '';
        if (o.pid > 0) {
          o.levelTwo = o.name;
          o.num = '';
          o.urlLink = o.url;
          childArr.push(o);
        } else {
          o.levelOne = o.name;
          o.num = num = num + 1;
          o.urlLink = o.url;
          o.childList = [];
          mainArr.push(o);
          newArr.push(o);
        }
      });
      mainArr.forEach((o, index) => {
        childArr.forEach(p => {
          if (o.id == p.pid) {
            let locaIndex = newArr.findIndex(c => {
              return c.id == o.id;
            });
            // if (locaIndex >= 0) {
            newArr[locaIndex].urlLink = '';
            newArr[locaIndex].childList.push(p);
            // }
            console.log('locaIndex', locaIndex);
            newArr.splice(locaIndex + 1, 0, p);
          }
        });
      });
      // console.log(mainArr, 'mainArr');
      return newArr;
    },
    vTitle () {
      return this.title;
    }
  },
  methods: {
    getDataList () {
      this.isShowAdd = false;
      SelectWeChatMenu().then(result => {
        this.dataList = result.data;
        this.searchObj.pageSize = this.dataList.length;
        console.log(result.data, 'SelectWeChatMenu');
      }).catch(error => {
        console.log('errorSelectWeChatMenu', error);
      });
    },
    // 生成菜单
    createMenu () {
      let isFalse = false;
      let otherFalseItem = '';
      console.log(this.vDataList, 'createMenu');
      this.vDataList.forEach(o => {
        if (o.url && (o.url.indexOf('http://') == -1 && o.url.indexOf('https://') == -1)) {
          isFalse = true;
          return false;
        } else if (!o.url && o.pid == 0 && o.childList.length == 0) {
          otherFalseItem += o.name + ',';
          return false;
        }
      });
      if (otherFalseItem) {
        otherFalseItem = otherFalseItem.replace(/(,)$/, '');
        layer.alert('请给' + otherFalseItem + '添加URL或新增一个二级菜单');
        return false;
      } else if (isFalse) {
        layer.alert('菜单链接格式不符合要求,请检查处理后再生成菜单');
        return false;
      } else {
        layer.confirm('请问是否生成菜单?', {
          btn: [{
            name: '确认',
            callBack: () => {
              GenerateWeChatMenu().then(result => {
                console.log('GenerateWeChatMenu', result);
                if (result.code != 0) {
                  layer.alert(result.msg);
                }
              }).catch(error => {
                layer.alert(error.msg);
                console.log(error, 'errorGenerateWeChatMenu');
              });
              layer.close();
            }
          }
          ]
        });
      }
    },
    // 删除
    delData (item) {
      DelWeChatMenu(item.id).then(result => {
        this.getDataList();
      }).catch(error => {
        console.log(error, 'errorDelDefaultReply');
      });
    },
    closeShowAdd () {
      this.isShowAdd = false;
    },
    closeShowFrequentURL () {
      this.isShowFrequentURL = false;
    },
    verifyData () {
      let secondaryMenuNum = 0;     // 符合条件的二级菜单数
      let level1MenuNum = 0;      // 符合条件的一级菜单数
      this.vDataList.forEach(o => {
        if (this.seletedItem.id == o.pid) {
          secondaryMenuNum++;
        }
        if (o.pid == 0) {
          level1MenuNum++;
        }
      });
      console.log(secondaryMenuNum, '二级一级菜单数', level1MenuNum);
      return { secondaryNum: secondaryMenuNum, level1Num: level1MenuNum };
      // if (this.seletedItem.id) {
      //   return secondaryMenuNum;
      // } else {
      //   return level1MenuNum;
      // }
    },
    // 添加一级菜单
    addNewMenu (rowData, eventName) {
      this.seletedItem = rowData;
      if (this.verifyData().level1Num >= 3) {
        layer.alert('一级菜单最多允许3个，不能添加。');
        return false;
      } else {
        this.doAfterTableBtnClick(rowData, 1);
      }
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      // this.seletedMDInfo = rowData;
      this.seletedItem = rowData;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.speceil:      // 新增二级菜单
          console.log('speceil');
          if (this.verifyData().secondaryNum >= 5) {
            layer.alert('二级菜单最多允许五个，不能添加。');
            return false;
          }
          // if (this.seletedItem.id > 0) { this.isEdit = false; }
          this.isEdit = false;
          this.title = '添加菜单';
          this.required = true;
          this.isShowAdd = true;
          break;
        case this.$initJson.baseFunctionBtnName.add:      // 新增一级菜单
          this.isEdit = false;
          this.required = false;
          this.title = '添加菜单';
          this.isShowAdd = true;
          break;
        case this.$initJson.baseFunctionBtnName.edit:     // 修改
          console.log('edit');
          if (this.seletedItem.pid > 0) {       // 修改二级菜单.
            this.required = true;
          } else {                        // 修改一级菜单.
            this.required = false;
          }
          this.isEdit = true;
          this.title = '修改菜单';
          this.isShowAdd = true;
          break;
        case this.$initJson.baseFunctionBtnName.del:
          layer.confirm('请问是否删除该项菜单?', {
            btn: [{
              name: '确认',
              callBack: () => {
                this.delData(this.seletedItem);
                layer.close();
              }
            }
            ]
          });

          break;
        default:
          break;
      }
    }
  }
};
</script>