<template>
  <div class="from_btn_box flex pa">
    <div class="from_next_btn btn_hover_bg_blue"
         v-if="showPre"
         @click.stop="preStep">
      上一步
    </div>
    <div class="from_next_btn btn_hover_bg_blue"
         v-if="showNext"
         @click.stop="nextStep">
      下一步
    </div>
    <div class="from_next_btn btn_hover_bg_blue"
         v-else
         @click.stop="submitData">
      发布
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isSubmitEnd: false
    };
  },
  props: {
    showPre: {
      type: Boolean,
      default: true
    },
    showNext: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    preStep () {
      this.$emit('preStep');
    },
    nextStep () {
      this.$emit('nextStep');
    },
    submitData () {
      if (!this.isSubmitEnd) {
        this.isSubmitEnd = true;
        this.$emit('submitData', this.initIsSubmitEnd);
      }
    },
    initIsSubmitEnd () {
      this.isSubmitEnd = false;
    }
  }
};
</script>
