<template>
  <!-- 云课认证详情 -->
  <div class="YK_added_view_details bg_white">
    <div v-if="!dataInfo"
         class="no_data_box">
      <img src="../../../../../public/image/cloud_class_un_authentication.png"
           class="no_data_img" />
      <div class="no_data_tips">还没有认证哦~</div>
      <div class="no_data_btn"
           @click="toCheck">去认证</div>
    </div>
    <!-- 只读模式 -->
    <div class="YK_only_box"
         v-else>
      <div class="border_bottom pr">
        <div class="YK_only_type">
          <div class="only_date">
            {{ dataInfo.ApplyTime }}
          </div>
          <!-- 0-未提交;1-认证中;2-已认证;-1-已拒绝 -->
          <div v-if="vHasSGS"
               style="display: flex">
            <div class="only_type type_green">已认证</div>
            <div class="btn_hover_bg_blue"
                 @click="toCheck">修改</div>
          </div>
          <div v-else-if="vHavingSGS"
               style="display: flex">
            <div class="only_type type_yellow">认证中</div>
            <div class="btn_hover_bg_blue"
                 @click="toWithdraw">撤回</div>
          </div>
          <div v-else-if="vRefusSGS"
               style="display: flex">
            <div class="only_type type_yellow">已拒绝</div>
            <div class="btn_hover_bg_blue"
                 @click="toCheck">修改</div>
          </div>
        </div>
        <company-info :dataInfo="dataInfo"></company-info>
      </div>
      <brand-info :dataInfo="dataInfo"></brand-info>
      <league-info :dataInfo="dataInfo"></league-info>
      <!-- 督学设置 -->
      <edu-info-form :dataInfo="dataInfo"
                     ref="eduInfo"></edu-info-form>
    </div>
  </div>
</template> 
<script>
import { GetYKPutAwayMsg, YKPutAwayWithdraw } from '../../../../API/workbench';
import eduInfoForm from './components/edu-info-form';
import companyInfo from './components/company-info';
import brandInfo from './components/brand-info';
import leagueInfo from './components/league-info';

export default {
  data () {
    return {
      dataInfo: null
    };
  },
  components: {
    companyInfo,
    brandInfo,
    leagueInfo,
    eduInfoForm
  },
  props: {},
  created () {
    this.getDataInfo();
  },
  computed: {
    vHasSGS () {
      return this.dataInfo.StatusKey == 2;
    },
    vHavingSGS () {
      return this.dataInfo.StatusKey == 1;
    },
    vRefusSGS () {
      return this.dataInfo.StatusKey == -1;
    }
  },
  methods: {
    // 去认证
    toCheck () {
      this.$emit('changeType');
      // this.$router.push('/cloudClassAttestation')
    },
    toWithdraw () {
      YKPutAwayWithdraw().then(
        (result) => {
          this.getDataInfo();
          console.log('YKPutAwayWithdraw', result.data);
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    getDataInfo () {
      GetYKPutAwayMsg()
        .then((result) => {
          this.dataInfo = result.data;
          this.$nextTick((o) => {
            console.log(this.$refs.eduInfo, 'GetYKPutAwayMsg', result.data);
            if (result.data) {
              this.$refs.eduInfo.getFormTemplate();
            }
          });
          console.log('GetYKPutAwayMsg', result.data);
        }).catch((error) => { });
    }
  }
};
</script>

<style scoped>
</style>


<!-- 只读模式 -->
	<style s>
.YK_added_view_details {
  width: 100%;
  height: 100%;
}

.YK_added_view_details .YK_added_content {
  padding-left: 270px;
}

.YK_added_view_details .YK_added_content_name {
  padding: 32px 0 24px;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
}

.YK_added_content_form .form_list {
  display: flex;
  margin-bottom: 24px;
  position: relative;
}

.YK_added_content_form .form_list.border_bottom {
  padding-bottom: 24px;
}

.YK_added_content_form .form_list_name {
  min-width: 133px;
  font-size: 14px;
  line-height: 35px;
  padding-right: 8px;
}
.content_list_form .form_list_name {
  min-width: 40px;
  padding-left: 8px;
}
.YK_added_content_form .form_list_name.required .text_box {
  position: relative;
}

.YK_added_content_form .form_list_name.required .text_box::after {
  content: '*';
  position: absolute;
  color: #ff6c67;
  right: -10px;
  line-height: 14px;
  top: 6px;
}

.YK_added_content_form .form_list_content {
  min-height: 35px;
  position: relative;
}

.YK_added_content_form .form_list_content > .flex {
  margin-bottom: 24px;
}

.YK_added_content_form .form_list_content > .flex:last-child {
  margin-bottom: 0;
}

.YK_added_content_form .form_list_input {
  width: 400px;
  height: 35px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 10px;
}

.YK_added_content_form .form_list_input_box {
  position: relative;
  margin-right: 24px;
}

.YK_added_content_form .form_list_upload {
  display: flex;
}

.YK_added_content_form .form_list_upload .upload_btn {
  width: 120px;
  height: 120px;
  background: #eeeeee;
  border: 1px dashed #dcdcdc;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding-top: 60px;
  color: #999;
  margin-right: 40px;
  position: relative;
}

.YK_added_content_form .form_list_upload .upload_btn::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #333;
  left: 50%;
  margin-left: -7px;
  top: 44px;
}

.YK_added_content_form .form_list_upload .upload_btn::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 14px;
  background-color: #333;
  left: 50%;
  margin-left: -1px;
  top: 38px;
}

.YK_added_content_form .form_list_upload .upload_text {
  margin-top: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
}

.YK_added_bottom_placeholder {
  height: 72px;
}

.YK_added_bottom_btn_box {
  position: absolute;
  bottom: 0;
  left: 113px;
  right: 113px;
  height: 72px;
  border-top: 1px solid #ececec;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.YK_added_bottom_btn_box .btn_hover_bg_blue {
  width: 175px;
  height: 40px;
  font-size: 14px;
  line-height: 38px;
  margin-left: 20px;
  text-align: center;
  border-radius: 4px;
}

.YK_added_content_form .form_list_checkbox {
}

.YK_added_content_form .form_list_checkbox .checkbox_list {
  line-height: 35px;
  height: 35px;
  padding-left: 28px;
  min-width: 108px;
  position: relative;
  margin-bottom: 17px;
}

.YK_added_content_form .form_list_checkbox.flex .checkbox_list {
  margin-bottom: 0;
  padding-right: 20px;
}

.YK_added_content_form .form_list_checkbox .checkbox_list:last-child {
  margin-bottom: 0;
}

.YK_added_content_form .form_list_checkbox .checkbox_list::after {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 2px;
}

.YK_added_content_form .form_list_checkbox .checkbox_list.opt::after {
  border-color: #3498db;
  background: #3498db url(../../../../../public/image/tick_white@2x.png) center
    center no-repeat;
  background-size: 12px;
}

.YK_added_content_form .form_list_children_box {
  line-height: 35px;
  display: flex;
  margin-right: 24px;
  margin-bottom: 10px;
}

.YK_added_content_form .form_list_children_box:last-child {
  margin-bottom: 0;
}

.YK_added_view_details
  .YK_added_content_form
  .form_list_children_box
  .children_name {
  width: 64px;
}

.YK_added_content_form .form_list_children_box .children_input {
  width: 120px;
  height: 35px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 10px;
}

.YK_added_content_form .form_list_textarea textarea {
  width: 400px;
  height: 56px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 8px 10px;
}

.YK_added_content_form .form_list_switch {
  height: 35px;
  padding-top: 2px;
}

.YK_added_content_form .form_list_input_unit {
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  color: #999;
  background-color: #f7f8f9;
  text-align: center;
  min-width: 58px;
  line-height: 33px;
}

.YK_added_content_form .el-input__inner {
  width: 400px;
  line-height: 33px;
  height: 35px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 10px;
  color: #3498db;
  position: relative;
}

.YK_added_content_form .form_course_block {
  width: 600px;
  margin-bottom: 24px;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.YK_added_content_form .form_course_block .course_title {
  line-height: 48px;
  background: #fafafa;
  border-bottom: 1px solid #ececec;
  padding-left: 24px;
}

.YK_added_content_form .form_course_block .course_content {
  display: flex;
  padding: 24px 0;
  padding-left: 24px;
  position: relative;
}

.YK_added_content_form .form_course_block .course_content_img {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  flex-shrink: 0;
  margin-right: 25px;
}

.YK_added_content_form .form_course_block .course_content_name {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 8px;
}

.YK_added_content_form .form_course_block .course_content_info {
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 22px;
}

.YK_added_content_form .form_course_block .course_content_text {
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  width: 400px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}

.YK_added_content_form .form_course_block .course_content_amount {
  position: absolute;
  right: 30px;
  top: 53px;
  font-size: 30px;
  line-height: 34px;
  color: #ff8a01;
}

.YK_added_content_form .form_course_block .course_content_amount .font_unit {
  font-size: 18px;
}

.YK_added_content_form .form_course_block .course_bottom {
  height: 82px;
  padding-top: 24px;
  border-top: 1px solid #ececec;
}

.YK_added_content_form .form_course_block .course_bottom_btn {
  width: 113px;
  height: 35px;
  line-height: 29px;
  padding-left: 16px;
  color: #666;
  border: 1px dashed #dcdcdc;
  border-radius: 3px;
  margin: 0 auto;
}

.YK_added_content_form .form_course_block .course_bottom_btn .btn_icon {
  font-size: 20px;
  margin-right: 8px;
  color: #999;
}

.YK_added_content_form .form_list_subtitle {
  line-height: 14px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 24px;
}

.YK_added_content_form .form_list_subtitle::after {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  bottom: 1px;
  width: 2px;
  background-color: #3498db;
}

.YK_added_content_form .form_list_radio {
  display: flex;
}

.YK_added_content_form .form_list_radio .radio_list {
  position: relative;
  padding-left: 26px;
  line-height: 35px;
  min-width: 80px;
  max-width: 348px;
}

.YK_added_content_form .form_list_radio .radio_list::after {
  content: '';
  position: absolute;
  left: 0;
  top: 9px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  box-sizing: border-box;
}

.YK_added_content_form .form_list_radio .radio_list.opt::after {
  border-color: #3498db;
}

.YK_added_content_form .form_list_radio .radio_list.opt::before {
  position: absolute;
  content: '';
  left: 4px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #3498db;
}

.YK_added_content_form .form_list_add {
  width: 35px;
  height: 35px;
  border: 1px dashed #dcdcdc;
  border-radius: 3px;
  margin-left: -16px;
}

.YK_added_content_form .form_list_add {
  font-size: 20px;
  color: #999999;
  line-height: 1;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.form_extra_box {
  padding: 10px 20px;
}

.form_extra_box .bg_white {
  border-radius: 4px;
}

.form_extra_list {
  padding: 0 20px;
  border-bottom: 1px solid #ececec;
}

.form_extra_list:last-child {
  border-bottom: none;
}

.form_extra_list .form_info_line {
  border-bottom: none;
}

.form_extra_btn {
  color: #3498db;
  width: 100%;
  line-height: 52px;
  font-size: 15px;
  padding-left: 25px;
  background: #fff url(../../../../../public/image/add_icon@2x.png) no-repeat
    144px center;
  background-size: 17px;
  margin-top: 10px;
  padding-left: 170px;
}

.YK_added_view_details .form_info_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 10px;
  min-height: 52px;
}

.YK_added_view_details .form_info_box .info_name {
  line-height: 20px;
}

.YK_added_view_details .form_info_box .info_name .font_gray {
  color: #999;
}

.YK_added_view_details .form_info_box .info_number {
  padding-right: 15px;
  color: #ff9b00;
  background: url(../../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 8px;
  cursor: pointer;
}
.YK_only_box {
  width: 1366px;
  margin: 0 auto;
  /* padding-left: 166px;
  padding-right: 60px; */
  position: relative;
}

.YK_only_box.mini_type {
  width: 882px;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 0;
  position: relative;
}

.YK_only_type {
  position: absolute;
  right: 0;
  top: 25px;
  display: flex;
}

.YK_only_type .btn_hover_bg_blue {
  width: 76px;
  height: 35px;
  line-height: 33px;
  text-align: center;
  border-radius: 4px;
  margin-left: 24px;
}

.YK_only_type .only_type {
  min-width: 84px;
  height: 30px;
  background: #f7f8f9;
  border-radius: 4px;
  padding-left: 33px;
  padding-right: 10px;
  line-height: 30px;
  margin-top: 3px;
  background-repeat: no-repeat;
  background-position: 10px 6px;
  background-size: 18px;
  margin-left: 8px;
}

.YK_only_type .only_type.type_green {
  background-image: url(../../../../../public/image/lable_type_tick.png);
}

.YK_only_type .only_type.type_yellow {
  background-image: url(../../../../../public//image/lable_type_warning.png);
}

.YK_only_type .only_type.type_red {
  background-image: url(../../../../../public//image/lable_type_no.png);
}

.YK_only_type .only_date {
  font-size: 16px;
  line-height: 35px;
  color: #999;
}

.YK_only_box .form_list_name {
  color: #999;
  line-height: 20px;
}

.YK_only_box .form_list_content {
  line-height: 20px;
  min-height: 20px;
  /* max-width: 600px; */
}

.YK_added_content_form .form_list_img {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  overflow: hidden;
}
.form_list_content.show_left_Line::after {
  content: '';
  width: 1px;
  height: 15px;
  background: black;
  position: absolute;
  right: 0;
  top: 3px;
}

.no_data_img {
  position: absolute;
  left: 0;
  right: 0;
  top: 314px;
  margin: auto;
}
.no_data_tips {
  position: absolute;
  left: 0;
  right: 0;
  top: 488px;
  margin: auto;
  text-align: center;
}
.no_data_btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 530px;
  margin: auto;
  text-align: center;
  width: 112px;
  height: 40px;
  line-height: 38px;
  background-color: #f8f8fa;
  border: 1px solid #e1e4e7;
  border-radius: 4px;
}
.YK_added_view_details .border_bottom {
  border-bottom: 1px solid #ececec;
  padding-left: 166px;
  padding-right: 60px;
}
</style>