<template>
     <div class="class_detail_teacher_list">
            <div class="class_detail_teacher_list_title">
                <span>{{title}}</span>
            </div>
            <div class="class_detail_teacher_list_content">

                <teacher-item v-for="(item,key) in dataList" :key="key" :item="item" @seletdItem="seletdItem" @delItem="delItem" :showDel="showDel"></teacher-item>
                <span class="class_detail_teacher_list_item font_blue button" v-if="showAdd" @click.stop="addItem">+</span>
            </div>
            </div>
</template>
<script>
import teacherItem from './teacher-item'
export default {
    name:'teacherList',
    data(){
        return{

        }
    },
    components:{
        teacherItem
    },
    props:{
        title:String,
        dataList:Array,
        showDel:Boolean,
        showAdd:Boolean,
        isTeacher:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        seletdItem(item){
            this.$emit("seletdItem",item)
        },
        delItem(item){
            this.$emit("delItem",item)
        },
        addItem(){
            this.$emit("addItem")
        }
    }
}
</script>