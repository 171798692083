<template>
  <div class="margin_left_right_div">
    <div class="general_summarizing_student payment_statistics_view height_type all_view template_minOrmax_width_div">
      <div class="single_result_detail"
           style="width:100%">

        <div class="accraditation_summary">
          <div class="course_class_summarizing_right"
               style="width:100%">
            <div class="flex">
              <div class="right_top_box">
                <title-summary-data v-for="(item,key,index) in headSummaryData"
                                    :sumData="item"
                                    :key="index"
                                    @onCilckEvent="doTitleBoxClick"></title-summary-data>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="course_statistics_table_search course_statistics_title pr">
        <div v-if="batchAllotList.length>0">
          <batch-distribution-box :batchAllotList="batchAllotList"
                                  @doCancelAllot="doCancelAllot"></batch-distribution-box>
        </div>

        <div class=""
             v-if="batchAllotList.length==0">
          <div class="">
            <date-range ref="monthPicker"
                        style="margin-right:36px"
                        :fromTitle="'登记日期'"
                        :searchObj="searchObj"
                        @changeDate="changeSaveDate"></date-range>
          </div>
        </div>
        <div v-if="isStudentStatus"
             class="top_select_box"
             :style="batchAllotList.length==0?'width: 160px;margin-right: 22px;':'width: 160px;margin-right: 260px;'">
          <div class="top_date_select_title">状态</div>
          <div class="table_select_title">
            <el-select v-model="StudentStatusList.StudentStatusValue"
                       value-key="key"
                       placeholder="选择"
                       @change="doSelectedDateType">
              <el-option v-for="item in optionsDateType"
                         :key="item.key"
                         :label="item.label"
                         :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="course_statistics_search"
             v-if="batchAllotList.length==0">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
        </div>
        <div class="summarizing_number_right_btn"
             style="top: 14px;right: 22px;">
          <div @click="goRouter"
               class="recycled_btn el-button btn_light_blue btn_hover_bg_blue el-button--default">
            回收站
          </div>
          <a class="el-button btn_light_blue btn_hover_bg_blue"
             :href="$store.getters.PCWebURL + '/ImportTemplate/学生线索导入模板.xls'">下载模板</a>
          <div class="el-button btn_light_blue btn_hover_bg_blue"
               style="padding:0">
            <el-upload accept=".xls, .xlsx"
                       action="string"
                       ref="upload"
                       :show-file-list="false"
                       :http-request="importEvent">导入</el-upload>
          </div>
        </div>
        <!-- <div class="mendian_backstage_search">
        <div class="btn_hover_bg_blue search_btn recycled_btn el-button "
             style="margin: 0;"
             @click="goRouter">回收站</div>
      </div> -->
      </div>
      <div class="course_statistics_table_box table_list_content follow_clue_list">
        <table-list class="summarizing_list"
                    ref="tableListRef"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :rowKey="'StudentKey'"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    :isShowCheckBox="true"
                    @changeCheckBoxFun="changeCheckBoxFun"
                    @loadTableData="getDataList"></table-list>
      </div>
      <custom-dialog :title="'分配线索'"
                     class=" "
                     width="410px"
                     :visible.sync="showAllot"
                     :before-close="closeshowAllot">
        <allot-dialog :dataInfo="seletedInfo"
                      :studentArray="studentArray"
                      @updateData="updateData"
                      @closeDialog="closeshowAllot"></allot-dialog>
      </custom-dialog>
      <div>
        <dialog-factory ref="waitDistributionClueDialogFactory"
                        :key="'waitDistributionClue'"
                        :routerName="'waitDistributionClue'"></dialog-factory>

        <dialog-factory-common ref="waitDistributionClueDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import allotDialog from './allot-dialog';
import dialogFactory from '../../../common/dialog-factory';
import titleSummaryData from './title-summary-data';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import batchDistributionBox from './batch-distribution-box';
import { GetWaitAllocationThread, SaleStatRecycleStudent, DoAllocationThread, ImportMakeUp, ImportStudentThread } from '../../../../API/workbench';
export default {
  name: 'waitDistributionClue',
  data () {
    return {
      seletedInfo: {},    // 选中的单项
      studentDetail: {},
      headSummaryData: [
        { title: '全部', className: '', value: 0, StatusKey: 0, decimalPlace: 0, isSelected: true },
        { title: '新生', className: '', value: 0, StatusKey: 1, decimalPlace: 0, isSelected: false },
        { title: '放弃', className: 'font_red', value: 0, StatusKey: 2, decimalPlace: 0, isSelected: false }
      ],
      isStudentStatus: true,
      StudentStatusList: {
        StudentStatusKey: 0,
        StudentStatusValue: '全部'
      },
      optionsDateType: [
        {
          key: 0,
          label: '全部'
        },
        {
          key: 1,
          label: '在校'
        },
        {
          key: 2,
          label: '潜客'
        },
        {
          key: 3,
          label: '离校'
        }
      ],
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        type: 0,
        StudentStatusKey: 0
      },
      salesList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      studentArray: [],     // 选中的分配学生.
      showAllot: false, // 是否显示分配弹出层
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '登记日期',
          prop: 'RegisterTime',
          minWidth: 160,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 100,
          sortable: false,
          type: 'student',
          extend: {
            studentKey: 'StudentKey',
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '意向级别',
          prop: 'WillingnessRateKeyValue',
          width: 60,
          sortable: false,
          align: 'center',
          type: 'textItem',
          columnClass: 'font_dual'
        },
        {
          label: '兴趣课',
          prop: 'FineSupport',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '到店参观',
          prop: 'ToStoreAppointTime',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              return this.setStoreClassType(rowData, 'ToStoreResultKey');
            }
          }
        },
        {
          label: '试课记录',
          prop: 'TrialClassValue',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              return this.setTrialClassType(rowData, 'TrialClassResultKey');
            }
          }
        },
        {
          label: '是否报名',
          prop: 'EnlistTimeApply',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '跟进情况描述',
          prop: 'followContent',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '下次跟进',
          prop: 'NextFollowUpTime',
          sortable: false,
          minWidth: 85,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              if (rowData.IsAbandonKey == 1) {
                return 'font_red';
              }
            }
          }
        },
        {
          label: '跟进人',
          prop: 'AdviserKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '120',
          sortable: false,
          align: 'center',
          type: 'tableBtn',
          extend: {
            tableBtnGroup: [
              {
                name: '分配',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.allotData(rowData);
                  }
                }

              },
              {
                name: '删除',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.deletedData(rowData);
                  }
                }

              }
            ]
          }
        }

      ],
      dataReportList: [],
      batchAllotList: [], // 批量分配列表
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    titleSummaryData,
    dialogFactory,
    allotDialog,
    batchDistributionBox
    // seletedOption
  },
  props: {
  },
  created () {
    console.log('this.toSearchObj');
    // if (this.$route.query.startTime) {
    this.searchObj.startTime = this.$route.query.startTime || '';
    this.searchObj.endTime = this.$route.query.endTime || '';
    this.searchObj.saleManValue = this.$route.query.SaleMan || '';
    this.searchObj.saleMan = this.$route.query.SaleManKey || 0;
    if (this.$route.query.type >= 0) {
      this.searchObj.type = this.$route.query.type || 0;
      this.headSummaryData.forEach(o => {
        if (o.StatusKey == this.$route.query.type) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
    }
    // } else {
    //   // this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    //   // this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    // }
  },
  mounted () {
    this.getDataList();
    this.resgisterBusEvent();
  },
  computed: {
    vsalesList () {
      return this.salesList;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.NextFollowUpTime = '2021-10-11 16:30:25';
        // o.Content = '测试133113';
        // o.FollowUpTime = '2021-10-11 16:30:25';.
        o.ToStoreResult = '';
        o.TrialClassResult = '';
        o.ToStoreAppointTime = o.ToStoreAppointTime || '';
        o.TrialClassAppointTime = o.TrialClassAppointTime || '';
        o.WillingnessRateKeyValue = o.WillingnessRateKeyValue.substring(0, 1);
        o.AdviserKeyValue = o.AdviserKeyValue || '-';
        o.EnlistTimeApply = o.EnlistTime ? '是' : '否';
        o.followContent = o.Content ? `<span class="font_gray">` + this.$utils.StrForTodoTime(o.FollowUpTime) + `:</span>` + o.Content : '';
        o.ToStoreAppointTime = o.ToStoreAppointTime || '';
        o.TrialClassAppointTime = o.TrialClassAppointTime || '';
        o.ToStoreAppointTime = o.ToStoreAppointTime.substring(5, 10);
        if (o.TrialClassAppointTime) {
          o.TrialClassValue = `<div class="flex try_course_apoint">` + o.TrialClassAppointTime.substring(5, 10) + `</div><div>` + o.OrderClassKeyValue + `</div>`;
        }
        if (o.IsAbandonKey == 1) {
          o.NextFollowUpTime = '已放弃';
        } else {
          o.NextFollowUpTime = (o.NextFollowUpTime ? this.$utils.leadTime(o.NextFollowUpTime.substring(0, 10)) + ' ' + `<div>` + o.NextFollowUpTime.substring(5, 10) + `</div>` : '');
        }
        if (o.ToStoreResultKey >= 0) {
          o.ToStoreResult = o.ToStoreAppointTime.substring(5, 10);
        }
        if (o.TrialClassResultKey >= 0 && o.OrderClassKeyValue) {
          o.TrialClassResult = `<div class="result_` + o.TrialClassResultKey + `">` + o.TrialClassAppointTime.substring(5, 10) + `</div>` + `<div class="font_gray">` + o.OrderClassKeyValue + `</div>`;
        }

        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      // if (this.totalAmount > 0) {
      //   text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // } else {
      //   text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // }
      return text;
    }
  },

  methods: {
    // 选择筛选类型
    doSelectedDateType (item) {
      this.searchObj.StudentStatusKey = item.key;
      this.StudentStatusList.StudentStatusKey = item.key;
      this.StudentStatusList.StudentStatusValue = item.label;
      this.searchObj.pageIndex = 0;
      this.getDataList();
      console.log(item, '选择', this.StudentStatusList);
    },
    setStoreClassType (item, type) {
      if (item[type] == 0) {
        return '';
      } else if (item[type] == 1) {
        return 'list_type_green';
      } else if (item[type] == 2) {
        return 'list_type_red';
      } else if (item[type] == 3) {
        return 'list_type_red';
      } else {
        return '';
      }
    },
    setTrialClassType (item, type) {
      if (item[type] == 0) {
        return 'list_Trial_type_gray';
      } else if (item[type] == 1) {
        return 'list_Trial_type_green';
      } else if (item[type] == 2) {
        return 'list_Trial_type_red';
      } else if (item[type] == 3) {
        return 'list_Trial_type_red';
      } else {
        return '';
      }
    },
    doTitleBoxClick (item) {
      console.log(item, 'doAfterTitleBoxClick');
      if (item.StatusKey == 0) {
        this.isStudentStatus = true;
      } else {
        this.isStudentStatus = false;
      }
      // 切换时清除已选中的复选项
      if (this.searchObj.type != item.StatusKey) {
        this.doCancelAllot();
      }
      // this.accraditationList = [];
      this.headSummaryData.forEach(o => { // 高亮显示
        if (item.title == o.title) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.searchObj.type = item.StatusKey;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '全部';
      this.StudentStatusList.StudentStatusKey = 0;
      this.StudentStatusList.StudentStatusValue = '全部';
      this.searchObj.StudentStatusKey = 0;
      this.getDataList();
    },
    // 销售人员层显示并获取数据
    setAfterDay (dateStr) {
      if (!dateStr) {
        return '';
      }
      let times = Date.new(dateStr).getTime() - Date.new(Date.new().toLocaleDateString()).getTime();
      if (times == 0) {
        return '今天';
      } else if (times > 0) {
        return (times / 86400000) + '天后';
      } else {
        return '已过期';
      }
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.PastDueType = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '全部';
      this.searchObj.StudentStatusKey = 0;
      this.StudentStatusList.StudentStatusKey = 0;
      this.StudentStatusList.StudentStatusValue = '全部';
      // this.searchObj.type = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      GetWaitAllocationThread(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.headSummaryData[0].value = result.data.CountStat.AllNum;
        this.headSummaryData[1].value = result.data.CountStat.newStudengtNum;
        this.headSummaryData[2].value = result.data.CountStat.AbandonNum;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
        if (result.data.PageDataList.length == 0 && this.searchObj.pageIndex > 0) {
          this.searchObj.pageIndex = this.searchObj.pageIndex - 1;
          this.getDataList();
        }
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    closeshowAllot () {
      this.showAllot = false;
    },
    // 分配
    allotData (item) {
      this.seletedInfo = item;
      this.studentArray = [];
      this.studentArray.push(this.seletedInfo.StudentKey);
      this.showAllot = true;
    },
    // 批量分配
    changeCheckBoxFun (list) {
      this.batchAllotList = list;
      console.log(this.batchAllotList, '批量分配');
    },
    // 取消分配
    doCancelAllot (succeed) {
      if (succeed) {
        this.getDataList();
      }
      this.batchAllotList = [];
      this.$refs.tableListRef.checkBoxList = [];
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
    },
    // 单个分配
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.doCancelAllot();
      this.getDataList();
    },
    // 删除
    deletedData (item) {
      layer.confirm('请问是否删除当前项', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              SaleStatRecycleStudent(item.OLAPKey).then(result => {
                console.log('RecycleStudent');
                layer.alert('删除成功');
                this.doCancelAllot();
                this.getDataList();
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    },

    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.$dialog.open(this, {
            name: '学生档案',
            routerName: this.$route.name,
            moduleName: 'studentFile',
            data: { studentID: rowData.StudentNumKey }
          });

          break;
        default:
          break;
      }
    },
    selectOverdueType (item) {
      console.log('selectOverdueType');
      this.searchObj.saleMan = item.value;
      this.searchObj.saleManValue = item.label;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.waitDistributionClueDialogFactory) {
        this.$refs.waitDistributionClueDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.waitDistributionClueDialogFactoryCommon) {
        this.$refs.waitDistributionClueDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    goRouter () {
      this.$router.push({
        name: 'clueRecycleBin'
      });
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'waitDistributionClue') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'waitDistributionClue') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    // 导入
    importEvent (param) {
      // this.showProgress = true;
      // this.ProgressHintText = '导入中...';
      const formData = new FormData();
      formData.append('file', param.file);
      // this.saveOldStudentEnterTemp(() => {
      ImportStudentThread(formData).then(result => {
        console.log('导入成功', result.data);
        this.getDataList();
        layer.alert('导入成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        // this.showProgress = false;
        this.$refs.upload.clearFiles(); // 上传成功之后清除历史记录
      });
      // });
    }

  }
};
</script>
<style scoped>
.top_select_box {
  display: flex;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-left: 15px;
}
.top_select_box >>> .el-select {
  height: 100%;
}
.top_select_box >>> .el-select .el-input {
  font-size: 12px;
}
.top_select_box >>> .el-select .el-input__inner {
  line-height: 34px;
  min-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top_select_box >>> .el-select .el-input__suffix {
  top: 0;
}
.top_date_select_title {
  line-height: 34px;
  width: 40px;
  position: absolute;
  margin-left: -39px;
}
.table_select_title {
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  margin-left: 0px;
  height: 34px;
}
</style>