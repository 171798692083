<template>
  <div class="new_course_box bg_white"
       style="padding-bottom: 75px;">
    <!-- 第一步：课表基本信息 -->
    <div v-show="isShowCourseDetail"
         class="class_table_form form_info_edit content_marginTop_30">
      <course-detail class="form_info_bottom"
                     :courseTableData="courseTableData"
                     @nextStep="nextStepEvent"
                     @cancelClick="cancelEditCourseClick"></course-detail>
    </div>
    <!-- 第二步：设置上课时间 -->
    <div v-show="!isShowCourseDetail">
      <!--课 基本信息 -->
      <course-Info :courseTableData="courseTableData"
                   :isClassGradeWarning="isClassGradeConflictTips"
                   @editCourse="editBasicCourseData"></course-Info>
      <!-- 日历建课 -->
      <div>
        <calendar-choose ref="calendar"
                         :courseTableData="courseTableData"
                         @selectTimeRange="selectTimeRange"></calendar-choose>
      </div>
      <!-- 按钮组 -->
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               :btnText="'确认'"
                               @confirmClick="confirmClick"
                               @cancelClick="cancelClick"></save-cancel-btn-group>
      </div>
    </div>
    <!-- 冲突提示 -->
    <div v-if="isShowCoursesConflictTips">
      <courses-conflict-tips :generatedClassTimeData="generatedClassTimeData"
                             @changeClassGrade="toChangeClassGrade"
                             @newClassGrade="toNewClassGrade"
                             @backToEdit="tobackToEdit"></courses-conflict-tips>
    </div>
  </div>
</template>

<script>
import courseDetail from './course-detail';
import courseInfo from './course-info';
import operationProcess from '../../common/operation-process';
import calendarChoose from './calendar-choose';
import coursesConflictTips from '../common/courses-conflict-tips';
import {
  getUsingClass,
  AddSubscribeCourse
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      isAdding: true, // 是否正在新增规律课块
      isShowCourseDetail: true,
      courseTableData: {
        GradeClassKey: 0,
        GradeClassKeyValue: '',
        CourseNameKey: 0,
        CourseNameKeyValue: '',
        FullPeopleCount: 0,
        StartCourseCount:0, 
        CircleStartTime: '',
        CircleEndTime: '',
        WeekKey: 0,
        SClassTimeName: '',
        EClassTimeName: '',
        ClassroomKey: '',
        ClassroomKeyValue: '',
        MTeacherKeyValue: '',
        MTeacherKey: '',
        PeriodCount: 1.0,
        IsChargesKey: 0,
        IsChargesKeyValue: '',
        IsleaveKey: 0,
        WarnLossCount: 0,
        IsleaveKeyValue: '',
        frequenceInfo: [],
        isAdding: true,
        DefaultTimeCount: '',
        TimeQuantum: '', // 建课时段
        DurationCount: 0,  // 建课时长
        DateList: []    // 选中日期列表
      },
      generatedClassTimeData: [],
      isShowProgress: false,
      structuredPlanList: [],
      isShowCoursesConflictTips: false, // 班级冲突弹出提示
      isClassGradeConflictTips: false, // 班级冲突警告
      gradeClassList: [],
      isShowEditDialog: false,
      editCourseTableData: {},
      isEditPlan: false,
      selectTimeInfo: null
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    courseDetail,
    operationProcess,
    courseInfo,
    coursesConflictTips,
    calendarChoose
  },
  created () {
    if (JSON.stringify(this.dataInfo) != '{}') {
      Object.assign(this.courseTableData, this.dataInfo);
    }
    console.log('this.created', this.dataInfo);
    if (this.dataInfo && this.dataInfo.CircleStartTime) {
      this.courseTableData.DateList.push(this.dataInfo.CircleStartTime);
    }
  },
  mounted () {
    this.$bus.on(this, 'AfterClassGradeAddOrEdit', (type, result) => {
      this.courseTableData.GradeClassKey = result.OLAPKey;
      this.courseTableData.GradeClassKeyValue = result.MainDemoName;
      this.isClassGradeConflictTips = false;
    });
    this.$dialog.register(this, ['squadClassSetting']);
  },
  computed: {
    vTips () {
      let str = '';
      if (this.isEditPlan) {
        str = '正在批量修改课...';
      } else {
        str = '正在批量新增课...';
      }
      return str;
    }
  },
  watch: {
    'courseTableData.GradeClassKey': {
      handler (n, o) {
        if (n != o) {
          this.isClassGradeConflictTips = false;
        }
      }
    }
  },
  methods: {
    selectTimeRange (item) {
      this.selectTimeInfo = item;
    },
    // 验证要提交的数据
    verifySubmitData () {
      let flag = false;
      if (!this.courseTableData.SClassTimeName) {
        flag = true;
        layer.alert('上课时间不能为空');
      }
      return flag;
    },
    // 确定
    confirmClick (callBack) {
      if (this.selectTimeInfo) {
        this.courseTableData.TimeQuantum = this.selectTimeInfo.SClassTimeName + '-' + this.selectTimeInfo.EClassTimeName;
        this.courseTableData.DurationCount = Number(this.selectTimeInfo.EClassTimeName.split(':')[0]) * 60 + Number(this.selectTimeInfo.EClassTimeName.split(':')[1]) - (Number(this.selectTimeInfo.SClassTimeName.split(':')[0]) * 60 + Number(this.selectTimeInfo.SClassTimeName.split(':')[1]));
      } else {
        layer.alert('请选择时间段');
        this.courseTableData.TimeQuantum = '';
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      if (this.courseTableData.DateList.length == 0) {
        layer.alert('请选择建课日期');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      } else {
        // 判断是否超过一年.
        let isOverYear = this.courseTableData.DateList.findIndex(o => {
          return Date.new(o) >= Date.new((Date.new(Date.new().toLocaleDateString()).getTime() + 31536000000));
        });
        if (isOverYear >= 0) {
          layer.alert('最长只能建立一年内的课');
          if (typeof callBack == 'function') {
            callBack();
          }
          return false;
        }
      }
      let isBeforeToday = this.courseTableData.DateList.findIndex(o => {
        return Date.new(o) < Date.new(Date.new().toLocaleDateString());
      });
      if (isBeforeToday >= 0) {
        layer.confirm('当前建课包含过去的时间，是否继续建课？',
          {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: () => {
                  layer.close();
                  callBack();
                  return false;
                }
              },
              {
                name: '确认',
                callBack: () => {
                  this.comfireSubmit(callBack);
                  // layer.close();
                }
              }
            ]
          }
        );
      } else {
        console.log(this.courseTableData, 'confirmClick');
        this.comfireSubmit(callBack);
      }
    },
    comfireSubmit (callBack) {
      if (!this.courseTableData.GradeClassKey) {
        let that = this;
        let className = this.newClassGradeName();
        layer.confirm('未选择班级，将为您建一个' + className + '，是否继续？',
          {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: () => {
                  layer.close();
                  callBack();
                  return false;
                }
              },
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                  that.courseTableData.GradeClassKeyValue = className;
                  that.batchSubmit(callBack);
                }
              }
            ]
          }
        );
      } else {
        console.log(this.courseTableData, 'confirmClick');
        this.batchSubmit(callBack);
      }
    },
    // 提交
    batchSubmit (callBack) {
      this.isShowProgress = true;
      let courseBillItemData = this.$utils.parseJson(this.courseTableData);
      delete courseBillItemData.frequenceInfo;
      let courseBillArr = [];
      this.courseTableData.frequenceInfo.forEach(value => {
        courseBillItemData.WeekKey = value == 0 ? 7 : value;
        courseBillArr.push(this.$utils.parseJson(courseBillItemData));
      });
      AddSubscribeCourse(courseBillItemData).then(result => {
        this.isShowProgress = false;
        this.isAdding = false;
        if(result.code==0){

          layer.alert('建课成功');
          if (typeof callBack == 'function') {
            callBack();
          }
          this.$emit("afterSuccess")
          this.$emit('closeDialog');
          
        }else{
          layer.alert(result.msg);
        }
        
      }, error => {
        this.isShowProgress = false;
        if (error.code == 1) {
          layer.alert(error.msg);
        } else if (error.code == 10000) {
          layer.alert(error.msg);
        } else if (error.code == 60101) {
          this.isShowCoursesConflictTips = true;
          this.isClassGradeConflictTips = true;
          this.getValidClassList();
        } else {
          layer.alert(error.msg);
        }
        if (typeof callBack == 'function') {
          callBack();
        }
      });
    },
    // 取消新建课
    cancelClick (type) {
      this.$emit('closeDialog');
    },
    newClassGradeName () {
      let lastStr = this.courseTableData.CourseNameKeyValue.substring(this.courseTableData.CourseNameKeyValue.length - 1);
      let courseName = '';
      if (lastStr == '课') {
        courseName = this.courseTableData.CourseNameKeyValue.substring(0, this.courseTableData.CourseNameKeyValue.length - 1);
      } else {
        courseName = this.courseTableData.CourseNameKeyValue;
      }
      return courseName + '班';
    },
    // 更换班级
    toChangeClassGrade (id, name) {
      this.closeConflictTip();
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择班级',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseTableData.GradeClassKey, dataSourceList: this.gradeClassList, required: true, addModuleName: '' },
        callBack: {
          onSelectedItem: (data) => { this.selectClassGradeForConflict(data); }
        }
      });
    },
    // 新建班级
    toNewClassGrade () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '新增班级',
        moduleName: 'squadClassSetting',
        data: { editItem: {}, isReadonly: false }
      });
      this.closeConflictTip();
    },
    // 返回修改
    tobackToEdit () {
      this.closeConflictTip();
    },
    // 关闭冲突提示
    closeConflictTip () {
      this.isShowCoursesConflictTips = false;
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        result.data.forEach(obj => {
          if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
            this.gradeClassList.push(obj);
          }
        });
      });
    },
    // 班级冲突选择班级
    selectClassGradeForConflict (item) {
      this.courseTableData.GradeClassKey = item.OLAPKey;
      this.courseTableData.GradeClassKeyValue = item.MainDemoName;
    },
    // 选择班级
    doAfterSelectClassGrade (item) {
      this.courseTableData.GradeClassKey = item.OLAPKey;
      this.courseTableData.GradeClassKeyValue = item.MainDemoName;
      this.getValidClassList();
    },
    // 修改课的基础信息
    editBasicCourseData () {
      this.isShowCourseDetail = true;
    },
    // 下一步
    nextStepEvent (courseData) {
      this.isNext = true;
      console.log("nextStepEvent",courseData)
      Object.assign(this.courseTableData, courseData);
      this.cancelEditCourseClick();
    },
    // 关闭修改课弹窗
    cancelEditCourseClick () {
      if (!this.isNext) {
        this.cancelClick();
      } else {
        this.isShowCourseDetail = false;
        this.$refs.calendar.getTimeRangeCourseData();
      }
    }
  }
};
</script>
<style scoped>
.new_course_box .class_table_form::after{
  display: none;
}
</style>