<template>
  <div class=" form_info_line el_switch_form_info_field"
       :class="[{form_info_required:required},className]">
    <div class=" form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class=" form_info_value ">
      <el-switch :disabled="readonly"
                 v-model="vValue"
                 @change="changeSwitch"></el-switch>
    </div>
    <span style="position: absolute;right: 0;top: 17px;"
          v-if="showInputValue">{{vValue?'是':'否'}}</span>
  </div>
</template>
<script>
export default {
  name: 'inputSwitch',
  data () {
    return {

    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    showInputValue: {
      type: Boolean,
      default: true
    },
    className: String,
    value: [Boolean, String, Number], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改,
    helpDescription: String // 表单说明提示
  },
  computed: {
    vValue: {
      get () {
        return this.value == 1;
      },
      set (newValue) {
        this.$emit('input', newValue ? 1 : 0);
      }
    }
  },
  created () {

  },
  methods: {
    changeSwitch () {
      this.$emit('change');
    }
  }
};
</script>

