import { fetchToken } from '../utils/fetch';

// 获取 课类课名 列表
export function getCourseTypeNameList (orderBy, sequence) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.GetNotDelCourse',
      orderBy: orderBy || 'MainDemoName', // 字符串	可选		数据源：GET	排序字段
      sequence: sequence || 'asc' //	字符串	可选		数据源：GET	排序方向
    },
    method: 'GET'
  });
}
// 获取班级列表GetUsingClass  //返回未删除的班级.
export function getUsingClass (StatusKey, orderBy, sequence) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetUsingClass',
      StatusKey: StatusKey, //	班级状态 4-进行中 5-已关闭
      orderBy: orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: sequence //	字符串	可选		数据源：GET	排序方向
    },
    method: 'GET'
  });
}
// 获取在职老师接口
export function getTeacherList () {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetIncumbentTeachEmployee'
    },
    method: 'GET'
  });
}
