<template>
  <div>
    <div class="signuppayment_amount_box bg_gray">
     <div class="signuppayment_amount_list">
        <div class="list_title">
          订单金额
        </div>
        <div class="list_content">
          {{ formData.RecableAmount==0?formData.DepositAmount: formData.RecableAmount}}
        </div>
      </div>
      <div class="signuppayment_amount_list" v-if="formData.DepositAmount">
        <div class="list_title">
          预付款
        </div>
        <div class="list_content">
          {{formData.DepositAmount}}
        </div>
      </div>
      <div class="signuppayment_amount_list" v-if="sumAmount>0">
       <input-number :max="vMaxStoPayAmount"
                      :min="0"
                      :formTitle="vStoPayAmountFormTitle"
                      :value="formData.StoPayAmount"
                      :placeholder="'0.00元'"
                      :decimalPlaces="2"
                      @inputChange="changeStoPayAmount"></input-number>
      </div>
      <div class="signuppayment_amount_list list_bottom">
        <input-number :max="formData.RecableAmount"
                      :min="0"
                      :formTitle="'实收金额'"
                      :value="formData.ActualColAmount"
                      :placeholder="'0.00元'"
                      :decimalPlaces="2"
                      @inputChange="changeActualColAmount"></input-number>
         <span class="font_gray" v-if="formData.DebtAmount>0">欠款<span class="font_red">￥{{formData.DebtAmount}}</span></span>
      </div>
    </div>
    <div class="signuppayment_amount_paytype ">
      <div class="paytype_title">
        收款方式
      </div>
      <div class="paytype_ul">
        <div  v-for="item in accountList" :key="item.OLAPKey">
          <div class="paytype_list" @click.stop="chooseCollectionAccount(item)" 
            :class="{opt:item.isActive,card_type:item.AccountTypeKey==1||item.AccountTypeKey==2,alipay_type:item.AccountTypeKey==3,WX_type:item.AccountTypeKey==4,cash_type:item.AccountTypeKey==5,other_type:item.AccountTypeKey==6}" >
            {{item.MainDemoName}}
          </div>
        </div>
      </div>
      <div class="paytype_btn btn_hover_bg_blue" @click.stop="collectionClick">
        立即收款
      </div>
    </div>
  </div>
</template>
<script>
import { getIncomeAccount } from '../../../../API/workbench.js';
export default {
  props: {
    submitData: Object,
    sumAmount: Number // 客户储值余额
  },
  data () {
    return {
      orederAmount: 0, // 订单金额=当前应收 +  预付
      accountList: [],
      curSelectionAccount: {},
      formData: {}
    };
  },
  computed: {
    vStoPayAmountFormTitle () {
      return '账户余额 （¥' + this.sumAmount + ')';
    },
    vMaxStoPayAmount () {
      let max = this.sumAmount;
      let curRecableAmount = this.formData.RecableAmount - this.formData.DepositAmount;
      if (this.sumAmount >= curRecableAmount) {
        max = curRecableAmount;
      }
      return max;
    }
  },
  created () {
    this.formData = this.$utils.parseJson(this.submitData);
    console.log(this.formData, this.sumAmount, 'this.formDatathis.formData');
    this.initAmount();
    this.loadCollectAccountList();
  },
  methods: {
    // 初始化金额
    initAmount () {
      if (this.sumAmount > 0) {
        if (this.sumAmount >= this.formData.ActualColAmount) {
          this.formData.StoPayAmount = this.formData.ActualColAmount;
          this.formData.ActualColAmount = 0;
        } else {
          this.formData.StoPayAmount = this.sumAmount;
          this.formData.ActualColAmount = this.$utils.mMoneyType(this.formData.ActualColAmount - this.formData.StoPayAmount, 2);
        }
      } else {
        this.formData.ActualColAmount = this.formData.ActualColAmount;
      }
    },
    // 收款账户列表
    loadCollectAccountList () {
      getIncomeAccount().then(
        result => {
          this.accountList = result.data;
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 选择收款账户
    chooseCollectionAccount (item) {
      this.formData.CollectAccontKey = item.OLAPKey;
      this.formData.CollectAccontKeyValue = item.MainDemoName;
      this.$set(this.curSelectionAccount, 'isActive', false);
      this.curSelectionAccount = item;
      this.$set(this.curSelectionAccount, 'isActive', true);
    },
    // 改变实收
    changeActualColAmount (value) {
      this.formData.ActualColAmount = value;
      let remainAmount = this.$utils.mMoneyType(this.formData.RecableAmount - this.formData.ActualColAmount - this.formData.DepositAmount, 2);
      // 剩余金额 > 0
      if (remainAmount > 0) {
        // 储值余额 > 0
        if (this.sumAmount > 0) {
          // 储值余额>=剩余金额
          if (this.sumAmount >= remainAmount) {
            this.formData.StoPayAmount = remainAmount;
          } else { // 储值余额 < 剩余金额
            this.formData.StoPayAmount = this.sumAmount;
          }
          this.formData.DebtAmount = this.$utils.mMoneyType(remainAmount - this.formData.StoPayAmount, 2);
        } else {
          // 储值余额 = 0
          this.formData.DebtAmount = remainAmount;
          this.formData.StoPayAmount = 0;
        }
      } else {
        this.formData.StoPayAmount = 0;
        this.formData.DebtAmount = 0;
      }
    },
    // 改变余额
    changeStoPayAmount (value) {
      this.formData.StoPayAmount = value;
      let remainAmount = this.formData.RecableAmount - this.formData.DepositAmount - this.formData.StoPayAmount;
      // 剩余金额 > 0
      if (remainAmount > 0) {
        this.formData.ActualColAmount = this.$utils.mMoneyType(remainAmount - this.formData.DebtAmount, 2);
      } else {
        this.formData.ActualColAmount = 0;
        this.formData.DebtAmount = 0;
      }
    },
    // 立即收款
    collectionClick () {
      console.log('立即收款', this.formData);
      var billMoney = this.formData.RecableAmount - this.formData.DepositAmount > 0 ? this.formData.RecableAmount - this.formData.DepositAmount : 0;
      if (this.formData.ActualColAmount > 0 && this.formData.CollectAccontKey <= 0) {
        layer.alert('请先选择收款方式');
        return false;
      } else if (billMoney != this.$utils.mMoneyType(Number(this.formData.ActualColAmount) + Number(this.formData.StoPayAmount) + Number(this.formData.DebtAmount), 2)) {
        layer.alert('各收款金额和订单金额不相等');
        return false;
      }
      this.$emit('collectionClick', this.formData);
    }
  }
};
</script>

<style>

</style>