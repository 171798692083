<template>
  <!-- 收支管理（总部） -->
  <div>
    <div class="content_marginTop_30 content_marginRL"
         @click="hiddenPopup">
      <div class="single_shadow">
        <div class="income_expenditure_manage_nav">
          <div class="income_expenditure_manage_nav_title clearfix">
            <!-- 待审核 -->
            <div class="income_expenditure_nav_btn fl "
                 @click.stop="checkPending()"
                 :class="selecteDSH">待审核
            </div>
            <!-- 已审核 -->
            <div class="income_expenditure_nav_btn no-border fl"
                 @click.stop="audited()"
                 :class="selectedYSH">已审核
            </div>
          </div>
          <div v-show="isAuditedShow">
            <div style="padding-top:18px"
                 class="clearfix">
              <div class="new_table_title_date left_type">
                <div @click.stop="searchLast()"
                     class="date_last fl"></div>
                <span class="date_text">{{yearView}}年{{monthView}}月</span>
                <div @click.stop="searchNext()"
                     class="date_next fr"></div>
              </div>
              <div class="income_expenditure_aggregate">
                <div>
                  <span>本月收入：</span>
                  <span class="font_number">{{Number(vIncomeAmount)}}</span>
                </div>
                <div>
                  <span>本月支出：</span>
                  <span class="font_number">{{Number(vExpendAmount)}}</span>
                </div>
              </div>
            </div>
            <div class="defult_dialog_searchform serach_select">
              <div class="search_form">
                <el-select class="serach_select_type"
                           v-model="selectSearchType"
                           placeholder="请选择">
                  <el-option class="serach_select_type_list"
                             v-for="item in searchType"
                             :key="item.type"
                             :label="item.text"
                             :value="item.type">
                  </el-option>
                </el-select>
                <input type="text"
                       placeholder="收入收支流水/发生人"
                       class="search_input"
                       @keydown="keydownEvent($event)"
                       v-model="searchTimeViewText">
                <span v-show="searchTimeViewText"
                      class="home_remove_btn"
                      style="right: 65px;z-index: 10;"
                      @click.stop="clearSearchCondition"></span>
              </div>
              <div class="search_btn "
                   @click="searchContentInfo">搜索</div>
            </div>
          </div>

          <div v-show="isCheckPendingShow">
            <input-search-contain-btn v-model="searchTimeViewText"
                                      ref="containBtn"
                                      :placeholder="'请输入收入收支流水、发生人'"
                                      @onSearchEvent="searchContentInfoTo"></input-search-contain-btn>
          </div>
        </div>
        <div>
          <!-- 待审核页面 -->
          <div v-if="isCheckPendingShow"
               class="pr">
            <div class="income_expenditure_manage"
                 @scroll="hiddenPopup"
                 ref="toAuditedItem"
                 :class="isAuditedShow?'min-size':''">
              <income-audit-item v-for="(item,index) in searchCheckPendingContent"
                                 :key="index"
                                 :isBtnGroup="true"
                                 :sourceItem="item"
                                 @selectItem="selectToAuditedItem"
                                 @auditPass="clickAuditPass"></income-audit-item>
              <div v-if="searchCheckPendingContent && searchCheckPendingContent.length==0">
                <div class="monitoring_content_nodata">
                  <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 待处理页面 -->
          <div>
            <div v-if="isProcessedShow"
                 class="income_expenditure_manage"
                 :class="isAuditedShow?'min-size':''">
              <income-audit-item v-for="(item,index) in processedList"
                                 :key="index"
                                 :isBtnGroup="false"
                                 :sourceItem="item"
                                 @selectItem="selectDisposeItem"></income-audit-item>
              <div v-if="processedList && processedList.length==0">
                <div class="monitoring_content_nodata">
                  <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 已审核页面 -->
          <div>
            <div v-if="isAuditedShow"
                 class="income_expenditure_manage"
                 :class="isAuditedShow?'min-size':''">
              <div class="income_expenditure_manage_audited">
                <income-audit-item v-for="(item,index) in searchContent"
                                   :key="index"
                                   :isBtnGroup="false"
                                   :sourceItem="item"
                                   @selectItem="selectAuditedItem"></income-audit-item>
                <div v-if="searchContent && searchContent.length==0">
                  <div class="monitoring_content_nodata">
                    <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 待处理-详情显示页面  -->
    <custom-dialog title="处理"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isDisposeItemDetailsShow"
                   :before-close="closeCubpageDialog">
      <div class="content_marginTop_30 content_marginRL">
        <div class="">
          <income-dispose-details :processedItem="processedItem"
                                  @processedPass="clickProcessedPass"></income-dispose-details>
        </div>
      </div>
    </custom-dialog>

    <!-- 已审核-详情显示页面 -->
    <custom-dialog title="已审核"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isAuditedItemDetailsShow"
                   :before-close="closeCubpageDialog">
      <div class="content_marginTop_30 content_marginRL">
        <div class="single_shadow">
          <incomeAuditedDetails :item="auditedItem"
                                @deleteBillClick="deleteBillClick"></incomeAuditedDetails>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog :title="'待审核'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowToAuditedSelectList"
                   :before-close="hiddenToAuditedSelectList">
      <home-incomeAndexpenses-form :item="selecttoAuditedInfo"
                                   @passHide="doAfterPassHide"></home-incomeAndexpenses-form>
    </custom-dialog>

  </div>
</template>
<script>
import incomeAuditItem from './income-audit-item';  // 待审核组件
import incomeDisposeDetails from './income-dispose-details';  // 待处理详情页面
import incomeAuditedDetails from './income-audited-details';  // 已审核组件详情页面
import homeIncomeAndexpensesForm from './home-incomeAndexpenses-form';
import dropDownBox from '../../../common/drop-down-box';
import { hqMessageSearchAuditForSZGL, hqMessageSearchDoForSZGL, hqMessageSearchAuditedForSZGL } from '../../../../API/workbench.js';
export default {
  name: 'incomePayManage',
  data () {
    return {
      formTitle: '收支记录',
      // incomeExpenditureManage: true, // 控制是否显示-收支管理主页面
      isCheckPendingShow: true, // 控制是否显示-待审核页面
      isProcessedShow: false, // 控制是否显示-待处理页面
      isAuditedShow: false, // 控制是否显示-已审核页面
      selecteDSH: 'opt', // 默认待审核-标题高亮
      selectedDCL: '', // 待处理-标题高亮
      selectedYSH: '',  // 已审核-标题高亮
      isDisposeItemDetailsShow: false, // 控制是否显示-待处理详情页面
      isAuditedItemDetailsShow: false, // 控制是否显示-待处理详情页面
      checkPendingList: [], // 待审核数据列表
      processedList: [], // 待处理数据列表
      processedItem: {}, // 选择待处理数据对象
      auditedList: [], // 已待审核数据列表
      auditedItem: {}, // 选择已待审核数据对象
      searchText: '',
      searchTimeViewText: '',
      monthView: '',
      yearView: '',
      selecttoAuditedInfo: {},
      isLoadData: false, // 显示没有数据
      isLoadDataCL: false,
      isShowToAuditedSelectList: false,
      topValue: 0,
      toAuditedSelectList: [
        {
          name: '审核通过',
          isShow: true,
          ModulePowerKey: 117,
          type: 0
        },
        {
          name: '审核拒绝',
          isShow: true,
          ModulePowerKey: 117,
          type: 1
        }
      ],
      searchType: [
        {
          type: 0,
          text: '全部'
        },
        {
          type: 1,
          text: '收入'
        },
        {
          type: 2,
          text: '支出'
        }
      ],
      selectSearchType: 0
    };
  },
  props: ['isIncomePayManageDialogVisible'],
  components: {
    incomeAuditItem,
    incomeDisposeDetails,
    incomeAuditedDetails,
    dropDownBox,
    homeIncomeAndexpensesForm
  },
  computed: {
    // 输入搜索
    searchContent () {
      let strTime = [];
      this.auditedList.forEach(item => {
        if (
          this.searchText == '' ||
          ((item.SourceKeyName &&
            item.SourceKeyName.toLowerCase().indexOf(this.searchText) >= 0) ||
            (item.SourceNumName &&
              item.SourceNumName.toLowerCase().indexOf(this.searchText) >= 0) ||
            (item.HapPeolKeyValue &&
              item.HapPeolKeyValue.toLowerCase().indexOf(this.searchText) >= 0) ||
            (item.FirstLetterName && item.FirstLetterName.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1))) {
          // 判断输入框中的值是否可以匹配到数据，如果匹配成功
          if (this.selectSearchType == 0 || item.IncomePayTypeKey == this.selectSearchType) {
            strTime.push(item);
          }
        };
      });
      return strTime;
    },
    searchCheckPendingContent () {
      let strTime = [];
      this.checkPendingList.forEach(item => {
        if (
          this.searchText == '' ||
          ((item.SourceKeyName &&
            item.SourceKeyName.toLowerCase().indexOf(this.searchText) >= 0) ||
            (item.SourceNumName &&
              item.SourceNumName.toLowerCase().indexOf(this.searchText) >= 0) ||
            (item.HapPeolKeyValue &&
              item.HapPeolKeyValue.toLowerCase().indexOf(this.searchText) >= 0) ||
            (item.FirstLetterName && item.FirstLetterName.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1)
          )
        ) {
          // 判断输入框中的值是否可以匹配到数据，如果匹配成功
          strTime.push(item);
        };
      });
      return strTime;
    },
    vIncomeAmount () {
      let number = 0;
      this.auditedList.forEach(e => {
        if (e.IncomePayTypeKey == 1 && (e.StatusKey != 5 && e.StatusKey != 6)) {
          e.InAmount = Number(e.InAmount);
          number += e.InAmount;
        }
      });
      number = number.toFixed(2);
      return number;
    },
    vExpendAmount () {
      let number = 0;
      this.auditedList.forEach(e => {
        if (e.IncomePayTypeKey == 2 && (e.StatusKey != 5 && e.StatusKey != 6)) {
          e.PayAmount = Number(e.PayAmount);
          number += Number(e.PayAmount);
        }
      });
      number = number.toFixed(2);
      return number;
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  created () {
    this.loadData();
  },
  watch: {
    searchText (n) {
      this.searchTimeViewText = n;
    }
  },
  methods: {
    toAuditedSelectFunction (menu) {
      switch (menu.type) {
        case 0:
          this.auditPass(this.selecttoAuditedInfo);
          break;
        case 1:
          this.auditReject(this.selecttoAuditedInfo);
          break;
        default:
          break;
      }
    },
    selectToAuditedItem (data, e) {
      this.selecttoAuditedInfo = data;
      this.isShowToAuditedSelectList = true;
      this.topValue = e.currentTarget.offsetTop - this.$refs.toAuditedItem.scrollTop + 32;
      console.log(e.currentTarget.offsetTop, this.$refs.toAuditedItem.scrollTop);
    },
    hiddenPopup () {
      this.isShowToAuditedSelectList = false;
    },
    hiddenToAuditedSelectList () {
      this.isShowToAuditedSelectList = false;
    },
    doAfterPassHide () {
      console.log('待审个');
      this.isShowToAuditedSelectList = false;
      this.$refs.containBtn.searchText = '';
      this.checkPending();
    },
    searchContentInfo (val) {
      this.searchText = this.searchTimeViewText.toLowerCase();
    },
    searchContentInfoTo (val) {
      this.searchTimeViewText = val;
      if (val) {
        this.searchTimeViewText = val;
        this.searchText = this.searchTimeViewText.toLowerCase();
      } else {
        this.searchTimeViewText = '';
        this.searchText = '';
      }
    },
    // 清除
    clearSearchCondition () {
      this.searchTimeViewText = '';
      this.searchContentInfo();
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    // 初始化数据
    loadData () {
      this.processed();
      this.checkPending();
      this.TimeViewText();
    },
    closeClientDialog () {
      this.checkPending();
      this.$parent.isIncomePayManageDialogVisible = false;
    },
    closeCubpageDialog () {
      this.isDisposeItemDetailsShow = false; // 控制是否显示-待处理详情页面
      this.isAuditedItemDetailsShow = false;// 控制是否显示-待处理详情页面
    },
    // 搜索时间选择
    TimeViewText () {
      var date = Date.new();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) { month = '0' + month; }
      this.yearView = year;
      this.monthView = month;
    },
    // 选择待审核页面
    checkPending () {
      this.selectSearchType = 0;
      this.selecteDSH = 'opt';
      this.selectedDCL = '';
      this.selectedYSH = '';
      this.searchText = '';
      this.searchTimeViewText = '';
      this.isCheckPendingShow = true;
      this.isProcessedShow = false;
      this.isAuditedShow = false;
      // 待审核查询接口
      hqMessageSearchAuditForSZGL().then(result => {
        console.log(result, '待审核 ');
        if (result.data && result.data.length > 0) {
          this.isLoadData = true; // 显示角标数量
          this.checkPendingList = result.data;
          // 赋值-倒序
          this.checkPendingList.sort(function (a, b) {
            return ((b.SourceKeyName.slice(2)) - (a.SourceKeyName.slice(2)));
          });
        } else {
          this.isLoadData = false;
          this.checkPendingList = [];
          console.log(this.checkPendingList.length, '待审核1 ');
        }
      });
    },
    // 选择待处理页面
    processed () {
      this.selecteDSH = '';
      this.selectedDCL = 'opt';
      this.selectedYSH = '';
      this.searchText = '';
      this.searchTimeViewText = '';
      this.isProcessedShow = true;
      this.isAuditedShow = false;
      this.isCheckPendingShow = false;
      let strTime = [];
      // 待处理查询接口
      hqMessageSearchDoForSZGL().then(result => {
        if (result.data && result.data.length > 0) {
          this.isLoadDataCL = true; // 显示角标数量
          this.processedList = result.data;
          console.log(result, '待处理1 ');
          // 赋值-倒序
          this.processedList.sort(function (a, b) {
            return ((b.SourceKeyName.slice(2)) - (a.SourceKeyName.slice(2)));
          });
        } else {
          console.log(result, '待处理2 ');
          this.isLoadDataCL = false;
          this.processedList = [];
        }
      });
    },
    // 收支作废
    deleteBillClick () {
      this.closeCubpageDialog();
      this.audited();
    },
    // 选择已审核页面
    audited () {
      this.hiddenPopup();
      this.searchText = '';
      this.searchTimeViewText = '';
      this.selecteDSH = '';
      this.selectedDCL = '';
      this.selectedYSH = 'opt';
      this.isAuditedShow = true;
      this.isProcessedShow = false;
      this.isCheckPendingShow = false;
      // 开始日期
      let startDate = this.yearView + '-' + this.monthView + '-' + '01';
      // 结束日期
      let endDate = this.yearView + '-' + this.monthView + '-' + this.mGetDate(this.yearView, this.monthView);
      // 已审核查询接口
      hqMessageSearchAuditedForSZGL(startDate, endDate).then(result => {
        if (result.data && result.data.length > 0) {
          this.auditedList = result.data;
          // 赋值-倒序
          this.auditedList.sort(function (a, b) {
            return ((b.SourceKeyName.slice(2)) - (a.SourceKeyName.slice(2)));
          });
        } else {
          this.auditedList = [];
        }
      });
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    },
    // 搜索上一个月
    searchLast () {
      this.monthView--;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView < 1) { this.monthView = 12; this.yearView = this.yearView - 1; }
      this.audited();
    },
    // 搜索下一个月
    searchNext () {
      this.monthView++;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView > 12) { this.monthView = '01'; this.yearView = this.yearView + 1; }
      this.audited();
    },
    // 待处理详情页面显示
    selectDisposeItem (item) {
      this.processedItem = item;
      // this.incomeExpenditureManage = false;
      this.isDisposeItemDetailsShow = true;
      this.formTitle = '处理';
    },
    // 已审核详情页面显示
    selectAuditedItem (item) {
      console.log(item, '已审核详情页面显示 ');
      this.auditedItem = item;
      // this.incomeExpenditureManage = false;
      this.isAuditedItemDetailsShow = true;
      this.formTitle = '已审核';
    },
    // 已审核按钮触发
    clickAuditPass () {
      this.checkPending();
    },
    // 待处理按钮触发
    clickProcessedPass () {
      this.checkPending();
      this.processed();

      this.returnEvent();
    },
    // 收支管理返回按钮
    returnEvent () {
      if (this.formTitle == '收支记录') {
        // this.$router.push('/indexview');
      } else {
        // this.incomeExpenditureManage = true;
        this.isDisposeItemDetailsShow = false;
      }
      this.formTitle = '收支记录';
    },
    // 回车
    keydownEvent (e) {
      if (e.keyCode == 13) {
        this.searchContentInfo();// 搜索函数
      }
    }
  }
};
</script>

<style>
.income_expenditure_manage {
  max-height: 500px;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.income_expenditure_manage.min-size {
  max-height: 500px;
}
</style>