<template>
  <div>
    <div class="customer_management_from form_info_list"
         style="min-height: 270px;margin-bottom: 30px;">
      <div class="PreSaleLabel_checked"
           v-for="(item,index) in labelGroupList"
           :key="index">
        <div v-if="item[0].LabelKey"
             style="display: flex;flex-wrap: wrap;">
          <span class="checked_span"
                :title="item[0].MainDemoName">{{ item[0].MainDemoName }}</span>
          <el-checkbox-group v-model="checkedPreSaleLabels"
                             @change="handleCheckedPreSaleLabelsChange">
            <el-checkbox v-for="child in item"
                         :label="child.LabelKeyValue"
                         :key="child.LabelKey"
                         :title="child.LabelKeyValue">{{child.LabelKeyValue}}</el-checkbox>
          </el-checkbox-group>
        </div>

      </div>

      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetLabelGroupList } from '../../../API/workbench';
export default {
  data () {
    return {
      labelGroupList: [],
      checkedPreSaleLabels: [],
      dataInfo: {}
    };
  },
  components: {
  },
  props: {
    labelInfo: [Object, String],
    allLabelList: Array
  },
  created () {
    this.getGetLabelGroupList();
    this.checkedPreSaleLabels = this.allLabelList;
    console.log(this.labelInfo, 'dataInfo', this.dataInfo, 'allLabelList', this.allLabelList, 'labelGroupList', this.labelGroupList);
  },
  computed: {
  },
  methods: {
    // 标签列表
    getGetLabelGroupList () {
      GetLabelGroupList().then(result => {
        this.labelGroupList = result.data;
        console.log(this.labelGroupList, 'this.labelGroupList', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择标签
    handleCheckedPreSaleLabelsChange (value) {
      this.checkedPreSaleLabels = value;
      console.log('this.PreSaleLabelList', value);
    },
    // 确定
    submit () {
      console.log('this.checkedPreSaleLabels.indexOf2', this.checkedPreSaleLabels);
      this.$emit('checkedLabelSubmit', this.checkedPreSaleLabels, this.labelGroupList);
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.PreSaleLabel_checked {
  display: flex;
  margin-top: 30px;
  width: 410px;
}
.checked_span {
  margin-right: 10px;
  width: 45px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.PreSaleLabel_checked >>> .el-checkbox-group {
  width: 340px;
  line-height: 22px;
}
.PreSaleLabel_checked >>> .el-checkbox-group .el-checkbox {
  width: 100px !important;
  margin-right: 10px !important;
}
.PreSaleLabel_checked >>> .el-checkbox-group .el-checkbox .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  top: 1px;
  max-width: 80px;
}
</style>