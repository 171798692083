<!-- 文本 -->
<template>
  <el-table-column :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :min-width="columnData.minWidth">
    <template slot-scope="scope">
      <!-- 特例，兼容多行文本鼠标移除显示效果 -->
      <el-tooltip placement="top"
                  :disabled="$utils.getTextWidth(scope.row[columnData.prop], 14)<columnData.width"
                  :content="mShowTooltip(scope)">
        <div class="follow-record"
             v-if="!scope.row.isEmpertyRow">
          <span class="follow_time_tips">{{scope.row[columnData.extend.FollowTime]?$utils.StrToRelativeTime(scope.row[columnData.extend.FollowTime])+'：':''}}</span>
          <span class="follow_content">{{scope.row[columnData.prop]}}</span>
        </div>
      </el-tooltip>

    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String
        };
      }
    }
  },
  data () {
    return {
    };
  },
  computed: {
    vShowOverflowTooltip () {
      if (typeof this.columnData.showOverflowTooltip == 'boolean') {
        return this.columnData.showOverflowTooltip;
      } else {
        return true;
      }
    }
  },
  created () {
  },
  methods: {
    mShowTooltip (scope) {
      let date = scope.row[this.columnData.extend.FollowTime] ? this.$utils.StrToRelativeTime(scope.row[this.columnData.extend.FollowTime]) + '：' : '';
      let text = scope.row[this.columnData.prop];
      return date + ' ' + text;
    }
  }
};
</script>

<style scoped>
.follow-record {
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}
.follow_time_tips {
  color: #999999;
  max-width: 105px;
}
</style>