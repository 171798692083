<template>
  <div class="single_result single_class_info"
       @click.stop="selectedItem()"
       :class="{active:Item.isActive}">
    <div class="single_result_info_detail">
      <div class="single_class_name">
        <span>{{Item.MainDemoName}}</span>
        <span v-if="Item.TypeKeyValue"
              class="single_rightcontent"
              style="float: right;">{{Item.TypeKeyValue}}</span>
      </div>
    </div>
  </div>
</template>
<script>;
export default {
  name: 'multiSelectItem',
  data () {
    return {
    };
  },
  props: { Item: Object }, // 单选数据项 需绑定到界面
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
    selectedItem (item) {
      this.$emit('selectedItem', this.Item);
    }
  }
};
</script>

<style>
.single_class_info {
  position: relative;
}
.single_rightcontent {
  color: #999;
  font-size: 14px;
}
</style>

