<template>
  <!-- 部门 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'部门名称'"
                  :maxLength="14"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonly"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>

<script>
import {
  hqAddDepartment,
  hqEditDepartment
} from '../../../../API/workbench.js';
export default {
  data () {
    return {
      // 提交数据对象
      dataJson: {
        OLAPKey: '',
        MainDemoName: '', // -- 部门名称
        FatherKey: 0, // --所属部门
        FatherKeyValue: '全部', // -- 所属部门
        RemarksSupport: '' // --备注
      }
    };
  },
  props: {
    dataInfo: Object, // 部门信息
    isReadonly: Boolean// 是否只读
  },
  created () {
    if (this.dataInfo.OLAPKey) {
      Object.assign(this.dataJson, this.dataInfo);
      console.log(this.dataJson, '表单信息2');
    }
  },
  computed: {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (this.dataJson.MainDemoName) {
        this.dataJson.MainDemoName = this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.dataJson.MainDemoName) {
        layer.alert('请输入名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.dataJson.OLAPKey) {
        this.editOperation(callBack);
      } else {
        this.addOperation(callBack);
      }
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 新增部门
    addOperation (callBack) {
      hqAddDepartment(this.dataJson).then(result => {
        console.log(result, '部门新增');
        this.$emit('afterSuccess', 'Add', result.data);
        layer.alert('新增成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 修改部门
    editOperation (callBack) {
      hqEditDepartment(this.dataJson).then(result => {
        console.log(result, '部门修改');
        this.$emit('afterSuccess', 'Edit', result.data);
        layer.alert('修改成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    }
  }
};
</script>

<style>
</style>
