<template>
  <div class="navbar_list " :class="item.isActive?'opt':''" @click.stop="onClickEvent">
    {{item.title}} <span class="font_number">({{item.num}})</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          isActive: false, // 是否被选中
          type: '', // 类型
          title: '', // 标题
          num: 0// 数量
        };
      }
    }
  },
  methods: {
    // 点击切换
    onClickEvent (data) {
      this.$emit('onClickSwitch', this.item);
    }
  }
};
</script>

<style>
</style>
