<template>
	<div class="course_estimate_info">
		<div class="info_title">
			{{vDateTime}}
		</div>
		<div class="info_text">
			{{dataInfo.SClassTimeName}}-{{dataInfo.EClassTimeName}} {{dataInfo.CourseNameKeyValue}}-{{dataInfo.GradeClassKeyValue}}
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vDateTime () {
      const date = this.$utils.formatDateToYYYYMMDD(this.dataInfo.ClassTime).slice(5, 11);
      const week = this.$utils.getWeek(this.$utils.formatDateToLine(this.dataInfo.ClassTime));
      return date + ' ' + week;
    }
  },
  methods: {

  }
};
</script>

<style>

</style>
