<template>
  <div>
    <div style="margin-left: 27px;margin-bottom: 20px;"><span class="spantext"
            :class="{textOption:type==1}"
            @click.stop="changeItem(1)">{{vTitleArr[0]}}</span>
      <span class="line">|</span><span @click.stop="changeItem(2)"
            :class="{textOption:type==2}"
            class="spantext">{{vTitleArr[1]}}</span><span v-if="vTitleArr.length>2"
            class="line">|</span>
      <span @click.stop="changeItem(3)"
            v-if="vTitleArr.length>2"
            :class="{textOption:type==3}"
            class="spantext">{{vTitleArr[2]}}</span>
    </div>
    <div class="course_statistics_echarts_box">
      <type-echart :echartID="'dual_histogram'+modeType"
                   :reqList="monthList"
                   :nowDataList="nowList"
                   :preDataList="oldList"
                   :monthTitle="yUnit"
                   :secondTitle="unit"
                   :echartType="'Bar'"
                   ref="echart"></type-echart>
    </div>
  </div>
</template>

<script>
import { } from '../../../../API/workbench';
import typeEchart from './type-echart';
export default {
  data () {
    return {
      nowList: [],
      oldList: [],
      monthList: [],
      type: 1,
      titleArr: [],
      unit:'',
      yUnit:''
    };
  },
  components: {
    typeEchart
  },
  created () {
  },
  props: {
    DataList: Array,
    modeType: Number
  },
  watch: {
    'DataList': {
      handler (newval, oldval) {
        this.getDataList();
      },
      deep: true
    }
  },
  mounted () {
    this.getDataList();
  },
  computed: {
    vDataList () {
      return this.DataList || [];
    },
    vTitleArr () {
      let arr = [];
      if (this.modeType == 1) {
        arr = ['报名收款', '课时单价'];
      } else if (this.modeType == 2) {
        arr = ['新增线索', '线索转化率'];
      } else if (this.modeType == 3) {
        arr = ['实际到校', '诺访到校率', '诺访转化率'];
      } else {
        arr = ['已试课', '试课出勤率', '试课转化率'];
      }
      return arr;
    },
    vWidthCount(){
      let windowWidth = window.innerWidth
      console.log('windowWidth',windowWidth)
      if(windowWidth>=1920){
        return 16
      }else if(windowWidth <1920 && windowWidth >=1600){
        return 12
      }else{
        return 8
      }
    }
  },
  methods: {
    changeItem (data) {
      this.type = data;
      this.getDataList();
    },
    getDataList () {
      this.monthList = [];
      this.vDataList.forEach((o, i) => {
        if (o.SalesKeyValue) {
          this.monthList.push(o.SalesKeyValue);
        }
      });

      if (this.monthList.length < this.vWidthCount) {
        let j = this.vWidthCount- this.monthList.length;// 显示8个
        for (var i = 0; i < j; i++) {
          this.monthList.push('');
        }
      }
      if(this.monthList.length > this.vWidthCount){
        this.monthList = this.monthList.splice(0,this.vWidthCount+1)
      }
      this.nowList = this.getNowList();
      console.log(this.vDataList, 'this.nowDataList this.nowList this.oldList', this.nowList, this.oldList);
      this.$nextTick(() => {
        this.$refs.echart.echartInit();
      });
      this.$forceUpdate();
    },
    getNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.vDataList.find(o => {
          return o.SalesKeyValue == p;
        });
        if (item) {
          nowList = this.getItem(nowList, item);
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    },
    getItem (nowList, item) {
      if (this.modeType == 1) {
        if (this.type == 1) {
          nowList.push(Number(item.ActualColAmount).toFixed(0));
          this.unit = ''
          this.yUnit = ''
        } else if (this.type == 2) {
          nowList.push(Number(item.ClassHourUnitPriceCount).toFixed(0));
          this.unit = ''
          this.yUnit = ''
        }
      } else if (this.modeType == 2) {
        if (this.type == 1) {
          nowList.push(Number(item.NewThreadCount).toFixed(0));
          this.unit = ''
          this.yUnit = ''
        } else if (this.type == 2) {
          nowList.push(Number(item.TransformationRate).toFixed(0));
          this.unit = '%'
          this.yUnit = '%'
        }
      } else if (this.modeType == 3) {
        if (this.type == 1) {
          nowList.push(Number(item.GetToSchoolCount).toFixed(0));
          this.unit = ''
          this.yUnit = ''
        } else if (this.type == 2) {
          nowList.push(Number(item.GetToSchoolRate).toFixed(0));
          this.unit = '%'
          this.yUnit = '%'
        } else if (this.type == 3) {
          nowList.push(Number(item.TransformationRate).toFixed(0));
          this.unit = '%'
          this.yUnit = '%'
        }
      } else if (this.modeType == 4) {
        if (this.type == 1) {
          nowList.push(Number(item.TrialLessonCount).toFixed(0));
          this.unit = ''
          this.yUnit = ''
        } else if (this.type == 2) {
          nowList.push(Number(item.TrialLessonFinishRate).toFixed(0));
          this.unit = '%'
          this.yUnit = '%'
        } else if (this.type == 3) {
          nowList.push(Number(item.TransformationRate).toFixed(0));
          this.unit = '%'
          this.yUnit = '%'
        }
      }

      return nowList;
    }
  }
};
</script>

<style scoped>
.course_statistics_table_box {
  padding: 0;
}
.titltText {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #333333;
}

.textOption {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 700 !important;
  line-height: 27px;
  letter-spacing: 0em;
  color: #333333 !important;
}
.spantext {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  color: #666666;
  cursor: pointer;
}
.line {
  color: #ececec;
  margin-left: 5px;
  margin-right: 7px;
}
.course_statistics_echarts_box {
  height: 277px !important;
  border: 1px solid #ececec;
}
.dual_histogram3 {
}
</style>