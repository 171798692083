<template>
  <div class="courseware_bag_info">
    <!-- 子课包级 当前项沒有子级 -->
    <div v-if="dataInfo.MRCoursewarePackage.length>0">
      <div class="big_courseware_file"
           v-if="vChildList.length>0">
        <div style="height: 20px;"></div>
        <div class="add_courseware_btn"
             @click="doAddCoursewarePackage('')"></div>
        <div class="courseware_son_list_ul">
          <courseware-info v-for="(item,key) in vChildList"
                           :key="key"
                           :dataInfo="item"
                           @doEditCourseware="doEditCourseware"
                           @deleteAmpus="deleteAmpus"></courseware-info>
        </div>
      </div>
      <!-- 目录级 -->
      <div class="big_courseware_file"
           v-for="(item) in vParentList"
           :key="item.CoursewarePackageKey">
        <div class="file_box">{{item.CoursewarePackageKeyValue}}</div>
        <div class="add_courseware_btn"
             @click="doAddCoursewarePackage(item)"></div>
        <div class="courseware_son_list_ul">
          <courseware-info v-for="(child) in item.childTree"
                           :key="child.CoursewarePackageKey"
                           :dataInfo="child"
                           :parentItem="item"
                           @doEditCourseware="doEditCourseware"
                           @deleteAmpus="deleteAmpus"></courseware-info>
        </div>
      </div>
      <div class="add_courseware_file"
           v-if="vParentList.length==0"
           @click="doAddCoursewarePackage('')">
        <div class="add_text">+</div>
      </div>
    </div>
    <!-- 无数据时 -->
    <div class="big_courseware_file"
         v-else>
      <div class="add_courseware_btn"
           @click="doAddCoursewarePackage('')"></div>
    </div>
  </div>
</template>
<script>
import coursewareInfo from './courseware-info';
import { HQDeleteMRCoursewarePackage } from '../../../../API/workbench';
export default {
  components: {
    coursewareInfo
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  created () {

  },
  computed: {
    vParentList () {
      let list = [];
      if (this.vDataList && this.vDataList.length > 0) {
        list = this.vDataList.filter(item => item.CoursewarePackageTypeKey == 2);
      }
      console.log(list, 'vParentList');
      return list;
    },
    vChildList () {
      let list = [];
      if (this.vDataList && this.vDataList.length > 0) {
        list = this.vDataList.filter(item => item.childTree.length == 0 && item.CoursewarePackageTypeKey == 0);
      }
      return list;
    },
    vDataList () {
      console.log(this.dataInfo, 'this.dataInfo');
      var arr = [];
      if (this.dataInfo.MRCoursewarePackage && this.dataInfo.MRCoursewarePackage.length > 0) {
        let treeData = this.dataInfo.MRCoursewarePackage.filter((item, index) => {
          this.$set(item, 'childTree', []);
          return item.CoursewarePackageKey;
        });
        // 父节点数据
        const ParentList = treeData.filter(item => item.BigCoursewarePackageKey == this.dataInfo.OLAPKey);
        // 子节点数据
        const childList = treeData.filter(item => item.BigCoursewarePackageKey != this.dataInfo.OLAPKey);
        let data = this.$utils.loopData(ParentList, childList, treeData, 'CoursewarePackageKey', 'BigCoursewarePackageKey');

        let noData = [];
        data.forEach(e => {
          e.childTree.forEach(o => {
            let i = data.findIndex((item) => { return item.CoursewarePackageKey == o.CoursewarePackageKey; });
            if (i > -1) {
              noData.push(o);
            }
          });
        });
        data.forEach(e => {
          let i = noData.findIndex((item) => { return item.CoursewarePackageKey == e.CoursewarePackageKey; });
          if (i == -1) {
            arr.push(e);
          }
        });
        console.log(arr, '12313');
      }
      return arr;
    }
  },
  methods: {
    doAddCoursewarePackage (item) {
      console.log(item, this.dataInfo, 'Add');
      this.$emit('doCoursewarePackageForm', this.dataInfo, 'Add', item);
    },
    // 编辑
    doEditCourseware (item, parentItem) {
      console.log(item, parentItem, 'Edit');
      this.$emit('doCoursewarePackageForm', item, 'Edit', parentItem);
    },
    // 删除该释放池子课包操作
    deleteAmpus (item) {
      console.log(item, 'delete');
      layer.confirm('请问是否删除该释放池子课包?', {
        btn: [{
          name: '确认',
          callBack: () => {
            HQDeleteMRCoursewarePackage(item.CoursewarePackageKey, item.OLAPKey).then(result => {
              this.$emit('afterSuccess');
              // this.dataInfo.MRCoursewarePackage = this.dataInfo.MRCoursewarePackage.filter((obj) => { return item.OLAPKey != obj.OLAPKey; });
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }]
      });
    }
  }
};
</script>
<style scoped>
.courseware_bag_info {
  /* padding: 16px; */
  /* padding-right: 10px; */
  width: 100%;
  /* padding-top: 0; */
  /* padding-bottom: 0px; */
  padding: 0px 10px 0px 16px;
}
.courseware_son_list_ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 12px 0px;
  border-bottom: 1px solid #ececec;
}
.courseware_bag_info .big_courseware_file:last-child .courseware_son_list_ul {
  border-bottom: none;
}

.add_courseware_btn {
  position: absolute;
  width: 27px;
  height: 22px;
  right: 20px;
  top: 0px;
  background: url(../../../../../public/cw-img/courseware_btn@2.png) no-repeat;
  background-size: 26px;
  cursor: pointer;
}
.big_courseware_file {
  position: relative;
  height: auto;
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  margin-top: 20px;
}
.big_courseware_file .file_box {
  padding-left: 36px;
  width: 95%;
}
.big_courseware_file .file_box::after {
  content: '';
  background: url(../../../../../public/cw-img/file_icon@2x.png) no-repeat 50%;
  background-size: 30px;
  width: 26px;
  height: 22px;
  position: absolute;
  top: 0px;
  left: 0;
}
.add_courseware_file {
  width: 190px;
  height: 50px;
  background: #f1f7ff;
  margin-top: 25px;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.add_text {
  color: #3498db;
  font-size: 30px;
}
</style>