const common = {
  state: {
    token: null,
    wktDomainURL: '',
    PCAPIURL: '',
    APIURL: '',
    CDNURL: '',
    WSURL: '',
    PCWebURL: '',
    newAPIURL: '',
    ProductENV: '',
    courseTableData: false,
    isShowPopupShade: false,
    popupMaxZIndex: 0,
    isShowHeader: true,
    SaaSClientInfo: {},
    showTimePicker: false, // 时间选择控件
    showTimetableId: 0, // 课ID
    showCoutseItem: {}, // 课项

    headerBarListDefault: [
      {
        routerName: 'homePage',
        isCanClose: false, // 禁止删除
        titleName: '首页',
        isSelected: true, // 已选中
        iconClass: 'icon_workbench'
      }
      // {
      //   routerName: 'indexView',
      //   isCanClose: false, // 禁止删除
      //   titleName: '工作台',
      //   isSelected: true, // 已选中
      //   iconClass: 'icon_workbench'
      // },
      // {
      //   routerName: 'teacherAttenClass',
      //   isCanClose: false, // 禁止删除
      //   titleName: '上课',
      //   isSelected: false, // 可选中
      //   iconClass: 'icon_teacher_atten_class'
      // },
      // {
      //   routerName: 'CourseTable',
      //   isCanClose: false,
      //   titleName: '课程表',
      //   isSelected: false,
      //   iconClass: 'icon_course_table'
      // },
      // {
      //   routerName: 'presidentKanban',
      //   isCanClose: false,
      //   titleName: '校长看板',
      //   isSelected: false,
      //   iconClass: 'icon_xiaozhang_kanban'
      // }
    ],
    headerBarListHQDefault: [
      {
        routerName: 'chainWorkBench',
        isCanClose: false, // 禁止删除
        titleName: '工作台',
        isSelected: true, // 可选中
        iconClass: 'icon_chainWorkBench'
      }
    ],
    headerBarListCWSDefault: [
      {
        routerName: 'coursewareWorkBench',
        isCanClose: false, // 禁止删除
        titleName: '工作台',
        isSelected: true, // 可选中
        iconClass: 'icon_coursewareWorkBench'
      }
    ],
    headerBarList: [],
    WarmingStatisical: {}, // 待办事项信息
    websocketReadyState: 0, // 长连接连接状态
    isOpenFaceSave: false, // 是否开启了面容录入弹窗
    isOpenFaceRecognition: false, // 是否开启面容签到功能
    faceSign: false, // 是否显示刷脸签到弹出层.
    faceSignTips: {
      statusKey: 1,
      statusKeyValue: '识别中...'
    },
    datePickerShow: false, // 日期插件
    isSysVersionTY: true, // 1 - 未指定;2 - 精英;3 - 通用;4 - 旗舰;5 - 连锁;
    isTemporaryStu: false, // 是否临时学生
    clientId: '', // 当前登录终端的唯一识别
    curRoute: {}, // 当前路由
    curOpenDialogName: '', // 当前打开弹框
    recentlyUsedMemeList: [], // 最近使用的表情
    AcademicMdCwType: '', // 教务门店课件类型 1-授权; 2-自研 ;
    geoInfo: {
      Longitude: '',
      Latitude: '',
      Accuracy: '',
      PositioningOffset: ''
    }, // 地理信息
    loginReply: null,
    isLoadtodoListData: false,    //待办事项是否请求过。
    isLoadWarmingStatisical:false    //智能预警是否请求过。
  },

  getters: {
    token: state => {
      return state.token;
    },
    RolePowerList: state => {
      return state.token ? state.token.RolePowerList : [];
    },
    signData: state => {
      return state.token ? state.token.SignData : null;
    },
    isSysVersionTY: state => {
      return state.isSysVersionTY;
    },
    isTemporaryStu: state => {
      return state.isTemporaryStu;
    },
    wktDomainURL: state => {
      return state.wktDomainURL;
    },
    PCAPIURL: state => {
      return state.PCAPIURL;
    },
    APIURL: state => {
      return state.newAPIURL ? state.newAPIURL : state.APIURL;
    },
    CDNURL: state => {
      return state.CDNURL;
    },
    WSURL: state => {
      return state.WSURL;
    },
    PCWebURL: state => {
      return state.PCWebURL;
    },
    ProductENV: state => {
      return state.ProductENV;
    },
    isShowPopupShade: state => {
      return state.isShowPopupShade;
    },
    popupMaxZIndex: state => {
      return state.popupMaxZIndex;
    },
    isShowHeader: state => {
      return state.isShowHeader;
    },
    headerBarList: state => {
      if (state.headerBarList.length == 0) {
        let typekey = state.token ? state.token.SaaSClientEditionKey : 0;
        switch (typekey) {
          case 5:
            state.headerBarList = JSON.parse(
              JSON.stringify(state.headerBarListHQDefault)
            );
            break;
          case 6:
            state.headerBarList = JSON.parse(
              JSON.stringify(state.headerBarListCWSDefault)
            );
            break;
          case 0:
            state.headerBarList = [];
            break;
          default:
            state.headerBarList = JSON.parse(
              JSON.stringify(state.headerBarListDefault)
            );
            break;
        }
      }
      return state.headerBarList;
    },
    SaaSClientInfo: state => {
      return state.SaaSClientInfo;
    },
    // 门店类型 6 总部
    SaaSClientEditionKey: state => {
      return state.token ? state.token.SaaSClientEditionKey : 0;
    },
    getShowTimetableId: state => {
      return state.showTimetableId;
    },
    getShowCoutseItem: state => {
      return state.showCoutseItem;
    },
    getWarmingStatisical: state => {
      return state.WarmingStatisical;
    },
    courseTableData: state => {
      return state.courseTableData;
    },
    getWebsocketReadyState: state => {
      return state.websocketReadyState;
    },
    getShowTimePicker: state => {
      return state.showTimePicker;
    },
    isOpenFaceSave: state => {
      return state.isOpenFaceSave;
    },
    isOpenFaceRecognition: state => {
      return state.isOpenFaceRecognition;
    },
    faceSign: state => {
      return state.faceSign;
    },
    faceSignTips: state => {
      return state.faceSignTips;
    },
    datePickerShow: state => {
      return state.datePickerShow;
    },
    clientId: state => {
      return state.clientId;
    },
    curRoute: state => {
      return state.curRoute;
    },
    curOpenDialogName: state => {
      return state.curOpenDialogName;
    },
    recentlyUsedMemeList (state) {
      return state.recentlyUsedMemeList;
    },
    AcademicMdCwType (state) {
      return state.AcademicMdCwType;
    },
    geoInfo: state => {
      return state.geoInfo;
    },
    loginReply: state => {
      return state.loginReply;
    },
    isLoadtodoListData: state => {
      return state.isLoadtodoListData;
    },
    isLoadWarmingStatisical: state => {
      return state.isLoadWarmingStatisical;
    }
  },
  mutations: {
    // 设置token
    SetToken: (state, obj) => {
      state.token = obj;
    },
    // 获取token
    GetToken: (state, obj) => {
      return state.token;
    },
    SetDomainURL: (state, obj) => {
      state.wktDomainURL = obj.wktDomainURL;
      state.PCAPIURL = obj.PCAPIURL;
      state.APIURL = obj.APIURL;
      state.CDNURL = obj.CDNURL;
      state.WSURL = obj.WSURL;
      state.PCWebURL = obj.PCWebURL;
      state.ProductENV = obj.ProductENV;
    },
    // 切换接口地址，从登录返回的ApiList中随机一个，不能跟当前用的相同
    selectNewAPIURL: (state) => {
      let apiList = localStorage.getItem('apiList') ? JSON.parse(localStorage.getItem('apiList')) : [];
      let optList = [];
      let nowAPI = localStorage.getItem('newAPIURL') ? localStorage.getItem('newAPIURL') : state.APIURL;
      if (apiList.length) {
        for (let i = 0; i < apiList.length; i++) {
          if (apiList[i].DomainName.indexOf(nowAPI) < 0) {
            optList.push(apiList[i].DomainName);
          }
        }
      }
      if (optList.length) {
        let url = optList[Math.floor(Math.random() * optList.length)];
        localStorage.setItem('newAPIURL', url);
        state.newAPIURL = url;
      } else {
        localStorage.setItem('newAPIURL', '');
        state.newAPIURL = '';
      }
    },
    // 设置遮罩层最大zIndex
    SetPopupShade: (state, boll) => {
      state.isShowPopupShade = boll;
    },
    // 设置弹出层最大zIndex
    SetPopupMaxZIndex: (state, val) => {
      state.popupMaxZIndex = val;
    },
    setIsShowHeader: (state, params) => {
      state.isShowHeader = params.booler;
      window.sessionStorage.setItem(
        'showHeader',
        JSON.stringify(state.isShowHeader)
      );
    },
    // 设置待办事项信息
    setWarmingStatisical: (state, obj) => {
      state.WarmingStatisical = obj;
    },
    setHeaderBarList: (state, arr) => {
      state.headerBarList = arr;
    },
    setHeaderBarListDefault: (state, arr) => {
      state.headerBarList = JSON.parse(JSON.stringify([]));
    },
    setSaaSClientInfo: (state, obj) => {
      state.isSysVersionTY = obj.EditionKey == 3;
      state.SaaSClientInfo = obj;
    },
    setIsTemporaryStu: (state, bool) => {
      state.isTemporaryStu = bool;
    },
    setShowTimetableId: (state, id) => {
      state.showTimetableId = id;
    },
    setShowCoutseItem: (state, bool) => {
      state.showCoutseItem = bool;
    },
    setCourseTableData: (state, bool) => {
      state.courseTableData = bool;
    },
    setWebsocketReadyState: (state, val) => {
      state.websocketReadyState = val;
    },
    setShowTimePicker: (state, id) => {
      state.showTimePicker = id;
    },
    setIsOpenFaceSave: (state, bool) => {
      state.isOpenFaceSave = bool;
    },
    setIsOpenFaceRecognition: (state, bool) => {
      state.isOpenFaceRecognition = bool;
    },
    setFaceSign: (state, bool) => {
      state.faceSign = bool;
    },
    setFaceSignTips: (state, val) => {
      state.faceSignTips = val;
    },
    setDatePickerShow: (state, bool) => {
      state.datePickerShow = bool;
    },
    setClientId: (state, val) => {
      state.clientId = val;
    },
    setCurRoute: (state, obj) => {
      state.curRoute = obj;
    },
    setCurOpenDialogName: (state, obj) => {
      state.curOpenDialogName = obj;
    },
    addRecentlyUsedMemeList (state, obj) {
      var locaItem = state.recentlyUsedMemeList.find(o => {
        return o.value == obj.value;
      });
      if (!locaItem) {
        if (state.recentlyUsedMemeList.length < 7) {
          state.recentlyUsedMemeList.unshift(obj);
        } else {
          state.recentlyUsedMemeList.unshift(obj);
          state.recentlyUsedMemeList.splice(
            state.recentlyUsedMemeList.length - 1,
            1
          );
        }
      }
    },
    setAcademicMdCwType: (state, type) => {
      state.AcademicMdCwType = type;
    },
    setGeoInfo: (state, Obj) => {
      state.geoInfo = Obj;
    },
    setLoginReply: (state, Obj) => {
      state.loginReply = Obj;
    },
    setisLoadtodoListData: (state, bool) => {
      state.isLoadtodoListData = bool;
    },
    setisLoadWarmingStatisical: (state, bool) => {
      state.isLoadWarmingStatisical = bool;
    },
    

  }
};

export default common;
