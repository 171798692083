<template>
  <div>
    <div class="pr">
      <input-setting-dialog :formTitle="formTitle"
                            :formPlaceholder="' '"
                            :customPlaceholder="true"
                            :readonly="false"
                            :dataInfoList="dataInfoList"
                            :ModulePowerKey="ModulePowerKey"
                            :isSwitchShowBox="isSwitchShowBox"
                            @showEldilog="newAdd"
                            @switchClick="switchClick"
                            v-model="newInputText"
                            class="padding_LR_10"
                            :style="vHasData?'border-bottom:0px':''"></input-setting-dialog>
      <div class="mendian_input_data_right pa"
           :class="vHasModulePower(ModulePowerKey)?'':'norole'">{{operateButName?operateButName:'新增'}}</div>
    </div>

    <!-- 启用数据 -->
    <!-- <div v-if="vShowStartDataInfoList.length > 0 && vHasData &&(vStopDataInfoList.length==0||isSwitchShowBox)" -->
    <div v-if="vShowStartDataInfoList.length > 0 && isSwitchShowBox"
         class="content_box ">
      <box-content v-for="item in vShowStartDataInfoList"
                   :key="item.OLAPKey"
                   :item="item"
                   :isActive="item.isActive"
                   :itemName="item.itemName"
                   :menuItemList="menuItemList"
                   :menuData="dataItemForOpreate"
                   @onItemClick="showOpreateMenu"
                   @doMenuClick="doOpreate"></box-content>

      <div class="added-shortcut-all-view"
           style="left: 47%;"
           :class="{opt:isShowAllStart}"
           @click="doShowStartDataAllClick"
           v-if="vStartDataInfoList.length>showItemCount">{{isShowAllStart?'恢复隐藏':'查看全部'}}</div>
    </div>
    <!-- 停用数据 -->
    <div v-if="vShowStopDataInfoList.length > 0 && vHasData && (vStartDataInfoList.length==0||!isSwitchShowBox)"
         class="content_box">
      <box-content v-for="item in vShowStopDataInfoList"
                   :key="item.OLAPKey"
                   :item="item"
                   :isActive="item.isActive"
                   :itemName="item.itemName"
                   :menuItemList="menuItemList"
                   :menuData="dataItemForOpreate"
                   @onItemClick="showOpreateMenu"
                   @doMenuClick="doOpreate"></box-content>

      <div class="added-shortcut-all-view"
           style="left: 47%;"
           :class="{opt:isShowAllStop}"
           @click="doShowStopDataAllClick"
           v-if="vStopDataInfoList.length>showItemCount">{{isShowAllStop?'恢复隐藏':'查看全部'}}</div>
    </div>
    <div v-if="vShowStopDataInfoList.length == 0 && !isSwitchShowBox">
      <div class="template_content_nodata_txt">暂无数据</div>
    </div>
  </div>
</template> 

<script>
import inputSettingDialog from './input-setting-dialog';
import detailedFunctionDialog from './detailed-function-dialog';
import boxContent from './box-content';
export default {
  data () {
    return {
      newInputText: '',
      newDataInfoList: [],
      menuItemList: [], // Ex. [{menuName:'查看',opreateType:'add'}]
      isShowOpreateMenu: false, // 操作菜单是否显示
      dataItemForOpreate: null, // 正在操作的数据项
      isSwitchShowBox: true, // 用于切换  默认为true
      isShowAllStart: false, // 是否显示全部（启用项）
      isShowAllStop: false // 是否显示全部（停用项）
    };
  },
  // ModulePowerKey 是权限码
  props: ['formTitle', 'dataInfoList', 'powerName', 'hasActive', 'isComplement', 'ModulePowerKey', 'showItemCount', 'operateButName'],
  computed: {
    vHasData () {
      return this.dataInfoList.length > 0;
    },
    vStartDataInfoList () {
      let arr = [];
      this.dataInfoList.forEach(o => {
        if ((this.isSwitchShowBox || o.dataKey) && o.isActive) {
          arr.push(o);
        }
      });
      let num = 6;
      if (document.body.clientWidth >= 1890 && document.body.clientWidth <= 1930) {
        // 1920分辨率 左右
        num = 6;
      } else if (document.body.clientWidth >= 1570 && document.body.clientWidth <= 1890) {
        // 1570 - 1890分辨率
        num = 5;
      } else {
        // 1366 - 1570分辨率
        num = 4;
      }
      let len = arr.length - 1;
      if (len > 0) {
        let lack = len % num;
        for (let i = 1; i < num - lack; i++) {
          arr.push({});
        }
      }
      return arr;
    },
    // 二次包装，用于启用项切换显示全部或隐藏
    vShowStartDataInfoList () {
      if (this.isShowAllStart || this.vStartDataInfoList.length <= this.showItemCount) {
        return this.vStartDataInfoList;
      } else {
        return this.vStartDataInfoList.slice(0, this.showItemCount);
      }
    },
    vStopDataInfoList () {
      let arr = [];
      this.dataInfoList.forEach(o => {
        if (o.dataKey && !o.isActive) {
          arr.push(o);
        }
      });
      let num = 6;
      if (document.body.clientWidth >= 1890 && document.body.clientWidth <= 1930) {
        // 1920分辨率 左右
        num = 6;
      } else if (document.body.clientWidth >= 1570 && document.body.clientWidth <= 1890) {
        // 1570 - 1890分辨率
        num = 5;
      } else {
        // 1366 - 1570分辨率
        num = 4;
      }
      let len = arr.length - 1;
      if (len > 0) {
        let lack = len % num;
        for (let i = 1; i < num - lack; i++) {
          arr.push({});
        }
      }
      return arr;
    },
    // 二次包装，用于停用项切换显示全部或隐藏
    vShowStopDataInfoList () {
      if (this.isShowAllStop || this.vStopDataInfoList.length <= this.showItemCount) {
        return this.vStopDataInfoList;
      } else {
        return this.vStopDataInfoList.slice(0, this.showItemCount);
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  components: {
    boxContent,
    detailedFunctionDialog,
    inputSettingDialog
  },
  created () {
    // this.startAllItemShowCount = this.showItemCount; // 显示启用数量
    // this.stopAllItemShowCount = this.showItemCount;  // 显示停用数量
  },
  watch: {
    // 'showItemCount': {
    //   // 监听如果选中学生发生改变的话,就初始化默认值.
    //   handler (n, o) {
    //   },
    //    deep: true
    //   immediate: true
    // }
  },
  methods: {
    // 是否显示全部启用项
    doShowStartDataAllClick () {
      this.isShowAllStart = !this.isShowAllStart;
      // console
    },
    //  是否显示全部停用项
    doShowStopDataAllClick () {
      this.isShowAllStop = !this.isShowAllStop;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    // 异常预警点击
    newAdd () {
      if (this.vHasModulePower(this.ModulePowerKey)) {
        if (this.operateButName && this.operateButName == '设置') {
          this.doOpreate('setting');
        } else {
          this.doOpreate('add');
        }
      } else {
        this.$utils.CheckModulePowerToTips(this.ModulePowerKey);
      }
    },
    // 使用/停用开关
    switchClick (boll) {
      this.isSwitchShowBox = boll;
      this.$emit('switchClick', boll);
    },
    // 显示操作菜单
    showOpreateMenu (item) {
      this.showMenuItem(item);
      // 如果没有权限，则 弹出无权限提示。 ToDo        { type: 0, name: '新增', eventName: 'add', isShow: true, ModulePowerKey: this.ModulePowerKey},
      this.menuItemList = [
        { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: this.ModulePowerKey }
      ];
      if (this.hasActive) {
        if (item.isActive) {
          this.menuItemList.push({
            type: 3,
            name: '停用',
            eventName: 'unActive',
            isShow: true,
            ModulePowerKey: this.ModulePowerKey
          });
        } else {
          this.menuItemList.push({
            type: 4,
            name: '启用',
            eventName: 'active',
            isShow: true,
            ModulePowerKey: this.ModulePowerKey
          });
        }
      } else {
        this.menuItemList.push({
          type: -1,
          name: '结束',
          eventName: 'finish',
          isShow: true,
          ModulePowerKey: this.ModulePowerKey

        });
      }
      this.dataItemForOpreate = item;
      // 有权限，Show menu
      // this.isShowOpreateMenu = true;
    },
    doOpreate (opreateType, item) {
      if (this.vStopDataInfoList.length == 0) {
        this.isSwitchShowBox = true;
      } else if (this.vStartDataInfoList.length == 0) {
        this.isSwitchShowBox = false;
      }
      item = item || { dataKey: 0, itemName: '', isActive: true, data: {} };
      // 取消菜单显示
      this.showMenuItem(item);
      this.$emit('onOpreate', opreateType, item.dataKey, item.data, returnData => { });
    },
    showMenuItem (item) {
      if (this.dataInfoList.length > 0) {
        this.dataInfoList.forEach(o => {
          if (item.dataKey == o.dataKey) {
            o.isMenu = !o.isMenu;
            // console.log(item.dataKey, 'item.dataKey');
          } else {
            o.isMenu = false;
          }
        });
      }
    }
  }
};
</script>

  