<template>
  <div class="class_file_course_list single_shadow">
    <div class="class_file_course_list_top">
      <div class="corse_flex1"
           :title="WeekKeyValue"><span>{{WeekKeyValue}}</span></div>
      <div class="corse_flex2"><span>{{planItem.SClassTimeName}}~{{planItem.EClassTimeName}}</span></div>
      <div class="corse_flex3"
           :title="ClassSetKeyValue"><span>{{ClassSetKeyValue}}</span></div>
      <div class="corse_flex4"
           :title="planItem.ClassRoomName">
        <div><span>{{planItem.ClassRoomName}}</span></div>
      </div>
      <div class="corse_flex5"
           :title="planItem.LecturerKeyValue">
        <span class="font_gray">主讲：</span>
        <span>{{planItem.LecturerKeyValue}}</span>
      </div>
      <div class="corse_flex6"
           :title="planItem.InsTeaName">
        <span class="font_gray"
              v-if="planItem.InsTeaName">辅讲：</span>
        <span>{{planItem.InsTeaName}}</span>
      </div>
    </div>
    <div class="class_file_course_list_bottom">
      <div class="corse_flex1"><span>{{StartTime}}~{{EndTime}}</span></div>
      <div class="corse_flex2">
        <span class="font_gray">共</span> <span>{{(AlreadyBeenCount)}}节</span>
      </div>
      <div class="corse_flex3">
        <span class="font_gray">已上：</span> <span>{{AlreadyCount}}节</span>
      </div>
      <div class="corse_flex4">
        <span class="font_gray">剩余：</span> <span>{{diffCount}}节</span>
      </div>
      <div class="corse_flex5"></div>
      <div class="corse_flex6"></div>
    </div>
    <!-- <div class="project_class_student_amend" @click.stop="showEditCourse">
      修改
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'arrangeCourse',
  data () {
    return {

    };
  },
  props: ['planItem'],
  components: {},
  computed: {
    ClassSetKeyValue () {
      // if (this.planItem.ClassSetKeyValue.length > 6) {
      //   return this.planItem.ClassSetKeyValue.substring(0, 5) + '...';
      // } else {
      return this.planItem.ClassSetKeyValue;
      // }
    },
    // 是否每周的频率.
    WeekKeyValue () {
      if (this.planItem.FrequencyKey == 2) {
        return '每' + this.planItem.WeekKeyValue;
      } else {
        return this.planItem.WeekKeyValue;
      }
    },
    // 共排课多少节
    AlreadyBeenCount () {
      return Number(this.planItem.AlreadyBeenCount);
    },
    // 已上多少节
    AlreadyCount () {
      return Number(this.planItem.AlreadyCount);
    },
    // 剩余多少节
    diffCount () {
      return Number(this.planItem.diffCount);
    },
    // 开始时间
    StartTime () {
      console.log('this.planItem', this.planItem);
      if (this.planItem.CircleStartTime) {
        return this.planItem.CircleStartTime.replace(/-/g, '.');
      }
    },
    // 结束时间
    EndTime () {
      if (this.planItem.CircleEndTime) {
        return this.planItem.CircleEndTime.replace(/-/g, '.');
      }
    }
  },
  methods: {
    showEditCourse () {
      this.$emit('showEditCourse', true, this.planItem);
    }
  }
};
</script>

