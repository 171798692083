<template>
  <div class="JXT_record_popup_menu">
    <div>
      <!-- uploading_voice -->
      <input @change="chooseFiles($event)"
             class="uploading_input uploading_input_video"
             type="file"
             accept="video/*">
    </div>
  </div>
</template>

<script>import { UploadFilesShowProgress } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      recorderDuration: 0 // 录音时长
    };
  },
  computed: {
  },
  mounted () {

  },
  methods: {
    // 点击上传音频
    chooseFiles (e) {
      var form = e.target.files[0];
      console.log(e.target.files[0], 'chooseFiles');
      // this.recorderDuration = e.target.files[0].size;
      let filename = (form.name.indexOf('.') > 0) ? form.name : form.name + '.wav';
      var formData = new FormData();
      formData.append('file', form, filename);
      this.uploadAudio(formData);
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      var loadInput = document.getElementsByClassName('uploading_input_video')[0];
      loadInput.value = '';
    },
    // 本地视频上传
    uploadAudio (formData) {
      console.log(formData, 'formData');
      // UploadFilesShowProgress(2, '', formData).then(result => {
      this.$emit('onUploadFiles', formData);
      this.$nextTick(o => {
        this.setInputValue();
      });
      // this.cancelClick();
      // }, err => {
      // layer.alert(err.msg);
      // });
    },
    // 取消
    cancelClick () {
      // this.recorder.destroy();
      this.recorder = null;
      this.$emit('onCancelVoice');
    }
  }
};
</script>
