<template>
  <div :id="echartID"></div>
</template>
<script>
export default {
  name: 'columnarEchart',
  data () {
    return {
      myChart: '',
      screenWidth: ''
    };
  },

  props: {
    echartID: String,
    nowList: Array,
    reqList: Array
  },
  computed: {
    // 这是为了预防 当没有数据时, Y轴刻度 连数字都不显示的问题.  20这个数字到时候直接从外面传值进来处理
  },
  methods: {
    echartInit () {
      if (document.getElementById(this.echartID)) {
        this.myChart = echarts.init(document.getElementById(this.echartID));
        this.myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'line' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            top: '30',
            left: '0',
            right: '8%',
            bottom: '4%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.reqList,
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#666666'
              }
            }
          },
          yAxis: {
            axisLine: {
              show: false,
              lineStyle: {
                color: '#666666'
              }
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            }
          },
          series: [{
            data: this.nowList,
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#477FFF'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#BDD1FF'
              }, {
                offset: 1,
                color: '#ffffff'
              }])
            }
          }]
        });
        console.log(this.myChart, 'echartInit');
      }
    }
  }
};
</script>

