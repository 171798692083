<template>
  <!--系统设置-->
  <div class="system_setting">
    <div class="form_info_edit">
      <div class>
        <input-dialog :formTitle="'上课时间范围'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('上课时间设置','64&65')"
                      :value="$utils.getSaaSClientSetInfo(64).SetContent+'-'+ $utils.getSaaSClientSetInfo(65).SetContent"></input-dialog>

        <input-dialog :formTitle="'签到提前时间'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('签到提前时间',31)"
                      :value="(Number($utils.getSaaSClientSetInfo(31).SetContent))+'分钟'"></input-dialog>

        <form-select :formTitle="'请假扣课时'"
                     class="padding_LR_10"
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="changeType($utils.getSaaSClientSet(32))"
                     :dataList="selectData1"
                     :value="changeType($utils.getSaaSClientSet(32))"
                     @choseItem="showqingjiaDialog"></form-select>

        <div v-if="$utils.getSaaSClientSet(59)>0">
          <form-select :formTitle="'旷课扣课时'"
                       class="padding_LR_10"
                       :readonly="isReadonly"
                       :formPlaceholder="' '"
                       :dataItem="changeType($utils.getSaaSClientSet(33))"
                       :dataList="selectData"
                       :value="changeType($utils.getSaaSClientSet(33))"
                       @choseItem="showkuangkeDialog"></form-select>
        </div>

        <form-select :formTitle="'微网校学生剩余课时'"
                     class="padding_LR_10 "
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="Number($utils.getSaaSClientSet(34))>0?'显示':'不显示'"
                     :dataList="[{key: 1, value: '显示',setKey:34},{key: 0, value: '不显示',setKey:34}]"
                     :value="Number($utils.getSaaSClientSet(34))>0?'显示':'不显示'"
                     @choseItem="formSelectSet"></form-select>

        <div v-if="vIsSysVersionTY">
          <form-select :formTitle="'年月日卡'"
                       class="padding_LR_10 "
                       :readonly="isReadonly"
                       :formPlaceholder="' '"
                       :dataItem="Number($utils.getSaaSClientSet(35))>0?'开启':'关闭'"
                       :dataList="[{key: 1, value: '开启',setKey:35},{key: 0, value: '关闭',setKey:35}]"
                       :value="Number($utils.getSaaSClientSet(35))>0?'开启':'关闭'"
                       @choseItem="formSelectSet"></form-select>
        </div>

        <form-select :formTitle="'考勤旷课及其相关应用'"
                     class="padding_LR_10 "
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="Number($utils.getSaaSClientSet(59))>0?'开启':'关闭'"
                     :dataList="[{key: 1, value: '开启',setKey:59},{key: 0, value: '关闭',setKey:59}]"
                     :value="Number($utils.getSaaSClientSet(59))>0?'开启':'关闭'"
                     @choseItem="formSelectSet"></form-select>

        <input-dialog :formTitle="'老师查看课件时间范围'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('老师查看课件时间范围',18)"
                      :value="vConversion"></input-dialog>

        <form-select v-if="vOpenUpSellSetting"
                     :formTitle="'追销   '+$utils.getSaaSClientSetInfo(17).SetContent"
                     class="padding_LR_10"
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="Number($utils.getSaaSClientSet(17))>0?'开启':'关闭'"
                     :dataList="[{key: 1, value: '开启',setKey:17,content:$utils.getSaaSClientSetInfo(17).SetContent},{key: 0, value: '关闭',setKey:17,content:$utils.getSaaSClientSetInfo(17).SetContent}]"
                     :value="Number($utils.getSaaSClientSet(17))>0?'开启':'关闭'"
                     @choseItem="formSelectSet"></form-select>

        <form-select v-if="vOpenTeachingManeger"
                     :formTitle="'开启备课'"
                     class="padding_LR_10"
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="Number($utils.getSaaSClientSet(68))>0?'开启':'关闭'"
                     :dataList="[{key: 1, value: '开启',setKey:68},{key: 0, value: '关闭',setKey:68}]"
                     :value="Number($utils.getSaaSClientSet(68))>0?'开启':'关闭'"
                     @choseItem="formSelectSet"></form-select>

        <div v-if="Number($utils.getSaaSClientSet(68))>0&&vOpenTeachingManeger">
          <input-dialog :formTitle="'备课提前天数'"
                        class="padding_LR_10 "
                        :formPlaceholder="' '"
                        :readonly="isReadonly"
                        @showEldilog="showSystemSetform('备课提前天数',67)"
                        :value="vPrepareLessonsDays"></input-dialog>
          <input-dialog :formTitle="'备课确认间隔'"
                        class="padding_LR_10 "
                        :formPlaceholder="' '"
                        :readonly="isReadonly"
                        @showEldilog="showSystemSetform('备课确认间隔',66)"
                        :value="vPrepareLessonsIntervalTime"></input-dialog>
        </div>
        <form-select :formTitle="'自动离校'"
                     class="padding_LR_10"
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="Number($utils.getSaaSClientSet(72))>0?'开启':'关闭'"
                     :dataList="[{key: 1, value: '开启',setKey:72},{key: 0, value: '关闭',setKey:72}]"
                     :value="Number($utils.getSaaSClientSet(72))>0?'开启':'关闭'"
                     @choseItem="formSelectSet"></form-select>
        <form-select v-if="vOpenApontCourseSetting"
                     :formTitle="'约课'"
                     class="padding_LR_10 "
                     :readonly="isReadonly"
                     :formPlaceholder="' '"
                     :dataItem="Number($utils.getSaaSClientSet(73))>0?'开启':'关闭'"
                     :dataList="[{key: 1, value: '开启',setKey:73},{key: 0, value: '关闭',setKey:73}]"
                     :value="Number($utils.getSaaSClientSet(73))>0?'开启':'关闭'"
                     @choseItem="formSelectSet"></form-select>
        <input-dialog :formTitle="'超时考勤控制'"
                      class="padding_LR_10"
                      :formPlaceholder="'老师不可超时考勤'"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('超时考勤控制',86)"
                      :value="vAttenSet"></input-dialog>
        <input-dialog :formTitle="'报名补充校正'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="isShowSupplementSet=true"
                      :value="vSupplementSet"></input-dialog>
        <input-dialog :formTitle="'考勤严格模式'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showStrictAttenform"
                      :value="vStrictAttenSet"></input-dialog>
        <!-- <input-dialog :formTitle="'单节课退课'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('单节课退课',122)"
                      :value="Number($utils.getSaaSClientSet(122))>0?'开启':'关闭'"></input-dialog>
        <input-dialog :formTitle="'退出循环课'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('退出循环课',123)"
                      :value="Number($utils.getSaaSClientSet(123))>0?'开启':'关闭'"></input-dialog>
        <input-dialog :formTitle="'考勤校正'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('考勤校正',124)"
                      :value="Number($utils.getSaaSClientSet(124))>0?'开启':'关闭'"></input-dialog>            
        <input-dialog :formTitle="'停课'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('停课',125)"
                      :value="Number($utils.getSaaSClientSet(125))>0?'开启':'关闭'"></input-dialog>
        <input-dialog :formTitle="'单节课清除排课'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('单节课清除排课',126)"
                      :value="Number($utils.getSaaSClientSet(126))>0?'开启':'关闭'"></input-dialog>
        <input-dialog :formTitle="'批量清除排课'"
                      class="padding_LR_10"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      @showEldilog="showSystemSetform('批量清除排课',127)"
                      :value="Number($utils.getSaaSClientSet(127))>0?'开启':'关闭'"></input-dialog>
       -->
      </div>
    </div>
    <!-- 系统设置弹窗 -->
    <custom-dialog :title="systemSetFormInfo.title"
                   class="new_class defult_dialog"
                   :visible.sync="isShowSystemSetForm"
                   :before-close="doCloseShowForm">
      <mendian-system-set-form :value="newInputText"
                               :scopeTime="PeriodTimeData"
                               :systemSetFormInfo="systemSetFormInfo"
                               :seletedSettingInfo="seletedSettingInfo"
                               @editSettingInfo="editSettingInfo"
                               @editSettingInfoItem="editSettingInfoItem"
                               @doCloseShowForm="doCloseShowForm"
                               @modifySetting="upModifySetting"></mendian-system-set-form>
    </custom-dialog>
    <!-- 报名补充校正弹窗 -->
    <custom-dialog title="报名补充校正"
                   width="400px"
                   :visible.sync="isShowSupplementSet"
                   :before-close="closeSupplementSet">
      <supplementtarySetting @afterSuccess="closeSupplementSet"
                             @cancelClick="closeSupplementSet"></supplementtarySetting>
    </custom-dialog>
     <!-- 考勤严格模式 -->
    <custom-dialog title="考勤严格模式"
                    class="new_class defult_dialog"
                   :visible.sync="isShowStrictAttenform"
                   :before-close="doCloseShowForm">
      <strict-atten-form  @updateStrictAtten="updateStrictAtten"
                          @closeFrom="doCloseShowForm"></strict-atten-form>
    </custom-dialog>
    
  </div>
</template> 

<script>
import timeSelectModule from '../../../common/time-select-module';
import formSelect from '../../../form-item/form-select';
import mendianSystemSetForm from './mendian-system-set-form';
import strictAttenForm from './strict-atten-form'
import { SynchronousUpdateIsLeaveOrIsCharges } from '../../../../API/workbench';
import supplementtarySetting from '../../../registration-supplementary-correction/report/supplementtary-setting.vue';
export default {
  props: ['mendianInfo'],
  data () {
    return {
      PeriodTimeData: {},
      newInputText: 0, // 默认值
      systemSetFormInfo: { title: '', setKey: '' },
      isShowSystemSetForm: false, // 系统表单设置弹窗
      selectData: [
        { key: 2, value: '不扣课时 （学生旷课时不扣课时，建课时不提示）' },
        { key: 3, value: '扣课时  （学生旷课时默认扣课时，建课时不提示）' },
        { key: 4, value: '建课设置 （建课时手动选择旷课是否扣课时）' }
      ],
      selectData1: [
        { key: 2, value: '不扣课时 （学生请假时不扣课时，建课时不提示）' },
        { key: 3, value: '扣课时  （学生请假时默认扣课时，建课时不提示）' },
        { key: 4, value: '建课设置 （建课时手动选择请假是否扣课时）' }
      ],
      isShowSupplementSet: false,
      seletedSettingInfo:null,
      isShowStrictAttenform:false
    };
  },
  components: { formSelect, timeSelectModule, mendianSystemSetForm, supplementtarySetting,strictAttenForm },
  created () {
    this.PeriodTimeData.bgeinTime = this.$utils.getSaaSClientSetInfo(64).SetContent || '';
    this.PeriodTimeData.endTime = this.$utils.getSaaSClientSetInfo(65).SetContent || '';
  },
  computed: {
    // 判断权限,
    isReadonly () {
      let modulePowerList = this.$store.getters.RolePowerList;
      return !this.$utils.CheckModulePower(modulePowerList, 38);
    },
    // 门店版本
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;// true：通用版，false精准版
    },
    vConversion () {
      if (Number(this.$utils.getSaaSClientSetInfo(18).SetContent) == 0) {
        return '限当天';
      } else {
        return '上课前后' + Number(this.$utils.getSaaSClientSetInfo(18).SetContent) + '天';
      }
    },
    vPrepareLessonsDays () {
      return Number(this.$utils.getSaaSClientSetInfo(67).SetContent) + '天';
    },
    vPrepareLessonsIntervalTime () {
      return Number(this.$utils.getSaaSClientSetInfo(66).SetContent) + '秒';
    },
    vAttenSet () {
      return Number(this.$utils.getSaaSClientSetInfo(86).SetContent) + '天';
    },
    // 开启销售中心
    vOpenUpSellSetting () {
      return this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule, 6);
    },
    // 是否开通约课设置
    vOpenApontCourseSetting () {
      return this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule, 3);
    },
    // 开启督学设置
    vOpenTeachingManeger () {
      return this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule, 8);
    },
    // 报名补充校正开启情况
    vSupplementSet () {
      let switch1 = this.$utils.getSaaSClientSetInfo(100).SetKey == '1';
      let switch2 = this.$utils.getSaaSClientSetInfo(101).SetKey == '1';
      let switch3 = this.$utils.getSaaSClientSetInfo(102).SetKey == '1';
      if (switch1 || switch2 || switch3) {
        return '开启';
      } else {
        return '关闭';
      }
    },
    // 考勤严格模式开启情况
    vStrictAttenSet () {
      let switch1 = this.$utils.getSaaSClientSetInfo(122).SetKey == '1';
      let switch2 = this.$utils.getSaaSClientSetInfo(123).SetKey == '1';
      let switch3 = this.$utils.getSaaSClientSetInfo(124).SetKey == '1';
      let switch4 = this.$utils.getSaaSClientSetInfo(125).SetKey == '1';
      let switch5 = this.$utils.getSaaSClientSetInfo(126).SetKey == '1';
      let switch6 = this.$utils.getSaaSClientSetInfo(127).SetKey == '1';
      
      if (switch1 || switch2 || switch3||switch4 || switch5 || switch6) {
        return '开启';
      } else {
        return '关闭';
      }
    },
    
  },
  methods: {
    // 修改系统设置
    editSettingInfoItem (val) {
      console.log('editSettingInfoItem',val)
      let obj = this.$utils.getSaaSClientSetInfo(this.systemSetFormInfo.setKey);
      obj=val
      console.log(obj, '修改系统设置');
      this.$emit('modifySetting', obj);
    },
    // 修改系统设置
    editSettingInfo (val) {
      let obj = this.$utils.getSaaSClientSetInfo(this.systemSetFormInfo.setKey);
      if (this.systemSetFormInfo.setKey == 32 || this.systemSetFormInfo.setKey == 33) { // 请假，旷课扣课时设置
        obj.SetKey = val;
        obj.SetKeyValue = this.changeType(val);
      } else {
        obj.SetContent = val;
      }
      console.log(obj, '修改系统设置');
      this.$emit('modifySetting', obj);
    },
    // 请假扣课时（特例）
    showqingjiaDialog (val) {
      console.log(val, 'typetype');
      this.systemSetFormInfo.setKey = 32;
      this.systemSetFormInfo.title = '请假扣课时设置';
      this.changeVal(val.key);
    },
    // 旷课扣课时 （特例）
    showkuangkeDialog (val) {
      this.systemSetFormInfo.setKey = 33;
      this.systemSetFormInfo.title = '旷课扣课时设置';
      this.changeVal(val.key);
    },
    // 系统下拉选择设置
    formSelectSet (obj) {
      let item = {
        SetKey: obj.key,
        SetKeyValue: obj.value,
        SetContent: (obj.setKey) == 17 ? obj.content : '', // 17 是否追销
        SetTypeKey: obj.setKey
      };
      this.$emit('modifySetting', item);
    },
    // 考勤严格模式表单
    showStrictAttenform(){
      this.isShowStrictAttenform=true
    },
    // 系统弹窗设置
    showSystemSetform (title, setKey, multipleKey) {
      this.systemSetFormInfo.title = title;
      this.systemSetFormInfo.setKey = setKey;
      if (Number(setKey) > 0) {
        this.newInputText = Number(this.$utils.getSaaSClientSetInfo(setKey).SetContent);
        this.seletedSettingInfo = this.$utils.getSaaSClientSetInfo(setKey);
      } else if (setKey == '64&65') {
        this.PeriodTimeData.bgeinTime = this.$utils.getSaaSClientSetInfo(64).SetContent;
        this.PeriodTimeData.endTime = this.$utils.getSaaSClientSetInfo(65).SetContent;
      }
      this.isShowSystemSetForm = true;
    },
    // 更新系统设置
    upModifySetting (obj) {
      this.$emit('modifySetting', obj);
    },
    updateStrictAtten(list){
      this.$emit('modifySettingList', list);
    },
    // 关闭弹窗
    doCloseShowForm () {
      this.isShowStrictAttenform=false
      this.isShowSystemSetForm = false;
    },
    // 同步更新请假旷课是否扣课时
    UpdateIsLeave () {
      let type = (this.systemSetFormInfo.setKey == 32 ? 1 : 2);
      SynchronousUpdateIsLeaveOrIsCharges(type).then(res => {
        console.log(res, '同步更新请假旷课是否扣课时成功');
      }, err => {
        layer.alert(err.msg);
      });
    },
    changeType (key) {
      if (Number(key) == 2) {
        return '不扣课时';
      } else if (Number(key) == 3) {
        return '扣课时';
      } else if (Number(key) == 4) {
        return '建课设置';
      }
    },
    // 菜单操作项
    changeVal (type) {
      if (type == 2 || type == 3) {
        let text = (type == 2 ? '不扣课时' : '扣课时');
        layer.confirm('是否将未开始的课修改为' + text, {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                this.editSettingInfo(type);
                // this.UpdateIsLeave();
              }
            }]
        });
      } else {
        this.editSettingInfo(type);
      }
    },
    closeSupplementSet () {
      this.isShowSupplementSet = false;
    }
  }
};
</script>

<style scoped>
.system_setting >>> .form_info_line .form_info_field {
  font-size: 16px !important;
  color: #333;
}
</style>
