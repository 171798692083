<template>
  <!-- 校区备课检查 -->
  <div class="prepare_lesson_module all_view">
    <!-- 头部 -->
    <prepare-lessons-head :titleList="prepareLessonsTitleList"></prepare-lessons-head>

    <!-- 待检查 -->
    <div v-if="prepareLessonsTitleList[0].isSelected">
      <await-examine-list></await-examine-list>
    </div>

    <!-- 已检查 -->
    <div v-else-if="prepareLessonsTitleList[1].isSelected">
      <already-examine-list></already-examine-list>
    </div>

    <!-- 逾期未备 -->
    <div v-else-if="prepareLessonsTitleList[2].isSelected">
      <overdue-examine-list></overdue-examine-list>
    </div>
    <div>
      <dialog-factory ref="campusPrepareLessonsExamine"
                      :routerName="'campusPrepareLessonsExamine'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import prepareLessonsHead from './common/prepare-lessons-head';
import awaitExamineList from './table-list/await-examine-list';
import alreadyExamineList from './table-list/already-examine-list';
import overdueExamineList from './table-list/overdue-examine-list';
import prepareLessonsExamine from './form/prepare-lessons-examine';
import dialogFactory from '../../common/dialog-factory';
export default {
  // name: 'campusPrepareLessonsExamine',
  data () {
    return {
      prepareLessonsTitleList: [
        { name: '待检查', key: 1, isSelected: true },
        { name: '已检查', key: 2, isSelected: false },
        { name: '逾期未备', key: 3, isSelected: false }
      ],
      showManagement: 'mendainList'
    };
  },
  components: {
    prepareLessonsHead,
    awaitExamineList,
    alreadyExamineList,
    overdueExamineList,
    prepareLessonsExamine,
    dialogFactory
  },
  created () {
    console.log('openCustomDialog', this.$store.getters);
    this.registerBusEvent();
  },
  mounted () {

  },
  methods: {
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        // console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'campusPrepareLessonsExamine') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.campusPrepareLessonsExamine) {
        this.$refs.campusPrepareLessonsExamine.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  },
  computed: {}
};
</script>

<style>
.prepare_lesson_module {
  width: 1366px;
  margin: 0 auto;
  /* margin: 20px 50px 50px 50px; */
}
.prepare_lesson_module .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.prepare_lesson_module .table_nav_box {
  background-color: #fff;
  width: 1290px;
}
.prepare_lesson_module .table_fixed_nav_list {
  padding: 0px 35px;
}
.prepare_lesson_module .table_select_box {
  margin-right: 10px;
}
.prepare_lesson_module .course_statistics_title {
  padding: 0;
}
.prepare_lesson_module .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.prepare_lesson_module .table_select_box .el-select {
  width: 200px;
}
.prepare_lesson_module
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}
</style>

