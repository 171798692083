<template>
  <div class="margin_left_right_div">
    <div class="general_summarizing_student payment_statistics_view height_type all_view template_minOrmax_width_div">
      <div class="single_result_detail"
           style="width:100%">

        <div class="accraditation_summary">
          <div class="course_class_summarizing_right"
               style="width:100%">
            <div class="flex">
              <div class="right_top_box"
                   style="width:50% !important">
                <title-summary-data v-for="(item,key,index) in headSummaryData"
                                    :sumData="item"
                                    :key="index"
                                    @onCilckEvent="doTitleBoxClick"></title-summary-data>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="course_statistics_table_search course_statistics_title"
           v-if="batchAllotList.length>0">
        <batch-distribution-box :batchAllotList="batchAllotList"
                                @doCancelAllot="doCancelAllot"></batch-distribution-box>
      </div>
      <div class="course_statistics_table_search course_statistics_title"
           v-else>
        <div class="table_select_box"
             style="margin-right:20px;min-width:306px">
          <div class="table_select_title">课程顾问</div>
          <el-select :value="vsaleManValue"
                     placeholder="请选择"
                     style="width:239px"
                     ref="resetText"
                     @change="selectOverdueType">
            <el-option v-for="item in vsalesList"
                       :key="item.value"
                       :label="item.label"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="follow_clue">
          <div class="flex">
            <div class="course_search_select border_right search_top"
                 style="width:110px">
              <el-select :value="vdateTypeValue"
                         size="small"
                         @change="chgSearchValue">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item">

                </el-option>
              </el-select>
            </div>
            <date-range ref="monthPicker"
                        style="margin-right:0px;min-width:250px"
                        :fromTitle="''"
                        :searchObj="searchObj"
                        @changeDate="changeSaveDate"></date-range>
          </div>
        </div>

        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
        </div>
      </div>
      <div class="course_statistics_table_box table_list_content follow_clue_list">
        <table-list class="summarizing_list"
                    ref="tableListRef"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :rowKey="'StudentKey'"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    :isShowCheckBox="true"
                    @changeCheckBoxFun="changeCheckBoxFun"
                    @loadTableData="getDataList"></table-list>
      </div>
      <custom-dialog :title="'分配线索'"
                     class=" "
                     width="410px"
                     :visible.sync="showAllot"
                     :before-close="closeshowAllot">
        <allot-dialog :dataInfo="seletedInfo"
                      :studentArray="studentArray"
                      @updateData="updateData"
                      @closeDialog="closeshowAllot"></allot-dialog>
      </custom-dialog>
      <div>
        <dialog-factory ref="followingClueDialogFactory"
                        :key="'followingClue'"
                        :routerName="'followingClue'"></dialog-factory>

        <dialog-factory-common ref="followingClueDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import allotDialog from './allot-dialog';
import dialogFactory from '../../../common/dialog-factory';
import titleSummaryData from './title-summary-data';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import batchDistributionBox from './batch-distribution-box';
import { SearchPageForFollowUpThread, SaleStatRecycleStudent, GetAllSales, DoAllocationThread } from '../../../../API/workbench';
export default {
  name: 'followingClue',
  data () {
    return {
      seletedInfo: {},    // 选中的单项
      studentDetail: {},
      batchAllotList: [], // 批量分配列表
      headSummaryData: [
        { title: '全部', className: '', value: 0, StatusKey: 0, decimalPlace: 0, isSelected: true },
        { title: 'S单', className: '', value: 0, StatusKey: 1, decimalPlace: 0, isSelected: false },
        { title: 'A单', className: '', value: 0, StatusKey: 2, decimalPlace: 0, isSelected: false },
        { title: 'B单', className: '', value: 0, StatusKey: 3, decimalPlace: 0, isSelected: false },
        { title: 'C单', className: '', value: 0, StatusKey: 4, decimalPlace: 0, isSelected: false }
      ],
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        type: 0,
        saleMan: 0,
        saleManValue: '',
        dateType: 1,  //	1:登记日期,2:下次跟进日期,3:最近跟进日期
        dateTypeValue: '登记日期'
      },
      options: [
        { label: '登记日期', value: 1 },
        { label: '下次跟进日期', value: 2 },
        { label: '最近跟进日期', value: 3 }

      ],
      salesList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      studentArray: [],     // 选中的分配学生.
      showAllot: false, // 是否显示分配弹出层
      tableCourseColumns: [
        // 明细总览 table 列

        {
          label: '课程顾问',
          prop: 'AdviserKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue button',
            click: (rowData) => {
              this.allotData(rowData);
            }
          }
        },

        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 100,
          sortable: false,
          type: 'student',
          extend: {
            studentKey: 'StudentKey',
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '意向级别',
          prop: 'WillingnessRateKeyValue',
          width: 60,
          sortable: false,
          align: 'center',
          type: 'textItem',
          columnClass: 'font_dual'
        },
        {
          label: '兴趣课',
          prop: 'FineSupport',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '到店参观',
          prop: 'ToStoreAppointTime',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              return this.setStoreClassType(rowData, 'ToStoreResultKey');
            }
          }
        },
        {
          label: '试课记录',
          prop: 'TrialClassValue',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              return this.setTrialClassType(rowData, 'TrialClassResultKey');
            }
          }
        },
        {
          label: '是否报名',
          prop: 'EnlistTimeApply',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '跟进情况描述',
          prop: 'followContent',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '下次跟进',
          prop: 'NextFollowUpTime',
          sortable: false,
          minWidth: 85,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              if (rowData.IsAbandonKey == 1) {
                return 'font_red';
              }
            }
          }
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '登记日期',
          prop: 'RegisterTime',
          sortable: false,
          minWidth: 160,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    titleSummaryData,
    dialogFactory,
    allotDialog,
    batchDistributionBox
  },
  props: {
  },
  created () {
    console.log('this.toSearchObj', this.$route);

    this.searchObj.saleManValue = this.$route.query.SaleMan || '';
    this.searchObj.saleMan = this.$route.query.SaleManKey || 0;
    if (this.$route.query.type >= 0) {
      this.searchObj.type = this.$route.query.type || 0;
      this.headSummaryData.forEach(o => {
        if (o.StatusKey == this.$route.query.type) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
    }
  },
  mounted () {
    this.getDataList();
    this.resgisterBusEvent();
    this.getSalesList();
  },
  computed: {
    vdateTypeValue () {
      return this.searchObj.dateType == 1
        ? '登记日期'
        : this.searchObj.dateTypeValue;
    },
    vsaleManValue () {
      return this.searchObj.saleMan == 0
        ? '全部'
        : this.searchObj.saleManValue;
    },
    vsalesList () {
      return this.salesList;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.NextFollowUpTime = '2021-10-11 16:30:25';
        // o.Content = '测试133113';
        // o.FollowUpTime = '2021-10-11 16:30:25';
        o.WillingnessRateKeyValue = o.WillingnessRateKeyValue.substring(0, 1);
        o.AdviserKeyValue = o.AdviserKeyValue || '-';
        o.EnlistTimeApply = o.EnlistTime ? '是' : '否';
        o.followContent = o.Content ? `<span class="font_gray">` + this.$utils.StrForTodoTime(o.FollowUpTime) + `:</span>` + o.Content : '';
        o.ToStoreAppointTime = o.ToStoreAppointTime || '';
        o.TrialClassAppointTime = o.TrialClassAppointTime || '';
        o.ToStoreAppointTime = o.ToStoreAppointTime.substring(5, 10);
        if (o.TrialClassAppointTime) {
          o.TrialClassValue = `<div class="flex try_course_apoint">` + o.TrialClassAppointTime.substring(5, 10) + `</div><div>` + o.OrderClassKeyValue + `</div>`;
        }
        if (o.IsAbandonKey == 1) {
          o.NextFollowUpTime = '已放弃';
        } else {
          o.NextFollowUpTime = (o.NextFollowUpTime ? this.$utils.leadTime(o.NextFollowUpTime.substring(0, 10)) + ' ' + `<div>` + o.NextFollowUpTime.substring(5, 10) + `</div>` : '');
        }
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      // if (this.totalAmount > 0) {
      //   text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // } else {
      //   text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // }
      return text;
    }
  },

  methods: {
    setStoreClassType (item, type) {
      if (item[type] == 0) {
        return '';
      } else if (item[type] == 1) {
        return 'list_type_green';
      } else if (item[type] == 2) {
        return 'list_type_red';
      } else if (item[type] == 3) {
        return 'list_type_red';
      } else {
        return '';
      }
    },
    setTrialClassType (item, type) {
      if (item[type] == 0) {
        return 'list_Trial_type_gray';
      } else if (item[type] == 1) {
        return 'list_Trial_type_green';
      } else if (item[type] == 2) {
        return 'list_Trial_type_red';
      } else if (item[type] == 3) {
        return 'list_Trial_type_red';
      } else {
        return '';
      }
    },
    // 选择搜索类型
    chgSearchValue (item) {
      console.log(item, '选择搜索类型');
      this.searchObj.dateType = item.value;
      this.searchObj.dateTypeValue = item.label;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 销售人员层显示并获取数据
    getSalesList () {
      GetAllSales()
        .then(result => {
          result.data.forEach(o => {
            o.label = o.MainDemoName + (o.IsLeaveKey != 1 ? ' (离职)' : '');
            o.value = o.OLAPKey;
            // this.$set(o, 'isActive', false);
          });
          result.data.unshift({
            label: '全部',
            value: 0
          });
          this.salesList = result.data;
        })
        .catch(err => {
          console.info(err);
        });
    },
    doTitleBoxClick (item) {
      console.log(item, 'doAfterTitleBoxClick');
      // 切换时清除已选中的复选项
      if (this.searchObj.type != item.StatusKey) {
        this.doCancelAllot();
      }
      // this.accraditationList = [];
      this.headSummaryData.forEach(o => { // 高亮显示
        if (item.title == o.title) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.searchObj.type = item.StatusKey;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '全部';
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 销售人员层显示并获取数据
    setAfterDay (dateStr) {
      if (!dateStr) {
        return '';
      }
      let times = Date.new(dateStr).getTime() - Date.new(Date.new().toLocaleDateString()).getTime();
      if (times == 0) {
        return '今天';
      } else if (times > 0) {
        return (times / 86400000) + '天后';
      } else {
        return '已过期';
      }
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.PastDueType = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '全部';
      // this.searchObj.type = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      SearchPageForFollowUpThread(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        // result.data.PageDataList.forEach(o => {
        //   o.OLAPKey = o.StudentKey;
        // });
        this.dataReportList = result.data.PageDataList;
        this.headSummaryData[0].value = result.data.CountStat.AllNum;
        this.headSummaryData[1].value = result.data.CountStat.SNum;
        this.headSummaryData[2].value = result.data.CountStat.ANum;
        this.headSummaryData[3].value = result.data.CountStat.BNum;
        this.headSummaryData[4].value = result.data.CountStat.CNum;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    closeshowAllot () {
      this.showAllot = false;
    },
    // 分配
    allotData (item) {
      this.seletedInfo = item;
      this.studentArray = [];
      this.studentArray.push(this.seletedInfo.StudentKey);
      this.showAllot = true;
    },
    // 批量分配
    changeCheckBoxFun (list) {
      this.batchAllotList = list;
      console.log(this.batchAllotList, '批量分配');
    },
    // 取消分配
    doCancelAllot (succeed) {
      this.batchAllotList = [];
      this.$refs.tableListRef.checkBoxList = [];
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      if (succeed) {
        this.getDataList();
      }
    },
    // 单个分配
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.doCancelAllot();
      this.getDataList();
    },
    // 删除
    deletedData (item) {
      SaleStatRecycleStudent(item.OLAPKey).then(result => {
        console.log('RecycleStudent');
        layer.alert('删除成功');
        this.getDataList();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.$dialog.open(this, {
            name: '学生档案',
            routerName: this.$route.name,
            moduleName: 'studentFile',
            data: { studentID: rowData.StudentNumKey }
          });

          break;
        default:
          break;
      }
    },
    selectOverdueType (item) {
      console.log('selectOverdueType');
      this.searchObj.saleMan = item.value;
      this.searchObj.saleManValue = item.label;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.followingClueDialogFactory) {
        this.$refs.followingClueDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.followingClueDialogFactoryCommon) {
        this.$refs.followingClueDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'followingClue') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'followingClue') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }

  }
};
</script>