<template>
  <div class="list">
    <div class="formbox">
      <a class="btn btn_1" @click="addPlan">添加一码通方案</a>
    </div>
    <el-table :data="tableData">
      <el-table-column prop="GenerateTime" label="添加日期" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="MainDemoName" label="方案名" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="RelevanceActivityKeyValue" label="活动名" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="TypeKeyValue" label="类型" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="you_hu_money" label="活动金额" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="RebateAmount" label="佣金金额" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column label="活动状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.RelevanceActivityTypeKey==1">停用</span>
          <span v-if="scope.row.RelevanceActivityTypeKey==2">过期</span>
          <span v-if="scope.row.RelevanceActivityTypeKey==3">正常</span>
        </template>
      </el-table-column>
      <el-table-column prop="StudentCount" label="累计报名学生" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="ApplyAmount" label="累计报名金额" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="ShouldRebateAmount" label="累计应返佣金" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="RebateAmount1" label="累计实返佣金" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.StatusKey==0">停用</span>
          <span v-if="scope.row.StatusKey==1">启用</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="70">
        <template slot-scope="scope">
          <a class="row_btn" v-if="scope.row.StatusKey==1" @click="stopPlan(scope.row)">停用</a>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="search.pageIndex" :page-sizes="[5,10,20,30,40,50]" :page-size="search.pageSize" layout="total,prev,pager,next,jumper,sizes" :total="totalNum" background></el-pagination>
    <custom-dialog title="添加一码通方案" width="500px" :visible.sync="isShowAdd" :before-close="closeAdd">
      <setup-plan-add @afterSuccess="successAdd" @cancelClick="closeAdd"></setup-plan-add>
    </custom-dialog>
  </div>
</template>

<script>
import {GetChannelActivityList,UpdateStopChannelActivity} from '../../../API/workbench';
import setupPlanAdd from './setup-plan-add.vue';
export default {
  data () {
    return {
      search:{
        pageIndex:1,
        pageSize:10
      },
      tableData:[],
      totalNum:0,
      PageCount:0,
      isShowAdd:false
    };
  },
  components: {
    setupPlanAdd
  },
  props: {
  },
  created () {
  },
  mounted () {
    this.getList();
  },
  computed: {
  },
  methods: {
    sizeChange(val){
      this.search.pageSize = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    currentChange(val){
      this.search.pageIndex = val;
      this.getList();
    },
    addPlan () {
      this.isShowAdd = true;
    },
    closeAdd () {
      this.isShowAdd = false;
    },
    successAdd () {
      this.isShowAdd = false;
      this.search.pageIndex = 1;
      this.getList();
    },
    stopPlan (row) {
      let that = this;
      layer.confirm('确认停用方案？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            UpdateStopChannelActivity(row.OLAPKey).then(result => {
              if (result.code == 0) {
                that.getList();
              } else {
                console.log(result.msg);
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }]
      });
    },
    getList () {
      let that = this;
      let params = {
        pageIndex:that.search.pageIndex - 1,
        pageSize:that.search.pageSize
      };
      GetChannelActivityList(params).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].you_hu_money = '￥' + Number(list[i].you_hu_money);
            list[i].RebateAmount = '￥' + Number(list[i].RebateAmount);
            list[i].StudentCount = Number(list[i].StudentCount);
            list[i].ApplyAmount = '￥' + Number(list[i].ApplyAmount);
            list[i].ShouldRebateAmount = '￥' + Number(list[i].ShouldRebateAmount);
            list[i].RebateAmount1 = '￥' + Number(list[i].RebateAmount1);
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
</style>