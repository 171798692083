<!-- 在读课程 -->
<template>
 <el-table-column :fixed="columnData.fixed||false"
                              :label="columnData.label"
                              :prop="columnData.prop"
                              :width="columnData.width||200"
                              :align="columnData.align||'left'"
                              :label-class-name="columnData.columnClass">
  <template slot-scope="scope">
    <span v-if="scope.row[columnData.prop]">
      <div v-if="scope.row[columnData.prop].split(',').length>2">
      <el-popover>
        <p v-for="(val,index) in scope.row[columnData.prop].split(',')"
            :key="index">{{ val }}</p>
        <div slot="reference" class="name-wrapper">
          <div size="medium">{{scope.row[columnData.prop].split(',')[0]}}</div>
          <div size="medium" class="list_down">{{ scope.row[columnData.prop].split(',')[1]}}</div>
        </div>
      </el-popover>
      </div>
      <div v-else slot="reference"
                       class="name-wrapper">
        <div :title="popTitle(scope.row[columnData.prop].split(',')[0])">{{ scope.row[columnData.prop].split(',')[0]}}</div>
        <div :title="popTitle(scope.row[columnData.prop].split(',')[1])">{{ scope.row[columnData.prop].split(',')[1]}}</div>
      </div>
    </span>
    <span v-else  :class="columnData.className">{{scope.row[columnData.prop]}}</span>
  </template>
</el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            splitSymbol: ',' // 分割符号
            // courseIDs: 'ApplyClassKey', // 在读课程IDs
            // courseNames: 'ApplyClassKeyValue' // 在读课程Names
          }
        };
      }
    }
  },
  created () {
  },
  methods: {
    splitApplyCourseValue (rowData) {
      let applyCourseList = null;
      if (rowData[this.columnData.prop] && (!this.columnData.extend || !this.columnData.extend.splitSymbol || this.columnData.extend.splitSymbol == ',')) {
        applyCourseList = rowData[this.columnData.prop].split(',');
      } else {
        applyCourseList = rowData[this.columnData.prop].split(this.columnData.extend.splitSymbol);
      }
      return applyCourseList;
    },
    popTitle (val) {
      if (val && val.length > 10) {
        return val;
      } else {
        return '';
      }
    },
    afterEnter () {
      console.log('afterEnter');
    },
    afterLeave () {
      console.log('afterLeave');
    },
    show (e) {
      console.log('show', e);
    },
    hide () {
      console.log('hide');
    }
  }
};
</script>

<style>

</style>