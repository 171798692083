<template>
  <div>
    <div class="apply_course_details_list">
      <div class="list_left">
        <div class="list_left_title clearfix"
             v-if="Number(tickItem.TaoCanKey)>0">
          <div class="list_left_title_icon fl">{{Number(tickItem.TaoCanKey)>0?'套餐':'非套餐'}}</div>
          <div class="fl">{{vTaoCanKeyValue}}</div>
        </div>
        <div class="list_left_range"
             v-if="Number(tickItem.TaoCanKey)>0">
          <span class="font_black">适用:</span>
          {{tickItem.ApplyCorName}}
        </div>
        <div class="list_left_title clearfix"
             v-else>
          <div class="fl">{{tickItem.ApplyCorName}}</div>
        </div>
        <div class="list_left_course">
          <span class="font_black">{{vGenerateTime}}</span> 购买 <span class="font_black">{{Number(tickItem.NumCount)}}</span> 课时 | 消耗<span class="font_black"> {{$utils.saveDecimal(Number(tickItem.expendCount)||$utils.saveDecimal(Number(tickItem.Sum_SpendCount))||0)}} </span>课时
          <!-- 剩余 <span class="font_black">{{Number(tickItem.PeriodCount)}}</span> 天 <span class="font_black">{{Number(tickItem.CurrentCount)}}</span> 课时 -->
        </div>
        <div class="list_left_course">
          可用<span class="font_black"> {{Number(tickItem.CurrentCount)}}</span> 课时 | 当前报课<span class="font_blue"> {{$utils.saveDecimal(Number(tickItem.HasBingCount)||$utils.saveDecimal(Number(tickItem.Sum_CurrentCount))||0)}} </span>课时
        </div>
      </div>
      <div class="list_right"
           v-if="Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)>0">
        <div class="font_block">待报课时</div>
        <div class="font_red font_big">{{Number(waitPlanCount)}}</div>

      </div>
      <div class="list_right"
           v-else-if="Number(tickItem.Sum_SuperQuote)>0">
        <div class="font_block">超报课</div>
        <div class="font_red font_big">{{Number(tickItem.Sum_SuperQuote)}}</div>
      </div>
      <div class="list_right"
           v-else>
        <div class="font_block">待报课时</div>
        <div class="font_green font_big">0</div>

      </div>
      <div class="list_btn"
           @click="chgTickItem">

      </div>
    </div>
    <!-- 绿色类名 no_warning -->
    <!-- <div class="warning_text_box" v-if="Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)>0">
      待报课{{Number(tickItem.CurrentCount)>Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)?Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged):Number(tickItem.CurrentCount)}}课时
    </div>
    <div class="warning_text_box" v-else-if="Number(tickItem.Sum_SuperQuote)>0">
      未报课签到消耗{{Number(tickItem.Sum_SuperQuote)}}课时
    </div>
    <div class="warning_text_box no_warning" v-else>
      无需报课
    </div> -->
  </div>

  <!-- <div class="swiper-slide">
    <div class="swiper_combo">套餐</div>
    <div class="swiper_title">
      <div class="swiper_title_name">{{vTaoCanKeyValue}}</div>
      <div class="swiper_title_introduce">适用丨{{tickItem.ApplyCorName}}</div>
      <div class="swiper_title_course">
        {{vGenerateTime}}购买
        <span class="font_black">{{Number(tickItem.NumCount)}}</span>课时,剩余
        <span class="font_black">{{Number(tickItem.PeriodCount)}}</span>天
      </div>
    </div>
    <div class="swiper_conetnt">
      <div class="swiper_conetnt_list">
        <div class="swiper_conetnt_list_title">当前剩余</div>
        <div class="swiper_conetnt_list_number">{{Number(tickItem.CurrentCount)}}</div>
      </div>
      <div class="swiper_conetnt_list">
        <div class="swiper_conetnt_list_title">已报课</div>
        <div class="swiper_conetnt_list_number">{{Number(tickItem.HasBingCount)}}</div>
      </div>
      <div class="swiper_conetnt_list">
        <div class="swiper_conetnt_list_title">待报课</div>
        <div class="swiper_conetnt_list_number font_red">{{Number(tickItem.WaitForPlanCount)}}</div>
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  data () {
    return {};
  },
  props: ['tickItem', 'waitPlanCount'],
  computed: {
    vGenerateTime () {
      if (this.tickItem && this.tickItem.GenerateTime) {
        let GenerateTime = this.tickItem.GenerateTime.substring(0, 10);
        return GenerateTime;
      }
    },
    vTaoCanKeyValue () {
      if (this.tickItem && Number(this.tickItem.TaoCanKey) > 0) {
        return this.tickItem.TaoCanKeyValue;
      } else {
        return '';
      }
    }
  },
  methods: {
    chgTickItem () {
      this.$emit('showTickList');
    }
  }
};
</script>