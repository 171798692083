<template>
    <!-- 修改文件密码 -->
    <div>
      <div class="form_info_edit form_info_list">
        <input-text :formTitle="'修改文件密码'"
                    :maxLength="14"
                    :required="true"
                    class="passwordTitle"
                    :readonly="isReadonly"
                    v-model="updatePassword"></input-text>
      </div>
      <!-- 按钮组 -->
      <save-cancel-btn-group v-if="!isReadonly"
                             :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </template>
  
  <script>
  import {
  } from '../../../../API/workbench.js';
  export default {
    data () {
      return {
            updatePassword:''
      };
    },
    props: {
      isReadonly: { // 是否自读
        type: Boolean,
        default: false
      }
    },
    created () {
      
    },
    computed: {
  
    },
    methods: {
      // 确认按钮
      confirmClick (callBack) {
        this.$emit('afterSuccess',this.updatePassword);
        this.$emit('cancelClick');
      },
      cancelClick () {
        this.$emit('cancelClick');
      }
    }
  };
  </script>
  
  <style scoped>
  .passwordTitle>>>.form_info_value {
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
  </style>
  