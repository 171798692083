<template>
  <!-- 添加跟单记录 -->
  <div class="">
    <!-- 跟进类型 -->
    <option-box-set :formTitle="''"
                    :optionList="typeList"
                    @doClick="chgType">
    </option-box-set>
    <!--2-日常跟进 显示该项 (意向级别)-->
    <div v-if="Number(dataInfo.TypeKey)==2">
      <option-box-set :formTitle="'意向级别'"
                      :required="true"
                      :optionList="intentionList"
                      @doClick="chgIntentionRankType">
      </option-box-set>
    </div>
    <!-- 预约到店 显示该项 (预约日期)-->
    <div v-if="Number(dataInfo.TypeKey)==3"
         class="date_icon">
      <input-date v-model="dataInfo.AppointmentTime"
                  :formTitle="'预约日期'"
                  :readonly="isReadonly"
                  :minDate="$utils.formatDatet(Date.new(), 'yyyy-MM-dd')"
                  :elementId="'AppointmentTime'"
                  :required="true"
                  :formPlaceholder="'可选'"
                  @changeDate="chgAppointmentTime"></input-date>
    </div>
    <!-- 跟进详情 OLAPKey-->
    <div class="form_text_box">
      <content-set :dataInfo="dataInfo"
                   :contentSet="brandContentSet"
                   @change="chgContent"></content-set>
    </div>
    <!-- 下次跟进 -->
    <div class="date_icon">
      <input-date v-model="dataInfo.NextFollowTime"
                  :formTitle="'下次跟进'"
                  :readonly="isReadonly"
                  :minDate="$utils.formatDatet(Date.new(), 'yyyy-MM-dd')"
                  :elementId="'NextFollowTime'"
                  :required="false"
                  :formPlaceholder="'可选'"
                  @changeDate="chgNextFollowTime"></input-date>
    </div>
  </div>
</template>

<script>
import contentSet from './form/content-set';
import radioButtonSet from './form/radio-button-set';
import optionBoxSet from './form/option-box-set';

const content = { name: '跟进详情', iconType: '', value: '', isReadonly: false };
const intentionList = [
  { id: 1, name: 'S 高意向', value: 'S', isOpt: true },
  { id: 2, name: 'A 中意向', value: 'A', isOpt: false },
  { id: 3, name: 'B 低意向', value: 'B', isOpt: false },
  { id: 4, name: 'C 无意向', value: 'C', isOpt: false }
];
const type = [
  { id: 2, name: '日常跟进', isOpt: true },
  { id: 3, name: '预约到店', isOpt: false },
  { id: 4, name: '到店考察', isOpt: false },
  { id: 9, name: '放弃', isOpt: false }
];

export default {
  components: {
    contentSet,
    radioButtonSet,
    optionBoxSet
  },
  props: {
    dataInfo: Object // 招商顾问信息
  },
  data () {
    return {
      isReadonly: false, // 是否只读
      brandContentSet: {},
      intentionList: [],
      typeList: []
    };
  },
  computed: {

  },
  watch: {
    // 变更线索
    'dataInfo.AttractInvestKey': {
      handler (n, o) {
        console.log(n, '变更线索');
        if (Number(n) != Number(o)) {
          this.initData();
        }
      },
      deep: true
    },
    // 变更类型 1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;8-放弃;
    'dataInfo.TypeKey': {
      handler (n, o) {
        console.log(n, '变更类型');
        if (Number(n) != Number(o)) {
          this.dataInfo.AppointmentTime = '';// 预约日期
          this.dataInfo.IntentionKey = '';// 意向级别
          this.dataInfo.IntentionKeyValue = '';// 意向级别
          this.dataInfo.Content = ''; // 内容;
        }
      },
      deep: true
    }
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      this.typeList = this.$utils.parseJson(type);
      this.intentionList = this.$utils.parseJson(intentionList);
      this.brandContentSet = this.$utils.parseJson(content);
      this.dataInfo.IntentionKey = 1;// 意向级别
      this.dataInfo.IntentionKeyValue = 'S';// 意向级别
    },
    // 跟进类型
    chgType (item) {
      this.dataInfo.TypeKey = item.id;
      this.dataInfo.TypeKeyValue = item.name;
    },
    // 预约日期
    chgAppointmentTime (date) {
      this.dataInfo.AppointmentTime = date;
    },
    // 意向级别
    chgIntentionRankType (item) {
      this.dataInfo.IntentionKey = item.id;
      this.dataInfo.IntentionKeyValue = item.value;
    },
    // 联系内容
    chgContent (value) {
      console.log(value, '内容');
      this.dataInfo.Content = value;
    },
    // 下次跟进日期
    chgNextFollowTime (date) {
      this.dataInfo.NextFollowTime = date;
    }
  }
};
</script>

<style>
</style>