<template>
  <!-- 有效期 -->
  <div class="form_info_edit content_marginRL ">
    <div class="taoCanSetting clearfix">
      <div class="label_radio_bg_white">
        <input-date v-model="formData.ExpireDateTime"
                    :formTitle="'有效期'"
                    :readonly="false"
                    :elementId="'startPeriod'"
                    :required="true"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :formPlaceholder="'必填'"
                     @changeDate="cheExpireDateTime"></input-date>

      </div>
      <div class="label_radio_bg_white">
        <input-number class="no_padding"
                      :min="0"
                      :formTitle="'有效天数'"
                      :placeholder="'必填'"
                      v-model="formData.PeriodCount"
                      :decimalPlaces="0"
                      :required="true"
                       @inputChange="chePeriodCount"></input-number>
      </div>
    </div>

    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="submitDate"
                             @cancelClick="closeFrom"></save-cancel-btn-group>

    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      formData: {
        PeriodCount: 1,
        StartTime: '',
        ExpireDateTime: ''
      },
      showFormDataType: 0
    };
  },

  props: {
    mendianInfo: Object // 套餐信息
  },
  computed: {

  },
  created () {
    console.log(this.mendianInfo, '默认有效期');
    var date = Date.new(this.mendianInfo.ExpireDateTime);
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);
    this.formData.ExpireDateTime = this.$utils.formatDateToLine(date);// 正式续期 建议默认往后 +1年

    this.formData.PeriodCount = 0;
    if (this.$utils.newDateDiff(this.$utils.formatDateToLine(Date.new()), this.formData.ExpireDateTime) > 0) {
      this.formData.PeriodCount = this.$utils.newDateDiff(this.$utils.formatDateToLine(Date.new()), this.formData.ExpireDateTime) + 1;
    }
  },
  watch: {

  },
  methods: {
    // 根据日期变更天数
    cheExpireDateTime (newValue) {
      console.log(newValue, '日期');
      this.formData.PeriodCount = this.$utils.newDateDiff(this.$utils.formatDateToLine(Date.new()), newValue) + 1;
      console.log(this.formData.PeriodCount, '天数');
    },
    // 根据天数变更日期
    chePeriodCount (newValue) {
      console.log(newValue, '日期');
      let newDay = Number(newValue) - 1;
      var date = Date.new();
      date.setDate(date.getDate() + newDay);
      this.formData.ExpireDateTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    },
    //  关闭
    closeFrom (ExpireDateTime) {
      this.$emit('closeFrom', ExpireDateTime);
    },
    // 提交
    submitDate (callBack) {
      if (callBack) {
        callBack();
      }
      if (!this.formData.ExpireDateTime) {
        layer.alert('有效期不能为空');
      } else if (!this.formData.PeriodCount || Number(this.formData.PeriodCount) == 0) {
        layer.alert('有效期数量必须大于0');
      } else {
        this.mendianInfo.ExpireDateTime = this.formData.ExpireDateTime;
        this.closeFrom(this.mendianInfo.ExpireDateTime);
      }
    }

  }
};
</script>


