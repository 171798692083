<template>
  <!-- <div class="apply_course_list is_dash" >
    <div class="list_title">
        <div class="list_title_dual">适用 | {{item.ApplyCorName}}</div>
        <div class="list_title_gray list_title_dual">
            {{vBuyDate}} <span class="font_black">{{transformDecimal(item.NumCount,1)}}</span> 课时，剩余 <span class="font_black">{{transformDecimal(item.PeriodCount,0)}}</span> 天
        </div>
        <div class="list_icon" @click.stop="clickMoreOption"></div>
    </div>
    <div class="list_progress_bar">
      <div class="list_progress_bar_left pa" v-show="item.isEdit">0</div>
      <div class="hidden_Index" v-if="!item.isEdit"></div>
       <el-slider v-model="item.activeCount"
                 :min="0"
                 :max="vMaxCount" 
                 :disabled="!item.isEdit"
                 :class="{'btn_gray':!item.isEdit}"
                 @input="chgSlider" 
                 :show-tooltip="false"
                 :step="1"></el-slider>

      <div class="list_progress_bar_number_box">
        <div class="list_progress_bar_blue" :style="{'width':vWidth+'%'}"></div>
      </div>

      <div class="list_progress_bar_number_box">
        <div class="list_progress_bar_number" :class="!item.isEdit?'font_black':''" :style="vleft">{{$utils.saveHowMuchDecimal(Number(item.activeCount),1)}}</div>
      </div>
      <div class="list_progress_bar_right pa" v-show="item.isEdit">{{vMaxCount}}</div>
      <div class="list_btn_center_down pa" @click.stop="reduceNum" :class="{'is_gray_down':!item.isEdit}"> </div>
      <div class="list_btn_center_up pa" @click.stop="addNum" :class="{'is_gray_add':!item.isEdit}"> </div>
    </div>
    <div class="list_btn clearfix">
        
        <div class="list_btn_right fr">
            <span class="font_gray">总计 : </span><span class="font_red">{{$utils.saveHowMuchDecimal(item.activeCount, 1)}}</span>
        </div>
    </div>
    <div class="list_box_dataList" v-if="isShowMore">
      <div class="list_box_dataList_title">
        <span>其它报课</span>
        <span class="fr">共 <span class="font_black">{{votherCount}}</span> 课时</span>
      </div>
      <div class="list_box_dataList_content">
        <tickListItem :dataList="dataList" :hideBorder="true"></tickListItem>
      </div>
    </div>
  </div> -->
  <div class="apply_course_details_list details_course_list">
    <div class="list_left">
      <div class="button"
           v-if="Number(item.TaoCanKey)>0"
           @click.stop="clickMoreOption">
        <div class="list_left_title clearfix">
          <div class="list_left_title_icon fl">{{Number(item.TaoCanKey)>0?'套餐':'非套餐'}}</div>
          <div class="fl">{{item.TaoCanKeyValue}}</div>
        </div>
        <div class="list_left_range">
          <span class="font_black">适用:</span>
          {{item.ApplyCorName}}
        </div>
        <div class="list_left_course">
          <span class="font_black">{{vGenerateTime}}</span> 购买 <span class="font_black">{{Number(item.NumCount)}}</span> 课时 | 消耗 <span class="font_black">{{Number(item.Sum_SpendCount)}}</span> 课时
        </div>
        <div class="list_left_course">
          可用 <span class="font_black">{{Number(item.CurrentCount)}}</span> 课时 | 当前报课 <span class="font_blue">{{Number(item.HasBingCount)>0?Number(item.HasBingCount):0}}</span> 课时
          <div class="box_red"
               v-if="Number(item.Sum_SpendCount)-Number(item.Sum_PlanCount)>0">超上 {{overUse}}</div>
        </div>

      </div>
      <div class="button"
           v-else
           @click.stop="clickMoreOption">
        <div class="list_left_title">
          {{item.ApplyCorName}}
        </div>
        <div class="list_left_course">
          <span class="font_black">{{vGenerateTime}}</span> 购买 <span class="font_black">{{Number(item.NumCount)}}</span> 课时
        </div>
        <div class="list_left_course">
          消耗 <span class="font_black">{{Number(item.Sum_SpendCount)}}</span> 课时 | 当前报课 <span class="font_blue">{{Number(item.HasBingCount)>0?Number(item.HasBingCount):0}}</span> 课时
          <div class="box_red"
               v-if="Number(item.Sum_SpendCount)-Number(item.Sum_PlanCount)>0">超上 {{Number(item.Sum_SpendCount)-Number(item.Sum_PlanCount)}}</div>
        </div>

      </div>
      <div class="list_bottom clearfix">
        <div class="list_bottom_text fl"
             v-if="isSuitable">{{item.WaitForPlanCount>=0?'待报课时':'超报课'}}</div>
        <div class="list_bottom_number fl"
             :class="Number(item.WaitForPlanCount)==0?'font_green':''"
             v-if="isSuitable"> {{Math.abs(Number(item.WaitForPlanCount))}}</div>
        <div class="list_bottom_btn fr"
             :class="(!isSuitable||Number(item.WaitForPlanCount)<=0)?'btn_gray':'btn_hover_blue'"
             v-if="isSuitable"
             @click.stop="applyCourse">报课</div>
        <div class="apply_course_list_right_btn btn_hover_white button fr"
             style="margin-right:6px"
             v-if="Number(item.HasBingCount)>0 &&isSuitable"
             @click.stop="refundCourse">退报</div>
      </div>
    </div>
    <div class="list_icon button"
         @click.stop="clickMoreOption"></div>
  </div>
</template>
<script>
import tickListItem from '../../base-module/apply-course/tick-list-item';
import { GetTheTicketPlanByApplyClassKey } from '../../../API/workbench';
export default {
  data () {
    return {
      isShowMore: false,
      dataList: []
    };
  },
  props: {
    item: Object,
    number: [Number, String],
    studentItem: [Object, String],
    tickItem: [Object, String],
    courseBillInfo: [Object, String]
  },
  components: {
    tickListItem
  },
  created () {
  },
  computed: {
    vNumber () {
      return (this.number >= 0 && this.number < 9) ? ('0' + (this.number + 1).toString()) : this.number + 1;
    },
    vBuyDate () {
      let date = Date.new(this.item.GenerateTime);
      return (date.getMonth() + 1) + '月' + date.getDate() + '日购买';// 10月25日购买
    },
    vCurDay () {
      return this.$utils.DateDiffDay(this.item.StartTime, this.item.ExpireTime);
    },
    vMaxCount () {
      return this.saveDecimal(Number(this.item.Sum_PlanCount - this.item.Sum_SpendCount) + Number(this.item.WaitForPlanCount));
    },
    vChangeNum () {
      if (this.item.changeNum > 0) {
        return '+' + this.item.changeNum;
      } else {
        return this.item.changeNum;
      }
    },
    vleft () {
      return { 'left': Number(this.item.activeCount) / this.vMaxCount * 100 + '%' };
    },
    votherCount () {
      console.log(this.courseBillInfo, 'votherCount');
      return Number(this.courseBillInfo.Sum_Apply) - Number(this.courseBillInfo.Apply);
    },
    vWidth () {
      return 100;
    },
    vGenerateTime () {
      if (this.item && this.item.GenerateTime) {
        let GenerateTime = this.item.GenerateTime.substring(0, 10);
        return GenerateTime;
        // return (Date.new(GenerateTime).getMonth() + 1) + '月' + Date.new(GenerateTime).getDate() + '日';
      }
    },
    isSuitable () {
      return this.item.isEdit;
    },
    overUse () {
      return Number((Number(this.item.Sum_SpendCount) - Number(this.item.Sum_PlanCount)).toFixed(1));
    }
  },
  methods: {
    addNum () {
      if (this.item.changeNum < this.item.WaitForPlanCount && this.item.isEdit) {
        ++this.item.changeNum;
        this.item.activeCount = this.saveDecimal(++this.item.activeCount);
      }
    },
    reduceNum () {
      if (this.item.activeCount > 0 && this.item.isEdit) {
        --this.item.changeNum;
        this.item.activeCount = this.saveDecimal(--this.item.activeCount);
      }
    },
    chgSlider (value) {
      let count = Number(this.item.Sum_PlanCount - this.item.Sum_SpendCount);
      this.item.changeNum = this.saveDecimal(this.item.activeCount - count);
    },
    // 保留小数位数
    saveDecimal (value) {
      var x = String(value).indexOf('.') + 1;   // 小数点的位置
      var y = String(value).length - (x + 1);  // 小数的位数
      if (x >= 0 && y > 1) {
        return Number(value.toFixed(1));
      } else {
        return value;
      }
    },
    clickMoreOption () {
      this.$emit('clickMoreOption', this.item);
    },
    transformDecimal (value, point) {
      return value == '' ? value : Number(value).toFixed(point);
    },
    showMore () {
      this.isShowMore = !this.isShowMore;
      if (this.isShowMore) {
        this.getTheTicketPlanByApplyClassKey();
      }
    },
    // 返回该学生指定课名的所有可用课单，及其下的报课计划
    getTheTicketPlanByApplyClassKey () {
      this.dataList = [];
      console.log(this.item, 'getTheTicketPlanByApplyClassKey');
      console.log(this.courseBillInfo);
      GetTheTicketPlanByApplyClassKey(this.studentItem.OLAPKey, this.courseBillInfo.ApplyClassTypeKey)
        .then(result => {
          result.data.dataList.forEach(obj => {
            // console.log(this.tickItem.TableID, 'obj', obj.TableID);
            if (this.item.TableID != obj.TableID && Number(obj.Sum_PlanCount) > 0) {
              this.dataList.push(obj);
            }
          });
          console.log(this.dataList, 'getTheTicketPlanByApplyClassKey');
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    refundCourse () {
      console.log(this.item, 'refundCourse');
      this.$emit('refundCourse', this.item);
    },
    applyCourse () {
      if (!this.isSuitable) {
        return false;
      } else if (this.item.WaitForPlanCount <= 0) {
        return false;
      }
      this.$emit('applyCourse', this.item);
    }
  }
};
</script>



