import { render, staticRenderFns } from "./class-li-item.vue?vue&type=template&id=97a95bfe&scoped=true"
import script from "./class-li-item.vue?vue&type=script&lang=js"
export * from "./class-li-item.vue?vue&type=script&lang=js"
import style0 from "./class-li-item.vue?vue&type=style&index=0&id=97a95bfe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a95bfe",
  null
  
)

export default component.exports