<template>
  <div class="courseware_list " :class="{opt:dataInfo.isSelect}" @click="clickItem">
    <div class="list_box">
      <div class="list_img">
        <div class="list_number">{{ dataInfo.ShowOrder }}</div>
        <img class="img_box" :src="vUrl" />
      </div>
      <div class="list_info">
        <div class="list_name" :title="(dataInfo.BigCoursewarePackageKeyValue?dataInfo.BigCoursewarePackageKeyValue+' - ':'')+dataInfo.AuthContent">{{ dataInfo.BigCoursewarePackageKeyValue?dataInfo.BigCoursewarePackageKeyValue+' - ':''}}{{ dataInfo.AuthContent }}</div>
        <div class="list_text">课件：{{ dataInfo.CoursewareNum }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object,
  },
  computed: {
    vUrl() {
      if (this.dataInfo.CoverUrl.indexOf("data:image/png;") != -1) {
        return this.dataInfo.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      }
    }
  },
  methods:{
      clickItem(){
          this.$emit('clickItem',this.dataInfo)
      }
  }
};
</script>