<template>
  <div class="from_list"
       :class="required?'from_list_required':''">
    <div class="from_list_title"
         v-if="titleName">{{titleName}}</div>
    <div class="from_list_content">
      <div class="from_list_input from_list_input_three pr">
        <div class="font_gray">满</div>
        <input type="number"
               :value="vInputOneValue"
               @input="inputOneEvent($event.target.value,$event)"
                @change="changeOneEvent($event.target.value,$event)"  
              >
        <div class="from_list_unit"
             v-if="unitName">{{unitName}}</div>
      </div>
      <div class="from_list_input from_list_input_three pr">
        <div class="font_gray">减</div>
        <input type="number"
               :value="vInputTwoValue"
               @input="inputTwoEvent($event.target.value,$event)"
                @change="changeTwoEEvent($event.target.value,$event)"  
              >
        <div class="from_list_unit"
             v-if="unitName">{{unitName}}</div>
      </div>
      <div class="font_gray"
           v-if="explainText">{{explainText}} </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      inputOneValue: '',
      inputTwoValue: ''
    };
  },
  props: {
    titleName: {//  标题 （选填）说明
      type: String,
      default: ''
    },
    oneValue: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    twoValue: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    decimalPlaces: {//  保留小数位 （选填）
      type: Number,
      default: 2
    },
    maxLength: {
      type: Number,
      default: 12
    },
    unitName: {//  单位 （选填）
      type: String,
      default: '元'
    },
    required: {//  是否必填 （选填）
      type: Boolean,
      default: false
    },
    explainText: {//  说明 （选填）
      type: String,
      default: ''
    }
  },
  created () {
    this.setInputValue(this.oneValue, this.twoValue);
  },
  watch: {
    oneValue (c, o) {
      this.setInputValue(c, '');
    },
    twoValue (c, o) {
      this.setInputValue('', c);
    }
  },
  computed:{
    vInputOneValue: {
      get () {
        if (this.inputOneValue == '0' || this.inputOneValue) {
          return this.$utils.mMoneyType(this.inputOneValue, this.vDecimalPlaces);
        } else {
          return '';
        }
      },
      set (n, o) {
        this.inputOneValue = n;
      }
    },
    vInputTwoValue: {
      get () {
        if (this.inputTwoValue == '0' || this.inputTwoValue) {
          return this.$utils.mMoneyType(this.inputTwoValue, this.vDecimalPlaces);
        } else {
          return '';
        }
      },
      set (n, o) {
        this.inputTwoValue = n;
      }
    },
     vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return 0;
      } else {
        return this.decimalPlaces || this.defaultDecimalPlaces;
      }
    }
  },
  methods: {
    setInputValue (oneValue, twoValue) {
      if (oneValue) {
        this.inputOneValue = this.$utils.mMoneyType(oneValue, this.decimalPlaces);
      }
      if (twoValue) {
        this.inputTwoValue = this.$utils.mMoneyType(twoValue, this.decimalPlaces);
      }
    },
    inputOneEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputOneValue;
        return false;
      }
       val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputOneValue = val;
      // e.target.value = this.inputOneValue;
      // this.$emit('inputChangeOneEvent', this.inputOneValue);
      if (!this.$utils.isIntermediateValue(this.inputOneValue)) {
        this.$emit('inputChangeOneEvent', Number(this.inputOneValue));
      }
    },
    changeOneEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputOneValue;
        return false;
      }
      this.inputOneValue = this.$utils.mMoneyType(val, this.decimalPlaces);
      e.target.value = this.inputOneValue;
      this.$emit('inputChangeOneEvent', Number(this.inputOneValue));
    },
    inputTwoEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputTwoValue;
        return false;
      }
          val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputTwoValue = val;
      if (!this.$utils.isIntermediateValue(this.inputTwoValue)) {
        this.$emit('inputChangeTwoEvent', Number(this.inputTwoValue));
      }
    },
    changeTwoEEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputTwoValue;
        return false;
      }
      this.inputTwoValue = this.$utils.mMoneyType(val, this.decimalPlaces);
      e.target.value = this.inputTwoValue;
      this.$emit('inputChangeTwoEvent', Number(this.inputTwoValue));
    }
  }

};
</script>

<style>
</style>