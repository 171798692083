<template>
  <div>
    <div class="html_view_select_the_student"
         @click.stop="showSeletedList">
      <div class="html_view_select_the_student_body">
        <div class="html_view_select_the_student_text">
          <span class="text_box5">选择学生</span>
        </div>
        <div class="html_view_select_the_student_image">
          <img src="../../../../../public/image/next_icon@2x.png"
               mode="">
        </div>
      </div>
      <span class="text_box9">
        {{vNameList}}
      </span>
    </div>
    <custom-dialog :title="'学生列表'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowStudentList"
                   :before-close="closeShowList">
      <homework-select-student-view :courseId="courseId"
                                    @closePopup="closeShowList"
                                    :studentList="vdataList"
                                    @completeStudent="completeTeacherList"></homework-select-student-view>
    </custom-dialog>
    <!-- <basics-custom-popup v-if="isShowStudentList" style="z-index: 5;" :formTitle="'选择学生'" @closeEvent="closeShowList">
			<homework-select-student-view  :courseId="courseId" @closePopup="closeShowList" :studentList="vdataList" @completeStudent="completeTeacherList"></homework-select-student-view>
		</basics-custom-popup> -->
  </div>
</template>

<script>
import homeworkSelectStudentView from './homework-select-student-view/index.vue';
export default {
  data () {
    return {
      isShowStudentList: false,
      dataListtoo: [],
      isAll: false // 是否显示全部
    };
  },
  components: {
    homeworkSelectStudentView
  },
  props: {
    value: {
      type: Object
    },
    courseId: [String, Number],
    dataList: Array,
    isTeacher: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    vdataList () {
      let newArr = [];
      this.dataList.forEach(o => {
        if (o.isActive) {
          newArr.push(o);
        }
      });
      return newArr;
    },
    vNameList () {
      let name = '';
      if (this.isAll) {
        return '全部学生';
      } else {
        this.dataList.forEach(o => {
          if (o.isActive) {
            name += o.StudentNameKeyValue + '、';
          }
        });
        if (name.length > 0) {
          name = '@ ' + name.substring(0, name.length - 1);
        }
      }
      return name;
    }
  },
  methods: {
    completeTeacherList (data, isAll) {
      this.isAll = isAll;
      this.$emit('completeTeacherList', data, isAll);
      this.closeShowList();
    },
    showSeletedList () {
      this.isShowStudentList = true;
    },
    closeShowList () {
      this.isShowStudentList = false;
    },
    seletedStudentItem () {

    },
    seletedTeacherItem () {

    }

  }
};
</script>

<style>
</style>
