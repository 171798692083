<template>
  <!-- 门店列表 -->
  <div class=" table_list_content summarizing_list"
       style="border-radius:8px;">
    <div class="single_result_detail">
      <!-- <div class="summarizing_number_left"
           v-if="listType=='mendainList'">
        <div class="summarizing_number_right_btn">
          <div class=" el-button btn_light_blue btn_hover_bg_blue el-button--default"
               @click="doApplyForListClick('临时')">
            临时开通门店
          </div>
          <div class=" el-button btn_light_blue btn_hover_bg_blue el-button--default"
               style="margin-left: 20px;"
               @click="doApplyForListClick('正式')">
            正式开通门店
          </div>
        </div>
      </div> -->
      <div class="flex">
        <div class="table_select_box" v-if="statusList.length>0&&listType=='mendainList'">
          <div class="table_select_title">开通状态</div>
          <el-select :value="vStatusValue"
                     filterable
                     placeholder="必选"
                     value-key="key"
                      @change="doSelectedStatus">
            <el-option v-for="item in statusList"
                       :key="item.key"
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>
             <div class="table_select_box" v-if="statusList.length>0&&listType=='recordList'">
          <div class="table_select_title">操作类型</div>
          <el-select :value="vRecordStatusValue"
                     filterable
                     placeholder="必选"
                     value-key="key"
                      @change="doRecordSelectedStatus">
            <el-option v-for="item in statusList"
                       :key="item.key"
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>
        <!-- 已选条件 -->
        <input-search-contain-btn v-model="searchText"
                                  style="margin: 10px;"
                                  :width="'280px'"
                                  :placeholder="'请输入门店名称'"
                                  @clearSearch="clearExtendSearchParams"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="pageTotal"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index';
import summaryDataItem from '../summary-data/item';
import inputSearchContainBtn from '../common/input-search-contain-btn';
export default {
  data () {
    return {
      isTemporaryStu: false,  // 是否开启临时学生
      searchText: ''
      // queryParams: {
      //   pageIndex: 0, // 页码，0为第一页
      //   pageSize: 15, // 每页记录数，0：表示查询所有
      //   searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
      //   orderBy: '', // 排序字段
      //   sequence: 'asc', // 排序字段
      //   status: 0,        //	0全部  学生状态 1-在校; 2-潜客;3-离校
      //   QueryID: ''
      // }
    };
  },
  props: {
    listType: String,
    statusList: Array,
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 全部学生数
    pageTotal: Number, // 分页总共条数
    tableColumns: Array, // table column
    studentInSchoolTotalNum: [String, Number],
    queryParams: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    summaryDataItem,
    tableList,
    inputSearchContainBtn
  },
  computed: {
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vStatusValue () {
      return this.queryParams.DredgeStatusKey === '' ? '全部' : this.queryParams.DredgeStatusKeyValue;
    },
    vRecordStatusValue () {
      return this.queryParams.OperationTypeKey === '' ? '全部' : this.queryParams.OperationTypeKeyValue;
    }
  },
  watch: {

  },
  created () {
    console.log(this.listType, 'listType');
  },
  mounted () {

  },
  methods: {
    // 开通
    doApplyForListClick (type) {
      this.$emit('doApplyForListClick', type);
    },
    // 门店搜索状态
    doSelectedStatus (obj) {
      console.log(obj, '选中状态');
      this.queryParams.DredgeStatusKeyValue = obj.name;
      this.queryParams.DredgeStatusKey = obj.key;
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = this.searchText;
      this.$emit('searchValue', this.queryParams.searchText);
    },
     // 门店管理记录搜索状态
    doRecordSelectedStatus (obj) {
      console.log(obj, '选中状态');
      this.queryParams.OperationTypeKeyValue = obj.name;
      this.queryParams.OperationTypeKey = obj.key;
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = this.searchText;
      this.$emit('searchValue', this.queryParams.searchText);
    },
    // 搜索
    search () {
      this.queryParams.searchText = this.searchText;
      this.$emit('searchValue', this.queryParams.searchText);
    },
    // 清除条件
    clearExtendSearchParams () {
      this.searchText = '';
      this.queryParams.searchText = '';
      if (this.queryParams.OperationTypeKeyValue) {
        this.queryParams.OperationTypeKeyValue = '全部';
        this.queryParams.OperationTypeKey = '';
      }
      if (this.queryParams.DredgeStatusKeyValue) {
        this.queryParams.DredgeStatusKeyValue = '全部';
        this.queryParams.DredgeStatusKey = '';
      }
      this.$emit('clearSearchParams', this.queryParams.searchText);
    },
    // 排序
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>
<style>
.search_top .el-select .el-input .el-select__caret {
  color: #333;
  font-size: 8px;
  position: relative;
  top: -1px;
  left: 13px;
  /* border-left: 1px solid #e6e6e6; */
}
</style>

