<template>
  <!-- 通用列表 -->
  <div class="table_list_content summarizing_list">
    <div class="single_result_detail"
         v-if="isLoading">
      <el-table element-loading-text="拼命加载中"
                class="table_list"
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                stripe
                :border="false"
                empty-text
                toggleRowSelection
                @sort-change="sortChange"
                :default-sort="defaultSort"
                @row-click="clickRow"
                :row-key="rowKey||'none'"
                :expand-row-keys="expandRowKeys"
                style="width: 100%;"
                :show-summary="isShowSummary"
                :summary-method="getSummaries">
        <!-- 表头 -->
        <el-table-column type="selection"
                         fixed
                         width="55"
                         v-if="vIsSearchText"></el-table-column>
        <el-table-column v-for="(item,index) in vTableColumns"
                         :fixed="item.fixed||false"
                         :width="item.width"
                         :sortable="item.sortable"
                         :align="item.align"
                         :key="index"
                         :label="item.label"
                         :prop="item.prop"
                         :class-name="item.className" 
                         :show-overflow-tooltip="true&&(!item.type&&item.type!='popUp')&&item.type!='student'&&item.type!='popDialog'"
                         :label-class-name="item.columnClass"
                         :sort-orders="['ascending', 'descending']">
          <template slot-scope="scope"
                    v-if="!item.childColumn">

            <div @click="tableaRowClick(scope.row,item.prop,item.decimalPlace)">

              <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
              <span v-else
                    :class="item.className">{{scope.row[item.prop]}}</span>
              <!-- 悬浮显示 -->
              <span class="new_bottom_warn"
                    style="border:none"
                    :class="item.className"
                    v-if="item.isBottomWarn&&scope.row[item.bottomWarnProp]">
                <el-popover v-if="scope.row[item.prop+'_Pop_Tips']"
                            trigger="hover"
                            placement="top">
                  <p v-html="scope.row[item.prop+'_Pop_Tips']"></p>
                  <div slot="reference">
                    <div size>{{scope.row[item.bottomWarnProp]}}</div>
                  </div>
                </el-popover>
                <span v-else>{{scope.row[item.bottomWarnProp]}}</span>
              </span>
            </div>
          </template>
        </el-table-column>
        <div slot="empty"
             style="height:260px;padding-top:60px">
          <i class="el-icon-warning-outline">
            <div class="monitoring_content_nodata"></div>
            亲，当前没有任何数据哦~
          </i>
        </div>
      </el-table>
      <!-- 
          选中行高亮  highlight-current-row 
          隔行换色  stripe
          数据为空展示的文字  empty-text
          选中行 复选框联动  toggleRowSelection
      -->
    </div>
    <div v-else
         class="loading_fixed_box">
      <div class="loading_fixed_gray">
        <span class="loading_style">加载中...</span>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 15,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      selectedRow: {}, // 选中行
      expandRowKeys: [], // 展开的行,
      isShowInnerScroll: true,
      isLoading: true,
      isShowSummary: false,
      studentDetailList: [], // 学生的报课详情
      totalCurrentCountDetailList: [], // 可用课时详情
      studentAllValidCourseBill: null, // 学生的所有有效课单
      curSelectedStudentInfo: {},
      countNum: 0,
      authbtn: {
        name: '',
        ModulePowerKey: 51
      }
    };
  },
  props: {
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    tableColumns: Array, // table column
    studentInSchoolTotalNum: [String, Number],
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    isShowSearch: Boolean,
    studentDetail: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
  },
  computed: {
    // 不足10行时空行补充
    vtableData () {
      let items = {
        StudentKey: '',
        ApplyClass: [],
        sourceData: [], // 已有用到的数组需实例化，不然渲染时会报错
        studentInfo: []
      };
      let arr = [];
      let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
      if (this.tableData.length < 15 && this.tableData.length > 0) {
        arr = this.tableData;
        for (let index = 1; index <= (15 - oldTableDataLength); index++) {
          arr.push(items);
        }
        return arr;
      } else {
        return this.tableData;
      }
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vIsSearchText () {
      if (this.isSearchText) {
        return true;
      } else {
        return false;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) { }
    },
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    }
  },
  watch: {
    tableColumns (n, o) {
      if (this.isNeedInnerScroll) {
        setTimeout(() => {
          this.caculInnerScroll();
        }, 800);
      }
    },
    'summary.summaryData': {
      handler (n, o) {
        if (this.summary.isShowSummary) {
          this.$nextTick(() => {
            this.isShowSummary = true;
          });
        }
      },
      deep: true
    }
  },
  created () {
    this.searchConditionType = this.$initJson.extendSearchConditionType;
    if (this.summary.isShowSummary) {
      this.isShowSummary = true;
    }
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
  },
  methods: {
    // 回车搜索
    keydownEvent (e) {
      switch (e.keyCode) {
        case 13: // 回车
          this.searchStudent();
          break;
        default:
          break;
      }
    },
    // 搜索
    searchStudent () {
      this.queryParams.searchText = this.searchText;
      this.$emit('searchStudent', this.queryParams.searchText);
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey, data, name) {
      // console.log(ModulePowerKey, data, name, '判断权限');
      ModulePowerKey = ModulePowerKey || '';
      if (data && name && name == '离校' && Number(data.CurrentClassHourCount) > 0) { // 离校
        return false;
      } else {
        return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
      }
    },
    // 课单课消
    studentCourseSingleClick (item, val) {
      let obj = {
        ModulePowerKey: 51,
        Stutas: '',
        eventName: 'KX',
        isShow: true,
        name: '课消',
        roleName: ''
      };
      this.$emit('tableBtnClick', item, obj.eventName);
    },
    // 课程报课
    applyCourseClick (item, val) {
      if (val && Number(val) == 0) {
        return false;
      }
      this.$emit('applyCourseClick', item);
    },
    // 课程排课
    arrangeClick (item, val) {
      if (val && Number(val) == 0) {
        return false;
      }
      this.$emit('arrangeClick', item);
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      console.log(val, '跳转到第几页');
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.expandRowKeys = [];
      this.$emit('loadTableData', this.queryParams);
      this.setScrollTop();
    },
    // 保留小数位数
    rounding (row, filedName, decimalPlace) {
      if (row[filedName] === '') {
        return row[filedName];
      } else if (row[filedName] == 0) {
        return '-';
      } else {
        if (!row[filedName]) {
          return '';
        } else {
          return this.$utils.mAmountType(row[filedName], decimalPlace || 0);
        }
      }
    },
    // 日期转换
    convertDateformat (row, value, type) {
      if (row[value] && type == 'YYMMDD') {
        return this.$utils.formatDateToLine(Date.new(row[value]));
      } else {
        return row[value];
      }
    },
    // 排序
    sortChange (column, prop) {
      if (
        column.prop == 'studentInfo' &&
        this.queryParams.orderBy == 'StudentKeyValue' &&
        column.order == 'ascending' &&
        this.queryParams.sequence == 'asc'
      ) {
        return;
      }
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence = column.order == 'ascending' ? 'asc' : 'desc';
        console.log(this.queryParams, 'this.queryParams');
      }
      this.$emit('loadTableData', this.queryParams);
      this.expandRowKeys = [];
    },
    editContentBtnClick (rowData) {
      let item = {};
      item.eventName = this.$initJson.baseFunctionBtnName.popDialog;
      this.tableBtnClick(rowData, item);
    },
    // 清除
    clearExtendSearchParams () {
      this.searchText = '';
      this.queryParams.searchText = this.searchText;
      this.expandRowKeys = [];
      this.queryParams.pageIndex = 0;
      this.$emit('clearSearchParams');
    },
    // 点击表单按钮
    tableBtnClick (rowData, item, val) {
      console.log('点击表单按钮', rowData, item, val);
      if (!rowData.StudentKey || (val && Number(val) == 0)) {
        return false;
      }
      this.curSelectedStudentInfo = rowData;
      if (this.vHasModulePower(item.ModulePowerKey)) {
        if (item.eventName == this.$initJson.baseFunctionBtnName.detail) {
          // 可用课时显示详情
          if (rowData[this.rowKey] == this.expandRowKeys[0]) {
            this.expandRowKeys = [];
          } else {
            this.expandRowKeys = [];
            this.expandRowKeys.push(rowData[this.rowKey]);
            this.$emit('tableBtnClick', rowData, item.eventName);
            if (rowData && Number(rowData.Number) % 10 == 0) {
              window.scrollBy(0, 1000);
            }
          }
        } else {
          this.$emit('tableBtnClick', rowData, item.eventName);
        }
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 点击整行元素
    tableaRowClick (rowData, item, val) {
      console.log('点击整行元素', rowData, item, val);
      this.$emit('tableaRowClick', rowData);
    },
    // 选择在校学生类型
    doTitleBoxClick (item) {
      this.expandRowKeys = [];
      this.$emit('doTitleBoxClick', item);
      this.setScrollTop();
    },
    setScrollTop () {
      if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
        document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
      }
    },
    // 选择搜索类型
    chgSearchValue (value) {
      // console.log(value, '选择搜索类型');
      this.searchText = '';
      this.$emit('searchStudent', this.searchText);
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    getSummaries (param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.summary.summaryName;
          return;
        }
        let findItem = this.summary.needSummariesColumns.find(o => {
          return o.prop == column.property;
        });
        if (findItem) {
          // 非数字类型 一般统计个数
          if (findItem.SumType == 'string') {
            sums[index] = this.summary.summaryData.length + findItem.unit;
          } else {
            const values = this.summary.summaryData.map(item =>
              Number(item[column.property])
            );
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return Number(this.$utils.mAmountType(prev + curr, 1));
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += findItem.unit;
            }
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0]
        ? document.getElementsByClassName('el-table table_list')[0].clientWidth
        : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0]
        ? document.getElementsByClassName('el-table__body')[0].clientWidth
        : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0]
        ? document.getElementsByClassName('el-table__fixed')[0].clientWidth
        : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0]
        ? document.getElementsByClassName('el-table__fixed-right')[0]
          .clientWidth
        : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName(
        'el-table__fixed-footer-wrapper'
      )[0]
        ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0]
          .offsetHeight
        : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName(
            'el-table__body-wrapper'
          )[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width =
            contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width =
            tableWidth - leftFix - rightFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft =
            leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop =
            -countColumnHeight + 'px';
        });
      }
    },
    scrollEvent (e) {
      let ETarget = e.target;
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0]
        .clientWidth;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0]
        .clientWidth;
      // 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0]
        .clientWidth;
      // 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0]
        .clientWidth;
      // 滚动条外框的宽度
      document.getElementsByClassName('float-scroll')[0].style.width =
        tableWidth - leftFix - rightFix + 'px';

      document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft =
        ETarget.scrollLeft /
        (contentWidth / (contentWidth - leftFix - rightFix)) -
        120;
    },
    popTitle (data) {
      if (data && data.length > 10) {
        return data;
      } else {
        return '';
      }
    },
    studentTitle (data) {
      // console.log(data, '学生');
      if (data && data.length > 10) {
        return data;
      } else {
        return '';
      }
    },
    popStudentTitle (data) {
      if (data && data.length >= 5) {
        return data;
      } else {
        return '';
      }
    }
  }
};
</script>
<style>
/* .new_popper_class.el-popper[x-placement^='top'] .popper__arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-top-color: #000;
  border-bottom-width: 0;
} */
</style>

