<template>
  <div class="form_item form-input form_info_line" :class="{ form_info_required: required }">
    <div class="form_info_field">{{formTitle}}</div>
    <div class="form_info_value"
         v-if="!readonly">
      <el-radio v-for="(item,index) in selectList"
                :key="index"
                v-model="radio"
                :label="item.id"
                @change="changeVal(item)">{{item.name}}</el-radio>
    </div>
    <div v-else
         class="input_readonly form_info_value">
      {{readonlyValue?readonlyValue:selectedInfo.name}}
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {
      radio: 0 // 选项Key
    };
  },
  props: {
    formTitle: {// 标题，（必填）
      type: String,
      default: ''
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填
    readonly: Boolean, // 是否只读，（可选）
    readonlyValue: {// 标题，（必填）
      type: String,
      default: ''
    },
    selectList: {// 选择项列表
      type: Array,
      default: function () {
        return [
          { name: '是', id: 1 },
          { name: '否', id: 0 }
        ];
      }
    },
    selectedInfo:{
      type:Object,
      default:()=>{
        return{
           name: '无',
           id: 0
        }
      }
    }
  },
  computed: {

  },
  created () {
    this.radio = Number(this.selectedInfo.id);
  },
  watch: {
    'selectedInfo.id':{
      handler(c,o){
         this.radio=c
      },
      deep:true,
      immediate:true
    }
  },
  methods: {
    changeVal (val) {
      this.$emit('change', val);
    }
  }
};
</script>
<style >
/* .form_info_value > span {
  display: inline !important;
} */
.form_info_list .form_info_value .el-radio .el-radio__original{
  height: auto !important;
}
.form_info_list .form_info_value{
  overflow:visible !important;
}
.form_item .form_info_value.input_readonly{
  overflow: hidden !important;
}
</style>
