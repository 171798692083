<template>
  <div class="pr package_complex_list label_radio_bg_white"
       @click="clickEvent">
    <div class="package_complex_title">
      <div class="package_complex_name">
        <span>{{packageInfo.PackageName}}</span>
        <div class="package_list_name_label"
             v-if="packageInfo.ApplyTypeKeyValue">{{packageInfo.ApplyTypeKeyValue}}</div>
      </div>
      <div class="package_list_amount">
        <div>
          <span v-if="Number(packageInfo.SalePrice)==0">赠送</span>
          <span v-else>￥{{$utils.mMoneyType(packageInfo.SalePrice,2)}}</span>
        </div>
        <div class="font_gray"
             v-if="Number(packageInfo.SalePrice)< packageInfo.ValueAmount">
          <span class="text_center_line">￥{{$utils.mMoneyType(packageInfo.ValueAmount,2)}}</span>
          <!-- <span>{{vDiscount}}</span> -->
        </div>
      </div>
    </div>
    <div class="package_complex_content">
      <class-hour-item v-for="item in packageInfo.KDNR"
                       :key="item.TableID"
                       :item="item"></class-hour-item>
      <zf-item v-for="item in packageInfo.ZFNR"
               :key="item.TableID"
               :item="item"></zf-item>
    </div>

    <!-- 下拉弹窗层 选择项目类型 课时 套餐 杂费 -->
    <!-- <div class="module_content_menuList" style="width:120px; top: 67px; left: 68%;"
        v-show="isShowAlerDialog">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="optionBtns"
                      @clickOptionForAlertDialog="chooseOptionBtn"></drop-down-box>
    </div> -->
    <!-- <span class="package_type">{{vDrainageKeyValue}}</span> -->
  </div>
</template>

<script>
import dropDownBox from '../../../../../common/drop-down-box';
import classHourItem from './class-hour-item.vue';
import zfItem from './zf-item.vue';

export default {
  data () {
    return {
      isShowAlerDialog: false,
      optionBtns: [// 操作按钮
        // {
        //   type: 'edit',
        //   name: '修改',
        //   isShow: true
        // },
        {
          type: 'delete',
          name: '删除',
          isShow: true
        }
      ]
    };
  },
  props: {
    packageInfo: Object,
    isReadonly: Boolean
  },
  components: { classHourItem, zfItem, dropDownBox },
  computed: {
    vDrainageKeyValue () {
      if (this.packageInfo.IsDrainageKey == '1') {
        return '引流';
      } else if (Number(this.packageInfo.AfterDisAmount) == 0) {
        return '赠送';
      } else {
        return '正课';
      }
    },
    vDiscount () {
      let str = '';
      if (Number(this.packageInfo.SalePrice) == 0) {
        str = '';
      } else if (Number(this.packageInfo.SalePrice) < Number(this.packageInfo.ValueAmount)) {
        str = this.$utils.mMoneyType(Number(this.packageInfo.SalePrice) / this.packageInfo.ValueAmount * 10, 1) + '折';
      } else {
        str = '';
      }
      return str;
    }
  },
  created () {
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlerDialog = false;
      }
    });
  },
  methods: {
    clickEvent (e) {
      if (!this.isReadonly) {
        setTimeout(() => {
          this.isShowAlerDialog = true;
        }, 50);
        let scrollTop = document.getElementsByClassName('package_revise_from')[0].scrollTop;
        let top = e.currentTarget.offsetTop + 40 - scrollTop;
        this.$emit('showAlertDialog', this.packageInfo, 'package', top);
      }
    },
    chooseOptionBtn (item) {
      if (item.type == 'edit') {
        this.$emit('onEditClick', this.item);
      } else {
        this.$emit('onDeleteClick', this.item);
      }
      this.isShowAlerDialog = false;
    }
  }
};
</script>

<style scoped>
.package_type {
  position: absolute;
  top: 3px;
  right: 17px;
  font-size: 12px;
  color: #999;
}
</style>