<template>
  <div class="gift_list">
    <div class="commodity_list">
      <div class="commodity_list_img">
        <img class="img_box" :src="CDNURL + dataInfo.PicSupport" />
      </div>
      <div class="commodity_list_info">
        <div class="commodity_list_name">{{ dataInfo.MainDemoName }}</div>
        <div class="commodity_list_text">
          <div class="commodity_list_credit">
            {{ Number(dataInfo.CreditCount) }}学分
          </div>
          <div class="commodity_list_amount text_center_line">
            {{ Number(dataInfo.PriceAmount) }}元
          </div>
        </div>
      </div>
    </div>
    <div class="list_btn_box flex">
      <div
        @click="istopGiftInfo"
        v-if="isShowTop"
        class="WKT_btn_istop WKT_action_btn"
      ></div>
      <div
        @click="upGiftInfo"
        v-if="isShowUp"
        class="WKT_btn_up WKT_action_btn"
      ></div>
      <div
        @click="downGiftInfo"
        v-if="isShowDown"
        class="WKT_btn_down WKT_action_btn"
      ></div>
      <div @click="editGiftInfo" class="WKT_btn_edit WKT_action_btn"></div>
      <div @click="removeGiftInfo" class="WKT_btn_remove WKT_action_btn"></div>
    </div>
  </div>
</template>
<script>
import {
  MoveExchangeGoods,
  DeleteExchangeGoods,
} from "../../../../API/workbench";
export default {
  data() {
    return {};
  },
  props: {
    dataInfo: Object,
    isShowTop: {
      type: Boolean,
      default: true,
    },
    isShowUp: {
      type: Boolean,
      default: true,
    },
    isShowDown: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    CDNURL() {
      return this.$store.getters.CDNURL;
    },
  },
  methods: {
    istopGiftInfo() {
      MoveExchangeGoods(this.dataInfo.OLAPKey, 1).then(
        (res) => {
          this.$emit("refreshDataList");
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    upGiftInfo() {
      this.$emit("upGiftInfo", this.dataInfo);
    },
    downGiftInfo() {
      this.$emit("downGiftInfo", this.dataInfo);
    },
    editGiftInfo() {
      this.$emit("editGiftInfo", this.dataInfo);
    },
    removeGiftInfo() {
      layer.confirm("是否删除物品", {
        btn: [
          {
            name: "取消",
            callBack: () => {
              layer.close();
            },
          },
          {
            name: "确认",
            callBack: () => {
              layer.close();
              DeleteExchangeGoods(this.dataInfo.OLAPKey).then(
                (res) => {
                  layer.alert("删除成功");
                  this.$emit("refreshDataList");
                },
                (err) => {
                  layer.alert(err.msg);
                }
              );
            },
          },
        ],
      });
    },
  },
};
</script>