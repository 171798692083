<template>
  <div class="register_student form_info_edit">
    <div class="bg_gray minHeight">
      <!-- 学生信息 -->
      <student-info :customerInfo="customerInfo"
                    :isReadonly="isReadonly"></student-info>
      <!-- 销售价值信息 -->
      <sales-value-info v-if="vZX17Power || type==1"
                        :customerInfo="customerInfo"
                        :isReadonly="isReadonly"></sales-value-info>
      <!-- 其他信息 -->
      <other-info :customerInfo="customerInfo"
                  :isReadonly="isReadonly"></other-info>
      <!-- 提交 -->
      <div>
        <button-single-comfire-btn v-if="!isReadonly"
                                   :btnText="'确定'"
                                   :btnClass="'popup_confirm_btn'"
                                   @click="confirmClick"></button-single-comfire-btn>
      </div>
    </div>
    <!-- 该用户下已有的学生列表弹出层 -->
    <custom-dialog :width="'300px'"
                   :top="300"
                   :visible.sync="isShowStudentPopups"
                   :before-close="doCloseClick">
      <user-below-student-dialog ref="studentPopups"
                                 :studentList="studentList"
                                 :popupContent="popupContent"
                                 :isSingleSubmit="true"
                                 :singleSubmitType="popupContent.mark == 'abnormal'?'dynamic':'static'"
                                 @doAddStudentClick="doPopupAddStudent"
                                 @doAmendStudentClick="doPopupAmendStudent"
                                 @doCloseClick="doCloseClick"></user-below-student-dialog>
    </custom-dialog>
  </div>
</template>
<script>
import studentInfo from './student-info';
import salesValueInfo from './sales-value-info';
import otherInfo from './other-info';
import userBelowStudentDialog from '../../common/popups-box/user-below-student-dialog/index';
import {
  registerCustomer,
  editCustomerInfo,
  GetStudentByMobile
} from '../../../API/workbench';
// 登记客户
const newCustomerInfo = {
  OLAPKey: '', // 整型  学生ID
  MainDemoName: '', // 学生名字
  SexKey: '3', // 整型 性别 2:男，3:女
  SexKeyValue: '女', // 学生性别
  NickName: '', // 昵称
  AgeName: '', // 整型 学生年龄
  BirthdayTime: '',
  CustomerRelationshipKey: 0, // 整形   ,关系 : 0--家长;2--爸爸;3-妈妈;11-本人	;10-其他;
  CustomerRelationshipKeyValue: '家长', // 客户关系
  FirstLetterName: '', //	姓名首字母
  HeadImgSupport: '', // 头像
  StudentTypeKey: '', // 整型  是否正式  1:正式, 0：临时
  StudentTypeKeyValue: '', // 是否正式 可视化
  CustStatusKey: '', // 整型  是否在校 1:是, 0：否
  IsActiveKeyValue: '', //	是否在校 可视化
  CurrentMaxDayCount: '', // 整型  最大剩余天数
  CurrentClassHourCount: '', // 整形  剩余课时
  RegisterTime: '', // 登记日期
  SchoolName: '', // 学校名字
  FineIdSupport: '', // 整形  兴趣课程ID
  FineSupport: '', // 兴趣课程
  WillingnessRateKey: 3, // 意向级别
  WillingnessRateKeyValue: 'B级', //	意向级别可视化
  AdviserKey: '', // 整型 销售顾问
  AdviserKeyValue: '', // 销售顾问
  ParentName: '', // 紧急联系人
  ParentPhoneName: '', // 紧急联系人电话
  RelationshipKey: 0, // 整型  紧急联系人关系.
  RelationshipKeyValue: '', // 紧急联系人关系.
  DebtAmount: '', // 客户欠款
  SumAmount: '', // 客户余额
  MobileSupport: '', // 整型  客户手机
  CustKey: '', // 客户ID
  CustKeyValue: '', // 客户名
  VillageKey: '', // 住宅ID.
  VillageKeyValue: '', // 住宅小区
  CompanyName: '', // 工作单位
  TheRestChild: [], // 其余的孩子.
  IsCompletionKey: 0, // 是否完善资料. 0否1是.
  IsCompletionKeyValue: '否',
  SourceTypeKey: '', // 来源方式
  SourceTypeKeyValue: '', // 来源方式
  RemarksSupport: '' // 备注
};
export default {
  components: {
    studentInfo,
    salesValueInfo,
    otherInfo,
    userBelowStudentDialog
  },
  data () {
    return {
      isShowStudentPopups: false, // 该手机号下已有的学生弹窗
      isReadonly: false, // 开放变量 默认false-修改模式;true-只读模式
      customerInfo: {}, // 登记信息
      studentList: [], // 该手机号下已有的学生列表
      // 学生弹出层显示内容
      popupContent: {
        titleContent: '',
        textContent: '',
        studentButName: '', // 显示学生下的合并按钮名称
        submitButName: '', // 提交按钮名称
        mark: 'normal' // 标示==》 normal：正常, abnormal:异常
      },
      isSubmit: true,
      type: 0 // 默认-登记新生，1-登记线索

    };
  },
  props: {
    isPopupShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 追销开关显示
    vZX17Power () {
      if (this.$store.getters.SaaSClientInfo.SaaSClientSetting && this.$store.getters.SaaSClientInfo.SaaSClientSetting.length > 0) {
        let locaIndex = this.$store.getters.SaaSClientInfo.SaaSClientSetting.findIndex(o => {
          return o.SetTypeKey == 17 && o.SetKey == 1;
        });
        if (locaIndex >= 0) {
          this.customerInfo.WillingnessRateKey = 3;
          this.customerInfo.WillingnessRateKeyValue = 'B 一般';
          return false;
        } else {
          return true;
        }
      }
    }
  },
  created () {
    this.customerInfo = this.$utils.parseJson(newCustomerInfo);
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  watch: {
    // 客户名称显示逻辑.
    'customerInfo.CustomerRelationshipKeyValue': {
      handler (newName, oldName) {
        this.customerInfo.CustKeyValue = (this.customerInfo.MainDemoName || '') + (this.customerInfo.CustomerRelationshipKeyValue || '');
      },
      deep: true
    },
    // 客户名称显示逻辑.
    'customerInfo.MainDemoName': {
      handler (newName, oldName) {
        this.customerInfo.CustKeyValue = (this.customerInfo.MainDemoName || '') + (this.customerInfo.CustomerRelationshipKeyValue || '');
        console.log(newName, '电话号码');
      },
      deep: true
    },
    // 出生日期
    'customerInfo.BirthdayTime': {
      handler (newName, oldName) {
        if (!this.customerInfo.BirthdayTime && !this.customerInfo.AgeName) {
          this.customerInfo.AgeName = '';
        } else if (this.customerInfo.BirthdayTime) {
          var dd = Date.new().getTime() - Date.new(this.customerInfo.BirthdayTime).getTime();
          this.customerInfo.AgeName = Math.floor(dd / 1000 / 24 / 3600 / 365);
        }
      },
      immediate: true
    },
    // 电话号码
    'customerInfo.MobileSupport': {
      handler (newName, oldName) {
        console.log(newName, '电话号码');
        if (newName && newName.length === 11 && newName !== oldName) {
          this.doAfterChangeMobileSupport();
        }
      },
      immediate: true
    }
  },
  methods: {
    // 改变手机后 查询该手机号下的所有学生
    doAfterChangeMobileSupport () {
      if (this.customerInfo.MobileSupport) {
        GetStudentByMobile(this.customerInfo.MobileSupport).then(result => {
          console.log(result.data, 'result.data');
          // 手机号下的所有学生
          this.studentList = result.data.filter(o => {
            this.$set(o, 'isActive', false);
            return o.OLAPKey > 0;
          });
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 确定执行
    confirmClick (callBack) {
      const res = this.validateCustomerInfo(callBack);
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.isSubmit) { // 防止接口请求太慢导致重复提交
        this.confirmData(callBack);
      }
    },
    // 验证客户信息
    validateCustomerInfo (callBack) {
      if (this.customerInfo.MainDemoName) {
        this.customerInfo.MainDemoName = this.customerInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      let flag = true;
      if (!this.customerInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入学生名字');
        flag = false;
      } else if (this.customerInfo.MainDemoName.length>50) {
        layer.alert('学生名字长度不能超过50个字符');
        flag = false;
      } else if (!this.customerInfo.CustomerRelationshipKeyValue) {
        layer.alert('请选择客户关系');
        flag = false;
      } else if (this.customerInfo.MobileSupport.length != 11 && this.customerInfo.MobileSupport) {
        layer.alert('请输入11位的手机号码');
        flag = false;
      } else if (!this.customerInfo.CustKeyValue.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('客户名称不能为空');
        flag = false;
      } else if (!this.customerInfo.MobileSupport && !this.customerInfo.OLAPKey) {
        flag = false;
        layer.confirm('未填写手机号，是否继续提交?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                this.isSubmit = false;
                this.confirmData(callBack);
                layer.close();
              }
            }
          ]
        });
      }
      return flag;
    },
    // 提交
    confirmData (callBack) {
      this.customerInfo.SexKeyValue = this.customerInfo.SexKey == 2 ? '男' : '女';
      this.customerInfo.AgeName = this.customerInfo.AgeName == '' || this.customerInfo.AgeName == '无年龄' ? 0 : this.customerInfo.AgeName;
      this.customerInfo.StuSourceKey = 2;
      this.customerInfo.StuSourceKeyValue = '员工登记';
      // 用户下已有学生时
      if (this.customerInfo.MobileSupport && this.studentList.length > 0) {
        this.popupContent.mark = 'abnormal'; // normal：正常, abnormal：异常
        this.popupContent.studentButName = '合并'; // 显示学生下的合并按钮名称
        this.popupContent.submitButName = '增加为新生'; // 提交按钮名称
        this.popupContent.titleContent = '';
        this.popupContent.textContent = '手机号' + ' (' + this.customerInfo.MobileSupport + ') ' + '已有其他学生,请确认:';
        this.isShowStudentPopups = true;
        if (callBack) {
          callBack();
        }
      } else {
        this.addConfirm(this.customerInfo, callBack);
      }
    },
    // 已有学生时 确认操作
    doPopupAddStudent (callBack) {
      if (this.popupContent.mark == 'normal') {
        this.isShowStudentPopups = false;
        this.cancelClick();
        if (callBack) {
          callBack();
        }
      } else if (this.popupContent.mark == 'commonHint') {
        this.addConfirm(this.customerInfo, callBack);
        this.isShowStudentPopups = false;
      } else {
        this.addConfirm(this.customerInfo, callBack);
        this.isShowStudentPopups = false;
      }
    },
    // 已有学生时 合并学生
    doPopupAmendStudent (item, callBack) {
      console.log(item, '合并');
      if (item.OLAPKey) {
        this.customerInfo.OLAPKey = item.OLAPKey;
        this.customerInfo.CustKey = item.CustKey;
        this.editConfirm(this.customerInfo, callBack, '合并');
      }
    },
    // 取消
    doCloseClick () {
      if (this.popupContent.mark == 'normal' && this.popupContent.studentButName == '') {
        this.$bus.emit('AfterStudentUpdate', this.customerInfo.OLAPKey);
        this.cancelClick();
      }
      this.isShowStudentPopups = false;
    },
    // 新增操作
    addConfirm (customerInfo, callBack) {
      registerCustomer(customerInfo).then(result => {
        layer.alert('登记成功');
        this.$emit('doafterRegisterCustomer', result.data);
        this.$bus.emit('AfterStudentAdd', 'new', result.data.OLAPKey);
        this.cancelClick(result.data);
      }, err => {
        layer.alert(err.msg);
      }).finally(() => {
        this.isSubmit = true;
        if (callBack) {
          callBack();
        }
      });
    },
    // 修改操作
    editConfirm (customerInfo, callBack) {
      editCustomerInfo(this.customerInfo.OLAPKey, customerInfo).then(result => {
        this.isShowStudentPopups = false;
        layer.alert('合并成功');
        this.$emit('doafterRegisterCustomer', result.data);
        this.$bus.emit('AfterStudentUpdate', result.data.OLAPKey);
        this.cancelClick(result.data);
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        this.isSubmit = true;
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消
    cancelClick (data) {
      if (this.isPopupShow) {
        this.$emit('addSuccess', data);
      } else {
        this.$emit('closeRegisterCustomer');
      }
    }
  }
};
</script>
