<template>
  <!-- 优秀教师列表-->
  <div class="WKT_information_teacher_list">
    <div class="WKT_information_list">
      <div class="WKT_information_block"
           style="cursor: pointer;"
           @click="doAddRecommendClick">
        <div class="WKT_information_img">
          <img :src="vImg"
               :onerror="vDefaultImg"
               alt="">
        </div>
        <div class="WKT_information_info">
          <div class="WKT_information_name">
            {{dataItem.TeacherName}}
          </div>
          <div class="WKT_information_post"
               v-if="dataItem.courses">
            主讲:{{dataItem.courses}}
          </div>
          <div class="WKT_information_text"
               v-html="dataItem.content"></div>
        </div>
      </div>
      <div class="WKT_information_openBtn btn_hover_bg_blue"
           @click="doAddRecommendClick">
        推荐
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {
      searchText: ''
    };
  },
  props: {
    dataItem: Object
  },
  created () {

  },
  mounted () {
  },
  // 一进入页面就触发；可用于初始化页面数据等
  activated () {
  },
  watch: {

  },
  computed: {
    vImg () {
      if (!this.dataItem.picurl) {
        this.dataItem.picurl = '';
      }
      return this.$utils.ossLitimgSet(this.dataItem.picurl);
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    clearSearText () {

    },
    //  添加推荐项
    doAddRecommendClick () {
      this.$emit('doAddRecommendClick', this.dataItem);
    }
  }
};
</script>

