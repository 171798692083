<template>
  <div class="growth_time_list_imgBox dynamic_content_body">
    <div class="body_img_box" :class="{one_img: dataInfo.attachList.length == 1,dual_img:dataInfo.attachList.length == 2}">
      <report-img-item v-for="(item,key) in dataInfo.attachList"
                       :key="key"
                       :item="item"
                       :imgList="dataInfo.attachList"></report-img-item>
      <!---->
    </div>
  </div>
</template>
<script>
import reportImgItem from './report-img-item';
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    reportImgItem
  },
  computed: {

  },
  methods: {

  }

};
</script>

