<!-- 文本 -->
<template>
  <el-table-column :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :min-width="columnData.minWidth"
                   :align="columnData.align||'left'">
    <template slot-scope="scope">
      <div class="follow_time" v-if="scope.row[columnData.prop]">
        <div class="follow_time_tips">{{$utils.leadTime(scope.row[columnData.prop].substring(0, 10))}}</div>
        <div class="follow_time_date">{{($utils.formatDate(scope.row[columnData.prop])).substring(5,10)}}</div>
      </div>
    </template>
  </el-table-column> 
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String
        };
      }
    }
  },
  data(){
    return{
    }
  },
  created () {
      //  obj.NextFollowTime = (obj.NextFollowTime ? this.$utils.leadTime(obj.NextFollowTime.substring(0, 10)) + ' ' + `<div>` + obj.NextFollowTime.substring(5, 10) + `</div>` : '');
       
  },
  methods: {
  }
};
</script>

<style scoped>
  .follow_time{
    font-size: 14px;
    line-height: 13px;
  }
  .follow_time_tips{
    color: #999999;
  }
  .follow_time_date{
    margin-top: 2px;
  }
</style>