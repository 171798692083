<template>
  <div>
    <input @change="selectImg($event)"
           class="uploading_input"
           type="file"
           multiple
           :style="inputStyle"
           accept='image/*'>
  </div>
</template>

<script>
import { UploadFilesShowProgress } from '../../API/workbench.js';
import { base64ToBlob } from '../../utils/upLoadTextImg.js';
import { OSSUploadFilesPublic } from '../../utils/ossupLoadTextImg.js';

export default {
  data () {
    return {
      newWidth: 800, // 新图片宽度
      newHeight: 600, // 新图片高度
      isUploading: false, // 是否正在上传图片
      fileList: [], // 选中的文件列表
      localImgData: [] // 本地图片列表
    };
  },
  props: {
    maxNum: {
      type: Number,
      default: function () {
        return 9;
      }
    },
    imgMaxWidth:{
      type: Number,
      default: function () {
        return 1000;
      }
    },
    inputStyle:{
      type:String,
      default:''
    }
  },
  methods: {
    selectImg (e) {
      let files = null;
      if (e.target.files.length > 0) {
        files = e.target.files;
        if (files.length > this.maxNum) {
          layer.alert('图片一次上传不可超过' + this.maxNum + '张 ');
          return;
        }
        for (let i in files) {
          if (i < files.length) {
            if (!/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/.test(files[i].name.toUpperCase())) {
              layer.alert("上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件");
              return false;
            }
          }
        }
      } else {
        return;
      }
      for (let i in files) {
        if (i < files.length) {
          this.fileList.push(files[i]);
        }
      }
      console.log('fileList', this.fileList);
      let index = 0;

        let readerFile = () => {
          let obj = this.fileList[index];
          var reader = new FileReader();	// 实例化一个FileReader对象，用于读取文件
          // 读取File对象的数据
          reader.onload = (evt) => {
            let item = { AttachTypeKey: 1, AttachUrl: evt.target.result, id: index, percent: 0, size: obj.size / 1024 / 1024 };
            this.localImgData.push(item);
            this.$emit('onUpLoadImgSuccess', item);
            ++index;
            if (index < this.fileList.length) {
              readerFile();
            } else {
                if(this.imgMaxWidth==0){

                }else{
                  this.fileList = [];
                }
                this.compressImg(0);
            }
          };
          reader.readAsDataURL(obj);
        };
        readerFile();
      
      
    },
    compressImg (index) {
      // if (this.localImgData[index].size > 2) {
    
      if(this.imgMaxWidth==0){
        this.directUploadFiles(this.fileList[index],index)
      }else{
        let img = new Image();
        img.src = this.localImgData[index].AttachUrl;// base64
        let that = this;
        img.onload = () => {
          let maxWidth = this.imgMaxWidth;
          let overWHRatio = 0;
          let oldWidth = img.width;
          let oldHeight = img.height;
          if (oldWidth > maxWidth || oldHeight > maxWidth) {
            if (oldWidth > oldHeight) {
              overWHRatio = maxWidth / oldWidth;
            } else {
              overWHRatio = maxWidth / oldHeight;
            }
            that.newWidth = oldWidth * overWHRatio;      // 图片超过比例的话就计算
            that.newHeight = oldHeight * overWHRatio;
          } else {
            that.newWidth = oldWidth;      // 新图片的宽高
            that.newHeight = oldHeight;
          }
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = that.newWidth;
          canvas.height = that.newHeight;
          // 清除画布
          ctx.clearRect(0, 0, that.newWidth, that.newHeight);
          //  将图片画到canvas上面   使用Canvas压缩
          ctx.drawImage(img, 0, 0, that.newWidth, that.newHeight);
          // canvas.toDataURL 返回的是一串Base64编码的URL
          const base64Data = canvas.toDataURL('image/png');
          let blobData = base64ToBlob(base64Data, 'image/png');
          that.uploadFiles(blobData, index);
        };
      
      // } else {
      //   let blobData = base64ToBlob(this.localImgData[index].AttachUrl, 'image/png');
      //   this.uploadFiles(blobData, index);
      // }
      }
    },
    // 直接上传文件
    directUploadFiles(fileItem,index){
      console.log('blobDatablobData', fileItem, index);
      var fd = new FormData();
      fd.append('file', fileItem);
      UploadFilesShowProgress(1, '', fd, this.localImgData[index]).then(result => {
        this.localImgData[index].AttachUrl = result.data.url;
        this.$emit('upLoadImgReject', result.data.url);
        ++index;
        if (this.localImgData.length > index) {
          this.compressImg(index);
        } else {
          this.localImgData = [];
          this.fileList = [];
          this.setInputValue();
          // this.isUploading = false;
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    uploadFiles (blobData, index) {
      console.log('blobDatablobData', blobData, index);
      var fd = new FormData();
      fd.append('file', blobData, 'image.png');
        // console.log('OSSUploadFilesPublic', OSSUploadFilesPublic);
      // OSSUploadFilesPublic(1, '', fd,this.localImgData[index]).then(result=>{
      //   console.log("initSecurityToken11",result)
      //   this.localImgData[index].AttachUrl = result.data.url;
      //   this.$emit('upLoadImgReject', result.data.url);
      //   ++index;
      //   if (this.localImgData.length > index) {
      //     this.compressImg(index);
      //   } else {
      //     this.localImgData = [];
      //     this.setInputValue();
      //     // this.isUploading = false;
      //   }
      // }).catch(error=>{
      //   console.log('errorinitSecurityToken11',error)
      // })
      UploadFilesShowProgress(1, '', fd, this.localImgData[index]).then(result => {
        this.localImgData[index].AttachUrl = result.data.url;
        this.$emit('upLoadImgReject', result.data.url);
        ++index;
        if (this.localImgData.length > index) {
          this.compressImg(index);
        } else {
          this.localImgData = [];
          this.setInputValue();
          // this.isUploading = false;
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      var loadInput = document.getElementsByClassName('uploading_input')[0];
      loadInput.value = '';
    }
  }
};
</script>

<style>
</style>