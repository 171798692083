<template>
  <!-- 基础设置项 -->
  <div>
    <div class="form_info_edit content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="mendian_setting_content single_shadow" :class="{btn_marginBottom_30:isReadonly}"> 
        <div>
          <input-text :formTitle="'收款名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'收款名称'"
                      :required="true"
                      v-model="dataJson.MainDemoName"></input-text>
          <form-select
            :formTitle="'收款类型'"
            :required="true"
            :readonly="isReadonly"
            :dataItem="this.dataJson.AccountTypeKeyValue"
            :dataList="ZHType"
            v-model="this.dataJson.AccountTypeKeyValue"
            @choseItem="getValueEvent"
          ></form-select>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"  class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else class="on_form_footer_btn_group"></div>
  </div>

</template> 

<script>
import formSelect from '../../../form-item/form-select';
import { hqAddIncomePayAccount, hqEditIncomePayAccount } from '../../../../API/workbench';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    editItem: {// 修改项
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      newInputText: '', // 默认值
      ZHType: [
        { key: 1, value: '储蓄卡' },
        { key: 2, value: '信用卡' },
        { key: 3, value: '支付宝' },
        { key: 4, value: '微信' },
        { key: 5, value: '现金' },
        { key: 6, value: '其它' }
      ],
      // 数据对象
      dataJson: {
        MainDemoName: '', // -- 收款账户名称
        AccountTypeKey: '', // --账户类型key
        AccountTypeKeyValue: '', // -- 账户类型value
        CommentSupport: '' // --备注
      }
    };
  },

  components: {
    formSelect
  },
  computed: {
    // 显示选择收支类型的值
    vZHType: {
      get () {
        return {
          key: this.dataJson.AccountTypeKey,
          value: this.dataJson.AccountTypeKeyValue
        };
      },
      set (newValue) {
        this.dataJson.AccountTypeKey = newValue.key;
        this.dataJson.AccountTypeKeyValue = newValue.value;
      }
    }
  },
  created () {
    if (JSON.stringify(this.editItem) != '{}') {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.MainDemoName = this.editItem.MainDemoName;
      this.dataJson.AccountTypeKey = this.editItem.AccountTypeKey;
      this.dataJson.AccountTypeKeyValue = this.editItem.AccountTypeKeyValue;
      this.dataJson.CommentSupport = this.editItem.CommentSupport;
    }
  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (!this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入收款名称');
        flag = true;
      } else if (!this.dataJson.AccountTypeKey) {
        layer.alert('请选择收款类型');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (JSON.stringify(this.editItem) != '{}') {
        hqEditIncomePayAccount(this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          layer.alert('修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        hqAddIncomePayAccount(this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 选择收支类型
    getValueEvent (item) {
      this.dataJson.AccountTypeKey = item.key;
      this.dataJson.AccountTypeKeyValue = item.value;
      console.log(item);
    }
  }
};
</script>

  <style>
</style>