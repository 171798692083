<template>
  <div class="add_screening_condition_box fl"
       v-if="show">
    <span>{{dataModel.formTitle}} : </span>
    <el-select v-model="dataModel.fieldList[0].value"
               placeholder="请选择">
      <el-option v-for="item in dataModel.fieldList[0].sourceData"
                 :key="item.key"
                 :label="item.value"
                 :value="item.key">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {

  name: 'selectOption',
  data () {
    return {
      show: true
    };
  },
  model: {
    prop: 'dataModel'
  },
  props: {
    dataModel: Object
  },
  created () {
    if (this.dataModel.formTitle == '操作' || this.dataModel.formTitle == '缴费类型') {
      this.show = false;
    }
    // 如果数据源没有不限选项 则添加一项不限
    if (!this.dataModel.fieldList[0].sourceData.find(o => { return o.key == '' && o.value == '不限'; })) {
      this.dataModel.fieldList[0].sourceData.unshift({ key: '', value: '不限' });
    }
  },
  methods: {
  },
  computed: {
  }
};
</script>
