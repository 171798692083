<template>
  <div class="register_student form_info_edit">
    <div class="bg_gray minHeight">
      <!-- 学生信息 -->
      <div style="background: #fff;"
           v-if="isPopupShow">
        <div style="padding: 20px 0;border-bottom: 1px solid #ccc;">
          <span style="font-size: 20px;">{{ customerInfo.MainDemoName }}</span> <span>{{ customerInfo.MobileSupport }}</span>
        </div>
        <div style="padding: 15px 10px;margin-top: 10px;background: #f59a23;">
          请确认学生信息，将按下信息建立学生档案
        </div>
      </div>

      <student-info :customerInfo="customerInfo"
                    :isPopupShow="isPopupShow"
                    :isReadonly="isReadonly"></student-info>
      <!-- 销售价值信息 -->
      <sales-value-info v-if="isPopupShow"
                        :customerInfo="customerInfo"
                        :isReadonly="isReadonly"></sales-value-info>
      <div class="form_info_box bg_white">

        <input-dialog v-if="!isPopupShow"
                      :formTitle="'来源方式'"
                      :formPlaceholder="'请选择'"
                      :readonly="isReadonly"
                      :required="true"
                      class="no_border_bottom"
                      v-model="customerInfo.SourceTypeKeyValue"
                      @showEldilog="openAdviser()"></input-dialog>
      </div>
      <!-- 其他信息 -->
      <other-info :customerInfo="customerInfo"
                  :isReadonly="isReadonly"></other-info>
      <!-- 提交 -->
      <div>
        <button-single-comfire-btn v-if="!isReadonly"
                                   :btnText="'确定'"
                                   :btnClass="'popup_confirm_btn'"
                                   @click="confirmClick"></button-single-comfire-btn>
      </div>
    </div>
    <!-- 该用户下已有的学生列表弹出层 -->
    <!-- <custom-dialog :width="'300px'"
                   :top="300"
                   :visible.sync="isShowStudentPopups"
                   :before-close="doCloseClick">
      <user-below-student-dialog ref="studentPopups"
                                 :studentList="studentList"
                                 :popupContent="popupContent"
                                 :isSingleSubmit="true"
                                 :singleSubmitType="popupContent.mark == 'abnormal'?'dynamic':'static'"
                                 @doAddStudentClick="doPopupAddStudent"
                                 @doAmendStudentClick="doPopupAmendStudent"
                                 @doCloseClick="doCloseClick"></user-below-student-dialog>
    </custom-dialog> -->
  </div>
</template>
<script>
import studentInfo from './student-info';
import otherInfo from './other-info';
import salesValueInfo from '../register-customer/sales-value-info';
import {
  AddStudentThread,
  AddStudent,
  editCustomerInfo,
  MessageSearchPageForSourceType,
  StudentThreadDetail,
  UpdateStudentThread
} from '../../../API/workbench';
// 登记客户
const newCustomerInfo = {
  StudentThreadKey: '', // 学生线索ID
  OLAPKey: '', // 整型  学生ID
  StudentKeyValue: '',
  MainDemoName: '', // 学生名字
  SexKey: '3', // 整型 性别 2:男，3:女
  SexKeyValue: '女', // 学生性别
  NickName: '', // 昵称
  AgeName: '', // 整型 学生年龄
  BirthdayTime: '',
  CustomerRelationshipKey: 0, // 整形   ,关系 : 0--家长;2--爸爸;3-妈妈;11-本人	;10-其他;
  CustomerRelationshipKeyValue: '家长', // 客户关系
  FirstLetterName: '', //	姓名首字母
  HeadImgSupport: '', // 头像
  StudentTypeKey: '', // 整型  是否正式  1:正式, 0：临时
  StudentTypeKeyValue: '', // 是否正式 可视化
  CustStatusKey: '', // 整型  是否在校 1:是, 0：否
  IsActiveKeyValue: '', //	是否在校 可视化
  CurrentMaxDayCount: '', // 整型  最大剩余天数
  CurrentClassHourCount: '', // 整形  剩余课时
  RegisterTime: '', // 登记日期
  SchoolName: '', // 学校名字
  FineIdSupport: '', // 整形  兴趣课程ID
  FineSupport: '', // 兴趣课程
  WillingnessRateKey: 3, // 意向级别
  WillingnessRateKeyValue: 'B级', //	意向级别可视化
  AdviserKey: '', // 整型 销售顾问
  AdviserKeyValue: '', // 销售顾问
  ParentName: '', // 紧急联系人
  ParentPhoneName: '', // 紧急联系人电话
  RelationshipKey: 0, // 整型  紧急联系人关系.
  RelationshipKeyValue: '', // 紧急联系人关系.
  DebtAmount: '', // 客户欠款
  SumAmount: '', // 客户余额
  MobileSupport: '', // 整型  客户手机
  CustKey: '', // 客户ID
  CustKeyValue: '', // 客户名
  VillageKey: '', // 住宅ID.
  VillageKeyValue: '', // 住宅小区
  CompanyName: '', // 工作单位
  TheRestChild: [], // 其余的孩子.
  IsCompletionKey: 0, // 是否完善资料. 0否1是.
  IsCompletionKeyValue: '否',
  SourceTypeKey: '', // 来源方式
  SourceTypeKeyValue: '', // 来源方式
  RemarksSupport: '', // 备注
  'AttendingSchool': '', // "就读学校",
  'Remark': '', // "备注事项",
  BirthTime: '', //
  ageNum: '',
  'SignUpIntentionKey': '', // "意向级别",//1-S级;2-A级;3-B级;4-C级;
  'SignUpIntentionKeyValue': '', // 1-S级;2-A级;3-B级;4-C级;
  'InterestedCourseIDs': '', // "感兴趣的课 123,124,125,126",
  'InterestedCourses': '' // "感兴趣的课",
};
export default {
  components: {
    studentInfo,
    otherInfo,
    salesValueInfo
    // userBelowStudentDialog
  },
  data () {
    return {
      OLAPKey: 0,
      isShowStudentPopups: false, // 该手机号下已有的学生弹窗
      isReadonly: false, // 开放变量 默认false-修改模式;true-只读模式
      customerInfo: {}, // 登记信息
      studentList: [], // 该手机号下已有的学生列表
      // 学生弹出层显示内容
      popupContent: {
        titleContent: '',
        textContent: '',
        studentButName: '', // 显示学生下的合并按钮名称
        submitButName: '', // 提交按钮名称
        mark: 'normal' // 标示==》 normal：正常, abnormal:异常
      },
      isSubmit: true,
      type: 0 // 默认-登记新生，1-登记线索

    };
  },
  props: {
    isPopupShow: {
      type: Boolean,
      default: false
    },
    isUpStudentsData: {// 是否修改
      type: Boolean,
      default: false
    },
    threadInfo: Object
  },
  computed: {
    // 追销开关显示
    vZX17Power () {
      if (this.$store.getters.SaaSClientInfo.SaaSClientSetting && this.$store.getters.SaaSClientInfo.SaaSClientSetting.length > 0) {
        let locaIndex = this.$store.getters.SaaSClientInfo.SaaSClientSetting.findIndex(o => {
          return o.SetTypeKey == 17 && o.SetKey == 1;
        });
        if (locaIndex >= 0) {
          this.customerInfo.WillingnessRateKey = 3;
          this.customerInfo.WillingnessRateKeyValue = 'B 一般';
          return false;
        } else {
          return true;
        }
      }
    }
  },
  created () {
    console.log(this.threadInfo, 'threadInfo isPopupShow', this.isPopupShow);
    this.customerInfo = this.$utils.parseJson(newCustomerInfo);
    if (this.isUpStudentsData) {
      this.OLAPKey = this.threadInfo.OLAPKey;
      this.getStudentThreadDetail();
    } else {
      if (this.isPopupShow) {
        // 关联学生档案
        this.OLAPKey = this.threadInfo.StudentThreadKey;
        this.customerInfo.StudentThreadKey = this.threadInfo.StudentThreadKey;// 该线索学生key
        this.customerInfo.MainDemoName = this.threadInfo.StudentKeyValue;// 该线索学生名
        this.customerInfo.MobileSupport = this.threadInfo.MobilePhone;// 该线索学生手机号
        this.getStudentThreadDetail();
      }
    }
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  watch: {
    // 客户名称显示逻辑.
    'customerInfo.CustomerRelationshipKeyValue': {
      handler (newName, oldName) {
        this.customerInfo.CustKeyValue = (this.customerInfo.MainDemoName || '') + (this.customerInfo.CustomerRelationshipKeyValue || '');
      },
      deep: true
    },
    // 客户名称显示逻辑.
    'customerInfo.MainDemoName': {
      handler (newName, oldName) {
        this.customerInfo.CustKeyValue = (this.customerInfo.MainDemoName || '') + (this.customerInfo.CustomerRelationshipKeyValue || '');
        console.log(newName, '电话号码');
      },
      deep: true
    },
    // 出生日期
    'customerInfo.BirthdayTime': {
      handler (newName, oldName) {
        if (!this.customerInfo.BirthdayTime && !this.customerInfo.AgeName) {
          this.customerInfo.AgeName = '';
        } else if (this.customerInfo.BirthdayTime) {
          var dd = Date.new().getTime() - Date.new(this.customerInfo.BirthdayTime).getTime();
          this.customerInfo.AgeName = Math.floor(dd / 1000 / 24 / 3600 / 365);
        }
      },
      immediate: true
    }
    // 电话号码

  },
  methods: {
    getStudentThreadDetail () {
      StudentThreadDetail(this.OLAPKey).then(result => {
        // this.customerInfo = result.data;
        this.customerInfo.StudentThreadKey = result.data.OLAPKey;
        this.customerInfo.MainDemoName = result.data.StudentKeyValue;
        this.customerInfo.MobileSupport = result.data.MobilePhone;
        this.customerInfo.BirthdayTime = result.data.BirthTime;
        this.customerInfo.SchoolName = result.data.AttendingSchool;
        this.customerInfo.RemarksSupport = result.data.Remark;
        this.customerInfo.CustomerRelationshipKey = result.data.CustomerRelationshipKey;
        this.customerInfo.CustomerRelationshipKeyValue = result.data.CustomerRelationshipKeyValue;
        this.customerInfo.WillingnessRateKey = result.data.SignUpIntentionKey;
        this.customerInfo.WillingnessRateKeyValue = result.data.SignUpIntentionKeyValue;
        this.customerInfo.SexKey = result.data.SexKey;
        this.customerInfo.SexKeyValue = result.data.SexKeyValue;
        this.customerInfo.SourceTypeKey = result.data.SourceTypeKey;
        this.customerInfo.SourceTypeKeyValue = result.data.SourceTypeKeyValue;
        this.customerInfo.VillageKey = result.data.VillageKey;
        this.customerInfo.VillageKeyValue = result.data.VillageKeyValue;
        this.customerInfo.CurrentNodeKey = result.data.CurrentNodeKey;
        this.customerInfo.CurrentNodeKeyValue = result.data.CurrentNodeKeyValue;
        this.$forceUpdate();
        console.log('StudentThreadDetail', result.data, this.customerInfo);
      }).catch(error => {
        console.log(error);
      });
    },
    // 打开跟销顾问人列表
    openAdviser () {
      // 查询客户来源
      let sourceParam = {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      };
      MessageSearchPageForSourceType(sourceParam).then(result => {
        this.salerList = result.data.PageDataList.filter(o => {
          return o.StateKey == 2;
        });
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择客户来源',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.customerInfo.SourceTypeKey, dataSourceList: this.salerList, required: false, addModuleName: 'sell-source-setting', addmoduleTitleName: '新增来源方式', ModulePowerKey: 45 },
          callBack: {
            onSelectedItem: (data) => {
              this.customerInfo.SourceTypeKey = data.OLAPKey;
              this.customerInfo.SourceTypeKeyValue = data.MainDemoName;
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },

    // 确定执行
    confirmClick (callBack) {
      const res = this.validateCustomerInfo(callBack);
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.isSubmit) { // 防止接口请求太慢导致重复提交
        this.confirmData(callBack);
      }
    },
    // 验证客户信息
    validateCustomerInfo (callBack) {
      if (this.customerInfo.MainDemoName) {
        this.customerInfo.MainDemoName = this.customerInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      let flag = true;
      this.customerInfo.StudentKeyValue = this.customerInfo.MainDemoName;
      this.customerInfo.MobilePhone = this.customerInfo.MobileSupport;
      this.customerInfo.AttendingSchool = this.customerInfo.SchoolName;
      this.customerInfo.Remark = this.customerInfo.RemarksSupport;
      this.customerInfo.BirthTime = this.customerInfo.BirthdayTime;
      this.customerInfo.ageNum = this.customerInfo.AgeName;

      if (!this.customerInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入学生名字');
        flag = false;
      } else if (!this.customerInfo.CustomerRelationshipKeyValue) {
        layer.alert('请选择客户关系');
        flag = false;
      }else if (!this.customerInfo.SourceTypeKey ) {
        layer.alert('请选择来源方式');
        flag = false;
      }else if (this.customerInfo.MobileSupport.length != 11 && this.customerInfo.MobileSupport) {
        layer.alert('请输入11位的手机号码');
        flag = false;
      } else if (!this.customerInfo.CustKeyValue.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('客户名称不能为空');
        flag = false;
      } else if (!this.customerInfo.MobileSupport && !this.customerInfo.OLAPKey) {
        flag = false;
        layer.confirm('未填写手机号，是否继续提交?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                this.isSubmit = false;
                this.confirmData(callBack);
                layer.close();
              }
            }
          ]
        });
      }
      return flag;
    },
    // 提交
    confirmData (callBack) {
      this.customerInfo.SexKeyValue = this.customerInfo.SexKey == 2 ? '男' : '女';
      this.customerInfo.AgeName = this.customerInfo.AgeName == '' || this.customerInfo.AgeName == '无年龄' ? 0 : this.customerInfo.AgeName;
      this.customerInfo.StuSourceKey = 2;
      this.customerInfo.StuSourceKeyValue = '员工登记';

      this.addConfirm(this.customerInfo, callBack);
    },

    // 新增操作
    addConfirm (customerInfo, callBack) {
      console.log(customerInfo, 'addConfirm customerInfo');
      if (this.isUpStudentsData) {
        // 修改
        UpdateStudentThread(customerInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess', result.data);
          this.cancelClick(result.data);
        }, err => {
          layer.alert(err.msg);
        }).finally(() => {
          this.isSubmit = true;
          if (callBack) {
            callBack();
          }
        });
      } else {
        if (this.isPopupShow) {
          // 关联学生
          AddStudent(customerInfo).then(result => {
            layer.alert('关联成功');
            this.$emit('afterSuccess');
            this.cancelClick(result.data);
          }, err => {
            layer.alert(err.msg);
          }).finally(() => {
            this.isSubmit = true;
            if (callBack) {
              callBack();
            }
          });
        } else {
          // 添加新线索
          AddStudentThread(customerInfo).then(result => {
            layer.alert('登记成功');
            this.$emit('afterSuccess', result.data);
            this.cancelClick(result.data);
          }, err => {
            layer.alert(err.msg);
          }).finally(() => {
            this.isSubmit = true;
            if (callBack) {
              callBack();
            }
          });
        }
      }
    },
    // 取消
    cancelClick (data) {
      this.$emit('close');
    }
  }
};
</script>
