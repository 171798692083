<template>
  <div>
    <div v-if="circleCourseItemData"
         class="content_marginRL content_marginTop_30">
      <course-info :courseInfo="circleCourseItemData"
                   :sumToBeArranged="sumToBeArranged"></course-info>

      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow">
        <input-date v-model="paramsForRule.StartTime"
                    :elementId="'beginDate'"
                    :required="true"
                    :formTitle="'开始日期'"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :placeholder="'开始日期'"
                    :isShowWeek="true"
                    @changeDate="doAfterChangeBeginDate"></input-date>
        <input-date v-model="paramsForRule.EndTime"
                    :elementId="'endDate'"
                    :required="true"
                    :formTitle="'结束日期'"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :placeholder="'结束日期'"
                    :isShowWeek="true"
                    @changeDate="doAfterChangeEndDate"></input-date>
        <div class="arrange_num">
          <input-number-dialog :formTitle="'安排节数'"
                               :dialogTitleName="'安排节数'"
                               v-model="vClassCount"
                               :decimalPlaces="0"
                               :isShowButtonInfo="true"
                               :required="true"
                               :class="'arrange_hour'"
                               :isNeedVerification="true"
                               :verificationData="paramsForRule.StartTime"
                               :verificationTip="'请先选择开始日期。'"
                               :min="1"></input-number-dialog>
          <span class="bottom_text">排入{{this.$utils.mAmountType(arrangeCountHour, 1)}}课时</span>
        </div>
      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmPartClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import courseInfo from './free-choice-arrange-course/course-info';
import { ArrangeStudent2MutiSameApplyClassCourseByRule, GetCourseAndStudentCourseArrangByRule, SearchStructedRule, GetTheStudentTicketPlanListByClassType, GetTicketPlanForGradeApplyClass } from '../../../API/workbench';
export default {
  data () {
    return {
      arrangeCountHour: 0,
      arrangeRules: {},
      scheduleCourseList: [],
      circleCourseItemData: null,
      sumToBeArranged: 0,
      paramsForRule: {
        StudentKey: 0,
        StructuredRuleKey: 0,
        StartTime: '',
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      },
      studentKey: 0,
      courseNameKey: 0,
      classGradeKey: 0
    };
  },
  components: {
    courseInfo
  },
  props: {
    paramsInfo: {
      type: Object,
      default: {
        studentKey: String,
        courseNameKey: String,
        classGradeKey: String,
        ruleID: String
      }
    }
  },
  computed: {
    vClassCount: {
      get () {
        return {
          number: this.paramsForRule.ClassCount,
          UnitName: '节'
        };
      },
      set (newValue) {
        this.paramsForRule.ClassCount = newValue.number;
        this.doAfterChangeClassCount();
      }
    }
  },
  created () {
    this.studentKey = this.paramsInfo.studentKey;
    this.courseNameKey = this.paramsInfo.courseNameKey;
    this.classGradeKey = this.paramsInfo.classGradeKey;
    this.loadStructedRule();
  },
  methods: {
    // 获取排课规律课块
    loadStructedRule (type) {
      SearchStructedRule(this.courseNameKey, this.classGradeKey).then(result => {
        let circleCourseItem = result.data.find(obj => { return obj.StructuredRuleKey == this.paramsInfo.ruleID; });
        circleCourseItem.alreadyAttenCount = 0;
        this.circleCourseItemData = circleCourseItem;
        this.getCourseAndStudentCourseArrangByRule();
      });
    },
    // 查询 根据学生ID 排课规律ID 查所有的排课信息
    getCourseAndStudentCourseArrangByRule () {
      GetCourseAndStudentCourseArrangByRule(this.studentKey, this.circleCourseItemData.StructuredRuleKey).then(result => {
        if (result.code == 0) {
          this.scheduleCourseList = result.data.ScheduleCourseList;
          this.arrangeRules = result.data.ArrangeRules; // 0:未排 1：全部 2：部分 自定义
          if (this.courseNameKey) {
            this.getTheStudentTicketPlanListByClassType();
          } else if (this.classGradeKey) {
            this.getTicketPlanForGradeApplyClass();
          }
        }
      });
    },
    // 获取此学生指定课名所有报课计划
    getTheStudentTicketPlanListByClassType () {
      GetTheStudentTicketPlanListByClassType(this.studentKey, this.courseNameKey).then(result => {
        this.sumToBeArranged = result.data.Sum_ToBeArranged;
        this.setBeginToEndTime();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取此学生指定班级所有报课计划
    getTicketPlanForGradeApplyClass () {
      GetTicketPlanForGradeApplyClass(this.studentKey, this.classGradeKey).then(result => {
        this.sumToBeArranged = result.data.Sum_ToBeArranged;
        this.setBeginToEndTime();
      }, error => {
        layer.alert(error.msg);
      });
    },
    setBeginToEndTime () {
      this.paramsForRule.StudentKey = this.studentKey;
      this.paramsForRule.StructuredRuleKey = this.circleCourseItemData.StructuredRuleKey;
      this.paramsForRule.StartTime = this.$utils.formatDateToLine(Date.new());
      let hasArrangedArr = this.scheduleCourseList.filter(obj => obj.ArrangeID > 0);
      this.circleCourseItemData.alreadyAttenCount = hasArrangedArr.length;
      // 未排 默认全部需排入
      if (this.arrangeRules.type == 0) {
        this.paramsForRule.StartTime = this.scheduleCourseList[0].ClassTime.slice(0, 10);
        if (this.sumToBeArranged > 0) {
          this.paramsForRule.ClassCount = 0;
          this.arrangeCountHour = 0;
          this.scheduleCourseList.forEach(obj => {
            if (obj.ClassTime.slice(0, 10) >= this.paramsForRule.StartTime) {
              if (this.arrangeCountHour < this.sumToBeArranged) {
                this.paramsForRule.ClassCount += 1;
                this.arrangeCountHour = this.arrangeCountHour + this.$utils.mAmountType(obj.PeriodCount, 1);
                this.paramsForRule.EndTime = obj.ClassTime.slice(0, 10);
              }
            }
          });
          return;
        }
      } else if (this.arrangeRules.type == 1 && this.arrangeRules.type == 2) { // 0：未排 1：全部 2：部分 3.自定义
        this.paramsForRule.StartTime = hasArrangedArr[0].ClassTime.slice(0, 10);
        this.paramsForRule.EndTime = this.arrangeRules.lastCourseDate;
      } else {
        if (hasArrangedArr.length > 0) {
          this.paramsForRule.StartTime = hasArrangedArr[0].ClassTime.slice(0, 10);
          this.paramsForRule.EndTime = hasArrangedArr[hasArrangedArr.length - 1].ClassTime.slice(0, 10);
        }
      }
      this.caculClassCount();
    },
    // 选择开始日期
    doAfterChangeBeginDate (date) {
      this.paramsForRule.StartTime = date;
      this.caculClassCount();
    },
    // 选择结束日期
    doAfterChangeEndDate (date) {
      this.paramsForRule.EndTime = date;
      this.caculClassCount();
    },
    // 变更节数
    doAfterChangeClassCount () {
      let courseList = this.$utils.parseJson(this.scheduleCourseList);
      courseList.sort((a, b) => {
        return (Date.new(a.ClassTime).getTime() - Date.new(b.ClassTime).getTime());
      });
      let lastCourseDate = Date.new(this.circleCourseItemData.LastClassTime).getTime();
      let startDate = Date.new(this.paramsForRule.StartTime).getTime();

      if (this.paramsForRule.ClassCount == 0) {
        this.paramsForRule.EndTime = this.paramsForRule.StartTime;
        this.caculClassCount();
      } else if (startDate > lastCourseDate) { // 如果开始日期大于最后一节课的日期
        this.paramsForRule.ClassCount = 0;
        this.paramsForRule.EndTime = this.paramsForRule.StartTime;
      } else if (startDate <= lastCourseDate) {
        let courseArr = courseList.filter(obj => Date.new(obj.ClassTime.slice(0, 10)) >= Date.new(this.paramsForRule.StartTime));
        if (this.paramsForRule.ClassCount > courseArr.length) {
          this.paramsForRule.ClassCount = courseArr.length;
        }
        this.paramsForRule.EndTime = courseArr[this.paramsForRule.ClassCount - 1].ClassTime.slice(0, 10);
        this.caculClassCount();
      }
    },
    // 计算上课节数
    caculClassCount () {
      this.paramsForRule.ClassCount = 0;
      this.arrangeCountHour = 0;

      if (this.paramsForRule.EndTime) {
        this.scheduleCourseList.forEach(obj => {
          if (obj.ClassTime.slice(0, 10) >= this.paramsForRule.StartTime && obj.ClassTime.slice(0, 10) <= this.paramsForRule.EndTime) {
            this.paramsForRule.ClassCount += 1;
            this.arrangeCountHour = this.arrangeCountHour + this.$utils.mAmountType(obj.PeriodCount, 1);
          }
        });
      }
    },
    // 部分日期 排课提交
    confirmPartClick (callBack) {
      let flag = false;
      if (!this.paramsForRule.StartTime) {
        layer.alert('开始日期不可为空 。');
        flag = true;
      } else if (!this.paramsForRule.EndTime) {
        layer.alert('结束日期不可为空 。');
        flag = true;
      } else if (this.paramsForRule.ClassCount == 0) {
        layer.alert('安排节数不可为0，如需取消安排，请选择 退出已排的课 。');
        flag = true;
      }
      if (flag) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return;
      }
      this.arrangeStudent2MutiSameApplyClassCourseByRule(() => {
        console.log('confirmPartClick', this.paramsForRule, this.paramsInfo);
        this.$bus.emit('afterArrangeScheduleCourse');
        this.$emit('afterBatchArrangeCourse');
        this.closeDilog();
      });
    },
    // 单学生多课规律排课
    arrangeStudent2MutiSameApplyClassCourseByRule (callBack) {
      ArrangeStudent2MutiSameApplyClassCourseByRule(this.paramsForRule)
        .then(result => {
          if (typeof callBack == 'function') {
            callBack();
          }
          // layer.alert('排课成功');
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    closeDilog () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style>
</style>