<template>
  <!-- 结余统计 列表 -->
  <div class="">
    <list v-if="isCourseInfo"
          ref="studentApplyCourseTable"
          :rowKey="'Number'"
          :tableData="classRecordListL"
          :totalNum="classRecordTotal"
          :PageCount="PageCount"
          :tableColumns="tableColumns"
          :pageSize="15"
          :isSearchText="false"
          :defaultSort="defaultSort"
          :queryParams="classRecordParams"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadAfterTableData"></list>
    <list-new v-else
              ref="studentApplyCourseTable"
              :rowKey="'Number'"
              :tableData="classRecordListL"
              :totalNum="classRecordTotal"
              :PageCount="PageCount"
              :tableColumns="tableColumnsNew"
              :tableColumnsNew="tableColumnsNew"
              :pageSize="15"
              :isSearchText="false"
              :defaultSort="defaultSort"
              :queryParams="classRecordParams"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="loadAfterTableData"></list-new>

  </div>
</template>
<script>

import list from './general-table';
import listNew from './general-table-new';
export default {
  data () {
    return {
      seletedCourseInfo: {},
      studentList: [], // 按学生 的 学生列表
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: '',
          decimalPlace: 0
        },
        {
          label: '购买日期',
          prop: 'GenerateTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '订单号',
          prop: 'SourceBillKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.toApplyRecord(rowData);
            }

          }
        },
        {
          label: '课单号',
          prop: 'NumberName',
          width: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.goRouter(rowData);
            }

          }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'reading-courses',
          columnClass: ''
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '课单类型',
          prop: 'ClassUnitType',
          width: 80,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '适用课程',
          prop: 'ApplyCorName',
          width: 140,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '购买课时',
          prop: 'NumCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '实收款',
          prop: 'ActualPayAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '生效日期',
          prop: 'StartTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '失效日期',
          prop: 'ExpireTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '消耗课时',
          prop: 'SpendCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '结转课消',
          prop: 'DeductAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '可用课时',
          prop: 'CurrentCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '课时结余',
          prop: 'ClassHourCashSurplus',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '可用天数',
          prop: 'PeriodCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        }
      ],
      tableColumnsNew: [
        {
          label: '序号',
          prop: 'Number',
          width: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: '',
          decimalPlace: 0
        },
        {
          label: '购买日期',
          prop: 'GenerateTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '订单号',
          prop: 'SourceBillKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.toApplyRecord(rowData);
            }

          }
        },
        {
          label: '课单号',
          prop: 'NumberName',
          width: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.goRouter(rowData);
            }

          }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          columnClass: ''
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '课单类型',
          prop: 'ClassUnitType',
          width: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '适用课程',
          prop: 'ApplyCorName',
          width: 140,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '购买天数',
          prop: 'DaysOfPurchase',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '实收款',
          prop: 'ActualPayAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '启用日期',
          prop: 'StartTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '截止日期',
          prop: 'ExpireTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '消耗天数',
          prop: 'DeductDays',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '结转课消',
          prop: 'DeductAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '可用天数',
          prop: 'PeriodCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '课时结余',
          prop: 'ValueAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        }
      ],
      PageCount: 0,
      summary: {
        needSummariesColumns: [
          { prop: 'StudentKeyValue', SumType: 'string', unit: '人' },
          { prop: 'Total_NumCount', SumType: 'number', unit: '' },
          { prop: 'Sum_SpendCount', SumType: 'number', unit: '' },
          { prop: 'CurrentClassHourCount', SumType: 'number', unit: '' },
          { prop: 'Sum_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Total_ToBePlaned', SumType: 'number', unit: '' },
          { prop: 'Sum_ArrangedCount', SumType: 'number', unit: '' },
          { prop: 'Sum_ToBeArranged', SumType: 'number', unit: '' }
        ],
        isShowSummary: false,
        summaryName: '合计',
        summaryData: []
      },
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list,
    listNew
  },
  props: {
    classRecordListL: Array,
    classRecordTotal: Number,
    classKey: [Number, String], // 班级ID
    classRecordParams: Object,
    isCourseInfo: Boolean // true课次记录 false结转课消

  },
  created () {
    console.log(this.classRecordListL, 'classRecordListL');
  },
  mounted () {
    this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
  },

  watch: {
    'classKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0 && n != o) {
          this.$nextTick(() => {
            this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
          });
        }
      },
      deep: true // 表示开启深度监听
    },
    'classRecordParams.pageIndex': {
      handler (n, o) {
        if (n == 0) {
          this.$nextTick(() => {
            this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
          });
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  computed: {
    vTable () {
      let arr = [];
      if (this.isCourseInfo) {
        arr = this.tableColumns;
      } else {
        arr = this.tableColumnsNew;
      }
      return arr;
    },
    vtableColumns () {
      let list = [];
      this.tableColumns.forEach(o => {
        if (this.$utils.getSaaSClientSet(59) > 0 && o.label == '旷课人次') {
          o.isShow = true;
        } else if (this.$utils.getSaaSClientSet(59) == 0 && o.label == '旷课人次') {
          o.isShow = false;
        }
        list.push(o);
      });
      return list;
    }
  },
  methods: {
    // 课单号
    goRouter (item) {
      console.log('goRouter', item);
      let moduleInfo = {
        routerName: 'classListQueryStatistics',
        moduleName: '',
        ModulePowerKey: 127,
        data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, searchText: item.NumberName } }

      };
      this.$bus.emit('needOpenRouter', moduleInfo);
      // layer.alert("此功能暂未开通")
    },
    // 到报名收款
    toApplyRecord (item) {
      console.log('toApplyRecord', item);
      if (item.SourceBillKeyValue != '-') {
        this.$dialog.open(this, {
          name: '报名收款',
          routerName: this.$route.name,
          moduleName: 'signuppayment',
          moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
          data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.SourceBillKey, isReadOnly: true } },
          callBack: { afterSucces: () => { this.getDataList(); } }
        });
      }
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
      this.seletedCourseInfo = rowData;
      this.$dialog.open(this, {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.OLAPKey, isAlter: false }
      });
    },
    // 跳转页码
    loadAfterTableData (queryParams) {
      console.log(queryParams, '跳转页码');
      this.$emit('loadTableData', queryParams);
    }
  }

};
</script>
