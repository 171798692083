<template>
  <div class="summarizing_list">
    <table-list
      :tableData="vTableInfoList"
      :tableColumns="tableColumns"
      :totalNum="totalNum"
      :queryParams="params"
      :isShowCheckBox="false"
      @loadTableData="getDataInfo"
    ></table-list>
    <custom-dialog
      :title="'拒绝原因'"
      :visible.sync="isShowMessagePopup"
      :before-close="hideMessagePopup"
    >
      <remerk-popup
        :placeholder="'请输入拒绝原因'"
        @onClickClose="hideMessagePopup"
        @onClickConfirm="rejectRecommend"
      ></remerk-popup>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import remerkPopup from '../remerk-popup';
import { HQSearchPagePaymentRecord, YKRecommendManageReceive, YKRecommendManageRefuse } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      isShowMessagePopup: false,
      selectInfo: {},
      dataInfoList: [],
      totalNum: 0,
      tableColumns: [
        {
          label: '付款日期',
          prop: 'PaymentTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '签单日期',
          prop: 'ApplicantTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '投资人',
          prop: 'AttractInvestKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          type: 'text-item',
          width: 120
        },
        {
          label: '计划城市',
          prop: 'cityValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '机构名称',
          prop: 'InstitutionName',
          type: 'text-item',
          width: 180
        },
        {
          label: '申请人',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '合同金额',
          prop: 'ContractAmount',
          type: 'amount-item',
          width: 80
        },
        {
          label: '付款类型',
          prop: 'PaymentTypeKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '付款金额',
          prop: 'PaymentAmount',
          type: 'amount-item',
          width: 80
        },
        {
          label: '付款截图',
          prop: 'paymentScreenshotIcon',
          type: 'text-item',
          width: 80,
          extend: {
            setClassName: (rowData) => {
              return 'button';
            },
            click: (rowData) => {
              this.doPaymentScreenshotClick(rowData);
            }
          }
        },
        {
          label: '收支科目',
          prop: 'IncomePaySubjectKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '收支账户',
          prop: 'IncomePayAccountKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '审核人',
          prop: 'AuditorKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '审核时间',
          prop: 'AuditTime',
          type: 'text-item',
          width: 100
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          className: '',
          width: 120,
          extend: {
            tableBtnGroup: [
              {
                name: '合同详情',
                extend: {
                  click: (rowData) => {
                    console.log('rowData', rowData);
                    this.selectInfo = rowData;
                    this.goDetail();
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  props: {
    params: Object
  },
  components: {
    tableList, remerkPopup
  },
  computed: {
    vTableInfoList () {
      let arr = [];
      this.dataInfoList.forEach((e) => {
        // 截图
        e.cityValue = e.ProvinceKeyValue + '·' + e.CityKeyValue;
                    if (e.PrintScreen) {
                e.paymentScreenshotIcon = `<span class="payment_screenshot_icon opt_icon"></span>`;
            } else {
                e.paymentScreenshotIcon = `<span class="payment_screenshot_icon"></span>`;
            }
        e.AuditTime = e.AuditTime.substring(0, 10);

        arr.push(e);
      });
      return arr;
    }
  },
  created () {
    this.getDataInfo();
  },
  methods: {
    // 下载截图
    doPaymentScreenshotClick (rowData) {
      if (rowData.PrintScreen) {
        let src = this.$store.getters.CDNURL + rowData.PrintScreen;
        console.log(src, '下载截图');
        // window.open(src);
        // src = src.replace('cdn.', '');
        // src = src.replace(':8999', '');
        console.log(src);
        var canvas = document.createElement('canvas');
        var img = document.createElement('img');
        img.setAttribute('crossOrigin', 'Anonymous');
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          var context = canvas.getContext('2d');
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = '下载截图';
            link.click();
          }, 'image/jpeg/png/JPG/PDF/TIFF/SWF/jpg/pdf/tiff/swf');
        };
        img.src = src;
      } else {

      }
    },
      // 导出
    doExportInfoClick (callBack) {
      if (this.dataInfoList.length > 0) {
        var newData = this.$utils.parseJson(this.params);
        this.params.IsExportKey = 1;
        this.params.pageIndex = 0;
        this.params.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '合同付款记录',
          clist: [
            { title: '付款日期', cName: 'PaymentTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '签单日期', cName: 'ApplicantTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '投资人', cName: 'AttractInvestKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'MobilePhone', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
             { title: '计划省份', cName: 'ProvinceKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '计划城市', cName: 'CityKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '加盟方式', cName: 'FranchiseTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '机构名称', cName: 'InstitutionName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '申请人', cName: 'CounselorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '合同金额', cName: 'ContractAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '付款类型', cName: 'PaymentTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '付款金额', cName: 'PaymentAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支科目', cName: 'IncomePayAccountKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支账户', cName: 'IncomePaySubjectKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '审核人', cName: 'AuditorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '审核时间', cName: 'AuditTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        HQSearchPagePaymentRecord(this.params, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.params.IsExportKey = 0;
          this.params.pageIndex = newData.pageIndex;
          this.params.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    openMessagePopup (item) {
      this.selectInfo = item;
      this.isShowMessagePopup = true;
    },
    goDetail () {
      // 排课 按钮
      let dataitem = {
        OLAPKey: this.selectInfo.AttractInvestSignContractKey
        // MainDemoName:this.selectInfo.AttractInvestKeyValue
      };
      this.$dialog.open(this, {
        name: '合同详情',
        routerName: this.$route.name,
        moduleName: 'crmSignContractForm',
        data: { dataInfo: dataitem, isReadonly: true, showBtn: false},
        callBack: {
          afterSuccess: () => {
            this.getDataInfo();
          } }
      });
    },
    resultRecommend (item) {
      YKRecommendManageReceive(item.OLAPKey).then(res => {
        this.$emit('refreshListInfo');
        this.getDataInfo();
      }, err => {
        layer.alert(err.msg);
      });
    },
    rejectRecommend (text) {
      YKRecommendManageRefuse(this.selectInfo.OLAPKey, text).then(res => {
        this.$emit('refreshListInfo');
        this.getDataInfo();
      }, err => {
        layer.alert(err.msg);
      });
    },
    hideMessagePopup () {
      this.isShowMessagePopup = false;
    },
    getDataInfo () {
      HQSearchPagePaymentRecord(this.params).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataInfoList = res.data.PageDataList;
          this.$emit('upDataInfo', res.data);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>