<template>
  <!-- 查看 图片 -->
  <div class="view_photo_img body_img_list"
       :style="{'margin-left':vmarginLeft,'margin-right':vmarginRight}">
    <img class="img_box"
         :src="vHeadImg"
         alt=""
         @click.stop="clickImg">
  </div>

</template>

<script>
export default {
  props: {
    imgUrl: {
      type: String
    },
    isShrink: {
      type: Boolean, // 是否显示缩略图片地址
      default: true
    },
    vIsShowVideo: {
      type: Boolean, // 是否显示视频
      default: true
    },
    keyIndex: Number
  },
  computed: {
    vHeadImg (url) {
      if (this.imgUrl) {
        // 缩略图
        if (this.isShrink) {
          return this.$utils.ossLitimgSet(this.imgUrl);
        } else {
          if (this.imgUrl.indexOf('http') > -1) {
            return this.imgUrl;
          } else {
            return this.$store.getters.CDNURL + this.imgUrl;
          }
        }
      } else {
        return '';
      }
    },
    vmarginLeft () {
      if (this.vIsShowVideo && this.keyIndex == 0) {
        return '114px';
      } else {
        return '0px';
      }
    },
    vmarginRight () {
      if (this.vIsShowVideo && this.keyIndex == 0) {
        return '4px';
      } else {
        return '5px';
      }
    }
  },
  methods: {
    // 点击图片
    clickImg () {
      this.$emit('onClickImg', this.imgUrl);
    }
  }
};
</script>

<style>
</style>