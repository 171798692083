<template>
  <div class="form_info_edit form_info_list">
    <input-number :min="0"
                  :formTitle="'申请数量'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.ApplyNum"></input-number>

    <input-text :formTitle="'备注'"
                :required="false"
                :readonly="false"
                :formPlaceholder="'请输入'"
                v-model="dataInfo.Remark"></input-text>
    <div style="margin: 85px; margin-bottom: 30px;color: #999;">申请后请联系专属客服经理开通</div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { ApplyCampusPort } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        ApplyNum: 1,//申请数
        Remark: ""//备注
      }
    };
  },
  components: {

  },
  props: {

  },
  created () {
    console.log(this.dataInfo, 'dataInfo');
  },
  computed: {
  },
  methods: {
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      ApplyCampusPort(this.dataInfo).then(result => {
        layer.alert('申请成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.ApplyNum) {
        layer.alert('申请数量最少不能小于1');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
