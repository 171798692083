<template>
  <div>
    <div class="attendance_arrange"
         :class="ClassName">
      <div class="class-list">
        <!-- 课信息 -->
        <course-info :courseInfo="courseInfo"
                     :isAlter="isAlter"
                     :unAttendanceNum="vUnAttendanceNum"
                     @updateCourseInfo="updateCourseInfo"></course-info>
        <div class="index_course_info_item form_info_bottom">
          <!-- <div class="form_attendance_title">
            <div class="fl">
              学员签到表
            </div>
            <div class="fr">签到:
              <span class="font_black">{{vSignInNum}}人</span>丨未到:
              <span class="font_black"><span class="font_red">{{vUnArriveNum}}</span>人</span>
            </div>
          </div> -->
          <div :class="{'student-sign-in':!vIsClassStop}"
               class="pr">
            <div class="course_student_attendance_title">
              <div class="title_text">学生考勤
                <span class="list_column button"
                      @click="showColumn(false)"
                      :class="isshowColumn?'':'opt'">
                  <img style="width:12px;"
                       src="../../../../public/image/atten_type_01.png"
                       alt="">
                </span>
                <span class="list_row button"
                      @click="showColumn(true)"
                      :class="isshowColumn?'opt':''">
                  <img style="width:12px;"
                       src="../../../../public/image/atten_type_02.png"
                       alt="">
                </span>
              </div>
              <div class="title_btn button"
                   v-if="isAlter&&!isshowColumn"
                   @click.stop="clickBatchAttendance(3)">批量签到</div>
              <div class="title_btn button"
                   style="width: 124px;"
                   v-if="isAlter&&isshowColumn"
                   @click.stop="clickAtten(3)">批量考勤校正</div>
            </div>
            <student-list v-if="courseInfo.ScheduleCourseKey>0"
                          ref="studentListRef"
                          :selectedCourseInfo="courseInfo"
                          :studentArrangeList="studentArrangeList"
                          :isAlter="isAlter"
                          :isBatchAttendance="isBatchAttendance"
                          :isTeacher="isTeacher"
                          :isshowColumn="isshowColumn"
                          @updateCourseInfo="updateCourseInfo"
                          @clickBatchAttendance="clickBatchAttendance"
                          @cancelBatchAttendance="cancelBatchAttendance"
                          @closeCourse="cancelAndBack"></student-list>
            <!-- 操作按钮 -->
            <!-- <div class="course_student_btn clearfix">
              <div class="course_student_btn_left fl">
                <function-btn v-if="vIsExpireDate && !vIsClassStop"
                              :item="editbtn"
                              @clickItem="editCourseShow"
                              :className="'course_student_btn_list btn_hover_white fl'"></function-btn>
                <function-btn v-if="vIsExpireDate && !vIsClassStop"
                              :item="stopbtn"
                              @clickItem="courseSuspend"
                              :className="'course_student_btn_list btn_hover_white fl'"></function-btn>
              </div>
              <function-btn v-if="!vIsClassStop"
                            :item="attenbtn"
                            @clickItem="clickBatchAttendance"
                            :className="'course_student_btn_list btn_hover_blue course_student_btn_right fr'"></function-btn>

            </div> -->

          </div>
          <!-- <attendance-statistics :courseInfo="courseInfo"
                                 :studentList="studentArrangeList"></attendance-statistics> -->
          <!--v-if="!isNoHomeWork"  -->
          <course-bottom :courseInfo="courseInfo"></course-bottom>
        </div>
      </div>
    </div>
    <div v-if="isShowBtn"
         @click="cancelAndBack"
         style="background-color:#fff;border-top:1px solid #ececec"
         class="confirm_btn btn_fixed_bottom">
      <div class="btn_fixed_blue btn_hover_bg_blue"
           style="width:100%">完成并返回</div>
    </div>
  </div>
</template>

<script>
import courseInfo from './course-info';
import courseBottom from './course-bottom';
import studentList from './student-list';
import attendanceStatistics from './attendance-statistics';
import selectAllTip from './select-all-tip';
import {
  getCourseDetailed, getTeacheCourseDetail,
  getScheduleCourseOnStudentList, PauseScheduleCourse, ScheduleCourseRecovery
} from '../../../API/workbench';
export default {
  data () {
    return {
      scheduleCourseKey: '',
      isBatchAttendance: false, // 是否批量考勤
      courseInfo: {
        CommentStudentList: [],
        UnCommentStudentList: [],
        HomeWorkStudentList: [],
        TeachingPlan: {}

      },
      studentArrangeList: [],
      isShowEditCourse: false,
      isShowEditCourseDialog: false,
      isshowColumn: false
    };
  },
  components: {
    studentList, courseInfo, attendanceStatistics, selectAllTip, courseBottom
  },
  // 提供外部输出显示，不给就输出内部默认显示
  props: {
    courseKey: {
      type: [Number, String]
    },
    isShowBtn: {
      type: Boolean,
      default: true
    },
    formTitle: {
      // 标题，
      type: String,
      default: '默认'
    },
    ClassName: {
      type: String,
      default: 'course_overflow'
    },
    isTeacher: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isAlter: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    isHistory: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  created () {
    console.log(this.courseKey, '000000000000000000', this.isAlter);
    if (this.courseKey > 0) {
      this.scheduleCourseKey = this.courseKey;
      this.getCourseInfo();
      this.loadStudentArrangeList();
      this.resgisterBusEvent();
    }
  },
  activated () {

    console.log(this.courseKey, '000000000000000000');
    if (this.courseKey > 0) {
      this.scheduleCourseKey = this.courseKey;
      this.getCourseInfo();
      this.loadStudentArrangeList();
      this.resgisterBusEvent();
    }
  },
  watch: {
    courseKey (n, o) {
      if (n) {
        this.scheduleCourseKey = n;
        this.isBatchAttendance = false;
        this.getCourseInfo();
        this.loadStudentArrangeList();
      }
    }
  },
  computed: {
    vUnAttendanceNum () {
      return this.studentArrangeList.filter(obj => { return obj.AttenceStatusKey == 2; }).length;
    },
    vSignInNum () { // 签到人数
      return this.studentArrangeList.filter(obj => { return obj.AttenceStatusKey == 3; }).length;
    },
    vUnArriveNum () { // 未到人数
      return this.studentArrangeList.length - this.vSignInNum;
    },
    vIsClassStop () { // 是否停课
      return this.courseInfo.IsClassStopKey == 1;
    },
    vIsExpireDate () { // 是否过期
      if (this.$utils.getFormatDateToQuery(this.courseInfo.ClassTime + ' ' + this.courseInfo.BeginClassTime).getTime() + 5184000000 > Date.new().getTime()) {
        return true;
      }
    },

    // 是否显示旷课
    vShowTruant () {
      return this.$utils.getSaaSClientSet(59) == 1;
    }
  },
  mounted () {
    this.$dialog.register(this, ['editCourse', 'studentFile', 'webvChooseCourseware', 'courseAuthQrcode', 'changeAttenList']);
  },
  methods: {
    showColumn (type) {
      this.isshowColumn = type
    },
    updateCourseInfo (ScheduleCourseKey) {
      console.log('updateCourseInfo', ScheduleCourseKey, this.courseInfo);
      if (ScheduleCourseKey == this.courseInfo.ScheduleCourseKey) {
        this.getCourseInfo();
      }
    },
    getCourseInfo (callBack) {
      console.log('isHistory', this.isHistory)
      let isHistory = this.isHistory ? '1' : '0'
      getCourseDetailed(this.scheduleCourseKey || this.courseInfo.ScheduleCourseKey, isHistory).then(
        res => {
          if (res.code == 0) {
            if (res.data.ClassTime) {
              res.data.week = this.$utils.getWeekByTwo(res.data.ClassTime);
            }
            this.courseInfo = res.data;
            // this.courseInfo.TeachingPlan = {
            //   'MainDemoName': '测试132322111',
            //   'HasEvaluating': '1',
            //   'HasAttach': '1',
            //   'HasHomeWork': '1',
            //   'HasStep': '1'
            // };
            console.log('getCourseDetailed', this.courseInfo);
            if (typeof callBack == 'function') {
              callBack(this.courseInfo);
            }
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    // 获取学员排课表
    loadStudentArrangeList (callBack) {
      let isHistory = this.isHistory ? 1 : 0
      getScheduleCourseOnStudentList(this.scheduleCourseKey || this.courseInfo.ScheduleCourseKey, isHistory).then(
        result => {
          this.studentArrangeList = [];
          if (result.data && result.data.length > 0) {
            result.data.forEach(obj => {
              obj.isSelected = false;
              obj.unSelected = false;
              obj.OLAPKey = obj.StudentKey;
              this.studentArrangeList.push(obj);
            });
          }
          if (callBack) {
            callBack();
          }
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 关闭安排考勤
    cancelAndBack () {
      this.$emit('closeDialog');
    },
    // 修改课程.
    editCourseShow () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: this.courseInfo },
        callBack: { afterSucces: () => { this.doAfterEditCourse(); } }

      });
    },
    // 询问是否 恢复上课
    restoreCourse () {
      var that = this;
      layer.confirm('是否恢复上课', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: function () {
              that.setCourseRecovery();
              layer.close();
            }
          }
        ]
      });
    },
    // 恢复上课
    setCourseRecovery () {
      ScheduleCourseRecovery(this.courseInfo.ScheduleCourseKey).then(
        result => {
          this.getCourseInfo();
          layer.alert('恢复课成功');
          this.$bus.emit('AfterBatchEditCourses');
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 询问是否停课
    courseSuspend () {
      let that = this;
      layer.confirm('是否停课', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.setCourseCancel(1);
            layer.close();
          }
        }]
      });
    },
    // 停课
    setCourseCancel (IsNotEndKey) {
      if (!this.courseInfo.ScheduleCourseKey) {
        return false;
      }
      PauseScheduleCourse(this, this.courseInfo.ScheduleCourseKey, IsNotEndKey).then(result => {
        layer.alert('停课成功');
        this.$bus.emit('AfterBatchEditCourses');

        this.cancelAndBack();
      }, error => {
        if (error.code == 70001) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setCourseCancel(0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      });
    },
    // 关闭 修改课程
    closeEditCourse () {
      this.isShowEditCourse = false;
      this.$nextTick(() => {
        this.isShowEditCourseDialog = false;
      });
    },
    // 修改课后
    doAfterEditCourse () {
      // this.closeEditCourse();
      this.getCourseInfo();
      this.$bus.emit('afterArrangeScheduleCourse', this.courseInfo.ScheduleCourseKey, this.courseInfo.StudentKey);
    },
    // 批量考勤
    clickBatchAttendance (type) {
      this.$refs.studentListRef.bantchType = type || 3;
      this.isBatchAttendance = true;
      this.$nextTick(() => {
        this.$refs.studentListRef.$refs.selectCourseStudentList.scrollTop = this.$refs.studentListRef.$refs.selectCourseStudentList.firstChild.clientHeight;
      });
    },
    clickAtten () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'changeAttenList',
        data: { ScheduleCourseKey: this.courseInfo.ScheduleCourseKey },
        callBack: { afterSuccess: () => { this.$emit('updateCourseInfo', this.courseInfo.ScheduleCourseKey); } }

      });
    },
    // 关闭批量考勤
    cancelBatchAttendance () {
      this.studentArrangeList.forEach(obj => {
        obj.isSelected = false;
        obj.unSelected = false;
      });
      this.isBatchAttendance = false;
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'AfterReleaseEssay', scheduleCourseKey => {
        console.log('AfterReleaseEssay', scheduleCourseKey, this.courseInfo.ScheduleCourseKey);
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo();
          this.loadStudentArrangeList();
        }
      });
      this.$bus.on(this, 'AfterBatchAttendance', scheduleCourseKey => {
        console.log('AfterBatchAttendance', scheduleCourseKey, this.courseInfo.ScheduleCourseKey);
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo();
          this.loadStudentArrangeList();
        }
      });
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo();
          this.loadStudentArrangeList();
        }
      });
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo();
          this.loadStudentArrangeList();
        }
      });
      this.$bus.on(this, 'afterArrangeScheduleCourse', (scheduleCourseKey, studentKey) => {
        console.log('afterArrangeScheduleCourse');
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo();
        }
      });
    }

  }
};
</script>

<style scoped>
/* 快速签到 */
.quick_signIn {
  float: right;
}
.course_student_attendance_title .list_column,
.course_student_attendance_title .list_row {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  background: #f5f5f5;
  text-align: center;
  line-height: 23px;
  cursor: pointer;
}
.course_student_attendance_title .list_column.opt,
.course_student_attendance_title .list_row.opt {
  background: #d9d9d9;
}
.course_student_attendance_title .list_column:hover,
.course_student_attendance_title .list_row:hover {
  /* background: #3498db; */
}
</style>

