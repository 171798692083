<template>
<!-- 领券记录 -->
  <div class="payment_statistics_bottom clearfix">
    <div class="payment_statistics_bottom_select flex">
      <!-- 日期 -->
      <div>
        <date-range ref="monthPicker"
                    :fromTitle="'领取时间'"
                    :searchObj="searchObj"
                    @changeDate="changeSaveDate"></date-range>
      </div>
      <!-- 优惠券来源 -->
      <div class="table_select_box">
        <div class="table_select_title">优惠券来源</div>
        <el-select style="width: 224px"
                   :value="vSourceValue"
                   filterable
                   placeholder="必选"
                   @change="selectedSource"
                   value-key="key">
          <el-option v-for="item in sourceList"
                     :key="item.key"
                     :label="item.name"
                     :value="item"></el-option>
        </el-select>
      </div>
    </div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="course_statistics_search"
           style="width: 84.9%;">
        <div class="table_select_box">
          <div class="table_select_title">使用状态</div>
          <el-select style="width: 240px"
                     :value="vStatusValue"
                     filterable
                     placeholder="必选"
                     @change="selectedStatus"
                     value-key="key">
            <el-option v-for="item in statusList"
                       :key="item.key"
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>
        <input-search-contain-btn v-model="searchValue"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
    </div>
    <div v-if="isLoading">
      <div class="table_list_content summarizing_list">
        <div class="single_result_detail">
          <table-list ref="tableListRef"
                      :tableData="dataReportList"
                      :tableColumns="tableCourseColumns"
                      :rowKey="'Number'"
                      :totalNum="totalNum"
                      :footerContent="'共'+totalNum+'条数据'"
                      :defaultSort="defaultSort"
                      :queryParams="searchObj"
                      @loadTableData="getDataList"></table-list>
        </div>
      </div>
    </div>
    <div v-else
         class="loading_fixed_box"
         style="height: 600px;">
      <div class="loading_fixed_gray">
        <span class="loading_style">加载中...</span>
      </div>
    </div>
  </div>
</template>
<script>
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
import { MessageSearchCouponReceiveRecord, ExpiredCouponNotification } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchValue: '', // 搜索内容，防止双向绑定联动
      dataReportList: [], // 记录数据列表
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        source: 0, // 领取来源：1.微课堂，2.客户档案送券，3.分享领取 4:手机掌柜赠券,5扫码关注领取,6微课堂(会员列表)赠送,7家长分销
        status: 0, // 状态0-全部 1：未使用2已使用3.已过期
        QueryID: ''

      },
      // 优惠券来源(1.微课堂，2.客户档案送券，3.分享领取 4:手机掌柜赠券,5扫码关注领取,6微课堂(会员列表)赠送,7家长分销)
      sourceList: [
        { name: '全部', key: 0 },
        { name: '微课堂', key: 1 },
        { name: '客户档案送券', key: 2 },
        { name: '分享领取', key: 3 },
        { name: '手机掌柜赠券', key: 4 },
        { name: '扫码关注领取', key: 5 },
        { name: '微课堂会员赠送', key: 6 },
        { name: '家长分销', key: 7 }
      ],
      // 使用状态状态1：未使用 2 已使用 3.已过期
      statusList: [
        { name: '全部', key: 0 },
        { name: '未使用', key: 1 },
        { name: '已使用', key: 2 },
        { name: '已过期', key: 3 }
      ],
      isLoading: false,
      totalNum: 0,
      tableKey: '0',
      defaultSort: { prop: '', order: 'ascending' },
      // 明细总览 table 列
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'text-item',
          sortable: false,
          width: 68
        },
        {
          label: '领取人',
          prop: 'telephoneAddNameText',
          type: 'text-item',
          sortable: false
        },
        {
          label: '优惠券来源',
          prop: 'sourceText',
          type: 'text-item',
          width: 180
        },
        {
          label: '领取时间',
          prop: 'add_time',
          type: 'text-item',
          width: 140
        },
        {
          label: '使用状态',
          prop: 'statusText',
          type: 'text-item',
          width: 150,
          extend: {
            setClassName: (rowData) => {
              if (rowData.id && rowData.status == 1) {
                return 'circle_red';
              } else if (rowData.id && rowData.status == 2) {
                return 'circle_green';
              } else if (rowData.id && rowData.status == 3) {
                return 'circle_yellow';
              }
            }
          }
        },
        {
          label: '推送时间',
          prop: 'push_time',
          type: 'text-item',
          width: 150
        },
        {
          label: '过期提醒',
          prop: 'warnText',
          type: 'text-item',
          width: 150,
          extend: {
            click: (rowData) => { this.sendMessage(rowData); }

          }
        }
      ]

    };
  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  props: {
    couponInfo: Object
  },
  created () {
    this.getDataList();
    console.log(this.searchObj, 'created-123');
  },
  computed: {
    vSourceValue () {
      return this.showSourceText(this.searchObj.source);
    },
    vStatusValue () {
      return this.showStatusText(this.searchObj.status);
    }
  },
  mounted () {

  },
  methods: {
    //  根据日期搜索
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      // this.searchObj.pageIndex = 0;
      // this.getDataList();
    },
    // 根据来源搜索
    selectedSource (obj) {
      console.log(obj, '选中课程');
      this.searchObj.source = obj.key;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 根据状态搜索
    selectedStatus (obj) {
      console.log(obj, '选中课程');
      this.searchObj.status = obj.key;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 搜索文本
    search () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = this.searchValue;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchValue = '';
      this.searchObj.searchText = this.searchValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.source = 0;
      this.searchObj.status = 0;
      this.getDataList();
    },
    // 返回符合条件的, 当前页的指定条数学生数据
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      this.searchObj.QueryID = this.couponInfo.id;
      MessageSearchCouponReceiveRecord(this.searchObj).then(result => {
        this.isLoading = true;
        this.totalNum = result.data.Total;
        result.data.PageDataList.forEach(o => {
          o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          o.telephoneAddNameText = this.$utils.phoneModulePower(o.telephone) + '( ' + o.username + ' )';
          o.sourceText = this.showSourceText(o.source);
          o.statusText = this.showStatusText(o.status);
          if (!o.add_time) {
            o.add_time = '未设置';
          }
          if (!o.push_time) {
            o.push_time = '未设置';
          }
          if (Number(o.status) == 1) {
            o.warnText = '推送消息';
          }
        });
        this.dataReportList = result.data.PageDataList;
        console.log(result.data.PageDataList, '指定条数学生数据');
      }, error => {
        layer.alert(error.msg);
      });
    },
    showSourceText (type) {
      switch (Number(type)) {
        case 0:
          return '全部';
        case 1:
          return '微课堂';
        case 2:
          return '客户档案送券';
        case 3:
          return '分享领取';
        case 4:
          return '手机掌柜赠券';
        case 5:
          return '扫码关注领取';
        case 6:
          return '微课堂会员赠送';
        case 7:
          return '家长分销';
        default:
          break;
      }
    },
    showStatusText (type) {
      switch (Number(type)) {
        case 0:
          return '全部';
        case 1:
          return '未使用';
        case 2:
          return '已使用';
        case 3:
          return '已过期';
        default:
          break;
      }
    },
    // 推送消息
    sendMessage (rowData) {
      ExpiredCouponNotification(rowData.id).then(result => {
        console.log(result.data, '推送成功');
        if (result.code == 0 && result.data.errcode && result.data.errcode > 0) {
          layer.alert(result.data.errtip);
        } else {
          this.getDataList();
          layer.alert('推送成功');
        }
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>