<template>
  <div :id="echartID"
       :class="[vClass]"></div>
</template>
<script>
export default {
  name: 'circleEchart',
  data () {
    return {
      chartItem: {
        resize: ''
      },
      echart: null
    };
  },
  props: {
    echartID: String,
    nowDataList: Array,      // 当前
    echartClass: String,
    tipstitle: String

  },
  computed: {
    vClass () {
      if (this.echartClass) {
        return this.echartClass;
      } else {
        return 'echarts_m';
      }
    }
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.initEchartCircle();
    },
    lineHide (opt) {
      opt.data.forEach(item => {
        console.log(item.value, 'lineHide');
        if (item.value == 0) {
          item.itemStyle.normal.labelLine.show = false;
          item.itemStyle.normal.label.show = false;
        }
      });
    },

    // 柱形图初始.
    initEchartCircle () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      // 指定图表的配置项和数据
      let _this = this;
      var option = {
        tooltip: {
          trigger: 'item',                        // 触发类型,'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。 'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          triggerOn: 'mousemove',                  // 提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
          showContent: false,                       // 是否显示提示框浮层
          alwaysShowContent: false,                 // 是否永远显示提示框内容
          showDelay: 0,                            // 浮层显示的延迟，单位为 ms
          hideDelay: 100,                          // 浮层隐藏的延迟，单位为 ms
          enterable: true,                        // 鼠标是否可进入提示框浮层中
          confine: true,                          // 是否将 tooltip 框限制在图表的区域内
          // transitionDuration: 0.4,                 // 提示框浮层的移动动画过渡时间，单位是 s,设置为 0 的时候会紧跟着鼠标移动
          // position: ['50%', '50%'],                // 提示框浮层的位置，默认不设置时位置会跟随鼠标的位置,[10, 10],回掉函数，inside鼠标所在图形的内部中心位置，top、left、bottom、right鼠标所在图形上侧，左侧，下侧，右侧，
          formatter: function (parmas) {
            // console.log(parmas.name, 'formatter');
            let text = '';
            let value = '';
            if (parmas.name.indexOf('新生') > -1) {
              text = '新生报名金额';
              value = parmas.name.replace('新生', _this.tipstitle);
            } else if (parmas.name.indexOf('续费') > -1) {
              text = '续费报名金额';
              value = parmas.name.replace('续费', _this.tipstitle);
            }
            if ((parmas.name.indexOf('班内学生数量') > -1) || parmas.name.indexOf('班级满编人数') > -1) {
              text = parmas.name;
              value = parmas.percent;
              return `<span  style="font-weight: 700;color: #333;" >${text}:</span><span  class="" >${value}%</span>`;
            } else {
              return `<span  style="font-weight: 700;color: #333;" >${text}</span>` + '<br/>' + `<span  class="" >${value}</span>`;
            }
          }, // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}，分别表示系列名，数据名，数据值等
          backgroundColor: '#fff',          // 标题背景色
          borderColor: '#ccc',                     // 边框颜色
          borderWidth: 0,                          // 边框线宽
          padding: [5, 5, 5, 12],                             // 图例内边距，单位px  5  [5, 10]  [5,10,5,10]
          textStyle: { color: '#333' },  // 文本样式
          extraCssText: 'width:200px;box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06),-8px 0px 26px 2px rgba(0, 0, 0, 0.06),-6px 0px 16px 0px rgba(0, 0, 0, 0.06);'
        },

        legend: {
          type: 'scroll',
          orient: 'vertical',
          selectedMode: false, // 取消图例上的点击事件
          itemHeight: 0,  // legend图形大小
          icon: 'circle',   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
          left: 260,
          top: 'middle',
          textStyle: {
            lineHeight: 28
          }
          // data: this.reqList
        },
        series: [
          {
            name: '',     // 设置
            type: 'pie',
            radius: ['62%', '100%'],
            center: ['117px', '50%'],
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center',
              normal: {
                formatter: '{d}%',
                position: 'inside',
                fontSize: '12',
                color: '#fff'
              }
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: '22',
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: false
            },
            data: this.nowDataList
          }
        ]
      };
      console.log(window.chartList);
      // var opt = option.series[0];
      // this.lineHide(opt);
      console.log(option, '使用刚指定的配置项和数据显示图表。');
      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    }

  }
};
</script>

