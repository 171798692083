<template>
  <div class="" style="background: #e8e8f3;width: 100%;height: 100%;">
    <div class="student_management_engineer">
        <left-content :dataList="teacherList"  :seletedID="seletedID" @seletedItem="seletedItemAfter"></left-content>
        <right-content :style="{width:screenWidth+'px'}" v-if="Number(seletedID)==0" @updateTeacherList="getTeacherList" :teacherList="teacherList"></right-content>
        <div v-else style="flex:1;" :style="{width:screenWidth+'px'}">
          <teacher-report-list 
              :seletedItem="seletedItem" 
              ref="teacherReportList"
              >
          </teacher-report-list>
        </div>
      
    </div>
    <div>
      <dialog-factory ref="studentManagementEngineer"
                      :routerName="'studentManagementEngineer'"></dialog-factory>
      <dialog-factory-common ref="studentManagementEngineerFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import { GetTeacherAdviserList, RegenTeacherPerformance } from '../../../API/workbench';
import leftContent from './left-content.vue';
import rightContent from './right-content.vue';
import teacherReportList from './teacher-report-list'
import { Result } from 'element-ui';
export default {
  name: 'studentManagementEngineer',
  data () {
    return {
      generationTime: '',
      seletedID:0,
      seletedItem:null,
      screenWidth:0,
      teacherList:[
      ]
    };
  },
  components: {
    dialogFactory,
    leftContent,
    rightContent,
    teacherReportList

  },
  created () {
    this.getTeacherList()
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'studentManagementEngineer') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'studentManagementEngineer') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });

  },
  mounted(){
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth-166;
        // alert(this.screenWidth, 'this.screenWidth');
        console.log('this.screenWidth', this.screenWidth);
      })();
    };
  },
  methods: {
    getTeacherList(){
        GetTeacherAdviserList().then(result=>{
            console.log('getTeacherList',result.data)
            result.data.forEach(o=>{
              o.ishover=false
            })
            this.teacherList=result.data
        })
    },
    seletedItemAfter(id,item){
        console.log("seletedItem",id,item)
        this.seletedID=id
        this.seletedItem=item
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentManagementEngineer) {
        this.$refs.studentManagementEngineer.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentManagementEngineerFactoryCommon) {
        this.$refs.studentManagementEngineerFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
 
 
  }
};
</script>
<style scoped>
.student_management_engineer{
    /* width: 1360px; */
    min-height: 880px;
    margin:  0px 20px !important;
    height: 100%;
    background: white;
    /* margin: auto; */
    /* padding-top: 20px; */
    display: flex;
    flex-direction: row;
}
@media screen and (min-width: 1921px) {
  .student_management_engineer {
      max-width: 1920px;
      margin: auto !important;
 
   }
 }
</style>
