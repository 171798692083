<template>
  <div class="attendance_course_info_ul">
    <div v-for="(item,index) in dataInfoList"
         :key="index"
         class="attendance_course_info_list">
      <div class="list_left">
        <div class="list_date">{{item.StageKeyValue}}</div>
        <div class="list_time">{{item.PublishTime}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { PublishRecord } from '../../API/workbench';

export default {
  data () {
    return {
      dataInfoList: []
    };
  },
  props: {
    selectInfo: Object
  },
  created () {
    this.getDataInfo();
  },
  methods: {
    getDataInfo () {
      console.log(this.selectInfo);
      PublishRecord(this.selectInfo.MiniProgramAppID).then(
        (res) => {
          this.dataInfoList = res.data;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>
<style scoped>
.attendance_course_info_list {
  display: flex;
  padding: 16px 0;
  min-height: 70px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  cursor: pointer;
}
.attendance_course_info_list .list_right {
  display: flex;
  padding-right: 18px;
  background: url(../../../public/image/next_icon@2x.png) no-repeat right center;
  background-size: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
}
.attendance_course_info_list .list_date {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
}
.attendance_course_info_list .list_time {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}
.attendance_course_info_list .list_type {
  width: 39px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 9px;
  font-size: 12px;
  margin-right: 5px;
}
.attendance_course_info_list .list_type.type_green {
  background-color: #ddf8eb;
  color: #1ed07a;
}
.attendance_course_info_list .list_type.type_yellow {
  background-color: #fff2c7;
  color: #ff8c39;
}
.attendance_course_info_list .list_type.type_red {
  color: #ff7527;
  border-color: #ffdfc7;
  background-color: #ffdfc7;
}
.attendance_course_info_list .list_type.type_pink {
  color: #ff6c67;
  background-color: #fcedf0;
}
.attendance_course_info_list .list_number {
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  font-weight: bold;
  font-family: Arial;
  min-width: 35px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>