<template>
  <!-- 优化组件 -->
  <!-- @click.stop="selectedItem(tickItem)" -->
  <!-- <div class="apply_course_search_list" @click.stop="selectedItem(tickItem)">
    <div class="list_class">
      套餐
    </div>
    <div class="list_name">
      {{vTaoCanKeyValue}}
    </div>
    <div class="clearfix">
      <div class="list_left fl">
        <div class="line_clamp_1 border_r">
          {{tickItem.StudentKeyValue}} {{tickItem.CustomerPhoneName}}
        </div>
        <div class="line_clamp_1 border_r">
          <span class="font_gray">购买日期</span> {{vGenerateTime}}
        </div>
      </div>
      <div class="list_right fl">
        <div class=" line_clamp_1">
          <span class="font_gray">适用</span> | {{tickItem.ApplyCorName}}
        </div>
        <div class="list_right_dual line_clamp_1">
          <div class="line_clamp_1 border_r fl">
            <span class="font_gray">购买课时</span> {{tickItem.NumCount}}
          </div>
          <div class="padding_l line_clamp_1 font_red fl">
            <span class="font_gray">待报课</span> {{vCount}}
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="apply_course_details_list button"
       style="border-bottom:1px solid #ececec"
       @click.stop="selectedItem(tickItem)">
    <div class="list_left">
      <div class="list_left_range">
        <span class="font_black"
              style="font-size:18px">{{tickItem.StudentKeyValue}}</span>
        {{$utils.phoneModulePower(tickItem.CustomerPhoneName)||'无手机号'}}
      </div>
      <div class="list_left_range">
        <span class="font_black">适用:</span>
        {{tickItem.ApplyCorName}}
      </div>
      <div class="list_left_course">
        <span class="font_black">{{vGenerateTime}}</span> 购买 <span class="font_black">{{Number(tickItem.NumCount)}}</span> 课时 | 消耗<span class="font_black"> {{Number(tickItem.expendCount)||Number(tickItem.Sum_SpendCount)||0}} </span>课时
        <!-- 剩余 <span class="font_black">{{Number(tickItem.PeriodCount)}}</span> 天 <span class="font_black">{{Number(tickItem.CurrentCount)}}</span> 课时 -->
      </div>
      <div class="list_left_course">
        可用<span class="font_black"> {{Number(tickItem.CurrentCount)}}</span> 课时 | 当前报课<span class="font_blue"> {{Number(tickItem.HasBingCount)||Number(tickItem.Sum_CurrentCount)||0}} </span>课时
      </div>
    </div>
    <div class="list_right"
         v-if="vCount">
      <div class="font_block">待报课时</div>
      <div class="font_red font_big">{{Number(vCount)}}</div>

    </div>
    <div class="list_right"
         v-else-if="Number(tickItem.Sum_SuperQuote)>0">
      <div class="font_block">超报课</div>
      <div class="font_red font_big">{{Number(tickItem.Sum_SuperQuote)}}</div>

    </div>
    <div class="list_right"
         v-else>
      <div class="font_block">待报课时</div>
      <div class="font_green font_big">0</div>

    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {
      vTestStudentItem: ''
    };
  },
  props: ['tickItem'], // 单选数据项 需绑定到界面
  created () {
    this.vTestStudentItem = this.tickItem;
  },
  watch: {
    tickItem () {
      this.vTestStudentItem = this.tickItem;
    }
  },
  computed: {
    vGenerateTime () {
      if (this.tickItem.GenerateTime) {
        return this.tickItem.GenerateTime.substring(0, 10);
      }
    },
    vTaoCanKeyValue () {
      if (this.tickItem && Number(this.tickItem.TaoCanKey) > 0) {
        return this.tickItem.TaoCanKeyValue;
      } else {
        return '不使用';
      }
    },
    vCount () {
      if (Number(this.tickItem.CurrentCount) < Number(this.tickItem.ToBeArranged)) {
        return this.$utils.saveHowMuchDecimal(Number(this.tickItem.CurrentCount), 1);
      } else {
        return this.$utils.saveHowMuchDecimal(Number(this.tickItem.ToBeArranged), 1);
      }
    }
  },
  methods: {
    selectedItem (item) {
      item.isActive = true;
      this.$emit('selectedItem', item);
    }
  }
};
</script>

