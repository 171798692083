<template>
  <div style="height:100%;overflow-y:auto;background: white;">
    <div v-if="circleCourseItemData">
      <course-info :courseInfo="circleCourseItemData"
                   :sumToBeArranged="sumToBeArranged"></course-info>
      <div class="couser_date_arrange_box_ul">
        <div v-if="scheduleCourseList.length>0">
          <year-item v-for="yearInfo in dateGroupByYear"
                     :key="yearInfo.yearName"
                     :courseItem="circleCourseItemData"
                     :yearInfo="yearInfo"></year-item>
        </div>
      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import courseInfo from './course-info';
import yearItem from './year-item';
import {
  CancelStudent2MutiCourse,
  ArrangeStudent2MutiSameApplyClassCourse, GetCourseAndStudentCourseArrangByRule,
  SearchStructedRule, GetTheStudentTicketPlanListByClassType,
  GetTicketPlanForGradeApplyClass
} from '../../../../API/workbench';

export default {
  data () {
    return {
      dateGroupByYear: [],
      cancelStudentArr: [],
      arrangeStudentArr: [],
      hasHowMuchMonth: 0,
      isShowMore: true,
      scheduleCourseList: [],
      circleCourseItemData: null,
      sumToBeArranged: 0,
      params: {
        StudentKey: 0,
        IsClassHourLackKey: 1,
        ScheduleCourseList: []
      },
      studentKey: 0,
      courseNameKey: 0,
      classGradeKey: 0
    };
  },
  props: {
    paramsInfo: {
      type: Object,
      default: {
        studentKey: String,
        courseNameKey: String,
        classGradeKey: String,
        ruleID: String
      }
    }
  },
  components: {
    courseInfo,
    yearItem
  },
  created () {
    this.studentKey = this.paramsInfo.studentKey;
    this.courseNameKey = this.paramsInfo.courseNameKey;
    this.classGradeKey = this.paramsInfo.classGradeKey;
    this.loadStructedRule();
    if (this.courseNameKey) {
      this.getTheStudentTicketPlanListByClassType();
    } else if (this.classGradeKey) {
      this.getTicketPlanForGradeApplyClass();
    }
  },
  methods: {
    // 获取排课规律课块
    loadStructedRule (type) {
      SearchStructedRule(this.courseNameKey, this.classGradeKey).then(result => {
        let circleCourseItem = result.data.find(obj => { return obj.StructuredRuleKey == this.paramsInfo.ruleID; });
        circleCourseItem.alreadyAttenCount = 0;
        this.circleCourseItemData = circleCourseItem;
        this.initDateGroup();
        this.getCourseAndStudentCourseArrangByRule();
      });
    },
    // 获取此学生指定课名所有报课计划
    getTheStudentTicketPlanListByClassType () {
      GetTheStudentTicketPlanListByClassType(this.studentKey, this.courseNameKey).then(result => {
        this.sumToBeArranged = result.data.Sum_ToBeArranged;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取此学生指定班级所有报课计划
    getTicketPlanForGradeApplyClass () {
      GetTicketPlanForGradeApplyClass(this.studentKey, this.classGradeKey).then(result => {
        this.sumToBeArranged = result.data.Sum_ToBeArranged;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 查询 根据学生ID 排课规律ID 查所有的排课信息
    getCourseAndStudentCourseArrangByRule () {
      GetCourseAndStudentCourseArrangByRule(this.studentKey, this.circleCourseItemData.StructuredRuleKey).then(result => {
        if (result.code == 0) {
          this.scheduleCourseList = result.data.ScheduleCourseList;
          this.scheduleCourseList.forEach(obj => {
            if (obj.ArrangeID > 0) {
              this.circleCourseItemData.alreadyAttenCount += 1;
            }
          });
          this.setDateByScheduleCourseList();
        }
      });
    },
    // 根据课里面 最后一节课日期 周几 把所有日期都构建出来
    initDateGroup () {
      // 默认显示6个月
      // 根据查出来的课 显示是否已排 是否可排
      let curDate = Date.new();
      // 当月第一天
      let startDate = this.$utils.formatDateToLine(Date.new(curDate.getFullYear(), curDate.getMonth(), 1));
      // 最后一节课日期
      let lastClassTime = this.$utils.getFormatDateToQuery(this.circleCourseItemData.LastClassTime);
      // 最后一节课 日期 (月的最后一天)
      let endDate = this.$utils.formatDateToLine(Date.new(lastClassTime.getFullYear(), lastClassTime.getMonth() + 1, 0));
      // 当月第一天 到 最后一节课 日期 (月的最后一天) 指定周几的 所有日期
      let allDateArr = this.$utils.getDateArrForOneDate(startDate, endDate, this.circleCourseItemData.WeekKey == 7 ? 0 : this.circleCourseItemData.WeekKey);
      this.hasHowMuchMonth = 0;
      // 今天至最后一个上课日期有几年 包含当年(算一年)
      let hasHowMuchYear = Date.new(endDate).getFullYear() - curDate.getFullYear() + 1;
      this.dateGroupByYear = [];
      for (let i = 0; i < hasHowMuchYear; i++) {
        // 年
        let year = curDate.getFullYear() + i;
        let yearObj = { yearName: year, weekKey: this.circleCourseItemData.WeekKey, weekKeyValue: this.circleCourseItemData.WeekKeyValue, hasCreatedNum: 0, monthArr: [] };
        allDateArr.forEach(dateTime => {
          if (Date.new(dateTime).getFullYear() == year) {
            let month = Date.new(dateTime).getMonth() + 1;
            month = month > 9 ? month : '0' + month;
            let date = Date.new(dateTime).getDate();
            date = date > 9 ? date : '0' + date;
            let hasExistsMonthIndex = yearObj.monthArr.findIndex(o => {
              return o.monthName == month;
            });
            let dateObj = {
              dateName: date,
              unArranged: false,
              hasArranged: false,
              hasSelected: false,
              cancelArranged: false
            };
            // 不存在 月 构建月份
            if (hasExistsMonthIndex == -1) {
              this.hasHowMuchMonth += 1;
              yearObj.monthArr.push({
                monthName: month,
                dayArr: [dateObj]
                // isShow: this.hasHowMuchMonth < 7
              });
              // 存在 添加日
            } else {
              yearObj.monthArr[hasExistsMonthIndex].dayArr.push(dateObj);
            }
          }
        });
        this.dateGroupByYear.push(yearObj);
      }
    },
    // 根据构建的日期和查回来的课 初始化日期
    setDateByScheduleCourseList () {
      this.dateGroupByYear.forEach(yearObj => {
        yearObj.monthArr.forEach(monthObj => {
          monthObj.dayArr.forEach(item => {
            let classDate = yearObj.yearName + '-' + monthObj.monthName + '-' + item.dateName;
            let findItemArr = this.scheduleCourseList.filter(o => o.ClassTime.slice(0, 10) == classDate);
            // 若存在 有课
            if (findItemArr.length > 0) {
              if (findItemArr.length == 1) {
                item.OLAPKey = findItemArr[0].OLAPKey;
              }
              findItemArr.forEach(obj => {
                if (obj.ArrangeID > 0) {
                  this.$set(obj, 'hasArranged', true);
                } else {
                  this.$set(obj, 'hasArranged', false);
                }
                this.$set(obj, 'cancelArranged', false);
                this.$set(obj, 'hasSelected', false);
                yearObj.hasCreatedNum += 1;
              });
              item.courseList = findItemArr;
              this.$set(item, 'courseList', findItemArr);
              // 不存在 没课
            } else {
              item.courseList = [];
              item.unArranged = true;
            }
          });
        });
      });
    },
    // 构建安排情况
    caculArrangedList (item) {
      this.cancelStudentArr = [];
      this.arrangeStudentArr = [];
      let functionEvent = (item) => {
        // 已安排 且 取消了
        if (item.hasArranged && item.cancelArranged) {
          this.cancelStudentArr.push({
            OLAPKey: item.OLAPKey,
            CourseNameKey: this.circleCourseItemData.CourseNameKey
          });
        } else if (item.hasSelected) {
          this.arrangeStudentArr.push({
            OLAPKey: item.OLAPKey,
            CourseNameKey: this.circleCourseItemData.CourseNameKey
          });
        }
      };
      this.dateGroupByYear.forEach(yearObj => {
        yearObj.monthArr.forEach(monthObj => {
          monthObj.dayArr.forEach(item => {
            if (item.courseList && item.courseList.length > 0) {
              item.courseList.forEach(course => {
                functionEvent(course);
              });
            }
          });
        });
      });
    },
    // 单学生多课移除排课
    cancelStudent2MutiCourse (callBack) {
      let submitParams = this.$utils.parseJson(this.params);
      submitParams.ScheduleCourseList = this.cancelStudentArr;
      CancelStudent2MutiCourse(submitParams)
        .then(result => {
          if (result.code == 0) {
            if (this.arrangeStudentArr.length > 0) {
              this.arrangeStudent2MutiSameApplyClassCourse(callBack);
            } else {
              layer.alert('排出成功');
              this.$bus.emit('afterArrangeScheduleCourse', this.cancelStudentArr, []);
              this.cancelClick(callBack);
            }
          }
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 单学生多课加入排课[同课名]
    arrangeStudent2MutiSameApplyClassCourse (callBack) {
      let submitParams = this.$utils.parseJson(this.params);
      submitParams.ScheduleCourseList = this.arrangeStudentArr;
      ArrangeStudent2MutiSameApplyClassCourse(submitParams).then(result => {
        if (result.code == 0) {
          layer.alert('排课成功');
          this.$bus.emit('afterArrangeScheduleCourse', this.cancelStudentArr, this.arrangeStudentArr);
          this.cancelClick(callBack);
        }
      }).catch(error => {
        if (typeof callBack == 'function') {
          callBack();
        }
        layer.alert(error.msg);
      });
    },
    confirmClick (callBack) {
      this.caculArrangedList();
      this.params.StudentKey = this.studentKey;
      if (this.cancelStudentArr.length > 0) {
        this.cancelStudent2MutiCourse(callBack);
      } else if (this.arrangeStudentArr.length > 0) {
        this.arrangeStudent2MutiSameApplyClassCourse(callBack);
      } else {
        layer.alert('至少选择一节排入或排出');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
    },
    cancelClick (callBack) {
      if (typeof callBack == 'function') {
        callBack();
      }
      this.$emit('cancelClick');
    }
  }
};
</script>
