<template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL">
      <input-number :formTitle="'剩余课时数'"
                    :min="0"
                    v-model="menDianInfo.Set60Content"
                    :decimalPlaces="1"
                    :required="true"></input-number>
      <input-number :formTitle="'剩余天数'"
                    :min="0"
                    v-model="menDianInfo.Set61Content"
                    :decimalPlaces="0"
                    :required="true"></input-number>
      <input-select :formTitle="'预警模式'"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :dataList="optionList"
                    :selectedInfo="vOpenInfo"
                    @selectedItem="chgType"></input-select>
    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'确定'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetSaaSClientMsg, ModifySetting } from '../../../API/workbench.js';
export default {
  data () {
    return {
      menDianInfo: {
        Set60Content: Number(this.$utils.getSaaSClientSetInfo(60).SetContent),
        Set61Content: Number(this.$utils.getSaaSClientSetInfo(61).SetContent),
        Set128Content: this.$utils.getSaaSClientSetInfo(128),
      },
      //   {
      //   MainDemoName: "课时预警-是否开启课单级课时不足",
      //   OLAPKey: "170530",
      //   SaaSClientKey: "10408",
      //   SetContent: "0"
      //   SetKey: "0"
      //   SetKeyValue: "关闭"
      //   SetTypeKey: "128"
      //   SetTypeKeyValue: "是否开启课单级课时不足"}
      // },
      optionList: [
        {
          id:1,
          name: '课单级',
        },
        {
          id: 0,
          name: '学生级',
        },
      ],
    };
  },
  created () {

  },
  computed: {
    vOpenInfo() {
      if(Number(this.menDianInfo.Set128Content.SetKey)==0){
        return{
          id: 0,
          name: '学生级',
        }
      }else{
        return{
          id:1,
          name: '课单级',
        }
      }
    },
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    // 选择加盟方式
    chgType(item) {
      this.menDianInfo.Set128Content.SetKey = item.id
      this.menDianInfo.Set128Content.SetKeyValue = item.name
    },
    updateMendianInfo (data, callBack) {
      ModifySetting(data).then(res => {
        console.log(res, '更新门店设置成功');
        layer.alert('更新成功');
        this.$store.getters.SaaSClientInfo.SaaSClientSetting = this.$utils.setSaaSClientSet(data.SetTypeKey, data);
        this.$emit('onConfirmClick');
        this.$emit('cancelClick');
      }, err => {
        layer.alert(err.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    doAfterChangeFullPeopleCount (val) {
      console.log(val, 'val');
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    confirmClick (callBack) {
      let flag = false;
      if (!this.menDianInfo.Set60Content) {
        flag = true;
        layer.alert('剩余课时数不能小于0');
      } else if (!this.menDianInfo.Set61Content) {
        flag = true;
        layer.alert('剩余天数不能小于0');
      }
      if (flag) {
        if (typeof callBack == 'function') { callBack(); }
        return;
      }
      let item60 = {
        SetKey: '',
        SetKeyValue: '',
        SetContent: this.menDianInfo.Set60Content,
        SetTypeKey: 60
      };
      this.updateMendianInfo(item60, callBack);
      let item61 = {
        SetKey: '',
        SetKeyValue: '',
        SetContent: this.menDianInfo.Set61Content,
        SetTypeKey: 61
      };
      
      this.updateMendianInfo(item61, callBack);
      let item128 = {
        SetKey: this.menDianInfo.Set128Content.SetKey,
        SetKeyValue: this.menDianInfo.Set128Content.SetKeyValue,
        SetContent: '',
        SetTypeKey: 128
      };
       this.updateMendianInfo(item128, callBack);
    }
  }
};
</script>
