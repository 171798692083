<template>
  <div class="form_info_list pr"
       :class="{form_info_required:required,form_info_select:!readonly}">
    <div class="form_info_title">
      {{formTitle}}
    </div>
    <div class="font_gray"
         v-if="formHint">{{formHint}}</div>
    <div class="form_info_content select"
         :style="styleName">
      <input adjust-position="false"
             type="text"
             class="input_box font_black"
             :value="value"
             :placeholder="formPlaceholder"
             disabled="true" />
      <div class="input_box pa input_text_dialog"
           @tap.stop="clickVal"></div>
    </div>
    <div class="list_type"
         v-if="storeName"
         :class="storeClassName"
         @tap.stop="clickVal">
      {{storeName}}
    </div>

  </div>
</template>

<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    styleName: String, // 样式类名
    storeName: String,
    storeClassName: String,
    formHint: { // 输入框左侧文本说明
      type: String,
      default: ''
    },
    ModulePowerKey: {// 权限
      type: Number,
      default: 0
    }
  },
  computed: {},
  created () { },
  methods: {
    clickVal () {
      console.log(this.$store.getters, 'this.$store.getters');
      if (this.$common.CheckModulePower(this.$store.getters.RolePowerList, this.ModulePowerKey)) {
        this.$emit('showEldilog');
      } else {
        this.$common.CheckModulePowerToTips(this.ModulePowerKey);
      }
    }
  }
};
</script>
<style scoped>
.form_info_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  min-height: 51px;
  line-height: 51px;
  border-bottom: 1px solid #ececec;
  font-size: 14px;
  padding: 0px 10px;
}
.form_info_title {
  max-width: 270px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
}
.form_info_content {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding: 0;
  color: #333;
  padding: 0px 10px;
}
.font_gray {
  position: absolute;
  left: 84px;
  top: 12px;
  font-size: 12px;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list_type {
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14px;
  position: absolute;
  right: 20px;
  bottom: 1px;
}
.list_type.type_green {
  background-image: url(../../../../../../public/image/upsell_tikc_green.png);
}

.list_type.type_red {
  background-image: url(../../../../../../public/image/upsell_tikc_red.png);
}
.form_info_content .input_box {
  text-align: left;
  padding: 0;
  color: #333;
  padding: 0px 10px;
}
/* .form_info_list.form_info_select .form_info_content {
    background: url(../../../../../../public/image/next_icon@2x.png) no-repeat right center;
    background-size: 8px;
    height: 51px;
} */
</style>
