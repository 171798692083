<template>
  <!-- 添加购买杂费 -->
  <div style="hieight:100%"
       class="bg_gray clearfix">
    <div class="form_info_edit label_radio_bg_white">
      <div class="mendian_setting_content">
        <input-dialog :formTitle="'杂费名称'"
                      :formPlaceholder="'单选'"
                      :readonly="false"
                      :required="true"
                      @showEldilog="doIncidentalsDialogShow"
                      v-model="newZFItem.ProjectKeyValue"></input-dialog>
        <input-number :min="0"
                      :max='9999'
                      v-model="newZFItem.NumCount"
                      :decimalPlaces="1"
                      :formTitle="'数量'"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="slDoIncidentalsChange"></input-number>
        <input-text :formTitle="'单位'"
                    :formPlaceholder="'必填'"
                    :required="true"
                    v-model="newZFItem.UnitName"></input-text>
        <input-number :min="0"
                      :value="newZFItem.UnitPrice"
                      :decimalPlaces="2"
                      :formTitle="'单价'"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="djDoIncidentalsChange"></input-number>
        <input-dialog :formTitle="'优惠折扣'"
                      :formPlaceholder="'必填'"
                      :readonly="false"
                      :required="true"
                      @showEldilog="isZFShowDiscountDialog=true"
                      :value="vDiscountCount"></input-dialog>
        <input-number :min="0"
                      :max='$utils.mAmountType(newZFItem.ValueAmount>0?newZFItem.ValueAmount:newZFItem.UnitPrice,2)'
                      :value="newZFItem.AfterDisAmount"
                      :decimalPlaces="2"
                      :formTitle="'收费价格'"
                      :placeholder="'必填'"
                      :required="true"
                      :readonly="false"
                      @inputChange="jgDoIncidentalsChange"></input-number>

      </div>
    </div>
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmFromClick"
                             @cancelClick="closeFrom"></save-cancel-btn-group>
    </div>
    <!-- 报课折扣 -->
    <custom-dialog title="折扣"
                   :visible.sync="isZFShowDiscountDialog"
                   :before-close="closeDiscountDialog">
      <form-discount v-model="newZFItem.DiscountCount"
                     @onConfirm="zkDoIncidentalsChange"
                     @onClose="closeDiscountDialog"></form-discount>
    </custom-dialog>
  </div>
</template> 

<script>
import { getIncidentals } from '../../../../../../API/workbench';
export default {
  components: {

  },
  props: {
    fromInfo: Object // 课程信息 （必填）
  },
  data () {
    return {
      isZFShowDiscountDialog: false, // 杂费
      newZFItem: {
        ProjectKey: '', // --杂费;
        ProjectKeyValue: '', // ;
        NumCount: 1, // --数量;;
        UnitName: '', // --单位;
        UnitPrice: 0, // --单价;
        ValueAmount: 0, // --价值;
        DiscountCount: 10, // --折扣;
        DiscountAmount: 0, // --折扣金额;
        AfterDisAmount: 0 // --金额;
      },
      zafeiadd: {
        name: '新增',
        ModulePowerKey: 45
      }
    };
  },
  created () {
    if (this.fromInfo.data && JSON.stringify(this.fromInfo.data) != '{}') { // 修改单课、组合课
      this.newZFItem = this.$utils.parseJson(this.fromInfo.data);
    }
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    vDiscountCount () {
      if (this.newZFItem.DiscountCount == 0) {
        return '赠送';
      } else if (this.newZFItem.DiscountCount == 10) {
        return '原价';
      } else {
        return this.$utils.mAmountType(this.newZFItem.DiscountCount, 1) + ' 折';
      }
    }
  },
  methods: {
    // 选择杂费
    doIncidentalsDialogShow () {
      getIncidentals().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择杂费',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.newZFItem.ProjectKey, dataSourceList: result.data, addModuleName: 'incidentals-setting' },
          callBack: {
            onSelectedItem: (data) => {
              this.doIncidentalsSelectedItem(data);
            }
          }
        });
      });
    },
    // 杂费回填
    slDoIncidentalsChange (val) {
      this.newZFItem.NumCount = Number(val);
      // 1.课时，计算报课价格
      if (this.newZFItem.UnitPrice) {
        console.log(this.newZFItem.UnitPrice, 'this.newZFItem.UnitPrice');
        this.newZFItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newZFItem.NumCount * this.newZFItem.UnitPrice * (this.newZFItem.DiscountCount / 10)), 2);
      }
    },
    djDoIncidentalsChange (val) {
      this.newZFItem.UnitPrice = Number(val);
      // 2.单价，计算报课价格
      if (this.newZFItem.NumCount) {
        this.newZFItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newZFItem.NumCount * this.newZFItem.UnitPrice * (this.newZFItem.DiscountCount / 10)), 2);
      }
    },
    zkDoIncidentalsChange (val) {
      // 限制折扣最大为10折
      if (Number(val) > 10 && this.newZFItem.DiscountCount > 10) {
        this.zkDoIncidentalsChange(10);
        this.newZFItem.DiscountCount = 10;
      } else {
        this.newZFItem.DiscountCount = this.$utils.mAmountType(Number(val), 1);
        // 3.折扣，计算报课价格
        if (this.newZFItem.UnitPrice) {
          this.newZFItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newZFItem.NumCount * this.newZFItem.UnitPrice * (this.newZFItem.DiscountCount / 10)), 2);
        }
      }
      this.closeDiscountDialog();
    },
    // 杂费价格
    jgDoIncidentalsChange (val) {
      if (this.newZFItem.UnitPrice > 0 && this.newZFItem.NumCount > 0) {
        this.newZFItem.ValueAmount = this.$utils.mAmountType(Number(this.newZFItem.UnitPrice) * Number(this.newZFItem.NumCount), 2);
      }
      this.newZFItem.AfterDisAmount = this.$utils.mAmountType(Number(val), 2);
      this.newZFItem.DiscountCount = this.$utils.parseIntType(this.newZFItem.AfterDisAmount / this.newZFItem.ValueAmount * 10, 10);
      if (this.newZFItem.DiscountCount > 10 || isNaN(this.newZFItem.DiscountCount)) {
        this.newZFItem.DiscountCount = 10;
      }
    },
    // 关闭折扣弹出层
    closeDiscountDialog (val) {
      this.isZFShowDiscountDialog = false;
    },
    // 选择杂费
    doIncidentalsSelectedItem (item) {
      console.log(item);
      this.newZFItem.UnitPrice = Number(item.UnitPrice);
      this.newZFItem.UnitName = item.UnitName;
      this.newZFItem.ProjectKey = item.OLAPKey;
      this.newZFItem.ProjectKeyValue = item.MainDemoName;
      // this.newZFItem.NumCount = 1;
      this.newZFItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newZFItem.NumCount * this.newZFItem.UnitPrice * (this.newZFItem.DiscountCount / 10)), 2);
      this.newZFItem.ValueAmount = this.newZFItem.UnitPrice;
    },
    // 提交杂费项
    confirmFromClick (callBack) {
      let flag = false;
      this.newZFItem.ValueAmount = this.$utils.mAmountType(Number(this.newZFItem.UnitPrice) * Number(this.newZFItem.NumCount), 2);
      if (this.newZFItem.ValueAmount < this.newZFItem.AfterDisAmount) { // 防止小数的回填计算存在差异问题
        this.newZFItem.ValueAmount = this.newZFItem.AfterDisAmount;
      }
      this.newZFItem.DiscountAmount = this.$utils.mAmountType(Number(this.newZFItem.ValueAmount) - Number(this.newZFItem.AfterDisAmount), 2);

      if (!this.newZFItem.ProjectKeyValue) {
        layer.alert('请选择杂费项');
        flag = true;
      } else if (!this.newZFItem.NumCount || this.newZFItem.NumCount == 0) {
        layer.alert('数量必须大于0');
        flag = true;
      } else if (!this.newZFItem.UnitPrice || this.newZFItem.UnitPrice <= 0) {
        layer.alert('单价必须大于0');
        flag = true;
      } else if (!this.newZFItem.UnitName) {
        layer.alert('请输入单位');
        flag = true;
      } else if (
        !this.newZFItem.DiscountCount &&
        this.newZFItem.DiscountCount !== 0
      ) {
        layer.alert('请输入折扣');
        flag = true;
      } else if (this.newZFItem.AfterDisAmount > 1000000) {
        layer.alert('基数过大，杂费价格不能超过一百万!');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.$emit('doConfirmFrom', this.fromInfo.type, this.fromInfo.index, this.newZFItem);
    },
    // 返回
    closeFrom () {
      this.$emit('doCloseFrom');
    },
    // 显示新增项
    doAddItmeCloseEvent (boo) {
      console.log(boo, '显示新增项');
    },
    // 新增回调
    doAddSuccessEvent (result) {
      // 接收选中值.
      console.log(result, '新增回调');
    }
  }
};
</script>