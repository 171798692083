<template>
  <!-- 右侧弹出层 -->
  <div class="scroll_type">
    <div class="spring_frame">
      <div class="spring_frame_header">
        <div class="spring_frame_header_title">{{ title }}</div>
        <div class="spring_frame_header_back">
          <i
            class="spring_frame_header_icon button"
            @click.stop="closePopup"
          ></i>
        </div>
      </div>
      <div class="spring_frame_hr"></div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
  },
};
</script>