<template>
  <div>
    <!-- <module-description style="padding-right:0"
                        :textTips="'说明文字'"></module-description> -->
    <div class="popup_title_padding view_title_flex">
      <div class="mendian_backstage_search" style="flex-direction: row-reverse;">
        <div class="btn_hover_bg_blue search_btn"
          style="margin: 0;width: 108px;"
          @click.stop="addItem">
          添加老师授权
        </div>
      </div>
    </div>
    <div class="table_list_content summarizing_list"
         style="padding-bottom: 40px">
      <table-list ref="tableListRef"
                  :tableData="vdataReportList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :queryParams="params"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import { CWSGetCWPTeacherAuthList,WEBVGetCWPTeacherAuthList } from '../../../../../API/workbench';
import tableList from '../../../../common/table-list/index';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '老师名',
          prop: 'TeacherKeyValue',
          align: 'left',
          width: 120,
          type: 'text-item',
          sortable: false
        },
        {
          label: '授权时间',
          prop: 'Authtime',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '授权内容',
          prop: 'AuthContent',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'hidden-btn',
          // fixed: 'right',
          // width: 150,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '变更授权',
                extend: {
                  isHide: (rowData) => {
                    return rowData.TeacherKey == 0;
                  },
                  click: (rowData) => { 
                    this.changeAuth(rowData)
                  },
                  ModulePowerKey: ''
                }
              },
            ]
          }
        }
      ],
      totalNum: 0,
      dataReportList: [],
      seletedItem:null,
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc' //	字符串	可选		数据源：GET	排序顺序
      }
    };
  },
  components: {
    tableList
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          BigCoursewarePackageKey: '',
          BigCoursewarePackageKeyValue:''
        };
      }
    }
  },
  created () {
    console.log('createddataInfo', this.dataInfo);
    this.getDataList(this.dataInfo.BigCoursewarePackageKey);
  },
  computed:{
    vdataReportList() {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.AuthContent=o.TypeKey==1?'全部':o.AuthCount+'/'+o.CoursewarePackageCount
        newArr.push(o);
      });
      return newArr;
    },
     isCWS(){      //是否为课件门店
      return this.$store.getters.SaaSClientEditionKey==6
    }
  },
  mounted () {
    this.$dialog.register(this,
      [
        'cwsTeacherAuthCw'
      ]);
  },
  methods: {
    addItem(){
      this.seletedItem=null
         this.openTeacherAuthForm()
    },
    changeAuth(item){
      this.seletedItem=item
      this.openTeacherAuthForm()
    },
    openTeacherAuthForm(){
      this.$dialog.open(this, {
        name: '授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthCw',
        data: { dataInfo:{
            BigCoursewarePackageKey:this.dataInfo.BigCoursewarePackageKey,
            BigCoursewarePackageKeyValue:this.dataInfo.BigCoursewarePackageKeyValue,
            TeacherKey:this.seletedItem?this.seletedItem.TeacherKey:0,
            TeacherKeyValue:this.seletedItem?this.seletedItem.TeacherKeyValue:'',
          } 
        },
        callBack: {
                afterSuccess: (data) => { this.getDataList(); this.$emit("afterSuccess")}
              }
      });
    },
    // 分页查询校区端口申请记录
    getDataList () {
      let fn=  this.isCWS?CWSGetCWPTeacherAuthList:WEBVGetCWPTeacherAuthList
      fn(this.dataInfo.BigCoursewarePackageKey).then(result => {
        this.totalNum = result.data.length;
        this.dataReportList = result.data;
    
        console.log('CWSSearchAuthorizedDeviceList', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
   
  }
};
</script>
