import { render, staticRenderFns } from "./work-course-head-box.vue?vue&type=template&id=5292c66a"
import script from "./work-course-head-box.vue?vue&type=script&lang=js"
export * from "./work-course-head-box.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports