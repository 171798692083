<template>
  <div @click.stop="hidePop">
    <div class="single_shadow" style="overflow:hidden">
      <div class="student_courses_name">
        <div class="form-input form_info_required form_info_line form_info_edit no_border_bottom">
          <div class="student_courses_name_title">
            <span>报读课程</span>
          </div>
          <div @click="toCourseList" class="form_info_value form_info_select">
            <div class="form_info_value_input">
              <input
                type="text"
                v-model="selectCourseInfo.MainDemoName"
                placeholder="必选"
                class="font_black"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="students_courses_ul clearfix">
        <div
          v-for="(item,index) in studentInfoList"
          :key="index"
          class="students_courses_list clearfix"
        >
          <div class="list_name fl">{{item.MainDemoName}}</div>
          <div
            @click="deleteStudent(item)"
            class="list_number fl"
          >{{Number(item.Sum_ToBeArranged)}}</div>
        </div>
        <div
          @click="addStudent"
          class="students_courses_list students_courses_add clearfix"
        >添加学生</div>
      </div>
    </div>
    
    <month-chart
      ref="monthChart"
      class="single_shadow corser_serach_popup"
      style="margin-top: 10px;"
      :monthInfo="monthInfo"
      @getMonthInfo="getMonthInfo"
      @continueClick="continueClick"
      @headerMenu="serachPopup"
    ></month-chart>
    <div
      class="course_student_attendance_operation module_content_menuList"
      :style="{top:pTop+'px',left:'-41px'}"
      v-show="isShowAlerDialog"
    >
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="optionStatus" @clickOptionForAlertDialog="doAfterClick"></drop-down-box>
    </div>
    <div class="merge_student_btn students clearfix">
      <div @click.stop="close" class="btn_footer_red fl">取消</div>
      <div @click="arrangeStudentsMutiCourse" class="btn_footer_brown fl">
        <div class="btn_footer_confirm">确认</div>
        <div class="btn_footer_info">
          已选
          <span class="">{{studentInfoList.length}}</span>个学生 , 安排
          <span class="">{{courseInfoList.length}}</span>节课
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GetTheStudentTicketPlanListByClassType,
  GetTheClassType,
  IntegratedQueryCourse,
  ArrangeStudents2MutiCourse
} from '../../../API/workbench';
import monthChart from '../../common/month-chart';
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      pTop: 0,
      isShowAlerDialog: false,
      AgentKey: '', // 当前登录人ID
      selectCourseInfo: {},
      selectcourseBillsInfo: {}, // 选中报课计划
      monthInfo: [], // 月视图数据
      studentInfoList: [],
      courseInfoList: [],
      type: 0, // 搜索类型
      serachInfo: {}, // 搜索条件数据
      optionStatus: [
        {
          type: 0,
          name: '搜索全部',
          eventName: 'clear',
          isShow: true
        },
        {
          type: 1,
          name: '按老师搜索',
          eventName: 'teacher',
          isShow: true
        },
        {
          type: 2,
          name: '按班级搜索',
          eventName: 'class',
          isShow: true
        }
      ]
    };
  },
  props: [''],
  components: {
    monthChart,
    dropDownBox
  },
  created () {
    let token = this.$store.getters.token;
    this.AgentKey = token.UserID;
  },
  mounted () {},
  watch: {
    selectCourseInfo (n, o) {
      this.getMonthInfo();
    },
    serachInfo (n, o) {
      if (n && this.selectCourseInfo.OLAPKey) {
        this.getMonthInfo();
      }
    }
  },
  methods: {
    hidePop () {
      this.isShowAlerDialog = false;
    },
    close () {
      this.$emit('close');
    },
    hideTips () {
      this.$refs.monthChart.isTips = false;
    },
    deleteStudent (item) {
      for (let i = 0; i < this.studentInfoList.length; i++) {
        if (item.OLAPKey == this.studentInfoList[i].OLAPKey) {
          this.studentInfoList.splice(i, 1);
        }
      }
    },
    addStudent () {
      if (this.selectCourseInfo.OLAPKey) {
        this.$emit('showStundentList');
        // this.isStudent = true;
        // this.$refs.studentsList.studenSearchText = '';
        // this.$refs.studentsList.dataStudentList = JSON.parse(JSON.stringify(this.studentInfoList));
        // this.$refs.studentsList.getStudentList();
      } else {
        layer.alert('请先选择报读课程');
      }
    },
    toCourseList () {
      this.$emit('showCourseList');
    },
    doAfterClick (optionItem) {
      switch (optionItem.type) {
        case 0:
          this.restoreSearch();
          this.isShowAlerDialog = false;
          break;
        case 1:
          this.$emit('showTeacherList');
          this.isShowAlerDialog = false;
          break;
        case 2:
          this.$emit('showClassList');
          this.isShowAlerDialog = false;
          break;
        case 3:
          this.isShowAlerDialog = false;
          break;
        default:
          break;
      }
    },
    serachPopup () {
      this.pTop = document.querySelector('.corser_serach_popup').offsetTop + 70;
      this.isShowAlerDialog = true;
      let that = this;
      document.onclick = function () {
        that.isShowAlerDialog = false;
      };
    },
    restoreSearch () {
      this.type = 0;
      this.serachInfo = {};
      this.getMonthInfo();
    },
    continueClick (item) {
      if (item.StatusKey != 2) {
        return;
      }
      if (item.alreadyJoin) {
        item.alreadyJoin = false;
        for (let i = 0; i < this.courseInfoList.length; i++) {
          if (item.OLAPKey == this.courseInfoList[i].OLAPKey) {
            this.courseInfoList.splice(i, 1);
          }
        }
      } else {
        this.courseInfoList.push(item);
        this.$set(item, 'alreadyJoin', true);
      }
    },
    // 获取课信息
    getCourseInfo (id) {
      GetTheClassType(id).then(
        res => {
          if (res.code == 0) {
            res.data.id = res.data.OLAPKey;
            res.data.menuName = res.data.MainDemoName;
            this.selectCourseInfo = res.data;
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    arrangeStudentsMutiCourse () {
      if (!this.selectCourseInfo.OLAPKey) {
        layer.alert('请先选择报读课程');
        return;
      }
      if (this.studentInfoList.length == 0) {
        layer.alert('请选择至少一个学生');
        return;
      }
      if (this.courseInfoList.length == 0) {
        layer.alert('请选择至少一项课程');
        return;
      }
      let student = [];
      let course = [];
      this.studentInfoList.forEach(e => {
        student.push(e.OLAPKey);
      });
      this.courseInfoList.forEach(e => {
        let obj = {};
        obj.OLAPKey = e.OLAPKey;
        obj.CourseNameKey = e.CourseNameKey;
        course.push(obj);
      });
      ArrangeStudents2MutiCourse(student, course).then(
        res => {
          if (res.code == 0) {
            layer.alert('安排课成功');
            this.$emit('arrangeMutilStudentSuccess');
            this.getStundentCourseInfo();
            this.courseInfoList = [];
            this.getMonthInfo();
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    // 更新已选中学生的课时
    getStundentCourseInfo () {
      this.studentInfoList.forEach(e => {
        GetTheStudentTicketPlanListByClassType(
          e.OLAPKey,
          this.selectCourseInfo.OLAPKey
        ).then(
          res => {
            e.Sum_ToBeArranged = res.data.Sum_ToBeArranged;
          },
          err => {
            layer.alert(err.msg);
          }
        );
      });
    },
    // 获取指名课名的当月课程
    getMonthInfo () {
      if (!this.selectCourseInfo.OLAPKey) {
        return;
      }
      let firstDate = this.$refs.monthChart.vAllWeek[0][0].date;
      let lastDate = this.$refs.monthChart.vAllWeek[this.$refs.monthChart.vAllWeek.length - 1][this.$refs.monthChart.vAllWeek[this.$refs.monthChart.vAllWeek.length - 1].length - 1].date;
      if (this.type == 1) {
        IntegratedQueryCourse(
          firstDate,
          lastDate,
          this.selectCourseInfo.OLAPKey,
          this.serachInfo.OLAPKey,
          ''
        ).then(
          res => {
            if (res.code == 0) {
              this.monthInfo = [];
              res.data.forEach(e => {
                if (this.courseInfoList.length > 0) {
                  this.courseInfoList.map(o => {
                    if (o.OLAPKey == e.OLAPKey) {
                      e.alreadyJoin = true;
                    }
                  });
                }
                this.monthInfo.push(e);
              });
            }
          },
          err => {
            layer.alert(err.msg);
          }
        );
      } else if (this.type == 2) {
        IntegratedQueryCourse(
          firstDate,
          lastDate,
          this.selectCourseInfo.OLAPKey,
          '',
          this.serachInfo.OLAPKey
        ).then(
          res => {
            if (res.code == 0) {
              this.monthInfo = [];
              res.data.forEach(e => {
                if (this.courseInfoList.length > 0) {
                  this.courseInfoList.map(o => {
                    if (o.OLAPKey == e.OLAPKey) {
                      e.alreadyJoin = true;
                    }
                  });
                }
                this.monthInfo.push(e);
              });
            }
          },
          err => {
            layer.alert(err.msg);
          }
        );
      } else {
        IntegratedQueryCourse(
          firstDate,
          lastDate,
          this.selectCourseInfo.OLAPKey,
          '',
          ''
        ).then(
          res => {
            if (res.code == 0) {
              this.monthInfo = [];
              res.data.forEach(e => {
                if (this.courseInfoList.length > 0) {
                  this.courseInfoList.map(o => {
                    if (o.OLAPKey == e.OLAPKey) {
                      e.alreadyJoin = true;
                    }
                  });
                }
                this.monthInfo.push(e);
              });
            }
          },
          err => {
            layer.alert(err.msg);
          }
        );
      }
    }
  }
};
</script>