<template>
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      优惠券
      <div class="title_btn btn_hover_bg_blue"
           @click="onActivityAdministration">
        优惠券管理
      </div>
    </div>
    <div class="WKT_discount_coupon_ul"
         @scroll="addedScrollTOP">
      <coupon-list :couponList="addedCouponList"
                   @doEditCoupon="doEditCoupon"></coupon-list>
      <div class="WKT_information_list">
        <div class="WKT_information_block">
          <add-box :titleName="'添加优惠券'"
                   @onAddBoxClick="onShowAddListClick()"></add-box>
        </div>
      </div>
    </div>
    <custom-dialog :title="'选择优惠券'"
                   :visible.sync="isCouponListDialogShow"
                   :before-close="closeCouponListDialog">
      <div class="defult_dialog_header_btn fr"
           @click="doAddCouponTypeClick()">
        <span class="btn_add">+</span>新建优惠券
      </div>
      <div class="WKT_discount_coupon_popup_ul"
           @scroll="addListScrollTOP">
        <coupon-list :isShowbtn="false"
                     :couponList="unShelveCouponList"
                     @doSelectCouponAdded="doSelectCouponAdded"></coupon-list>
      </div>
    </custom-dialog>

    <custom-dialog :title="'新建优惠券'"
                   :visible.sync="isShowCouponType"
                   :before-close="closeCouponTypeDialog">
      <add-coupon-type @doAddCouponClick="doAddCouponClick"></add-coupon-type>
    </custom-dialog>

    <!-- 建券表单 -->
    <custom-dialog :title="typeInfo.name"
                   :width="'1200px'"
                   :top="100"
                   :visible.sync="isShowAddCouponForm"
                   :before-close="closeAddCouponFormDialog">
      <coupon-edit-form :dataInfo="dataInfo"
                        @afterSuccess="afterSuccessUpCouponList"
                        @closeDialog="closeAddCouponFormDialog"></coupon-edit-form>
    </custom-dialog>

    <!-- 优惠券管理 -->
    <custom-dialog :title="'优惠券管理'"
                   :width="'1060px'"
                   :top="100"
                   :visible.sync="isShowCouponManagementForm"
                   :before-close="closeCouponManagementFormDialog">
      <div class="defult_dialog_header_btn fr"
           @click="doAddCouponTypeClick()">
        <span class="btn_add">+</span>新建优惠券
      </div>
      <coupon-management-form ref="managementForm"
                              :dataInfo="dataInfo"
                              @afterSuccess="afterSuccessUpCouponManagement"
                              @closeDialog="closeAddCouponFormDialog"
                              @editClick="doEditCoupon"></coupon-management-form>
    </custom-dialog>

  </div>
</template>

<script>
import addBox from '../common/add-box';
import couponList from './coupon-list/index';
import addCouponType from './coupon-list/add-coupon-type';
import couponEditForm from './coupon-edit-form/index';
import couponManagementForm from './coupon-management-form/index';
import { SelectSimpleCoupon, PutAwaySimpleCoupon } from '../../../API/workbench';

const newData = {
  coupon_name: '', // 优惠券名称
  coupon_discribe: '', // 优惠券描述
  if_fenxiang: 2, // 券类1:转介绍券2普通券3:裂变券;4线上套餐
  time_start: '', // 开始时间
  time_end: '', // 结束时间
  type: '', // 优惠方式1：满折2：满减3：特价4：代金券
  da_zhe: '', // 优惠方式 折扣
  di_xian_jin: '', // 优惠方式 代金金额
  you_hu_money: '', // 优惠方式 优惠金额
  user_goods: '', // 优惠方式 消费条件 特价 商品id
  xiao_fei_tiao_jian: '', // 优惠方式 消费条件 指满多少钱
  taocanid: '', // 使用范围 套餐id'
  only_newuser: 0, // 1只允许新用户 0不允许新用户
  sort: 1, // 排序
  packgePrice: '', // 套餐原价
  scopeExplain: ''// 适用范围
};
export default {
  components: { addBox, couponList, addCouponType, couponEditForm, couponManagementForm },
  data () {
    return {
      // 查询热门优惠券
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: 'sort', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: 'asc', //	字符串	可选		数据源：GET	 排序方向
        searchText: '', //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
        online: '', //	字符串	可选		数据源：GET	 是否上架0-否;1-是
        isInvalid: 0
      },
      addedCouponList: [], // 已上架的优惠券
      unShelveCouponList: [], // 已下架的优惠券
      isCouponListDialogShow: false, // 显示优惠券列表弹窗
      isShowCouponType: false, // 显示优惠券类型弹窗
      isShowAddCouponForm: false, // 显示建券弹窗
      isShowCouponManagementForm: false, // 显示优惠券管理弹窗
      typeInfo: {}, // 2：满减3：特价4：代金券'
      dataInfo: {}, // 建券信息
      noMore: false
    };
  },
  props: {

  },
  created () {
    this.showAddedCouponList();
  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 滚动加载事件.
    addedScrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      if (parseInt(ETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        this.noMore = false;
        this.showAddedCouponList();
      }
    },
    addListScrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      if (parseInt(ETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        this.noMore = false;
        this.onShowAddCouponList();
      }
    },
    // 打开优惠券管理
    onActivityAdministration () {
      this.isShowCouponManagementForm = true;
    },
    // 打开优惠券选择列表
    onShowAddListClick () {
      this.parameter.pageIndex = 0;
      this.onShowAddCouponList('Add');
    },
    // 获取上架的优惠券列表
    showAddedCouponList () {
      this.parameter.online = 1;
      this.parameter.orderBy = 'sort'; //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      this.parameter.sequence = 'asc'; //	字符串	可选		数据源：GET	 排序方向
      SelectSimpleCoupon(this.parameter).then(result => {
        if (this.parameter.pageIndex == 0) {
          this.addedCouponList = [];
        }
        result.data.PageDataList.forEach(o => {
          let hasFind = false;
          if (this.addedCouponList.length > 0) {
            hasFind = this.addedCouponList.findIndex(p => p.id == o.id) >= 0;
          }
          if (!hasFind) {
            this.addedCouponList.push(o);
          }
        });
        if (result.data.PageDataList.length < this.parameter.pageSize) {
          this.noMore = true;
        }
        this.parameter.pageIndex++;
        console.log(this.addedCouponList, '获取上架的优惠券列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取未上架的优惠券列表
    onShowAddCouponList (type) {
      this.parameter.online = 0;
      this.parameter.orderBy = ''; //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      this.parameter.sequence = '';
      SelectSimpleCoupon(this.parameter).then(result => {
        if (type == 'Add') {
          this.isCouponListDialogShow = true;
        }
        if (this.parameter.pageIndex == 0) {
          this.unShelveCouponList = [];
        }
        result.data.PageDataList.forEach(o => {
          let hasFind = false;
          if (this.unShelveCouponList.length > 0) {
            hasFind = this.unShelveCouponList.findIndex(p => p.id == o.id) >= 0;
          }
          if (!hasFind) {
            this.unShelveCouponList.push(o);
          }
        });
        if (result.data.PageDataList.length < this.parameter.pageSize) {
          this.noMore = true;
        }
        this.parameter.pageIndex++;
        console.log(this.unShelveCouponList, '获取未上架的优惠券列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择创建的优惠券类型
    doAddCouponTypeClick () {
      this.isShowCouponType = true;
    },
    // 上架优惠券
    doSelectCouponAdded (Item) {
      PutAwaySimpleCoupon(Item.id).then(result => {
        this.closeCouponListDialog();
        this.showAddedCouponList();
        layer.alert('上架成功');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 创建优惠券
    doAddCouponClick (item) {
      this.typeInfo.name = '新建' + item.name;
      this.typeInfo.key = item.key;
      this.closeCouponTypeDialog();
      this.isShowAddCouponForm = true;
      this.dataInfo = this.$utils.parseJson(newData);
      this.dataInfo.time_start = this.$utils.formatDateToLine(Date.new());
      this.dataInfo.type = this.typeInfo.key;
    },
    // 修改优惠券
    doEditCoupon (item) {
      console.log(item, '修改优惠券');
      this.dataInfo = item;
      this.typeInfo.key = item.type;
      switch (Number(this.typeInfo.key)) { // 2：满减 3：特价 4：代金券'
        case 2:
          this.typeInfo.name = '修改满减券';
          break;
        case 3:
          this.typeInfo.name = '修改特价券';
          break;
        case 4:
          this.typeInfo.name = '修改代金券';
          break;
        default:
          break;
      }
      this.isShowAddCouponForm = true;
    },
    // 关闭选择列表
    closeCouponListDialog () {
      this.isCouponListDialogShow = false;
      this.parameter.pageIndex = 0;
    },
    // 关闭优惠券类型弹窗
    closeCouponTypeDialog () {
      this.isShowCouponType = false;
    },
    // 券表单(新增/修改)成功回调
    afterSuccessUpCouponList (type, data) {
      if (this.isShowCouponManagementForm && this.$refs.managementForm) {
        this.afterSuccessUpCouponManagement();
      } else {
        this.parameter.pageIndex = 0;
        if (type == 'Add') {
          this.onShowAddCouponList(type);
        } else {
          this.showAddedCouponList();
        };
      }

      this.closeAddCouponFormDialog();
    },
    // 券管理-(新增/修改)成功回调
    afterSuccessUpCouponManagement (data) {
      if (data) { //
        this.parameter.pageIndex = 0;
        this.showAddedCouponList();
      } else if (this.isShowCouponManagementForm && this.$refs.managementForm) {
        this.$refs.managementForm.parameter.pageIndex = 0;
        this.$refs.managementForm.getMessageSearchSimpleCoupon();
      }
      this.closeAddCouponFormDialog();
    },

    // 关闭优惠券表单弹窗
    closeAddCouponFormDialog () {
      this.isShowAddCouponForm = false;
    },
    closeCouponManagementFormDialog () {
      this.isShowCouponManagementForm = false;
    }
  }
};
</script>
