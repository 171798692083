<template>
  <!--系统设置表单-->
  <div>
    <!-- 上课时间范围 -->
    <div v-if="systemSetFormInfo.setKey=='64&65'">
      <time-select-module :SClassTimeName="PeriodTimeData.bgeinTime"
                          :EClassTimeName="PeriodTimeData.endTime"
                          :timeTitleObj="timeTitleObj"
                          :isNoLimitTimeRange="false"
                          @confirmChooseEvent="editSchooltimeSet"
                          @cancleChooseEvent="doCloseShowForm">
      </time-select-module>
    </div>
    <div class="content_marginRL content_marginTop_30 single_shadow overflow_hidden"
         v-else>
      <!-- 上课时间范围 -->
      <div class="mendian_setting_content"
           v-if="systemSetFormInfo.setKey==31">
        <form-input :min="0"
                    :readonly="isReadonly"
                    v-model="newInputText"
                    :decimalPlaces="0"
                    :formTitle="'签到提前时间(分钟)'"
                    class="no_border_bottom"
                    :formPlaceholder="'必填'"
                    :required="true"
                    :numberVerify="true"></form-input>
      </div>
      <!-- 上课时间范围 -->
      <div class="mendian_setting_content"
           v-if="systemSetFormInfo.setKey==86">
        <input-number :min="1"
                      :max="60"
                      :readonly="isReadonly"
                      v-model="newInputText"
                      :decimalPlaces="0"
                      :formTitle="'超时考勤控制(天)'"
                      class="no_border_bottom"
                      :placeholder="'老师不可超时考勤'"
                      :required="true"
                      :numberVerify="true"></input-number>
      </div>
      <!-- 老师查看教案时间范围 -->
      <div class="mendian_setting_content"
           v-else-if="systemSetFormInfo.setKey==18">
        <form-input :min="0"
                    :readonly="isReadonly"
                    v-model="newInputText"
                    :decimalPlaces="0"
                    :formTitle="'上课前后(天)'"
                    class="no_border_bottom"
                    :formPlaceholder="'必填'"
                    :required="true"
                    :numberVerify="true"></form-input>
      </div>
      <!-- 备课提前天数 -->
      <div class="mendian_setting_content"
           v-else-if="systemSetFormInfo.setKey==67">
        <form-input :min="0"
                    :readonly="isReadonly"
                    v-model="newInputText"
                    :decimalPlaces="0"
                    :formTitle="'备课提前(天)'"
                    class="no_border_bottom"
                    :formPlaceholder="'必填'"
                    :required="true"
                    :numberVerify="true"></form-input>
      </div>
      <!-- 备课确认间隔 -->
      <div class="mendian_setting_content"
           v-else-if="systemSetFormInfo.setKey==66">
        <form-input :min="0"
                    :readonly="isReadonly"
                    v-model="newInputText"
                    :decimalPlaces="0"
                    :formTitle="'备课确认间隔(秒)'"
                    class="no_border_bottom"
                    :formPlaceholder="'必填'"
                    :required="true"
                    :numberVerify="true"></form-input>
      </div>
      <!-- 严格模式 -->
      <div class="mendian_setting_content"
           v-else-if="systemSetFormInfo.setKey>=122&&systemSetFormInfo.setKey<=127">
        <input-switch v-model="settingInfo.SetKey"
                      :formTitle="'是否开启考勤严格模式'"
                      :showInputValue="false"
                      :readonly="isReadonly"
                      @change="changeSwitchOpen"
                     ></input-switch>
        <input-switch v-show="settingInfo.SetKey==1&&systemSetFormInfo.setKey<125"
                      v-model="settingInfo.SetContent"
                      :formTitle="'是否限制老师'"
                      :showInputValue="false"
                      :readonly="isReadonly"
                      ></input-switch>

      </div>
      <!-- 按钮组 -->
      <div v-if="!isReadonly"
           class="">
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="confirmFormClick"
                               @cancelClick="doCloseShowForm"></save-cancel-btn-group>
      </div>
    </div>
  </div>
</template> 

<script>
import timeSelectModule from '../../../common/time-select-module';
import formSelect from '../../../form-item/form-select';
import { SynchronousUpdateIsLeaveOrIsCharges } from '../../../../API/workbench';
export default {
  data () {
    return {
      timeTitleObj: { startTimeName: '最早开课时间', endTimeName: '最晚下课时间' },
      PeriodTimeData: {},
      newInputText: 0, // 默认值
      settingInfo:{
        SetKey:0,
        SetContent:0,
      }
    };
  },

  components: { formSelect, timeSelectModule },
  props: {
    value: [String, Number],
    scopeTime: Object,
    systemSetFormInfo: Object,
    seletedSettingInfo:Object
  },
  created () {
    this.newInputText = this.value;
    this.PeriodTimeData = this.scopeTime;
    this.settingInfo=this.$utils.parseJson(this.seletedSettingInfo)
    this.settingInfo.SetKey=Number(this.settingInfo.SetKey)
    this.settingInfo.SetContent=Number(this.settingInfo.SetContent)
    console.log(this.settingInfo,'settingInfo')
  },
  computed: {
    // 判断权限,
    isReadonly () {
      let modulePowerList = this.$store.getters.RolePowerList;
      return !this.$utils.CheckModulePower(modulePowerList, 38);
    }
  },
  methods: {
    changeSwitchOpen(){
      if(this.settingInfo.setKey!=1){
        this.settingInfo.SetContent=0
      }
    },
    // 修改上课时间范围
    editSchooltimeSet (newBeginTime, newEndTime) { // 确定时间选择时改变数据.
      console.log(newBeginTime, newEndTime, 'beginTime, endTime');
      if (!newBeginTime || !newEndTime) {
        layer.alert('最早开课时间或最晚下课时间不能为空');
      } else {
        this.PeriodTimeData.bgeinTime = newBeginTime;
        this.PeriodTimeData.endTime = newEndTime;
        let startTime = this.PeriodTimeData.bgeinTime.split(':')[0] * 60 + Number(this.PeriodTimeData.bgeinTime.split(':')[1]);
        let endTime = this.PeriodTimeData.endTime.split(':')[0] * 60 + Number(this.PeriodTimeData.endTime.split(':')[1]);
        if (endTime < startTime) {
          layer.alert('最早开课时间不能大于最晚下课时间');
        } else if (endTime == startTime) {
          layer.alert('最早开课时间不能等于最晚下课时间');
        } else {
          if (this.PeriodTimeData.bgeinTime != this.$utils.getSaaSClientSetInfo(64).SetContent) {
            this.$emit('modifySetting', { SetKey: '', SetKeyValue: '', SetContent: this.PeriodTimeData.bgeinTime, SetTypeKey: 64 });
          }
          if (this.PeriodTimeData.endTime != this.$utils.getSaaSClientSetInfo(65).SetContent) {
            this.$emit('modifySetting', { SetKey: '', SetKeyValue: '', SetContent: this.PeriodTimeData.endTime, SetTypeKey: 65 });
          }
          this.doCloseShowForm();
        }
      }
    },
    confirmFormClick (callBack) {
      let flag = false;
      this.editSettingInfo(this.newInputText);
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.doCloseShowForm();
    },
    // 修改系统设置
    editSettingInfo (val) {
      if(this.systemSetFormInfo.setKey>=122&&this.systemSetFormInfo.setKey<=127){
        this.$emit('editSettingInfoItem', this.settingInfo);
      }else{
        this.$emit('editSettingInfo', val);
      }
    },
    // 同步更新请假旷课是否扣课时
    UpdateIsLeave () {
      let type = 0;
      if (this.systemSetFormInfo.setKey == 32) { // 请假扣课时设置
        type = 1;
      } else if (this.systemSetFormInfo.setKey == 33) { // 旷课扣课时设置
        type = 2;
      }
      SynchronousUpdateIsLeaveOrIsCharges(type).then(res => {
        console.log(res, '同步更新请假旷课是否扣课时成功');
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 关闭弹窗
    doCloseShowForm () {
      this.$emit('doCloseShowForm');
    }
  }
};
</script>

<style>
</style>
