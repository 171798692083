<template>
  <!-- 安排试课-->
  <div class="single_result_detail">
    <!-- 预约课程 -->
    <div class="has_trial_courseName single_shadow content_marginTop_30 content_marginRL">
      <div class="trial_course_filed">预约课程</div>
      <div class="trial_course_value">{{searchScheduleCourseCondition.CourseNameKeyValue}}</div>
    </div>
    <!-- 日期筛选 -->
    <div class="has_trial_changeData content_marginRL single_shadow">
      <div class="has_trial_dataList">
        <div class="has_trial_item"
             @click.stop="showDatePicker">
          <div class="has_trial_Picker">
            <el-date-picker v-model="searchScheduleCourseCondition.StartTime"
                            type="date"
                            :editable="false"
                            format="yyyy-MM-dd"
                            @change="changeApplicationTryCourseTime"
                            placeholder="选择日期">
            </el-date-picker>
            <!-- <span>2018-10-19</span> -->
            <div class="date_time_week">
              <span>{{Week}}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="has_trial_changeBtn">
        <div class="prev"
             @click.stop="prevApplicationTryCourseTime"></div>
        <div class="next fr"
             @click.stop="nextApplicationTryCourseTime"></div>
      </div>
    </div>
    <!-- 已排课程列表 -->
    <div class="addTrialClass_content has_arrange_course content_marginTop_8 ">
      <div class="has_arrange_course_list content_marginRL">
        <!-- 单个课块 -->
        <div class="arrange_course_info  single_shadow"
             v-for="(item,index) in scheduleCourseList"
             :key="index"
             :class="{active:item.isOpen}"
             @click.stop="changeSwitchStatus(item)">
          <div class="arrange_course_infoImportant">
            <div>
              <span>{{item.ClassTime}}</span>
            </div>
            <div>
              <span>{{item.week}}</span>
            </div>
            <div>
              <span>
                <span>{{item.BeginClassTime.slice(0,5)}}</span>-
                <span>{{item.EndClassTime.slice(0,5)}}</span>
              </span>
            </div>
          </div>
          <div class="arrange_course_infoOthers">
            <div class="course_infoOthers_left">
              <div class="arrange_course_name">
                <span>{{item.GradeClassKeyValue}}</span>
              </div>
              <div class="arrange_course_otherInfo">
                <div class="arrange_course_className">
                  <span>{{item.classRoomName||'教室: 无'}}</span>
                </div>
                <div class="arrange_course_teacher">
                  <span>主讲：
                    <span>{{item.MTeacherKeyValue}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 亲，当前没有任何数据哦~显示 -->
        <div class=" course_block_nodata"
             v-if="scheduleCourseList.length<=0">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>

      </div>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmBtn"
                           @cancelClick="cancleBtn"></save-cancel-btn-group>
  </div>
</template>
<script>
import { MessageSearchPageForCourse, ArrangeTrialClass, getStudentRelatedMsg } from '../../../API/workbench.js';

export default {
  name: 'arrangeTryCourse',
  data () {
    return {
      scheduleCourseList: [], // 适用课程列表
      isOpen: true,
      saveParams: {
        ID: 0, // 试课预约ID
        CourseKey: 0, // 课ID
        IsClassHourLackKey: 0 // 是否无课时安排0:否, 1:是
      },
      selectionScheduleCourseInfo: {} // 选中的课信息 为安排好后刷新安排人的信息

    };
  },
  props: ['searchScheduleCourseCondition', 'courseHide', 'studentKey'],
  components: {
  },
  created () {
    this.saveParams.ID = this.searchScheduleCourseCondition.ID;
    this.searchScheduleCourseCondition.StartTime = this.$utils.formatDateToLine(Date.new());
    this.searchScheduleCourseCondition.EndTime = this.searchScheduleCourseCondition.StartTime;
    this.MessageSearchPageForCourse();
  },
  mounted () {
  },
  methods: {
    // 触发datepicker显示
    showDatePicker () {
      this.$el.querySelector('.el-input__inner').focus();
    },
    // 显示的日期格式
    formatday () {
      return 'yyyy-MM-dd' + this.week;
    },
    // 申请试课日期向前一天
    prevApplicationTryCourseTime () {
      // 开始日期大于当前日期 才可向前查询
      console.log('prevApplicationTryCourseTime', this.searchScheduleCourseCondition.StartTime);
      if (Date.new(this.searchScheduleCourseCondition.StartTime) > Date.new()) {
        let currentDate = Date.new(this.searchScheduleCourseCondition.StartTime);
        this.searchScheduleCourseCondition.StartTime = this.$utils.formatDateToLine(Date.new(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1));
        this.searchScheduleCourseCondition.EndTime = this.searchScheduleCourseCondition.StartTime;
        this.MessageSearchPageForCourse();
      }
    },
    // 申请试课日期向后一天
    nextApplicationTryCourseTime () {
      console.log('nextApplicationTryCourseTime', this.searchScheduleCourseCondition.StartTime);
      let currentDate = Date.new(this.searchScheduleCourseCondition.StartTime);
      this.searchScheduleCourseCondition.StartTime = this.$utils.formatDateToLine(Date.new(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1));
      this.searchScheduleCourseCondition.EndTime = this.searchScheduleCourseCondition.StartTime;
      this.MessageSearchPageForCourse();
    },
    // 变更申请试课日期
    changeApplicationTryCourseTime (date) {
      console.log(date);
      this.searchScheduleCourseCondition.StartTime = this.$utils.formatDateToLine(Date.new(date));
      this.searchScheduleCourseCondition.EndTime = this.searchScheduleCourseCondition.StartTime;
      this.MessageSearchPageForCourse();
    },
    // 获取适用课列表
    MessageSearchPageForCourse () {
      this.scheduleCourseList = [];
      MessageSearchPageForCourse(this.searchScheduleCourseCondition).then(result => {
        if (result.code == 0 && result.data) {
          console.log(result.data.PageDataList, '试课');
          result.data.PageDataList.forEach(obj => {
            if (obj.IsListenKey == 1) {
              obj.isOpen = false;
              obj.week = this.$utils.getWeek(obj.ClassTime);
              if (obj.StatusKey == 2) {
                this.scheduleCourseList.push(obj);
              }
            }
          });
          // 上课时间排序（顺序）
          this.scheduleCourseList.sort((a, b) => {
            let differ = parseInt(a.BeginClassTime.replace(/:/g, '')) - parseInt(b.BeginClassTime.replace(/:/g, ''));
            return differ == 0 ? ((parseInt(a.EndClassTime.replace(/:/g, ''))) - (parseInt(b.EndClassTime.replace(/:/g, '')))) : differ;
          });
        }
      }).catch(err => {
        console.log(err);
        layer.alert(err.msg);
      });
      this.saveParams.CourseKey = 0;
    },
    // 取消
    cancleBtn () {
      this.$emit('cancleBtn');
    },
    // 确定保存
    confirmBtn (callBack) {
      console.log(this.studentKey, '确定保存');
      getStudentRelatedMsg(this.studentKey).then(result => {
        if (this.saveParams.CourseKey > 0) {
          ArrangeTrialClass(this.saveParams).then(result => {
            if (result.code == 0) {
              this.$emit('confirmBtn');
              layer.alert('安排成功');
            }
          }, err => {
            if (err.code == 60004) {
              let that = this;
              layer.confirm('该学生没有足够的试课课时,是否继续安排', {
                btn: [{
                  name: '确认',
                  callBack: function () {
                    that.saveParams.IsClassHourLackKey = 1;
                    that.confirmBtn();
                    layer.close();
                  }
                }]
              });
            } else {
              layer.alert(err.msg);
            }
          }).finally(() => {
            if (callBack) {
              callBack();
            }
          });
        } else {
          if (callBack) {
            callBack();
          }
          layer.alert('至少选中一节课');
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 改变选中状态
    changeSwitchStatus (item) {
      this.scheduleCourseList.forEach(obj => {
        obj.isOpen = false;
      });
      item.isOpen = true;
      this.selectionScheduleCourseInfo = item;
      this.saveParams.CourseKey = item.ScheduleCourseKey;
    }
  },
  watch: {
  },
  computed: {
    Week () {         // 根据绑定时间插件的值来计算周几.
      if (typeof (this.searchScheduleCourseCondition.StartTime) == 'string') {
        return this.$utils.getWeek(this.searchScheduleCourseCondition.StartTime);
      } else {
        return this.$utils.getWeekByTime(this.searchScheduleCourseCondition.StartTime);
      }
    }
  }
};
</script>
<style scoped>
.date_time_week {
  position: absolute;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  line-height: 30px;
}
.date_time_week > span {
  padding-left: 110px;
}
.el-date-editor.el-input {
  width: 100%;
}
</style>

