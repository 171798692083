<template>
     <div>
        <div class="form_info_edit form_info_list">
            <div class="form_info_line">
                <div class="form_info_field">
                    <span>新密码</span>
                </div>
                <div class="form_info_value font_cyan">
                    <input
                    type="password"
                    placeholder="密码长度6-18位"
                    autocomplete="off"
                    v-model="newPwd"
                    />
                </div>
                </div>
        
        </div>
         <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
     </div>
</template>
<script>
import { CWSModifyTeacherPassword} from "../../../../../API/workbench";

export default {
    data(){
        return{
            // 提交数据对象
           newPwd:''
      
        }
    },
    components:{

    },
    props:{
         TeacherId:{
            type:[String,Number],
            default:0
        },
    },
    created(){
    },
    computed:{
    },
    methods:{
        verifyData(){
            let isFail=false
            if(!this.newPwd){
                isFail=true
                layer.alert("请输入新密码")
            }else if(this.newPwd.length<6||this.newPwd.length>18){
               isFail=true
                layer.alert("密码长度只能为6-18位数，请重新输入") 
            }
            return isFail
        },   
        confirmClick(callBack){
            if(this.verifyData()){
                if(callBack){
                    callBack()
                }
                return false
            }
                 //修改
                CWSModifyTeacherPassword(this.TeacherId,this.newPwd).then(result=>{
                    layer.alert("修改成功")
                    console.log("CWSModifyTeacherPassword",result)
                        this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(err.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSUpdateTeacher",error.msg)
                })
        },
        cancelClick(){
            this.$emit("cancelClick")
        }
    }
}
</script>