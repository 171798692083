<template>
  <div class="table_tr"
       @click.stop="clickItem">
    <div class="table_td tl"
         :style="{'width': vWith+'%','padding-left': '5px'}">
      {{vSetUpTime}}
    </div>
    <!-- <div class="table_td tl"
         :style="{'width': vWith2+'%','padding-left': '5px'}">
      {{Number(Number(item.DeductAmount).toFixed(2))}}
    </div> -->
    <div class="table_td tl"
         :style="{'width': vWith3+'%','padding-left': '5px'}">
      {{Number(item.ArrangeCourseCount)}}
    </div>
    <div class="table_td tl"
         :style="{'width': otherWitdh+'%','padding-left': '5px'}">
      {{Number(item.ArrivedNumCount)}}
    </div>
    <div class="table_td tl"
         :style="{'width': otherWitdh+'%','padding-left': '5px'}">
      {{Number(item.LeaveNumCount)}}
    </div>
    <div class="table_td tl"
         :style="{'width': otherWitdh+'%','padding-left': '5px'}">
          <span v-if="this.$utils.getSaaSClientSet(59) > 0">{{Number(item.TruantNumCount)}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    isShowLeave: {
      type: Boolean,
      default: false
    },
    isShowTruant: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vSetUpTime () {
      if (this.item) {
        return this.item.SetUpTime.substring(5);
      }
    },
    vWith () {
      let width = Math.round((80 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    vWith2 () {
      let width = Math.round((90 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    vWith3 () {
      let width = Math.round((80 / 377) * 100);
      let otherWitdh = Math.round((85 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    otherWitdh () {
      let otherWitdh = Math.round((85 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return otherWitdh;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return otherWitdh + (otherWitdh * count) / (5 - count);
      }
    }
  },
  methods: {
    clickItem () {
      this.$emit('clickItem', this.item);
    }
  }
};
</script>

