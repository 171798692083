<template>
  <div>
    <seleted-list :courseId="releaseData.RelateCourseID"
                  ref="seletedList"
                  :isTeacher="0"
                  :dataList="assignStudentInfo.StudentList"
                  @completeTeacherList="confirmClickAfter"></seleted-list>

    <div class="release_content_title">
      <span class="left_side">课评详情</span>
      <!-- <span class="right_side">课评模板</span> -->
    </div>
    <release-content ref="releaseContent"
                     :dataInfo="releaseData"
                     :settingInfo="settingInfo"
                     :className="'homework_publish_textBox'"></release-content>
    <!-- <div class="bottom_save pr">
      <div class="opt"
           :class="IsSaveKey?'html_view_Bottom':'html_view_save_as'"
           @click.stop="saveClick">
        <span>
          保存为私享话术
        </span>
      </div>
    </div> -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'提交'"
                             :btnClass="vReleaseBtnClass"
                             @confirmClick="comfireAfter"
                             @cancelClick="closeDialog"></save-cancel-btn-group>
    </div>
    <!-- <basics-custom-popup v-if="isShowLessonEvaluatingVerbalLibrary"  :formTitle="'课评模板'" @closeEvent="doCloseEvent">
		<lesson-evaluating-verbal-library :typeKey="1" :isPrivateKey="1" :selectKey="selectCourseLibraryInfo.ID" @onClickEvent="selectCourseLibrary"></lesson-evaluating-verbal-library>
	</basics-custom-popup> -->
  </div>

</template>

<script>
import { editCourseInfoForReview, GetCourseInfoForReview, EditReview, PublishedReview } from '../../../../API/workbench';
import seletedList from './seleted-list';
// import commonHeader from '../common-component/common-header.vue';
import releaseContent from '../../common-component/release-content/index.vue';
// import lessonEvaluatingVerbalLibrary from '../components/template-library/lesson-evaluating-verbal-library/index'
export default {
  components: {
    releaseContent,
    // commonHeader,
    seletedList
    // lessonEvaluatingVerbalLibrary
  },
  data () {
    return {
      releaseData: {
        essayID: 0,
        Title: '', // 标题,
        Content: '', // 内容,
        Currency: 0,
        RelateCourseID: 0, // 课ID,
        StudentList: [], // 接收学生列表 { StudentKey: 0, // 学生Id, StudentKeyValue: ''// 学生姓名  }
        AttachList: [] // 附件列表 { AttachTypeKey: 1, // 1-图片; 2-视频;3-音频  AttachUrl: '../../../../../public/img/01.jpg'}

      },
      settingInfo: {
        addVideoType: '1',
        isTeacher: 0,     // 是否老师
        formTitle: '',   // 主标题
        showAddAudio: true,  // 是否显示录音
        formTitleplaceholder: '',  // 主标题栏水印
        contentTitle: '',   // 内容标题
        placeholder: '请输入课评内容',  // 内容
        showAddPic: true,   // 显示添加图片
        showRelevance: true,    // 显示添加关联
        showUploadFile: false   // 显示上传文件

      },
      IsSaveKey: 0, // 是否保存为作业题库
      searchType: 1,
      courseInfo: {},
      essayInfo: null,
      StudentKey: 0,
      stuInfo: {},
      assignStudentInfo: {
        Title: '点评学生',
        StudentList: []
      },
      isShowLessonEvaluatingVerbalLibrary: false,
      selectCourseLibraryInfo: ''
    };
  },
  props: {
    dataInfo: { // 修改的作业信息
      type: Object,
      default: {}
    }
  },
  created () {
    console.log('created', this.dataInfo);
    this.releaseData.essayID = Number(this.dataInfo.essayID) || 0; // 作业ID
    // 作业ID大于0表示修改状态 反之 新增
    this.releaseData.RelateCourseID = this.dataInfo.scheduleCourseID || 0; // 课ID
    this.releaseData.Content = this.dataInfo.Content || '';
    this.StudentKey = this.dataInfo.studentKey || 0;
    if (this.releaseData.essayID) {
      this.searchType = 2;
      this.getEditClassStudentsList();
    } else {
      this.ClassStudentsArrangeQuery();
    }
  },
  computed: {
    vHeadTitle () {
      if (this.releaseData.essayID > 0) {
        return '修改点评';
      } else {
        return '发布点评';
      }
    },
    vDefaultImgUrl () {
      // 默认头像.
      if (this.stuInfo.SexKey == 2) { // 2:男 3：女
        return '../../../../../../public/image/deflut_boy.png';
      } else if (this.stuInfo.SexKey == 3) {
        return '../../../../../../public/image/deflut_gril.png';
      } else {
        return '';
      }
    },
    vImgUrl () {
      let imageUrl = '';
      if (this.stuInfo.HeadImgSupport) {
        if (this.stuInfo.HeadImgSupport.indexOf('http') == -1) {
          imageUrl = this.$store.getters.CDNURL + this.stuInfo.HeadImgSupport;
        } else {
          imageUrl = this.stuInfo.HeadImgSupport;
        }
      } else {
        if (this.stuInfo.SexKey == 2) { // 男
          imageUrl = '../../../../../../public/image/deflut_boy.png';
        } else if (this.stuInfo.SexKey == 3) {
          return '../../../../../../public/image/deflut_gril.png';
        } else {
          return '';
        }
      }
      return imageUrl;
    },
    vAttenClass () {
      if (this.stuInfo.AttenceStatusKey == 3) {
        return 'type_green';
      } else if (this.stuInfo.AttenceStatusKey == 4) {
        return 'type_yellow';
      } else if (this.stuInfo.AttenceStatusKey == 5) {
        return 'type_red';
      } else {
        return 'type_gray';
      }
    },
    vAttenceStatusKeyValue () {
      if (this.stuInfo.AttenceStatusKey == 3) {
        return '签到';
      } else if (this.stuInfo.AttenceStatusKey == 4) {
        return '请假';
      } else if (this.stuInfo.AttenceStatusKey == 5) {
        return '旷课';
      } else {
        return '未考勤';
      }
    },
    vSeletedStudentList () {
      const newArr = [];
      console.log(this.assignStudentInfo.StudentList, 'thisvSeletedStudentList');
      this.assignStudentInfo.StudentList.forEach(o => {
        if (o.isActive) {
          newArr.push(o);
        }
      });
      return newArr;
    },
    vStudentList () {
      const newArr = [];
      this.assignStudentInfo.StudentList.forEach(o => {
        if (this.essayInfo) {
          this.essayInfo.CurrencyList.forEach(p => {
            if (o.StudentKey == p.StudentKey) {
              o.Currency = Number(p.Currency);
            }
          });
        }
        if (this.searchType == 1) {
          if (o.Status != 1) {
            newArr.push(o);
          }
        } else if (this.searchType == 2) {
          if (o.Status == 1) {
            newArr.push(o);
          }
        }
      });
      return newArr;
    },
    vReleaseBtnClass () {
      let className = 'no_confirm';
      console.log(this.releaseData.Content, this.releaseData.AttachList.length, '11111111111111');
      if (this.releaseData.Content || this.releaseData.AttachList.length > 0) {
        className = '';
      }
      return className;
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog');
    },
    doCloseEvent () {
      this.isShowLessonEvaluatingVerbalLibrary = false;
    },
    selectCourseLibrary (data) {
      this.selectCourseLibraryInfo = data;
      this.doCloseEvent();
      this.releaseData.AttachList = data.AttachList;
      this.releaseData.Content = data.Content;
      this.$nextTick(o => {
        // this.$refs.releaseContent.initContentValue(this.releaseData.Content);
      });
      console.log(data, 'selectCourseLibrary');
    },
    changeCurrency (key) {
      this.stuInfo.Currency = key + 1;
    },
    getEditClassStudentsList () {
      editCourseInfoForReview(this.releaseData.essayID).then(res => {
        console.log('getCourseInfoForFSC', res.data);
        this.essayInfo = res.data;
        this.releaseData.AttachList = res.data.AttachList;
        this.releaseData.Content = res.data.Content;
        this.$store.commit('setCurrencyCount', Number(res.data.Currency));
        this.$nextTick(o => {
          // this.$refs.releaseContent.initContentValue(this.releaseData.Content);
        });
        GetCourseInfoForReview(this.releaseData.RelateCourseID).then(res => {
          console.log('getCourseInfoForFSC', res.data);
          this.assignStudentInfo.StudentList = [];
          this.courseInfo = res.data;
          res.data.ArrangeStudentList.forEach(o => {
            this.$set(o, 'isActive', false);
            this.$set(o, 'Currency', 0);
            //               if (o.Status != 1 && o.AttenceStatusKey == 3) {

            //                 this.assignStudentInfo.StudentList.push(o);
            //               }
            // else if (o.AttenceStatusKey == 3) {
            const locaItem = this.essayInfo.CurrencyList.find(p => {
              return o.StudentKey == p.StudentKey;
            });
            if (locaItem) {
              this.$set(o, 'isActive', true);
              this.$set(o, 'Currency', locaItem.Currency);
              this.$set(o, 'ScTempKey', locaItem.ScTempKey);
              this.$set(o, 'ScTempKeyValue', locaItem.ScTempKeyValue);
              this.$set(o, 'EssayAssessTempSetList', locaItem.EssayAssessTempSetList);

            }
            if (this.StudentKey > 0 && this.StudentKey == o.StudentKey) {
              this.stuInfo = o;
              this.$set(o, 'isActive', true);
              console.log('this.stuInfo = o;', this.stuInfo);
            }
            this.assignStudentInfo.StudentList.push(o);
            // }
          });
        }, err => {
          console.log(err);
        });
      }, err => {
        console.log(err);
      });
    },
    // 获取课考勤学生列表.
    ClassStudentsArrangeQuery () {
      GetCourseInfoForReview(this.releaseData.RelateCourseID).then(res => {
        console.log('getCourseInfoForFSC', res.data);
        this.assignStudentInfo.StudentList = [];
        this.courseInfo = res.data;
        this.$store.commit('setCurrencyCount', 0);
        res.data.ArrangeStudentList.forEach(o => {
          this.$set(o, 'isActive', false);
          this.$set(o, 'Currency', 0);
          this.$set(o, 'ScTempKey', '');
          this.$set(o, 'ScTempKeyValue', '');
          this.$set(o, 'EssayAssessTempSetList', []);
          // o.isActive = false;

          // if (o.Status != 1 && o.AttenceStatusKey == 3) {
          //   this.assignStudentInfo.StudentList.push(o);
          // } else if (o.AttenceStatusKey == 3) {
          //   this.assignStudentInfo.StudentList.push(o);
          // }
          if (this.StudentKey > 0 && this.StudentKey == o.StudentKey) {
            this.stuInfo = o;
            this.$set(o, 'isActive', true);
            this.assignStudentInfo.StudentList.push(o);
          }
        });
        console.log(this.stuInfo, 'this.stuInfo');
      }, err => {
        console.log(err);
      });
    },
    saveClick (value) {
      this.IsSaveKey = !this.IsSaveKey;
    },
    // 选择课评模板
    doModuTitleClick () {
      this.isShowLessonEvaluatingVerbalLibrary = true;
      // this.$zxrouter.navigateTo({
      //   url: '/pages/home-school-communication/components/template-library/lesson-evaluating-verbal-library/index' +
      //     '?type=1'
      // });
    },
    // 点击学分详情的确定触发.
    confirmClickAfter (studentList) {
      this.assignStudentInfo.StudentList = studentList;
      // this.assignStudentInfo.StudentList.forEach(o => {
      //   studentList.forEach(p => {
      //     if (o.StudentKey == p.StudentKey) {
      //       o.Currency = p.Currency;
      //     }
      //   });
      // });
    },
    comfireAfter (callBack) {
      console.log('submitClick:this.releaseData', this.releaseData);
      if (this.vReleaseBtnClass) {
        if (typeof callBack === 'function') {
          callBack();
        }
        return;
      }
      if (this.releaseData.Content.length > 1000) {
        layer.alert('内容不能超过1000字');
        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      const uploadingImgList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 1 && obj.AttachUrl.indexOf('data:image/') >= 0;
      });
      if (uploadingImgList.length > 0) {
        layer.alert('图片正在上传中，请稍后提交');

        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      const imgList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 1;
      });
      if (imgList.length > 12) {
        layer.alert('图片不可超过12张');
        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      const uploadingVideoList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 2 && obj.VideoCoverUrl == '';
      });
      if (uploadingVideoList.length > 0) {
        layer.alert('视频正在上传中，请稍后提交');

        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      this.releaseData.Currency = 0;
      let num = 0;
      this.assignStudentInfo.StudentList.forEach(obj => {
        if (obj.isActive == true) {
          num++;
          let sutdentCurrency = 0
          obj.EssayAssessTempSetList.forEach(c => {
            sutdentCurrency += Number(c.TempSetStarGrade)
          })
          this.releaseData.Currency += Number(obj.Currency);
          this.releaseData.StudentList.push({
            StudentKey: obj.StudentKey,
            StudentKeyValue: obj.StudentKeyValue,
            HeadImg: obj.HeadImgSupport,
            Currency: Math.ceil((sutdentCurrency / obj.EssayAssessTempSetList.length) || 0),
            ScTempKey: obj.ScTempKey,
            ScTempKeyValue: obj.ScTempKeyValue,
            EssayAssessTempSetList: obj.EssayAssessTempSetList,
          });
        }
      });
      if (this.stuInfo.StudentKey && this.releaseData.StudentList.length == 0) {
        let sutdentCurrency = 0
        obj.EssayAssessTempSetList.forEach(c => {
          sutdentCurrency += Number(c.TempSetStarGrade)
        })
        this.releaseData.StudentList.push({
          StudentKey: this.stuInfo.StudentKey,
          StudentKeyValue: this.stuInfo.StudentKeyValue,
          HeadImg: this.stuInfo.HeadImgSupport,
          Currency: this.stuInfo.Currency
        });
      }
      // this.releaseData.AttachList.forEach(obj => {
      //   if (obj.AttachTypeKey == 3) {
      //     this.$set(obj, 'AudioSize', this.$refs.releaseContent.getAudioRecorderDuration());
      //   }
      // });
      this.releaseData.Currency = Math.ceil((this.releaseData.Currency / num) || 0);
      console.log('this.releaseData.Currency', this.releaseData.Currency);
      if (this.releaseData.StudentList.length == 0) {
        layer.alert('请先选择点评的学生');
        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      if (this.releaseData.essayID > 0) {
        EditReview(this.releaseData.essayID, this.releaseData).then(result => {
          console.log('EditReview', result.data);
          this.$emit('afterSuccess');
          this.$bus.emit('AfterReleaseEssay', this.dataInfo.scheduleCourseID);
          this.closeDialog();
        }, error => {
          if (typeof callBack === 'function') {
            callBack();
          }
          layer.alert(error.msg);
        });
      } else {
        PublishedReview(this.releaseData, this.IsSaveKey).then(result => {
          console.log('PublishedReview', result.data);
          this.$emit('afterSuccess');
          this.$bus.emit('AfterReleaseEssay', this.dataInfo.scheduleCourseID);
          this.closeDialog();
        }, error => {
          if (typeof callBack === 'function') {
            callBack();
          }
          layer.alert(error.msg);
        });
      }
    }
  }
};
</script>

<style>
</style>
