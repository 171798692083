<template>
    <div class="data_content">
        <div class="data_content_header pr teacher_atten_search">
            <input-search-contain-btn v-model="searchText"
                                 :placeholder="'学生名、首字母、手机号'"
                                 :clearAll="true"
                                 @clearSearch="searchText==''"
                                 @onSearchEvent="search"></input-search-contain-btn>
             <!-- <function-btn :item="comnmentBtnobj"
                            @clickItem="clickItem(comnmentBtnobj)"
                            :className="'data_content_item data_content_header_btn'">
              
            </function-btn> -->
            <function-btn :item="vStudentBtn"
                            @clickItem="clickItem(vStudentBtn)"
                            :className="'data_content_item data_content_header_btn_02'">
              
            </function-btn>
           
        </div>
        <div class="data_content_list">
           
            <div class="data_content_report"> 
                <div class="report_num" style="color:#FF9800;">{{Number(dataInfo.RenewalWarningCount)}}</div>
                 <div class="report_title">续费预警</div>
            </div>
            <div class="data_content_report"> 
                 <div class="report_num" style="color:#F75040;">{{Number(dataInfo.NotRenewalCount)}}</div>
                 <div class="report_title">未续失效</div>
            </div>
            <div class="data_content_report"> 
                 <div class="report_num" style="color:#F75040;">{{Number(dataInfo.AttendanceWarningCount)}}</div>
                 <div class="report_title">出勤率预警</div>
            </div>
            <div class="data_content_report"> 
                 <div class="report_num" style="color:#F75040;">{{Number(dataInfo.StuentNotOneCount)}}</div>
                 <div class="report_title">排课不足</div>
            </div>
            <div class="data_content_report"> 
                 <div class="report_num" style="color:#F75040;">{{Number(dataInfo.CampusWarningCount)}}</div>
                 <div class="report_title">离校预警</div>
            </div>
        </div>
         <!-- 分享二维码页面  -->
        <custom-dialog :title="'我的学生'"
                    :visible.sync="showStudentList"
                    :before-close="closeDialog">
            <student-list :searchText="searchText" :dataList="studentDataList" @closeDialog="closeDialog" @selectedItem="showStudentFile"></student-list>
        </custom-dialog>
    </div>
</template>
<script>
import {
  GetCourseStudentList,GetTeacherStudentList
} from '../../../API/workbench';
import studentList from './student-list'
export default {
    data(){
        return{
            studentDataList:[],
            showStudentList:false,
            comnmentBtnobj: {
            "name": "课评话术",
            "routertitle": "课评话术",
            "routerName": "teacherAttenClass",
            "auth": true,
            "roleName": "",
            "ModulePowerKey": 0,
            "img_type": "05",
             "moduleName": "commentReportList"
            },
            studentBtnobj: {
             "name": "我的学生",
            "routertitle": "课评话术",
            "routerName": "teacherAttenClass",
            "auth": true,
            "roleName": "",
            "ModulePowerKey": 0,
            "img_type": "05",
             "moduleName": "myStudentList"
            },
            studentBtnobj02: {
            "name": "我的学生",
            "routertitle": "课评话术",
            "routerName": "teacherAttenClass",
            "auth": true,
            "roleName": "",
            "ModulePowerKey": 0,
            "img_type": "05",
               "moduleName": "theacherAdiser"
            },
            searchText:'',
            optionList:[]
        }
    },
    components:{
        studentList
    },
    props:{
        dataInfo:Object
    },
    created(){
        this.optionList= this.$initJson.exceptionJson;
    },
    computed:{
        vtoken(){
            return this.$store.getters.token
        },
        vMendianInfo () {
            return this.$store.getters.SaaSClientInfo;
        },
        vStudentBtn(){
            if (!(this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 15)&&this.vtoken.IsTeacherAdviserKey==1)) {
                return this.studentBtnobj
            }else{
                return this.studentBtnobj02
            }
        }
   
    },
    methods:{
        getStudentList() {
            if (!(this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 15)&&this.vtoken.IsTeacherAdviserKey==1)) {
                this.studentDataList=[]
                GetTeacherStudentList().then((result) => {
                    console.log('GetCourseStudentList', result)
                    result.data.forEach(o => {
                        o.OLAPKey = o.StudentKey
                        o.MainDemoName = o.StudentKeyValue
                        let locaItem=this.studentDataList.find(item=>{
                            return o.OLAPKey==item.OLAPKey
                        })
                        if(!locaItem){
                            this.studentDataList.push(o)
                        }
                    });
                    this.showStudentList=true

                })
            }else{
                let seachObj= {
                TeacherKey: this.$store.getters.token.UserID,
                searchText: '',
                pageIndex: 0,
                pageSize: 0,
                sequence: '',
                orderBy: '',
                startTime: '',
                endTime: '',
                StatusType: 0
                }

                GetCourseStudentList(seachObj).then((result) => {
                    console.log('GetCourseStudentList', result)
                    this.studentDataList = result.data.PageDataList
                    this.showStudentList=true

                })
            }
           
     

        },
        closeDialog(){
            this.showStudentList=false
        },
        search(){
            console.log('点击搜索 搜索框可见 并查询数据');
            this.getStudentList()
            // this.$dialog.open(this, {
            //     routerName: this.$route.name,
            //     moduleName: 'studentList',
            //     data: {
            //           searchText:this.searchText
            //     },
            //     callBack: {
            //     selectedItem: (item) => {
            //         this.showStudentFile(item);
            //     }
            //     }
            // });
        },
        // 显示学生信息
        showStudentFile (item) {
            let moduleInfo = {
                name: '学生档案',
                routerName: this.$route.name,
                moduleName: 'studentFile',
                data: { studentID: item.OLAPKey }
            };
            this.$bus.emit('needOpenRouter', moduleInfo);
        },
        clickItem(item){
            this.$emit("doAfterClickFunctionBtn",item)
        }
    }
}
</script>
<style scoped>
.data_content{
  width: 100%;
  height: 159px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  box-shadow: 2px 2px 2px 0px #D7D7D733;
  margin-bottom: 20px;
  padding: 15px 20px;
}
.data_content_list{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.data_content_report{
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.data_content_report .report_num{
    margin-top: 20px;
      margin-bottom: 5px;
      font-size: 18px;
}
.data_content_header_btn_02{
    position: absolute;
    right: 2px;
    top: 0;
}
.data_content_header_btn{
    position: absolute;
    right: 83px;
    top: 0;
}
.data_content_item{   
     font-size:12px;
    color:#636A7C;
    width: 18%;
    height: 36px;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    margin-right: 2%;
    margin-bottom: 2%;
    text-align: center;
    line-height: 36px;
    height: 100%;
       cursor: pointer;

}
.data_content_item:hover{
    color: #3498db!important;
    border: 1px solid #3498db!important;
    border-radius: 4px;
}
.teacher_atten_search>>>.defult_dialog_searchform{
    padding: 0px;
      width: 78%;
}
.data_content_header>>>.student_search_form{
  
        background: #F2F3F8;
    border-radius: 100px;
    height: 36px;
}
.data_content_header>>>.btn_light_blue_search, 
.data_content_header>>>.list_btn_light_blue_search{
    bottom: 0px;
    right: 0px;
    color: white;
    width: 68px;
    height: 36px;
    background: #3498db!important;
    text-align: center;
    border-radius: 100px;
    




}

</style>