<template>
  <div class="backlog_list"
       @click.stop="seletedItem"
       :title="item.TypeKey<3||item.TypeKey==5||item.TypeKey==6||item.TypeKey==7?item.TypeKeyValue+'：'+item.Title:item.Title">
    <div class="backlog_list_name"
         v-if="item.TypeKey<3||item.TypeKey==5||item.TypeKey==6||item.TypeKey==7">{{item.TypeKeyValue}}：{{item.Title}}</div>
    <div class="backlog_list_name"
         v-else>{{item.Title}}</div>
    <div class="backlog_list_text">{{$utils.StrForTodoTime(item.GenerateTime)}}</div>
    <div class="list_btn"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number
  },
  computed: {

  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item, this.keyIndex);
    }
  }
};
</script>

