<template>
  <div>
    <div class="WKT_course_info_popup">
      <div class="from_input_list">
        <div class="from_input_title tr">
          课程
        </div>
        <div class="from_input_box font_input_seleted"
             @click.stop="showCourseNameListDialog">
          <input type="text"
                 v-model="dataInfo.CourseKeyValue"
                 disabled
                 placeholder="请选择课程">
        </div>
      </div>
      <div class="from_input_list">
        <div class="from_input_title tr">
          周几上课
        </div>
        <div class="from_input_weekUl">
          <week-item v-for="(item,key) in weekList"
                     :key="key"
                     :item="item"></week-item>
        </div>
      </div>
      <div class="from_textarea_box">
        <div class="from_textarea_title">
          课程描述
        </div>
        <div class="from_textarea_content">
          <textarea v-model="dataInfo.CourseIntro"
                    placeholder="请输入课程描述"
                    maxlength="1000"></textarea>
          <div class="from_textarea_content_btn"
               @click.stop="dataInfo.CourseIntro=''">
          </div>
        </div>
      </div>
      <div class="">
        <div class="WKT_slideshow_title">
          <span class="font_gray">图片尺寸：800px*604px</span>
        </div>
        <div class="WKT_slideshow_ul">
          <environment-show-picture-list v-for="(item,index) in dataInfo.ImgList"
                                         :Item="item"
                                         :Index="index"
                                         :key="index"
                                         :dataList="dataInfo.ImgList"
                                         @doChangeImageItemClick="doChangeImageItemClick"></environment-show-picture-list>
          <!-- 上传图片，最多只能添加4张 -->
          <div :style="dataInfo.ImgList.length<4?'':'opacity: 0;pointer-events: none'">
            <crop-upload-img ref="uploadImg"
                             :imgClass="''"
                             :imgHeight="604"
                             :imgWidth="800"
                             :isShrink="false"
                             @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
          </div>

        </div>
      </div>
      <div>

      </div>

    </div>
    <common-button-group @buttonClickEvent="submit"
                         @closeDialog="doAfterClickCancelBtn"></common-button-group>
    <!-- <custom-dialog title="选择课程"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeCourseNameList">
      
      <single-selection-list :dataSourceList="courseNameList"
                             :selectedKey="dataInfo.CourseKey"
                             :placeholder="'请选择课程'"
                             @onSelectedItem="doAfterSelectionCourseName"></single-selection-list>
      <save-cancel-btn-group :btnText="'下一步'"
                             @confirmClick="confirmSave"
                             @cancelClick="closePurchaseDialog"></save-cancel-btn-group>
    </custom-dialog> -->
  </div>

</template>
<script>
import weekItem from './week-item';
import environmentShowPictureList from './environment-show-picture-list';
import cropUploadImg from '../../common/crop-upload-img';
import { AddCourse, EditCourse, getCourseTypeNameListX } from '../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        'id': 0,
        'CourseKey': 0,
        'CourseKeyValue': '',
        'CourseIntro': '',
        'ClassTime': '',
        'sort': '',
        'ImgList': []
      },
      changeImgId: 0,
      weekList: [
        {
          value: '周一',
          isActive: false
        },
        {
          value: '周二',
          isActive: false
        }, {
          value: '周三',
          isActive: false
        },
        {
          value: '周四',
          isActive: false
        },
        {
          value: '周五',
          isActive: false
        },
        {
          value: '周六',
          isActive: false
        },
        {
          value: '周日',
          isActive: false
        }
      ],
      isShowCourseNameList: false,
      courseNameList: []
    };
  },
  components: {
    cropUploadImg, environmentShowPictureList, weekItem
  },
  props: {
    item: Object,
    dataList: Array
  },
  created () {
    // this.getCourseNameList();
    if (this.item && this.item.id > 0) {
      this.dataInfo = this.$utils.parseJson(this.item);
      this.weekList.forEach(o => {
        if (this.dataInfo.ClassTime.indexOf(o.value) != -1) {
          o.isActive = true;
        }
      });
    }
  },
  computed: {

  },
  methods: {
    submit (callback) {
      if (!this.checkForm()) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.dataInfo.id > 0) {
        this.editItem(callback);
      } else {
        this.dataInfo.sort = this.dataList.length + 1;
        this.addItem(callback);
      }
    },
    checkForm () {
      let ischeck = true;
      this.dataInfo.ClassTime = '';
      this.weekList.forEach(o => {
        if (o.isActive) {
          this.dataInfo.ClassTime += o.value + '、';
        }
      });
      this.dataInfo.ImgList.forEach((o, index) => {
        o.sort = index + 1;
      });
      if (this.dataInfo.CourseKeyValue == '') {
        ischeck = false;
        layer.alert('请选择课程');
      } else if (this.dataInfo.CourseIntro == '') {
        ischeck = false;
        layer.alert('请输入课程描述');
      } else if (this.dataInfo.ImgList.length == 0) {
        ischeck = false;
        layer.alert('请选择图片');
      }
      //  else if (this.dataInfo.ClassTime == '') {
      //   ischeck = false;
      //   layer.alert('请选择日期');
      // }
      // 处理掉最后的逗号
      if (this.dataInfo.ClassTime.lastIndexOf('、') == this.dataInfo.ClassTime.length - 1) {
        this.dataInfo.ClassTime = this.dataInfo.ClassTime.substring(0, this.dataInfo.ClassTime.lastIndexOf('、'));
      }
      console.log('this.dataInfo.ClassTime', this.dataInfo.ClassTime);
      return ischeck;
    },
    addItem (callback) {
      AddCourse(this.dataInfo).then(result => {
        layer.alert('新增成功');
        this.$emit('addAfter');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.log('AddStarStudents', error);
      });
    },
    editItem (callback) {
      // let obj = this.$utils.parseJson(this.item);
      // obj.StarStudentsName = this.value;
      EditCourse(this.dataInfo).then(result => {
        layer.alert('修改成功');
        this.$emit('addAfter');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.log('EditStarStudentsError', error);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    // 环境展示图(添加/修改)
    doAfterUploadImgSuccess (url, index) {
      this.changeImgId = index;
      console.log(url, '添加环境展示图', this.changeImgId);
      if (url) {
        if (this.changeImgId >= 0 && this.changeImgId < 4) { // 修改
          this.dataInfo.ImgList.forEach((o, index) => {
            if (index == this.changeImgId) { o.imgUrl = url; }
          });
          this.changeImgId = 0;
        } else { // 添加
          this.imgKey = this.dataInfo.ImgList.length == 0 ? 1 : this.dataInfo.ImgList.length;
          let imgObj = {
            imgUrl: url,
            dataID: 0
          };
          this.dataInfo.ImgList.push(imgObj);
          this.changeImgId = 0;
        }
      }
    },
    // 选择图片修改环境展示图
    doChangeImageItemClick (e, item, index) {
      console.log(index);
      let newIndex = index == 0 ? '0' : index;
      this.$refs.uploadImg.chooseImg(e, newIndex);
      // this.changeImgId = index;
    },
    // 关闭课程列表
    closeCourseNameList () {
      this.isShowCourseNameList = false;
    },
    // 打开课程
    showCourseNameListDialog () {
      this.isShowCourseNameList = true;
      this.$emit('showCourseNameListDialog');
    },
    doAfterSelectionCourseName (item) {
      console.log(item, 'doAfterSelectionCourseName');
      this.dataInfo.CourseKeyValue = item.CourseKeyValue;
      this.dataInfo.CourseKey = item.CourseKey;
      this.closeCourseNameList();
    }
    // getCourseNameList () {
    //   getCourseTypeNameListX('MainDemoName', 'asc').then(result => {
    //     this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
    //   });
    // }
  }
};
</script>

