<template>
  <div>
    <!-- <custom-dialog
      :title="formName"
      class="student_info_dialog defult_dialog"
      :visible.sync="isShowBox"
      :before-close="hiddenBox"
    > -->
    <div v-show="isShowBox">
      <!-- 搜索学生弹框 -->
      <div v-if="searchBoxShow">
        <searchBox :searchInitJson="newScheduleCourseInitJson"
                   @searchClick="chooseClick"></searchBox>
      </div>
      <!-- 学生列表弹框 -->
      <div v-if="singleSelectionListShow">
        <student-list :isShowSearchInput="false"
                      :studentList="studentList"
                      :switchItem="0"
                      @selectedItem="selectedItem"></student-list>
      </div>
    </div>
    <!-- </custom-dialog> -->
    <!-- <custom-dialog
      :title="formName"
      class="student_info_dialog defult_dialog"
      :visible.sync="isShowPayment"
      :before-close="hiddenPayment"
    > -->
    <div v-if="isShowPayment">
      <student-lable ref="studentLableBox"
                     class="form_info_top content_marginRL content_marginTop_30"
                     :studentID="selectedStudentInfo.OLAPKey"
                     @alterStudent="alterStudent"
                     :showMenu="true"></student-lable>
      <payment-and-withdraw ref="paymentandwithdraw"
                            :payOrWithdrawMethods="paymentTypeInfo[viewType].payOrWithdrawMethods"
                            :amount="paymentTypeInfo[viewType].amount"
                            :canAmount="paymentTypeInfo[viewType].canAmount"
                            :allClean="paymentTypeInfo[viewType].allClean"
                            :studentItem="selectedStudentInfo"
                            @closeshowWithDraw="hiddenPayment"
                            @showsucceedPayment="showsucceedPayment"
                            :cashRepayment="stundentCashRepayment"></payment-and-withdraw>
    </div>
    <!-- </custom-dialog>
    <custom-dialog
      :title="formName"
      class="refund_dialog defult_dialog"
      :visible.sync="isShowSucceedPayment"
      :before-close="closedsucceedPayment"
    > -->
    <div v-show="isShowSucceedPayment">
      <succeed-payment @closedsucceedPayment="closedsucceedPayment"></succeed-payment>
    </div>
    <!-- </custom-dialog> -->
  </div>
</template>
<script>
import searchBox from '../../common/search-box';
import studentList from '../../common/student-list';
import paymentAndWithdraw from '../../common/payment-and-withdraw';
import studentLable from '../../common/student-lable';
import { getStudentRelatedMsg } from '../../../API/workbench';
import succeedPayment from '../../common/succeed-payment';
export default {
  data () {
    return {
      formName: '客户还款',
      viewType: 0,
      stundentCashRepayment: '',
      searchBoxShow: true,
      singleSelectionListShow: false,
      isShowBox: false,
      isShowPayment: false,
      isShowSucceedPayment: false,
      studentList: [],
      selectedStudentInfo: {},
      newScheduleCourseInitJson: {
        name: '目标学生'
      },
      paymentTypeInfo: [
        {
          payOrWithdrawMethods: '还款方式',
          amount: '还款金额',
          canAmount: '欠款金额',
          allClean: '全部还清'
        },
        {
          payOrWithdrawMethods: '付款方式',
          amount: '提现金额',
          canAmount: '可提现金额',
          allClean: '全部提现'
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object
    },
    menuType: {
      type: [Number, Object]
    }
  },
  computed: {
    vIsShowBox () {
      if (this.isShowBox || this.isShowPayment || this.isShowSucceedPayment) {
        return true;
      } else {
        this.$emit('closePaymentDialog');
        return false;
      }
    }
  },
  components: {
    paymentAndWithdraw,
    searchBox,
    studentList,
    studentLable,
    succeedPayment
  },
  watch: {
    viewType (n, o) {
      if (n == 1) {
        this.stundentCashRepayment = this.selectedStudentInfo.SumAmount;
        this.formName = '客户提现';
      } else {
        this.stundentCashRepayment = this.selectedStudentInfo.DebtAmount;
        this.formName = '客户还款';
      }
    }
  },
  created () {
    console.log(this.dataInfo, this.menuType, 'this.dataInfo');
    if (parseFloat(this.menuType)) {
      this.viewType = this.menuType;
    }
    if (this.dataInfo && this.dataInfo.OLAPKey) {
      this.selectedItem(this.dataInfo);
    } else {
      this.doShowPopupShowClick();
    }
  },
  methods: {
    doShowPopupShowClick () {
      this.searchBoxShow = true;
      this.singleSelectionListShow = false;
      this.isShowBox = true;
    },
    chooseClick () {
      this.searchBoxShow = false;
      this.singleSelectionListShow = true;
    },
    selectedItem (item) {
      this.selectedStudentInfo = item;
      if (this.viewType == 1) {
        this.stundentCashRepayment = item.SumAmount;
        console.log('提现');
      } else {
        this.stundentCashRepayment = item.DebtAmount;
        console.log('还款');
      }

      this.singleSelectionListShow = false;
      this.isShowBox = false;
      this.isShowPayment = true;
    },
    hiddenBox () {
      this.isShowBox = false;
    },
    hiddenPayment () {
      this.isShowPayment = false;
      this.$emit('closePaymentDialog');
    },
    selectStundent () {
      this.isShowBox = true;
      this.singleSelectionListShow = true;
    },
    closedsucceedPayment () {
      this.isShowSucceedPayment = false;
      this.$emit('closePaymentDialog');
    },
    alterStudent (item) {
      if (this.viewType == 1) {
        this.stundentCashRepayment = item.SumAmount;
        console.log('提现');
      } else {
        this.stundentCashRepayment = item.DebtAmount;
        console.log('还款');
      }
      this.selectedStudentInfo = item;
      this.getStudentInfo();
      this.$refs.paymentandwithdraw.cashAmount = 0;
      this.$refs.paymentandwithdraw.studentInfo = item;
    },
    // 还款成功
    showsucceedPayment (Val) {
      this.isShowSucceedPayment = true;
      this.$emit('afterSucces', this.selectedStudentInfo.OLAPKey);
      this.$emit('upStudentFileInfo', this.selectedStudentInfo.OLAPKey); // 学生档案还款后用于刷新页面
      this.hiddenPayment();
    },
    // 获取学生信息
    getStudentInfo () {
      getStudentRelatedMsg(this.selectedStudentInfo.OLAPKey).then(
        (res) => {
          if (res.code == 0) {
            this.selectedStudentInfo = res.data;
          }
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>