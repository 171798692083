<template>
  <!--  -->
  <div class="top-md-info">
    <!-- 1 -->
    <!-- <div class="look_study_video_info"
         v-if="this.$store.getters.token.RoleKey == 2">
      <div class="shortcut_enable">快捷启用</div>
      <div class="teacher_manage_all">
        <div class="teacher_info add_teacher">1、添加老师</div>
        <div class="teacher_info upload_courseware">2、上传课件</div>
        <div class="teacher_info empower_teacher">3、授权老师</div>
        <div class="teacher_info teacher_download_app">4、老师下载APP并登录</div>
      </div>
      <div class="look_video">看视频学习</div>
    </div> -->
    <!-- 2 -->
    <div class="role_md_info">
      <!-- <div class="role_md_logo">
        <img :src="vMendianLogo"
             alt />
      </div> -->
      <div class="role_md_logo"
           :class="staffInfo.XMLIcon?'':'hide_md_logo'">
        <!-- staffInfo.XMLIcon  -->
        <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                                :staffInfo="staffInfo"
                                :formTitle="'员工头像:'"
                                :isImgEditText="false"
                                :imgHeight="64"
                                :imgWidth="64"
                                @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
      </div>
      <div class="info_img"
           :v-if="staffInfo.XMLIcon"
           :class="staffInfo.XMLIcon?'':vClass">
        <span>{{ MainDemoName }}</span>
      </div>
      <div class="role_text">
        <div class="r_t_one">Hi，{{$store.getters.token.UserName}}，欢迎回来！</div>
        <div class="roleOrChangePassword">
          <div class="r_t_two">当前角色：{{ vRoleKey }}</div>
          <div class="r_t_btn"
               @click="doEditStaff">修改密码</div>
        </div>

      </div>
      <div class="module_info">
        <div class="m_student_works"
             @click="doStudentWorksClick">学生作品</div>
        <div class="m_teaching_works"
             @click="goToteaching">教学模式</div>
      </div>
    </div>
    <!-- 3 -->
    <div class="my_application"
         v-if="this.$store.getters.token.RoleKey == 2">
      <div class="my_app_text">
        我的应用
      </div>
      <div class="todo_summarizing">
        <div class="cwscoursewareManager"
             v-for="(item,index) in vstatisticsList"
             :key="index"
             @click="doStatisticsClick(item)">
          <img :src="require('../../../../../public/image/courseware_icon_'+item.icon+'.png')"
               class="app_icon">
          <div class="courseware_text">{{ item.name }}</div>
        </div>
      </div>

    </div>
    <!-- 4 -->
    <div class="md_work_box">
      <new-right-box-work-bench @afterSuccess="reflashData"></new-right-box-work-bench>
    </div>
  </div>
</template>
<script>
import { GetTheEmployeeByID } from '../../../../API/workbench';
import newRightBoxWorkBench from '../../new-right-course-box/index';
export default {
  data () {
    return {
      MainDemoName: '',
      staffInfo: {},
      mendianInfo: this.$store.getters.SaaSClientInfo,
      statisticsList: [
        { name: '三方课件', key: 'cwscoursewareManager', icon: 'one', isShow: true },
        { name: '自研课件', key: 'cwsgeneralCoursewareManagement', icon: 'two', isShow: true },
        { name: '全部老师', key: 'cwsteacherManager', icon: 'three', isShow: true },
        { name: '课件使用账单', key: 'coursewareUseReport', icon: 'four', isShow: true },
        { name: '课包排序', key: 'coursePackageSort', icon: 'five', isShow: true },
        { name: '操作日志', key: 'operationLogReport', icon: 'six', isShow: true }
      ],
      CWSworksUploadObj: {
        name: '学生作品管理',
        routerName: 'worksUploadManagement',
        routertitle: '学生作品管理',
        auth: true,
        roleName: '',
        ModulePowerKey: 0,
        class: 'bg_yellow',
        firstTitle: 'L',
        moduleName: ''
      }
    };
  },
  components: {
    newRightBoxWorkBench
  },
  computed: {
    vstatisticsList () {
      let newArr = []
      let list = JSON.parse(JSON.stringify(this.statisticsList))
      list.forEach(o => {
        if (o.key == 'cwscoursewareManager') {
          if (Number(this.vMendianInfo.IsAuthorizeCoursewareKey == 0)) {
            o.isShow = false
          } else {
            o.isShow = true
          }
        }
        if (o.key == 'cwsgeneralCoursewareManagement') {

          // !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 12)
          if (Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)) {
            o.isShow = false
          } else {
            o.isShow = true
          }
        }
        if (o.isShow) {
          newArr.push(o)
        }
      })
      return newArr

    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vClass () {
      const n = Math.floor(1 + Math.random() * (9 - 1));
      return 'bg_' + n;
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vRoleKey () {
      if (Number(this.$store.getters.token.RoleKey) == 2) {
        return '校长';
      } else {
        return '老师';
      }
    },
    vMendianLogo () {
      let img = this.$store.getters.token.XMLIcon;
      if (!img) {
        return '';
      }
      if (img.indexOf('http') > -1) {
        return img;
      } else {
        return (
          this.$store.getters.CDNURL +
          this.$store.getters.token.XMLIcon
        );
      }
    }
  },
  created () {
    this.token = this.$store.getters.token;
    this.getStaffInfo(this.token.UserID);
    console.log(this.$store.getters, 'this.$store.getters');
  },
  mounted () {

  },
  methods: {
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey).then(result => {
        this.staffInfo = result.data;
        if (callback) {
          callback();
        }
        this.MainDemoName = this.staffInfo.MainDemoName.slice(0, 1);
        console.log('getStaffInfo', result.data);
      });
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      console.log('ImgURl', this.staffInfo);
      this.staffInfo.XMLIcon = ImgURl;
    },
    doStatisticsClick (item) {
      let obj = {
        name: item.name,
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: item.key,
        callBack: { close: () => { } }
      };
      if (item.key == 'cwscoursewareManager') {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: 0,
          name: item.name,
          title: item.name,
          moduleName: 'cwscoursewareManager',
          data: {},
          callBack: {
            close: () => { }
          }
        });
      } else if (item.key == 'cwsgeneralCoursewareManagement') {
        let type = this.$store.getters.SaaSClientInfo.IsOpenSelfCoursewareKey;
        if (type == 0) {
          layer.alert('未开通此功能');
          return;
        }
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: 0,
          name: item.name,
          moduleName: 'cwsgeneralCoursewareManagement',
          data: {},
          callBack: {
            close: () => { },
            afterSuccess: () => { }
          }
        });
      } else if (item.key == 'operationLogReport') {
        let routerObj = {
          name: item.name,
          routerName: item.key,
          routertitle: item.name,
          auth: true,
          roleName: '',
          ModulePowerKey: 154,
          class: 'bg_yellow',
          firstTitle: 'L',
          moduleName: ''
        };

        console.log(routerObj, 'routerObj');
        this.$bus.emit('needOpenRouter', routerObj);
      } else if (item.key == 'coursePackageSort') {
        let coursePackageSortObj = {
          name: '课包排序',
          routerName: 'coursePackageSort',
          routertitle: '课包排序',
          auth: true,
          roleName: '',
          ModulePowerKey: 0,
          class: 'bg_yellow',
          firstTitle: 'L',
          moduleName: [118, 123]
        };
        this.$bus.emit('needOpenRouter', coursePackageSortObj);
      } else if (item.key == 'coursewareUseReport') {
        let StartTime = this.$utils.formatDateToLine(Date.new()); //	字符串	必须		数据源：GET	起始时间
        let EndTime = this.$utils.formatDateToLine(Date.new()); //	字符串	必须		数据源：GET	结束时间
        this.$dialog.open(this, {
          name: '课件使用账单',
          routerName: this.$route.name,
          moduleName: 'coursewareUseReport',
          data: {
            dataInfo: {
              TeacherKey: '',
              StartTime: StartTime,
              EndTime: EndTime,
              BigCoursewarePackageKey: '',
              // Source: this.authorizationHead[0].isSelected ? 1 : 2
              Source: this.$store.getters.AcademicMdCwType
            }
          }
        });
      } else {
        this.$bus.emit('openCustomDialog', obj);
      }
    },
    reflashData () {
      this.$refs.leftBox.reflashData();
    },
    doStudentWorksClick () {
      this.$bus.emit('needOpenRouter', this.CWSworksUploadObj);
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    doEditStaff () {
      this.$dialog.open(this, {
        name: '修改密码',
        routerName: this.$route.name,
        moduleName: 'editPassWord',
        callBack: { afterSucces: (data) => { } }
      });
    },
    goToteaching () {
      this.$store.commit('SetPopupShade', false);
      let moduleInfo = {
        routerName: 'teachingModeView',
        routertitle: '教学模式',
        ModulePowerKey: 0
      };
      if (this.vHasModulePower(moduleInfo.ModulePowerKey)) {
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        this.$utils.CheckModulePowerToTips(moduleInfo.ModulePowerKey);
      }
    }
  }
};
</script>

<style scoped>
.look_study_video_info {
  height: 46px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 96, 0, 1);
  box-shadow: 0px 3px 8px rgba(255, 96, 0, 0.2);
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
.shortcut_enable {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 22px;
  color: rgba(228, 62, 9, 1);
  text-align: left;
  vertical-align: middle;
  margin-top: 12px;
  margin-left: 20px;
}
.teacher_info {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  vertical-align: middle;
  margin-left: 40px;
}
.look_video {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  color: rgba(255, 96, 0, 1);
  text-align: left;
  vertical-align: middle;
  position: absolute;
  right: 35px;
  margin-top: 12px;
  cursor: pointer;
}
.look_video::before {
  content: '';
  display: flex;
  position: absolute;
  width: 14px;
  height: 14px;
  right: -19px;
  top: 5px;
  background-image: url('../../../../../public/image/look-icon.png');
  background-repeat: no-repeat;
}
.teacher_manage_all {
  display: flex;
  margin-top: 12px;
}
.role_md_info {
  width: 964px;
  height: 148px;
  border-radius: 8px;
  background-image: url('../../../../../public/image/role_md_bj.png');
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  box-shadow: 0px 1px 5px rgba(222, 225, 230, 0.6);
  margin-bottom: 20px;
  display: flex;
  position: relative;
}
.role_md_logo {
  margin-top: 18px;
  margin-left: 18px;
}
.role_md_logo img {
  width: 64px;
  height: 64px;
  border-radius: 83.5px;
  cursor: pointer;
}
.role_text {
  margin-top: 18px;
  margin-left: 40px;
  line-height: 35px;
}
.r_t_one {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 36px;
  color: rgba(51, 51, 51, 1);
}
.r_t_two {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  vertical-align: top;
}
.module_info {
  position: absolute;
  display: flex;
  left: 120px;
  top: 100px;
}
.m_student_works,
.m_teaching_works {
  width: 119px;
  height: 36px;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  text-align: left;
  vertical-align: middle;
  margin-left: 4px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  border-radius: 8px;

  background: linear-gradient(
      225.5deg,
      rgb(255, 96, 0) 50%,
      rgb(255, 137, 0) 100%
    ),
    rgb(0, 0, 0);
  cursor: pointer;
}
.roleOrChangePassword {
  display: flex;
}
.r_t_btn {
  margin-left: 33px;
  cursor: pointer;
  color: #e43e09;
}
.m_student_works:hover,
.m_teaching_works:hover {
  background: linear-gradient(
      225.5deg,
      rgb(255, 96, 0) 0%,
      rgb(255, 137, 0) 100%
    ),
    rgb(0, 0, 0);
}
/* 3 */
.my_application {
  height: 185px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 5px rgba(222, 225, 230, 0.6);
}
.my_app_text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  padding: 23px 0 20px 50px;
  position: relative;
}
.my_app_text::after {
  content: '';
  width: 25px;
  height: 25px;
  display: flex;
  position: absolute;
  top: 18px;
  left: 20px;
  background: url('../../../../../public/image/my_app_icon.png');
}
.todo_summarizing {
  display: flex;
  /* justify-content: space-around; */
  text-align: center;
  line-height: 45px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  margin-left: 6px;
}
.cwscoursewareManager {
  width: 90px;
  padding-top: 10px;
  cursor: pointer;
  margin-left: 25px;
}
.cwscoursewareManager:hover {
  border-radius: 4px;
  background: rgba(217, 223, 228, 0.4);
}
.md_work_box {
  margin-top: 20px;
}
.role_md_logo .teacher_img::after {
  content: '';
  width: 20px;
  height: 20px;
  display: none !important;
}
.role_md_logo .img-logo {
  cursor: pointer;
  top: -17px !important;
  height: 80px !important;
}
.info_img {
  width: 64px;
  height: 64px;
  top: 17px;
  left: 17px;
  border-radius: 83.5px;
  cursor: pointer;
  position: absolute;
  text-align: center;
  line-height: 64px;
  font-size: 25px;
  color: #fff;
}
.hide_md_logo {
  opacity: 0 !important;
  z-index: 6;
}
.bg_1 {
  background-color: #bad7df;
}

.bg_2 {
  background-color: #769fcd;
}

.bg_3 {
  background-color: #99ddcc;
}

.bg_4 {
  background-color: #fbafaf;
}

.bg_5 {
  background-color: #f2c6b4;
}

.bg_6 {
  background-color: #b9bbdf;
}
.bg_7 {
  background-color: #99e1e5;
}

.bg_8 {
  background-color: #c1c0b9;
}
</style>
