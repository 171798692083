<!-- 面容识别 -->
<template>
 <el-table-column :fixed="columnData.fixed||false"
                              :label="columnData.label"
                              :prop="columnData.prop"
                              :width="columnData.width||60"
                              :align="columnData.align||'left'"
                              :label-class-name="columnData.columnClass">
  <template slot-scope="scope">
    <div  class="faceRecognition">
      <!-- 未刷脸 -->
      <div v-if="Number(scope.row[columnData.prop])==0"
            class="faceRecognition_icon no_type">
      </div>
      <!-- 已刷脸 -->
      <div v-if="Number( scope.row[columnData.prop])>0"
            class="faceRecognition_icon"></div>
    </div>
  </template>
</el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            studentKey: 'StudentKey',
            studentKeyValue: 'StudentKeyValue',
            click: () => {}
          }
        };
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData) {
      if (typeof this.columnData.extend.click == 'function') {
        let item = {
          routerName: this.$route.name,
          moduleName: 'arrangeSupportEdit',
          name: '学生：' + rowData[this.columnData.extend.studentKeyValue],
          data: { studentKey: rowData[this.columnData.extend.studentKey], content: rowData[this.columnData.prop] },
          callBack: {updateSuccess: (val) => {
            rowData[this.columnData.prop] = val;
          }}
        };
        this.$dialog.open(this, item);
      }
    }
  }
};
</script>

<style>

</style>