<template>

  <div class="student_choose_all"
       style="font-size: 12px"
       v-if="isBatchAttendance">
    <div class="student_choose_all_tip">
      <span class="point_red">{{vbantchTypeValueTwo}}学生选中后，点"{{vbantchTypeValue}}"。不{{vbantchTypeValueTwo}}学生，点学生反选。</span>
    </div>
    <div class="select_course_title_right choose_all_btngroup form_footer_btn_group">
      <div @click.stop="cancleBatchAttendance()"
           class="btn_white btn_hover_white">取消</div>
      <div @click.stop="confirmBatchAttendance()"
           class="btn_blue  btn_hover_bg_blue">{{vbantchTypeValue}}</div>
    </div>
  </div>
</template>   
<script>
export default {
  name: 'selectAllTip',
  data () {
    return {

    };
  },
  props: ['isBatchAttendance', 'bantchType'],
  computed: {
    vbantchTypeValue () {
      if (this.bantchType == 3) {
        return '确认签到';
      } else if (this.bantchType == 4) {
        return '确认请假';
      } else if (this.bantchType == 5) {
        return '确认旷课';
      } else if (this.bantchType == 6) {
        return '确认退课';
      }
    },
    vbantchTypeValueTwo () {
      if (this.bantchType == 3) {
        return '签到';
      } else if (this.bantchType == 4) {
        return '请假';
      } else if (this.bantchType == 5) {
        return '旷课';
      } else if (this.bantchType == 6) {
        return '退课';
      }
    }
  },
  methods: {
    // 取消批量考勤
    cancleBatchAttendance () {
      this.$emit('cancleBatchAttendance');
    },
    // 确认批量考勤
    confirmBatchAttendance () {
      this.$emit('confirmBatchAttendance');
    }
  }

};
</script>

