<template>
  <div :id="echartID"
       :class="[vClass]"></div>
</template>
<script>
export default {
  name: 'doubleTypeEchart',
  data () {
    return {
      chartItem: {
        resize: ''
      },
      echart: null
    };
  },
  props: {
    echartID: String,
    reqList: Array,
    nowDataList: Array,      // 当前
    preDataList: Array,    // 上一个.
    rotate: String,
    echartClass: String,
    echartType: String,
    maxType: String,
    monthTitle: {
      type: String,
      default: ''
    },
    secondTitle: {
      type: String,
      default: ''
    }
  },
  computed: {

    vClass () {
      if (this.echartClass) {
        return this.echartClass;
      } else {
        return 'echarts_m';
      }
    },
    maxSize () {
      let count = 0;
      for (var i = 0; i < this.nowDataList.length; i++) {
        count += Number(this.nowDataList[i]);
      }
      if (this.preDataList) {
        for (var j = 0; j < this.preDataList.length; j++) {
          count += Number(this.preDataList[j]);
        }
      }
      if (this.maxType == 'rate') {
        return 100;
      } else if (count == 0) {
        return 10;
      }
    }
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.initEchartBar();
    },
    // 柱形图初始.
    initEchartBar () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}' + this.monthTitle + '<br>{c}' + this.secondTitle
          // formatter: (e) => {
          //   return e.data.value + '元';
          // }
        },
        grid: {
          top: '30',
          left: '4%',
          right: '4%',
          bottom: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.reqList,
          axisLabel: {
            textStyle: {
              color: '#c0c4cc'
            },
            rotate: 0,
            // 文字过长时隐藏
            formatter: function (value) {
              return (value.length > 8 ? (value.slice(0, 8) + '...') : value);
            }
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          z: 10
        },
        yAxis: {
          min: 0,
          max: this.maxSize,
          type: 'value',
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#f0f0f2']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['#fff', '#fff']
            }
          }
        },
        series: [{ // For shadow
          type: 'bar',
          barWidth: 10,
          itemStyle: {
            barBorderWidth: 1,
            // barBorderRadius: [4, 4, 4, 4],
            color: '#4e83fd',
            barBorderColor: '#4e83fd',
            abel: {
              show: true,
              textStyle: {
                color: '#27727B'
              }
            }
          },
          data: this.nowDataList
        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    }

  }
};
</script>

