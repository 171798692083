<template>
  <div>
      <div class="table_select_box">
      <div class="table_select_title">{{selectTitle}}</div>
      <el-select :value="vSelectedName"
                 filterable
                 :placeholder="placeholder"
                 @change="changeEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in dataSourceList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    selectTitle:{//名称
      type:String,
      required:true
    },
    dataSourceList:{//下拉数据源
      type:Array,
      required:true,
      default:()=>{
        return[]
      }
    },
    selectionKey:{
      type:[String,Number]
    },
    placeholder:{
      type:String,
      default:()=>{
        return '必选'
      }
    }
  },
  computed:{
    vSelectedName(){
      let item = this.dataSourceList.find(obj=>{return obj.OLAPKey==this.selectionKey});
      let name ='';
      if(item){
        name =item.MainDemoName;
      }
      return name;
    }
  },
  created(){
  },
  methods:{
    changeEvent(item){
      this.$emit('changeEvent',item)
    }
  }
}
</script>

<style>

</style>