<template>
  <div class="fl"
       :class="{opt:tabItem.isSelected}"
       @click="clickEvent">
    {{tabItem.title}}
  </div>
</template>

<script>
export default {
  props: {
    tabItem: {
      type: Object,
      required: true,
      default: () => {
        return { title: '循环课表', isSelected: true, type: 0 };
      }
    }
  },
  methods: {
    clickEvent () {
      this.$emit('clickEvent', this.tabItem);
    }
  }
};
</script>

<style>
</style>