<template>
  <div class="course_dispense_right">
    <div class="course_dispense_right_title">
      <span>分发</span>
      <div class="title_btn button"
           v-if="dispenseCourseList.length>0"
           @click="cancelSelect">取消</div>
    </div>
    <!-- 课件选择列表 -->
    <div class="course_dispense_right_content">
      <!-- 选择分发的课件 -->
      <div v-if="dispenseCourseList.length>0">
        <div class="course_dispense_right_info">
          <div class="info_name">{{dispenseCourseList[0].GradeClassKeyValue}}</div>
          <div class="info_text">{{dispenseCourseList[0].CourseNameKeyValue}}丨{{dispenseCourseList[0].MTeacherKeyValue}}</div>
        </div>
        <!-- 课件内容 -->
        <div class="course_dispense_right_ul">
          <div class="course_dispense_list">
            <course-dispense-info-block v-for="(item,key) in dispenseCourseList"
                                        :dataInfo="item"
                                        :key="key"></course-dispense-info-block>
            
          </div>
          <div class="course_dispense_right_btn button"
                 @click="doSelectAfterDistribute">选择课件分发</div>
        </div>
      </div>
      <div class="course_dispense_right_nodata"
           v-else>
        <div>请先在左侧选择希望</div>
        <div>分发课件的循环课</div>
      </div>
    </div>
    <!-- 步骤提示 -->
    <course-dispense-tips></course-dispense-tips>
  </div>
</template>

<script>
import courseDispenseInfoBlock from './course-dispense-info-block';
import courseDispenseTips from './course-dispense-tips';
export default {
  components: {
    courseDispenseInfoBlock,
    courseDispenseTips
  },
  data () {
    return {
      dispenseCourseList: [] // 循环课列表
    };
  },
  props: {
    selectCourseList: Array,
    coursewareDistributeRuleList: Array
  },
  watch: {
    selectCourseList (n, v) {
      this.dispenseCourseList = n;
      // this.dispenseCourseList.sort((x, y) => {  return Date.new(y.AuthorizeTime) - Date.new(x.AuthorizeTime);});
      console.log(this.dispenseCourseList, 'selectCourseList');
    }
  },

  created () {
    this.dispenseCourseList = this.selectCourseList;
  },
  methods: {
    // 取消选择
    cancelSelect () {
      this.$emit('cancelSelect');
    },
    // 选择课件分发
    doSelectAfterDistribute () {
      let existsIndex = -1;
      if (this.dispenseCourseList[0].CDID) {
        existsIndex = this.coursewareDistributeRuleList.findIndex(obj => {
          return obj.isOpt && (this.dispenseCourseList[0].CDID != obj.CDID);
        });
      }
      if (existsIndex >= 0) {
        layer.confirm('当前选择分发的循环课组合与以前选择的组合不同，请问是否继续?', {
          btn: [{
            name: '确认',
            callBack: () => {
              this.$emit('showAllocationCourseware');
              layer.close();
            }
          }
          ]
        });
      } else {
        this.$emit('showAllocationCourseware');
      }
    }
  }
};
</script>

<style>
</style>