<template>
  <div class="attract_investment_manage all_width table_list_content summarizing_list cooperativeManagement">
    <div class="investor_manage_top pr cooperativeManagement_top">
      <div class="statistics-box cooperativeManagement_top_left">
        <statisticsBox :dataList="useData"
                       @clickEvent="clcikQueryTypeLeft"></statisticsBox>
      </div>
      <div class="cooperativeManagement_top_right">
        <div :class=" isSelecType == 4 ? 'cooperativeManagement_top_right_expire_IS' : 'cooperativeManagement_top_right_expire'"
             @click="clcikQueryTypeRight(4)">
          <div class="cooperativeManagement_top_right_expire_title">端口到期</div>
          <div :class=" isSelecType == 4 ? 'cooperativeManagement_top_right_expire_text_IS' : 'cooperativeManagement_top_right_expire_text'">{{expire == null ? '0' : expire}}</div>
        </div>
        <div class="cooperativeManagement_top_right_center"></div>
        <div :class=" isSelecType == 5 ? 'cooperativeManagement_top_right_soonExpireSum_IS' : 'cooperativeManagement_top_right_soonExpireSum'"
             @click="clcikQueryTypeRight(5)">
          <div class="cooperativeManagement_top_right_soonExpireSum_title">端口到期预警</div>
          <div :class=" isSelecType == 5 ? 'cooperativeManagement_top_right_soonExpireSum_text_IS' : 'cooperativeManagement_top_right_soonExpireSum_text'">
            {{soonExpireSum == null ? '0' : soonExpireSum}}</div>
        </div>
      </div>
    </div>

    <InUse ref="InUse"
           :defaultSort="defaultSort"
           @viewClick="viewClick"
           @loadTableData="loadTableDataList"
           :totalNum="totalNum"
           :dataInfo="dataInfo"
           @afterSuccess="reloadStatictisData"></InUse>

    <custom-dialog :title="'修改课程合作校区'"
                   :visible.sync="isShowChainCampusFormPopup"
                   :before-close="closeChainCampusFormPopup">
      <retailCampusEditForm :CampusKey="Number(campusEditIndo.OLAPKey)"
                            :isShowInput="false"
                            @close="closeChainCampusFormPopup"
                            :isReadonly="false"
                            :noShowJW="true"
                            @afterSuccess="reloadStatictisData"></retailCampusEditForm>
    </custom-dialog>
  </div>
</template>

<script>
import {
  SearchPageCourseCooperationList
} from '../../../API/workbench';
import retailCampusEditForm from '../headquarters-management/campus-management/form/retail-campus-edit-form.vue';
import statisticsBox from '../../report/common/statistics-box'; // 统计栏
import InUse from './InUse'; // 使用中
export default {
  name: 'courseCooperation',

  components: {
    statisticsBox,
    InUse,
    retailCampusEditForm
  },

  props: {
    SelecType: {
      type: [Number, String],
      default: 0
    }
  },

  data () {
    return {
      dataInfo: [],
      isShowChainCampusFormPopup: false,
      curShowTableList: null, // 当前显示的页面 // 使用中 、续费预警 、已停用
      isShowInvestorDetails: false, // 是否显示详情
      isSelecType: this.SelecType,
      useData: {
        newInvestor: {
          title: '使用中',
          value: '',
          type: 'InUse',
          isSelection: true,
          index: 1
        },
        following: {
          title: '续费预警',
          numberClassName: 'font_ar',
          value: '',
          type: 'ARenewalWarning',
          isSelection: false,
          index: 2
        },
        giveup: {
          title: '已停用',
          numberClassName: 'font_hbd',
          value: '',
          type: 'HasBeenDiscontinued',
          isSelection: false,
          index: 3
        }
      },
      defaultSort: {},
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：校区名，账户ID，校区负责人手机号
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序字段
        TypeKey: 1 //	整型	必须		数据源：GET	查询状态: 1-使用中; 2-续费预警; 3-已停用; 4-端口到期; 5-端口到期预警
      },
      totalNum: 0,
      expire: null, // 端口到期数据
      soonExpireSum: null, // 端口到期预警数据
      campusEditIndo: {}
    };
  },

  created () {

  },

  mounted () {
    this.curShowTableList = this.useData.newInvestor;
    this.initData();
    this.reloadStatictisData();
  },

  methods: {
    // 初始化接收外面传参。
    initData () {
      let outSideParams = this.$route.params.data || {};
      let type = outSideParams.type || 1;
      if (type == 1) {
        this.curShowTableList = this.useData.newInvestor;
      } else {
        for (let key in this.useData) {
          this.useData[key].isSelection = false;
        }
      }
      this.isSelecType = type;
      this.curShowTableList = type;
      this.params.TypeKey = type;
      this.$refs.InUse.params.searchText = '';
      this.params.searchText = '';
      this.reloadStatictisData();

      console.log(type, 'initData');
    },
    // 使用、续费预警、已停用
    clcikQueryTypeLeft (type) {
      console.log(type, 'clcikQueryTypeLeft');
      // this.outSideParams = {};
      this.dataInfo = [];
      this.isSelecType = 0;
      this.curShowTableList = type;
      this.params.TypeKey = type.index;
      this.$refs.InUse.initParams();
      this.params.searchText = '';
      this.params.pageIndex = 0;
      this.params.orderBy = '';
      this.reloadStatictisData(1);
    },
    clcikQueryTypeRight (type) {
      // this.outSideParams = {};
      this.dataInfo = [];
      for (let key in this.useData) {
        this.useData[key].isSelection = false;
      }
      this.isSelecType = type;
      this.curShowTableList = type;
      this.params.TypeKey = type;
      this.$refs.InUse.initParams();
      this.params.searchText = '';
      this.params.pageIndex = 0;
      this.params.orderBy = '';
      this.reloadStatictisData(1);
    },
    // 分页查询课程合作List
    reloadStatictisData (val) {
      console.log(val, '分页传入数据是否是1');
      if (val == 1) {
        this.$refs.InUse.$refs.tableListRef.expandRowKeys = [];
      }
      SearchPageCourseCooperationList(this.params).then(res => {
        this.dataInfo = [];
        this.dataInfo = res.data.PageDataList;
        this.dataInfo.forEach(o => {
          if (o.StatusKey == 0) {
            o.StatusKeyValue = '已暂停';
          } else if (o.StatusKey == 1) {
            o.StatusKeyValue = '已过期';
          } else if (o.StatusKey == 2) {
            o.StatusKeyValue = '使用中';
          }
          o.CoursewareChannelExpiredTimeText = o.CoursewareChannelExpiredTime + ' ' + o.CoursewareChannelExpiredTimeNum + '天';
          if (o.AuthorizeMinimum == 0) {
            o.AuthorizeMinimum = '-';
          } else {
            o.AuthorizeMinimum = o.AuthorizeMinimum + '天';
          }
        });
        this.useData.newInvestor.value = res.data.InUseSum || 0;
        this.useData.following.value = res.data.RenewalAlertSum || 0;
        this.useData.giveup.value = res.data.DeactivatedSum || 0;
        this.totalNum = res.data.Total;
        this.expire = res.data.expireSum;
        this.soonExpireSum = res.data.soonExpireSum;
        console.log(this.dataInfo, res, '分页查询课程合作List');
      });
    },
    // 条件查询
    loadTableDataList (queryParams) {
      // queryParams.orderBy = (queryParams.orderBy == 'SysStatusKeyValue' ? 'SysStatusKey' : queryParams.orderBy);
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      this.reloadStatictisData();
    },
    // 关闭
    closeChainCampusFormPopup () {
      this.isShowChainCampusFormPopup = false;
    },
    // getSearchPageForCampus() {
    //   this.reloadStatictisData()
    // },
    // 查看
    viewClick (rowData) {
      console.log('viewClickviewClick');
      this.campusEditIndo = rowData;
      this.isShowChainCampusFormPopup = true;
    }
    // 转换时间 date1-开始时间  date2-结束时间  days-转换天数
    // getDaysBetween(date1, date2) {
    //   let startDate = new Date(date1)
    //   let endDate = new Date(date2)
    //   if (startDate > endDate) {
    //     return 0
    //   }
    //   if (startDate == endDate) {
    //     return 1
    //   }
    //   let days = (endDate - startDate) / (1000*3600*24)
    //   return days
    // }
  }
};
</script>

<style scoped>
.cooperativeManagement {
  margin: 20px auto;
  width: 1368px;
  border-radius: 8px;
}

.cooperativeManagement_top {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.cooperativeManagement_top_left {
  width: 332px;
}

.cooperativeManagement_top_right {
  width: 341px;
  border: 1px solid #ececec;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.cooperativeManagement_top_right_expire {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  color: #333333;
  border-bottom: 3px solid #ffffff;
}
.cooperativeManagement_top_right_expire_IS {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  color: #3498db;
  border-bottom: 3px solid #3498db;
}

.cooperativeManagement_top_right_expire_title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  margin-right: 10px;
}

.cooperativeManagement_top_right_expire_text {
  font-family: 'Arial';
  font-style: normal;
  font-size: 24px;
  color: #ff6c67;
}
.cooperativeManagement_top_right_expire_text_IS {
  font-family: 'Arial';
  font-style: normal;
  font-size: 24px;
  color: #3498db;
}

.cooperativeManagement_top_right_center {
  width: 1px;
  height: 30px;
  background: #ececec;
}

.cooperativeManagement_top_right_soonExpireSum {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  color: #333333;
  border-bottom: 3px solid #ffffff;
}
.cooperativeManagement_top_right_soonExpireSum_IS {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  color: #3498db;
  border-bottom: 3px solid #3498db;
}

.cooperativeManagement_top_right_soonExpireSum_title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  margin-right: 10px;
}

.cooperativeManagement_top_right_soonExpireSum_text {
  font-family: 'Arial';
  font-style: normal;
  font-size: 24px;
  color: #ff8723;
}
.cooperativeManagement_top_right_soonExpireSum_text_IS {
  font-family: 'Arial';
  font-style: normal;
  font-size: 24px;
  color: #3498db;
}
</style>
