<template>
  <div class="ZSJK_bottom_view WKT_activity_compile_popup pr">
    <editor-view ref="editor"
                 :scaleWidth="300"
                 :scaleHeight="535"
                 :dataInfo="playInfo"
                 :CouponInfo="couponInfo"
                 :PosterInfo="posterInfo"></editor-view>
    <div class="ZSJK_bottom_QRcode_btn"
         @click="clickQRCode">

    </div>
    <div v-show="isShowQrCode"
         class="ZSJK_bottom_QRcode_box"
         @click="isShowQrCode = false">
      <!-- <div id="QRCode"
           class="QRcode_box"></div> -->
      <qrcode-vue class="QRcode_box"
                  :value="codeValue"
                  :size="codeValue?210:0"
                  level="L"
                  id="QRCode" />
    </div>
  </div>
</template>
<script>
import {
  GetActivitiesCouponInfo,
  GetActivitiesPlayByID
} from '../../../API/workbench.js';
import editorView from '../../recruit-student-show/component/ishow-editor-dialog/component/editor-view';
import QrcodeVue from 'qrcode.vue';
export default {
  data () {
    return {
      codeValue: '',
      playInfo: {},
      couponInfo: {},
      posterInfo: {},
      isShowQrCode: false
    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  components: {
    editorView,
    QrcodeVue
  },
  created () {
    this.getActivitiesPlay();
  },
  methods: {
    closePopup () {
      this.$emit('closePopup');
    },
    // 点击二维码
    clickQRCode () {
      this.isShowQrCode = !this.isShowQrCode;
      // document.getElementById('QRCode').innerHTML = ''; // 清除二维码
      let twoCode = this.$store.getters.wktDomainURL.replace('http:', 'https:') + '/do/shareCouponToGet.php?coupon_id=' + this.dataInfo.id + '&source_type=1&share_user_id=0&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey;
      // var qrcode = new QRCode(document.getElementById('QRCode'), {
      //   correctLevel: QRCode.CorrectLevel.L // 容错级别，
      // });
      // qrcode.makeCode(twoCode);

      let ENV = 'release'
      // if (process.env.NODE_ENV === 'development') {
      //   ENV = 'develop'
      // } else {
      //   if(window.zxConfig.Product_ENV==='product'){
      //     ENV = 'release'
      //   }else{
      //     ENV = 'trial'
      //   }
      // }
      // let parms='targetSaaSClientID='+this.$store.getters.SaaSClientInfo.OLAPKey+'&coupon_id='+this.dataInfo.id+'&source_type=4&share_user_id='+this.$store.getters.token.UserID+'&SaaSClientKey='+this.$store.getters.SaaSClientInfo.OLAPKey+'&isMPopen=1'
      // let twoCode=this.$store.getters.SaaSClientInfo.MiniProgramUrl+'/'+ENV+'?targetRouterParms='+encodeURIComponent(parms)

      //let twoCode='https://zxbeta.zhixuerj.com/mp/10419/trial?targetRouterParms=targetSaaSClientID%3D10017%26coupon_id%3D3061%26source_type%3D4%26share_user_id%3D32704%26SaaSClientKey%3D10017%26isMPopen%3D1'
      // let twoCode ='https://zxbeta.zhixuerj.com/mp/10419/'+ENV+'?targetRouterParms=targetSaaSClientID='+this.$store.getters.SaaSClientInfo.OLAPKey+'&coupon_id=' + this.dataInfo.id + '&source_type=1&share_user_id=' + this.$store.getters.token.UserID + '&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey;

      this.codeValue = twoCode;
    },
    getActivitiesCouponInfo () {
      GetActivitiesCouponInfo(this.dataInfo.id).then(
        (res) => {
          this.couponInfo = res.data.CouponInfo;
          this.initResult(res.data.PosterInfo);
          this.posterInfo = res.data.PosterInfo;
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    initResult (data) {
      data.isTemplate = true;
      data.pageJson = JSON.parse(data.pageJson) || [];
      // 初始化获取.
      if (!data.settingJson) {
        data.settingJson = {
          footerNav: false,
          isMusic: false,
          musicTarget: {}
        };
      } else {
        console.log(data.settingJson, 'data.settingJson');
        data.settingJson = JSON.parse(data.settingJson);
        if (!data.settingJson.musicTarget) {
          data.settingJson.musicTarget = {};
        }
        if (!data.settingJson.isMusic) {
          data.settingJson.isMusic = false;
        }
        if (!data.settingJson.footerNav) {
          data.settingJson.footerNav = false;
        }
      }
      data.picTemp = data.picTemp || '';
      data.pageJson.forEach(o => {
        o.elJson.forEach(p => {
          p.isActive = false;
          p.animate = p.animate || [];
          p.isLock = p.isLock || false;
          p.width = Number(p.width) || 'auto';
          p.height = Number(p.height) || 'auto';
          p.form = p.form || {};
          p.specialName = p.specialName || '';
          p.fontSet = p.fontSet || {
            fontID: 0,
            fontFamily: '',
            fontFacePath: '',
            fontFaceID: ''
          };
          if (p.type == '204' || p.type == '205') {
            if (p.width == 'auto') {
              p.width = '185px';
            } else {
              p.width = p.width.toString() + 'px';
            }
          }
          if (p.type == '206' || p.type == '207') {
            if (p.width == 'auto') {
              p.width = '640px';
            } else {
              p.width = p.width.toString() + 'px';
            }
            // 轮播
            if (p.type == '206') {
              console.log('206', p.form);
              p.form = !Array.isArray(p.form) ? p.form : { imgList: [] };
            }
            // 封面
            if (p.type == '207') {
              if (this.coverInfo && !p.content) {
                data.pic = this.coverInfo.coverimg;
                p.content = this.coverInfo.coverimg;
              }
            }
          }
          if (p.type == '208') {
            p.form = {
              t_address: this.couponInfo.t_address,
              t_telephone: this.couponInfo.t_telephone,
              t_company: this.couponInfo.t_company,
              logo: p.form.logo
            };
          }
          p.content = p.content.toString();
          if (p.fontSet.fontID > 0) {
            this.loadOnlineFont(p.fontSet);
          }
        });
      });
      if (this.vSaasClientInfo.WktInfo) {
        data.name = data.name || this.vSaasClientInfo.WktInfo.name;
        data.address = data.address || this.vSaasClientInfo.WktInfo.address;
        data.tel = data.tel || this.vSaasClientInfo.WktInfo.tel;
      }
      return data;
    },
    // 在线字体load
    loadOnlineFont (fontSet) {
      console.log('this.$store.getters.PCWebURL', this.$store.getters.CDNURL + fontSet.fontFacePath);
      var f = new FontFace(fontSet.fontFamily, 'url(' + this.$store.getters.CDNURL + fontSet.fontFacePath + ')', {});
      f.load().then(loadedFace => {
        document.fonts.add(loadedFace);
      });
    },
    getActivitiesPlay () {
      GetActivitiesPlayByID(this.dataInfo.playID).then(
        (res) => {
          res.data.posterTemplateID = 0;
          res.data.id = this.dataInfo.id;
          this.playInfo = res.data;
          this.getActivitiesCouponInfo();
        },
        (err) => {
          console.log(err.msg);
        }
      );
    }
  }
};
</script>