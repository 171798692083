<template>
  <li class="package_box_li"
      @click="chgCoureDateAfter">
    <div class="package_box_li_title">{{ item.CoursewarePackageKeyValue }}</div>
    <div class="left_box_list_div">
      <span class="left_box_list_two_span"><span class="lesson_preparation_img"></span> 备课：<span v-if="Number(item.PrepareLessonsCount)!=0&&Number(item.CoursewareNum)!=0"
              style="color: #333">{{ item.PrepareLessonsCount}}/{{ item.CoursewareNum }}</span>
        <span class="font_red"
              v-else>未备课</span>
      </span>
    </div>
    <div class="left_box_list_div">
      <span class="left_box_list_two_span">
        <span>
          <span class="lesson_preparation_img1"></span>
          上课：<span style="color: #333"
                v-if="Number(item.CourseCount)!=0&&Number(item.CourseTimeCount)!=0">
            {{ item.CourseCount}}课次&nbsp&nbsp|&nbsp&nbsp&nbsp {{ item.CourseTimeCount}}分钟
          </span>
          <span class="font_red"
                v-else>未上课</span>
        </span>

      </span>
    </div>
    <span class="left_box_line"></span>
    <div class="left_box_list_div">
      <span class="left_box_list_two_span"><span class="lesson_preparation_img2"></span> 课件：<span style="color: #333">{{ item.CoursewareNum }}</span><span class="left_box_list_two_a">详情</span></span>
    </div>
  </li>
</template>
<script>
export default {
  components: {},
  props: ['item'],
  data () {
    return {
    };
  },

  created () { },
  mounted () { },
  methods: {
    getItem () { },
    chgCoureDateAfter () {
      this.$emit('chgCoureDateAfter', this.item)
    }
  }
};
</script>
<style scoped>
.left_box_list_div {
  position: relative;
  margin-top: 8px;
  margin-left: 25px;
}
.package_box_li {
  display: block;
  height: 141px;
  width: 258px;
  background: #f7f8fa;
  border-radius: 4px;
  margin: 5px 8px 5px 5px;
  float: left;
  cursor: pointer;
}
.package_box_li_title {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  font-family: Arial;
}
.left_box_line {
  display: block;
  width: 258px;
  height: 1px;
  background: #ececec;
  margin-top: 10px;
}
.left_box_list_two_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #999999;
  padding-left: 5px;
}
.lesson_preparation_img {
  top: 3px;
  left: -15px;
  position: absolute;
  width: 15px;
  height: 15px;
  color: #999;
  background: url(../../../../../public/cw-img/prepare_lessons.png) no-repeat
    50%;
  background-size: 14px;
}
.lesson_preparation_img1 {
  top: 3px;
  left: -15px;
  position: absolute;
  width: 15px;
  height: 15px;
  color: #999;
  background: url(../../../../../public/cw-img/attend_class.png) no-repeat 50%;
  background-size: 14px;
}
.lesson_preparation_img2 {
  top: 3px;
  left: -15px;
  position: absolute;
  width: 15px;
  height: 15px;
  color: #999;
  background: url(../../../../../public/cw-img/courseware.png) no-repeat 50%;
  background-size: 14px;
}
.left_box_list_two_a {
  position: absolute;
  top: 3px;
  right: 10px;
  width: 30px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3498db;
}
</style>