<template>
  <!--   -->
  <div v-if="showBox && courseData.CourseNameKeyValue">
    <div @click.stop="hideBox">
      <div :style="{'top':vTop,'left':vLeft}"
           class="course_popup_label"
           @mouseover.stop="keepShow"
           @mouseleave="closeShow"
           @click.stop="boxShow">
        <div class="label_title">
          <div class="label_name">
            {{courseData.CourseNameKeyValue}}
          </div>
          <div class="label_course">{{courseData.week}}&nbsp;&nbsp;{{courseData.BeginClassTime}}&nbsp;&nbsp;{{formatDate(courseData.ClassTime)}}&nbsp;&nbsp;上课消耗: {{Number(courseData.PeriodCount)}}课时/人</div>
          <div v-if="courseData.ArrangedNumCount - courseData.AttenceCount > 0"
               class="label_attendance">未考勤{{courseData.ArrangedNumCount - courseData.AttenceCount}}人</div>
          <div @click="hideBox"
               class="label_attendance_close"></div>
        </div>
        <div class="label_info clearfix">
          <div class="label_info_list list_icon01">
            <div class="label_info_list_name">{{courseData.GradeClassKeyValue}}</div>
          </div>
          <div class="label_info_list list_icon02">
            <div class="label_info_list_name">{{courseData.MTeacherKeyValue}}</div>
          </div>
          <div class="label_info_list list_icon03">
            <div class="label_info_list_name">{{courseData.classRoomName}}</div>
          </div>
        </div>
        <div class="label_progress">
          <div class="label_progress_text clearfix">
            <div class="label_progress_text_list">
              <span class="list_orange">签到: {{Number(courseData.ArrivedNumCount)}}人</span>
            </div>
            <div class="label_progress_text_list tc">
              <span class="list_yellow">安排: {{Number(courseData.ArrangedNumCount)}}人</span>
            </div>
            <div class="label_progress_text_list tr">
              <span class="list_gray">满员: {{Number(courseData.FullPeopleCount)}}人</span>
            </div>
          </div>
          <div class="label_progress_gray">
            <div :style="{width:courseData.ArrangedPercentage + '%'}"
                 class="label_progress_middle"
                 v-if="Number(courseData.AttenceCount)!=0||Number(courseData.ArrangedNumCount)!=0||Number(courseData.FullPeopleCount)!=0"></div>
            <div :style="{width:courseData.AttencePercentage + '%'}"
                 class="label_progress_top"></div>
          </div>
        </div>
        <div class="course_option_btn">
          <function-btn v-if="!noBtn"
                        :item="authBtn.attnArrange"
                        @clickItem="toAttendance"
                        :className="'el-button el-button--default el-button--small'"></function-btn>
          <div class="menu_left_list icon_10 pr"
               @mouseover.stop="mouseOverNewCourseTable"
               @mouseout.stop="mouseOutNewCourseTable">
            <el-button size="small"
                       v-if="!noBtn"
                       :disabled="btnChangeEnable">新建课</el-button>
            <div class="module_content_menuList"
                 style="left:38px"
                 v-show="isShowNewCourseType"
                 @mouseover.stop="mouseOverNewCourseTable">
              <div class="top_bg"
                   style="top:-42px"></div>
              <div style="margin-top: -40px;">
                <drop-down-box :optionJsonList="newScheduleCourseObjType"
                               @clickOptionForAlertDialog="doAfterSelectedNewCourseTableType"></drop-down-box>
              </div>
            </div>
          </div>
          <el-button size="small"
                     @click.stop="toNoAttendance"
                     v-if="!noBtn">查看课程</el-button>
          <function-btn v-if="!noBtn"
                        :item="authBtn.editCourse"
                        @clickItem="toAlterCourse"
                        :className="'el-button el-button--default el-button--small'"></function-btn>
          <function-btn v-if="!noBtn"
                        :item="authBtn.stopCourse"
                        @clickItem="courseSuspend"
                        :className="'el-button el-button--default el-button--small'"></function-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PauseScheduleCourse, getCourseDetailed } from '../../../API/workbench';
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      showBox: false,
      // vTop: 300
      upTop: 300,
      vtop: 0,
      courseData: {},
      modulePowerList: [], // 权限列表
      // 权限控制
      authBtn: {
        attnArrange: {
          name: '安排考勤',
          ModulePowerKey: 51
        },
        editCourse: {
          name: '修改课程',
          ModulePowerKey: 47
        },
        stopCourse: {
          name: '停课',
          ModulePowerKey: 47
        },
        addSingleCourse: {
          name: '新建课',
          ModulePowerKey: 47
        }
        // addAroundCourse: {
        //   name: "新建循环课",
        //   ModulePowerKey: 47
        // }
      },
      newScheduleCourseObjType: [
        {
          id: '3',
          name: '日历建课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '新建循环课',
          ModulePowerKey: 47,
          isShow: true
        }
      ],
      isShowNewCourseType: false
    };
  },
  props: {
    // courseData: Object,
    vTop: String,
    vLeft: String,
    noBtn: Boolean,
    showContent: Boolean
  },
  components: {
    dropDownBox
  },
  created () {
    this.modulePowerList = this.$store.getters.RolePowerList;
    if (this.showBox) {
      this.initPopupDialogBoxStyle();
    }
    if (this.noBtn) {
      this.showBox = true;
    }
  },
  computed: {
    // 计算是否超过30天
    isExpireDate () {
      // 2592000000 为一个月的时间戳.
      console.log(this.courseData, 'isExpireDate');
      let classTime =
        this.courseData.ClassTime + ' ' + this.courseData.BeginClassTime;
      if (Date.new(classTime).getTime() + 5184000000 > Date.new().getTime()) {
        return true;
      } else {
        return false;
      }
    },
    // 已完结的课程不可点击
    btnChangeEnable () {
      let presentDate = this.$utils.formatDateToLine(Date.new());
      if (this.courseData.ClassTime > presentDate) {
        return false;
      } else if (this.courseData.ClassTime == presentDate) {
        if (this.courseData.StatusKey == 2) {
          // 2:未开始，4：已完结
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    showBox (c, o) {
      if (c) {
        this.initPopupDialogBoxStyle();
      }
    }
  },
  methods: {
    showGetData (OLAPKey) {
      this.showBox = true;
      this.getCourseDetailedByID(OLAPKey);
    },
    getCourseDetailedByID (OLAPKey) {
      if (!OLAPKey) {
        return false;
      }
      getCourseDetailed(OLAPKey).then(result => {
        let e = result.data;
        if (Number(e.ArrangedNumCount) <= Number(e.FullPeopleCount)) {
          if (
            Number(e.AttenceCount) == 0 &&
            Number(e.FullPeopleCount) == 0
          ) {
            e.AttencePercentage = 0;
          } else {
            e.AttencePercentage = Math.ceil(
              (Number(e.AttenceCount) / Number(e.FullPeopleCount)) * 100
            );
          }
          if (
            Number(e.ArrangedNumCount) == 0 &&
            Number(e.FullPeopleCount) == 0
          ) {
            e.ArrangedPercentage = 0;
          } else {
            e.ArrangedPercentage = Math.ceil(
              (Number(e.ArrangedNumCount) / Number(e.FullPeopleCount)) *
              100
            );
          }
        } else {
          if (
            Number(e.AttenceCount) == 0 &&
            Number(e.ArrangedNumCount) == 0
          ) {
            e.AttencePercentage = 0;
          } else {
            e.AttencePercentage = Math.ceil(
              (Number(e.AttenceCount) / Number(e.ArrangedNumCount)) *
              100
            );
          }
          e.ArrangedPercentage = 100;
        }
        this.courseData = e;
        this.courseData.OLAPKey = this.courseData.ScheduleCourseKey;
      }).catch(err => {
        console.log(err, 'error');
      });
    },
    // 是否有权限.
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    // 计算滚动条高度
    initPopupDialogBoxStyle () {
      let top = 0;
      if (this.showBox) {
        if (process.env.NODE_ENV !== 'development') {
          // console.log('this.width', this.width);
          // console.log('dialogHeight', dialogHeight);
          // console.log(
          //   "clientHeight",
          //   window.parent.document.getElementById("Form").clientHeight
          // );
          // console.log(
          //   "scrollTop",
          //   window.parent.document.getElementById("Form").scrollTop
          // );
          if (window.parent.document.getElementById('Form')) {
            top =
              window.parent.document.getElementById('Form').scrollTop - 100 <= 0
                ? Number(this.upTop)
                : window.parent.document.getElementById('Form').scrollTop -
                100 +
                Number(this.upTop);
          }
        } else {
          top = 300;
        }
        this.vtop = top + 'px';
      }
    },
    hideBox () {
      this.showBox = false;
      this.$emit('hideBox');
    },
    formatDate (date) {
      if (date) {
        let arr = date.split('-');
        return arr[1] + '/' + arr[2];
      }
    },
    toAlterCourse () {
      this.$emit('chgEditCourseShow', true);
    },
    // 停课
    courseSuspend () {
      var that = this;
      layer.confirm('是否停课', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: function () {
              that.setCourseCancel(1);
              layer.close();
            }
          }
        ]
      });
    },
    // 根据课名ID 停课.
    setCourseCancel (IsNotEndKey) {
      if (!this.courseData.OLAPKey) {
        return false;
      }
      PauseScheduleCourse(this, this.courseData.OLAPKey, IsNotEndKey).then(
        result => {
          layer.alert('停课成功');
          this.hideBox();
          this.$emit('chgCourse');
        },
        error => {
          if (error.code == 70001) {
            layer.confirm(error.msg, {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    this.setCourseCancel(0);
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        }
      );
    },
    toAttendance () {
      this.$emit('unAttendanceItem', this.courseData);
      // this.$router.push({ path: 'attendanceArrange', query: { id: this.courseData.OLAPKey, title: '安排考勤'} });
    },
    toNoAttendance () {
      // this.hideBox();
      this.$emit('chgCourseDetailShow');
      // this.$router.push({ path: 'attendanceArrange', query: { id: this.courseData.OLAPKey, title: '查看课程'} });
    },
    // 进去新建课表
    toNewschedulecourse (type) {
      if (this.vHasModulePower(47)) {
        this.$emit('toScheduleCourse', this.courseData, type);
      } else {
        this.$utils.CheckModulePowerToTips(47);
      }
    },
    keepShow () {
      console.log('keepShow');
      this.$emit('keepShow', true);
    },
    closeShow () {
      this.$emit('closeShow');
    },
    boxShow () {
      this.$emit('keepShow', true);
    },
    // 鼠标移到新建课
    mouseOverNewCourseTable () {
      clearTimeout(this.timer);
      this.isShowNewCourseType = true;
    },
    // 鼠标离开新建课
    mouseOutNewCourseTable () {
      this.timer = setTimeout(() => {
        this.isShowNewCourseType = false;
      }, 100);
    },
    // 新建课表 选中课类型（多课 单课）
    doAfterSelectedNewCourseTableType (item) {
      this.isShowNewCourseType = false;
      this.showBox = false;
      this.toNewschedulecourse(item.id);
    }
  }
};
</script>