<template>
  <div class="brand_info border_bottom pr">
    <div class="YK_added_content_name">
      <span>品牌信息</span>
    </div>
    <div class="YK_added_content_form">
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">品牌名称</span>
        </div>
        <div class="form_list_content">
          {{dataInfo.BrandName}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">加盟方式</span>
        </div>
        <div class="form_list_content">
          {{vFranchiseType}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">门店数量</span>
        </div>
        <div class="form_list_content">
          自营店：{{Number(dataInfo.DirectSaleStoreCount)||0}}
          <span style="padding: 0 8px;">|</span>
          整店加盟：{{Number(dataInfo.WholeStoreCount)||0}}
          <span style="padding: 0 8px;">|</span>
          课程加盟：{{Number(dataInfo.CourseStoreCount)||0}}
          <span style="padding: 0 8px;">|</span>
          门店总数：{{Number(dataInfo.StoreCount)||0}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">1年内加盟数</span>
        </div>
        <div class="form_list_content">
          整店加盟：{{Number(dataInfo.OneYearWholeStoreCount)}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box"></span>
        </div>
        <div class="form_list_content">
          课程加盟：{{Number(dataInfo.OneYearCourseStoreCount)}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">品牌简介</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_textarea">
            <textarea style="padding: 0;"
                      v-model="dataInfo.BrandIntroduction"
                      readonly></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: Object,
  },
  computed: {
    vFranchiseType() {
      let str = ''
      if (this.dataInfo.FranchiseTypeKey == 1) {
        str = '整店加盟 | 课程加盟'
      } else if (this.dataInfo.FranchiseTypeKey == 2) {
        str = '整店加盟'
      } else if (this.dataInfo.FranchiseTypeKey == 3) {
        str = '课程加盟'
      }
      return str
    },
  },
}
</script>

<style >
.brand_info .YK_added_content_form .form_list_textarea textarea {
  border: 0px solid #ececec;
}
</style>