<template>
  <div class="weChat_template">
    <div class="weixin_setting_form form_info_color clerafix">
      <!-- 模板内容 -->
      <div class="message_template_box">
        <div v-if="selectedInfo.id">
          <message-content :messageTitle="templateName"
                           :templateInfo="selectedInfo"
                           :recentlyTime="recentlyTime"
                           :isReadonly="true"
                           :isManualTemplate="false"
                           :selectEventID="eventId"></message-content>

        </div>
        <div v-else-if="!loading&&templateList.length==0">
          <div class="template_content_nodata_txt">未设置模版消息，请在门店设置中进行设置。</div>
        </div>
        <div v-else>
          <div class="template_content_nodata_txt">加载中...</div>
        </div>
      </div>
      <!-- 按钮组 -->
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'发送'"
                             @confirmClick="confirmEdit"
                             @cancelClick="cancelEdit"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import messageContent from './message-content';
import {
  PreviewDebtClassHourNotification,
  PreviewArrearageNotification,
  PreviewLackClassHourNotification,
  SelectTemplate,
  GetTemplateMsg,
  ArrearageNotification,
  DebtClassHourNotification,
  LackClassHourNotification
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      templateList: [], // 获取模板列表
      templateInfo: {}, // 获取模板消息设置
      templateReplaceInfo: [], // 获取事件标签显示
      selectedInfo: {}, // 模板选择项
      templateName: '', // 模板名称
      recentlyTime: '', // 上次发送时间
      loading: true,
      // 提交
      parameterSubmit: {
        eventId: '',
        id: '',
        templateId: '',
        url: '',
        incontent: {}
      }
    };
  },
  props: {
    StudentKey: [String, Number], // 学生ID（必填）
    eventId: Number // 模板事件ID（必填）
  },
  components: {
    messageContent
  },

  created () {
    this.getDataInfo();
    console.log(this.StudentKey, '学生ID');
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    // 获取模板
    getDataInfo () {
      SelectTemplate().then(result => { // 获取模板列表
        console.log(result, '获取模板列表0');
        this.loading = false;
        this.templateList = result.data;
        GetTemplateMsg(this.eventId).then(res => { // 根据模板id匹配找到该元素数据模板
          this.templateInfo = res.data;
          console.log(this.templateInfo, '消息设置1');
          if (this.templateList.length > 0) {
            this.templateList.forEach(item => {
              if (this.templateInfo && this.templateInfo.module_id == item.id) {
                this.templateName = item.title;
                if (this.templateInfo.incontent) {
                  this.templateInfo.incontent.forEach(r => {
                    item.incontent.forEach(e => {
                      if (e.key == r.key) {
                        e.valueNameInit = r.value;
                        e.valueName = r.value;
                      }
                    });
                  });
                }
                console.log(item, '选中模板');
                this.matchingTemplate(item);
              }
            });
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 根据事件ID获取模板标签内容
    matchingTemplate (item) {
      switch (this.eventId) {
        case 35:// 欠费提醒
          PreviewArrearageNotification(this.StudentKey).then(res => {
            console.log(res.data, '欠费提醒');
            this.recentlyTime = res.data.RecentlyTime;
            this.templateReplaceInfo = res.data.param;
            this.replaceResult(item, this.templateReplaceInfo);
          });
          break;
        case 36:// 欠课时提醒
          PreviewDebtClassHourNotification(this.StudentKey).then(res => {
            console.log(res.data, '欠课时提醒');
            this.recentlyTime = res.data.RecentlyTime;
            this.templateReplaceInfo = res.data.param;
            this.replaceResult(item, this.templateReplaceInfo);
          });
          break;
        case 37:// 课时不足提醒
          PreviewLackClassHourNotification(this.StudentKey).then(res => {
            console.log(res.data, '课时不足提醒');
            this.recentlyTime = res.data.RecentlyTime;
            this.templateReplaceInfo = res.data.param;
            this.replaceResult(item, this.templateReplaceInfo);
          });
          break;
        default:
          break;
      }
    },
    // 替换模板内容
    replaceResult (item, templateReplaceInfo) {
      if (item.incontent && templateReplaceInfo.length > 0) {
        item.incontent.forEach(r => {
          templateReplaceInfo.forEach(it => {
            var defaultImg = require('../../../../../../public/image/X_icon.png') + '"';
            var keyVale = it.value;// 防止门店信息标签与输入时内容显示问题
            if (r.valueName) {
              r.valueName = r.valueName.replace(it.key, `<span contenteditable="false" style="display: inline;" class="remover_block templeateField font_block" onclick="doSelectedLabelShow(this)">${keyVale}<img class="remover_button" src="${defaultImg}" alt="" onclick="removeTemplateFieldForNoticeSetting(this)"></span>&nbsp`);
              r.valueName = r.valueName.replace('\n', `<br>`);
            }
          });
        });
      }
      this.selectedInfo = item;
      console.log(this.selectedInfo, '显示完整模板信息');
    },
    // 确认保存
    confirmEdit (callBack) {
      switch (this.eventId) {
        case 35:// 发送欠费通知
          ArrearageNotification(this.StudentKey).then(res => {
            console.log(res.data, '发送欠费通知');
            if (res.data.errcode && res.data.errcode != 0) {
              layer.alert(res.data.errtip);
            } else {
              layer.alert('发送成功');
              this.cancelEdit();
            }
          }, err => {
            layer.alert(err.msg);
          }).finally(() => {
            if (callBack) {
              callBack();
            }
          });
          break;
        case 36:// 发送欠课时通知
          DebtClassHourNotification(this.StudentKey).then(res => {
            console.log(res.data, '欠课时提醒');
            if (res.data.errcode && res.data.errcode != 0) {
              layer.alert(res.data.errtip);
            } else {
              layer.alert('发送成功');
              this.cancelEdit();
            }
          }, err => {
            layer.alert(err.msg);
          }).finally(() => {
            if (callBack) {
              callBack();
            }
          });
          break;
        case 37:// 发送课时不足通知
          LackClassHourNotification(this.StudentKey).then(res => {
            console.log(res.data, '课时不足提醒');
            layer.alert('发送成功');
            this.cancelEdit();
          }, err => {
            layer.alert(err.msg);
          }).finally(() => {
            if (callBack) {
              callBack();
            }
          });
          break;
        default:
          break;
      }
      // if (this.mendianInfo.WktInfo.PatternKey == 1) {
      //   var param = {};
      //   if (this.selectedInfo.incontent) {
      //     this.selectedInfo.incontent.forEach(o => {
      //       param[o.key] = o.valueNameInit;
      //     });
      //   }
      //   this.parameterSubmit = {
      //     eventId: this.selectEventType ? this.eventId : null,
      //     id: this.templateInfo ? this.templateInfo.message_id : null,
      //     templateId: this.selectedInfo ? this.selectedInfo.id : null,
      //     url: '',
      //     incontent: param
      //   };
      //   AddOrEditTemplateMsg(this.parameterSubmit).then(res => {
      //     console.log(res.data, '确认保存');
      //     this.cancelEdit();
      //   }, err => {
      //     layer.alert(err.msg);
      //   }).finally(() => {
      //     if (callBack) {
      //       callBack();
      //     }
      //   });
      // }
    },
    // 取消保存
    cancelEdit () {
      this.$emit('closeDialog');
    }

  }

};
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  not supported by any browser */
}
.snow-container {
  border: 1px solid #3498db;
  color: #3498db;
  /* padding-right: 5px; */
}
.message_template_box .banner_content_text .first_text img {
  display: none;
}
</style>
