<template>
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      热门活动
      <div class="title_btn btn_hover_bg_blue"
           @click="onActivityAdministration">
        活动管理
      </div>
    </div>
    <div class="WKT_index_from_content">
      <div>
        <activity-list :activitiesCouponList="activitiesCouponList"></activity-list>
      </div>
      <div class="WKT_discount_package_list">
        <div class="WKT_discount_package_blcok">
          <add-box :titleName="'选择活动'"
                   @onAddBoxClick="openSelectActivity"></add-box>
        </div>
      </div>
    </div>
    <custom-dialog :title="'选择活动'"
                   :visible.sync="isSelectActivityListShow"
                   :before-close="doActivityListCloseClick">
      <span class="defult_dialog_header_btn fr"
            @click="doAddActivityClick()">
        <span class="btn_add">+</span>创建活动
      </span>
      <div>
        <activity-list :isShowbtn="false"
                     class="WKT_student_activity_popup_ul"
                     :activitiesCouponList="historyActivitiesList"
                     @refreshActivityInfo="onSelectActivity"
                     @doSelectActivity="doSelectActivity"></activity-list>
      </div>
      <div v-if="historyActivitiesList.length == 0">
        <div class="has_arrange_course"
             style="height:320px;margin-top:100px">
          <div class="has_arrange_course_content"
               style="padding: 0px">
            <div class="has_arrange_course_list">
              <div class="has_arrange_course_nodata single_shadow">
                <span>亲，当前没有任何数据哦~</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import addBox from '../common/add-box';
import activityList from './activity-list/index';
import { SelectActivitiesCoupon, PutAwayActivitiesCoupon, SoldOutActivitiesCoupon } from '../../../API/workbench';

export default {
  components: { addBox, activityList },
  data () {
    return {
      // 查询热门活动
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: 'sort', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: 'asc', //	字符串	可选		数据源：GET	 排序方向
        searchText: '', //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
        online: '' //	字符串	可选		数据源：GET	 是否上架0-否;1-是
      },
      total: 1,
      activitiesCouponList: [], // 已上架的活动
      historyActivitiesList: [], // 已下架的活动
      isSelectActivityListShow: false
    };
  },
  props: {

  },
  created () {
    this.getSelectActivitiesCoupon();
    this.$bus.on(this, 'selectActivitiesCoupon', () => {
      this.getSelectActivitiesCoupon();
    });
  },
  watch: {

  },
  computed: {
    // 开启销售中心
    vOpenUpSellSetting(){
        return this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule,6)
    },
  },
  activated(){
    this.total = 1;
  },
  methods: {
    // 获取热门活动列表
    getSelectActivitiesCoupon () {
      this.parameter.online = 1;
      this.parameter.pageIndex = 0;
      this.parameter.pageSize = 0;
      SelectActivitiesCoupon(this.parameter).then(result => {
        this.activitiesCouponList = result.data.PageDataList;
        console.log(this.activitiesCouponList, '获取热门活动列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 打开活动管理
    onActivityAdministration () {
      if(this.vOpenUpSellSetting){
         this.$router.push({ path: '/recruitStudentShow' });
      }else{
        this.$utils.CheckOpenSettingToTips(6);
      }
     
    },
    openSelectActivity (){
      this.isSelectActivityListShow = true;
      this.parameter.pageIndex = 0;
      this.historyActivitiesList = [];
      this.onSelectActivity();
    },
    // 显示选择活动列表弹窗 历史
    onSelectActivity () {

      if(this.historyActivitiesList.length < this.total){
        this.parameter.online = 0;
        this.parameter.pageSize = 5;
        SelectActivitiesCoupon(this.parameter).then(result => { // 过滤掉已上架和已过期的活动
          this.total = result.data.Total;
          result.data.PageDataList.forEach(e => {
            this.historyActivitiesList.push(e);
          });
          if(this.historyActivitiesList.length < this.total){
            this.parameter.pageIndex++;
          }
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 创建活动
    doAddActivityClick () {
      if(this.vOpenUpSellSetting){
          this.$router.push({ name: 'recruitStudentShow' });
      }else{
          this.$utils.CheckOpenSettingToTips(6);
      }
     
    },
    // 上架活动
    doSelectActivity (Item) {
      PutAwayActivitiesCoupon(Item.id).then(result => {
        this.getSelectActivitiesCoupon();
        this.isSelectActivityListShow = false;
        this.activitiesCouponList.push(Item);
        layer.alert('上架成功');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭活动列表弹窗
    doActivityListCloseClick () {
      this.isSelectActivityListShow = false;
    }

  }
};
</script>
