<template>
  <div style="height: 100%">
    <custom-dialog :title="formTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAddStudent"
                   :before-close="doShowPopupHideClick">
      <function-btn :item="addbtn"
                    class="button"
                    @clickItem="addOldStudent"
                    :className="'defult_dialog_header_btn fr'">
        <span class="btn_add">+</span>录入老生
      </function-btn>
      <div class="students_list">
        <!-- 搜索选择层 -->
         <module-description v-if="formTitle=='安排学生补课'"
                      :textTips="'补课不扣课时'"
                        ></module-description>
        <div class="single_shadow">
          <div>
            <!-- 搜索框 -->
            <div v-if="!vMendianVersioneTY"
                 class="students_list_navbar">
              <div @click="clearCriteria(0)"
                   :class="{ opt: isAllstudent == 0 }"
                   class="navbar_list">
                报课学生<span class="font_number">({{ courseStudentNumber }})</span>
              </div>
              <div @click="clearCriteria(1)"
                   :class="{ opt: isAllstudent == 1 }"
                   class="navbar_list">
                在校学生<span class="font_number">({{ allStudentNumber }})</span>
              </div>
            </div>
            <input-search-contain-btn v-model="studenSearchText"
                                      :placeholder="'请输入姓名、姓名首字母、手机号'"
                                      @onSearchEvent="searchTextSearch"></input-search-contain-btn>
          </div>
          <div class="students_list_box clearfix">
            <div class="students_list_left fl">
              <div class="left_title">
                <span>已排学生</span>
                <span class="font_blue">{{ dataStudentList.length }}</span>
              </div>
              <div class="left_ul">
                <div v-for="(item, index) in VDataStudentList"
                     :key="index"
                     @click.stop="studentDetail(item)"
                     :class="{ dot: item.dot }"
                     class="left_li">
                  <span>{{ item.MainDemoName || item.StudentKeyValue }}</span>
                  <div @click.stop="deleteStudentBefore(item)"
                       class="left_li_remove pa"></div>
                </div>
              </div>
            </div>
            <div class="students_list_right fl">
              <div class="right_title flex">
                <div class="right_title_name">
                  {{ isAllstudent == 1 ? "在校" : "报课" }}<br />学生
                </div>
                <div v-if="!isAllstudent"
                     class="right_title_number">
                  当前<br />报课
                </div>
                <div v-else
                     class="right_title_number">适用<br />课时</div>
                <div class="right_title_text">排课<br />备注</div>
              </div>
              <div @scroll="scrollTOP"
                   v-if="studentList.length != 0"
                   class="right_content">
                <div v-for="(item, index) in vStudentList"
                     :key="index"
                     @click.stop="optStudent(item)">
                  <div v-if="!item.isOpt"
                       class="right_list flex">
                    <div class="right_list_name">
                      <span>{{ item.MainDemoName }}</span>
                    </div>
                    <div @click.stop="showStundentCourse(item)"
                         class="right_list_number">
                      <span v-if="isAllstudent == 0">{{
                        Number(item.Sum_CurrentCount)
                        }}</span>
                      <span v-else>{{ cardCourse(item) }}</span>
                    </div>
                    <div @click.stop="alterArrangeSupport(item)"
                         class="right_list_text">
                      <span class="right_list_text_font">{{
                        item.ArrangeSupport
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="loading"
                   class="students_loading">
                <span class="el-icon-loading"></span>
                <span class="loading_style">加载中...</span>
              </div>
              <div v-else-if="showNoData || studentList.length == 0"
                   class="students_list_right fl">
                <div class="monitoring_content_nodata">
                  <div v-if="
                      showNoData ||
                      isAllstudent == 1 ||
                      studenSearchText.length == 0
                    "
                       class="monitoring_content_nodata_txt">
                    亲，当前没有任何数据哦~
                  </div>
                  <div v-else>
                    <div class="monitoring_content_nodata_txt font_black">
                      报课学生中没有该学生！
                    </div>
                    <div @click="clearCriteria(1)"
                         class="to_all_student">
                      去在校学生搜索 <span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="complete"
             class="confirm_btn btn_fixed_bottom">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               style="magin: 0 auto">
            完成并返回
          </div>
        </div>
        <custom-dialog :title="'学生：' + selectedStudentInfo.MainDemoName"
                       :visible.sync="isAlterMessagePopup"
                       :before-close="hideAlterMessagePopup">
          <div class="form_info_detail micro_message">
            <div class="micro_message_edit content_marginRL single_shadow">
              <div class="message_edit_input">
                <textarea cols="30"
                          rows="10"
                          maxlength="100"
                          placeholder="请输入排课备注"
                          v-model="alterMessageText"></textarea>
              </div>
            </div>
            <!-- 按钮组 -->
            <div class="footer_btn_group form_footer_btn_group">
              <el-button round
                         class="btn_light_red btn_hover_white"
                         @click.stop="hideAlterMessagePopup">取消</el-button>
              <el-button round
                         class="btn_light_blue btn_hover_bg_blue"
                         @click.stop="submitStudentArrangeSupport">确认</el-button>
            </div>
          </div>
        </custom-dialog>
        <div v-show="isStundentCourse"
             class="suspension_label">
          <div class="shade"
               style="opacity: 0"
               @click="isStundentCourse = false"></div>
          <div class="suspension_label_box">
            <div class="fl">
              <div>
                共 {{ Number(selectedStudentInfo.Sum_PlanCount) }} 课时，已上
                {{ Number(selectedStudentInfo.Sum_SpendCount) }} 课时
              </div>
              <div>
                已排
                <span class="font_red">{{
                  Number(selectedStudentInfo.Current_ArrangedCount)
                  }}</span>
                课时，剩余可排
                <span class="font_red">{{
                  Number(selectedStudentInfo.Sum_ToBeArranged)
                  }}</span>
                课时
                <span v-if="selectedStudentInfo.Sum_SuperQuote > 0">
                  , 超排
                  <span class="font_red">{{
                    Number(selectedStudentInfo.Sum_SuperQuote)
                    }}</span>课时
                </span>
              </div>
            </div>
            <div @click="isStundentCourse = false"
                 class="fr"></div>
          </div>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog title="录入老生"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAddOldStudentDialog"
                   :before-close="closeAddOldStudentDialog">
      <add-old-student @addSuccess="doAfterAddStudentSuccess"
                       :isChildComponent="true"
                       @closeDialog="closeAddOldStudentDialog"></add-old-student>
    </custom-dialog>
    <custom-dialog title="已排学生"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isPopup"
                   :before-close="hiddenPopup">
      <popup-box ref="Popup"
                 :studentInfo="optStundentInfo"
                 @deleteCourse="refreshDataStundentList"></popup-box>
    </custom-dialog>
     <custom-dialog :title="'备注'"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowQuitCourseReason"
                     :before-close="closeReason">
        <quit-course-reason @deleteClick="delReasonHide"
                           @cancel="closeReason"></quit-course-reason>
      </custom-dialog>
  </div>
</template>
<script>
import {
  EditArrangeSupport,
  GetTheStudentTicketPlanListByClassType,
  MessageSearchPageForClassArrangementStudent
} from '../../../API/workbench.js';
import addOldStudent from '../../pop-up-menu/exception-handle/add-old-student';
import popupBox from './popup-box';
import quitCourseReason from '../../course-table/attendance-arrange/components/quit-course-reason';
export default {
  data () {
    return {
      studenSearchText: '',
      showNoData: false,
      pageSize: 20, // 每次搜索人数.
      pageIndex: 0, // 每次查询页数
      studentList: [],
      optStundentInfo: {},
      courseStudentNumber: null,
      allStudentNumber: null,
      isAllstudent: 0,
      isPopup: false,
      isStundentCourse: false,
      loading: false,
      noMore: false, // 没有更多数据加载了
      dataStudentList: [], // 选中的选择数据
      isAlterMessagePopup: false,
      selectedStudentInfo: {},
      alterMessageText: '',
      isShowAddStudent: false,
      isShowAddOldStudentDialog: false,
      isAdding: false, // 防止重复点击
      isDelete: false,
      isShowQuitCourseReason:false,
      addbtn: {
        name: '',
        type: 'blls',
        ModulePowerKey: 49
      }
    };
  },
  props: {
    formTitle: String, // 自定义标题名（必填）
    selectCourseID: [String, Number], // 选中的课名
    selectCourseTime: [String, Number], // 选中的上课时间
    classGradeID: [String, Number], // 班级Id
    classHoureCount: [String, Number], // 课时消耗
    ParentOperation: Boolean, // 是否由父组件主导
     isTeacher: Boolean,
     delPowerKey:{
      type:[Number,String],
       default:()=>{
        return 0
      }
     }
  },
  components: {
    addOldStudent,
    popupBox,
    quitCourseReason
  },
  watch: {
    isShowAddStudent (n, o) {
      if (!n) {
        this.studentList = [];
        if (this.vMendianVersioneTY) {
          this.isAllstudent = 1;
        } else {
          this.isAllstudent = 0;
        }
        this.pageIndex = 0;
      }
    }
  },
  computed: {
    vMendianVersioneTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vStudentList () {
      this.studentList.forEach((e) => {
        let flag = false;
        this.dataStudentList.forEach((i) => {
          if (i.StudentKey == e.OLAPKey) {
            flag = true;
          }
        });
        e.isOpt = flag;
      });
      let arr = this.studentList.filter((e) => {
        return e.isOpt == false;
      });
      if (arr.length <= 10 && !this.noMore) {
        this.getStudentList();
      }
      if (arr.length == 0) {
        this.showNoData = true;
      } else {
        this.showNoData = false;
      }
      return this.studentList;
    },
    VDataStudentList () {
      console.log(this.dataStudentList, '1122');
      let arr = JSON.parse(JSON.stringify(this.dataStudentList));
      arr = arr.reverse();
      return arr;
    }
  },
  created () { },
  mounted () {
    if (this.vMendianVersioneTY) {
      this.isAllstudent = 1;
      // this.clearCriteria(1)
    }
  },
  methods: {
    closeReason(){
      this.isShowQuitCourseReason=false
    },
    cardCourse (item) {
      if (item.HasTermCard > 0) {
        return '有';
      } else if (Number(item.CurrentClassHourCount) > 0) {
        return Number(item.AvailableNum);
      } else {
        return 0;
      }
    },
    checkIsClassHourLackKey (item) {
      console.log(item, 'checkIsClassHourLackKey');
      if (!this.classHoureCount) {
        return false;
      } else if (item.HasTermCard > 0) {
        return false;
      } else if (Number(item.AvailableNum) >= this.classHoureCount) {
        return false;
      } else {
        return true;
      }
    },
    pushDataStudentList (data) {
      this.dataStudentList = JSON.parse(JSON.stringify(data));
      this.dataStudentList.forEach((e) => {
        e.dot = false;
      });
    },
    addDataStudentList (studentInfo) {
      this.isAdding = false;
      studentInfo.dot = true;
      // this.dataStudentList.push(studentInfo);
      this.getStudentCourse(studentInfo);
    },
    // 点击已有学生块
    studentDetail (item) {
      this.optStundentInfo = item;
      this.isPopup = true;

      if (this.selectCourseID) {
        this.$nextTick(() => {
          this.$refs.Popup.getCourseInfo(this.selectCourseID);
        });
      }
    },
    refreshDataStundentList () {
      if (typeof this.$parent.loadStudentArrangeList == 'function') {
        this.$parent.loadStudentArrangeList(() => {
          this.dataStudentList = JSON.parse(
            JSON.stringify(this.$parent.studentArrangeList)
          );
        });
      }
    },
    hiddenPopup () {
      this.isPopup = false;
    },
    showStundentCourse (item) {
      if (this.isAllstudent == 0) {
        this.selectedStudentInfo = item;
        this.isStundentCourse = true;
      }
    },
    doShowPopupHideClick () {
      this.isShowAddStudent = false;
      this.pageIndex = 0;
      this.studenSearchText = '';
      this.$emit('completeData', this.dataStudentList);
    },
    doAfterAddStudentSuccess (studentInfo) {
      this.isShowAddOldStudentDialog = false;
      if (studentInfo.OLAPKey) {
        studentInfo.StudentKey = studentInfo.OLAPKey;
        this.pageIndex = 0;
        if (!this.vMendianVersioneTY) {
          this.getStudentNumber();
        }
        this.searchTextSearch();
        this.doAddStudent(studentInfo, 1);
      }
    },
    addOldStudent () {
      this.isShowAddOldStudentDialog = true;
    },
    closeAddOldStudentDialog () {
      this.isShowAddOldStudentDialog = false;
    },
    // 完成多选
    complete () {
      this.pageIndex = 0;
      this.studentList = [];
      this.studenSearchText = '';
      this.isShowAddStudent = false;
      this.$emit('completeData', this.dataStudentList);
    },
    // 选中学生
    optStudent (item) {
      let that = this;

      if (item.IsConfirmKey == 0) {
        layer.alert('该学生未验证，请先前往学生档案进行验证');
        return false;
      }

      let hasFind = false;
      if (this.dataStudentList.length > 0) {
        hasFind =
          this.dataStudentList.findIndex((p) => p.OLAPKey == item.OLAPKey) >= 0;
      }
      if (!hasFind) {
        item.StudentKey = item.OLAPKey;
        let isClassHourLackKey = this.checkIsClassHourLackKey(item);
        if (isClassHourLackKey) {
          // layer.confirm(item.MainDemoName + '课时不足,是否继续安排？', {
          //   shadeClose: false,
          //   btn: [
          //     {
          //       name: '取消',
          //       callBack: () => {
          //         layer.close();
          //       }
          //     },
          //     {
          //       name: '确认',
          //       callBack: () => {
          that.doAddStudent(item, 1);
          //         layer.close();
          //       }
          //     }
          //   ]
          // });
        } else {
          that.doAddStudent(item, this.classHoureCount ? 0 : 1);
        }
      } else {
        console.log(item);
        layer.alert('请勿重复安排学生');
      }
    },
    // 添加学生
    doAddStudent (item, isAllowClassHourLackKey) {
      item.dot = true;
      this.dataStudentList.push(item);
      this.$emit(
        'onAddStudent',
        item,
        () => {
          this.getStudentCourse(item);
        },
        isAllowClassHourLackKey,
        () => {
          console.log(isAllowClassHourLackKey);
          var index = this.dataStudentList.indexOf(item);
          if (index > -1) {
            item.dot = false;
            this.dataStudentList.splice(index, 1);
          }
        }
      );
    },
    delReasonHide(Reason){
      this.deleteStudent(this.selectedStudentInfo,Reason)
      
    },
    deleteStudentBefore (item,Reason){
      console.log("this,deleteStudentBefore",this.delPowerKey)
      this.selectedStudentInfo = item
      if(this.delPowerKey&&this.$utils.getSaaSClientSetInfo(this.delPowerKey).SetContent==1&&this.isTeacher){
        layer.alert("已开启老师限制，不能操作")
      }
      // else if(this.delPowerKey&&Number(this.$utils.getSaaSClientSet(this.delPowerKey))>0){
      //   this.isShowQuitCourseReason = true;
      // }
      else{
        this.deleteStudent(item,Reason);
      }
    },
    // 删除多选数据
    deleteStudent (item,Reason) {
      let index = this.dataStudentList.findIndex(p => p.OLAPKey == item.OLAPKey);
      if (index < 0) {
        return false;
      }
      if (this.isDelete == true) {
        return false;
      }
      if (this.ParentOperation) {
        this.isDelete = true;
        setTimeout(() => {
          this.isDelete = false;
        }, 100);
        let hasArrangeList = this.$utils.parseJson(this.dataStudentList);
        this.$emit('onDeleteStudent', this.dataStudentList[index], index, () => {
          this.getStudentCourse(hasArrangeList[index]);
          this.dataStudentList.splice(index, 1);
        },Reason);

        // this.getStudentCourse(hasArrangeList[index]);
      } else {
        this.isDelete = true;
        setTimeout(() => {
          this.isDelete = false;
        }, 100);
        let hasArrangeList = this.$utils.parseJson(this.dataStudentList);
        this.$emit('onDeleteStudent', this.dataStudentList[index], index, () => {
          this.getStudentCourse(hasArrangeList[index]);
          this.dataStudentList.splice(index, 1);
        },Reason);
      }
    },
    clearCriteria (type) {
      this.isAllstudent = type;
      this.pageIndex = 0;
      this.getStudentList();
    },
    alterArrangeSupport (item) {
      console.log('????');
      this.selectedStudentInfo = item;
      this.alterMessageText = this.selectedStudentInfo.ArrangeSupport;
      this.isAlterMessagePopup = true;
    },
    hideAlterMessagePopup () {
      this.isAlterMessagePopup = false;
    },
    submitStudentArrangeSupport () {
      EditArrangeSupport(
        this.selectedStudentInfo.OLAPKey,
        this.alterMessageText
      ).then(
        (res) => {
          this.selectedStudentInfo.ArrangeSupport = this.alterMessageText;
          this.hideAlterMessagePopup();
        },
        (err) => {
          this.hideAlterMessagePopup();
          layer.alert(err.msg);
        }
      );
    },
    // 点击搜索
    searchTextSearch () {
      this.studenSearchText = this.studenSearchText.replace(/ /g, '');
      this.pageIndex = 0;
      this.noMore = false;
      this.getStudentList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore
      ) {
        this.getStudentList();
      }
    },
    getStudentCourse (data, callback) {
      if (!data.OLAPKey) {
        return false;
      }
      GetTheStudentTicketPlanListByClassType(
        data.OLAPKey,
        this.selectCourseID
      ).then(
        (res) => {
          data.Sum_ToBeArranged = res.data.Sum_ToBeArranged;
          this.studentList.forEach((e) => {
            if (e.OLAPKey == data.OLAPKey) {
              e.Sum_ToBeArranged = res.data.Sum_ToBeArranged;
              e.Current_ArrangedCount = res.data.Sum_ArrangedCount;
              e.Sum_PlanCount = res.data.Sum_PlanCount;
              e.Sum_SpendCount = res.data.Sum_SpendCount;
              // e.Sum_SuperQuote = e.Sum_PlanCount - e.Sum_SpendCount
            }
          });
          this.dataStudentList.forEach((e) => {
            if (e.OLAPKey == data.OLAPKey) {
              e.Sum_ToBeArranged = res.data.Sum_ToBeArranged;
              e.Current_ArrangedCount = res.data.Sum_ArrangedCount;
            }
          });
          if (callback) {
            callback();
          }
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    // 查询总学生数
    getStudentNumber () {
      MessageSearchPageForClassArrangementStudent(
        this.pageIndex,
        this.pageSize,
        '',
        '',
        '',
        this.classGradeID,
        this.selectCourseID,
        0
      ).then((res) => {
        this.courseStudentNumber = res.data.Total;
      });
      MessageSearchPageForClassArrangementStudent(
        this.pageIndex,
        this.pageSize,
        '',
        '',
        '',
        this.classGradeID,
        this.selectCourseID,
        1,
        this.selectCourseTime
      ).then((res) => {
        this.allStudentNumber = res.data.Total;
      });
    },
    // 学生查询翻页
    getStudentList (callback) {
      if (this.loading) {
        return false;
      } else {
        this.loading = true;
      }
      if (
        this.courseStudentNumber == null &&
        this.allStudentNumber == null &&
        !this.vMendianVersioneTY
      ) {
        this.getStudentNumber();
      }
      let selectCourse = this.selectCourseID;
      let classGrade = this.classGradeID;
      if (this.pageIndex == 0) {
        this.studentList = [];
      }
      MessageSearchPageForClassArrangementStudent(
        this.pageIndex,
        this.pageSize,
        this.studenSearchText,
        '',
        '',
        classGrade,
        selectCourse,
        this.isAllstudent,
        this.selectCourseTime
      )
        .then((result) => {
          if (result.data) {
            console.log(result.data, 'result.data-学生查询翻页1');
            // 切换学生类型时 (首页)需清空数据
            if (this.studentList.length < result.data.Total) {
              result.data.PageDataList.forEach((e) => {
                this.studentList.push(e);
              });
              this.pageIndex++;
            }
            if (result.data.PageDataList.length < this.pageSize) {
              this.noMore = true;
            } else {
              this.noMore = false;
            }
            this.loading = false;
          }
          if (callback) {
            callback();
          }
          console.log(this.studentList, 'result.data-学生查询翻页');
        })
        .catch((error) => {
          console.log(error.msg);
        });
    }
  }
};
</script>