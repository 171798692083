<template>
  <div>
    <!-- 普通版本 -->
    <courseware-manage-head :bigcoursewareType="type"
                            :maxCoursewareInfo="maxCoursewareInfo"
                            :coursewareNum="vCoursewareNum"
                            :showEdit="true"
                            @editbigCoursewareInfo="editbigCoursewareInfo"
                            @doReturn="doOperateItemClick"
                            @doRecycle=" openCommonCustomDialogByFactoryDialog"></courseware-manage-head>
    <div v-if="isShowBody"
         class="courseware_manage_view scroll_type">
      <courseware-list ref="refCourseware"
                       :type="type"
                       :maxCoursewareInfo="maxCoursewareInfo"
                       :coursewareTypeList="coursewareTypeList"
                       :coursewareList="coursewareList"
                       :coursewareInfo="coursewarePackageInfo"
                       :vCoursewareTreeList="vCoursewareTreeList"
                       @updateElscrollTop="updateElscrollTop"
                       @selectEvent="selectEvent"
                       @selectEventSecond="selectEventSecond"
                       @doListSort="doListSort"
                       @doOperateItemClick="doOperateItemClick"
                       @selectCoursewareType="selectCoursewareType"
                       @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"
                       @editCoursewarePackageAfterSuccess="getNormalCoursewarePackage"></courseware-list>
      <courseware-content :type="type"
                          :isLoadData="isLoadData"
                          :coursewareInfo="coursewarePackageInfo"
                          :maxCoursewareInfo="maxCoursewareInfo"
                          :coursewareContentList="coursewareContentList"
                          @coursewareOperate="coursewareOperate"
                          @afterSuccess="afterSuccess"
                          @afterSucceed="selectCoursewareType"
                          @afterSucceedSort="selectEvent"></courseware-content>
    </div>
    <div v-if="!isShowBody">
      <courseware-editor :coursewareId="coursewareEditInfo.CoursewareID" 
                         :PublishStatusKey="coursewareEditInfo.PublishStatusKey"
                         :scrollTop="scrollTop"
                         @cancel="showBody"
                         @saveCourseware="showBody"></courseware-editor>
    </div>
     <custom-dialog :title="'编辑大课包'"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <max-courseware-form :coursewareId="maxCoursewareInfo.ID"
                           :isReadonly="false"
                           @close="closeCoursewareFormPopup"
                           @afterSuccess="editCoursewarePackageAfterSuccess"></max-courseware-form>
    </custom-dialog>
    <div>
      <dialog-factory ref="generalCoursewareManagementhDialogFactory"
                      :key="'generalCoursewareManagement'"
                      :routerName="'generalCoursewareManagement'"></dialog-factory>
      <dialog-factory-common ref="generalCoursewareManagementDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  GetAuthCoursewarePackageList,
  SearchCourseWarePackageListForNormal,
  CWSSearchCourseWarePackageListForNormal,
  SearchCourseWarePackageListForRecycle,
  CWSSearchCourseWarePackageListForRecycle,
  CoursewarePackageManageSort,
  SearchAuthCoursewareListByCoursewarePackageID,
  SearchCoursewareListByCoursewarePackageID,
  CWSSearchCoursewareListByCoursewarePackageID,
  CoursewarePackageManageCopy,
  CWSCoursewarePackageManageCopy,
  CoursewarePackageManageDelete,
  CWSCoursewarePackageManageDelete,
  CoursewarePackageManageDisable,
  CWSCoursewarePackageManageDisable,
  CoursewarePackageManageEnable,
  CoursewareManageAdd,
  CoursewareManageDelete,
  VersionRestoreCourseware
} from '../../API/workbench';
import maxCoursewareForm from './max-courseware-list/max-courseware-form';
import coursewareList from './courseware-list/index';
import coursewareContent from './courseware-content/index';
import coursewareEditor from './courseware-editor/index';
import coursewareManageHead from '../head-quarters/headquarters-management/courseware-management/courseware-manage-head';
import dialogFactory from '../common/dialog-factory';
export default {
  name: 'generalCoursewareManagement',
  provide () {
    return {
      coursewarePackage: this.coursewarePackage,
      getNormalCoursewarePackage: this.getNormalCoursewarePackage,
      setCoursewareList: this.setCoursewareList,
      coursewarePackageID: this.maxCoursewareInfo.ID // 大课包ID
    };
  },
  data () {
    return {
      isShowBody: true,
      coursewareTypeList: [
        { name: '授权课件', key: 'SQ', isOpt: false, isShow: true },
        { name: '自研课件', key: 'ZZ', isOpt: false, isShow: true },
        { name: '回收站', key: 'HSZ', isOpt: false, isShow: true }
      ],
      coursewareList: [], // 课件包列表
      // type: 'ZZ', // 课件类型
      coursewarePackageID: '', // 支持外部进入选中查看
      coursewareId: '', // 课件ID
      coursewareEditInfo: {},
      coursewarePackageInfo: {}, // 课件包信息
      coursewareContentList: [], // 课件包内容列表
      isLoadData: true,
      coursewarePackage: 'smallCourseware',
      isShowCoursewareFormPopup:false,
      elscrollTop:0
    };
  },
  components: {
    coursewareList,
    coursewareContent,
    coursewareEditor,
    coursewareManageHead,
    dialogFactory,
    maxCoursewareForm
  },
  props: {
    maxCoursewareInfo: Object, // 大课包信息
    type: String,
    scrollTop:Number
  },
  created () {
    console.log(this.maxCoursewareInfo, 'single-courseware-list');
    if (this.type == 'ZZ') {
      this.getNormalCoursewarePackage(()=>{
        this.enterRouterInit()
      });
    } else if (this.type == 'SQ') {
      this.getAuthCoursewarePackageList();
    }
  },
  watch: {
    maxCoursewareInfo: {
      handler (n, o) {
        if (n) {
          this.maxCoursewareInfo = n;
        }
      },
      deep: true
    }
  },
  activated () {
    // if (
    //   (this.$route.params.dataKey && this.$route.params.dataKey > 0) ||
    //   this.coursewarePackageID
    // ) {
    //   // 外部进入定位
    //   this.coursewarePackageID =
    //     this.$route.params.dataKey || this.coursewarePackageID;
    //   if (this.type == 'ZZ') {
    //     this.coursewareTypeList[0].isOpt = false;
    //     this.coursewareTypeList[2].isOpt = false;
    //     this.getNormalCoursewarePackage();
    //   }
    // }
    console.log('外部进入定位', this.$route.params);
  },
  computed: {
    vCoursewareNum () {
      let list = this.coursewareList.filter(o => {
        return Number(o.TypeKey) == 0;
      });
      return list.length;
    },
    vCoursewareTreeList () {
      let arr = [];
      // console.log(this.newCoursewareList, this.maxCoursewareInfo, 'newCoursewareList');
      console.log('this.vCoursewareTreeList', this.coursewareList);
      let treeData = this.coursewareList.filter(item => {
        return item.ID;
      });
      // 父节点数据
      const ParentList = treeData.filter(
        item => Number(item.ParentKey) == Number(this.maxCoursewareInfo.ID)
      );
      // 子节点数据
      const childList = treeData.filter(
        item => Number(item.ParentKey) != Number(this.maxCoursewareInfo.ID)
      );
      // console.log(ParentList, childList, '父节点数据-子节点数据');
      arr = this.$utils.loopData(
        ParentList,
        childList,
        treeData,
        'ID',
        'ParentKey'
      );
      console.log('vCoursewareTreeList222', arr);
      return arr;
    }
  },
  methods: {
     // 判断是否有路由传参过来。
    enterRouterInit(){
        if(this.$route.params.data&&this.$route.params.data.levelPath){
            let dataArr= this.$route.params.data.levelPath.split('.')
            if(dataArr[0]==''){
              dataArr.splice(0,1)
            }
            console.log("dataArr",dataArr)
          
              let item = this.coursewareList.find(p=>{
                return p.ID==dataArr[1]
              })
              // 判断是否目录，如果不是直接选择课件
              if(item.TypeKey==0){
                this.selectEvent(item)
                this.$route.params.data={}
              }else{
                // 是目录，先调选择课件接口。
                item.isOpt=true
                this.selectEventSecond(item,()=>{
                  let secondItem = this.coursewareList.find(p=>{
                    return p.ID==dataArr[2]
                  })
                  console.log("secondItem",secondItem)
                  if(secondItem){
                    secondItem.isSelected=true
                    this.selectEvent(secondItem)
                  }
                })
                
                this.$route.params.data={}
              }
              console.log("item",item)

          }
    },
    updateElscrollTop(scrollTop){
      console.log("this.updateElscrollTop",this.elscrollTop)
      this.elscrollTop=scrollTop
    },
    editCoursewarePackageAfterSuccess(data){
      this.$emit("getParentCourseWarePackageList",data)
    },
    closeCoursewareFormPopup(){
      this.isShowCoursewareFormPopup=false
    },
    editbigCoursewareInfo(coursewareInfo){
      console.log('editbigCoursewareInfo',coursewareInfo)
      console.log("22222",this.maxCoursewareInfo)
      this.isShowCoursewareFormPopup=true
    },
    showBody () {
      this.isShowBody = true;
      console.log("showBody",this.coursewarePackageInfo)
      let locaItem = this.coursewareList.find(o => {
          return o.ID == this.coursewarePackageInfo.ParentKey;
      });
      if (locaItem) {
          locaItem.childTree = [];
              this.coursewareList = this.coursewareList.filter(o => {
              return o.TypeKey == 2 && o.ParentKey != locaItem.ID;
          });
          this.selectEventSecond(locaItem);
      }else{
        this.coursewareList = [];
        // this.getNormalCoursewarePackage();

      }
      this.getNormalCoursewarePackage();
      this.$emit('getParentCourseWarePackageList');
    },
    // 选中课件包
    selectedCoursewarePackag () {
      console.log('initselectedCoursewarePackag', this.coursewareEditInfo);
      if (this.coursewarePackageID) {
        let locaIndex = this.coursewareList.findIndex(o => {
          return this.coursewarePackageID == o.ID;
        });
        if (locaIndex >= 0) {
          this.selectEvent(this.coursewareList[locaIndex]);
          
            console.log("this.elscrollTop",this.elscrollTop)
             document.querySelector('.left_ul').scrollTop = this.elscrollTop
       
          // document.querySelector('.left_ul').scrollTop = locaIndex * 205;
        } else {
          this.selectEvent(this.coursewarePackageInfo);
        }
      } else {
        // this.selectEvent(this.coursewareList[0]); // 默认第一项
      }
    },
    // 查询课件包列表-授权
    getAuthCoursewarePackageList () {
      GetAuthCoursewarePackageList(this.maxCoursewareInfo.ID).then(
        result => {
          this.$set(this.maxCoursewareInfo, 'Authorize', result.data.Authorize);
          result.data.CoursewarePackageList.forEach(o => {
            this.$set(o, 'isSelected', false);
            this.$set(o, 'isOpt', false);
          });
          this.coursewareList = result.data.CoursewarePackageList;

          this.coursewareTypeList[0].isOpt = true;
          this.type = 'SQ';
          if (this.coursewareList.length > 0) {
            this.selectedCoursewarePackag();
          } else {
            this.coursewareContentList = [];
            this.coursewarePackageInfo = {};
          }
          console.log('查询课件包列表-授权', result.data);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 查询课件包列表-自研
    getNormalCoursewarePackage (callback) {
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      let apiUrl = '';
      if (type == 6) {
        apiUrl = CWSSearchCourseWarePackageListForNormal;
      } else {
        apiUrl = SearchCourseWarePackageListForNormal;
      }
      apiUrl(this.maxCoursewareInfo.ID).then(
        result => {
          result.data.forEach(o => {
            this.$set(o, 'isOpt', false);
            this.$set(o, 'isSelected', false);
            this.$set(o, 'childTree', []);
            let isrepeat = false;
            this.coursewareList.forEach(item => {
              if (o.ID == item.ID) {
                isrepeat = true;
              }
            });
            if (!isrepeat) {
              this.coursewareList.push(o);
            }else{
              let locaIndex=this.coursewareList.findIndex(item => {
                return o.ID==item.ID
              });
              if(locaIndex>=0){
                this.coursewareList[locaIndex].CoursewareNum=o.CoursewareNum
              }

            }
          });
          this.coursewareTypeList[1].isOpt = true;
          this.type = 'ZZ';
          if (this.coursewareList.length > 0) {
            if(callback){
              callback()
            }else{
              this.selectedCoursewarePackag();
            }
          } else {
            this.coursewareContentList = [];
            this.coursewarePackageInfo = {};
          }
          console.log('查询课件包列表-自研', result.data);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 查询课件包列表-回收站
    getRecycleCoursewarePackage () {
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      let apiUrl = '';
      if (type == 6) {
        apiUrl = CWSSearchCourseWarePackageListForRecycle;
      } else {
        apiUrl = SearchCourseWarePackageListForRecycle;
      }

      apiUrl().then(
        result => {
          this.coursewareList = result.data;
          this.coursewareList.forEach((o, index) => {
            o.ShowOrder = index + 1;
          });
          this.type = 'HSZ';
          if (this.coursewareList.length > 0) {
            // this.selectEvent(this.coursewareList[0]); // 默认第一项
          } else {
            this.coursewareContentList = [];
            this.coursewarePackageInfo = {};
          }
          console.log('查询课件包列表-回收站', result.data);
          this.$emit('getParentCourseWarePackageList');
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 课件包排序
    doListSort (sortType, data) {
      let changeToSort =
        sortType == 'Top'
          ? Number(data.ShowOrder) - 1
          : Number(data.ShowOrder) + 1;
      CoursewarePackageManageSort(data.ID, changeToSort).then(
        result => {
          let locaItem = this.coursewareList.find(o => {
            return o.ID == data.ParentKey;
          });
          console.log(locaItem);
          if (locaItem) {
            locaItem.childTree = [];
            this.coursewareList = this.coursewareList.filter(o => {
              return o.TypeKey == 2 && o.ParentKey != locaItem.ID;
            });
            this.selectEventSecond(locaItem);
          } else {
            this.coursewareList = [];
            this.getNormalCoursewarePackage();
          }
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 点击科目时加载数据
    selectEventSecond (dataInfo, callBack) {
      console.log('点击科目时加载数据', dataInfo);
      
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      let apiUrl = '';
      if (type == 6) {
        apiUrl = CWSSearchCourseWarePackageListForNormal;
      } else {
        apiUrl = SearchCourseWarePackageListForNormal;
      }
      apiUrl(dataInfo.ID).then(
        result => {
          console.log('result.data', result.data);
          // dataInfo.childTree=result.data
          result.data.forEach(o => {
            this.$set(o, 'isOpt', false);
            this.$set(o, 'isSelected', false);
            this.$set(o, 'childTree', []);
            let isrepeat = false;
            this.coursewareList.forEach(item => {
              if (o.ID == item.ID) {
                isrepeat = true;
                item=this.$utils.parseJson(o)
              }
            });
            if (!isrepeat) {
              this.coursewareList.push(o);
            }else{

            }
          });
          if (callBack) {
            this.$nextTick(o => {
              callBack();
            });
          }
          console.log('111111', result.data);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 根据指定课件包ID获取课件列表
    selectEvent (dataInfo) {
      console.log('根据指定课件包ID获取课件列表');
      this.coursewarePackageInfo = dataInfo;
      this.coursewarePackageID = this.coursewarePackageInfo.ID;
      this.coursewareContentList = [];
      // if (dataInfo) {
      //   this.coursewareList.forEach(o => {
      //     if (dataInfo.ParentKey && dataInfo.ParentKey == o.ID) {
      //       this.$set(o, 'isOpt', true);
      //     }
      //   });
      //   console.log('根据指定课件包ID获取课件列表(总部)', dataInfo, this.coursewareList);
      // }
      let types = this.$store.getters.SaaSClientInfo.EditionKey;
      let apiFN = '';
      if (this.type == 'SQ') {
        apiFN = SearchAuthCoursewareListByCoursewarePackageID;
      } else {
        console.log(types, 'types');
        apiFN =
          types == 6
            ? CWSSearchCoursewareListByCoursewarePackageID
            : SearchCoursewareListByCoursewarePackageID;
      }
      apiFN(this.coursewarePackageID).then(
        result => {
          this.coursewareContentList = result.data.filter(obj => {
            this.$set(obj, 'isOperate', false);
            return obj.OLAPKey;
          });
          this.isLoadData = false;
          // console.log('根据指定课件包ID获取课件列表(总部)', result.data);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 重新加载数据
    selectCoursewareType (item, data) {
      // data,用于选中当前项
      console.log('重新加载数据', item, data);
      this.coursewarePackageID = data && data.ID ? data.ID : '';
      item.key = item.key || this.type;
      if (item.key == 'SQ') {
        this.getAuthCoursewarePackageList();
      } else if (item.key == 'ZZ') {
        this.getNormalCoursewarePackage();
      } else if (item.key == 'HSZ') {
        this.getRecycleCoursewarePackage();
      }
    },
    afterSuccess () {
      this.$emit('getParentCourseWarePackageList');
    },
    // 课件包操作
    doOperateItemClick (item, data) {
      console.log(item, data, '操作项');
      switch (item.key) {
        case 'return': // 返回大课包列表
          this.$parent.isShowMaxCoursewareList = true;
          if (this.type == 'SQ') {
            this.$parent.showAuthorizationList();
          } else {
            this.$parent.showScienceList();
          }
          break;
        case 'copy':
          layer.confirm('确定复制该课件包?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let type = this.$store.getters.SaaSClientInfo.EditionKey;
                  let apiUrl = '';
                  if (type == 6) {
                    apiUrl = CWSCoursewarePackageManageCopy;
                  } else {
                    apiUrl = CoursewarePackageManageCopy;
                  }
                  apiUrl(data.ID).then(
                    result => {
                      this.coursewarePackageID =
                        result.data.CoursewarePackageID;
                      // this.getNormalCoursewarePackage();
                      if (this.$refs.refCourseware) {
                        let locaIndex = this.coursewareList.findIndex(o => {
                          return o.ID == data.ID;
                        });
                        if (locaIndex >= 0) {
                          this.coursewareList[locaIndex].childTree = [];
                        }
                        this.$refs.refCourseware.editCoursewarePackageAfterSuccess(
                          'add',
                          result.data
                        );

                        this.selectEvent(result.data);
                      }
                      // this.getNormalCoursewarePackage();
                      this.$emit('getParentCourseWarePackageList');
                    },
                    error => {
                      layer.alert(error.msg);
                    }
                  );
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'delete':
          layer.confirm('是否删除该课件包？', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let type = this.$store.getters.SaaSClientInfo.EditionKey;
                  let apiUrl = '';
                  if (type == 6) {
                    apiUrl = CWSCoursewarePackageManageDelete;
                  } else {
                    apiUrl = CoursewarePackageManageDelete;
                  }
                  apiUrl(data.ID).then(
                    result => {
                      if (this.$refs.refCourseware) {
                        let locaIndex = this.coursewareList.findIndex(o => {
                          return o.ID == data.ParentKey;
                        });
                        if (locaIndex >= 0) {
                          this.coursewareList[locaIndex].childTree = [];
                          let n = this.coursewareList.findIndex(o => {
                            return o.ID == data.ID;
                          });
                          if (n > -1) {
                            this.coursewareList.splice(n, 1);
                          }

                          let findItem = this.coursewareList.find(o => {
                            return o.isSelected;
                          });
                          console.log(findItem, 'findItem');
                          if (findItem) {
                            this.$refs.refCourseware.editCoursewareInfo = findItem;
                          } else {
                            this.selectEvent({ ID: 0, MainDemoName: '' });
                          }
                        } else {
                          let n = this.coursewareList.findIndex(o => {
                            return o.ID == data.ID;
                          });
                          if (n > -1) {
                            this.coursewareList.splice(n, 1);
                          }
                        }
                      }
                      this.$emit('getParentCourseWarePackageList');
                    },
                    error => {
                      layer.alert(error.msg);
                    }
                  );
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'disable':
          layer.confirm(
            '停用该课件包,所有该课件包的分发将会取消,是否继续停用?',
            {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    let type = this.$store.getters.SaaSClientInfo.EditionKey;
                    let apiUrl = '';
                    if (type == 6) {
                      apiUrl = CWSCoursewarePackageManageDisable;
                    } else {
                      apiUrl = CoursewarePackageManageDisable;
                    }
                    apiUrl(data.ID).then(
                      result => {
                        // this.getNormalCoursewarePackage();
                        // this.selectEvent(this.coursewareList[0]);
                        if (this.$refs.refCourseware) {
                          let locaIndex = this.coursewareList.findIndex(o => {
                            return o.ID == data.ParentKey;
                          });
                          if (locaIndex >= 0) {
                            this.coursewareList[locaIndex].childTree = [];
                            let n = this.coursewareList.findIndex(o => {
                              return o.ID == data.ID;
                            });
                            if (n > -1) {
                              this.coursewareList.splice(n, 1);
                            }

                            let findItem = this.coursewareList.find(o => {
                              return o.isSelected;
                            });
                            console.log(findItem, 'findItem');
                            if (findItem) {
                              this.$refs.refCourseware.editCoursewareInfo = findItem;
                            } else {
                              this.selectEvent({ ID: 0, MainDemoName: '' });
                            }
                          } else {
                            let n = this.coursewareList.findIndex(o => {
                              return o.ID == data.ID;
                            });
                            if (n >= 0) {
                              this.coursewareList.splice(n, 1);
                            }
                            let findItem = this.coursewareList.find(o => {
                              return o.isSelected;
                            });
                            console.log(findItem, 'findItem');
                            if (findItem) {
                              this.$refs.refCourseware.editCoursewareInfo = findItem;
                            } else {
                              this.selectEvent({ ID: 0, MainDemoName: '' });
                            }
                          }

                          this.$emit('getParentCourseWarePackageList');
                        }
                      },
                      error => {
                        layer.alert(error.msg);
                      }
                    );
                    layer.close();
                  }
                }
              ]
            }
          );

          break;
        case 'enable':
          CoursewarePackageManageEnable(data.ID).then(
            result => {
              console.log('启用成功', result.data);
              layer.alert('启用成功');
              this.getNormalCoursewarePackage();
              this.$emit('getParentCourseWarePackageList');
              this.getRecycleCoursewarePackage();
            },
            error => {
              layer.alert(error.msg);
            }
          );
          break;
        default:
          break;
      }
    },
    setCoursewareList (data) {
      let locaIndex = this.coursewareList.findIndex(o => {
        return o.ID == data.ID;
      });
      if (locaIndex == -1) {
        this.$set(data, 'isSelected', false);
        this.$set(data, 'isOpt', false);
        this.$set(data, 'childTree', []);
        this.coursewareList.push(data);
      }
      console.log('setCoursewareList', this.coursewareList);
    },
    // 课件内容-新增课件
    coursewareOperate (type, data, deviceInfo) {
      console.log(type, data, '课件内容操作');
      switch (type) {
        case 'Add':
          this.coursewareId = '';
          this.getCoursewareDeviceInfo(
            this.coursewarePackageInfo.ID,
            deviceInfo
          );
          break;
        case 'Edit':
          this.coursewareEditInfo.CoursewareID = data.OLAPKey;
          this.isShowBody = false;
          break;
        case 'Restore':
          VersionRestoreCourseware(data.OLAPKey).then(
            res => {
              layer.alert(data.MainDemoName + '还原成功');
              this.getNormalCoursewarePackage();
              this.$emit('getParentCourseWarePackageList');
            },
            err => {
              layer.alert(err.msg);
            }
          );
          break;
        case 'Delete':
          layer.confirm('确定删除该课件?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.coursewareId = data.OLAPKey;
                  this.deleteCourseware();
                  layer.close();
                }
              }
            ]
          });
          break;
        default:
          break;
      }
    },
    // 获取课件新增信息
    getCoursewareDeviceInfo (coursewarePackageID, deviceInfo) {
      CoursewareManageAdd(coursewarePackageID, deviceInfo).then(
        result => {
          this.coursewareEditInfo = result.data;
          this.isShowBody = false;
          localStorage.setItem('isAddCourse', 1);
          console.log('设置---------------')
          this.$emit('getParentCourseWarePackageList');
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 删除
    deleteCourseware () {
      CoursewareManageDelete(this.coursewareId).then(
        result => {
          this.coursewarePackageID = this.coursewarePackageInfo.ID;
          let locaItem = this.coursewareList.find(o => {
              return o.ID == this.coursewarePackageInfo.ParentKey;
          });
          if (locaItem) {
              locaItem.childTree = [];
                  this.coursewareList = this.coursewareList.filter(o => {
                  return o.TypeKey == 2 && o.ParentKey != locaItem.ID;
              });
              this.selectEventSecond(locaItem);
          }else{
            this.coursewareList = [];
            // this.getNormalCoursewarePackage();

          }
          this.getNormalCoursewarePackage();
          this.$emit('getParentCourseWarePackageList');
          // this.selectEvent(this.coursewarePackageInfo);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.generalCoursewareManagementDialogFactoryCommon) {
        this.$refs.generalCoursewareManagementDialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callback,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>
<style scoped>
@media (-webkit-min-device-pixel-ratio: 1.25), screen and (max-width: 1680px) {
  .reMaxCoursewareList .courseware_manage_view {
    width: 100% !important;
  }
  .reMaxCoursewareList .courseware_manage_view .courseware_manage_right {
    margin-left: 20px;
  }
}
</style>
