<template>
  <div class="top_left"
       :class="sumData.iconClass">
    <div class="top_left_title">{{sumData.title}}</div>
    <div class="top_left_number">{{vTextValue}}
      <div class="top_left_month"
           :style="vCursor"
           v-if="sumData.date"
           @click="cilckEvent">{{sumData.date.dateType}}
        <span class="font_blue">+{{sumData.date.dateSum}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sumData: {
      type: Object,
      required: true,
      default: () => {
        return { title: '标题名', value: 0, iconClass: '', isFilter: '是否支持点击过滤', date: null };
      }
    }
  },
  computed: {
    vCursor () {
      if (this.sumData.date && Number(this.sumData.date.dateSum) > 0 && this.sumData.isFilter) {
        return 'cursor: pointer';
      } else {
        return 'cursor: default';
      }
    },
    vTextValue () {
      if (Number(this.sumData.value) > 0) {
        return this.$utils.mAmountType(this.sumData.value, this.sumData.decimalPlace);
      } else {
        return this.sumData.value;
      }
    }
  },
  methods: {
    cilckEvent () {
      if (this.sumData.date && Number(this.sumData.date.dateSum) > 0 && this.sumData.isFilter) {
        this.$emit('onCilckEvent', this.sumData);
      }
    }
  }
};
</script>

<style>
</style>