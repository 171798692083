<template>
  <div class="courseware_add_box">
    <div class="courseware_add_title_box">
      <input class="input_box"
             type="text"
             v-model="dataInfo.MainDemoName"
             :placeholder="vPlaceholderText"
             @blur="blurAccessoryTitle" />
      <div class="delete_btn"
           @click="deleteItem"></div>
    </div>
    <upload v-if="(!dataInfo.ID || !dataInfo.AttachViewUrl) ||vErrorkey"
            :dataInfo="dataInfo"
            @seletedPPTItem="seletedPPTItem"></upload>
    <image-editor v-else-if="attachType == 'img'"
                  :dataInfo="dataInfo"
                  @overLoad="hiddenUpLoad"></image-editor>
    <video-editor v-else-if="attachType == 'video'"
                  :dataInfo="dataInfo"></video-editor>
    <audio-editor v-else-if="attachType == 'audio'&&isShowAudio"
                  :dataInfo="dataInfo"></audio-editor>
    <ppt-editor v-else-if="attachType == 'ppt'"
                :dataInfo="dataInfo"></ppt-editor>
    <pdf-editor v-else-if="attachType == 'pdf'"
                :dataInfo="dataInfo"></pdf-editor>
  </div>
</template>
<script>
import audioEditor from './audio-editor';
import imageEditor from './image-editor';
import pptEditor from './ppt-editor';
import pdfEditor from './pdf-editor';
import videoEditor from './video-editor';
import upload from './upload';
import {
  HQDeleteCoursewareAttach,
  DeleteCoursewareAttach,
  DeleteCoursewareManageAttach
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      isShowUpLoad: false,
      isShowAudio: true,
      AudioVerify: null
    };
  },
  props: {
    dataInfo: Object,
    attachType: String
  },
  components: {
    audioEditor,
    imageEditor,
    pptEditor,
    pdfEditor,
    videoEditor,
    upload
  },
  created () {
    if (this.dataInfo.percent < 100) {
      this.isShowUpLoad = true;
    }
    this.errorMessage();
    this.$bus.on(this, 'wsCoursewareEditor', (data) => {
      console.log('监听到');
      // console.log(data, this.dataInfo, this.attachType, '封面显示');
      if (data.DataKey == this.dataInfo.ID && data.status == 1) { // 成功
        this.dataInfo.ResultStageKey = 0;
        this.dataInfo.UnusualStatusKey = 0;
        if (data.Data.AttachTypeKey == '2') {
          this.dataInfo.AttachViewUrl = data.Data.AttachViewUrl;
        }
      }

      // if()
    });
    // this.$bus.on(this, 'pptPageVerify', this.AudioVerify = (data) => {
    //   console.log('pptPageVerify', data);
    //   // this.setDataListItem(data);
    //   if (data.DataKey == this.dataInfo.ID && data.status == 1) { // 成功
    //     this.dataInfo.ResultStageKey = 0;
    //     this.dataInfo.UnusualStatusKey = 0;
    //     if (data.Data.AttachTypeKey == '2') {
    //       console.log('监听到');
    //       this.dataInfo.AttachViewUrl = data.Data.AttachViewUrl;
    //     }
    //   }
    // });
    // console.log(this.dataInfo, this.attachType, '封面显示2');
  },
  destroyed () {
    this.$bus.off(this, 'pptPageVerify', this.AudioVerify);
  },
  computed: {
    vPlaceholderText () {
      if (this.attachType == 'img') {
        return '点击编辑图片标题';
      } else if (this.attachType == 'video') {
        return '点击编辑视频标题';
      } else if (this.attachType == 'audio') {
        return '点击编辑音频标题';
      } else if (this.attachType == 'ppt') {
        return '点击编辑PPT标题';
      } else if (this.attachType == 'pdf') {
        return '点击编辑PDF标题';
      }
    },
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) {
        // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vErrorkey () {
      if (this.dataInfo.ResultStageKey && this.dataInfo.UnusualStatusKey && (this.attachType == 'video' || this.attachType == 'ppt')) {
        return (Number(this.dataInfo.ResultStageKey) != 0 || Number(this.dataInfo.UnusualStatusKey) != 0);
      } else {
        return false;
      }
    }
  },
  watch: {
    'dataInfo.AttachViewUrl' (n, o) {
      // if (o) {
      //   if (
      //     this.isShowUpLoad &&
      //     (!this.dataInfo.percent || this.dataInfo.percent == 100) &&
      //     this.dataInfo.CoverUrl
      //   ) {
      //     this.isShowUpLoad = false;
      //   }
      // }
      console.log('dddddddddddddddddddd');
      if (this.attachType == 'audio') {
        console.log('sasaaaaaaaaaaaaaaaa');
        this.isShowAudio = false;
        setTimeout(() => {
          this.isShowAudio = true;
          console.log('1111111111111');
        }, 10);
      }

      this.errorMessage();
    }
  },
  methods: {
    seletedPPTItem (data) {
      this.$emit('seletedPPTItem', data);
    },
    errorMessage () {
      // console.log(this.dataInfo, this.attachType, 'courseware-edit-errorMessage(dataInfo)');
      if (this.dataInfo.ResultStageKey > 0 && this.dataInfo.UnusualStatusKey == 0) {
        this.isShowUpLoad = true;
        this.dataInfo.Remark = this.dataInfo.ResultStageKeyValue;
        let isTimeOut = Date.new().getTime() - Date.new(this.dataInfo.DealTime).getTime() > 86400000;
        if (isTimeOut) {
          // layer.alert('附件处理超时，请删除重新上传，如多次失败，请联系专属客服');
        }
      } else {
        if (this.dataInfo.ResultStageKey == 3) {
          // layer.alert('封面生成失败，请确认文件是否正常');
        } else if (this.dataInfo.ResultStageKey == 4 || this.dataInfo.ResultStageKey == 5) {
          // layer.alert('转码失败，请重试，如多次失败，请联系专属客服');
        } else if (this.dataInfo.Remark) {
          // layer.alert(this.dataInfo.Remark);
        }
      }
    },
    blurAccessoryTitle () {
      if (this.dataInfo.MainDemoName.length <= 100) {
        this.$emit('saveAccessory');
      } else {
        layer.alert('附件名字不能超过100字符');
      }
    },
    deleteItem () {
      console.log('deleteItem', this.dataInfo);
      if (this.dataInfo.ID > 0) {
        layer.confirm('是否删除当前内容', {
          btn: [
            {
              name: '取消',
              callBack: () => {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                if (this.dataInfo.ID) {
                  // console.log(this.dataInfo, '删除');
                  DeleteCoursewareManageAttach(this.dataInfo.ID).then((res) => {

                  }, (err) => {
                    layer.alert(err.msg);
                  }
                  );
                }

                // if(this.vTypeKey) {
                //   HQDeleteCoursewareAttach(this.dataInfo.ID).then(res=>{
                //   },err=>{
                //     layer.alert(err.msg)
                //   })
                // }else {
                //   DeleteCoursewareAttach(this.dataInfo.ID).then(res=>{
                //   },err=>{
                //     layer.alert(err.msg)
                //   })
                // }
                this.$emit('deleteItem', this.dataInfo);
                layer.close();
              }
            }
          ]
        });
      } else {
        layer.alert('文件正在上传中，请上传成功后再删除');
      }
    },
    hiddenUpLoad () {
      this.isShowUpLoad = false;
    }
  }
};
</script>