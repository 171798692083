<template>
    <!-- 课件授权管理 -->
  <div class="super_management">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin"> 
        <div @click="showUsingPackageClick"
             class="table_fixed_nav_list" 
             :class="{opt:showManagement=='UsingPackage'}">在用大课包</div>
        <!-- <div @click="showHistoryPackageClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='HistoryPackage'}">历史大课包</div>
        <div @click="showUseAuthorizationClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='UseAuthorization'}">在用授权</div>
        <div @click="showExpiredAuthorizationClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='ExpiredAuthorization'}">失效授权</div> -->
      </div>
    </div>
    <div>
      <!-- 在用大课包列表 -->
      <div class="showManagement" v-if="showManagement=='UsingPackage'">
        <using-package></using-package>
      </div>
      <!-- 历史大课包列表 -->
      <div class="showManagement" v-else-if="showManagement=='HistoryPackage'">
        <history-package></history-package>
      </div>
       <!-- 在用授权列表 -->
      <div class="showManagement" v-if="showManagement=='UseAuthorization'">
        <use-authorization></use-authorization>
      </div>
      <!-- 过期授权列表 -->
      <div class="showManagement" v-else-if="showManagement=='ExpiredAuthorization'">
        <expired-authorization></expired-authorization>
      </div>

    </div>
    <div>
      <dialog-factory ref="coursewareAuthorizationManagementDialog"
                      :routerName="'coursewareAuthorizationManagement'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import DialogFactory from '../../common/dialog-factory';
import ExpiredAuthorization from './expired-authorization/index.vue'
import UseAuthorization from './use-authorization/index.vue'
import HistoryPackage from './history-package/index.vue'
import UsingPackage from './using-package/index.vue'
export default {
  name: 'coursewareAuthorizationManagement',
  data () {
    return {
      showManagement: 'UsingPackage'
    };
  },
  components: {
   UsingPackage,
   HistoryPackage,
   UseAuthorization,
   ExpiredAuthorization,
   DialogFactory
  },
  created () {
     this.registerBusEvent()
  },
  mounted () {

  },
  methods: {
    // 切换门店列表
    showUsingPackageClick () {
      this.showManagement = 'UsingPackage';
    },
    // 切换管理历史记录列表
    showHistoryPackageClick () {
      this.showManagement = 'HistoryPackage';
    },
    showUseAuthorizationClick () {
      this.showManagement = 'UseAuthorization';
    },
    // 切换管理历史记录列表
    showExpiredAuthorizationClick () {
      this.showManagement = 'ExpiredAuthorization';
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'coursewareAuthorizationManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
     openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursewareAuthorizationManagementDialog) {
        this.$refs.coursewareAuthorizationManagementDialog.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    
  },
  computed: {}
};
</script>
<style scoped>
.super_management .tltle_margin{
  margin: 0 auto;
}
.showManagement{
  margin: 0 auto;
  width: 1290px;
}
.super_management{
  margin-bottom: 0px;
  margin-top: 68px; 
}
.table_fixed_nav_list {
  padding: 0;
   width: 155px;
}
</style>