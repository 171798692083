<template>
  <!-- 通用列表 -->
  <div>
    <!-- 弹框显示模式 -->
    <div>
      <custom-dialog :title="listTitle"
                     width="1213px"
                     show-position="top"
                     :close-on-click-modal="false"
                     class="class_staistics_list defult_dialog"
                     :visible.sync="isShowListDialog"
                     :before-close="closeDialog">
        <div class="group_detail_box type_small">
          <div v-for="(item,index) in titleInfoList"
               :key="index"
               class="group_detail_list"
               style="width:260px">
            <div><span class="group_detail_list_name">{{item.name}} : </span>{{item.text}}</div>
          </div>
        </div>
        <div class="group_detail_award_box flex">
          <div class="group_detail_award">
            邀请成功奖励 {{awardText}} /人！
          </div>
        </div>
        <div class="table_list_content summarizing_list">
          <div class="single_result_detail">
            <!-- 表格 -->
            <div class="">
              <el-table class="table_list"
                        ref="multipleTable"
                        :data="tableData"
                        highlight-current-row
                        stripe
                        empty-text
                        toggleRowSelection
                        @sort-change="sortChange"
                        @row-click="clickRow"
                        :row-key="rowKey||'none'"
                        :expand-row-keys="expandRowKeys"
                        style="width: 100%">
                <!-- 表头 -->
                <!-- <el-table-column type="selection"
                                 width="55"></el-table-column> -->
                <el-table-column v-for="(item,index) in vTableColumns"
                                 :width="item.width"
                                 :sortable="item.sortable"
                                 :align="item.align"
                                 :key="index"
                                 :type="item.type"
                                 :label="item.label"
                                 :prop="item.prop"
                                 :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
                    <span v-else-if="item.type=='table-index'"
                          class="">{{setSerialNumber(scope.row[item.prop])}}</span>
                    <el-button v-else-if="item.type=='tableBtn'"
                               v-for="(info,indexs) in item.tableBtnGroup"
                               :class="info.className(scope.row[info.Stutas.split(',')[0]],scope.row[info.Stutas.split(',')[1]])"
                               class="tableBtn"
                               :key="indexs"
                               size="mini"
                               @click.stop="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
                    <span v-else-if="item.type=='tolink'"
                          @click.stop="openLink(scope.row[item.prop])">{{scope.row[item.prop]}}</span>
                    <span v-else-if="item.type=='html'"
                          v-html="scope.row[item.prop]">{{scope.row[item.prop]}}</span>
                    <span v-else>{{scope.row[item.prop]}}</span>
                  </template>
                </el-table-column>
                <!-- 收起部分内容 -->
                <el-table-column fixed="center"
                                 type="expand">
                  <template slot-scope="props">
                    <el-form label-position="left"
                             inline
                             class="table_expand_item demo-table-expand">
                      <el-form-item :label="item.label"
                                    v-for="(item,index) in detailColumns"
                                    :key="index">
                        <span :title="props.row[item.prop]">{{ props.row[item.prop] }}</span>
                      </el-form-item>
                    </el-form>
                  </template>
                </el-table-column>
                <div slot="empty"
                     style="height:440px;line-height:440px;">
                  <i class="el-icon-warning-outline">
                    <div class="monitoring_content_nodata"></div>
                    亲，当前没有任何数据哦~
                  </i>
                </div>
              </el-table>
              <!-- 分页 -->
              <div class="table_pagination_right table_pagination">
                <el-pagination @current-change="handleCurrentChange"
                               :page-size="10"
                               :current-page.sync="vPageIndex"
                               background
                               layout="prev, pager, next, jumper"
                               :total="totalNum"></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </custom-dialog>
    </div>

  </div>
</template>
<script>
import initJson from '../../../js/initJson.json';
import { parseJson } from '../../../utils';
// import layer from '../../../public/js/layer';
let initQueryData = {
  pageIndex: 0,
  pageSize: 10,
  searchText: '',
  orderBy: '',
  sequence: ''
};
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      isToSearch: true,
      isSearch: false,      // 决定是否显示展开按钮
      isShowListDialog: false, // 是否显示主界面
      isShowMoreSearchCondition: false, // 是否显示更多查询条件
      selectedRow: {}, // 选中行
      isLoading: true, // 正在加载
      selectedParams: [], // 扩展查询参数 [{name:'参数名',value:'值'}]
      unClearParams: [], // 不可清除只展示的显示参数 [{name:'参数名',value:'值'}]
      searchConditionType: {}, // 扩展查询条件类型
      expandRowKeys: [] // 展开的行,
    };
  },
  props: {
    listTitle: {
      // 列表头
      type: String,
      required: true
    },
    awardText: String,
    titleInfoList: Array,
    functionBtnGroup: Array, // 功能按钮组
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 列表总共条数
    PageCount: Number, // 共几页
    tableColumns: Array, // table column
    btnDelete: false, // 回收站显示隐藏
    hiddenNumber: 0, // 回收站总数据
    isDialogShow: String, // 是否需要显示弹框，默认开启
    listTitleName: String // 是否显示自定义列表名称

  },
  components: {

  },
  computed: {
    visDialogShow () {
      if (this.isDialogShow == '关闭') {
        return false;
      } else {
        return true;
      }
    },
    vlistTitleName () {
      if (this.listTitleName) {
        return this.listTitleName;
      } else {
        return false;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    },
    // 标题头部
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    }

  },
  created () {
    this.searchConditionType = initJson.extendSearchConditionType;
    this.initQueryParams();
  },
  methods: {
    vItem (imgurl) {
      var ImgObj = new Image(); // 判断图片是否存在
      ImgObj.src = imgurl;
      // 存在图片
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        return true;
      } else {
        return false;
      }
    },
    logoImgShow (logo) {
      console.log(logo, 'data');
      if (logo) {
        if (logo.indexOf('/Upload/') != -1) {
          return this.$store.getters.CDNURL + logo;
        } else {
          return this.$store.getters.CDNURL + '/PUBLIC/images/base64img/' + logo;
        }
      } else {
        return 'this.src="' + '"';
      }
    },
    getLabel (item, props) {
      // console.log(props.row[item.dynamicLabel], '1111111');
      // console.log(item, '22222222');
      if (props.row[item.dynamicLabel]) {
        return props.row[item.dynamicLabel];
      } else {
        if (item.label) {
          return item.label + ' : ';
        } else {
          return '';
        }
      }
    },
    hasRole (roleName) {
      return true;
    },
    // 域名打开新窗口
    openLink (rowData) {
      console.log(rowData, 'rowData');
      if (rowData) {
        window.open(rowData);
      } else {
        layer.alert('无法打开网页');
      }
    },
    // 首次打开 初始化查询参数（对外开放）
    initSelectedParams (selectedParams, unClearParams) {
      this.getSelectedParamsByClickSearchBtn(selectedParams);

      this.unClearParams = unClearParams;
    },
    // 构建查询参数
    initQueryParams () {
      this.queryParams = parseJson(initQueryData);
      // this.extendSearchCondition.forEach(condition => {
      //   condition.fieldList.forEach((param, index) => {
      //     this.queryParams[param.name] = param.defaultValue;
      //   });
      // });
    },
    // 点击查询按钮
    searchBtn () {
      // 若更多查询条件 显示 重新收集已选参数

      if (this.isShowMoreSearchCondition) {
        this.$refs.searchConditionGroup.getSelectedParams(this.selectedParams);
        // 反之 直接查询
      } else {
        this.queryParams.pageIndex = 0;
        this.queryParams.searchText = this.searchText;
        this.$emit('loadTableData', this.queryParams);
      }
      this.$refs.multipleTable.setCurrentRow();
      this.selectedParams.forEach(o => {
        if (o.name == 'isshare' && o.value !== 0) {
          this.isToSearch = true;
        }
        if (o.name == 'isshare' && o.value == 0) {
          this.isToSearch = false;
        }
      });
    },
    // 点击查询后 获取已选参数条件 并且拼接查询参数 获取列表
    getSelectedParamsByClickSearchBtn (selectedParams) {
      this.initQueryParams();
      console.log(this.selectedParams, 'this.selectedParams');
      this.selectedParams = selectedParams;
      for (var index in selectedParams) {
        this.queryParams[selectedParams[index].name] = selectedParams[index].value;
      }
      this.isShowMoreSearchCondition = false;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.setCurrentRow();
      }
      this.queryParams.searchText = this.searchText;
      this.$emit('loadTableData', this.queryParams);
    },
    // 显示更多查询条件
    showMoreSearchCondition () {
      this.isShowMoreSearchCondition = true;
      this.$nextTick(() => {
        this.$refs.searchConditionGroup.showExtendSearchCondition(this.selectedParams);
      });
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.$emit('loadTableData', this.queryParams);
    },
    // 保留小数位数
    rounding (row, value, decimalPlace) {
      return Number(row[value]).toFixed(decimalPlace);
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence =
          column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.$emit('loadTableData', this.queryParams);
    },
    // 隐藏更多查询条件
    hideMoreSearchCondition () {
      this.isShowMoreSearchCondition = false;
    },
    // 删除某个已选条件 重构queryParams重新刷新
    doAfterChangeExtendSearchParams () {
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
    },
    // 清除已选条件栏  需重新刷新
    clearExtendSearchParams () {
      let unClearSelectedParams = [];
      this.selectedParams.forEach(obj => {
        if (obj.unClear) {
          unClearSelectedParams.push(obj);
        }
      });
      this.selectedParams = unClearSelectedParams;
      console.log(this.selectedParams, 'selectedParams');
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
      this.isToSearch = false;
    },
    // 关闭窗口
    closeDialog () {
      this.isShowListDialog = false;
      this.searchText = '';
      this.isShowMoreSearchCondition = false;
      this.isToSearch = true;
      this.initQueryParams();
    },
    // 点击功能按钮
    actionBtnClick (item) {
      // this.$emit('actionBtnClick', item.eventName);
    },
    // 点击图片
    imgClick (rowData) {
      console.log(rowData, '点击图片');
      this.$emit('imgClick', rowData);
    },
    // 操作点击表单按钮(第一个参数为券对象，第二个参数为按钮对象)
    tableBtnClick (rowData, item) {
      console.log(item.name, '功能按钮');
      // console.log(rowData);
      switch (item.name) {
        case '停用':
          this.doAffirmHint(rowData, item, item.name);
          break;
        case '启用':
          this.doAffirmHint(rowData, item, item.name);
          break;
        case '删除':
          this.doAffirmHint(rowData, item, item.name);
          break;
        default:
          if (item.eventName == initJson.baseFunctionBtnName.detail) {
            if (rowData[this.rowKey] == this.expandRowKeys[0]) {
              this.expandRowKeys = [];
            } else {
              this.expandRowKeys = [];
              this.expandRowKeys.push(rowData[this.rowKey]);
              this.$emit('tableBtnClick', rowData, item.eventName);
            }
          } else {
            this.$emit('tableBtnClick', rowData, item.eventName);
          }
          break;
      }
    },
    doAffirmHint (rowData, item, hint) {
      var _this = this;
      layer.confirm('请确定是否' + hint + '?', {
        btn: [{
          name: '确认',
          callBack: function () {
            layer.close();
            // layer.alert(hint + '成功', {
            // btn: [ {
            // name: '确认',
            // callBack: function () {
            if (item.eventName == initJson.baseFunctionBtnName.detail) {
              if (rowData[_this.rowKey] == _this.expandRowKeys[0]) {
                _this.expandRowKeys = [];
              } else {
                _this.expandRowKeys = [];
                _this.expandRowKeys.push(rowData[_this.rowKey]);
                _this.$emit('tableBtnClick', rowData, item.eventName);
              }
            } else {
              _this.$emit('tableBtnClick', rowData, item.eventName);
            }
            layer.close();
            // }
            // }
            // ]
            // });
          }
        }
        ]
      });
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 回收站数据切换
    btnDeleteList (IsHiddenKey) {
      let HiddenKey = true;
      this.selectedParams.forEach(e => {
        if (e.name == 'IsHiddenKey' && e.value == 1) {
          HiddenKey = false;
        }
      });
      if (HiddenKey) {
        this.selectedParams = [];
        this.selectedParams.push({ name: 'IsHiddenKey', value: 1 });

        this.getSelectedParamsByClickSearchBtn(this.selectedParams);
      }
    },
    // 判定显示按钮名
    ifBtnName (data) {
      if (data.IsHiddenKey == 1) {
        return '恢复';
      } else {
        return '隐藏';
      }
    },
    setSerialNumber (num) {
      if (num > 0) {
        return num > 9 ? num : '0' + Number(num);
      } else {
        return '';
      }
    }
  }
};
</script>
<style>
</style>


