<template>
  <!-- 全部学生 -->
  <div class="all_student minHeight bg_gray">
    <!-- <basics-page-header :titleName="'我的学生'"></basics-page-header> -->
    <div class="minHeight">
      <!-- 全部学生 -->
      <div v-if="studentList.length>0"
           class="student_manage_list bg_white">
        <!-- <scroll-div class="div_box pf fixed_info_ul" style="top: 86rpx;border-top: none" scroll-y="true"
          lower-threshold="80"> -->
        <div class=""
             v-for="(item,index) in studentList"
             :key="index">
          <div class="form_header_gray">
            <div class="flex">
              <div class="text_hidden"
                   style="max-width: 280px;">{{item.GradeName}}</div>
              <div style="padding-left: 5px;">{{item.dataList.length}}人</div>
            </div>
          </div>
          <div class="school_student_ul">
            <student-item v-for="(obj,index1) in item.dataList"
                          :key="index1"
                          :studentInfo="obj"
                          type="all">
            </student-item>
          </div>
        </div>
        <!-- </scroll-div> -->
      </div>
      <div v-if="studentList.length == 0">
        <div class="monitoring_content_nodata"
             style="margin-top: 120px;">
          <div class="monitoring_content_nodata_txt2">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { GetTeacherStudentList } from '../../../../API/workbench';
import studentItem from './student-item.vue';
export default {
  components: {
    studentItem
  },
  data () {
    return {
      studentList: [], // 全部学生
    };
  },
  created () {
    this.getStudentList();
    console.log('全部学生列表');
  },
  computed: {
    vLessonContentList () {
      var obj = {};
      var arr = this.lessonContentList.reduce(function (a, b) {
        obj[b.ClassTime] ? '' : obj[b.ClassTime] = true && a.push(b);
        return a;
      }, []);
      var list = [];
      arr.forEach(n => {
        const monthItem = {
          dateTime: '',
          dataList: []
        };
        this.lessonContentList.forEach(o => {
          if (o.ClassTime == n.ClassTime) {
            const date = this.$utils.formatDateToYYYYMMDD(n.ClassTime).slice(5, 11);
            const week = this.$utils.getWeek(this.$utils.formatDateToLine(n.ClassTime));
            monthItem.dateTime = date + ' ' + week;
            monthItem.dataList.push(o);
          }
        });
        list.push(monthItem);
      });
      console.log(list, 'list');
      return list;
    }
  },
  methods: {
    // 获取全部学生列表
    getStudentList () {
      GetTeacherStudentList().then(result => {
        console.log(result.data, '获取我的学生列表');
        var obj = {};
        var arr = result.data.reduce(function (a, b) {
          obj[b.GradeKey] ? '' : obj[b.GradeKey] = true && a.push(b);
          return a;
        }, []);
        arr.forEach(n => {
          const Item = {
            GradeName: '',
            dataList: []
          };
          result.data.forEach(o => {
            o.OLAPKey = o.StudentKey
            o.MainDemoName = o.StudentKeyValue
            if (o.GradeKey == n.GradeKey) {
              Item.GradeName = n.GradeKeyValue;
              Item.dataList.push(o);
            }
          });
          this.studentList.push(Item);
        });
        console.log(this.studentList, 'this.studentList');
      }, error => {
        this.$showModal({
          content: error.msg
        });
      });
    },
  }
};
</script>

<style scoped>
.div_box.pf {
  left: 0;
  right: 0;
  /* #ifdef APP-PLUS */
  top: 0px !important;
  /* #endif */
  bottom: 0;
  overflow-y: auto;
}

.bg_white {
  padding-top: 1px;
  background-color: #fff !important;
}

.text_hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed_info_ul {
  overflow-y: auto;
  border-top: 1px solid #ececec;
}

.fixed_info_ul.height_top {
  max-height: 460px;
}

.fixed_info_ul.height_top_list {
  height: 360px;
}

.fixed_info_ul .student_leave_ul {
  top: 0px;
  bottom: 0;
}

.popup_all_div .fixed_info_ul {
  max-height: inherit;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  top: 109px;
  padding-bottom: 1px;
}

.school_student_ul {
  padding-left: 15px;
  background-color: #fff;
}

.school_student_ul .student_info_list {
  min-height: 54px;
  padding: 15px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: none;
}

.school_student_ul .student_info_list::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 0;
  height: 1px;
  background-color: #ececec;
}

.school_student_ul .student_info_list:last-child::after {
  background-color: #fff;
}

.student_info_list .list_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 12px;
  text-align: center;
  line-height: 38px;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
}

.student_info_list .list_img.bg_1 {
  background-color: #bad7df;
}

.student_info_list .list_img.bg_2 {
  background-color: #769fcd;
}

.student_info_list .list_img.bg_3 {
  background-color: #99ddcc;
}

.student_info_list .list_img.bg_4 {
  background-color: #fbafaf;
}

.student_info_list .list_img.bg_5 {
  background-color: #f2c6b4;
}

.student_info_list .list_img.bg_6 {
  background-color: #b9bbdf;
}

.student_info_list .list_img.bg_7 {
  background-color: #99e1e5;
}

.student_info_list .list_img.bg_8 {
  background-color: #c1c0b9;
}

.student_info_list .list_img .img_box {
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.school_student_ul .student_info_list .list_name {
  font-size: 16px;
  line-height: 20px;
  margin-right: 9px;
  margin-bottom: 2px;
  max-width: 100px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.school_student_ul .student_info_list .list_text {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  margin-bottom: 2px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.school_student_ul .student_info_list .list_text .text_box {
  display: inline-block;
  max-width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
  padding-right: 10px;
}

.list_name .list_WX,
.list_name .list_phone {
  display: inline-block;
}

.list_name .list_WX,
.school_student_ul .student_info_list .list_WX {
  width: 22px;
  height: 22px;
  background: url('../../../../../public/image/wx_icon.png') no-repeat;
  background-size: 22px;
  position: static;
}

.list_name .list_WX.type_gray,
.student_info_list .list_WX.type_gray {
  width: 22px;
  height: 22px;
  background-size: 22px;
  background-image: url('../../../../../public/image/wx_icon_no.png');
}

.list_name .list_phone,
.school_student_ul .student_info_list .list_phone {
  width: 22px;
  height: 22px;

  margin-left: 16px;
  margin-right: 15px;

  background-image: url('../../../../../public/image/student_phone_icon.png');
  background-size: 20px;
}

.list_name .list_phone.type_gray,
.school_student_ul .student_info_list .list_phone.type_gray {
  width: 22px;
  height: 22px;

  background-image: url('../../../../../public/image/student_phone_icon_gray.png') !important;
  background-size: 20px;
}

.student_info_list {
  min-height: 54px;
  padding: 15px 0;
  border-bottom: 1px solid #ececec;
  position: relative;
}

.student_info_list:last-child {
  border-bottom: none;
}

.student_info_list .list_name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-right: 9px;
  margin-bottom: 2px;
  max-width: 100px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student_info_list .list_phone {
  font-size: 12px;
  line-height: 20px;
  color: #999;
}

.student_info_list .list_text {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  margin-bottom: 2px;
}

.student_info_list .list_WX {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 0;
  top: 15px;
  background: url('../../../../../public/image/wx_icon.png') no-repeat;
  background-size: 18px;
}

.student_info_list .list_type {
  position: absolute;
  right: 30px;
  top: 15px;
  width: 38px;
  height: 18px;
  line-height: 16px;
  border: 1px solid #c3cad9;
  font-size: 12px;
  border-radius: 20px;
  text-align: center;
  color: #909399;
  background-color: #e7eaef;
}

.student_info_list .list_type.type_blue {
  color: #1989fa;
  background-color: #d1e7fe;
  border-color: #8cc4fc;
}

.student_info_list .list_type.type_yellow {
  color: #ff8c39;
  background-color: #fffbe6;
  border-color: #ffe58f;
}

.bg_gray {
  background-color: #f5f5f5;
  min-height: 100%;
}

.bg_gray.pf {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
}

.minHeight {
  min-height: 100%;
}
</style>
