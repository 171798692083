<template>
  <!-- 左边导航栏切换 -->
  <div class="course_content_left">
    <!-- 课程介绍 -->
    <div class="course_introduction_div"
         v-if="courseIndex==2">
      <div class="c_i_content"
           v-for="(item,index) in courseList"
           :key="index">
        <div class="bgimg-box"
             v-if='item.AttachTypeKey == 1'>
          <img :src="item.img"
               class="swimg"
               :id="'Imgbox_'+item.ShowOrder" />
          <span class="c_i_span"
                :title="item.MainDemoName">{{ item.MainDemoName }}</span>
          <div class="link_wrapper"
               @click="download(item.img,item.MainDemoName,item.ShowOrder)">下载</div>
        </div>

        <div v-else
             class='course_content_ppt'>
          <img src="../../../../../public/image/ppt_icon.png"
               mode="scaleToFill"
               class='ppt_img'>
          <div class='course_name'>
            <span class="c_name"
                  :title="item.MainDemoName">{{ item.MainDemoName }}</span>
            <div class='c_MB'>{{ Number($utils.mMoneyType((Number(item.AttachSize) / 1024 / 1024), 3)) +'MB'}}</div>
          </div>
          <div class="open_ppt"
               @click.stop="openPPTbtn(item.img,item.MainDemoName,item.ShowOrder)">打开</div>
        </div>

      </div>
    </div>
    <!-- 附件下载-->
    <div class="course_introduction_div"
         v-if=" courseIndex==3">
      <div class="c_i_content"
           style="margin-bottom: 20px;"
           v-for="(item,index) in courseList"
           :key="index">
        <div class="introduction-bgimg-div">
          <img class="introduction_img_box"
               :id="'Imgbox_'+item.ShowOrder"
               :src="require('../../../../../public/image/course_icon_0'+item.AttachTypeKey+'.png')"
               alt=""
               mode="aspectFit">
          <span class="c_i_span"
                :title="item.MainDemoName">{{ item.MainDemoName }}</span>
          <div class="link_wrapper"
               @click="introductionownload(item.AttachUrl,item.MainDemoName,item.ShowOrder)">下载</div>
        </div>
      </div>
    </div>
    <!-- 家长课堂 -->
    <div class="course_introduction_div"
         v-if="courseIndex==4">
      <div class="c_i_content"
           v-for="(item,index) in courseListA"
           :key="index">
        <div class="bgimg-box">
          <img :src="item.img"
               class="swimg"
               :id="'Imgbox_'+item.ShowOrder"
               @click.stop="prepare(item)" />
          <span class="c_i_span"
                :title="item.MainDemoName"
                @click.stop="prepare(item)">{{ item.MainDemoName }}</span>
        </div>
      </div>
    </div>

    <div class="cover_download_div"
         v-else-if="courseIndex==5">
      <div>
        <!-- 1 -->
        <div v-for="item in childNodes"
             :key="item.id">
          <div class="tree-menu-item"
               @click="toggleChildrenOne(item)">
            <span>{{ item.MainDemoName }}</span>
            <i class="icon"
               :class="{ 'icon-open': item.open }"></i>
            <div class="down_img"
                 :class="item.CoverIsDownload == 1? 'can_down':'no_can_down' "
                 @click.stop="downloadImg(item)">下载</div>
          </div>
          <!-- 2 -->
          <div v-if="item.children && item.open"
               style="margin-left: 40px;">
            <div v-for="child in item.children"
                 :key="child.id">
              <div class="tree-menu-item"
                   style='width:385px;'
                   @click="toggleChildrenTwo(item,child)">
                <span>{{ child.MainDemoName }}</span>
                <i class="icon"
                   :class="{ 'icon-open': child.open }"></i>
                <div class="down_img"
                     :class="child.CoverIsDownload == 1? 'can_down':'no_can_down' "
                     @click.stop="downloadImg(child)">下载</div>
              </div>
              <!-- 3 -->
              <div v-if="child.children && child.open"
                   style="margin-left: 40px;">
                <div v-for="son in child.children"
                     :key="son.id">
                  <div class="tree-menu-item"
                       style='width:345px;'
                       @click="toggleChildrenThree(son)">
                    <span>{{ son.MainDemoName }}</span>
                    <div class="down_img"
                         :class="son.CoverIsDownload == 1? 'can_down':'no_can_down' "
                         @click.stop="downloadImg(son)">下载</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="(courseIndex==2 || courseIndex==3) && courseList.length == 0">
      <div class="template_content_nodata_txt">暂无数据</div>
    </div>
    <div v-if="courseIndex==4 && courseListA.length == 0">
      <div class="template_content_nodata_txt">暂无数据</div>
    </div>
    <div v-if="courseIndex==5 && childNodes.length == 0">
      <div class="template_content_nodata_txt">暂无数据</div>
    </div>
  </div>
</template>

<script>
// getGetCoursewarePackageAttach 1-课包介绍2-附件下载
// getGetCoursewarePackageDownloadCoverUrlList 封面下载列表
// getCoursewarePackageParentList 获取大课包关联课件列表
import {
  getGetCoursewarePackageAttach,
  getGetCoursewarePackageDownloadCoverUrlList,
  getCoursewarePackageParentList,
  CWSTeacherGiveLectureSetUpScheduleCourse, CWSGetCoursewarePackageDetails, CWSTeacherWorkbenchGetCoursewarePackageDetails

} from '../../../../API/workbench';
export default {
  components: {

  },
  props: {
    courseIndex: Number,
    BigCoursewarePackageInfo: [Object, String],
    dataInfo: [Object, String],
    Source: [Number, String],
    CoursewarePackageKey: [Number, String],
  },
  data () {
    return {
      childNodes: [],
      courseList: [],
      courseListA: [],
      TeacherKey: '',
      childCWDataList: [],
      role: this.vRoleKey(),
      chooseCoursewareInfoType: '',
      chooseType: '',
      chooseScheduleCourseKey: '',
      maxCoursewarePackage: {}, // 子课包详情
      particulars: [],
      openClassData: [],
      IsContinueKey: 0,
      IsAuthKey: 0,
      TeacherID: '', // this.$store.getters.token.UserID || '' ,// 老师id
      busClassLocationEvent: null
    };
  },
  computed: {
    // 判断是校长还是老师
    vRoleKeyA () {
      console.log(Number(this.$store.getters.token.RoleKey), '校长and老师');
      return this.$store.getters.token.RoleKey ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },
    vUrl (item) {
      if (item.AttachTypeKey == '1') {
        return require('../../../../../public/image/material_icon01.png');
        // return require('../../../../../public/image/pic.png');
      }
      if (item.AttachTypeKey == '2') {
        return require('../../../../../public/image/material_icon02.png');
      } else if (item.AttachTypeKey == '3') {
        return require('../../../../../public/image/material_icon04.png');
      } else if (item.AttachTypeKey == '4') {
        return require('../../../../../public/image/material_icon03.png');
      } else if (item.AttachTypeKey == '5') {
        return require('../../../../../public/image/material_icon05.png');
      } else if (item.AttachTypeKey == '8') {
        return require('../../../../../public/image/material_icon09.png');
      } else if (item.AttachTypeKey == '6') {
        return require('../../../../../public/image/material_icon11.png');
      } else if (item.AttachTypeKey == '7') {
        return require('../../../../../public/image/material_icon10.png');
      }
    },
    vdataList () {
      let newArr = [];
      let childArr = [];
      // type 0-子课包；1-大课包；2-目录
      this.childCWDataList.forEach(o => {
        o.label = o.MainDemoName;
        o.id = o.OLAPKey;
        o.OLAPKey = o.OLAPKey;
        o.icon = 'tree_file';
        if (o.TypeKey == 2) {
          o.level = 0;
          o.children = [];
          newArr.push(o);
        }
        if (o.TypeKey == 0) {
          o.level = 1;
          childArr.push(o);
        }
        if (o.children == undefined) {
          o.leaf = true;
        }
        console.log(o, 'oo');
      });
      if (newArr.length > 0) {
        console.log(newArr, 'oo');
      } else {
        newArr = childArr;
      }
      console.log(newArr, 'newArr111');
      return newArr;
    }
  },
  created () {
    this.TeacherKey = this.dataInfo.TeacherKey;
    if (this.TeacherKey) {
      this.TeacherKey = this.dataInfo.TeacherKey;
    } else {
      this.TeacherKey = this.$store.getters.token.UserID;
    }
    this.TeacherID = this.TeacherKey;
    console.log(this.courseIndex, 'console.log(this.courseIndex) this.BigCoursewarePackageInfo', this.BigCoursewarePackageInfo, 'this.dataInfo', this.dataInfo, this.TeacherKey);
    if (this.$zxClient && this.$zxClient.IsClient) {
      this.deviceCode = this.$zxClient.GetMCode();
    } else {
      // this.tipContont = '非桌面端';
      this.deviceCode = '';
    }
    this.getCoursewarePackageDetails(this.BigCoursewarePackageInfo.OLAPKey || this.CoursewarePackageKey, this.deviceCode, this.TeacherID);
    this.vRoleKey();
    console.log(this.CoursewarePackageKey, 'this.CoursewarePackageKey', this.role);
    this.busClassLocationEvent = res => {
      if (this.chooseType == 1) {
        this.$router.push({
          name: 'coursewareTeachingView',
          query: {
            type: this.chooseType,
            scheduleCourseID: this.chooseScheduleCourseKey,
            CoursewareInfoType: this.chooseCoursewareInfoType,
            Accuracy: this.maxCoursewarePackage.Accuracy,
            IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
          }
        });
      } else if (this.chooseType == 10) {
        this.openClassData.Longitude = res.Longitude;
        this.openClassData.Latitude = res.Latitude;
        console.log('this.openClassData', this.openClassData);
        CWSTeacherGiveLectureSetUpScheduleCourse(
          this.openClassData,
          this.IsContinueKey,
          this.IsAuthKey
        )
          .then(result => {
            console.log(result, '上课返回数据');
            // this.$emit("afterSuccess");
            // this.$emit("closeDialog");
            if (result.data.ScheduleCourseKey) {
              setTimeout(() => {
                this.$router.push({
                  name: 'coursewareTeachingView',
                  query: {
                    type: 1,
                    scheduleCourseID: result.data.ScheduleCourseKey,
                    Accuracy: this.maxCoursewarePackage.Accuracy,
                    IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                  }
                });
              }, 500);
            } else {
              console.log(123456456);
              layer.alert(error.msg);
            }
          })
          .catch(error => {
            console.log(123456456);
            if (error.code == 80001) {
              this.isAuthCourseware();
            } else if (error.code == 80002) {
              layer.confirm(error.msg, {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      this.IsContinueKey = 0;
                      layer.close();
                      if (callBack) {
                        callBack();
                      }
                    }
                  },
                  {
                    name: '确认',
                    callBack: () => {
                      this.IsContinueKey = 1;
                      CWSTeacherGiveLectureSetUpScheduleCourse(
                        this.openClassData,
                        this.IsContinueKey,
                        this.IsAuthKey
                      )
                        .then(res => {
                          if (res.data.ScheduleCourseKey) {
                            setTimeout(() => {
                              this.$router.push({
                                name: 'coursewareTeachingView',
                                query: {
                                  type: 1,
                                  scheduleCourseID: res.data.ScheduleCourseKey,
                                  Accuracy: this.maxCoursewarePackage.Accuracy,
                                  IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                                }
                              });
                            }, 500);
                          } else {
                            layer.alert(error.msg);
                          }
                        })
                        .catch(error => {
                          if (error.code == 80001) {
                            this.isAuthCourseware();
                          } else {
                            layer.alert(error.msg);
                          }
                        });
                      layer.close();
                    }
                  }
                ]
              });
            } else {
              layer.alert(error.msg);
            }
          });
      } else if (this.chooseType == 2) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
            }
          });
        }, 500);
      } else if (this.chooseType == 4) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
            }
          });
        }, 500);
      }
      setTimeout(() => {
        this.getCoursewarePackageInfo(4);
      }, 500);
    };
  },
  mounted () {
  },
  methods: {
    isAuthCourseware () {
      layer.confirm(
        '您还没有该课包的授权，如果继续，将会自动将该课包授权给您，是否继续?',
        {
          btn: [
            {
              name: '取消',
              callBack: function () {
                this.IsAuthKey = 0;
                layer.close();
                if (callBack) {
                  callBack();
                }
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.IsAuthKey = 1; // 是否自动授权
                CWSTeacherGiveLectureSetUpScheduleCourse(
                  this.openClassData,
                  this.IsContinueKey,
                  this.IsAuthKey
                ).then(result => {
                  if (result.data.ScheduleCourseKey) {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 1,
                        coursewareId: result.data.ScheduleCourseKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                  } else {
                    layer.alert(error.msg);
                  }
                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    // 获取子课件包详情
    getCoursewarePackageDetails (OLAPKey, deviceCode, TeacherID) {
      console.log(this.TeacherID, '老师ID');
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      if (this.role) {
        CWSGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            console.log('geoInfo', geoInfo);
            console.log('result.data', result.data);
            this.$store.commit('setGeoInfo', geoInfo);

            console.log('校长-获取子课件包详情index页面', result.data);
          });
      } else {
        CWSTeacherWorkbenchGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            this.$store.commit('setGeoInfo', geoInfo);
            console.log('老师-获取子课件包详情index页面', result.data);
          });
      }
      console.log(6545665165);
      this.$emit('afterSuccess');
      // }
    },
    // 进入备课模式
    prepare (item) {
      console.log(
        '去备课',
        item,
        this.BigCoursewarePackageInfo,
        this.Source
      );
      if (!this.$zxClient.IsClient) {
        layer.alert('请用桌面端打开程序');
      } else {
        if (this.maxCoursewarePackage.DeviceIsAuth != 1) {
          layer.alert('设备未授权');
        } else {
          if (this.$store.getters.SaaSClientEditionKey == 6) {
            if (this.role) {
              if (this.Source == 1 || this.Source == 2) {
                // 授权
                let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
                if (
                  this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                  !this.$store.getters.geoInfo.Longitude
                ) {
                  this.openAuthDialog(item.OLAPKey, viewType, 2, 12);
                } else {
                  let newdataInfo = {
                    ScheduleCourseKey: item.ScheduleCourseKey || '',
                    courserId: this.BigCoursewarePackageInfo.OLAPKey,
                    client_id: this.$store.getters.clientId,
                    SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                    DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                    introType: 12,
                    DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                  };
                  this.$utils.verifySystemLocation(
                    newdataInfo,
                    success => {
                      this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 2,
                          coursewareId: item.OLAPKey,
                          coursewareInfoType: viewType,
                          Accuracy: this.maxCoursewarePackage.Accuracy,
                          IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                        }
                      });
                      setTimeout(() => {
                        this.getCoursewarePackageInfo(4);
                      }, 500);
                    },
                    error => {
                      this.openAuthDialog(item.OLAPKey, viewType, 2, 12);
                    }
                  );

                  console.log('12ssss34544444');
                }
              }
            } else {
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(item.OLAPKey, '', 4, 12);
              } else {
                console.log('12ssss345611112222');
                let newdataInfo = {
                  ScheduleCourseKey: item.ScheduleCourseKey || '',
                  courserId: this.BigCoursewarePackageInfo.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 12,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 4,
                        coursewareId: item.OLAPKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                    setTimeout(() => {
                      this.getCoursewarePackageInfo(4);
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(item.OLAPKey, '', 4, 12);
                  }
                );
              }
            }
          } else {
            if (this.Source == 1 || this.Source == 2) {
              let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
              // 授权
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(item.OLAPKey, viewType, 2, 22);
              } else {
                console.log('12ssss3456');
                let newdataInfo = {
                  ScheduleCourseKey: item.ScheduleCourseKey || '',
                  courserId: this.dataInfo.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 22,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 2,
                        coursewareId: item.OLAPKey,
                        coursewareInfoType: viewType,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                    setTimeout(() => {
                      this.getCoursewarePackageInfo(4);
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(item.OLAPKey, viewType, 2, 22);
                  }
                );
              }
            }
          }
        }
      }
    },
    openAuthDialog (OLAPKey, type, model, introType) {
      console.log(
        'openAuthDialog maxCoursewarePackage',
        this.maxCoursewarePackage
      );
      if (this.maxCoursewarePackage.HQAuthKey != 0) {
        let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
        layer.alert(text);
        return false;
      }
      this.chooseScheduleCourseKey = '';
      this.coursewareIdforOpen = OLAPKey;
      this.chooseType = model;
      this.chooseCoursewareInfoType = type;
      let skey = '';
      if (model == 1) {
        this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }
      if (this.maxCoursewarePackage.PCIsLocationLockKey == 1) {
        console.log('courseAuthQrcode');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '安全验证',
          moduleName: 'courseAuthQrcode',
          data: {
            dataInfo: {
              ScheduleCourseKey: skey,
              courserId: this.BigCoursewarePackageInfo.OLAPKey,
              client_id: this.$store.getters.clientId,
              SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
              DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              introType: introType,
              DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
            }
          },
          callBack: {
            afterSuccess: data => {
              this.busClassLocationEvent(data);
            }
          }
        });
      }
    },
    getUrlBase64 (url, ShowOrder) {
      return new Promise(resolve => {
        let Imgbox = document.getElementById('Imgbox_' + ShowOrder);
        console.log(Imgbox, 'Imgbox_');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();
        img.crossOrigin = 'Anonymous'; // 允许跨域
        img.src = url;
        img.onload = function () {
          canvas.width = Imgbox.naturalWidth;
          canvas.height = Imgbox.naturalHeight;
          ctx.drawImage(img, 0, 0, Imgbox.naturalWidth, Imgbox.naturalHeight);
          let dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    getDownUrlBase64 (url, ShowOrder) {
      return new Promise(resolve => {
        let Imgbox = document.getElementById('Imgbox_' + ShowOrder);
        console.log(Imgbox, 'Imgbox_');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();
        img.crossOrigin = 'Anonymous'; // 允许跨域
        img.src = url;
        img.onload = function () {
          canvas.width = 640;
          canvas.height = 360;
          ctx.drawImage(img, 0, 0, 640, 360);
          let dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    downloadImg (data) {
      console.log(data);
      if (data.CoverIsDownload == 1) {
        this.getDownUrlBase64(this.$store.getters.CDNURL + data.CoverUrl).then(base64 => {
          let link = document.createElement('a');
          link.href = base64;
          link.download = data.MainDemoName + '.png';
          link.click();
        });
      } else {
        layer.alert('该图片不能下载');
      }
    },
    // 下载图片
    download (img, MainDemoName, ShowOrder) {
      console.log('img', img, MainDemoName, ShowOrder);
      this.getUrlBase64(img, ShowOrder).then(base64 => {
        let link = document.createElement('a');
        link.href = base64;
        link.download = MainDemoName + '.png';
        link.click();
      });
    },
    introductionownload (img, MainDemoName, ShowOrder) {
      console.log(img, MainDemoName, ShowOrder, 'img, MainDemoName, ShowOrder');
      let link = document.createElement('a');
      // 这里是将url转成blob地址，
      fetch(img).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob);
        console.log(link.href);
        link.download = '';
        link.download = MainDemoName;
        document.body.appendChild(link);
        link.click();
        // layer.alert("下载成功")
      });
    },
    openPPTbtn (img, MainDemoName, ShowOrder) {
      console.log('img', img, MainDemoName, ShowOrder);
      let link = document.createElement('a');
      link.href = img;
      link.download = MainDemoName + '.png';
      link.click();
    },
    toggleChildrenOne (item) {
      console.log(item, 'item');
      item.open = !item.open;
      this.getGetCoursewarePackageDownloadCoverUrlList(2, item.id, '', item.OLAPKey || this.CoursewarePackageKey);
    },
    toggleChildrenTwo (item, child) {
      console.log(item, 'item child', child);
      child.open = !child.open;
      this.getGetCoursewarePackageDownloadCoverUrlList(0, item.id, child.id, child.OLAPKey);
    },
    toggleChildrenThree (son) {
      console.log(son, 'son');
    },
    getGetCoursewarePackageDownloadCoverUrlList (index, ItemId, childId, OLAPKey) {
      if (index == 1) {
        OLAPKey = this.BigCoursewarePackageInfo.OLAPKey || this.CoursewarePackageKey;
      }
      console.log(index, 'index down');
      // 封面下载
      getGetCoursewarePackageDownloadCoverUrlList(OLAPKey, index, this.TeacherKey).then(result => {
        let id_index = 0;
        result.data.forEach((o) => {
          o.open = false;
          o.id = id_index++;
          o.children = [];
        });
        console.log(result.data, ' getGetCoursewarePackageDownloadCoverUrlList');
        if (index == 1) {
          this.childNodes = result.data;
        }
        if (index == 2) {
          this.childNodes[ItemId].children = result.data;
        }

        if (index == 0) {
          this.childNodes[ItemId].children[childId].children = result.data;
        }
        console.log(this.childCWDataList, ' this.childCWDataList  this.childNodes', this.childNodes);
      }, error => {
        layer.alert(error.msg);
      });
    },
    getCoursewarePackageInfo (type) {
      let CoursewarePackageKey = this.BigCoursewarePackageInfo.OLAPKey ? this.BigCoursewarePackageInfo.OLAPKey : this.CoursewarePackageKey;
      console.log(CoursewarePackageKey, 'getCoursewarePackageInfo CoursewarePackageKey')
      this.courseList = [];
      // 1-课包介绍2-附件下载
      if (this.courseIndex == 2) {
        getGetCoursewarePackageAttach(CoursewarePackageKey, 1).then(result => {
          console.log(result.data, '1-课包介绍2-附件下载 getGetCoursewarePackageAttach');
          if (result.data.length > 0) {
            result.data.forEach((o) => {
              o.img = o.AttachUrl;
            });
            this.courseList = result.data;
          } else {
            this.courseList = [];
          }
          console.log(this.courseList, 'this.courseList 1');
        }, error => {
          layer.alert(error.msg);
        });

      } else if (this.courseIndex == 3) {
        getGetCoursewarePackageAttach(CoursewarePackageKey, 2).then(result => {
          console.log(result.data, ' getGetCoursewarePackageAttach');
          if (result.data.length > 0) {
            this.courseList = result.data;
          } else {
            this.courseList = [];
          }
          console.log(this.courseList, 'this.courseList 2');
        }, error => {
          layer.alert(error.msg);
        });
      } else if (this.courseIndex == 4 && type == 4) {
        getCoursewarePackageParentList(CoursewarePackageKey).then(result => {
          console.log(result.data, ' getCoursewarePackageParentList');
          if (result.data.length > 0) {
            result.data.forEach((o) => {
              o.img = this.$store.getters.CDNURL + o.CoverUrl;
            });
            this.courseListA = result.data;
          } else {
            this.courseListA = [];
          }
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    vRoleKey () {
      if (this.$store.getters.SaaSClientEditionKey == 6) {
        return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
      } else if (this.$store.getters.SaaSClientEditionKey <= 3) {
        return true;
      }
    },
    // 父类传来的方法
    searchCourseinfo (type) {
      this.courseList = [];
      console.log(type, 'courseBarInfo type');
      if (type == 5) {
        this.getGetCoursewarePackageDownloadCoverUrlList(1);
      } else {
        this.getCoursewarePackageInfo(type);
      }
    }
  }
};
</script>

<style scoped>
.course_content_left {
  width: 435px;
  height: 455px;
  overflow-y: auto;
}
.course_introduction_div {
  display: flex;
  flex-wrap: wrap;
}
.bgimg-box {
  width: 190px;
  margin-left: 20px;
  margin-bottom: 40px;
  position: relative;
  height: 120px;
}
.introduction-bgimg-div {
  width: 190px;
  margin-left: 20px;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
  height: 125px;
  /* line-height: 51px; */
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  background: #f7f8fc;
}
.introduction-bgimg-div .c_i_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
  position: relative;
  display: block;
  margin-top: 67px;
  cursor: pointer;
}
.introduction-bgimg-div .link_wrapper {
  bottom: -45px;
}
.swimg {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.c_i_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
  position: relative;
  display: block;
  margin-top: 10px;
}
.link_wrapper {
  width: 40px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: -35px;
  right: 0;
  background: #f1f3f9;
  color: #2d5ff8;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
}
.cover_download_div >>> .el-tree > .el-tree-node > .el-tree-node__content {
  border: none;
  line-height: 40px;
  margin-bottom: 0px;
}

.cover_download_div >>> .el-tree .el-tree-node__children .el-tree-node {
  margin-bottom: 10px;
}
.cover_download_div
  >>> .el-tree
  > .el-tree-node
  > .el-tree-node__content
  > .el-tree-node__expand-icon {
  margin-right: 75px;
}
.down_img {
  position: absolute;
  right: 0;
  width: 40px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  z-index: 4;
  top: 4px;
  color: #3498db;
}
.can_down {
  background: #f1f3f9;
  color: #2d5ff8;
  cursor: pointer;
}
.no_can_down {
  background: #f1f3f9;
  color: #999;
  cursor: no-drop;
}
.course_content_ppt {
  display: flex;
  align-items: center;
  position: relative;
  width: 435px;
  height: 90px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: #f7f8fc;
  margin-top: 10px;
  margin-bottom: 20px;
}

.ppt_img {
  width: 45px;
  height: 54px;
  margin-left: 20px;
  margin-right: 20px;
}

.course_name {
  line-height: 29px;
  font-family: Arial;
  color: #333;
}

.c_MB {
  color: #999 !important;
}

.open_ppt {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #f1f3f9;
  width: 120rpx;
  color: #2d5ff8;
  font-family: Alibaba PuHuiTi;
  text-align: center;
  position: absolute;
  right: 16px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  width: 65px;
}
.introduction_img_box {
  width: 34px;
  margin-top: 35px;
}

.tree-menu-item {
  padding-left: 25px;
  position: relative;
  cursor: pointer;
  width: 425px;
  height: 40px;
  margin-top: 10px;
  line-height: 40px;
}
.icon {
  display: inline-block;
  display: inline-block;
  width: 13px;
  height: 10px;
  margin-right: 10px;
  background-image: url(../../../../../public/image/course_drop_two.png);
  background-size: 13px;
  background-repeat: no-repeat;
}

.icon-open {
  transform: rotate(180deg);
}
</style>
