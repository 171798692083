<template>
  <!-- 跟进表单  登记诺访 登记约课 报名 放弃-->
  <div class="follow_up_list">
    <div style="margin: 0 20px;">
      <input-date v-model="scheduleObj.StartTime"
                  :formTitle="vScheduleTitle + '日期'"
                  :readonly="false"
                  :elementId="'startPeriod'"
                  :required="false"
                  :formPlaceholder="'可选'"></input-date>
    </div>
    <save-cancel-btn-group :cancelBtnText="'取消'"
                           :isSingleSubmit="true"
                           :fixedBtn="true"
                           @confirmClick="submit"
                           @cancelClick="cancel"></save-cancel-btn-group>
  </div>
</template>
<script>
import { AddPromiseVisit, AddTrialLesson, AddSignUp, UpdatePromiseVisitStatus, UpdateTrialLessonStatus } from '../../API/workbench.js';
export default {
  data () {
    return {
      scheduleObj: {
        StudentThreadKey: '',
        StartTime: ''
      }
    };
  },
  components: {
  },
  computed: {
    vScheduleTitle () {
      return this.scheduleTitle;
    }
  },
  props: {
    dataInfo: Object,
    scheduleTitle: String,
    followTitle: String
  },
  created () {
    this.scheduleObj.StudentThreadKey = this.dataInfo.OLAPKey;
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    // 提交
    submit (callback) {
      console.log('submit', this.scheduleObj);
      let fn = '';
      let flag = true;
      let obj = {};
      if (!this.scheduleObj.StartTime) {
        layer.alert('请选择日期');
        flag = false;
      }
      if (!flag) {
        if (callback) {
          callback();
        }
        return false;
      }

      if (this.scheduleTitle == '登记诺访') {
        obj = {
          StudentThreadKey: this.dataInfo.OLAPKey,
          PromiseVisitTime: this.scheduleObj.StartTime
        };
        fn = AddPromiseVisit;
      } else if (this.scheduleTitle == '登记约课') {
        obj = {
          StudentThreadKey: this.dataInfo.OLAPKey,
          TrialLessonTime: this.scheduleObj.StartTime
        };
        fn = AddTrialLesson;
      } else if (this.scheduleTitle == '报名') {
        obj = {
          StudentThreadKey: this.dataInfo.OLAPKey,
          SignUpTime: this.scheduleObj.StartTime
        };
        fn = AddSignUp;
      } else if (this.scheduleTitle == '改约' && this.followTitle == '诺访记录') {
        obj = {
          StudentThreadKey: this.dataInfo.StudentThreadKey, // 线索ID
          PromiseVisitKey: this.dataInfo.PromiseVisitKey, // 诺访ID
          PromiseVisitTime: this.scheduleObj.StartTime, // 改约诺访日期
          StatusKey: 3, // 1-待处理;2-未到;3-改约;4-已到
          StatusKeyValue: '改约' // 状态
        };
        fn = UpdatePromiseVisitStatus;
      } else if (this.scheduleTitle == '改约' && this.followTitle == '试课记录') {
        obj = {
          StudentThreadKey: this.dataInfo.StudentThreadKey, // 线索ID
          TrialLessonKey: this.dataInfo.TrialLessonKey, // 试课记录ID
          TrialLessonDisposeKey: this.dataInfo.TrialLessonDisposeKey, // 试课记录处理ID
          TrialLessonTime: this.scheduleObj.StartTime, // 改约日期
          StatusKey: 3, // 2-未到;3-改约;4-已试课
          StatusKeyValue: '改约' // 状态
        };
        fn = UpdateTrialLessonStatus;
      }
      fn(obj).then(
        (result) => {
          layer.alert('添加成功');
          this.$emit('afterSuccess');
        },
        (error) => {
          layer.alert(error.msg);
        }
      )
        .finally(() => {
          if (callback) {
            callback();
          }
        });
    },
    cancel () {
      this.$emit('afterSuccess');
    }
  }
};
</script>

<style scoped>
</style>

