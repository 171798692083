<template>
  <!-- 考勤详情对话框右侧的中间模块 -->
  <div class="course_essay_label new_course_essay_label"
       :class="vPassAble ? 'type_green' : 'type_red'">
    <div class="label_info">
      <div class="label_left"
           @click.stop="showAddCW">
        <div class="label_img pr">
          <img :src="vDefaultImg"
               alt="" />
          <div class="label_img_text">
            {{ teachingPlanInfo.ParentCoursewarePackageKeyValue }}
          </div>
        </div>
        <div class="label_content pr">
          <div class="label_name">
            {{ teachingPlanInfo.CoursewarePackageKeyValue }}
          </div>
          <div :class="[
              teachingPlanInfo.AuthorizeStatusKey != 0 &&
              teachingPlanInfo.CoursewareAuthorizeStatusKey != 0
                ? 'label_text'
                : 'font_gray',
              showAll ? 'text_down' : '',
              'label_text_ellipsis'
            ]"
               @click.stop="changeShowAll">
            {{ teachingPlanInfo.MainDemoName }}
          </div>
        </div>
      </div>
      <!-- <div class="label_right"
           v-if="vIsTeacherInTimeScope"
           @click.stop="openAuthDialog">
        <div class="label_right_top">上课</div>
        <div class="label_right_bottom">{{ vAuthorizeTitle }}</div>
      </div> -->
      <div class="label_right pr"
           v-if="teachingPlanInfo.IsPCAuthKey == 1"
           @click.stop="openAuthDialog">
        <div class="label_right_top"
             :class="vAuthorizeClass"></div>
        <div class="label_right_bottom"
             :class="vAuthorizeClass">
          {{ vAuthorizeTitle }}
        </div>
        <!-- <div class="label_right_green" v-if="vAuthorizeClass=='type_green'"></div> -->
        <!-- <div
              class="course_student_attendance_option_list
                course_student_attendance_operation
                module_content_menuList
                active
              "
              :style="{ left: 0 + 'px', top: 70 + 'px' }"
              v-show="isShowDialog"
            >
              <div class="top_bg"></div>
              <drop-down-box
                :optionJsonList="menuTypeList"
                @clickOptionForAlertDialog="clickOption"
              ></drop-down-box>
            </div> -->
      </div>
    </div>
    <div class="label_bottom"
         v-if="showAll">
      <div class="label_nav_ul">
        <div class="label_nav_list"
             :class="searchType == 1 ? 'opt' : ''"
             @click.stop="changeSearchType(1)"
             v-if="teachingPlanInfo.HasStep == 1">
          <span class="text_box">教学步骤</span>
        </div>
        <div class="label_nav_list"
             :class="searchType == 2 ? 'opt' : ''"
             @click.stop="changeSearchType(2)"
             v-if="teachingPlanInfo.HasAttach == 1">
          <span class="text_box">课件素材</span>
        </div>
        <div class="label_nav_list"
             :class="searchType == 3 ? 'opt' : ''"
             @click.stop="changeSearchType(3)"
             v-if="teachingPlanInfo.HasEvaluating == 1">
          <span class="text_box">课评话术</span>
        </div>
        <div class="label_nav_list"
             :class="searchType == 4 ? 'opt' : ''"
             @click.stop="changeSearchType(4)"
             v-if="teachingPlanInfo.HasHomeWork == 1">
          <span class="text_box">课后作业</span>
        </div>
      </div>
      <div class="label_bottom_content"
           :class="searchType == 4 ? 'no_padding' : ''">
        <!-- 教学步骤 -->
        <div v-if="searchType == 1">
          <pre class="label_bottom_content_text"
               v-html="$utils.translationSchoolText(dataInfo.TeachingProcedure)"></pre>
          <courseware-attach :dataList="vCoursewareTeachingStep"
                             @clickEvent="clickEvent"></courseware-attach>
          <other-attach v-if="votherAttachList.length > 0"
                        :dataList="votherAttachList"
                        @clickEvent="clickEvent"></other-attach>
        </div>
        <!-- 课件素材 -->
        <courseware-attach v-if="searchType == 2"
                           :dataList="vCoursewareAttach"></courseware-attach>
        <!-- 课评话术 -->
        <evaluation v-if="searchType == 3"
                    :dataList="dataInfo.Evaluation"
                    @releaseComment="releaseComment">
        </evaluation>
        <div class="index_JXT_ul pr"
             v-if="searchType == 4">
          <!-- 作业内容 -->
          <new-home-task-browse-item :dataInfo="dataInfo.HomeWork"
                                     @edit="doEditHomeWorkAfter"></new-home-task-browse-item>
          <div class="course_list_btn"
               @click.stop="releaseHomeTask">
            布置作业
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="'申请设备授权'"
                   :visible.sync="showAuth"
                   :before-close="closeAuthDialog">
      <apply-auth :dataInfo="authDataInfo"
                  @closeDialog="closeAuthDialog"
                  @afterSuccess="updateData"></apply-auth>
    </custom-dialog>

    <!-- 全屏播放 -->
    <allview-custom-dialog :visible.sync="isShowAllViewDialog">
      <all-view v-if="true"
                :dataInfo="curSelectionCourseware"
                :dataInfoLength="vCoursewareTeachingStep.length"
                :watermarkInfo="watermarkInfo"
                :viewType="3"
                @hiddenAllView="hiddenAllView"></all-view>
    </allview-custom-dialog>
  </div>
</template>
<script>
import {
  GetCoursewareDetail,
  WEBVVerifyAuthorizedDevice,
  GetCoursewareDetailForPrepareLessons,
  GetSaaSClientSet,
  GetSecurityWatermark
} from "../../../API/workbench";
import { md5 } from "../../../API/md5";
import otherAttach from "./teaching-components/other-attch";
import applyAuth from "../../courseware-work-bench/components/report/auth-components/apply-auth";
import coursewareAttach from "./teaching-components/courseware-attach";
import evaluation from "./teaching-components/evaluation";
import newHomeTaskBrowseItem from "../../home-school-communication/home-task-page/new-home-task-browse-item";
import allView from "../../courseware-teaching/all-view";
import dropDownBox from "../../common/drop-down-box";
import allviewCustomDialog from "./teaching-components/allview-custom-dialog";
export default {
  name: "newTeachingPlanInfo",
  data () {
    return {
      showAll: false,
      isAuthorize: false, // 是否授权
      showAuth: false, // 显示授权弹出层
      searchType: 1,
      tipContont: "",
      dataInfo: {
        Title: "课件ID58的标题",
        TeachingProcedure: "这是教学步骤。01 干啥XXXX；02 干啥XXXXX",
        CoursewareAttach: [
          {
            MainDemoName: "pptx01",
            ShowOrder: "3",
            AttachUrl: "/Upload/Resource/1005/File/202108191823171023.ppt",
            AttachTypeKey: "6",
            DurationNum: "0",
            SizeNum: "0",
            CoverUrl: "",
            ID: "222",
            ParentKey: "98"
          },
          {
            MainDemoName: "pptx01",
            ShowOrder: "3",
            AttachUrl: "/Upload/Resource/1005/File/202108191823171023.ppt",
            AttachTypeKey: "6",
            DurationNum: "0",
            SizeNum: "0",
            CoverUrl: "",
            ID: "223",
            ParentKey: "98"
          },
          {
            MainDemoName: "pptx01",
            ShowOrder: "3",
            AttachUrl: "/Upload/Resource/1005/File/202108191823171023.ppt",
            AttachTypeKey: "6",
            DurationNum: "0",
            SizeNum: "0",
            CoverUrl: "",
            ID: "224",
            ParentKey: "98"
          }
        ],
        Evaluation: [
          {
            Content: "话术01",
            OLAPKey: "49"
          },
          {
            Content: "话术02",
            OLAPKey: "50"
          }
        ],
        HomeWork: {
          id: 2654,
          Title: "这是课件ID58的课后作业00",
          Content: "这是课件ID58的课后作业内容，测试内容",
          AttachList: [
            {
              AttachTypeKey: 1,
              AttachUrl: "/Upload/Resource/1005/Image/202108191511321243.jpg",
              VideoCoverUrl: null,
              AudioSize: "",
              IsDefault: 0
            },
            {
              AttachTypeKey: 2,
              AttachUrl: "/Upload/Resource/1005/Video/202108191511192659.mp4",
              VideoCoverUrl:
                "/Upload/Resource/1005/Image/34fa5269a186df0ba248a949b3e68e4e.jpg",
              AudioSize: "6",
              IsDefault: 0
            }
          ]
        }
      },
      mCode: "",
      authDataInfo: null,
      curSelectionCourseware: {}, // 当前选中的课件
      isShowAllViewDialog: false, // 是否显示全屏
      watermarkInfo: [
        {
          MainDemoName: "是否开启老师水印",
          OLAPKey: "",
          SetContent: "",
          SetKey: "0",
          SetKeyValue: "",
          SetTypeKey: "",
          SetTypeKeyValue: ""
        },
        {
          MainDemoName: "是否开启门店水印",
          OLAPKey: "",
          SetContent: "",
          SetKey: "0",
          SetKeyValue: "",
          SetTypeKey: "",
          SetTypeKeyValue: ""
        },
        {
          MainDemoName: "是否开启品牌水印",
          OLAPKey: "",
          SetContent: "",
          SetKey: "0",
          SetKeyValue: "",
          SetTypeKey: "",
          SetTypeKeyValue: ""
        }
      ],
      coursewareInfoType: "",
      menuTypeList: [
        {
          name: "进入课件",
          type: "1",
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: "更换课件",
          type: "2",
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      isShowDialog: false
    };
  },
  components: {
    evaluation,
    coursewareAttach,
    newHomeTaskBrowseItem,
    applyAuth,
    allView,
    allviewCustomDialog,
    otherAttach,
    dropDownBox
  },
  watch: {
    "courseInfo.TeachingPlan": {
      handler (c, o) {
        // console.log(c,'cccccc',o)
        // if (c.OLAPKey != o.OLAPKey) {
        this.showAll = false;
        this.initsearchType();
        if (this.$zxClient.IsClient) {
          this.tipContont = "";
          console.log(
            "this.$zxClient.IsClient",
            this.$zxClient,
            this.$zxClient.GetMCode()
          );
          this.mCode = this.$zxClient.GetMCode();
        } else {
          this.tipContont = "非桌面端";
        }
        this.verifyAuthorize();
        // }
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    teachingPlanInfo: {
      type: Object,
      default: () => {
        return {
          MainDemoName: "",
          HasEvaluating: "1",
          HasAttach: "1",
          HasHomeWork: "0",
          HasStep: "1"
        };
      }
    },
    courseInfo: Object,
    isAlter: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  created () {
    console.log(this.vMenDianInfo, "mCode11111", this.$zxClient, this.mCode);
    this.getWatermarkInfo();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector("body").addEventListener(
      "click",
      e => {
        this.isShowDialog = false;
      },
      true
    );
  },
  computed: {
    vDefaultImg () {
      let imgUrl = this.teachingPlanInfo.CoverUrl || "";
      if (
        imgUrl &&
        imgUrl.indexOf("deflut_studentPhoto") == -1 &&
        imgUrl.indexOf("data:image/png;base64") == -1
      ) {
        if (imgUrl.indexOf("http") == -1) {
          return this.$store.getters.CDNURL + imgUrl;
        } else {
          return imgUrl;
        }
      } else {
        return require("../../../../public/image/data-null.png");
      }
    },
    vShowListTitle () {
      let text = "";
      text += this.teachingPlanInfo.HasStep == 1 ? "步骤" : "";
      text += this.teachingPlanInfo.HasAttach == 1 ? " · 素材" : "";
      text += this.teachingPlanInfo.HasEvaluating == 1 ? " · 课评" : "";
      text += this.teachingPlanInfo.HasHomeWork == 1 ? " · 作业" : "";
      if (text.indexOf(" · ") == 0) {
        text = text.replace(" · ", "");
      }
      return text;
    },
    vAuthorizeTitle () {
      if (this.tipContont) {
        return this.tipContont;
      } else if (!this.isAuthorize) {
        // || this.teachingPlanInfo.AuthorizeStatusKey == 2
        return "设备授权";
      } else if (
        this.teachingPlanInfo.AuthorizeStatusKey == 0 ||
        this.teachingPlanInfo.CoursewareAuthorizeStatusKey == 0
      ) {
        return "课件授权";
      } else if (this.teachingPlanInfo.IsUseUp == 1) {
        return "课件授权";
      } else if (!this.vEarlySignInNameScope) {
        return "上课时间";
      } else {
        // || this.teachingPlanInfo.AuthorizeStatusKey == 2
        return "进入课件";
      }
    },
    vAuthorizeClass () {
      if (this.vAuthorizeTitle == "设备授权") {
        return "type_gray";
      } else if (this.teachingPlanInfo.CoursewareAuthorizeStatusKey == 0) {
        return "type_gray";
      } else if (this.teachingPlanInfo.AuthorizeStatusKey == 0) {
        return "type_gray";
      } else if (this.vAuthorizeTitle == "非桌面端") {
        return "type_gray";
      } else if (this.vPassAble) {
        return "type_green";
      } else {
        return "type_gray";
      }
    },
    vMenDianInfo () {
      // TimeScopeCount
      return this.$store.getters.SaaSClientInfo;
    },
    // 是否在事件范围内
    vIsTeacherInTimeScope () {
      // let TimeScopeCount = Number(this.teachingPlanInfo.InTimeScope) || 0;
      // let classTime = this.courseInfo.ClassTime;
      // console.log(classTime, TimeScopeCount);
      // return this.setInTimeScope(TimeScopeCount, classTime);
      return this.teachingPlanInfo.InTimeScope == 1;
    },
    // 最早签到时间.
    vEarlySignInNameScope () {
      let startClassTime =
        this.courseInfo.ClassTime + " " + this.courseInfo.BeginClassTime;
      let endClassTime =
        this.courseInfo.ClassTime + " " + this.courseInfo.EndClassTime;
      let EarlySignInName =
        Number(this.$utils.getSaaSClientSetInfo(31).SetContent) || 60;
      // EarlySignInName = EarlySignInName > 60 ? 60 : EarlySignInName;
      console.log("startClassTime", startClassTime, endClassTime);
      EarlySignInName = 30;

      if (
        Date.new(startClassTime).getTime() - EarlySignInName * 60 * 1000 <
        new Date().getTime() &&
        Date.new(endClassTime).getTime() + 30 * 60 * 1000 > new Date().getTime()
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 是否在事件范围内
    vIsInTimeScope () {
      let TimeScopeCount = Number(this.vMenDianInfo.TimeScopeCount) || 0;
      let classTime = this.courseInfo.ClassTime;
      console.log(classTime, TimeScopeCount);

      return this.setInTimeScope(TimeScopeCount, classTime);
    },
    vPassAble () {
      console.log("上课时间范围小时内", this.vEarlySignInNameScope);
      console.log("上课时间范围天数", this.vIsTeacherInTimeScope);
      // 课件暂停
      if (
        this.teachingPlanInfo.AuthorizeStatusKey == 0 ||
        this.teachingPlanInfo.CoursewareAuthorizeStatusKey == 0
      ) {
        return false;
      } else if (this.teachingPlanInfo.CoursewareAuthorizeStatusKey == 1) {
        // 课件授权状态1正常;0暂停授权'
        return true;
      } else if (this.teachingPlanInfo.ClearingFormKey == 1) {
        // 课件是按课次
        if (!this.vEarlySignInNameScope) {
          //
          // 不在上课允许天数或者不在课前课后时间查看
          return false;
        } else if (
          // this.teachingPlanInfo.AuthorizeStatusKey == 1 &&
          this.isAuthorize
        ) {
          // 该课件不是按课次 及是自研课件
          return true;
        } else {
          return false;
        }
      } else if (this.isAuthorize && this.vEarlySignInNameScope) {
        //  this.teachingPlanInfo.AuthorizeStatusKey == 1 &&
        // 该课件不是按课次 及是自研课件和其他条件符合
        return true;
      } else if (
        this.teachingPlanInfo.AuthorizeStatusKey == 2 &&
        this.vEarlySignInNameScope
      ) {
        // 该课件不是按课次 及是自研课件和其他条件符合
        return true;
      } else if (this.teachingPlanInfo.IsUseUp == 1) {
        // 该课件已用完
        return false;
      } else {
        return false;
      }
    },
    vCoursewareAttach () {
      return (this.dataInfo.CoursewareAttach || []).filter(obj => {
        return obj.TypeKey == 1;
      });
    },
    vCoursewareTeachingStep () {
      return (this.dataInfo.CoursewareAttach || []).filter(obj => {
        return obj.TypeKey == 2;
      });
    },
    votherAttachList () {
      return (this.dataInfo.CoursewareAttach || []).filter(obj => {
        return obj.TypeKey == 3;
      });
    },
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) {
        // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    setInTimeScope (TimeScopeCount, classTime) {
      if (TimeScopeCount >= 0) {
        if (Date.new(classTime) > Date.new(Date.new().toLocaleDateString())) {
          if (
            Date.new(classTime).getTime() <=
            Date.new(Date.new().toLocaleDateString()).getTime() +
            86400000 * TimeScopeCount
          ) {
            return true;
          } else {
            return false;
          }
        } else if (
          Date.new(classTime) < Date.new(Date.new().toLocaleDateString())
        ) {
          console.log(
            "Date.new(classTime).getTime()",
            Date.new(classTime),
            Date.new(Date.new().toLocaleDateString())
          );
          if (
            Date.new(classTime).getTime() >=
            Date.new(Date.new().toLocaleDateString()).getTime() -
            86400000 * TimeScopeCount
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    initsearchType () {
      if (this.teachingPlanInfo.HasStep == 1) {
        this.searchType = 1;
      } else if (this.teachingPlanInfo.HasAttach == 1) {
        this.searchType = 2;
      } else if (this.teachingPlanInfo.HasEvaluating == 1) {
        this.searchType = 3;
      } else if (this.teachingPlanInfo.HasHomeWork == 1) {
        this.searchType = 4;
      }
    },
    releaseHomeTask () {
      console.log("releaseHomeTask");
      if (this.courseInfo.IsClassStopKey == 1) {
        layer.alert("这节课已停课");
      } else if (this.courseInfo.ArrangedNumCount == 0) {
        layer.alert("这节课没有安排学生");
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: "",
          name: "布置作业",
          moduleName: "releaseHomeTask",
          data: {
            taskData: {
              scheduleCourseID: this.courseInfo.ScheduleCourseKey,
              courseName: this.courseInfo.CourseNameKeyValue,
              HomeWork: this.dataInfo.HomeWork
            }
          },
          callBack: {
            afterSuccess: () => {
              this.getTeachingData();
              this.$emit("updateCourseInfo", this.courseInfo.ScheduleCourseKey);
            }
          }
        });
      }
    },
    releaseComment (item) {
      console.log(item, "releaseComment");
      if (this.courseInfo.IsClassStopKey == 1) {
        layer.alert("这节课已停课");
      } else if (this.courseInfo.ArrangedNumCount == 0) {
        layer.alert("这节课没有安排学生");
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: "",
          name: "发布点评",
          moduleName: "releaseComment",
          data: {
            taskData: {
              scheduleCourseID: this.courseInfo.ScheduleCourseKey,
              courseName: this.courseInfo.CourseNameKeyValue,
              Content: item.Content
            }
          },
          callBack: {
            afterSuccess: () => {
              this.getTeachingData();
              this.$emit("updateCourseInfo", this.courseInfo.ScheduleCourseKey);
            }
          }
        });
      }
    },
    closeAuthDialog () {
      this.showAuth = false;
    },
    openAuthDialog () {
      // this.isShowDialog=true
      if (this.isAlter) {
        if (this.vAuthorizeTitle == "设备授权") {
          this.authDataInfo = {
            CoursewareKey: this.teachingPlanInfo.ParentCoursewarePackageKey, // this.teachingPlanInfo.CoursewarePackageTypeKey == 1 ? this.teachingPlanInfo.ParentCoursewarePackageKey : this.courseInfo.CoursewarePackageKey, // 教案ID
            DeviceCode: this.mCode
          };
          this.showAuth = true;
        } else if (this.teachingPlanInfo.CoursewareAuthorizeStatusKey == 0) {
          layer.alert("课件授权已暂停，请向课件授权方确认。");
        } else if (this.teachingPlanInfo.AuthorizeStatusKey == 0) {
          layer.alert("课件授权已暂停，请向课件授权方确认。");
        } else if (this.vAuthorizeTitle == "非桌面端") {
          if (this.vAuthorizeTitle == "非桌面端") {
            layer.alert("请下载安装桌面端，再使用课件上课功能。");
          }
        } else if (this.vPassAble) {
          if (this.courseInfo.MTeacherKey == this.$store.getters.token.UserID) {
            // this.setMenuTypeList()
            // this.isShowDialog=true
            console.log(
              "this.$store.getters.geoInfo",
              this.$store.getters.geoInfo
            );
            if (
              this.teachingPlanInfo.PCIsLocationLockKey == 1 &&
              this.teachingPlanInfo.AuthorizeStatusKey != 2 &&
              !this.$store.getters.geoInfo.Longitude
            ) {
              console.log("courseAuthQrcode", this.vMenDianInfo);
              this.openCourseAuthQrcode()
            } else {
              if (this.teachingPlanInfo.AuthorizeStatusKey != 2) {
                let newdataInfo = {
                  ScheduleCourseKey: this.courseInfo.ScheduleCourseKey,
                  SaaSClientKey: this.vMenDianInfo.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  DeviceTypeKey: "2", //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  DeviceCode: this.$zxClient.GetMCode() || "" //桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: "coursewareTeachingView",
                      query: {
                        type: 1,
                        scheduleCourseID: this.courseInfo.ScheduleCourseKey,
                        IsPCRecordKey: this.teachingPlanInfo.IsPCRecordKey
                      }
                    });
                  },
                  error => {
                    this.openCourseAuthQrcode()
                  }
                );
                this.$router.push({
                  name: "coursewareTeachingView",
                  query: {
                    type: 1,
                    scheduleCourseID: this.courseInfo.ScheduleCourseKey,
                    IsPCRecordKey: this.teachingPlanInfo.IsPCRecordKey
                  }
                });
              } else {
                this.$router.push({
                  name: "coursewareTeachingView",
                  query: {
                    type: 1,
                    scheduleCourseID: this.courseInfo.ScheduleCourseKey,
                    IsPCRecordKey: this.teachingPlanInfo.IsPCRecordKey
                  }
                });
              }
            }
          } else {
            layer.alert("您不是当前课的主讲老师.");
          }
        } else {
          if (!this.vEarlySignInNameScope) {
            // if (this.teachingPlanInfo.ClearingFormKey == 1) {
            // layer.alert('当前时间无法进入上课模式，请在上课时间前后30分钟内进入。');
            // } else {
            //   let day = Number(this.teachingPlanInfo.SetDate) > 0 ? '前后' + Number(this.teachingPlanInfo.SetDate) + '天内' : '当天';
            //   layer.alert('当前时间无法进入上课模式，请在上课时间' + day + '进入。');
            // }
            layer.alert(
              "当前时间无法进入上课模式，请在上课时间前后30分钟内进入。"
            );
          }
        }
      }
    },
    openCourseAuthQrcode () {
      if (this.teachingPlanInfo.HQAuthKey != 0) {
        let text = this.teachingPlanInfo.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件'
        layer.alert(text)
        return false
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: "",
          name: "安全验证",
          moduleName: "courseAuthQrcode",
          data: {
            dataInfo: {
              ScheduleCourseKey: this.courseInfo.ScheduleCourseKey,
              SaaSClientKey: this.vMenDianInfo.OLAPKey,
              client_id: this.$store.getters.clientId,
              DeviceTypeKey: "2", //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              DeviceCode: this.$zxClient.GetMCode() || "", //桌面端设备码
              introType: 21,
            }
          },
          callBack: {
            afterSuccess: () => {
              this.verifyAuthorize();
              this.$router.push({
                name: "coursewareTeachingView",
                query: {
                  type: 1,
                  scheduleCourseID: this.courseInfo.ScheduleCourseKey,
                  IsPCRecordKey: this.teachingPlanInfo.IsPCRecordKey
                }
              });
            }
          }
        });
      }
    },
    updateData () {
      this.closeAuthDialog();
      this.verifyAuthorize();
    },
    verifyAuthorize () {
      let CoursewareKey = this.teachingPlanInfo.ParentCoursewarePackageKey; // this.teachingPlanInfo.CoursewarePackageTypeKey == 1 ? this.teachingPlanInfo.ParentCoursewarePackageKey : this.courseInfo.CoursewarePackageKey;
      if (CoursewareKey > 0) {
        WEBVVerifyAuthorizedDevice(this.mCode, CoursewareKey)
          .then(result => {
            if (result.code == 0) {
              console.log("WEBVVerifyAuthorizedDevice", result.data);
              let geoInfo = result.data.geoInfo;
              // let geoInfo={
              //   Longitude:this.$store.getters.geoInfo.Longitude||'',
              //   Latitude:this.$store.getters.geoInfo.Latitude||''
              // }
              if (geoInfo) {
                this.$store.commit("setGeoInfo", geoInfo);
              }
              this.isAuthorize = true;
            } else {
              this.isAuthorize = false;
            }
          })
          .catch(error => {
            this.isAuthorize = false;
          });
      }
    },
    getTeachingData (callBack) {
      // let dataObj = {
      //   'DeviceTypeKey': this.mCode ? '2' : '1', // '设备类型 1-浏览器;2-桌面端;3-APP;4-电视',
      //   'DeviceTypeKeyValue': this.mCode ? '桌面端' : '浏览器',
      //   'DeviceCode': this.mCode,
      //   'Longitude': '',    // 经度
      //   'Latitude': '',    // 纬度
      //   'Sign': '' // '授权签名'
      // };
      // let KJ = 'KJ';
      // // pc  sign 为门店ID,  设备类型, 设备码.还有登录人签名组成
      // var stringmd5 = this.$store.getters.SaaSClientInfo.OLAPKey + dataObj.DeviceTypeKey + dataObj.DeviceCode + this.$store.getters.signData.Sign + KJ;
      // dataObj.Sign = md5(stringmd5);
      GetCoursewareDetailForPrepareLessons(
        this.courseInfo.ScheduleCourseKey
      ).then(result => {
        console.log("getTeachingData", result.data);
        this.dataInfo = result.data;
        if (callBack) {
          callBack();
        }
        // this.dataInfo = result.data;
      });
    },
    changeSearchType (type) {
      this.searchType = type;
    },
    changeShowAll () {
      if (
        this.teachingPlanInfo.AuthorizeStatusKey != 0 &&
        this.teachingPlanInfo.CoursewareAuthorizeStatusKey != 0
      ) {
        console.log("this.changeShowAll", this.showAll);
        if (this.showAll == false) {
          this.getTeachingData(() => {
            this.$nextTick(o => {
              this.showAll = !this.showAll;
            });
          });
        } else {
          this.showAll = !this.showAll;
        }
      }
    },
    // 修改作业
    doEditHomeWorkAfter (item, submitBtnName) {
      console.log(item, submitBtnName, "修改作业");
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: "",
        name: "修改作业",
        moduleName: "releaseHomeTask",
        data: {
          taskData: {
            scheduleCourseID: this.courseInfo.ScheduleCourseKey,
            courseName: this.courseInfo.CourseNameKeyValue,
            Content: item.Content
          }
        },
        callBack: {
          afterSuccess: () => {
            this.getTeachingData();
          }
        }
      });
    },
    // 点击素材
    clickEvent (item) {
      this.curSelectionCourseware = item;
      this.isShowAllViewDialog = true;
      document.body.style.overflowY = "hidden";
    },
    // 隐藏全屏
    hiddenAllView () {
      this.isShowAllViewDialog = false;
      document.body.style.overflowY = "auto";
    },
    showAddCW () {
      let isCanChange = true;
      if (this.isAlter) {
        if (this.courseInfo.StatusKey == 4) {
          isCanChange = false;
        } else if (this.courseInfo.StatusKey == 3) {
          if (this.teachingPlanInfo.ClearingFormKey == 1) {
            if (
              new Date().getTime() -
              Date.new(this.courseInfo.StartTime).getTime() <
              900000
            ) {
              isCanChange = true;
            } else {
              isCanChange = false;
            }
          } else {
            isCanChange = true;
          }
        } else {
          isCanChange = true;
        }
        if (isCanChange) {
          // if (this.$zxClient && this.$zxClient.IsClient) {
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: "",
            name: "选择课件",
            moduleName: "webvChooseCourseware",
            data: { ScheduleCourseKey: this.courseInfo.ScheduleCourseKey },
            callBack: {
              afterSuccess: data => {
                console.log(data, "afterSuccess");
                this.$emit(
                  "updateCourseInfo",
                  this.courseInfo.ScheduleCourseKey
                );
                // this.showAll = false
                // this.getTeachingData(() => {

                // });
                // this.addCWforClass(data);
              }
            }
          });
          // } else {
          // layer.alert('请用桌面端打开程序');
          // }
        } else {
          layer.alert("不在可更换时间范围内，不可更换课件");
        }
      }
    },
    clickOption (item) {
      if (item.type == 1) {
        if (this.courseInfo.MTeacherKey == this.$store.getters.token.UserID) {
          this.$router.push({
            name: "coursewareTeachingView",
            query: {
              type: 1,
              scheduleCourseID: this.courseInfo.ScheduleCourseKey
            }
          });
        } else {
          layer.alert("您不是当前课的主讲老师.");
        }
      } else {
        this.showAddCW();
      }
    },
    getWatermarkInfo () {
      if (this.vTypeKey) {
        GetSaaSClientSet().then(res => {
          if (res.data.length > 0) {
            this.watermarkInfo[0] = res.data[7];
            this.watermarkInfo[1] = res.data[8];
            this.watermarkInfo[2] = res.data[9];
          }
        });
      } else {
        GetSecurityWatermark().then(res => {
          if (res.data.length > 0) {
            this.watermarkInfo = res.data;
          } else {
            this.watermarkInfo[0].SetKey = 1;
            this.watermarkInfo[0].SetContent = this.$store.getters.token.UserName;
            this.watermarkInfo[1].SetKey = 1;
            this.watermarkInfo[1].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
            this.watermarkInfo[2].SetKey = 1;
            this.watermarkInfo[2].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
          }
          if (this.coursewareInfoType == "ZZ") {
            this.watermarkInfo[2].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
          }
          console.log(this.coursewareInfoType);
          console.log(this.watermarkInfo, "this.watermarkInfo");
        });
      }
    },
    // 判断是否显示MenuTypeList
    setMenuTypeList () {
      // 已完结
      if (this.courseInfo.StatusKey == 4) {
        this.menuTypeList[1].isShow = false;
      } else if (this.courseInfo.StatusKey == 3) {
        if (this.teachingPlanInfo.ClearingFormKey == 1) {
          if (
            new Date().getTime() -
            Date.new(this.courseInfo.StartTime).getTime() <
            900000
          ) {
            this.menuTypeList[1].isShow = true;
          } else {
            this.menuTypeList[1].isShow = false;
          }
        } else {
          this.menuTypeList[1].isShow = true;
        }
      } else {
        this.menuTypeList[1].isShow = true;
      }
    }
  }
};
</script>
<style scoped>
.new_course_essay_label .label_left {
  display: flex;
  padding-top: 0px;
  flex-direction: row;
}
.new_course_essay_label .label_img {
  flex: 1;
  width: 117px;
  height: 75px;
  padding-top: 10px;
  border-radius: 24px;
  /* display: inline-block; */
  /* width: ; */
}
.label_img img {
  width: 117px;
  height: 100%;
  border-radius: 8px;
  /* height: 65px; */
  /* display: inline-block; */
  /* width: ; */
}

.new_course_essay_label .label_content {
  flex: 1;
  padding-top: 22px;
  padding-left: 10px;
  width: 125px;
}
.course_essay_label .label_name {
  display: block;
}
.course_essay_label .label_name::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 20px;
  width: 8px;
  background: url(../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 8px;
}
.label_img .label_img_text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* background-color: black; */
  /* opacity: 0.5; */
  color: white;
}
.new_course_essay_label .label_right_top {
  width: 100px;
  height: 50px;
}
.new_course_essay_label .label_right_top.type_green {
  background: #fff url(../../../../public/image/course_type_green.png) no-repeat
    34px center;
  background-size: 34px;
}
.new_course_essay_label .label_right_top.type_gray {
  background: #fff url(../../../../public/image/course_type_gray.png) no-repeat
    34px center;
  background-size: 34px;
}
.new_course_essay_label .label_right_bottom.type_green {
  width: 62px;
  height: 20px;
  color: #33c987;
  font-size: 12px;
  background: #ceeee0;
  border-radius: 100px;
  /* opacity: 0.15; */
}
.new_course_essay_label .label_right_bottom.type_gray {
  color: #fa5a46;
  width: 62px;
  height: 20px;
  font-size: 12px;
  background: #fde1e0;
  border-radius: 100px;
  /* opacity: 0.15; */
}
.label_right_green {
  width: 20px;
  height: 20px;
  background: #fff url(../../../../public/image/green_right.png);
  position: absolute;
  right: -8px;
  top: 24px;
  background-size: 20px;
}
.label_text_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
