<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="general_atSchool_student_list">
        <div class="table_fixed_nav_box"
             style="position: static;border-bottom: 2px solid #f2f2f2;">
          <div class="table_fixed_nav"
               style="margin-left: 40px;width: auto;">
            <div @click="showStudentList='pendingProcessing'"
                 class="table_fixed_nav_list"
                 :class="{opt:showStudentList=='pendingProcessing'}">
              待处理
              <!-- <span class="form_info_field">
            <input-form-tip :tips="'已报名购课且未办理离校的学生。'"></input-form-tip>
          </span> -->
            </div>
            <div @click="showStudentList='processed'"
                 v-if="vSettingShow"
                 class="table_fixed_nav_list"
                 :class="{opt:showStudentList=='processed'}">已处理</div>
            <div @click="showStudentList='associatedConversionDetails'"
                 v-if="vSettingShow"
                 class="table_fixed_nav_list"
                 :class="{opt:showStudentList=='associatedConversionDetails'}">关联转化明细</div>
          </div>
        </div>
        <div>
          <!-- 待处理 -->
          <div v-if="showStudentList=='pendingProcessing'"
               class="pendingf">
            <!-- <div @click="openSetting">设置</div> -->
            <div class="table_list_content summarizing_list"
                 style="border-radius: 8px;">
              <pending-processing></pending-processing>
            </div>
          </div>
          <!-- 已处理 -->
          <div v-else-if="showStudentList=='processed'"
               class="pendingf">
            <div class="table_list_content summarizing_list"
                 style="border-radius: 8px;">
              <processed></processed>
            </div>
          </div>
          <!-- 关联转化明细 -->
          <div v-else-if="showStudentList=='associatedConversionDetails'"
               class="pendingf">
            <div class="table_list_content summarizing_list"
                 style="border-radius: 8px;">
              <associated-conversion-details></associated-conversion-details>
            </div>
          </div>
        </div>

        <div>
          <dialog-factory ref="studentApplyCourseReportDialogFactory"
                          :routerName="'studentManagementReport'"></dialog-factory>
        </div>
        <dialog-factory-common ref="studentManagementCommonDialogFactory"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import {
} from '../../API/workbench.js';
import associatedConversionDetails from './report/associated-conversion-details.vue';
import processed from './report/processed.vue';
import pendingProcessing from './report/pending-processing.vue';
import dialogFactory from '../common/dialog-factory';
export default {
  data () {
    return {
      data1: {
        SetKey: '0'
      },
      data2: {
        SetKey: '0'
      },
      data3: {
        SetKey: '1'
      },
      showStudentList: 'pendingProcessing' // pendingProcessing-待处理，processed-已处理,associatedConversionDetails-关联转化明细
    };
  },
  components: {
    dialogFactory,
    pendingProcessing,
    processed,
    associatedConversionDetails
  },
  computed: {
    vSaaSClientSetting () {
      return this.$store.getters.SaaSClientInfo.SaaSClientSetting;
    },
    vSettingShow () {
      console.log('wewewe', this.data1, this.data2, this.data3);
      if (this.data1.SetKey != '1' && this.data2.SetKey != '1' && this.data3.SetKey != '1') {
        return false;
      }
      return true;
    }
  },
  created () {
    this.registerBusEvent();
  },
  watch: {
    'vSaaSClientSetting': {
      handler (newValue, oldValue) {
        if (newValue) {
          let index1 = this.vSaaSClientSetting.findIndex((item) => {
            return item.SetTypeKey == '100';
          });
          // console.log();
          this.data1 = this.vSaaSClientSetting[index1];
          let index2 = this.vSaaSClientSetting.findIndex((item) => {
            return item.SetTypeKey == '101';
          });
          console.log();
          this.data2 = this.vSaaSClientSetting[index2];
          let index3 = this.vSaaSClientSetting.findIndex((item) => {
            return item.SetTypeKey == '102';
          });
          this.data3 = this.vSaaSClientSetting[index3];
          console.log('data3', this.data3, index3);
        }
      },
      deep: true
    }
  },
  activated () {
  },
  mounted () {
    setTimeout(() => {
      // this.tableColumsList = JSON.parse(JSON.stringify(this.tableCourseColumns));s
      let index1 = this.vSaaSClientSetting.findIndex((item) => {
        return item.SetTypeKey == '100';
      });
      // console.log();
      this.data1 = this.vSaaSClientSetting[index1];
      let index2 = this.vSaaSClientSetting.findIndex((item) => {
        return item.SetTypeKey == '101';
      });
      console.log();
      this.data2 = this.vSaaSClientSetting[index2];
      let index3 = this.vSaaSClientSetting.findIndex((item) => {
        return item.SetTypeKey == '102';
      });
      this.data3 = this.vSaaSClientSetting[index3];
      console.log('data3', this.data3, index3);
    }, 300);
  },
  methods: {
    registerBusEvent () {
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'registrationSupplementaryCorrection') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'registrationSupplementaryCorrection') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    supplementtarySettingSuccess () {
      console.log('设置成功');
    },

    // 单例
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialogByFactoryDialog', dialogInfo);
      // this.$emit('openCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'registrationSupplementaryCorrection') {
        if (this.$refs.studentApplyCourseReportDialogFactory) {
          this.$refs.studentApplyCourseReportDialogFactory.openDialog(
            dialogInfo.moduleName,
            dialogInfo.dataKey,
            dialogInfo.data,
            dialogInfo.callBack,
            dialogInfo.name
          );
        }
      }
    },
    // 多例
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      // this.$emit('openCommonCustomDialog', dialogInfo);
      if (this.$refs.studentManagementCommonDialogFactory) {
        this.$refs.studentManagementCommonDialogFactory.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>

<style scoped>
.pendingf {
  margin: auto;
}
</style>

