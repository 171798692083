<template>
  <div>
    <div class="bg_gray new_course_time_from clearfix">
      <student-label :studentID="studentInfo.StudentKey"
                     :dataInfo="studentInfo"
                     @alterStudent="alterStudent"
                     :isShowEdit="true"
                     :showMenu="true"></student-label>
      <!-- 选项卡 -->
      <div class="apply_record_title">报名记录</div>
      <div class="apply_record_ul">
        <apply-record-item v-for="(item,key) in recordDataList"
                           :key="key"
                           :item="item"
                           @seletedItem="relevanceData"
                           @toApplyRecord="toApplyRecord">
        </apply-record-item>
        <div v-if="recordDataList.length==0&&isLoadEnd">
          <div class="monitoring_content_nodata label_radio_bg_white overflow_hidden">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            <div class="btn_light_blue btn_hover_bg_blue"
                 @click.stop="toSignUpPayment">去报名 </div>
          </div>
        </div>
        <div v-if="isNoRelevance&&recordDataList.length>0">
          <div class="monitoring_content_nodata label_radio_bg_white overflow_hidden">
            <div class="monitoring_content_nodata_txt2">亲，当前没有可关联的数据。</div>
            <div class="btn_light_blue btn_hover_bg_blue"
                 @click.stop="toSignUpPayment">去报名 </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import studentLabel from './arrange-student-label';
import applyRecordItem from './apply-record-component/apply-record-item';
import { GetApplyPaymentForStudent, TrialRelevanceApply } from '../../../../API/workbench';

export default {
  data () {
    return {
      studentInfo: {},
      recordDataList: [],  // 全部记录
      isLoadEnd: false
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    studentLabel, applyRecordItem
  },
  computed: {
    isNoRelevance () {
      let showRelevance = true;
      this.recordDataList.forEach(o => {
        if (o.TrialArrangeKey == 0) {
          showRelevance = false;
        }
      });
      return showRelevance;
    }
  },
  created () {
    this.studentInfo = this.dataInfo;
    this.getRecordList();
  },
  methods: {
    initOptionHighLinght () {
      if (this.studentInfo.PurposeKeyName) {
        this.optionList.forEach(o => {
          if (o.level === this.studentInfo.PurposeKeyName) {
            o.isOpt = true;
            this.dataObj.PurposeName = o.value;
            this.dataObj.PurposeKeyName = o.level;
          } else {
            o.isOpt = false;
          }
        });
      }
    },
    alterStudent (item) {
      this.studentInfo.IsConfirmKey = item.IsConfirmKey;
      console.log('alterStudent', item);
    },
    getRecordList () {
      GetApplyPaymentForStudent(this.studentInfo.StudentKey).then(result => {
        console.log(result.data, 'getRecordList');
        result.data.sort((x, y) => {
          // 总分不同，降序排序
          return Date.new(y.GenerateTime) - Date.new(x.GenerateTime);
        });
        this.recordDataList = result.data;
        this.isLoadEnd = true;
      });
    },
    relevanceData (item) {
      console.log('relevanceData', item);
      // 接口调完之后触发更新
      TrialRelevanceApply(this.dataInfo.TableID, item.TableID).then(result => {
        layer.alert('关联成功');
        this.getRecordList();
        this.$emit('updateData');
      });
    },
    toSignUpPayment () {
      console.log(this.dataInfo, 'toSignUpPayment', this.studentInfo);
      if (this.studentInfo.IsConfirmKey == 0) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
        return false;
      }
      let obj = {
        StudentKey: this.dataInfo.StudentKey,
        isReadOnly: false,
        TableID: ''
      };
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: obj },
        callBack: { afterSucces: () => { this.getRecordList(); } }
      });
    },
    toApplyRecord (item) {
      console.log('toApplyRecord', item);
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: this.dataInfo.StudentKey, TableID: item.TableID, isReadOnly: true } },
        callBack: { afterSucces: () => { this.getRecordList(); } }
      });
    }

  }
};
</script>
