<template>
  <!-- 直营店列表 -->
  <div class="chainstore_manage_content">
    <div class="chainstore_manage_ul">
      <div class="chainstore_manage_list">
        <div class="chainstore_manage_info">
          <div class="info_left">
            <div class="info_left_top">
              <div class="info_number">NO.{{ranking}} </div>
              <div class="info_id"> ID: {{dataInfo.SaaSClientNO}}</div>
              <!-- <div class="info_red"
                   v-if="vExpiredTime">{{vExpiredTime}}</div> -->
            </div>
            <div class="info_left_center">
              <div class="info_title">
                <el-tooltip :content="dataInfo.MainDemoName"
                            placement="top"
                            :disabled="dataInfo.MainDemoName.length<10">
                  <div class="text_box">
                    {{dataInfo.MainDemoName}}
                  </div>
                </el-tooltip>
                <div class="info_btn"
                     @click.stop="isShowBtnMenu=!isShowBtnMenu">
                  <div class="info_btn_ul"
                       v-if="isShowBtnMenu">
                    <div class="info_btn_menu type_01"
                         @click.stop="viewBtn">
                      查看
                    </div>
                    <div class="info_btn_menu type_02"
                         @click.stop="editBtn">
                      修改
                    </div>
                  </div>

                </div>
              </div>
              <div class="info_teacher">
                <div class="info_teacher_img"
                     :class="vClass">
                  <span v-if="!vXMLIcon"
                        class="text_box">{{dataInfo.AdminName.slice(0, 1)}}</span>
                  <img class="img_box"
                       :src="vXMLIcon"
                       mode="">
                </div>
                <div class="info_teacher_text">
                  <div class="info_teacher_name">{{dataInfo.AdminName}}</div>
                  <div class="info_teacher_phone">{{dataInfo.DefaultMobile}}</div>
                </div>
              </div>
            </div>
            <div class="info_left_bottom">
              <!-- <div class="info_type"
                   :class="vTypeKeyValue=='参股'?'type_green':'type_blue'">{{vTypeKeyValue}}</div> -->
              <div class="info_type_text_number">
                <div class="teacher_type_text">师</div>
                <div class="type_num">{{this.$utils.mAmountType(dataInfo.OnJobEmployeesCount,0)||0}}</div>
              </div>
              <div class="info_type_text_number">
                <div class="student_type_text">生</div>
                <div class="type_num">{{this.$utils.mAmountType(dataInfo.ActivityStudentCount,0)||0}}</div>
              </div>
            </div>
          </div>
          <!-- 中间内容 -->
          <div class="info_right"
               v-if="!vNoDataTeachingPower">
            <div class="info_right_title">
              <div class="fr">
                <span class="font_gray">月排课：</span>{{this.$utils.mAmountType(dataInfo.ThisMonthScheduleCourseCount,0)||0}}课次丨{{this.$utils.mAmountType(dataInfo.ThisMonthArrangeStudentNum,0)||0}}人次
              </div>
            </div>
            <!-- 左侧栏 -->
            <div class="info_statistics_box"
                 v-if="isShowLeftBox">
              <div class="info_statistics_list"
                   v-for="(leftData,index) in statisticsBoxLeft"
                   :key="index">
                <div class="info_statistics_name">
                  {{leftData.title}}
                </div>
                <div class="info_statistics_data">
                  <div class="flex_1">
                    <div class="info_statistics_number">{{transformValue(leftData.leftValue,leftData.type)}}</div>
                    <div class="info_statistics_text">{{leftData.leftText}}</div>
                  </div>
                  <div class="flex_1">
                    <div class="info_statistics_number">{{transformValue(leftData.rightValue,leftData.type)}}</div>
                    <div class="info_statistics_text">{{leftData.rightText}}</div>
                  </div>
                </div>
              </div>
              <div class="info_statistics_right el-icon-arrow-right"
                   @click="isShowLeftBox=false"></div>
            </div>
            <!-- 右侧栏 -->
            <div class="info_statistics_box"
                 v-else>
              <div class="info_statistics_left el-icon-arrow-right"
                   @click="isShowLeftBox=true"></div>
              <div class="info_statistics_list"
                   v-for="(leftData,index) in statisticsBoxRight"
                   :key="index">
                <div class="info_statistics_name">
                  {{leftData.title}}
                </div>
                <div class="info_statistics_data">
                  <div class="flex_1">
                    <div class="info_statistics_number">{{transformValue(leftData.leftValue,leftData.type)}}</div>
                    <div class="info_statistics_text">{{leftData.leftText}}</div>
                  </div>
                  <div class="flex_1">
                    <div class="info_statistics_number">{{transformValue(leftData.rightValue,leftData.type)}}</div>
                    <div class="info_statistics_text">{{leftData.rightText}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else
               class="info_right_noData_text">
            <div :class="vNoDataTeachingPower">{{vNoDataTeachingPower=='overdue_text'?'门店已过期':'尚未开通教务'}}</div>
            <div class="btn_hover_bg_blue renewal_btn"
                 v-if="vNoDataTeachingPower=='overdue_text'"
                 @click="openRenewalForm('md')">点击续期</div>
            <div class="btn_hover_bg_blue renewal_btn"
                 v-else-if="vNoDataTeachingPower=='no_teaching_Power'"
                 @click="openForm('md')">点击开通</div>
          </div>
          <!-- 右侧内容 -->
          <!-- :style="Number(dataInfo.AuthorizeCoursewareCount) == 0?' cursor: default':''" -->
          <div class="info_right_btn"
               @click="showCourseware"
               v-if="!vNoDataCoursewarePower">
            <div class="btn_text">授权课包</div>
            <div class="btn_number"
                 :class="vClassName">{{$utils.mAmountType(dataInfo.AuthorizeCoursewareCount,0)||0}}</div>
          </div>
          <div v-else
               class="info_right_btn_noData_text">
            <div :class="vNoDataCoursewarePower">{{vNoDataCoursewarePower=='overdue_text'?'课件已过期':'尚未开通课件'}}</div>
            <div class="btn_hover_bg_blue renewal_btn"
                 v-if="vNoDataCoursewarePower=='overdue_text'"
                 @click="openRenewalForm('kj')">点击续期</div>
            <div class="btn_hover_bg_blue renewal_btn"
                 v-else-if="vNoDataCoursewarePower=='no_courseware_Power'"
                 @click="openForm('kj')">点击开通</div>
          </div>
        </div>
        <div class="chainstore_manage_table_box summarizing_list"
             v-if="isShowCourseware">
          <!-- <courseware-list :retailInfo="dataInfo"></courseware-list> -->
          <tableList :SchoolKey='Number(dataInfo.OLAPKey)'
                     :showPagePagination='false'
                     :plan="'C'"
                     :retailInfo="dataInfo"
                     @flashData='flashData'></tableList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import coursewareList from './courseware-list';
import tableList from '../../courseware-authorization-management/courseware-package/table-list-common.vue';
export default {
  data () {
    return {
      isShowBtnMenu: false, // 操作弹窗
      isShowLeftBox: true, // 默认显示左侧栏
      isShowCourseware: false, // 是否开发课件包列表
      statisticsBoxLeft: [
        { title: '报名(万元)', type: 'money', leftText: '昨天', leftValue: 'YesterdayApplyAmount', rightText: '本月', rightValue: 'ThisMonthApplyAmount' },
        { title: '课消(万元)', type: 'money', leftText: '昨天', leftValue: 'YesterdayClassAmount', rightText: '本月', rightValue: 'ThisMonthClassAmount' },
        { title: '收款余额', type: 'money', leftText: '余额(万元)', leftValue: 'ResidueAmount', rightText: '课时', rightValue: 'ClassHourCount' },
        { title: '出勤率', type: 'ratio', leftText: '昨天', leftValue: 'YesterdaySignRate', rightText: '本月', rightValue: 'ThisMonthSignRate' }
      ],
      statisticsBoxRight: [
        { title: '出勤率', type: 'ratio', leftText: '昨天', leftValue: 'YesterdaySignRate', rightText: '本月', rightValue: 'ThisMonthSignRate' },
        { title: '签到(人次)', type: 'count', leftText: '昨天', leftValue: 'YesterdaySignNum', rightText: '本月', rightValue: 'ThisMonthSignNum' },
        { title: '请假(人次)', type: 'count', leftText: '昨天', leftValue: 'YesterdayLeaveNum', rightText: '本月', rightValue: 'ThisMonthLeaveNum' },
        { title: '未考勤(人次)', type: 'count', leftText: '昨天', leftValue: 'YesterdayUnAttendanceNum', rightText: '本月', rightValue: 'ThisMonthUnAttendanceNum' }
      ],
      // 课件包
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '' //	字符串	可选		数据源：GET	排序顺序
      }
    };
  },
  props: {
    ranking: Number,
    dataInfo: Object
  },
  components: {
    coursewareList,
    tableList
  },
  watch: {

  },
  created () {

  },
  mounted () {
    document.querySelector('body').addEventListener('click', e => {
      this.isShowBtnMenu = false;
    }, false);
  },
  computed: {
    vXMLIcon () {
      if (this.dataInfo.XMLIcon) {
        if (this.dataInfo.XMLIcon.indexOf('http') > -1) {
          return this.dataInfo.XMLIcon;
        } else if (this.dataInfo.XMLIcon && this.dataInfo.XMLIcon.indexOf('/') == -1) {
          return this.$store.getters.CDNURL + '/' + this.dataInfo.XMLIcon;
        } else {
          return this.$store.getters.CDNURL + this.dataInfo.XMLIcon;
        }
      } else {
        return '';// https://zxtest.zhixuerj.com/WXBV/img/WXimg/deflut_teacher_photo@2x.png
      }
    },
    vClass () {
      let n = Math.floor(1 + Math.random() * (9 - 1));
      return 'bg_' + n;
    },
    // 处理显示过期时间
    // vExpiredTime () {
    //   if (this.dataInfo.ExpiredTime) {
    //     let days = this.$utils.DateDiffDay(this.$utils.formatDateToLine(Date.new()), this.dataInfo.ExpiredTime.slice(0, 10)) + 1;
    //     if (this.dataInfo.StatusKey == 0 && this.dataInfo.ChannelStatusKey == 0) {
    //       return '已停用';
    //     } else if (days <= 31 && days > 0 && Date.new(this.dataInfo.ExpiredTime).getTime() > Date.new().getTime()) {
    //       return days + '天后过期';
    //     } else if (days < 0 || Date.new(this.dataInfo.ExpiredTime).getTime() < Date.new().getTime()) {
    //       return '已过期';
    //     } else {
    //       return '';
    //     }
    //   }
    // },
    // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
    vTypeKeyValue () {
      if (Number(this.dataInfo.TypeKey) == 1) {
        return '参股';
      } else if (Number(this.dataInfo.TypeKey) == 2) {
        return '直属';
      }
    },
    vClassName () {
      // if (Number(this.dataInfo.AuthorizeCoursewareCount) > 0) {
      if (this.isShowCourseware) {
        return 'el-icon-caret-top';
      } else {
        return 'el-icon-caret-bottom';
      }
      // } else {
      //   return 'no_data';
      // }
    },
    // 教务-无权限或门店过期
    vNoDataTeachingPower () {
      let atDate = Date.new().getTime();
      if (this.dataInfo.IsOpenAcademicKey == 0) {
        return 'no_teaching_Power';
      } else if ((Number(this.dataInfo.StatusKey) == 0 ||
        (this.dataInfo.ExpiredTime && atDate > Date.new(this.dataInfo.ExpiredTime).getTime())) &&
        this.dataInfo.IsOpenAcademicKey == 1) {
        return 'overdue_text';
      } else {
        return '';
      }
    },
    // 课件-无权限或门店过期
    vNoDataCoursewarePower () {
      let atDate = Date.new().getTime();
      if (this.dataInfo.IsOpenChannelKey == 0) {
        return 'no_courseware_Power';
      } else if ((Number(this.dataInfo.ChannelStatusKey) == 0 ||
        (this.dataInfo.CoursewareChannelExpiredTime && atDate > Date.new(this.dataInfo.CoursewareChannelExpiredTime).getTime())) &&
        this.dataInfo.IsOpenChannelKey == 1) {
        return 'overdue_text';
      } else {
        return '';
      }
    }

  },
  methods: {
    // 查看
    viewBtn () {
      this.isShowBtnMenu = false;
      this.$emit('doViewBtn', this.dataInfo);
    },
    // 修改
    editBtn () {
      this.isShowBtnMenu = false;
      this.$emit('doEditBtn', this.dataInfo);
    },
    // 切换显示
    showCourseware () {
      // if (Number(this.dataInfo.AuthorizeCoursewareCount) > 0) {
      this.isShowCourseware = !this.isShowCourseware;
      console.log(this.dataInfo, 'this.dataInfo');
      // }
    },
    // 续费
    openRenewalForm (type) {
      console.log(type, this.dataInfo, '点击');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '续期',
        moduleName: 'renewalForm',
        data: {
          dataInfo: {
            OLAPKey: this.dataInfo.OLAPKey,  // 校区ID
            MainDemoName: this.dataInfo.MainDemoName,
            IsOpenChannelKey: type == 'kj' ? 1 : 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: type == 'md' ? 1 : 0, // "是否开通教务  0-否;1-是",
            ExpireTime: type == 'md' ? this.dataInfo.ExpiredTime : this.dataInfo.CoursewareChannelExpiredTime  // 到期日
          }
        },
        callBack: {
          afterSuccess: (data) => { this.$emit('afterSuccess'); }
        }
      });
    },
    // 子列表回调
    flashData () {
      this.$emit('afterSuccess');
    },
    // 开通
    openForm (type) {
      console.log(type, this.dataInfo, '点击');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '开通功能',
        moduleName: 'openOptionsForm',
        data: {
          dataInfo: {
            CampusKey: this.dataInfo.OLAPKey,  // 校区ID
            CampusName: this.dataInfo.MainDemoName,
            MobilePhone: this.dataInfo.MobilePhone,
            IsOpenChannelKey: type == 'kj' ? 1 : 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: type == 'md' ? 1 : 0, // "是否开通教务  0-否;1-是",
            ExpireTime: type == 'md' ? this.dataInfo.ExpiredTime : this.dataInfo.CoursewareChannelExpiredTime,  // 到期日
            type: this.dataInfo.TypeKey // 1-直营店;2-加盟店;3-课程合作
          }
        },
        callBack: {
          afterSuccess: (data) => { this.$emit('afterSuccess'); }
        }
      });
    },
    transformValue (val, type) {
      var num = Number(this.dataInfo[val]) || 0;
      if (val == 'ClassHourCount') { // 课时类型
        return this.$utils.mAmountType(num, 1);
      } else if (type == 'count') { // 人次类型
        return this.$utils.mAmountType(num, 0);
      } else if (val != 'ClassHourCount' && type == 'money') { // 金额类型（万元）
        num = num / 10000;
        return this.$utils.mAmountType(num, 1);
      } else if (type == 'ratio') { // 考勤率类型（%）
        num = num * 100;
        return this.$utils.mAmountType(num, 0) + (num > 0 ? '%' : '');
      }
    }
  }
};
</script>
<style>
.info_teacher_img.bg_1 {
  background-color: #bad7df;
}
.info_teacher_img.bg_2 {
  background-color: #769fcd;
}
.info_teacher_img.bg_3 {
  background-color: #99ddcc;
}
.info_teacher_img.bg_4 {
  background-color: #fbafaf;
}
.info_teacher_img.bg_5 {
  background-color: #f2c6b4;
}
.info_teacher_img.bg_6 {
  background-color: #b9bbdf;
}
.info_teacher_img.bg_7 {
  background-color: #99e1e5;
}
.info_teacher_img.bg_8 {
  background-color: #c1c0b9;
}
.info_teacher_img .img_box {
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}
.info_type_text_number {
  display: flex;
  border: 1px solid #ececec;
  border-radius: 100px;
  width: 64px;
  height: 24px;
  margin-left: 20px;
  color: #333 !important;
  position: relative;
}
.info_type_text_number .teacher_type_text {
  background: #feaa35;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  color: #fff;
  position: relative;
  left: 2px;
  top: 2px;
}
.info_type_text_number .student_type_text {
  background: #4dccd4;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  color: #fff;
  position: relative;
  left: 2px;
  top: 2px;
}
.info_type_text_number .type_num {
  position: absolute;
  left: 25px;
  top: 4px;
}
.info_right_noData_text {
  width: 873px;
  text-align: center;
  height: 100px;
  margin: 60px 5px 0px 5px;
  border-left: 1px solid #ececec;
  position: relative;
}
.info_right_btn_noData_text {
  width: 100px;
  text-align: center;
  height: 190px;
  padding: 60px 5px 0;
  border-left: 1px solid #ececec;
  position: relative;
}
.info_right_noData_text .overdue_text,
.info_right_noData_text .no_teaching_Power,
.info_right_btn_noData_text .overdue_text,
.info_right_btn_noData_text .no_courseware_Power {
  color: #666;
  margin-top: 30px;
}
.info_right_noData_text .overdue_text::after {
  content: '';
  background: url(../../../../../public/image/warning@2x.png) no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -5px;
  left: 426px;
}
.info_right_noData_text .no_teaching_Power::after {
  content: '';
  background: url(../../../../../public/image/yellow_warning.png) no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -5px;
  left: 426px;
}
.info_right_btn_noData_text .no_courseware_Power::after {
  content: '';
  background: url(../../../../../public/image/yellow_warning.png) no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 55px;
  left: 40px;
}
.info_right_btn_noData_text .overdue_text::after {
  content: '';
  background: url(../../../../../public/image/warning@2x.png) no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 55px;
  left: 40px;
}
.info_right_noData_text .renewal_btn {
  width: 76px;
  height: 28px;
  line-height: 28px;
  border-radius: 100px;
  margin: 10px 400px;
}
.info_right_btn_noData_text .renewal_btn {
  width: 76px;
  height: 28px;
  line-height: 28px;
  border-radius: 100px;
  margin: 10px 7px;
}
</style>
<style scoped>
.chainstore_manage_table_box {
  padding: 0px !important;
}
/* .chainstore_manage_table_box >>> .courseware-tablist {
  width: 1208px !important;
} */
</style>
