<template>
  <div>
    <div class="WX_setting_content_title">
      文字信息
    </div>
    <div class="WX_setting_content_textarea_box">
      <textarea class="WX_setting_content_textarea"
                v-model="dataInfo.content"></textarea>
      <div class="WX_setting_content_textarea_number"><span class="font_blue">{{contentLength}}</span>/<span>200</span></div>
    </div>
    <div class="WX_message_submit btn_hover_bg_blue"
         @click.stop="submit">
      提交
    </div>
    <div class="WX_message_tips">
      <div class="WX_message_tips_title">
        请注意：
      </div>
      <div class="WX_message_tips_text">
        1、对于认证订阅号，群发接口每天可成功调用1次，此次群发可选择发送给全部用户或某个标签；
      </div>
      <div class="WX_message_tips_text">
        2、对于认证服务号虽然开发者使用高级群发接口的每日调用限制为100次，但是用户每月只能接收4条，无论在公众
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平台网站上，还是使用接口群发，用户每月只能接收4条群发消息，多于4条的群发将对该用户发送失败；
      </div>
      <div class="WX_message_tips_text">
        3、系统提示发送成功便是成功，请耐心等待！
      </div>
    </div>
  </div>
</template>
<script>
import { MassMessage } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        content: ''
      },
      isSubmit: false
    };
  },
  computed: {
    contentLength () {
      return this.dataInfo.content.length;
    }
  },
  props: {

  },
  methods: {
    submit () {
      if (!this.dataInfo.content) {
        layer.alert('请输入内容');
        return false;
      } else if (this.contentLength > 200) {
        layer.alert('内容不能超过200字数');
        return false;
      }
      if (this.isSubmit) {
        return false;
      } else {
        this.isSubmit = true;
        MassMessage(this.dataInfo).then(result => {
          layer.alert('群发消息成功');
          this.isSubmit = false;
        }).catch(error => {
          this.isSubmit = false;
          console.log('ErrorMassMessage', error);
        });
      }
    }
  }
};
</script>
