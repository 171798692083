<template>
  <div class="recruit_student_activity_content cover_content_over">
    <div class="course_statistics_table_search flex course_statistics_title">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'搜索模板'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
       
      </div>
    </div>
    <div class="content_ul" style="min-height:500px"
         v-if="vTemplateList.length>0">
      <template-item v-for="(item,key) in vTemplateList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedTemplateAfter"></template-item>

    </div>
    <div v-if="vTemplateList.length==0"
         style="padding-top:150px;height:500px">
      <div class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="searchObj.pageSize > 0"
         class="table_pagination table_pagination_right" style="margin: 20px;">
      <div class="table_left_text">
        共{{total}}条数据
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :page-size="searchObj.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     layout="prev, pager, next, jumper"
                     :total="total>0?total:searchObj.pageSize"></el-pagination>
      <table-pagination-number :pageSize="searchObj.pageSize"
                               :queryParams="searchObj"
                               @loadTableData="getDataList"></table-pagination-number>
    </div>
    <!-- <custom-dialog :title="'活动预览'"
                   width="1200px"
                   :visible.sync="isShowActivityDetailsPopup"
                   :before-close="hiddenActivityDetailsPopup">
      <activities-preview :dataInfo="selectActivityInfo"
                          @closePopup="hiddenActivityDetailsPopup"></activities-preview>
    </custom-dialog> -->
  </div>
</template>
<script>
import templateItem from './template-item';
import tablePaginationNumber from '../../../../../../common/table-pagination-number'; // table 分页
import inputSearchContainBtn from '../../../../../../report/common/input-search-contain-btn';
import { MessageSearchActivitytemplate,AbortActivity } from '../../../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        PublishStatus:'-1', //-1 全部， 0未发布，1已发布
        startTime: '',
        endTime: '',
        TypeKey:'5' //活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
      },
      total: 0,
      templateList:[
      ],
      defaultSort: { prop: '', order: 'ascending' },
      
    };
  },
  components: {
    inputSearchContainBtn,templateItem,tablePaginationNumber
  },
  props: {
    
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.searchObj.TypeKey = this.$route.query.TypeKey || 5;
    this.getDataList()
  },
  watch:{

  },
  computed: {
    vTemplateList () {
      let newArr = [];
      this.templateList.forEach((o, index) => {
        this.$set(o, 'isActive', false);
        if (!this.searchObj.searchText && this.searchObj.pageIndex == 0 && this.searchObj.SceneKey == 0 && this.searchObj.AgenciesKey == 0) {
          if (index < 5) {
            this.$set(o, 'isHot', true);
          }
        } else {
          this.$set(o, 'isHot', false);
        }
        newArr.push(o);
      });
      return newArr;
    },
    vPageIndex: {
      get () {
        return this.searchObj.pageIndex + 1;
      },
      set (n, o) {
      }
    },
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    // 选中模板
    seletedTemplateAfter (item,type) {
      this.templateList.forEach(o => {
        if (item.id == o.id) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      console.log(type,'seletedTemplateAfter')
      this.coverInfo = item;
      this.$emit("afterSuccess",item)
    },
    editPoster (item) {
      window.open(this.$store.getters.wktDomainURL+'/iShowEditor/#/ishow?activityTempId=' + item.id+'&isHiddenModule=1');
    },
    // 删除
    delItem(item){
      AbortActivity(item.id).then(result=>{
        console.log("AbortActivity",result.data)
        this.getDataList()
      }).catch(error=>{
        console.log('AbortActivityError',error)
      })
    },
     // 跳转到第几页
    handleCurrentChange (val) {
      this.searchObj.pageIndex = val - 1;
      this.getDataList(this.searchObj);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 新增模板
    addActivity (callBack) {
      
      console.log('新增模板');
    },
    getDataList (queryParams) {
      let fn = MessageSearchActivitytemplate;
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      fn(this.searchObj).then(result => {
        this.total = result.data.Total;
        this.templateList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
.cover_content_over{
  padding: 0 22px !important;
}
</style>