<template>
  <div v-dialogDrag
       class="popup_dialog"
       v-if="vIsVisible||visible">
    <!-- 弹出框 -->
    <div class="popup_dialog_box"
         :style="popupDialogBoxStyle">
      <!-- 表头 -->
      <div class="popup_dialog_box_header"
           v-show="title">
        <span class="popup_dialog_box_header_title">{{title}}</span>
        <span v-if="moduleDescription">
          <input-form-tip :tips='moduleDescription'></input-form-tip>
        </span>
        <span class="popup_dialog_box_header_slogn">{{descriptionText}}</span>
        <div class="popup_dialog_box_header_btn"
             @click.stop="handleClose"></div>
      </div>
      <!-- 内容 -->
      <div class="popup_dialog_box_content clearfix">
        <slot></slot>
      </div>
      <dialog-factory-common></dialog-factory-common>
    </div>
  </div>
</template> 

<script>
export default {
  data () {
    return {
      popupDialogBoxStyle: null
    };
  },
  props: {
    title: String, // 标题
    dialogParamList: Array, // 打开弹窗所带的参数
    curModuleName: { // 当前所打开弹窗的ModuleName
      type: String,
      default: ''
    },
    showPosition: { // 弹窗位置（偏上 居中 偏下）
      type: String,
      default: 'top'
    },
    visible: {// 显示隐藏
      type: Boolean,
      default: false
    },
    beforeClose: Function, // 关闭后的回调
    width: {
      type: String,
      default: '410px'
    },
    top: { // 距离顶部位置
      type: [Number, String],
      default: 30
    },
    upTop: { // 距离顶部偏移位置
      type: [Number, String],
      default: 0
    },
    closeOnClickModal: {// 点击遮罩层关闭弹窗
      type: Boolean,
      default: false
    },
    descriptionText: {// 说明文字
      type: String,
      default: ''
    },
    appendToBody: {// 弹窗添加到Body
      type: Boolean,
      default: false
    },
    moduleDescription: {
      type: String,
      default: ''
    },
    isOpenFactoryDialogCommon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vIsVisible () {
      let flag = false;
      if (this.dialogParamList && this.dialogParamList.length > 0) {
        let visibleDialog = this.dialogParamList.find(obj => { return obj.moduleName == this.curModuleName; });
        if (visibleDialog) {
          flag = true;
        }
      }
      return flag;
    }
  },
  created () {
    if (this.visible) {
      this.initPopupDialogBoxStyle();
    }
  },
  mounted () {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
  watch: {
    visible (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
        this.$nextTick(() => {
          this.appendDialogToElement();
        });
      } else {
        this.hide();
      }
    },
    vIsVisible (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
        this.$nextTick(() => {
          this.appendDialogToElement();
        });
      } else {
        this.handleClose();
      }
    },
    width (n, o) {
      if (n != o && this.popupDialogBoxStyle) {
        this.popupDialogBoxStyle.width = n;
      }
    }
  },
  methods: {
    initPopupDialogBoxStyle () {
      let top = 0;
      this.$store.commit('setCurOpenDialogName', this.title);
      this.$nextTick(() => {
        this.popupDialogBoxStyle = {};
        if (this.width) {
          this.popupDialogBoxStyle.width = this.width;
        }
        let dialogHeight = this.$el.querySelector('.popup_dialog_box').offsetHeight;
        if (this.showPosition == 'visibleMiddle') {
          top = document.documentElement.scrollTop + document.documentElement.clientHeight / 2 - dialogHeight / 2;
        } else if (this.showPosition == 'top') {
          top = document.documentElement.scrollTop + Number(this.top) + 70;
        } else if (this.showPosition == 'bottom') {
        } else if (this.showPosition == 'clickNearby') {
        }
        if (this.upTop == 0) {
          this.popupDialogBoxStyle.top = parseInt(top) + 'px';
        } else {
          this.popupDialogBoxStyle.top = parseInt(top) - Number(this.upTop) + 'px';
        }
        this.setMaxZIndex();
      });
    },
    setMaxZIndex () {
      let maxZIndex = this.caculateMaxZIndex();
      this.popupDialogBoxStyle.zIndex = maxZIndex + 2;
      this.$store.commit('SetPopupMaxZIndex', maxZIndex + 1);
    },
    caculateMaxZIndex () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (!shadeDiv) {
        this.$store.commit('SetPopupShade', true);
      }
      let curRouterElement = document.getElementById(this.$route.name);
      let divs = curRouterElement.querySelectorAll('.popup_dialog_box');
      let max = 0;
      for (let i = 0; i < divs.length; i++) {
        let divEL = divs[i];
        max = Math.max(max, divEL.style.zIndex || 0);
      }
      // 如果打开了面容识别窗口
      if (this.$store.getters.faceSign) {
        let faceDialogZIndex = document.getElementsByClassName('popup_dialog_box_of_face')[0].style.zIndex;
        max = Math.max(max, faceDialogZIndex);
      }
      if (max <= 0) {
        max = 20;
      }
      return max;
    },
    // 把弹窗添加到指定元素上
    appendDialogToElement () {
      let curRouterElement = document.getElementById(this.$route.name);
      if (curRouterElement.getElementsByClassName('custom-dialog-factory')[0]) {
        curRouterElement.getElementsByClassName('custom-dialog-factory')[0].appendChild(this.$el);
      }
    },
    removeDialogFromElement () {
      let deleteZIndex = this.$el.querySelector('.popup_dialog_box').style.zIndex;
      let parentNode = this.$el.parentNode;
      let dialogList = parentNode.querySelectorAll('.popup_dialog');
      dialogList.forEach(el => {
        let zIndex = el.querySelector('.popup_dialog_box').style.zIndex;
        if (zIndex > deleteZIndex) {
          parentNode.removeChild(el);
        }
      });
    },
    handleClose () {
      this.removeDialogFromElement();
      if (typeof this.beforeClose === 'function') {
        this.beforeClose();
      }
      if (this.dialogParamList && this.dialogParamList.length > 0) {
        let visibleDialog = this.dialogParamList.find(obj => { return obj.moduleName == this.curModuleName; });
        if (visibleDialog) {
          this.$emit('beforeClose', this.curModuleName);
        }
      }
      this.hide();
    },
    hide () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (shadeDiv) {
        // 关闭时 把zIndex设置为零 下次打开重计算
        if (this.popupDialogBoxStyle) {
          this.popupDialogBoxStyle.zIndex = 0;
        }
        let maxZIndex = this.caculateMaxZIndex();
        this.$store.commit('SetPopupMaxZIndex', maxZIndex - 3);
      }
    },

    destroyed () {
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
};
</script>
<style scoped>
</style>