<template>
  <div class="courseware_autorelease_pool">
    <module-description :textTips="'只有开启手动释放的大课包可以进行释放。'"
                        :btnTips="''"
                        :btnUrl="''"></module-description>

    <div class="courseware_autorelease_pool_ul">
      <div class="courseware_autorelease_pool_list"
           v-for="(item,key) in bigCoursewareBagList"
           :key="key">
        <courseware-bag-info-left :dataInfo="item"
                                  @doAutoAutorelease="doAutoAutorelease"></courseware-bag-info-left>

        <courseware-bag-info-right :dataInfo="item"
                                   @afterSuccess="afterSuccess"
                                   @doCoursewarePackageForm="doCoursewarePackageForm"></courseware-bag-info-right>
      </div>
      <div v-if="bigCoursewareBagList.length==0"
           class="monitoring_content_nodata"
           style="margin: 180px 0;">
        <div class="monitoring_content_nodata_txt">暂无数据</div>
      </div>
      <div class="add_courseware_bag_btn"
           @click="openCoursewareBagListSelect">选择大课包转手动释放</div>
    </div>
    <custom-dialog :title="bigCoursewareItem.CoursewarePackageKey>0?'变更查看':'添加课包'"
                   :visible.sync="isShowCoursewareBagForm"
                   :before-close="closeCoursewareBagPopup">
      <courseware-bag-form :bigCoursewareInfo="bigCoursewareItem"
                           @afterSuccess="afterSuccess"
                           @close="closeCoursewareBagPopup"></courseware-bag-form>
    </custom-dialog>
    <div>
      <dialog-factory ref="dialogFactory"
                      :key="'dialogFactory'"
                      :routerName="'dialogFactory'"></dialog-factory>
      <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import coursewareBagInfoLeft from './courseware-bag-info-left';
import coursewareBagInfoRight from './courseware-bag-info-right/index';
import coursewareBagForm from './form/courseware-bag-form';
import { HQGetManualReleaseBigCoursewarePackage, HQModifyBigCoursewarePackageAutomaticRelease, HQGetBigCoursewarePackageList, HQAddBigCoursewarePackageManualRelease } from '../../../API/workbench';
import dialogFactory from '../../common/dialog-factory';
export default {
  components: {
    coursewareBagInfoLeft,
    coursewareBagInfoRight,
    coursewareBagForm,
    dialogFactory
  },
  data () {
    return {
      bigCoursewareBagList: [],
      bigCoursewareItem: { BigCoursewarePackageKey: '', CoursewarePackageKey: '', sourceKey: '' },
      isShowCoursewareBagForm: false
    };
  },
  created () {
    this.getManualReleaseBigCoursewarePackage();
    console.log(this.$router.getters);
  },
  mounted () {
    // this.registerBusEvent();
    // this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // loopData
  },
  methods: {
    // 获取手动释放的大课包List
    getManualReleaseBigCoursewarePackage () {
      HQGetManualReleaseBigCoursewarePackage().then(result => {
        console.log(result.data, '获取手动释放的大课包List');
        this.bigCoursewareBagList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    doCoursewarePackageForm (obj, type, item) {
      this.bigCoursewareItem.BigCoursewarePackageKey = (type == 'Edit' ? obj.BigCoursewarePackageKey : obj.OLAPKey);
      this.bigCoursewareItem.CoursewarePackageKey = (type == 'Edit' ? obj.CoursewarePackageKey : '');
      this.bigCoursewareItem.sourceKey = 1;// 来源，1-大课包; 2-目录;
      if (item) {
        this.bigCoursewareItem.sourceKey = 2;
        this.bigCoursewareItem.BigCoursewarePackageKey = item.CoursewarePackageKey;// 大课包Key/目录Key
      }
      this.isShowCoursewareBagForm = true;
    },
    closeCoursewareBagPopup () {
      this.isShowCoursewareBagForm = false;
    },
    // 大课包转自动释放
    doAutoAutorelease (item) {
      layer.confirm('请确认将该大课包转为自动释放？', {
        btn: [{
          name: '确认',
          callBack: () => {
            HQModifyBigCoursewarePackageAutomaticRelease(item.OLAPKey).then(result => {
              this.afterSuccess();
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }]
      });
    },
    // 编辑成功后的回调
    afterSuccess () {
      this.getManualReleaseBigCoursewarePackage();
    },
    // 打开大课包列表
    openCoursewareBagListSelect () {
      HQGetBigCoursewarePackageList().then(result => {
        console.log(result.data, '打开大课包列表');
        let obj = {
          name: '选择需要手动释放的大课包',
          routerName: this.$route.name,
          routertitle: '工作台',
          ModulePowerKey: '',
          moduleName: 'singleSelectionList',
          data: { selectedKey: '', dataSourceList: result.data, required: true },
          callBack: {
            onSelectedItem: (data) => {
              HQAddBigCoursewarePackageManualRelease(data.OLAPKey).then(result => {
                layer.alert('添加成功！');
                this.afterSuccess();
              }, error => {
                layer.alert(error.msg);
              });
              console.log(data, '大课包');
            }
          }
        };
        this.openCommonCustomDialogByFactoryDialog(obj);
      }, error => {
        layer.alert(error.msg);
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.dialogFactory) {
        this.$refs.dialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
    // registerBusEvent () {
    //   this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
    //     if (dialogInfo.routerName == 'dialogFactory') {
    //       this.openCustomDialogByFactoryDialog(dialogInfo);
    //     }
    //   });
    //   this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
    //     if (dialogInfo.routerName == 'dialogFactoryCommon') {
    //       this.openCommonCustomDialogByFactoryDialog(dialogInfo);
    //     }
    //   });
    // }
  }
};
</script>
<style scoped>
.courseware_autorelease_pool {
  width: 1290px;
  margin: 20px auto;
}
.courseware_autorelease_pool_ul {
  margin: 20px;
  width: 1252px;
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  background: #fff;
  padding: 20px;
}
.courseware_autorelease_pool_list {
  margin-bottom: 20px;
  display: flex;
  border: 1px solid #ececec;
  border-radius: 8px;
}
.add_courseware_bag_btn {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background: #f1f7ff;
  text-align: center;
  line-height: 46px;
  position: relative;
  cursor: pointer;
}
.add_courseware_bag_btn::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 505px;
  top: 12px;
  background: url(../../../../public/image/add_icon_blue.png) no-repeat 100%;
  background-size: 19px;
}
</style>
