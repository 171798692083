<template>

  <div class="couser_block_list new_couser_block_list clearfix pr"
       :class="'couser_'+item.OLAPKey">
    <function-btn :item="showArrangeBtn"
                  @clickItem="showArrange"
                  class="list_left"
                  :class="courseClass(item)"
                  :className="''">
      <div class="pr">
        <div class="list_title list_title_one">
          {{item.SClassTimeName}}-{{item.EClassTimeName}}
        </div>
        <div class="list_title list_title_two"
             :title="vMTeacherKeyValue">
          {{vMTeacherKeyValue}}
        </div>
        <div class="list_title list_title_three"
             :title="item.GradeClassKeyValue">
          {{item.GradeClassKeyValue}}
        </div>
        <div class="list_title list_title_four">
          排课{{Number(item.ArrangedNumCount)}}人
        </div>
        <div class="list_title_btn font_blue">
          详情>
        </div>
      </div>
    </function-btn>
    <div class="list_right"
         v-if="vShowAttenReport">
      <transition name="fade">
        <div class="couser_block_tips echart_content"
             style="font-size:12px">
          <div class="pr"
               style="padding-left:0px; flex:1">
            <circleEchart :echartID="'atten_'+item.OLAPKey"
                          :nowDataList="attenList"
                          ref="attenEchart"></circleEchart>
            <div class="center_tips">
              <div class="">出勤率</div>
              <div style="font-size: 16px;font-weight:500">{{vAttenrate+'%'}}</div>
            </div>
          </div>
          <div class="pr"
               style="padding-left:0px;flex:1">
            <circleEchart v-if="commentCheck"
                          :echartID="'comment_'+item.OLAPKey"
                          :nowDataList="commentList"
                          ref="commentEchart"></circleEchart>
            <div class="center_tips"
                 v-if="commentCheck">
              <div class="">课评率</div>
              <div style="font-size: 16px;font-weight:500">{{commentRate+'%'}}</div>
            </div>
          </div>

          <!-- <div class="">
              <div class="font_dual type_green">全部已打考勤</div>
              <div class="font_dual">
                <span> 课消{{vDeductCount}}人，</span>
                <span> 出勤率{{vAttenrate}}%，</span>
                <span> 签到{{vAtten3}}人</span>
                <span v-if="vAtten4">，请假<span class="font_red">{{vAtten4}}</span>人</span>
                <span v-if="vAtten5">，旷课<span class="font_red">{{vAtten5}}</span>人</span>
                <span v-if="commentCheck"> | 课评率{{commentRate}}%，已发{{Number(item.CommentStudentCount)||0}}人， 未发{{Number(item.UnCommentStudentCount)||0}}人</span>
              </div>
            </div> -->
        </div>
      </transition>
    </div>
    <!-- <div class="list_right"
          v-if="vShowAttenReport">
        <transition name="fade">
          <div class="couser_block_tips type_green"  style="font-size:12px">
            <div class="">
              <div class="font_dual type_green">全部已打考勤</div>
              <div class="font_dual">
                <span> 课消{{vDeductCount}}人，</span>
                <span> 出勤率{{vAttenrate}}%，</span>
                <span> 签到{{vAtten3}}人</span>
                <span v-if="vAtten4">，请假<span class="font_red">{{vAtten4}}</span>人</span>
                <span v-if="vAtten5">，旷课<span class="font_red">{{vAtten5}}</span>人</span>
                <span v-if="commentCheck"> | 课评率{{commentRate}}%，已发{{Number(item.CommentStudentCount)||0}}人， 未发{{Number(item.UnCommentStudentCount)||0}}人</span>
              </div>
            </div>
          </div>
        </transition>
      </div> -->
    <div class="list_right"
         v-else-if="vShowNoAttenReport">
      <transition name="fade">
        <div class="couser_block_tips echart_content"
             style="font-size:12px">
          <div class="pr"
               style="padding-left:0px; flex:1">
            <circleEchart :echartID="'noatten_'+item.OLAPKey"
                          :nowDataList="attenList"
                          :lineHeight="9"
                          ref="attenEchart"></circleEchart>
            <div class="center_tips">
              <div class="">出勤率</div>
              <div style="font-size: 16px;font-weight:500">{{vAttenrate+'%'}}</div>
            </div>
          </div>
          <div class="pr"
               style="padding-left:0px;flex:1">
          </div>

          <!-- <div class="">
              <div class="font_dual type_green">全部已打考勤</div>
              <div class="font_dual">
                <span> 课消{{vDeductCount}}人，</span>
                <span> 出勤率{{vAttenrate}}%，</span>
                <span> 签到{{vAtten3}}人</span>
                <span v-if="vAtten4">，请假<span class="font_red">{{vAtten4}}</span>人</span>
                <span v-if="vAtten5">，旷课<span class="font_red">{{vAtten5}}</span>人</span>
                <span v-if="commentCheck"> | 课评率{{commentRate}}%，已发{{Number(item.CommentStudentCount)||0}}人， 未发{{Number(item.UnCommentStudentCount)||0}}人</span>
              </div>
            </div> -->
        </div>
      </transition>
      <!-- <transition name="fade">
          <div class="couser_block_tips type_red"  style="font-size:12px">
            <div class="">
              <div class="font_dual type_red">已考勤{{Number(hasAttenCount)}}人，未考勤{{Number(noSignCount)}}</div>
              <div class="font_dual">
                <span> 课消{{vDeductCount}}人，</span>
                <span> 出勤率{{vAttenrate}}%，</span>
                <span> 签到{{vAtten3}}人</span>
                <span v-if="vAtten4">，请假<span class="font_red">{{vAtten4}}</span>人</span>
                <span v-if="vAtten5">，旷课<span class="font_red">{{vAtten5}}</span>人</span>

              </div>
            </div>
          </div>
        </transition> -->
    </div>

    <div class="list_right"
         style="font-size:12px"
         v-else-if="vshowFullorNoAtten">
      <transition name="fade"
                  v-if="vArrangedNumCount==0">
        <div class="couser_block_tips type_red">
          <div>未安排学生</div>
        </div>
      </transition>
      <transition name="fade"
                  v-else>
        <div class="couser_block_tips type_green">
          <div class="type_green">满勤</div>
        </div>
      </transition>
    </div>

    <div class="list_right"
         v-else>

      <div class="list_student_box"
           :class="item.StatusKey == 4&&item.studentList.length>0?'all_couser':''">

        <student-item v-for="stuItem in vStudentList"
                      :key="stuItem.StudentKey"
                      :stuItem="stuItem"
                      @seletedStuAfter="seletedStuAfter">
        </student-item>

        <!-- 批量考勤 -->
        <div class="all_couser_btn btn_hover_white"
             style="font-size:12px"
             v-if="!commentCheck&&item.StatusKey == 4&&noSignCount>0"
             @click.stop="changeIsShowDialog">
          批量考勤
          <div class="module_content_menuList"
               style="width:100%;top:30px;left:0"
               v-show="isShowDialog">
            <div class="top_bg"
                 style=""></div>
            <drop-down-box :optionJsonList="attendanceStatus"
                           @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
          </div>
        </div>

      </div>

    </div>
    <!-- <div class="comment_content" v-if="commentCheck">
          <span  class="font_gray">课评 </span>
          <span class="font_gray">已发
            <span class="font_black">{{Number(item.CommentStudentCount)||0}}人</span>
          </span>
          <span class="font_gray" v-if="Number(item.UnCommentStudentCount)>0">/未发<span class="font_black">{{Number(item.UnCommentStudentCount)||0}}人</span></span>
        
         
        </div> -->

    <!-- 显示加载的进度条 -->
    <custom-dialog class="progress_max progress"
                   width="300px"
                   :visible.sync="showProgress">
      <div class="progress_title">
        正在批量考勤 ，{{runCount}}/{{totolPeoCount}}。
      </div>
      <div class="progress_box">
        <el-progress type="circle"
                     :percentage="progressStatus"></el-progress>
      </div>
      <!-- <div class="progress_exit"></div> -->
    </custom-dialog>
  </div>

</template> 
<script>
import dropDownBox from '../common/drop-down-box';
import {
  getScheduleCourseOnStudentList, setAttendance, kqChange, CancelArrangeForNoAttendByCourse, kqLeave, kqTruancy, BatchSyncSubmit, KQBatchTruancy, KQBatchLeave, KQBatchDelete,
  mainTeakqLeave, mainTeakqTruancy, GetMainTeaScheduleCourseStudentList, mainTeasetAttendance, mainTeakqChange
} from '../../API/workbench';
import studentItem from './student-item';
import circleEchart from './components/sign-circle-echart'
export default {
  data () {
    return {
      attenList: [],
      attenEchartColor: ['#32D77E', '#FEB564', '#F85A59', '#DCDEE1'],
      commentList: [],
      commentEchartColor: ['#206CFF', '#B4D5FF'],
      isShowNoAtten: true,
      isShowMTeaName: false,
      showArrangeBtn: {
        'name': '',
        'eventName': '',
        'ModulePowerKey': 0// 51
      },
      isChg: false,
      isShowDialog: false,
      attendanceStatus: [
        {
          type: this.$initJson.attendanceType.view,
          name: '全部请假',
          eventName: 'allLeave',
          className: '',
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.view,
          name: '全部旷课',
          eventName: 'allTruant',
          className: '',
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.view,
          name: '全部退课',
          eventName: 'allQuit',
          className: '',
          isShow: true
        }
      ],
      totolPeoCount: 0,
      runCount: 0, // 进度条.
      showProgress: false,
      batchAttencePercentage: 0,
      isBatchAttendanceSucces: false // 判断批量考勤是否成功过

    };
  },
  props: ['item', 'textSearch', 'commentCheck'],
  components: {
    studentItem, dropDownBox, circleEchart
  },
  created () {
    // let websocketReadyState = this.$store.getters.getWebsocketReadyState;
    // if (websocketReadyState == 1) {              //获取学生列表接口无关长连接.
    console.log('this.item.ClassTime', this.item.ClassTime,)
    if (this.isTodayafter) {
      this.getStudentListInCourse(this.item.OLAPKey);
    }
    // }

    this.$bus.on(this, 'AfterStudentUpdate', (studentKey, studentData) => {
      if (studentKey) {
        let studentInfo = this.item.studentList.find(obj => { return obj.StudentKey == studentKey; });
        if (studentInfo && studentData && studentData.MainDemoName) {
          studentInfo.StudentKeyValue = studentData.MainDemoName;
        }
      }
    });
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      e => {
        this.isShowDialog = false;
      },
      true
    );
    if (this.vShowAttenReport) {
      this.initAttenChartData(this.item)
      if (this.commentCheck) {
        this.initCommentChartData(this.item)
      }
    } else if (this.vShowNoAttenReport) {
      this.initAttenChartData(this.item)
    }
  },
  computed: {
    commentRate () {
      let total = Number(this.item.CommentStudentCount + this.item.UnCommentStudentCount)

      return total > 0 ? Math.ceil(Number(this.item.CommentStudentCount) / total * 100) : 0

    },
    // 显示全部已考勤
    vShowAttenReport () {
      if (!this.isTodayafter) {
        return this.vAtten
      } else {
        return this.vAtten
      }
    },
    // 显示有未考勤的。
    vShowNoAttenReport () {
      if (!this.isTodayafter) {
        if (this.vArrangedNumCount > 0 && this.noSignCount > 0) {
          return true
        } else {
          return false
        }
      } else {
        return this.vAtten
      }
    },
    vshowFullorNoAtten () {
      if (!this.isTodayafter) {
        return true
      } else {
        return this.vArrangedNumCount == 0 || this.vArrivedNumCount == this.vArrangedNumCount
      }
    },

    isTodayafter () {
      return Date.new(this.item.ClassTime) >= Date.new(this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd'))
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vStudentList () {
      let newArr = [];
      this.item.studentList.forEach(o => {
        this.$set(o, 'isOpt', false);
        if (this.textSearch) {
          if (o.StudentKeyValue.indexOf(this.textSearch) != -1 ||
            o.FirstLetterName.toLowerCase().indexOf(this.textSearch.toLowerCase()) != -1 ||
            o.CustomerPhoneName.indexOf(this.textSearch) != -1) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
        } else {
          o.isActive = false;
        }
        if (this.isShowNoAtten) {       // 显示未考勤.
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {
            newArr.push(o);
          }
        } else {              // 显示已签到.
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {

          } else {
            newArr.push(o);
          }
        }
      });
      return newArr;
    },
    vStudentKeyList () {
      let newArr = [];
      this.vStudentList.forEach(o => {
        newArr.push(o.StudentKey);
      });
      return newArr;
    },
    noAttenCount () {     // 未签到人数
      let count = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey != 3) {
            count++;
          }
        });
      } else {
        count = Number(this.item.NoAttenceCount)
      }
      return count;
    },
    noSignCount () {     // 未考勤人数
      let count = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(o => {
          if (!o.AttenceStatusKey || o.AttenceStatusKey == 2) {
            count++;
          }
        });
      } else {
        count = Number(this.item.NoAttenceCount)
      }
      return count;
    },
    hasAttenCount () {    // 已出勤人数.包括请假旷课
      let count = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {

          } else {
            count++;
          }
        });
      } else {
        count = Number(this.item.AttenceCount)
      }
      return count;
    },
    isFullAtten () {
      if (this.vArrivedNumCount > 0 && this.vArrivedNumCount == this.item.studentList.length) {
        return true;
      } else {
        return false;
      }
    },
    vAtten () {       // 显示全部已打考勤
      if (this.vArrangedNumCount > 0 && this.vArrangedNumCount == this.hasAttenCount) {
        return true;
      } else {
        return false;
      }
    },
    // 出勤率
    vAttenrate () {
      let count = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 3) {
            count++;
          }
        });
      } else {
        count = Number(this.item.ArrivedNumCount)
      }
      return parseInt((count / Number(this.item.ArrangedNumCount) * 100));
    },
    // 课消
    vDeductCount () {
      let count = Number(this.item.DeductCount);
      // if (this.item.studentList) {
      //   this.item.studentList.forEach(o => {
      //     count+=Number(o.DeductCount)
      //   });
      // }
      return count;
    },

    vAtten3 () {      // 出勤人数
      let count = 0;
      if (this.item.studentList > 0) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 3) {
            count++;
          }
        });
      } else {
        count = Number(this.item.ArrivedNumCount)
      }
      return count;
    },
    vAtten4 () {      // 请假人数
      let count = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 4) {
            count++;
          }
        });
      } else {
        count = Number(this.item.LeaveNum)
      }
      return count;
    },
    vAtten5 () {      // 旷课人数
      let count = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 5) {
            count++;
          }
        });
      } else {
        count = Number(this.item.TruantNum)
      }
      return count;
    },
    //  进度条 百分比.
    progressStatus () {
      var percent = Math.floor((this.runCount / this.totolPeoCount).toFixed(2) * 100);
      return percent || 0;
    },

    // 背景图片颜色
    vFullAttenBGClass () {
      if (this.hasAttenCount == 0) {
        return 'state_gray';
      } else {
        return 'state_green';
      }
    },
    // 箭头颜色图片
    vFullAttenClass () {
      if (this.vArrangedNumCount == 0) {
        return 'mirror right_gray';
      } else if (this.vArrivedNumCount == this.item.studentList.length) {
        return 'mirror right';
      } else {
        return 'left';
      }
    },
    SaaSClientInfo () {  // 门店信息   最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    vMTeacherKeyValue () {
      if (this.item) {
        this.item.MTeacherKeyValue = this.item.MTeacherKeyValue || '';
        if (this.isShowMTeaName) {
          return this.item.MTeacherKeyValue;
        } else {
          if (this.item.MTeacherKeyValue.indexOf(',') == -1) {
            return this.item.MTeacherKeyValue;
          } else {
            return this.item.MTeacherKeyValue.substring(0, this.item.MTeacherKeyValue.indexOf(',')) + '...';
          }
        }
      } else {
        return '';
      }
    },
    // 	--签到人数
    vArrivedNumCount () {
      let num = 0;
      if (this.item.studentList.length > 0) {
        this.item.studentList.forEach(obj => {
          if (obj.AttenceStatusKey == 3) {
            num += 1;
          }
        });
      } else {
        num = Number(this.item.ArrivedNumCount)
      }
      return num;
    },
    // 安排人数
    vArrangedNumCount () {
      return this.item.studentList.length > 0 ? this.item.studentList.length : Number(this.item.ArrangedNumCount);
    }
  },
  methods: {
    // 设置考勤人数
    initAttenChartData (data) {
      console.log('initData', data)
      this.attenList = [];
      let obj1 = {
        value: Number(this.vAtten3),
        name: '签到 ' + Number(this.vAtten3) + '人',
        id: '',
        itemStyle: {
          color: this.attenEchartColor[0]
        }
      };
      let obj2 = {
        value: Number(this.vAtten4),
        name: '请假 ' + Number(this.vAtten4) + '人',
        id: '',
        itemStyle: {
          color: this.attenEchartColor[1]
        }
      };
      let obj3 = {
        value: Number(this.vAtten5),
        name: '旷课 ' + Number(this.vAtten5) + '人',
        id: '',
        itemStyle: {
          color: this.attenEchartColor[2]
        }
      };
      let obj4 = {
        value: Number(this.noSignCount),
        name: '未考勤 ' + Number(this.noSignCount) + '人',
        id: '',
        itemStyle: {
          color: this.attenEchartColor[3]
        }
      };

      this.attenList.push(obj1);
      this.attenList.push(obj2);
      this.attenList.push(obj3);
      if (!this.vShowAttenReport) {
        this.attenList.push(obj4);
      }
      console.log(this.attenList, '出勤list');
      this.$nextTick(() => {
        this.$refs.attenEchart.echartInit();
      });
    },
    // 设置课消余额金额
    initCommentChartData (data) {
      console.log('initData', data)
      this.commentList = [];
      let obj1 = {
        value: Number(this.item.CommentStudentCount),
        name: '已课评 ' + Number(this.item.CommentStudentCount) + '人',
        id: '',
        itemStyle: {
          color: this.commentEchartColor[0]
        }
      };
      let obj2 = {
        value: Number(this.item.UnCommentStudentCount),
        name: '未课评 ' + Number(this.item.UnCommentStudentCount) + '人',
        id: '',
        itemStyle: {
          color: this.commentEchartColor[1]
        }
      };

      this.commentList.push(obj1);
      this.commentList.push(obj2);
      console.log(this.commentList, '出勤list');
      this.$nextTick(() => {
        this.$refs.commentEchart.echartInit();
      });
    },
    setColor (item) {

    },
    attenClass (item) {
      if (item.AttenceStatusKey == 3) {
        return 'list_stundent_type';
      } else if (item.AttenceStatusKey == 4) {
        return 'list_stundent_type type_yellow';
      } else if (item.AttenceStatusKey == 5) {
        return 'list_stundent_type type_red';
      } else {
        return '';
      }
    },
    attenVal (item) {
      if (item.AttenceStatusKey == 4) {
        return '假';
      } else if (item.AttenceStatusKey == 5) {
        return '旷';
      } else {
        return '';
      }
    },
    courseClass (data) {
      let c = '';
      c += data.StyleName + '_color';
      if (data.StatusKey != 2 && data.NoAttendanceCount > 0) {
        c += ' warning_icon';
      }
      return c;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    setStatusKeyValue (item) {
      let timer = item.ClassTime.replace(/-/g, '/') + ' ' + item.SClassTimeName;        // 开始时间
      let seconds = Number(Date.new(timer).getTime() / 1000) - Number(this.$utils.getSaaSClientSetInfo(31).SetContent) * 60;  // 获取当前数据的时间
      if (item.StatusKey == 4) {
        return '已上完';
      } else if (item.StatusKey == 3) {
        return '上课中';
      } else if (item.StatusKey == 2 && seconds < (Date.new().getTime()) / 1000) {
        return '可签到';
      } else {
        return '未开始';
      }
    },
    showArrange () {
      this.$emit('attendanceArrange', this.item, this.getStudentListInCourse);
    },
    getStudentListInCourse (OLAPKey, callBack) {
      // GetMainTeaScheduleCourseStudentList
      console.log("this.$route.name", this.$route.name, this.$route.name == 'indexView')
      let api = this.$route.name == 'indexView' ? getScheduleCourseOnStudentList : GetMainTeaScheduleCourseStudentList
      api(OLAPKey).then(result => {
        result.data.forEach(o => {
          o.isOpt = false;
          o.isActive = false;
        });
        this.item.studentList = result.data;
        if (callBack) {
          callBack(result.data);
        }
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    seletedStuAfter (stuItem) {
      if (!this.isChg) {
        this.isChg = true;
        if (this.vHasModulePower(this.showArrangeBtn.ModulePowerKey)) {
          if (this.isShowNoAtten) {
            stuItem.isOpt = true;
            this.setAttendanceByStu(stuItem, 0);
          } else {
            this.changeAttendanceStatus(2, stuItem);
          }
        } else {
          this.$utils.CheckModulePowerToTips(this.showArrangeBtn.ModulePowerKey);
        }
      }
    },
    // 签到接口
    setAttendanceByStu (stuItem, IsClassHourLackKey) {
      // mainTeasetAttendance
      let api = this.$route.name == 'indexView' ? setAttendance : mainTeasetAttendance
      api(stuItem.StudentKey,
        stuItem.ScheduleCourseKey,
        IsClassHourLackKey
      ).then(result => {
        this.isChg = false;
        // stuItem.isOpt = true;
        // this.$nextTick(() => {
        stuItem.AttenceStatusKey = 3;
        stuItem.AttenceStatusKeyValue = '签到';
        if (IsClassHourLackKey == 1) {
          stuItem.ISClassHourDebtKey = 1;
          stuItem.ISClassHourDebtKeyValue = '欠课时';
        }
        stuItem.ClassHourKey = result.data.ClassHourKey;     // 消费状态. 5为消费.
        this.item.ArrivedNumCount = this.vArrivedNumCount + 1;        // 签到人数+1
        this.item.NoAttenceCount = Number(this.item.NoAttenceCount) - 1 < 0 ? 0 : Number(this.item.NoAttenceCount) - 1;      // 未签到人数-1
        // });
        // layer.alert(stuItem.StudentKeyValue + '签到成功');
        this.$nextTick(o => {
          if (this.vShowAttenReport) {
            this.initAttenChartData(this.item)
            if (this.commentCheck) {
              this.initCommentChartData(this.item)
            }
          } else if (this.vShowNoAttenReport) {
            this.initAttenChartData(this.item)
          }
        })
      }, err => {
        let that = this;
        if (err.code == 60001) {
          that.isChg = false;
          layer.confirm(stuItem.StudentKeyValue + '课时不足，是否以欠课时的方式继续签到？', {
            shadeClose: false,
            btn: [{
              name: '取消',
              callBack: function () {
                stuItem.isOpt = false;
                that.isSaving = false;
                layer.close();
              }
            }, {
              name: '确认',
              callBack: function () {
                that.setAttendanceByStu(stuItem, 1);
                layer.close();
              }
            }]
          });
        } else if (err.code == 60008 || err.code == 60009) {
          let tips = '';
          if (err.code == 60008) {
            tips = '学生考勤状态不符，请确认考勤状态后再考勤';
          } else {
            tips = '学生安排不符，请确认安排状态后再考勤';
          }
          layer.alert(tips);
          this.getStudentListInCourse(this.item.OLAPKey);
        } else {
          if (err.code == 10000) {
            stuItem.isOpt = false;
          }
          this.isChg = false;
          layer.alert(err.msg);
        }
      });
    },
    // 改变考勤接口.
    // 变更考勤（已考勤的变更成另一种状态）
    changeAttendanceStatus (type, stuItem) {
      // mainTeakqChange
      let api = this.$route.name == 'indexView' ? kqChange : mainTeakqChange
      api(stuItem.StudentKey,
        stuItem.ScheduleCourseKey,
        stuItem.IsClassHourLackKey,
        type, stuItem.StudentArrangeKey
      ).then(result => {
        if (result.code === 0) {
          this.isChg = false;
          if (type == 2) {        // 2为未考勤.
            stuItem.WKQRS += 1;
            stuItem.AttenceStatusKey = 2;
            stuItem.AttenceStatusKeyValue = '未考勤';
            stuItem.ISClassHourDebtKey = 0;
            stuItem.ISClassHourDebtKeyValue = '否';
            this.item.NoAttenceCount = Number(this.item.NoAttenceCount) + 1;      // 未签到人数+1
          }
        }
        this.$nextTick(o => {
          if (this.vShowAttenReport) {
            this.initAttenChartData(this.item)
            if (this.commentCheck) {
              this.initCommentChartData(this.item)
            }
          } else if (this.vShowNoAttenReport) {
            this.initAttenChartData(this.item)
          }
        })
        layer.alert(stuItem.StudentKeyValue + '设置未考勤成功');
      }, error => {
        this.isChg = false;
        layer.alert(error.msg);
      });
    },
    allQuit (item) {
      layer.confirm('请问是否全部退课?', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelArrangeForNoAttendByCourse(this, item.OLAPKey).then(result => {
              // layer.alert('全部退课成功');
              this.getStudentListInCourse(item.OLAPKey, () => {
                this.$nextTick(o => {
                  if (this.vShowAttenReport) {
                    this.initAttenChartData(this.item)
                    if (this.commentCheck) {
                      this.initCommentChartData(this.item)
                    }
                  } else if (this.vShowNoAttenReport) {
                    this.initAttenChartData(this.item)
                  }
                })
              });
              this.$bus.emit('todayQuitAll', item.OLAPKey);
              console.log(this.item.studentList, 'CancelArrangeForNoAttendByCourse');
            }).catch(error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    // 全部旷课
    allTruant (item) {
      console.log('旷课', this.vStudentList);
      layer.confirm('请问是否全部旷课?', {
        btn: [{
          name: '确认',
          callBack: () => {
            this.batchTruancy(this.vStudentKeyList, this.vStudentList);
            // this.changeBatchAttendance(this.vStudentList, 0, 2);
            layer.close();
          }
        }
        ]
      });
    },
    // 全部请假
    allLeave (item) {
      console.log('请假', this.vStudentList);
      layer.confirm('请问是否全部请假?', {
        btn: [{
          name: '确认',
          callBack: () => {
            this.batchLeave(this.vStudentKeyList, this.vStudentList);
            // this.changeBatchAttendance(this.vStudentList, 0, 1);
            layer.close();
          }
        }
        ]
      });
    },
    // 批量签到接口
    // 批量签到考勤
    batchAttendance (studentKeyList, selectionStudentList) {
      console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.runCount = 0;
      this.batchAttencePercentage = 0;
      this.totolPeoCount = studentKeyList.length; // 百分比弹出层总人数
      studentKeyList = {
        StudentKeyList: JSON.stringify(studentKeyList)
      };
      this.$api.KQBatchAttendance(studentKeyList, this.item.OLAPKey).then(result => {
        console.log('新批量签到', result.data);
        this.commonAsyncTask(result, selectionStudentList);
      }, error => {
        layer.alert(error.msg);
        // this.$store.dispatch('popup/open', { content: error.msg });
        this.cancelBantchAttren();
      });
    },
    // 批量旷课
    batchTruancy (studentKeyList, selectionStudentList) {
      console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.runCount = 0;
      this.batchAttencePercentage = 0;
      this.totolPeoCount = studentKeyList.length; // 百分比弹出层总人数
      studentKeyList = {
        StudentKeyList: JSON.stringify(studentKeyList)
      };
      KQBatchTruancy(studentKeyList, this.item.OLAPKey).then(result => {
        console.log('新批量旷课', result.data);
        this.commonAsyncTask(result, selectionStudentList);
      }, error => {
        layer.alert(error.msg);
        // this.$store.dispatch('popup/open', { content: error.msg });
        this.cancelBantchAttren();
      });
    },
    // 批量请假
    batchLeave (studentKeyList, selectionStudentList) {
      console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.runCount = 0;
      this.batchAttencePercentage = 0;
      this.totolPeoCount = studentKeyList.length; // 百分比弹出层总人数
      studentKeyList = {
        StudentKeyList: JSON.stringify(studentKeyList)
      };
      KQBatchLeave(studentKeyList, this.item.OLAPKey).then(result => {
        console.log('新批量请假', result.data);
        this.commonAsyncTask(result, selectionStudentList);
      }, error => {
        layer.alert(error.msg);
        // this.$store.dispatch('popup/open', { content: error.msg });
        this.cancelBantchAttren();
      });
    },
    batchDelete (studentKeyList, selectionStudentList) {
      var StudentKeyList = JSON.stringify(studentKeyList);
      KQBatchDelete(StudentKeyList, this.item.OLAPKey).then(result => {
        this.$emit('changeAttendanceStutus', this.item.OLAPKey, '', '', () => {
          this.getStudentListInCourse(this.item.OLAPKey);
        });

        this.cancelBatchAttendance();
        console.log('批量退课', result.data);
      }, error => {
        layer.alert(error.msg);
        // this.$store.dispatch('popup/open', { content: error.msg });
        this.cancelBantchAttren();
      });
    },
    // 取消批量考勤
    cancelBantchAttren () {
      // this.item.studentList.forEach(o => {
      //   o.isActive = false;
      // });

      // this.bantchType = '';
    },
    // 异步队列
    commonAsyncTask (result, selectionStudentList) {
      this.showProgress = true;
      this.$asyncTask.register(result.data.AsynTaskID, (sendData) => {
        console.log('新批量考勤长连接通知:', sendData);
        if (sendData.nodeType == 1 && sendData.status == 1) { // nodeType =1循环节点
          if (sendData.number > this.runCount) { // 返回的顺序未必按0~n递增方式 ，存在网络传输的快慢问题，所以后面的通知可能会比前面的通知先接收到
            this.runCount = sendData.number; // 进度条.
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          }
          const studentItem = selectionStudentList.find(obj => {
            return obj.StudentKey == sendData.DataKey;
          });
          Object.assign(studentItem, sendData.remark);
        } else if (sendData.nodeType == 0) { // 结束节点
          this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          this.showProgress = false;
          this.$asyncTask.delete(sendData.AsynTaskID);
          this.cancelBatchAttendance();
          console.log('结束了');
          this.$emit('changeAttendanceStutus', this.item.OLAPKey);
        } else if (sendData.status == 0) { // status：1成功 0失败
          console.log('批量考勤某个节点失败-》sendData:', sendData);
        }
        this.cancelBantchAttren();
      });
    },
    // 批量考勤
    changeBatchAttendance (dataList, IsClassHourLackKey, type) {
      this.totolPeoCount = dataList.length;
      this.showProgress = true;
      let APIPromiseCreator = obj => {
        if (obj.IsClassHourLackKey == 1) {
          IsClassHourLackKey = 1;
        }
        if (type == 1) {
          // mainTeakqLeave
          let api = this.$route.name == 'indexView' ? kqLeave : mainTeakqLeave
          return api(obj.StudentKey, obj.ScheduleCourseKey, IsClassHourLackKey);
        } else {
          // mainTeakqTruancy
          let api = this.$route.name == 'indexView' ? kqTruancy : mainTeakqTruancy
          return api(obj.StudentKey, obj.ScheduleCourseKey, IsClassHourLackKey);
        }
      };
      let resolve = (r, obj) => {
        this.runCount++;
        Object.assign(obj, r.data);
        // 已签到的 课时状态未已消费
        if (obj.AttenceStatusKey == 3) {
          obj.ClassHourKey = 5;
        }
        this.isBatchAttendanceSucces = true;
      };
      let reject = (r, obj) => {
        this.runCount++;
      };
      let resultCallback = resultList => {
        let isLackOfClassTime = []; // 课时不足的数组.
        let isLackOfClassTimeTS = ''; // 课时不足时提示.
        let otherJecCode = []; // 其他错误时的数组
        let otherPeopleTS = ''; // 其他错误时提示.
        let otherErrorTS = []; // 其他错误时提示.
        resultList.forEach(o => {
          if (o.r.code == 60001) {
            isLackOfClassTime.push(o.data);
            isLackOfClassTimeTS = isLackOfClassTimeTS == '' ? o.data.StudentKeyValue : isLackOfClassTimeTS + ',' + o.data.StudentKeyValue;
          } else if (o.r.code != 0) {
            otherJecCode.push(o.data);
            otherErrorTS.push(o.r);
            otherPeopleTS = otherPeopleTS == '' ? o.data.StudentKeyValue : otherPeopleTS + ',' + o.data.StudentKeyValue;
          }
        });
        if (isLackOfClassTime.length > 0) {
          let that = this;
          layer.confirm(isLackOfClassTimeTS + '课时不足，是否以欠课时的方式继续操作？', {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: function () {
                  layer.close();
                  that.runCount = 0;
                  that.cancelBatchAttendance();
                }
              },
              {
                name: '确认',
                callBack: function () {
                  that.runCount = 0;
                  that.changeBatchAttendance(isLackOfClassTime, 1, type);
                  layer.close();
                }
              }
            ]
          });
        } else {
          this.runCount = 0;
          if (otherJecCode.length > 0) {
            layer.alert(otherErrorTS[0].msg);
          }
          this.cancelBatchAttendance();
        }
      };
      BatchSyncSubmit(
        dataList,
        APIPromiseCreator,
        resultCallback,
        resolve,
        reject
      );
    },
    // 关闭批量考勤
    cancelBatchAttendance () {
      this.showProgress = false;
      this.getStudentListInCourse(this.item.OLAPKey, () => {
        this.$nextTick(o => {
          if (this.vShowAttenReport) {
            this.initAttenChartData(this.item)
            if (this.commentCheck) {
              this.initCommentChartData(this.item)
            }
          } else if (this.vShowNoAttenReport) {
            this.initAttenChartData(this.item)
          }
        })
      });
      this.$bus.emit('todayQuitAll', this.item.OLAPKey);
      // this.$emit('cancelBatchAttendance');
    },
    // 显示下拉框
    changeIsShowDialog () {
      this.isShowDialog = true;
    },
    // 按钮操作
    doAfterExceptBtn (item) {
      console.log(item, 'doAfterExceptBtn');
      switch (item.eventName) {
        case 'allLeave':
          this.allLeave(this.item);
          break;
        case 'allTruant':
          this.allTruant(this.item);
          break;
        case 'allQuit':
          this.allQuit(this.item);
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped>
.couser_block_list {
  font-size: 12px !important;
}
.couser_block_list .list_left {
  width: 155px !important;
  min-height: 88px !important;
  top: 0px;
}
.couser_block_list .list_right {
  min-height: 72px !important;
  padding-left: 150px !important;
}
.new_couser_block_list .list_title {
  line-height: 14px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  padding-left: 22px !important;
  padding-right: 10px !important;
  width: 100% !important;
  margin-top: 5px !important;
  color: #333 !important;
  font-size: 14px !important;
  position: relative !important;
}
.list_title_one::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../public/image/list_title_one.png') no-repeat center;
  background-size: 11px;
}
.list_title_two::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../public/image/list_title_two.png') no-repeat center;
  background-size: 11px;
}
.list_title_three::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../public/image/list_title_three.png') no-repeat center;
  background-size: 11px;
}
.list_title_four::before {
  display: block;
  position: absolute;
  left: 6px;
  content: '';
  width: 12px;
  height: 12px;
  background: url('../../../public/image/list_title_four.png') no-repeat center;
  background-size: 11px;
}
.list_title_btn {
  font-size: 14px;
  position: absolute;
  right: 4px;
  top: 0px;
}
.comment_content {
  font-size: 12px;
  width: 150px;
  border: none;
  position: absolute;
  right: 0;
  height: 100%;
  margin: auto;
  background: white;
  z-index: 5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  border-left: 1px solid #ececec;
}
.couser_block_tips.echart_content {
  height: 88px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  margin: 0 auto;
}
.couser_block_tips >>> .echarts_m {
  padding-left: 0 !important;
  height: 88px;
}
.couser_block_tips.echart_content .center_tips {
  position: absolute;
  height: 34px;
  top: 26px;
  left: 40px;
  width: 50px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  font-family: Arial;
}

.couser_block_tips.type_red {
  background: none !important;
}
</style>
