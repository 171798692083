<template>
  <div class="package_complex_box pr">
    <div class="box_name">
      <span>{{item.ApplyCorName}}</span>
      <!-- <div class="package_list_name_label"
           v-if="vDiscount">{{vDiscount}}</div> -->
    </div>
    <div class="box_text">
      {{vType}}
    </div>
    <span class="package_type">{{vDrainageKeyValue}}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  computed: {
    vDrainageKeyValue () {
      if (this.item.IsDrainageKey == '1' || this.item.ApplyTypeKey == '4') {
        return '引流';
      } else if (Number(this.item.AfterDisAmount) == 0) {
        return '赠送';
      } else {
        return '正课';
      }
    },
    vDiscount () {
      let str = '';
      if (Number(this.item.AfterDisAmount) == 0) {
        str = '赠送';
      }
      // else if (Number(this.item.AfterDisAmount) < Number(this.item.UnitPrice)) {
      //   str = this.$utils.mMoneyType(Number(this.item.AfterDisAmount) / Number(this.item.UnitPrice), 1) + '折';
      // } else {
      //   str = '';
      // }
      return str;
    },
    vType () {
      let str = '';
      // 课时卡
      if (this.item.UnitKey == 5) {
        str = Number(this.item.UnitPrice) + '元×' + Number(this.item.NumCount) + '课时';
      } else {
        str = this.item.UnitKeyValue + '卡 | 有效期：' + Number(this.item.NumCount) + (this.item.UnitKey == 3 ? '个' : '') + this.item.UnitKeyValue;
      }
      return str;
    }
  }
};
</script>

<style scoped>
.package_type {
  position: absolute;
  top: 15px;
  right: 17px;
  font-size: 12px;
  color: #999;
}
</style>