<template>
 <div class="hover_show" >
    <span class="list_time">{{item.addTime||item.add_time}}</span>
    <el-tooltip :disabled="!item.telephone" :content="$utils.phoneModulePower(item.telephone)" placement="bottom" effect="light">
      <span class="list_information">{{item.username}}</span>
    </el-tooltip>
    <span class="list_transmit">转发<span class="font_red">{{item.share_number}}</span>次</span>
    <!-- <span class="hover_block" v-show="isShow">{{item.telephone}}</span> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      // isShow: false
    };
  },
  props: {
    item: Object
  }
};
</script>
<style>
  .hover_block{
    display: inline-block;
    position: absolute;
    left: 25%;


  }
</style>