<template>
  <div class=""
       style="position: relative;width: 100%;">
    <div class="pr"
         style="background: white">
      <div class="summarizing_number_left">
        <span>循环课表</span>
        <span class="font_number">({{courseTableList.length}})</span>
      </div>
      <div class="btn_hover_bg_blue right_course_table_title_btn "
           @click.stop="newCourseTable">
        新建课表
      </div>
    </div>
    <circle-course-table :classNameKey="classNameKey"
                         @openCustomDialog="openCustomDialog"
                         @structedRuleData="structedRuleData"></circle-course-table>
  </div>
</template>

<script>
import circleCourseTable from '../../../relation-course-component/circle-course-table/index';

export default {
  data () {
    return {
      courseTableList: [], // 循环课表
      isShowBatchEditCourseDialog: false,
      editCourseParamObj: {},
      isShowItem: false,
      curSelectedCourseInfo: {}
    };
  },
  props: {
    classNameKey: String,
    className: String
  },
  components: {
    circleCourseTable
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    // 新建课表
    newCourseTable () {
      let item = {
        routerName: 'classGradeApplycourse',
        routertitle: '班级排课',
        moduleName: 'newCircleCourseTable',
        data: { dataInfo: { BJPKIDKey: this.classNameKey, BJPKIDKeyValue: this.className } }
      };
      this.$emit('openCustomDialog', item);
    },
    structedRuleData (data) {
      console.log(data, 'structedRuleData');
      this.courseTableList = data;
      this.$emit('updateRuleCourseBlock');
    },
    openCustomDialog (item) {
      this.$emit('openCustomDialog', item);
    }

  }
};
</script>

<style>
</style>