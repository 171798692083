<template>
    <div>
        <activity-report :typeKey="1" :routerName="'ladderGroupEnrollment'"></activity-report>
    </div>
</template>
<script>
import activityReport from '../activity-report/index'
export default {
    data(){
        return{

        }
    },
    components:{
        activityReport
    },
    beforeCreate(){
        this.$route.query.TypeKey=4
        console.log("beforeCreate", this.$route.query.TypeKey)
    }
}
</script>