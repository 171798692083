<template>
  <div>
    <div class="payment_statistics_bottom clearfix">
      <div class="payment_statistics_bottom_select flex">
        <seleted-option :searchObj="searchObj"
                        :IsMTeaKey="IsMTeaKey"
                        @changeSearchObj="updateData"></seleted-option>
      </div>
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isShowHistory"
                    :fromTitle="'选择日期'"
                    @changeDate="changeSaveDate"></date-range>
        <div class="change_history_data"
             @click.stop="historyBtn(historyName)">{{ historyName }}</div>
        <div class="width_small course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>

          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin-right:20px;width:auto;padding: 0 10px;"
                 @click.stop="openAttenDetail"
                 v-if="IsMTeaKey==1">课消明细</div>
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>

        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import debtCourseHourseDetails from '../../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../../common/table-list/index';
import seletedOption from './seleted-option';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { MessageSearchTeachingPerformanceForCourse, MessageSearchHistoryTeachingPerformanceForCourse, getStudentRelatedMsg } from '../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        GradeKey: 0,
        GradeKeyValue: '',
        CourseKey: 0,
        CourseKeyValue: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        // this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate() - 1)))),
        IsExportKey: 0,
        IsMTeaKey: 1
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '上课时间',
          prop: 'ClassTime',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '老师',
          prop: 'TeacherKeyValue',
          minWidth: 104,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },

        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          minWidth: 160,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          minWidth: 142,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '老师课时',
          prop: 'CourseHourCount',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'class-hour'
        },
        {
          label: '学生课时',
          prop: 'StudentHourCount',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'class-hour'
        },
        {
          label: '签到',
          prop: 'SignCount',
          sortable: 'custom',
          type: 'number-item',
          width: 80,
          decimalPlace: 0
        },
        {
          label: '请假',
          prop: 'LeaveCount',
          sortable: 'custom',
          type: 'number-item',
          width: 80,
          decimalPlace: 0
        },
        {
          label: '旷课',
          prop: 'TruantCount',
          sortable: 'custom',
          type: 'number-item',
          width: 80,
          decimalPlace: 0
        },
        {
          label: '补课',
          prop: 'MakeupCount',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'number-item'
        },
        {
          label: '欠课时',
          prop: 'ClassHourDebtCount',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'class-hour'
        },
        {
          label: '课消金额',
          prop: 'ClassAwayAmountCount',
          sortable: 'custom',
          minWidth: 110,
          align: 'left',
          isShow: true,
          type: 'amount-item'
        },
        {
          label: '应到人次',
          prop: 'ArrangedNumCount',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'number-item',
          decimalPlace: 0,
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '实到人次',
          prop: 'ArrivedNumCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'number-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          width: 140,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 51
              },
              {
                name: '明细',
                extend: {
                  isHide: (rowData) => {
                    return this.IsMTeaKey != 1;
                  },
                  click: (rowData) => {
                    this.seletedItemToAttenDetail(rowData);
                  }
                }
              }

            ]
          }
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    debtCourseHourseDetails,
    seletedOption,
    inputSearchContainBtn
  },
  props: {
    seletedDate: {
      type: String,
      default: ''
    },
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  created () {

  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        o.newClassTime = o.ClassTime.substring(0, 10);
        o.ClassTime = o.ClassTime.substring(5);
        o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.searchObj.IsMTeaKey = this.IsMTeaKey;
    if (this.seletedDate) {
      this.searchObj.startTime = this.seletedDate;
      this.searchObj.endTime = this.seletedDate;
    }
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.saveDate.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.TeacherKey = 0;
      this.searchObj.GradeKey = 0;
      this.searchObj.CourseKey = 0;
      // this.searchObj = {
      //   pageIndex: 0,   // 门店id
      //   pageSize: 15,
      //   searchText: '',
      //   orderBy: '',
      //   type: '',
      //   sequence: 'DESC',
      //   QueryID: 0,
      //   TeacherKey: 0,
      //   startTime: '',
      //   endTime: '',
      //   IsExportKey: 0
      // };
      this.$refs.saveDate.resetTime();
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用,根据指定老师课名课程
    searchDataForKey (data, seachDataObj) {
      if (new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31' >= seachDataObj.startTime) {
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
        this.isShowHistory = true;
      } else {
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
        this.isShowHistory = false;
      }
      console.log('data, seachDataObj', data, seachDataObj);
      this.searchObj.TeacherKey = data.TeacherKey;
      this.searchObj.TeacherKeyValue = data.TeacherKeyValue;
      this.searchObj.GradeKey = data.GradeClassKey;
      this.searchObj.CourseKey = data.CourseNameKey;
      this.searchObj.GradeKeyValue = data.GradeClassKeyValue;
      this.searchObj.CourseKeyValue = data.CourseNameKeyValue;
      this.searchObj.startTime = seachDataObj.startTime;
      this.searchObj.endTime = seachDataObj.endTime;
      // this.searchObj = this.$utils.parseJson(this.searchObj);
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchDataForDate (item, date) {
      console.log('searchDataForDate', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.TeacherKeyValue = item.TeacherKeyValue;
      this.searchObj.GradeKey = 0;
      this.searchObj.GradeKeyValue = '全部';
      this.searchObj.CourseKey = 0;
      this.searchObj.CourseKeyValue = '全部';
      // this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
      }
      this.getDataList();
    },
    search () {
      console.log('aaa');
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    seletedItemToAttenDetail (item) {
      console.log(item, 'seletedItemToAttenDetail');
      let dataInfo = {
        CourseKey: item.CourseNameKey,
        CourseKeyValue: item.CourseNameKeyValue,
        GradeKey: item.GradeClassKey,
        GradeKeyValue: item.GradeClassKeyValue,
        TeacherKey: item.TeacherKey,
        TeacherKeyValue: item.TeacherKeyValue,
        ScheduleCourseKey: item.ScheduleCourseKey,
        startTime: item.newClassTime.substring(0, 10),
        endTime: item.newClassTime.substring(0, 10)
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 打开课消明细
    openAttenDetail () {
      let dataInfo = {
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchHistoryTeachingPerformanceForCourse;
        } else {
          fn = MessageSearchTeachingPerformanceForCourse;
        }
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课次明细',
          clist: [
            { title: '上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '老师', cName: 'TeacherKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '老师课时', cName: 'CourseHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::CourseHourCount::1::0}}" },
            { title: '学生课时', cName: 'StudentHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::StudentHourCount::1::0}}" },
            {
              title: '签到', cName: 'SignCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::SignCount::1::0}}"
            },
            {
              title: '请假', cName: 'LeaveCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::LeaveCount::1::0}}"
            },
            {
              title: '旷课', cName: 'TruantCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::TruantCount::1::0}}"
            },
            {
              title: '补课', cName: 'MakeupCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::MakeupCount::1::0}}"
            },

            { title: '欠课时', cName: 'ClassHourDebtCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ClassHourDebtCount::1::0}}" },
            { title: '课消金额', cName: 'ClassAwayAmountCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ClassAwayAmountCount::1::0}}" },
            {
              title: '应到人次', cName: 'ArrangedNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex", "template": "{{float::ArrangedNumCount::1::0}}"
            },
            {
              title: '实到人次', cName: 'ArrivedNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex", "template": "{{float::ArrivedNumCount::1::0}}"
            }

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchHistoryTeachingPerformanceForCourse;
      } else {
        fn = MessageSearchTeachingPerformanceForCourse;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalAbnormalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');

      this.seletedCourseInfo = rowData;
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.ScheduleCourseKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 去还款
    doDebtDetailsClick (item) {
      getStudentRelatedMsg(item.StudentNameKey).then(res => {
        if (res.code == 0) {
          this.studentDetail = res.data;
          this.$nextTick(o => {
            this.isDebtCourseHoursDetailsShow = true;
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    },
    // 确认欠课时详情
    confirmDebtCourseHoursDetails (error) {
      if (error.code == 0) {
        this.getDataList();
        this.closeDetails();
      }
    }
  }
};
</script>
<style scoped>
.summarizing_list >>> .el-table .el-table__empty-block {
  min-height: 690px !important;
}
.summarizing_list >>> .el-table .el-table__cell {
  padding: 0 !important;
}
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>