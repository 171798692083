<template>
  <!-- 销售 -->
  <div class="source_sale_div">
    <div class="mendian_backstage_search"
         style="margin: 20px 10px;">
      <div class="btn_hover_bg_blue search_btn "
           @click.stop="addSourceBtn">添加</div>
    </div>
    <div class="source_sale_table"
         style="margin: 10px 10px 40px 20px;">
      <el-table :data="tableData"
                style="width: 100%;"
                border>
        <el-table-column label="员工"
                         prop="MainDemoName"
                         :align="'center'">
        </el-table-column>
        <el-table-column label="手机号"
                         prop="Mobile"
                         :align="'center'">
        </el-table-column>
        <el-table-column label="操作"
                         :align="'center'">
          <template slot-scope="scope">
            <el-button @click.native.prevent="chooseEdit(scope.$index, tableData)"
                       type="text"
                       size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <custom-dialog :title="'员工列表'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeForm">
      <get-sale-list :isStaffSale="true"
                     @closeForm="closeForm"
                     @onSelectedItem="onSelectedItem">
      </get-sale-list>
    </custom-dialog>
  </div>
</template>

<script>
import { MessageSearchPageForEmployee, DelRecruitStudentSale } from '../../../API/workbench.js';
import getSaleList from './getSale-list.vue';// 销售
export default {
  data () {
    return {
      isShowSalesList: false, // 销售
      sellSourceList: [],
      // 查询销售员工
      tableData: []
    };
  },
  components: {
    getSaleList
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      let obj = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      };
      this.tableData = [];
      MessageSearchPageForEmployee(obj).then(
        (result) => {
          if (result.data.PageDataList && result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((o) => {
              if (Number(o.IsAdviserKey) == 1) {
                this.tableData.push(o);
              }
            });
          } else {
            this.tableData = [];
          }
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    closeForm () {
      this.isShowSalesList = false;// 销售
    },
    // 打开员工列表
    addSourceBtn () {
      this.isShowSalesList = true;
    },
    onSelectedItem (item) {
      console.log('item0,', item);
      this.isShowSalesList = false;// 销售
      this.loadData();
    },
    chooseEdit (index, row) {
      console.log(index, row, '员工');
      layer.confirm('删除后将会解绑线索数据，是否删除', {
        btn: [{
          name: '确认',
          callBack: () => {
            layer.close();
            DelRecruitStudentSale(row[index].OLAPKey).then(result => {
              layer.alert('删除成功');
              this.loadData();
            }, error => {
              layer.alert(error.msg);
            });
          }
        }
        ]
      });
    }
  }
};
</script>
<style scoped>
.source_sale_table >>> .el-table .el-table__cell {
  padding: 0 !important;
}
</style>