<template>
  <div class="courseware_list accessory pr"
       :class="{ opt: dataInfo.isSelect }">
    <div class="flex">
      <div class="list_box"
           @click="selectItem">
        <div class="list_img">
          <div class="list_number">{{dataInfo.PageNO}} </div>
          <img class="img_box"
               :src="vUrl" />
          <span class="cw_authorization"
                v-if="dataInfo.isError">
            <img src="../../../../../public/image/ppt_warming.png"
                 alt="">
            <!-- <span class="ppt_warming"></span> -->
            <!-- 第{{keyIndex+1}}个元素 -->
            {{dataInfo.Remark}}
          </span>
          <span class="cw_authorization "
                v-else-if="dataInfo.progress==100">
            <img src="../../../../../public/image/tick_green.png"
                 alt=""
                 style="width:25px;height:25px">
            <!-- <span class="ppt_green"></span> -->
          </span>
          <span class="cw_authorization "
                v-else>
            <img src="../../../../../public/image/ppt_waiting.gif"
                 alt="">
            <!-- <span class="ppt_waiting"></span> -->
            验证中...</span>
          <!-- <div v-if="vErrorMessage"
               class="error_message">
            {{vErrorMessage}}
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div v-else
         @click="selectItem"
         class="button"
         style="width:100%">
      <upload-item :dataInfo="dataInfo"></upload-item>
    </div> -->
  </div>
</template>
<script>
// import uploadItem from './upload-item';
export default {
  data () {
    return {
      isShowUpload: false
    };
  },
  components: {
    // uploadItem
  },
  props: {
    dataInfo: Object,
    keyIndex: Number
  },
  computed: {
    vUrl () {
      // if (this.dataInfo.CoverObject) {
      //   return this.dataInfo.CoverObject;
      // } else {
      return require('../../../../../public/image/courseware_list_type03.png');
      // }
    },
    vClass () {
      if (this.dataInfo.AttachTypeKey == '1') {
        return 'type_01';
      } else if (this.dataInfo.AttachTypeKey == '2') {
        return 'type_02';
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return 'type_04';
      } else if (this.dataInfo.AttachTypeKey == '4') {
        return 'type_03';
      } else if (this.dataInfo.AttachTypeKey == '5') {
        return 'type_05';
      }
    },

    vErrorMessage () {
      if (this.dataInfo.UnusualStatusKey == 1) {
        if (this.dataInfo.ResultStageKey == 3) {
          return '封面生成失败，请确认文件是否正常';
        } else if (this.dataInfo.ResultStageKey == 4 || this.dataInfo.ResultStageKey == 5) {
          return '转码失败，请重试，如多次失败，请联系专属客服';
        }
      } else if (this.dataInfo.UnusualStatusKey == 0) {
        if (this.dataInfo.ResultStageKey > 0) {
          let isTimeOut = Date.new().getTime() - Date.new(this.dataInfo.DealTime).getTime() > 86400000;
          if (isTimeOut) {
            return '附件处理超时，请删除重新上传';
          }
        }
      } else {
        return '';
      }
    }
  },
  created () {
    this.$bus.on(this, 'wsCoursewareEditor', (data) => {
      // console.log(data, this.dataInfo, this.attachType, 'wsCoursewareEditor');
      if (data.DataKey == this.dataInfo.ID && data.status == 1) {
        this.dataInfo.ResultStageKey = 0;
        this.dataInfo.UnusualStatusKey = 0;
      }
    });
  },
  methods: {
    upItem () {
      this.$emit('upItem', this.dataInfo);
    },
    downItem () {
      this.$emit('downItem', this.dataInfo);
    },
    selectItem () {
      this.$emit('selectItem', this.dataInfo);
    }
  }
};
</script>
<style scoped>
.courseware_list.accessory {
  width: 170.4px;
  height: 120.4px;
  border-radius: 8px;
  margin-right: 15px;
}
.courseware_list .list_img {
  width: 170.4px;
  height: 120.4px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  /* margin-right: 15px; */
}
.courseware_list .list_box {
  width: 170.4px;
  height: 120.4px;
}
.cw_authorization {
  width: 170px;
  height: 29px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 8px 8px;
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
}
.cw_authorization img {
  width: 15px;
  height: 15px;
  margin-top: -2px;
}
.ppt_warming {
  width: 15px;
  height: 100%;
  background: url('../../../../../public/image/ppt_warming.png') no-repeat left
    center;
  background-size: 10px;
}
.ppt_green {
  width: 15px;
  height: 100%;
  background: url('../../../../../public/image/tick_green.png') no-repeat left
    center;
  background-size: 10px;
}
.ppt_waiting {
  width: 15px;
  height: 100%;
  background: url('../../../../../public/image/ppt_waiting.gif') no-repeat left
    center;
  background-size: 10px;
}
.error_message {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.courseware_name_capacity {
  position: absolute;
  bottom: 15px;
  right: 0px;
}
.courseware_name_capacity_icon {
  width: 10px;
  height: 10px;
}
.courseware_name_capacity_text {
  font-size: 12px;
  color: #999999;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
</style>