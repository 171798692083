<template>
  <div :id="echartID"
       class="echartbox"></div>
</template>

<script>
export default {
  name: 'echartPie',
  data () {
    return {
      echart: null
    };
  },
  props: {
    echartID: String,
    dataList: Array
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      var option = {
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['40%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.dataList
          }
        ],
        legend: {
          orient: 'horizontal',
          left: 0,
          top: 30,
          itemGap: 20,
          itemWidth: 20,
          itemHeight: 20,
          textStyle: {
            fontSize: 14,
            lineHeight: 20
          },
          selectedMode: false
        }
      };
      this.echart.setOption(option);
    }

  }
};
</script>