<template>
  <!-- 购买金额 -->
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:69px">
    <input :class="{text_error:vCurrentCountWarning}"
           :value="rowData.ActualPayAmount"
           v-if="vIsEdit"
           type="text"
           ref="ActualPayAmount"
           :placeholder="rowType=='add'?'购买金额':''"
           @keydown="keydownEvent($event)"
           @blur="blurEvent"
           @focus="focusEvent($event)"
           @input="inputEvent($event.target.value,$event)"
           @change="changeEvent($event.target.value,$event)"
           onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.')">

  </div>
</template>

<script>
export default {
  data () {
    return {
      ActualPayAmount: '', // 购买金额
      isFocus: false
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object
  },
  computed: {
    vCurrentCountWarning () {
      let flag = false;
      let findItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 7; });
      if (findItem) {
        flag = true;
      }
      return flag;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    'rowData.focusField': {
      handler (n, o) {
        if (n.focusField == 'ActualPayAmount' && n.optioningRow) {
          this.rowData.focusField = 'ActualPayAmount';
          this.$refs.ActualPayAmount.focus();
          // 光标定位到文本框字符串末尾
          var tObj = this.$refs.ActualPayAmount;
          if (tObj.value) {
            var sPos = tObj.value.length;
            this.$utils.setCaretPosition(tObj, sPos);
          }
        }
      },
      // immediate: true
      deep: true // 表示开启深度监听
    }
  },
  created () {
  },
  methods: {
    // 鼠标聚焦
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'ActualPayAmount';
      this.$emit('onFocusEvent', 'ActualPayAmount');
    },
    // 键盘事件   if (typeof count === 'number' && !isNaN(count)) {
    keydownEvent (e) {
      switch (e.keyCode) {
        case 9: // Tab
          this.rowData.focusField = 'NumCount';
          break;
        case 13: // 回车
          this.rowData.focusField = 'NumCount';
          break;
        case 37: // 左键
          this.rowData.focusField = 'ExpireTime';
          break;
        case 38: // 向上键
          if (Number(this.rowData.Number) > 1) {
            this.$emit('doUpwardMove', this.rowData, 'ActualPayAmount');
          }
          break;
        case 39: // 右键
          this.rowData.focusField = 'NumCount';
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'ActualPayAmount');
          break;
        default:
          break;
      }
    },
    inputEvent (val, e) {
      if (val.toString().length > 10) {
        e.target.value = (val === '' ? '' : Number(val.toString().substr(0, 10)));
        this.rowData.ActualPayAmount = e.target.value;
      }
    },
    changeEvent (val, e) {
      this.rowData.ActualPayAmount = (val === '' ? '' : this.$utils.mMoneyType(val, 2));
    },
    // 失去焦点事件
    blurEvent () {
      this.rowData.focusField = '';
      this.isFocus = false;
      if (this.rowData.ActualPayAmount && isNaN(this.rowData.ActualPayAmount)) {
        this.rowData.ActualPayAmount = this.rowData.ActualPayAmount.replace(/[^\d.]/g, '');// 校验数字类型（字符串提取数字和小数点）
      }
      if (Number(this.rowData.ActualPayAmount) && typeof this.rowData.ActualPayAmount === 'number') {
        this.rowData.ActualPayAmount = Number(this.rowData.ActualPayAmount);
      }
      // this.rowData.ActualPayAmount = this.rowData.ActualPayAmount <= 0 ? '' : this.rowData.ActualPayAmount;
      this.verificationActualPayAmount();
      this.rowData.updateTime = Date.new();
      this.$emit('onBlurEvent', this.rowData, 'ActualPayAmount');
    },
    // 验证 购买金额
    // 异常TargetField：7 》 TypeKey 1：购买金额[非数字]填写错误，2：购买金额[负数]必须大于等于0，3：购买金额超出9999999
    verificationActualPayAmount () {
      let NANErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 7 && obj.TypeKey == 1; });
      // 1：购买金额[非数字]填写错误
      if ((!this.rowData.ActualPayAmount || this.rowData.ActualPayAmount > 0) && NANErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(NANErrorIndex, 1);
      }
      let negativeErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 7 && obj.TypeKey == 2; });
      // 2：购买金额[负数]必须大于0
      if (this.rowData.ActualPayAmount < 0 && negativeErrorIndex < 0) {
        this.rowData.ExceptionList.push({ TargetField: 7, TypeKey: 2, TypeKeyValue: '购买金额必须大于0' });
      } else if ((this.rowData.ActualPayAmount > 0 || this.rowData.ActualPayAmount === '') && negativeErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(negativeErrorIndex, 1);
      }
      let maxErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 7 && obj.TypeKey == 3; });
      // 3：购买金额超出9999999
      if (this.rowData.ActualPayAmount > 9999999 && maxErrorIndex < 0) {
        let maxItem = { TargetField: 7, TypeKey: 3, TypeKeyValue: '购买金额超出9999999' };
        this.rowData.ExceptionList.push(maxItem);
      } else if (this.rowData.ActualPayAmount <= 9999999 && maxErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(maxErrorIndex, 1);
      }
      // 在读课程未选择
      if (!this.rowData.ApplyClassKeyValue) {
        let unSelectedErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
        if ((this.rowData.ActualPayAmount > 0 || this.rowData.ActualPayAmount === 0) && unSelectedErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 3, TypeKeyValue: '在读课程未选择' });
          this.rowData.isShowCorrect = false;
        } else if ((this.rowData.ActualPayAmount === '' || this.rowData.ActualPayAmount === null) && unSelectedErrorIndex >= 0) {
          if (!this.rowData.PayTime && !this.rowData.ExpireTime && (this.rowData.NumCount === '' || this.rowData.NumCount === null) && (this.rowData.CurrentCount === '' || this.rowData.CurrentCount === null) && unSelectedErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(unSelectedErrorIndex, 1);
          }
        }
      }
    }
  }
};
</script>

<style>
</style>