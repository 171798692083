<template>
  <div class="course_report">
    <seleted-option :searchObj="searchObj"
                    style="padding-top:20px;padding-left:20px"
                    :teacherList="teacherList"
                    :gradeClassList="gradeClassList"
                    :courseNameList="courseNameList"
                    @changeSearchObj="updateData"></seleted-option>
    <div class="payment_statistics_bottom course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :minDate="vMinDate"
                  :maxDate="vMaxDate"
                  :isShowHistory="isShowHistory"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入老师姓名'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="vtableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
    <!-- <attendance-arrange ref="newAttendanceArrangeDialog"
                        :courseKey="seletedCourseInfo.OLAPKey"
                        :formTitle="'安排考勤'"
                        @parentUpdate="getDataList"></attendance-arrange> -->
  </div>
</template>
<script>
import attendanceArrange from '../../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import tableList from '../../../common/table-list/index';
import seletedOption from './seleted-option';
import { MessageSearchClassAwayForCourse, MessageSearchClassAwayForCourseH } from '../../../../API/workbench';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        GradeKey: 0,
        GradeKeyValue: '',
        CourseKey: 0,
        CourseKeyValue: '',
        QueryID: 0,
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '上课时间',
          prop: 'ClassTime',
          minWidth: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => { this.doAfterClickToAtten(rowData); },
            ModulePowerKey: 51
          }
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          minWidth: 142,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          minWidth: 160,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: 'font_dual'

        },
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          minWidth: 104,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生课时',
          prop: 'ClassHourCount',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'font_black',
            setShowOwe: (rowData) => {
              return rowData.ISClassHourDebtKey > 0;
            }
          }
        },
        {
          label: '签到',
          prop: 'AttenceCount',
          sortable: 'custom',
          minWidth: 60,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '请假',
          prop: 'LeaveCount',
          sortable: 'custom',
          minWidth: 60,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '旷课',
          prop: 'TruantCount',
          sortable: 'custom',
          minWidth: 60,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '补课',
          prop: 'MakeupCount',
          sortable: 'custom',
          minWidth: 60,
          align: 'left',
          isShow: true,
          type: 'text-item'
        }

      ],
      dataReportList: []
    };
  },
  components: {
    tableList, attendanceArrange, inputSearchContainBtn, seletedOption
  },
  props: {
    seletedDate: {
      type: String,
      default: ''
    },
    teacherList: Array,
    gradeClassList: Array,
    courseNameList: Array
  },
  created () {

  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vtableCourseColumns () {
      let list = [];
      this.tableCourseColumns.forEach(o => {
        if (this.$utils.getSaaSClientSet(59) > 0 && o.label == '旷课') {
          o.isShow = true;
        } else if (this.$utils.getSaaSClientSet(59) == 0 && o.label == '旷课') {
          o.isShow = false;
        }
        list.push(o);
      });
      return list;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        o.ClassTime = o.ClassTime.substring(5);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      this.getDataList();
    });
    // 关闭安排考勤刷新
    this.$bus.on(this, 'closeArrangeAttendanceDialog', (data) => {
      console.log('closeArrangeAttendanceDialog');
      this.getDataList();
    });
    if (this.seletedDate) {
      this.searchObj.startTime = this.seletedDate;
      this.searchObj.endTime = this.seletedDate;
    }
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.saveDate.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      // this.searchObj = {
      //   pageIndex: 0,   // 门店id
      //   pageSize: 15,
      //   searchText: '',
      //   orderBy: '',
      //   type: '',
      //   sequence: 'DESC',
      //   QueryID: 0,
      //   startTime: '',
      //   endTime: '',
      //   IsExportKey: 0
      // };
      this.$refs.saveDate.resetTime();
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (date) {
      if (date) {
        console.log(date, 'searchData');
        this.searchObj.startTime = date;
        this.searchObj.endTime = date;
      }
      this.searchObj.pageIndex = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchClassAwayForCourseH;
        } else {
          fn = MessageSearchClassAwayForCourse;
        }
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课次明细',
          clist: [
            { title: '上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '老师', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '学生课时', cName: 'ClassHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ClassHourCount::1::0}}" },
            { title: '签到', cName: 'AttenceCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '请假', cName: 'LeaveCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '旷课', cName: 'TruantCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '补课', cName: 'MakeupCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchClassAwayForCourseH;
      } else {
        fn = MessageSearchClassAwayForCourse;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }

      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeductAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击 去考勤页面
    doAfterClickToAtten (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');
      this.seletedMDInfo = rowData;
      this.seletedCourseInfo = rowData;
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.OLAPKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    doAfterClickToClassDetailRecord (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');
      this.seletedMDInfo = rowData;
      this.seletedCourseInfo = rowData;
      let newInfo = {
        studentKey: this.studentKey, // 学生ID
        courseBillKey: rowData.OLAPKey // 课单ID
      };
      console.log(rowData, '课消');
      let moduleInfo = {
        name: '课单课消',
        routerName: this.$route.name,
        moduleName: 'buyClassDetailRecord',
        data: newInfo
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    }
  }
};
</script>
<style scoped>
.course_report
  >>> .payment_statistics_bottom
  .course_statistics_search
  .search_box {
  width: 250px;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>