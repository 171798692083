<!-- 添加流量套餐管理模块 -->
<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'课评模板名称'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.TemplateName"></input-text>
    </div>
    <div class="label_set_div">
      <div class="label_content"
           style="margin-bottom: 30px">
        <!-- 左边  分组-->
        <div class="content_div">
          <div class="label_title">
            <div class="group_title">维度名称</div>
            <div class="name_title">星级分组</div>
          </div>

          <div class="group_and_label"
               v-for="(item, index) in dataInfo.TemplateSetting"
               :key="index">
            <!-- 组 -->
            <div class="group_content">
              <img src="../../../../public/image/courseware_menu_list10.png"
                   class="group_img"
                   style="top: 4px; right: 27px"
                   @click.stop="groupSetBtn(item,index)" />
              <img src="../../../../public/image/courseware_menu_list09.png"
                   class="group_img"
                   style="top: 4px;right: 5px;"
                   @click.stop="delItem(item,index)" />
              <div class="group_value"
                   :title="item.SetName"
                   :class="
                  index == dataInfo.TemplateSetting.length - 1
                    ? 'add_div add_group_div'
                    : ''
                ">
                {{ item.SetName }}
              </div>
            </div>
            <!-- 右边 标签名称 -->
            <div class="form_info_edit form_info_list"
                 style="width:228px">
              <input-text :formTitle="'一星'"
                          :readonly="isReadonly"
                          :formPlaceholder="'选填'"
                          :required="false"
                          :maxLength="14"
                          v-model="item.OneStar"></input-text>
              <input-text :formTitle="'二星'"
                          :readonly="isReadonly"
                          :formPlaceholder="'选填'"
                          :required="false"
                          :maxLength="14"
                          v-model="item.TwoStar"></input-text>
              <input-text :formTitle="'三星'"
                          :readonly="isReadonly"
                          :formPlaceholder="'选填'"
                          :required="false"
                          :maxLength="14"
                          v-model="item.ThreeStar"></input-text>
              <input-text :formTitle="'四星'"
                          :readonly="isReadonly"
                          :formPlaceholder="'选填'"
                          :required="false"
                          :maxLength="14"
                          v-model="item.FourStar"></input-text>
              <input-text :formTitle="'五星'"
                          :readonly="isReadonly"
                          :formPlaceholder="'选填'"
                          :required="false"
                          :maxLength="14"
                          v-model="item.FiveStar"></input-text>

            </div>
          </div>
          <div class="label_title">
            <div class="group_title font_blue button"
                 style="width:100% !important;"
                 @click="addModuleItem">新增维度</div>
            <!-- <div class="name_title"></div> -->
          </div>
        </div>
      </div>
      <custom-dialog title="修改维度名称"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowLabelSet"
                     :before-close="closeForm">
        <item-form :seletdInfo="seletdInfo"
                   @cancelClick="closeForm"
                   @afterSuccess="editName">
        </item-form>
      </custom-dialog>
    </div>
    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import itemForm from "./item-form.vue"; // 销售
import {
  getTemplateDetail,
  AddTemplate,
  EditTemplate
} from "../../../API/workbench";
export default {
  data () {
    return {
      isShowLabelSet: false, // 标签
      seletdInfo: null,
      dataInfo: {
        TemplateName: "",
        TemplateSetting: [
          {
            "SetName": "",
            "OneStar": "",
            "TwoStar": "",
            "ThreeStar": "",
            "FourStar": "",
            "FiveStar": ""
          }
        ]
      }
    };
  },
  components: {
    itemForm
  },
  props: {
    seletedItem: Object,
    isReadonly: Boolean
  },
  created () {
    console.log(this.seletedItem, 'created')
    if (this.seletedItem) {
      this.getDataInfo()
    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    addModuleItem () {
      let obj = {
        "SetName": "",
        "OneStar": "",
        "TwoStar": "",
        "ThreeStar": "",
        "FourStar": "",
        "FiveStar": ""
      }
      this.dataInfo.TemplateSetting.push(obj)
    },
    closeForm () {
      this.isShowLabelSet = false; // 标签
    },
    editName (item) {
      console.log("editName", item)
      this.isShowLabelSet = false; // 标签
      this.seletdInfo.SetName = item.SetName
      console.log(this.dataInfo)
    },
    getDataInfo () {
      getTemplateDetail(this.seletedItem.ID).then(result => {
        console.log("getTemplateDetail", result.data);
        this.dataInfo = result.data
      });
    },
    // 修改 或者 删除 组
    groupSetBtn (item) {
      console.log("groupSetBtn", this.seletdInfo)
      this.seletdInfo = item;
      this.isShowLabelSet = true;
    },
    delItem (item, key) {
      console.log("delItem", item, key)
      this.dataInfo.TemplateSetting.splice(key, 1)
      console.log(this.dataInfo.TemplateSetting)
    },
    // 提交
    submit (callback) {
      console.log("submit", this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.seletedItem) {
        EditTemplate(this.dataInfo)
          .then(
            result => {
              layer.alert("修改成功");
              this.$emit("afterSuccess");
              this.cancel();
            },
            error => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      } else {
        AddTemplate(this.dataInfo)
          .then(
            result => {
              layer.alert("添加成功");
              this.$emit("afterSuccess");
              this.cancel();
            },
            error => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      }
    },
    // 验证客户信息
    verificationInfo () {
      // if (this.dataInfo.CampusName) {
      //   this.dataInfo.CampusName = this.dataInfo.CampusName.replace(/(^\s*)|(\s*$)/g, '');
      // }
      let flag = true;
      if (!this.dataInfo.TemplateName) {
        layer.alert("请输入课评模板名称");
        flag = false;
      } else if (this.dataInfo.TemplateSetting.length == 0) {
        layer.alert("课评维度不能为空");
        flag = false;
      } else {
        let locaIndex = this.dataInfo.TemplateSetting.findIndex(o => {
          return !o.SetName
        })
        if (locaIndex > -1) {
          layer.alert("请输入维度名称");
          flag = false;
        }

      }
      return flag;
    },
    cancel () {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.label_set_div {
  min-height: 550px;
}
.content_div {
  width: 370px;
  margin: 30px auto;
  border: 1px solid rgba(236, 236, 236, 1);
  max-height: 550px;
  overflow-y: auto;
}
.label_title {
  display: flex;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: rgba(151, 156, 160, 1);
  background: rgba(250, 250, 250, 1);
}
.group_title {
  font-size: 14px;
  width: 138px;
  border-right: 1px solid rgba(236, 236, 236, 1);
  text-align: center;
}
.name_title {
  width: 232px;
  text-align: center;
}
.group_and_label {
  display: flex;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.group_content {
  width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(236, 236, 236, 1);
  position: relative;
}
.group_img {
  position: absolute;
  cursor: pointer;
}
.group_value {
  max-width: 125px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label_right {
  width: 232px;
}
.label_name {
  height: 30px;
  line-height: 30px;
  width: 208px;
  width: 200p;
  margin: 10px auto;
  border-radius: 4px;
  color: rgba(102, 102, 102, 1);
  border: 1px solid rgba(236, 236, 236, 1);
}
.hide_img {
  display: none;
}
.add_div {
  position: relative;
  text-align: center;
  color: rgba(102, 102, 102, 1);
  cursor: pointer;
}
.add_group_div {
  width: 120px;
  height: 30px;
  border: none;
  line-height: 30px;
  border-radius: 4px;
  color: rgba(102, 102, 102, 1);
}
.label_span {
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 163px;
}
.add_div span {
  width: 100% !important;
}
</style>
