<template>
  <custom-dialog title=""
                 width="275px"
                 top="400"
                 class="student_info_dialog defult_dialog"
                 :close-on-click-modal="true"
                 :visible.sync="isShowMain"
                 :before-close="choseIsShowMain">
    <!-- <div class=""
       v-if="isShowMain"> -->
    <!-- <div class="shade"
         @click="isShowMain=false"></div> -->
    <div class="apply_course_popup">
      <div class="apply_course_popup_close button"
           @click="isShowMain=false"></div>
      <div class="popup_title">{{planItem.ApplyClassTypeKeyValue}}</div>
      <div class="popup_content">
        <span>计划报课</span>
        <!-- <div class="popup_content_box">20</div> -->
        <input type="number"
               v-model="planCount"
               class="popup_content_box"
               onclick="this.select();">
        <span>课时</span>
      </div>
      <div class="popup_number clearfix">
        <div class="fl">当前报课 <span class="font_black">{{planItem.activeCount}}</span> 课时</div>
        <div class="popup_number_red fl"
             v-if="Number(planItem.SpendCount)-Number(planItem.PlanCount)>0">超上{{Number(planItem.SpendCount)-Number(planItem.PlanCount)}}</div>
        <div class="fr">完成后 <span class="font_red">{{vTotalCount}}</span> 课时</div>
      </div>
      <div class="popup_tips">
        报课后请及时给学生安排上课时间。
      </div>
      <div class="popup_btn_box">
        <div class="popup_btn btn_white btn_hover_white button"
             @click="isShowMain=false">取消</div>
        <div class="popup_btn btn_blue  btn_hover_bg_blue button"
             @click="toSure">确定</div>
      </div>
    </div>
    <!-- </div> -->
  </custom-dialog>
</template>
<script>
export default {
  data () {
    return {
      isShowMain: false,
      planCount: 0

    };
  },
  props: ['planItem', 'tickItem', 'waitPlanCount'],
  computed: {
    vMax () {
      return this.waitPlanCount;
    },
    vTotalCount () {
      return this.$utils.saveDecimal(Number(this.planItem.activeCount) + Number(this.planCount));
    }
  },
  watch: {
    planCount (c, o) {
      if (this.planCount != '') {
        this.planCount = this.$utils.saveDecimal(Number(this.planCount));
        if (this.planCount > this.vMax) {
          this.planCount = this.vMax;
        }
      }
    }
  },
  mounted () {
    this.$bus.on(this, 'clickShade', () => {
      this.choseIsShowMain();
    });
  },
  methods: {
    // 初始化.
    chgisShowMain () {
      this.isShowMain = true;
      this.$nextTick(() => {
        this.planCount = this.vMax;
      });
    },
    toSure () {
      if (this.planCount < 0) {
        layer.alert('计划报课数量不能少于0');
        return false;
      }
      if (this.planCount > this.vMax) {
        layer.alert('计划报课数量不能大于' + this.vMax);
        return false;
      }
      if (this.planCount == '') {
        layer.alert('请输入报课课时数量');
        return false;
      }
      this.planItem.adjustCount = Number(this.planCount);
      this.planItem.activeCount = Number(this.planItem.activeCount) + this.planCount;
      this.isShowMain = false;
      this.$emit('confirm', this.planItem, '报课成功');
    },
    choseIsShowMain () {
      this.isShowMain = false;
    }
  }
};
</script>
