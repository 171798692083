<template>
  <div class="margin_left_right_div">
    <div class="general_summarizing_student payment_statistics_view height_type all_view template_minOrmax_width_div">
      <div class="single_result_detail"
           style="width:100%">

        <div class="accraditation_summary">
          <div class="course_class_summarizing_right"
               style="width:100%">
            <div class="flex">
              <div class="right_top_box"
                   style="width:40% !important">
                <title-summary-data v-for="(item,key,index) in headSummaryData"
                                    :sumData="item"
                                    :key="index"
                                    @onCilckEvent="doTitleBoxClick"></title-summary-data>

              </div>
              <div class="right_top_box"
                   style="width:10% !important;margin-left:20px">
                <title-summary-data :sumData="headSummaryOtherData"
                                    @onCilckEvent="doTitleBoxClick"></title-summary-data>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="course_statistics_table_search course_statistics_title">
        <div class="table_select_box"
             style="margin-right:20px;min-width:306px">
          <div class="table_select_title">课程顾问</div>
          <el-select :value="vsaleManValue"
                     placeholder="请选择"
                     style="width:239px"
                     ref="resetText"
                     @change="selectOverdueType">
            <el-option v-for="item in vsalesList"
                       :key="item.value"
                       :label="item.label"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="">
          <div class="">
            <date-range ref="monthPicker"
                        style="margin-right:36px"
                        :fromTitle="'预约日期'"
                        :searchObj="searchObj"
                        @changeDate="changeSaveDate"></date-range>
          </div>
        </div>

        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
        </div>

      </div>
      <div class="course_statistics_table_box table_list_content try_course_table_list">
        <table-list class="summarizing_list"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    @loadTableData="getDataList"></table-list>
      </div>
      <div>
        <dialog-factory ref="tryCourseDialogFactory"
                        :key="'tryCourse'"
                        :routerName="'tryCourse'"></dialog-factory>

        <dialog-factory-common ref="tryCourseDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import allotDialog from './allot-dialog';
import dialogFactory from '../../../common/dialog-factory';
import titleSummaryData from './title-summary-data';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { SearchPageToShopTryClassList, GetAllSales } from '../../../../API/workbench';
export default {
  name: 'tryCourse',
  data () {
    return {
      seletedInfo: {},    // 选中的单项
      studentDetail: {},
      headSummaryData: [
        { title: '全部', className: '', value: 0, StatusKey: 0, decimalPlace: 0, isSelected: true },
        { title: '到店', className: '', value: 0, StatusKey: 1, decimalPlace: 0, isSelected: false },
        { title: '试课', className: '', value: 0, StatusKey: 2, decimalPlace: 0, isSelected: false }
      ],
      headSummaryOtherData: { title: '未跟进', className: 'font_red', value: 0, StatusKey: 3, decimalPlace: 0, isSelected: false },
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        type: 0,
        saleMan: 0,
        saleManValue: ''

      },
      salesList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      studentArray: [],     // 选中的分配学生.
      showAllot: false, // 是否显示分配弹出层
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '课程顾问',
          prop: 'AdviserKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 100,
          sortable: false,
          type: 'student',
          extend: {
            studentKey: 'StudentKey',
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '意向级别',
          prop: 'WillingnessRateKeyValue',
          width: 60,
          sortable: false,
          align: 'center',
          type: 'textItem',
          columnClass: 'font_dual'
        },
        {
          label: '预约类型',
          prop: 'TypeKeyValue',
          width: 80,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '预约日期',
          prop: 'AppointTimeDate',
          minWidth: 130,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '预约内容',
          prop: 'tryCrouse',
          sortable: false,
          minWidth: 150,
          align: 'left',
          type: 'textItem',
          extend: {

          }
        },
        {
          label: '预约结果',
          prop: 'ResultCRMKeyValue',
          sortable: false,
          minWidth: 85,
          align: 'left',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              // 0-预约(问好);1-已到店(对勾)/已试课(对勾);2-未到店(叉)/未试课(叉);3-取消(叉)
              if (rowData.ResultCRMKey == 1) {
                return 'dot_green';
              } else if (rowData.ResultCRMKey == 2) {
                return 'dot_red';
              } else if (rowData.ResultCRMKey == 3) {
                return 'dot_red';
              } else if (rowData.ResultCRMKey == 4) {
                return 'dot_red';
              } else {
                return '';
              }
            }
          }
        },
        {
          label: '跟进情况描述',
          prop: 'followContent',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '邀约日期',
          prop: 'GenerateTime',
          minWidth: 160,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }

      ],

      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    titleSummaryData,
    dialogFactory,
    allotDialog
    // seletedOption
  },
  props: {
  },
  created () {
    console.log('this.toSearchObj');
    if (this.$route.query.startTime) {
      this.searchObj.startTime = this.$route.query.startTime;
      this.searchObj.endTime = this.$route.query.endTime;
      this.searchObj.saleManValue = this.$route.query.SaleMan || '';
      this.searchObj.saleMan = this.$route.query.SaleManKey || 0;
      if (this.$route.query.type >= 0) {
        this.searchObj.type = this.$route.query.type || 0;
        this.headSummaryData.forEach(o => {
          if (o.StatusKey == this.$route.query.type) {
            o.isSelected = true;
          } else {
            o.isSelected = false;
            this.headSummaryOtherData.isSelected = false;
          }
        });
      }
    } else {
      // this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      // this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    }
  },
  mounted () {
    this.getDataList();
    this.resgisterBusEvent();
    this.getSalesList();
  },
  computed: {

    vsaleManValue () {
      return this.searchObj.saleMan == 0
        ? '全部'
        : this.searchObj.saleManValue;
    },
    vsalesList () {
      return this.salesList;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.NextFollowUpTime = '2021-10-11 16:30:25';
        // o.Content = '测试133113';
        o.WillingnessRateKeyValue = o.WillingnessRateKeyValue.substring(0, 1);
        o.AdviserKeyValue = o.AdviserKeyValue || '-';
        // ResultCRMKey  0-预约(问好);1-已到店(对勾)/已试课(对勾);2-未到店(叉)/未试课(叉);3-取消(叉)

        this.$set(o, 'ResultCRMKeyValue', this.setResultCRMKeyValue(o));
        o.copyFollowUpTime = o.FollowUpTime ? o.FollowUpTime.substring(0, 10) : '';
        o.AppointTimeDate = o.AppointTime ? o.AppointTime.substring(0, 10) : '';
        o.GenerateTime = o.GenerateTime ? o.GenerateTime.substring(0, 16) : '';
        o.ExpectedNum = Math.abs(Number(o.ExpectedNum)) + '天';
        o.EnlistTimeApply = o.EnlistTime ? '是' : '否';
        o.followContent = o.Content ? `<span class="font_gray">` + this.$utils.StrForTodoTime(o.FollowUpTime) + `:</span>` + o.Content : '';
        if (!o.OrderClassKeyValue) {
          o.tryCrouse = '';
        } else {
          o.tryCrouse = `<div class="flex try_course_apoint"><span>` + o.BeginClassTime.substring(11, 16) + `</span><span style="margin-left:65px;" class="` + this.setStatuClass(o) + `">` + this.setStatuKey(o) + `</span></div><div style="width: 80%;">` + o.OrderClassKeyValue + `</div>`;
        }
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      // if (this.totalAmount > 0) {
      //   text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // } else {
      //   text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // }
      return text;
    }
  },

  methods: {
    setResultCRMKeyValue (item) {
      console.log('setResultCRMKeyValue,', item);
      if (item.TypeKey == 1) {
        if (item.ResultCRMKey == 1) {
          return '已到店';
        } else if (item.ResultCRMKey == 2) {
          return '未到店';
        } else if (item.ResultCRMKey == 3) {
          return '已取消';// 改约
        } else {
          return '';
        }
      } else {
        if (item.ResultCRMKey == 1) {
          return '已试课';
        } else if (item.ResultCRMKey == 2) {
          return '未试课';
        } else if (item.ResultCRMKey == 3) {
          return '已取消';
        } else {
          return '';
        }
      }
    },
    setStatuKey (item) {
      if (item.AttenceStatusKey == 3) {
        return '签到';
      } else if (item.AttenceStatusKey == 4) {
        return '请假';
      } else if (item.AttenceStatusKey == 5) {
        return '旷课';
      } else {
        return '';
      }
    },
    setStatuClass (item) {
      if (item.AttenceStatusKey == 3) {
        return 'font_green';
      } else if (item.AttenceStatusKey == 4) {
        return 'font_yellow';
      } else if (item.AttenceStatusKey == 5) {
        return 'font_red';
      } else {
        return '';
      }
    },
    // 销售人员层显示并获取数据
    getSalesList () {
      GetAllSales()
        .then(result => {
          result.data.forEach(o => {
            o.label = o.MainDemoName + (o.IsLeaveKey != 1 ? ' (离职)' : '');
            o.value = o.OLAPKey;
            // this.$set(o, 'isActive', false);
          });
          result.data.unshift({
            label: '全部',
            value: 0
          });
          this.salesList = result.data;
        })
        .catch(err => {
          console.info(err);
        });
    },
    doTitleBoxClick (item) {
      console.log(item, 'doAfterTitleBoxClick');
      // this.accraditationList = [];
      this.headSummaryData.forEach(o => { // 高亮显示
        if (item.title == o.title) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      if (item.title == this.headSummaryOtherData.title) {
        this.headSummaryOtherData.isSelected = true;
      } else {
        this.headSummaryOtherData.isSelected = false;
      }
      this.searchObj.type = item.StatusKey;
      this.searchObj.searchText = '';
      this.searchObj.saleMan = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleManValue = '全部';
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 销售人员层显示并获取数据
    setAfterDay (dateStr) {
      if (!dateStr) {
        return '';
      }
      let times = Date.new(dateStr).getTime() - Date.new(Date.new().toLocaleDateString()).getTime();
      if (times == 0) {
        return '今天';
      } else if (times > 0) {
        return (times / 86400000) + '天后';
      } else {
        return '已过期';
      }
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.PastDueType = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '全部';
      // this.searchObj.type = 0;
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      console.log(this.searchObj);

      SearchPageToShopTryClassList(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.headSummaryData[0].value = result.data.CountStat.AllNum;
        this.headSummaryData[1].value = result.data.CountStat.toShopNum;
        this.headSummaryData[2].value = result.data.CountStat.tryClassNum;
        this.headSummaryOtherData.value = result.data.CountStat.noFollowNum;
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    closeshowAllot () {
      this.showAllot = false;
    },
    // 分配
    allotData (item) {
      this.seletedInfo = item;
      this.studentArray = [];
      this.studentArray.push(this.seletedInfo.StudentKey);
      this.showAllot = true;
    },

    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.$dialog.open(this, {
            name: '学生档案',
            routerName: this.$route.name,
            moduleName: 'studentFile',
            data: { studentID: rowData.StudentNumKey }
          });

          break;
        default:
          break;
      }
    },
    selectOverdueType (item) {
      console.log('selectOverdueType');
      this.searchObj.saleMan = item.value;
      this.searchObj.saleManValue = item.label;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.tryCourseDialogFactory) {
        this.$refs.tryCourseDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.tryCourseDialogFactoryCommon) {
        this.$refs.tryCourseDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'tryCourse') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'tryCourse') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }

  }
};
</script>