<!-- 金额 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :min-width="columnData.minWidth"
                   :width="columnData.width"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'center'"
                   :show-overflow-tooltip="vShowOverflowTooltip"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass">
    <template slot-scope="scope">
      <div v-if="scope.row[columnData.prop]>0"
           @click="clickEvent(scope.row,$event)">
        <span :class="[columnData.extend&&columnData.extend.className]">
          {{formatData(scope.row)}}
        </span>
      </div>
      <div v-else>
        <span> {{formatData(scope.row)}} </span>
      </div>
    </template>
  </el-table-column>
</template>
<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            click: () => { } // 表示该列可点击，点击需要的其它操作直接写在回调函数里
          }
        };
      }
    }
  },
  computed: {
    vShowOverflowTooltip () {
      if (typeof this.columnData.showOverflowTooltip == 'boolean') {
        return this.columnData.showOverflowTooltip;
      } else {
        return true;
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData, e) {
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 展开列
        if (this.columnData.extend.type == 'expand') {
          let cellClientWidth = e.currentTarget.parentNode.offsetParent.clientWidth;
          let positionLeft = e.currentTarget.parentNode.offsetParent.offsetLeft + cellClientWidth / 2;
          this.$emit('onClickExpandRow', rowData, this.columnData, positionLeft);
        } else {
          // 普通点击事件 直接执行回调
          this.columnData.extend.click(rowData);
        }
      }
    },
    // 保留小数位数
    formatData (rowData) {
      if (!rowData.isEmpertyRow) {
        // console.log('formatDataformatData', rowData, rowData[this.columnData.prop], this.columnData.prop);
        if (rowData[this.columnData.prop] === '') {
          return rowData[this.columnData.prop];
        } else if (rowData[this.columnData.prop] == 0 || rowData[this.columnData.prop] == '-') {
          return '-';
        } else {
          return this.$utils.mAmountType(rowData[this.columnData.prop], this.columnData.decimalPlace || 0);
        }
      } else {
        return '';
      }
    }
  }
};
</script>

<style>
</style>