<template>
  <div v-if="authorizationInfo">
    <div class="right_title">
      <div class="title_right">授权设备:
        <span class="font_black">{{authorizationInfo.AuthorizeDeviceNum}}</span> 台（已授权设备
        <span :class="vPowerCss"
              @click="showAuth">{{authorizationInfo.AuthDeviceNum||0}}</span>台）
      </div>
      <div class="right_form_info_tip">
        <input-form-tip :tips="vAuthorizeTips"></input-form-tip>
      </div>
    </div>
    <custom-dialog :title="'授权设备'"
                   width="740px"
                   :visible.sync="showAuthList"
                   :before-close="closeAuthListDialog">
      <auth-manager-list :dataInfo="{CoursewarePackageKey:maxCoursewareID}"
                         @afterSucces="afterSucces"></auth-manager-list>
    </custom-dialog>
  </div>
</template>
<script>
// auth-components
import authManagerList from '../../pop-up-menu/auth-components/auth-manager-list';
export default {
  components: {
    authManagerList
  },
  data () {
    return {
      showAuthList: false
    };
  },
  props: {
    authorizationInfo: Object,
    maxCoursewareID: [String, Number]
  },
  created () {
    console.log(this.authorizationInfo, 'authorizationInfo');
  },
  computed: {
    vAuthorizeTips () {
      let SQFS = '';// 授权方式
      if (this.authorizationInfo.ClearingFormKey == 1 && Number(this.authorizationInfo.ReleasedNum) > 0) {
        SQFS = '课次授权' + Number(this.authorizationInfo.ReleasedNum) + '课次' +
          '(剩余' + (Number(this.authorizationInfo.ReleasedNum) - Number(this.authorizationInfo.UsageNum)) + '课次)';
      } else if (this.authorizationInfo.ClearingFormKey == 2) {
        SQFS = '按年授权' + (Number(this.authorizationInfo.ExpireTime.slice(0, 4)) - Number(this.authorizationInfo.AuthorizeTime.slice(0, 4))) + '年;' +
          '(剩余' + this.$utils.DateDiffDay(this.$utils.formatDateToLine(Date.new()), this.authorizationInfo.ExpireTime.slice(0, 10)) + '天)';
      }else if (this.BigCoursewarePackageInfo.ClearingFormKey == 4) {
        SQFS = '按天授权' + (Number(this.authorizationInfo.ExpireTime.slice(0, 4)) - Number(this.authorizationInfo.AuthorizeTime.slice(0, 4))) + '年;' +
          '(剩余' + this.$utils.DateDiffDay(this.$utils.formatDateToLine(Date.new()), this.authorizationInfo.ExpireTime.slice(0, 10)) + '天)';
          } else {
        SQFS = this.authorizationInfo.ClearingFormKeyValue;
      }
      let text = `<div>
      授权方：${this.authorizationInfo.SaaSClientKeyValue}<br/> 
      经办人：${this.authorizationInfo.OperatorKeyValue}<br/> 
      授权日期：${this.$utils.formatDateToYYYYMMDD(this.authorizationInfo.AuthorizeTime)}<br/> 
      授权方式：${SQFS}
      </div>`;
      return text;
    },
    vPowerCss () {
      // 有权限
      if (this.vHasModulePower(123) && !this.authorizationInfo.type) {
        return 'font_blue';
        // 无权限
      } else {
        return 'font_gray';
      }
    }
  },
  methods: {
    showAuth () {
      // return false;
      if (this.vHasModulePower(123) && !this.authorizationInfo.type) {
        this.showAuthList = true;
      } else {
        this.$utils.CheckModulePowerToTips(123);
      }
    },
    afterSucces () {
      this.$emit('afterSuccesAuth');
    },
    closeAuthListDialog () {
      this.showAuthList = false;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    }
  }
};
</script>

<style>
.right_form_info_tip .header_tips_icon {
  background-color: #909399;
  padding: 0;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 8px;
}
</style>
