
<template>
  <!-- 跟班级排课 -->
  <div>
    <!-- 操作流程 -->
    <div v-show="isShowProcess">
      <operation-process :processList="processDataList"
                         @onClickEvent="doAfterClickProcessStep"></operation-process>
    </div>
    <!-- 开始操作=》学生列表=》课程名称列表=》课程排课首页-->
    <div>
      <select-arrange-step-list :curShowProcessStepInfo="curShowProcessStepInfo"
                                :processStepNames="processStepNames"
                                :singleArrangeList="studentSquadClass"
                                :externalSelectTypeKey="curSelectedClassInfo.OLAPKey"
                                @clickBeginOperation="clickBeginOperation"
                                @selectedChooseStudent="doAfterChooseStudent"
                                @selectedCourseOrClassName="doAfterChooseClass"></select-arrange-step-list>
    </div>
    <!-- 学生跟班级排课 以课程名称分组 -->
    <div v-if="vShowArrangeTime">
      <!-- 学生信息 -->
      <student-lable class="content_marginTop_30 single_shadow content_marginRL"
                     :studentID="studentInfo.OLAPKey"
                     :readonly="false"
                     :CustStatusKey="1"
                     @alterStudent="doAfterChooseStudent"></student-lable>
      <!-- 选择班级 班级汇总信息 -->
      <div style="border-top: 2px solid #ededed">
        <class-summary :formTitle="'选择班级'"
                       :formTitleKey='"BanJi"'
                       :selectedData="curSelectedClassInfo"
                       :singleArrangeList="studentSquadClass"
                       :collectSummaryInfo="studentTicketPlanForGradeApplyClass"
                       @selectedCourseOrClassName="doAfterChooseClass"
                       @classOperateButton="classOperateButton"></class-summary>
      </div>
      <!-- 班级课块列表 -->
      <div>
        <!-- 选项卡菜单 -->
        <tab-menu :tabMenuList="tabMenuList"
                  @clickEvent="doAfterSwitchTab"></tab-menu>
        <!-- 循环课表 -->
        <div v-if="curSelectedTab.type==tabType.circleCourseTable"
             class="all_course_table_content"
             @scroll="doAfterEventScrollTOP">
          <rule-course-list v-for="item in allCourseTableList"
                            :key="item.index"
                            :dataObj="item"
                            :popupsMenu="popUpOperationList"
                            @openOrCloseDetail="downOrUpDetail"
                            @doOneTopValueEvent="doOneTopValueEvent"></rule-course-list>
          <!-- 无数据展示 -->
          <div v-if="!allCourseTableList.length"
               class="course_block_nodata">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
        <!-- 精准 排课 -->
        <div v-else>
          <precise-courses-arranging-month-view :studentInfo="studentInfo"
                                                :selectClassInfo="curSelectedClassInfo"
                                                :selectCourseInfo="curSelectedCircleCourseData"
                                                :optionStatusList="optionStatusList"
                                                @upLoadArrangeStatisticsInfo="getTicketPlanForGradeApplyClass"></precise-courses-arranging-month-view>
        </div>
      </div>
      <!-- 点击学生 展开更多操作 -->
      <div class="course_student_attendance_operation module_content_menuList"
           :style="{'top':topValue+'px','left':'290px'}"
           v-show="isShowItem">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="popUpOperationList"
                       @clickOptionForAlertDialog="doClickPopItem"></drop-down-box>
      </div>
    </div>

  </div>
</template>

<script>
import ruleCourseList from '../student-relation-course-component/rule-course-list';
import tabMenu from '../../relation-course-component/tab-menu';
import batchArrangeCourse from '../student-relation-course-component/batch-arrange-course';
import freeChoiceArrangeCourse from '../student-relation-course-component/free-choice-arrange-course';

import classSummary from '../selected-coursename-or-class-summary';
import operationProcess from '../../common/operation-process';
import studentLable from '../../common/student-lable';
import selectArrangeStepList from '../select-arrange-step-list';
import preciseCoursesArrangingMonthView from '../precise-courses-arranging-month-view';
import dropDownBox from '../../common/drop-down-box';
import {
  SearchStructedRule,
  getStudentArrangRuleByGrade,
  GetCourseAndStudentCourseArrangByRule,
  CancelStudent2MutiSameApplyClassCourseByRule,
  getUsingClass,
  GetTicketPlanForGradeApplyClass,
  ArrangeStudent2MutiSameApplyClassCourseByRule,
  GetTheClassByID,
  studentAddClass,
  QuitStudentFromClass
} from '../../../API/workbench';
export default {
  data () {
    return {
      processDataList: [
        {
          index: 0,
          title: '选择学生',
          stepName: '1',
          type: 'chooseStudent',
          status: 'undo',
          calssName: '',
          left: 0
        },
        {
          index: 1,
          title: '选择班级',
          stepName: '2',
          type: 'chooseCourseName',
          status: 'undo',
          calssName: '',
          left: '50%'
        },
        {
          index: 2,
          title: '安排时间',
          stepName: 'end',
          type: 'arrangeTime',
          status: 'undo',
          calssName: '',
          left: '100%'
        }
      ],
      processStepNames: {
        chooseStudent: '1',
        chooseCourseName: '2',
        arrangeTime: 'end'
      },
      studentInfo: {}, // 学生信息
      paramsForRule: {
        StudentKey: 0,
        StructuredRuleKey: 0,
        StartTime: '',
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      },
      topValue: 0, // 默认第一个top值
      newScrollTop: 0, // 滚动距离
      isShowItem: false,
      isTheBatchProcessShow: false, // 控制批量排课显示
      isOptionalCourseArrangementShow: false, // 控制自选排课显示

      popUpOperationList: [
        {
          type: 1,
          name: '批量排课',
          eventName: 'batch',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          type: 2,
          name: '自选排课',
          eventName: 'freeChoice',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          type: 3,
          name: '退出已排的课',
          eventName: 'cancelAllArrange',
          isShow: true,
          ModulePowerKey: 47
        }
      ],
      tabMenuList: [
        { title: '循环课表', isSelected: true, type: 0 },
        { title: '精准排课', isSelected: false, type: 1 }
      ],
      tabType: {
        circleCourseTable: 0, // 循环课表
        accurateArrangeCourse: 1// 精准排课
      },
      curSelectedTab: null, // 当前选中的选项卡
      curShowProcessStepInfo: {}, // 当前显示的流程步骤
      curSelectedCircleCourseData: {}, // 当前选中课信息
      curSelectedClassInfo: {}, // 当前选中班级
      courseTableList: [], // 所有课信息
      allCourseTableList: [],
      studentArrangedCourseList: [], // 学生已安排的课
      classList: [],
      studentSquadClass: [], // 班级列表

      scheduleCourseList: [],
      arrangeRules: {},
      courseNameApplyCourseData: {},
      studentTicketPlanForGradeApplyClass: {}, // 获取此学生指定班级所有报课计划
      showBox: false,
      optionStatusList: [
        {
          type: 0,
          name: '搜索全部',
          eventName: 'clear',
          isShow: true
        },
        {
          type: 1,
          name: '按老师搜索',
          eventName: 'teacher',
          isShow: true
        },
        {
          type: 3,
          name: '按课程搜索',
          eventName: 'course',
          isShow: true
        }
      ],
      isShowProcess: true
    };
  },
  props: {
    studentID: String,
    classNameID: String,
    className: String
  },
  components: {
    ruleCourseList,
    operationProcess,
    studentLable,
    classSummary,
    tabMenu,
    selectArrangeStepList,
    freeChoiceArrangeCourse,
    preciseCoursesArrangingMonthView,
    batchArrangeCourse,
    dropDownBox
  },
  computed: {
    vShowArrangeTime () {
      return this.curShowProcessStepInfo.stepName == this.processStepNames.arrangeTime;
    },
    vCourseList () {
      let newList = [];
      this.courseTableList.forEach(obj => {
        let arrangedCourse = this.studentArrangedCourseList.find(item => {
          return obj.CourseNameKey == item.CourseNameKey && obj.WeekKey == item.WeekKey && obj.ClassTimeName == item.ClassTimeName;
        });
        if (arrangedCourse) {
          this.$set(obj, 'alreadyAttenCount', this.$utils.mAmountType(arrangedCourse.ClassCount, 0));
        } else {
          this.$set(obj, 'alreadyAttenCount', 0);
        }
        let course = newList.find(item => {
          return item.OLAPKey == obj.CourseNameKey;
        });
        // 课程名称不存在 添加一项
        if (!course) {
          newList.push({ OLAPKey: obj.CourseNameKey, MainDemoName: obj.CourseNameKeyValue, dataList: [obj] });
        } else {
          course.dataList.push(obj);
        }
      });
      return newList;
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  watch: {
    vCourseList (n, o) {
      this.allCourseTableList = [];
      n.forEach(obj => {
        if (n.length > 1) {
          obj.isOpen = false;
        } else {
          obj.isOpen = true;
        }
        this.allCourseTableList.push(obj);
      });
    }
  },
  created () {
    this.initData();
    this.curSelectedTab = this.tabMenuList[0];
    this.$bus.on(this, 'afterArrangeScheduleCourse', (studentKey) => {
      if (this.curSelectedCircleCourseData && this.curSelectedCircleCourseData.StructuredRuleKey > 0) {
        this.doAfterArrangeStudentSuccess();
      }
    });
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowItem = false;
    }, true);
  },
  methods: {
    initData () {
      this.showBox = true;
      if (this.studentID) {
        this.isShowProcess = false;
        this.updateProcessStatus(this.processDataList[0]);
        this.doAfterChooseStudent({ OLAPKey: this.studentID }, 'showProcess');
      }
      if (this.classNameID) {
        this.updateProcessStatus(this.processDataList[1]);
        GetTheClassByID(this.classNameID).then(result => {
          this.doAfterChooseClass(result.data, 'showProcess');
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 关闭模块
    closeDialog () {
      this.curSelectedTab.isSelected = false;
      this.tabMenuList[0].isSelected = true;
      this.curSelectedTab = this.tabMenuList[0];
      this.showBox = false;
      this.curShowProcessStepInfo = {};
      this.processDataList.forEach(o => {
        o.status = 'undo';
        o.calssName = '';
      });
      this.isShowItem = false;
      this.isShowProcess = true;
      this.curSelectedCircleCourseData = {};
      this.newScrollTop = 0;
    },
    // 获取班级下的所有循环课
    loadStructedRule (type) {
      SearchStructedRule(type == 'refresh' ? this.curSelectedCircleCourseData.CourseNameKey : '', this.curSelectedClassInfo.OLAPKey).then(result => {
        this.getStuArrangRuleByGrade(() => {
          if (type == 'refresh') {
            let findItem = result.data.find(obj => { return obj.StructuredRuleKey == this.curSelectedCircleCourseData.StructuredRuleKey; });
            if (findItem) {
              if (this.vIsSysVersionTY) {
                this.curSelectedCircleCourseData.Num_SyncArranged = findItem.Num_SyncArranged;
              } else {
                this.curSelectedCircleCourseData.Num_InGrade = findItem.Num_InGrade;
              }
            }
          } else {
            this.courseTableList = result.data;
          }
        }, type);
      });
    },
    // 获取学生所有排课规律信息.
    getStuArrangRuleByGrade (callback, type) {
      getStudentArrangRuleByGrade(this.studentInfo.OLAPKey, this.curSelectedClassInfo.OLAPKey).then(result => {
        // 对于排课后 刷新已排节数
        if (type == 'refresh') {
          let findItem = result.data.find(obj => { return obj.OLAPKey == this.curSelectedCircleCourseData.StructuredRuleKey; });
          if (findItem) {
            this.curSelectedCircleCourseData.alreadyAttenCount = findItem.ClassCount;
          } else {
            this.curSelectedCircleCourseData.alreadyAttenCount = 0;
          }
        } else {
          this.studentArrangedCourseList = result.data;
        }
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    // 班级列表
    getStudentUsingClassList () {
      getUsingClass().then(result => {
        this.studentSquadClass = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 加入班级/退出班级
    classOperateButton (classItem, IsInGrade) {
      if (!IsInGrade) {
        studentAddClass(this.studentInfo.OLAPKey, classItem.selectTypeKey).then(result => { // 加入
          GetTheClassByID(classItem.selectTypeKey).then(result => {
            this.getTicketPlanForGradeApplyClass();
            this.loadStructedRule('refresh');
            this.getStudentUsingClassList();
            this.curSelectedClassInfo = result.data;
            // layer.alert(this.studentInfo.MainDemoName + '同学加入班级成功!');
          });
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        QuitStudentFromClass(this.studentInfo.OLAPKey, classItem.selectTypeKey).then(result => { // 退出
          GetTheClassByID(classItem.selectTypeKey).then(result => {
            this.getTicketPlanForGradeApplyClass();
            this.loadStructedRule('refresh');
            this.getStudentUsingClassList();
            this.curSelectedClassInfo = result.data;
            // layer.alert(this.studentInfo.MainDemoName + '同学退班成功!');
          });
        }, error => {
          layer.alert(error.msg);
        });
      }
      this.$emit('afterCancelAllArrangeSuccess');// 触发回调事件
    },
    // 获取此学生指定班级所有报课计划
    getTicketPlanForGradeApplyClass () {
      GetTicketPlanForGradeApplyClass(this.studentInfo.OLAPKey, this.curSelectedClassInfo.OLAPKey).then(result => {
        this.studentTicketPlanForGradeApplyClass = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 点击开始操作
    clickBeginOperation () {
      this.updateProcessStatus(this.processDataList[0]);
    },
    // 选中学生后
    doAfterChooseStudent (item, type) {
      this.studentInfo = item;
      if (type == 'showProcess') {
        this.updateProcessStatus(this.processDataList[1]);
        this.getStudentUsingClassList();
      } else {
        this.getTicketPlanForGradeApplyClass();
        this.loadStructedRule();
      }
    },
    // 选中班级后
    doAfterChooseClass (item, type) {
      console.log(this.curSelectedClassInfo, '当前选中班级信息');
      this.curSelectedClassInfo = item; // 当前选中班级信息
      if (type == 'showProcess') {
        this.updateProcessStatus(this.processDataList[2]);
      }
      this.getTicketPlanForGradeApplyClass();
      this.loadStructedRule();
    },
    // 变更流程状态
    updateProcessStatus (stepInfo) {
      if (stepInfo.stepName == 'end') {
        stepInfo.calssName = 'complete';
      } else {
        stepInfo.calssName = 'the';
      }
      stepInfo.status = 'doing';
      if (JSON.stringify(this.curShowProcessStepInfo) != '{}') {
        this.curShowProcessStepInfo.calssName = 'tick';
        this.curShowProcessStepInfo.status = 'done';
      }
      this.curShowProcessStepInfo = stepInfo;
    },
    // 点击流程步骤
    doAfterClickProcessStep (item) {
      item.status = 'doing';
      item.calssName = 'the';
      // 往前点 后面的样式要变
      this.processDataList.forEach(obj => {
        if (obj.index > item.index) {
          obj.status = 'undo';
          obj.calssName = '';
        }
      });
      this.curShowProcessStepInfo = item;
    },
    // 切换选项卡
    doAfterSwitchTab (item) {
      this.newScrollTop = 0;
      this.curSelectedTab.isSelected = false;
      item.isSelected = true;
      this.curSelectedTab = item;
      if (this.curSelectedTab.type == this.tabType.circleCourseTable) {
        this.loadStructedRule();
      } else {
        this.curSelectedCircleCourseData = {};
      }
    },
    // 查询 根据学生ID 排课规律ID 查所有的排课信息
    getCourseAndStudentCourseArrangByRule () {
      GetCourseAndStudentCourseArrangByRule(this.studentInfo.OLAPKey, this.curSelectedCircleCourseData.StructuredRuleKey).then(result => {
        if (result.code == 0) {
          this.scheduleCourseList = result.data.ScheduleCourseList;
          this.arrangeRules = result.data.ArrangeRules; // 0:未排 1：全部 2：部分 自定义
        }
      });
    },
    // 展开收起
    downOrUpDetail (item) {
      item.isOpen = !item.isOpen;
      this.isShowItem = false;
    },
    // 点击一节课 弹出层位置计算
    doOneTopValueEvent (courseInfo, e) {
      this.curSelectedCircleCourseData = courseInfo;
      if (this.vIsSysVersionTY) {
        if (this.curSelectedCircleCourseData.alreadyAttenCount > 0) {
          layer.confirm('请确认是否退出当前循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let IsSyncArrangedKey = 1;// 是否循环跟排 0-否;1-是
                  this.cancelAllArrangeClick(IsSyncArrangedKey);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.confirm('请确认是否安排进当前循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.addToCircleCourseForStudent();
                  layer.close();
                }
              }
            ]
          });
        }
      } else {
        this.doOneTopValueCount(e);
        this.getCourseAndStudentCourseArrangByRule();
      }
    },
    // 通用版 安排进循环课
    addToCircleCourseForStudent () {
      let params = {
        StudentKey: this.studentInfo.OLAPKey,
        StructuredRuleKey: this.curSelectedCircleCourseData.StructuredRuleKey,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: this.curSelectedCircleCourseData.LastClassTime,
        ClassCount: Number(this.curSelectedCircleCourseData.ClassCount),
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      ArrangeStudent2MutiSameApplyClassCourseByRule(params).then(result => {
        this.$bus.emit('afterArrangeCirculationCourse');
        this.doAfterArrangeStudentSuccess();
      })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 计算TOP值
    doOneTopValueCount (e) {
      let offsetTop = e.currentTarget.offsetTop;
      this.topValue = offsetTop - this.newScrollTop + 42;
      this.isShowItem = true;
    },
    // 滚动后触发获取弹窗位置
    doAfterEventScrollTOP (e) {
      this.newScrollTop = e.target.scrollTop;
      this.isShowItem = false;
    },
    // 点击弹出层项
    doClickPopItem (menu) {
      if (menu.eventName == 'batch' || menu.eventName == 'cancelAllArrange') {
        this.paramsForRule.StudentKey = this.studentInfo.OLAPKey;
        this.paramsForRule.StructuredRuleKey = this.curSelectedCircleCourseData.StructuredRuleKey;
        this.paramsForRule.StartTime = this.$utils.formatDateToLine(Date.new());
      }
      let paramsData = { studentKey: this.studentInfo.OLAPKey, courseNameKey: this.curSelectedCircleCourseData.CourseNameKey, classGradeKey: this.curSelectedCircleCourseData.GradeClassKey, ruleID: this.curSelectedCircleCourseData.StructuredRuleKey };

      switch (menu.eventName) {
        case 'batch':
          let item = {
            routerName: this.$route.name,
            moduleName: 'batchArrangeScheduleCourse',
            data: { paramsInfo: paramsData }
          };
          this.$bus.emit('needOpenRouter', item);
          break;
        case 'freeChoice':
          let moduleInfo = {
            routerName: this.$route.name,
            moduleName: 'freeChoiceArrangeScheduleCourse',
            data: { paramsInfo: paramsData }
          };
          this.$bus.emit('needOpenRouter', moduleInfo);
          break;
        case 'cancelAllArrange':
          layer.confirm('请确认是否退出已排的课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.cancelAllArrangeClick();
                  layer.close();
                }
              }
            ]
          });
          break;
        default:
          break;
      }
      this.isShowItem = false;
    },
    // 取消全部安排
    cancelAllArrangeClick (IsSyncArrangedKey) {
      if (!this.curSelectedCircleCourseData.alreadyAttenCount) {
        return;
      }
      CancelStudent2MutiSameApplyClassCourseByRule(this,this.studentInfo.OLAPKey, this.curSelectedCircleCourseData.StructuredRuleKey, IsSyncArrangedKey).then(result => {
        this.doAfterArrangeStudentSuccess();
        this.$bus.emit('afterArrangeCirculationCourse');
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAfterArrangeStudentSuccess (cancelArr, arrangeArr) {
      this.loadStructedRule('refresh');
      this.getTicketPlanForGradeApplyClass();
    },
    // 关闭批量安排弹窗
    closeTheBatchProcessShow () {
      this.isTheBatchProcessShow = false;
    },
    // 关闭自选排课界面
    closeOptionalCourseArrangementShow () {
      this.isOptionalCourseArrangementShow = false;
    }
  }

};
</script>

<style>
</style>