<template>
  <div v-if="visible">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'containerDiv',
  data () {
    return {
    };
  },
  props: {
    ModulePowerKey: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasPower () {
      if (this.ModulePowerKey > 0) {
        return this.$utils.CheckModulePower(
          this.$store.getters.RolePowerList,
          this.item.ModulePowerKey
        );
      }

      return true;
    },
    vPowerCss () {
      // 有权限
      if (this.hasPower) {
        return '';
        // 无权限
      } else {
        return 'norole';
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
};
</script>
<style>
</style>