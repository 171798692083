<!-- 多级表头 -->
<template>
 <el-table-column :fixed="columnData.fixed||false"
                              :label="columnData.label"
                              :prop="columnData.prop"
                              :width="columnData.width||200"
                              :align="columnData.align||'center'"
                              :label-class-name="columnData.columnClass">
  <component v-for="columnInfo in columnData.extend.childColumn" :key="columnInfo.prop" :is="columnInfo.type" :columnData="columnInfo"></component>
</el-table-column>
</template>

<script>
import textItem from './text-item';
import student from './student';
import dateItem from './date-item';
import readingCourses from './reading-courses';
import classHour from './class-hour';
import amountItem from './amount-item';
import numberItem from './number-item';
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            childColumn: {//  多级标题的子标题 跟其他的column列配置一致
              type: Array,
              default: () => {
                return [];
              }
            }
          }
        };
      }
    }
  },
  components: {
    textItem,
    student,
    dateItem,
    readingCourses,
    classHour,
    amountItem,
    numberItem
  },
  created () {
  },
  methods: {
  }
};
</script>

<style>

</style>