<template>
  <div class="form_info_line"
       :class="{form_info_required:required}">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value form_info_select " :class="readonly?'form_info_select_readonly':''"
         id="cascader_input">
        <span class="input_span" :class="value?'font_blue':'input_readonly'" style="padding-right:27px">{{value?value:'请选择'}}</span>
       
        <el-cascader v-model="selectedOptions"
                   ref="cascaderAddr"
                    :disabled="readonly"
                     :class="readonly?'input_readonly':className"
                   placeholder="请选择省市"
                   :options="options"
                   @change="seletdItem"
                   :props="props">
      </el-cascader>
    </div>
  </div>
</template>

<script>
import { SelectProvince, SelectCityForProvince } from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      selectedOptions: [],
      options: [],
      adgadg: false,
      props: {
        value: 'TableID',
        label: 'name',
        children: 'cities',
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level;
          if (level === 1) {
            console.log(node.level);
            const id = node.value;
            SelectCityForProvince(id).then(res => {
              const { code, msg } = res;
              const list = res.data;
              console.log(list, 'SelectCityForProvince');
              if (code === 0) {
                const nodes = list;
                nodes.forEach(item => {
                  item.leaf = level >= 1;
                });
                resolve(nodes);
              }
            });
          }
        }
      }

    };
  },
  props: {
    formTitle: String,
    value: {
      type: [String, Number, Boolean],
      default: ''
    }, // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: 'input_blue'
    },
    selectedInfo: Array, // [1,2,3]
    readonly: {
      type: Boolean,
      default: false
    },
    helpDescription: String // 表单说明提示
  },
  created () {

  },
  mounted () {
    this.getData();
  },
  watch: {

  },
  methods: {
    seletdItem (item) {
      console.log('seletdItem', item);
      this.$emit('selectedItem', this.selectedOptions, this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels);
    },
    getData (val) {
      this.getNodes(val);
    },
    getNodes (val, callBack) {
      console.log(val, callBack);
      // if (!val) {
      SelectProvince().then(result => {
        this.options = result.data.map((item, i) => {
          item.name = item.name.replace('市', '');
          return {
            TableID: item.TableID,
            name: item.name,
            cities: []
          };
        });
      });
    }
  }
};
</script>
<style >
#cascader_input .el-cascader {
  display: inline-block;
  font-size: 14px;
  line-height: 26px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
#cascader_input .el-cascader .el-input {
  width: 300px;
}
#cascader_input .el-cascader .el-cascader__label {
  text-align: right;
}
#cascader_input .el-cascader .el-input__suffix-inner {
  position: absolute;
  right: -4px;
  bottom: 2px;
  display: none;
}
/* #cascader_input .el-cascader .el-cascader .el-icon-arrow-down {
  font-size: 16px;
} */
/* 滚动条的宽度 */
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.input_readonly input {
  color: #999 !important;
}
.input_readonly .el-input.is-disabled .el-input__inner {
  background: none;
}
.form_info_select.form_info_select_readonly{
  background: none !important;
}
.form_info_select_readonly .input_span{
 color: #999 !important;
}

</style>
