<template>
  <div class="all_course_table_title clearfix">
    <tab-item v-for="(item,index) in tabMenuList"
              :key="index"
              :tabItem='item'
              @clickEvent="onClickEvent">
    </tab-item>
  </div>
</template>

<script>

import tabItem from './tab-item';
export default {
  props: {
    tabMenuList: {
      type: Array,
      required: true,
      default: () => {
        return [
          { title: '循环课表', isSelected: true, type: 0 }
        ];
      }
    }
  },
  components: {
    tabItem
  },
  methods: {
    onClickEvent (item) {
      this.$emit('clickEvent', item);
    }
  }
};
</script>

<style>
</style>