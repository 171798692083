<template>
  <!-- 权限设置 -->
  <div>
    <!-- 文字说明模板 -->
    <explain-module :required="true"
                    :explainText="'系统按角色分配权限。录入员工时分配角色权限，员工即获得相应权限。'"></explain-module>
    <div class="single_shadow content_marginRL content_marginTop_30">
      <div class="clearfix pr"
           style="height: 93.5%;">
        <div class="permission_setting_rank fl">
          <div class="rank_list_type">推荐角色</div>
          <div class="rank_list"
               :class="{'opt':item.isActive}"
               v-for="item in vRecommendRoleList"
               :key="item.OLAPKey"
               @click="doSelectRoleClick(item)">
            <div class="text_overflow_hidden">{{item.MainDemoName}}</div>
          </div>
          <div class="rank_list_type">自定义角色</div>
          <div class="rank_list"
               :class="{'opt':item.isActive}"
               v-for="item in vCustomRoleList"
               :key="item.OLAPKey"
               @click="doSelectRoleClick(item)">
            <div class="text_overflow_hidden">{{item.MainDemoName}}</div>
          </div>
          <div class="add_list rank_list">
            <span class="list_icon">+</span>
            <span @click="doAddRoleClick">角色新增</span>
          </div>
        </div>
        <div class="permission_setting_content fr"
             v-if="newSearchModuleListInit.length>0&&isLoad">
          <div class="content_btn clearfix"
               v-if="isShowRoleBut">
            <div class="fl btn_hover_blue"
                 @click="doEditRoleClick">修改角色名称</div>
            <div class="fr btn_hover_blue"
                 @click="doDeleteRoleClick">删除角色</div>
          </div>
          <div class="content_ul">
            <div class="content_list"
                 v-for="(item,index) in newSearchModuleListInit"
                 :key="index">
              <div class="content_list_title clearfix">
                <div class="fl">{{item.MainDemoName}}</div>
                <div class="fr"
                     :class="{'opt':(item.isOpt) ||(item.Count == item.childTree.length)}"
                     @click="doCheckAllClick(item)"
                     v-if="searchModulePowerList.length>0">
                  <span></span>
                  全选
                </div>
                <div v-else>(空)</div>
              </div>
              <div class="content_list_ul clearfix"
                   v-if="item.childTree.length>0">
                <div v-for="(childItem,index) in item.childTree"
                     :key="index">
                  <div class="list_block" :title="childItem.MainDemoName"
                       v-if="childItem.isShow"
                       :class="{'opt':(item.isOpt)|| (childItem.isSingleOpt) }"
                       @click="doChildItemClick(childItem,item)">
                    {{childItem.MainDemoName}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="monitoring_content_nodata">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                           :cancelBtnText="'还原'"
                           :btnText="'确认'"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="doAffirmClick"
                           @cancelClick="doRestoreClick"></save-cancel-btn-group>
    <div>
      <custom-dialog :title="formTitleName.value"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isPowerLevelFormShow"
                     :before-close="doShowPopupHideLevelClick">
        <setting-item :formTitleItem="formTitleName"
                      :Item="selectRoleItem"
                      @cancelClick="doCancelLevelFormClick"></setting-item>
      </custom-dialog>
    </div>
  </div>
</template> 

<script>
import settingItem from './setting-item';
import {
  hqSearchModulePower,
  hqSearchRole,
  hqGetRole,
  hqEditRole,
  hqDeleteRole,
  GetSaaSClientSet
} from '../../../../API/workbench';
export default {
  data () {
    return {
      isShowRoleBut: true, // 是否显示修改和删除角色按钮
      // isPowerFormShow: false, // 控制显示主表单
      isPowerLevelFormShow: false, /// / 控制显示子表单
      formTitleName: { value: '', key: '' }, // 名字
      searchRoleList: [], // 获取角色列表
      selectRoleItem: {}, // 选择角色项
      isRestore: false, // 防止重复点击还原操作
      // copySearchModuleListInit: [], // 拷贝模块列表
      newSearchModuleListInit: [], // 重新初始化模块列表
      newRolePermissionList: [], // --角色权限list
      searchModuleList: [], // 模块列表
      searchModulePowerList: [], // 权限列表
      childItemCount: 0,
      isLoad: false, // 加载
      getSaaSClientSet: []
    };
  },
  props: [],
  components: {
    settingItem
  },
  computed: {
    // 推荐角色(1号店系统负复制过来的角色)
    vRecommendRoleList () {
      let list = [];
      this.searchRoleList.forEach(o => {
        if (Number(o.SysRoleKey) > 0) {
          list.push(o);
        }
      });
      return list;
    },
    // 自定义角色
    vCustomRoleList () {
      let list = [];
      this.searchRoleList.forEach(o => {
        if (!o.SysRoleKey || Number(o.SysRoleKey) == 0) {
          list.push(o);
        }
      });
      return list;
    },
    // 直营店是否显示
    vZYPower () {
      if (this.getSaaSClientSet.length > 0) {
        let locaIndex = this.getSaaSClientSet.findIndex(o => {
          return (o.SetTypeKey == 6 && o.SetKey == 1) || (o.SetTypeKey == 7 && o.SetKey == 1);
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 加盟店是否显示
    vJMPower () {
      if (this.getSaaSClientSet.length > 0) {
        let locaIndex = this.getSaaSClientSet.findIndex(o => {
          return (o.SetTypeKey == 4 && o.SetKey == 1) || (o.SetTypeKey == 5 && o.SetKey == 1);
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      }
    },
     vMendianInfo() {
      return this.$store.getters.SaaSClientInfo
    },
  },
  created () {
    this.getMenDianSet();
  },
  methods: {
    // 获取门店设置
    getMenDianSet () { 
      GetSaaSClientSet().then(result => {
        this.getSaaSClientSet = result.data;
        this.loadRoleData(); // 渲染列表数据
        console.log('GetSaaSClientSet', result.data);
      });
    },
    // 子表单开关
    doShowPopupHideLevelClick () {
      this.isPowerLevelFormShow = false;
    },
    // 子表单操作后触发回调
    doCancelLevelFormClick (item) {
      this.isPowerLevelFormShow = false;
      if (item) {
        this.isShowRoleBut = true;
        this.loadRoleData(item);
      }
    },
    newSearchModuleList () {
      let list = [];
      var item = {};
      // 父级模块
      this.searchModuleList.forEach(o => {
        o.isSingleOpt = false;
        if (o.ParentKey == 0) {
          item = {
            MainDemoName: o.MainDemoName,
            OLAPKey: o.OLAPKey || -1,
            ParentKey: o.ParentKey,
            ParentKeyValue: o.ParentKeyValue,
            RemarkSupport: o.RemarkSupport,
            isOpt: false,
            data: o,
            childTree: [],
            Count: 0
          };
          if (Number(o.OLAPKey) == 21 && Number(this.vMendianInfo.OLAPKey) == 1) { // 当门店为1号门店时，显示"治学运营"权限
            list.push(item);
          } else {
            if(Number(o.OLAPKey) ==28){
              // if(this.vMendianInfo.IsSignYKKey == 1||this.vMendianInfo.OLAPKey==3){
              //   list.push(item);
              // }
            }else if (Number(o.OLAPKey) == 24) {
              if(this.vMendianInfo.IsOpenChainKey == 1||this.vMendianInfo.OLAPKey==3){
                list.push(item);
              }
            }else if (Number(o.OLAPKey) != 21) {
              list.push(item);
            }
          }
        }
      });
      // 子级模块
      this.searchModulePowerList.forEach((p, index) => {
        this.$set(p, 'isShow', true);
        if (!this.vZYPower && p.OLAPKey == 105) { // 直营店管理
          this.$set(p, 'isShow', false);
        }
        if (!this.vJMPower && p.OLAPKey == 106) { // 加盟店管理
          this.$set(p, 'isShow', false);
        }
        list.forEach(o => {
          if (o.OLAPKey == p.ModuleKey) {
            if (p.OLAPKey != 72) { // 19：隐藏课时消耗分析权限
              if (this.vMendianInfo.TypeKey == 4 && p.OLAPKey == 87) { // 连锁总店:4(TypeKey),当门店不是连锁总店时，隐藏连锁门店统计权限
                o.childTree.push(p);
              } else if(this.vMendianInfo.IsOpenChainKey == 1&&p.OLAPKey == 129){    //督学设置
                o.childTree.push(p);
              }else {
                if (p.OLAPKey != 87) {
                  o.childTree.push(p);
                }
              }
            }
          }
        });
      });
      this.newSearchModuleListInit = list; // 还原的数据
      console.log(this.newSearchModuleListInit, '子级模块');
    },
    loadRoleData (item) {
      // 获取角色列表
      hqSearchRole().then(result => {
        result.data.forEach((o, i) => {
          if (item) {
            if (item.OLAPKey == o.OLAPKey) {
              o.isActive = true;
            } else {
              o.isActive = false;
            }
          } else {
            if (i == 0) {
              o.isActive = true;
            } else {
              o.isActive = false;
            }
          }
        });
        this.searchRoleList = result.data;
        if (item) {
          // 选择角色
          this.selectRoleItem = item; // 触发点击事件时选择当前角色
          console.log(item, '选择角色1');
        } else {
          // 默认角色
          if (this.searchRoleList.length > 0) {
            this.selectRoleItem = this.searchRoleList[0]; // 没触发点击事件时默认第一个角色
            // 判断默认选中的第一个角色是否为系统1号店过来的角色;
            if (Number(this.searchRoleList[0].SysRoleKey) > 0) {
              this.isShowRoleBut = false;
            } else {
              this.isShowRoleBut = true;
            }
            console.log(result.data, '选择角色0');
          }
        }
        // 获取模块列表
        hqSearchModulePower().then(res => {
          console.log(res.data, this.vMendianInfo, '模块查询');
          // 2.权限-》营销相关（微网校相关）：需开通微课堂 才显示

          if (Number(this.vMendianInfo.IsOpenMicroClassKey) == 0) { // if_open_shop-门店未开启微网校 IsOpenPay-支付
            this.searchModulePowerList = res.data.ModulePowerList.filter((obj) => {
              return obj.ModuleKey != 16; // 微网校设置
            });
            this.searchModuleList = res.data.ModuleList.filter((obj) => {
              return obj.OLAPKey != 16; // 微网校设置
            });
          } else {
            this.searchModuleList = res.data.ModuleList;
            this.searchModulePowerList = res.data.ModulePowerList;
          }

          this.newSearchModuleList();
          this.getRoleList(this.selectRoleItem.OLAPKey);
        }, error => {
          layer.alert(error.msg);
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取角色权限List
    getRoleList (OLAPKey) {
      hqGetRole(OLAPKey).then(result => {
        console.log(result.data.RolePermissionList, '获取角色所属模块权限-1');
        if (result.data.RolePermissionList.length > 0) {
          result.data.RolePermissionList.forEach(r => {
            this.newRolePermissionList.push({
              ModulePowerKey: r.ModulePowerKey
            });
          });
          this.newRolePermissionList = [];
          var newchildList = [];
          this.newSearchModuleListInit.forEach(p => {
            result.data.RolePermissionList.forEach(o => {
              p.childTree.forEach(c => {
                if (o.ModulePowerKey == c.OLAPKey) {
                  c.isSingleOpt = !c.isSingleOpt;
                  if (c.isSingleOpt == true) {
                    p.Count++;
                  }
                } else {
                  if (c.isSingleOpt == false) {
                    p.Count--;
                  }
                }
              });
            });
            if (p.Count == p.childTree.length) {
              this.$set(p, 'isOpt', true);
            } else {
              this.$set(p, 'isOpt', false);
            }
            newchildList.push(p);
          });
          this.newSearchModuleListInit = newchildList;
        }
        this.isLoad = true;
        console.log(this.newSearchModuleListInit, '获取角色所属模块权限-2');
      });
    },
    // 选择角色
    doSelectRoleClick (item) {
      console.log(item, '获取角色');
      if (Number(item.SysRoleKey) > 0) {
        this.isShowRoleBut = false;
      } else {
        this.isShowRoleBut = true;
      }
      // this.selectRoleItem = item;
      if (this.isRestore == true) {
        var _this = this;
        layer.confirm('是否放弃当前修改?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                _this.selectRoleItem = item;
                _this.doSelectInquiry(_this.selectRoleItem);
                layer.close();
              }
            }
          ]
        });
      } else {
        this.doSelectInquiry(item);
      }
    },
    maximum () {
      var isCache = 0;
      this.newSearchModuleListInit.forEach(o => {
        o.childTree.forEach(c => {
          if (c.isSingleOpt == true) {
            isCache++;
          } else {
            // isCache--;
          }
        });
      });
      // console.log(isCache, 'isCache');
      // console.log(this.newRolePermissionList.length, '数字');
      return isCache;
    },
    // 选择角色后的操作逻辑
    doSelectInquiry (item) {
      this.searchRoleList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.loadRoleData(item);
      this.isRestore = false;
    },
    // 选择全部模块
    doCheckAllClick (item) {
      // console.log(item, '选择全部');
      var newList = [];
      this.newSearchModuleListInit.forEach(o => {
        // o.Count = 0;
        if (o.OLAPKey == item.OLAPKey) {
          o.isOpt = !o.isOpt;
          if (o.isOpt == false) {
            o.childTree.forEach(c => {
              c.isSingleOpt = false;
              if (c.isSingleOpt == false) {
                o.Count--;
              }
            });
          } else {
            o.childTree.forEach(c => {
              c.isSingleOpt = true;
              o.Count = o.childTree.length;
            });
          }
        }
        newList.push(o);
      });
      // console.log(newList, 'newList');
      this.newSearchModuleListInit = newList;
      // if (this.newRolePermissionList.length !== this.maximum()) {
      this.isRestore = true;
      // }
    },
    // 选中单个模块
    doChildItemClick (childItem, item) {
      var newchildListA = [];
      // 单个高亮
      this.newSearchModuleListInit.forEach(o => {
        // o.Count = 0;
        o.childTree.forEach(c => {
          if (c.OLAPKey == childItem.OLAPKey) {
            c.isSingleOpt = !c.isSingleOpt;
            if (c.isSingleOpt == true) {
              o.Count++;
            } else {
              o.Count--;
              o.isOpt = false;
            }
          }
        });
        newchildListA.push(o);
      });
      this.newSearchModuleListInit = [];
      this.newSearchModuleListInit = newchildListA;
      this.isRestore = true;
    },
    // 新增角色
    doAddRoleClick () {
      this.formTitleName.value = ' 新增角色';
      this.formTitleName.key = 'add';
      this.isPowerLevelFormShow = true;
    },
    // 修改角色
    doEditRoleClick () {
      this.formTitleName.value = ' 修改角色';
      this.formTitleName.key = 'edit';
      this.isPowerLevelFormShow = true;
    },

    // 删除角色
    doDeleteRoleClick () {
      if (this.searchRoleList.length == 1) {
        layer.alert('当前角色为最后一个，不可进行删除操作');
      } else {
        var _this = this;
        layer.confirm('是否删除当前角色?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                layer.close();
                hqDeleteRole(_this.selectRoleItem.OLAPKey).then(
                  result => {
                    // 删除的角色是当前用户所属角色 就不在重新加载数据了 直接关闭当前界面
                    if (_this.selectRoleItem.OLAPKey != _this.$store.getters.token.RoleKey) {
                      _this.loadRoleData(); // 刷新
                    }
                  },
                  error => {
                    layer.alert(error.msg);
                  }
                );
              }
            }
          ]
        });
      }
    },
    // 还原
    doRestoreClick () {
      if (this.isRestore == true) {
        var _this = this;
        layer.confirm('还原后将不保留当前更改，请确认是否操作?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                _this.loadRoleData(_this.selectRoleItem);
                _this.isRestore = false;
                layer.close();
              }
            }
          ]
        });
      } else {
        this.loadRoleData(this.selectRoleItem);
      }
    },
    // 提交
    doAffirmClick (callBack) {
      this.newRolePermissionList = [];
      this.newSearchModuleListInit.forEach(o => {
        o.childTree.forEach(c => {
          if (c.isSingleOpt == true) {
            this.newRolePermissionList.push({ ModulePowerKey: c.OLAPKey });
          }
        });
      });
      let editData = {
        RoleKey: this.selectRoleItem.OLAPKey, //  --角色id
        MainDemoName: this.selectRoleItem.MainDemoName, // -- 角色名
        RolePermissionList: this.editUnique(this.newRolePermissionList) // 角色权限列表
      };
      console.log(editData.RolePermissionList, '666');
      hqEditRole(editData).then(
        result => {
          console.log(result.data, '修改角色');
          layer.alert('角色权限修改成功!');
          this.isRestore = false;
        }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 去重
    editUnique (arr) {
      const res = new Map();
      return arr.filter(
        arr => !res.has(arr.ModulePowerKey) && res.set(arr.ModulePowerKey, 1)
      );
    }
  }
};
</script>

  <style>
/*  */
</style>