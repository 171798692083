<template>
  <div style="position: relative;    margin-bottom: 20px;"
       :style="this.$store.getters.token.RoleKey == 2? 'margin-left:15px;':''">
    <div class="coursetips"
         :class="isTeacherInto?'teacherright':''"
         v-if="classData.ISAuthExpireKey==1">课件端口过期，无法加载课件，请联系课件授权方</div>
    <div class="coursetips"
         :class="isTeacherInto?'teacherright':''"
         v-else-if="classData.IsSuspendAuthKey==1">课件授权暂停，无法加载课件，请联系课件授权方</div>
    <div class="coursetips"
         :class="isTeacherInto?'teacherright':''"
         v-else-if="vshowChannelDate>0">课件端口{{vshowChannelDate}}天后过期，过期后无法加载课件，请联系课件授权方续期</div>
    <div class="coursetips"
        :class="isTeacherInto?'teacherright':''"
         v-else-if="vshowExpireTime>0">课包授权{{vshowExpireTime}}天后过期，过期后无法加载课件，请联系课件授权方续期</div>
    
    <div class="package_box"
         :class="classData.ISAuthExpireKey==1 || classData.IsSuspendAuthKey==1||vshowChannelDate>0||vshowExpireTime>0 ? 'expirepackage' : ''">
      <div class="cw_details_img pr"
           @click.stop="doBigCwDetails">
        <img :src="headImg"
             alt="../../../../../public/image/no_logo@2x.png" />
      </div>
      <div class="head_div_right">
        <div class="p_list_box">
          <div class="left_box_list">
            <span class="left_box_list_span"
                  :class="isMoveCourse?'move_course_div':''"
                  @mouseover="moveCourseFun"
                  @mouseout="outCourseFun"
                  @click.stop="doBigCwDetails"
                  :title="classData.BigCoursewarePackageKeyValue"><span class="data_span">{{classData.BigCoursewarePackageKeyValue}}</span></span>
            <div class="left_box_list_div left_box_list_div_flex"
                 :class="(Number(classData.DeviceIsAuth)==0&&this.classData.IsPCAuthKey=='1'&&this.classData.IsPCAuthKey == '1')?'suth_text':''">
              <span class="left_box_list_two_span"
                    :title="classData.AuthorizedParty"
                    style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 240px;">授权方：<span style="color:#333;margin-left:12px;">{{classData.AuthorizedParty}}</span></span>
              <span v-if="this.$zxClient.IsClient"
                    class="current_device"
                    :style="classData.IsPCAuthKey!='0' && Number(this.classData.DeviceIsAuth) > 0?'background: #d1f8e6;color: #1cce75;font-size: 14px;':'background: rgba(228, 62, 9, 0.1);color: rgba(228, 62, 9, 1);'">
                当前设备：
                <span v-if="this.classData.IsPCAuthKey == '0'"
                      class="device_auth_no"> 禁用</span>
                <span v-else
                      :class="Number(classData.DeviceIsAuth)>0?'device_auth_ok':'device_auth_no'">
                  {{Number(classData.DeviceIsAuth)>0?'已授权':'未授权'}}
                </span>
                <!-- <span class="root_buttom" v-if="Number(classData.DeviceIsAuth)==0"><span v-if="!isTeacherInto" @click="goAuthor">授权本设备</span><span v-else @click="goAuthorOfTeacher">申请设备授权</span> {{!isTeacherInto?'':''}}</span> -->
                <span class="root_buttom"
                      v-if="Number(classData.DeviceIsAuth)==0&&this.classData.IsPCAuthKey=='1'&&this.classData.IsPCAuthKey == '1'"><span v-if="isTeacherInto"
                        @click.stop="goAuthorOfTeacher">申请设备授权</span></span>
              </span>
              <span v-else
                    class="left_box_list_two_span current_device">当前设备：<span class="device_auth_no1">非桌面端</span></span>
            </div>
            <!-- 3 -->
            <div class="left_both_list">
              <div class="list_content"><span class="list_span">设备支持：</span>
                <div class="support_bj"
                     style="left: 63px;width: 30px;"></div>
                <div class="support_bj"
                     style="left: 98px;width: 30px;"></div>
                <div class="support_bj"
                     style="right: 0px;width: 30px;"></div>
                <span class="support_span">电脑</span>
                <span class="support_span">手机</span>
                <span class="support_span">电视</span>
              </div>
              <div class="list_content secure_support"><span class="list_span">安全支持：</span>
                <div class="support_bj"
                     style="left: 62px;width: 67px;"></div>
                <div class="support_bj"
                     style="left: 132px;width: 67px;"></div>
                <div class="support_bj"
                     style="right: 1px;width: 53px;"></div>
                <span class="support_span">防录屏截屏</span>
                <span class="support_span">防复制下载</span>
                <span class="support_span">位置锁定</span>
              </div>
            </div>

          </div>

        </div>
        <!-- 
          <div class="p_list_box">
          <class-bigin-data @registerBusEvent='doViewCoursewareDetails'
                            :TeacherKey="TeacherKey"
                            :BigCoursewarePackageKey="classData.BigCoursewarePackageKey"
                            :dataInfo='dataInfo'
                            @getDateRangeCoursewareStat='getDateRangeCoursewareStat'>
          </class-bigin-data>

        </div>
          -->

      </div>
      <div class="device_div">
        <div class="device_control">设备授权：</div>
        <div class="device_control_list">
          <iconAuthorization v-for="(item,index) in vCourseAuthList"
                             :key="index"
                             :courseAuth="item"
                             :isShowBeforLine="true"
                             :isShowStyle="true"
                             :modelType="isTeacherInto ? 'B' : 'A' "></iconAuthorization>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {
  CWSGetDateRangeCoursewareStat, GetDateCourseCountForTeacher
} from '../../../../API/workbench';
import ClassBiginData from './class-bigin-data.vue';
import PackageLiItem from './package-li-item.vue';
import iconAuthorization from '../courseware-details/icon-authorization.vue';
export default {
  components: {
    PackageLiItem, ClassBiginData, iconAuthorization
  },
  props: [
    'isShowCourseware', 'packgeData', 'classData', 'isTeacherInto', 'TeacherKey', 'TeacherName'
  ],
  data () {
    return {
      isMoveCourse: false,
      params: {
        StartTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        EndTime: this.$utils.getCurrentMonthLast(),
        TeacherKey: 0,
        GradeKey: ''
      },
      dataInfo: {},
      isShowCoursewareThis: true,
      BigCoursewarePackageKey: 0,
      dataList: [
        { txtId: 0, title: '水墨小画初级-1月' },
        { txtId: 1, title: '水墨小画初级-2月' },
        { txtId: 2, title: '水墨小画初级-3月' },
        { txtId: 3, title: '水墨小画初级-4月' },
        { txtId: 4, title: '水墨小画初级-5月' }
      ],
      classStatistics: {
        name: '',
        routerName: 'coursewareWorkBench',
        moduleName: 'classStatistics'

      }

    };
  },
  computed: {
     vshowChannelDate(){
      let date=this.classData.CoursewareChannelExpiredTime||''
      let today=Date.new().toLocaleDateString()
      console.log("vshowChannelDate",date,Date.new(date).getTime()-Date.new(today).getTime(),86400000*7)
       // this.bigCoursewareItem.ExpireTime=date
      if(date&&(Date.new(date).getTime()-Date.new(today).getTime()+86400000)<=86400000*7){
        return parseInt((Date.new(date).getTime()-Date.new(today).getTime()+86400000)/86400000)
      }else{
        return 0
      }
    },
    vshowExpireTime(){
      let date=this.classData.ExpireTime||''
      let today=Date.new().toLocaleDateString()
      console.log("vshowExpireTime",date,Date.new(date).getTime()-Date.new(today).getTime(),86400000*7)
        // this.bigCoursewareItem.ExpireTime=date
      if(date&&(Date.new(date).getTime()-Date.new(today).getTime()+86400000)<=86400000*7){
        return parseInt((Date.new(date).getTime()-Date.new(today).getTime()+86400000)/86400000)
      }else{
        return 0
      }
    },
    headImg () {
      if (!this.classData.CoverUrl) {
        this.classData.CoverUrl = '';
      }
      if (this.classData.CoverUrl.indexOf('http') > -1) {
        return this.classData.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.classData.CoverUrl;
      }
    },
    vCourseAuthList () {
      let data = this.classData;
      let arr = [];
      if (!this.classData) {
        return arr;
      }
      let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.PCDeviceAuthCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVDeviceAuthCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPDeviceAuthCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      arr.push(itemPC);

      arr.push(itemAPP);
      arr.push(itemTV);
      return arr;
    }
  },
  watch: {
    // classData: {
    //   handler (c, o) {
    //     if (c) {
    //       this.getDateRangeCoursewareStat(this.$utils.getCurrentMonthFirst(), this.$utils.getCurrentMonthLast());
    //     }
    //   }
    // }
  },
  created () {
  },
  mounted () {
    console.log('当前选中课包信息', this.classData);
    console.log('当前选中小课包信息', this.packgeData);
    // this.getDateRangeCoursewareStat(this.$utils.getCurrentMonthFirst(), this.$utils.getCurrentMonthLast());
  },
  methods: {
    moveCourseFun () {
      this.isMoveCourse = true;
    },
    outCourseFun () {
      this.isMoveCourse = false;
    },
    doBigCwDetails () {
      if (this.classData.ISAuthExpireKey == 1) {
        layer.alert('课件端口过期，无法加载课件，请联系课件授权方!');
      } else if (this.classData.IsSuspendAuthKey == 1) {
        layer.alert('课件授权暂停，无法加载课件，请联系课件授权方!');
      } else {
        console.log(this.classData, ' this.classData');
        this.$dialog.open(this, {
          name: '大课包详情',
          routerName: this.$route.name,
          moduleName: 'bigCoursewareDetails',
          data: {
            dataInfo: {
              TeacherKey: this.TeacherKey,
              BigCoursewarePackageKey: this.classData.BigCoursewarePackageKey,
              AuthKey: this.classData.AuthKey,
              TeacherName: this.TeacherName,
              Source: this.classData.Source,
              MainDemoName: this.classData.MainDemoName,
              CoursewarePackageKey: this.classData.BigCoursewarePackageKey,
            }
          },
          callBack: {
            afterSuccess: () => {
              this.$emit('flashData');
            },
            closeDialog: () => {
              this.$emit('flashData');
            }
          }
        });
      }
    },
    goAuthor () {
      this.$emit('goAuthor', this.classData);
    },
    goAuthorOfTeacher () {
      this.$emit('goAuthorOfTeacher', this.classData);
    },
    getDateRangeCoursewareStat (startDate, endDate) {
      this.params.StartTime = startDate;
      this.params.EndTime = endDate;
      this.params.BigCoursewarePackageKey = this.classData.BigCoursewarePackageKey;
      this.params.TeacherKey = this.TeacherKey;
      if (this.isTeacherInto) {
        GetDateCourseCountForTeacher(this.params).then(result => {
          console.log(result.data, '获取老师指定日期范围的课件上课统计');
          this.dataInfo = result.data;
          // this.cwsSummList[2].value = Number(result.data.ArrivedNumCount);// 签到人次
          // if (this.params.StartTime == this.params.EndTime) {
          //   this.cwsSummList[3].value = Number(result.data.CourseCount);// 今日已上课次
          //   this.cwsSummList[4].value = Number(result.data.CourseTimeCount);// 今日上课时长
          // } else {
          //   this.cwsSummList[0].value = Number(result.data.CourseCount) || 0;// 已上课次
          //   this.cwsSummList[1].value = Number(result.data.CourseTimeCount) || 0;// 上课时长
          // }
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        // CWSGetDateRangeCoursewareStat(this.params).then(result => {
        console.log('获取指定日期范围的课件上课统计');
        //   this.dataInfo = result.data;
        //   // this.cwsSummList[2].value = Number(result.data.ArrivedNumCount);// 签到人次
        //   // if (this.params.StartTime == this.params.EndTime) {
        //   //   this.cwsSummList[3].value = Number(result.data.CourseCount);// 今日已上课次
        //   //   this.cwsSummList[4].value = Number(result.data.CourseTimeCount);// 今日上课时长
        //   // } else {
        //   //   this.cwsSummList[0].value = Number(result.data.CourseCount) || 0;// 已上课次
        //   //   this.cwsSummList[1].value = Number(result.data.CourseTimeCount) || 0;// 上课时长
        //   // }
        // }, error => {
        //   layer.alert(error.msg);
        // });
      }
    },
    isShowCoursewareAction () {
      this.isShowCoursewareThis = !this.isShowCoursewareThis;
    },
    chgCoure (item) {
      console.log('this.classData', this.classData);
      item.OLAPKey = item.CoursewarePackageKey;
      item.TeacherKey = this.TeacherKey;
      item.MainDemoName = item.CoursewarePackageKeyValue;
      console.log(item, 'item');
      this.$dialog.open(this, {
        name: '课件详情',
        routerName: this.$route.name,
        moduleName: 'CoursewareParticular',
        data: { CoursewarePackage: item }
      });
    },
    doViewCoursewareDetails () {
      if (!this.isTeacherInto) {
        this.$dialog.open(this, {
          name: '课件使用统计',
          routerName: this.$route.name,
          moduleName: 'coursewareUseStatlist',
          data: {
            dataInfo: {
              TeacherKey: this.TeacherKey,
              StartTime: this.params.StartTime,
              EndTime: this.params.EndTime,
              BigCoursewarePackageKey: this.params.BigCoursewarePackageKey,
              ClassKey: ''
            }
          }
        });
      } else {
        this.$dialog.open(this, {
          name: '老师课件使用统计',
          routerName: this.$route.name,
          moduleName: 'classStatisticsTeacher',
          data: {
            dataInfo: {
              StartTime: this.params.StartTime,
              EndTime: this.params.EndTime,
              BigCoursewarePackageKey: this.params.BigCoursewarePackageKey,
              ClassKey: ''

            }
          }
        });
      }
    },
    openTeacherAuthForm () {
      this.$emit('openTeacherAuthForm');
    }
  }
};
</script>
<style scoped>
.cw_details_img img {
  height: 112px;
  width: 172px;
  cursor: pointer;
  border: 2px solid rgb(187, 187, 187);
}
.cw_details_img img:hover {
  border: 2px solid rgba(255, 96, 0, 1);
}
.package_box {
  display: flex;
  margin-top: 15px;
  width: 820px;
  height: 204px;
  background: #fff;
  border-radius: 8px;
  padding: 20px 17px 26px;
}
.head_div_left {
  width: 300px;
  height: 168px;
  border-radius: 8px;
  position: relative;
  float: left;
  margin: 20px 0;
}
.head_div_left_text {
  position: absolute;
  width: 300px;
  height: 29px;
  bottom: 0px;
  background: linear-gradient(0deg, #000000, #000000);
  opacity: 0.3;
  border-radius: 0px 0px 8px 8px;
}
.head_div_left_span {
  width: 300px;
  position: absolute;
  bottom: 0px;
  line-height: 29px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  align-items: center;
  color: #ffffff;
  left: 0;
}
.head_div_right {
  float: right;
  width: 590px;
  height: 168px;
  position: relative;
  margin-left: 15px;
}
.after_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  position: absolute;
  right: -65px;
  top: -2px;
  color: #999999;
  cursor: pointer;
}
.p_list_box {
  width: 500px;
  height: 84px;
}
.left_box_list {
  height: 84px;
  float: left;
  position: relative;
}
.right_box_list {
  cursor: pointer;
  width: 100px;
  height: 22px;
  float: right;
}
.left_box_list_span {
  display: block;
  height: 22px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  cursor: pointer;
}
.data_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 439px;
  display: inline-block;
}
.left_box_list_span::after {
  content: '';
  background: url(../../../../../public/image/book_next@2x.png) no-repeat 50%;
  background-size: 12px;
  width: 30px;
  height: 12px;
  position: absolute;
  top: 5px;
}
.move_course_div::after {
  content: '';
  background: url(../../../../../public/image/book_next@2x_two.png) no-repeat
    50% !important;
  background-size: 14px !important;
  width: 30px !important;
  height: 14px !important;
}
.left_box_list_two_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  color: #999999;
  /* padding-left: 5px; */
}
.left_box_list_div {
  margin-top: 25px;
}
.root_span {
  width: 60px;
  height: 20px;
  background: rgba(255, 108, 103, 0.2);
  border: 1px solid #ff6c67;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #ff6c67;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  padding: 3px;
}
.root_buttom {
  padding-left: 5px;
  height: 16px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #3498db;
  cursor: pointer;
}
.button_right_box {
  width: 100px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  border-radius: 4px;
  float: right;
}
.package_box_ul {
  display: block;
  width: 815px;
  float: left;
  list-style: none;
  margin-top: 10px;
}
.package_box_li {
  display: block;
  height: 141px;
  width: 258px;
  background: #f7f8fa;
  border-radius: 4px;
  margin: 5px 8px 5px 5px;
  float: left;
}
.package_box_li_title {
  display: block;
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  font-family: Arial;
}
.left_box_line {
  margin-left: 10px;
  width: 124px;
  height: 1px;
  background: #ffffff;
  opacity: 0.1;
}
.is_block_ul {
  box-sizing: border-box;
  width: 815px;
  height: 46px;
  background: #f1f7ff;
  border-radius: 4px;
  float: left;
  align-items: center;
  text-align: center;
  line-height: 46px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}
.device_auth_ok {
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  padding: 3px;
  display: inline-table;
  color: #1cce75;
}
.device_auth_no {
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  padding: 3px;
  display: inline-table;
  color: #e43e09;
}
.device_auth_no1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  padding: 3px;
  display: inline-table;
  color: #e43e09;
}
.line_list_buttom {
  position: relative;
  box-sizing: border-box;

  /* position: absolute; */
  width: 815px;

  background: linear-gradient(0deg, #ffffff, #ffffff), #fafbfd;
  border-top: 1px solid #ececec;
  border-radius: 8px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  top: 0px;
}
.left_box_list_div_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
}
.device_control_list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 400px;
  line-height: 20px;
  font-size: 12px;
}
.device_control_list >>> .icon_authorization_box {
  margin-right: 0px !important;
  margin-left: -15px !important;
}
.device_control_list >>> .icon_authorization_box .img_box {
  margin-right: -5px !important;
}
.device_control_list >>> .icon_authorization_box .courseType {
  margin-left: 5px !important;
}
.suth_text {
  width: 600px;
}
.coursetips {
  position: absolute;
  left: 0;
  right: 20px;
  top: 0px;
  height: 35px;
  background: #fffbe6;
  border: 1px solid #ffe694;
  border-radius: 2px;
  line-height: 35px;
  padding-left: 20px;
  color: #fea409;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.teacherright {
  right: 130px;
  width: 100%;
}
.expirepackage {
  margin-top: 20px;
  padding: 39px 17px 26px !important;
  height: 225px !important;
}
.left_both_list {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
}
.support_span {
  border-radius: 2px;
  padding: 0 4px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.support_bj {
  width: 40px;
  height: 20px;
  position: absolute;
  background: rgba(217, 223, 228, 1);
  top: 0px;
  opacity: 0.4;
}
.list_content {
  position: relative;
  color: #999;
  line-height: 20px;
}
.secure_support {
  position: absolute;
  left: 290px;
}
.current_device {
  position: absolute;
  left: 290px;
  border-radius: 100px;
  background: rgba(228, 62, 9, 0.1);
  padding: 0 8px;
  text-align: center;
  color: rgba(228, 62, 9, 1);
}
.device_div {
  position: absolute;
  bottom: 25px;
  width: 790px;
  background: #f9f9fb;
  text-align: center;
  border-radius: 4px;
  display: flex;
}
.device_control {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;
  margin-left: 10px;
  align-items: center;
  width: 150px;
  color: #333333;
}
.device_control_list {
  box-sizing: border-box;
  width: 615px;
  height: 35px;
  background: #f9f9fb;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-around;
}
</style>