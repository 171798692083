<template>
  <div class="top_content course_statistics_title general_summarizing_student box_shadow_border_div"
       style="flex-direction: column;padding-top:20px;padding-bottom: 30px;margin-bottom: 15px;">
    <div class="table_select_box"
         style="margin-bottom:10px"
         v-if="$store.getters.SaaSClientEditionKey == 5">
      <div class="table_select_title">选择校区</div>
      <el-select :value="vschoolItem"
                 style="width: 180px;"
                 filterable
                 placeholder="必选"
                 @change="chooseSchoolItem"
                 value-key="OLAPKey">
        <el-option v-for="item in schoolList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="header_title title_date"
         v-if="showTableList">
      <span class="header_title_span">
        {{title}}
      </span>
      <span>
        （截至{{nowTime}}
        <span class="font_blue hover"
              @click.stop="reload">刷新</span>
        ）
      </span>
    </div>
    <div class="header_content"
         v-if="showTableList">
      <div class="header_content_item"
           @click.stop="changeType(1)"
           :class="indexType==1?'opt':''">
        <div class="header_content_item_text_top font_gray">
          结余总额
        </div>
        <div class="header_content_item_text_middle"
             :style="setSizeByLength(dataInfo.ValueAmount)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.ValueAmount))}}
          <span class="middle_unit">元</span>
        </div>
      </div>
      <div class="header_content_unit">
        =
      </div>
      <div class="header_content_item"
           @click.stop="changeType(3)"
           :class="indexType==3?'opt':''">
        <div class="header_content_item_text_top font_gray">
          课时结余
        </div>
        <div class="header_content_item_text_middle"
             :style="setSizeByLength(dataInfo.ClassHourAmount)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.ClassHourAmount))}}
          <span class="middle_unit">元</span>
        </div>
        <div class="header_content_item_text_bottom">
          可用课时: {{Number(dataInfo.ClassHourNum)}}
        </div>
      </div>
      <div class="header_content_unit">
        +
      </div>
      <div class="header_content_item"
           @click.stop="changeType(4)"
           :class="indexType==4?'opt':''">
        <div class="header_content_item_text_top font_gray">
          天数结余
        </div>
        <div class="header_content_item_text_middle"
             :style="setSizeByLength(dataInfo.DayAmount)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.DayAmount))}}
          <span class="middle_unit">元</span>
        </div>
        <div class="header_content_item_text_bottom">
          可用天数: {{Number(dataInfo.DayNum)}}
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';

export default {
  data () {
    return {
      nowTime: ''
    };
  },
  components: {
    singleYearPicker
  },
  created () {
    console.log("this.$utils.formatDatet(new Date(),'yyyy-mm-dd hh:mm')", this.$utils.formatDatet(new Date(), 'yyyy-MM-dd hh:mm'));
    this.nowTime = this.$utils.formateDateYMRHMS(this.$utils.formatDatet(new Date(), 'yyyy-MM-dd') + ' ' + this.$utils.formatDatet(new Date(), 'hh:mm'), 'yyyymmddhhmm');
  },
  props: {
    searchObj: Object,
    dataInfo: Object,
    title: String,
    indexType: [Number, String],
    unShowDate: {
      type: Boolean,
      default: false
    },
    schoolList: Array,
    showTableList: Boolean,
    schoolItem: {
      type: Object,
      default: () => {
        return {
          MainDemoName: '',
          OLAPKey: 0
        };
      }
    }
  },
  computed: {
    vschoolItem () {
      return this.schoolItem.MainDemoName;
    }
  },

  methods: {
    chooseSchoolItem (item) {
      this.$emit('chooseSchoolItem', item);
    },
    // 根据字数设定字体大小
    setSizeByLength (num) {
      num = num || 0;
      let count = 5;
      let size = parseInt(num).toString().length <= count ? 'font-size:34px' : 'font-size:24px;padding:10px 0px';
      return size;
    },
    reload () {
      this.nowTime = this.$utils.formateDateYMRHMS(this.$utils.formatDatet(new Date(), 'yyyy-MM-dd') + ' ' + this.$utils.formatDatet(new Date(), 'hh:mm'), 'yyyymmddhhmm');
      this.$emit('reload', new Date().getTime());
    },
    changeType (TypeKey) {
      this.$emit('changeType', TypeKey);
    }
  }

};
</script>
<style scoped>
.header_title_span {
  color: black;
  font-size: 20px;
}
.header_content {
  /* width: 1300px; */
  height: 168px;
  border-radius: 8px;
  background: linear-gradient(
      90deg,
      rgba(226, 234, 253, 0.5) 0%,
      rgba(236, 237, 255, 0.5) 100%
    ),
    linear-gradient(90deg, #ffffff -0.99%, rgba(255, 255, 255, 0) 100%);
  margin-top: 20px;
  padding-left: 25px;
  display: flex;
  justify-content: row;
  align-items: center;
}
.header_content_item {
  width: 215px;
  height: 124px;
  border-radius: 8px;
  /* border: 1px; */
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  padding-right: 0px;
}
.header_content_item:hover {
  background: linear-gradient(
      90deg,
      rgba(226, 234, 253, 0.5) 0%,
      rgba(236, 237, 255, 0.5) 100%
    ),
    linear-gradient(90deg, #ffffff -0.99%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(45, 152, 217, 1);
  color: rgba(45, 152, 217, 1);
}
.header_content_item:hover .header_content_item_text_top {
  color: rgba(45, 152, 217, 1) !important;
}
.header_content_item.opt {
  color: rgba(45, 152, 217, 1);
  border: 1px solid rgba(45, 152, 217, 1);

  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}
.header_content_unit {
  width: 40px;
  height: 40px;
  background: linear-gradient(0deg, #0075ff, #0075ff),
    linear-gradient(0deg, #ffffff, #ffffff);
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  color: white;
  font-size: 40px;
  line-height: 37px;
}
.header_content_item_text_top {
  font-size: 12px;
}
.header_content_item_text_middle {
  font-size: 34px;
  padding: 5px 0px;
}

.middle_unit {
  font-size: 14px;
}
.header_content_item_text_bottom {
  width: 153px;
  height: 26px;
  line-height: 26px;
  border-radius: 100px;
  text-align: center;
}
</style>