<template>
  <div class="WKT_student_activity_ul 1" @scroll="scrollActivityInfo">
    <activity-item
      v-for="(item, index) in activitiesCouponList"
      :key="index"
      :Item="item"
      :Index="index"
      :isShowbtn="isShowbtn"
      :dataList="activitiesCouponList"
      @doSelectActivity="doSelectActivity"
    ></activity-item>
  
  </div>
</template>
<script>
import activityItem from "./activity-item";
export default {
  components: {
    activityItem,
  },
  data() {
    return {
      searchText: "",
    };
  },
  props: {
    activitiesCouponList: Array,
    isShowbtn: {
      type: Boolean,
      default: true,
    },
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    // 上架
    doSelectActivity(Item) {
      this.$emit("doSelectActivity", Item);
    },
    scrollActivityInfo(e){
      if(!this.isShowbtn  && e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight)){
        this.$emit('refreshActivityInfo');
      }
    },
  },
};
</script>

