 <template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL content_marginTop_30">
      <!-- 有效期 -->
      <div class="form_info_edit">
        <div class="form_info_line form_info_select form_info_required">
          <div class="form_info_field">起始日期</div>
          <div class="form_info_value">
            <el-date-picker v-model="dateRangeInfo.startDate"
                            type="date"
                            placeholder="必选"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptionsForStart"
                            @change="startDateEvent($event)"></el-date-picker>
          </div>
        </div>
      </div>
      <div class="form_info_edit">
        <div class="form_info_line form_info_select form_info_required">
          <div class="form_info_field">结束日期</div>
          <div class="form_info_value">
            <el-date-picker v-model="dateRangeInfo.endDate"
                            type="date"
                            placeholder="必选"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptionsForEnd"
                            @change="endDateEvent()"></el-date-picker>
          </div>
        </div>
      </div>

      <div v-if="isShowFastBtn"
           class="form_info_line">
        <div class="form_week_content flex">
          <div class="form_week_list form_week_list_big"
               :class="{opt:item.isActive}"
               v-for="(item,index) in fastBtnList"
               :key="index"
               @click="clickDataBtnType(item)">{{item.value}}</div>
        </div>
      </div>
    </div>
    <common-button-group @buttonClickEvent="confirValidityPeriod"
                         @closeDialog="closeDialog"></common-button-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dateRangeInfo: {
        startDate: this.StartTime || '', // 绑定开始周期
        endDate: this.EndTime || '' // 绑定结束周期
      },

      fastBtnList: [
        { value: '1个月', key: 1, isActive: false },
        { value: '2个月', key: 2, isActive: false },
        { value: '3个月', key: 3, isActive: false },
        { value: '半年', key: 4, isActive: false },
        { value: '1年', key: 5, isActive: false }
      ],
      fastBtnTypeObj: {
        oneMonth: 1,
        twoMonth: 2,
        threeMonth: 3,
        halfYear: 4,
        oneYear: 5
      },
      curSelectedFastBtn: null,
      pickerOptionsForStart: this.sMinData(),
      pickerOptionsForEnd: this.eMinData()
    };
  },
  props: {
    minSData: Date,
    maxSData: Date,
    minEData: Date,
    maxEData: Date,
    StartTime: String,
    EndTime: String,
    // StartTimeRequired: Boolean,
    // EndTimeRequired: Boolean,
    isShowFastBtn: {
      type: Boolean,
      default: false
    }
  },

  created () {
  },
  watch: {},
  methods: {
    // 选择开始周期
    startDateEvent () {
      if (this.curSelectedFastBtn) {
        this.curSelectedFastBtn.isActive = false;
        this.curSelectedFastBtn = null;
      }
    },
    // 选择结束周期
    endDateEvent () {
      if (this.curSelectedFastBtn) {
        this.curSelectedFastBtn.isActive = false;
        this.curSelectedFastBtn = null;
      }
    },
    // 快捷(二月 半年 一年) 按钮
    clickDataBtnType (item) {
      if (!this.dateRangeInfo.startDate) {
        layer.alert('请先选择开始日期');
        return false;
      }
      let curDate = Date.new(this.dateRangeInfo.startDate);
      let year = curDate.getFullYear();
      let month = curDate.getMonth();
      let date = curDate.getDate() - 1;
      if (this.curSelectedFastBtn) {
        this.curSelectedFastBtn.isActive = false;
      }
      item.isActive = true;
      this.curSelectedFastBtn = item;
      switch (item.key) {
        case this.fastBtnTypeObj.oneMonth:
          this.dateRangeInfo.endDate = this.$utils.formatDateToLine(Date.new(year, month + 1, date));
          break;
        case this.fastBtnTypeObj.twoMonth:
          this.dateRangeInfo.endDate = this.$utils.formatDateToLine(Date.new(year, month + 2, date));
          break;
        case this.fastBtnTypeObj.threeMonth:
          this.dateRangeInfo.endDate = this.$utils.formatDateToLine(Date.new(year, month + 3, date));
          break;
        case this.fastBtnTypeObj.halfYear:
          this.dateRangeInfo.endDate = this.$utils.formatDateToLine(Date.new(year, month + 6, date));
          break;
        case this.fastBtnTypeObj.oneYear:
          this.dateRangeInfo.endDate = this.$utils.formatDateToLine(Date.new(year + 1, month, date));
          break;
        default:
          break;
      }
    },
    // 传递按钮事件
    confirValidityPeriod () {
      if (this.dateRangeInfo.startDate) {
        var a = Number(this.dateRangeInfo.startDate.replace(/\-/g, '')) ? Number(this.dateRangeInfo.startDate.replace(/\-/g, '')) : Number(this.dateRangeInfo.startDate.replace(/\./g, '')); // 开始日期
      }
      if (this.dateRangeInfo.endDate) {
        var b = Number(this.dateRangeInfo.endDate.replace(/\-/g, '')) ? Number(this.dateRangeInfo.endDate.replace(/\-/g, '')) : Number(this.dateRangeInfo.endDate.replace(/\./g, '')); // 结束日期
      }
      var presentDate = this.$utils.formatDateToLine(Date.new()); // 当天日期
      var c = Number(presentDate.replace(/\-/g, ''));

      if (!this.dateRangeInfo.startDate) {
        layer.alert('起始日期不可为空');
      } else if (!this.dateRangeInfo.endDate) {
        layer.alert('结束日期不可为空');
      } else if (a > b) {
        layer.alert('起始日期不可晚于结束日期');
      } else if (b < c) {
        layer.alert('结束日期不能小于今天');
      } else {
        this.closeDialog(this.dateRangeInfo);
      }
    },
    // 点击取消
    closeDialog (date) {
      this.$emit('cancel', date);
    },
    // 开始日期
    sMinData () {
      let self = this;
      return {
        disabledDate (time) {
          return (
            Date.new(time.getFullYear(), time.getMonth(), time.getDate() + 1) <
            Date.new(self.minSData)
          ); // 开始时间不选时，结束时间最大值小于等于当天
        }
      };
    },
    // 结束日期
    eMinData () {
      let self = this;
      return {
        disabledDate (time) {
          if (self.dateRangeInfo.startDate) {
            return (
              Date.new(self.dateRangeInfo.startDate).getTime() >
              Date.new(
                time.getFullYear(),
                time.getMonth(),
                time.getDate() + 1
              ) || time.getTime() < Date.now()
            );
          } else {
            return (
              Date.new(
                time.getFullYear(),
                time.getMonth(),
                time.getDate() + 1
              ) < Date.now()
            ); // 开始时间不选时，结束时间最大值小于等于当天
          }
        }
      };
    }
  },
  computed: {}
};
</script>
