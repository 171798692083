<template>
  <div>
    <pre class="label_bottom_content_text"
         v-html="$utils.translationSchoolText(dataInfo.TeachingProcedure)"></pre>
    <courseware-attach :dataList="vCoursewareTeachingStep"
                             ></courseware-attach>
    <div style="margin: 0px 40px;">
      <other-attach :dataList="votherAttachList"
                              ></other-attach>
    </div>
  </div>

</template>
<script>
import otherAttach from '../../course-table/attendance-arrange/teaching-components/other-attch'
import coursewareAttach from '../../course-table/attendance-arrange/teaching-components/courseware-attach';
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  components:{
    otherAttach,coursewareAttach
  },
  computed:{
    vCoursewareTeachingStep () {
      return (this.dataInfo.CoursewareAttach || []).filter(obj => { return obj.TypeKey == 2; });
    },
    votherAttachList(){
       return (this.dataInfo.CoursewareAttach || []).filter(obj => { return obj.TypeKey == 3 });
    },
  }
};
</script>

