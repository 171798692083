<template>
	<div class="article_details_comment">
		<div class="article_details_comment_title list_credit_type">
			<div class="title_left flex">
				<span class="title_text">评分</span>
				<div class="list_credit">
					<div class="list_credit_img" v-for="(obj,key) in 5" :key="key" :class="{'opt':key<Number(dataInfo.RummageScoreCount)}"></div>
				</div>
			</div>
		</div>
		<div class="comment_list">
			<span class="font_blue" v-if="dataInfo.RummagerKeyValue">{{dataInfo.RummagerKeyValue}}：</span>
			<span v-html="$utils.translationSchoolText(dataInfo.RummageFeedBack)"></span>
		</div>
		<!-- <div class="complete_the_job_date">
			<span>{{$utils.replyTime(dataInfo.CreateTime)}}</span>
		</div> -->
	</div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 作业项
  },
  methods: {
    // 修改点评
    remarkEdit () {
		 this.$emit('remarkEdit');
    }
  }
};
</script>

<style>

</style>
