<template>
  <div>
    <div class="form_info_detail">
      <!-- 1. 默认表单未展开 form_extend
            2. 默认可编辑表单 form_info_edit -->
      <div class="form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow ">

        <formItem :formTitle="'上课班级'"
                  :readonly="true"
                  :required="true"
                  v-model="courseItem.GradeClassKeyValue">
        </formItem>

        <formItem :formTitle="'上课课程'"
                  :formPlaceholder="'课程'"
                  :readonly="true"
                  :required="true"
                  v-model="courseItem.CourseNameKeyValue">
        </formItem>

        <div class="form_data form_info_line form_info_required">
          <div class=" form_info_field">上课日期</div>
          <div class=" form_info_value form_info_select">
            <el-date-picker type="date"
                            placeholder="必选"
                            @change="chaDate"
                            v-model="courseItem.ClassTime"
                            :picker-options="pickerOptions0"
                            value-format=yyyy-MM-dd
                            format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
        <select-class-time-rang :classTimeData="{SClassTimeName:courseItem.SClassTimeName,EClassTimeName:courseItem.EClassTimeName}"
                                @onSelectTimeRange="selectTimeRange"
                                @onDelTimeRange="delTimeRange"></select-class-time-rang>

        <form-dialog-mutiselect :formTitle="'上课老师'"
                                :formPlaceholder="'必选'"
                                :required="true"
                                v-model="vLecTeaSelectInfo"
                                :dataSource="mTeacherList"
                                :doCurModuleName="'staff'"
                                :ModulePowerKey='44'
                                :selectTypeItem="{name:'addTeacher',key:1}"
                                :isSingleMode="true"
                                @beforeLoadDataSource="getMTeacherList"></form-dialog-mutiselect>
        <input-dialog :formTitle="'上课教室'"
                      :formPlaceholder="'教室'"
                      v-model="courseItem.ClassroomKeyValue"
                      @showEldilog="getClassRoomList"></input-dialog>

        <div class="pr">
          <span class="pa input_date_week"
                style="top:8px">消耗课时</span>
          <input-number-dialog :formTitle="'消耗课时'"
                               :dialogTitleName="'输入课时'"
                               v-model="vPeriodCount"
                               :decimalPlaces="1"
                               :isShowButtonInfo="true"
                               :required="true"
                               :formPlaceholder="'学生上课消耗'"
                               :helpDescription="'每节课的扣除课时数。'"
                               :readonly="!isAllowedCEditClassCount"
                               @showDialog="editPeriodCount"></input-number-dialog>
        </div>
        <input-number :formTitle="'开课人数'"
                      :min="0"
                      v-model="courseItem.StartCourseCount"
                      :decimalPlaces="0"
                      :required="true"></input-number>
        <input-number :min="0"
                      :formTitle="'满员人数'"
                      :required="true"
                      v-model="courseItem.FullPeopleCount"
                      :placeholder="'选填'"
                      :decimalPlaces="0"></input-number>
        <div class=" form_info_line"
             v-if="vIsShowChargeLeave">
          <div class=" form_info_field">请假扣课时</div>
          <div class=" form_info_value ">
            <el-switch v-model="courseItem.IsleaveKey"
                       active-color="#49b5f3"
                       inactive-color="#a5b6c8"
                       active-value="1"
                       inactive-value="0">
            </el-switch>
          </div>
        </div>
        <div class=" form_info_line"
             v-if="vIsShowChargeTruant">
          <div class=" form_info_field">旷课扣课时</div>
          <div class=" form_info_value ">
            <el-switch v-model="courseItem.IsChargesKey"
                       active-color="#49b5f3"
                       inactive-color="#a5b6c8"
                       active-value="1"
                       inactive-value="0">
            </el-switch>
          </div>
        </div>
        <!-- <input-number :min="0"
                        :formTitle="'亏损预警人数'"
                        :required="false"
                        v-model="courseItem.WarnLossCount"
                        :placeholder="'选填'"
                        :decimalPlaces="0"></input-number> -->
      </div>

      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="comfireBtn"
                             @cancelClick="courseSuspend"></save-cancel-btn-group>

    </div>
  </div>
</template>
<script>
import formItem from '../../form-item/form-input';
import formDialog from '../../form-item/form-dialog';
import formSelect from '../../form-item/form-select';
import inputNumberc from '../../form-item/input-numberc';
import multipleClassroomItem from '../../common/multiple-select-info';
import { getValidClassroomList, AllowedUpdateClassCount, getInJobTeacherList, PauseScheduleCourse, UpdateAppointCourse, getAppointCourseDetailed } from '../../../API/workbench';
import timeSelectModule from '../../common/time-select-module';

export default {
  name: 'editCourse',
  data () {
    return {
      changeCourseDialogVisible: false,
      allClassOpen: false,      // 是否全班一起上课
      truantSpend: false,        // 是否旷课扣课时
      leaveSpend: false,          // 是否请假扣课时
      classList: [],         // 班级列表
      choseClassItem: '',       // 选中的
      courseItem: {
        IsAutoLearnKey: 0,
        IsChargesKey: 0,
        IsleaveKey: 0
      },          // 选中的该课程.
      classRoomList: [],       // 教室列表
      mTeacherList: [],      // 主讲老师列表
      showMTeacherList: false, // 主讲老师弹出层
      ATeacherList: [],      // 主讲老师列表
      showATeacherList: false, // 主讲老师弹出层
      showTimeFrame: false,        // 选择时间段弹出层 .
      PeriodCount: '',             // 输入课时弹出层绑定的数据
      beginTime: [],
      endTime: '',
      courseItemCopy: {},
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      isChgDate: {},        // 判断是否日期改变.
      isAllowedCEditClassCount: false,      // 判断是否可以修改课时
      isChgDateTime: false,            // 判断是否改变过时间
      isChgDayTime: false,            // 判断是否改版过日期
      mistiming: Number

    };
  },
  components: {
    formItem,
    inputNumberc,
    formDialog,
    formSelect,
    multipleClassroomItem,
    timeSelectModule
  },
  props: ['courseInfo'],

  created () {
    // this.changeCourseDialogVisible = this.vchangeCourseDialogVisible || false;
    if (this.courseInfo.OLAPKey) {
      this.CourseDetailed(this.courseInfo.OLAPKey);
      this.isAllowedUpdateClassCount(this.courseInfo.OLAPKey);
    }
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  watch: {
    courseInfo (n, o) {
      if (n.OLAPKey) {
        this.CourseDetailed(this.courseInfo.OLAPKey);
        this.isAllowedUpdateClassCount(this.courseInfo.OLAPKey);
      }
    },
    showTimeFrame () {
      if (this.showTimeFrame == true) {
        this.mistiming = Number((this.courseItem.EClassTimeName.slice(0, 2) * 60 + Number(this.courseItem.EClassTimeName.slice(3, 5))) - (this.courseItem.SClassTimeName.slice(0, 2) * 60 + Number(this.courseItem.SClassTimeName.slice(3, 5))));
      }
    },
    PeriodCount (n, o) {
      this.courseItem.PeriodCount = this.$utils.mAmountType(n, 1);
    }
  },
  computed: {
    vLecTeaSelectInfo: {
      get () {
        return {
          idText: this.courseItem.MTeacherKey,
          nameText: this.courseItem.MTeacherKeyValue
        };
      },
      set (newValue) {
        this.courseItem.MTeacherKey = newValue.idText;
        this.courseItem.MTeacherKeyValue = newValue.nameText;
      }
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59);
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    },
    vPeriodCount: {
      get () {
        return {
          number: this.$utils.mAmountType(this.courseItem.PeriodCount, 1),
          UnitName: '课时 / 节课'
        };
      },
      set (newValue) {
        this.courseItem.PeriodCount = newValue.number;
      }
    }
  },
  methods: {
    chaDate () {
      this.isChgDayTime = true;
    },
    closeShowTimeFrame () {              // 关闭时间段弹出层
      this.showTimeFrame = false;
    },
    ComfireTimeFrame (beginTime, endTime) {              // 确定时间选择时改变数据.
      this.beginTime[0] = beginTime;
      this.beginTime[1] = endTime;
      if (this.courseItem.SClassTimeName == this.beginTime[0] && this.courseItem.EClassTimeName == this.beginTime[1]) {
      } else {
        this.isChgDateTime = true;
      }
      this.courseItem.SClassTimeName = this.beginTime[0];
      this.courseItem.EClassTimeName = this.beginTime[1];
      this.showTimeFrame = false;
    },

    // 选中时间段查询
    selectTimeRange (item) {
      this.courseItem.SClassTimeName = item.SClassTimeName;
      this.courseItem.EClassTimeName = item.EClassTimeName;
      this.courseItem.ClassTimeName = item.ClassTimeName;
    },
    // 删除时间段
    delTimeRange () {
      this.courseItem.SClassTimeName = '';
      this.courseItem.EClassTimeName = '';
      this.courseItem.ClassTimeName = '';
    },
    // 判断是否可以修改课单.
    isAllowedUpdateClassCount (OLAPKey) {
      AllowedUpdateClassCount(OLAPKey).then(result => {
        this.isAllowedCEditClassCount = true;
      }).catch(error => {
        if (error.code == 60002 || error.code == 10000) {
          this.isAllowedCEditClassCount = false;
        }
      });
    },
    // 修改课单
    editPeriodCount () {
      if (!this.isAllowedCEditClassCount) {
        layer.alert('已有学生结算课时,不可修改.');
      }
    },
    CourseDetailed (OLAPKey) {                             // 根据课名ID获取课信息.
      this.beginTime.length = 0;
      getAppointCourseDetailed(OLAPKey).then(result => {
        this.courseItem = result.data;
        this.courseItem.FullPeopleCount = Number(Number(this.courseItem.FullPeopleCount).toFixed(0));
        this.courseItem.PeriodCount = parseFloat(this.courseItem.PeriodCount).toFixed(1);
        this.PeriodCount = this.courseItem.PeriodCount;
        this.isChgDate = this.courseItem.ClassTime;

        this.beginTime.push(this.courseItem.SClassTimeName, this.courseItem.EClassTimeName);                 // 绑定时间弹出层的默认值.
        this.endTime = this.courseItem.EClassTimeName;                       // 绑定时间弹出层的默认值.
        this.courseItem.IsChargesKey = this.courseItem.IsChargesKey || 0;
        this.courseItem.IsleaveKey = this.courseItem.IsleaveKey || 0;
        this.courseItem.IsAutoLearnKey = this.courseItem.IsAutoLearnKey || 0;
        this.courseItem.MTeacherKey = this.courseItem.MTeacherKey || '';
        this.courseItem.MTeaKey = this.courseItem.MTeaKey || '';
        this.courseItem.ATeaKeyName = this.courseItem.ATeaKeyName || '';
        this.courseItem.ATeaKey = this.courseItem.ATeaKey || '';
        this.courseItem.ClassroomKey = this.courseItem.ClassroomKey || '';
        this.courseItem.classRoomKey = this.courseItem.classRoomKey || '';
        this.isChgDateTime = false;
        this.isChgDayTime = false;
      }).catch(error => {
        console.log(error);
      });
    },

    setCourseCancel (OLAPKey, IsNotEndKey) {
      if (!OLAPKey) {
        return false;
      }                     // 根据课名ID 停课.
      PauseScheduleCourse(this, OLAPKey, IsNotEndKey).then(result => {
        this.courseItem.IsClassStopKey = 1;
        this.$nextTick(() => {
          this.$emit('chgClassStopKey', this.courseItem, this.isChgDate);    // 通知父组件显示停课.
        });
        this.$emit('closeDialog');
        this.$bus.emit('AfterStopCourse', OLAPKey);
        layer.alert('停课成功');
      }, error => {
        if (error.code == 70001) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setCourseCancel(OLAPKey, 0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      });
    },
    updateCheck (courseItem) {
      if (!courseItem.ClassroomKeyValue) {
        layer.alert('请选择教室');
        return false;
      } else if (!courseItem.MTeacherKeyValue) {
        layer.alert('请选择主讲老师');
        return false;
      }
    },
    // 根据课名ID 更新信息,修改接口.
    courseUpdate (OLAPKey, courseItem, callback, IsNotEndKey) {
      if (!OLAPKey) {
        return false;
      }
      console.log("UpdateAppointCourse", this.courseItem)
      let isChangeDate = false;
      if (this.courseItem.ClassTime == this.isChgDate) {        // 判断是否修改过日期.
        isChangeDate = false;
      } else {
        isChangeDate = true;
      }
      this.courseItem.IsChargesKeyValue = this.courseItem.IsChargesKey == 1 ? '是' : '否';
      this.courseItem.IsleaveKeyValue = this.courseItem.IsleaveKey == 1 ? '是' : '否';
      UpdateAppointCourse(this.courseItem.OLAPKey, this.courseItem).then(result => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');      // 提交带store. 去更新数据.
        this.closeEditCourse();
      }, error => {
        if (error.code == 60102) {
          layer.alert('课程表冲突，请检查后再修改');
        } else if (error.code == 70000) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.courseUpdate(OLAPKey, courseItem, callback, 0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },

    changeClassItem (val) {
      this.choseClassItem = val;
    },
    receiveItemMainDemoName (val) {        // 接收地址
      this.courseItem.MainDemoName = val.MainDemoName;
    },
    getClassRoomList () {
      getValidClassroomList().then(result => {
        this.classRoomList = result.data;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择教室',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.courseItem.ClassroomKey, dataSourceList: this.classRoomList, addModuleName: 'classroom-setting' },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectClassRoom(data); }
          }
        });
      }).catch(error => {
        console.log(error);
      });
    },
    // 选择教室
    doAfterSelectClassRoom (item) {
      this.courseItem.ClassroomKey = item.OLAPKey || 0;
      this.courseItem.ClassroomKeyValue = item.MainDemoName || '';
      // this.closeClassRoomDialog();
    },
    getMTeacherList () {          /// 获取主讲老师列表
      getInJobTeacherList().then(result => {
        this.mTeacherList = result.data;
        this.mTeacherList.forEach(o => {
          this.$set(o, 'isActive', false);
        });
        this.showMTeacherList = true;    // 显示主讲老师弹出层
      }).catch(error => {
        console.log(error);
      });
    },
    closeshowMTeacherList () {       // 关闭弹出层
      this.showMTeacherList = false;
    },
    receiveMTeacherItem (val) {        // 接收地址
      this.courseItem.MTeacherKeyValue = val.selectedNames.toString().replace(/\,/g, ',');
      this.courseItem.MTeacherKey = val.selectedIDs.toString().replace(/\,/g, ',');
      this.showMTeacherList = false;
    },

    getATeacherList () {        // 获取辅讲老师列表
      getInJobTeacherList().then(result => {
        this.ATeacherList = result.data;
        this.ATeacherList.forEach(o => {
          this.$set(o, 'isActive', false);
        });
        this.showATeacherList = true;      // 显示辅讲老师弹出层
      }).catch(error => {
        console.log(error);
      });
    },
    closeshowATeacherList () {        // 关闭弹出层
      this.showATeacherList = false;
    },
    // 选中辅奖老师
    doAfterSelectATea (val) {
      this.courseItem.ATeaName = val.MainDemoName || '';
      this.courseItem.ATeaKeyName = val.OLAPKey || 0;
      this.closeshowATeacherList();
    },
    closeEditCourse () {              // 关闭弹出层
      this.$emit('closeDialog');
    },
    courseSuspend () {                          // 停课
      this.closeEditCourse();
    },

    changeFullPeopleCount (value) {
      this.courseItem.FullPeopleCount = value;
    },
    comfireBtn (callback) {                 // 修改单课接口.
      let beginClassTime = this.courseItem.ClassTime + ' ' + this.courseItem.SClassTimeName;
      let ATeaList = this.courseItem.ATeaKeyName.split(',');
      let MTeaList = this.courseItem.MTeacherKey.split(',');
      let locaIndex = null;
      if (ATeaList.length > 0) {
        ATeaList.forEach(o => {
          locaIndex = MTeaList.findIndex(p => { return o.indexOf(p) >= 0; });
          if (locaIndex >= 0) {

          }
        });
      }
      var that = this;
      let flag = true;
      console.log('comfireBtn', this.courseItem);
      if (!this.courseItem.MTeacherKey) {
        layer.alert('请选择主讲老师');
        flag = false;
      } else if ((this.isChgDateTime == true || this.isChgDayTime == true) && this.$utils.getFormatDateToQuery(beginClassTime).getTime() < Date.new().getTime()) {
        layer.alert('开始时间不能为过去的时间.');
        flag = false;
      } else if (this.courseItem.PeriodCount < 0) {
        layer.alert('课消不能小于0');
        flag = false;
      } else if (Number(this.courseItem.StartCourseCount) <= 0) {
        layer.alert('开课人数不能小于等于0');
        flag = false;
      } else if (Number(this.courseItem.FullPeopleCount) <= 0) {
        layer.alert('满员人数不能小于等于0');
        flag = false;
      } else if (Number(this.courseItem.StartCourseCount) > Number(this.courseItem.FullPeopleCount)) {
        layer.alert('开课人数不能大于满员人数');
        flag = false;
      }
      if (!flag) {
        if (typeof callback == 'function') {
          callback();
        }
        return false;
      }
      layer.confirm('是否修改单课', {
        shadeClose: false,
        btn: [{
          name: '取消',
          callBack: function () {
            if (typeof callback == 'function') {
              callback();
            }
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.courseUpdate(that.courseItem.OLAPKey, that.courseItem, callback, 1);
            layer.close();
          }
        }]
      });
    }

  }

};
</script>

