<template>
  <!-- 建券表单 -->
  <div class="popup_dialog_box_content clearfix">
    <div class="WKT_activity_compile_popup">
      <!-- 左侧预览 -->
      <div>
        <left-coupon-view :dataInfo="newDataInfo"></left-coupon-view>
      </div>
      <!-- 右侧编辑 -->
      <div>
        <right-coupon-edit :dataInfo="newDataInfo"
                           @submit="afterSuccess"
                           @cancel="closeDialog"></right-coupon-edit>
      </div>

    </div>
  </div>
</template>
<script>
import leftCouponView from './left-coupon-view';
import rightCouponEdit from './right-coupon-edit';

export default {
  components: { leftCouponView, rightCouponEdit },
  data () {
    return {
      newDataInfo: {}// 复制一份信息
    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {
    this.newDataInfo = this.$utils.parseJson(this.dataInfo);
  },
  watch: {

  },
  computed: {

  },
  methods: {
    afterSuccess (type, data) {
      this.$emit('afterSuccess', type, data);
    },
    closeDialog () {
      this.$emit('closeDialog');
    }
  }
};
</script>

