<template>
  <div class="apply_record_list label_radio_bg_white">
    <div @click.stop="toApplyRecord">
      <div class="list_date">{{item.GenerateTime}}</div>
      <div class="list_info">
        <div class="list_text">
          实收金额: {{Number(item.ActualColAmount)}}
        </div>
        <div class="list_text">
          经办人: {{item.BillerKeyValue}}
        </div>
      </div>
    </div>
    <div class="list_btn">
      <div class="button font_blue"
           v-if="Number(item.TrialArrangeKey)==0"
           @click.stop="seletedItem">关联报名</div>
      <div v-else>已关联</div>
    </div>
  </div>

</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item);
    },
    toApplyRecord () {
      this.$emit('toApplyRecord', this.item);
    }
  }
};
</script>