<template>
  <div>
    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="studenSearchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                @onSearchEvent="searchTextSearch"></input-search-contain-btn>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student"
            >
          <div class="search_result_list">
            <!-- active 选中当前 -->
            <single-selection-item v-for="item in vStudentList"
                                   :key="item.OLAPKey"
                                   :isTestStudentItem="item"
                                   @selectedItem="selectedItem"></single-selection-item>
           
            <div v-if="vStudentList.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content"
                     style="padding:0">
                  <div class="has_arrange_course_list ">
                    <div class=" has_arrange_course_nodata single_shadow ">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>

import singleSelectionItem from '../../common/single-selection-item';
export default {
  name: 'singleSelectionList',
  // switchItem--判断进入验证学生的开关，1为不验证
  // IsConfirmKey--0:显示全部学生， 1：过滤掉待验证的学生,默认为显示全部
  // props: [ 'existsStudentList', 'switchItem', 'IsConfirmKey', 'studentInfo', 'type'],
  props: {
    dataList:Array,
    addTitleName: {
      type: String,
      default: '新增'
    },
    isShowAddBtn: {
      type: Boolean,
      default: true
    },
    searchText:{
      type:String,
      default:()=>{
        return ''
      }
    }
  },
  data () {
    return {
      // 测试学生列表 数据
      studentList: [],
      studenSearchText: '',
      pageSize: 20,     // 每次搜索人数.
      pageIndex: 0,     // 每次查询页数
      noMore: false,
      loading: false
    };
  },
  components: {
    singleSelectionItem
  },
  computed: {
   
    vStudentList () {
      let newArr = [];
    
      this.dataList.forEach(o => {
       if(this.studenSearchText){
        if (o.MainDemoName.toLowerCase().indexOf(this.studenSearchText)!=-1 ){
            newArr.push(o);
        }
       }else{
        newArr.push(o);
       }
      });
      return newArr;
    }
    // vRouter(){

    // }

  },
  watch: {
  },
  created () {
    
    this.studenSearchText=this.searchText||''
  },
  methods: {
   
    searchTextSearch () {
    },
   
    selectedItem (item) {
      this.studentList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
       this.$emit('selectedItem', item);
        this.$emit('closeDialog');
      console.log('itemitemitemitem', item);

     
    },
    clearSearText () {
      this.studenSearchText = '';
    }
  }

};
</script>

