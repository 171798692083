<template>
  <div class="summarizing_list">
    <table-list
      :tableData="vTableInfoList"
      :tableColumns="tableColumns"
      :totalNum="totalNum"
      :queryParams="params"
      :isShowCheckBox="false"
      @loadTableData="getDataInfo"
    ></table-list>
  </div>
</template>
<script>
import tableList from "../../../common/table-list/index";
import { SearchPageYKRecommendForReceiveOvertime } from "../../../../API/workbench.js";
export default {
  data() {
    return {
      isShowMessagePopup: false,
      selectInfo: false,
      dataInfoList: [],
      totalNum: 0,
      tableColumns: [
        {
          label: "序号",
          prop: "Number",
          type: "table-index",
          className: "table_index_gray",
          sortable: false,
          width: 48,
        },
        {
          label: "推荐日期",
          prop: "RecommendTime",
          width: 200,
          type: "date-item",
          sortable: "custom",
        },
        {
          label: "投资人",
          prop: "MainDemoName",
          type: "text-item",
          width: 200,
        },
        {
          label: "手机号",
          prop: "MobilePhone",
          type: "text-item",
          width: 200,
        },
        {
          label: "计划城市",
          prop: "CityKeyValue",
          type: "text-item",
          width: 200,
        },
        {
          label: "加盟方式",
          prop: "FranchiseTypeKeyValue",
          type: "text-item",
          width: 200,
        },
        {
          label: "投资预算",
          prop: "BudgetAmount",
          type: "text-item",
          width: "",
        }
      ],
    };
  },
  props: {
    params: Object,
  },
  components: {
    tableList,
  },
  computed: {
    vTableInfoList() {
      let arr = [];
      this.dataInfoList.forEach((e) => {
        e.BudgetAmount = Number(e.StratBudgetAmount) + "-" + Number(e.EndBudgetAmount) + "万";
        e.CityKeyValue = e.ProvinceKeyValue + '·' + e.CityKeyValue;
        e.MobilePhone = String(e.MobilePhone).replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
        arr.push(e);
      });
      return arr;
    },
  },
  created() {
    this.getDataInfo();
  },
  methods: {
    getDataInfo() {
      SearchPageYKRecommendForReceiveOvertime(this.params).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataInfoList = res.data.PageDataList;
          this.$emit("upDataInfo", res.data);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
  },
};
</script>