<template>
  <div>
    <div class="form_info_line"
         :class="{ form_info_required: required }">
      <div class="form_info_field">
        <span>{{formTitle}}</span>
        <span v-if="helpDescription">
          <input-form-tip :tips='helpDescription'></input-form-tip>
        </span>
      </div>
      <div class="form_info_value form_info_select"
           @click.stop="showEditDialog">
        <input type="text"
               :placeholder="required?'必选':'可选'"
               readonly
               :value="value.nameText" />
      </div>
      <!-- 多选项 -->
      <!-- .single_result.single_class_info:after -->
      <custom-dialog :title="vDialogTitleName"
                     :visible.sync="isShowDialog"
                     :before-close="cancelEdit">
        <btn-add-select :addTitleName="addTitleName"
                        :addmoduleName="formTitle"
                        :addmoduleTitleName="'添加'+ formTitle"
                        :addmoduleKey="ModulePowerKey"
                        @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>

        <muti-select-list :mutilSelectSourceData="dataSource"
                          :selectionData="value"
                          :dialogTitleName="vDialogTitleName"
                          :idFieldName="'OLAPKey'"
                          :nameFieldName="'MainDemoName'"
                          :parentIDFieldName="'FatherKey'"
                          :curModuleName="doCurModuleName" 
                          :selectTypeItem="selectTypeItem"
                          :isSingleMode="isSingleMode"
                          @buttonClickEvent="confirmEdit"
                          @cancleClickForMutilSelected="cancelEdit"></muti-select-list>
      </custom-dialog>
    </div>
  </div>
</template>
<script>
import mutiSelectList from '../common/muti-select-list';
export default {
  name: 'formDialogMutiselect',
  data () {
    return {
      isShowDialog: false
    };
  },
  props: {
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    dataSource: Array, // 数据源
    value: {
      // 值，双向绑定，如不想双向绑定，则自行实现input事件
      type: Object,
      required: true,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return {
          nameText: '',
          idText: ''
        };
      }
    },
    ModulePowerKey: {
      type: [String, Number],
      default: ''
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    doCurModuleName: String,
    selectTypeItem: Object,
    isSingleMode: {  // true 为单选
      type: Boolean,
      default: false
    },
    helpDescription: String, // 表单说明提示
    addTitleName: {  // 新增按钮
      type: String,
      default: '新增'
    }
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    },
    vDialogTitleName () {
      return '选择' + this.formTitle;
    }
  },
  components: {
    mutiSelectList
  },
  methods: {
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      // 接收选中值.
      var item = {};

      item.selectedIDs = [];
      item.selectedNames = [];
      item.selectedIDs.push(result.OLAPKey);
      item.selectedNames.push(result.MainDemoName);
      this.confirmEdit(item);
      this.$emit('beforeLoadDataSource');
    },
    // 点击时，显示选择层
    showEditDialog () {
      this.isShowDialog = true;
      this.loadDataSource();
      this.$nextTick(e => {
        this.$emit('showGuide');
      });
    },
    loadDataSource () {
      if (this.dataSource.length == 0) {
        this.$emit('beforeLoadDataSource'); // 触发获取数据源事件，事件内部需更新数据源对象dataSource
      }
    },
    confirmEdit (val) {
      console.log(val, 'v');
      console.log(this.value, ' this.value');
      let oldIdText = this.value.idText;
      this.value.idText = val.selectedIDs.join(',');
      this.value.nameText = val.selectedNames.join(',');
      this.cancelEdit();
      if (oldIdText != this.value.idText) {
        this.$emit('input', this.value); // 触发普通change，模拟双向绑定
        this.$emit('change', val);// 触发变更事件，用于自定义变更监控
      }
    },
    cancelEdit () {
      this.isShowDialog = false;
      this.$parent.isShowMainForm = true;
    }
  }
};
</script>

