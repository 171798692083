<template>
  <div>
    <div class="content_marginRL content_marginTop_30 customer_registration"
         v-if="vShowStaffForm">
      <div class="customer_registration_content form_info_edit"
           id="customer_registration">
        <div class="form_info_edit mendian_setting_content single_shadow">
          <!-- 基础信息 -->
          <div class="customer_registration_info">
            <input-text :formTitle="'姓名'"
                        :readonly="readonlyInfo"
                        :formPlaceholder="'必填'"
                        :required="true"
                        v-model="staffItemInfo.MainDemoName"></input-text>

            <input-phone :formTitle="'手机号'"
                         :formPlaceholder="'必填'"
                         :required="true"
                         :readonly="vPower"
                         @change="doAfterChangeMobileSupport"
                         v-model="staffItemInfo.Mobile"></input-phone>

            <input-dialog :formTitle="'权限角色'"
                          :formPlaceholder="'必选'"
                          :readonly="vPower"
                          :required="true"
                          @showEldilog="doRoleSelect"
                          v-model="staffItemInfo.Chose2KeyValue"></input-dialog>

            <input-dialog :formTitle="'部门'"
                          :formPlaceholder="'必选'"
                          :readonly="readonlyInfo"
                          :required="false"
                          @showEldilog="doDepartmentSelect"
                          v-model="staffItemInfo.DepartmentKeyValue"></input-dialog>

            <div v-if="Number(staffItemInfo.DepartmentKey)>0">
              <input-dialog :formTitle="'职务'"
                            :formPlaceholder="'必选'"
                            :readonly="readonlyInfo"
                            :required="false"
                            @showEldilog="doPostSelect"
                            v-model="staffItemInfo.PostKeyValue"></input-dialog>
            </div>

            <input-password :formTitle="'初始密码'"
                            :readonly="readonlyInfo"
                            :formPlaceholder="'选填'"
                            :required="false"
                            :customPlaceholder='true'
                            :passwordDefaultVal="'默认密码 111111'"
                            v-model="staffItemInfo.PassWord"></input-password>

            <div class="pr"
                 v-if="$store.getters.SaaSClientEditionKey != 5">
              <input-switch v-model="staffItemInfo.IsTeacherKey"
                            :formTitle="'是否老师'"
                            style="padding-right: 23px;"
                            :readonly="readonlyInfo"
                            :required="false"></input-switch>
            </div>

            <div class="pr">
              <input-switch v-model="staffItemInfo.IsAdviserKey"
                            :readonly="readonlyInfo"
                            style="padding-right: 23px;"
                            :formTitle="'是否销售'"
                            :required="false"
                            @change="changeAdviser"></input-switch>
            </div>
            <div class="pr">
              <input-switch v-model="staffItemInfo.IsTeacherAdviserKey"
                            :readonly="readonlyInfo"
                            style="padding-right: 23px;"
                            :class="isShowBtn?'no_border_bottom':''"
                            :formTitle="'是否学管师'"
                            :required="false"
                            ></input-switch>
            </div>
          </div>
          <!-- 完善信息 追加内容 -->
          <div class="customer_registration_info customer_registration_info_more"
               v-show="!isShowBtn">
            <div>
              <div id="screanContent"
                   style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
              <input-upload-img :ImgURl="staffItemInfo.XMLIcon?staffItemInfo.XMLIcon:''"
                                :formTitle="'员工头像'"
                                :imgHeight="101"
                                :imgWidth="101"
                                :readonly="readonlyInfo"
                                @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>

              <form-select :formTitle="'性别'"
                           :required="false"
                           :readonly="readonlyInfo"
                           :dataItem="staffItemInfo.SexKeyValue"
                           :dataList="sexData"
                           v-model="staffItemInfo.SexKeyValue"
                           @choseItem="changeSexData"></form-select>

              <input-text :maxLength="18"
                          :formTitle="'身份证号'"
                          :readonly="readonlyInfo"
                          :formPlaceholder="'选填'"
                          :required="false"
                          v-model="staffItemInfo.IDCard"></input-text>
              <input-upload-img :ImgURl="staffItemInfo.ZangIcon?staffItemInfo.ZangIcon:''"
                                :formTitle="'身份证照片'"
                                :imgHeight="210"
                                :imgWidth="320"
                                :readonly="readonlyInfo"
                                :imgstyle="'width: 40px!important;height: 40px;z-index:10;'"
                                @doSetPicCorp="doSetPicCorpIdentityImg"></input-upload-img>

              <input-date v-model="staffItemInfo.StartTime"
                          :formTitle="'入职日期'"
                          :readonly="readonlyInfo"
                          :elementId="'BirthdayTime'"
                          :required="false"
                          :formPlaceholder="'选填'"
                          @changeDate="changeBirthdayTime"></input-date>

              <!-- <input-switch v-model="staffItemInfo.IsPTJobKey"
                            :readonly="readonlyInfo"
                            style="padding-right: 23px;"
                            :formTitle="'是否兼职'"
                            :required="false"></input-switch> -->

              <input-text :formTitle="'备注'"
                          :readonly="readonlyInfo"
                          :formPlaceholder="readonlyInfo?'':'备注'"
                          :required="false"
                          v-model="staffItemInfo.Script"
                          :class="!isShowBtn?'no_border_bottom':''"></input-text>
            </div>
          </div>
        </div>
        <!-- 完善信息 -->
        <div class="form_extend_btn content_marginRL single_shadow"
             @click.stop="showAllInfo"
             v-show="isShowBtn">
          <div>
            <span class="form_extend_more">完善{{vstaffTypeName}}信息</span>
          </div>
        </div>
        <!-- 按钮组 -->

        <div v-if="!readonlyInfo">
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
        </div>
        <div v-else
             class="on_form_footer_btn_group"></div>
      </div>
    </div>

    <custom-dialog title="选择权限角色"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRoleList"
                   :before-close="doCancelClick">
      <div class="search_box">
        <option-list :formTitle="' '"
                     :switchItem="true"
                     :isShowBtnGroup="false"
                     :addTitle="' '"
                     :dataSourceLsit="roleList"
                     @return="returnBox"
                     @selectedItem="selectInfo"></option-list>
      </div>
    </custom-dialog>

    <custom-dialog :title="'选择部门'"
                   :visible.sync="isShowJobList"
                   :before-close="doCancelClick">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'department-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='$store.getters.SaaSClientInfo.TypeKey==6?115:44'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>
      <single-select-tree :sourceDate="departmentList"
                          :selectionDate="vDepartmentInfo"
                          :disabledType="1"
                          @confirmClcik="doAfterSelectedItem"
                          @cancleClcik="closeSingleSelectionList"
                          ref="singleSelectTree"></single-select-tree>
    </custom-dialog>

    <custom-dialog :title="'选择职务'"
                   :visible.sync="isShowPostList"
                   :before-close="doCancelClick">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'post-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='$store.getters.SaaSClientInfo.TypeKey==6?115:44'
                      @doAddSuccessEvent="selectPostInfo"></btn-add-select>
      <option-list :formTitle="' '"
                   :switchItem="true"
                   :isShowBtnGroup="false"
                   :addTitle="' '"
                   :dataSourceLsit="postList"
                   @return="returnBox"
                   @selectedItem="selectPostInfo"></option-list>
    </custom-dialog>
  </div>
</template>
<script>

import formSelect from '../../../form-item/form-select';
import singleSelectTree from '../../../common/single-select-tree';
import optionList from '../../../common-module/timetable-list/option-single-list/index';
import { AddEmployee, EditEmployeeInfo, SearchRole, MessageSearchPageForStation, MessageSearchPageForDepartment, GetEmployeeInfo, DelRecruitStudentSale } from '../../../../API/workbench.js';
// import fun from '../../../assets/common/KPI';
export default {
  name: 'registerCustomer',
  data () {
    return {
      staffItemInfo: {
        MainDemoName: '', // --名称
        SexKey: 2, // --性别
        SexKeyValue: '男', // --性别
        Mobile: '', // --手机号
        StartTime: this.$utils.formatDateToLine(Date.new()), // 获取当前日期和时间, // --入职日期
        Birthday: '', // --出生日期
        IDCard: '', // --身份证号
        XMLIcon: '', // --头像照片
        ZangIcon: '', // --身份证照片
        DepartmentKey: '', // --所属部门
        DepartmentKeyValue: '', // --所属部门
        PostKey: '', // --岗位
        PostKeyValue: '', // --岗位
        IsAdviserKey: 1, // --是否销售(1:是;0否)
        IsAdviserKeyValue: '是', // --是否销售 顾问
        IsTeacherAdviserKey: 0, // --是否销售(1:是;0否)
        IsTeacherAdviserKeyValue: '否', // --是否销售 顾问
        IsTeacherKey: 1, // --是否老师(1:是;0否)
        IsTeacherKeyValue: '是', // --是否老师
        IsPTJobKey: 0, // --是否兼职(1:是;0否)
        IsPTJobKeyValue: '', // --是否兼职
        Chose2Key: '', // --权限角色
        Chose2KeyValue: '', // --权限角色
        Script: '', // --备注
        SpreadConponKey: '', // --推广活动
        SpreadConponKeyValue: '', // --推广活动
        PassWord: '', // --密码
        IfCloseKey: 1, // --是否开通系统(1:是;0否)
        IfCloseKeyValue: '是'
      }, // 员工信息
      readonlyInfo: this.isReadonly, // 开放变量 默认false-修改模式;true-只读模式
      isShowBtn: true,
      sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
      roleList: [], // 获取权限List
      postList: [], // 获取职务List(岗位 + 部门)
      departmentList: [],
      allDutyList: [],
      // 职务查询
      postParameters: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      // newStaffFormDescribe: '完善员工信息',
      isShowRoleList: false, // 权限列表
      isShowJobList: false, // 部门列表
      isShowPostList: false // 岗位列表

    };
  },
  components: {
    optionList,
    singleSelectTree,
    formSelect
  },
  props: {
    // 员工ID
    staffID: {
      type: [Number, String],
      default: 0
    },
    // 员工类型
    staffType: {
      type: String,
      default: '员工'
    },
    // 是否只读
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (Number(this.staffID) > 0) { // 修改员工
      this.getEmployeeInfo();
      this.showAllInfo();
    };
    this.departmentListSelect();
    this.loadPostData();
  },
  computed: {
    // 显示员工表单
    vShowStaffForm () {
      if (Number(this.staffID) > 0 && Number(this.staffItemInfo.OLAPKey) > 0) { // 修改时，防止数据还未加载就被渲染
        return true;
      } else if (Number(this.staffID) == 0) { // staffID：为0时，新增模式
        return true;
      }
    },
    // 显示折叠位置提示类型
    vstaffTypeName () {
      if (this.staffType && this.staffType.indexOf('老师') > -1) {
        return '老师';
      } else {
        return this.staffType || '员工';
      }
    },
    vDepartmentInfo: {
      get () {
        return {
          id: this.staffItemInfo.DepartmentKey,
          label: this.staffItemInfo.DepartmentKeyValue
        };
      },
      set (newValue) {
        this.staffItemInfo.DepartmentKeyValue = newValue.label;
        this.staffItemInfo.DepartmentKey = newValue.id;
      }
    },
    vSexKey: {
      get () {
        return {
          key: this.staffItemInfo.SexKey,
          value: this.staffItemInfo.SexKeyValue
        };
      },
      set (newValue) {
        this.staffItemInfo.SexKey = newValue.key;
        this.staffItemInfo.SexKeyValue = newValue.value;
      }
    },
    vPower () {
      if (this.staffItemInfo.OLAPKey > 0) {
        let isTypeKey = Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6;
        return (this.vHasModulePower(isTypeKey ? 115 : 39) == false ||
          Number(this.staffItemInfo.Chose2Key) == (isTypeKey ? 101 : 2) ||// 默认管理员 (key为2)，角色锁死
          Number(this.staffItemInfo.Chose2Key) == 10049// 总部管理员 (key为4)，角色锁死
        ) ? true : this.readonlyInfo;
      } else {
        return false;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    // 是否顾问
    changeAdviser (type) {
      console.log(this.staffItemInfo.IsAdviserKey, 'staffItemInfo.IsAdviserKey', type);
      if (Number(this.staffID) > 0 && this.staffItemInfo.IsAdviserKey == 0) {
        this.staffItemInfo.IsAdviserKey = 1;
        layer.confirm('关闭后将会解绑线索数据，是否关闭', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              DelRecruitStudentSale(this.staffItemInfo.OLAPKey).then(result => {
                this.staffItemInfo.IsAdviserKey = 0;
                layer.alert('关闭成功');
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      }
    },
    getEmployeeInfo () {
      GetEmployeeInfo(this.staffID).then(result => {
        this.staffItemInfo = result.data;
        if (this.staffItemInfo.StartTime) {
          this.staffItemInfo.StartTime = this.staffItemInfo.StartTime.slice(0, 10);
        }
        console.log(this.staffItemInfo, '加载员工项');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 新增回调
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      // 接收选中值.
      var item = {};
      item.id = result.OLAPKey;
      item.label = result.MainDemoName;
      item.parentId = result.FatherKey;
      this.doAfterSelectedItem(item);
      this.departmentListSelect();
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    doCancelClick () {
      this.isShowRoleList = false;
      this.isShowJobList = false;
      this.isShowPostList = false;
    },
    // 是否完善资料.
    showAllInfo () {
      this.isShowBtn = false;
    },
    // 性别显示
    changeSexData (val) {
      this.staffItemInfo.SexKey = val.key;
      this.staffItemInfo.SexKeyValue = val.value;
    },
    // 员工头像地址
    doSetPicCorpHeadImg (Url) {
      this.staffItemInfo.XMLIcon = Url;
    },
    // 员工身份证地址
    doSetPicCorpIdentityImg (Url) {
      console.log(Url, 'ZangIcon');
      this.staffItemInfo.ZangIcon = Url;
    },
    // 先选择部门
    doDepartmentSelect () {
      this.departmentListSelect();
      this.isShowJobList = true;
    },
    // 在选择岗位
    doPostSelect () {
      this.loadPostData();
      this.isShowPostList = true;
    },
    // 查询部门
    departmentListSelect () {
      MessageSearchPageForDepartment(this.postParameters).then(result => {
        this.departmentList = [];
        result.data.PageDataList.forEach(o => {
          o.TypeKey = 2;
          if (o.StatusKey == 1) {
            this.departmentList.push(o);
          }
        });
        this.departmentList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部',
          FatherKey: '0',
          FatherKeyValue: '',
          Number: '0',
          StatusKey: '2',
          TypeKey: 1,
          StatusKeyValue: '启用',
          RemarksSupport: ''
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 查询岗位
    loadPostData () {
      MessageSearchPageForStation(this.postParameters).then(result => {
        this.postList = [];
        result.data.PageDataList.forEach(o => {
          o.TypeKey = 2;
          if (o.StatusKey == 1 && this.staffItemInfo.DepartmentKey == o.FatherKey) {
            this.postList.push(o);
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 确定部门
    doAfterSelectedItem (item) {
      console.log(item, 'doAfterSelectedItem111');
      this.staffItemInfo.DepartmentKey = item.id;
      this.staffItemInfo.DepartmentKeyValue = item.label;// --所属部门;
      // 选择部门初始化所属岗位
      this.staffItemInfo.PostKey = '';
      this.staffItemInfo.PostKeyValue = '';// --所属岗位;
      this.isShowJobList = false;
      this.loadPostData();
    },
    // 确定岗位
    selectPostInfo (item) {
      this.staffItemInfo.DepartmentKey = item.FatherKey;
      this.staffItemInfo.DepartmentKeyValue = item.FatherKeyValue;// --所属岗位;
      this.staffItemInfo.PostKey = item.OLAPKey;
      this.staffItemInfo.PostKeyValue = item.MainDemoName;// --所属岗位;
      this.$store.commit('setShowTimetableId', this.staffItemInfo.PostKey);
      this.isShowPostList = false;
      this.loadPostData();
    },
    // 取消职务
    closeSingleSelectionList () {
      this.isShowJobList = false;
    },
    // 变更角色权限
    doRoleSelect () {
      let staffRolePower = Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6 ? 115 : 39;
      if (this.staffItemInfo.OLAPKey > 0) {
        if (this.vHasModulePower(staffRolePower)) {
          SearchRole().then(
            result => {
              console.log(result, '角色权限查询');
              this.roleList = result.data;
              this.isShowRoleList = true;
            },
            error => {
              layer.alert(error.msg);
            }
          );
        } else {
          console.log('权限不足');
          this.$utils.CheckModulePowerToTips(staffRolePower);
        }
      } else {
        SearchRole().then(
          result => {
            console.log(result, '角色权限查询');
            this.roleList = result.data;
            this.isShowRoleList = true;
          },
          error => {
            layer.alert(error.msg);
          }
        );
      }
    },
    returnBox () {
      this.isShowRoleList = false;
      this.isShowPostList = false;
    },
    selectInfo (item) {
      console.log(item, '3');
      this.staffItemInfo.Chose2Key = item.OLAPKey;
      this.staffItemInfo.Chose2KeyValue = item.MainDemoName;
      this.$store.commit('setShowTimetableId', this.staffItemInfo.Chose2Key);
      this.returnBox();
    },
    onItemClick (val, item) {
      console.log(val, item, 'val, item');
      this.staffItemInfo.SexKeyValue = item.menuName;
      this.staffItemInfo.SexKey = val;
      this.$refs.isShowMenu.hideBox = false;
    },
    // 保存
    confirmClick (callBack) {
      let res = this.validatestaffItemInfo();
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.confirmData(callBack);
    },
    confirmData (callBack) {
      console.log(this.staffItemInfo, 'ZangIcon11');
      if (this.staffItemInfo.OLAPKey > 0) {
        var data = this.upmendianInfo(this.staffItemInfo);
        EditEmployeeInfo(data).then(result => {
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          layer.alert('修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddEmployee(this.staffItemInfo).then(result => {
          result.data.isActive = true;
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 验证客户信息
    validatestaffItemInfo () {
      if (this.staffItemInfo.MainDemoName) {
        this.staffItemInfo.MainDemoName = this.staffItemInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;  // 校验身份证输入
      let flag = true;
      if (!this.staffItemInfo.MainDemoName) {
        layer.alert('请输入员工姓名');
        flag = false;
      } else if (this.staffItemInfo.Mobile.length != 11) {
        layer.alert('请输入11位的手机号码');
        flag = false;
      } else if (!this.staffItemInfo.Chose2KeyValue) {
        layer.alert('请选择权限角色');
        flag = false;
      } else if (this.staffItemInfo.IDCard && reg.test(this.staffItemInfo.IDCard) == false) {
        layer.alert('身份证输入不合法');
        flag = false;
      }
      return flag;
    },
    // 取消
    cancelClick () {
      this.$store.commit('setShowTimetableId', 0);
      this.$emit('closeDialog');
    },

    // 改变手机后 查询该手机号下的所有员工
    doAfterChangeMobileSupport () {

    },
    // 修改提交员工信息
    upmendianInfo (item) {
      if (item) {
        this.mendianInfo = item;
      }
      return {
        OLAPKey: this.staffItemInfo.OLAPKey || '', // --员工id
        MainDemoName: this.staffItemInfo.MainDemoName || '', // --名称
        SexKey: this.staffItemInfo.SexKey || '', // --性别
        SexKeyValue: this.staffItemInfo.SexKeyValue || '', // --性别
        Mobile: this.staffItemInfo.Mobile || '', // --手机号
        StartTime: this.staffItemInfo.StartTime || '', // --入职日期
        Birthday: this.staffItemInfo.Birthday || '', // --出生日期
        IDCard: this.staffItemInfo.IDCard || '', // --身份证号
        XMLIcon: this.staffItemInfo.XMLIcon || '', // --头像照片
        ZangIcon: this.staffItemInfo.ZangIcon || '', // --身份证照片
        DepartmentKey: this.staffItemInfo.DepartmentKey || '', // --所属部门
        DepartmentKeyValue: this.staffItemInfo.DepartmentKeyValue || '', // --所属部门
        PostKey: this.staffItemInfo.PostKey || '', // --岗位
        PostKeyValue: this.staffItemInfo.PostKeyValue || '', // --岗位
        IsAdviserKey: this.staffItemInfo.IsAdviserKey || '', // --是否销售(1:是;0否)
        IsAdviserKeyValue: this.staffItemInfo.IsAdviserKeyValue || '', // --是否销售
        IsTeacherAdviserKey: this.staffItemInfo.IsTeacherAdviserKey || '', // --是否学管师(1:是;0否)
        IsTeacherAdviserKeyValue: this.staffItemInfo.IsTeacherAdviserKeyValue || '', // --是否学管师
        
        IsTeacherKey: this.staffItemInfo.IsTeacherKey || '', // --是否老师(1:是;0否)
        IsTeacherKeyValue: this.staffItemInfo.IsTeacherKeyValue || '', // --是否老师
        IsPTJobKey: this.staffItemInfo.IsPTJobKey || '', // --是否兼职(1:是;0否)
        IsPTJobKeyValue: this.staffItemInfo.IsPTJobKeyValue || '', // --是否兼职
        Chose2Key: this.staffItemInfo.Chose2Key || '', // --权限角色
        Chose2KeyValue: this.staffItemInfo.Chose2KeyValue || '', // --权限角色
        Script: this.staffItemInfo.Script || '', // --备注
        SpreadConponKey: this.staffItemInfo.SpreadConponKey || '', // --推广活动
        SpreadConponKeyValue: this.staffItemInfo.SpreadConponKeyValue || '', // --推广活动
        PassWord: this.staffItemInfo.PassWord || '', // --密码
        IfCloseKey: this.staffItemInfo.IfCloseKey || 1, // --是否开通系统(1:是;0否)
        IfCloseKeyValue: this.staffItemInfo.IfCloseKeyValue || '是'
      };
    },
    // 改变生日
    changeBirthdayTime () {

    }
  }
};
</script>
