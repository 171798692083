<template>
  <div class="couser_date_arrange_list">
    <div class=" clearfix">
      <div class="list_month fl">
        {{monthInfo.monthName}} 月
      </div>
      <div class="list_day fl">
        <date-item v-for="(item,index) in monthInfo.dayArr"
                   :key="index"
                   :dateInfo="item"
                   :index="index"
                   :courseItem="courseItem"></date-item>
      </div>
    </div>
  </div>
</template>

<script>
import dateItem from './date-item';
export default {
  props: {
    monthInfo: Object,
    courseItem: Object
  },
  components: {
    dateItem
  },
  methods: {
  }
};
</script>

<style>
</style>