<template>
  <div class="table_th"
       :class="columnInfo.className"
       :style="{width:columnInfo.width+'px'}">
    <div>
      {{columnInfo.label}}
    </div>
    <div v-if="columnInfo.tips" class="table_header_tips">
      {{columnInfo.tips}}
    </div>
    <div v-if="columnInfo.warning" class="table_header_warning">
      {{columnInfo.warning}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columnInfo: {
      type: Object,
      default: {
        label: '序号',
        prop: 'Number',
        width: 55,
        isShow: true,
        className: 'table_index'
      }
    }
  }
};
</script>

<style>
</style>