<template>
  <div>
    <seleted-option :searchObj="searchObj"
                    style="padding-top:20px;padding-left:20px"
                    :teacherList="teacherList"
                    :gradeClassList="gradeClassList"
                    :courseNameList="courseNameList"
                    @changeSearchObj="updateData"></seleted-option>
    <div class="payment_statistics_bottom course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  :minDate="vMinDate"
                  :maxDate="vMaxDate"
                  :isShowHistory="isShowHistory"
                  @changeDate="changeSaveDate"></date-range>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content deduct_detail_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import debtCourseHourseDetails from '../../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
import attendanceArrange from '../../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
// import list from './table';
import seletedOption from './seleted-option';
import { MessageSearchClassAwayForDeduct, getStudentRelatedMsg, MessageSearchClassAwayForDeductH } from '../../../../API/workbench';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        QueryID: 0,
        GradeKey: 0,
        GradeKeyValue: '',
        CourseKey: 0,
        CourseKeyValue: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '上课时间',
          prop: 'ClassTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => { this.doAfterClickToAtten(rowData); },
            ModulePowerKey: 51
          },
          decimalPlace: 0
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          width: 142,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          wdth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: 'font_dual'
        },
        {
          label: '学生',
          prop: 'StudentNameKeyValue',
          width: 104,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_blue',
          type: 'student',
          extend: {
            studentKey: 'StudentNameKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '学生课时',
          prop: 'ClassCount',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'class-hour',
          extend: {
            setClassName: (rowData) => {
              if (rowData.IsMakeupKey == 1) {
                return 'makeup_class';
              } else if (rowData.IsTrialKey == 1) {
                return 'makeup_class';
              } else {
                return rowData.XYKDKey > 0 ? '' : 'no_click';
              }
            },
            setShowOwe: (rowData) => {
              return rowData.ISClassHourDebtKey > 0;
            }
          }
        },
        {
          label: '考勤状态',
          prop: 'AttenceStatusKeyValue',
          sortable: 'custom',
          width: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '打考勤人',
          prop: 'AttenceOpratorKeyValue',
          sortable: 'custom',
          width: 120,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '考勤时间',
          prop: 'AttenceTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '还课时',
                extend: {
                  click: (rowData) => {
                    this.studentDetail = rowData;
                    this.doDebtDetailsClick(this.studentDetail, rowData);
                  },
                  ModulePowerKey: 50
                }

              }

            ]
          }
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    attendanceArrange,
    debtCourseHourseDetails,
    inputSearchContainBtn,
    seletedOption
  },
  props: {
    seletedDate: {
      type: String,
      default: ''
    },
    teacherList: Array,
    gradeClassList: Array,
    courseNameList: Array
  },
  created () {

  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      // this.dataReportList.forEach(o => {
      //   // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
      //   // o.classTimeObj = info.split(',');
      //   o.ClassTime = o.ClassTime.substring(5);
      //   o.AttenceTime = o.AttenceTime.substring(5, 16);
      //   if((o.IsMakeupKey == 1|| o.IsTrialKey == 1)&&o.ClassHourKey==5){
      //     o.ClassCountCash = o.ClassCount
      //     console.log('o.ClassCountCash',o.ClassCountCash)
      //   }
      //   o.ClassCount = o.IsMakeupKey == 1 ?'补课': o.IsTrialKey == 1 ? '试课' : o.ClassCount;
      //   o.ISClassHourDebtKey = 1;
      //   // this.$set(o, 'OLAPKey', o.CourseNameKey);
      //   newArr.push(o);
      // });
      return this.dataReportList;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据 欠课时数' + this.totalAmount + '课时';
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      this.getDataList();
    });
    if (this.seletedDate) {
      this.searchObj.startTime = this.seletedDate;
      this.searchObj.endTime = this.seletedDate;
    }
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.saveDate.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      // this.searchObj = {
      //   pageIndex: 0,   // 门店id
      //   pageSize: 15,
      //   searchText: '',
      //   orderBy: '',
      //   type: '',
      //   sequence: 'DESC',
      //   QueryID: 0,
      //   startTime: '',
      //   endTime: '',
      //   IsExportKey: 0
      // };
      this.$refs.saveDate.resetTime();
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (date) {
      if (date) {
        console.log(date, 'searchData');
        this.searchObj.startTime = date;
        this.searchObj.endTime = date;
      }
      this.searchObj.pageIndex = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchClassAwayForDeductH;
        } else {
          fn = MessageSearchClassAwayForDeduct;
        }
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '欠课时明细',
          clist: [
            { title: '上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '学生课时', cName: 'ClassCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ClassCount::1::0}}" },
            { title: '是否欠课时', cName: 'ISClassHourDebtKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤状态', cName: 'AttenceStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '打考勤人', cName: 'AttenceOpratorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤时间', cName: 'AttenceTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchClassAwayForDeductH;
      } else {
        fn = MessageSearchClassAwayForDeduct;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }

      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeduct).toFixed(2));
        result.data.PageDataList.forEach(o => {
          // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
          // o.classTimeObj = info.split(',');
          o.ClassTime = o.ClassTime.substring(5);
          o.AttenceTime = o.AttenceTime.substring(5, 16);
          if (((o.IsMakeupKey == 1 || o.IsTrialKey == 1) && o.ClassHourKey == 5) || o.ISClassHourDebtKey == 1) {
            o.ClassCountCash = o.ClassCount;
            console.log('o.ClassCountCash', o.ClassCountCash);
          }
          o.ClassCount = o.IsMakeupKey == 1 ? '补课' : o.IsTrialKey == 1 ? '试课' : o.ClassCount;
          o.ISClassHourDebtKey = 1;
          // this.$set(o, 'OLAPKey', o.CourseNameKey);
        });
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterClickToAtten (rowData) {
      this.seletedCourseInfo = rowData;
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.ScheduleCourseKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 去还款
    doDebtDetailsClick (item, rowData) {
      getStudentRelatedMsg(item.StudentNameKey).then(res => {
        if (res.code == 0) {
          this.studentDetail = res.data;
          this.studentDetail.CourseNameKey = rowData.CourseNameKey;
          this.$nextTick(o => {
            this.isDebtCourseHoursDetailsShow = true;
            this.$dialog.open(this, {
              name: '还课时',
              routerName: this.$route.name,
              moduleName: 'debtCourseHourseDetails',
              data: { studentItem: this.studentDetail },
              callBack: {
                afterSuccess: () => { this.confirmDebtCourseHoursDetails(); }
              }
            });
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    },
    // 确认欠课时详情
    confirmDebtCourseHoursDetails (error) {
      this.getDataList();
      this.closeDetails();
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>