<template>
  <!-- type_warning 签到异常 -->
  <div class="sundent_list clearfix"
       @click.stop="seletedItemAfter"
       :class="item.isActive?'opt':''">
    <div class="sundent_list_photo fl">
      <img :src="headImg"
           alt="">
    </div>
    <div class="sundent_list_info fl">
      <div class="sundent_list_info_name">
        {{item.MainDemoName}}
      </div>
      <div class="sundent_list_info_phone">
        {{$utils.phoneModulePower(item.MobileSupport)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    // 头像
    headImg () {
      if (this.item.HeadImgSupport && this.item.HeadImgSupport != 'null') {
        if (this.item.HeadImgSupport.indexOf('http') > -1 && this.item.HeadImgSupport.indexOf('KPIupload') > -1) {
          return this.item.HeadImgSupport;
        } else {
          let pcDomainURLOld = this.$store.getters.CDNURL;
          return pcDomainURLOld + this.item.HeadImgSupport; // 服务器的图片地址有时有问题，会返回404报错
        }
        // 默认显示头像
      } else {
        if (parseInt(this.item.SexKey) === 2) {
          return require('../../../../public/image/deflut_studentPhoto_boy.png');
        } else if (parseInt(this.item.SexKey) === 3) {
          return require('../../../../public/image/deflut_studentPhoto_gril.png');
        } else {
          return require('../../../../public/image/face_screenage_noImg.png');
        }
      }
    }
  },
  methods: {
    seletedItemAfter () {
      this.$emit('seletedItem', this.item);
    }
  }

};
</script>

<style>
</style>