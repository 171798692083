<template>
  <div class="form_list_children_box">
    <div class="children_name"
          style="width: 113px;">
      {{listData.title}}
    </div>
    <div class="children_content">
      <div class="form_list_checkbox flex">
        <item-box v-for="(item,index) in listData.childrenList" :key="index" :item="item" :parentList="listData" @clickItem="clickItem"></item-box>
      </div>
    </div>
  </div>
</template>

<script>
import itemBox from './item-box'
export default {
  props:{
    listData:Object
  },
  components:{
    itemBox
  },
  methods:{
    clickItem(){
      this.$emit('clickItem')
    }
  }
}
</script>

<style>

</style>