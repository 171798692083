var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"student_info_list",on:{"click":_vm.studentInfoClick}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"list_img",class:_vm.vClass},[(!_vm.vStudentPhoto)?_c('span',{staticClass:"text_box"},[_vm._v(_vm._s(_vm.vStudentInitial))]):_vm._e(),_c('img',{staticClass:"img_box",attrs:{"src":_vm.vStudentPhoto,"mode":""}})]),_c('div',{},[_c('div',{staticClass:"list_name"},[_c('span',{},[_vm._v(_vm._s(_vm.studentInfo.MainDemoName))])]),_c('div',{staticClass:"list_text"},[(_vm.studentInfo.CurrentClassHourCount > 0)?_c('span',{},[_vm._v("剩余课时 "),_c('span',{staticClass:"font_margin"},[_vm._v(_vm._s(Number(_vm.studentInfo.CurrentClassHourCount)))])]):_vm._e(),(
              _vm.studentInfo.CurrentClassHourCount > 0 &&
                (_vm.studentInfo.YearCardMaxDayCount > 0 ||
                  _vm.studentInfo.DebtCount > 0)
            )?_c('span',[_vm._v("丨")]):_vm._e(),(_vm.studentInfo.YearCardMaxDayCount > 0)?_c('span',[_vm._v("年卡剩余 "),_c('span',{staticClass:"font_margin"},[_vm._v(_vm._s(Number(_vm.studentInfo.YearCardMaxDayCount))+"天")])]):_vm._e(),(
              _vm.studentInfo.YearCardMaxDayCount > 0 && _vm.studentInfo.DebtCount > 0
            )?_c('span',[_vm._v("丨")]):_vm._e(),(_vm.studentInfo.DebtCount && _vm.studentInfo.DebtCount > 0)?_c('span',[_vm._v(" 欠 "),_c('span',{staticClass:"font_margin font_red"},[_vm._v(_vm._s(Number(_vm.studentInfo.DebtCount || 0)))]),_c('span',{staticClass:"font_margin"},[_vm._v("课时")])]):_vm._e(),(
              _vm.studentInfo.DebtCount == 0 &&
                _vm.studentInfo.CurrentClassHourCount == 0 &&
                _vm.studentInfo.YearCardMaxDayCount == 0
            )?_c('span',{staticClass:"font_red"},[_vm._v("课时耗尽")]):_vm._e()])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"list_WX",class:Number(_vm.studentInfo.SubscribeKey > 0) ? '' : 'type_gray',on:{"click":function($event){$event.stopPropagation();return _vm.toWX.apply(null, arguments)}}}),_c('div',{staticClass:"list_phone",class:_vm.studentInfo.CustomerPhoneName.length == 0 ? 'type_gray' : '',on:{"click":function($event){$event.stopPropagation();return _vm.makePhone.apply(null, arguments)}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }