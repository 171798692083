<template>
  <div class="recommend_manage_view table_list_content all_width box_withe_radius">
    <div class="recycle_title">加盟签单回收站</div>
    <search-box :isBatchDistribute="isBatchDistribute"
              :params="paramsForQuery" 
              :batchAllotList="batchAllotList" 
              :tableData="brandList"
              @searchClick="searchClick" 
              @clearSearchParams="clearSearchParams"
              @doBatchAllot="distributeAdvisor"
              @doExportInfoClick='doExportInfoClick'
              @cancleBatchDistribute="cancleBatchDistribute" ></search-box>
    <div class="summarizing_list">
      <table-list ref="tableListRef"
                  :tableData="brandList"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="paramsForQuery"
                  :isShowCheckBox="false"
                  @changeCheckBoxFun="clickCheckBox"
                  @loadTableData="loadDataList"></table-list>
                  <!-- :selectable="false" -->
    </div>
   <div>
      <dialog-factory ref="crmContactRecycleBinDialogFactory"
                      :key="'crmContactRecycleBin'"
                      :routerName="'crmContactRecycleBin'"></dialog-factory>
      <dialog-factory-common ref="crmContactRecycleBinDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>

<script>
import dialogFactory from '../../../common/dialog-factory';
import { HQSearchPageContractRecycleBin } from '../../../../API/workbench.js';
import tableList from '../../../common/table-list';// 下拉选择框
import searchBox from './search-box';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          type: 'table-index'
        },
        {
          label: '作废日期',
          prop: 'CancellationTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '签单日期',
          prop: 'ApplicantTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '投资人',
          prop: 'AttractInvestKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '顾问',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '合同金额',
          prop: 'ContractAmount',
          type: 'amount-item',
          width: 100
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '合同变更条款',
          prop: 'AmendingClause',
          type: 'text-item',
          width: 240
        },
        {
          label: '定金',
          prop: 'EarnestAmount',
          type: 'amount-item',
          width: 80
        },
        {
          label: '作废原因',
          prop: 'CancellationReson',
          type: 'text-item',
          width: 180
        },
        {
          label: '操作人',
          prop: 'CancellationKeyValue',
          type: 'text-item',
          minWidth: 80
        }
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: '', // 排序顺序
        sourceKey: 0,     // 来源
        paymentScheduleKey: 0,  // 付款进度 1-已签单;2-付定中;3-已付定;4-尾款中;5-全款中;6-已全款
        counselorKey: 0,    // 销售顾问
        joinTheWay: 0,    // 加盟方式  1-课程加盟;2-整店加盟
        IsExportKey: 0 // 是否导出：0-否;1-是;如果为是pageIndex传0,pageSize传0
      },
      brandList: [], // 投资人列表

      totalNum: 0,
      defaultSort: {},
      batchAllotList: [], // 选择的投资人列表
      isBatchDistribute: false,
      curSelectedBrand: {}, // 当前选中品牌
      isShowDetail: false// 查看品牌详情
    };
  },
  components: {
    tableList,
    searchBox,
    dialogFactory

  },
  computed: {
  },
  created () {
    this.loadDataList();
    this.registerBusEvent();
  },
  methods: {
      // 导出
    doExportInfoClick (callBack) {
      if (this.brandList.length > 0) {
        var newData = this.$utils.parseJson(this.paramsForQuery);
        this.brandList.IsExportKey = 1;
        this.brandList.pageIndex = 0;
        this.brandList.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '加盟签单回收站记录',
          clist: [
            { title: '作废日期', cName: 'CancellationTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '签单日期', cName: 'ApplicantTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '来源方式', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '投资人', cName: 'AttractInvestKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '顾问', cName: 'CounselorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '合同金额', cName: 'ContractAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '加盟方式', cName: 'FranchiseTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '合同变更条款', cName: 'AmendingClause', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '定金', cName: 'EarnestAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '作废原因', cName: 'CancellationReson', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '操作人', cName: 'CancellationKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        HQSearchPageContractRecycleBin(this.brandList, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.brandList.IsExportKey = 0;
          this.brandList.pageIndex = newData.pageIndex;
          this.brandList.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    },
    // 获取 潜客品牌回收站列表
    loadDataList () {
      HQSearchPageContractRecycleBin(this.paramsForQuery).then(result => {
        console.log('获取 潜客品牌回收站列表', result.data.PageDataList);
        // 推荐记录 当前状态：CurrentStageKeyValue 1-投资审核;2-品牌审核;3-管理审核;4-品牌接收;5-到达品牌;6-签单;7-排斥;8-重推;0-推荐发起
        result.data.PageDataList.forEach(obj => {
          // obj.budgetAmount = this.$utils.formatNumber(obj.StratBudgetAmount) + '-' + this.$utils.formatNumber(obj.EndBudgetAmount) +'万';
        });
        this.brandList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 点击搜索
    searchClick (queryParams) {
      console.log('searchClick', this.paramsForQuery);
      // this.paramsForQuery = queryParams;
      this.loadDataList();
    },
    // 清除搜索条件
    clearSearchParams () {
      this.paramsForQuery = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        sourceKey: 0,
        counselorKey: 0,
        joinTheWay: 0,
        paymentScheduleKey: 0,
        StartTime: '',
        EndTime: ''
      };
      this.loadDataList();
    },
    // 查看品牌详情
    lookUpDetail (rowData) {
      console.log('查看品牌详情', rowData);
      this.curSelectedBrand = rowData;
      this.isShowDetail = true;
    },
    // 批量分配 选中一行
    clickCheckBox (lists, rowData) {
      if (!this.isBatchDistribute && lists.length > 0) {
        this.isBatchDistribute = true;
      } else if (lists.length == 0) {
        this.isBatchDistribute = false;
      }
      console.log('clickCheckBox', lists, rowData);
      let arr = lists.filter((item) => { return item.OLAPKey > 0 ;});
      this.batchAllotList = arr;
    },
    // 取消批量分配
    cancleBatchDistribute () {
      this.isBatchDistribute = false;
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.batchAllotList = [];
    },
    // 分配品牌顾问
    distributeAdvisor (rowData, type) {
      let item = {
         routerName: this.$route.name,
         moduleName: 'distributeBrandAdvisor',
         name: '分配品牌顾问',
         data: { investorIDs: rowData.OLAPKey },
         callBack: {
            afterSuccess: (data) => {
              this.loadDataList();
              if (type == 'batch') {
                this.cancleBatchDistribute();
              }
              // if(type=='batch'){
              //   this.doAfterBatchDistribute(data);
              // }else{
              //   let idx = this.brandList.findIndex(obj=>{return obj.BrandCounselorKey == data.OLAPKey})
              //   this.brandList.splice()
              //   rowData.BrandCounselorKey = data.OLAPKey,rowData.BrandCounselorKeyValue = data.name;
              // }
            }
          }

       };
      this.$dialog.open(this, item);
    },
    // 批量分配后
    doAfterBatchDistribute (data) {
      this.brandList = this.brandList.filter(function (item) {
        return this.batchAllotList.find(obj => { return obj.BrandCounselorKey != item.BrandCounselorKey ;});
      });
      console.log('doAfterBatchDistribute', this.brandList);
      this.cancleBatchDistribute();
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.crmContactRecycleBinDialogFactory) {
        this.$refs.crmContactRecycleBinDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.crmContactRecycleBinDialogFactoryCommon) {
        this.$refs.crmContactRecycleBinDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'crmContactRecycleBin') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'crmContactRecycleBin') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>

<style scoped>
.box_withe_radius {
  background-color: #fff;
  border-radius: 8px;
}
.recommend_manage_view {
  padding: 20px 20px 10px;
}

.all_width {
  width: 1290px;
  margin: 20px auto 40px;
}

.recycle_title{
  line-height: 20x;
  width: 135px;
  margin-left: 20px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}
</style>