<template>
  <div class="content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30 overflow_hidden">
    <div class="recording_data_table"
         v-if="dataList.length>0"
         style="margin-top: 20px;">
      <div class="new_table_list_thead clearfix">
        <div class="new_table_list_th style_gray tl"
             style="width: 125px;font-weight: bold;">
          <div>学生名</div>
        </div>
        <div class="new_table_list_th style_gray tl"
             style="width: 120px;font-weight: bold;">
          <div>考勤状态</div>
        </div>
        <div class="new_table_list_th style_gray tc"
             style="width: 100px;font-weight: bold;">
          <div>扣减课时</div>
        </div>
        <div class="new_table_list_th style_gray tl"
             style="width: 98px;font-weight: bold;">
          <div>操作</div>
        </div>
      </div>
      <div class="recording_data_tbody">
        <div class="new_table_list_tr next_icon"
             v-for="(item,key) in dataList"
             :key="key">
          <div class="new_table_list_td tl"
               style="width: 125px;">
            <div>{{item.StudentKeyValue}}</div>

          </div>
          <div class="new_table_list_td tl"
               style="width: 120px;">
            <div>{{item.AttenceStatusKeyValue}} {{item.IsMakeupKey==1?'补':''}}</div>
          </div>
          <div class="new_table_list_td tc"
               style="width: 100px;">
            <div>{{(item.AttenceStatusKey==2||!item.AttenceStatusKey)?'-':(Number(item.ClassCount)||'-')}}
              <span class="table_font_red"
                    v-if="item.ISClassHourDebtKey==1">欠</span>
            </div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 98px;">
            <div class="backlog_list_btn_02 font_blue"
                 v-if="(item.AttenceStatusKey==2||!item.AttenceStatusKey)"
                 @click="showAtten(item,1)">考勤</div>
            <div class="backlog_list_btn_02 font_blue"
                 v-else
                 @click="showAtten(item,2)">校正</div>
          </div>
          <!-- <div class="new_table_list_arrow"></div> -->
        </div>
        <div class="new_table_list_bottom"
             v-if="isLoadEnd">
          <span class="loading_btn">加载中...</span>
        </div>
        <div class="new_table_list_bottom"
             v-if="noMore">
          <span class="font_gray">已显示全部</span>
        </div>
      </div>
    </div>
    <div class="course_block_nodata"
         style="padding-bottom:20px"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <custom-dialog :title="attenTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowChangeAttendance"
                   :before-close="closeAttendanceReason">
      <change-attendance :curSelectedStudentInfo="curSelectedStudentInfo"
                         :courseInfo="courseInfo"
                         @changeAttendance="changeAttendanceAfter"
                         @cancel="closeAttendanceReason"></change-attendance>
    </custom-dialog>
  </div>
</template>
<script>
import changeAttendance from '../change-attendance';
import { getCourseDetailed, getScheduleCourseOnStudentList, KQ_AdjustHour } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataList: [],
      isLoadEnd: false, // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      noMore: false,
      curSelectedStudentInfo: null,
      isShowChangeAttendance: false,
      courseInfo: null,
      attenTitle: ''

    };
  },
  components: {
    changeAttendance
  },
  props: {
    ScheduleCourseKey: [Number, String]  // 课id
  },
  created () {
    this.getCourseInfo();
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this, ['signuppayment', 'signuppaymentSuccess']);
  },
  computed: {},
  beforeDestroy () {
    console.log('beforeDestroy');
    // this.$emit("afterSuccess")
    this.$bus.emit(
      'AfterChangeAttendanceStatus',
      this.ScheduleCourseKey
    );
  },
  methods: {
    getCourseInfo () {
      getCourseDetailed(this.ScheduleCourseKey).then(
        res => {
          this.courseInfo = res.data;
        },
        err => {
          console.log(err);
        }
      );
    },
    showAtten (item, type) {
      this.attenTitle = type == 1 ? '考勤' : '校正考勤';
      this.curSelectedStudentInfo = item;
      this.isShowChangeAttendance = true;
    },
    closeAttendanceReason () {
      this.isShowChangeAttendance = false;
    },
    // 校正考勤
    changeAttendanceAfter (dataItem, ISClassHourLackKey, type, callback) {
      this.curSelectedStudentInfo.ISClassHourLackKey = 1;// ISClassHourLackKey || 0;
      KQ_AdjustHour(
        this.curSelectedStudentInfo.StudentArrangeKey,
        this.curSelectedStudentInfo.ISClassHourLackKey,
        this.curSelectedStudentInfo.IsTrialKey,
        dataItem

      )
        .then((result) => {
          if (result.code === 0) {
            console.log('kqChange', this.$utils.parseJson(result.data));
            Object.assign(this.curSelectedStudentInfo, result.data);
          }
          this.curSelectedStudentInfo.isSelected = false;
          this.getDataList();
          if (type == 'cancel') {
            layer.alert('撤销考勤成功');
          } else {
            layer.alert(this.attenTitle + '成功');
          }
          this.closeAttendanceReason();
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue +
              '课时不足，是否以欠课时的方式变更考勤？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      if (callback) {
                        callback();
                      }
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.changeAttendanceAfter(
                        dataItem,
                        1
                      );
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.$bus.emit(
              'AfterChangeAttendanceStatus',
              this.curSelectedStudentInfo.ScheduleCourseKey,
              this.curSelectedStudentInfo.StudentKey
            );
          } else {
            layer.alert(err.msg);
          }
        });
    },
    getDataList () {
      this.isLoadEnd = true;
      this.dataList = [];
      getScheduleCourseOnStudentList(this.ScheduleCourseKey).then(result => {
        console.log(result.data, 'getPotentialCustomerData');
        result.data.forEach(o => {
          this.dataList.push(o);
        });
        this.noMore = true;
        this.isLoadEnd = false;
      });
    }
  }
};
</script>
<style scoped>
.backlog_list_btn_02 {
  width: 46px;
  height: 24px;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  border: 1px solid #ececec;
  border-radius: 8px;
  /* margin-top: 8px; */
  cursor: pointer;
}
.table_font_red {
  color: #fff;
  position: relative;
  top: -1px;
  background: #ff6464;
  font-size: 12px;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 1px;
  line-height: 15px;
  text-align: center;
  margin-left: 6px;
}
</style>