<template>
  <div class="icon_authorization_box"
       :class="[minWidth?'magrinR':'',isShowBeforLine&&courseAuth.type!='PC'?'befor_line':'']">
    <div class="rowll_cell"
         @click.stop="doDeviceCount(courseAuth.type)">
      <div class="img_box rowll_cell">
        <img :src="vImgRequireIcon"
             class="icon_img" />
        <span v-if="courseAuth.type=='PC'"
              class="courseType">电脑</span>
        <span v-if="courseAuth.type=='APP'"
              class="courseType">APP</span>
        <span v-if="courseAuth.type=='TV'"
              class="courseType">电视</span>
      </div>
      <div v-if="courseAuth.IsAuthKey==1"
           class="rowll_cell">
        <!-- <div v-if="courseAuth.AuthorizeDeviceNum==0">
            <span v-if="modelType == 'A'"
                  class="text_style">不限</span>
            <span v-else
                  class="text_style">允许</span>
          </div> -->
        <el-tooltip effect="dark"
                    :content="vAuthCountTitle"
                    popper-class="mendian_block_tooltip"
                    placement="top-start">
          <div class="AuthCount_num">
            <span class="text_style"
                  v-if="courseAuth.AuthorizeDeviceNum != '0'">{{courseAuth.AuthCount+'/'+courseAuth.AuthorizeDeviceNum}}</span>
            <span class="text_style"
                  v-else>{{courseAuth.AuthCount}}</span>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark"
                    :content="vLocationTitle"
                    popper-class="mendian_block_tooltip"
                    placement="top-start">
          <div class="AuthCount_img"
               :class="courseAuth.LocationLockKey == '1'?'SelectedAuth':''"
               @mousemove="authMoveF"
               @mouseout="authOutF">
            <img :src="require('../../../../../public/image/positioning-'+authNum+'.png')"
                 class="location_iconA" />
          </div>
        </el-tooltip>
        <el-tooltip effect="dark"
                    :content="vRecordTitle"
                    popper-class="mendian_block_tooltip"
                    placement="top-start">
          <div class="AuthCount_img"
               style="border-radius: 0px 2px 2px 0px;"
               :class="Number(courseAuth.IsRecordKey) == 1?'SelectedAuth':''"
               @mousemove="RecordKeyMoveF"
               @mouseout="RecordKeyOutF">
            <img :src="require('../../../../../public/image/protective-'+RecordKeyNum+'.png')"
                 class="location_iconA" />
          </div>
        </el-tooltip>

      </div>
      <div v-else>
        <!-- 禁用 -->
        <el-tooltip effect="dark"
                    :content="vProhibitTitle"
                    popper-class="mendian_block_tooltip"
                    placement="top-start">
          <img :src="require('../../../../../public/image/prohibit_icon.png')"
               class="location_iconA"
               style="width: 16px;" />
        </el-tooltip>
      </div>
    </div>
    <!-- <span v-if="isShowAfterLine&&courseAuth.type!='TV'"
          class="line_after"></span> -->
  </div>
</template>
<script>

export default {
  data () {
    return {
      typaName: '',
      authNum: 1,
      RecordKeyNum: 1,
      AuthCountTitle: ''
    };
  },
  props: {
    courseAuth: {
      type: Object,
      default: () => {
        return {
          IsAuthKey: 1, // 是否允许访问
          IsRecordKey: 1, // 是否防护屏
          AuthorizeDeviceNum: 0, // 允许访问设备数
          AuthCount: 0, // 以授权访问数
          LocationLockKey: 1, // 是否定位锁定
          type: 'PC', // 类型
          LocationLockKeyValue: '定位开启'
        };
      }
    },
    modelType: {
      type: String,
      default: 'A'// A为校长模式B为老师模式
    },
    minWidth: {
      type: Boolean,
      default: false
    },
    isShowBeforLine: {
      type: Boolean,
      default: false
    },
    isShowStyle: {
      type: Boolean,
      default: false
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    vImgRequireIcon () {
      if (this.courseAuth.LocationLockKey == '1') {
        this.authNum = 1;
      } else if (this.courseAuth.LocationLockKey == '0') {
        this.authNum = 2;
      }
      if (Number(this.courseAuth.IsRecordKey) == 1) {
        this.RecordKeyNum = 1;
      } else if (Number(this.courseAuth.IsRecordKey) == 0) {
        this.RecordKeyNum = 2;
      }
      console.log(this.courseAuth, 'courseAuth12');
      let url = '';
      if (this.courseAuth.type == 'TV') {
        this.typaName = '电视';
        url = require('../../../../../public/image/TV_icon.png');
      } else if (this.courseAuth.type == 'PC') {
        this.typaName = '电脑';
        url = require('../../../../../public/image/PC_icon.png');
      } else if (this.courseAuth.type == 'APP') {
        this.typaName = '手机';
        url = require('../../../../../public/image/APP_icon.png');
      }
      return url;
    },
    vIconLocation () {
      let url = '';
      if (this.courseAuth.LocationLockKey == '1') {
        url = require('../../../../../public/image/positioning-1.png');
      } else if (this.courseAuth.LocationLockKey == '0') {
        url = require('../../../../../public/image/positioning-2.png');
      }
      return url;
    },
    vRecordIconLocation () {
      let url = '';
      if (Number(this.courseAuth.IsRecordKey) == 1) {
        url = require('../../../../../public/image/protective-1.png');
      } else {
        url = require('../../../../../public/image/protective-2.png');
      }
      return url;
    },
    vAuthCountTitle () {
      let textTip = '';
      if (this.courseAuth.AuthorizeDeviceNum != '0') {
        textTip = '最大允许' + this.courseAuth.AuthorizeDeviceNum + '台，已授权' + this.courseAuth.AuthCount + '台';
      } else {
        textTip = '设备数不限，已授权' + this.courseAuth.AuthCount + '台';
      }
      return textTip;
    },
    vLocationTitle () {
      let textTip = '';
      if (this.courseAuth.LocationLockKey == '1') {
        textTip = '定位开启';
      } else {
        textTip = '定位不限';
      }
      return textTip;
    },
    vRecordTitle () {
      let textTip = '';
      if (Number(this.courseAuth.IsRecordKey) == 1) {
        textTip = '防录屏开启';
      } else {
        textTip = '防录屏关闭';
      }
      return textTip;
    },
    vProhibitTitle () {
      return '禁用';
    },
    vTitleTip () {
      let textTip = '';
      if (this.courseAuth.IsAuthKey != '1') {
        textTip = '禁用';
        return textTip;
      } else {
        if (this.modelType != 'A') {
          if (this.courseAuth.LocationLockKey == '1') {
            textTip = this.typaName + '允许' + '，定位开启';
            return textTip;
          } else {
            textTip = this.typaName + '允许' + '，定位不限';
            return textTip;
          }
        } else {
          if (this.courseAuth.LocationLockKey == '1') {
            textTip = this.typaName + (this.courseAuth.AuthorizeDeviceNum == '0' ? '不限' : this.courseAuth.AuthCount + '/' + this.courseAuth.AuthorizeDeviceNum) + '，定位开启';
            return textTip;
          } else {
            textTip = this.typaName + (this.courseAuth.AuthorizeDeviceNum == '0' ? '不限' : this.courseAuth.AuthCount + '/' + this.courseAuth.AuthorizeDeviceNum) + '，定位不限';
            return textTip;
          }
        }
      }

      // return url;
    }
  },
  methods: {
    doDeviceCount (type) {
      console.log(type, 'type');
      this.$emit('doDeviceCount', type);
    },
    authMoveF () {
      if (this.courseAuth.LocationLockKey == '1') {
        this.authNum = 3;
      }
    },
    authOutF () {
      if (this.courseAuth.LocationLockKey == '1') {
        this.authNum = 1;
      }
    },
    RecordKeyMoveF () {
      if (Number(this.courseAuth.IsRecordKey) == 1) {
        this.RecordKeyNum = 3;
      }
    },
    RecordKeyOutF () {
      if (Number(this.courseAuth.IsRecordKey) == 1) {
        this.RecordKeyNum = 1;
      }
    }
  }
};
</script>
<style scoped>
.icon_authorization_box {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 5px;
  position: relative;
}
.magrinR {
  margin-right: 1px !important;
}
.iconMinr {
  margin-top: 12px !important;
}
.img_box {
  width: 47px;
  height: 20px;
  align-items: center;
  text-align: center;

  margin-right: 2px;
}
.icon_img {
  width: 18px;
  height: 18px;
}
.location_iconA {
  margin-left: 5px;
  margin-right: 10px;
}
.text_style {
  color: rgba(255, 96, 0, 1);
  font-size: 12px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.line_after {
  position: absolute;
  width: 1.01px;
  height: 14px;
  background: linear-gradient(0deg, #ececec, #ececec), #ffffff;
  position: absolute;
  right: 0;
  top: 10px;
}
.rowll_cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AuthCount_num {
  width: 33px;
  text-align: center;
  border: 1px solid rgba(219, 219, 219, 1);
  height: 18px;
  line-height: 15px;
  border-radius: 2px 0 0 2px;
}
.AuthCount_num:hover {
  border: 1px solid rgba(255, 96, 0, 1);
}
.AuthCount_img {
  border: 1px solid rgba(219, 219, 219, 1);
  text-align: center;
  width: 22px;
  height: 18px;
  line-height: 15px;
}
.SelectedAuth:hover {
  border: 1px solid rgba(255, 96, 0, 1);
}
</style>