<template>
  <div class="">
    <!-- 模板 -->
    <div>
      <div class="from_title">
        <span>活动模板</span>
        <span class="font_gray">选择适合的模板提升展示效果</span>
      </div>
      <div class="from_list">
        <div class="from_list_title">
          更换模板
        </div>
        <div class="from_list_content">
          <div class="font_black from_list_input"
               v-if="this.PosterInfo&&this.PosterInfo.title">
            {{this.PosterInfo.title}}
          </div>
          <div class="font_black from_list_input"
               v-else>
            无
          </div>
        </div>
        <div class="btn_hover_white"
             @click.stop="showModuleDialog">
          选择模板
        </div>
      </div>
    </div>
    <!-- 海报 -->
    <div>
      <div class="from_title">
        <span>主题图</span>
        <span class="font_gray">请上传小于5M的JPG/PNG图片，宽高比为5:4，建议尺寸750px* 600px。</span>
      </div>
      <div class="from_list">
        <div class="from_list_title">
        </div>
        <div class="from_list_content">
          <crop-upload-img :size="''"
                           style=""
                           :imgWidth="640"
                           :imgHeight="495"
                           :imgClass="'from_list_img pr'"
                           :remarks="''"
                           :isShowImg="true"
                           :ImgURl="PosterInfo.pic"
                           @seletedItem="seletedSetScrollTop(207)"
                           @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
          <div class="btn_hover_white seletecd_pic"
               style="position: absolute;left: 359px;top: 0;"
               @click.stop="showPicDialog">
            选择图库
          </div>
        </div>
      </div>
    </div>
    <!-- 音乐 -->
    <div>
      <div class="from_title">
        <span>音乐</span>
        <span class="font_gray">添加音乐增加活动氛围</span>
      </div>
      <div class="from_list">
        <div class="from_list_title">
          更换音乐
        </div>
        <div class="from_list_content">
          <div class="font_black from_list_input"
               v-if="this.PosterInfo.settingJson.musicTarget&&this.PosterInfo.settingJson.musicTarget.title">
            {{this.PosterInfo.settingJson.musicTarget.title}}
          </div>
          <div class="font_black from_list_input"
               v-else>
            {{musicTarget.title}}
          </div>
        </div>
        <div class="btn_hover_white"
             @click.stop="showMusicDialog">
          选择音乐
        </div>
      </div>
    </div>

    <div v-for="(item,key) in setSortList"
         :key="key">

      <form-textarea v-if="contentSetting.brief&&item.specialName=='活动介绍'"
                     :formTitle="'活动介绍'"
                     v-model="contentSetting.brief.content"
                     :tipsTitle="'此处填写活动内容'"
                     :required="false"
                     :unintTitle="''"
                     :titleClass="''"
                     @seletedItem="seletedSetScrollTop('活动介绍')"
                     @change="changeBrief"></form-textarea>
      <form-textarea v-if="contentSetting.introduce&&item.type==200"
                     :formTitle="'机构简介'"
                     v-model="contentSetting.introduce.content"
                     :tipsTitle="'默认显示微官网首页机构简介内容，可直接修改内容'"
                     :required="false"
                     :unintTitle="''"
                     :titleClass="''"
                     @seletedItem="seletedSetScrollTop(200)"
                     @change="changeIntroduce"></form-textarea>
      <mien v-if="contentSetting.imgList&&item.type==206"
            :imgList="contentSetting.imgList.form.imgList"
            @changeImg="changeMien"
            @seletedMien="seletedSetScrollTop(206)"></mien>
    </div>
    <!-- <div>
      <div class="from_title">
        <span>联系方式</span>
        <span class="font_gray"></span>
      </div>

      <form-input :formTitle="'活动举办方'"
                  v-model="CouponInfo.t_company"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_company"></form-input>
      <form-input :formTitle="'活动联系地址'"
                  v-model="CouponInfo.t_address"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_address"></form-input>
      <form-input :formTitle="'活动联系手机'"
                  v-model="CouponInfo.t_telephone"
                  :isPhone="true"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_telephone"></form-input>
    </div> -->

    <step-btn :showPre="true"
              :showNext="false"
              :PreText="'返回方案设置'"
              :showViewSettingContent="'自定义界面'"
              @viewSettingShow="viewSettingShow"
              @preStep="preStep"
              @submitData="confirm">
    </step-btn>
    <!-- 新版海报券选择音乐列表 -->
    <custom-dialog :title="'选择音乐'"
                   width="740px"
                   :moduleDescription="''"
                   :append-to-body="true"
                   :visible.sync="isShowMusic"
                   :before-close="closeIsShowMusicDialog">
      <ishow-editor-music-list :dataInfo="PosterInfo.settingJson"
                               @afterSuccess="setMusicSetting"
                               @cancelClick="closeIsShowMusicDialog"></ishow-editor-music-list>
    </custom-dialog>
    <!-- 新版海报券选择音乐列表 -->
    <custom-dialog :title="'选择模板'"
                   width="855px"
                   :moduleDescription="''"
                   :append-to-body="true"
                   :visible.sync="isShowModule"
                   :before-close="closeIsShowMusicDialog">
      <choose-template-list @afterSuccess="seletedModule"></choose-template-list>
    </custom-dialog>
    <custom-dialog :title="'选择图库封面'"
                   class=""
                   width="855px"
                   :visible.sync="isShowPic"
                   :before-close="closeIsShowMusicDialog">
      <choose-coverimg-list @afterSuccess="seletedCoverImg"></choose-coverimg-list>
    </custom-dialog>
    <custom-dialog class="editContent_iframe_dialog"
                   :title="'修改'"
                   :visible.sync="isShowEditContent"
                   :before-close="closeEditContent">
      <iframe id="ifra2"
              :src="editContentUrl"
              class="ishowEdit"
              frameborder="0"></iframe>
    </custom-dialog>
  </div>
</template>
<script>
// import cropUploadImg from '../../../../../wkt-back/common/crop-upload-img';
import cropUploadImg from './common/crop-upload-img';
import formTextarea from './common/form-textarea';
import formInput from './common/form-input';
import stepBtn from './step-btn';
import mien from './content-setting-components/mien';
import ishowEditorMusicList from './ishow-editor-music-list/index';
import chooseTemplateList from './choose-template-list/index';
import chooseCoverimgList from './choose-coverimg-list/index';
import bus from '../../../../../../js/bus.js';
import { AddShowBill, EditShowBill, GetActivitiesPlayInfo, PublishActivity } from '../../../../../../API/workbench';
import { convertToMd5 } from '../../../../../../utils/upLoadTextImg';
export default {
  data () {
    return {
      isShowMusic: false,
      isShowModule: false,
      isShowPic: false,
      mendianInfo: this.$store.getters.SaaSClientInfo,
      seletedPlayMethodInfo: null,
      imgList: [], // 临时装载而已.
      sumitData: {

      },
      pic: '',
      templateId: '',
      musicTarget: {
        title: ''
      },
      isShowEditContent: false,
      editContentUrl: ''
    };
  },
  components: {
    stepBtn, formTextarea, cropUploadImg, formInput, ishowEditorMusicList, mien, chooseTemplateList, chooseCoverimgList
  },
  props: {
    dataInfo: Object,
    CouponInfo: Object,
    menuItem: Object,
    coverInfo: Object,
    PosterInfo: Object,
    contentSetting: Object

  },
  computed: {
    vImg () {
      if (!this.PosterInfo.pic) {
        this.PosterInfo.pic = '';
      }
      if (this.PosterInfo.pic.indexOf('http') > -1) {
        return this.PosterInfo.pic;
      } else {
        return this.$store.getters.CDNURL + this.PosterInfo.pic;
      }
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../../../../public/image/no_logo@2x.png') + '"';
    },
    setSortList () {
      var newArr = [];
      if (this.PosterInfo && this.PosterInfo.pageJson) {
        // let cover = this.findItem(207)    //封面
        // let contact = this.findItem(208)      //联系方式
        let carousel = this.findItem(206);   // 轮播
        let brief = this.findItem('活动介绍');   // 活动介绍
        let introduce = this.findItem(200);    // 机构介绍

        console.log(carousel, brief, introduce);
        carousel ? newArr.push(carousel) : newArr;
        brief ? newArr.push(brief) : newArr;
        introduce ? newArr.push(introduce) : newArr;
        // cover ? newArr.push(cover) : newArr
        // contact ? newArr.push(contact) : newArr
        newArr.sort((x, y) => {
          return Number(x.position.top) - Number(y.position.top);
        });
      }
      console.log(newArr, 'setSortList');
      return newArr;
    }
  },
  created () {
    console.log('content-setting this.coverInfo', this.coverInfo);
    console.log('content-setting this.PosterInfo', this.PosterInfo, this.PosterInfo.settingJson);
    // this.PosterInfo.pic = this.coverInfo.coverimg || '';

    // if (this.coverInfo && this.coverInfo.coverimg){
    //   this.PosterInfo.pic = this.coverInfo.coverimg||this.PosterInfo.pic
    // }
    this.getPlayInfo()
  },
  methods: {
    // 修改模板
    showEditContent (callback) {
      console.log("showEditContent", this.CouponInfo)
      this.submitData(callback, (result) => {
        let billId = 0
        if (this.CouponInfo.billId > 0) {
          billId = this.CouponInfo.billId
        } else {
          billId = result.data
          this.dataInfo.billId = result.data

        }
        this.editContentUrl = this.$store.getters.PCWebURL + '/iShowEditor/#/ishow?activityId=' + billId + '&isHiddenModule=1'
        console.log("editContentUrl", this.editContentUrl)
        this.$nextTick(o => {
          this.isShowEditContent = true
        })
      })

    },
    closeEditContent () {
      this.isShowViewContent = false
      this.isShowEditContent = false
      this.$emit("updateData")
    },
    getPlayInfo () {
      GetActivitiesPlayInfo(this.$route.query.TypeKey || 1).then(result => {
        console.log('getPlayInfo', result.data)
        this.seletedPlayMethodInfo = result.data
      })
    },
    publishBtn (callback) {
      PublishActivity(this.CouponInfo.id).then(result => {
        console.log("PublishActivity", result)
        console.log("发布活动成功")
        layer.alert("发布成功")
        this.$bus.emit("updateTemplatelist")
        this.$emit("cancelClick")
        if (callback) {
          callback()
        }
      }).catch(error => {
        if (callback) {
          callback()
        }
      })
    },
    // 打开编辑器
    openIshowEditor (item) {
      // table 按钮点击

      // this.seletedPlayMethodInfo = item;
      // this.seletedPlayMethodInfo.id=this.coverInfo.id
      console.log("this.dataInfo.id", this.dataInfo)
      console.log("this.seletedPlayMethodInfo.id", this.seletedPlayMethodInfo.id, this.CouponInfo)
      let moduleInfo = {
        name: '活动制作-方案设置',
        moduleName: 'ishowEditorDialog',
        routerName: this.$route.name,
        data: { dataInfo: this.seletedPlayMethodInfo, coverInfo: this.coverInfo, activityInfo: this.dataInfo },
        callBack: {
          afterSuccess: () => {
            // this.getDataList()
          }
        }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
      // bus.emit('openCustomDialog', moduleInfo);
      // this.$nextTick(o => {

      // });

    },
    viewSettingShow () {
      this.showEditContent()
    },
    findItem (type) {
      let seletedItem = null;
      let pageJson = null;
      console.log(this.PosterInfo, 'this.findItem');
      if (this.PosterInfo.pageJson && !Array.isArray(this.PosterInfo.pageJson)) {
        pageJson = JSON.parse(this.PosterInfo.pageJson);
      } else {
        pageJson = this.PosterInfo.pageJson;
      }
      console.log(pageJson, 'pageJson');
      if (pageJson) {
        if (Number(type)) {
          seletedItem = pageJson[0].elJson.find(o => {
            return o.type == type;
          });
        } else {
          seletedItem = pageJson[0].elJson.find(o => {
            return o.specialName == type;
          });
        }
      }
      return seletedItem;
    },
    changeBrief (val) {
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          let x = val.replace(/\n/g, '<br>');
          if (obj.specialName == '活动介绍') {
            // obj.form.t_company = this.CouponInfo.t_company;
            this.$set(obj, 'content', x);
          }
        });
      }
    },
    changeIntroduce (val) {
      console.log('val', val);
      if (this.PosterInfo) {
        let x = val.replace(/\n/g, '<br>');
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 200) {
            this.$set(obj, 'content', x);
          }
        });
      }
    },
    changet_company () {
      console.log('this.changet_company', this.PosterInfo);
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 208) {
            console.log(obj, '208');
            // obj.form.t_company = this.CouponInfo.t_company;
            this.$set(obj.form, 't_company', this.CouponInfo.t_company);
          }
        });
      }
    },
    changet_address () {
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 208) {
            // obj.form.t_address = this.CouponInfo.t_address;
            this.$set(obj.form, 't_address', this.CouponInfo.t_address);
          }
        });
      }
    },
    changet_telephone (val) {
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 208) {
            // obj.form.t_telephone = this.CouponInfo.t_telephone;
            this.$set(obj.form, 't_telephone', this.CouponInfo.t_telephone);
          }
        });
      }
    },
    changeMien () {
      console.log('changeMien');
      if (this.PosterInfo) {
        if (this.contentSetting.imgList.form.imgList) {
          this.contentSetting.imgList.form.imgList.forEach(o => {
            o.title = o.title.toString();
          });
        }
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 206) {
            // obj.form.t_telephone = this.CouponInfo.t_telephone;
            this.$set(obj.form, 'imgList', this.contentSetting.imgList.form.imgList);
          }
        });
      }
    },
    // 选中,设置滚动条高度定位.
    seletedSetScrollTop (type) {
      this.$emit('setscorllTop', type);
    },
    preStep () {
      this.$emit('preStep', this.menuItem);
      this.submitData(() => { }, () => {
        this.$emit('success');
        this.$bus.emit("updateTemplatelist")
        this.openIshowEditor()
      })

    },
    nextStep () {
      this.$emit('nextStep', this.menuItem);
    },
    seletedModule (item) {
      console.log("seletedModule", item)
      this.PosterInfo.templateId = item.id
      this.templateId = item.id
      this.$emit("seletedModule", item, () => {
        console.log("callback musicTarget", this.musicTarget)
        console.log("callback pic", this.pic)
        this.$nextTick(o => {
          this.PosterInfo.templateId = this.templateId
          if (this.musicTarget.id > 0) {
            this.setMusicSetting(this.musicTarget)
          }
          if (this.PosterInfo.pic) {
            this.doAfterUploadImgSuccess(this.PosterInfo.pic)
          }
        })
      })
      this.closeIsShowMusicDialog()
    },
    seletedCoverImg (item) {
      console.log("seletedCoverImg", item)

      this.doAfterUploadImgSuccess(item.coverimg)
      this.closeIsShowMusicDialog()
    },
    showPicDialog () {
      this.isShowPic = true
    },
    showModuleDialog () {
      this.isShowModule = true
    },
    closeIsShowMusicDialog () {
      this.isShowMusic = false;
      this.isShowModule = false
      this.isShowPic = false
    },
    showMusicDialog () {
      this.isShowMusic = true;
    },
    setMusicSetting (item) {
      console.log(item, 'item');
      if (item) {
        this.PosterInfo.settingJson.isMusic = true;
        this.PosterInfo.settingJson.musicTarget = item;
        this.musicTarget = item
      } else {
        this.PosterInfo.settingJson.isMusic = false;
        this.PosterInfo.settingJson.musicTarget = {};
        this.musicTarget = {
          title: '无'
        }
      }
    },
    doAfterUploadImgSuccess (imgUrl) {
      console.log('doAfterUploadImgSuccess', imgUrl, this.PosterInfo);
      if (this.PosterInfo.pageJson) {
        this.PosterInfo.pageJson[0].elJson.forEach(o => {
          if (o.type == 207) {
            console.log("207777", o)
            o.isActive = true;
            o.content = imgUrl;
          }
        })
        // let locaItem = this.PosterInfo.pageJson[0].elJson.find(o => {
        //   return o.type == 207;
        // });
        //  console.log('locaItem', locaItem);
        // if (locaItem) {
        //   locaItem.isActive = true;
        //   locaItem.content = imgUrl;
        // }
      }
      // content
      this.PosterInfo.pic = imgUrl;
      this.pic = imgUrl
    },
    doAfterUploadImgSuccessForLogo (imgUrl) {
      this.coverInfo.coverimg = imgUrl;
    },
    verifyData () {
      let isComfire = true;
      console.log("this.PosterInfo.templateId", this.PosterInfo.templateId)
      if (!this.PosterInfo.templateId) {
        isComfire = false;
        layer.alert('请选择活动模板');
        return isComfire;
      } else if (!this.PosterInfo.pic) {
        isComfire = false;
        layer.alert('请选择主题图');
        return isComfire;
      }
      // if (!this.CouponInfo.t_company) {
      //   isComfire = false;
      //   layer.alert('请输入活动举办方');
      //   return isComfire;
      // }
      // if (!this.CouponInfo.t_address) {
      //   isComfire = false;
      //   layer.alert('请输入活动联系地址');
      //   return isComfire;
      // }
      // if (!this.CouponInfo.t_telephone) {
      //   isComfire = false;
      //   layer.alert('请输入活动联系手机');
      //   return isComfire;
      // }
      return isComfire;
    },
    confirm (callback) {
      // this.submitData(callback);
      this.submitData(callback, () => {

        this.publishBtn(callback)
        this.$emit('success');
      })

    },
    submitData (callback, callbakcktwo) {
      console.log('submitDataPosterInfo', this.PosterInfo);
      console.log('submitDataCouponInfo', this.CouponInfo);
      console.log('submitDatadataInfo', this.dataInfo);
      this.pic = this.pic || ''
      this.PosterInfo.pic = this.PosterInfo.pic || ''
      if (!this.verifyData()) {
        if (callback) {
          callback();
        }
        return false;
      }
      console.log("this.PosterInfo", this.PosterInfo)
      let obj = {
        PosterInfo: this.$utils.parseJson(this.PosterInfo)
      };


      obj.PosterInfo.pageJson = JSON.stringify(this.PosterInfo.pageJson);
      obj.PosterInfo.settingJson = JSON.stringify(this.PosterInfo.settingJson);
      obj.PosterInfo.ActivityClassifyID = this.$route.query.TypeKey || 1
      console.log("obj.PosterInfo", obj.PosterInfo)
      if (this.dataInfo.billId > 0) {
        this.editActivity(obj, callback, callbakcktwo);
      } else {
        obj.PosterInfo.ID = 0
        this.addActivity(obj, callback, callbakcktwo);
      }
    },
    // 新增
    addActivity (obj, callback, callbakcktwo) {
      console.log("this.CouponInfo.id", this.CouponInfo.id)
      AddShowBill(this.CouponInfo.id, obj.PosterInfo).then(result => {
        if (callback) {
          callback(result);
        }
        if (callbakcktwo) {
          callbakcktwo(result)
        }
        layer.alert('新增成功');

        console.log(result.data, 'submitData');
      }).catch(error => {
        if (callback) {
          callback();
        }
        layer.alert(error.msg);
        console.log(error, 'errorsubmitData');
      });
    },
    // 修改
    editActivity (obj, callback, callbakcktwo) {
      EditShowBill(this.dataInfo.billId, obj.PosterInfo).then(result => {
        if (callback) {
          callback(result);
        }
        if (callbakcktwo) {
          callbakcktwo(result)
        }
        layer.alert('修改成功');

        console.log(result.data, 'submitData');
      }).catch(error => {
        if (callback) {
          callback();
        }
        layer.alert(error.msg);
        console.log(error, 'errorsubmitData');
      });
    }

  }
};
</script>
<style>
.editContent_iframe_dialog {
  position: fixed;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: 0 !important;
  z-index: 9999;
}
.editContent_iframe_dialog .popup_dialog_box {
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: 0 !important;
  width: auto !important;
  transform: translateX(0) !important;
}
.ishowView {
  width: 640px;
  transform-origin: 8% bottom;
  height: 4895px;
  margin-top: -2227.22px;
  transform: scale(0.545);
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
}
.ishowEdit {
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
</style>