<template>
  <div class="credit_management_popup">
    <div class="statistics-box"
         style="width: 322px;margin: 20px">
      <statistics-box :dataList="statisticsData"
                      @clickEvent="clcikStatictisItem"></statistics-box>
    </div>
    <div class="popup_title_padding view_title_flex">
      <input-search-contain-btn v-model="searchObj.searchText"
                                :placeholder="'请输入大课包'"
                                @clearSearch="clearSearchCondition"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="credit_management_table">
      <table-list ref="credit_management_table"
                  class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>

  </div>
</template>
<script>
import statisticsBox from '../../../../report/common/statistics-box';
import { CWSSearchPageCoursewareStat, WEBVSearchPageCoursewareStat } from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
export default {
  data () {
    return {
      statisticsData: {
        InUseCount: {
          title: '在用',
          value: 0,
          type: 2,
          isSelection: true
        },
        StopAuthCount: {
          title: '停用',
          value: 0,
          type: 3,
          isSelection: false
        },
        ExpireCount: {
          title: '失效',
          value: 0,
          type: 1,
          isSelection: false
        }
      },
      seletedItem: null, // 选中单个
      showAddForm: false,  // 新增老师修改老师表单显示
      showEditPWForm: false, // 修改密码
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        typeKey: '2'
      },
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '大课包名',
          prop: 'BigCoursewarePackageKeyValue',
          width: 220,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '子课包名',
          prop: 'AuthContent',
          width: 220,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '授权日期',
          prop: 'AuthorizeTime',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '到期日期',
          prop: 'ExpireTime',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '授权方式',
          prop: 'vClearingFormKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '授权剩余可用',
          prop: 'authDebtCount',
          width: 120,
          sortable: false,
          type: 'textItem',
          extend: {
            className: 'font_blue button',
            click: (rowData) => {
              this.toTeacherReport(rowData);
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '授权设备',
          prop: 'authText',
          width: 300,
          sortable: false,
          align: 'left',
          type: 'toolTipsItem',
          showOverflowTooltip: false,
          extend: {
            className: 'font_blue button',
            click: (rowData) => {
              this.toAuthManager(rowData);
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '授权老师',
          prop: 'TeacherAuthorizationCount',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: this.vIsAuthor ? '' : {
            className: 'font_blue button',
            click: (rowData) => {
              this.toTeacherAuthManager(rowData);
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '课件数',
          prop: 'CoursewareNum',
          width: 88,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '上课课次',
          prop: 'CourseCount',
          width: 88,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '上课时长',
          prop: 'DurationCount',
          width: 88,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        // {
        //   label: "消耗流量",
        //   prop: "ThisMonthFlow",
        //   width: 88,
        //   sortable: false,
        //   align: "left",
        //   type: "numberItem",
        // },
        {
          label: '消耗流量',
          prop: 'ConsumptionFlowCount',
          width: 88,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '授权方',
          prop: 'AuthorizedParty',
          width: 188,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '状态',
          prop: 'StateKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }

      ]
    };
  },
  props: {},
  components: {
    inputSearchContainBtn,
    tableList,
    statisticsBox
  },
  computed: {
    vClassName () {
      return this.vIsAuthor ? '' : 'font_blue button';
    },
    vIsAuthor () {
      console.log(this.$utils.getSaaSClientSetInfo(71).SetKey, 'this.$utils.getSaaSClientSetInfo(71).SetKey');
      return this.$utils.getSaaSClientSetInfo(71).SetKey == '1';
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        // 	1-课次授权;2-按年授权（天）;3-永久授权
        o.vClearingFormKeyValue = o.ClearingFormKeyValue;
        if (o.ClearingFormKey == 3) {
          o.authDebtCount = '-';
        } else if (o.ClearingFormKey == 1) {
          o.authDebtCount = '剩余 ' + (Number(o.ReleasedNum) - Number(o.UsageNum)) + '/' + Number(o.ReleasedNum) + '课次';
          o.vClearingFormKeyValue = '课次授权';
        } else {
          o.authDebtCount = '剩余 ' + (Number(o.ReleasedNum) - Number(o.UsageNum)) + '/' + Number(o.ReleasedNum) + '天';
        }
        // if(o.ExpireTime&&Date.new(o.ExpireTime)<Date.new(Date.new().toLocaleDateString())){
        //   o.ExpireTime='-'
        // }
        o.ExpireTime = (o.ClearingFormKey == 2 || o.ClearingFormKey == 4) ? o.ExpireTime : '-';

        o.ConsumptionFlowCount = this.$utils.flowFormate(o.ConsumptionFlowCount);
        o.authCount = Number(o.DeviceAuthCount) + '/' + Number(o.AuthorizeDeviceNum);
        o.TeacherAuthorizationCount = this.vIsAuthor ? '全部' : o.TeacherAuthorizationCount;

        if (o.AuthorizeDeviceNum == 0) {
          o.AuthorizeDeviceNumStr = '不限';
        } else {
          o.AuthorizeDeviceNumStr =
            o.DeviceAuthCount + '/' + o.AuthorizeDeviceNum;
        }
        var autnContentTips = ''
        if (o.IsPCAuthKey == '1') {
          let pcText = '';
          let contentTips = '';

          if (o.PCIsLocationLockKey == '1') {
            contentTips = '电脑' + (o.AuthorizeDeviceNum == 0 ? '不限' : o.DeviceAuthCount + '/' + o.AuthorizeDeviceNum) + '，定位开启';
          } else {
            contentTips = '电脑' + (o.AuthorizeDeviceNum == 0 ? '不限' : o.DeviceAuthCount + '/' + o.AuthorizeDeviceNum) + '，定位不限';
          }
          if (o.AuthorizeDeviceNum == '0') {
            pcText = '不限';
          } else {
            pcText = o.DeviceAuthCount + '/' + o.AuthorizeDeviceNum;
          }
          autnContentTips = autnContentTips + contentTips
          o.PCAuthText = `<img src='` + require('../../../../../../public/image/PC-icon.png') + `' class='location_icon' />` + `&nbsp` + pcText + `&nbsp` + (Number(o.PCIsLocationLockKey) == 1 ? `<img src='` + require('../../../../../../public/image/lock.png') + `' title='定位开启` + (o.PCPositionalAccuracyKey == 2 ? " 超高精度'" : " 高精度'") + ` class='location_icon' />` : `<img src='` + require('../../../../../../public/image/all-way.png') + `' title='定位不限' class='location_icon' />`);
        } else {
          let contentTips = '电脑禁用';
          autnContentTips = autnContentTips + contentTips
          o.PCAuthText = `<img src='` + require('../../../../../../public/image/PC-icon.png') + `' class='location_icon' />` + `&nbsp` + '禁用' + ``;
        }

        if (o.IsAPPAuthKey == '1') {
          let pcText = '';
          let contentTips = '';
          if (o.IsLocationLockKey == '1') {
            contentTips = '手机' + (o.APPAuthorizeDeviceNum == 0 ? '不限' : o.APPDeviceAuthCount + '/' + o.APPAuthorizeDeviceNum) + '，定位开启';
          } else {
            contentTips = '手机' + (o.APPAuthorizeDeviceNum == 0 ? '不限' : o.DeviceAuthCount + '/' + o.AuthorizeDeviceNum) + '，定位不限';
          }
          if (o.APPAuthorizeDeviceNum == '0') {
            pcText = '不限';
          } else {
            pcText = o.APPDeviceAuthCount + '/' + o.APPAuthorizeDeviceNum;
          }
          autnContentTips = autnContentTips + '。' + contentTips
          console.log(Number(o.IsLocationLockKey) == 1, o.APPIsLocationLockKey, 'o.APPIsLocationLockKey');
          o.APPAuthText = `<img src='` + require('../../../../../../public/image/APP-icon.png') + `' class='location_icon' />` + `&nbsp` + pcText + `&nbsp` + (Number(o.IsLocationLockKey) == 1 ? `<img src='` + require('../../../../../../public/image/lock.png') + `' title='定位开启` + (o.APPPositionalAccuracyKey == 2 ? " 超高精度'" : " 高精度'") + ` class='location_icon' />` : `<img src='` + require('../../../../../../public/image/all-way.png') + `' title='定位不限' class='location_icon' />`);
        } else {
          autnContentTips = autnContentTips + '。' + '手机禁用'
          o.APPAuthText = `<img src='` + require('../../../../../../public/image/APP-icon.png') + `' class='location_icon' />` + `&nbsp` + '禁用' + ``;
        }

        if (o.IsTVAuthKey == '1') {
          let pcText = '';
          let contentTips = '';
          if (o.TVIsLocationLockKey == '1') {
            contentTips = '电视' + (o.TVAuthorizeDeviceNum == 0 ? '不限' : o.TVDeviceAuthCount + '/' + o.TVAuthorizeDeviceNum) + '，定位开启';
          } else {
            contentTips = '电视' + (o.TVAuthorizeDeviceNum == 0 ? '不限' : o.TVDeviceAuthCount + '/' + o.TVAuthorizeDeviceNum) + '，定位不限';
          }
          if (o.TVAuthorizeDeviceNum == '0') {
            pcText = '不限';
          } else {
            pcText = o.TVDeviceAuthCount + '/' + o.TVAuthorizeDeviceNum;
          }
          autnContentTips = autnContentTips + '。' + contentTips
          o.TVAuthText = `<img src='` + require('../../../../../../public/image/TV-icon.png') + `' class='location_icon' />` + `&nbsp` + pcText + `&nbsp` + (Number(o.TVIsLocationLockKey) > 0 ? `<img src='` + require('../../../../../../public/image/lock.png') + `' title='定位开启` + (o.TVPositionalAccuracyKey == 2 ? " 超高精度'" : " 高精度'") + ` class='location_icon' />` : `<img src='` + require('../../../../../../public/image/all-way.png') + `' title='定位不限' class='location_icon' />`);
        } else {
          autnContentTips = autnContentTips + '。' + '电视禁用'
          o.TVAuthText = `<img src='` + require('../../../../../../public/image/TV-icon.png') + `' class='location_icon' />` + `&nbsp` + '禁用';
        }
        o.authText = o.PCAuthText + `&nbsp&nbsp&nbsp` + o.APPAuthText + `&nbsp&nbsp&nbsp` + o.TVAuthText;
        o.Tips = autnContentTips
        newArr.push(o);
      });
      console.log(newArr, 'vdataReportList')
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    isCWS () {      // 是否为课件门店
      return this.$store.getters.SaaSClientEditionKey == 6;
    }

  },

  created () {
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this,
      [
        'cwsAuthManagerList', 'cwsTeacherAuthList', 'classStatistics', 'WebvClassStatistics', 'coursewareUseStatlist'
      ]);


  },
  methods: {
    // 去老师统计
    toTeacherReport (item) {
      this.seletedItem = item;
      if (this.isCWS) {
        this.$dialog.open(this, {
          name: '课件使用统计',
          routerName: this.$route.name,
          routertitle: '工作台',
          ModulePowerKey: '',
          moduleName: 'coursewareUseStatlist',
          data: {
            dataInfo: {
              BigCoursewarePackageKey: this.seletedItem.BigCoursewarePackageKey,
              BigCoursewarePackageName: this.seletedItem.BigCoursewarePackageKeyValue,
              CoursewarePackageKey: this.seletedItem.AuthTypeKey != 2 ? this.seletedItem.AuthContent == '全部' ? '' : this.seletedItem.CoursewarePackageKey : '',
              CoursewarePackageName: this.seletedItem.AuthContent == '全部' ? '' : this.seletedItem.CoursewarePackageKeyValue,
              // CoursewareKey
              // AuthTypeKey==2
              Catalogue: this.seletedItem.AuthTypeKey == 2 ? this.seletedItem.CoursewarePackageKey : '',
              packageName: ''
            }
          },
          callBack: {
            afterSuccess: (data) => { this.getDataList(); }
          }
        });
      } else {
        this.$dialog.open(this, {
          name: '课件使用统计',
          routerName: this.$route.name,
          routertitle: '工作台',
          ModulePowerKey: '',
          moduleName: 'WebvClassStatistics',
          data: {
            dataInfo: {
              BigCoursewarePackageKey: this.seletedItem.BigCoursewarePackageKey,
              BigCoursewarePackageName: this.seletedItem.BigCoursewarePackageKeyValue,
              CoursewarePackageKey: this.seletedItem.AuthTypeKey != 2 ? this.seletedItem.AuthContent == '全部' ? '' : this.seletedItem.CoursewarePackageKey : '',
              CoursewarePackageName: this.seletedItem.AuthContent == '全部' ? '' : this.seletedItem.CoursewarePackageKeyValue,
              packageName: ''
            }
          },
          callBack: {
            afterSuccess: (data) => { this.getDataList(); }
          }
        });
      }
    },
    // 去授权管理
    toAuthManager (item) {
      this.seletedItem = item;
      this.$dialog.open(this, {
        name: '授权设备管理',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsAuthManagerList',
        data: {
          dataInfo: {
            CoursewarePackageKey: this.seletedItem.CoursewarePackageKey,
            CoursewarePackageKeyValue: this.seletedItem.CoursewarePackageKeyValue
          }
        },
        callBack: {
          afterSuccess: (data) => { this.getDataList(); }
        }
      });
    },
    // 去老师授权列表
    toTeacherAuthManager (item) {
      this.seletedItem = item;
      this.$dialog.open(this, {
        name: '课包授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthList',
        data: {
          dataInfo: {
            BigCoursewarePackageKey: this.seletedItem.BigCoursewarePackageKey,
            BigCoursewarePackageKeyValue: this.seletedItem.BigCoursewarePackageKeyValue
          }
        },
        callBack: {
          afterSuccess: (data) => { this.getDataList(); }
        }
      });
    },
    updateData () {
      this.getDataList();
    },
    // 重置查询条件
    initParamsForQuery () {
      this.dataReportList = [];
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
    },
    // 点击分类
    clcikStatictisItem (item) {
      this.initParamsForQuery();
      this.searchObj.typeKey = item.type;
      this.getDataList();
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    getDataList () {
      this.dataReportList = [];
      let fn = this.isCWS ? CWSSearchPageCoursewareStat : WEBVSearchPageCoursewareStat;
      fn(this.searchObj).then(
        (res) => {
          console.log('CWSSearchPageCoursewareStat', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.statisticsData.StopAuthCount.value = Number(res.data.StopAuthCount);
          this.statisticsData.InUseCount.value = Number(res.data.InUseCount);
          this.statisticsData.ExpireCount.value = Number(res.data.ExpireCount);

          // this.$refs['table'].doLayout()
          // console.log( this.$refs.credit_management_table,' this.$refs.credit_management_table')
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>
<style scoped>
.icon_img {
  width: 14px;
  height: 14px;
}
</style>