<template>
  <!-- 校长管理员 1 -->
  <div class="big_courseware_details">
    <div class='teaching_mode_cw_list'>
      <div class="teaching_mode_cw_list_search">
        <!-- 0 -->
        <span class="home_icon"><span class='span_hover'
                @click="toSeletedPage(0)">{{ dataInfo.MainDemoName }}</span> <span v-if="isOneCourseware">></span> </span>

        <!-- 1 -->
        <span :class="isOneCourseware && !isTwoCourseware?'opt':''"
              v-if="isOneCourseware">
          <span class='span_hover'
                @click="toSeletedPage(1,oneMainDemoName)">{{oneMainDemoName}}</span>
          <span v-if="isTwoCourseware">></span></span>
        <!-- 2 -->
        <span class="span_hover"
              v-if="isTwoCourseware"
              @click="toSeletedPage(2,twoMainDemoName)">
          <span :class="isTwoCourseware?'opt':''">{{twoMainDemoName}}</span>
        </span>

      </div>
    </div>
    <div class="return_courseware"
         v-if="isOneCourseware || isTwoCourseware"
         @click.stop="returnCoursewareClick">返回</div>
    <details-left :dataInfo="dataInfo"
                  @afterSuccess="afterSuccess"
                  @closeDialog='closeDialog'
                  ref="refDetailsLeft"></details-left>
    <details-right ref="refDetailsRight"
                   :coursewarePackageInfo="dataInfo"
                   :TeacherKey="dataInfo.TeacherKey"
                   :Source="dataInfo.Source"
                   :CoursewarePackageKey="dataInfo.CoursewarePackageKey"
                   @doOneTitle="doOneTitle"
                   @doTwoTitle="doTwoTitle"
                   @afterSuccess="afterSuccess"></details-right>
  </div>
</template>

<script>
import detailsLeft from './details-left';
import detailsRight from './details-right';
export default {
  components: {
    detailsLeft,
    detailsRight
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          AuthKey: '', // 授权key(校长工作台课件-必填)
          TeacherKey: 0, // 老师key(校长/老师工作台-必填)
          BigCoursewarePackageKey: 0, // 大课包ID(校长/老师工作台-必填)
          TeacherName: 0, // 老师名(校长/老师工作台-必填)
          Source: '1',
          AllBigCoursewarePackageKey: 0,
          CoursewarePackageKey: 0,
        };
      }
    }
  },
  data () {
    return {
      selectedCoursewareObj: {},
      oneMainDemoName: '',
      twoMainDemoName: '',
      isOneCourseware: false,
      isTwoCourseware: false,
      isThreeCourseware: false
    };
  },
  created () {
    if (!this.dataInfo.AuthKey) {
      this.dataInfo.AuthKey = '';
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getDetailsRight();
    });
    this.$dialog.register(this, ['CoursewareParticular', 'cwsTeacherAuthCw', 'cwsTeacherAuthList', 'cwsApplyAuth', 'coursewareTeachingView', 'courseAuthQrcode', 'cwsAuthManagerList', 'authManagerList']);
  },
  methods: {
    toSeletedPage (type, item) {
      this.searchText = '';
      console.log(type, 'item', item);
      if (type == 0) {
        this.isOneCourseware = false;
        this.isTwoCourseware = false;
        this.getDetailsRight();
      } else if (type == 1) {
        this.isOneCourseware = true;
        this.isTwoCourseware = false;
        this.$refs.refDetailsRight.searchCoursePackageinfo(this.selectedCoursewareObj.CoursewarePackageKey);
      }
    },
    returnCoursewareClick () {
      console.log(this.isOneCourseware, ' isOneCourseware isTwoCourseware isThreeCourseware', this.isTwoCourseware, this.isThreeCourseware);
      if (this.isOneCourseware == true && this.isTwoCourseware == false) {
        this.toSeletedPage(0);
      } else if (this.isOneCourseware == true && this.isTwoCourseware == true) {
        this.toSeletedPage(1);
      }
    },
    doOneTitle (name, type) {
      this.selectedCoursewareObj = type;
      this.isOneCourseware = true;
      this.oneMainDemoName = name;
      console.log(this.oneMainDemoName, 'this.oneMainDemoName');
    },
    doTwoTitle (name) {
      this.isOneCourseware = true;
      this.isTwoCourseware = true;
      this.twoMainDemoName = name;
      console.log(this.twoMainDemoName, 'this.twoMainDemoName');
    },
    getDetailsRight () {
      // console.log(this.dataInfo, 'dataInfo');
      var DeviceCode = '';
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();// 340BC40DC769411B
      }
      if (!this.dataInfo.BigCoursewarePackageKey) {
        this.$refs.refDetailsRight.getAuthBigCoursewarePackageDetails(this.dataInfo.AuthKey, DeviceCode);
      } else if (Number(this.dataInfo.BigCoursewarePackageKey) > 0 && Number(this.dataInfo.TeacherKey) > 0) {
        let roleKey = Number(this.$store.getters.token.RoleKey) == 2; // 2为校长；非则为老师
        if (roleKey) {
          this.$refs.refDetailsRight.getBigCoursewarePackageDetailsForPrincipaTeacher(this.dataInfo.TeacherKey, this.dataInfo.BigCoursewarePackageKey, DeviceCode, this.dataInfo.AuthKey);
        } else {
          this.$refs.refDetailsRight.getBigCoursewarePackageDetailsForTeacher(this.dataInfo.BigCoursewarePackageKey, DeviceCode, this.dataInfo.AuthKey);
        }
      }
    },
    getDetailsLeft () {
      this.$refs.refDetailsLeft.isTeacherInfo();
    },
    afterSuccess () {
      console.log(111112322);
      this.getDetailsRight();
      this.getDetailsLeft();
      this.$emit('afterSuccess');
    },
    closeDialog () {
      this.$emit('closeDialog');
    }
  }
};
</script>

<style scoped>
.big_courseware_details {
  height: 850px;
  display: flex;
}

.teaching_mode_cw_list {
  position: absolute;
}
.teaching_mode_cw_list_search {
  margin: 33px 0 0 500px;
}
.span_hover {
  position: relative;
  margin: 0 3px;
  cursor: pointer;
}
.home_icon {
  position: relative;
}
.home_icon::after {
  content: '';
  width: 24px;
  height: 24px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAYCAYAAADpnJ2CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHLSURBVHgB5ZYxT8JAFMffa4mRjdGRj0DDonGBzVE3NwuJwCZCjKNsxgHtYgIapU6OuDrJJCwE/ATWTyCTxgTufFdBCimlB42Lv+nd6+v7H9z/XoogSSpbNBDggEKThQeHpmH0ZN5Hv4V6Ph9RP9U6B0g40hbDUNKsnFl++/gS1HPHUYX3nyiMujyWElXnFej7RzEFBk3a2tqMkghypmvxja9Ou9ma189TMJ0p5FGBOomtetXZzxG3tPg6dtqtBiwimM4VS9TpFGRATGjxzUin/fw4s2Q6IcyBH6qBCHuwIGSsLsfQjtu5TggOzVGnMAbL42om5VdMmOPHiUGICaIK63f0THHbmbTPMJ0r6PRT7z2cuBhkJjqaXaeZVNscHC/mOnEp4bGZMJ0tcvgjMER/M3nKEAtGs3EUBwc3GWLJjgAaN5fltwmX0kU/gWFBUIK31fOUMxMCSWin1ihG99nqiZQgYwPNvDa6o7Wey9O9VV8lWozvoR/UFfXduTYrhgWSSAkGwT8TRESp7xMf9DwFBwgvECCcY9dT0KyUG2IigFRX6LmnuVW7Kt95Cgpq1XJSjCPxwlSHBzGapuuZghPjkNMGGOclHmYauPANAh+kPBBqBDgAAAAASUVORK5CYII=)
    no-repeat;
  background-size: 19px;
  position: absolute;
  left: -27px;
  top: 1px;
  z-index: 2;
}
.opt {
  color: #e64024;
}
.return_courseware {
  line-height: 34px;
  width: 56px;
  height: 36px;
  text-align: center;
  color: #fff;
  background: linear-gradient(
      225.5deg,
      rgb(255, 96, 0) 50%,
      rgb(255, 137, 0) 100%
    ),
    rgb(0, 0, 0);
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 80px;
}
.return_courseware:hover {
  background: linear-gradient(
      225.5deg,
      rgb(255, 96, 0) 0%,
      rgb(255, 137, 0) 100%
    ),
    rgb(0, 0, 0);
}
</style>
