<template>

  <div class="pr">
    <!-- new_course_calendar -->
    <!-- <div class="course_block_gray pa" style="left:0;top:0;width:100%"><span class="font_red">*</span>点击课块给学生排课</div> -->
    <div class="single_shadow month_chart_box new_course_calendar">
      <div class="month_chart_header pr">
        <div class="pr">
          <!-- <div @click.stop="headerMenu" class="header_menu"></div> -->
          <div class="header_date">
            <div @click="preMonth"
                 class="header_date_prod btn_hover_white fl">上月</div>
            <div class="header_date_text fl">{{vData[0] + '年' + vData[1] + '月'}}</div>
            <div @click="nextMonth"
                 class="header_date_next btn_hover_white fr">下月</div>
          </div>
          <!-- @click="showTips"  -->
          <!-- <div @click.stop="headerMenu"
               class="header_btn"> -->
          <!-- <div v-show="isTips" class="header_tips_box">
                      <span>鼠标悬停在课块上，可查看详情。</span>
                      <div @click.stop="hideTips" class="header_tips_box_close"></div>
                    </div> -->
          <!-- </div> -->
        </div>
        <div class="header_week clearfix">
          <div>一</div>
          <div>二</div>
          <div>三</div>
          <div>四</div>
          <div>五</div>
          <div>六</div>
          <div>日</div>
        </div>
      </div>
      <div class="month_chart_content"
           style="background-color:#fff;">
        <div v-for="(item,index) in vAllWeek"
             :key="index"
             class="chart_vertical clearfix">
          <div v-for="(item1,index1) in item"
               :key="index1"
               class="chart_across"
               :class="[mDate(item1.date)?'today':'',vClass(item1)]"
               @click.stop="seletedDay(item1,index)">
            <!-- border_blue 为有数据的时候  vClass   today 为今天 -->
            <!-- :class="index == 0 && item1.date.split('-')[2] > 7 || index == vAllWeek.length - 1 && item1.date.split('-')[2] < 10 -->
            <div :class="index == 0 && item1.date.split('-')[2] > 10 || index == vAllWeek.length - 1 && item1.date.split('-')[2] < 10?'font_gray':''"
                 class="chart_number ">{{getDay(item1.date)}}</div>
            <!-- mDate(item1.date)?'今天':getDay(item1.date -->

          </div>
        </div>
      </div>
      <div class="month_chart_bottom_btn"
           @click.stop="getNowMonth">
        本月
      </div>
    </div>

    <popup-box style="min-height:0;"
               ref="popupbox"
               v-if="showPop"
               :showContent="true"
               :noBtn="true"
               :vTop="vTop"
               :vLeft="vLeft"
               @hideBox="hideBox()"
               @keepShow="keepShow"
               @closeShow="closeShow">
    </popup-box>
  </div>
</template>
<script>
import popupBox from '../../schedule-course-table/popup-box';
export default {
  data () {
    return {
      data: '',
      lastDay: '',
      move: false,
      isTips: false,
      timeOutEvent: '',
      timeOut: '',
      showPop: false,
      vTop: '0',
      vLeft: '0',
      minValue: ''
    };
  },
  props: {
    monthInfo: {
      type: Array,
      default: []
    },
    courseDateList: {
      type: Array,
      default: []
    }
  },
  components: {
    popupBox
  },
  computed: {
    vData () {
      let arr = this.data.split('-');
      return arr;
    },
    vAllWeek () {
      let day = this.getWeekTime(this.vData[0], this.vData[1], 1);
      this.lastDay = this.vData[0] + '-' + this.vData[1] + '-' + day[1];
      let month = [];
      let week = [];
      let i1;
      let i2;
      let i3;
      let i4;
      for (let i = day[0]; i <= day[1]; i++) {
        if (i < 10) {
          i1 = '0' + i;
        } else {
          i1 = i;
        }
        month.push({
          date: this.vData[0] + '-' + this.vData[1] + '-' + i1,
          info: [],
          isSeleted: false,
          isActive: false
        });
      }
      for (let i = 0; i < month.length; i += 7) {
        week.push(month.slice(i, i + 7));
      }
      // 补充下个月的日期
      if (week[week.length - 1].length < 7) {
        let num = 7 - week[week.length - 1].length;
        let fm;
        let fy;
        if (this.vData[1] < 12) {
          fy = this.vData[0];
          if (Number(this.vData[1]) < 10) {
            fm = '0' + (Number(this.vData[1]) + 1);
          } else {
            fm = Number(this.vData[1]) + 1;
          }
        } else {
          fy = Number(this.vData[0]) + 1;
          fm = '01';
        }

        for (let i = 1; i <= num; i++) {
          if (i < 10) {
            i2 = '0' + i;
          } else {
            i2 = i;
          }
          week[week.length - 1].push({
            date: fy + '-' + fm + '-' + i2,
            info: [],
            isSeleted: false,
            isActive: false
          });
        }
      }
      if (day[0] == 2 && week.length < 6 || week.length < 6) {
        let last = this.getWeekTime(this.vData[0], Number(this.vData[1]) - 1, 1)[1];
        let j = week[0][0].date.split('-')[2];
        let lm;
        let ly;
        if (this.vData[1] == 1) {
          ly = Number(this.vData[0]) - 1;
          lm = 12;
        } else {
          ly = Number(this.vData[0]);
          lm = Number(this.vData[1]) - 1;
          if (lm < 10) {
            lm = '0' + lm;
          } else {
            lm = lm;
          }
        }
        week.unshift([]);
        for (let i = 0; i < 7; i++) {
          j--;
          if (j > 0) {
            if (j < 10) {
              i3 = '0' + j;
            } else {
              i3 = j;
            }
            week[0].unshift({
              date: this.vData[0] + '-' + this.vData[1] + '-' + i3,
              info: [],
              isSeleted: false,
              isActive: false
            });
          } else {
            if (last < 10) {
              i4 = '0' + last;
            } else {
              i4 = last;
            }
            week[0].unshift({
              date: ly + '-' + lm + '-' + i4,
              info: [],
              isSeleted: false,
              isActive: false
            });
            last--;
          }
        }
      }
      this.monthInfo.forEach(e => {
        this.$set(e, 'showPopUp', false);
        week.forEach(o => {
          o.forEach(d => {
            if (d.date && d.date.indexOf('010') > -1) {
              d.date = d.date.replace(/\-010-/g, '-10-'); // 10月份日期异常
            }
            if (d.date == e.ClassTime) {
              d.info.push(e);
              d.isActive = true;
            }
          });
        });
      });
      this.courseDateList.forEach(p => {
        week.forEach(o => {
          o.forEach(d => {
            if (d.date && d.date.indexOf('010') > -1) {
              d.date = d.date.replace(/\-010-/g, '-10-'); // 10月份日期异常
            }
            if (d.date == p) {
              d.info.push(p);
              d.isSeleted = true;
              // console.log(p)
            }
          });
        });
      });
      return week;
    }
  },
  created () {
    this.data = this.$utils.getCurrentMonthFirst();
  },
  mounted () {
    this.showTips();
  },
  methods: {
    vClass (item) {
      if (item.isActive) {
        return 'border_blue';
      } else if (item.isSeleted) {
        return 'opt';
      } else {
        return '';
      }
    },
    mDate (date) {
      // console.log(date, '日期');
      let newDate = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd');
      if (newDate === date) {
        return true;
      } else {
        return false;
      }
    },
    showTips () {
      if (!this.isTips) {
        this.isTips = true;
        let clock = window.setTimeout(() => {
          this.isTips = false;
        }, 3000);
      }
    },
    hideBox (item) {
      this.showPop = false;
    },
    hideTips () {
      this.isTips = false;
    },
    getNowMonth () {
      this.data = this.$utils.getCurrentMonthFirst();
      this.$emit('getMonthInfo', this.data);
    },
    preMonth () {
      this.data = this.$utils.getPreMonth(this.data);
      // console.log(this.data, '开始日期');
      this.$emit('getMonthInfo', this.data);
    },
    nextMonth () {
      this.data = this.$utils.getNextMonth(this.data);
      // console.log(this.data, '结束日期');
      this.$emit('getMonthInfo', this.data);
    },
    headerMenu () {
      this.$emit('headerMenu');
    },
    getDay (date) {
      let d = date.split('-');
      return d[2];
    },
    // 根据年月周获取该周从周一到周日的日期
    getWeekTime (year, month, weekday) {
      var d = Date.new();
      // 该月第一天
      d.setFullYear(year, month - 1, 1);
      var w1 = d.getDay();
      if (w1 == 0) { w1 = 7; }
      // 该月天数
      d.setFullYear(year, month, 0);
      var dd = d.getDate();
      // 第一个周一
      let d1;
      if (w1 != 1) { d1 = 7 - w1 + 2; } else { d1 = 1; }
      var monday = d1 + (weekday - 1) * 7;
      // 获取当月最后一天日期
      var new_year = year;    // 取当前的年份
      var new_month = month++;// 取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) {
        new_month -= 12;        // 月份减
        new_year++;            // 年份增
      }
      var new_date = Date.new(new_year, new_month, 1);          // 取当年当月中的第一天
      let arr = [];
      arr.push(monday);
      arr.push((Date.new(new_date.getTime() - 1000 * 60 * 60 * 24)).getDate());
      return arr;
    },
    mouseoverCourse (event, item) {
      let that = this;
      let $e = event.currentTarget;
      let poplHeight;
      if (document.documentElement.getElementsByClassName('student_arrange_dialog')[0]) {
        let popElemntBox = document.documentElement.getElementsByClassName('student_arrange_dialog')[0].children[0];
        poplHeight = popElemntBox.offsetTop + popElemntBox.offsetHeight;
      }
      let htmllHeight = document.documentElement.offsetHeight;
      if (poplHeight && htmllHeight - poplHeight < 180) {
        that.vTop = $e.parentElement.offsetTop + $e.offsetTop + 34 - $e.parentElement.parentElement.parentElement.scrollTop - 230 + 'px';
        that.vLeft = $e.parentElement.offsetLeft + $e.offsetLeft + 'px';
      } else {
        that.vTop = $e.parentElement.offsetTop + $e.offsetTop + 34 - $e.parentElement.parentElement.parentElement.scrollTop + 'px';
        that.vLeft = $e.parentElement.offsetLeft + $e.offsetLeft + 'px';
      }
      clearTimeout(this.timeOutEvent);// 清除定时器
      clearTimeout(this.timeOut);
      this.timeOutEvent = 0;
      this.timeOut = 0;
      this.timeOutEvent = setTimeout(function () {
        that.showPop = true;
        item.week = that.$utils.getWeekByTwo(item.ClassTime);

        that.$nextTick(() => {
          that.$refs.popupbox.getCourseDetailedByID(item.OLAPKey);
        });
        that.$emit('continuePress', item);
      }, 300);// 这里设置定时
    },
    closeCourse (item) {
      let that = this;
      this.timeOut = 0;
      this.timeOut = setTimeout(function () {
        that.showPop = false;
      }, 200);
      clearTimeout(this.timeOutEvent);
    },
    keepShow () {
      clearTimeout(this.timeOut);
    },
    closeShow () {
      this.showPop = false;
    },
    gotouchend (item) {
      console.log(item, 'item');
      this.$emit('continueClick', item);
    },
    seletedDay (item, index) {
      console.log(item, 'seletedDay', Date.new(item.date).getTime() + 86400000 * 60 >= Date.new().getTime());
      // if (index == 0 && item.date.split('-')[2] > 7 || index == this.vAllWeek.length - 1 && item.date.split('-')[2] < 10) {
      //   return false;
      // }
      // 禁止选中两个月前
      // if (!(Date.new(item.date).getTime() + 86400000 * 60 >= Date.new().getTime()) || item.isActive) {
      //   return false;
      // }
      if (!(Date.new(item.date).getTime() + 86400000 >= Date.new().getTime()) || item.isActive) {
        return false;
      }
      let locaIndex = this.courseDateList.findIndex(p => {
        return p == item.date;
      });
      if (locaIndex >= 0) {
        this.courseDateList.splice(locaIndex, 1);
      } else {
        this.courseDateList.push(item.date);
      }
      console.log(this.courseDateList, 'seletedDay', item);
    }
  }
};
</script>