<template>
  <!-- 套餐设置列表 -->
  <div class="taoCanSetting clearfix">
    <div class="single_shadow content_marginRL content_marginTop_30">
      <span class="defult_dialog_header_btn fr"
            @click="doComboAddClick()"
            v-if="isShowAdd">
        <span class="btn_add">+</span>新建套餐
      </span>

      <div class="class_common_list_box">
        <input-search-immediately v-model="searchText"
                                  :placeholder="'请输入套餐名、套餐首字母'"
                                  :styleText="'right: 45px;z-index: 10;'"
                                  @clearSearch="clearSearch"></input-search-immediately>
        <div class="class_common_list_content">
          <!-- 套餐内容 -->
          <div class="package_setting_ul">
            <taocan-list v-for="(item,index) in vTaocanList"
                        :key="index"
                        :item="item"
                        @doComboSelectClick="doComboSelectClick"></taocan-list>
          </div>
          <div v-if="vTaocanList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>  

<script>
import {
  SumarizeTaocanTicketPlan
} from '../../../../API/workbench';
import dropDownBox from '../../../common/drop-down-box';
import taocanList from './taocan-list';
export default {
  data () {
    return {
      taocanList: [], // 套餐列表
      taocanSelectItem: {}, // 套餐项
      searchText: '' // 搜索条件
    };
  },

  components: {
    dropDownBox,
    taocanList
  },
  props: {
    isShowAdd: {
      type: Boolean,
      default: true
    },
    isChildComponent: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.loadData(); // 渲染列表数据
  },
  mounted () {
    // 点击别处时弹框自动收回
    this.$dialog.register(this, ['taoCanSetting']);
  },
  computed: {
    vTaocanList () {
      let newArr = [];
      let trueSearchText = this.searchText.replace(/ /g, '').toLowerCase();
      if (trueSearchText) {
        this.taocanList.forEach(o => {
          if (trueSearchText &&
            (o.PackageName.toLowerCase().indexOf(trueSearchText) != -1 ||
              (o.FirstLetterName && o.FirstLetterName.toLowerCase().indexOf(trueSearchText) != -1))
          ) {
            newArr.push(o);
          }
        });
        return newArr;
      } else {
        return this.taocanList;
      }
    }
  },
  methods: {
    // 清空搜索条件并查找
    clearSearch () {
      this.searchText = '';
    },
    loadData () {
      SumarizeTaocanTicketPlan().then(result => {
        console.log(result.data, '套餐');
        this.taocanList = result.data;
      }, error => {
        layer.alert(error.msg);
      }
      );
    },
    // 新建套餐项
    doComboAddClick () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '46',
        name: '新建套餐',
        moduleName: 'taoCanSetting',
        data: { TableID: 0, type: 'add' },
        callBack: {
          afterSuccess: (type, data) => { console.log(data, '新建套餐成功'); this.loadData(); }
        }
      });
    },
    // 查看套餐项
    doComboSelectClick (item) {
      if (this.isChildComponent) { // 套餐排课
        this.$emit('onClick', item);
      } else { // 套餐详情
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '46',
          name: '套餐详情',
          moduleName: 'taoCanSetting',
          data: { TableID: item.TableID, type: 'view' },
          callBack: {
            afterSuccess: (type, data) => {
              console.log(type, data, '查看套餐回调');
              if (type == 'edit') {
                this.doComboEditClick(data);
              } else {
                this.loadData();
              }
            }
          }
        });
      }
    },
    // 修改套餐项
    doComboEditClick (data) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '46',
        name: '修改套餐',
        moduleName: 'taoCanSetting',
        data: { TableID: data.TableID, type: 'edit' },
        callBack: {
          afterSuccess: (type, data) => { console.log(data, '修改套餐成功'); this.loadData(); }
        }
      });
    }
  }
};
</script>

