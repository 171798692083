<template>
  <div>
    <div class="">
      <div class="principal_swiper_nav clearfix">
        <div class="nav_left"
             :class="{'opt':!searchType}"
             @click="chgSearchType(false)">课程签到分析</div>
        <div class="nav_right"
             :class="{'opt':searchType}"
             @click="chgSearchType(true)">班级签到分析</div>
      </div>
      <div class="principal_echart_option"
           v-if="!searchType">
        <div class="fl">选择课程</div>
        <div class="fr"
             @click="showCourseList">
          <input type="text"
                 readonly
                 :style="selectedCourse.MainDemoName?{'color':'#3498DB'}:''"
                 v-model="selectedCourse.MainDemoName"
                 placeholder="请选择课程" />
        </div>
      </div>
      <div class="principal_echart_option"
           v-if="searchType">
        <div class="fl">选择班级</div>
        <div class="fr"
             @click="showClassList">
          <input type="text"
                 readonly
                 :style="selectedClass.MainDemoName?{'color':'#3498DB'}:''"
                 v-model="selectedClass.MainDemoName"
                 placeholder="请选择班级" />
        </div>
      </div>
      <div class="principal_echart_content">
        <div class="principal_echart_box">
          <div class="echart_btn">
            <div :class="{'opt':!isMonth}"
                 @click="chgIsMonth(false)">按周</div>
            <div :class="{'opt':isMonth}"
                 @click="chgIsMonth(true)">按月</div>
          </div>
          <div class="echart_box">
            <singleLineEchart :echartID="'echart_01'"
                              :nowList="nowList"
                              :reqList="reqList"
                              ref="echart"></singleLineEchart>
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="'选择课程'"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeDialogForCourseNameList">
      <div>
        <single-selection-list ref="studentListDialog"
                               isShowSearchInput="false"
                               :dataSourceList="courseNameList"
                               :required="false"
                               :footerButtonTitle="'全部课程'"
                               :selectedKey="searchObj.CourseKey"
                               @onSelectedItem="doAfterSelectedCourse"></single-selection-list>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import singleLineEchart from './single-line-echart';
// import doubleLineEchart from './double-line-echart';
import {
  getKQStatisticsForMonth,
  getKQStatisticsForWeek,
  SelectTheClassType
} from '../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        startTime: '',
        endTime: '',
        CourseKey: '', // 课ID
        GradeKey: ''
      },
      searchType: false, // false为课程签到分析  true为班级签到分析
      isMonth: false, // 判断是否查询月接口或者周接口.
      selectedCourse: {
        // 选中课程
        MainDemoName: '全部课程'
      },
      selectedClass: {
        // 选中班级
      },
      reportList: [], // 接收result.data.
      preList: [], // 传递进echart里面的显示数据
      nowList: [], // 传递进echart里面的显示数据
      reqList: [], // x轴显示数组.
      courseNameList: [],
      isShowCourseNameList: false
    };
  },
  components: {
    singleLineEchart
  },
  props: {
    screenWidth: [String, Number]
  },
  created () { },
  watch: {
    screenWidth (c, o) {
      console.log('this.$refs.echart考勤.', this.$refs.echart.myChart);
      if (this.$refs.echart.myChart) {
        this.$refs.echart.myChart.resize();
      }
    },
    $route (to, from) {
      // 监听路由是否变化
      if (to.name == 'presidentKanban') {
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }
    }
  },
  mounted () {
    this.loadData();
  },
  methods: {
    // 获取课名列表
    loadCourseNameList () {
      SelectTheClassType().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          if (obj.StateKey != 3 && obj.TypeKey == 2) {
            // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
            this.courseNameList.push(obj);
          }
        });
        this.$nextTick(() => {
          console.log(this.courseNameList, 'this.courseNameList');
          this.isShowCourseNameList = true;
        });
        // 要根据课名列表来判断课单对应的报课计划是否为单课
        // this.getTheTicketPlanByApplyClassKey();
      });
    },
    loadData () {
      this.setWeekTime();
      this.getKQChartForWeek(this.searchObj);
    },
    setMonthTime () {
      let month = Date.new().getMonth();
      let startTime =
        this.$utils.formatDatet(
          Date.new(Date.new().setMonth(month - 5)),
          'yyyy-MM'
        ) + '-01';
      let lastDate = Date.new(
        Date.new(
          this.$utils.formatDatet(
            Date.new(Date.new().setMonth(month + 1)),
            'yyyy-MM'
          ) + '-01'
        ).setDate(-1)
      ); // 获取这个月最后一天的日期.
      this.searchObj.endTime = this.$utils.formatDatet(lastDate, 'yyyy-MM-dd');
      this.searchObj.startTime = startTime;
      console.log(this.searchObj);
    },
    setWeekTime () {
      this.isMonth = false;
      let weekDay = Date.new().getDay(); // 获取这是周几
      let dateTime = Date.new().getTime(); // 时间戳,等待计算.
      this.searchObj.endTime = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd'); // (86400000 * (weekDay - 1)) 为这周的时间.
      this.searchObj.startTime = this.$utils.formatDatet(
        Date.new(dateTime - 86400000 * (weekDay - 1) - 86400000 * 7 * 5),
        'yyyy-MM-dd'
      );
      console.log(weekDay, 'setWeekTime', this.searchObj);
    },
    getKQChartForMonth (searchObj) {
      getKQStatisticsForMonth(searchObj)
        .then(result => {
          this.reportList = result.data.DataList;
          this.reqList = this.setMonthReqList();
          this.nowList = this.setNowList();
          this.preList = this.setPreList();
          if (this.$refs.echart) {
            this.$nextTick(() => {
              this.$refs.echart.echartInit();
            });
          }
        })
        .catch(error => {
          console.log('getKQChartForMonth  error', error);
        });
    },
    getKQChartForWeek (searchObj) {
      getKQStatisticsForWeek(searchObj)
        .then(result => {
          this.reportList = result.data.DataList;
          this.reqList = this.setWeekReqList();
          this.nowList = this.setNowList();
          this.preList = this.setPreList();
          if (this.$refs.echart) {
            this.$nextTick(() => {
              this.$refs.echart.echartInit();
            });
          }
          console.log(this.reqList, 'getKQChartForWeek', this.dataList);
        })
        .catch(error => {
          console.log('getKQChartForWeek  error', error);
        });
    },
    // 获取X轴的数组
    setWeekReqList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        for (var i = 0; i < this.reportList.length; i++) {
          if (i == 0) {
            newArr.push('前5周');
          } else if (i == 1) {
            newArr.push('前4周');
          } else if (i == 2) {
            newArr.push('前3周');
          } else if (i == 3) {
            newArr.push('前2周');
          } else if (i == 4) {
            newArr.push('上周');
          } else {
            newArr.push('本周');
          }
        }
      }
      return newArr;
    },
    setMonthReqList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(this.$utils.formatDateStr(o.SetUpTime, 'yyyy.M'));
          // totalDeductAmount
        });
      }
      return newArr;
    },

    // 获取柱状图数组
    setNowList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(Number(o.Attence3));
          // totalDeductAmount
        });
      }
      return newArr;
    },
    setPreList () {
      // 安排人数
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(Number(o.TotalPerson));
          // totalDeductAmount
        });
      }
      return newArr;
    },
    // 更改searchType查询
    chgSearchType (type) {
      this.searchType = type;
      this.searchObj.CourseKey = '';
      this.searchObj.GradeKey = '';
      this.selectedCourse = {};
      this.selectedClass = {};
      this.isMonth = false;
      if (!this.searchType) {
        this.selectedCourse.MainDemoName = '全部课程';
      } else {
        this.selectedClass.MainDemoName = '全部班级';
      }
      this.toLoadData();
    },
    chgIsMonth (type) {
      this.isMonth = type;
      this.toLoadData();
    },
    toLoadData () {
      if (this.isMonth) {
        this.setMonthTime();
        this.getKQChartForMonth(this.searchObj);
      } else {
        this.setWeekTime();
        this.getKQChartForWeek(this.searchObj);
      }
    },
    // 显示课程列表
    showCourseList () {
      this.loadCourseNameList();
      // this.isShowCourseNameList = true;
      // this.$refs.listkechengsetting.isShowMainForm = true;
    },
    closeDialogForCourseNameList () {
      this.isShowCourseNameList = false;
    },
    // 显示班级列表
    showClassList () {
      let obj = {
        isChildComponent: true,
        required: true,
        footerButtonTitle: '全部班级'
      };
      let item = {
        name: '选择班级',
        routerName: this.$route.name,
        routertitle: '',
        auth: true,
        roleName: '',
        moduleName: 'banJiSettingList',
        ModulePowerKey: 45,
        data: { dataInfo: obj },
        callBack: { onClick: (item) => { this.doAfterSelectedClass(item); } }
      };
      this.$bus.emit('needOpenRouter', item);
    },
    // 弹出课程列表后选中.
    doAfterSelectedCourse (item) {
      console.log(item, 'item');
      this.selectedCourse = item;
      this.searchObj.CourseKey = item.OLAPKey;
      if (this.selectedCourse.MainDemoName == '') {
        this.selectedCourse.MainDemoName = '全部课程';
      }
      this.toLoadData();
      this.closeDialogForCourseNameList();
      // this.$refs.listkechengsetting.isShowMainForm = false;
      // this.backToMain();
    },
    doAfterSelectedClass (item) {
      // console.log(item, 'item');
      this.selectedClass = item;
      console.log(this.selectedClass, 'doAfterSelectedClass');
      this.searchObj.GradeKey = item.OLAPKey;
      if (this.selectedClass.MainDemoName == '') {
        this.selectedClass.MainDemoName = '全部班级';
      }
      this.toLoadData();
      // this.$refs.banjisetting.isShowMainForm = false;
      // this.backToMain();
    }
    // backToMain () {
    // this.$refs.courseListPopup.PopUpShow = false;
    // this.$refs.classListPopup.PopUpShow = false;
    // }
  }
};
</script>
