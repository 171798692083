<template>
  <div class="table_select_box_flex">
    <div class="table_select_box">
      <div class="table_select_title">课程</div>
      <el-select :value="vCourseKeyValue"
                 filterable
                 placeholder="必选"
                 @change="subjectEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in courseNameList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box">
      <div class="table_select_title">剩余课时</div>
      <el-select :value="vSeletedTimeValue"
                 filterable
                 placeholder="必选"
                 @change="seletedTime"
                 value-key="OLAPKey">
        <el-option v-for="item in maxTimeList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { getCourseTypeNameListX } from '../../../API/workbench';
export default {
  data () {
    return {
      teacherList: [], // 老师列表
      gradeClassList: [], // 班级列表
      courseNameList: [],
      maxTimeList: [
        {
          MainDemoName: '不限',
          OLAPKey: ''
        },
        {
          MainDemoName: '小于5',
          OLAPKey: '5'
        },
        {
          MainDemoName: '小于10',
          OLAPKey: '10'
        },
        {
          MainDemoName: '小于50',
          OLAPKey: '50'
        },
        {
          MainDemoName: '小于100',
          OLAPKey: '100'
        }
      ]
    };
  },
  created () {
    this.getCourseNameList();
  },
  props: {
    searchObj: Object
  },
  computed: {
    vCourseKeyValue () {
      return this.searchObj.CourseKey == 0
        ? '全部'
        : this.searchObj.CourseKeyValue;
    },
    vSeletedTimeValue () {
      return this.searchObj.MaxHour == 0
        ? '不限'
        : this.searchObj.MaxHourValue;
    }
  },
  watch: {

  },
  methods: {
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.CourseKeyValue = obj.MainDemoName;
      this.searchObj.CourseKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    seletedTime (obj) {
      this.searchObj.MaxHourValue = obj.MainDemoName;
      this.searchObj.MaxHour = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 调用在职老师接口
    getCourseNameList () {
      getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
        this.courseNameList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    }
  }

};
</script>