<template>
  <div class="box">
    <iframe v-if="isSSP && isShow||isSSPFail"
            id="CTIframe"
            ref="iframe"
            width="100%"
            height="100%"
            :src="vUrl"
            class="iframe_box"></iframe>
    <div v-else-if="!isSSP||isSSPFail"
         class="ssp_tips">
      <div class="home_nav_back_icon button"
           @click.stop="reback"></div>
      <div class="ssp_tips_txt">
        <span class="ssp_tips_txt_loading">正在加载课件……</span><br>
        <span class="ssp_tips_txt_ext"
              v-if="sspError==''">如果长时间加载失败，请退出重进</span>
        <span class="ssp_tips_txt_ext"
              v-if="sspError!=''">{{sspError}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { md5 } from '../../API/md5';
export default {
  data () {
    return {
      isSSP: null, // 启动防截屏程序,
      isSSPFail: false,
      sspError: '', // 启动防截屏程序报错
      sspCheckHandler: null, // 防截屏确认定时器，默认30秒一次。
      rlen: 0,
      viewType: 0,
      coursewareId: 0,
      authCoursewareID: 0,
      scheduleCourseID: 0,
      authorizeType: 0,
      isShow: true,
      isTeachingMode: false,
      clientId: 0
      // authorizeType: 0,
      // authorizeType: 0
    };
  },
  props: {
    dataInfo: Object,
    watermark: String
  },
  computed: {
    vUrl () {
      return '/CT';
      // console.log('process.env.NODE_ENV');
      // if (process.env.NODE_ENV === 'development') { // 开发环境
      //   console.log('dev');
      // return 'http://127.0.0.1:8080/CT';
      // } else {
      //   console.log('p');
      //   return '/CT';
      // }
    },

  },

  created () {
    this.$bus.on(this, 'scanToAuthCourseware', (res) => {
      console.log('scanToAuthCourseware111', res.data);
      let data = JSON.stringify(res.data);
      console.log('scanToAuthCourseware2222', data);
    });
    console.log('location', location.origin)
    console.log('IsPCRecordKey', this.$route.query.IsPCRecordKey == 1);
    console.log(this.dataInfo, 'dataInfo123456  watermark123 PositioningOffset', this.watermark, this.$store.getters.geoInfo.PositioningOffset);
    this.rlen = window.history.length;
    if (this.$zxClient.IsClient && this.$zxClient.FullScreen) {
      this.$zxClient.FullScreen();
      if (this.$route.query.IsPCRecordKey == 1) {
        this.isSSPFail = false
        this.startSSP();
      } else {
        this.isSSPFail = true
      }

    } else {
      this.$router.push({
        path: this.$utils.getIndexPath()
      });
    }
    if (this.$route.query.type) {
      this.viewType = this.$route.query.type;
    }
    this.clientId = this.$store.getters.clientId;
    if (this.$route.query.isTeachingMode) {
      this.isTeachingMode = this.$route.query.isTeachingMode;
    }
    if (this.$route.query.scheduleCourseID) {
      this.scheduleCourseID = this.$route.query.scheduleCourseID;
      console.log(this.$route.query.scheduleCourseID, '课ID');
    }
    if (this.$route.query.coursewareId) {
      this.coursewareId = this.$route.query.coursewareId;
      console.log(this.$route.query.coursewareId, '课件ID');
    }
    if (this.$route.query.authCoursewareID) {
      this.authCoursewareID = this.$route.query.authCoursewareID;
      console.log(this.$route.query.authCoursewareID, '授权课件ID');
    }
    if (this.$route.query.coursewareInfoType) {
      this.authorizeType = this.$route.query.coursewareInfoType == 'ZZ' ? 2 : 1;
    }
    this.$bus.on(this, 'wsquitAttendClassPreview', (data) => {
      // layer.alert('该课已被强制结束！');
      console.log('wsquitAttendClassPreview', data);
      this.wvExecCommand('closeCourseware("该课已被强制结束！")');
    });
    this.$bus.on(this, 'wsCoursewareMutualExclusion', (data) => {
      // layer.alert('该课已被强制结束！');
      console.log('wsCoursewareMutualExclusion', data);
      let DeviceTypeKeyValue = '';
      if (data.newData.DeviceTypeKey == 1) {
        DeviceTypeKeyValue = '浏览器端';
      } else if (data.newData.DeviceTypeKey == 2) {
        DeviceTypeKeyValue = '桌面端';
      } else if (data.newData.DeviceTypeKey == 3) {
        DeviceTypeKeyValue = 'APP端';
      } else {
        DeviceTypeKeyValue = 'TV端';
      }
      let text = '您已在' + DeviceTypeKeyValue + '进行上课！';
      this.wvExecCommand('closeCourseware("' + text + '")');
    });
    this.$bus.on(this, 'wsVerifyISEndVisit', (data) => {
      // layer.alert('该课已被强制结束！');
      console.log('wsVerifyISEndVisit', data);
      this.wvExecCommand('VerifyISEndVisitApp()');
    });
    //  this.$bus.on(this, 'wsquitAttendClassPreview', (data) => {
    //   // layer.alert('该课已被强制结束！');
    //   console.log('wsquitAttendClassPreview', data);
    //   this.wvExecCommand('closeCourseware("")');
    // });
    document.body.style.overflow = 'hidden';
  },
  mounted () {
    window.addEventListener('message', this.doMessage);
    window.ExitCT = this.exitCT;
  },
  beforeDestroy () {
    window.removeEventListener('message', this.doMessage);
    document.body.style.overflow = 'visible';
    this.closeSSP();
    this.isSSP = false;
    window.ExitCT = null;
  },
  methods: {
    doMessage (messageData) {
      console.log('recive message', messageData);
      switch (messageData.data.cmd) {
        case 'OnCTInitalise':
          this.initCTIframe();
          break;
        case 'CloseSSP':
          console.log('关闭防录屏', 'CloseSSP');
          this.closeSSP();
          break;
        case 'exitCourseware':
          console.log('退出aaa', this.rlen, window.history.length, window.history);
          if (!this.isTeachingMode) {
            this.$zxClient.UnFullScreen();
          }
          this.isShow = false;
          // this.isSSPFail=false
          setTimeout(() => {
            let len = this.rlen - window.history.length - 1;// -1是不进入iframe页面的下级页面直接退出的话，执行后退一步的操作
            console.log('back', len, window.history.length, this.rlen);
            window.history.go(len);
            console.log('go', len);
          }, 500);
          break;
        case 'coursewareVisit':
          console.log('messageData.data.data', messageData.data.data);
          this.$websocket.commonWebsocketApi(messageData.data.data);
          break;
        default:
          break;
      }
    },
    exitCT () {
      this.wvExecCommand('closeCourseware()');
    },
    initCTIframe () {
      console.log('location', location.href)
      if (this.isSSP || this.isSSPFail) {

        let initData = {};
        initData.config = {
          CDNURL: this.$store.getters.CDNURL,
          PCWebURL: location.origin,
          APIURL: this.$store.getters.APIURL,
          APIBasicUrl: this.$store.getters.APIURL + process.env.VUE_APP_WKT_API_Url
        };
        initData.token = this.$store.getters.token;
        console.log('token', initData.token);
        initData.mdInfo = this.$store.getters.SaaSClientInfo;
        initData.deviceInfo = {
          DeviceTypeKey: '2',
          DeviceTypeKeyValue: '桌面端',
          DeviceCode: this.$zxClient.GetMCode(),
          Longitude: this.$store.getters.geoInfo ? this.$store.getters.geoInfo.Longitude : '',
          Latitude: this.$store.getters.geoInfo ? this.$store.getters.geoInfo.Latitude : '',
          IsSettle: 1,
          Accuracy: this.$store.getters.geoInfo.PositioningOffset ? this.$store.getters.geoInfo.PositioningOffset : this.$route.query.Accuracy ? this.$route.query.Accuracy : ''
        };
        initData.plateform = 'PC';
        if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
          let obj = JSON.parse(window.brandCustomSettingStr);
          initData.isOpenBrand = true;
          initData.LoginBackground = obj.LoginBackground;
          initData.TeachingBackground = obj.TeachingBackground;
          initData.LogoUrl = obj.LogoUrl;
        } else {
          initData.isOpenBrand = false;
          initData.LoginBackground = '';
          initData.TeachingBackground = '';
          initData.LogoUrl = '';
          // if (this.$store.getters.SaaSClientEditionKey == 6) {
          //   obj.LogoUrl = require('../../../public/image/zhixue_logo.png');
          // } else {
          //   obj.LogoUrl = require('../../../public/image/header_logo.png');
          // }
        }
        initData.deviceInfo.Sign = md5(this.$utils.setStringmd5(initData.deviceInfo.DeviceTypeKey, initData.deviceInfo.DeviceCode, 'KJ', initData.deviceInfo.Longitude, initData.deviceInfo.Latitude));
        this.wvExecCommand('init(' + JSON.stringify(initData) + ')');
        let settings = {
          viewType: this.viewType,
          scheduleCourseID: this.scheduleCourseID,
          coursewareId: this.coursewareId,
          authCoursewareID: this.authCoursewareID,
          authorizeType: this.authorizeType,
          courseWarePackageKey: 0,
          courseWareOrder: 0,
          prepareLessonsKey: 0,
          clientId: this.clientId
        };

        this.wvExecCommand('openCT(' + JSON.stringify(settings) + ')');
      } else if (this.isSSP === null || this.isSSPFail) {
        setTimeout(() => {
          this.initCTIframe();
        }, 100);
      }
    },
    // 翻页封装
    wvExecCommand (command) {
      console.log('wvExecCommand', command);
      this.$refs.iframe.contentWindow.postMessage({
        cmd: 'evalJS',
        content: command
      }, '*');
    },
    startSSP () {
      let r = this.$zxClient.StartSSP();

      this.isSSP = r == 'OK';
      if (!this.isSSP) {
        this.sspError = r == 'NO' ? '环境异常，无法启动课件' : r;
      } else {
        if (this.sspCheckHandler) {
          clearInterval(this.sspCheckHandler);
        }
        this.sspCheckHandler = setInterval(() => {
          this.checkSSP();
        }, 30000);
      }
      console.log('StartSSP', this.isSSP, r);
      return this.isSSP;
    },
    checkSSP () {
      let r = this.$zxClient.CheckSSP();
      this.isSSP = r == 'OK';
      if (!this.isSSP) {
        this.sspError = r == 'NO' ? '环境异常，无法启动课件' : r;

        this.$zxClient.UnFullScreen();
      }
    },
    closeSSP () {
      if (this.sspCheckHandler) {
        clearInterval(this.sspCheckHandler);
        this.sspCheckHandler = null;
      }
      if (this.$zxClient.IsClient) {
        console.log('StopSSP');
        this.$zxClient.StopSSP();
      }
    }
  }
};
</script>

<style scoped>
.box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3001;
  width: 100%;
  height: 100%;
  background-color: white;
}
.ssp_tips {
  background-color: #150b35;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ssp_tips span {
  margin: auto;
  color: white;
  font-size: 32px;
  opacity: 0.6;
}
.ssp_tips_txt_ext {
  font-size: 16px !important;
  line-height: 40px;
}
.iframe_box {
  border: 0;
}
.iframe_box ::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>