<template>
  <!-- 设置表单 -->
  <div class="">
    <custom-dialog :title="'快捷启用设置'"
                   :moduleDescription="'新开系统进行门店设置的地方，所有基础设置都可以在此界面完成，这里可以选择开启或关闭在首页的入口！关闭后在门店设置首行可再次进入！'"
                   :top="120"
                   :visible.sync="showShortcutStartSetting"
                   :before-close="doHideShortcutStartForm">
      <!-- 快捷启用手动开关设置 -->
      <span class="speedy_start_switch"
            style="position: absolute;left: 300px;top: 8px;">
        <el-tooltip effect="light"
                    visible-arrow="false"
                    :content="'开启时，首页将显示快捷入口可供操作'"
                    placement="top">
          <el-switch class="pa"
                     :value="$utils.getSaaSClientSet(57)"
                     active-value="1"
                     inactive-value="0"
                     @change="doElSwitch($event)"></el-switch>
        </el-tooltip>

      </span>

      <div class="speedy_start_box">
        <!-- <div class="speedy_start_title">快捷启用系统应设置6项关键资料：</div> -->
        <div class="speedy_start_content mendian_setting_content">
          <div class="form_info_edit">
            <div class="speedy_start_list">
              <div class="list_index">1</div>
              <box-content-shortcutStart-list :dataInfoList="vCourseNameListForQuick"
                                              :powerName="'abce'"
                                              class="box_dual_type"
                                              :isComplement="true"
                                              :addButName="'设置'"
                                              :hasActive="portInitSettings.activeInfo.canActive"
                                              :formName="'SZKC'"
                                              :formTitle='"设置课程"'
                                              :ModulePowerKey="45"
                                              :showItemCount="4"
                                              @onOpreate="doOpreateForCourseName"></box-content-shortcutStart-list>
            </div>

            <div class="speedy_start_list">
              <div class="list_index">2</div>
              <box-content-shortcutStart-list :dataInfoList="vStaffListForQuick"
                                              :powerName="'abc'"
                                              class="box_dual_type"
                                              :isComplement="true"
                                              :addButName="'设置'"
                                              :hasActive="portInitSettings.activeInfo.canActive"
                                              :ModulePowerKey="44"
                                              :showItemCount="4"
                                              @onOpreate="doOpreateForStaff"
                                              :formName="'SZLS'"
                                              :formTitle='"设置老师"'></box-content-shortcutStart-list>
            </div>

            <div class="speedy_start_list">
              <div class="list_index">3</div>
              <box-content-shortcutStart-list :dataInfoList="vSquadClassForQuick"
                                              :powerName="'abce'"
                                              class="box_dual_type"
                                              :isComplement="true"
                                              :hasActive="false"
                                              :addButName="'设置'"
                                              :formName="'SZBJ'"
                                              :formTitle='"设置班级"'
                                              :ModulePowerKey="45"
                                              :showItemCount="4"
                                              @onOpreate="doOpreateForSquadClass"></box-content-shortcutStart-list>
            </div>

            <div class="speedy_start_list">
              <div class="list_index">4</div>
              <box-content-shortcutStart-list :dataInfoList="vXJKBForQuick"
                                              :powerName="'abc'"
                                              class="box_dual_type"
                                              :isComplement="true"
                                              :addButName="'设置'"
                                              :hasActive="portInitSettings.activeInfo.canActive"
                                              :ModulePowerKey="47"
                                              :formName="'JKPK'"
                                              @onOpreate="doOpreateForXJKB"
                                              :formTitle='"班级排课"'></box-content-shortcutStart-list>
            </div>
            <div class="speedy_start_list">
              <div class="list_index">5</div>
              <box-content-shortcutStart-list :dataInfoList="vLSZLForQuick"
                                              :powerName="'abc'"
                                              class="box_dual_type"
                                              :isComplement="true"
                                              :addButName="'导入'"
                                              :hasActive="portInitSettings.activeInfo.canActive"
                                              :ModulePowerKey="48"
                                              @onOpreate="doOpreateForLSZL"
                                              :formName="'DRXS'"
                                              :formTitle='"导入学生"'></box-content-shortcutStart-list>
            </div>
            <div class="speedy_start_list">
              <div class="list_index">6</div>
              <box-content-shortcutStart-list :dataInfoList="vRegisterNum"
                                              :powerName="'abc'"
                                              class="box_dual_type"
                                              :isComplement="true"
                                              :addButName="'分享'"
                                              :hasActive="portInitSettings.activeInfo.canActive"
                                              :ModulePowerKey="48"
                                              :formName="'JZZC'"
                                              @onOpreate="doOpreateForJZZC"
                                              :formTitle='"家长注册"'></box-content-shortcutStart-list>
            </div>
          </div>
        </div>
        <!-- <div class="speedy_start_tips">
          快捷启用设置完成，应每天用系统排课、签到、报名， 核对统计报表。数据越多越完善，使用越多越好用。
        </div> -->
        <div class="confirm_btn btn_fixed_bottom">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               @click.stop="doCompleteBack">完成并返回</div>
        </div>
      </div>
    </custom-dialog>

    <!-- 分享二维码页面  -->
    <custom-dialog :title="'分享二维码'"
                   :visible.sync="showshareQrcodePage"
                   :before-close="hideShareQrcodePage">
      <share-qrcode-page></share-qrcode-page>
    </custom-dialog>

  </div>
</template> 

<script>
import boxContentShortcutStartList from './box-content-shortcutStart-list';

import shareQrcodePage from './share-qrcode-page';
import {
  getUsingClass,
  MessageSearchPageForEmployee,
  getIndexReportRealtimeData,
  CourseStatisticsforScheduleCourse,
  SyncIfOpenQuickStart
} from '../../../../API/workbench';

export default {
  data () {
    return {
      showShortcutStartSetting: false, // 控制显示表单
      isShowAddOldStudentDialog: false, // 录入老生
      isNewcourseTimetable: false, // 新建课表
      shwoPushItem: { push: '', isShowMainForm: false, formTitle: '', isReadonly: false, editItem: {} }, // 显示弹出层
      toSelectItem: '', // 选择项
      toSelectItemValue: '', // 选择项名称
      readonlyInfo: false, // 是否只读

      portInitSettings: {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: '', // 启用接口
          apiUnActive: '', // 停用接口
          apiFinish: '', // 结束接口
          activeCallBack: (data) => {
            data.statusKey = 1; // 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '启用';
          },
          unActiveCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          finishCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          otherOpreateHandler: null // 其它非通用操作处理方法
        }
      },
      optionsDataID: 0,
      // courseClassList: [], // 课类列表
      courseNameList: [], // 课程列表
      staffList: [], // 员工列表
      squadClass: [], // 班级列表
      taocanList: [], // 套餐列表
      courseTimetableList: [], // 课表列表
      courseTimetableSum: '', // 课表总节数
      shortcutInfo: {},
      // 查询员工
      staffParameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      },
      // 查询套餐
      comboParameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
        StatusKey: 0 //  字符串	可选	0	数据源：GET	套餐状态0-全部;2-启用;3-停用
      },
      showshareQrcodePage: false// 显示分享二维码页面
    };
  },
  props: [

  ],
  components: {
    shareQrcodePage,
    boxContentShortcutStartList
  },

  computed: {
    vCourseNameListForQuick () { // 课程
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.courseNameList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StateKey == 2, data: o, isMenu: false, isMenuShow: true, unActive: '停用', active: '启用' };
          list.push(item);
        });
      }
      return list;
    },
    vSquadClassForQuick () { // 班级
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.squadClass.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StutasKey == 4, data: o, isMenu: false, isMenuShow: true, finish: '结束' };
          list.push(item);
        });
      }
      return list;
    },
    vStaffListForQuick () { // 员工
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.staffList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.IsLeaveKey == 1, data: o, isMenu: false, isMenuShow: true, unActive: '离职', active: '复职' };
          list.push(item);
        });
      }
      return list;
    },
    vLSZLForQuick () { // 老生
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      let num = Number(this.shortcutInfo.EnrolledNum);// 导入学生数量
      if (this.portInitSettings.lastRefreshTime != '') {
        for (let i = 1; i < num + 1; i++) {
          let item = { dataKey: -1, itemName: '无', isActive: true, data: {}, isMenu: false, isMenuShow: false };
          list.push(item);
        }
      }
      return list;
    },
    vXJKBForQuick () { // 课表
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      // 不显示明细
      if (this.portInitSettings.lastRefreshTime != '') {
        console.log(this.courseTimetableList, '课表');
        this.courseTimetableList.forEach(o => {
          let item = { dataKey: o.CourseNameKey, itemName: o.CourseNameKeyValue + '(' + (Number(o.CourseNum) || 0) + '节)', courseTimetableSum: this.courseTimetableSum, isActive: true, data: o, isMenuShow: false };
          list.push(item);
        });
      }
      return list;
    },
    vRegisterNum () { // 注册
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      let num = Number(this.shortcutInfo.RegisterNum);// 已注册人数
      if (this.portInitSettings.lastRefreshTime != '') {
        for (let i = 1; i < num + 1; i++) {
          let item = { dataKey: -1, itemName: '无', isActive: true, data: {}, isMenu: false, isMenuShow: false };
          list.push(item);
        }
      }
      return list;
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  created () {
    this.registerBusEvent();
    this.loadData();
  },
  mounted () {

  },
  methods: {
    // 快捷启用手动开关设置
    doElSwitch (type) {
      console.log(type, '快捷启用手动开关设置');
      SyncIfOpenQuickStart(type).then(result => {
        console.log(result.data);
        let item57 = {
          SetKey: type,
          SetKeyValue: type == 0 ? '否' : '是',
          SetContent: '',
          SetTypeKey: 57
        };
        this.$store.getters.SaaSClientInfo.SaaSClientSetting = this.$utils.setSaaSClientSet(57, item57);
        this.$emit('doElSwitch', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭快捷启用窗口
    doHideShortcutStartForm () {
      this.showShortcutStartSetting = false;
    },
    // 返回操作
    doCompleteBack () {
      this.doHideShortcutStartForm();
    },
    // 刷新数据
    doCloseShowFormUpdata () {
      this.loadData();
    },
    // 刷新课类/课名
    upCourseAndClassListData (item) {
      if (item.OLAPKey) {
        let findItem = this.courseNameList.find(o => { // 找到该项
          return Number(o.OLAPKey) == Number(item.OLAPKey);
        });
        if (findItem && findItem.OLAPKey) {
          if (Number(findItem.OLAPKey) == Number(item.OLAPKey)) {
            Object.assign(findItem, item);// 修改
          }
        } else {
          this.courseNameList.unshift(item);// 添加
        }
      } else {
        console.log(item, '快捷启用');
        this.loadData();
      }
    },
    // 班级列表
    getUsingClass () {
      getUsingClass().then(result => {
        this.squadClass = [];
        this.squadClass = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 员工列表
    getMessageSearchPageForEmployee () {
      MessageSearchPageForEmployee(this.staffParameter).then(result => {
        this.staffList = [];
        this.staffList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 返回当前门店的即时业务数据
    getIndexReportRealtimeData () {
      getIndexReportRealtimeData().then(result => {
        console.log(result.data, 'getRealtimeData:result');
        this.shortcutInfo = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 新建课表
    getCourseStatisticsforScheduleCourse () {
      CourseStatisticsforScheduleCourse().then(result => {
        this.courseTimetableList = [];
        console.log(result.data, 'ScheduleCourseList');
        this.courseTimetableSum = result.data.ScheduleCourseCount;
        this.courseTimetableList = result.data.ScheduleCourseList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    async loadData () {
      // ToDo 获取数据
      this.portInitSettings.lastRefreshTime = Date.new();
      let result = this.$utils.parseJson(await this.$basicData.loadCourseNameList());
      this.courseNameList = [];
      if (result && result.length > 0) {
        result.forEach(o => {
          if (o.TypeKey != 1) {
            this.courseNameList.push(o);
          }
        });
      }
      // 班级列表
      this.getUsingClass();
      // 员工列表
      this.getMessageSearchPageForEmployee();
      // 返回当前门店的即时业务数据
      this.getIndexReportRealtimeData();
      // 新建课表
      this.getCourseStatisticsforScheduleCourse();
    },
    // 执行课名相关操作
    doOpreateForCourseName (opreateType, dataKey, data, callBack) {
      let item = {
        name: '课程课类管理',
        routerName: this.$route.name,
        routertitle: this.$route.name == 'indexView' ? '工作台' : '门店设置',
        auth: true,
        roleName: '',
        moduleName: 'courseClassManageSetting',
        ModulePowerKey: ''
      };
      this.$bus.emit('needOpenRouter', item);
    },
    // 执行员工相关操作
    doOpreateForStaff (opreateType, dataKey, data, callBack) {
      let item = {
        name: '员工管理',
        routerName: this.$route.name,
        routertitle: this.$route.name == 'indexView' ? '工作台' : '门店设置',
        auth: true,
        roleName: '',
        moduleName: 'staffManagementList',
        ModulePowerKey: 44,
        callBack: { cancelClick: () => { console.log('资料回调'); this.getMessageSearchPageForEmployee(); } } // 新增或修改员工后触发回调
      };
      this.$bus.emit('needOpenRouter', item);
    },
    // 执行班级相关操作
    doOpreateForSquadClass (opreateType, dataKey, data, callBack) {
      let item = {
        name: '班级设置',
        routerName: this.$route.name,
        routertitle: this.$route.name == 'indexView' ? '工作台' : '门店设置',
        auth: true,
        roleName: '',
        moduleName: 'gradeClassManageSetting',
        ModulePowerKey: 45
      };
      this.$bus.emit('needOpenRouter', item);
    },
    // 执行导入学生点击相关操作
    doOpreateForLSZL (opreateType, dataKey, data, callBack) {
      let item = {};
      if (opreateType == 'add') {
        item = {
          name: '批量录入老生',
          routerName: this.$route.name,
          routertitle: this.$route.name == 'indexView' ? '工作台' : '门店设置',
          auth: true,
          roleName: '',
          moduleName: 'batchAddOldStudent',
          ModulePowerKey: 49
        };
      } else if (opreateType == 'view') {
        item = {
          name: '学生管理',
          auth: true,
          routerName: 'studentManagementReport',
          routertitle: '学生管理',
          ModulePowerKey: 48,
          data: {
            headSummaryData: { title: '在校学生' }     // 在校学生
          }
        };
      }
      this.$bus.emit('needOpenRouter', item);
    },
    // 建课排课  到 排课管理 （精准版 到课程表）
    doOpreateForXJKB (opreateType, dataKey, data, callBack) {
      let item = {};
      console.log(opreateType, dataKey, data, 'opreateType, dataKey, data');
      item = {
        ModulePowerKey: 53,
        name: '班级排课',
        auth: true,
        routerName: 'classGradeApplycourse',
        routertitle: '班级排课'
      };
      // if (this.vIsSysVersionTY) { // 通用版:3
      //   item = {
      //     ModulePowerKey: 53,
      //     name: '排课管理',
      //     auth: true,
      //     routerName: 'newCourseArrangement',
      //     routertitle: '排课管理'
      //   };
      // } else {
      //   item = {
      //     ModulePowerKey: 146,
      //     auth: true,
      //     name: '课程表',
      //     roleName: '',
      //     routerName: 'CourseTable',
      //     routertitle: '课程表'
      //   };
      // }
      this.$bus.emit('needOpenRouter', item);
    },
    // 家长注册 到 在校学生（按 已关注 过滤）
    doOpreateForJZZC (opreateType, dataKey, data, callBack) {
      console.log(opreateType, 'opreateType');
      if (opreateType == 'add') {
        this.showshareQrcodePage = true;
      } else if (opreateType == 'view') {
        let item = {
          name: '学生管理',
          auth: true,
          routerName: 'studentManagementReport',
          routertitle: '学生管理',
          ModulePowerKey: 48,
          data: {
            headSummaryData: { title: '已关注' }     // 已关注
          }
        };
        this.$bus.emit('needOpenRouter', item);
      }
    },
    hideShareQrcodePage () {
      this.showshareQrcodePage = false;
    },
    // 新建单节课
    doAddSingleCourseClick () {
      let item = {
        name: '新建课表',
        routerName: this.$route.name,
        routertitle: '课程表',
        moduleName: 'newCircleCourseTable'
      };
      this.$bus.emit('needOpenRouter', item);
      this.isNewcourseTimetable = false;
    },
    // 新建循环课
    doAddCirculationCourseClick () {
      let item = {
        name: '新建课表',
        routerName: this.$route.name,
        routertitle: '课程表',
        moduleName: 'newCircleCourseTable'
      };
      this.$bus.emit('needOpenRouter', item);
      this.isNewcourseTimetable = false;
    },
    // 关闭建立课表弹窗
    closeNewcourseTimetable () {
      this.isNewcourseTimetable = false;
    },
    registerBusEvent () {
      // 课程课类管理=》 新增或修改，停用,启用后的刷新操作
      this.$bus.on(this, 'CourseClassNameAddOrEdit', (data) => {
        this.upCourseAndClassListData(data);
      });
      // 修改班级后的回调
      this.$bus.on(this, 'AfterClassGradeAddOrEdit', (type, result) => {
        if (type !== 'Edit') {
          this.getUsingClass();
        }
      });
      this.$bus.on(this, 'AfterBatchAddOldStudent', () => {
        getIndexReportRealtimeData().then(result => {
          this.shortcutInfo = result.data;
        });
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'studentManagementReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classGradeApplycourseDialogFactory) {
        this.$refs.classGradeApplycourseDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  }

};
</script>

  <style>
/*  */
</style>