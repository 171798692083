<template>
  <div class="details_left"
       v-if="BigCoursewarePackageInfo">
    <div class="head_div_left">
      <img :src="headImg"
           :onerror="defaultImg"
           width="100%"
           height="100%"
           style="border-radius: 8px"
           alt="../../../../public/image/no_logo@2x.png" />
    </div>
    <div class="coursePackage_info_bar">
      <div class="c_i_bar"
           :class="courseIndex==1?'bar_opt':''"
           @click="courseInfoBtn(1)">授权详情</div>
      <div class="c_i_bar course_introduction"
           :class="courseIndex==2?'bar_opt':''"
           @click="courseInfoBtn(2)">课程介绍</div>
      <div class="c_i_bar annex_download"
           :class="courseIndex==3?'bar_opt':''"
           @click="courseInfoBtn(3)">附件下载</div>
      <div class="c_i_bar parent_classroom"
           :class="courseIndex==4?'bar_opt':''"
           @click="courseInfoBtn(4)">家长课堂</div>
      <div class="c_i_bar cover_download"
           :class="courseIndex==5?'bar_opt':''"
           @click="courseInfoBtn(5)">封面下载</div>
    </div>
    <div v-if="courseIndex==1">
      <div class="head_div_dwon">
        <!-- <span class="left_box_list_span"
            :title="BigCoursewarePackageInfo.MainDemoName">{{ BigCoursewarePackageInfo.MainDemoName }}</span>
      <div class="left_box_list_div">
        <span class="left_box_list_two_span">课件数：
          <span style="color: #333">{{
            BigCoursewarePackageInfo.CoursewareNum
              ? BigCoursewarePackageInfo.CoursewareNum
              : BigCoursewarePackageInfo.CoursewareCount
          }}</span>
        </span>
      </div>
      <div class="left_box_list_div"
           v-if="this.$zxClient.IsClient">
        <span class="left_box_list_two_span">当前设备：</span>
        <span v-if="BigCoursewarePackageInfo.IsPCAuthKey=='0'"
              class="device_auth_no">禁用</span>
        <span v-else
              :class="
            Number(BigCoursewarePackageInfo.DeviceIsAuth) > 0
              ? 'device_auth_ok'
              : 'device_auth_no'
          ">
          {{
            Number(BigCoursewarePackageInfo.DeviceIsAuth) > 0
              ? "已授权"
              : "未授权"
          }}
        </span>
      </div>
      <div class="left_box_list_div"
           v-else>
        <span class="left_box_list_two_span">当前设备：
          <span class="device_auth_no1">非桌面端</span>
        </span>
      </div> -->

        <div class="head_div_dwon">
          <span class="left_box_list_span"
                :title="BigCoursewarePackageInfo.MainDemoName">{{BigCoursewarePackageInfo.MainDemoName}}</span>
          <div class="left_box_list_div"
               v-if="vIsAuthorize">
            <span class="left_box_list_two_span">授权方：
              <span style="color:#333">{{BigCoursewarePackageInfo.ClientName?BigCoursewarePackageInfo.ClientName:BigCoursewarePackageInfo.AuthorizedParty}}</span>
            </span>
          </div>
          <div class="left_box_list_div"
               v-if="infoType == 1&&vIsAuthorize">
            <span class="left_box_list_two_span">协议时长：
              <span class="font_black"
                    v-if="BigCoursewarePackageInfo.ClearingFormKeyValue == '永久授权'">{{BigCoursewarePackageInfo.AuthorizeTime}} ~ 不限</span>
              <span class="font_black"
                    v-else-if="BigCoursewarePackageInfo.ExpireTime">{{BigCoursewarePackageInfo.AuthorizeTime}} ~ {{ BigCoursewarePackageInfo.ExpireTime }}</span>
              <span class="font_black"
                    v-else>{{BigCoursewarePackageInfo.AuthorizeTime}}</span>
            </span>
          </div>
          <div class="left_box_list_div"
               v-if="infoType == 1&&vIsAuthorize">
            <span class="left_box_list_two_span">剩余天数：
              <span style="color:#333">{{vAuthorizeTips}}</span>
            </span>
          </div>
          <div class="device_support_info">
            <div class="list_content"><span class="list_span"
                    style="color: #999;">设备支持：</span>
              <span class="support_span">电脑</span>
              <span class="support_span">手机</span>
              <span class="support_span">电视</span>
            </div>
            <div class="left_box_list_div"
                 v-if="this.$zxClient.IsClient"
                 style="margin-left: 10px;margin-top: 5px;">
              <span class="left_box_list_two_span">当前设备：</span>
              <span v-if="this.BigCoursewarePackageInfo.IsPCAuthKey == '0'"
                    class="device_auth_no"> 禁用</span>
              <span v-else
                    :class="Number(BigCoursewarePackageInfo.DeviceIsAuth)>0?'device_auth_ok':'device_auth_no'">
                {{Number(BigCoursewarePackageInfo.DeviceIsAuth)>0?'已授权':'未授权'}}
              </span>

              <span class="auBtn"
                    v-if='!Number( BigCoursewarePackageInfo.DeviceIsAuth ) > 0 && ( infoType == 2 || infoType == 1 )&&this.BigCoursewarePackageInfo.IsPCAuthKey=="1"'>
                <div type="primary"
                     class="button_right_box btn_hover_bg_blue"
                     @click="goAuthor">授权本设备</div>
              </span>
            </div>
            <div class="left_box_list_div"
                 style="margin-left: 10px;"
                 v-else>
              <span class="left_box_list_two_span">当前设备：
                <span class="device_auth_no1">非桌面端</span>
              </span>
            </div>
          </div>
          <div class="list_content"
               style="color: #999;    margin-top: 4px;"><span class="list_span">安全支持：</span>
            <span class="support_span">防录屏截屏</span>
            <span class="support_span">防复制下载</span>
            <span class="support_span">位置锁定</span>
          </div>
          <!-- <div class="left_box_list_div"
           v-if="this.$zxClient.IsClient">
        <span class="left_box_list_two_span">当前设备：</span>
        <span v-if="this.BigCoursewarePackageInfo.IsPCAuthKey == '0'"
              class="device_auth_no"> 禁用</span>
        <span v-else
              :class="Number(BigCoursewarePackageInfo.DeviceIsAuth)>0?'device_auth_ok':'device_auth_no'">
          {{Number(BigCoursewarePackageInfo.DeviceIsAuth)>0?'已授权':'未授权'}}
        </span>

        <span class="auBtn"
              v-if='!Number( BigCoursewarePackageInfo.DeviceIsAuth ) > 0 && ( infoType == 2 || infoType == 1 )&&this.BigCoursewarePackageInfo.IsPCAuthKey=="1"'>
          <div type="primary"
               class="button_right_box btn_hover_bg_blue"
               @click="goAuthor">申请授权</div>
        </span>
      </div> -->
          <!-- <div class="left_box_list_div"
        style="margin-left: 10px;"
           v-else>
        <span class="left_box_list_two_span">当前设备：
          <span class="device_auth_no1">非桌面端</span>
        </span>
      </div> -->

        </div>
      </div>
      <div class="box_control">
        <div class="device_control">设备控制</div>
        <div class="device_control_list">
          <iconAuthorization v-for="(item,index) in vCourseAuthList"
                             :key="index"
                             :courseAuth="item"
                             :modelType="'B'"
                             :isShowBeforLine="true"
                             :minWidth="true"
                             @doDeviceCount="doDeviceCount"></iconAuthorization>
        </div>
      </div>
      <div class="line_down"></div>
      <div type="primary"
           v-if="
        Number(BigCoursewarePackageInfo.DeviceIsAuth) == 0 &&
        this.$zxClient.IsClient&&this.BigCoursewarePackageInfo.IsPCAuthKey == '1'
      "
           class="button_right_box btn_hover_bg_blue btn_down_btn"
           @click="goAuthorOfTeacher">
        申请授权
      </div>
    </div>
    <div class="course_left_bar"
         v-if="courseIndex!=1">
      <course-left-bar :courseIndex="courseIndex"
                       :BigCoursewarePackageInfo="BigCoursewarePackageInfo"
                       :dataInfo="dataInfo"
                       :Source="dataInfo.Source"
                       ref="courseRef"></course-left-bar>
    </div>
  </div>
</template>

<script>
import {
  GetCoursewarePackageDetailsForTeacher,
  CWSAuthorizedDeviceManagementAuthorizedTheDevice,
  AddInterfaceLog
} from '../../../API/workbench';
import courseLeftBar from '../../../components/courseware-work-bench/components/big-courseware-details/course-left-bar';
import iconAuthorization from './icon-authorization.vue';
export default {
  data () {
    return {
      courseIndex: 1,
      infoType: 0, // 1课件详情 2 校长 3 老师
      BigCoursewarePackageInfo: [] // 大课包信息
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          AuthKey: null, // 授权key(校长工作台课件-必填)
          TeacherKey: null, // 老师key(校长/老师工作台-必填)
          BigCoursewarePackageKey: null, // 大课包ID(校长/老师工作台-必填)
          TeacherName: null, // 老师name(校长/老师工作台-必填)
          Source: '1'
        };
      }
    }
  },
  components: {
    iconAuthorization,
    courseLeftBar
  },
  computed: {
    vSaaSClientEditionKey () {
      if ((this.$store.getters.SaaSClientEditionKey == 2 || this.$store.getters.SaaSClientEditionKey == 3 || this.$store.getters.SaaSClientEditionKey == 6)) { // 教务门店
        return true;
      } else {
        return false;
      }
    },
    vDeviceCountClass () {
      if (this.vSaaSClientEditionKey || this.$store.getters.SaaSClientEditionKey == 6) { // 教务门店
        return true;
      } else {
        return false;
      }
    },
    headImg () {
      if (this.BigCoursewarePackageInfo && this.BigCoursewarePackageInfo.CoverUrl) {
        if (this.BigCoursewarePackageInfo.CoverUrl.indexOf('http') > -1) {
          return this.BigCoursewarePackageInfo.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.BigCoursewarePackageInfo.CoverUrl;
        }
      } else {
        return '';
      }
    },
    defaultImg () {
      return (
        'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"'
      );
    },
    vIsAcademicMd () {
      if (this.$store.getters.SaaSClientEditionKey == 2 || this.$store.getters.SaaSClientEditionKey == 3 || this.$store.getters.SaaSClientEditionKey == 6) {
        return true;
      } else {
        return false;
      }
    },
    vIsAuthorize () {
      if (this.vIsAcademicMd) {
        return this.$store.getters.AcademicMdCwType == 1;// 授权
      } else {
        return true;
      }
    },
    vCourseAuthList () {
      let data = this.BigCoursewarePackageInfo;
      let arr = [];
      if (!this.BigCoursewarePackageInfo) {
        return arr;
      }
      let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.PCDeviceAuthCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVDeviceAuthCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPDeviceAuthCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      arr.push(itemPC);
      arr.push(itemAPP);
      arr.push(itemTV);
      return arr;
    }
  },
  created () {
    this.getBigCoursewarePackageInfo();
  },
  mounted () { },
  methods: {
    courseInfoBtn (type) {
      this.courseIndex = type;
      console.log(this.courseIndex, 'courseIndex');
      if (type != 1) {
        setTimeout(() => {
          this.$refs.courseRef.searchCourseinfo(type);
        }, 500);
      }
    },
    // 授权老师
    doAuthorizeTeacher () {
      this.$dialog.open(this, {
        name: '授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthCw',
        data: {
          coursewareReadonly: true,
          teacherReadonly: false,
          dataInfo: {
            BigCoursewarePackageKey: this.BigCoursewarePackageInfo.OLAPKey,
            BigCoursewarePackageKeyValue:
              this.BigCoursewarePackageInfo.MainDemoName,
            TeacherKey: this.$store.getters.token.UserID,
            TeacherKeyValue: this.$store.getters.token.UserName
          }
        },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, 'data');
            this.getBigCoursewarePackageInfo();
            this.$emit('afterSuccess');
            // this.$emit('closeDialog')
          },
          cancelSuccess: () => {
            this.$emit('closeDialog');
            // this.$emit('afterSuccess')
          }
        }
      });
    },
    // 变更老师授权
    openTeacherAuthForm () {
      console.log(
        'this.BigCoursewarePackageInfo',
        this.BigCoursewarePackageInfo
      );
      this.$dialog.open(this, {
        name: '授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthCw',

        data: {
          teacherReadonly: true,
          coursewareReadonly: true,
          dataInfo: {
            BigCoursewarePackageKey: this.BigCoursewarePackageInfo.OLAPKey,
            BigCoursewarePackageKeyValue:
              this.BigCoursewarePackageInfo.MainDemoName,
            TeacherKey: this.dataInfo.TeacherKey,
            TeacherKeyValue: this.dataInfo.TeacherName
          }
        },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, 'data');
            this.getBigCoursewarePackageInfo();
            this.$emit('afterSuccess');
            // this.$emit('closeDialog')
          },
          cancelSuccess: () => {
            this.$emit('closeDialog');
            // this.$emit('afterSuccess')
          }
        }
      });
    },
    // 授权本设备
    goAuthor () {
      if (this.$zxClient) {
        if (this.$zxClient.GetMInfo() === '{}') {
          layer.alert('设备信息为空');
          let interfaceLogData = {
            InterfaceName: this.$store.getters.APIURL + 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice',
            parameter: { BigCoursewarePackageKey: this.BigCoursewarePackageInfo.OLAPKey },
            PostData: JSON.stringify(this.$store.getters.token),
            LogContents: '设备信息为空',
            LogTitleName: '设备信息为空',
            RefererName: window.location.href
          };
          AddInterfaceLog(interfaceLogData).then(result => {
            console.log('记录日志');
          });
        } else {
          let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
          // var DeviceCode = '340BC40DC769411B'
          CWSAuthorizedDeviceManagementAuthorizedTheDevice(
            DeviceInfo,
            this.BigCoursewarePackageInfo.OLAPKey
          ).then(
            (res) => {
              this.$zxClient.SetUCode(res.data.DeviceUCode);
              layer.alert('授权成功');
              this.getBigCoursewarePackageInfo();
              this.$emit('afterSuccess');
            },
            (err) => {
              layer.alert(err.msg);
            }
          );
        }
      } else {
        layer.alert('请使用桌面端登录');
      }
    },
    // 设备授权
    goAuthorOfTeacher () {
      var DeviceCode = this.$zxClient.GetMCode();
      // var DeviceCode = '340BC40DC769411B'
      this.$dialog.open(this, {
        name: '申请设备授权',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsApplyAuth',
        data: {
          dataInfo: {
            CoursewareKey: this.BigCoursewarePackageInfo.OLAPKey,
            DeviceCode: DeviceCode
          }
        },
        callBack: {
          afterSuccess: (data) => {
            this.getBigCoursewarePackageInfo();
            this.$emit('afterSuccess');
          }
        }
      });
      // this.$dialog.open(this, {
      //     name: '设备授权',
      //     routerName: this.$route.name,
      //     moduleName: 'cwsTeacherAuthList',
      //     routertitle: '工作台',
      //     data: {dataInfo:{
      //       isTeacher: isTeacherInto,
      //     // EndTime: this.params.EndTime,
      //     BigCoursewarePackageKey: this.BigCoursewarePackageInfo.OLAPKey,
      //     BigCoursewarePackageKeyValue: this.BigCoursewarePackageInfo.MainDemoName
      //     }},
      //      callBack: {
      //           afterSuccess: (data) => {
      //               this.getBigCoursewarePackageInfo()
      //               this.$emit('afterSuccess')
      //             }
      //         }
      //   });
    },
    // 获取大课包详情
    getBigCoursewarePackageInfo () {
      console.log(111111111111111111111111);
      if (this.$zxClient.IsClient) {
        var DeviceCode = this.$zxClient.GetMCode();
      } else {
        var DeviceCode = '';
      }
      // var DeviceCode = "A3151608D2C760AC";
      GetCoursewarePackageDetailsForTeacher(
        DeviceCode,
        this.dataInfo.BigCoursewarePackageKey
      ).then(
        (result) => {
          this.BigCoursewarePackageInfo = result.data;
          console.log(result.data, '获取指定大课包详情');
          console.log(this.BigCoursewarePackageInfo.length, 'isTrue');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    doDeviceCount (type) {
      console.log(type, 'tttype', this.BigCoursewarePackageInfo, this.dataInfo);
      if (this.vDeviceCountClass) {
        let opneModuleName = this.vSaaSClientEditionKey ? 'authManagerList' : 'cwsAuthManagerList';
        this.$dialog.open(this, {
          name: '授权设备',
          routerName: this.$route.name,
          routertitle: '工作台',
          ModulePowerKey: '',
          moduleName: opneModuleName,
          data: {
            dataInfo: {
              CoursewarePackageKey: this.dataInfo.BigCoursewarePackageKey,
              CoursewarePackageKeyValue: this.dataInfo.MainDemoName,
              type: type
            }
          }
        });
      }
    }
  }
};
</script>

<style scoped>
/* .text_overflow{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
} */
/*  */

.details_left {
  position: relative;
  width: 436px;
  height: 690px;
  background: #ffffff;
  border-radius: 8px;
  margin: 18px;
}
.head_div_left {
  width: 400px;
  height: 236px;
  border-radius: 8px;
  position: relative;
  float: left;
  margin: 20px 20px;
}
.head_div_left_text {
  position: absolute;
  width: 300px;
  height: 29px;
  bottom: 0px;
  background: linear-gradient(0deg, #000000, #000000);
  opacity: 0.3;
  border-radius: 0px 0px 8px 8px;
}
.left_box_list_span {
  display: block;
  height: 22px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  width: 300px;
}
.head_div_dwon {
  width: 400px;
  margin-left: 20px;
}
.left_box_list_div {
  margin-top: 8px;
}
.left_box_list_two_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #999999;
}
.device_auth_no1 {
  width: 60px;
  height: 20px;
  background: rgba(255, 108, 103, 0.2);
  border: 1px solid #ff6c67;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #ff6c67;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 3px;
  display: inline-table;
}
.device_auth_ok {
  width: 47px;
  height: 20px;
  background: rgba(209, 248, 230);
  border: 1px solid #1cce75;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #1cce75;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 3px;
  display: inline-table;
}
.device_auth_no {
  width: 47px;
  height: 20px;
  background: rgba(255, 108, 103, 0.2);
  border: 1px solid #ff6c67;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #ff6c67;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 3px;
  display: inline-table;
}
.auBtn {
  width: 76px;
  height: 30px;
  display: block;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  float: right;
  text-align: center;
  overflow: hidden;
  margin-left: 10px;
  margin-top: -6px;
}
/* .text_overflow{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
} */
.line_down {
  position: absolute;
  width: 400px;
  height: 1px;
  left: 20px;
  bottom: 60px;
  background: #ececec;
}
.btn_down_btn {
  position: absolute;
  width: 400px;
  height: 40px;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  float: right;
  text-align: center;
  overflow: hidden;
  align-items: center;
  bottom: 10px;
  left: 20px;
  background: linear-gradient(225deg, #ff6000 0%, #ff8900 100%);
  border: none;
}
.btn_down_btn:hover {
  background: linear-gradient(225.5deg, #ff6000, #ff8900), #000;
}
.box_control {
  width: 300px;
  position: absolute;
  bottom: 70px;
  left: 20px;
}
.device_control {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  align-items: center;

  color: #333333;
}
.device_control_list {
  display: flex;
  flex-direction: row;
  width: 400px;
  line-height: 20px;
  background: #f9f9fb;
  border-radius: 4px;
  justify-content: space-between;
  padding: 10px 0;
}
.device_support_info {
  display: flex;
}
.list_content {
  margin-top: 8px;
}
.support_span {
  border-radius: 2px;
  padding: 0 4px;
  font-weight: 400;
  background: rgba(217, 223, 228, 0.4);
  color: rgba(153, 153, 153, 1);
  margin-right: 2px;
  font-size: 12px;
}
.btn_hover_bg_blue {
  background: linear-gradient(233deg, #ff6000 0%, #ff8900 100%);
  border: none;
  border-radius: 4px;
}
.btn_hover_bg_blue:hover {
  background: linear-gradient(225.5deg, #ff6000, #ff8900), #000;
}
.coursePackage_info_bar {
  justify-content: space-evenly;
  width: 400px;
  display: flex;
  margin-bottom: 10px;
  border-radius: 45.455px;
  background: rgba(255, 96, 0, 0.1);
  height: 39.091px;
  margin-left: 20px;
}
.c_i_bar {
  width: 79.091px;
  height: 34.545px;
  border-radius: 45.455px;
  line-height: 34.545px;
  text-align: center;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
  margin-top: 2px;
  margin-left: 2px;
  cursor: pointer;
}
.bar_opt {
  background: linear-gradient(225deg, #ff6000 0%, #ff8900 100%), #000;
  color: #fff;
}
.course_left_bar .course_content_left {
  height: 420px !important;
  width: 440px !important;
}
</style>
