<template>
  <div>
    <div class="table_ul_nav flex">
      <div class="title_list"
           v-for="(item,key) in optionList"
           :key="key"
           :class="searchType==item.type?'opt':''"
           @click.stop="changeType(item.type)">
        {{item.name}}
      </div>
    </div>
    <div v-if="searchType==1">
      <apply-report :searchParams="searchParams"></apply-report>
    </div>
    <div v-if="searchType==2">
      <course-sell-detail></course-sell-detail>
    </div>
    <div v-if="searchType==3">
      <incidentals-detail></incidentals-detail>
    </div>
    <div v-if="searchType==4">
      <debt-detail></debt-detail>
    </div>
    <div v-if="searchType==5">
      <repayment-detail></repayment-detail>
    </div>
    <div v-if="searchType==6">
      <activity-apply-detail></activity-apply-detail>
    </div>
  </div>
</template>
<script>
import applyReport from './apply-component/apply-report';
import courseSellDetail from './apply-component/course-sell-detail';
import debtDetail from './apply-component/debt-detail';
import repaymentDetail from './apply-component/repayment-detail';
import activityApplyDetail from './apply-component/activity-apply-detail';
import incidentalsDetail from './apply-component/incidentals-detail';
// import { MessageSearchInComePayReportForKDSY } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchType: 1,
      optionList: [
        { name: '报名统计', type: 1 },
        { name: '课程销售明细', type: 2 },
        { name: '杂费销售明细', type: 3 },
        { name: '当前欠款', type: 4 },
        { name: '还款记录', type: 5 },
        { name: '活动报名统计', type: 6 }
      ]
    };
  },
  components: {
    applyReport,
    courseSellDetail,
    incidentalsDetail,
    debtDetail,
    repaymentDetail,
    activityApplyDetail
  },
  props: {
    searchParams: Object
  },
  created () {

  },
  methods: {
    changeType (type) {
      this.searchType = type;
    }
  }

};
</script>