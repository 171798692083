<template>
  <div class="package_complex_box">
    <div class="box_name">
      <span>{{item.ProjectKeyValue}}</span>
      <div class="package_list_name_label" v-if="vDiscount">{{vDiscount}}</div>
    </div>
    <div class="box_text">
      {{vType}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  computed: {
    vDiscount () {
      let str = '';
      if (Number(this.item.AfterDisAmount) == 0) {
        str = '赠送';
      }
      //  else if (Number(this.item.AfterDisAmount) < Number(this.item.UnitPrice)) {
      //   str = this.$utils.mMoneyType(Number(this.item.AfterDisAmount) / Number(this.item.UnitPrice), 1) + '折';
      // }
      // else {
      //   str = '';
      // }
      return str;
    },
    vType () {
      // return Number(this.item.UnitPrice) + '元×' + Number(this.item.NumCount) + this.item.UnitName;
      return Number(this.item.NumCount) + this.item.UnitName;
    }
  }
};
</script>

<style>

</style>