<template>
  <!-- 失效授权 -->
  <div class="title_package">
    <div class="useAuth">
        <tableListCommon
              :plan="'B'"
              :UseStatusKey="2"
            ></tableListCommon>
  </div>
  
  </div>
</template>
<script>
import tableListCommon from '../courseware-package/table-list-common.vue'
export default {
  props:{
  },
  data () {
    return {
    };
  },
  components: {
    tableListCommon
  },
  created () {
      // this.registerBusEvent()
  },
  mounted () {
      
  },
  methods: {
    // registerBusEvent () {
    //   this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
    //     console.log('openCustomDialog', dialogInfo);
    //     if (dialogInfo.routerName == 'campusPrepareLessonsExamine') {
    //       this.openCustomDialogByFactoryDialog(dialogInfo);
    //     }
    //   });
    // },
    // openCustomDialogByFactoryDialog (dialogInfo) {
    //   if (this.$refs.campusPrepareLessonsExamine) {
    //     this.$refs.campusPrepareLessonsExamine.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
    //   }
    // },
  },
  computed: {}
};
</script>

<style scoped>
.title_package{
    position: relative;
    top : 20px;
    border-radius: 8px;
    overflow: hidden;
}
.useAuth{
  padding-top: 20px;
  background: #fff;
}
.prepare_lesson_module {
  position: relative;
  width: 1208px;
  /* margin: 0 auto; */
  /* margin: 20px 50px 50px 50px; */
}
.prepare_lesson_module .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.prepare_lesson_module .table_nav_box {
  background-color: #fff;
}
.prepare_lesson_module .table_fixed_nav_list {
  padding: 0px 35px;
}
.prepare_lesson_module .table_select_box {
  margin-right: 10px;
}
.prepare_lesson_module .course_statistics_title {
  padding: 0;
}
.prepare_lesson_module .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.prepare_lesson_module .table_select_box .el-select {
  width: 200px;
}
.prepare_lesson_module
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}
.buttom_sq{
    display: block;
    width: 76px;    
    height: 35px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    top: 6px;
}
</style>

