<template>
  <div class="margin_left_right_div">
    <div class="upsell_management_view all_view template_minOrmax_width_div">
      <div class="upsell_management_top box_shadow_border_div">
        <div class="upsell_management_top_list">
          <div class="list_date">昨天</div>
          <div class="list_content"
               @click.stop="goRouter('applyRecord','yesterDay')">
            <div class="font_number">{{Number(dataInfo.ApplyNumAndMoneySum.yesterdayNum||0)}}/{{Number(Number(dataInfo.ApplyNumAndMoneySum.yesterdaySum||0).toFixed(2))}}</div>
            <div class="font_gray">报名人数/金额</div>
          </div>
          <div class="list_bottom">
            <div class="list_bottom_number">
              <span class="font_gray">新线索</span>
              <span>{{Number(dataInfo.ThreadNum.yesterdayNum||0)}}</span>
            </div>
            <div class="list_bottom_number">
              <span class="font_gray">跟单</span>
              <span>{{Number(dataInfo.FollowNum.yesterdayNum||0)}}</span>
            </div>
          </div>
        </div>
        <div class="upsell_management_top_list">
          <div class="list_date">本周</div>
          <div class="list_content"
               @click.stop="goRouter('applyRecord','week')">
            <div class="font_number">{{Number(dataInfo.ApplyNumAndMoneySum.weekNum||0)}}/{{Number(Number(dataInfo.ApplyNumAndMoneySum.weekSum||0).toFixed(2))}}</div>
            <div class="font_gray">报名人数/金额</div>
          </div>
          <div class="list_bottom">
            <div class="list_bottom_number">
              <span class="font_gray">新线索</span>
              <span>{{Number(dataInfo.ThreadNum.weekNum||0)}}</span>
            </div>
            <div class="list_bottom_number">
              <span class="font_gray">跟单</span>
              <span>{{Number(dataInfo.FollowNum.weekNum||0)}}</span>
            </div>
          </div>
        </div>
        <div class="upsell_management_top_list">
          <div class="list_date">本月</div>
          <div class="list_content"
               @click.stop="goRouter('applyRecord','month')">
            <div class="font_number">{{Number(dataInfo.ApplyNumAndMoneySum.monthNum||0)}}/{{Number(Number(dataInfo.ApplyNumAndMoneySum.monthSum||0).toFixed(2))}}</div>
            <div class="font_gray">报名人数/金额</div>
          </div>
          <div class="list_bottom">
            <div class="list_bottom_number">
              <span class="font_gray">新线索</span>
              <span>{{Number(dataInfo.ThreadNum.monthNum||0)}}</span>
            </div>
            <div class="list_bottom_number">
              <span class="font_gray">跟单</span>
              <span>{{Number(dataInfo.FollowNum.monthNum||0)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="upsell_management_center box_shadow_border_div">
        <div class="upsell_management_title">追销管理</div>
        <div class="upsell_management_center_content">
          <div class="upsell_management_center_list">
            <div class="list_top button"
                 @click.stop="goRouter('waitDistributionClue')">
              <div class="list_top_img">
                <img src="../../../../public/image/upsell_management_icon01.png"
                     alt="">
              </div>
              <div class="list_top_info">
                <div class="list_top_name">待分配的线索</div>
                <div class="list_top_number font_yellow">{{Number(dataInfo.UndistributedNum.AllNum||0)}}</div>
              </div>
            </div>
            <div class="list_bottom">
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.UndistributedNum.newStudengtNum||0)}}</div>
                <div class="list_bottom_text">新生</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.UndistributedNum.AbandonNum||0)}}</div>
                <div class="list_bottom_text">放弃</div>
              </div>

            </div>
          </div>
          <div class="upsell_management_center_list">
            <div class="list_top button"
                 @click.stop="goRouter('followingClue')">
              <div class="list_top_img">
                <img src="../../../../public/image/upsell_management_icon02.png"
                     alt="">
              </div>
              <div class="list_top_info">
                <div class="list_top_name">跟进中的线索</div>
                <div class="list_top_number">{{Number(dataInfo.FollowingNum.AllNum||0)}}</div>
              </div>
            </div>
            <div class="list_bottom">
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.FollowingNum.SNum||0)}}</div>
                <div class="list_bottom_text">S单</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.FollowingNum.ANum||0)}}</div>
                <div class="list_bottom_text">A单</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.FollowingNum.BNum||0)}}</div>
                <div class="list_bottom_text">B单</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.FollowingNum.CNum||0)}}</div>
                <div class="list_bottom_text">C单</div>
              </div>
            </div>
          </div>
          <div class="upsell_management_center_list">
            <div class="list_top button"
                 @click.stop="goRouter('tryCourse')">
              <div class="list_top_img">
                <img src="../../../../public/image/upsell_management_icon03.png"
                     alt="">
              </div>
              <div class="list_top_info">
                <div class="list_top_name">到店试课</div>
                <div class="list_top_number">{{Number(dataInfo.TryToShopNum.AllNum||0)}}</div>
              </div>
            </div>
            <div class="list_bottom">
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.TryToShopNum.shopNum||0)}}</div>
                <div class="list_bottom_text">到店</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.TryToShopNum.classNum||0)}}</div>
                <div class="list_bottom_text">试课</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.TryToShopNum.notFollowNum||0)}}</div>
                <div class="list_bottom_text">未跟进</div>
              </div>
            </div>
          </div>

          <div class="upsell_management_center_list">
            <div class="list_top button"
                 @click.stop="goRouter('unfollowClue')">
              <div class="list_top_img">
                <img src="../../../../public/image/upsell_management_icon04.png"
                     alt="">
              </div>
              <div class="list_top_info">
                <div class="list_top_name">未及时跟进的线索</div>
                <div class="list_top_number font_red">{{Number(dataInfo.NotFollowNum.AllNum||0)}}</div>
              </div>
            </div>
            <div class="list_bottom">
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.NotFollowNum.GreaterTwoNum||0)}}</div>
                <div class="list_bottom_text">逾期≥2天</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.NotFollowNum.LessOneNum||0)}}</div>
                <div class="list_bottom_text">逾期≤1天</div>
              </div>
              <div class="list_bottom_list">
                <div class="list_bottom_number">{{Number(dataInfo.NotFollowNum.ExpectedSupplementNum||0)}}</div>
                <div class="list_bottom_text">逾期补录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="general_summarizing_student box_shadow_border_div">
        <div class="general_summarizing_student_navUl">
          <div class="title_list"
               @click="tableSwitch(1)"
               :class="{ opt: tableType == 1 }">
            日统计
          </div>
          <div class="title_list"
               @click="tableSwitch(2)"
               :class="{ opt: tableType == 2 }">
            周统计
          </div>
          <div class="title_list"
               @click="tableSwitch(3)"
               :class="{ opt: tableType == 3 }">
            月统计
          </div>

        </div>
        <div class="payment_statistics_bottom clearfix"
             style="padding-bottom: 20px;">
          <!-- <content-report></content-report> -->
          <div v-if="tableType == 1">
            <day-report></day-report>
          </div>
          <div v-if="tableType == 2">
            <week-report></week-report>
          </div>
          <div v-if="tableType == 3">
            <month-report></month-report>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayReport from './list/day-report';
import weekReport from './list/week-report';
import monthReport from './list/month-report';
import { SaleStatistics } from '../../../API/workbench';
export default {
  name: 'upsellManagement',
  data () {
    return {
      tableType: 1,
      startTime: '',
      endTime: '',
      dataInfo: {
        ApplyNumAndMoneySum: {},  // 报名数和金额
        ThreadNum: {},  // 线索
        FollowNum: {},  // 跟单
        UndistributedNum: {}, // 待分配
        FollowingNum: {}, // 跟进中
        NotFollowNum: {}, // 未及时跟进
        TryToShopNum: {}  // 到店试客
      }
    };
  },
  components: {
    dayReport, weekReport, monthReport
  },
  created () {
    this.getSaleStatistics();
  },
  methods: {
    tableSwitch (type) {
      this.tableType = type;
    },
    getSaleStatistics () {
      SaleStatistics().then(result => {
        console.log('getSaleStatistics', result.data);
        this.dataInfo = result.data;
      });
    },
    setWeekTime (startTime, endTime) {
      var date = Date.new();
      const weekDay = Date.new(date).getDay(); // 得出选中日是星期几
      const day = Date.new(date).getDate(); // 得出选中日是多少号
      if (weekDay == 0) {
        this.startTime = this.$utils.formatDateToLine(Date.new(Date.new(date).setDate(day - 6)).toLocaleDateString());
        this.endTime = this.$utils.formatDateToLine(date);
      } else {
        this.startTime = this.$utils.formatDateToLine(Date.new(Date.new(date).setDate(day - weekDay +
          1)).toLocaleDateString());
        this.endTime = this.$utils.formatDateToLine(Date.new(Date.new(date).setDate(day + 7 - weekDay))
          .toLocaleDateString());
      }
    },
    goRouter (url, dateType) {
      if (url == 'applyRecord') {
        // let startTime = '';
        // let endTime = '';
        if (dateType == 'week') {
          this.setWeekTime(this.startTime, this.endTime);
        } else if (dateType == 'month') {
          this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
          this.endTime = this.$utils.formatDateToLine(Date.new());
        } else {
          var day1 = Date.new();
          day1.setTime(day1.getTime() - 86400000);
          this.startTime = this.$utils.formatDateToLine(Date.new(day1));
          this.endTime = this.startTime;
        }
        this.$router.push({
          name: url,
          query: {
            startTime: this.startTime,
            endTime: this.endTime
          }
        });
      } else {
        this.$router.push({
          name: url
        });
      }
    }
  }
};
</script>
<style>
.upsell_management_view {
  /* width: 1366px; */
  margin: 0 auto;
}

.upsell_management_top {
  display: flex;
  height: 181px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.upsell_management_top_list {
  flex: 1;
  position: relative;
  cursor: pointer;
}

.upsell_management_top_list::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 61px;
  height: 95px;
  width: 1px;
  background: #ececec;
}

.upsell_management_top_list .list_date {
  margin-left: 19px;
  margin-top: 13px;
  font-size: 14px;
  line-height: 19px;
  color: #333;
  padding-left: 17px;
  position: relative;
  margin-bottom: 21px;
}

.upsell_management_top_list .list_date::after {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 0;
  top: 6px;
  background-color: #feaa35;
}

.upsell_management_top_list .list_content {
  text-align: center;
  position: relative;
}

.upsell_management_top_list .list_content::after {
  content: '';
  position: absolute;
  right: 82px;
  top: 22px;
  width: 14px;
  height: 14px;
  background: url(../../../../public/image/next_icon@2x.png) no-repeat center
    center;
  background-size: 8px;
}

.upsell_management_top_list .list_content .font_number {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 6px;
}

.upsell_management_top_list .list_content .font_gray {
  font-size: 14px;
  line-height: 19px;
  color: #999;
}

.upsell_management_top_list .list_bottom {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.upsell_management_top_list .list_bottom_number {
  font-size: 20px;
  line-height: 24px;
}

.upsell_management_top_list .list_bottom_number .font_gray {
  font-size: 14px;
  color: #999;
  margin-right: 5px;
}

.upsell_management_center {
  background-color: #fff;
  height: 223px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.upsell_management_title {
  font-size: 18px;
  line-height: 60px;
  border-bottom: 1px solid #ececec;
  padding-left: 20px;
}

.upsell_management_center_content {
  display: flex;
  padding: 0 10px;
}

.upsell_management_center_list {
  flex: 1;
  padding: 0 10px;
}

.upsell_management_center_list .list_top {
  display: flex;
  padding-top: 20px;
}

.upsell_management_center_list .list_top_info {
  margin-left: 20px;
}

.upsell_management_center_list .list_top_name {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  position: relative;
}

.upsell_management_center_list .list_top_name::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  right: -40px;
  background: url(../../../../public/image/arrow_right_icon_black@2x.png)
    no-repeat;
  background-size: 8px;
}

.upsell_management_center_list .list_top_number {
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
}

.upsell_management_center_list .list_top_number.font_yellow {
  color: #ff8723;
}

.upsell_management_center_list .list_top_number.font_red {
  color: #ff6c67;
}

.upsell_management_center_list .list_bottom {
  height: 71px;
  margin-top: 16px;
  display: flex;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 4px;
  padding-top: 12px;
  justify-content: space-around;
}

.upsell_management_center_list .list_bottom_list {
  text-align: center;
}

.upsell_management_center_list .list_bottom_number {
  font-size: 20px;
  line-height: 23px;
  height: 28px;
}

.upsell_management_center_list .list_bottom_text {
  font-size: 14px;
  line-height: 19px;
  color: #999;
}
</style>

