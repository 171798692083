
<template>
  <!-- 按课程排课 -->
  <div style="">
    <!-- 操作流程 -->
    <div v-show="isShowProcess">
      <operation-process :processList="processDataList"
                         @onClickEvent="doAfterClickProcessStep"></operation-process>
    </div>
    <!-- 开始操作=》学生列表=》课程名称列表=》课程排课首页-->
    <select-arrange-step-list :curShowProcessStepInfo="curShowProcessStepInfo"
                              :processStepNames="processStepNames"
                              :singleArrangeList="studentCourseNameList"
                              :externalSelectTypeKey="curSelectedCourseNameInfo.OLAPKey"
                              @clickBeginOperation="clickBeginOperation"
                              @selectedChooseStudent="doAfterChooseStudent"
                              @selectedCourseOrClassName="doAfterChooseCourseName"></select-arrange-step-list>

    <!-- 学生按课程排课 以班级分组 -->
    <div v-if="vShowArrangeTime">
      <!-- 学生信息 -->
      <student-lable class="content_marginTop_30 single_shadow content_marginRL"
                     :studentID="studentInfo.OLAPKey"
                     :readonly="false"
                     :CustStatusKey="1"
                     @alterStudent="doAfterChooseStudent"></student-lable>
      <!-- 选择课程 课程汇总信息 -->
      <div>
        <course-name-summary :formTitle="'选择课程'"
                             :formTitleKey='"KeCheng"'
                             :selectedData="curSelectedCourseNameInfo"
                             :singleArrangeList="studentCourseNameList"
                             :collectSummaryInfo="studentTicketPlanListByClassType"
                             @selectedCourseOrClassName="doAfterChooseCourseName"></course-name-summary>

      </div>
      <!-- 班级课块列表 -->
      <div>
        <!-- 选项卡菜单 -->
        <tab-menu :tabMenuList="tabMenuList"
                  @clickEvent="doAfterSwitchTab"></tab-menu>
        <!-- 循环课表 -->
        <div v-if="curSelectedTab.type==tabType.circleCourseTable"
             class="all_course_table_content"
             @scroll="doAfterEventScrollTOP">
          <rule-course-list v-for="item in allCourseTableList"
                            :key="item.index"
                            :dataObj="item"
                            :popupsMenu="popUpOperationList"
                            @openOrCloseDetail="downOrUpDetail"
                            @addInClass="addInClass"
                            @doOneTopValueEvent="doOneTopValueEvent"></rule-course-list>

          <!-- 无数据展示 -->
          <div v-if="!allCourseTableList.length"
               class="course_block_nodata">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
        <!-- 精准排课 -->
        <div v-else>
          <precise-courses-arranging-month-view :studentInfo="studentInfo"
                                                :selectCourseInfo="curSelectedCourseNameInfo"
                                                :selectClassInfo="{}"
                                                :optionStatusList="optionStatusList"
                                                @upLoadArrangeStatisticsInfo="updateStudentTicketPlanListByClassType"></precise-courses-arranging-month-view>
        </div>
      </div>
      <!-- 点击学生 展开更多操作 -->
      <div class="course_student_attendance_operation module_content_menuList"
           :style="{'top':topValue+'px','left':'290px'}"
           v-show="isShowItem">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="popUpOperationList"
                       @clickOptionForAlertDialog="doClickPopItem"></drop-down-box>
      </div>

    </div>
  </div>

</template>

<script>
import ruleCourseList from '../student-relation-course-component/rule-course-list';
import tabMenu from '../../relation-course-component/tab-menu';
import batchArrangeCourse from '../student-relation-course-component/batch-arrange-course';
import freeChoiceArrangeCourse from '../student-relation-course-component/free-choice-arrange-course';

import courseNameSummary from '../selected-coursename-or-class-summary';
import operationProcess from '../../common/operation-process';
import studentLable from '../../common/student-lable';
import selectArrangeStepList from '../select-arrange-step-list';
import preciseCoursesArrangingMonthView from '../precise-courses-arranging-month-view';
import dropDownBox from '../../common/drop-down-box';
import {
  CancelStudent2MutiSameApplyClassCourseByRule,
  GetTheStudentTicketPlanListByClassType,
  SearchStructedRule,
  GetStructuredRuleByCourseNameStudent,
  studentAddClass,
  getStudentHavingClass,
  ArrangeStudent2MutiSameApplyClassCourseByRule
} from '../../../API/workbench';
export default {
  data () {
    return {
      processDataList: [
        {
          index: 0,
          title: '选择学生',
          stepName: '1',
          type: 'chooseStudent',
          status: 'undo',
          calssName: '',
          left: 0
        },
        {
          index: 1,
          title: '选择课程',
          stepName: '2',
          type: 'chooseCourseName',
          status: 'undo',
          calssName: '',
          left: '50%'
        },
        {
          index: 2,
          title: '安排时间',
          stepName: 'end',
          type: 'arrangeTime',
          status: 'undo',
          calssName: '',
          left: '100%'
        }
      ],
      processStepNames: {
        chooseStudent: '1',
        chooseCourseName: '2',
        arrangeTime: 'end'
      },
      studentInfo: {}, // 学生信息
      paramsForRule: {
        StudentKey: 0,
        StructuredRuleKey: 0,
        StartTime: '',
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      },
      topValue: 0, // 默认第一个top值
      newScrollTop: 0, // 滚动距离
      isShowItem: false,
      isTheBatchProcessShow: false, // 控制批量排课显示
      isOptionalCourseArrangementShow: false, // 控制自选排课显示
      popUpOperationList: [
        {
          type: 1,
          name: '批量排课',
          eventName: 'batch',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          type: 2,
          name: '自选排课',
          eventName: 'freeChoice',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          type: 3,
          name: '退出已排的课',
          eventName: 'cancelAllArrange',
          isShow: true,
          ModulePowerKey: 47
        }
      ],
      tabMenuList: [
        { title: '循环课表', isSelected: true, type: 0 },
        { title: '精准排课', isSelected: false, type: 1 }
      ],
      tabType: {
        circleCourseTable: 0, // 循环课表
        accurateArrangeCourse: 1// 精准排课
      },
      curSelectedTab: null, // 当前选中的选项卡
      curShowProcessStepInfo: {}, // 当前显示的流程步骤
      curSelectedCircleCourseData: {}, // 当前选中课信息
      curSelectedCourseNameInfo: {}, // 当前选中课程
      courseTableList: [], // 所有课信息
      studentArrangedCourseList: [], // 学生已安排的课
      classList: [],
      studentCourseNameList: [], // 课程列表
      studentTicketPlanListByClassType: {}, // 获取此学生指定课名所有报课计划
      allCourseTableList: [],

      courseNameApplyCourseData: {},
      studentInClassList: [],
      isShowMenu: true,
      showBox: false,
      optionStatusList: [
        {
          type: 0,
          name: '搜索全部',
          eventName: 'clear',
          isShow: true
        },
        {
          type: 1,
          name: '按老师搜索',
          eventName: 'teacher',
          isShow: true
        },
        {
          type: 5,
          name: '按班级搜索',
          eventName: 'class',
          isShow: true
        }
      ],
      isShowProcess: true
    };
  },
  props: {
    studentID: [String, Number],
    courseNameID: [String, Number],
    courseName: [String, Number]
  },
  components: {
    ruleCourseList,
    tabMenu,
    operationProcess,
    studentLable,
    courseNameSummary,
    selectArrangeStepList,
    freeChoiceArrangeCourse,
    batchArrangeCourse,
    preciseCoursesArrangingMonthView,
    dropDownBox
  },
  computed: {
    vShowArrangeTime () {
      return this.curShowProcessStepInfo.stepName == this.processStepNames.arrangeTime;
    },
    vClassList () {
      let newList = [];
      this.courseTableList.forEach(obj => {
        let course = newList.find(item => {
          return item.OLAPKey == obj.GradeClassKey;
        });
        // 班级名称不存在 添加一项
        if (!course) {
          let findItem = this.studentInClassList.find(item => { return item.OLAPKey == obj.GradeClassKey; });
          let hasArrangedCourse = false;
          if (findItem) {
            hasArrangedCourse = true;
          }
          newList.push({ OLAPKey: obj.GradeClassKey, MainDemoName: obj.GradeClassKeyValue, dataList: [obj], isShow: true, hasArrangedCourse: hasArrangedCourse, isOpen: obj.isOpen });
        } else {
          course.dataList.push(obj);
        }
      });
      return newList;
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  watch: {
    vClassList (n, o) {
      this.allCourseTableList = [];
      n.forEach(obj => {
        if (n.length > 1) {
          obj.isOpen = obj.isOpen || false;
        } else {
          obj.isOpen = true;
        }
        this.allCourseTableList.push(obj);
      });
    }
  },
  created () {
    this.initData();
    this.curSelectedTab = this.tabMenuList[0];
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowItem = false;
    }, true);
    this.$bus.on(this, 'afterArrangeScheduleCourse', (studentKey) => {
      if (this.curSelectedCircleCourseData && this.curSelectedCircleCourseData.StructuredRuleKey > 0) {
        this.$emit('afterSucces');
        this.doAfterArrangeStudentSuccess();
      }
    });
  },
  methods: {
    // 关闭模块
    closeDialog () {
      this.curSelectedTab.isSelected = false;
      this.tabMenuList[0].isSelected = true;
      this.curSelectedTab = this.tabMenuList[0];
      this.showBox = false;
      this.curShowProcessStepInfo = {};
      this.curSelectedCourseNameInfo = {};
      this.processDataList.forEach(o => {
        o.status = 'undo';
        o.calssName = '';
      });
      this.isShowItem = false;
      this.isShowProcess = true;
      this.curSelectedCircleCourseData = {};
      this.newScrollTop = 0;
    },
    initData () {
      this.showBox = true;
      if (this.studentID) {
        this.isShowProcess = false;
        this.updateProcessStatus(this.processDataList[0]);
        this.doAfterChooseStudent({ OLAPKey: this.studentID }, 'showProcess');
      }
      if (this.courseNameID) {
        this.updateProcessStatus(this.processDataList[1]);
        this.doAfterChooseCourseName({ OLAPKey: this.courseNameID, MainDemoName: this.courseName }, 'showProcess');
      }
    },
    // 获取课程排课规律课表
    loadStructedRule (gradeClassID) {
      SearchStructedRule(this.curSelectedCourseNameInfo.OLAPKey, gradeClassID > 0 ? gradeClassID : '').then(result => {
        this.getStructuredRuleByCourseNameStudent(() => {
          if (gradeClassID > 0) {
            result.data.forEach(obj => {
              let findItem = this.courseTableList.find(item => { return obj.StructuredRuleKey == item.StructuredRuleKey; });
              if (findItem) {
                if (this.vIsSysVersionTY) {
                  findItem.Num_SyncArranged = obj.Num_SyncArranged;
                } else {
                  findItem.Num_InGrade = obj.Num_InGrade;
                }
              }
            });
          } else {
            this.courseTableList = result.data;
          }
          this.courseTableList.forEach(obj => {
            let arrangedCourse = this.studentArrangedCourseList.find(item => {
              return obj.GradeClassKey == item.GradeClassKey && obj.WeekKey == item.WeekKey && obj.ClassTimeName == item.ClassTimeName;
            });
            if (arrangedCourse) {
              this.$set(obj, 'alreadyAttenCount', this.$utils.mAmountType(arrangedCourse.ClassCount, 0));
            } else {
              this.$set(obj, 'alreadyAttenCount', 0);
            }
          });
        }, gradeClassID);
      });
    },
    // 获取学生指定课程已排规律
    getStructuredRuleByCourseNameStudent (callback, gradeClassID) {
      GetStructuredRuleByCourseNameStudent(this.studentInfo.OLAPKey, this.curSelectedCourseNameInfo.OLAPKey).then(result => {
        this.studentArrangedCourseList = result.data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    // 课名列表
    async getStudentCourseTypeNameList () {
      let resultData = await this.$basicData.loadCourseNameList();
      this.studentCourseNameList = [];
      resultData.forEach(o => {
        if (o.TypeKey == 2 && o.StateKey == 2) {
          this.studentCourseNameList.push(o);
        }
      });
    },
    // 精准排课成功时更新数据
    updateStudentTicketPlanListByClassType () {
      this.$emit('afterSucces');
      this.getTheStudentTicketPlanListByClassType();
    },
    // 获取此学生指定课名所有报课计划
    getTheStudentTicketPlanListByClassType () {
      GetTheStudentTicketPlanListByClassType(this.studentInfo.OLAPKey, this.curSelectedCourseNameInfo.OLAPKey).then(result => {
        this.studentTicketPlanListByClassType = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 点击开始操作
    clickBeginOperation () {
      this.updateProcessStatus(this.processDataList[0]);
    },
    // 选中学生后
    doAfterChooseStudent (item, type, courseNameList) {
      this.studentInfo = item;
      if (type == 'showProcess') {
        this.updateProcessStatus(this.processDataList[1]);
        if (courseNameList) { // 拿已有的课程列表选择
          this.studentCourseNameList = courseNameList;
        } else {
          this.getStudentCourseTypeNameList();
        }
      } else {
        this.loadCourseTable();
      }
    },
    // 选中课程名称后
    doAfterChooseCourseName (item, type) {
      this.curSelectedCourseNameInfo = item; // 当前选中课信息
      this.loadCourseTable();
      if (type == 'showProcess') {
        this.updateProcessStatus(this.processDataList[2]);
      }
    },
    // 切换课程 学生 重新加载课表数据
    loadCourseTable () {
      // 获取学生所在班级
      this.loadStudentInClassList();
      this.getTheStudentTicketPlanListByClassType();
      this.loadStructedRule();
    },
    // 变更流程状态
    updateProcessStatus (stepInfo) {
      if (stepInfo.stepName == 'end') {
        stepInfo.calssName = 'complete';
      } else {
        stepInfo.calssName = 'the';
      }
      stepInfo.status = 'doing';
      if (JSON.stringify(this.curShowProcessStepInfo) != '{}') {
        this.curShowProcessStepInfo.calssName = 'tick';
        this.curShowProcessStepInfo.status = 'done';
      }
      this.curShowProcessStepInfo = stepInfo;
    },
    // 点击流程步骤
    doAfterClickProcessStep (item) {
      item.status = 'doing';
      item.calssName = 'the';
      // 往前点 后面的样式要变
      this.processDataList.forEach(obj => {
        if (obj.index > item.index) {
          obj.status = 'undo';
          obj.calssName = '';
        }
      });
      this.curShowProcessStepInfo = item;
    },
    // 切换选项卡
    doAfterSwitchTab (item) {
      this.newScrollTop = 0;
      this.curSelectedTab.isSelected = false;
      item.isSelected = true;
      this.curSelectedTab = item;
      if (this.curSelectedTab.type == this.tabType.circleCourseTable) {
        this.loadStudentInClassList();
        this.loadStructedRule();
      } else {
        this.curSelectedCircleCourseData = {};
      }
    },
    // 展开收起
    downOrUpDetail (item) {
      item.isOpen = !item.isOpen;
      this.isShowItem = false;
    },
    // 计算TOP值
    doOneTopValueCount (e) {
      let offsetTop = e.currentTarget.offsetTop;
      this.topValue = offsetTop - this.newScrollTop + 42;
      this.isShowItem = true;
    },
    // 点击一节课 弹出层位置计算
    doOneTopValueEvent (courseInfo, e) {
      this.curSelectedCircleCourseData = courseInfo;
      if (this.vIsSysVersionTY) {
        if (this.curSelectedCircleCourseData.alreadyAttenCount > 0) {
          layer.confirm('请确认是否退出当前循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let IsSyncArrangedKey = 1;// 是否循环跟排 0-否;1-是
                  this.cancelAllArrangeClick(IsSyncArrangedKey);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.confirm('请确认是否安排进当前循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.addToCircleCourseForStudent();
                  layer.close();
                }
              }
            ]
          });
        }
      } else {
        this.doOneTopValueCount(e);
      }
    },
    // 通用版 安排进循环课
    addToCircleCourseForStudent () {
      let params = {
        StudentKey: this.studentInfo.OLAPKey,
        StructuredRuleKey: this.curSelectedCircleCourseData.StructuredRuleKey,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: this.curSelectedCircleCourseData.LastClassTime,
        ClassCount: Number(this.curSelectedCircleCourseData.ClassCount),
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      ArrangeStudent2MutiSameApplyClassCourseByRule(params).then(result => {
        this.$bus.emit('afterArrangeCirculationCourse');
        this.$emit('afterSucces');
        this.doAfterArrangeStudentSuccess();
      })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 滚动后触发获取弹窗位置
    doAfterEventScrollTOP (e) {
      this.newScrollTop = e.target.scrollTop;
      this.isShowItem = false;
    },
    // 点击弹出层项
    doClickPopItem (menu) {
      if (menu.eventName == 'batch' || menu.eventName == 'cancelAllArrange') {
        this.paramsForRule.StudentKey = this.studentInfo.OLAPKey;
        this.paramsForRule.StructuredRuleKey = this.curSelectedCircleCourseData.StructuredRuleKey;
        this.paramsForRule.StartTime = this.$utils.formatDateToLine(Date.new());
      }
      let paramsData = { studentKey: this.studentInfo.OLAPKey, courseNameKey: this.curSelectedCircleCourseData.CourseNameKey, classGradeKey: this.curSelectedCircleCourseData.GradeClassKey, ruleID: this.curSelectedCircleCourseData.StructuredRuleKey };
      switch (menu.eventName) {
        case 'batch':
          let item = {
            routerName: this.$route.name,
            moduleName: 'batchArrangeScheduleCourse',
            data: { paramsInfo: paramsData }
          };
          this.$bus.emit('needOpenRouter', item);
          break;
        case 'freeChoice':
          let moduleInfo = {
            routerName: this.$route.name,
            moduleName: 'freeChoiceArrangeScheduleCourse',
            data: { paramsInfo: paramsData }
          };
          this.$bus.emit('needOpenRouter', moduleInfo);
          break;
        case 'cancelAllArrange':
          layer.confirm('请确认是否退出已排的课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.cancelAllArrangeClick();
                  layer.close();
                }
              }
            ]
          });
          break;
        default:
          break;
      }
      this.isShowItem = false;
    },
    // 取消全部安排
    cancelAllArrangeClick (isSyncArrangedKey) {
      if (!this.curSelectedCircleCourseData.alreadyAttenCount) {
        return;
      }
      CancelStudent2MutiSameApplyClassCourseByRule(this,this.studentInfo.OLAPKey, this.curSelectedCircleCourseData.StructuredRuleKey, isSyncArrangedKey).then(result => {
        this.doAfterArrangeStudentSuccess();
        this.$bus.emit('afterArrangeCirculationCourse', this.studentInfo.OLAPKey);
        this.$emit('afterCancelAllArrangeSuccess', this.studentInfo.OLAPKey);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 排课成功后
    doAfterArrangeStudentSuccess (cancelArr, arrangeArr) {
      this.loadStructedRule(this.curSelectedCircleCourseData.GradeClassKey);
      this.getTheStudentTicketPlanListByClassType();
    },
    // 加入班级
    addInClass (item) {
      this.isShowItem = false;
      studentAddClass(this.studentInfo.OLAPKey, item.OLAPKey).then(result => {
        layer.alert('加入班级成功');
        this.$emit('afterSucces');
        item.hasArrangedCourse = true;
        this.curSelectedCircleCourseData.GradeClassKey = item.OLAPKey;
        this.loadStructedRule(item.OLAPKey, 'addInClass');
        this.$bus.emit('afterQuitOrAddClass', this.studentInfo.OLAPKey, item.OLAPKey, 'add');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取班级列表
    loadStudentInClassList () {
      getStudentHavingClass(this.studentInfo.OLAPKey).then(result => {
        this.studentInClassList = result.data;
      }).catch(error => {
        console.log(error);
      });
    }
  },
  destroyed () {
    this.$emit('afterSucces');
  }

};
</script>

<style>
</style>