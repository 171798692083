<template>
  <div class="massive_bg"
       style="margin-bottom:20px">
    <div class="workbench_view_title pr">
      经营月报
      <div class="principal_manage_date">
        <single-year-picker @chgDate="changeSaveDate"
                            :minDate="vMinDate"
                            ref="monthPicker"
                            :type="'month'"></single-year-picker>
      </div>
    </div>
    <div class="principal_manage_proceeds"
         style="margin-left: 20px;margin-right: 20px;width: auto;">
      <div class="principal_manage_proceeds_title">
        <div class="title_name">
          报课收入
        </div>
        <div class="title_text">
          <span>￥{{$utils.setMoneyformatToParseInt(applyPaymentDetail.TotalActualColAmount||0)}}</span>
          <!-- <span class="font_gray"> / {{Number(applyPaymentDetail.TotalNum||0)}}单</span> -->
        </div>
      </div>
      <div class="principal_manage_proceeds_text">
        <div class="text_list">
          <span class="font_gray">续费</span>
          <span class="text_overflow_hidden">￥{{$utils.setMoneyformatToParseInt(applyPaymentDetail.RenewedActualColAmount||0)}}</span>
          <!-- <span class="font_gray"> / {{Number(applyPaymentDetail.RenewedNum||0)}}单</span> -->
        </div>
        <div class="text_list">
          <span class="font_gray">新生</span>
          <span class="text_overflow_hidden">￥{{$utils.setMoneyformatToParseInt(applyPaymentDetail.NewActualColAmount||0)}}</span>
          <!-- <span class="font_gray"> / {{Number(applyPaymentDetail.NewNum||0)}}单</span> -->
        </div>
        <div class="text_list">
          <span class="font_gray">扩科</span>
          <span class="text_overflow_hidden">￥{{$utils.setMoneyformatToParseInt(applyPaymentDetail.expandSubjectAmount||0)}}</span>
          <!-- <span class="font_gray"> / {{Number(applyPaymentDetail.expandSubjectNum||0)}}单</span> -->
        </div>
      </div>
    </div>
    <div class="principal_manage_course"
         style="margin-left: 20px;margin-right: 20px;width: auto;">
      <div class="principal_manage_proceeds_title">
        <div class="title_name">
          课时课消
        </div>
        <div class="title_text">
          <span>￥{{$utils.setMoneyformatToParseInt(classAmountDetail.TotalClassAmount||0)}}</span>
          <span style="color:#666;font-size:16px">（{{Number(classAmountDetail.TotalClassPeriod||0)}}课时 / {{Number(classAmountDetail.TotalClassCount||0)}}课次）</span>
        </div>
      </div>
      <div class="principal_manage_course_content">
        <div class="manage_course_dual">
          <div class="manage_course_title">
            学生人均课消
          </div>
          <div class="manage_course_center">
            <span>￥{{$utils.setMoneyformatToParseInt(classAmountDetail.StudentAvgClassAmount||0)}} / {{Number(classAmountDetail.StudentAvgClassPeriod||0)}}</span>
            <span class="font_gray">课时</span>
          </div>
          <div class="manage_course_bottom">
            （考勤人数 {{Number(classAmountDetail.AttendanceCount||0)}}）
          </div>
        </div>
        <div class="manage_course_dual">
          <div class="manage_course_title">
            老师人均创收
          </div>
          <div class="manage_course_center">
            <span>￥{{$utils.setMoneyformatToParseInt(classAmountDetail.TeacherAvgClassAmount||0)}} / {{Number(classAmountDetail.TeacherAvgClassPeriod||0)}}</span>
            <span class="font_gray">课次</span>
          </div>
          <div class="manage_course_bottom">
            （主讲老师 {{Number(classAmountDetail.TotalTeacherNum||0)}}）
          </div>
        </div>
      </div>
    </div>
    <div class="principal_manage_class">
      <div class="principal_manage_class_title">
        <div :class="searchType==0?'font_blue':''"
             @click.stop="changeType(0)">课程课消</div>
        <div :class="searchType==1?'font_blue':''"
             @click.stop="changeType(1)">班级课消</div>
      </div>
      <!-- 课程饼图 -->
      <div v-show="searchType==0">
        <div class="principal_manage_class_number">
          <div class="class_number_box">
            <span class="font_gray">开设课程</span>
            <span>
              {{Number(courseClassAmountDetail.CourseCount||0)}}
            </span>
          </div>
          <div class="class_number_box ">
            <span class="font_gray">课消金额</span>
            <span>
              ￥{{$utils.setMoneyformatToParseInt(courseClassAmountDetail.ClassAmount||0)}}
            </span>
          </div>
        </div>
        <div class="principal_manage_echarts pr"
             v-show="Number(courseClassAmountDetail.ClassAmount)>0">
          <circleEchart :echartID="'courseClassAmount_echart'"
                        :reqList="monthList"
                        :nowDataList="courseAmountList"
                        :tipstitle="'课消金额'"
                        :secondUnits="'课次'"
                        @clickItem="doAfterClickEchart"
                        ref="courseClassAmount"></circleEchart>
          <div class="center_tips"
               :style="setSizeByLength(courseClassAmountDetail.ClassAmount)"
               v-if="Number(courseClassAmountDetail.ClassAmount)>0">
            <div class="font_gray">课消金额</div>
            <div>{{Number(courseClassAmountDetail.ClassAmount)}}</div>
          </div>
        </div>
        <div v-show="Number(courseClassAmountDetail.ClassAmount)==0"
             style="width:100%;height:100%">
          <div class="monitoring_content_nodata"
               style="margin-top: 60px;">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
      <!-- 班级饼图 -->
      <div v-show="searchType==1">
        <div class="principal_manage_class_number">
          <div class="class_number_box">
            <span class="font_gray">开设班级</span>
            <span>
              {{Number(gradeClassAmountDetail.GradeCount||0)}}
            </span>
          </div>
          <div class="class_number_box ">
            <span class="font_gray">课消金额</span>
            <span>
              ￥{{$utils.setMoneyformatToParseInt(gradeClassAmountDetail.ClassAmount||0)}}
            </span>
          </div>
        </div>
        <div class="principal_manage_echarts pr"
             v-show="Number(gradeClassAmountDetail.ClassAmount)>0">
          <circleEchart :echartID="'gradeClassAmount_echart'"
                        :reqList="monthList"
                        :nowDataList="gradeAmountList"
                        :tipstitle="'课消金额'"
                        :secondUnits="'课次'"
                        @clickItem="doAfterClickEchart"
                        ref="gradeClassAmount"></circleEchart>
          <div class="center_tips"
               :style="setSizeByLength(gradeClassAmountDetail.ClassAmount)"
               v-if="Number(gradeClassAmountDetail.ClassAmount)>0">
            <div class="font_gray">课消金额</div>
            <div>{{Number(gradeClassAmountDetail.ClassAmount)}}</div>
          </div>
        </div>
        <div v-show="Number(gradeClassAmountDetail.ClassAmount)==0"
             style="width:100%;height:100%">
          <div class="monitoring_content_nodata"
               style="margin-top: 60px;">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="principal_manage_proceeds_title"
           style="padding:6px 20px 0">
        <div class="title_name">
          预收款余额
        </div>
        <!-- <div class="title_text">
          <span>￥{{$utils.setMoneyformatToParseInt(Number(classBalanceDetail.ValueAmount||0))}}</span>
        </div> -->
      </div>
      <div class="principal_manage_class_number">
        <div class="class_number_box">
          <span class="font_gray">课时余额</span>
          <span>
            ￥{{$utils.setMoneyformatToParseInt(Number(classBalanceDetail.ValueAmount||0))}}
            <!-- / {{Number(classBalanceDetail.ValueCount||0)}} 课时 / {{Number(classBalanceDetail.StudentNum||0)}} 人 -->
          </span>
        </div>
        <function-btn :item="applyRecordbtn"
                      @clickItem="goRouter"
                      :className="''">
          <div class="class_number_box next_icon">
            <span class="font_gray">年月日卡余额</span>
            <span>
              ￥{{$utils.setMoneyformatToParseInt(Number(classBalanceDetail.cardValueAmount)||0)}}
            </span>
          </div>
        </function-btn>

      </div>
      <div class="principal_manage_echarts pr"
           v-show="Number(classBalanceDetail.ValueAmount)>0">
        <circleEchart :echartID="'classBalance_echart'"
                      :reqList="monthList"
                      :nowDataList="classBalanceList"
                      :tipstitle="'课时余额'"
                      :secondUnits="'课时'"
                      @clickItem="doAfterClickEchart"
                      ref="classBalance"></circleEchart>
        <div class="center_tips"
             :style="setSizeByLength(classBalanceDetail.ValueAmount)"
             v-if="Number(classBalanceDetail.ValueAmount)>0">
          <div class="font_gray">课时余额</div>
          <div>{{Number(classBalanceDetail.ValueAmount)}}</div>
        </div>

      </div>
      <div v-show="Number(classBalanceDetail.ValueAmount)==0"
           style="width:100%;height:100%">
        <div class="monitoring_content_nodata"
             style="margin-top: 60px;">
          <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import circleEchart from './president-circle-echart';
import singleYearPicker from '../base-module/date-picker-common/single-year-picker';
import {
  GetApplyPaymentReportForMonth,
  GetClassAmountReportForMonth,
  GetClassAmountReportForCourse,
  GetClassAmountReportForGrade,
  GetClassBalanceForCourse
} from '../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new())
      },
      searchType: 0,
      applyPaymentDetail: {},   // 报课收入月报信息
      classAmountDetail: {
        StudentAvgClassPeriod: '0',
        TeacherAvgClassPeriod: '0'
      },    // 课时课消月报信息
      classBalanceDetail: {     // 课时余额

      },
      gradeClassAmountDetail: {    // 班级课消

      },
      courseClassAmountDetail: {    // 班级课消

      },
      courseAmountList: [],     // 饼图课程课消数据
      gradeAmountList: [],      // 饼图班级课消数据
      classBalanceList: [],     // 饼图课消余额数据
      monthList: [],
      echartColor: ['#5A8DF6', '#62DAAB', '#F56E53', '#657798', '#EEB91D'],
      applyRecordbtn: {     // 报名记录权限
        name: '',
        eventName: 'classListQueryStatistics',
        ModulePowerKey: 127
      }

    };
  },
  components: {
    singleYearPicker, circleEchart
  },
  created () {

  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    // 根据字数设定字体大小
    setSizeByLength (num) {
      num = num || 0;
      let size = parseInt(num).toString().length <= 6 ? 'font-size:30px' : 'font-size:18px';
      return size;
    },
    // 切换
    changeType (type) {
      this.searchType = type;
      if (this.searchType == 0) {
        this.getClassAmountDataListForCourse();
      } else {
        this.getClassAmountDataListForGrade();
      }
      // 测试
      // this.getClassBalanceForCourse();
    },
    // 更改日期.
    changeSaveDate (val, startTime, endTime) {
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-');;
      this.getData();
    },
    // 课时课消月报
    getClassAmountReport () {
      GetClassAmountReportForMonth(this.searchObj).then(result => {
        this.classAmountDetail = result.data;
        this.classAmountDetail.TeacherAvgClassPeriod = Number(this.classAmountDetail.TeacherAvgClassPeriod).toFixed(1);
        this.classAmountDetail.StudentAvgClassPeriod = Number(this.classAmountDetail.StudentAvgClassPeriod).toFixed(1);
        console.log(result.data, 'GetClassAmountReportForMonth');
      });
    },
    // 报课收入月报
    getApplyPaymentReport () {
      GetApplyPaymentReportForMonth(this.searchObj).then(result => {
        this.applyPaymentDetail = result.data;
        console.log(result.data, 'GetApplyPaymentReportForMonth');
      });
    },
    setMoneyToWan (num) {
      return (Math.floor(((Number(num)) / 10000) * 100) / 100) + '万';
    },
    // 课程课消饼图数据
    getClassAmountDataListForCourse () {
      GetClassAmountReportForCourse(this.searchObj).then(result => {
        this.courseClassAmountDetail = result.data;
        this.courseClassAmountDetail.ClassAmount = Number(this.courseClassAmountDetail.ClassAmount || 0).toFixed(0);
        // this.courseClassAmountDetail.GradeList.sort((x, y) => {
        //   return Number(y.ClassAmount) - Number(x.ClassAmount);
        // });
        this.courseAmountList = this.setCourseAmonutList();
        this.$nextTick(() => {
          if (this.courseClassAmountDetail.ClassAmount > 0) {
            this.$refs.courseClassAmount.echartInit();
          }
        });
        console.log(result.data, 'GetClassAmountReportForCourse');
      }).catch(error => {
        console.log(error, 'ErrorGetClassAmountReportForCourse');
      });
    },
    // 班级课消饼图数据
    getClassAmountDataListForGrade () {
      GetClassAmountReportForGrade(this.searchObj).then(result => {
        this.gradeClassAmountDetail = result.data;
        this.gradeClassAmountDetail.ClassAmount = Number(this.gradeClassAmountDetail.ClassAmount || 0).toFixed(0);
        // this.gradeClassAmountDetail.GradeList.sort((x, y) => {
        //   return Number(y.ClassAmount) - Number(x.ClassAmount);
        // });
        this.gradeAmountList = this.setGradeAmonutList();
        this.$nextTick(() => {
          if (this.gradeClassAmountDetail.ClassAmount > 0) {
            this.$refs.gradeClassAmount.echartInit();
          }
        });
        console.log(result.data, 'GetClassAmountReportForGrade');
      }).catch(error => {
        console.log(error, 'ErrorGetClassAmountReportForCourse');
      });
    },
    // 课时余额饼图数据
    getClassBalanceForCourse () {
      GetClassBalanceForCourse().then(result => {
        console.log(result.data, 'GetClassBalanceForCourse');
        this.classBalanceDetail = result.data;
        this.classBalanceDetail.ValueAmount = Number(this.classBalanceDetail.ValueAmount || 0).toFixed(0);
        this.classBalanceDetail.cardValueAmount = Number(this.classBalanceDetail.cardValueAmount || 0).toFixed(0);
        // this.classBalanceDetail.courseList.sort((x, y) => {
        //   return Number(y.ValueAmount) - Number(x.ValueAmount);
        // });
        this.classBalanceList = this.setClassBalanceList();
        this.$nextTick(() => {
          if (this.classBalanceDetail.ValueAmount > 0) {
            this.$refs.classBalance.echartInit();
          }
        });
        // console.log(result.data, 'GetClassBalanceForCourse');
      }).catch(error => {
        console.log(error, 'ErrorGetClassAmountReportForCourse');
      });
    },
    // 点击图片示例.
    doAfterClickEchart (item) {

    },
    // 设置课程课消金额
    setCourseAmonutList () {
      let nowList = [];
      let count = 0;
      let PayAmount = 0;
      let num = 0;
      this.courseClassAmountDetail.GradeList.forEach((o, index) => {
        o.CourseNameKeyValue = o.CourseNameKeyValue || '';
        o.ClassAmount = Math.round(Number(o.ClassAmount));
        o.ClassCount = Math.round(Number(o.ClassCount));
        // let CourseNameKeyValue = o.CourseNameKeyValue.length > 12 ? o.CourseNameKeyValue.substring(0, 12) + '...' : o.CourseNameKeyValue;
        if (Number(o.ClassAmount) > 0 && index < 5) {
          let obj = {
            value: Number(o.ClassAmount),
            name: o.CourseNameKeyValue,
            num: Number(o.ClassCount),
            id: o.CourseNameKey,
            itemStyle: {
              color: this.echartColor[index]
            }
          };
          nowList.push(obj);
        } else {
          count++;
          PayAmount += Number(o.ClassAmount) || 0;
          num += Number(o.ClassCount) || 0;
        }
      });
      // if (count > 0) {
      //   console.log(PayAmount, 'count>0');
      //   let obj = {
      //     value: PayAmount > 0 ? PayAmount : null,
      //     name: '其他',
      //     num: num,
      //     itemStyle: {
      //       color: '#ccc'
      //     }
      //   };
      //   nowList.push(obj);
      // }
      return nowList;
    },
    // 设置班级课消金额
    setGradeAmonutList () {
      let nowList = [];
      let count = 0;
      let PayAmount = 0;
      let num = 0;
      this.gradeClassAmountDetail.GradeList.forEach((o, index) => {
        o.GradeClassKeyValue = o.GradeClassKeyValue || '';
        o.ClassAmount = Math.round(Number(o.ClassAmount));
        o.ClassCount = Math.round(Number(o.ClassCount));
        // let GradeClassKeyValue = o.GradeClassKeyValue.length > 12 ? o.GradeClassKeyValue.substring(0, 12) + '...' : o.GradeClassKeyValue;
        if (Number(o.ClassAmount) > 0 && index < 5) {
          let obj = {
            value: Number(o.ClassAmount),
            name: o.GradeClassKeyValue,
            num: Number(o.ClassCount),
            id: o.GradeClassKey,
            itemStyle: {
              color: this.echartColor[index]
            }
          };
          nowList.push(obj);
        } else {
          count++;
          PayAmount += Number(o.ClassAmount) || 0;
          num += Number(o.ClassCount) || 0;
        }
      });
      // if (count > 0) {
      //   console.log(PayAmount, 'count>0');
      //   let obj = {
      //     value: PayAmount > 0 ? PayAmount : null,
      //     name: '其他',
      //     num: num,
      //     itemStyle: {
      //       color: '#6DC8EC'
      //     }
      //   };
      //   nowList.push(obj);
      // }
      return nowList;
    },
    // 设置课消余额金额
    setClassBalanceList () {
      let nowList = [];
      let count = 0;
      let PayAmount = 0;
      let num = 0;
      this.classBalanceDetail.courseList.forEach((o, index) => {
        o.CourseKeyValue = o.CourseKeyValue || '';
        o.ValueAmount = Math.round(Number(o.ValueAmount));
        o.ValueCount = Math.round(Number(o.ValueCount));
        // let CourseKeyValue = o.CourseKeyValue.length > 12 ? o.CourseKeyValue.substring(0, 12) + '...' : o.CourseKeyValue;
        if (Number(o.ValueAmount) > 0 && index < 5) {
          let obj = {
            value: Number(o.ValueAmount),
            name: o.CourseKeyValue,
            num: Number(o.ValueCount),
            id: o.CourseKey,
            itemStyle: {
              color: this.echartColor[index]
            }
          };
          nowList.push(obj);
        } else {
          count++;
          PayAmount += Number(o.ValueAmount) || 0;
          num += Number(o.ValueCount) || 0;
        }
      });
      // if (count > 0) {
      //   let obj = {
      //     value: PayAmount > 0 ? PayAmount : null,
      //     name: '其他',
      //     num: num,
      //     itemStyle: {
      //       color: '#6DC8EC'
      //     }
      //   };
      //   nowList.push(obj);
      // }
      console.log(nowList, '余额list');
      return nowList;
    },
    // 初始化获取数据
    getData () {
      this.getClassAmountReport();
      this.getApplyPaymentReport();
      this.getClassBalanceForCourse();
      if (this.searchType == 0) {
        this.getClassAmountDataListForCourse();
      } else {
        this.getClassAmountDataListForGrade();
      }
    },
    goRouter (item) {
      console.log('goRouter', item, window.ModulePowerSetting);
      let moduleInfo = {
        routerName: item.eventName,
        moduleName: ''

      };
      this.$bus.emit('needOpenRouter', moduleInfo);
      // layer.alert("此功能暂未开通")
    }
  }
};
</script>