<template>
  <div style="border-radius:8px;background-color:#fff;width:100%">
    <div class=" "
         style="margin-top:0;position: relative;">
      <!-- 按课程查看 v-if="!isLoadding" -->
      <div class="course_class_summarizing courseNameApplyCourseReport clearfix">
        <div class="course_class_summarizing_left">
          <div class="summarizing_number_left">
            <span>全部课程</span>
            <span class="font_number">({{applyCourseNameList.length}})</span>
          </div>
          <div class="left_search_box"
               style="position: relative;">
            <div class="left_search ">
              <input type="text"
                     placeholder="搜索课程"
                     v-model="searchTextForCourseName"
                     onkeyup="this.value=this.value.replace(/[, ]/g,'')" />
              <span v-show="searchTextForCourseName"
                    class="home_remove_btn"
                    @click="searchTextForCourseName=''"></span>
            </div>
          </div>
          <div class="left_content">
            <course-item v-for="(item,index) in vApplyCourseNameList"
                         :key="index"
                         dir="ltr"
                         :courseItem="item"
                         @clickCourse="doAfterClickCourse"></course-item>
            <div v-if="vApplyCourseNameList.length==0"
                 class="course_block_nodata"
                 style="background-size:100px">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
        <div v-if="curSelectedCourseInfo.OLAPKey"
             class="course_class_summarizing_right">
          <div class="right_top clearfix">
            <div class="right_list_title">
              <div class="right_list_title_icon fl">课</div>
              <div class="fl">{{curSelectedCourseInfo.MainDemoName}}</div>
            </div>
          </div>
          <!-- 汇总数据 -->
          <div class="flex">
            <div class="right_top_box box_left">
              <summary-data-item v-for="(item,key,index) in summaryDataForCourse"
                                 :sumData="item"
                                 :key="index"></summary-data-item>
            </div>
            <div class="right_top_box box_right fr">
              <summary-data-item v-for="(item,key,index) in summaryDataForHour"
                                 :sumData="item"
                                 :key="index"
                                 @onCilckEvent="afterCilckSummaryData"></summary-data-item>
            </div>
          </div>
          <div class="flex">
            <!-- 循环课 -->
            <circle-course-table ref="circleCourseTable"
                                 :courseNameKey="curSelectedCourseInfo.OLAPKey"
                                 :courseName="curSelectedCourseInfo.MainDemoName"
                                 :taoCanKey="taoCanKey"
                                 :isStopOfCourse="curSelectedCourseInfo.StateKey==3"
                                 @openCustomDialog="openCustomDialogByFactoryDialog"
                                 @updateRuleCourseBlock="afterUpdateRuleCourseBlock"></circle-course-table>
            <div class="right_table">
              <div class="right_table_box clearfix">
                <div class="summarizing_number_left fl"
                     style="padding-left:0">
                  <span>在读学生</span>
                  <span class="font_number">({{inSchooldStudentNum}})</span>
                </div>

                <div class="content_search fr"
                     style="margin-top:8px">
                  <input-search-contain-btn v-model="paramsForStudent.searchText"
                                            :placeholder="'请输入姓名、姓名首字母、手机号'"
                                            :clearAll="true"
                                            @onSearchEvent="searchStudent"
                                            @clearSearch="clearSearchCondition"></input-search-contain-btn>
                </div>

              </div>
              <!-- 报课明细总览 -->
              <table-list class="table_list_content summarizing_list"
                          ref="tableListRef"
                          :tableData="studentList"
                          :tableColumns="tableColumns"
                          :totalNum="totalNum"
                          :defaultSort="defaultSort"
                          :queryParams="paramsForStudent"
                          @loadTableData="getSummariseApplyClassTicketPlanDetial"></table-list>
            </div>
          </div>
        </div>
        <div v-else
             class="course_class_summarizing_right">
          <div class="course_block_nodata"
               style="margin-top:250px;background-position:center 70px;background-size:100px">
            <div style="padding-top: 150px;"
                 class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多学生排课 -->
    <students-courses-arranging ref="studentsCoursesArranging"
                                @arrangeMutilStudentSuccess="doAfterArrangeOrApplyCourseSuccess"></students-courses-arranging>
    <div v-if="!taoCanKey">
      <dialog-factory ref="courseNameApplyCourseReportDialogFactory"
                      :routerName="'courseNameApplyCourseReport'"></dialog-factory>
      <dialog-factory-common ref="courseNameApplyCourseReportDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  MessageSearchTicketPlanPageForApplyClass,
  SumarizehTaocanApplyClassTicketPlan,
  GetTheClassType,
  SumarizehApplyClassTicketPlan,
  GetTheApplyClassToBePlaned,
  MessageSearchTicketPlanPageForTaoCanApplyClass
} from '../../../API/workbench.js';

import tableList from '../../common/table-list/index';
import courseItem from './course-item';
import circleCourseTable from './circle-course-table';
import dialogFactory from '../../common/dialog-factory';
import studentCoursesArranging from '../../pop-up-menu/student-courses-arranging/index';
import studentsCoursesArranging from '../../pop-up-menu/students-courses-arranging/index';
import summaryDataItem from '../summary-data/item';
import inputSearchContainBtn from '../common/input-search-contain-btn';
export default {
  name: 'courseNameApplyCourseReport',
  data () {
    return {
      courseNameKey: 0,
      applyCourseNameList: [], // 课名列表（有学生报课的课名）
      curSelectedCourseInfo: {}, // 当前选中课名
      paramsForStudent: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 20, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'StudentKeyValue', // 排序字段
        sequence: 'asc', // 排序字段
        ApplyClassKey: 0, // 课程ID
        SpecialFilter: 0, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
        QueryID: 0, // 整型	可选	0	数据源：GET	学生ID
        noMore: false // 没有更多数据加载了

      },
      studentList: [], // 按学生 的 学生列表
      tableColumns: [
        {
          label: '学生',
          prop: 'StudentKeyValue',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          width: 130,
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '当前报课',
          prop: 'Sum_CurrentCount',
          type: 'class-hour',
          sortable: 'custom',
          width: 84
        },
        {
          label: '当前排课',
          prop: 'Sum_ArrangedCount',
          type: 'class-hour',
          sortable: 'custom',
          width: 84
        },
        {
          label: '待排课时',
          prop: 'Sum_ToBeArranged',
          type: 'class-hour',
          width: 84,
          sortable: true,
          extend: {
            click: (rowData) => { this.studentArrangCourses(rowData); }
          }
        },
        {
          label: '待报课时',
          prop: 'Total_ToBePlaned',
          type: 'class-hour',
          sortable: 'custom',
          width: 84,
          extend: {
            click: (rowData) => { this.studentApplyCourse(rowData); },
            className: 'total_to_beplaned'
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '报课',
                extend: {
                  // setClassName: (rowData) => {
                  //   if (Number(rowData.Total_ToBePlaned) <= 0) {
                  //     return 'btn_light_gray';
                  //   }
                  // },
                  click: (rowData) => { this.studentApplyCourse(rowData); },
                  ModulePowerKey: 51
                }
              },
              {
                name: '排课',
                extend: {
                  // setClassName: (rowData) => {
                  //   if (Number(rowData.Sum_ToBeArranged) <= 0) {
                  //     return 'btn_light_gray';
                  //   }
                  // },
                  click: (rowData) => { this.studentArrangCourses(rowData); },
                  ModulePowerKey: 51
                }
              }
            ]
          }
        }
      ],
      defaultSort: { prop: 'StudentKeyValue', order: 'ascending' }, // table 默认排序
      PageCount: 0,
      totalNum: 0,
      searchTextForCourseName: '',
      isLoadding: true,
      summaryDataForCourse: {
        weekCircleCourse: { title: '周循环课', className: '', value: 0, dataType: 'number', decimalPlace: 0 },
        circleCourseNotEnough: { title: '循环课不足', className: '', value: 0, dataType: 'number', decimalPlace: 0 }
      },
      summaryDataForHour: {
        inSchoolStudent: { title: '在读学生', opt: '在读学生', className: 'font_blue', value: 0, type: 'inSchoolStudent', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: true },
        SumCurrentCount: { title: '当前报课', className: '', value: 0, dataType: 'number', decimalPlace: 1 },
        NumToBeArranged: { title: '待排人数', className: 'font_blue', value: 0, type: 'NumToBeArranged', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        NumPlanCount: { title: '待报人数', className: 'font_blue', value: 0, type: 'NumPlanCount', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        NumNotEnought: { title: '课时不足', className: 'font_blue', value: 0, type: 'NumNotEnought', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }
      },
      curSelectedSummaryData: {},
      tableKey: '0',
      inSchooldStudentNum: 0
    };
  },
  props: {
    taoCanKey: [Number, String]
  },
  components: {
    dialogFactory,
    tableList,
    courseItem,
    studentCoursesArranging,
    studentsCoursesArranging,
    summaryDataItem,
    circleCourseTable,
    inputSearchContainBtn
  },
  created () {
    this.courseNameKey = this.$route.params.dataKey;
    this.curSelectedSummaryData = this.summaryDataForHour.inSchoolStudent;
    this.loadData();
    this.registerBusEvent();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.$nextTick(() => {
        if (this.$refs.circleCourseTable) {
          this.$refs.circleCourseTable.isShowItem = false;
        }
      });
    }, true);
  },
  watch: {
    'curSelectedCourseInfo.OLAPKey': {
      handler (n, o) {
        if (n > 0 && n != o) {
          this.initSummaryData();
          this.tableKey = n;
        }
      },
      deep: true
    },
    'paramsForStudent.SpecialFilter': {
      handler (n, o) {
        if (n == 0 && n != o) {
          this.curSelectedSummaryData.isSelected = false;
          this.summaryDataForHour.inSchoolStudent.isSelected = true;
          this.curSelectedSummaryData = this.summaryDataForHour.inSchoolStudent;
        }
      },
      deep: true
    }
  },
  computed: {
    vApplyCourseNameList () {
      let newList = [];
      if (this.searchTextForCourseName) {
        this.searchTextForCourseName = this.searchTextForCourseName.replace(/ /g, '');
        let trueSearchText = this.searchTextForCourseName.toLowerCase();
        this.applyCourseNameList.forEach(obj => {
          if (obj.MainDemoName.toLowerCase().indexOf(trueSearchText) > -1) {
            newList.push(obj);
          }
        });
      } else {
        newList = this.applyCourseNameList;
      }
      return newList;
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  activated () {
    if (this.$route.params.dataKey && this.courseNameKey != this.$route.params.dataKey) {
      let findNewItem = this.applyCourseNameList.find(obj => {
        return obj.ApplyClassKey == this.$route.params.dataKey;
      });
      if (findNewItem) {
        this.$set(findNewItem, 'isActive', true);
        this.doAfterClickCourse(findNewItem);
      }
    }
  },
  methods: {
    loadData (type) {
      if (this.taoCanKey > 0) {
        this.paramsForStudent.TaoCanKey = this.taoCanKey;
        this.getAllCourseByTaoCanKey();
      } else {
        this.getAllCourse();
      }
    },
    getAllCourse () {
      this.isLoadding = true;
      SumarizehApplyClassTicketPlan().then(result => {
        if (result.data.length > 0) {
          let findItem = null;
          this.applyCourseNameList = result.data;
          if (JSON.stringify(this.curSelectedCourseInfo) == '{}') {
            if (this.courseNameKey) {
              findItem = this.applyCourseNameList.find(o => {
                return o.OLAPKey == this.courseNameKey;
              });
              if (!findItem) {
                this.getTheClassType();
                findItem = result.data[0];
              }
            } else {
              findItem = result.data[0];
            }
          } else {
            findItem = this.applyCourseNameList.find(o => {
              return (
                o.OLAPKey == this.curSelectedCourseInfo.OLAPKey
              );
            });
          }
          console.log(this.applyCourseNameList, '课程列表');
          this.curSelectedCourseInfo = findItem;
          this.$set(findItem, 'isActive', true);
          this.paramsForStudent.ApplyClassKey = findItem.OLAPKey;
          this.paramsForStudent.pageSize = 10;
          this.initSummaryData();
          // 默认选中第一个课名 查询该课下的学生情况
          this.getSummariseApplyClassTicketPlanDetial();
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    initSummaryData () {
      console.log('initSummaryData', this.curSelectedCourseInfo);
      this.summaryDataForCourse.weekCircleCourse.value = this.curSelectedCourseInfo.ClassCount;
      this.summaryDataForCourse.circleCourseNotEnough.value = this.curSelectedCourseInfo.NotEnoughCount;
      this.summaryDataForHour.inSchoolStudent.value = this.curSelectedCourseInfo.Total_Student;
      this.summaryDataForHour.SumCurrentCount.value = this.curSelectedCourseInfo.Sum_CurrentCount;
      this.summaryDataForHour.NumToBeArranged.value = this.curSelectedCourseInfo.Num_ToBeArranged;
      this.summaryDataForHour.NumNotEnought.value = this.curSelectedCourseInfo.Num_NotEnought;
      GetTheApplyClassToBePlaned(this.taoCanKey || '', this.curSelectedCourseInfo.OLAPKey).then(res => {
        this.summaryDataForHour.NumPlanCount.value = res.data && res.data.Num_PlanCount >= 0 ? res.data.Num_PlanCount : 0;
      });
      // 循环课不足
      if (this.curSelectedCourseInfo.NotEnoughCount <= 5) {
        this.summaryDataForCourse.circleCourseNotEnough.className = 'font_red';
      }
    },
    //  查询课程(根据套餐ID)
    getAllCourseByTaoCanKey () {
      this.isLoadding = true;
      SumarizehTaocanApplyClassTicketPlan(this.taoCanKey, '').then(result => {
        if (result.data.length > 0) {
          this.applyCourseNameList = result.data;
          if (this.applyCourseNameList.length > 0) {
            console.log(this.applyCourseNameList, 'this.根据套餐ID');
            if (JSON.stringify(this.curSelectedCourseInfo) == '{}') {
              this.curSelectedCourseInfo = this.applyCourseNameList[0];
              this.$set(this.applyCourseNameList[0], 'isActive', true);
              this.paramsForStudent.ApplyClassKey = this.applyCourseNameList[0].OLAPKey;
            } else {
              let findItem = this.applyCourseNameList.find(o => {
                return (
                  o.OLAPKey == this.curSelectedCourseInfo.OLAPKey
                );
              });
              this.curSelectedCourseInfo = findItem;
              this.$set(findItem, 'isActive', true);
            }
            this.paramsForStudent.pageSize = 10;
            this.initSummaryData();
            // 默认选中第一个课名 查询该课下的学生情况
            this.getSummariseApplyClassTicketPlanDetial();
          }
          // });
        }
      }).finally(() => {
        this.isLoadding = false;
      });
    },
    // 查询 选中课下的学生 接口
    getSummariseApplyClassTicketPlanDetial () {
      this.paramsForStudent.orderBy = (this.paramsForStudent.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.paramsForStudent.orderBy);
      let portName = '';
      if (Number(this.paramsForStudent.TaoCanKey) > 0) {
        portName = MessageSearchTicketPlanPageForTaoCanApplyClass;// 套餐
      } else {
        portName = MessageSearchTicketPlanPageForApplyClass;// 课程
      }
      portName(this.paramsForStudent).then(result => {
        this.totalNum = result.data.Total;
        this.studentList = result.data.PageDataList;
        this.studentList.forEach(o => {
          o.studentInfo = [o.StudentKeyValue, o.CustomerPhoneName];

          if (Number(o.Sum_SuperQuote) > 0) {
            o.Total_ToBePlaned_Warn = '超上';
            o.Total_ToBePlaned_Pop_Tips = '超上' + Math.abs(o.Sum_SuperQuote) + '课时';
          }
          if (Number(o.Sum_Overplace) > 0) {
            o.Sum_ToBeArranged_Warn = '超排';
            o.Sum_ToBeArranged_Pop_Tips = '超排' + Math.abs(o.Sum_Overplace) + '课时';
          }
          if (Number(o.Sum_CurrentCount) <= 5 && Number(o.Sum_CurrentCount) > 0) {
            o.Sum_CurrentCount_Warn = '不足';
            // o.Sum_CurrentCount_Pop_Tips = '不足' + Math.abs(o.Sum_CurrentCount) + '课时';
          }
        });
        if (this.paramsForStudent.SpecialFilter == 0 && this.paramsForStudent.searchText == '') {
          this.inSchooldStudentNum = this.totalNum;
        }
      });
    },
    // 点击课程
    doAfterClickCourse (courseItem) {
      if (this.$utils.isObjEqual(this.curSelectedCourseInfo, courseItem)) {
        return false;
      }
      this.$set(this.curSelectedCourseInfo, 'isActive', false);
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.searchText = '';
      this.curSelectedCourseInfo = courseItem;
      this.paramsForStudent.ApplyClassKey = courseItem.OLAPKey;
      this.paramsForStudent.pageIndex = 0;
      if (this.$refs.table) {
        this.$refs.table.queryParams.pageIndex = 0;
      }
      this.getSummariseApplyClassTicketPlanDetial();
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    // 学生排课
    studentArrangCourses (rowData) {
      console.log(rowData, '学生排课');
      this.studentKey = rowData.StudentKey;
      // if (Number(rowData.Sum_ToBeArranged) > 0) {
      let moduleInfo = {
        routerName: this.$route.name,
        routertitle: this.$route.meta.title,
        moduleName: 'studentArrangeCourseByCourseName',
        name: '按课程排课',
        data: {
          studentID: this.studentKey,
          courseNameID: this.curSelectedCourseInfo.OLAPKey,
          courseName: this.curSelectedCourseInfo.MainDemoName
        },
        callBack: { afterCancelAllArrangeSuccess: () => { this.loadData(); } }
      };
      this.$dialog.open(this, moduleInfo);
      // }
    },
    // 学生报课
    studentApplyCourse (rowData) {
      this.studentKey = rowData.StudentKey;
      // if (Number(rowData.Total_ToBePlaned) > 0) {
      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: 'courseApplycourse',
        name: '课程报课',
        data: { studentKey: this.studentKey, applyClassID: this.curSelectedCourseInfo.OLAPKey }
      };
      this.$dialog.open(this, moduleInfo);
      // }
    },
    // 搜索
    searchStudent () {
      this.paramsForStudent.searchText = this.paramsForStudent.searchText.replace(/ /g, '');
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.pageIndex = 0;
      this.getSummariseApplyClassTicketPlanDetial();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.pageIndex = 0;

      this.getSummariseApplyClassTicketPlanDetial();
    },
    // 多学生安排
    arrangeClick () {
      this.$refs.studentsCoursesArranging.showBox = true;
      this.$refs.studentsCoursesArranging.selectClassName(
        this.curSelectedCourseInfo.OLAPKey
      );
    },
    //  多学生安排 成功
    doAfterArrangeOrApplyCourseSuccess () {
      if (this.taoCanKey > 0) {
        this.getAllCourseByTaoCanKey();
      } else {
        this.getAllCourse();
      }
      this.getSummariseApplyClassTicketPlanDetial();
      this.$emit('arrangeOrApplyCourseSuccess');
    },
    getTheClassType () {
      GetTheClassType(this.courseNameKey).then(result => {
        layer.alert(result.data.MainDemoName + '课程暂无排课数据');
      });
    },
    // 通过汇总数据过滤
    afterCilckSummaryData (item) {
      if (!item.value || item.value == 0 || item.title == '当前报课') {
        return false;
      }
      this.curSelectedSummaryData.isSelected = false;
      item.isSelected = true;
      this.curSelectedSummaryData = item;

      switch (item.type) {
        case 'inSchoolStudent':
          this.paramsForStudent.SpecialFilter = '0';
          break;
        case 'NumToBeArranged':
          this.paramsForStudent.SpecialFilter = '1';
          break;
        case 'NumPlanCount':
          this.paramsForStudent.SpecialFilter = '2';
          break;
        case 'NumNotEnought':
          this.paramsForStudent.SpecialFilter = '3';
          break;
        default:
          break;
      }
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.getSummariseApplyClassTicketPlanDetial();
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        if (studentKey > 0) {
          this.loadData();
        }
      });
      // 刷新排课数据
      this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
        this.loadData();
      });
      this.$bus.on(this, 'AfterStudentApplyCourse', applyCourseInfo => {
        this.loadData();
      });
      this.$bus.on(this, 'AfterArrangeForSingleStudent', (type, courseID, studentID) => {
        this.loadData();
      });
      this.$bus.on(this, 'AfterCourseNameApplyCourse', () => {
        this.loadData();
      });
      this.$bus.on(this, 'AfterAbortCircleCorse', (ruleID) => {
        this.getSummariseApplyClassTicketPlanDetial(); // 终止循环节
      });

      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseNameApplyCourseReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseNameApplyCourseReport') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    // 对 循环课进行操作后
    afterUpdateRuleCourseBlock (courseNameID) {
      SumarizehApplyClassTicketPlan(courseNameID).then(result => {
        let findIndex = this.applyCourseNameList.findIndex(obj => { return obj.OLAPKey == result.data[0].OLAPKey; });
        if (findIndex > -1) {
          this.applyCourseNameList.splice(findIndex, 1, result.data[0]);
        } else {
          this.applyCourseNameList.unshift(result.data[0]);
        }
        if (this.curSelectedCourseInfo.OLAPKey == result.data[0].OLAPKey) {
          this.curSelectedCourseInfo = result.data[0];
          this.$set(this.curSelectedCourseInfo, 'isActive', true);
          this.initSummaryData();
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseNameApplyCourseReportDialogFactory) {
        this.$refs.courseNameApplyCourseReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseNameApplyCourseReportDialogFactoryCommon) {
        this.$refs.courseNameApplyCourseReportDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
