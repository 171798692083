<template>
  <div class="chainstore_manage_statistics">
    <div class="chainstore_manage_statistics_left">
      <div class="chainstore_manage_statistics_title">
        <span>实时数据</span>
        <span class="font_gray">{{month}}</span>
      </div>
      <div class="chainstore_manage_statistics_box">
        <div class="statistics_list">
          <div class="statistics_name">报名(万元)</div>
          <div class="statistics_content">
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(Number(summaryInfo.YesterdayApplyAmount)/ 10000,1)||0}}
              </div>
              <div class="statistics_text">
                昨天
              </div>
            </div>
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(Number(summaryInfo.ThisMonthApplyAmount)/ 10000,1)||0}}
              </div>
              <div class="statistics_text">
                本月
              </div>
            </div>
          </div>
        </div>
        <div class="statistics_list">
          <div class="statistics_name">课消(万元)</div>
          <div class="statistics_content">
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(Number(summaryInfo.YesterdayClassAmount)/ 10000,1)||0}}
              </div>
              <div class="statistics_text">
                昨天
              </div>
            </div>
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(Number(summaryInfo.ThisMonthClassAmount)/ 10000,1)||0}}
              </div>
              <div class="statistics_text">
                本月
              </div>
            </div>
          </div>
        </div>
        <div class="statistics_list">
          <div class="statistics_name">出勤率</div>
          <div class="statistics_content">
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(summaryInfo.YesterdaySignRate*100,0)||0}}{{Number(summaryInfo.YesterdaySignRate)>0?'%':''}}
              </div>
              <div class="statistics_text">
                昨天
              </div>
            </div>
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(summaryInfo.ThisMonthSignRate*100,0)||0}}{{Number(summaryInfo.ThisMonthSignRate)>0?'%':''}}
              </div>
              <div class="statistics_text">
                本月
              </div>
            </div>
          </div>
        </div>
        <div class="statistics_list">
          <div class="statistics_name">未考勤(人次)</div>
          <div class="statistics_content">
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(summaryInfo.YesterdayUnAttendanceNum,0)||0}}
              </div>
              <div class="statistics_text">
                昨天
              </div>
            </div>
            <div class="flex_1">
              <div class="statistics_number">
                {{this.$utils.mAmountType(summaryInfo.ThisMonthUnAttendanceNum,0)||0}}
              </div>
              <div class="statistics_text">
                本月
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chainstore_manage_statistics_right">
      <div class="chainstore_manage_statistics_title">
        <span>直营规模</span>
      </div>
      <div class="chainstore_manage_statistics_box">
        <div class="info_list type_01">
          <div class="info_name">直营校区</div>
          <div class="info_text">
            <span> {{this.$utils.mAmountType(summaryInfo.RetailStoresCount,0)||0}}</span>
            <span class="font_gray">家</span>
          </div>
        </div>
        <div class="info_list type_02">
          <div class="info_name">在职员工</div>
          <div class="info_text">
            <span> {{this.$utils.mAmountType(summaryInfo.OnJobEmployeesCount,0)||0}}</span>
            <span class="font_gray">人</span>
          </div>
        </div>
        <div class="info_list type_03">
          <div class="info_name">在校学生</div>
          <div class="info_text">
            <span> {{this.$utils.mAmountType(summaryInfo.ActivityStudentCount,0)||0}}</span>
            <span class="font_gray">人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import summarizingBox from '../common/summarizing-box';
import summarizingSingleItem from '../common/summarizing-single-item';
import { GetRetailStoresScaleStatistical } from '../../../../API/workbench.js';
export default {
  components: {
    summarizingBox,
    summarizingSingleItem
  },
  data () {
    return {
      month: this.$utils.formatDateToLine(Date.new()).slice(0, 7),
      summaryInfo: {}// 汇总数据
    };
  },
  created () {
    this.initSummaryData();
  },
  methods: {
    // 汇总数据
    initSummaryData () {
      GetRetailStoresScaleStatistical().then(result => {
        console.log(result.data, '汇总数据');
        this.summaryInfo = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAfterCilckSummaryData () {

    }
  }
};
</script>

<style>
</style>