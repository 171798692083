<template>
  <!-- 总部-售后客户明细表 -->
  <div class="headquarters_details">
    <div class="table_list_content summarizing_list">
      <div class="single_result_detail after_sale_detail">
        <div class="after_sale_list">
          <div class="customer_table_follow">

            <div class="customer_table_box">
              <div class="table_title">教务：</div>
              <div class="table_name cp"
                   @click="doReportStatisticsClick(1)">
                <span class="font_gray">剩</span>
                <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.CurrentPortalNum,0)||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">临</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.ExpAcademicCount,0)||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">存</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.AcademicCount,0)||0}}</span>
              </div>
            </div>

            <div class="customer_table_box">
              <div class="table_title">课件：</div>
              <div class="table_name cp"
                   @click="doReportStatisticsClick(2)">
                <span class="font_gray">剩</span>
                <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.CoursewarePortalCount,0)||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">临</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.ExpCoursewareCount,0)||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">存</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.CoursewareCount,0)||0}}</span>
              </div>
            </div>

            <div class="customer_table_box">
              <div class="tab_width">当前流量：<span :class="{highlighted_font: Number(reportStatistics.FluxCount) < 0}">{{trafficUseStatus(reportStatistics.FluxCount)}}</span></div>
            </div>

            <div class="customer_table_box">
              <div class="tab_width">当前已用空间：{{$utils.flowFormate(reportStatistics.UsedSpaceCount)}}</div>
            </div>

            <div class="customer_table_box">
              <div class="tab_width">当前总空间：{{$utils.flowFormate(reportStatistics.CurrentSpaceCount)}}</div>
            </div>

          </div>
        </div>

        <div class="flex">
          <div class="table_select_box date_box">
            开户日期
          </div>
          <div style="padding-right: 38px;">
            <date-range ref="monthPicker"
                        :fromTitle="''"
                        :searchObj="searchObj"
                        @changeDate="changeDredgeDate"></date-range>
          </div>
          <!-- 已选条件 -->
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入门店名称'"
                                    @clearSearch="clearExtendSearchParams"
                                    @onSearchEvent="search"></input-search-contain-btn>
          <!-- <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin-left: 630px;"
                 @click.stop="doExportInfoClick">导出</div>
          </div> -->
        </div>
        <table-list ref="tableListRef"
                    class="customer_mendian_list"
                    :tableData="mendianQueryList"
                    :tableColumns="tableColumns"
                    :rowKey="'Number'"
                    :totalNum="totalNum"
                    :footerContent="'共' + totalNum + '条数据'"
                    :defaultSort="defaultSort"
                    :queryParams="queryParams"
                    :heightValue="'100%'"
                    @loadTableData="getMessageSearchPageForAfterDetailsSale"></table-list>
      </div>
      <custom-dialog :title="'转介绍'"
                     width="986px"
                     :visible.sync="isShowIntroduceCountPopup"
                     :before-close="closeIntroduceCountPopup">
        <introduce-list :threadInfo="threadInfo"
                        @closeFrom="getMessageSearchPageForAfterDetailsSale"></introduce-list>
      </custom-dialog>

      <custom-dialog title="查看员工"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowStaffListDialog"
                     :before-close="doCloStaffList">
        <staff-list :threadInfo="threadInfo"></staff-list>
      </custom-dialog>

      <custom-dialog :title="'跟进情况'"
                     :visible.sync="isShowUpSellRecordFormPopup"
                     :before-close="closeUpSellRecordFormPopup">
        <up-sell-record :dataInfo="threadInfo"
                        @close="closeUpSellRecordFormPopup"
                        @afterSuccess="getMessageSearchPageForAfterDetailsSale"></up-sell-record>
      </custom-dialog>

      <custom-dialog title="全部记录"
                     :visible.sync="isShowRecord"
                     :before-close="closeisShowRecord">
        <recordList :recordList="recordDataList"></recordList>
      </custom-dialog>

      <custom-dialog title="端口充值消费流水"
                     width="1200px"
                     :visible.sync="isShowPortExpenseBillList"
                     :before-close="closeisShowPortExpenseBillList">
        <port-expense-bill-list :mendianId="mendianId"
                                :portType="portType"></port-expense-bill-list>
      </custom-dialog>

      <custom-dialog :title="'付款申请'"
                     :visible.sync="isShowPaymentRequestFormPopup"
                     :before-close="closeFormPopup">
        <payment-request :seletedItem="threadInfo"
                         :paymentType="3"
                         @close="closeFormPopup"
                         @afterSuccess="getMessageSearchPageForAfterDetailsSale"></payment-request>
      </custom-dialog>

      <custom-dialog :title="'清数据'"
                     :visible.sync="isShowCleanDataFormPopup"
                     :before-close="closeFormPopup">
        <clean-data :seletedItem="threadInfo"
                    @close="closeFormPopup"
                    @afterSuccess="getMessageSearchPageForAfterDetailsSale"></clean-data>
      </custom-dialog>

      <custom-dialog title="导出"
                     :visible.sync="isShowExportPopup"
                     :before-close="closeFormPopup">
        <export-form :seletedItem="threadInfo"
                     @close="closeFormPopup"
                     @afterSuccess="getMessageSearchPageForAfterDetailsSale"></export-form>
      </custom-dialog>
      <custom-dialog title="流量赠送"
                     :visible.sync="isShowFlowGivePopup"
                     :before-close="closeFlowFormPopup">
        <flowgive-form :seletedItem="threadInfo"
                       @close="closeFlowFormPopup"
                       @afterSuccess="getMessageSearchPageForAfterDetailsSale"></flowgive-form>
      </custom-dialog>

      <custom-dialog :title="'修改客户信息'"
                     width="640px"
                     :visible.sync="isShowAddCustomerFormPopup"
                     :before-close="closeFormPopup">
        <customer-thread :OLAPKey="threadInfo.OLAPKey"
                         :source="sourceInfo"
                         @closeFrom="getMessageSearchPageForAfterDetailsSale"></customer-thread>
      </custom-dialog>
    </div>
  </div>
</template>
<script>
import { MessageSearchPageForAfterDetailsSale, SelectFollowRecode, GetAfterSalesDetailsReport, UpdateGradeStar, UpdateGradeV, ExportXYKD } from '../../../../API/workbench.js';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import staffList from '../components/form-components/staff-list';
import marketList from '../components/form-components/market-list';
import introduceList from './introduce-list';
import customerThread from '../components/form-components/customer-thread';
import temporaryAccountOpening from '../components/form-components/temporary-account-opening';
import upSellRecord from '../components/form-components/up-sell-record';
import recordList from '../components/form-components/up-sell-record-component/record-list';
import cleanData from '../components/form-components/clean-data';
import paymentRequest from '../components/form-components/payment-request';
import portExpenseBillList from './port-expense-bill-list';
import exportForm from '../components/form-components/export-form';
import flowgiveForm from '../components/form-components/flowgive-form';
const queryParamsInfo = {
  pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
  pageSize: 10, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
  orderBy: '', //	字符串	可选		数据源：GET	排序字段
  sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
  searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
  IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
  FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
  FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
  DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
  DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
  StartDredgeTime: '', // 字符串	可选		数据源：GET	开户时间开始
  EndDredgeTime: '', // 字符串	可选		数据源：GET	开户时间结束
  StartExpireTime: '', // 续费日期起
  EndExpireTime: '', // 续费日期止
  DocumentaryKey: '', //	字符串	可选		数据源：GET	销售人id
  DocumentaryKeyValue: '',
  IsUseKey: '', //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
  IsGradeStarKey: '', //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
  IsGradeVKey: '', //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
  StartCount: '', //	整型	可选		数据源：GET	介绍起始数
  EndCount: '', //	整型	可选		数据源：GET	介绍终止数
  StartBargain: '', //	整型	可选		数据源：GET	成交起始数
  EndBargain: '', //	整型	可选		数据源：GET	成交起始数
  StartStudentCount: '', //	整型	可选		数据源：GET	学生起始数
  EndStudentCount: '', //	整型	可选		数据源：GET	学生起始数
  StayBack: '', //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
  Renew: '', //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
  IsBalance: '', //	整型	可选		数据源：GET	是否有尾款 0-否 1-是
  AttenRate: '', //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
  OLAPKey: ''
};
export default {
  components: {
    tableList,
    inputSearchContainBtn,
    customerThread,
    introduceList,
    staffList,
    marketList,
    temporaryAccountOpening,
    upSellRecord,
    recordList,
    cleanData,
    paymentRequest,
    portExpenseBillList,
    exportForm,
    flowgiveForm
  },
  props: {
    mendianId: [String, Number], // 门店id
    isShowOperate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // 查询参数
      queryParams: {},
      mendianQueryList: [], // 门店查询列表
      mendianStatisticsInfo: {}, // 统计信息
      threadInfo: {}, // 线索信息
      isShowIntroduceCountPopup: false, // 转介绍列表
      isShowStaffListDialog: false, // 员工列表
      isShowUpSellRecordFormPopup: false, // 跟进情况表单
      isShowRecord: false, // 记录表单
      isShowPortExpenseBillList: false,
      isShowCleanDataFormPopup: false, // 清数据
      isShowPaymentRequestFormPopup: false, // 付款申请
      isShowExportPopup: false, // 导出
      isShowAddCustomerFormPopup: false,
      isShowFlowGivePopup: false, // 流量赠送
      recordDataList: [],
      // 类型
      sourceInfo: {},
      // 日期
      dateItemValue: '开户日期', // 默认选中开户日期
      reportStatistics: {
        FluxCount: 0,
        UsedSpaceCount: 0,
        CurrentSpaceCount: 0
      }, // 统计数据
      // 日期
      searchObj: {
        searchText: '', // 搜索
        startTime: '',
        endTime: ''
      },
      portType: '', // 端口类型
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'sell-documentary-table-row',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '要紧',
          prop: 'IsGradeStarKey',
          width: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: true,
            click: (rowData, val) => {
              this.doImportantClick(rowData, val);
            }
          }
        },
        {
          label: '机构名',
          prop: 'SaaSClientNameKeyValue',
          width: 110,
          align: 'left',
          sortable: false,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: true,
            click: (rowData) => {
              this.doOrganizationClick(rowData);
            }
          }
        },
        {
          label: '门店功能',
          prop: 'storeFunction',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店类型',
          prop: 'TypeKeyValue', // SaaSClientCountText
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店版本',
          prop: 'EditionKeyValueText',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '联系人/手机号',
          prop: 'ContactsName',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '介绍',
          prop: 'IntroduceCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: true,
            className: 'font_blue',
            click: (rowData) => {
              this.doIntroduceCountClick(rowData);
            }
          }
        },
        {
          label: '待返',
          prop: 'WaitReturnCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '跟进情况描述',
          prop: 'FTime',
          align: 'left',
          width: 250,
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            // isEdit: true,
            isClick: true,
            click: (rowData) => {
              this.doMarketFollowRecodeClick(rowData);
            }
          }
        },
        {
          label: '下次跟进',
          prop: 'FollowTime',
          width: 90,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: 'custom',
          extend: {
            isEdit: true,
            isClick: true,
            click: (rowData) => {
              this.doFollowTimeClick(rowData);
            }
          }
        },
        {
          label: '员工',
          prop: 'AgentCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: true,
            className: 'font_blue',
            click: (rowData) => {
              this.doAgentClick(rowData);
            }
          }
        },
        {
          label: '运行情况',
          prop: 'SaaSClientPort',
          width: 180,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          width: 110,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '开户',
          prop: 'DredgeTime',
          width: 150,
          align: 'left',
          sortable: 'custom',
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '合同付款',
          prop: 'TotalAmountText',
          width: 90,
          align: 'left',
          sortable: false,
          decimalPlace: 2,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '尾款',
          prop: 'BalanceAmount',
          width: 80,
          align: 'left',
          sortable: false,
          decimalPlace: 2,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false,
            className: 'font_red'
          }
        },
        {
          label: '流量空间',
          prop: 'FluxCount',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 220,
          isShow: this.isShowOperate,
          type: 'sell-documentary-table-btn',
          align: 'left',
          extend: {
            tableBtnGroup: [
              {
                extend: {
                  name: (rowData) => { // 付款中 （子门店为课件门店时不支持付款申请）
                    return rowData.PayAuditKey > 0 ? '付款中' : '付款';
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.PayAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('FK', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 开户中 总部不支持操作
                    if (rowData.StatusKey != 0 && Number(rowData.SaaSClientCount) <= 1) {
                      return rowData.ClearAuditKey > 0 ? '清数据中' : '清数据';
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.ClearAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('QSJ', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 导出 总部不支持操作
                    if (rowData.StatusKey != 0 && Number(rowData.SaaSClientCount) <= 1) {
                      if (rowData.ExportExpirTime && rowData.ExportExpirTime >= this.$utils.formatDateToLine(Date.new()) && rowData.ExportAuditKey == 0) {
                        return '可导出';
                      } else if (!rowData.ExportExpirTime && rowData.ExportAuditKey > 0) {
                        return '导出中';
                      } else {
                        return '导出';
                      }
                    } else {
                      return '';
                    }
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('DC', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 流量赠送
                    return Number(rowData.GiveFluxApplyKey) > 0 ? '流量赠送中' : '流量赠送';
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.GiveFluxApplyKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('LLZS', rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0, // 分页器总数
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
    this.queryParams = queryParamsInfo;
    if (this.mendianId) {
      this.queryParams.OLAPKey = this.mendianId;
    }
  },
  mounted () {
    this.getMessageSearchPageForAfterDetailsSale();
  },
  computed: {

  },
  methods: {
    // 获取售前跟单统计
    getAfterSalesReportForManage () {
      if (this.mendianId > 0) {
        GetAfterSalesDetailsReport(this.mendianId).then(result => {
          console.log(result.data, '售后-获取售前详情跟单统计');
          this.reportStatistics = result.data;
        });
      }
    },
    // 管理与统计-》售后跟单
    getMessageSearchPageForAfterDetailsSale (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.searchText = this.queryParams.searchText.trim();
      this.getAfterSalesReportForManage();// 统计售后跟单
      MessageSearchPageForAfterDetailsSale(this.queryParams).then(result => {
        console.log('售后跟单', result.data);
        this.isShowAddCustomerFormPopup = false;
        this.totalNum = result.data.Total;
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.dredgeType = 1;// 售后
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.CompleteCount = Number(o.CompleteCount);
            o.IntroduceCount = o.CompleteCount + '/' + Number(o.IntroduceCount);
            // 根据学生数显示考勤率
            if (Number(o.StudentCount) > 0) {
              // 考勤人数+考勤率
              o.AttendText = Number(o.AttendCount) + ' | ' + (Number(o.AttendRate) > 50 ? Number(o.AttendRate) + '%' : `<span class="font_red">${Number(o.AttendRate) + '%'}</span>`);
            } else {
              o.AttendText = '';
            }
            if (o.TotalAmount && o.StrokeCount) {
              o.TotalAmountText = this.$utils.mAmountType(o.TotalAmount, 2) + ' ' + o.StrokeCount + '笔';
            }
            o.BalanceAmount = Number(o.BalanceAmount) > 0 ? o.BalanceAmount : '-';
            switch (Number(o.EditionKey)) { // 2标准 3旗舰 4高级 5总部 6课件
              case 2:
                o.EditionKeyValueText = '精准版';
                break;
              case 3:
                o.EditionKeyValueText = '旗舰版';
                break;
              case 4:
                o.EditionKeyValueText = '高级版';
                break;
              case 5:
                o.EditionKeyValueText = '连锁版';
                break;
              case 6:
                o.EditionKeyValueText = '课件版';
                break;
              default:
                break;
            }
          });
          console.log('tableColumns', this.tableColumns[this.tableColumns.length - 1].extend.tableBtnGroup);
          this.mendianQueryList = result.data.PageDataList;
        } else {
          this.mendianQueryList = [];
        }
        document.querySelector('.popup_dialog .el-table__body-wrapper ').scrollTop = 0;// 默认滚动条置顶
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 点击机构名操作 SourceTypeKey
    doOrganizationClick (rowData) {
      this.threadInfo = this.$utils.parseJson(rowData);
      console.log(rowData, '操作菜单');
      this.sourceInfo = {
        type: rowData.SourceTypeKey,
        name: rowData.SourceTypeKeyValue,
        organizationName: rowData.SaaSClientNameKeyValue,
        organizationId: rowData.SaaSClientNameKey
      };
      this.isShowAddCustomerFormPopup = true;
    },
    // 转介绍表单
    doIntroduceCountClick (rowData) {
      console.log(rowData, '转介绍表单');
      this.threadInfo = rowData;
      this.isShowIntroduceCountPopup = true;
    },
    // 关闭转介绍表单
    closeIntroduceCountPopup () {
      this.isShowIntroduceCountPopup = false;
    },
    // 员工表单
    doAgentClick (rowData) {
      console.log(rowData, '员工');
      this.threadInfo = rowData;
      this.isShowStaffListDialog = true;
    },
    // 关闭员工表单
    doCloStaffList () {
      this.isShowStaffListDialog = false;
    },
    // 点击跟进情况描述操作
    doMarketFollowRecodeClick (rowData) {
      this.threadInfo = rowData;
      SelectFollowRecode(this.threadInfo.OLAPKey).then(result => {
        this.recordDataList = result.data;
        console.log('SelectFollowRecode', result.data);
        this.isShowRecord = true;
      }, error => {
        layer.alert(error.msg);
      });

      console.log(rowData.Number, '操作菜单');
    },
    // 关闭跟进情况描述表单
    closeisShowRecord () {
      this.isShowRecord = false;
    },
    // 点击下次跟进操作
    doFollowTimeClick (rowData) {
      this.threadInfo = rowData;
      this.isShowUpSellRecordFormPopup = true;
      console.log(rowData.Number, '操作菜单');
    },
    closeUpSellRecordFormPopup () {
      this.isShowUpSellRecordFormPopup = false;
    },
    // 打开充值消费流水列表
    doReportStatisticsClick (type) {
      this.portType = type;
      this.isShowPortExpenseBillList = true;
    },
    closeisShowPortExpenseBillList () {
      this.isShowPortExpenseBillList = false;
    },
    //  开户日期筛选
    changeDredgeDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.pageIndex = 0;
    },
    // 要紧
    doImportantClick (rowData, val) {
      console.log(rowData, val, '要紧');
      if (val == 'IsGradeStarKey') {
        let IsGradeStarKey = Number(rowData.IsGradeStarKey) == 1 ? 0 : 1;
        let IsGradeStarKeyValue = Number(IsGradeStarKey) == 1 ? '是' : '否';
        UpdateGradeStar(rowData.OLAPKey, IsGradeStarKey, IsGradeStarKeyValue).then(result => {
          console.log(result.data, '星级');
          this.getMessageSearchPageForAfterDetailsSale();
        });
      } else if (val == 'IsGradeVKey') {
        let IsGradeVKey = Number(rowData.IsGradeVKey) == 1 ? 0 : 1;
        let IsGradeVKeyValue = Number(IsGradeVKey) == 1 ? '是' : '否';
        UpdateGradeV(rowData.OLAPKey, IsGradeVKey, IsGradeVKeyValue).then(result => {
          console.log(result.data, 'V级');
          this.getMessageSearchPageForAfterDetailsSale();
        });
      }
    },
    // 搜索
    search () {
      this.initializeData('SS');
      this.queryParams.searchText = this.searchObj.searchText;
      if (this.dateItemValue == '开户日期') {
        this.queryParams.StartDredgeTime = this.searchObj.startTime;
        this.queryParams.EndDredgeTime = this.searchObj.endTime;
      } else {
        this.queryParams.StartExpireTime = this.searchObj.startTime;
        this.queryParams.EndExpireTime = this.searchObj.endTime;
      }
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageForAfterDetailsSale();
    },
    // 清除条件
    clearExtendSearchParams (type) {
      console.log(this.queryParams, '清除条件');
      this.initializeData(); // 初始化
      this.searchObj = {
        searchText: '', // 搜索
        startTime: '',
        endTime: ''
      };
      this.getMessageSearchPageForAfterDetailsSale();
    },
    // 初始化数据
    initializeData (type) {
      this.queryParams = this.$utils.parseJson(queryParamsInfo);
      this.queryParams.OLAPKey = this.mendianId;
      console.log(this.queryParams, '初始化数据');
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 导出
    // doExportInfoClick () {
    //   if (this.mendianQueryList.length > 0) {
    //     var obj = this.$utils.parseJson(this.queryParams);
    //     obj.IsExportKey = 1;
    //     obj.pageIndex = 0;
    //     obj.pageSize = 0;
    //     let jsonFormate = {
    //       SheetTitle: '售后客户明细表',
    //       clist: [
    //         { title: '销售', cName: 'DocumentaryKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
    //         { title: '机构名', cName: 'SaaSClientNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '门店类型', cName: 'TypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '门店数', cName: 'SaaSClientCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '机构ID', cName: 'support20', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '联系人', cName: 'ContactsName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '手机号', cName: 'MobileSupport', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
    //         { title: '介绍数量', cName: 'IntroduceCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '成交数量', cName: 'CompleteCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '待返数量', cName: 'WaitReturnCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '跟进时间', cName: 'FTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '跟进内容', cName: 'ContentScript', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '下次跟进', cName: 'FollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '员工数', cName: 'AgentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '学生数', cName: 'StudentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '考勤人数', cName: 'AttendCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '考勤率', cName: 'AttendRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '来源方式', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '开户开始日期', cName: 'DredgeTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '开户失效日期', cName: 'ExpireDateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '合同总金额', cName: 'TotalAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '付款笔数', cName: 'StrokeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '尾款', cName: 'BalanceAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
    //       ]
    //     };
    //     console.log(jsonFormate, 'jsonFormate');
    //     MessageSearchPageForAfterDetailsSale(obj, jsonFormate).then(result => {
    //       console.log(result.data, '导出');
    //       window.location.href = this.$store.getters.APIURL + result.data.url;
    //     }, error => {
    //       layer.alert(error.msg);
    //     });
    //   } else {
    //     layer.alert('暂无数据导出');
    //   }
    //   console.log('导出');
    // },
    // 操作菜单
    operateBtnEvent (type, rowData) {
      console.log(rowData.Number, '操作菜单');
      this.threadInfo = rowData;
      switch (type) {
        case 'FK':// 付款
          this.isShowPaymentRequestFormPopup = true;
          break;
        case 'QSJ':// 清数据
          this.isShowCleanDataFormPopup = true;
          break;
        case 'DC':// 导出
          if (rowData.ExportExpirTime && rowData.ExportExpirTime >= this.$utils.formatDateToLine(Date.new()) && rowData.ExportAuditKey == 0) { // 可导出
            this.doExportCustomerClick(this.threadInfo.OLAPKey);
          } else {
            this.isShowExportPopup = true;
          }
          break;
        case 'LLZS':// 流量赠送
          this.isShowFlowGivePopup = true;
          break;
        default:
          break;
      }
    },
    // 导出
    doExportCustomerClick (OLAPKey) {
      ExportXYKD(OLAPKey).then(result => {
        console.log(result.data, '导出');
        window.location.href = this.$store.getters.APIURL + result.data.url;
        this.queryParams.pageIndex = 0;
        this.getMessageSearchPageForAfterDetailsSale();
      }, error => {
        layer.alert(error.msg);
      });
      console.log('导出');
    },
    closeFormPopup () {
      this.isShowAddCustomerFormPopup = false;
      this.isShowPaymentRequestFormPopup = false;
      this.isShowCleanDataFormPopup = false;
      this.isShowExportPopup = false;
    },
    closeFlowFormPopup () {
      this.isShowFlowGivePopup = false;
      this.getMessageSearchPageForAfterDetailsSale();
    },
    // 流量使用情况
    trafficUseStatus (count) {
      const countVal = this.$utils.flowFormate(count);
      return Number(count) < 0 ? `${countVal}` : countVal;
    }
  }
};
</script>

<style scoped>
.table_select_box.date_box {
  width: 95px;
  padding-right: 5px;
}
.table_select_box.date_box .el-select {
  margin-left: 0;
}
.table_select_box.date_box .el-select .el-input.is-focus .el-input__inner {
  border-color: white;
}
.table_select_box.date_box .el-select input.el-input__inner {
  color: #666;
  border: none;
  padding-left: 0px;
  font-size: 14px;
}
.headquarters_details .after_sale_detail .customer_table_box .table_title {
  width: 80px;
}
.headquarters_details .customer_table_box {
  flex: 0;
}
.tab_width {
  width: 170px !important;
}
.highlighted_font {
  color: #fa5a46;
}
</style>

