<template>
  <!-- 新版通用列表 -->
  <div class="stundent_list_box single_result single_student single_student_item"
       :class="isTestStudentItem.isActive?'active':''"
       @click.stop="selectedItem(isTestStudentItem)">
    <div class="single_result_right student_info">
      <div class="student_info_name fl"
           :style="isTestStudentItem.CurrentPeoCount?'width:100%':''"
           :title="isTestStudentItem.MainDemoName">
        <!-- 名称 -->
        <span>{{isTestStudentItem.MainDemoName}}</span>
        <!-- 人数 -->
        <span v-if="isTestStudentItem.CurrentPeoCount">（{{Number(isTestStudentItem.CurrentPeoCount)}}人）</span>
      </div>
      <!--线索显示学生-->
      <img class="student_img fl"
           title="已关联学生"
           :src="require('../../../public/image/student12.png') "
           v-if="apiType == 2&&isTestStudentItem.StudentKey!=0">
      <div v-if="apiType == 2&&isTestStudentItem.StudentKey==0"
           class="student_img fl"></div>
      <!-- 性别 -->
      <div class="student_info_sex fl"
           :class="isTestStudentItem.SexKey==2?'boy':'gril'"
           v-if="isTestStudentItem.SexKey"></div>
      <!-- 年龄 -->
      <div class="student_info_otherinfo fl"
           v-if="!isTestStudentItem.CurrentPeoCount">
        <span v-if="Number(isTestStudentItem.AgeName)> 0"
              class="student_info_age">{{isTestStudentItem.AgeName}}岁</span>
        <span class="student_info_relation"
              v-if="isTestStudentItem.RelationKeyValue">{{isTestStudentItem.RelationKeyValue}}</span>
        <span class="student_info_phone"
              v-if="isTestStudentItem.MobileSupport">{{$utils.phoneModulePower(isTestStudentItem.MobileSupport)}}</span>
        <!-- 电话 -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'singleSelectionItem',
  data () {
    return {
    };
  },
  props: ['isTestStudentItem', 'apiType'], // 单选数据项 需绑定到界面
  created () {
  },
  watch: {
  },
  computed: {
    vIsShowImg () {
      if (this.isTestStudentItem.SexKey && this.isTestStudentItem.StudentTypeKey) {
        return true;
      } else {
        return false;
      }
    },
    // 头像
    headImg () {
      // 自身头像为空  根据男女性别显示头像
      this.isTestStudentItem.HeadImgSupport = this.isTestStudentItem.HeadImgSupport == 'null' ? '' : this.isTestStudentItem.HeadImgSupport;
      let pcDomainURLOld = this.$store.getters.PCWebURL;
      if (!this.isTestStudentItem.HeadImgSupport) {
        this.isTestStudentItem.HeadImgSupport = '';
        if (parseInt(this.isTestStudentItem.SexKey) == 2) {
          return require('../../../public/image/deflut_studentPhoto_boy.png');
        } else {
          return require('../../../public/image/deflut_studentPhoto_gril.png');
        }
      }
      if (this.isTestStudentItem.HeadImgSupport.indexOf('http') > -1) {
        return this.isTestStudentItem.HeadImgSupport;
      } else if (this.isTestStudentItem.XMLIcon && this.isTestStudentItem.HeadImgSupport.indexOf('/') == -1) {
        return pcDomainURLOld + '/' + this.isTestStudentItem.HeadImgSupport;
      } else {
        return pcDomainURLOld + this.isTestStudentItem.HeadImgSupport;
      }
    }
  },
  methods: {
    selectedItem (item) {
      item.isActive = true;
      this.$emit('selectedItem', item);
    }
  }
};
</script>
<style scoped>
.single_student_item .student_info_name {
  width: 150px !important;
}

.student_img {
  width: 20px;
  height: 20px;
  margin-top: 8px;
}
</style>

