<template>
  <div class="img_box">
    <div class="content_bannber">
      <div @click="prePage"
           class="turn content_bannber_icon button left_btn"
           :class="{grey: currentPage==1}"></div>
      <div class="content_banner_box button">
        <!-- <pdf :class="{content_banner_box_img:type==1,content_banner_box_img_all:type==2,content_banner_box_attendance_detail:type==3}"
             :page="currentPage"
             :src="vUrl"
             @progress="loadedRatio = $event"
             @num-pages="pageTotalNum=$event">
        </pdf> -->
        <div class="watermark">
          {{watermark}}
        </div>
      </div>
      <div @click="nextPage"
           class="turn content_bannber_icon button right_btn"
           :class="{grey: currentPage==pageTotalNum}"></div>
    </div>
    <div class="content_bottom">
      <div class="content_bottom_tag">{{currentPage}}/{{pageTotalNum}}</div>
    </div>
  </div>
  <!-- <div >
  <pdf :src="'https://zxbeta.zhixuerj.com/CoursewareView/061d240093ae2ef682d88cf021b537a5/11416/5?sign=f9014318c8fcde7de63a428f7c384a3f794d5d0d'"> </pdf>
</div> -->

</template>

<script>
//import pdf from 'vue-pdf';
export default {
  data () {
    return {
      url: '',
      currentPage: 1,
      pageTotalNum: 0,
      loadedRatio: 0 // # 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
    };
  },
  props: {
    dataInfo: Object,
    watermark: String,
    type: Number // 1:普通预览，2：全屏,3:考勤详情打开
  },
  computed: {
    vUrl () {
      return this.dataInfo.AttachViewUrl;
    }
  },
  // components: {
  //   pdf
  // },
  created () {
    this.currentPage = this.dataInfo.currentPage || 1;
    console.log(this.dataInfo, 'all-pdf-view');
  },
  methods: {
    // 上一页
    prePage () {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        this.dataInfo.currentPage = this.currentPage;
      }
      console.log('prePage');
    },
    // 下一页
    nextPage () {
      if (this.currentPage < this.pageTotalNum) {
        this.currentPage = this.currentPage + 1;
        this.dataInfo.currentPage = this.currentPage;
      }
      console.log('nextPage', this.currentPage);
    }
  }
};
</script>
<style scoped>  
.content_banner_box_img{
   width: 420px!important; 
}
</style>
<style>
.content_banner_box_img_all {
  width: 700px !important;
}
.courseware_reveal_view .content_banner_box {
  margin-top: 0px !important;
}
.content_banner_box_attendance_detail {
  width: 650px !important;
}
</style>