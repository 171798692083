<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">新客户线索月报-系统登记</div>
        <single-year-picker @chgDate="chgDate"></single-year-picker>
      </div>
      <div>
        <columnarEchart :echartID="'hour3'"
                        :reqList="reqList"
                        :dataList="dataList"
                        :echartColor="echartColor"
                        :echartBGColor="echartBGColor"
                        ref="echart"></columnarEchart>
      </div>
      <div>
        <a @click.stop="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <rightSide :reportList="reportList"
               :dotColor="'dot_green'"
               :selectedDate="selectedDate"></rightSide>
  </div>
</template>
<script>
import { getSystemClewReportByMonth } from '../../../API/workbench';
import columnarEchart from '../echart-common/columnar-echart';
import singleYearPicker from '../date-picker-common/single-year-picker';
import rightSide from './right-side';

export default {
  name: 'systemReport',
  data () {
    return {
      selectedDate: Date.new(),
      startTime: null,
      endTime: null,
      reportList: [],     // 接收result.data.
      dataList: [],     // 传递进echart里面的显示数据
      reqList: ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'],
      echartColor: 'rgba(73,181,243,0.6)', // 报表颜色,这是红色,  'rgba(73,181,243,0.6)'这是蓝色 'rgba(255,108,103,0.6)' 这是红色
      echartBGColor: 'rgba(73,181,243,1)'// 报表颜色,这是红色, 'rgba(73,181,243,1)'这是蓝色  'rgba(255,108,103,1)' 这是红色
    };
  },
  props: {
  },
  mounted () {
    this.startTime = Date.new().getFullYear() + '/01/01';
    this.endTime = Date.new().getFullYear() + '/12/31';
    this.getAddStudentList(this.startTime, this.endTime);
  },
  components: {
    columnarEchart, singleYearPicker, rightSide
  },
  methods: {
    showDetail () {
      this.$emit('showDetail', '系统登记', this.startTime, this.endTime);
    },
    getAddStudentList (startTime, endTime) {
      getSystemClewReportByMonth(startTime, endTime).then(result => {
        this.reportList = result.data;
        this.dataList = this.getYearNowList();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      });
    },
    chgDate (val, startTime, endTime) {
      console.log(val, 'val', startTime, endTime);
      this.selectedDate = val;
      this.getAddStudentList(startTime, endTime);
    },
    // 整理传入报表的数据
    getYearNowList () {
      let nowList = [];
      this.reqList.forEach(p => {
        let item = this.reportList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.TotalPerson));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    }
  }
};
</script>


