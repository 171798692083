<template>
  <!-- 授权管理 消息推送查询-->
  <div class="margin_left_right_div">
    <div class="flow_packet_report template_minOrmax_width_div">
      <!-- 头部 -->
      <div class="course_statistics_table_search flex">
        <div class="table_select_box"
             style="margin-right: 20px; min-width: 192px">
          <div class="table_select_title">消息类型</div>
          <el-select :value="vTypeKeyValue"
                     placeholder="请选择"
                     style="width: 115px"
                     ref="resetText"
                     @change="selectMsgType">
            <el-option v-for="item in TypeList"
                       :key="item.value"
                       :label="item.label"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="width_small course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>

        </div>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :key="tableKey"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    @loadTableData="getDataList"></table-list>
      </div>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import {
  SearchPageMessagePushList
} from '../../../API/workbench';
export default {
  components: {
    tableList,
    inputSearchContainBtn
    // coursewareDetails
  },
  data () {
    return {
      TypeList: [
        {
          label: '所有',
          value: 0
        },
        {
          label: '欠费提醒通知',
          value: 1
        },
        {
          label: '欠课时提醒通知',
          value: 2
        },
        {
          label: '课时不足提醒通知',
          value: 3
        },
        {
          label: '长期未到校上课学生',
          value: 4
        },
        {
          label: '优惠券过期提醒',
          value: 5
        }

      ],
      // 查询参数
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        sequence: 'DESC',
        orderBy: '',
        MsgTypeKey: 0,
        MsgTypeKeyValue: '所有'
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      GeneralFluxCount: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '接收用户',
          prop: 'CustomerKeyValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '模块',
          prop: 'MsgTypeKeyValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '名称',
          prop: 'OperationName',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '推送信息',
          prop: 'RemarkSupport',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '推送人',
          prop: 'AgentKeyValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '推送时间',
          prop: 'StartTimeFrom',
          minWidth: 160,
          align: 'left',
          sortable: false,
          type: 'textItem'
        }
      ]
    };
  },
  created () {
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this,
      [
        'flowPacketDetailReport',
        'rechargeTheBalance',
        'HQflowPacketReport'
      ]);
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vTypeKeyValue () {
      return this.searchObj.MsgTypeKey == 0
        ? '所有'
        : this.searchObj.MsgTypeKeyValue;
    }
  },
  methods: {
    getDataList () {
      SearchPageMessagePushList(this.searchObj).then(result => {
        console.log(result.data, 'WEBVGetFluxPackageForList');
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      }).catch(error => {
        console.log('errorWEBVGetFluxPackageForList', error.msg);
      });
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.MsgTypeKey = 0;
      this.searchObj.MsgTypeKeyValue = '所有';
      this.search();
    },

    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    selectMsgType (item) {
      this.searchObj.MsgTypeKeyValue = item.label;
      this.searchObj.MsgTypeKey = item.value;
      this.searchObj.pageIndex = 0;
      this.search();
    }

  }
};
</script>

<style>
.flow_packet_report {
  height: 728px;
  background: white;
}
.flow_packet_report .course_statistics_search .search_box {
  min-width: 240px !important;
  width: 240px;
}
.flow_packet_report .course_statistics_table_search {
  padding: 20px 20px;
}
.flow_packet_report .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.flow_packet_report .table_fixed_nav_list {
  margin: 0 20px;
  padding: 0px 2px;
  font-size: 16px;
}
.flow_packet_report .course_statistics_title {
  padding: 0;
}
.flow_packet_report .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.flow_packet_report .table_select_box .el-select {
  width: 200px;
}
.flow_packet_report
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.flow_packet_report .cw_summarizing .top_content_list .list_content .list_num {
  text-overflow: clip;
}
.flow_packet_total {
  position: absolute;
  right: 20px;
  top: 65px;
  line-height: 35px;
  padding-left: 40px;
  background: url('../../../../public/image/flow.png') left center no-repeat;
  background-size: 30px;
}
.flow_packet_report .el-table .el-table__cell {
  padding: 0;
}
</style>

