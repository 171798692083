<template>
  <div class="all_workbench_top box_withe_radius">
    <div class="top_left">
      <div class="flex">
        <!-- <div class="top_type"
             :class="vClassName">{{vSignedLevelKey}}</div> -->

        <div class="rank_label_box">
          <div class="investor_intentionKey top_type"
               :class="vClassName">{{vSignedLevelKey}}</div>
          <span class="down_type">{{vIntentionValue}}</span>
        </div>
        <div class="top_info"
             style="margin-left: 50px">
          <div class="top_name">
            <span>{{ dataInfo.MainDemoName }}</span>
            <span class="top_right_lable"
                  :class="vStatusType"
                  v-if="dataInfo.StatusKeyValue">
              {{dataInfo.StatusKeyValue}}
            </span>
          </div>

          <div class="flex">
            <div class="top_phone">
              {{ dataInfo.MobilePhone }}
              <span class="top_sex"
                    v-if="dataInfo.SexKeyValue">{{ dataInfo.SexKeyValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="top_bottom">
        <span class="top_bottom_list"
              v-if="dataInfo.ProvinceKeyValue">
          <span class="font_gray">计划城市:</span>
          {{ dataInfo.ProvinceKeyValue}}·{{ dataInfo.CityKeyValue }}
        </span>
        <!-- 来源 -->
        <span class="top_source">
          {{ dataInfo.SourceTypeKeyValue }}
        </span>
        <span class="investor_btn_1 btn_hover_bg_white"
              :style="vRight"
              @click="openEditForm">查看详情</span>

        <span class="investor_btn_2 btn_hover_bg_blue"
              v-if="Number(this.dataInfo.IsValidKey) == 0"
              @click="openInvalidForm">无效线索</span>

        <span class="investor_btn_2 btn_hover_bg_blue"
              v-else-if="Number(dataInfo.IsSignedKey) == 0"
              @click="openSignForm">签单</span>

        <span class="investor_btn_2 btn_hover_bg_blue"
              v-else-if="Number(dataInfo.IsPay) == 1"
              @click="openPaymentForm">付款</span>
      </div>

    </div>
    <div class="top_remark">
      <div class="top_remark_title">个人描述</div>
      <span>{{ dataInfo.Remark }}</span>
    </div>
    <custom-dialog :title="'无效线索'"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="showInvalidDialog"
                   :before-close="closeDialog">
      <invalid-form :dataInfo="dataInfo"
                    @afterSuccess="afterSuccess"
                    @cancelClick="closeDialog"
                    :isReadonly="false"></invalid-form>
    </custom-dialog>
  </div>
</template>

<script>
import dropDownBox from '../../../../common/drop-down-box';
import invalidForm from '../form/invalid-form';
export default {
  data () {
    return {
      showInvalidDialog: false
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    dropDownBox,
    invalidForm
  },
  computed: {
    vRight () {
      if (Number(this.dataInfo.IsValidKey) == 0 || Number(this.dataInfo.IsSignedKey) == 0 || Number(this.dataInfo.IsPay) == 1) {
        return 'right: 115px;';
      } else {
        return 'right: 30px';
      }
    },
    vIntentionValue () {
      if (this.dataInfo) {
        if (this.dataInfo.IntentionKey == 1) {
          return '高意向';
        } else if (this.dataInfo.IntentionKey == 2) {
          return '中意向';
        } else if (this.dataInfo.IntentionKey == 3) {
          return '有意向';
        } else if (this.dataInfo.IntentionKey == 4) {
          return '无意向';
        } else {
          return '新线索';
        }
      }
      return '';
    },
    vSignedLevelKey () {
      if (this.dataInfo) {
        if (this.dataInfo.IntentionKey == 1) {
          return 'S';
        } else if (this.dataInfo.IntentionKey == 2) {
          return 'A';
        } else if (this.dataInfo.IntentionKey == 3) {
          return 'B';
        } else if (this.dataInfo.IntentionKey == 4) {
          return 'C';
        } else {
          return 'N';
        }
      }
      return '';
    },
    vClassName () {
      if (this.dataInfo.IntentionKey == 1) {
        return 'rank_S';
      } else if (this.dataInfo.IntentionKey == 2) {
        return 'rank_A';
      } else if (this.dataInfo.IntentionKey == 3) {
        return 'rank_B';
      } else if (this.dataInfo.IntentionKey == 4) {
        return 'rank_C';
      } else {
        return 'rank_new';
      }
    },
    // 0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款
    vStatusType () {
      if (Number(this.dataInfo.StatusKey) < 5) {
        return 'type_yellow';
      } else if (Number(this.dataInfo.StatusKey) == 5 || Number(this.dataInfo.StatusKey) == 6) {
        return 'type_aqua';
      } else if (Number(this.dataInfo.StatusKey) == 7) {
        return 'type_green';
      }
      // if (Number(this.dataInfo.StatusKey) == 1) {
      //   return 'type_blue';
      // } else if (
      //   Number(this.dataInfo.StatusKey) == 2 ||
      //   Number(this.dataInfo.StatusKey) == 3 ||
      //   Number(this.dataInfo.StatusKey) == 4 ||
      //   Number(this.dataInfo.StatusKey) == 5
      // ) {
      //   return 'type_yellow';
      // } else {
      //   return 'type_green';
      // }
    }
  },
  mounted () {
    // this.$dialog.register(this, [ 'improveClewForm' ]);
  },
  methods: {
    closeDialog () {
      this.showInvalidDialog = false;
    },
    // 完善信息
    openEditForm () {
      this.$dialog.open(this, {
        name: '投资人信息',
        routerName: this.$route.name,
        moduleName: 'crmImproveClewForm',
        ModulePowerKey: '',
        data: { dataInfo: this.dataInfo, isReadonly: true }
      });
    },
    // 无效线索
    openInvalidForm () {
      this.showInvalidDialog = true;
    },
    // 签单
    openSignForm () {
      this.$dialog.open(this, {
        name: '签单申请',
        routerName: this.$route.name,
        moduleName: 'crmSignContractForm',
        data: { dataInfo: this.dataInfo, isReadonly: false },
        callBack: {
          afterSuccess: () => {
            this.afterSuccess(this.dataInfo);
          }
        }
      });
    },
    // 付费
    openPaymentForm () {
      console.log(this.dataInfo, '付费');
      this.$dialog.open(this, {
        name: '付款申请', // 新增传付款申请， 审核传付款审核
        routerName: this.$route.name,
        moduleName: 'crmPaymentForm',
        data: {
          OLAPKey: Number(this.dataInfo.OLAPKey),
          isReadonly: false,
          paymentType: 'add'
        }, // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
        callBack: {
          afterSuccess: () => {
            this.afterSuccess(this.dataInfo);
          }
        }
      });
    },
    afterSuccess (data) {
      this.closeDialog();
      this.$emit('afterSuccess', data);
    }
  }
};
</script>

<style>
.all_workbench_top {
  /* width: 1500px; */
  width: 945px;
  height: 121px;
  border-radius: 8px;
  display: flex;
}

.all_workbench_top .top_left {
  width: 580px;
  flex-shrink: 0;
  padding-left: 24px;
  padding-top: 20px;
  position: relative;
}

.all_workbench_top .top_type {
  width: 28px;
  height: 21px;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  margin-right: 12px;
  margin-top: 2px;
}

.all_workbench_top .top_type.type_new {
  background-color: #fc7345;
}
.all_workbench_top .top_type.type_black {
  background-color: black;
  color: white;
}
.all_workbench_top .top_type.rank_new {
  background-color: #fc7345;
  color: white;
}

.all_workbench_top .top_type.rank_S {
  background-color: #282828;
  color: white;
}

.all_workbench_top .top_type.rank_A {
  background-color: #d0a487;
  color: white;
}

.all_workbench_top .top_type.rank_B {
  background-color: #d8b376;
  color: white;
}

.all_workbench_top .top_type.rank_C {
  background-color: #a6a2a3;
  color: white;
}
.all_workbench_top .top_name {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 2px;
}

.all_workbench_top .top_phone {
  font-size: 12px;
  line-height: 24px;
  color: #999;
  padding-left: 16px;
  background: url(../../../../../../public/image/workbench_vector_icon.png)
    no-repeat left center;
}
.all_workbench_top .top_sex {
  background: #4fc5ff;
  padding: 0px 2px;
  margin-left: 5px;
  border-radius: 2px;
  color: white;
}

.all_workbench_top .top_source {
  margin-left: 20px;
  font-size: 12px;
  color: #ff8723;
  background-color: #ffedde;
  border-radius: 4px;
  padding: 2px 7px;
}

.all_workbench_top .top_bottom {
  margin-top: 16px;
  font-size: 14px;
  line-height: 19px;
  margin-left: 42px;
}
.top_bottom .investor_btn_1 {
  height: 35px;
  width: 76px;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 115px;
  bottom: 20px;
}
.top_bottom .investor_btn_2 {
  height: 35px;
  width: 76px;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.all_workbench_top .top_right {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
}

.all_workbench_top .top_right_btn {
  line-height: 30px;
  padding-right: 18px;
  background: url(../../../../../../public/image/next_icon@2x.png) no-repeat
    right center;
  background-size: 8px;
  color: #3499db;
  margin-left: 20px;
}

.all_workbench_top .top_right_lable {
  min-width: 84px;
  line-height: 30px;
  padding-left: 34px;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 4px;
  margin-left: 12px;
  padding-right: 7px;
  background-repeat: no-repeat;
  background-position: 11px center;
}

.all_workbench_top .top_right_lable.type_no {
  background-image: url(../../../../../../public/image/lable_type_no.png);
}

.all_workbench_top .top_right_lable.type_tick {
  background-image: url(../../../../../../public/image/lable_type_tick.png);
}

.all_workbench_top .top_right_lable.type_warning {
  background-image: url(../../../../../../public/image/lable_type_warning.png);
}

.all_workbench_top .top_bottom_list .font_gray {
  color: #999;
}
.all_workbench_top .top_right_lable.type_blue {
  min-width: auto;
  height: 28px;
  background: #d2e1ff;
  border-radius: 2px;
  padding: 2px 5px;
  line-height: 20px;
  font-size: 14px;
}

.all_workbench_top .top_right_lable.type_yellow {
  min-width: auto;
  height: 28px;
  background: #f9e0c7;
  border-radius: 2px;
  padding: 2px 5px;
  line-height: 20px;
  font-size: 14px;
}
.all_workbench_top .top_right_lable.type_aqua {
  min-width: auto;
  height: 28px;
  background: #bcebdc;
  border-radius: 2px;
  padding: 2px 5px;
  line-height: 20px;
  font-size: 14px;
}
.all_workbench_top .top_right_lable.type_green {
  min-width: auto;
  height: 28px;
  background: #78d1b5;
  border-radius: 2px;
  padding: 2px 5px;
  line-height: 20px;
  font-size: 14px;
}
.all_workbench_top .top_bottom_list {
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
}

.all_workbench_top .top_bottom_list:first-child {
  margin-left: 0;
}

.all_workbench_top .top_bottom_list::after {
  content: '';
  position: absolute;
  right: -15px;
  top: 3px;
  width: 1px;
  height: 16px;
  background-color: #e7e9e8;
}

.all_workbench_top .top_bottom_list:last-child:after {
  width: 0;
}

.all_workbench_top .top_remark {
  width: 350px;
  height: 81px;
  padding-left: 63px;
  padding-top: 13px;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
  position: relative;
}
.all_workbench_top .top_remark span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}

.all_workbench_top .top_remark_title {
  position: absolute;
  width: 23px;
  height: 66px;
  left: 20px;
  top: 0;
  padding-top: 4px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #fff;
  background: url(../../../../../../public/image/remark_title_banner.png)
    no-repeat;
  background-size: 23px;
}
.rank_label_box {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(225, 231, 235, 0.65);
  border-radius: 4px;
  width: 38px;
  height: 40px;
}
.investor_intentionKey {
  position: absolute;
  top: 2px;
  left: 5px;
}
.down_type {
  position: relative;
  width: 20px;
  height: 5px;
  font-size: 12px;
  top: 24px;
  left: 1px;
  color: #333 !important;
}
</style>