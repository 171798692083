<template>
  <div class="general_summarizing_student">
    <div class="general_summarizing_student_navUl"
         style="margin: 0px;">
      <div class="title_list"
           @click="tableSwitch(1)"
           :class="{ opt: tableType == 1 }">
        报名记录统计
      </div>
      <div class="title_list"
           @click="tableSwitch(2)"
           :class="{ opt: tableType == 2 }">
        课时收入明细
      </div>
      <div class="title_list"
           @click="tableSwitch(3)"
           :class="{ opt: tableType == 3 }">
        天数收入明细
      </div>
      <div class="title_list"
           @click="tableSwitch(4)"
           :class="{ opt: tableType == 4 }">
        杂费收入明细
      </div>
      <div class="title_list"
           @click="tableSwitch(5)"
           :class="{ opt: tableType == 5 }">
        学生退费明细
      </div>
    </div>
    <div class="payment_statistics_bottom clearfix">
      <div v-if="tableType == 1">
        <apply-record-report @toTableType="tableSwitch"
                             @changeType="changeType"
                             :searchTime="searchObj"
                             :schoolItem="schoolItem"></apply-record-report>
      </div>
      <div v-if="tableType == 2">
        <classcount-detail-report @toTableType="tableSwitch"
                                  :searchText="searchText"
                                  :searchTime="searchObj"
                                  :schoolItem="schoolItem"></classcount-detail-report>
      </div>
      <div v-if="tableType == 3">
        <day-detail-report @toTableType="tableSwitch"
                           :searchText="searchText"
                           :searchTime="searchObj"
                           :schoolItem="schoolItem"></day-detail-report>
      </div>
      <div v-if="tableType == 4">
        <other-detail-report @toTableType="tableSwitch"
                             :searchText="searchText"
                             :searchTime="searchObj"
                             :schoolItem="schoolItem"></other-detail-report>
      </div>
      <div v-if="tableType == 5">
        <refund-detail-report @toTableType="tableSwitch"
                              :searchTime="searchObj"
                              :schoolItem="schoolItem"></refund-detail-report>
      </div>

    </div>
  </div>
</template>
<script>

import refundDetailReport from './components/refund-detail-report';
import classcountDetailReport from './components/classcount-detail-report';
import otherDetailReport from './components/other-detail-report';
import dayDetailReport from './components/day-detail-report';
import applyRecordReport from './components/apply-record-report';

export default {
  data () {
    return {
      searchText: '',
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      }
    };
  },
  components: {
    applyRecordReport, refundDetailReport, classcountDetailReport, otherDetailReport, dayDetailReport
  },
  props: {
    tableType: [Number, String],
    schoolItem: Object
  },
  created () {
    console.log(this.$route.query, 'this.$route.query');
  },
  activated () {
    console.log('activated');
  },
  methods: {
    initData (type, searchData) {
      this.searchText = '';
      this.searchObj = searchData;
      // this.$nextTick(o=>{
      //   this.tableType = type||this.tableType;
      // })
    },
    changeType (item, type, searchData) {
      console.log('searchData', searchData);
      this.searchText = item.MainDemoName || item.StudentKeyValue || '';
      this.searchObj = searchData;
      this.$nextTick(o => {
        this.$parent.indexType = type;
      });
    },
    tableSwitch (type, optDataTime) {
      this.$parent.indexType = type;
      this.searchText = '';
    }
  }
};
</script>
<style scoped>
.payment_statistics_bottom >>> .course_statistics_table_search {
  padding: 20px 0px !important;
}
</style>