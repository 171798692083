<template>
  <div class="courseware_reveal_view_banner">
    <div class="courseware_reveal_view"
         :style="{ transform: vScale, top: vTop, left: vLeft }">
      <!-- 上课头部 上课模式 1;备课模式-2 -->
      <courseware-class-header :dataInfo="dataInfo"
                               ref="coursewareClassHeader"
                               :courseInfo="courseInfo"
                               :studentArrangeList="studentArrangeList"
                               @reback="reback"
                               @showPopup="showAtten"></courseware-class-header>

      <div v-if="!dataInfo.Title || (vListLength == 0 && (!dataInfo.HomeWork || !dataInfo.HomeWork.id))"
           class="container_nodata">
        请先添加附件
      </div>
      <div v-else
           class="container">
        <courseware-list :dataInfo="dataInfo"
                         ref="coursewareList"
                         @selectAttach="selectAttach"
                         @showHomeWork="showHomeWork"></courseware-list>

        <courseware-content v-if="!isShowAllView"
                            :dataInfo="showInfo"
                            :dataInfoLength="vListLength"
                            :watermarkInfo="watermarkInfo"
                            @upInfo="upAttachInfo"
                            @downInfo="downAttachInfo"
                            @showAllView="showAllView"></courseware-content>
      </div>
      <div class="literature">由 治学软件 提供技术支持</div>
    </div>
    <all-view v-if="isShowAllView"
              :dataInfo="showInfo"
              :dataInfoLength="vListLength"
              :watermarkInfo="watermarkInfo"
              :viewType="1"
              @upInfo="upAttachInfo"
              @downInfo="downAttachInfo"
              @hiddenAllView="hiddenAllView"></all-view>

    <popup-view v-if="isShowHomeWork"
                @closePopup="closePopup"
                :title="'课后作业'">
      <div class="index_JXT_ul">
        <!-- 作业内容 -->
        <new-home-task-browse-item :dataInfo="dataInfo.HomeWork">

        </new-home-task-browse-item>
      </div>
    </popup-view>

    <popup-view v-if="isShowAtten"
                @closePopup="closePopupAtten"
                :title="'学生考勤'">
      <student-attendance :courseInfo="courseInfo"
                          :studentArrangeList="studentArrangeList"></student-attendance>
    </popup-view>

    <popup-view v-if="isShowTeachingStep"
                @closePopup="closePopupStep"
                :title="'教学步骤'">
      <div class="course_essay_label">
        <teaching-step-view :dataInfo="dataInfo"></teaching-step-view>
      </div>
    </popup-view>

    <popup-view v-if="isShowEvaluation"
                @closePopup="closePopupEvaluation"
                :title="'课评话术'">
      <evaluation :dataList="dataInfo.Evaluation"></evaluation>
    </popup-view>

    <dialog-factory ref="coursewareTeachingDialogFactory"
                    :key="'coursewareTeaching'"
                    :routerName="'coursewareTeaching'"></dialog-factory>
  </div>
</template>

<script>
import allView from '../courseware-teaching/all-view';
import coursewareClassHeader from '../courseware-teaching/courseware-class-header/index';
import coursewarePreviewHeader from '../courseware-teaching/courseware-preview-header/index';
import coursewareList from '../courseware-teaching/courseware-list/index';
import coursewareContent from '../courseware-teaching/courseware-content/index';
import popupView from '../courseware-teaching/popup-view';
import studentAttendance from '../courseware-teaching/courseware-class-header/student-attendance';
import evaluation from '../courseware-teaching/courseware-preview-header/evaluation';
import teachingStepView from '../courseware-teaching/courseware-preview-header/teaching-step-view';

import newHomeTaskBrowseItem from '../home-school-communication/home-task-page/new-home-task-browse-item';
import dialogFactory from '../common/dialog-factory';
import {
  GetCoursewareDetailForTVGiveLecture,
  GetSecurityWatermark,
  getTeacheCourseDetail,
  getScheduleCourseOnStudentList
} from '../../API/workbench';
import { md5 } from '../../API/md5';
export default {
  components: {
    allView,
    coursewareClassHeader,
    coursewarePreviewHeader,
    coursewareList,
    coursewareContent,
    dialogFactory,
    popupView,
    newHomeTaskBrowseItem,
    studentAttendance,
    evaluation,
    teachingStepView
  },
  data () {
    return {
      // viewType: 1, // 1-上课；2-备课
      isShowAllView: false,
      isShowHomeWork: false,  // 显示作业
      isShowAtten: false,
      isShowTeachingStep: false,
      isShowEvaluation: false,
      dataInfo: {},
      showInfo: {},
      scheduleCourseKey: 0, // 课ID
      courseInfo: {
        CommentStudentList: [],
        UnCommentStudentList: [],
        HomeWorkStudentList: [],
        TeachingPlan: {}
      },
      studentArrangeList: [],
      windowResizeTimeout: null,
      watermarkInfo: [
        {
          MainDemoName: '是否开启老师水印',
          OLAPKey: '',
          SetContent: '',
          SetKey: '0',
          SetKeyValue: '',
          SetTypeKey: '',
          SetTypeKeyValue: ''
        },
        {
          MainDemoName: '是否开启门店水印',
          OLAPKey: '',
          SetContent: '',
          SetKey: '0',
          SetKeyValue: '',
          SetTypeKey: '',
          SetTypeKeyValue: ''
        },
        {
          MainDemoName: '是否开启品牌水印',
          OLAPKey: '',
          SetContent: '',
          SetKey: '0',
          SetKeyValue: '',
          SetTypeKey: '',
          SetTypeKeyValue: ''
        }
      ],
      authorizedCode: {}, // 课件授权设备ID
      timeoutObj: null,
      timeoutNumber: 30// 30分钟后结束
    };
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
    console.log(this.$route.query, 'this.$route.query');
    this.init(this.$store.getters.token);
    // alert('预加载中-created');
  },
  watch: {
    '$route' (to, from) {
      // alert('预加载完成$route：');
      this.init(this.$store.getters.token);
    }
  },
  destroyed () {
    // coursewareTvPrestrain;
    document.body.style.overflow = 'visible';
  },
  mounted () {
    // setTimeout(() => {
    // }, 3000);
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'coursewareTeaching') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    window.addEventListener('resize', this.windowResize);
  },
  computed: {
    vScale () {
      if (
        document.documentElement.clientHeight / 1080 >
        document.documentElement.clientWidth / 1920
      ) {
        return 'scale(' + document.documentElement.clientWidth / 1920 + ')';
      } else {
        return 'scale(' + document.documentElement.clientHeight / 1080 + ')';
      }
    },
    vTop () {
      if ((1080 - document.documentElement.clientHeight) / 2 > 0) {
        return '-' + (1080 - document.documentElement.clientHeight) / 2 + 'px';
      } else {
        return (
          Math.abs((1080 - document.documentElement.clientHeight) / 2) + 'px'
        );
      }
    },
    vLeft () {
      if ((1920 - document.documentElement.clientWidth) / 2 > 0) {
        return '-' + (1920 - document.documentElement.clientWidth) / 2 + 'px';
      } else {
        return (
          Math.abs((1920 - document.documentElement.clientWidth) / 2) + 'px'
        );
      }
    },
    vListLength () {
      if (this.dataInfo.newCoursewareAttach) {
        return this.dataInfo.newCoursewareAttach.length;
      } else {
        return 0;
      }
    }
  },
  methods: {
    getUrlSrc (name, url) { return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(/\+/g, '%20')) || null; },
    // 初始化
    init (token) {
      var query = this.$route.query;
      console.log('预加载完成-query', query);
      var url = '';
      for (let i in query) {
        console.log('i:' + i, 'query[i]:' + query[i]);
        if (!query[i] && i != 'Sign') {
          url = '?' + i;
        }
      }
      if (url) {
        url = decodeURIComponent(url);
      }
      let isPrestrain = url ? this.getUrlSrc('isPrestrain', url) : this.$route.query.isPrestrain;
      // alert('isPrestrain：' + isPrestrain);
      if (isPrestrain == 1) {
        // alert(JSON.stringify(url));
        if (!token) {
          // 装载token
          let token = { UserName: (url ? this.getUrlSrc('UserName', url) : this.$route.query.UserName),
            SignData: { Sign: (url ? this.getUrlSrc('Sign', url) : this.$route.query.Sign) } };
          this.$store.commit('SetToken', token);
          // 装载门店信息
          let SaaSClientInfo = { MainDemoName: url ? this.getUrlSrc('SaaSClientKeyValue', url) : this.$route.query.SaaSClientKeyValue,
            OLAPKey: url ? this.getUrlSrc('SaaSClientKey', url) : this.$route.query.SaaSClientKey };

          this.$store.commit('setSaaSClientInfo', SaaSClientInfo);
          console.log(this.$store.getters.token, 'token-签名');
        }
        if (this.$store.getters.signData) {
          this.scheduleCourseKey = url ? this.getUrlSrc('ScheduleCourseKey', url) : this.$route.query.ScheduleCourseKey || 0;// 课ID
          this.authorizedCode = url ? this.getUrlSrc('AuthorizedCode', url) : this.$route.query.AuthorizedCode || null;
          document.body.style.overflow = 'hidden';
          this.getWatermarkInfo();
          this.getDataInfo();
          this.getCourseInfo();
        }
      }
    },
    windowResize () {
      if (this.windowResizeTimeout) {
        clearTimeout(this.windowResizeTimeout);
        this.windowResizeTimeout = null;
      }
      this.windowResizeTimeout = setTimeout(() => {
        if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
          this.showAllView();
        } else {
          this.hiddenAllView();
        }
      }, 300);
    },
    // 课结束
    courseEndTime (courseInfo) {
      clearInterval(this.timeoutObj);
      this.timeoutObj = null;
      this.timeoutObj = setInterval(() => {
        // console.log(Date.new(), '每秒');
        let num = Math.trunc((Date.new().getTime() - Date.new(courseInfo.EndTime).getTime()) / (1000 * 60));
        if (courseInfo.EndTime && Date.new().getTime() > Date.new(courseInfo.EndTime).getTime()) {
          // alert('课时间：' + courseInfo.EndTime);
          // alert(num > 0);
          if (num > 0) {
            this.timeoutNumber = this.timeoutNumber - num;
            // alert('课时间2：' + this.timeoutNumber);
          }
          this.courseQuitTime();
        }
      }, 1000);
    },
    // 课结束30分钟后
    courseQuitTime () {
      clearInterval(this.timeoutObj);
      this.timeoutObj = null;
      this.timeoutObj = setInterval(() => {
        this.timeoutNumber--;
        // alert(this.timeoutNumber + '分钟');
        console.log('课已结束，离退出还有' + this.timeoutNumber + '分钟');
        if (this.timeoutNumber == 0) {
          // alert('课已结束，离退出还有' + this.timeoutNumber + '分钟');
          clearInterval(this.timeoutObj);
          this.timeoutObj = null;
          _uni.postMessage({
            data: {
              action: 'message',
              eventTypeKey: 4, // 1-预加载完成；2-课件加载完成；3-课件预览手动退出；4-课件预览自动退出
              eventTypeKeyValue: '课件预览自动退出',
              data: {}
            }
          });
        }
      }, 1000 * 60);
    },
    // 返回
    reback () {
      _uni.postMessage({
        data: {
          action: 'message',
          eventTypeKey: 3, // 1-预加载完成；2-课件加载完成；3-课件预览手动退出；4-课件预览自动退出
          eventTypeKeyValue: '课件预览手动退出',
          data: {}
        }
      });
      // window.parent.postMessage({
      //   status: 'ok',
      //   params: {
      //     ok: true,
      //     typeKey: 1,
      //     typeVal: '手动'
      //   }
      // }, '*');
    },
    showHomeWork () {
      this.isShowHomeWork = true;
    },
    closePopup () {
      this.isShowHomeWork = false;
    },
    showAtten () {
      this.loadStudentArrangeList();
      this.isShowAtten = true;
    },
    closePopupAtten () {
      this.isShowAtten = false;
    },
    showPopupStep () {
      this.isShowTeachingStep = true;
    },
    closePopupStep () {
      this.isShowTeachingStep = false;
    },
    showPopupEvaluation () {
      this.isShowEvaluation = true;
    },
    closePopupEvaluation () {
      this.isShowEvaluation = false;
    },
    enterFullscreen () {
      this.$zxClient.FullScreen();
    },
    exitFullscreen () {
      this.$zxClient.UnFullScreen();
    },
    showAllView () {
      // this.enterFullscreen();
      this.isShowAllView = true;
    },
    hiddenAllView () {
      // this.exitFullscreen();
      this.isShowAllView = false;
    },
    upAttachInfo (i) {
      this.selectAttach(this.dataInfo.newCoursewareAttach[i - 2]);
    },
    downAttachInfo (i) {
      this.selectAttach(this.dataInfo.newCoursewareAttach[i]);
    },
    selectAttach (data) {
      this.dataInfo.newCoursewareAttach.forEach((e) => {
        this.$set(e, 'isSelect', false);
      });
      console.log(data, 'selectAttach');
      this.$set(data, 'isSelect', true);
      this.$set(data, 'currentPage', 1);// bug-13712
      this.showInfo = data;
      this.isShowAtten = false;
    },
    // 获取老师课详细信息
    getCourseInfo (callBack) {
      getTeacheCourseDetail(this.scheduleCourseKey).then(res => {
        if (res.code == 0) {
          if (res.data.ClassTime) {
            res.data.week = this.$utils.getWeekByTwo(res.data.ClassTime);
          }
          this.courseInfo = res.data;
          this.courseEndTime(this.courseInfo);
          console.log('getCourseDetailed11', this.courseInfo);
          if (typeof callBack == 'function') {
            callBack(this.courseInfo);
          }
        }
      }, err => {
        console.log(err);
      }
      );
    },
    // 获取学员排课表
    loadStudentArrangeList (callBack) {
      getScheduleCourseOnStudentList(this.scheduleCourseKey).then(
        result => {
          this.studentArrangeList = [];
          if (result.data && result.data.length > 0) {
            result.data.forEach(obj => {
              obj.isSelected = false;
              obj.unSelected = false;
              obj.OLAPKey = obj.StudentKey;
              this.studentArrangeList.push(obj);
            });
          } if (callBack) {
            callBack();
          }
        }, error => {
        layer.alert(error.msg);
      }
      );
    },
    getWatermarkInfo () {
      GetSecurityWatermark().then((res) => {
        if (res.data.length > 0) {
          this.watermarkInfo = res.data;
        } else {
          this.watermarkInfo[0].SetKey = 1;
          this.watermarkInfo[0].SetContent = this.$store.getters.token.UserName;
          this.watermarkInfo[1].SetKey = 1;
          this.watermarkInfo[1].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
          this.watermarkInfo[2].SetKey = 1;
          this.watermarkInfo[2].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
        }
        console.log(this.watermarkInfo, 'this.watermarkInfo');
      });
    },
    getDataInfo () {
      console.log(this.deviceData, 'getDataInfo');
      GetCoursewareDetailForTVGiveLecture(this.scheduleCourseKey, this.authorizedCode).then((res) => {
        this.dataInfo = res.data;
        this.doSelectAttachFun();
        this.$nextTick(() => {
          _uni.postMessage({
            data: {
              action: 'message',
              eventTypeKey: 2, // 1-预加载完成；2-课件加载完成；3-课件预览手动退出；4-课件预览自动退出
              eventTypeKeyValue: '课件加载完成',
              data: {}
            }
          });
        });
      }, (err) => {
        layer.confirm(err.msg, {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                this.reback();
              }
            }
          ]
        });
      });
    },
    doSelectAttachFun () {
      this.$set(this.dataInfo, 'newCoursewareAttach', []);
      this.dataInfo.newCoursewareAttach = this.dataInfo.CoursewareAttach.filter(obj => {
        return obj.TypeKey == 1;
      });
      if (this.dataInfo.newCoursewareAttach) {
        let i = 1;
        this.dataInfo.newCoursewareAttach.forEach((e) => {
          this.$set(e, 'ShowOrder', i);
          this.$set(e, 'isSelect', false);
          i++;
        });
      }
      if (this.dataInfo.newCoursewareAttach && this.dataInfo.newCoursewareAttach.length > 0) {
        this.selectAttach(this.dataInfo.newCoursewareAttach[0]);
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursewareTeachingDialogFactory) {
        this.$refs.coursewareTeachingDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>