<template>
  <div class="record_list_day">
    <div class="record_list_day_title">
      <div class="layout_flex1 font_blue"><span>{{dateStr}}</span></div>
      <div class="layout_flex2"
           :title="attenItem.CourseNameKeyValue"><span>{{attenItem.CourseNameKeyValue}}</span></div>
      <div class="layout_flex3"><span class="font_gray">小计：</span><span>{{TotalClassCount}}课时</span></div>
      <div class="layout_flex4"><span class="font_gray">出勤率：</span><span>{{attenRate}}</span></div>
      <div class="layout_flex5"><span>签到 {{attenItem.Attence3}} 人/请假 {{attenItem.Attence4}} 人/旷课 {{attenItem.Attence5}} 人</span></div>
      <div class="layout_flex6"
           @click.stop="showCourseDetail">
        <div class="record_list_day_details">详情</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'attenItem',
  data () {
    return {

    };
  },
  props: ['attenItem'],
  methods: {
    showCourseDetail () {
      this.$emit('showCourseDetail', this.attenItem);
    }
  },
  computed: {
    dateStr () {
      return this.$utils.formatDateStr(this.attenItem.ClassTime, 'MM.dd') + '  ' + this.$utils.formatDateStr(this.attenItem.ClassTime, 'ddddd');
    },
    TotalClassCount () {
      return Number(this.attenItem.Total_ClassCount).toFixed(1);
    },
    attenRate () {
      if (this.attenItem.TotalPerson == 0) {
        return '0.00%';
      } else {
        return ((Number(this.attenItem.Attence3) / Number(this.attenItem.TotalPerson)) * 100).toFixed(2) + '%';
      }
    }
  }
};
</script>

