<template>
  <div class="search_courseware_info">
    <div class="defult_dialog_searchform clearfix">
      <!-- 搜索框 -->
      <div class="student_search_form">
        <input type="text"
               class="search_input"
               placeholder="搜索课件名"
               v-model="searchCourseText">
        <span class="remove_btn"
              v-if="searchCourseText.length > 0"
              @click.stop="clearSearText"
              style="right:85px;background-size: 10px;width: 20px;height: 20px;"></span>
      </div>
      <div class="btn_light_blue_search fl"
           @click.stop="searchTextSearch()">搜索</div>
    </div>
    <div class="courseware_right_big">
      <div class="file_list_ul">
        <div v-for="(item,i) in coursewarePackageList"
             :key="i"
             style="position: relative;">
          <div class="big_courseware_img"
               :class="item.SettingCoverUrl?'':'big_courseware_default_img'"
               @click.stop="prepare(item)">
            <img :src="item.img"
                 :class="item.SettingCoverUrl?'swimg':'default_img'" />
            <span class="big_courseware_text">{{item.MainDemoName}}</span>
          </div>
          <div class="attend_class_bj"
               @click.stop="prepare(item)">开始上课</div>
        </div>
      </div>
    </div>

    <div v-if="coursewarePackageList.length==0"
         class="course_block_nodata">
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
</template>

<script>
import { TeachingModegetSearchCourseware, CWSTeacherGiveLectureSetUpScheduleCourse, CWSGetCoursewarePackageDetails, CWSTeacherWorkbenchGetCoursewarePackageDetails } from '../../../../API/workbench';
export default {
  components: {
  },
  props: {
    dataInfo: Object,
    searchText: String,
    TeacherKey: [Number, String],
    Source: [Number, String],
    CoursewarePackageKey: [Number, String],
  },
  data () {
    return {
      searchCourseText: '',
      coursewarePackageList: [],
      role: this.vRoleKey(),
      chooseCoursewareInfoType: '',
      chooseType: '',
      chooseScheduleCourseKey: '',
      maxCoursewarePackage: {}, // 子课包详情
      particulars: [],
      openClassData: [],
      IsContinueKey: 0,
      IsAuthKey: 0,
      TeacherID: this.TeacherKey || '', // this.$store.getters.token.UserID || '' ,// 老师id
      busClassLocationEvent: null
    };
  },
  computed: {
    // 判断是校长还是老师
    vRoleKeyA () {
      console.log(Number(this.$store.getters.token.RoleKey), '校长and老师');
      return this.$store.getters.token.RoleKey ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    }
  },
  created () {
    this.searchCourseText = this.searchText;
    console.log(this.TeacherKey, 'this.TeacherKey  Source', this.Source, 'this.CoursewarePackageKey', this.CoursewarePackageKey);
    console.log(this.dataInfo, 'this.dataInfo123  searchText', this.searchText);

    if (this.$zxClient && this.$zxClient.IsClient) {
      this.deviceCode = this.$zxClient.GetMCode();
    } else {
      // this.tipContont = '非桌面端';
      // this.dataInfo.DeviceCode = '340BC40DC769411B'
    }
    this.getCoursewarePackageDetails(this.dataInfo.OLAPKey || this.CoursewarePackageKey, this.deviceCode, this.TeacherID);
    this.vRoleKey();
    console.log(this.dataInfo, 'dataInfo this.role', this.role);
    this.getCoursewareList(this.searchCourseText);

    this.busClassLocationEvent = res => {
      if (this.chooseType == 1) {
        this.$router.push({
          name: 'coursewareTeachingView',
          query: {
            type: this.chooseType,
            scheduleCourseID: this.chooseScheduleCourseKey,
            CoursewareInfoType: this.chooseCoursewareInfoType,
            Accuracy: this.maxCoursewarePackage.Accuracy,
            IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
          }
        });
      } else if (this.chooseType == 10) {
        this.openClassData.Longitude = res.Longitude;
        this.openClassData.Latitude = res.Latitude;
        console.log('this.openClassData', this.openClassData);
        CWSTeacherGiveLectureSetUpScheduleCourse(
          this.openClassData,
          this.IsContinueKey,
          this.IsAuthKey
        )
          .then(result => {
            console.log(result, '上课返回数据');
            // this.$emit("afterSuccess");
            // this.$emit("closeDialog");
            if (result.data.ScheduleCourseKey) {
              setTimeout(() => {
                this.$router.push({
                  name: 'coursewareTeachingView',
                  query: {
                    type: 1,
                    scheduleCourseID: result.data.ScheduleCourseKey,
                    Accuracy: this.maxCoursewarePackage.Accuracy,
                    IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                  }
                });
              }, 500);
            } else {
              console.log(123456456);
              layer.alert(error.msg);
            }
          })
          .catch(error => {
            console.log(123456456);
            if (error.code == 80001) {
              this.isAuthCourseware();
            } else if (error.code == 80002) {
              layer.confirm(error.msg, {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      this.IsContinueKey = 0;
                      layer.close();
                      if (callBack) {
                        callBack();
                      }
                    }
                  },
                  {
                    name: '确认',
                    callBack: () => {
                      this.IsContinueKey = 1;
                      CWSTeacherGiveLectureSetUpScheduleCourse(
                        this.openClassData,
                        this.IsContinueKey,
                        this.IsAuthKey
                      )
                        .then(res => {
                          if (res.data.ScheduleCourseKey) {
                            setTimeout(() => {
                              this.$router.push({
                                name: 'coursewareTeachingView',
                                query: {
                                  type: 1,
                                  scheduleCourseID: res.data.ScheduleCourseKey,
                                  Accuracy: this.maxCoursewarePackage.Accuracy,
                                  IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                                }
                              });
                            }, 500);
                          } else {
                            layer.alert(error.msg);
                          }
                        })
                        .catch(error => {
                          if (error.code == 80001) {
                            this.isAuthCourseware();
                          } else {
                            layer.alert(error.msg);
                          }
                        });
                      layer.close();
                    }
                  }
                ]
              });
            } else {
              layer.alert(error.msg);
            }
          });
      } else if (this.chooseType == 2) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
            }
          });
        }, 500);
      } else if (this.chooseType == 4) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
            }
          });
        }, 500);
      }
      setTimeout(() => {
        this.getCoursewareList(this.searchCourseText);
      }, 500);
    };
  },
  mounted () {
  },
  methods: {
    isAuthCourseware () {
      layer.confirm(
        '您还没有该课包的授权，如果继续，将会自动将该课包授权给您，是否继续?',
        {
          btn: [
            {
              name: '取消',
              callBack: function () {
                this.IsAuthKey = 0;
                layer.close();
                if (callBack) {
                  callBack();
                }
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.IsAuthKey = 1; // 是否自动授权
                CWSTeacherGiveLectureSetUpScheduleCourse(
                  this.openClassData,
                  this.IsContinueKey,
                  this.IsAuthKey
                ).then(result => {
                  if (result.data.ScheduleCourseKey) {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 1,
                        coursewareId: result.data.ScheduleCourseKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                  } else {
                    layer.alert(error.msg);
                  }
                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    // 获取子课件包详情
    getCoursewarePackageDetails (OLAPKey, deviceCode, TeacherID) {
      console.log(this.TeacherID, '老师ID');
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      if (this.role) {
        CWSGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            console.log('geoInfo', geoInfo);
            console.log('result.data', result.data);
            this.$store.commit('setGeoInfo', geoInfo);

            console.log('校长-获取子课件包详情index页面', result.data);
          });
      } else {
        CWSTeacherWorkbenchGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            this.$store.commit('setGeoInfo', geoInfo);
            console.log('老师-获取子课件包详情index页面', result.data);
          });
      }
      console.log(6545665165);
      this.$emit('afterSuccess');
      // }
    },
    getCoursewareList (searchText) {
      let teacherKey = this.vRoleKeyA ? '' : this.$store.getters.token.UserID;
      let CoursewarePackageKey = this.dataInfo.OLAPKey || this.CoursewarePackageKey;
      TeachingModegetSearchCourseware(CoursewarePackageKey, searchText, teacherKey).then(result => {
        console.log('getCoursewareList', result.data);
        if (result.data.length > 0) {
          this.coursewarePackageList = result.data;
          this.coursewarePackageList.forEach((o, index) => {
            if (o.SettingCoverUrl) {
              o.img = this.$store.getters.CDNURL + o.CoverUrl;
            } else {
              o.img = require('../../../../../public/image/default_courseware_bj.png');
            }
          });
        } else {
          this.coursewarePackageList = [];
        }
      });

    },
    // 进入备课模式
    prepare (item) {
      console.log(
        '去备课',
        item,
        this.dataInfo,
        this.Source
      );
      if (!this.$zxClient.IsClient) {
        layer.alert('请用桌面端打开程序');
      } else {
        if (this.maxCoursewarePackage.DeviceIsAuth != 1) {
          layer.alert('设备未授权');
        } else {
          if (this.$store.getters.SaaSClientEditionKey == 6) {
            if (this.role) {
              if (this.Source == 1 || this.Source == 2) {
                // 授权
                let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
                if (
                  this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                  !this.$store.getters.geoInfo.Longitude
                ) {
                  this.openAuthDialog(item.OLAPKey, viewType, 2, 12);
                } else {
                  let newdataInfo = {
                    ScheduleCourseKey: item.ScheduleCourseKey || '',
                    courserId: this.dataInfo.OLAPKey,
                    client_id: this.$store.getters.clientId,
                    SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                    DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                    introType: 12,
                    DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                  };
                  this.$utils.verifySystemLocation(
                    newdataInfo,
                    success => {
                      this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 2,
                          coursewareId: item.OLAPKey,
                          coursewareInfoType: viewType,
                          Accuracy: this.maxCoursewarePackage.Accuracy,
                          IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                        }
                      });
                      setTimeout(() => {
                        this.getCoursewareList(this.searchCourseText);
                      }, 500);
                    },
                    error => {
                      this.openAuthDialog(item.OLAPKey, viewType, 2, 12);
                    }
                  );

                  console.log('12ssss34544444');
                }
              }
            } else {
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(item.OLAPKey, '', 4, 12);
              } else {
                console.log('12ssss345611112222');
                let newdataInfo = {
                  ScheduleCourseKey: item.ScheduleCourseKey || '',
                  courserId: this.dataInfo.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 12,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 4,
                        coursewareId: item.OLAPKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                    setTimeout(() => {
                      this.getCoursewareList(this.searchCourseText);
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(item.OLAPKey, '', 4, 12);
                  }
                );
              }
            }
          } else {
            if (this.Source == 1 || this.Source == 2) {
              let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
              // 授权
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(item.OLAPKey, viewType, 2, 22);
              } else {
                console.log('12ssss3456');
                let newdataInfo = {
                  ScheduleCourseKey: item.ScheduleCourseKey || '',
                  courserId: this.dataInfo.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 22,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 2,
                        coursewareId: item.OLAPKey,
                        coursewareInfoType: viewType,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                    setTimeout(() => {
                      this.getCoursewareList(this.searchCourseText);
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(item.OLAPKey, viewType, 2, 22);
                  }
                );
              }
            }
          }
        }
      }
    },
    openAuthDialog (OLAPKey, type, model, introType) {
      console.log(
        'openAuthDialog maxCoursewarePackage',
        this.maxCoursewarePackage
      );
      if (this.maxCoursewarePackage.HQAuthKey != 0) {
        let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
        layer.alert(text);
        return false;
      }
      this.chooseScheduleCourseKey = '';
      this.coursewareIdforOpen = OLAPKey;
      this.chooseType = model;
      this.chooseCoursewareInfoType = type;
      let skey = '';
      if (model == 1) {
        this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }
      if (this.maxCoursewarePackage.PCIsLocationLockKey == 1) {
        console.log('courseAuthQrcode');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '安全验证',
          moduleName: 'courseAuthQrcode',
          data: {
            dataInfo: {
              ScheduleCourseKey: skey,
              courserId: this.dataInfo.OLAPKey,
              client_id: this.$store.getters.clientId,
              SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
              DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              introType: introType,
              DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
            }
          },
          callBack: {
            afterSuccess: data => {
              this.busClassLocationEvent(data);
            }
          }
        });
      }
    },
    vRoleKey () {
      if (this.$store.getters.SaaSClientEditionKey == 6) {
        return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
      } else if (this.$store.getters.SaaSClientEditionKey <= 3) {
        return true;
      }
    },
    // 点击搜索
    searchTextSearch () {
      this.searchCourseText = this.searchCourseText.replace(/ /g, '');
      this.getCoursewareList(this.searchCourseText);
      console.log('this.searchCourseText', this.searchCourseText);
    },
    clearSearText () {
      this.searchCourseText = '';
      this.getCoursewareList(this.searchCourseText);
    }
  }

};
</script>

<style scoped>
.search_courseware_info {
  height: 728px;
}
.courseware_right_big {
  height: 600px;
  border-radius: 8px;
  overflow-y: auto;
  margin-top: 40px;
  margin-left: 20px;
}
.file_list_ul {
  display: flex;
  flex-wrap: wrap;
}
.defult_dialog_searchform {
  width: 579px;
  top: 20px;
  padding: 10px 12px 20px !important;
  margin: 0 auto;
}
.student_search_form {
  height: 56px;
  border: 1px solid #ff6000;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(0deg, #f3f5f3 0%, #f3f5f3 100%), #000;
  box-shadow: 0px 3px 8px 0px rgba(255, 96, 0, 0.2);
}
.student_search_form input {
  padding-right: 69px;
  padding-left: 38px;
  background-size: 19px;
  font-size: 18px;
}
.btn_light_blue_search {
  border-radius: 8px;
  background: linear-gradient(225deg, #ff6000 0%, #ff8900 100%), #000;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  bottom: 30px;
}
.big_courseware_img {
  position: relative;
  display: flex;
  margin-right: 20px;
  width: 284px;
  height: 167px;
  margin-bottom: 45px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
}
.big_courseware_default_img {
  background-color: #ccc;
}
.swimg {
  width: 284px;
  height: 167px;
  border-radius: 10px;
}
.default_img {
  width: 135px;
  height: 110px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;
}
.courseware_bj {
  width: 284px;
  height: 167px;
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 10px;
  background-color: #ccc;
}
.big_courseware_text {
  position: absolute;
  font-family: Alibaba PuHuiTi;
  color: #333;
  font-size: 14px;
  bottom: -45px;
  width: 100%;
  height: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 43px;
}
.course_block_nodata {
  margin-top: 150px;
  position: absolute;
  top: 25%;
  left: 42%;
}
.attend_class_bj {
  width: 284px;
  height: 167px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  background-image: url(../../../../../public/image/attend_class_icon.png);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  text-align: center;
  line-height: 167px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  opacity: 0;
}
.attend_class_bj:hover {
  opacity: 1;
}
</style>
