<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 475px">
      <input-text :formTitle="'校区名称'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'必填'"
                  v-model="dataInfo.CampusName"></input-text>

      <!-- <input-date :value="dataInfo.ApplyTime"
                  :minDate="$utils.formatDateToLine(Date.new())"
                  :formTitle="'申请日期'"
                  :readonly="true"
                  :elementId="'BirthdayTime'"
                  :required="false"
                  :formPlaceholder="'选填'"></input-date>

      <input-text :formTitle="'经办人'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CreateKeyValue"></input-text> -->
      <input-text :formTitle="'开通功能'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'必选'"
                  v-model="vRenewTitle"></input-text>
      <input-number :min="0"
                    :max="100"
                    :formTitle="'开通时长（年）'"
                    :formPlaceholder="'必填'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataInfo.PortalNum"></input-number>
      <input-text :formTitle="'有效期'"
                  :decimalPlaces="0"
                  :required="false"
                  :readonly="true"
                  :className="'font_black'"
                  v-model="vExpireTime"></input-text>
      <input-text :min="0"
                  :formTitle="'扣除端口数（个）'"
                  :decimalPlaces="0"
                  :required="false"
                  :readonly="true"
                  :className="'font_black'"
                  v-model="vPortalNumTitle"></input-text>
      <div v-if="isFlowSet">
        <input-select :formTitle="'流量设置'"
                      :id="'type'"
                      :name="'name'"
                      :required="true"
                      :readonly="isReadonly"
                      :selectedInfo="vflowSetInfo"
                      :dataList="flowSetType"
                      @selectedItem="changeFlowSet"></input-select>

      </div>

    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import {
  CourseCooperationOpenFeature,
  FranchiseeOpenFeature,
  RetailStoresOpenFeature,
  QueryCampuInfo
} from '../../../../API/workbench';
export default {
  data () {
    return {
      isFlowSet: false,
      // 流量设置
      flowSetType: [
        { type: 1, name: '总部' },
        { type: 2, name: '门店' }
      ],
      // 直营类型
      sourceType: [
        { type: 1, name: '参股直营' },
        { type: 2, name: '直属直营' },
        { type: 3, name: '课件加盟' },
        { type: 4, name: '整店加盟' }
      ],
      locationInfo: [], // 地址
      dataInfo: {
        CampusName: '', // "校区名",
        CampusKey: '', // 校区ID
        PortalNum: '1', // "扣除端口数",
        OpenFeatureTypeKey: '',   // "开通功能类型 1-教务；2-课件"
        IsOpenChannelKey: '', // "是否开通课件 0-否;1-是",
        IsOpenAcademicKey: '', // "是否开通教务  0-否;1-是",
        ExpiredTime: '',    // 有效期
         CurrentFluxObjectKey: 1, // 1-总部;2-门店
        CurrentFluxObjectKeyValue: '总部', // 总部、门店
      }
    };
  },
  components: {},
  props: {
    campusInfo: {
      type: Object,
      default: () => {    // 下面都是必传的值。
        return {
          CampusKey: '',  // 校区ID
          CampusName: '', // 校区名字
          MobilePhone: '',  // 负责人手机号·
          IsOpenChannelKey: '', // "是否开通课件 0-否;1-是",
          IsOpenAcademicKey: '', // "是否开通教务  0-否;1-是",
          ExpireTime: '',   // 到期日
          type: '3' // 1-直营店;2-加盟店;3-课程合作
        };
      }
    },
    isReadonly: Boolean
  },
  created () {
    // console.log(this.$store.getters, 'initAddData');
    // if(this.campusInfo.type==1){
    //   this.getDataInfo();
    // }
    this.initData();

  },
  computed: {
    // 流量设置
    vflowSetInfo () {
      return {
        type: this.dataInfo.CurrentFluxObjectKey,
        name: this.dataInfo.CurrentFluxObjectKeyValue
      };
    },
    vRenewTitle () {
      let text = '';
      text = this.campusInfo.IsOpenAcademicKey == 1 ? '教务' : '课件';
      return text;
    },
    vExpireTime () {
      if (this.dataInfo.PortalNum > 0) {
        let ExpiredTime = this.$utils.formatDateToLine(Date.new());
        //  if(this.campusInfo.type==1){

        //     ExpiredTime= this.dataInfo.IsOpenAcademicKey==1?this.dataInfo.ExpiredTime:this.dataInfo.CoursewareChannelExpiredTime
        //  }else{
        //      ExpiredTime= this.campusInfo.ExpireTime
        //  }
        //  if(!ExpiredTime){
        //    ExpiredTime=this.$utils.formatDateToLine(Date.new())
        //  }
        // let date1 = this.$utils.formatDateToLine(
        //   Date.new(
        //     Date.new(ExpiredTime).setDate(
        //       Date.new(ExpiredTime).getDate()
        //     )
        //   )
        // );
        let date1 = ExpiredTime;
        // let date2 = this.$utils.formatDateToLine(Date.new(Date.new(ExpiredTime).setFullYear(Date.new(ExpiredTime).getFullYear() + Number(this.dataInfo.PortalNum))));
        let date2 = this.$utils.formatDateToLine(Date.new((Date.new(ExpiredTime).getFullYear() + Number(this.dataInfo.PortalNum)), (Date.new(ExpiredTime).getMonth()), (Date.new(ExpiredTime).getDate() - 1)));
        return date1 + ' - ' + date2;
      } else {
        return '';
      }
    },
    vPortalNumTitle () {
      let text = '';
      if (parseInt(this.dataInfo.PortalNum) > 0) {
        text +=
          this.dataInfo.IsOpenAcademicKey == 1
            ? '教务*' + parseInt(this.dataInfo.PortalNum)
            : '课件*' + parseInt(this.dataInfo.PortalNum);
        console.log(this.dataInfo, 'vPortalNumTitle.', text);
      }
      return text;
    }
  },
  methods: {
    // 选择流量设置
    changeFlowSet (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.CurrentFluxObjectKey = obj.type;
      this.dataInfo.CurrentFluxObjectKeyValue = obj.name;
      console.log(this.dataInfo.CurrentFluxObjectKey, 'this.dataInfo.CurrentFluxObjectKey');
    },
    getDataInfo () {
      QueryCampuInfo(
        this.campusInfo.MobilePhone,
        this.campusInfo.CampusKey
      ).then((result) => {
        console.log(result.data, 'getDataInfo');
        this.dataInfo = result.data;
        this.initData();
      });
    },
    initData () {
      console.log(this.campusInfo, 'initData');
      if (this.campusInfo.IsOpenAcademicKey == 0) {
        this.isFlowSet = true;
        this.dataInfo.CurrentFluxObjectKey = 1;
        this.dataInfo.CurrentFluxObjectKeyValue ='总部';
      } else {
        this.isFlowSet = false;
        this.dataInfo.CurrentFluxObjectKeyValue = '未设置';
      }
      this.dataInfo.CampusKey = this.campusInfo.CampusKey;  // 校区ID
      this.dataInfo.CampusName = this.campusInfo.CampusName;// 校区名字
      this.dataInfo.PortalNum = 1;
      this.dataInfo.ExpireTime = this.campusInfo.ExpireTime;
      this.dataInfo.IsOpenAcademicKey = this.campusInfo.IsOpenAcademicKey || '0';
      this.dataInfo.IsOpenChannelKey = this.campusInfo.IsOpenChannelKey || '0';
      this.dataInfo.OpenFeatureTypeKey = this.campusInfo.IsOpenAcademicKey == 1 ? '1' : '2';
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.campusInfo.type == 3) {
        CourseCooperationOpenFeature(this.dataInfo, this.campusInfo.CampusKey)
          .then(
            (result) => {
              layer.alert('开通成功');
              this.$emit('afterSuccess');
              this.cancel();
            },
            (error) => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      } else if (this.campusInfo.type == 1) {
        RetailStoresOpenFeature(this.dataInfo, this.campusInfo.CampusKey)
          .then(
            (result) => {
              layer.alert('开通成功');
              this.$emit('afterSuccess');
              this.cancel();
            },
            (error) => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      } else {
        // 加盟店
        FranchiseeOpenFeature(this.dataInfo, this.campusInfo.CampusKey)
          .then(
            (result) => {
              layer.alert('开通成功');
              this.$emit('afterSuccess');
              this.cancel();
            },
            (error) => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      }
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      this.dataInfo.PortalNum = parseInt(this.dataInfo.PortalNum);
      if (!this.dataInfo.PortalNum) {
        layer.alert('请输入开通时长（年）');
        flag = false;
      } else if (!this.dataInfo.CurrentFluxObjectKeyValue) {
        layer.alert('请选择流量设置');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
