<template>
  <div class="inventory_management_div">
    <!-- 标题菜单栏 -->
    <report-title-bar :menuList="menuList"
                      @changeMenuOpt="doChangeMenuOpt"></report-title-bar>
    <div class="margin_left_right_div">
      <!-- 库存明细 -->
      <div class="inventoryDetailFile template_minOrmax_width_div">
        <inventory-detail-file v-if="menuInfo.key=='KCGL' && menuInfo.opt"
                               @showInventoryRecord="showInventoryModule"></inventory-detail-file>
      </div>

      <!-- 出入库记录 -->
      <div class="inventoryRecordFile template_minOrmax_width_div">
        <inventory-record-file v-if="menuInfo.key=='KCJL' && menuInfo.opt"
                               :tableData="inventoryDetailInfo"></inventory-record-file>
      </div>
    </div>
    <div>
      <dialog-factory ref="inventoryManagementFile"
                      :routerName="'inventoryManagementFile'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import reportTitleBar from '../common/report-title-bar';
import inventoryDetailFile from './inventory-detail-file/index';
import inventoryRecordFile from './inventory-record-file/index';
import dialogFactory from '../../common/dialog-factory';

import { } from '../../../API/workbench';
export default {
  name: 'inventoryManagementFile',
  data () {
    return {
      //  头部标题菜单栏
      menuList: [
        { name: '库存明细', key: 'KCGL', opt: true },
        { name: '出入库记录', key: 'KCJL', opt: false }
      ],
      menuInfo: { name: '库存明细', key: 'KCGL', opt: true },
      inventoryDetailInfo: {}// 库存明细
    };
  },
  components: {
    inventoryDetailFile,
    inventoryRecordFile,
    reportTitleBar,
    dialogFactory
  },
  created () {

  },
  methods: {
    // 标题菜单项
    doChangeMenuOpt (item) {
      this.menuInfo = item;
      if (item.key == 'KCGL') { // 库存明细
        this.inventoryDetailInfo = {};
      }
      console.log('标题菜单项:', this.menuInfo);
    },
    // 定位到某项库存模块
    showInventoryModule (menu, data) {
      this.menuList.forEach(o => {
        if (o.key != menu.key) {
          o.opt = false;
        } else {
          o.opt = true;
        }
      });
      this.inventoryDetailInfo = data;
      this.menuInfo = menu;
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherPerformance) {
        this.$refs.teacherPerformance.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  }
};
</script>
<style scoped>
.inventory_management_div >>> .table_fixed_nav_box .table_fixed_nav {
  width: auto !important;
  min-width: 1366px;
  max-width: 1920px;
  padding-left: 20px;
}
</style>