<template>
  <!-- 课件包表单 -->
  <div class="courseware_form">
    <div 
         v-if="dataInfo.TypeKey==1">
      <div class="form_info_edit form_info_list">
        <input-text :formTitle="'课包名称'"
                    :required="true"
                    :readonly="isReadonly"
                    :customPlaceholder="true"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.CoursewarePackageName"></input-text>
       
      <input-text :formTitle="'课件包封面'"
                    :required="false"
                    :readonly="true"
                    :formPlaceholder="''"
                    :amendButtonTitle="'清除封面'"
                    @clickAmend="cleanCoverUrl"
                    class="no_border_bottom courseware_form_img"
                    :value="''">
        
      </input-text>
        <div class="courseware_form_upload_box"
            :class="dataInfo.CoverUrl?'show_Img':''">
          <div class="courseware_form_upload_noImg"
              v-if="!dataInfo.CoverUrl">
            <div class="upload_icon">+</div>
            <div>上传图片</div>
          </div>
          <div id="screanContent"
              style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
          <input-upload-img :ImgURl="dataInfo.CoverUrl"
                            :formTitle="''"
                            :imgHeight="360"
                            :imgWidth="640"
                            :readonly="isReadonly"
                            :acceptType="'image/*'"
                            @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>
        </div>
     
      </div>
      <div  class="input_readonly form_info_value" style="text-align: center;padding-bottom: 10px;border-bottom: 1px solid #ececec;">
        上传图片尺寸为 640*360px(16:9)
      </div>
    </div>
    <div v-else-if="dataInfo.TypeKey==2"
        >
      <!-- <div class="form_title_info">基础设置</div> -->
      <div class="form_info_edit form_info_list">
        <input-text :formTitle="'课件包名称'"
                    :required="true"
                    :readonly="isReadonly"
                    :customPlaceholder="true"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.CoursewarePackageName"></input-text>

     <input-text :formTitle="'课件包封面'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  class="no_border_bottom courseware_form_img"
                  :amendButtonTitle="'清除封面'"
                    @clickAmend="cleanCoverUrl"
                  :value="''"></input-text>
      <div class="courseware_form_upload_box"
           :class="dataInfo.CoverUrl?'show_Img':''">
        <div class="courseware_form_upload_noImg"
             v-if="!dataInfo.CoverUrl">
          <div class="upload_icon">+</div>
          <div>上传图片</div>
        </div>
        <div id="screanContent"
             style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
        <input-upload-img :ImgURl="dataInfo.CoverUrl"
                          :formTitle="''"
                          :imgHeight="360"
                          :imgWidth="640"
                          :readonly="isReadonly"
                          :acceptType="'image/*'"
                          @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>

      </div>
      <div  class="input_readonly form_info_value" style="text-align: center;padding-bottom: 10px;border-bottom: 1px solid #ececec;">
        上传图片尺寸为 640*360px(16:9)
      </div>
        <!-- <input-number :formTitle="'学期时长（月）'"
                      class="no_border_bottom"
                      :required="false"
                      :readonly="isReadonly"
                      :formPlaceholder="'请输入'"
                      v-model="dataInfo.TermDurationNum"></input-number> -->

      </div>
    
    </div>
     
    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import {
  CoursewarePackageManageAdd,
  hqCoursewarePackageManageAdd,
  CWSCoursewarePackageManageAdd,
  CoursewarePackageManageEdit,
  CWSCoursewarePackageManageEdit,
  hqCoursewarePackageManageEdit,
  CoursewarePackageManageGetDetail,
  hqCoursewarePackageManageGetDetail,
  CWSCoursewarePackageManageGetDetail
} from '../../API/workbench';
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: '警告提示' },
        { type: 2, name: '自动禁用' }
      ],
      locationInfo: [], // 地址
      dataInfo: {
        CoursewarePackageName: '', //	课件包名称
        CoverUrl: '', // 课件包封面
        TermDurationNum: '', // 学期时长（月）
        ParentKey: '', // 大课包ID
        ParentKeyValue: '', // 大课包
        TypeKey: 0, // 	整型	必须		数据源：GET	课包类型 1-目录2-子课包
        
      },
    };
  },
  components: {

  },
  props: {
    maxCoursewareInfo: Object, // 大课包信息
    coursewareId: Number,
    isReadonly: Boolean,
    parentCourseBag: Object
  },
  created () {
    this.dataInfo.TypeKey = this.parentCourseBag.catalogKey;
    if (this.coursewareId > 0) {
      this.getDetailInfo();
    }
    console.log('大课包信息', this.maxCoursewareInfo);
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // 模式
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    cleanCoverUrl(){
      console.log("清空背景图")
      this.dataInfo.CoverUrl=''
    },
    // 课件包封面地址
    doSetPicCorpHeadImg (Url) {
      console.log('课件包封面地址', Url);
      this.dataInfo.CoverUrl = Url;
    },
    // 获取授权详情信息
    getDetailInfo () {
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSCoursewarePackageManageGetDetail;
      } else if (type == 5) {
        apiFN = hqCoursewarePackageManageGetDetail;
      } else {
        apiFN = CoursewarePackageManageGetDetail;
      }
      apiFN(this.coursewareId).then(result => {
        Object.assign(this.dataInfo, result.data);
        console.log('获取授权详情信息', this.dataInfo);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      // 修改
      if (this.coursewareId > 0) {
        let apiFN = '';
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSCoursewarePackageManageEdit;
        } else if (type == 5) {
          apiFN = hqCoursewarePackageManageEdit;
        } else {
          apiFN = CoursewarePackageManageEdit;
        }
        apiFN(this.coursewareId, this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$set(result.data, 'isOpt', this.dataInfo.isOpt||false);
          this.$set(result.data, 'isSelected', this.dataInfo.isSelected||false);
          this.$set(result.data, 'childTree', this.dataInfo.childTree||[]);
          this.$emit('afterSuccess', 'edit', result.data);
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      } else { // 新增
        this.dataInfo.ParentKey = this.parentCourseBag.ParentKey;
        this.dataInfo.ParentKeyValue = this.parentCourseBag.ParentKeyValue;
        let apiFN = '';
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSCoursewarePackageManageAdd;
        } else if (type == 5) {
          apiFN = hqCoursewarePackageManageAdd;
        } else {
          apiFN = CoursewarePackageManageAdd;
        }
        apiFN(this.dataInfo).then(result => {
          layer.alert('新增成功');
          this.$set(result.data, 'isOpt', false);
          this.$set(result.data, 'isSelected', false);
          this.$set(result.data, 'childTree', []);
          this.$emit('afterSuccess', 'add', result.data);
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      }
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.CoursewarePackageName) {
        layer.alert('请输入课件包名称');
        flag = false;
      }else{
       const  reg = /^([\u4E00-\u9FA5A-Za-z0-9\uFF00-\uFFFF\u2026\u00D7\u00B7\u3010\u3011,\s`~!@#$%^&*()_。，——·‘’“”、。《》－；+<>?:"{},.\/;'[\]=\-|\\])+$/
        //   /[\s\x21-\x7e//\u4e00-\u9fa5A-Za-z0-9_-]*/
        //   /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]?$/;
        const  nonChineseCharacter = this.dataInfo.CoursewarePackageName
        for(let i = 0; i<nonChineseCharacter.length; i++){
          if(!reg.test(nonChineseCharacter.charAt(i))){
            flag = false;
            layer.alert('课件包名称不能有特殊字符');
            return false
          }
        }
        
        
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.courseware_form>>>.courseware_form_img .form_info_value{
  text-align: left !important; 
}

.courseware_form_upload_box {
    width: 200px;
    height: 126px;
    background: #f9fafc;
    border: 1px solid #ececec;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    margin: auto;
    margin-top: -30px;
}
.courseware_form_upload_box >>> .my_datum_picture_portrait img {
    width: 200px!important;
    height: 125px!important;
    border-radius: 0!important;
}
</style>

