<template>
  <div>
    <div class="content_marginTop_30 content_marginRL">
      <div class="form_info_edit mendian_setting_content single_shadow">
        <div class="form_info_line">
          <div class="form_info_field">
            <span>原密码</span>
          </div>
          <div class="form_info_value font_cyan">
            <input type="password"
                   autocomplete="new-password"
                   placeholder="请输入原密码"
                   v-model="oldPwd" />
          </div>
        </div>
        <div class="form_info_line">
          <div class="form_info_field">
            <span>新密码</span>
          </div>
          <div class="form_info_value font_cyan">
            <input type="password"
                   placeholder="密码长度6-18位,非纯数字"
                   autocomplete="off"
                   v-model="newPwd1" />
          </div>
        </div>
        <div class="form_info_line">
          <div class="form_info_field">
            <span>确认密码</span>
          </div>
          <div class="form_info_value font_cyan">
            <input type="password"
                   placeholder="请确认密码"
                   autocomplete="off"
                   v-model="newPwd2" />
          </div>
        </div>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="submit"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>
<script>
import { HQEditPassword, CWSTeacherEditPassword,EditPassword } from '../../API/workbench';
export default {
  data () {
    return {
      oldPwd: '',
      newPwd1: '',
      newPwd2: ''
    };
  },
  props: {
    OLAPKey: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  created () {
    console.log('this.vSaasClientInfo', this.vSaasClientInfo, this.OLAPKey);
  },
  methods: {
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 确认修改密码确认判断
    submit (callBack) {
      // 旧密码
      if (this.oldPwd == '') {
        layer.alert('原密码不能为空');
        if (callBack) {
          callBack();
        }
      } else {
        if (this.newPwd1 == '') {
          // 新密码
          layer.alert('新密码不能为空');
          if (callBack) {
            callBack();
          }
        } else if ((this.newPwd1.length < 6 || this.newPwd1.length > 18) || (!isNaN(this.newPwd1) && this.newPwd1.indexOf('.') == -1)) {
          layer.alert('密码长度为6-18位,非纯数字');
          if (callBack) {
            callBack();
          }
        } else {
          if (this.newPwd2 == '') {
            // 确认密码
            layer.alert('确认密码不能为空');
            if (callBack) {
              callBack();
            }
          } else if (this.newPwd2 != this.newPwd1) {
            layer.alert('两次输入密码不一致');
            if (callBack) {
              callBack();
            }
          } else {
            // 全部输入正确
            if (this.$store.getters.SaaSClientEditionKey == 6) {
                CWSTeacherEditPassword(this.oldPwd, this.newPwd1).then(result => {
                  layer.alert('修改密码成功');
                  if (callBack) {
                    callBack();
                  }
                  this.cancelClick();
                }, errer => {
                  layer.alert(errer.msg);
                  if (callBack) {
                    callBack();
                  }
                });
              
            } else if(this.$store.getters.SaaSClientEditionKey == 5){
              HQEditPassword(this.oldPwd, this.newPwd1).then(result => {
                layer.alert('修改密码成功');
                if (callBack) {
                  callBack();
                }
                this.cancelClick();
              }, errer => {
                layer.alert(errer.msg);
                if (callBack) {
                  callBack();
                }
              });
            }else{
               EditPassword(this.oldPwd, this.newPwd1).then(result => {
                layer.alert('修改密码成功');
                if (callBack) {
                  callBack();
                }
                this.cancelClick();
              }, errer => {
                layer.alert(errer.msg);
                if (callBack) {
                  callBack();
                }
              });
            }
          }
        }
      }
    }
  }
};
</script>
<style >
.message_box_btn {
  background: #fff;
  width: 410px;
  padding: 20px 22px;
}
</style>