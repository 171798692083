<template>
  <div class="add_student"
       @click.stop="showDialog">
    <div class="add_student_left">
      <img src="../../../public/image/plus.png"
           alt="">
    </div>
    <div class="add_student_right">
      <span>添加学生
      </span>
    </div>
  </div>
</template>
<script>
import dropDownBox from './drop-down-box';
export default {
  name: 'addStudentBtn',
  data () {
    return {
    };
  },
  components: {
    dropDownBox
  },
  props: {
    isAlter: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  methods: {
    showDialog () {
      if (this.isAlter) {
        let Rect = this.$el.getBoundingClientRect();
        let parentRect = this.$parent.$el.getBoundingClientRect();
        let that = this;
        document.onclick = function () {
          that.$store.commit('showAlerDialog', false);
          // studentInfo.isSelected = false;
        };
        console.log(this.isBatchAttendance, typeof this.$parent.isShowDialog, 'isBatchAttendance2');
        if (typeof this.$parent.isShowDialog == 'boolean') {
          this.$parent.isShowDialog = true;
        }
        this.$store.commit('showAlerDialog', true);
        this.$emit('setPosition', Rect.left - parentRect.left, Rect.bottom - parentRect.top + 14, parentRect.top); // event.clientX  event.clientY   //显示修改考勤
      }
    }
  }
};
</script>

<style>
.el-select .el-input--suffix {
  border: none;
  background-color: transparent;
  color: #49b5f3;
}
</style>
