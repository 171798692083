
<!-- 在读课程 合并多列的 显示课程-》班级-》排课情况 -->
<template>
 <el-table-column :fixed="columnData.fixed||false"
                              :label="columnData.label" 
                              :prop="columnData.prop"
                              :width="columnData.width||200"
                              :align="columnData.align||'left'"
                              :label-class-name="columnData.columnClass">
  <template slot-scope="scope">
     <div v-if="scope.row.sourceData&&scope.row.sourceData.length>0">
      <course-row v-for="(Obj,index) in scope.row.sourceData"
                  :key="index"
                  :courseItem="Obj"></course-row> 
    </div>
    <div v-else class="cell_course"></div>
  </template>
</el-table-column>
</template>

<script>
import courseRow from './course-row/index';
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            splitSymbol: ',' // 分割符号
          }
        };
      }
    }
  },
  components: {
    courseRow
  },
  created () {
    // console.log(this.scope.row.sourceData, '列名配置-1232131-6666666666');
  },
  methods: {
    popTitle (val) {
      if (val && val.length > 10) {
        return val;
      } else {
        return '';
      }
    }
  }
};
</script>

<style>

</style>