<template>
  <!--ShowTypeKey-2:单行文本 -->
  <div class="form_list_content" :class="keyIndex<dataLength-1&&item.Length!=1&&!item.ParentName&&nextItem&&nextItem.ShowTypeKey!=6?'show_left_Line':''"
       :style="vFieldsWidth">
    <div class="form_list_name" >
      <span class="text_box font_black">{{item.FieldDescription&&categoryItem.MainDemoName!=item.FieldDescription?item.FieldDescription+'':''}}{{item.DefaultValue}}{{item.Unit}}</span>
    </div>
  </div>
</template> 
<script>

export default {
  components: {

  },
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object, // 字段项
    rowWidth: Number, // 总行宽
    keyIndex: Number, // 当前下标
    dataLength: Number, // 数组长度
    nextItem:Object, // 字段项
  },
  created () {
    console.log('单行文本:', this.categoryItem);
  },
  computed: {
    vFieldsWidth () {
      let FWh = 0;
      if (Number(this.item.Length) > FWh) {
        FWh = Number(this.rowWidth) / Number(this.item.Length);
        return 'max-width:' + FWh + 'px';
      } else {
        return '';
      }
    }
  },
  methods: {

  }
};
</script>

<style>
</style>