<template>
  <div class="payment_statistics_view height_type">
    <!-- 考勤图表 showCourseStatisticsClick-->
    <!-- <div>
      <top-report></top-report>
    </div> -->
    <!-- <div class="general_summarizing_student_nav"
         style="height: 20px;width: 100%;">
      <div class="statistics_box">
        <div class="nav_list"
             :class="{opt:isShowNavbox=='course'}"
             style="position: absolute;height: 45px;padding: 0 15px;"
             @click.stop="showCourseStatisticsClick">
          按课程统计
        </div>
        <div class="nav_list"
             :class="{opt:isShowNavbox=='class'}"
             style="position: absolute;height: 45px;margin-left: 120px;"
             @click.stop="showClassStatisticsClick">
          按班级统计
        </div>
      </div>
    </div> -->

    <div class="table_ul_nav flex">
      <div class="title_list"
           :class="{opt:isShowNavbox=='course'}"
           @click.stop="showCourseStatisticsClick">
        按课程统计
      </div>
      <div class="title_list"
           :class="{opt:isShowNavbox=='class'}"
           @click.stop="showClassStatisticsClick">
        按班级统计
      </div>
    </div>
    <!-- 按课程统计 -->
    <div v-if="isShowNavbox=='course'">
      <table-course-content @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"
                            :teacherList="teacherList"
                            :gradeClassList="gradeClassList"
                            @doAfterTableBtnClicks="doAfterTableBtnClicks"
                            :courseNameList="courseNameList"></table-course-content>
    </div>
    <!-- 按班级统计 -->
    <div v-else>
      <table-class-content @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"
                           :teacherList="teacherList"
                           :gradeClassList="gradeClassList"
                           @doAfterTableBtnClicks="doAfterTableBtnClicks"
                           :courseNameList="courseNameList"></table-class-content>
    </div>
  </div>
</template>
<script>
import topReport from './top-report';
import tableCourseContent from './table-course-content';
import tableClassContent from './table-class-content';
import { geTteacherList } from '../../../../API/workbench';
export default {
  data () {
    return {
      isShowNavbox: 'course',
      teacherList: [],
      gradeClassList: [],
      courseNameList: []

    };
  },
  components: {
    topReport,
    tableCourseContent,
    tableClassContent
  },
  created () {
    // this.loadDataSourceTeacher();
    // this.getValidClassList();
    // this.getCourseNameList();
  },
  methods: {
    showCourseStatisticsClick () {
      this.isShowNavbox = 'course';
    },
    showClassStatisticsClick () {
      this.isShowNavbox = 'class';
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      this.$emit('openCustomDialogByFactoryDialog', dialogInfo);
      console.log(dialogInfo, 'operatingRevenueStatistics');
    },
    // 调用在职老师接口
    // loadDataSourceTeacher () {
    //   geTteacherList().then(result => {
    //     this.teacherList = result.data;
    //     this.teacherList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    // 获取有效班级列表
    // getValidClassList () {
    //   getUsingClass().then(result => {
    //     this.gradeClassList = result.data;
    //     this.gradeClassList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    // getCourseNameList () {
    //   getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
    //     this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
    //     this.courseNameList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    doAfterTableBtnClicks (data) {
      console.log('doAfterTableBtnClicks');
      this.$emit('doAfterTableBtnClicks', data);
    }
  }
};
</script>