<template>
  <div class="record_img_content">
    <div class=""
         style="width:34px">
      <div class="font_black"
           style="font-size:14px">
        {{vYear}}
      </div>
      <div class="font_gray"
           style="font-size:12px;color:#999">
        {{vDate}}
      </div>
    </div>
    <div class="record_right_content">
      <div class="record_right_content_item">
        <span class="font_black span_flex">
          <span class="font_gray"
                style="color:#999">节点:</span>
          <span>{{item.FormerNodeKeyValue}}</span>
          <span class="record_type_01"
                v-if="item.FormerNodeKeyValue">→</span>
          <span>{{item.CurrentNodeKeyValue}}</span>
        </span>

      </div>
      <div class="record_right_content_item"
           v-if="vLabelChangeList.length>0">
        <span class="font_black span_flex">
          <span class="font_gray"
                style="color:#999">标签: </span>
          <span style="display: flex;"
                v-for="(item,key) in vLabelChangeList"
                :key="key"
                :item="item">
            <span :class="setType(key)"></span>
            <span :style="setLineStyle(key)"> {{setLabelChangeText(key)}}</span>
            <span v-if="key<vLabelChangeList.length-1">、</span>
          </span>

        </span>

      </div>

      <div class="record_right_content_item font_black"
           style="line-height:20px;word-break: break-word;"
           v-html="item.FollowContent"></div>
      <div class="record_right_content_item"
           v-if="item.FollowUpUrlList.length>0">
        <div class="record_img_content_imgitem"
             v-for="(imgItem, key) in item.FollowUpUrlList"
             :key="key"
             @click="showPreview(imgItem)">
          <img :src="$store.getters.CDNURL + imgItem.FollowUpUrl"
               :onerror="defaultImg"
               alt="" />
        </div>
      </div>

      <div class=""
           style="color:#999">操作人: {{item.FollowUpPersonKeyValue}}</div>
    </div>
    <custom-dialog :title="'预览图片'"
                   :visible.sync="isPreviewImg"
                   :before-close="closePreviewImg">
      <div class="home_work_img">
        <preview-img :imgList="vimgList"
                     :showIndex="item.index"
                     :previewText="item.Content"
                     @onClosePreviewImg="closePreviewImg"></preview-img>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import previewImg from '../../home-school-communication/common-component/preview-img/index';
export default {
  data () {
    return {
      isPreviewImg: false
    }
  },
  components: {
    previewImg
  },
  props: {
    item: Object
  },
  computed: {
    vimgList () {
      let list = [];
      this.item.FollowUpUrlList.forEach(o => {
        list.push({ imgUrl: o.FollowUpUrl, id: o.OLAPKey });
      });
      return list;
    },
    vYear () {
      let date = ''
      date = this.item.GenerateTime ? this.item.GenerateTime.substring(0, 4) : ''
      return date
    },
    vDate () {
      let date = ''
      date = this.item.GenerateTime ? this.item.GenerateTime.substring(5, 10) : ''
      return date
    },
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    },

    vLabelChangeList () {
      let newArr = []
      if (this.item.LabelChange == '' || this.item.LabelChange == '-') {
        return newArr
      } else {
        newArr = this.item.LabelChange.split('、')
        console.log("newArr", newArr)
        return newArr
      }
    },

  },
  methods: {
    showPreview (item) {
      this.PhotoUrl = this.$store.getters.CDNURL + item.AttachUrl;
      console.log(item, 'aaa');
      this.isPreviewImg = true;
      let locaIndex = this.item.FollowUpUrlList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey;
      });
      if (locaIndex >= 0) {
        this.item.Content = item.Title;
        this.item.index = locaIndex;
      } else {
        this.item.index = 0;
      }
    },
    // 关闭预览图片
    closePreviewImg () {
      this.isPreviewImg = false;
      this.item.index = 0;
    },
    setLineStyle (key) {
      let text = ''
      if (this.vLabelChangeList.length > 0) {
        console.log("this.vLabelChangeList.length", this.vLabelChangeList)
        text = this.vLabelChangeList[key].substring(0, 1)
        // if(this.vLabelChangeList.length==1){
        //   return text==''
        // }else{
        return text == '-' ? 'text-decoration-line: line-through;' : ''
        // }
      }
      return text
    },
    setType (key) {
      let text = ''
      if (this.vLabelChangeList.length > 0) {
        console.log("this.vLabelChangeList.length", this.vLabelChangeList)
        text = this.vLabelChangeList[key].substring(0, 1)
        // if(this.vLabelChangeList.length==1){
        //   return text==''
        // }else{
        return text == '-' ? 'record_type_02' : 'record_type_04'
        // }
      }
      return text
    },
    setLabelChangeText (key) {
      if (this.vLabelChangeList.length > 0) {
        let text = key < this.vLabelChangeList.length - 1 ? '、' : ''
        return this.vLabelChangeList[key].substring(1, this.vLabelChangeList[key].length)
      }
    },

  }
}
</script>
<style scoped>
.record_right_content {
  flex: 1;
  padding-left: 15px;
  display: flex;
  flex-direction: column;

  /* margin-bottom: 10px; */
}
.record_right_content_item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
}
.span_flex {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.span_flex > span {
  margin-bottom: 5px;
}
.record_type_01 {
  /* background: url("../../../../public/image/record_type_01.png") center no-repeat;
  background-size: 14px;
  display: inline-block; */
  width: 15px;
}
.record_type_02 {
  /* background: url("../../../../public/image/record_type_02.png") center
    no-repeat;
  background-size: 14px;
   display: inline-block;
  width: 15px; */
}
.record_type_04 {
  background: url('../../../../public/image/record_type_04.png') center
    no-repeat;
  background-size: 14px;
  display: inline-block;
  width: 15px;
}
.record_img_content {
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
  /* height: 109px; */
  /* margin-bottom: 15px; */
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.record_img_content_imgitem {
  width: 85px;
  height: 85px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.record_img_content_imgitem img {
  width: 85px;
  height: 85px;
  object-fit: contain;
}
</style>
