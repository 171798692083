<template>
  <div>
    <!-- 收支录入 -->
    <custom-dialog title="选择客户"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="enteringClientListShow"
                   :before-close="closeClientDialog">
      <student-for :formTitle="'学生列表'"
                   :switchItem="1"
                   @selectedItem="selectedItemEvent"></student-for>
    </custom-dialog>
    <div class="form_info_detail">
      <div class="content_marginRL content_marginTop_30">
        <div class="student_info_edit form_info_edit single_shadow">
          <div class="form_info_tabs">
            <!-- active 选中类 -->
            <div class="info_tabs_income info_tabs_item"
                 :class="{active:isSelectedIncome}"
                 @click.stop="incomeClick(1)">
              <span class>收入录入</span>
            </div>
            <div class="info_tabs_incomePay info_tabs_item"
                 :class="{active:!isSelectedIncome}"
                 @click.stop="paymentClick(2)">
              <span class>支出录入</span>
            </div>
          </div>
          <div class="form_info_list">
            <input-number :min="0"
                          :max="10000000"
                          v-model="AmountItem"
                          :formTitle="isSelectedIncome?'收入金额':'支出金额'"
                          :decimalPlaces="2"
                          :placeholder="'必填'"
                          :required="true"
                          :readonly="false"></input-number>
            <div class="form_info_line form_info_required">
              <div class="form_info_field">收支账户</div>
              <div class="form_info_value form_info_select">
                <el-select v-model="selectedIncomePayAccount"
                           placeholder="必选"
                           @change="accountEvent"
                           value-key="OLAPKey">
                  <el-option v-for="item in incomePayAccountList"
                             :key="item.OLAPKey"
                             :label="item.MainDemoName"
                             :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div class="form_info_line form_info_required"
                 v-if="isSelectedIncome == true">
              <div class="form_info_field">收支科目</div>
              <div class="form_info_value form_info_select">
                <el-select v-model="selectedIncomePaySubject"
                           placeholder="必选"
                           @change="subjectEvent"
                           value-key="OLAPKey">
                  <el-option v-for="item in subjectListS"
                             :key="item.OLAPKey"
                             :label="item.MainDemoName"
                             :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div class="form_info_line form_info_required"
                 v-else>
              <div class="form_info_field">收支科目</div>
              <div class="form_info_value form_info_select">
                <el-select v-model="selectedIncomePaySubject"
                           placeholder="必选"
                           @change="subjectEvent"
                           value-key="OLAPKey">
                  <el-option v-for="item in subjectListZ"
                             :key="item.OLAPKey"
                             :label="item.MainDemoName"
                             :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div class="form_info_line form_info_required">
              <div class="form_info_field">发生人类型</div>
              <div class="form_info_value form_info_select">
                <el-select v-model="selectedHapPeopleType"
                           placeholder="必选"
                           @change="getValueEvent"
                           value-key="key">
                  <el-option v-for="item in hapPeopleType"
                             :key="item.key"
                             :label="item.value"
                             :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div class="form_info_line form_info_required"
                 v-if="selectedHapPeopleType !==''">
              <div class="form_info_field">{{selectedHapPeopleType.value}}</div>
              <div class="form_info_value form_info_select"
                   v-if="selectedHapPeopleType.value =='客户'"
                   @click.stop="changeClientEvent()">
                <input type="text"
                       placeholder="必选"
                       readonly
                       v-model="enteringClient" />
              </div>
              <div class="form_info_value form_info_select"
                   v-else>
                <el-select v-model="enteringPerson"
                           placeholder="必选"
                           @change="hapPeoEvent"
                           value-key="OLAPKey">
                  <el-option v-for="item in enteringType"
                             :key="item.OLAPKey"
                             :label="item.MainDemoName"
                             :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div class="form_info_line no_border_bottom">
              <div class="form_info_field">备注</div>
              <div class="form_info_value">
                <input type="text"
                       placeholder="选填"
                       v-model="remarksText" />
              </div>
            </div>
          </div>
        </div>
        <!-- 按钮组 -->
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="confirmClick"
                               @cancelClick="cancleClick"></save-cancel-btn-group>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getIncomeAccount,
  getIncomeSubject,
  getEnteringStaff,
  getEnteringSupplier,
  hqSZOtherAdd
} from '../../../../API/workbench.js';
import studentFor from '../../../common-module/search-list/student-for/index';
export default {
  name: 'incomePayEntry',
  data () {
    return {
      // dataInfo: {
      HapPeoKey: '', // int--发生人ID,
      HapPeoKeyValue: '', // varchar(20)--发生人,
      InPayTypeKey: 1, // int--收支类型：1-收，2-支,
      InPaySubKey: '', // int--收支科目
      // InPaySubKeyValue: this.selectedIncomePaySubject, // varchar(20)--收支科目 关联收支科目维度,
      InPayAccountKey: '', // int--收支账户,
      // InPayAccountKeyValue: this.selectedIncomePayAccount, // varchar(20)--收支账户,
      // Amount: this.AmountItem, // decimal(18, 8)--收支金额,
      // AgentKey: '', // int--创建人,
      // AgentKeyValue: '', // varchar(50),
      // CommentSupport: "无", // varchar(100)--备注,
      // InPayDetail: '', // int--收支账户是否为多个：0-单个收支账户，1-多个收支账户,
      // InPayAccountDetail: '', // varchar(2000)--多个收支账户拼接明细，如果@InPayDetail=0，改字段可为空,
      HapObjTypeKey: 1, // int, --发生人类型 1、客户 2供应商 3员工,
      HapObjTypeKeyValue: '', // varchar(120)--发生人类型,
      // },
      incomePayAccountList: [], // 收支账户列表
      incomePaySubjectList: [], // 收支科目列表
      enteringType: [], // 录入类型 2-供应商;3-员工
      enteringClientList: [], // 录入客户
      enteringClientListShow: false,
      enteringPerson: '', // 绑定2-供应商;3-员工
      enteringClient: '', // 绑定客户
      selectedIncomePayAccount: '', // 绑定收支账户值
      selectedIncomePaySubject: '', // 绑定收支科目值
      AmountItem: 0, // 输入金额
      selectedHapPeopleType: '',
      clientSearchText: '', // 搜索客户
      hapPeopleType: [
        // {
        //   key: 1,
        //   value: '客户'
        // },
        // {
        //   key: 2,
        //   value: '供应商'
        // },
        {
          key: 3,
          value: '员工'
        }
      ],
      remarksText: '', // 备注
      isSelectedIncome: true,
      incomePayJson: {}
    };
  },
  components: {
    studentFor
  },
  watch: {

  },
  created () {
    this.InfoInitialize();
    this.getConnectorData();
    this.employeeData();
    this.supplierData();
    this.getValueEvent(); // 点击员工时重新加载 有问题
  },
  methods: {
    amountText (val) {
      if (val) {
        this.AmountItem = val;
      }
    },
    inputNum (evt) {
      // 火狐使用evt.which获取键盘按键值，IE使用window.event.keyCode获取键盘按键值
      var ev = evt.which ? evt.which : window.event.keyCode;
      if (ev >= 48 && ev <= 57) {
        return true;
      } else {
        return false;
      }
    },
    InfoInitialize () {
      this.AmountItem = '';
      this.selectedIncomePayAccount = '';
      this.selectedIncomePaySubject = '';
      this.selectedHapPeopleType = '';
      this.remarksText = '';
      this.HapPeoKeyValue = '';
      this.HapPeoKey = '';
    },
    getValueEvent (item) {
      if (item !== undefined) {
        if (item.value === '员工') {
          // 获取录入员工
          this.employeeData();
        } else if (item.value == '供应商') {
          // 获取录入供应商
          this.supplierData();
        }
        // 清除发生人
        this.enteringPerson = '';
        this.enteringClient = '';
        this.HapObjTypeKey = item.key;
        this.HapObjTypeKeyValue = item.value;
      }
    },
    // 获取录入员工
    employeeData () {
      getEnteringStaff().then(result => {
        if (result.code == 0) {
          this.enteringType = result.data;
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    // 获取录入供应商
    supplierData () {
      getEnteringSupplier().then(result => {
        if (result.code == 0) {
          this.enteringType = result.data;
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    // 客户列表
    changeClientEvent () {
      this.clientSearchText = '';
      this.enteringClientListShow = true;
    },
    // 收支录入-数据源
    getConnectorData () {
      // 获取收支账户接口
      getIncomeAccount().then(result => {
        if (result.code == 0) {
          console.log('getIncomeAccount', result.data);
          this.incomePayAccountList = result.data;
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
      // 获取收支科目接口
      getIncomeSubject().then(result => {
        if (result.code == 0) {
          this.incomePaySubjectList = result.data;
          this.incomePaySubjectList.forEach(o => { });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    // 关闭收支弹窗
    closeIncomePayDialog () {
      this.cancleClick();
    },
    // 关闭客户列表弹窗
    closeClientDialog () {
      console.log('13');
      this.enteringClientListShow = false;
    },
    // 收入
    incomeClick (type) {
      // 初始值
      if (this.InPayTypeKey != type) {
        this.isSelectedIncome = true;
        this.InPayTypeKey = type;
        this.InfoInitialize();
      }
    },
    // 支出
    paymentClick (type) {
      this.isSelectedIncome = false;
      // 初始值
      if (this.InPayTypeKey != type) {
        this.isSelectedIncome = false;
        this.InPayTypeKey = type;
        this.InfoInitialize();
      }
    },
    // 取消 关闭收支录入窗口
    cancleClick () {
      this.$emit('closeIncomepayentryDialog');
      this.enteringClientListShow = false;
      this.incomeClick();
    },
    // 选中账户
    accountEvent (item) {
      console.log(item, '收支账户');
      this.selectedIncomePayAccount = item.MainDemoName;
      this.InPayAccountKey = item.OLAPKey;
    },
    // 选中科目
    subjectEvent (item) {
      console.log(item, '收支科目');
      this.selectedIncomePaySubject = item.MainDemoName;
      this.InPaySubKey = item.OLAPKey;
    },
    // 选中供应商 / 员工
    hapPeoEvent (item) {
      console.log(item, '选中供应商 / 员工');
      this.HapPeoKey = item.OLAPKey;
      this.HapPeoKeyValue = item.MainDemoName;
      if (this.selectedHapPeopleType.value == '员工') {
        this.employeeData();
      } else if (this.selectedHapPeopleType.value == '供应商') {
        this.supplierData();
      }
    },
    // 选中客户
    selectedItemEvent (item) {
      this.enteringClient = item.CustKeyValue;
      this.enteringClientListShow = false;
      console.log(item, '选中客户');
      this.HapPeoKey = item.CustKey;
      this.HapPeoKeyValue = item.CustKeyValue;
    },
    clearSearText () {
      this.clientSearchText = '';
    },
    // 验证 调用提交保存接口
    verification () {
      console.log(this.AmountItem, 'isNaN');
      let flag = false;
      var reg = /^[0-9]+.?[0-9]*$/;
      if (this.AmountItem >= 100000000) {
        layer.alert('金额必须小于一亿');
        flag = true;
      } else if (this.AmountItem <= 0) {
        layer.alert('金额必须大于0且不能为空');
        flag = true;
      } else if (isNaN(this.AmountItem) && !reg.test(this.AmountItem)) {
        layer.alert('请输入正确的数字金额');
        flag = true;
      } else if (this.selectedIncomePayAccount == '') {
        layer.alert('收支账户不能为空');
        flag = true;
      } else if (this.selectedIncomePaySubject == '') {
        layer.alert('收支科目不能为空');
        flag = true;
      } else if (this.selectedHapPeopleType == '') {
        layer.alert('请选择发生人类型');
        flag = true;
      } else if (this.HapPeoKeyValue == '') {
        layer.alert(this.HapObjTypeKeyValue + '不能为空');
        flag = true;
      }
      return flag;
    },
    // 确定保存
    confirmClick (callBack) {
      const dataInfo = {
        HapPeoKey: this.HapPeoKey, // int--发生人ID,
        HapPeoKeyValue: this.HapPeoKeyValue, // varchar(20)--发生人,
        InPayTypeKey: this.InPayTypeKey, // int--收支类型：1-收，2-支,
        InPaySubKey: this.InPaySubKey, // int--收支科目 关联收支科目维度,
        InPaySubKeyValue: this.selectedIncomePaySubject, // varchar(20)--收支科目 关联收支科目维度,
        InPayAccountKey: this.InPayAccountKey, // int--收支账户,
        InPayAccountKeyValue: this.selectedIncomePayAccount, // varchar(20)--收支账户,
        Amount: this.AmountItem || 0, // decimal(18, 8)--收支金额,
        AgentKey: this.$store.getters.token.UserID, // int--创建人,
        AgentKeyValue: this.$store.getters.token.UserName, // varchar(50),
        CommentSupport: this.remarksText, // varchar(100)--备注,
        InPayDetail: 0, // int--收支账户是否为多个：0-单个收支账户，1-多个收支账户,
        InPayAccountDetail: 1, // varchar(2000)--多个收支账户拼接明细，如果@InPayDetail=0，改字段可为空,
        HapObjTypeKey: this.HapObjTypeKey, // int, --发生人类型 1、客户 2供应商 3员工,
        HapObjTypeKeyValue: this.HapObjTypeKeyValue // varchar(120)--发生人类型,
      };
      let result = this.verification();
      if (result) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      hqSZOtherAdd(dataInfo).then(result => {
        layer.alert(result.msg);
        this.$emit('closeIncomepayentryDialog');
        this.$bus.emit('IncomePayAdd');
        this.incomeClick();
      }, error => {
        layer.alert(error.msg);
      }
      )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    }
  },
  // 通过计算属性过滤数据
  computed: {
    // 过滤收科目(收)
    subjectListS () {
      var SubjectResult = [];
      this.incomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 1) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    // 过滤支科目(支)
    subjectListZ () {
      var SubjectResult = [];
      this.incomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 2) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    }
  }
};
</script>
<style>
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>

