<template>
  <div class="WKT_student_activity_list" @click="doSelectActivity">
    <div class="WKT_student_activity_box" >
      <div class="WKT_student_activity_img">
        <img :src="vImgUrl"
             alt="">
        <div class="WKT_student_activity_number">{{Item.total_get_num}}人已参加</div>
      </div>
      <div class="WKT_student_activity_info">
        <div class="WKT_student_activity_name">
          <div class="WKT_student_activity_scale" v-if="Number(Item.target_num)>0">{{Item.target_num}}人团</div>
          <div>{{Item.active_title}}</div>
        </div>
        <div class="WKT_student_activity_date">{{Item.time_start}}至{{Item.activity_time_end}}</div>
      </div>
    </div>
    <div class="list_btn_box" v-if="isShowbtn">
      <operation-btn :isShowSetTop="true"
                     :item="Item"
                     :dataList="dataList"
                     @promote="promote"
                     @decline="decline"
                     @edit="edit"
                     @remove="remove"
                     @setTop="setTop"></operation-btn>
    </div>
  </div>

</template>
<script>
import bus from '../../../../js/bus.js';
import operationBtn from '../../common/operation-btn';
import { MoveActivitiesCoupon, SoldOutActivitiesCoupon, PutAwayActivitiesCoupon, GetActivitiesPlayByID } from '../../../../API/workbench';
export default {
  components: {
    operationBtn
  },
  data () {
    return {
      searchText: ''
    };
  },
  props: {
    Item: Object,
    Index: Number,
    dataList: Array,
    isShowbtn: Boolean
  },
  created () {

  },
  watch: {

  },
  computed: {
    vImgUrl () {
      let url = this.Item.pic || '';
      if (url && url.indexOf('http') == -1) {
        url = this.$store.getters.CDNURL + url;
      }
      return url;
    }
  },
  methods: {
    getActivitiesPlay (id, callback) {
      GetActivitiesPlayByID(id).then(result => {
        result.data.posterTemplateID = 0;
        if (callback) {
          callback(result.data);
        }
        console.log(result, 'GetActivitiesPlayByID');
      });
    },
    // 移动
    setMoveActivity (id, indedx) {
      MoveActivitiesCoupon(id, indedx).then(result => {
        // this.$emit('afterMoveActivity');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 上升
    promote () {
      console.log('上升');
      let Indedx = this.Index;
      Indedx = (Indedx == 0 ? 1 : Indedx);
      this.setMoveActivity(this.Item.id, Indedx);
      // document.getElementsByClassName('WKT_discount_package')[0].scrollTop = this.Index * 221 - 221;
    },
    // 下降
    decline () {
      console.log('下降');
      let Indedx = this.Index + 3;
      this.setMoveActivity(this.Item.id, Indedx);
      // document.getElementsByClassName('WKT_discount_package')[0].scrollTop = this.Index * 221 + 221;
    },
    // 置顶
    setTop () {
      console.log('置顶');
      this.setMoveActivity(this.Item.id, 1);
      document.getElementsByClassName('WKT_index_from_content')[0].scrollTop = 0;
    },
    // 修改
    edit () {
      console.log('toEdit', this.Item);
      this.getActivitiesPlay(this.Item.playID, (data) => {
        data.id = this.Item.id;
        let moduleInfo = {
          name: data.title,
          moduleName: 'oldIshowEditorDialog',
          routerName: this.$route.name,
          data: { dataInfo: data },
          callBack: {
            afterSuccess: (type, data) => { this.$bus.emit('selectActivitiesCoupon');}
          }
        };
        bus.emit('openCustomDialog', moduleInfo);
      });
    },
    // 下架
    remove () {
      layer.confirm('是否下架该活动?', {
        btn: [{
          name: '确认',
          callBack: () => {
            SoldOutActivitiesCoupon(this.Item.id).then(result => {
              this.dataList.splice(this.Index, 1);
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
      console.log('删除');
    },
    // 上架
    doSelectActivity () {
      if (!this.isShowbtn) {
        this.$emit('doSelectActivity', this.Item);
      }
    }
  }
};
</script>

