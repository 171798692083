<template>
  <div class="form_item form-input form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value">
      <!-- 修改模式 -->
      <input :type="Number(inputValue)>0?'number':'text'"
             v-if="!readonly"
             :class="className"
             :placeholder="placeholder"
             :value="inputValue"
             onclick="this.select();"
             @input="inputEvent($event.target.value,$event)"
             @change="changeEvent($event.target.value,$event)"
             onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.').replace(/\D/g, '')" />

      <!-- 只读模式 -->
      <input v-else
             readonly
             :value="inputValue"
             class="input_readonly" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'inputNumber',
  data () {
    return {
      inputValue: ''
    };
  },
  props: {
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    value: [Number, String], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: String, // 提示文本，可选，如不提供，则默认显示title
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    className: String, // placeholder_color      为蓝色.
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },

    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证,
    helpDescription: String // 表单说明提示
  },
  created () {
    this.inputValue = this.value;
  },
  watch: {
    value (n, o) {
      this.inputValue = n;
    }
  },
  methods: {
    inputEvent (val, e) {
      val = Number(val);
      if (!this.$utils.myIsNaN(val)) {
        val = 0;
      }
      val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputValue = parseInt(val);
      e.target.value = this.inputValue;
      this.$emit('input', this.inputValue);
    },
    changeEvent (val, e) {
      this.inputValue = parseInt(this.inputValue > Number(this.max) ? Number(this.max) : this.inputValue);
      this.inputValue = parseInt(this.inputValue < Number(this.min) ? Number(this.min) : this.inputValue);
      e.target.value = this.inputValue;
      this.$emit('change', this.inputValue);
      this.$emit('inputChange', this.inputValue);

      // this.inputValue = val;
      // this.$emit('input', this.inputValue);
    }
  }
};
</script>


