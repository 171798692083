<template>
  <div class="table_number_select">
    <div class="select_box" v-show="pageSizeShow">
      <div
        class="select_list"
        v-for="(item, key) in pageSizeList"
        :key="key"
        :class="item.isActive ? 'opt' : ''"
        @click.stop="changePageSize(item)"
      >
        <div>{{ item.value }}</div>
      </div>
    </div>
    <div class="select_text" @click.stop="pageSizeShow = !pageSizeShow">
      {{ queryParams.pageSize }}条/页
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pageSizeShow: false,
      pageSizeList: [
        {
          value: 5,
          isActive: false
        },
        {
          value: 10,
          isActive: this.pageSize == 10
        },
        {
          value: 15,
          isActive: this.pageSize == 15
        },
        {
          value: 20,
          isActive: false
        },
        {
          value: 25,
          isActive: false
        },
        {
          value: 30,
          isActive: false
        },
        {
          value: 35,
          isActive: false
        },
        {
          value: 40,
          isActive: false
        },
        {
          value: 45,
          isActive: false
        },
        {
          value: 50,
          isActive: false
        },
        {
          value: 100,
          isActive: false
        },
        {
          value: 200,
          isActive: false
        },
        {
          value: 500,
          isActive: false
        },
        {
          value: 1000,
          isActive: false
        },
        {
          value: 2000,
          isActive: false
        }
      ]
    };
  },
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    queryParams: {
      type: Object
    }
  },
  watch: {
    pageSize (n, o) {
      this.pageSizeList.forEach(o => {
        if (o.value == n) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
    }
  },
  mounted () {
    console.log();
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.pageSizeShow = false;
    }, false);
  },
  methods: {
    changePageSize (item) {
      this.queryParams.pageSize = item.value;
      this.pageSizeList.forEach((o) => {
        if (o.value == item.value) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.queryParams.pageIndex = 0;
      this.pageSizeShow = false;
      this.$emit('loadTableData', this.queryParams);
    }
  }
};
</script>