<template>
  <div class="WKT_activity_compile_popup">
    <!-- <editor-view ref="editor"
                 :dataInfo="playInfo"
                 :PosterInfo="submitData.PosterInfo"
                 :coverInfo="coverInfo"
                 :CouponInfo="submitData.CouponInfo"></editor-view> -->
    <setting-view ref="settingView"
                  :dataInfo="playInfo"
                  :coverInfo="coverInfo"
                  :PosterInfo="submitData.PosterInfo"
                  :CouponInfo="submitData.CouponInfo"
                  :contentSetting="contentSetting"
                  @setscorllTop="setscorllTop"
                  @success="success"></setting-view>
    <!-- <div @click.stop="success">lasdjl</div> -->

  </div>
</template>
<script>
var  groupSetting= {
            'id': 0, // '(新增时为0)',
            'pid': '', // '优惠券ID(新增时为0)',
            'gl_saasclientid': '', // '关联的门店id(新增时为0)',
            'target_num': 0, // '总目标人数',
            'target_type': '', // '结算类型1 用券 2，售券',
            'virtual_group_count': '', // '虚拟团数',
            'virtual_group_person_num': '', // '虚拟团人数',
            'target_1': '', // '一阶团，0表示关闭，大于0表示一阶团人数',
            'target_2': '', // '二阶团，0表示关闭，大于0表示二阶团人数',
            'target_3': '', // '三阶团，0表示关闭，大于0表示三阶团人数',
            'target_1_A': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_1_B': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_2_A': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_2_B': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_3_A': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_3_B': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            }
          }
import editorView from './component/editor-view';
import settingView from './component/setting-view';
import { GetActivityInfo, GetActivitiesPlayByID } from '../../../../API/workbench.js';
import { getModuleInfo, SearchSpecialContent, GetMenDianInfoByMenDianId } from '../../../../API/ishoweditorbench.js';
export default {
  data () {
    return {
      submitData: {
        CouponInfo: {
          'coupon_name': '', // '优惠券名称'
          'active_title': '', // '活动标题',
          'coupon_discribe': '', // '优惠券描述',
          'if_fenxiang': 1, // '1:极客券2优惠券3裂变券4线上套餐',
          'time_start': '', // '开始时间',
          'time_end': '', // '结束时间',
          'activity_time_end': '', // '活动结束时间',
          'type': '', // '优惠方式1：满折2：满减3：特价4：代金券',
          'da_zhe': '', // '优惠方式 折扣',
          'di_xian_jin': '', // '优惠方式 代金金额',
          'you_hu_money': '', // '优惠方式 优惠金额',
          'user_goods': '', // '优惠方式 消费条件 特价 商品id',
          'xiao_fei_tiao_jian': '', // '优惠方式 消费条件 指满多少钱',
          'shiyong_fanwei': '', // '优惠券使用范围 1可类 2课程 3套餐 4不限',
          'kechengid': '', // '使用范围 课程id 课类OLAPKey 套餐id',
          'taocanid': '', // '使用范围 套餐id',
          'buy_amount': 0, // '购券金额，为0表示免费领取',
          'only_newuser': 0, // '1只允许新用户 0不允许新用户',
          'sharing_activation': '', // '是否分享激活1激活，则优惠券必须分享方可使用 0为关闭，不用分享即可使用该优惠券',
          'online': '', // '是否商城上架 1是 0否',
          'shop_num': '', // '商城上架数量',
          'get_num': '', // '商城已领数量',
          'share_num': '', // '子券数量',
          'sort': '', // '排序',
          'QRCodeUrl': '', // '二维码url',
          'billId': '', // '海报id 0表示未开启海报券',
          'status': '1', // '状态 1启用 0禁用',
          'add_time': '', // '建立时间',
          'maxVirNum': '',
          'virtual_read_person_num': 0, // '虚拟浏览人数',
          'virtual_get_person_setting': '', // '', // '虚拟参与设置 如果为空，表示关闭虚拟参与，// 如果有内容，则格式为json格式｛'bgeinDate':'2019-1 - 1','endDate':'2019 - 10 - 10','bgeinTime':'08: 30','endTime':'22: 30','perDayNum':99｝'
          'programme_type': '', // '活动方案 0 默认 1 拼团',
          'promotion_parent_open': '', // '开启家长推广',
          'promotion_resale_parent_open': '', // '开启家长分销',
          'promotion_resale_parent_canbuy': '1', // '家长分销允许自己报名',
          'commission_agent_open': '', // '开启员工提成',
          't_company': '', // '活动举办方',
          't_address': '', // '活动联系地址',
          't_telephone': '', // '活动联系电话',
          'coverTemplateID': 0, // '模板封面id',
          'playID': '', // '玩法id',
          'playType': '',
          'maxGetNum': '', // '最大招生数',
          'totalGetCouponUserNum': 0,
          'packgePrice': '', // '套餐原价'
          'scopeExplain': '',  // '适用范围'
          'ActivityClassifyID':'1', //'1-优惠券招生2-引流套餐招生3-拼团招生4-阶梯团招生5-员工分销招生6-家长分销招生'
          'strategyList': [],
          // [{
          //   'pid': '优惠券id 新增时为0',
          //   'strategy_type': '策略类型 1 家长推广 2家长分销 3员工提成',
          //   'strategy_sub_type': '策略维度1用券 2售券 3浏览',
          //   'fanxian_amount': '返现金额',
          //   'fanxian_type': '返现方式1线下返现（默认） 2微信红包-自动 3微信红包-手动',
          //   'fanhour_num': '返课时数',
          //   'fanhour_keys': '返课时适用范围keys 0不限 其他1，2，3类似表示，课名课类ID ',
          //   'fanhour_keyvalues': '返课时适用范围keyvalues 0 不限 其他 A,B,C 类似表示，课名课类名称',
          //   'fangift_name': '返礼品名称 空表示，不送',
          //   'IsLink': '是否复链0否 1是',
          //   'targetnum': '达到目标才送 浏览时 指浏览人数，用券，售券暂不生效'
          // }]
          'groupSetting': {
            'id': 0, // '(新增时为0)',
            'pid': '', // '优惠券ID(新增时为0)',
            'gl_saasclientid': '', // '关联的门店id(新增时为0)',
            'target_num': 0, // '总目标人数',
            'target_type': '', // '结算类型1 用券 2，售券',
            'virtual_group_count': '', // '虚拟团数',
            'virtual_group_person_num': '', // '虚拟团人数',
            'target_1': '', // '一阶团，0表示关闭，大于0表示一阶团人数',
            'target_2': '', // '二阶团，0表示关闭，大于0表示二阶团人数',
            'target_3': '', // '三阶团，0表示关闭，大于0表示三阶团人数',
            'target_1_A': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_1_B': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_2_A': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_2_B': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_3_A': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            },
            'target_3_B': {
              'id': '', // '(新增时为0)',
              'pid': '', // '优惠券ID(新增时为0)',
              'strategy_type': '', // '策略类型 1、家长推广 2、家长分销 3、员工提成 4、拼团奖励',
              'strategy_sub_type': '', // '策略维度 1、用券 2、售券 3、浏览',
              'fanxian_amount': '', // '返现金额',
              'fanxian_type': 1, // '返现方式 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动',
              'fanhour_num': '', // '返课时数',
              'fanhour_keys': '', // '返课时适用范围Keys 0、不限 其它，1,2,3类似表示，课名课类ID',
              'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0、不限 其它，A,B,C类似表示，课名课类名称',
              'fangift_name': '', // '返礼品名称 空表示，不送',
              'IsLink': '', // '是否复链 0否1是',
              'targetnum': '', // '达到目标才送 浏览时，指浏览人数，用券、售券、拼团团长、拼团团员暂不生效',
              'description': '' // '补充说明'
            }
          }
        },
        PosterInfo: {
          'title': '', // '标题',
          'summary': '', // '描述',
          'picTemp': '', // '临时封面图片Url',
          'pic': '', //  '封面图片Url',
          'showType': '', //  '展示模式1-单页;2-多页;',
          'pageWidth': '', // '页宽',
          'pic':'',
          'pageHeight': '', // '', //  '页高',
          'templateId': '',//'模版ID,0表示没用模版',
          'activeType': '', // '内容类型 1-海报券;',
          'activeSubType': '', // 'activeType 为券时,activeSubType即为券的大类：1优惠券 2转介绍券 3裂变券',
          'activeDataID': '', // '内容数据ID',
          'fonts': '', // '字体，竖线间隔，例如：|A|B|C|',
          'pageJson': '', //  '页面内容',
          'settingJson': { musicTarget: {} }, //  '设置内容',
          'mendian_id': '', //  'WKT门店ID',
          'isLock': '', // '是否锁定 0 不锁定1锁定',
          'specialName': '' // '特殊内容名'
        }     // 海报券信息
      },
      playInfo: null,
      contentSetting: {
        imgList: null,    // 轮播图
        brief: null,    // 机构介绍
        introduce: null,     // 机构简介
        contact: null,      // 活动举办方信息
        coverImg: null    // 海报封面
      },
      specialNameList: [],
      menDianInfo: {}
    };
  },
  props: {
    dataInfo: Object,
    coverInfo: Object,
    activityInfo:Object
  },
  components: {
    editorView, settingView
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  created () {
    console.log('created', this.dataInfo, this.coverInfo,this.$route.query.TypeKey);
    
    // if (this.dataInfo.posterTemplateID > 0) {
    //   this.playInfo = this.dataInfo;
    //   this.fetchSpecialNameList(() => {
    //     // 获取门店信息.
    //     this.getMenDianInfo(() => {
    //       this.getModuleInfoByID();
    //     });
    //   });
    // } else {
      this.playInfo = this.dataInfo;
      if(this.activityInfo&&this.activityInfo.id>0){
        this.getCouponInfoForEdit(this.activityInfo.id);
      }
      // this.playInfo = this.dataInfo;
      // this.getActivitiesPlay(this.dataInfo.playID);
    // }
  },
  mounted () {
    this.getCourseNameList();
    this.$dialog.register(this, ['singleSelectionList']);
  },

  methods: {
    // 调用课类课名列表接口
    getCourseNameList () {
      this.$basicData.loadCourseNameList();
    },
    setContentSetting () {
      // 机构简介
      let introduce = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
        return o.type == 200;
      });
      console.log('contentSetting.introduce.content', introduce);
      if (introduce) {
        introduce = this.$utils.parseJson(introduce);
        introduce.content = introduce.content.toString().replace(/<br>/g, '\n');
        this.contentSetting.introduce = introduce;
      }
      // 轮播图
      this.contentSetting.imgList = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
        return o.type == 206;
      });
      // 机构介绍
      let brief = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
        return o.specialName == '活动介绍';
      });
      if (brief) {
        brief = this.$utils.parseJson(brief);
        brief.content = brief.content.toString().replace(/<br>/g, '\n');
        this.contentSetting.brief = brief;
      }
      // 海报图片
      let coverImg = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
        return o.type == 207;
      });
      if (coverImg) {
        coverImg = this.$utils.parseJson(coverImg);
        this.contentSetting.coverImg = coverImg;
      }
      // 联系方式
      let contact = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
        return o.type == 208;
      });
      if (contact) {
        contact = this.$utils.parseJson(contact);
        this.contentSetting.contact = contact;
      }
      console.log(this.contentSetting, 'this.contentSetting');
    },
    getCouponInfoForEdit (id) {
      GetActivityInfo(id).then(result => {
        // result.data.PosterInfo = this.initResult(result.data.PosterInfo, result.data.CouponInfo);
        console.log("this.submitData.CouponInfo",this.submitData.CouponInfo)
        result.data.groupSetting=result.data.groupSetting||groupSetting
        this.submitData.CouponInfo = result.data//;
        this.submitData.CouponInfo.virtual_get_person_setting = this.submitData.CouponInfo.virtual_get_person_setting ? JSON.parse(this.submitData.CouponInfo.virtual_get_person_setting) : { 'bgeinDate': '', 'endDate': '', 'bgeinTime': '', 'endTime': '', 'perDayNum': 0 };
        
        // this.$refs.editor.initGetPageJsonByID(this.submitData.PosterInfo.ID);
        // this.setContentSetting();
        this.$refs.settingView.initGetPackage(this.submitData.CouponInfo);
        console.log('GetActivitiesCouponInfo',this.submitData.CouponInfo);
      });
    },
    getActivitiesPlay (id) {
      GetActivitiesPlayByID(id).then(result => {
        result.data.posterTemplateID = 0;
        // result.data.activityId = this.dataInfo.id;
        this.playInfo = result.data;
        console.log(result, 'GetActivitiesPlayByID');
      });
    },
    success () {
      console.log('success');
      this.$bus.emit('closeEditorDialog');
      this.$emit('afterSuccess');
      this.$emit('cancelClick');
    },
    setscorllTop (type) {
      if(this.$refs.editor){

        if (Number(type)) {
          let locaItem = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
            return o.type == type;
          });
          if (locaItem) {
            console.log(locaItem, 'setscorllTop');
            let scale = 0.5859375; // 海报券里面的缩放比.
            this.$refs.editor.$el.querySelector('.ishow_editor_show').scrollTop = (locaItem.position.top) * scale;
          }
        } else {
          let locaItem = this.submitData.PosterInfo.pageJson[0].elJson.find(o => {
            return o.specialName == '活动介绍';
          });
          if (locaItem) {
            console.log(locaItem, 'setscorllTop');
            let scale = 0.5859375; // 海报券里面的缩放比.
            this.$refs.editor.$el.querySelector('.ishow_editor_show').scrollTop = (locaItem.position.top) * scale;
          }
        }
      }
      // console.log(type, 'setscorllTop')
    },

    // 获取特殊内容列表
    fetchSpecialNameList (callback) {
      SearchSpecialContent().then(response => {
        console.log(response.data, 'fetchSpecialNameList');
        this.specialNameList = response.data;
        this.specialNameList.forEach(o => {
          o.specialName = o.specialName.trim();
        });
        if (callback) {
          callback();
        }
      }).catch(err => {
        console.info(err);
      });
    },
    // 获取模板信息
    getModuleInfoByID () {
      getModuleInfo(this.dataInfo.posterTemplateID).then(result => {
        this.submitData.PosterInfo = this.initResult(result.data, {});
        if (this.dataInfo.activityId > 0) {
          this.submitData.PosterInfo.ID = this.dataInfo.activityId;
        } else {
          this.submitData.PosterInfo.ID = 0;
        }
        if (this.dataInfo.posterTemplateID > 0) {
          this.submitData.PosterInfo.templateId = this.dataInfo.posterTemplateID;
        } else {
          this.submitData.PosterInfo.templateId = 0;
        }
        this.setContentSetting();
      }).catch(error => {
        console.log('getModuleInfoByIDerr', error);
      });
    },
    initResult (data, couponInfo) {
      data.isTemplate = true;
      data.isEditting = true;
      data.pageJson = JSON.parse(data.pageJson) || [];
      // 初始化获取.
      if (!data.settingJson) {
        data.settingJson = {
          footerNav: false,
          isMusic: false,
          musicTarget: {}
        };
      } else {
        data.settingJson = JSON.parse(data.settingJson);
        if (!data.settingJson.musicTarget) {
          data.settingJson.musicTarget = {};
        }
        if (!data.settingJson.isMusic) {
          data.settingJson.isMusic = false;
        }
        if (!data.settingJson.footerNav) {
          data.settingJson.footerNav = false;
        }
      }
      data.picTemp = data.picTemp || '';
      data.pageJson.forEach(o => {
        o.elJson.forEach(p => {
          p.isActive = false;
          p.animate = p.animate || [];
          p.isLock = p.isLock || false;
          p.width = Number(p.width) || 'auto';
          p.height = Number(p.height) || 'auto';
          p.form = p.form || {};
          p.specialName = p.specialName || '';
          p.fontSet = p.fontSet || {
            fontID: 0,
            fontFamily: '',
            fontFacePath: '',
            fontFaceID: ''
          };
          if (p.type == '204' || p.type == '205') {
            if (p.width == 'auto') {
              p.width = '185px';
            } else {
              p.width = p.width.toString() + 'px';
            }
          }
          if (p.type == '206' || p.type == '207') {
            if (p.width == 'auto') {
              p.width = '640px';
            } else {
              p.width = p.width.toString() + 'px';
            }
            // 轮播
            if (p.type == '206') {
              console.log('206', p.form);
              p.form = !Array.isArray(p.form) ? p.form : { imgList: [] };
            }
            // 封面
            if (p.type == '207') {
              if (this.coverInfo && !p.content) {
                data.pic = this.coverInfo.coverimg;
                p.content = this.coverInfo.coverimg;
              }
            }
          }
          if (p.type == '208') {
            p.form = {
              t_address: this.submitData.CouponInfo.t_address,
              t_telephone: this.submitData.CouponInfo.t_telephone,
              t_company: this.submitData.CouponInfo.t_company,
              logo: p.form.logo
            };
          }
          if (this.specialNameList.length > 0) {
            let localItem = this.specialNameList.find(c => {
              return p.specialName == c.specialName && p.type == c.specialType;
            });
            console.log(localItem, 'localItem');
            if (localItem) {
              p.specialName = localItem.specialName;
              p.content = localItem.specialContent||'';
            }
          }
          p.content=p.content||''
          p.content = p.content.toString();
          if (p.fontSet.fontID > 0) {
            this.loadOnlineFont(p.fontSet);
          }
          if (this.dataInfo.posterTemplateID > 0) {
            this.updateMenDianInfoForEle(p);
          }
        });
      });
      data.name = couponInfo.t_company || this.vSaasClientInfo.WktInfo.name;
      data.address = couponInfo.t_address || this.vSaasClientInfo.WktInfo.address;
      data.tel = couponInfo.t_telephone || this.vSaasClientInfo.WktInfo.tel;
      data.allBuy = this.dataInfo.type == 3;// 线上全款
      return data;
    },
    // 在线字体load
    loadOnlineFont (fontSet) {
      console.log('this.$store.getters.PCWebURL', this.$store.getters.CDNURL + fontSet.fontFacePath);
      var f = new FontFace(fontSet.fontFamily, 'url(' + this.$store.getters.CDNURL + fontSet.fontFacePath + ')', {});
      f.load().then(loadedFace => {
        document.fonts.add(loadedFace);
      });
    },

    // 获取门店信息.
    getMenDianInfo (callback) {
      GetMenDianInfoByMenDianId(this.$store.getters.token.SaaSClientKey).then(result => {
        this.menDianInfo = result.data;
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.info(error);
      });
    },
    // 改变el对应的优惠券信息
    updateMenDianInfoForEle (elInfo) {
      switch (elInfo.type) {
        // 机构简介
        case 200:
          elInfo.data = this.menDianInfo.introduce;
          elInfo.content = this.menDianInfo.introduce;
          break;
        // 活动标题
        case 201:
          elInfo.data = this.menDianInfo.name;
          elInfo.content = this.menDianInfo.name;
          break;
        // 优惠类型
        case 202:
          elInfo.data = this.menDianInfo.address;
          elInfo.content = this.menDianInfo.address;
          break;
        case 203:
          elInfo.data = this.menDianInfo.tel;
          elInfo.content = this.menDianInfo.tel;
          break;
        case 204:
          elInfo.data = this.menDianInfo.logo;
          elInfo.content = this.menDianInfo.logo;
          break;
        case 205:
          elInfo.data = this.menDianInfo.QRcode;
          elInfo.content = this.menDianInfo.QRcode;
          break;
        case 208:
          elInfo.form.logo = this.menDianInfo.logo;
          break;
        default:
          break;
      }
    }
  }
};
</script>
