<template>
  <div>
    <div
      class="watermark lean_type"
      style="top: -60px; left: -200px; right: 0px"
    >
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
    </div>
    <div
      class="watermark lean_type"
      style="top: 180px; left: 180px; right: 100px"
    >
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
    </div>
    <div
      class="watermark lean_type"
      style="top: 420px; left: 440px; right: 0px"
    >
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
    </div>
    <div
      class="watermark lean_type"
      style="top: 800px; left: 400px; right: -40px"
    >
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
      <div>{{textInfo}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    textInfo: String
  }
};
</script>