<template>
  <div class="view_photo_box">
    <div class="view_photo_title">
      <div class="fl view_photo_return"
           @click.stop="closePreviewImg"></div>
      <span>{{vActiveIndex}}</span>/<span>{{imgList.length}}</span>
    </div>
    <div class="view_photo_swiper swiper-container_preview-img"
         :class="{'swiper-no-swiping':isEdit}">
      <div class="view_photo_swiper swiper-wrapper">
        <img-item v-for="(item,index) in preImgList"
                  :key="index"
                  :index="(index +1)"
                  :imgInfo="item"></img-item>
      </div>
    </div>
    <div class="view_photo_text"
         v-if="previewText">
      <pre class="text_box"
           v-html="$utils.translationSchoolText(previewText)">
      </pre>
    </div>
    <div class="view_photo_text edit_box"
         v-if="isShowEditBtn">
      <div v-if="!isEdit"
           @click.stop="eidtImg"
           class="edit_btn">编辑</div>
      <div v-else>
        <div class="preview_img_edit_cancel"
             @click.stop="cancelClickEvent()">取消</div>
        <div class="right_rotate"
             @click.stop="imgRotateClick('right')">右旋</div>
        <div class="left_rotate"
             @click.stop="imgRotateClick('left')">左旋</div>
        <div class="preview_img_edit_confirm"
             @click.stop="confirmClick">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import imgItem from './img-item';
import { base64ToBlob } from '../../../../utils/upLoadTextImg';
import { UploadFiles } from '../../../../API/workbench';
export default {
  components: {
    imgItem
  },
  props: {
    imgList: {
      type: Array,
      default: function () {
        return [{
          imgUrl: ''
        }];
      }
    },
    showIndex: {
      type: Number,
      default: 0
    },
    previewText: {
      type: String,
      default: ''
    },
    isShowEditBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vActiveIndex () {
      console.log('vActiveIndex', this.mySwiper);
      if (this.mySwiper && this.mySwiper.activeIndex) {
        return this.mySwiper.activeIndex + 1;
      } else {
        return 1;
      }
    }
  },
  data () {
    return {
      mySwiper: null,
      currentRotate: 0,
      activeIndex: this.showIndex,
      imgWidth: 0,
      imgHeight: 0,
      step: 0,
      isEdit: false,
      preImgList: []
    };
  },
  created () {
    this.preImgList = this.$utils.parseJson(this.imgList);
    console.log('this.imgList111', this.preImgList);
  },
  mounted () {
    this.swiperInit();
  },
  methods: {
    swiperInit () {
      this.mySwiper = new Swiper('.swiper-container_preview-img', {
        slidesPerView: 1,
        initialSlide: this.showIndex,
        preloadImages: true,
        updateOnImagesReady: true
      });
    },
    // 编辑图片 按钮
    eidtImg () {
      let imgItem = new Image();
      imgItem.onload = () => {
        console.log(imgItem.width, imgItem.height);
        this.imgWidth = imgItem.width;      // 新图片的宽高
        this.imgHeight = imgItem.height;
      };
      imgItem.src = this.$store.getters.CDNURL + this.imgList[this.mySwiper.activeIndex].imgUrl;
      this.isEdit = true;
      // document.getElementsByClassName('swiper-container_preview-img')[0].style.pointerEvents = 'none';
    },
    // 图片旋转click
    imgRotateClick (leftOrRight) {
      let imgEl = document.getElementById('img_box_' + this.vActiveIndex);
      if (leftOrRight == 'left') { // 左旋转
        this.currentRotate = (this.currentRotate - 90) % 360;
        if (this.step == -3) {
          this.step = 0;
        } else {
          this.step--;
        }
      } else if (leftOrRight == 'right') { // 右旋转
        this.currentRotate = (this.currentRotate + 90) % 360;
        if (this.step == 3) {
          this.step = 0;
        } else {
          this.step++;
        }
      }
      let scale = 1;
      let screenWidth = 410;
      console.log('this.imgHeight:', this.imgHeight, ',this.imgWidth:' + this.imgWidth, ',screenWidth:' + screenWidth, ',this.step:' + this.step);
      if (Math.abs(this.step) == 1 || Math.abs(this.step) == 3) {
        if (this.imgWidth < this.imgHeight) { // 宽《高
          if (this.imgHeight <= screenWidth) { // 高 < 屏幕高  放大
            scale = Number((this.imgHeight / screenWidth).toFixed(2));
          } else {
            scale = Number((this.imgWidth / this.imgHeight).toFixed(2));
          }
        } else if (this.imgWidth > this.imgHeight) { // 宽》高
          if (this.imgHeight <= screenWidth) { // 高<屏幕框 图片原始高度放大
            scale = Number((screenWidth / this.imgHeight).toFixed(2));
          } else { // 否则 图片原始高度缩小
            scale = Number((this.imgWidth / this.imgHeight).toFixed(2));
          }
        }
        //
        // if (scale > 1) {
        //   document.getElementById('view_photo_img_flex_' + this.vActiveIndex).style.height = this.imgWidth * scale + 'px';
        // } else {
        //   document.getElementById('view_photo_img_flex_' + this.vActiveIndex).style.height = document.getElementById('view_photo_img_flex_' + this.vActiveIndex).parentNode.clientHeight + 'px';
        // }
      }
      imgEl.style.transform = 'rotate(' + this.currentRotate + 'deg) scale(' + scale + ')';// 旋转角度
    },
    // 确认 编辑
    confirmClick () {
      if (this.step != 0) {
        let imgEl = document.getElementById('img_box_' + this.vActiveIndex);
        const canvas = document.createElement('canvas');
        const cxt = canvas.getContext('2d');
        switch (this.step) {
          case 0:
            canvas.width = this.imgWidth;
            canvas.height = this.imgHeight;
            cxt.rotate(0 * Math.PI / 180);
            cxt.drawImage(imgEl, 0, 0);
            break;
          case 1:
          case -3:
            canvas.width = this.imgHeight;
            canvas.height = this.imgWidth;
            cxt.rotate(90 * Math.PI / 180);
            cxt.drawImage(imgEl, 0, -this.imgHeight);
            break;
          case 2:
          case -2:
            canvas.width = this.imgWidth;
            canvas.height = this.imgHeight;
            cxt.rotate(180 * Math.PI / 180);
            cxt.drawImage(imgEl, -this.imgWidth, -this.imgHeight);
            break;
          case 3:
          case -1:
            canvas.width = this.imgHeight;
            canvas.height = this.imgWidth;
            cxt.rotate(270 * Math.PI / 180);
            cxt.drawImage(imgEl, -this.imgWidth, 0);
            break;
          default: break;
        }
        const base64Data = canvas.toDataURL('image/png');
        this.preImgList[this.vActiveIndex - 1].imgUrl = base64Data;
        this.$nextTick(() => {
          this.cancelClickEvent();
        });
        this.uploadFiles(base64Data);
      } else {
        this.cancelClickEvent();
      }
    },
    uploadFiles (base64Data) {
      let blobData = base64ToBlob(base64Data, 'image/png');
      var fd = new FormData();
      fd.append('file', blobData, 'image.png');
      UploadFiles(1, '', fd).then(result => {
        this.$emit('changeImgRotateEvent', this.imgList[this.vActiveIndex - 1].imgUrl, result.data.url);
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 取消 编辑
    cancelClickEvent () {
      let imgEl = document.getElementById('img_box_' + this.vActiveIndex);
      imgEl.style.transform = 'rotate(0deg) scale(1)';// 旋转角度
      this.step = 0; this.currentRotate = 0;
      this.isEdit = false;
      // document.getElementsByClassName('swiper-container_preview-img')[0].style.pointerEvents = 'auto';
    },
    closePreviewImg () {
      this.$emit('onClosePreviewImg');
    }
  }
};
</script>

<style>
</style>