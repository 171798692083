<template>
  <li class="courseware_box_li"
      @click="doEditCourseware">
    <div class="courseware_box_li_title">{{dataInfo.CoursewarePackageKeyValue}}</div>
    <div class="courseware_box_li_info">
      <span class="courseware_box_down_info">
        <span class="courseware_box_down_date"></span>{{dataInfo.ReleaseTime?dataInfo.ReleaseTime.slice(0,10):dataInfo.ReleaseTime}}
        <span class="courseware_box_li_btn"
              v-if="dataInfo.TypeKey==1">全部校区</span>
        <span class="courseware_box_li_btn"
              v-else>{{dataInfo.ClientSum||0}}校区</span>
      </span>
    </div>
    <span class="campus_delete_box"
          @click.stop="deleteAmpus()">×</span>
  </li>
</template>
<script>
export default {
  components: {

  },
  props: {
    dataInfo: Object,
    parentItem: Object
  },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    doEditCourseware () {
      this.$emit('doEditCourseware', this.dataInfo, this.parentItem);
    },
    deleteAmpus () {
      this.$emit('deleteAmpus', this.dataInfo);
    }
  }
};
</script>
<style scoped>
.courseware_box_li {
  display: block;
  height: 51px;
  width: 228px;
  background: #fff;
  border-radius: 4px;
  margin: 5px 7px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ececec;
  margin-left: 1px;
}
.courseware_box_li_info {
  position: relative;
  margin-top: 3px;
  margin-left: 29px;
}
.courseware_box_li_title {
  display: block;
  margin-top: 4px;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 95%; */
  display: block;
}
.courseware_box_down_info {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #999999;
  padding-left: 5px;
}
.courseware_box_down_date {
  top: 3px;
  left: -15px;
  position: absolute;
  width: 15px;
  height: 15px;
  color: #999;
  background: url(../../../../../public/cw-img/attend_class.png) no-repeat 50%;
  background-size: 14px;
}
.courseware_box_li_btn {
  color: rgba(74, 95, 138, 1);
  margin-left: 10px;
}
.campus_delete_box {
  right: 0px;
  position: absolute;
  color: #c4c4c4;
  cursor: pointer;
  width: 30px;
  top: 6px;
  text-align: right;
  padding-right: 10px;
}
</style>