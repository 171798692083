<template>
  <!-- active 选中当前 -->
  <div class="single_result single_class_info"
       @click.stop="selectedItem"
       :class="{active:item.isSelected}">
    <div class="single_result_info_detail  ">
      <div class="single_class_name">
        <span v-html="item.MainDemoName"
              :style="{'max-width':showRightContent?'80%':'90%'}"></span>
        <span v-if="item.CurrentPeoCount">
          <span v-if="Number(item.WantPeopleCount)">
            （{{Number(item.CurrentPeoCount)+'/'+Number(item.WantPeopleCount)}}人<span v-if="Number(item.WantPeopleCount)>Number(item.CurrentPeoCount)">，差<span class="differ_red">{{Number(item.WantPeopleCount) - Number(item.CurrentPeoCount)}}</span>人</span>）
          </span>
          <span v-else>（{{Number(item.CurrentPeoCount)}}人）</span> </span>
        <span v-if="item.rightContent&&showRightContent"
              class="single_rightcontent"
              style="float: right;">{{item.rightContent}}</span>
      </div>
      <div class="custom_font_gray"
           v-if="item.customGrayText">
        <span v-html="item.customGrayText"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addressItem',
  data () {
    return {
    };
  },
  props: {
    item: Object,
    showRightContent: Boolean
  }, // 单选数据项 需绑定到界面
  created () {
  },
  methods: {
    selectedItem () {
      this.$emit('onSelectedItem', this.item);
    }
  }
};
</script>
<style >
.single_class_info {
  position: relative;
}
.custom_font_gray {
  color: #666;
  font-size: 12px;
  padding-top: 5px;
}
.list_name_type {
  display: inline-block;
  vertical-align: top;
  width: 35px;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  color: #ff8c39;
  background-color: #fffbe6;
  margin-left: 10px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid #ffe58f;
  font-weight: normal;
}
.single_rightcontent {
  color: #999;
  font-size: 14px;
}
.differ_red {
  color: red;
}
</style>