<template>
  <!-- 失效时间 -->
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:122px">
    <div v-if="vIsEdit"
         style="cursor: pointer;">

      <input type="text"
             v-if="!expireTime&&!isFocus&&rowType=='add'"
             :placeholder="rowType=='add'?'失效日期':''"
             @focus="focusWatermarkEvent($event)">

      <div class="mendian_backstage_search"
           v-if="rowData.TextExpireTime"
           @click.stop="rowTextTimeClick">
        <input type="text"
               ref="expireTime"
               class="text_right"
               :class="{text_error:vExpireTimeError}"
               @keydown="keydownEvent($event)"
               @blur="blurEvent"
               @focus="focusEvent($event)"
               v-model="rowData.TextExpireTime">
        <span class="remove_btn"
              style="right:10px"
              v-if="rowData.TextExpireTime != ''"
              @click.stop="rowTextTimeClick"></span>
      </div>
      <div v-else>
        <input type="date"
               min="2010-01-01"
               max="2100-01-01"
               ref="expireTime"
               v-model="expireTime"
               :style="(expireTime||isFocus||vExpireTimeError)?'opacity: 1':'opacity: 0;position: absolute;'"
               :class="{text_error:vExpireTimeError}"
               @keydown="keydownEvent($event)"
               @blur="blurEvent"
               @focus="focusEvent($event)">

        <div class="date_calendar"
             :class="{icon_left:vExpireTimeError}"
             :style="(expireTime||isFocus||vExpireTimeError)?'opacity: 1':'opacity: 0'">
          <input-date v-if="isDateShow"
                      v-model="expireTime"
                      :formTitle="''"
                      :formPlaceholder="' '"
                      :minDate="'2010-01-01'"
                      :maxDate="'2100-01-01'"
                      :popperClass="'table_date_popup'"
                      @changeDate="changeDay"
                      @focusEvent="inputDatefocusEvent"></input-date>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

export default {
  data () {
    return {
      expireTime: '',
      isFocus: false,
      isDateShow: true // 是否显示日期插件
    };
  },
  props: {
    rowData: Object,
    rowType: {
      type: String,
      default: ''
    } // 输出类型
  },
  computed: {
    vExpireTimeError () {
      let flag = false;
      let findItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 6; });
      if (findItem) {
        flag = true;
      }
      return flag;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    'rowData.ExpireTime': {
      handler (n, o) {
        this.expireTime = n;
      }
    },
    rowData: {
      handler (n, o) {
        if (n.focusField == 'ExpireTime' && n.optioningRow) {
          this.rowData.focusField = 'ExpireTime';
          this.isFocus = true;
          this.$refs.expireTime.focus();
          // 光标定位到文本框字符串末尾
          var tObj = this.$refs.expireTime;
          if (tObj.value) {
            // var sPos = tObj.value.length;
            // if (tObj && sPos) {
            //   this.$utils.setCaretPosition(tObj, sPos);
            // }
          }
          if (!this.expireTime) {
            this.$refs.expireTime.style.opacity = 1;
          }
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  created () {
    this.expireTime = this.rowData.ExpireTime;
  },
  methods: {
    // 水印聚焦
    focusWatermarkEvent () {
      this.isFocus = true;
      if (this.$refs.expireTime) {
        this.$refs.expireTime.focus();
        this.$refs.expireTime.style.opacity = 1;
      }
    },
    // 鼠标聚焦
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'ExpireTime';
      this.$emit('onFocusEvent', 'ExpireTime');
      if (!this.expireTime) {
        this.$refs.expireTime.style.opacity = 1;
      }
    },
    // 键盘事件
    keydownEvent (e) {
      // console.log(e, '键盘事件');
      this.isDateShow = false;
      switch (e.keyCode) {
        case 9: // Tab
          this.rowData.focusField = 'ActualPayAmount';
          break;
        case 13: // 回车
          this.rowData.focusField = 'ActualPayAmount';
          break;
        case 32: // 禁止空格，防止input-date控件自带的日历显示
          e.preventDefault();
          break;
        case 37: // 左键
          this.rowData.focusField = 'PayTime';
          break;
        case 38: // 向上键
          this.$emit('doUpwardMove', this.rowData, 'ExpireTime');
          break;
        case 39: // 右键
          this.rowData.focusField = 'ActualPayAmount';
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'ExpireTime');
          break;
        default:
          break;
      }
      if (this.expireTime && (Number(this.expireTime.charAt(0)) !== 2 || this.expireTime > '2100-12-29')) {
        this.expireTime = '';
        this.rowData.ExpireTime = this.expireTime;
      }
    },
    // 失去焦点事件 要验证学生名 是否存在
    blurEvent () {
      this.rowData.focusField = '';
      this.isFocus = false;
      if (!this.expireTime) {
        this.$refs.expireTime.style.opacity = 0;
      } else {
        this.$refs.expireTime.style.opacity = 1;
      }
      console.log(this.expireTime, 'this.expireTime');
      if (this.rowData.ExpireTime != this.expireTime) {
        this.rowData.ExpireTime = this.expireTime;
      }
      this.verificationExpireTime();
      setTimeout(() => {
        this.isDateShow = true;
      }, 20);
      this.rowData.updateTime = Date.new();
      if (this.rowType == 'add') {
        this.$refs.expireTime.blur();
      }
      this.$emit('onBlurEvent', this.rowData, 'ExpireTime');
      if (this.rowData.TextExpireTime && !this.expireTime) {
        this.isShowTextExpireTime = true;
        this.$refs.expireTime.style.opacity = 1;
      } else {
        this.isShowTextExpireTime = false;
        this.$refs.expireTime.style.opacity = 1;
      }
      if (!this.rowData.ExpireTime) {
        this.rowData.TextExpireTime = '';
      }
    },
    // 手动清除异常日期
    rowTextTimeClick () {
      this.rowData.TextExpireTime = '';
      this.rowData.focusField = 'ExpireTime';
      this.isFocus = true;
      console.log(this.rowData, 'this.ExpireTime');
      this.$refs.expireTime.style.opacity = 1;
      this.$refs.expireTime.focus();
      this.verificationExpireTime();
      this.$emit('onBlurEvent', this.rowData, 'ExpireTime');
      this.$emit('onFocusEvent', 'ExpireTime');
    },
    changeDay (date) {
      this.expireTime = date;
      this.rowData.ExpireTime = this.expireTime;
      this.isDateShow = true;
      this.rowData.focusField = 'ActualPayAmount';
      this.verificationExpireTime();
    },
    inputDatefocusEvent () {
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'ExpireTime';
    },
    // 异常TargetField：6-》 1：购买日期格式错误，2：失效日期不能为过去时间，3：失效日期不能超过10年
    verificationExpireTime () {
      console.log(this.rowData, '失效日期-异常');
      let formatErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 6 && obj.TypeKey == 1; });
      let expireTimeExpireErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 6 && obj.TypeKey == 2; });
      let expireTimeErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 6 && obj.TypeKey == 3; });

      let curDate = Date.new(Date.new().toLocaleDateString());
      let expireTime = this.rowData.ExpireTime ? Date.new(this.rowData.ExpireTime) : '';
      // 1：购买日期格式错误
      if (formatErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(formatErrorIndex, 1);
      }
      // 2：失效日期不能为过去时间
      if (this.rowData.ExpireTime && expireTime.getTime() < curDate.getTime()) {
        if (expireTimeExpireErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 6, TypeKey: 2, TypeKeyValue: '失效日期不能为过去时间' });
          this.rowData.isShowCorrect = false;
        }
      } else {
        if (expireTimeExpireErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(expireTimeExpireErrorIndex, 1);
        }
      }
      // 3：失效日期不能超过10年
      if (this.rowData.ExpireTime && expireTime.getFullYear() - curDate.getFullYear() >= 10) {
        if (expireTimeErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 6, TypeKey: 3, TypeKeyValue: '失效日期不能超过10年' });
          this.rowData.isShowCorrect = false;
        }
      } else {
        if (expireTimeErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(expireTimeErrorIndex, 1);
        }
      }

      // 在读课程未选择
      if (!this.rowData.ApplyClassKeyValue) {
        let unSelectedErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
        if (this.rowData.ExpireTime && unSelectedErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 3, TypeKeyValue: '在读课程未选择' });
          this.rowData.isShowCorrect = false;
        } else {
          if (!this.rowData.PayTime && !this.rowData.ExpireTime && !this.rowData.ActualPayAmount && !this.rowData.NumCount && !this.rowData.CurrentCount && unSelectedErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(unSelectedErrorIndex, 1);
          }
        }
      }
    }
  }
};
</script>

<style>
.form_info_select .el-input--prefix .el-input__inner {
  border: none;
  background-color: transparent;
}
::-webkit-datetime-edit {
  /* background: red */
}
/* – 控制编辑区域的 */

::-webkit-datetime-edit-fields-wrapper {
  /* display: none; */
  /* opacity: 0; */
}
/* – 控制年月日这个区域的 */

::-webkit-datetime-edit-text {
  opacity: 0.2;
  /* color: #c30909; */
}
/* – 这是控制年月日之间的斜线或短横线的 */

::-webkit-datetime-edit-month-field {
  /* opacity: 0.3; */
}
/* – 控制月份 */

::-webkit-datetime-edit-day-field {
  /* opacity: 0.2; */
  /* background: url(../../../../../../public/image/course_header_icon01.png) no-repeat 10%; */
}
/* – 控制具体日子 */

::-webkit-datetime-edit-year-field {
  /* opacity: 0.1; */
}
/* – 控制年文字, 如2017四个字母占据的那片地方 */

::-webkit-inner-spin-button {
  display: none;
}
/* – 这是控制上下小箭头的 */

::-webkit-calendar-picker-indicator {
  display: none;
}
/* – 这是控制下拉小箭头的 */

::-webkit-clear-button {
  display: none;
}
/* –这是控制清除按钮的 */

/* ::-webkit-calendar-picker-indicator {
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px #fff, 0 1px #eee;
  background-color: #eee;
  background-image: -webkit-linear-gradient(top, #f0f0f0, #e6e6e6);
  color: #666;
  opacity: 0;
} */
</style>