<template>
  <div class="edit-class-room edit_class_from">
    <div class="content_marginRL content_marginTop_30">
      <course-info :circleCourseInfo="circleCourseInfo"
                   v-if="vIsShowCircleCourseItem"></course-info>
      <choose-class-time-range :circleCourseInfo="circleCourseInfo" @confirmClassRangeTime="confirmClassRangeTime"></choose-class-time-range>
      <div class="new_course_title border_top_line"
             style="margin-bottom:12px">
          修改内容
        </div>
      <div class="mendian_setting_content form_info_edit">
        <input-dialog :formTitle="'教室'"
                      :formPlaceholder="'教室'"
                      :required="true"
                      class="form_info_border_type no_border_bottom"
                      v-model="selectedClassRoomData.MainDemoName"
                      @showEldilog="showClassRoomListDialog"></input-dialog>

      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>

    <custom-dialog title="选择教室"
                   :visible.sync="isShowClassRoomListDialog"
                   :before-close="closeClassRoomDialog">

      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'classroom-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAfterSelectClassRoom"></btn-add-select>

      <single-selection-list :selectedKey="selectedClassRoomData.OLAPKey"
                             :dataSourceList="classRoomList"
                             :required="false"
                             @onSelectedItem="doAfterSelectClassRoom"></single-selection-list>
    </custom-dialog>
  </div>
</template>
<script>
import courseInfo from '../course-info';
import chooseClassTimeRange from './common/choose-class-time-range';
import { getValidClassroomList, EditClassRoomByRuleId, GetStructuredRuleRecentlyCourseInfo } from '../../../API/workbench';
export default {
  data () {
    return {
      classRangeTimeType: '全部',
      classRoomList: [],
      sClassDate: '', // 开始上课时间
      eClassDate: '', // 结束上课时间
      isShowClassRoomListDialog: false,
      selectedClassRoomData: { OLAPKey: 0, MainDemoName: '' },
      isShowClassTimeRangeDialog: false // 修改课表范围
    };
  },
  components: {
    courseInfo,
    chooseClassTimeRange
  },
  props: {
    circleCourseInfo: {
      type: Object
    }
  },
  computed: {
    vIsShowCircleCourseItem () {
      let bool = false;
      if (!this.circleCourseInfo.SubRule) { // SubRule为NULL 就显示循环课块
        bool = true;
      }
      return bool;
    }
  },
  created () {
    this.sClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.StartTime : this.circleCourseInfo.CurrentRuleTime;
    this.eClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.EndTime : this.circleCourseInfo.LastClassTime;

    this.loadDataSourceClassRoom();
    this.getStructuredRuleRecentlyCourseInfo();
  },
  methods: {
    // 排课规律-》获取规律最近一节课课信息
    getStructuredRuleRecentlyCourseInfo () {
      if (this.circleCourseInfo.SubRule) {
        this.selectedClassRoomData.OLAPKey = this.circleCourseInfo.SubRule.classRoomKeyName;
        this.selectedClassRoomData.MainDemoName = this.circleCourseInfo.SubRule.classRoomName;
        return;
      }
      GetStructuredRuleRecentlyCourseInfo(this.circleCourseInfo.StructuredRuleKey).then(result => {
        this.selectedClassRoomData.OLAPKey = result.data.classRoomKeyName;
        this.selectedClassRoomData.MainDemoName = result.data.classRoomName;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取教室列表
    loadDataSourceClassRoom () {
      getValidClassroomList().then(result => {
        this.classRoomList = result.data;
      });
    },
    // 打开教室列表
    showClassRoomListDialog () {
      this.isShowClassRoomListDialog = true;
    },
    // 选择教室
    doAfterSelectClassRoom (item) {
      this.selectedClassRoomData = item;
      this.closeClassRoomDialog();
    },
    // 关闭教室列表
    closeClassRoomDialog () {
      this.loadDataSourceClassRoom();
      this.isShowClassRoomListDialog = false;
    },
    confirmClick (callback) {
      if (!this.selectedClassRoomData.OLAPKey) {
        layer.alert('请先选择教室'); if (typeof callback == 'function') { callback(); }
        return false;
      }
      EditClassRoomByRuleId(this.circleCourseInfo.StructuredRuleKey,
        this.sClassDate, this.eClassDate,
        this.selectedClassRoomData.OLAPKey, this.selectedClassRoomData.MainDemoName).then(resule => {
          this.$emit('AfterEditCircleCourseClassRoom', this.circleCourseInfo.StructuredRuleKey);
          layer.alert('修改循环课教室成功');
          this.closeDilog(this.circleCourseInfo.StructuredRuleKey);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (typeof callback == 'function') { callback(); }
        });
    },
    closeDilog (ruleID) {
      this.$emit('closeDilog', ruleID);
    },
    // 确认 修改课表范围
    confirmClassRangeTime (startDate, endDate, classNum) {
      this.sClassDate = startDate;
      this.eClassDate = endDate;
    }
  }
};
</script>

<style>
</style>