<template>
  <div class="verify_identity">
    <div class="verify_identity_text">为了保护你的账户安全，请再次输入登录密码以完成支付</div>
    <div class="verify_identity_div">
      <input class="verify_identity_input"
             name="password"
             type="text"
             placeholder="请输入密码"
             onfocus="this.type = 'password'"
             v-model="passwaord" />
      <img class="passwaordIcon"
           :src="require('../../../../../../public/image/password.png')" />
    </div>
    <div class="recharge_balance_bottom">
      <div type="primary"
           class="button_right_box  btn_down_btn bottom_line"
           @click.stop="close">取消</div>

      <div type="primary"
           class="button_right_box btn_hover_bg_blue btn_down_btn"
           @click.stop="passwaordSubmit">确定</div>
    </div>
  </div>
</template>
<script>
import {
  CWScheckSaaSClientPwd,
  WEBVcheckSaaSClientPwd
} from '../../../../../API/workbench';

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      passwaord: ''
    };
  },

  created () {
  },
  mounted () {

  },
  computed: {
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  watch: {
  },
  methods: {
    passwaordSubmit () {
      if (this.passwaord == '') {
        layer.alert('请输入密码');
        return;
      }
      let fn = null;
      if (this.vMendianInfo.EditionKey == 6) {
        fn = CWScheckSaaSClientPwd;
      } else if (this.vMendianInfo.EditionKey == 5) {
        layer.alert('同事正在加班搬砖中!');
        return;
      } else {
        fn = WEBVcheckSaaSClientPwd;
      }
      fn(this.passwaord).then(result => {
        console.log(result, '密码验证提交');
        if (result.code == 0) {
          this.$emit('afterSuccess');
          this.$emit('close');
        }
      }).catch(error => {
        console.log(error, 'currentAmountRecharge');
        layer.alert(error.msg);
      });
    },
    close () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.verify_identity {
  height: 200px;
}
.verify_identity_text {
  width: 345px;
  height: 19px;

  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  margin: 26px 32px 0 33px;
  color: #666666;
}
.verify_identity_div {
  margin: 17px 20px 40px 20px;
  width: 370px;
  height: 39px;
  position: relative;
}
.verify_identity_input {
  box-sizing: border-box;
  padding-left: 34px;
  width: 370px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.recharge_balance_bottom {
  width: 100%;
  border-top: 1px solid #ececec;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btn_down_btn {
  width: 175px;
  height: 40px;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  overflow: hidden;
  align-items: center;
  margin: 20px;
}
.bottom_line {
  border: 1px solid #dadce0;
  cursor: pointer;
}
.passwaordIcon {
  position: absolute;
  top: 8px;
  left: 7px;
}
</style>