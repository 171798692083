<template>
  <div>
    <div class="signuppayment_print_box"
         :class="{no_top_type:isMoneyReceipt}">
      <div class="signuppayment_print_menu">
        <div class="print_type">
          <!-- <el-radio v-model="radio"
                    label="1">A4</el-radio>
          <el-radio v-model="radio"
                    label="2">小票</el-radio> -->
        </div>
        <div class="print_btn btn_hover_bg_blue"
             @click="printing">打印</div>
      </div>
      <div class="signuppayment_print_from"
           ref="iframeDom">
        <iframe id="frame"
                ref="iframe"
                :style="{height:iframeHeight + 'px',width:vIframeWidth + 'px',paddingLeft:radio == '2'?'0px':''}"
                :src="vIframeSrc"
                frameborder="0"
                scrolling="no"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { GetTheTicketMsg, getUsingClass, GetSalesList } from '../../API/workbench.js';
export default {
  data () {
    return {
      isShowPrintFrom: false,
      state: '',
      iframeHeight: 380,
      iframeWin: {},
      formJson: {},
      gradeClassList: [],
      CounselorKey: '',
      CounselorKeyValue: '',
      classKey: '',
      className: ''

    };
  },
  props: {
    tableID: {
      type: Number
    },
    studentInfo: Object,
    isMoneyReceipt: {
      type: Boolean,
      default: false
    },
    radio: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  created () {
    // if (localStorage.getItem('print-type')) {
    //   console.log(localStorage.getItem('print-type'), 'l1ocalStorage.getItem');
    //   this.radio = localStorage.getItem('print-type');
    // } else {
    //   this.radio = '1';
    // }
    this.getValidClassList();
  },
  activated () {
    console.log('isMoneyReceipt', this.tableID, this.studentInfo, this.isMoneyReceipt);
    window.addEventListener('message', this.handleMessage); // mounted钩子函数中
    this.iframeWin = this.$refs.iframe.contentWindow; // 获取iframe
    this.$nextTick(() => {
      this.setFromJson();
    });
  },
  mounted () {
    this.isShowPrintFrom = true;
    this.$dialog.register(this, ['singleSelectionList']);
    if (this.tableID > 0) {
      this.getfromInfo();
    }
  },
  methods: {
    hiddenView () {
      this.$emit('closeDialog');
      this.$router.push({ name: 'indexView' });
    },
    showPrintFrom () {
      this.isShowPrintFrom = true;
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        result.data.forEach(obj => {
          if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
            this.gradeClassList.push(obj);
          }
        });
      });
    },
    // 打开班级列表
    showSingleSelectionDialog () {
      // 判断权限,
      if (this.vCheckModulePower) {
        this.$dialog.open(this, {
          name: '加入班级',
          routerName: this.$route.name,
          routertitle: '',
          ModulePowerKey: 51,
          moduleName: 'addStudentToBJ',
          data: { dataInfo: { studentInfo: this.studentInfo, type: '进班' } },
          callBack: {
            onSelectedItem: (data) => {
              console.log('data', data);
              this.classKey = data.OLAPKey;
              this.className = data.MainDemoName;
            }
          }
        });
        // this.$emit('closeDialog');
      } else {
        this.$utils.CheckModulePowerToTips(51);
      }
    },
    // 获取来源方式
    openCounselorList () {
      GetSalesList().then(
        (result) => {
          this.CounselorList = result.data;
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择销售顾问',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.CounselorKey,
              dataSourceList: this.CounselorList,
              required: false,
              ModulePowerKey: 0
            },
            callBack: {
              onSelectedItem: (data) => {
                this.CounselorKey = data.OLAPKey;
                this.CounselorKeyValue = data.MainDemoName;
              }
            }
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    getfromInfo () {
      GetTheTicketMsg(this.tableID).then(res => {
        this.formJson = res.data;
        console.log(this.formJson, 'GetTheTicketMsg');
        this.formJson.saasClientInfo = this.vSaasClientInfo;
        if (this.formJson.saasClientInfo.WktInfo && this.formJson.saasClientInfo.WktInfo.QRcode && this.formJson.saasClientInfo.WktInfo.QRcode.indexOf('http') == -1) {
          this.formJson.saasClientInfo.WktInfo.QRcode = this.$store.getters.CDNURL + this.formJson.saasClientInfo.WktInfo.QRcode;
        }
        if (this.formJson.CouponKey == 0) {
          this.formJson.CouponKeyValue = '未使用';
        }
        if (this.formJson.BillerKeyValue.length == 0) {
          this.formJson.BillerKeyValue = this.$store.getters.token.UserName;
        }
        if (this.formJson.MobileSupport.length > 0) {
          this.formJson.MobileSupport = this.$utils.phoneModulePower(this.formJson.MobileSupport);
        }
        this.courseListSort();
        window.addEventListener('message', this.handleMessage); // mounted钩子函数中
        this.iframeWin = this.$refs.iframe.contentWindow; // 获取iframe
        this.$nextTick(() => {
          this.setFromJson();
        });
      }, err => {
        console.log(err);
      });
    },
    courseListSort () {
      let arr = [];
      let packageList = [];
      this.formJson.KDNR.forEach(e => {
        let arr2 = [];
        if (e.PackageKey > 0) {
          packageList.push(e);
        } else {
          arr2.push(e.ApplyCorName);
          arr2.push(Number(e.UnitPrice).toFixed(2));
          arr2.push(Number(e.NumCount) + e.UnitKeyValue);
          arr2.push(Number(e.ValueAmount).toFixed(2));
          if (e.DiscountCount >= 10) {
            arr2.push('原价');
          } else if (e.DiscountCount == 0) {
            arr2.push('赠送');
          } else {
            arr2.push(Number(e.DiscountCount) + '折');
          }
          arr2.push(Number(e.AfterDisAmount).toFixed(2));
          arr.push(arr2);
        }
      });
      this.formJson.ZFNR.forEach(e => {
        let arr2 = [];
        if (e.PackageKey > 0) {
          packageList.push(e);
        } else {
          arr2.push(e.ProjectKeyValue);
          arr2.push(Number(e.UnitPrice).toFixed(2));
          arr2.push(Number(e.NumCount) + e.UnitName);
          arr2.push(Number(e.ValueAmount).toFixed(2));
          if (e.DiscountCount >= 10) {
            arr2.push('原价');
          } else if (e.DiscountCount == 0) {
            arr2.push('赠送');
          } else {
            arr2.push(Number(e.DiscountCount) + '折');
          }
          arr2.push(Number(e.AfterDisAmount).toFixed(2));
          arr.push(arr2);
        }
      });
      if (packageList.length > 0) {
        let packageData = {
          SalePrice: 0,
          amount: 0,
          afterDisAmount: 0
        };
        packageList.forEach(e => {
          packageData.SalePrice += Number(e.ValueAmount || e.ValueAmount);
          packageData.amount += Number(e.ValueAmount || e.ValueAmount);
          packageData.afterDisAmount += Number(e.AfterDisAmount || e.AfterDisAmount);
        });
        packageData.afterDisAmount = packageData.afterDisAmount - this.formJson.ComboDiscountAmount;
        let arr2 = [];
        arr2.push(this.formJson.PackageKeyValue);
        arr2.push(packageData.SalePrice.toFixed(2));
        arr2.push('1套');// 数量
        arr2.push(packageData.amount.toFixed(2));
        arr2.push('原价');// 折扣
        arr2.push(packageData.afterDisAmount.toFixed(2));
        arr.unshift(arr2);
      }
      this.formJson.courseList = arr;
    },
    setFromJson () {
      setTimeout((res) => {
        if (!this.iframeWin) {
          return false;
        }
        if (this.iframeWin.postMessage) {
          this.iframeWin.postMessage(
            {
              cmd: 'getFormJson',
              info: this.formJson
            },
            '*'
          );
        }
      }, 500);
    },
    handleMessage (event) {
      // 根据上面制定的结构来解析iframe内部发回来的数据
      const data = event.data;
      // console.log("vue文件接收的数据", data);
      if (data.cmd == 'success') {
        // 自己定义一个判断是否正常
        this.state = 'ok';
        if (this.iframeWin && this.iframeWin.document) {
          this.iframeHeight = this.iframeWin.document.getElementById('printFrom').clientHeight + 20;
        }
      }
    },
    printing () {
      // 点击打印
      let iframe = document.getElementById('frame'); // 获取iframe标签相当于获取了打印的区域了
      this.iframeWin.postMessage(
        {
          cmd: 'getFormJson',
          info: this.formJson
        },
        '*'
      );
      setTimeout((res) => {
        if (this.state == 'ok') {
          iframe.contentWindow.print(); // 打印iframe的html页面
          // if (this.radio == '1' && this.$zxClient) {
          //   //iframe.contentWindow.document.body.style = 'transform: scale(0.95);transform-origin: 0 0;';
          //   iframe.contentWindow.print(); // 打印iframe的html页面
          //   setTimeout(() => { iframe.contentWindow.document.body.style = ''; }, 500);
          // } else {
          // }
        }
      }, 500);
    }
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vIframeSrc () {
      let src;
      if (this.radio == '1') {
        src = 'printview.html?v=2';
      } else {
        src = 'printview-moblie.html?v=2';
      }
      if (this.$refs.iframeDom) {
        this.$refs.iframeDom.scrollTop = 0;
      }
      this.setFromJson();
      if (process.env.NODE_ENV === 'development') {
        return '/public/' + src;
      } else {
        return window.location.origin + '/WebV/' + src;
      }
    },
    vIframeWidth () {
      if (this.radio == '2') {
        localStorage.setItem('print-type', '2');
        return '260';
      } else {
        localStorage.setItem('print-type', '1');
        return '700';
      }
    },
    vCheckModulePower () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 51)) {
        return true;
      } else {
        return false;
      }
    }
  }

};
</script>

<style>
</style>