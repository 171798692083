<template>
  <div>
    <div class="img_ul">
      <img-item v-for="(item,index) in vImgList"
                :key="index"
                :keyIndex="index"
                :imgInfo="item"
                :essayID="dataInfo.essayID"
                :isVideo="isVideo"
                @onClickImg="toPreviewImg"
                @onDeleteImg="toDeleteImg"></img-item>
      <slot></slot>
    </div>
    <custom-dialog :title="'预览图片'"
                   :visible.sync="isShowRecordVoice"
                   :before-close="toClosePreviewImg">
      <div class="home_work_img">
        <preview-img :imgList="vPreviewImgData"
                     :showIndex="imgShowIndex"
                     :isShowEditBtn="isShowEditBtn"
                     :dataInfo="dataInfo"
                     @changeImgRotateEvent="afterChangeImgRotate"
                     @onClosePreviewImg="toClosePreviewImg"></preview-img>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import imgItem from './img-item';
import previewImg from '../../preview-img/index';
export default {
  components: {
    imgItem,
    previewImg
  },
  props: {
    dataInfo: {
      type: Object,
      default: function () {
        return {
          essayID: 0,
          AttachList: [{ // 附件列表
            AttachTypeKey: 1, // 1-图片; 2-视频;3-音频
            AttachUrl: ''// "/Upload/Resource/10145/Image/202007291031397744.jpg",
          }]
        };
      }
    },
    isShowEditBtn: Boolean,
    isVideo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imgShowIndex: 0,
      isShowRecordVoice: false
    };
  },
  computed: {
    vImgList () {
      console.log(this.dataInfo.AttachList, 'vImgListvImgList');
      return this.dataInfo.AttachList.filter(obj => { return obj.AttachTypeKey == 1; });
    },
    vPreviewImgData () {
      let urlList = [];
      this.vImgList.forEach(obj => {
        let imgData = {
          imgUrl: obj.AttachUrl
        };
        urlList.push(imgData);
      });
      return urlList;
    }
  },
  methods: {
    // 不支持预览图片
    toPreviewImg (imgUrl) {
      let imgIndex = this.vImgList.findIndex(obj => { return obj.AttachUrl == imgUrl; });
      if (imgIndex >= 0) {
        this.imgShowIndex = imgIndex;
      }
      this.isShowRecordVoice = true;
    },
    // 关闭预览图片
    toClosePreviewImg () {
      this.isShowRecordVoice = false;
      this.imgShowIndex = 0;
    },
    afterChangeImgRotate (imgUrl, newImgUrl) {
      let imgIndex = this.dataInfo.AttachList.findIndex(obj => { return obj.AttachUrl == imgUrl && obj.AttachTypeKey == 1; });
      this.dataInfo.AttachList[imgIndex].AttachUrl = newImgUrl;
    },
    // 删除图片
    toDeleteImg (imgUrl) {
      layer.confirm('请问是否删除该图片', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              let exsistImgIndex = this.dataInfo.AttachList.findIndex(obj => { return obj.AttachTypeKey == 1 && obj.AttachUrl == imgUrl; });
              this.dataInfo.AttachList.splice(exsistImgIndex, 1);
              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>

<style>
</style>