<template>
  <div class="form_item form-input form_info_line no_border clew_form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field">{{formTitle}}</div>
    <div class=""
         style="padding-top: 4px;">
      <div class="flex_row_itemb no_wrap"
           v-for="(item,index) in selectList"
           :key="index"
           style="margin-right: 8px;margin-bottom: 8px"
           :class="item.isActive?'opt':''"
           @click="changeVal(item)"
           :title="item.LabelKeyValue">{{item.LabelKeyValue}}</div>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {
      radio: 0 // 选项Key
    };
  },
  props: {
    formTitle: {// 标题，（必填）
      type: String,
      default: ''
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填
    readonly: Boolean, // 是否只读，（可选）
    readonlyValue: {// 标题，（必填）
      type: String,
      default: ''
    },
    selectList: {// 选择项列表
      type: Array,
      default: function () {
        return [
          { name: '是', id: 1 },
          { name: '否', id: 0 }
        ];
      }
    },
    selectedInfo: {
      type: Object,
      default: () => {
        return {
          name: '无',
          id: 0
        }
      }
    }
  },
  computed: {

  },
  created () {
    this.radio = Number(this.selectedInfo.id);
  },
  watch: {
    'selectedInfo.id': {
      handler (c, o) {
        this.radio = c
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeVal (val) {
      this.selectList.forEach(o => {
        if (val.LabelKey == o.LabelKey) {
          o.isActive = !o.isActive
        } else {
          o.isActive = false
        }
      })
      this.$emit('change', val);
    }
  }
};
</script>
<style>
/* .form_info_value > span {
  display: inline !important;
} */
.form_info_list .form_info_value .el-radio .el-radio__original {
  height: auto !important;
}
.form_info_list .form_info_value {
  overflow: visible !important;
}
.form_item .form_info_value.input_readonly {
  overflow: hidden !important;
}
.flex_row_itemb {
  height: 23px;
  border-radius: 4px;
  text-align: center;
  line-height: 23px;
  border: 1.25px solid #ececec;
  display: inline-block;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  min-width: 66px;
  max-width: 215px;
  padding: 0 2px;
}
.flex_row_itemb.no_wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.flex_row_itemb.opt {
  border: 1.25px solid #3498db;
  color: #3498db;
}
.flex_row_itemb.opt::after {
  display: block;
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 14px;
  height: 14px;
  background: url('../../../public/image/tick_br.png') center no-repeat;
}

.no_border {
  border: none;
}
.clew_form_info_line {
  padding: 0px 0 !important;
  min-height: 37px !important;
}
</style>
