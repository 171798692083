<template>

  <div>
    <!-- 新增班级GOTO -->
    <btn-add-select :addTitleName="'新增'"
                    :addmoduleName="'squad-class-Setting'"
                    :addmoduleTitleName="'add'"
                    :addmoduleKey='45'
                    @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>

    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <div class="defult_dialog_searchform">
        <!-- 搜索框 -->
        <div class="search_form">
          <input type="text"
                 class="search_input"
                 placeholder=""
                 v-model="studenSearchText">
          <span class="remove_btn"
                v-show="studenSearchText.length > 0"
                @click.stop="clearSearText"></span>
        </div>
      </div>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content">
          <div class="search_result_list"
               v-if="vClassList.length> 0">
            <single-selection-item v-for="(item,index) in vClassList"
                                   :key="item.OLAPKey"
                                   :isTestStudentItem="item"
                                   @selectedItem="selectedItem"></single-selection-item>
          </div>
          <div v-else-if="vClassList.length == 0"
               class="course_block_nodata">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import singleSelectionItem from './single-selection-item';
import { getUsingClass } from '../../API/workbench.js';
export default {
  name: 'classGoalList',
  data () {
    return {
      // 测试班级数据
      gradeClassList: [],
      studenSearchText: ''
    };
  },
  components: {
    singleSelectionItem
  },
  props: {
    isValidClassShow: { // true 为有效班级，false为全部班级
      type: Boolean,
      default: true

    },
    isPayment: {
      type: Boolean,
      default: false
    },
    selectedKey:{
      type: [Number,String],
      default: 0
    }
  },
  created () {
    this.validClassList();
  },
  methods: {
    // 新增回调
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      this.selectedItem(result);
    },
    selectedItem (item) {
      this.gradeClassList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.$emit('selectedItem', item);
      this.closeDialog();
    },
    closeDialog () {
      this.$emit('closeDialog');
    },
    // 获取有效班级列表
    validClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        if (result.code == 0) {
          if (this.isValidClassShow) {
            result.data.forEach(obj => {
              obj.isActive = false;
              if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
                this.gradeClassList.push(obj);
              }
            });
          } else {
            this.gradeClassList = result.data;
          }
        } else {
          layer.alert('接口异常，注意检查', {
            btn: [{ name: '确认', callBack: function () { layer.close(); } }]
          });
        }
      });
    },
    clearSearText () {
      this.studenSearchText = '';
    }

  },
  computed: {
    vClassList () {
      let newClassList = [];
      this.gradeClassList.forEach(o => {
        if (this.studenSearchText === '' || (o.MainDemoName && o.MainDemoName.indexOf(this.studenSearchText) >= 0) ||
          (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(this.studenSearchText.toUpperCase()) >= 0)
        ) {
          newClassList.push(o);
        };
      });

      if (this.isPayment) {
        newClassList.unshift({
          OLAPKey: 0,
          MainDemoName: '不关联'
        });
      }
      newClassList.forEach(o => {
        if (this.selectedKey == o.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      console.log('newClassList',newClassList,this.selectedKey)
      return newClassList;
    }
  }
};
</script>

