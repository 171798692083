<template>
  <div>
    <!--已有班级， 选学生去排课 -->
    <div v-if="type=='showStudentList'">
      <student-list :CustStatusKey="1"
                    :isShowAddBtn="true"
                    @selectedItem="selectedItem"></student-list>
    </div>
    <!--已有学生， 选班级去排课 -->
    <div v-else-if="type=='showClassList'">
      <grade-list @selectedItem="selectedClass"
                  :isPayment="dataInfo.isPayment"
                  :selectedKey="dataInfo.selectedKey"></grade-list>
    </div>
  </div>
</template>
<script>
import studentList from '../../../common/student-list';
import gradeList from '../../../common/grade-list';
import { SearchStructedRule, studentAddClass, GetTheClassByID } from '../../../../API/workbench';
export default {
  name: 'addStudentToBJ',
  data () {
    return {
      selectedClassItem: null,       // 选中班级
      selectedStudentItem: null,    // 选中学生
      type: ''// 列表类型
    };
  },
  props: {
    dataInfo: Object
  },
  components: { studentList, gradeList },
  created () {
    console.log(this.dataInfo, '加入');
    if (this.dataInfo.studentInfo) {
      this.selectedStudentItem = this.dataInfo.studentInfo;
      this.type = 'showClassList';
    } else if (!this.dataInfo.studentInfo && this.dataInfo.classInfo) {
      this.selectedClassItem = this.dataInfo.classInfo;
      this.type = 'showStudentList';
    }
  },
  methods: {
    selectedItem (item) { // 选学生 学生进班并排课
      this.selectedStudentItem = item;
      this.showCourseArrangingFrom();
    },
    selectedClass (item) {  // 选班级
      this.selectedClassItem = item;
      if (item.OLAPKey == 0) {
        this.$emit('onSelectedItem', item);
        this.$emit('closeAddStudentToBJDialog');
      }else{
        this.showCourseArrangingFrom(item);
        this.$emit('onSelectedItem', item);
      }
    },
    showCourseArrangingFrom () { // 9808 PC班级管理-班内学生、PC、WXB学生档案-进班：+学生进班调整
      if (this.selectedClassItem && this.selectedStudentItem) {
        GetTheClassByID(this.selectedClassItem.OLAPKey).then(resultClass => {
          SearchStructedRule('', this.selectedClassItem.OLAPKey).then(resultRule => {
            console.log(resultClass.data, resultRule.data, '进班调整');
            if (resultClass.data.IsSyncGradeClassKey == 1 || resultRule.data.length == 0) {
              studentAddClass(this.selectedStudentItem.OLAPKey, this.selectedClassItem.OLAPKey).then(result => { // 加入
                layer.alert(this.selectedStudentItem.MainDemoName + '同学加入班级成功!');
                this.$emit('closeAddStudentToBJDialog');
                this.$emit('afterSucces', result.data, this.selectedStudentItem.OLAPKey);
                this.$bus.emit('afterQuitOrAddClass', this.selectedClassItem.OLAPKey, this.selectedStudentItem.OLAPKey, 'add');
              }, error => {
                layer.alert(error.msg);
              });
            } else {
              this.$emit('selectedItem', this.selectedStudentItem);
              this.$dialog.open(this, {
                name: '学生进班并排课',
                routerName: this.$route.name,
                routertitle: '',
                ModulePowerKey: 51,
                moduleName: 'quitOrEnterClassForm',
                data: {
                  dataInfo: { studentID: this.selectedStudentItem.OLAPKey, classNameID: this.selectedClassItem.OLAPKey, classTitle: '进班' }
                },
                callBack: {
                  afterSucces: (type, data) => { console.log('12314654'); this.$emit('closeAddStudentToBJDialog'); }
                }
              });
            }
          });
        });
      }
    }
  }
};
</script>
