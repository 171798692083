<template>
  <div class="popup_dialog_tips_box">
    <div class="tips_text">{{textTips}}</div>
    <div class="tips_btn"
         v-if="btnUrl">
      <a :href="btnUrl"
         target="_blank">{{btnTips}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textTips: {// 文本说明
      type: String
    },
    btnTips: {// 按钮文本
      type: String,
      default: '查看教程'
    },
    btnUrl: {// 按钮链接
      type: String
    }
  }
};
</script>

<style>
</style>