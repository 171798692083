<template>
  <div>
    <div class="form_info_edit form_info_list">

      <input-number :min="1"
                    :formTitle="'数量'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="vIsEdit"
                    v-model="dataInfo.FluxCombo.NumberCount"></input-number>

      <input-text :formTitle="'单位'"
                  :required="true"
                  readonly='readonly'
                  class=""
                  v-model="this.dataInfo.UnitKeySymbol"></input-text>
      <input-text :formTitle="'原因'"
                  :readonly="vIsEdit"
                  :required="true"
                  :formPlaceholder="'请输入'"
                  class=""
                  v-model="dataInfo.ReasonScript"></input-text>
    </div>

    <div v-if="!vIsEdit">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submitAdd"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
    <div v-else>
      <save-cancel-btn-group :cancelBtnText="'撤销'"
                             :isSingleSubmit="true"
                             :btnText="'提交审核'"
                             :fixedBtn="true"
                             @confirmClick="submitAudit"
                             @cancelClick="revocation"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'赠送流量申请'"
                   :visible.sync="showAuthCode"
                   :before-close="closeShowAuthCode">
      <auth-code :dataInfo="dataInfo"
                 @close="closeShowAuthCode"
                 @afterSuccess="afterSuccess"></auth-code>
    </custom-dialog>
  </div>
</template>
<script>
import authCode from './auth-code';
import { AddApply, GetApplyDetails, CancelApply } from '../../../../../API/workbench';
export default {
  components: {
    authCode
  },
  data () {
    return {
      showAuthCode: false,
      dataInfo: {
        'ApplyTime': this.$utils.formatDateToLine(Date.new()), // '申请时间',
        'ApplicantKey': '', // '申请人',
        'ApplicantKeyValue': '', // '申请人',
        'SaaSClientNameKey': '', // '机构Key',
        'SaaSClientNameKeyValue': '', // '机构',
        'SaaSClientStatusKey': '0', // '机构状态0-未开通;1-临时开通;2-正式开通;3-关停',
        'SaaSClientStatusKeyValue': '开通', // '机构状态',
        'MobileSupport': '', // '手机号',
        'ContactsName': '', // '联系人',
        'ThreadKey': '', // '线索key',
        'TypeKey': '8', // 1-开户;2-付款;3-返介绍费;4-清数据;5-导出学员课单;6-临时延期;7-续费赠送延期'
        'TypeKeyValue': '赠送流量', // 1-开户;2-付款;3-返介绍费;4-清数据;5-学员课单导出;6-临时延期;7-续费赠送延期'
        'ReasonScript': '', // '申请原因',
        'PayMent': null,
        'ClearSaaSClien': null,
        'CashBack': null,
        'DredgeTime': '',
        'UnitKeySymbol': 'G',
        'DataBaseKey': '',
        'FluxCombo': {
          // 流量包信息
          'UnitKey': 1, // 单位 1-G;2-T
          'NumberCount': 0  // 流量大小
        }
      }
    };
  },
  props: {
    seletedItem: Object
  },
  created () {
    if (this.seletedItem && this.seletedItem.GiveFluxApplyKey > 0) {
      console.log(this.seletedItem.GiveFluxApplyKey, 'this.seletedItem.StatusKey');
      this.getDetailInfo(this.seletedItem.GiveFluxApplyKey);
    } else {
      this.initAddData();
    }
    this.initAddData();
    console.log(this.seletedItem, 'this.seletedItem');
    console.log(this.dataInfo, 'dataInfo');
  },
  computed: {
    vIsEdit () {
      if (this.seletedItem && this.seletedItem.GiveFluxApplyKey > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    initAddData () {
      console.log(this.seletedItem, 'initAddData');
      this.dataInfo.DredgeTime = this.seletedItem.DredgeTime;
      this.dataInfo.ApplicantKey = this.$store.getters.token.UserID;
      this.dataInfo.ApplicantKeyValue = this.$store.getters.token.UserName;
      this.dataInfo.SaaSClientNameKey = this.seletedItem.SaaSClientNameKey; // '机构Key',
      this.dataInfo.SaaSClientNameKeyValue = this.seletedItem.SaaSClientNameKeyValue; // '机构',
      this.dataInfo.MobileSupport = this.seletedItem.MobileSupport; // '手机号',
      this.dataInfo.ContactsName = this.seletedItem.ContactsName; // '联系人',
      this.dataInfo.ThreadKey = this.seletedItem.OLAPKey; // '线索key',

      // 开户状态
      if (this.dataInfo.SaaSClientStatusKey == 0) {
        this.dataInfo.SaaSClientStatusKeyValue = '未开';
      } else if (this.dataInfo.SaaSClientStatusKey == 1 || this.dataInfo.SaaSClientStatusKey == 2) {
        this.dataInfo.SaaSClientStatusKeyValue = '已开';
      } else if (this.dataInfo.SaaSClientStatusKey == 3) {
        this.dataInfo.SaaSClientStatusKeyValue = '关闭';
      }
    },
    getDetailInfo (TableID) {
      GetApplyDetails(TableID).then(result => {
        console.log('GetApplyDetails', result.data);
        console.log('result.data', result.data.FluxCombo.UnitKey);
        this.dataInfo = result.data;
        if (result.data.FluxCombo.UnitKey == 1) {
          this.dataInfo.UnitKeySymbol = 'G';
        } else {
          this.dataInfo.UnitKeySymbol = 'T';
        }
      });
    },
    // 提交审核
    submitAudit (callback) {
      console.log('submit', this.dataInfo);
      this.showAuthCode = true;
      callback();
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.dataInfo);
      let isPass = true;
      if (this.dataInfo.FluxCombo.NumberCount == '') {
        layer.alert('请输入流量赠送数量');
        isPass = false;
      } else if (this.dataInfo.ReasonScript == '') {
        layer.alert('请输入原因');
        isPass = false;
      }
      if (!isPass) {
        if (callback) {
          callback();
          console.log(isPass, 'ispass');
        }
        return false;
      }
      AddApply(this.dataInfo).then(result => {
        layer.alert('新增成功');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        if (callback) {
          callback();
        }
        console.log('errEditDefaultReply', error);
      });
    },
    // 撤销
    revocation () {
      layer.confirm('请问是否要撤销该申请', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelApply(this.dataInfo.TableID).then(result => {
              console.log(this.dataInfo.TableID, 'this.dataInfo.TableID');
              this.doAfterClickCancelBtn();
            }).catch(error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    closeShowAuthCode () {
      this.showAuthCode = false;
    },
    afterSuccess () {
      this.showAuthCode = false;
      this.$nextTick(() => {
        this.$emit('afterSuccess');
        this.$emit('close');
      });
    }
  }
};
</script>
