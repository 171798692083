<template>
  <div>
    <div class="fixed_nav_ul">
      <div class="fixed_nav_list"
           :class="{opt:selectType == 1}"
           @click="showTypeOpt(1)">
        转发详情
      </div>
      <div class="fixed_nav_list"
           :class="{opt:selectType == 2}"
           @click="showTypeOpt(2)">
        点赞详情
      </div>
    </div>
    <div class="fixed_info_ul height_top_list "
         style="border-top:1px solid #ececec">
      <div class="interaction_staff_ul">
        <div class="interaction_staff_list"
             v-for="(item,index) in serachDataList"
             :key="index">
          <div class="flex flex_ai_c">
            <div class="list_img">
              <img class="img_box"
                   :src="item.HeadImg">
            </div>
            <div class="list_name">
              {{selectType==1?item.Share:item.Reader}}
            </div>
          </div>
          <div class="list_btn"
               v-if="selectType==1">
            转发·{{item.ShareCount||0}}
          </div>
        </div>
        <div v-if="serachDataList.length == 0">
          <basics-nodata-tips :isLoading="isLoading"></basics-nodata-tips>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { GetShareListForComment, GetPraiseListForComment } from '../../../../API/workbench';
export default {
  data () {
    return {
      scrollTopNumber: 0,
      selectType: '',
      serachDataList: [],
      isLoading: true
    };
  },
  props: {
    id: [String, Number],
    type: Number
  },
  components: {

  },
  computed: {

  },
  created () {
    this.selectType = this.type || 1;
    this.showTypeOpt(this.selectType);
  },
  methods: {
    showTypeOpt (type) {
      if (type == 1) {
        this.getShareListForComment(type);
      } else {
        this.getPraiseListForComment(type);
      }
    },
    // 获取转发列表
    getShareListForComment (type) {
      this.isLoading = true;
      GetShareListForComment(this.id).then(res => {
        this.serachDataList = res.data;
        this.serachDataList.forEach(o => {
          if (o.ShareHeadImg && o.ShareHeadImg.indexOf('http') == -1) {
            o.HeadImg = this.$store.getters.CDNURL + o.ShareHeadImg;
          } else if (o.ShareHeadImg && o.ShareHeadImg.indexOf('http') > -1) {
            o.HeadImg = o.ShareHeadImg;
          } else {
            o.HeadImg = '../../../public/image/deflut_teacher_photo@2x.png';
          }
        });
        this.selectType = type;
        this.isLoading = false;
      }, err => {
        this.$store.dispatch('popup/open', { content: err.msg });
      });
    },
    // 获取点赞列表
    getPraiseListForComment (type) {
      this.isLoading = true;
      GetPraiseListForComment(this.id).then(res => {
        this.serachDataList = res.data;
        this.serachDataList.forEach(o => {
          if (o.ReaderHeadImg && o.ReaderHeadImg.indexOf('http') == -1) {
            o.HeadImg = this.$store.getters.CDNURL + o.ReaderHeadImg;
          } else if (o.ReaderHeadImg && o.ReaderHeadImg.indexOf('http') > -1) {
            o.HeadImg = o.ReaderHeadImg;
          } else {
            o.HeadImg = '../../../public/image/deflut_teacher_photo@2x.png';
          }
        });
        this.isLoading = false;
        this.selectType = type;
      }, err => {
        this.$store.dispatch('popup/open', { content: err.msg });
      });
    }
  }
};
</script>

<style>
</style>
