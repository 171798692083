<template>
  <div class="form_info_block_ul">
    <item v-for="(item,key) in dataList"
          :key="key"
          :item="item"
          :dataKey="dataKey"
          @seletedItem="seletedItem"></item>
  </div>
</template>

<script>
import item from './item';
export default {
  data () {
    return {

    };
  },
  components: {
    item
  },
  props: {
    dataList: Array,
    dataKey: [String, Number]
  },
  methods: {
    seletedItem (item) {
      this.$emit('seletedItem', item);
    }
  }
};
</script>

<style>
</style>
