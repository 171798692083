<template>
  <div class="active_condition_popup">
    <div class="popup_title">
      {{vTitle}}  
    </div>
    <div class="popup_content">
      <div class="popup_checkbox" v-if="dataInfo.UsingStatusKey!=2" @click.stop="changeType(2)" :class="status==2?'opt':''">
        留存
      </div>
      <div  class="popup_checkbox" v-if="dataInfo.UsingStatusKey!=1" @click.stop="changeType(1)" :class="status==1?'opt':''">
        试用中
      </div>
      <div  class="popup_checkbox" v-if="dataInfo.UsingStatusKey!=3" @click.stop="changeType(3)" :class="status==3?'opt':''">
        流失
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                                 :fixedBtn="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="saveComfire"
                                 @cancelClick="closeDialog"></save-cancel-btn-group>
  </div>
</template>
<script>
import { UpdateUsingStatus } from '../../../../API/workbench';
export default {
  data () {
    return {
      status: 0
    };
  },
  created () {
    // this.status = this.dataInfo.UsingStatusKey;
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          status: 0
        };
      }
    }
  },
  computed: {
    vTitle () {
      if (this.dataInfo.UsingStatusKey == 1) {
        return '试用中状态可切换成留存或流失';
      } else if (this.dataInfo.UsingStatusKey == 2) {
        return '已留存状态可切换成试用中或流失';
      } else {
        return '已流失状态可切换成留存或试用中';
      }
    }
  },
  methods: {
    changeType (type) {
      console.log(type, 'changeType');
      this.status = type;
    },
    // 更新门店状态   //UsingStatusKey 门店使用状态0-待辅导;1-试用中;2-已留存;3-已流失;
    updateMenDianStatus (SaaSClientKey, UsingStatusKey, callback) {
      UpdateUsingStatus(SaaSClientKey, UsingStatusKey).then(result => {
        console.log('UpdateUsingStatus', result.data);
        this.dataInfo.UsingStatusKey = Number(this.status);
        this.$emit('updateMenDianStatus');
        if (callback) {
          callback();
        }
      }).catch(error => {
        if (callback) {
          callback();
        }
        layer.alert(error.msg);
        console.log(error, 'UpdateUsingStatusError');
      });
    },
    saveComfire (callback) {
      if (this.status == 0) {
        layer.alert('请选择后再点击确定');
      }
      this.updateMenDianStatus(this.dataInfo.SaaSClientKey, this.status, callback);
    },
    closeDialog () {
      this.$emit('closeDialog');
    }
  }
};
</script>
