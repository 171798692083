<template>
  <div class="payment_statistics_bottom ">
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="flex"
           style="margin-right: 10px; min-width: 120px;margin-left:0px">
        <el-select v-model="searchObj.TeacherKey"
                   placeholder="选择学管师"
                   style="width: 150px;margin-right:20px;height: 36px;"
                   ref="resetText"
                   @change="selectTeacher">
          <el-option v-for="item in vteacherList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item.OLAPKey">
          </el-option>
        </el-select>
        <div class="flex">
          <span style="line-height: 32px;">选择月份</span>
          <div class="title_date_select">
            <div class="el-select">
              <div class="el-input el-input--small el-input--suffix is-focus">
                <single-year-picker @chgDate="changeSaveDate"
                                    :minDate="''"
                                    :maxDate="''"
                                    ref="monthPicker"
                                    :type="'month'"></single-year-picker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"
                                  style="margin-right:10px"></input-search-contain-btn>

        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';

// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../../common/table-list/index';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { GetHaveRenewList, CancelRelevantRenewXykd } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        StatusType: '',
        FollowTime: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        IsExportKey: 0,
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          sortable: false,
          align: 'left',
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '应续课单',
          prop: 'GenerateText',
          minWidth: 400,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '关联',
          prop: 'isAboutText',
          width: 80,
          sortable: false,
          align: 'center',
          isShow: true,
          className: 'font_dual',
          type: 'text-item',
          decimalPlace: 0,

        },
        {
          label: '关联的续费课单',
          prop: 'DrainageGenerateText',
          minWidth: 400,
          sortable: false,
          align: 'center',
          isShow: true,
          className: 'font_dual',
          type: 'text-item',
          decimalPlace: 0,

        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          minWidth: 100,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '解除关联',
                extend: {
                  click: (rowData) => {
                    this.cancelItem(rowData);
                  }
                }
              },


            ]
          }
        }


      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    singleYearPicker
  },
  props: {
    seletedItem: {
      type: Object,
      default: () => {
        return {
          OLAPKey: 0,
          MainDemoName: '趁',
          StudentCount: 50
        }
      }
    },
    teacherList: Array

  },

  created () {
  },
  computed: {
    vteacherList () {
      let newArr = []
      newArr = this.$utils.parseJson(this.teacherList || [])
      newArr.unshift({
        OLAPKey: -1,
        MainDemoName: '未分配学管师'
      })
      newArr.unshift({
        OLAPKey: 0,
        MainDemoName: '全部学管师'
      })

      return newArr
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        o.GenerateText = `<div class="div_flex_row">` +
          `<span class="` + (o.StuatusTypeKey == 2 ? 'type_green' : 'type_red') + `">` + o.StuatusTypeKeyValue + `</span>` +
          `<span class="font_gray">购买日期: <span class="font_black">` + o.GenerateTime + `</span></span>` +
          `<span class="font_gray ApplyCor_text">适用: <span class="font_black" :title="` + o.ApplyCorName + `">` + o.ApplyCorName + `</span></span>` +
          `<span class="font_gray">剩余: <span class="font_black ">` + Number(o.CurrentCount) + '/' + Number(o.NumCount) + (o.UnitKey == 5 ? '课时' : '天') + `</span></span>`
          + `</div>`;
        o.isAboutText = `<div class="about_img"></div>`;
        o.DrainageGenerateText = `<div class="div_flex_row">` +
          `<span class="font_gray">购买日期: <span class="font_black">` + o.DrainageGenerateTime + `</span></span>` +
          `<span class="font_gray ApplyCor_text">适用: <span class="font_black " :title="` + o.DrainageApplyCorName + `">` + o.DrainageApplyCorName + `</span></span>` +
          `<span class="font_gray">续费金额: <span class="font_black ">￥` + Number(o.DrainageActualPayAmount) + `</span></span>`
          + `</div>`;
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
    this.getDataList();
  },
  watch: {


  },
  methods: {
    cancelItem (item) {
      layer.confirm(
        '是否解除关联',
        {
          btn: [
            {
              name: '确认',
              callBack: () => {
                CancelRelevantRenewXykd(item.TableID).then(result => {
                  this.getDataList();
                }).catch(error => {

                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    selectTeacher () {
      this.getDataList();

    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetHaveRenewList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '已续记录统计',
          clist: [
            { title: '学管师', cName: 'AdviserStudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '应续课单购买日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '应续课单适用课程', cName: 'ApplyCorName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买数', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::NumCount::1::0}}" },
            { title: '可用课时', cName: 'CurrentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::CurrentCount::1::0}}" },
            { title: '可用天数', cName: 'PeriodCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::PeriodCount::1::0}}" },
            { title: '天数/课时结余', cName: 'ValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ValueAmount::1::0}}" },
            { title: '课单状态', cName: 'StuatusTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关联课单购买日期', cName: 'DrainageGenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关联课单适用课程', cName: 'DrainageApplyCorName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关联课单续费金额', cName: 'DrainageActualPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::DrainageActualPayAmount::1::0}}" },

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    changeSaveDate (val, startTime, endTime) {
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-');
    },

    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    getDataList (queryParams) {
      let fn = '';
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      GetHaveRenewList(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);

    },

  }
};
</script>
<style scoped>
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_search {
  width: auto !important;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.payment_statistics_bottom .list_title {
  width: 100%;
  height: 87px;
  margin: auto;
  padding-left: 20px;
  line-height: 87px;
}
.statistics_list {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.statistics_list .statistics_item {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #ff9800;
  border: 1.25px solid #ff9800;
}
.statistics_list .statistics_item_red {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #f75040;
  border: 1.25px solid #f75040;
}
.statistics_list .border_left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: none;
}
.statistics_list .border_right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}
.table_list_content >>> .div_flex_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.table_list_content >>> .div_flex_row .type_red {
  background: #f7504033;
  color: #f75040;
  font-size: 12px;
  padding: 1px;
  border-radius: 4px;
}
.table_list_content >>> .div_flex_row .type_green {
  background: #49cd8933;
  color: #49cd89;
  font-size: 12px;
  padding: 1px;
  border-radius: 4px;
}
.table_list_content >>> .div_flex_row span {
  margin-right: 10px;
}
.table_list_content >>> .ApplyCor_text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table_list_content >>> .about_img {
  background: url('../../../../../public/image/about_cb.png') center repeat;
  background-size: 20px;
  width: 20px;
  height: 20px !important;
  margin: auto;
}
</style>