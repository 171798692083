<template>
  <div class="recruit_student_activity_content clearfix cover_content_over">
    <div class="nav_bar">
      <div class="nav_bar_title">
        招生场景：
      </div>
      <div class="nav_bar_content scene"
           :style="sceneStyle&&!sceneShowMore?'max-height:38px;overflow: hidden':''">
        <option-item v-for="(item,key) in sceneList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedSceneItem">
        </option-item>
        <div class="nav_bar_btn font_blue type_blue"
             v-if="sceneStyle&&!sceneShowMore"
             @click.stop="showsceneMore">展开</div>
        <div class="nav_bar_btn font_blue type_blue close"
             v-if="sceneStyle&&sceneShowMore"
             @click.stop="showsceneMore">收起</div>
      </div>
    </div>
    <div class="nav_bar">
      <div class="nav_bar_title">
        适用机构：
      </div>
      <div class="nav_bar_content agencies"
           :style="agenciesStyle&&!agenciesShowMore?'max-height:38px;overflow: hidden':''">
        <option-item v-for="(item,key) in agenciesList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedAgenciesItem">
        </option-item>
        <div class="nav_bar_btn font_blue type_blue"
             v-if="agenciesStyle&&!agenciesShowMore"
             @click.stop="showMore">展开</div>
        <div class="nav_bar_btn font_blue type_blue close"
             v-if="agenciesStyle&&agenciesShowMore"
             @click.stop="showMore">收起</div>
      </div>
    </div>
    <div class="content_ul"
         v-if="vTemplateList.length>0">
      <template-item v-for="(item,key) in vTemplateList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedTemplateAfter"></template-item>

    </div>
    <div v-if="vTemplateList.length==0"
         style="padding-top:150px;height:500px">
      <div class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="searchObj.pageSize > 0"
         class="table_pagination table_pagination_right">
      <div class="table_left_text">
        共{{total}}条数据
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :page-size="searchObj.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     layout="prev, pager, next, jumper"
                     :total="total>0?total:searchObj.pageSize"></el-pagination>
      <table-pagination-number :pageSize="searchObj.pageSize"
                               :queryParams="searchObj"
                               @loadTableData="getDataList"></table-pagination-number>
    </div>
   
  </div>
</template>
<script>
import {
  SelectEnableApplicableAgencies, SelectEnableScene, SelectCoverTemplate
} from '../../../../../../../API/workbench.js';
import optionItem from './option-item';
import templateItem from './template-item';
import tablePaginationNumber from '../../../../../../common/table-pagination-number'; // table 分页
export default {
  data () {
    return {
      agenciesList: [], // 机构列表
      sceneList: [],     // 场景列表
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: 'sort',
        sequence: 'asc',
        SceneKey: 0,
        AgenciesKey: 0
      },
      templateList: [],     // 模板列表
      coverInfo: null,   // 选中模板
      total: 0,
      sceneStyle: false,
      agenciesStyle: false,
      sceneShowMore: false,
      agenciesShowMore: false,
      oneClick: true  // 避免事件触发两次.
    };
  },
  components: {
    optionItem, templateItem, tablePaginationNumber
  },
  computed: {
    vTemplateList () {
      let newArr = [];
      this.templateList.forEach((o, index) => {
        this.$set(o, 'isActive', false);
        if (!this.searchObj.searchText && this.searchObj.pageIndex == 0 && this.searchObj.SceneKey == 0 && this.searchObj.AgenciesKey == 0) {
          if (index < 5) {
            this.$set(o, 'isHot', true);
          }
        } else {
          this.$set(o, 'isHot', false);
        }
        newArr.push(o);
      });
      return newArr;
    },
    vPageIndex: {
      get () {
        return this.searchObj.pageIndex + 1;
      },
      set (n, o) {
      }
    }
  },
  props: {

  },
  created () {
    this.getSelectEnableApplicableAgencies();
    this.getSelectEnableScene();
    this.getDataList();
  },
  mounted () {
    
    // this.setHeight();
  },
  methods: {
    showMore () {
      this.agenciesShowMore = !this.agenciesShowMore;
    },
    showsceneMore () {
      this.sceneShowMore = !this.sceneShowMore;
    },
    setAgenciesHeight () {
      if (this.$el.querySelector('.agencies').offsetHeight > 50) {
        this.agenciesStyle = true;
      } else {
        this.agenciesStyle = false;
      }
    },
    setSceneHeight () {
      if (this.$el.querySelector('.scene').offsetHeight > 50) {
        this.sceneStyle = true;
      } else {
        this.sceneStyle = false;
      }
    },
    // 打开编辑器
    openIshowEditor (item) {
      // table 按钮点击
      if (this.oneClick) {
        this.oneClick = false;
        this.seletedPlayMethodInfo = item;
        let moduleInfo = {
          name: item.title,
          moduleName: 'oldIshowEditorDialog',
          routerName: this.$route.name,
          data: { dataInfo: this.seletedPlayMethodInfo, coverInfo: this.coverInfo },
          callBack: { afterSuccess: () => {
            this.$emit('changeType');
          }
          }
        };
        this.$emit('openFactoryDialog', moduleInfo);
        // bus.emit('openCustomDialog', moduleInfo);
        setTimeout(() => {
          this.oneClick = true;
        }, 1000);
        // this.$nextTick(o => {

        // });
      }
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.searchObj.pageIndex = val - 1;
      this.getDataList(this.searchObj);
    },
    // 选中模板
    seletedTemplateAfter (item) {
      this.templateList.forEach(o => {
        if (item.id == o.id) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.coverInfo = item;
      this.$emit("afterSuccess",item)
    },
    // 选中场景item
    seletedSceneItem (item) {
      this.sceneList.forEach(o => {
        if (o.id == item.id) {
          this.searchObj.SceneKey = item.id;
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 选中机构item
    seletedAgenciesItem (item) {
      this.agenciesList.forEach(o => {
        if (o.id == item.id) {
          this.searchObj.AgenciesKey = item.id;
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList () {
      SelectCoverTemplate(this.searchObj).then(result => {
        this.templateList = result.data.PageDataList;
        this.total = result.data.Total;
      });
    },
    // 获取适用机构列表
    getSelectEnableApplicableAgencies () {
      SelectEnableApplicableAgencies().then(result => {
        this.agenciesList = [];
        result.data.forEach(o => {
          o.value = o.agencies;
          o.isActive = false;
          this.agenciesList.push(o);
        });
        this.agenciesList.unshift({
          value: '全部',
          isActive: true,
          id: 0
        });
        this.$nextTick(o => {
          this.setAgenciesHeight();
        });
      });
    },
    // 获取场景列表
    getSelectEnableScene () {
      SelectEnableScene().then(result => {
        this.sceneList = [];
        result.data.forEach(o => {
          o.value = o.scene;
          o.isActive = false;
          this.sceneList.push(o);
        });
        this.sceneList.unshift({
          value: '全部',
          isActive: true,
          id: 0
        });
        this.$nextTick(o => {
          this.setSceneHeight();
        });
      });
    }
  }
};
</script>
<style scoped>
.cover_content_over{
  padding: 0 15px !important;
}
</style>
