<template>
  <!-- 备课检查-->
  <div class="prepare_lessons_form"
       v-if="courseInfo">
    <!-- 课标题-->
    <div class="prepare_lessons_content_title">
      <div class="info_name">{{courseInfo.CourseWareName}}</div>
      <div class="info_text">{{courseInfo.ParentKeyValue?courseInfo.ParentKeyValue+'-':''}}{{courseInfo.AuthContent}}</div>
    </div>
    <div class="prepare_lessons_content"
         :style="!isReadonly?'max-height:350px':''">
      <!-- 备课内容 -->
      <div class="dynamic_content_body work_attach_bottom pr">
        <browse-item-content :dataInfo="courseInfo"></browse-item-content>
      </div>
      <!-- 反馈内容查看 -->
      <div v-if="isReadonly">
        <work-correct-content :dataInfo="courseInfo"></work-correct-content>
      </div>
    </div>
    <!-- 点评 -->
    <div v-if="!isReadonly">
      <div class="release_content_title">
        <span class="left_side pr">评分</span>
        <div class="flex cp">
          <div class="star_dynamic_list"
               v-for="(item,index) in starList"
               :class="{opt:releaseData.Score>=item.number}"
               :key="index"
               @click="selectStarDynamic(index)"></div>
        </div>
      </div>
      <!-- 反馈内容编辑-->
      <div class="prepare_lessons_edit_content">
        <content-text ref="content"
                      v-model="releaseData.Feedback"
                      :placeholder="'请输入检查反馈内容'"></content-text>
      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'确定'"
                             :btnClass="''"
                             @confirmClick="comfireAfter"
                             @cancelClick="closeDialog"></save-cancel-btn-group>
    </div>
  </div>

</template>

<script>
import { hqGetPrepareLessonsExamine, hqGetPrepareLessonsDetail, hqSubmitPrepareLessonsExamine } from '../../../../API/workbench';
import browseItemContent from '../../../home-school-communication/common-component/browse-box/browse-item-content';
import workCorrectContent from './work-correct-content.vue';
import contentText from '../../../home-school-communication/common-component/release-content/content-text';
export default {
  components: {
    workCorrectContent,
    contentText,
    browseItemContent
  },
  data () {
    return {
      releaseData: {
        Score: 0, //	得分
        Feedback: '', // 反馈
        PrepareLessonsKey: '' // 备课id
      },
      courseInfo: null,
      starList: [{ number: 1 }, { number: 2 }, { number: 3 }, { number: 4 }, { number: 5 }]
    };
  },
  props: {
    prepareLessonsKey: [String, Number], // 备课id
    isReadonly: Boolean// 只读（用于查看）
  },
  created () {
    console.log('created', this.prepareLessonsKey);

    if (this.prepareLessonsKey) {
      this.releaseData.PrepareLessonsKey = this.prepareLessonsKey;
      this.getPrepareLessonsExamine();
    }
  },
  computed: {

  },
  methods: {
    selectStarDynamic (index) {
      this.releaseData.Score = index + 1;
      console.log(index);
    },
    closeDialog () {
      this.$emit('closeDialog');
    },
    getPrepareLessonsExamine () {
      let apiFN = (this.isReadonly ? hqGetPrepareLessonsExamine : hqGetPrepareLessonsDetail);
      apiFN(this.prepareLessonsKey).then(res => {
        console.log(res.data);
        this.courseInfo = res.data;
        this.courseInfo.Content = res.data.Essay;
        this.courseInfo.isShowTag = true;// 显示标签
      }, err => {
        console.log(err);
      });
    },
    comfireAfter (callBack) {
      console.log('submitClick:this.releaseData', this.releaseData);
      let flag = true;
      if (this.releaseData.Score == 0) {
        layer.alert('请选择评分，至少点亮一个小星星。');
        flag = false;
      } else if (this.releaseData.Feedback.length > 4000) {
        layer.alert('内容不能超过4000字。');
        flag = false;
      }
      if (!flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      hqSubmitPrepareLessonsExamine(this.releaseData.Feedback, { Score: this.releaseData.Score, PrepareLessonsKey: this.releaseData.PrepareLessonsKey }).then(result => {
        this.$emit('afterSuccess');
        this.closeDialog();
      }, error => {
        if (typeof callBack === 'function') {
          callBack();
        }
        layer.alert(error.msg);
      });
    }
  }
};
</script>

<style>
.prepare_lessons_form {
  margin-bottom: 10px;
}
.prepare_lessons_form .left_side:before {
  content: '*';
  color: #ff6c67;
  position: absolute;
  left: -10px;
  top: 3px;
  line-height: 18px;
  display: block;
}
.prepare_lessons_content_title {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 75px;
  border-bottom: 1px solid #ececec;
}
.prepare_lessons_content_title .info_name {
  font-weight: 500;
  font-size: 16px;
  padding-top: 18px;
}
.prepare_lessons_content_title .info_text {
  color: #999999;
  padding-top: 10px;
}
.prepare_lessons_content {
  padding: 10px 20px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}
.prepare_lessons_edit_content {
  border: 2px dashed #c0deff;
  margin: 0 20px 20px 20px;
  overflow-y: auto;
  transform: scale(1);
  padding: 5px 0px 5px 10px;
  height: 180px;
  line-height: 20px;
}
.prepare_lessons_edit_content .text_box {
  min-height: 160px;
}
</style>
