<template>
  <div class="form_item form-input form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value">
      <span class="form_input_hint"
            v-if="formInputHint">{{formInputHint}}</span>
      <!-- 修改模式 rational -->
      <input type="number"
             :placeholder="inputPlaceholder?inputPlaceholder:(required?'必填':'选填')"
             v-model="inputValue"
             v-if="!readonly"
             @input="inputEvent($event.target.value,$event)" />
      <!-- 只读模式 -->
      <input v-if="readonly"
             readonly="readonly"
             v-model="value"
             class="input_readonly" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'inputNumber',
  data () {
    return {
      inputValue: 0,
      isWrithing: false,
      inputPlaceholder: this.placeholder
    };
  },
  props: {
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    value: [Number, String], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: String, // 提示文本，可选，如不提供，则默认显示title
    decimalPlaces: Number, // 保留的小数位数
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    maxLength: {
      type: Number,
      default: 12
    },
    helpDescription: [String, Number], // 表单说明提示
    formInputHint: String // 输入框说明提示
  },
  computed: {

  },
  created () {

  },
  watch: {
    'value': {
      handler (n, o) {
        if (n) {
          this.inputValue = Number(n).toFixed(this.decimalPlaces);
        } else {
          this.inputValue = n;
        }
      },
      // deep: true,
      immediate: true
    }
  },
  methods: {
    inputEvent (val, e) {
      // if (val.length >= 2 && val[length - 1] == '') {
      //   val.replace(/[+-]$/, '');
      // }
      this.$emit('inputChange', this.inputValue);
      console.log(val, this.inputValue, 'inputEvent-999');
    }
  }
};
</script>


