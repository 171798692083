<template>
  <div class="WKT_activity_music_list"
       @click.stop="seletedItem"
       :class="item.isActive?'opt':''">
    <div class="list_name">{{item.title}}</div>
    <div class="list_btn"></div>
  </div>

</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item);
    }
  }
};
</script>
