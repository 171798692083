<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <el-container class="page">
        <el-main class="page_content">
          <!-- 报名缴费统计 -->
          <div style="display: block;margin-bottom: 100px;"
               class="apply_payment clearfix">
            <!-- 报名缴费详细信息 -->
            <div class="apply_payment_details fl">
              <applyStatisticsItem :formTitle="'今天'"
                                   :secondTitle="'报名金额'"
                                   :secondColor="'dot_green'"
                                   :dataInfo="KDSYDatail.Summary_KDSY_Today"
                                   :lastColor="'dot_blue'"
                                   :lastTitle="'查看详情'"
                                   :Amount="KDSYDatail.Summary_KDSY_Today.RecableAmount"
                                   @showDetail="showDetail(KDSYDatail.Summary_KDSY_Today)"></applyStatisticsItem>
              <applyStatisticsItem :formTitle="'昨天'"
                                   :secondTitle="'报名金额'"
                                   :secondColor="'dot_green'"
                                   :dataInfo="KDSYDatail.Summary_KDSY_Yesterday"
                                   :lastColor="'dot_blue'"
                                   :lastTitle="'查看详情'"
                                   :Amount="KDSYDatail.Summary_KDSY_Yesterday.RecableAmount"
                                   @showDetail="showDetail(KDSYDatail.Summary_KDSY_Yesterday)"></applyStatisticsItem>
              <applyStatisticsItem :formTitle="'报名欠款'"
                                   :secondTitle="'欠款净额'"
                                   :secondColor="'dot_yellow'"
                                   :dataInfo="KDSYDatail.Summary_DebtAmount"
                                   :lastColor="'dot_purple'"
                                   :lastTitle="'查看详情'"
                                   :Amount="KDSYDatail.Summary_DebtAmount.DebtAmount"
                                   @showDetail="showDetail(KDSYDatail.Summary_DebtAmount)"></applyStatisticsItem>
            </div>
            <!-- 图表区域 -->
            <div class="apply_payment_echarts fl">
              <applyDayStatisticsReport @showDetail="showDetail"></applyDayStatisticsReport>
              <applyMonthStatisticsReport @showDetail="showDetail"></applyMonthStatisticsReport>

            </div>
            <!-- 底部信息区 -->
            <div class="details_bottom_box">
              <applyAmountItem :formTitle="'报名金额'"
                               :dataInfo="KDSYDatail.Summary_KDSY_ThisMonth"
                               :AmountValue="KDSYDatail.Summary_KDSY_ThisMonth.RecableAmount"
                               @showDetail="showDetail(KDSYDatail.Summary_KDSY_ThisMonth)"></applyAmountItem>
              <applyAmountItem :formTitle="'客户还款金额'"
                               :dataInfo="KDSYDatail.Summary_Repay"
                               :AmountValue="KDSYDatail.Summary_Repay.RefundAmount"
                               @showDetail="showNumerousDetail(KDSYDatail.Summary_Repay)"></applyAmountItem>
              <applyAmountItem :formTitle="'充值金额'"
                               :dataInfo="KDSYDatail.Summary_Recharge"
                               :AmountValue="KDSYDatail.Summary_Recharge.MoneyCount"
                               @showDetail="showNumerousDetail(KDSYDatail.Summary_Recharge)"></applyAmountItem>
              <applyAmountItem :formTitle="'提现金额'"
                               :dataInfo="KDSYDatail.Summary_CashOut"
                               :AmountValue="KDSYDatail.Summary_CashOut.WithdrawAmount"
                               @showDetail="showNumerousDetail(KDSYDatail.Summary_CashOut)"></applyAmountItem>
              <applyAmountItem :formTitle="'课时退款金额'"
                               :dataInfo="KDSYDatail.Summary_CashBack"
                               :AmountValue="KDSYDatail.Summary_CashBack.WithdrawAmount"
                               @showDetail="showNumerousDetail(KDSYDatail.Summary_CashBack)"></applyAmountItem>
            </div>
          </div>
        </el-main>

        <!-- 弹窗区域 -->
        <!-- 报名收款列表 -->
        <div v-if="isShowList">
          <list ref="applyGrade"
                :listTitle="'报名收款列表'"
                :extendSearchCondition="extendSearchCondition"
                :functionBtnGroup="functionBtnGroupForBMSK"
                :tableData="applyGradeList"
                :totalNum="totalNum"
                :PageCount="PageCount"
                :tableColumns="tableColumns"
                :btnDelete="true"
                :hiddenNumber="hiddenNumber"
                :hiddenParams="hiddenParams"
                @actionBtnClick="doAfterFunctionBtnClick"
                @tableBtnClick="doAfterTableBtnClick"
                @loadTableData="loadTableData"></list>
        </div>
        <!-- 客户还款金额列表 -->
        <list ref="repayGrade"
              :listTitle="'客户还款金额列表'"
              :extendSearchCondition="repayExtendSearchCondition"
              :functionBtnGroup="functionBtnGroup"
              :tableData="repayGradeList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="repayTableColumns"
              @actionBtnClick="repayDoAfterFunctionBtnClick"
              @loadTableData="repayLoadTableData">
        </list>
        <!-- 充值金额列表 -->
        <list ref="rechargeGrade"
              :listTitle="'充值金额列表'"
              :extendSearchCondition="rechargeExtendSearchCondition"
              :tableData="rechargeGradeList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="rechargeTableColumns"
              @actionBtnClick="rechargeDoAfterFunctionBtnClick"
              @loadTableData="rechargeLoadTableData"></list>
        <!-- 提现金额列表 -->
        <list ref="cashOutGrade"
              :listTitle="'提现金额列表'"
              :extendSearchCondition="cashOutExtendSearchCondition"
              :functionBtnGroup="functionBtnGroup"
              :tableData="cashOutGradeList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="cashOutTableColumns"
              @actionBtnClick="cashOutDoAfterFunctionBtnClick"
              @loadTableData="cashOutLoadTableData"></list>
        <!-- 课时退款金额列表 -->
        <list ref="cashBackGrade"
              :listTitle="'课时退款金额列表'"
              :extendSearchCondition="cashBackExtendSearchCondition"
              :functionBtnGroup="functionBtnGroupTKTK"
              :tableData="cashBackGradeList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="cashBackTableColumns"
              @actionBtnClick="cashBackDoAfterFunctionBtnClick"
              @loadTableData="cashBackLoadTableData"></list>
        <!-- 还款成功 -->
        <div>
          <debt-amount-detail :studentItem="{}"
                              :isShowDebtAmountDetails="isShowDebtAmountDetails"
                              @closeDebtAmountDetail="closeDebtAmountDetail"
                              @backAmountSuccess="doAfterBackAmountSuccess">
          </debt-amount-detail>
        </div>
        <!-- 提现成功 -->
        <div>
          <cash-withdrawal :studentItem="{}"
                           :isShowCashWithdrawals="isShowCashWithdrawal"
                           @closeCashWithdrawal="closeCashWithdrawal"
                           @cashWithdrawalSuccess="doAfterCashWithdrawalSuccess">
          </cash-withdrawal>
        </div>
        <!-- 课时退款弹窗 -->
        <!-- <refund-money-back-course @showStudentFile="showStudentFile"
                                @finishFunction="finishFunction"
                                :searchBoxShow="searchBoxShow"
                                ref="refundMoney"></refund-money-back-course> -->

        <!-- 班级档案 -->
        <div>
          <classFile ref="classFile"></classFile>
        </div>
      </el-container>
      <div>
        <dialog-factory ref="applyGradeDialogFactory"
                        :key="'applyGrade'"
                        :routerName="'applyGrade'"></dialog-factory>
      </div>
    </div>
  </div>
</template>
<script>
import applyStatisticsItem from './apply-statistics-item';
import applyAmountItem from './apply-amount-item';
import applyDayStatisticsReport from './apply-day-statistics-report';
import applyMonthStatisticsReport from './apply-month-statistics-report';
import debtAmountDetail from '../../common/debt-amount-detail';
import cashWithdrawal from '../../common/cash-withdrawal';
import list from '../../list/index';

import classFile from '../../pop-up-menu/class-file/index';
import dialogFactory from '../../common/dialog-factory';

import { getKDSYStatistics, MessageSearchPageForKDSY, HideCommonModule, ShowCommonModule, MessageSearchPageForCustomerRepay, MessageSearchPageForStoreValue, MessageSearchPageForBackCourse, MessageSearchPageForWithdrawCash } from '../../../API/workbench';

export default {
  name: 'applyGrade',
  data () {
    return {
      isShowList: false,
      value1: '',
      searchBoxShow: false,   // 搜索框
      isrefundMoneyForBackCourse: false, // 转让课单
      // 初始化KDSYDetail
      KDSYDatail: {
        'Summary_KDSY_Today': {},     // 今天
        'Summary_KDSY_Yesterday': {},   // 昨天
        'Summary_DebtAmount': {},      // 报名欠款
        'Summary_KDSY_ThisMonth': {},   // 这个月报名总额
        'Summary_Repay': {},          // 报名金额
        'Summary_Recharge': {},       // 客户还款金额
        'Summary_CashOut': {},        // 提现
        'Summary_CashBack': {}        // 退款
      },
      // 报名收款参数
      hiddenParams: {
        BillType: 0,
        IsHiddenKey: 1,
        endTime: '',
        orderBy: '',
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        sequence: '',
        startTime: ''
      },
      // 操作按钮名
      functionBtnGroupForBMSK: [{ name: '新增', eventName: this.$initJson.baseFunctionBtnName.add, icon: '', roleName: '', ModulePowerKey: 50 }], // 功能按钮组
      functionBtnGroupTKTK: [{ name: '新增', eventName: this.$initJson.baseFunctionBtnName.add, icon: '', roleName: '', ModulePowerKey: 52 }], // 功能按钮组
      functionBtnGroup: [{ name: '新增', eventName: this.$initJson.baseFunctionBtnName.add, icon: '', roleName: '', ModulePowerKey: 42 }], // 功能按钮组
      extendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '报名日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '缴费类型',
          fieldList: [
            {
              name: 'BillTypeKey',
              defaultValue: 0,
              value: 0,
              validateHandle: '',
              sourceData: [{ key: 1, value: '全款' }, { key: 2, value: '欠款' }, { key: 3, value: '余额' }, { key: 4, value: '欠款余额' }]
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '操作',
          fieldList: [
            {
              name: 'IsHiddenKey',
              defaultValue: 0,
              value: 0,
              validateHandle: '',
              sourceData: [{ key: 0, value: '不隐藏' }, { key: 1, value: '隐藏' }, { key: '', value: '全部' }]
            }
          ]
        }
      ],
      repayExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '还款时间',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      rechargeExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '充值时间',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      cashOutExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '提现时间',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      cashBackExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '退款时间',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      tableColumns: [ // 报名收款列表
        {
          label: '单号',
          prop: 'NumberName',
          type: 'text-item',
          width: 120,
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '报名时间',
          prop: 'ColMonTime',
          type: 'text-item',
          width: 120,
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          type: 'text-item',
          width: 100,
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '客户关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          type: 'text-item',
          width: 160,
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '报名班级',
          prop: 'GradeClassKeyValue',
          type: 'text-item',
          width: 150,
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '实收金额',
          prop: 'ActualColAmount',
          type: 'amount-item',
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '缴费类型',
          prop: 'BillTypeKeyValue',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '隐藏',
                extend: {
                  isHide: false,
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.informationStatusConversion(rowData, callBack);
                  }
                }
              }
            ]
          }
        }
      ],
      repayTableColumns: [ // table 列
        {
          label: '还款时间',
          prop: 'FilingDateTime',
          type: 'text-item',
          width: 140,
          align: 'left',
          sortable: 'custom'
        },

        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '客户关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: 180,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '欠款金额',
          prop: 'DebtAmount',
          width: 120,
          align: 'left',
          sortable: 'custom',
          type: 'amount-item'
        },
        {
          label: '还款金额',
          prop: 'RefundAmount',
          width: 120,
          align: 'left',
          sortable: 'custom',
          type: 'amount-item'
        },
        {
          label: '操作人',
          prop: 'CreatorKeyValue',
          width: 100,
          align: 'left',
          sortable: 'custom',
          type: 'text-item'

        },
        {
          label: '流水号',
          prop: 'SourceKeyName',
          width: 'auto',
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        }

      ],
      rechargeTableColumns: [ // table 列
        {
          label: '充值时间',
          prop: 'StartTimeFrom',
          type: 'text-item',
          width: 180,
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '客户关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: 180,
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '充值金额',
          prop: 'MoneyCount',
          width: 120,
          align: 'left',
          sortable: 'custom',
          type: 'amount-item'
        },
        {
          label: '收支账户',
          prop: 'ReceiveKeyValue',
          width: 120,
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '操作人',
          prop: 'AgentKeyValue',
          width: 100,
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '流水号',
          prop: 'WaterName',
          width: 'auto',
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        }

      ],
      cashOutTableColumns: [ // table 列
        {
          label: '提现时间',
          prop: 'StartTimeFrom',
          width: 180,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '客户关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '提现金额',
          prop: 'MoneyCount',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'amount-item'
        },
        {
          label: '储值余额',
          prop: 'RemainAmount',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'amount-item'
        },
        {
          label: '操作人',
          prop: 'AgentKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '流水号',
          prop: 'WaterName',
          width: 'auto',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        }

      ],
      cashBackTableColumns: [ // table 列

        {
          label: '退款时间',
          prop: 'ApplyTime',
          width: '',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '客户关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '退款金额',
          prop: 'ActualBackAmount',
          width: '',
          sortable: 'custom',
          align: 'left',
          type: 'amount-item'
        },
        {
          label: '退款方式',
          prop: 'BackCourseTypeKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '操作人',
          prop: 'CreatorKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '流水号',
          prop: 'NumberName',
          width: 'auto',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        }

      ],
      applyGradeList: [], // 报名缴费列表数据
      repayGradeList: [], // 客户还款数据
      rechargeGradeList: [], // 充值金额数据
      cashOutGradeList: [], // 提现金额数据
      cashBackGradeList: [], // 课时退款数据
      totalNum: 0,
      PageCount: 0,

      hiddenNumber: 0,
      isShowDebtAmountDetails: false, // 是否显示客户还款
      isShowCashWithdrawal: false // 是否显示提现
    };
  },
  components: {
    applyStatisticsItem,
    applyAmountItem,
    applyDayStatisticsReport,
    applyMonthStatisticsReport,
    list,
    debtAmountDetail,
    cashWithdrawal,
    classFile,
    dialogFactory
  },
  watch: {

  },
  created () {
    this.getBriefKDSYStatistics();
    this.loadTableData(this.hiddenParams);
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'applyGrade') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    // 获取开单收银汇总
    getBriefKDSYStatistics () {
      getKDSYStatistics().then(result => {
        result.data.Summary_KDSY_Today = result.data.Summary_KDSY_Today || {};
        result.data.Summary_KDSY_Yesterday = result.data.Summary_KDSY_Yesterday || {};
        result.data.Summary_DebtAmount = result.data.Summary_DebtAmount || {};
        result.data.Summary_KDSY_ThisMonth = result.data.Summary_KDSY_ThisMonth || {};
        result.data.Summary_Repay = result.data.Summary_Repay || {};
        result.data.Summary_Recharge = result.data.Summary_Recharge || {};
        result.data.Summary_CashOut = result.data.Summary_CashOut || {};
        result.data.Summary_CashBack = result.data.Summary_CashBack || {};
        this.KDSYDatail = result.data;
      }, r => {
        console.log(r);
      });
    },
    // 显示报名收款数据列表
    showDetail (type, startTime, endTime) {
      let initSelectedParams = [];
      if (startTime && endTime) {
        if (startTime.split('/').length > 1) {
          startTime = startTime.split('/')[0] + '-' + startTime.split('/')[1] + '-' + startTime.split('/')[2];
          endTime = endTime.split('/')[0] + '-' + endTime.split('/')[1] + '-' + endTime.split('/')[2];
        }
      }
      switch (type) {
        case this.KDSYDatail.Summary_KDSY_Today:
          initSelectedParams.push({ name: 'startTime', value: this.KDSYDatail.Summary_KDSY_Today.SetUpTime }, { name: 'endTime', value: this.KDSYDatail.Summary_KDSY_Today.SetUpTime });
          break;
        case this.KDSYDatail.Summary_KDSY_Yesterday:
          initSelectedParams.push({ name: 'startTime', value: this.KDSYDatail.Summary_KDSY_Yesterday.SetUpTime }, { name: 'endTime', value: this.KDSYDatail.Summary_KDSY_Yesterday.SetUpTime });
          break;
        case this.KDSYDatail.Summary_DebtAmount:
          initSelectedParams.push({ name: 'BillTypeKey', value: 2 });
          break;
        case this.KDSYDatail.Summary_KDSY_ThisMonth:
          let dateMonth = this.KDSYDatail.Summary_KDSY_ThisMonth.SetUpTime.split('-');
          initSelectedParams.push({ name: 'startTime', value: this.KDSYDatail.Summary_KDSY_ThisMonth.SetUpTime }, { name: 'endTime', value: this.$utils.getDataMonthLast(Date.new(dateMonth[0], dateMonth[1], 0)) });
          break;
        case '报名缴费日报':
          initSelectedParams.push({ name: 'startTime', value: startTime }, { name: 'endTime', value: endTime });
          break;
        case '报名缴费月报':
          initSelectedParams.push({ name: 'startTime', value: startTime }, { name: 'endTime', value: endTime });
          break;
        default:
          break;
      }
      this.isShowList = true;
      this.$nextTick(() => {
        this.$refs.applyGrade.isShowListDialog = true;
        this.$refs.applyGrade.initSelectedParams(initSelectedParams);
      });
    },
    // 显示数据列表
    showNumerousDetail (type) {
      let initSelectedParams = [];
      let start = this.$utils.getCurrentMonthFirst();
      let end = this.$utils.getCurrentMonthLast();
      switch (type) {
        case this.KDSYDatail.Summary_Repay:
          initSelectedParams.push({ name: 'startTime', value: start }, { name: 'endTime', value: end });
          this.$refs.repayGrade.isShowListDialog = true;
          this.$refs.repayGrade.initSelectedParams(initSelectedParams);
          break;
        case this.KDSYDatail.Summary_Recharge:
          initSelectedParams.push({ name: 'startTime', value: start }, { name: 'endTime', value: end });
          this.$refs.rechargeGrade.isShowListDialog = true;
          this.$refs.rechargeGrade.initSelectedParams(initSelectedParams);
          break;
        case this.KDSYDatail.Summary_CashOut:
          initSelectedParams.push({ name: 'startTime', value: start }, { name: 'endTime', value: end });
          this.$refs.cashOutGrade.isShowListDialog = true;
          this.$refs.cashOutGrade.initSelectedParams(initSelectedParams);
          break;
        case this.KDSYDatail.Summary_CashBack:
          initSelectedParams.push({ name: 'startTime', value: start }, { name: 'endTime', value: end });
          this.$refs.cashBackGrade.isShowListDialog = true;
          this.$refs.cashBackGrade.initSelectedParams(initSelectedParams);
          break;
        default:
          break;
      }
    },
    // 加载数据列表
    loadTableData (queryParams) {
      if (this.$refs.applyGrade) {
        this.$refs.applyGrade.isLoading = true;
      }
      if (queryParams.IsHiddenKey == 1) {
        this.tableColumns[this.tableColumns.length - 1].extend.tableBtnGroup[0].name = '恢复';
      } else {
        this.tableColumns[this.tableColumns.length - 1].extend.tableBtnGroup[0].name = '隐藏';
      }
      // IsHiddenKey
      MessageSearchPageForKDSY(queryParams).then(
        result => {
          if (queryParams.IsHiddenKey == 1 && queryParams.BillType == 0 && queryParams.startTime == '') {
            this.hiddenNumber = result.data.Total;
          } else {
            this.applyGradeList = [];
            if (result.data.PageDataList.length > 0) {
              result.data.PageDataList.forEach((obj, index) => {
                obj.CustomerPhoneName = this.$utils.phoneModulePower(obj.CustomerPhoneName) || '无手机号';
                obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + obj.CustomerPhoneName;
                obj.ActualColAmount = Number(obj.ActualColAmount);
                this.applyGradeList.push(obj);
              });
            }
            this.totalNum = result.data.Total;
            this.PageCount = result.data.PageCount;
            this.$refs.applyGrade.isLoading = false;
          }
        });
    },

    repayLoadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'AuditorTime';
        queryParams.sequence = 'DESC';
      }
      queryParams.stutasKey = 3;
      MessageSearchPageForCustomerRepay(queryParams).then(
        result => {
          this.repayGradeList = [];
          console.log('result.data.PageDataList', result.data.PageDataList);
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((obj, index) => {
              obj.MobileSupport = this.$utils.phoneModulePower(obj.MobileSupport) || '无手机号';
              obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + obj.MobileSupport;
              obj.DebtAmount = Number(obj.DebtAmount);
              obj.RefundAmount = Number(obj.RefundAmount);
              this.repayGradeList.push(obj);
            });
          }
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.$refs.repayGrade.isLoading = false;
        }

      );
    },
    rechargeLoadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'SetTime';
        queryParams.sequence = 'DESC';
      }
      queryParams.typeKey = '2';
      queryParams.stutasKey = '3';
      MessageSearchPageForStoreValue(queryParams).then(
        result => {
          this.rechargeGradeList = [];
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((obj, index) => {
              obj.MobileSupport = this.$utils.phoneModulePower(obj.MobileSupport) || '无手机号';
              obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + obj.MobileSupport;
              obj.MoneyCount = Number(obj.MoneyCount);
              this.rechargeGradeList.push(obj);
            });
          }
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.$refs.rechargeGrade.isLoading = false;
        }
      );
    },
    cashOutLoadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'SetTime';
        queryParams.sequence = 'DESC';
      }
      queryParams.stutasKey = 4;
      MessageSearchPageForWithdrawCash(queryParams).then(
        result => {
          this.cashOutGradeList = [];
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((obj, index) => {
              obj.MobileSupport = this.$utils.phoneModulePower(obj.MobileSupport) || '无手机号';
              obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + obj.MobileSupport;
              obj.MoneyCount = Number(obj.MoneyCount);
              obj.RemainAmount = Number(obj.RemainAmount);
              this.cashOutGradeList.push(obj);
            });
          }
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.$refs.cashOutGrade.isLoading = false;
        }
      );
    },
    cashBackLoadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'CreatTime';
        queryParams.sequence = 'DESC';
      }
      queryParams.stutasKey = '3';
      queryParams.typeKey = '0';
      MessageSearchPageForBackCourse(queryParams).then(
        result => {
          this.cashBackGradeList = [];
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((obj, index) => {
              obj.MobileSupport = this.$utils.phoneModulePower(obj.MobileSupport) || '无手机号';
              obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + obj.MobileSupport;
              obj.ActualBackAmount = Number(obj.ActualBackAmount);
              this.cashBackGradeList.push(obj);
            });
          }
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.$refs.cashBackGrade.isLoading = false;
        }
      );
    },

    // 打开新增报名收款
    doAfterFunctionBtnClick (eventName) {
      let queryParams = this.$refs.applyGrade.queryParams;
      let item = {
        routertitle: '报名统计',
        routerName: 'applyGrade',
        moduleName: 'signuppayment',
        callBack: { afterSucces: () => { this.loadTableData(queryParams); } }
      };
      this.openCustomDialogByFactoryDialog(item);
    },
    // 客户还款
    repayDoAfterFunctionBtnClick () {
      this.openCustomDialogByFactoryDialog({ moduleName: 'paymentrepayment' });
    },
    // 客户还款  关闭
    closeDebtAmountDetail () {
      this.isShowDebtAmountDetails = false;
    },
    // 客户还款 成功后
    doAfterBackAmountSuccess () {
      this.closeDebtAmountDetail();
      this.repayLoadTableData(this.$refs.repayGrade.queryParams);
    },
    // 充值金额弹窗
    rechargeDoAfterFunctionBtnClick () {

    },
    cashOutDoAfterFunctionBtnClick () {
      this.openCustomDialogByFactoryDialog({ moduleName: 'paymentwithdrawal' });
    },
    // 提现  关闭
    closeCashWithdrawal () {
      this.isShowCashWithdrawal = false;
    },
    // 提现成功 后
    doAfterCashWithdrawalSuccess () {
      this.closeCashWithdrawal();
      this.cashOutLoadTableData(this.$refs.cashOutGrade.queryParams);
    },
    // 课时退款弹窗
    cashBackDoAfterFunctionBtnClick () {
      let item = {
        routertitle: '报名统计',
        routerName: 'applyGrade',
        moduleName: 'refundmoneybackcourse',
        ModulePowerKey: 52
      };
      let queryParams = this.$refs.cashBackGrade.queryParams;
      item.callBack = () => { this.cashBackLoadTableData(queryParams); };
      this.openCustomDialogByFactoryDialog(item);
    },
    // table 列表按钮点击
    doAfterTableBtnClick (rowData, eventName) {
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.hidden:
          this.informationStatusConversion(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.edit:
          this.printData(rowData);
          break;
        default:
          break;
      }
    },
    // 打印按钮
    printData (rowData) {
      top.self_action_printopen(1239, 'jfd', rowData.NumberName);
    },
    // 隐藏/恢复数据
    informationStatusConversion (rowData, callBack) {
      if (rowData.IsHiddenKey != 1) {
        let that = this;
        layer.confirm('是否将此报名收款记录放入回收站', {
          btn: [{
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          }, {
            name: '确认',
            callBack: function () {
              HideCommonModule({
                dataKey: rowData.TableID,
                dataName: 'BillCollectMoney'
              }).then(result => {
                if (result.code == 0) {
                  that.loadTableData(that.hiddenParams);
                  that.loadTableData(that.$refs.applyGrade.queryParams);
                }
                if (callBack) {
                  callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                }
              }, error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }]
        });
      }
      if (rowData.IsHiddenKey == 1) {
        let that = this;
        layer.confirm('是否将此报名收款记录还原', {
          btn: [{
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          }, {
            name: '确认',
            callBack: function () {
              ShowCommonModule({
                dataKey: rowData.TableID,
                dataName: 'BillCollectMoney'
              }).then(result => {
                if (result.code == 0) {
                  that.loadTableData(that.hiddenParams);
                  that.loadTableData(that.$refs.applyGrade.queryParams);
                }
                if (callBack) {
                  callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                }
              }, error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }]
        });
      }
    },
    // 班级档案显示
    showClassFile (item) {
      this.$refs.classFile.showClassFile = true;
      this.$refs.classFile.selectedClass(item);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.applyGradeDialogFactory) {
        this.$refs.applyGradeDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }

  }
};
</script>
