import { render, staticRenderFns } from "./attendance-arrange.vue?vue&type=template&id=056dc13b&scoped=true"
import script from "./attendance-arrange.vue?vue&type=script&lang=js"
export * from "./attendance-arrange.vue?vue&type=script&lang=js"
import style0 from "./attendance-arrange.vue?vue&type=style&index=0&id=056dc13b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056dc13b",
  null
  
)

export default component.exports