<template>
  <div class="chain_workbench_warning pr scroll_type">
    <div class="warning_title pr">
      待办事项
      <span class="renew_btn" @click.stop="renew">刷新</span>
      <!-- <div
        class="warning_btn"
        v-if="dataList.length > 0"
        @click="changesearchObj"
      ></div> -->
    </div>
    <div class="backlog_ul"
         @scroll="scrollTOP"
         v-if="dataList.length > 0">
      <item v-for="(item, key) in dataList"
            :key="key"
            :keyIndex="key"
            :item="item"
            @seletedItem="seletedItemAfter"></item>
    </div>
    <div v-if="dataList.length == 0"
         class="course_block_nodata">
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <div class="module_content_menuList"
         :style="{ top: topValue + 'px', left: '68.5%' }"
         v-show="isShowdropDownBox"
         @mouseover.stop="isShowdropDownBox = true">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="vmenuList"
                     @clickOptionForAlertDialog="clickAfter"></drop-down-box>
    </div>
    <custom-dialog :title="'待审核'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowToAuditedSelectList"
                   :before-close="hiddenToAuditedSelectList">
      <home-incomeAndexpenses-form :item="selecttoAuditedInfo"
                                   @passHide="doAfterPassHide"></home-incomeAndexpenses-form>
    </custom-dialog>
    <custom-dialog :title="'拒绝原因'"
                   width="410px"
                   :visible.sync="isShowMessagePopup"
                   :before-close="hideMessagePopup">
      <remerk-popup :placeholder="'请输入拒绝原因'"
                    @onClickClose="hideMessagePopup"
                    @onClickConfirm="rejectRecommend"></remerk-popup>
    </custom-dialog>
    <custom-dialog :title="'查看'"
                   :visible.sync="isShowParticipateCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <retail-campus-edit-form :CampusKey="Number(CampusKey)"
                               :isReadonly="true"
                               :isCheck="true"
                               @close="closeParticipateCampusFormPopup"></retail-campus-edit-form>
    </custom-dialog>

  </div>
</template>
<script>
import {
  SearchPageForToDo,
  CoursewareAuditPass,
  hqAuditSZGL,
  CoursewareRefuse,
  HQContractReview,
  HQRefuseContractReview,
  HQRefusePaymentReview,
  GetSZGLDetails,
  RefuseSaaSClientAddressLog,
  AuditSaaSClientAddressLog
} from '../../../API/workbench';
import retailCampusEditForm from '../../head-quarters/headquarters-management/campus-management/form/retail-campus-edit-form';
import remerkPopup from './remerk-popup';
import homeIncomeAndexpensesForm from '../../head-quarters/basis-setting-module/hq-income-pay-manage/home-incomeAndexpenses-form';
import dropDownBox from '../../common/drop-down-box';
import item from './item';
export default {
  data () {
    return {
      searchObj: {
        PageSize: 10,
        PageIndex: 0
      },
      dataList: [],
      seletdItem: null,
      showAll: true,
      isShowMessagePopup: false,
      menuList: [
        {
          name: '查看详情',
          type: 'detail',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '审核通过',
          type: 'pass',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '退回',
          type: 'reback',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      menuTypeList: [
        {
          name: '通过',
          type: 'pass',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '拒绝',
          type: 'reback',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      authMenuList: [{
        name: '查看',
        type: 'check',
        isShow: true,
        ModulePowerKey: 0
      }, {
          name: '通过',
          type: 'locationChangePass',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '拒绝',
          type: 'locationChangeReback',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      screenMenuList: [{
        name: '查看',
        type: 'screencheck',
        isShow: true,
        ModulePowerKey: 0
      }
      ],
      isShowdropDownBox: false,
      newIndex: -1,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      isShowToAuditedSelectList: false,
      selecttoAuditedInfo: null,
      isShowParticipateCampusFormPopup: false,
      isShowScreenFormPopup:false,   //防录频列表
      CampusKey: 0,
        isLoadEnd: false, // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      noMore: false,

    };
  },
  components: {
    item,
    dropDownBox,
    homeIncomeAndexpensesForm,
    remerkPopup,
    retailCampusEditForm
  },
  props: {},
  created () {
    this.getDataList();
  },
  computed: {
    vmenuList () {
      if (this.seletdItem && this.seletdItem.TypeKey == 5) {
        // if(this.$utils.getSaaSClientSetInfo(87).SetKey==0){
          this.authMenuList[0].isShow=true;
          this.authMenuList[1].isShow=false;
          this.authMenuList[2].isShow=false;
        // }else{
        //   this.authMenuList[0].isShow=false;
        //   this.authMenuList[1].isShow=true;
        //   this.authMenuList[2].isShow=true;
        // }
        return this.authMenuList;
      }else if(this.seletdItem && this.seletdItem.TypeKey == 6){
        this.authMenuList[0].isShow=false;
          this.authMenuList[1].isShow=true;
          this.authMenuList[2].isShow=true;
           return this.authMenuList;
      }else if(this.seletdItem && this.seletdItem.TypeKey == 7){
           return this.screenMenuList;
      }
      if (this.seletdItem && this.seletdItem.TypeKey < 3) {
        return this.menuList;
      } else {
        if (this.seletdItem && this.seletdItem.TypeKey == 3) {
          this.menuTypeList.forEach(o => {
            o.ModulePowerKey = 134;
          });
        } else {
          this.menuTypeList.forEach(o => {
            o.ModulePowerKey = 133;
          });
        }
        return this.menuTypeList;
      }
    }
  },
  mounted () {
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowdropDownBox = false;
      },
      false
    );
  },
  methods: {
    renew(){
      this.getDataList();
    },
    hideMessagePopup () {
      this.isShowMessagePopup = false;
    },
    hiddenToAuditedSelectList () {
      this.isShowToAuditedSelectList = false;
    },
    doAfterPassHide () {
      console.log('待审个');
      this.isShowToAuditedSelectList = false;
      this.getDataList();
    },
    changesearchObj () {
      this.searchObj.PageSize = 99;
      this.getDataList();
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowdropDownBox = false;
      }
      let ETarget = e.target;
       console.log('scrollTOP',ETarget.scrollTop);
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      console.log(ETarget.scrollTop, 'scrollTOP', HeightView,this.noMore,this.isLoadEnd);
      if (parseInt(ETarget.scrollTop)+20>= parseInt(HeightView) && !this.noMore&&!this.isLoadEnd) {
        console.log("scrollTOP22222")
        this.scrollGetDataList(this.searchObj);
      }
     
    },
    scrollGetDataList(){
        this.isLoadEnd=true
        SearchPageForToDo(this.searchObj)
        .then((result) => {
          // this.dataList = result.data.PageDataList;
          result.data.PageDataList.forEach(o => {
            this.dataList.push(o);
          });
          console.log('SearchPageForToDo', result.data);
           if (result.data.PageDataList.length == this.searchObj.PageSize) {
            this.searchObj.PageIndex++;
          } else {
            this.noMore = true;
          }
          this.isLoadEnd = false;
        })
        .catch((error) => {
          console.log(error, 'errorSearchPageForToDo');
        });
    },
    getDataList () {
          this.isLoadEnd = true;
           this.searchObj.PageIndex=0
           this.dataList=[]
      SearchPageForToDo(this.searchObj)
        .then((result) => {
          this.dataList = result.data.PageDataList;
          // result.data.PageDataList.forEach(o => {
          //   this.dataList.push(o);
          // });
          
          console.log('SearchPageForToDo', result.data.PageDataList,this.searchObj.PageSize);
           if (result.data.PageDataList.length == this.searchObj.PageSize) {
            this.searchObj.PageIndex++;
            this.noMore = false
          } else {
            this.noMore = true;
          }
          this.isLoadEnd = false;
          console.log(' this.isLoadEnd', this.isLoadEnd, this.noMore)
        })
        .catch((error) => {
          console.log(error, 'errorSearchPageForToDo');
        });
    },
    seletedItemAfter (item, key) {
      this.dataList.forEach((o) => {
        if (o.OLAPKey == item.OLAPKey) {
          o.isShow = true;
        } else {
          o.isShow = false;
        }
      });
      this.seletdItem = item;
      this.isShowdropDownBox = true;
      this.doOneTopValueCount(key);
      console.log('seletedItemAfter', this.seletdItem);
    },
    clickAfter (item) {
      this.dataList.forEach((o) => {
        o.isShow = false;
      });
      this.isShowdropDownBox = false;
      switch (item.type) {
        case 'detail':
          this.showDetail(this.seletdItem);
          break;
        case 'pass':
          this.passItem(this.seletdItem);
          break;
        case 'reback':
          this.refuseItem(this.seletdItem);
          break;
        case 'check':
          this.checkItem(this.seletdItem);
          break;
        case 'screencheck':
          // this.isShowScreenFormPopup=true
          this.$dialog.open(this, {
            name: '截屏录屏通知', // 新增传付款申请， 审核传付款审核
            routerName: this.$route.name,
            moduleName: 'screenRecordList',
            dialogWidth: "1360px",
            data: {
              dataInfo:this.seletdItem
            }, // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
            callBack: {
              afterSuccess: () => {
                console.log("screenRecordListAfter")
                // this.$emit('updateData');
                this.getDataList();
              },
              
            }
          });
          setTimeout(o=>{
            console.log("screenRecordListAfter2222")
            // this.$emit('updateData');
                this.getDataList();
          },1500)
          console.log("防录频")
          break;
        case 'locationChangePass':
           layer.confirm('请确定是否审核通过?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                  AuditSaaSClientAddressLog(this.seletdItem.ID).then(result=>{
                     this.$emit('updateData');
                      this.getDataList();
                  }).catch(error=>{
                    layer.alert(error.msg)
                  });
                }
              }
            ]
          });
         
          break;
        case 'locationChangeReback':
          layer.confirm('请确定是否审核拒绝?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                   RefuseSaaSClientAddressLog(this.seletdItem.ID).then(result=>{
                     this.$emit('updateData');
                      this.getDataList();
                  }).catch(error=>{
                    layer.alert(error.msg)
                  });
               
                }
              }
            ]
          });
         
          break;
        default:
          break;
      }
    },
    // 查看详情
    showDetail () {
      console.log('showDetail');
      if (this.seletdItem.TypeKey == 2) {
        // 收支审核
        GetSZGLDetails(this.seletdItem.ID).then((result) => {
          this.selecttoAuditedInfo = result.data[0];
          this.$nextTick((o) => {
            this.isShowToAuditedSelectList = true;
          });
        });
      } else {
        // 课件
        this.goRouter(this.$initJson.chainWorkBenchBtn.hqCoursewareManagement);
      }
    },
    goRouter (item) {
      let isNew=this.$store.getters.headerBarList.findIndex(o=>{
        return o.routerName==item.routerName
      })
      // dataKey
      item.data={
        levelPath:this.seletdItem.levelPath,
         Title:this.seletdItem.Title,
           isNew:isNew==-1
      }
      item.dataKey = this.seletdItem.ID;
      console.log(item, 'goRouter');
      this.$bus.emit('needOpenRouter', item);
    },
    passItem () {
      if (this.seletdItem.TypeKey == 2) {
        // 收支审核
        layer.confirm('请确定是否审核通过?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                hqAuditSZGL(this.seletdItem.ID, 1).then(
                  (result) => {
                    this.$emit('updateData');
                    this.getDataList();
                  },
                  (error) => {
                    layer.alert(error.msg);
                  }
                );
              }
            }
          ]
        });
      } else if (this.seletdItem.TypeKey == 1) {
        // 课件
        CoursewareAuditPass(this.seletdItem.ID)
          .then((result) => {
            console.log('AuditPass', this.seletdItem);
            this.$emit('updateData');
            this.getDataList();
          })
          .catch((error) => {
            console.log('errorAuditPass', error);
          });
      } else if (this.seletdItem.TypeKey == 3) {
        layer.confirm('请确定是否审核通过?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                this.resultRecommend(this.seletdItem);
              }
            }
          ]
        });
      } else {
        // 合同付款通过。
        this.$dialog.open(this, {
          name: '付款审核', // 新增传付款申请， 审核传付款审核
          routerName: this.$route.name,
          moduleName: 'crmPaymentForm',
          data: {
            OLAPKey: Number(this.seletdItem.ID),
            isReadonly: false,
            paymentType: 'other'
          }, // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
          callBack: {
            afterSuccess: () => {
              this.$emit('updateData');
              this.getDataList();
            }
          }
        });
      }
    },
    refuseItem () {
      if (this.seletdItem.TypeKey == 2) {
        // 收支审核
        layer.confirm('请确定是否审核拒绝?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                hqAuditSZGL(this.seletdItem.ID, 0).then(
                  (result) => {
                    this.$emit('updateData');
                    this.getDataList();
                  },
                  (error) => {
                    layer.alert(error.msg);
                  }
                );
              }
            }
          ]
        });
      } else if (this.seletdItem.TypeKey == 1) {
        this.isShowMessagePopup = true;
      } else if (this.seletdItem.TypeKey == 3) {
        this.isShowMessagePopup = true;
      } else {
        this.isShowMessagePopup = true;
      }
    },
    resultRecommend () {
      HQContractReview(this.seletdItem.ID).then(
        (res) => {
          this.$emit('updateData');
          this.getDataList();
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    rejectRecommend (text) {
      this.isShowMessagePopup = false;
      if (this.seletdItem.TypeKey == 1) {
        // 课件
        CoursewareRefuse(this.seletdItem.ID, text)
          .then((result) => {
            console.log('AuditPass', this.seletdItem);
            this.$emit('updateData');
            this.getDataList();
          })
          .catch((error) => {
            console.log('errorAuditPass', error);
          });
      } else if (this.seletdItem.TypeKey == 3) {
        HQRefuseContractReview(this.seletdItem.ID, text).then(
          (res) => {
            this.$emit('updateData');
            this.getDataList();
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      } else {
        HQRefusePaymentReview(this.seletdItem.ID, text).then(
          (res) => {
            this.$emit('updateData');
            this.getDataList();
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    checkItem (item) {
      console.log('item', item);
      this.CampusKey = item.ID;
      this.isShowParticipateCampusFormPopup = true;
    },
    closeParticipateCampusFormPopup () {
      this.isShowParticipateCampusFormPopup = false;
      this.isShowScreenFormPopup=false
      this.getDataList();
    },
    // 计算TOP值
    doOneTopValueCount (index) {
      this.newIndex = index;
      this.topValue = 0;
      let oneTopValue = 54;
      console.log(this.toScrollTop, 'doOneTopValueCount');
      if (this.toScrollTop > 0) {
        this.topValue =
          oneTopValue * this.newIndex -
          this.toScrollTop +
          this.topValue +
          64 +
          20;
      } else {
        this.topValue = this.topValue + oneTopValue * this.newIndex + 64 + 20;
      }
    }
  }
};
</script>

