<template>
  <!-- 选项 -->
  <div :class="{ 'form_info_required': required }">
    <span class="font_gray" v-if="formTitle">{{formTitle}}</span>
    <div class="form_first_block_ul">
      <div class="form_first_block_list"
           :class="{'opt':item.isOpt}"
           v-for="(item,index) in optionList"
           :key="index"
           @click="doClick(item)">
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    optionList: Array,
    required: Boolean // 是否必填，可选，如不提供
  },
  data () {
    return {

    };
  },
  computed: {

  },
  created () {
    // this.initData();
  },
  methods: {
    doClick (item) {
      this.optionList.forEach(o => {
        if (o.id == item.id) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.$emit('doClick', item);
    }
  }
};
</script>

<style>
.form_first_block_ul {
  display: flex;
  border-radius: 4px;
  height: 35px;
  width: 100%;
  cursor: pointer;
  margin: 15px 0 15px 0;
}
.form_first_block_list {
  font-size: 14px;
  line-height: 35px;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  color: #666;
  background: #f4f4f4;
  margin: 0px 4px;
}
.form_first_block_list.opt {
  background-color: #3498db;
  color: #fff;
}
</style>