<template>
  <div class=" statistics_echarts_right fl ">
    <div class="apply_payment_box_title"
         v-if="!type">
      {{vFormTitle}} <span>{{vYear}}</span>
    </div>
    <div class="apply_payment_box_title"
         v-else>
      {{vFormTitle}} <span>{{vYear}}</span>
    </div>
    <div class="box_dot dot_green">
      {{formTitle}}
    </div>
    <div class="apply_payment_box_number">
      <span>￥</span>{{nowTotalRecableAmount}}
    </div>
    <div class="box_contrast"
         v-if="nowTotalRecableAmount-lastYearTotalRecableAmount<0">
      <img class="fl "
           src="../../../../public/image/contrast_down.png"
           alt=" ">
      <div class="box_contrast_data fl ">
        <div class="data_number"><span v-if="increaseRate=='--'?false:true">-</span>{{increaseRate}}</div>
        <div>环比下降</div>
      </div>
    </div>
    <div class="box_contrast "
         v-else>
      <img class="fl "
           src="../../../../public/image/contrast_up.png"
           alt=" ">
      <div class="box_contrast_data fl ">
        <div class="data_number">{{increaseRate}}</div>
        <div>环比增长</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    nowDataList: Array,
    oldDataList: Array,
    seletedDate: Date,
    type: String
  },
  computed: {
    vYear () {
      if (this.type) {
        return this.seletedDate.getFullYear() + '年';
      } else {
        return this.seletedDate.getFullYear() + '年' + (this.seletedDate.getMonth() + 1) + '月';
      }
    },
    // 选中月的报表总数
    nowTotalRecableAmount () {
      let RecableAmount = 0;
      this.nowDataList.forEach(o => {
        RecableAmount += Number(o.totalDeductAmount);
      });
      return (Math.round(RecableAmount * 100) / 100 || 0).toFixed(2);
    },
    // 上一个月的报表总数
    lastYearTotalRecableAmount () {
      let RecableAmount = 0;
      this.oldDataList.forEach(o => {
        RecableAmount += Number(o.totalDeductAmount);
      });
      return Math.round(RecableAmount * 100) / 100 || 0;
    },
    // 环比增长率=（本期的某个指标的值-上一期这个指标的值）/上一期这个指标的值*100%。
    increaseRate () {
      if (this.lastYearTotalRecableAmount == 0 && this.nowTotalRecableAmount > 0) {
        return '100%';
      } else if (this.lastYearTotalRecableAmount >= 0 && this.nowTotalRecableAmount == 0) {
        return '---';
      } else {
        return parseInt(Math.abs((this.nowTotalRecableAmount - this.lastYearTotalRecableAmount) / this.lastYearTotalRecableAmount) * 100) + '%';
      }
    },
    // 判断显示本年本月.
    vFormTitle () {
      if (this.type) {
        if (this.seletedDate.getFullYear() == Date.new().getFullYear()) { return '本年'; }
      } else {
        if (this.seletedDate.getFullYear() == Date.new().getFullYear() && this.seletedDate.getMonth() == Date.new().getMonth()) { return '本月'; }
      }
    }
  }
};
</script>
