<template>
  <div class="attendance_arrange_statistics">
    <div class="attendance_arrange_statistics_title">
      <span>课后统计</span>
      <!-- <span class="font_gray">请假、旷课的学生课时不计入老师业绩</span> -->
    </div>
    <div class="attendance_arrange_statistics_content button" @click="routerGo">
      <div class="statistics_content_dual">
        <div class="statistics_content_text">
          签到 <span class="font_black">{{vSignInStudents.length}}</span> 人
        </div>
        <div class="statistics_content_text">
          学生课时 <span class="font_black">{{Number(courseInfo.Total_ClassCount)}}</span> 课时
        </div>
      </div>
      <div class="statistics_content_dual">
        <div class="statistics_content_text"> 
          请假 <span class="font_black">{{vLeaveStudents.length}}</span> 人 <span v-if="this.$utils.getSaaSClientSet(59)>0">丨旷课 <span class="font_black">{{vAbsenteeismStudents.length}}</span> 人</span>
        </div>
        <div class="statistics_content_text button"></div>
        <!-- <function-btn :item="attenbtn"
                      
                      :className="'statistics_content_text  pr'">
          课消金额
          <span class="font_black">￥{{courseInfo.DeductAmount}}</span>
        </function-btn> -->
      </div>
    </div>
    <!-- <div class="gray_bg"></div>
    <div class="student_check_type">
      <div class="cut-class">
        <span class="students_check_title">
          签到
          <span class="font_black">{{vSignInStudents.length}}</span> 人
        </span>
        <div class="check_list">
          <span class
                v-for="(studentData,index) in vSignInStudents"
                :key="index">
            {{studentData.StudentKeyValue}}
            <span v-if="index+1 != vSignInStudents.length">,</span>
          </span>
        </div>
      </div>
      <div class="sign_in">
        <span class="students_check_title">
          请假
          <span class="font_black">{{vLeaveStudents.length}}</span> 人
        </span>
        <div class="check_list">
          <span class
                v-for="(studentData,index) in vLeaveStudents"
                :key="index">
            {{studentData.StudentKeyValue}}
            <span v-if="index+1 != vLeaveStudents.length">,</span>
          </span>
        </div>
      </div>
      <div class="leave-early">
        <span class="students_check_title">
          旷课
          <span class="font_black">{{vAbsenteeismStudents.length}}</span> 人
        </span>
        <div class="check_list">
          <span class
                v-for="(studentData,index) in vAbsenteeismStudents"
                :key="index">
            {{studentData.StudentKeyValue}}
            <span v-if="index+1 != vAbsenteeismStudents.length">,</span>
          </span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      attenbtn: {
        name: '',
        ModulePowerKey: 86
      }
    };
  },
  props: {
    studentList: Array,
    courseInfo: Object
  },
  computed: {
    vSignInStudents () {
      return this.studentList.filter(obj => { return obj.AttenceStatusKey == 3; });
    },
    vLeaveStudents () {
      return this.studentList.filter(obj => { return obj.AttenceStatusKey == 4; });
    },
    vAbsenteeismStudents () {
      return this.studentList.filter(obj => { return obj.AttenceStatusKey == 5; });
    }
  },
  methods: {
    routerGo (type) {
      console.log(this.courseInfo, 'routerGo');
      this.$router.push({ name: 'classSpendStatisticsAnalyze',
        query: {
          type: 1,
          GradeKey: this.courseInfo.GradeClassKey,
          GradeKeyValue: this.courseInfo.GradeClassKeyValue,
          TeacherKey: this.courseInfo.MTeacherKey,
          TeacherKeyValue: this.courseInfo.MTeacherKeyValue,
          CourseKey: this.courseInfo.CourseNameKey,
          CourseKeyValue: this.courseInfo.CourseNameKeyValue,
          StudentNameKey: 0,
          StudentNameKeyValue: '',
          startTime: this.courseInfo.ClassTime,
          endTime: this.courseInfo.ClassTime } });
    }
  }
};
</script>

<style>
</style>