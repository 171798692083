<template>
  <!-- 班级分类名称 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <input-text :formTitle="'班级分类名称'"
                    :maxLength="14"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataJson.GradeTypeName"></input-text>

      </div>
              <!-- 按钮组 -->
        <div v-if="!isReadonly">
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
        </div>
    </div>
  </div>
</template>

<script>
import { AddGradeType, EditGradeType } from '../../../../API/workbench';
export default {
  data () {
    return {
      coursList: [], // 班级分类列表
      // 提交数据对象
      dataJson: {
        OLAPKey: '',
        GradeTypeName: '' // -- 名称
      }
    };
  },
  props: {
    editItem: {
      // 修改项
      type: [Object, String],
      default: function () {
        return {};
      }
    },
    isReadonly: Boolean// 是否只读
  },
  created () {
    if (this.editItem.OLAPKey) {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.GradeTypeName = this.editItem.MainDemoName;
    }
  },
  computed: {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (this.dataJson.GradeTypeName) {
        this.dataJson.GradeTypeName = this.dataJson.GradeTypeName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.dataJson.GradeTypeName) {
        layer.alert('请输入名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.dataJson.OLAPKey) {
        this.editOperation(callBack);
      } else {
        this.addOperation(callBack);
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 新增课名
    addOperation (callBack) {
      AddGradeType(this.dataJson.GradeTypeName).then(result => {
        console.log(result, '班级分类新增');
        layer.alert('新增成功');
        this.$emit('afterSuccess', 'Add', result.data);
        this.$bus.emit('AfterClassGradeAddOrEdit', 'Add', result.data);
        this.cancelClick();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 班级类型
    editOperation (callBack) {
      delete this.dataJson.TypeKey;
      EditGradeType(this.dataJson.OLAPKey, this.dataJson.GradeTypeName).then(result => {
        console.log(result, '班级分类修改');
        layer.alert('修改成功');
        this.$emit('afterSuccess', 'Edit', result.data);
        this.$bus.emit('AfterClassGradeAddOrEdit', 'Add', result.data);
        this.cancelClick();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    }
  }
};
</script>

<style>
</style>
