<template>
  <!-- 老师课消详情页面 -->
  <div style="height:100%">
    <custom-dialog title="老师上课课消详情"
                   class="new_popup_box student_info_dialog defult_dialog"
                   :visible.sync="isShowModule"
                   :before-close="closeModule">
      <div style="background-color: #fff">
        <div class="new_table_title_info">
          <div class="info_top">
            <span class="info_top_name">{{teacherItem.TeacherKeyValue}}</span>
            <span>{{teacherItem.CourseTypeKeyValue}}</span>
            <span class="fr">{{vData[0] + '年' + vData[1] + '月'}}</span>
          </div>
          <div class="info_bottom">
            <div class="">
              <span>上课节数:</span>
              <span class="font_number">{{$utils.newAmountType(teacherItem.CourseCount||0,1)}}</span>
            </div>
            <div class="">
              <span>签到人次:</span>
              <span class="font_number font_green">{{$utils.newAmountType(teacherItem.SignCount||0,0)}}</span>
            </div>
            <div class="">
              <span>消耗课时:</span>
              <span class="font_number font_blue">{{$utils.newAmountType(teacherItem.StudentHourCount||0,1)}}</span>
            </div>
          </div>
        </div>
        <div class="new_table_list_box">
          <div class="new_table_list_thead">
            <div style="width: 120px"
                 class="new_table_list_th style_gray tl">上课时间</div>
            <div style="width: 90px;"
                 class="new_table_list_th style_yellow tc">签到人次</div>
            <div style="width: 90px;"
                 class="new_table_list_th style_red tc">试课人数</div>
            <div style="width: 90px;"
                 class="new_table_list_th style_green tc">课消数</div>
            <div style="width:90px"
                 class="new_table_list_th style_blue tl">出勤率</div>
          </div>
          <div class="new_table_list_tbody"
               style="max-height: 600px;overflow-y: auto;cursor: pointer">
            <div class="new_table_list_tr"
                 v-for="(item,index) in teacherCourseDetailsList"
                 :key="index"
                 @click="doAttendanceDetailsClick(item)">
              <div style="width: 120px"
                   class="new_table_list_td tl">
                <div class="font_name">
                  <span>{{mdate(item.ClassTime)}}</span>
                </div>
              </div>
              <div style="width: 90px"
                   class="new_table_list_td tc">
                <span class="">{{$utils.newAmountType(item.SignCount,0)}}</span>
              </div>
              <!-- 试课人数 -->
              <div style="width: 90px"
                   class="new_table_list_td tc">
                <span class="">{{$utils.newAmountType(item.TrialCount,0)}}</span>
              </div>
              <div style="width:90px"
                   class="new_table_list_td tc">
                <span class="">{{$utils.newAmountType(item.StudentHourCount,1)}}</span>
              </div>
              <div style="width: 90px"
                   class="new_table_list_td tc">
                <div style="padding-right: 25px">
                  <span class="font_blue">{{mattendanceRate(item)}}</span>
                </div>
              </div>
              <div class="new_table_list_arrow"
                   style="right: 5px"></div>
            </div>
            <div v-if="teacherCourseDetailsList.length==0">
              <div class="monitoring_content_nodata">
                <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-dialog>
    <!-- 安排考勤 -->
    <!-- <div>
      <attendance-arrange ref="arrangeDialog"
                          :formTitle="'安排考勤'"
                          @updateStudentFileEvent="updateStudentFileEvent"></attendance-arrange>
    </div> -->

  </div>
</template> 

<script>
import attendanceArrange from '../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import { GetTeachingPerformanceDetails } from '../../../API/workbench';
export default {
  data () {
    return {
      parameter: {
        startTime: '',
        endTime: '',
        TecherId: 0,
        CourseId: 0
      },
      teacherItem: {}, // 老师上课项
      teacherCourseDetailsList: [], // 老师课消详情列表
      isShowModule: false // 控制显示表单
    };
  },
  props: [

  ],
  components: {
    attendanceArrange
  },

  created () {

  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {
    vData () {
      let arr = this.parameter.startTime.split('-');
      return arr;
    }
  },
  methods: {
    closeModule () {
      this.isShowModule = false;
    },
    // 考勤签到后刷新数据
    updateStudentFileEvent (StudentKey, ScheduleCourseKey) {
      this.loadData();
    },
    mdate (date) {
      if (date) {
        let newDate = date.slice(5, 16);
        return newDate;
      } else {
        return '-';
      }
    },
    mattendanceRate (item) {
      if (item.AttendanceRate && Number(item.AttendanceRate) > 0) {
        return this.$utils.newAmountType(item.AttendanceRate, 1) + '%';
      } else {
        return '-';
      }
    },
    // mIsShowArrangeDialog (item) {
    //   if (Number(item.SignCount) > 0 || Number(item.TrialCount) > 0 || Number(item.StudentHourCount) > 0 || Number(item.AttendanceRate) > 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.getToken.RolePowerList, ModulePowerKey);
    },
    loadData () {
      this.isShowModule = true;
      console.log(this.parameter, '日期');
      GetTeachingPerformanceDetails(this.parameter).then(result => {
        this.teacherCourseDetailsList = result.data;
        console.log(result.data, '老师课消详情列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAttendanceDetailsClick (item) {
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: item.ScheduleCourseKey,isAlter:false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    }
  }

};
</script>

  <style>
/*  */
</style>