<template>
  <!-- 基础设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <div>
          <input-text :formTitle="'科目名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'科目名称'"
                      :required="true"
                      v-model="dataJson.MainDemoName"></input-text>

          <div>
            <form-select :formTitle="'收支类型'"
                         :required="false"
                         :readonly="isReadonly ||Number(dataJson.OLAPKey)>0"
                         :dataItem="dataJson.IncomePayTypeKeyValue"
                         :dataList="SZType"
                         v-model="dataJson.IncomePayTypeKeyValue"
                         @choseItem="getValueEvent"></form-select>
          </div>

          <input-dialog :formTitle="'上级科目'"
                        :formPlaceholder="'上级科目'"
                        class="no_border_bottom"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showSingleSelectionDialog()"
                        v-model="FatherKeyValue"></input-dialog>

        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else
         class="on_form_footer_btn_group"></div>
    <custom-dialog :title="'选择上级科目'"
                   class="new_class defult_dialog"
                   :visible.sync="isChildForm"
                   :before-close="doCloseChildForm">

      <single-select-tree :sourceDate="subjectList"
                          :selectionDate="vDataJsonInfo"
                          :disabledType="2"
                          @confirmClcik="doAfterSelectedItem"
                          @cancleClcik="closeSingleSelectionList"></single-select-tree>
    </custom-dialog>
  </div>

</template> 

<script>
import formSelect from '../../../form-item/form-select';
import { AddIncomePaySubject, EditIncomePaySubject, MessageSearchPageForIncomePaySubject } from '../../../../API/workbench';
import singleSelectTree from '../../../common/single-select-tree';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    editItem: {// 修改项
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      // 数据对象
      dataJson: {
        MainDemoName: '', // -- 科目名称
        FatherKey: '', // --上级科目 0为全部
        CommentSupport: '', // -- 备注
        IncomePayTypeKey: '1', // --收支类型Key 1-收;2-支
        IncomePayTypeKeyValue: '收' // --收支类型KeyValue 1-收;2-支
      },
      FatherKeyValue: '', // 上级科目Value
      subjectList: '', // 科目列表
      // 查询数据对象
      parameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配部门名称
        StatusKey: 0, // 字符串	可选	0	数据源：GET	状态 1-启用;2-停用
        IncomePayTypeKey: 1 // 收支类型 1-收;2-支
      },
      SZType: [{ key: 1, value: '收' }, { key: 2, value: '支' }],
      isChildForm: false
    };
  },

  components: {
    singleSelectTree,
    formSelect
  },
  created () {
    if (JSON.stringify(this.editItem) != '{}') {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.MainDemoName = this.editItem.MainDemoName;
      this.dataJson.FatherKey = this.editItem.FatherKey;
      this.dataJson.CommentSupport = this.editItem.CommentSupport;
      this.dataJson.IncomePayTypeKey = this.editItem.IncomePayTypeKey;
      this.dataJson.IncomePayTypeKeyValue = this.editItem.IncomePayTypeKeyValue;
      this.FatherKeyValue = this.editItem.FatherKeyValue;
      this.parameter.IncomePayTypeKey = this.editItem.IncomePayTypeKey;
    }
    this.getInquirePort(this.parameter);
  },
  computed: {
    // 显示选择收支类型的值
    vSZType: {
      get () {
        return {
          key: this.dataJson.IncomePayTypeKey,
          value: this.dataJson.IncomePayTypeKeyValue
        };
      },
      set (newValue) {
        this.dataJson.IncomePayTypeKey = newValue.key;
        this.dataJson.IncomePayTypeKeyValue = newValue.value;
      }
    },
    vDataJsonInfo: {
      get () {
        return {
          id: this.dataJson.FatherKey,
          label: this.FatherKeyValue
        };
      },
      set (newValue) {
        this.FatherKeyValue = newValue.label;
        this.dataJson.FatherKey = newValue.id;
      }
    }
  },
  methods: {
    getInquirePort (parameter) {
      MessageSearchPageForIncomePaySubject(parameter).then(result => {
        console.log(result, '科目查询');
        this.subjectList = [];
        result.data.PageDataList.forEach(o => {
          if (Number(o.IncomePayTypeKey) == Number(this.parameter.IncomePayTypeKey) && o.StatusKey == 1) {
            this.subjectList.push(o);
          }
        });
        this.subjectList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部',
          FatherKey: '0',
          FatherKeyValue: '',
          Number: '0',
          StatusKey: '2',
          StatusKeyValue: '启用',
          RemarksSupport: ''
        });
      });
    },
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (!this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入科目名称');
        flag = true;
      } else if (!this.dataJson.FatherKey) {
        layer.alert('请选择上级科目');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (JSON.stringify(this.editItem) != '{}') {
        EditIncomePaySubject(this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          layer.alert('修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddIncomePaySubject(this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 子组件弹出层显示
    showSingleSelectionDialog () {
      this.isChildForm = true;
    },
    // 子组件弹出层取消
    doCloseChildForm () {
      this.isChildForm = false;
    },
    // 取消科目
    closeSingleSelectionList () {
      this.isChildForm = false;
    },
    // 选择科目
    doAfterSelectedItem (item) {
      this.dataJson.FatherKey = item.id;
      this.FatherKeyValue = item.label;
      this.isChildForm = false;
      console.log(item, 'doAfterSelectedItem');
    },
    // 选择收支类型
    getValueEvent (item) {
      this.dataJson.IncomePayTypeKey = item.key;
      this.dataJson.IncomePayTypeKeyValue = item.value;
      this.FatherKeyValue = '';// 选择收支时默认清空上级科目
      this.dataJson.FatherKey = '';// 选择收支时默认清空上级科目
      this.parameter.IncomePayTypeKey = item.key;
      this.getInquirePort(this.parameter);
      console.log(item);
    }
  }
};
</script>

  <style>
</style>