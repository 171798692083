<template>
  <div class="middle_content massive_bg "
       style="padding-bottom:0px">
    <div class="quick_options_content">
      <div class="flex_row"
           style="justify-content: space-between">
        <span class="quick_options_title">
          角色工作台
          <span class="quick_options_tips">
            根据您的角色选择“工作台”，一站解决所有工作。
          </span>
        </span>

        <span class="homepage_download font_blue button"
              @click="isShowDownLoadBox=true">
          下载客户端
        </span>
      </div>
      <div class="homepage_workbench flex_row">
        <div class="flex_column button homepage_workbench_item"
             v-for="(item, key) in opionsList"
             :key="key"
             :class="item.className"
             @click.stop="goRouter(item)">
          <span class="big_text">{{ item.title }}</span>
          <span>工作台</span>
          <span class="homepage_workbench_item_img">
            <img :src="item.imgUrl"
                 alt="" />
          </span>
        </div>
      </div>
    </div>
    <div class="quick_options_content"
         v-if="!isShowSetting">
      <div class="flex_row"
           style="justify-content: space-between">
        <span class="quick_options_title">
          快捷功能
          <span class="quick_options_tips">
            可根据您的需要，去右侧“自定义功能“选择更多快捷功能。
          </span>
        </span>

        <span class="homepage_options font_blue button"
              @click="showSetting">
          自定义功能
        </span>
      </div>
      <div class="quick_content flex_row">
        <quick-options-item v-for="(item, key) in quickList"
                            :key="key"
                            :item="item"
                            @clickItem="goRouter">
        </quick-options-item>
      </div>
    </div>
    <!-- 快捷设置 -->
    <div v-if="isShowSetting"
         style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.4;
        z-index: 9;
    "></div>
    <quick-setting style="background:white;z-index:10"
                   v-if="isShowSetting"
                   :quickList="quickList"
                   @cancelSetting="cancelSetting"
                   @updateSetting="updateSetting"></quick-setting>
    <custom-dialog class="defult_dialog login_phone_box"
                   :visible.sync="isShowDownLoadBox"
                   :before-close="closeDownLoadBox"
                   width="1000px"
                   :showPosition="'visibleMiddle'">
      <div @click.stop="closeDownLoadBox"
           class="login_phone_close button"></div>
      <div class="download_content">
        <div class="download_content_item">
          <div class="download_content_item_bg">
            <img class="download_img_box"
                 src="../../../../public/image/system_pc.png"
                 alt="" />
          </div>
          <div class="download_text_desk"
               @click="mobileLogin('电脑端')">winPC端下载</div>
        </div>
        <div class="download_content_item">
          <div class="download_content_item_bg">
            <img class="download_img_box"
                 src="../../../../public/image/system_tv.png"
                 alt="" />
          </div>
          <div class="download_text_desk"
               @click.stop="mobileLogin('电视端')">TV端下载</div>
        </div>
        <div class="download_content_item">
          <div class="download_content_item_bg"
               style="margin-right:0px">
            <img style="width:50%"
                 class="download_img_box"
                 src="../../../../public/image/system_mobile.png"
                 alt="" />
          </div>
          <div class="download_text_desk"
               @click.stop="vBrandInfo.isOpenBrand?doAppBrandDownUrl('App'):mobileLogin('手机端')">安卓APP下载</div>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog class="defult_dialog login_phone_box"
                   :visible.sync="isShowMibileLogin"
                   :before-close="closeMibileLogin"
                   :showPosition="'visibleMiddle'">
      <div class="login_phone_box"
           :style="versionsHint=='电脑端'?'height:340px;':versionsHint=='手机端'?'height:560px;':'height:350px;'">
        <!-- desktop_port_hint app_port_hint -->
        <div class="login_phone_title">{{versionsHint}}</div>

        <!-- 手机端 -->
        <div class="login_phone_img tc"
             v-if="versionsHint=='手机端'">
          <div class="app_port_download"
               @click="doPortDownload('App')"></div>
          <div class="download_package_one">
            <span class="zero_one">01</span><span class="download_text">手机应用商店搜索 <span style="color: #333;font-weight: 600;">治学</span> 安装APP。</span>
            <div class="download_APP">
              <div class="APP_text">如果安卓手机应用商店中搜索不到治学APP，请 <span style="color: #3498DB;font-weight: 600;">点击</span> 下载APK。</div>
            </div>
            <img style="position: absolute;top: 108px;left: 188px;"
                 src="../../../../public/image/arrow_bottom.png"
                 alt="">
          </div>
          <div class="download_package_two">
            <span class="zero_two">02</span><span class="download_text">安装成功手机桌面会生成 <span style="color: #333;font-weight: 600;">治学APP图标</span>，点击登录。</span>

            <img style="margin-left: -5px;margin-top: 8px;"
                 src="../../../../public/image/APP_two.png"
                 alt="">
            <div class="contact_service">安装如遇问题请联系专属客服。</div>
          </div>
        </div>
        <!-- 电脑端 -->
        <div class="login_phone_img tc"
             v-if="versionsHint=='电脑端'">
          <div class="desktop_port_download"
               @click="doPortDownload('Desktop')"></div>

          <div class="download_package_one">
            <span class="zero_one">01</span><span class="download_text">下载桌面端安装包。</span>
            <div class="download_PC">
              <img src="../../../../public/image/download_png.png" />
              下载桌面端
            </div>
            <img style="position: absolute;top: 88px;left: 188px;"
                 src="../../../../public/image/arrow_bottom.png"
                 alt="">
          </div>
          <div class="download_package_two">
            <span class="zero_two">02</span><span class="download_text">点击<span style="color: #333;font-weight: 600;"><span v-if='!vBrandInfo.isOpenBrand'>ZX</span><span v-else>BC</span>Setup.exe</span>安装桌面端。</span>

            <!-- <img style="margin-left: -5px;margin-top: 8px;"
                 src="../../../public/image/PC_two.png"
                 alt=""> -->
            <div class="contact_service">安装如遇问题请联系专属客服。</div>
          </div>
        </div>
        <!-- 电视端 -->
        <div class="login_phone_img tc"
             v-if="versionsHint=='电视端'">
          <div class="desktop_port_download"
               @click="doPortDownload('MB')"></div>

          <div class="download_package_one">
            <span class="zero_one">01</span><span class="download_text">下载电视端安装包到U盘。</span>
            <div class="download_PC"><img src="../../../../public/image/download_png.png" />下载电视端</div>
            <img style="position: absolute;top: 88px;left: 188px;"
                 src="../../../../public/image/arrow_bottom.png"
                 alt="">
          </div>
          <div class="download_package_two">
            <span class="zero_two">02</span><span class="download_MB_text">将U盘插到电视或盒子USB接口，打开U盘找到相应的安装包安装即可。</span>

            <div class="contact_service"
                 style="margin-top: 18px;">安装如遇问题请联系专属客服。</div>
          </div>
        </div>
        <div @click.stop="closeMibileLogin"
             class="login_phone_close button"></div>
        <div class="login_phone_button">
          <div @click.stop="closeMibileLogin"
               class="btn_light_blue btn_hover_bg_blue button">
            知道了
          </div>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog :top="220"
                   width="250px"
                   :showPosition="'visibleMiddle'"
                   class="defult_dialog login_phone_box"
                   :visible.sync="isShowAppQRcodeImg"
                   :before-close="closeAppQRcodeImg">
      <div class="login_phone_box"
           v-show="isShowAppQRcodeImg">
        <div class="sign_QRcode_img">
          <qrcode-vue :value="signCodeValue"
                      :size="116"
                      level="L"
                      id="signQRcode" />
        </div>
        <div class="sign_QRcode_text">
          请使用手机浏览器扫码下载
        </div>
        <div @click.stop="closeAppQRcodeImg"
             class="login_phone_close button"></div>
      </div>
    </custom-dialog>

  </div>
</template>
<script>
import {
  GetAgentSetting, GetBrandDownUrl
} from '../../../API/workbench';
import quickOptionsItem from "./quick-options-item";
import quickSetting from "./quick-setting";
import downloadContent from './download-content'
import QrcodeVue from 'qrcode.vue';
export default {
  data () {
    return {
      DesktopAPPTVDownUrl: '',
      signCodeValue: '',
      isShowDownLoadBox: false,
      isShowMibileLogin: false,
      isShowAppQRcodeImg: false,
      versionsHint: '',
      opionsList: [
        {
          path: "presidentKanban",
          title: "校长",
          routerName: "presidentKanban",
          routertitle: "校长工作台",
          moduleName: "",
          ModulePowerKey: 63,
          className: "homepage_workbench_item_one",
          imgUrl: "../../../../public/image/homepage_01.png"
        },
        {
          path: "indexView",
          title: "教务",
          routerName: "indexView",
          routertitle: "教务",
          moduleName: "",
          ModulePowerKey: 51,
          className: "homepage_workbench_item_two",
          imgUrl: "../../../../public/image/homepage_02.png"
        },
        //    store.getters.token.IsTeacherKey == 1
        {
          path: "teacherAttenClass",
          title: "老师",
          routerName: "teacherAttenClass",
          routertitle: "老师工作台",
          moduleName: "",
          ModulePowerKey: 0,
          className: "homepage_workbench_item_three",
          imgUrl: "../../../../public/image/homepage_03.png"
        },
        {
          path: "other",
          title: "销售",
          routerName: "other",
          routertitle: "销售工作台",
          ModulePowerKey: 0,
          className: "homepage_workbench_item_four",
          imgUrl: "../../../../public/image/homepage_04.png"
        }
      ],
      quickList: [],
      isShowSetting: false
    };
  },
  components: {
    quickSetting,
    quickOptionsItem,
    downloadContent,
    QrcodeVue
  },
  created () {
    this.getSettingInfo()
  },
  computed: {
    vquickList () {
      let newArr = []
      let initMenuList = this.$initJson.allMenuList
      this.quickList.forEach(o => {
        let locaItem = initMenuList.find(item => {
          return o.name == item.name
        })
        if (locaItem) {
          newArr.push(locaItem)
        }
      })
      return newArr
    },
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = 'url(' + this.$store.getters.CDNURL + obj.LoginBackground + ')';
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = '#fff';
      } else {
        obj = {
          isOpenBrand: false,
          // BrandSlogan: 'url(' + require('../../../../../public/image/login_text.png') + ')',
          // LoginBackground: 'url(' + require('../../../public/image/login_banner.png') + ')',
          LoginBackground: '',
          // LogoUrl: require('../../../../../public/image/logo_terminal.png'),
          MainBodyColor: '#f85a59',
          MainDemoName: '治学培训机构',
          QRCode: '',
          inputBackground: '#f7f7f7',
          AdvertisingConfig: [],
          HomePageVideo: ''
        };
      }
      return obj;
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    mobileLogin (title) {
      this.isShowDownLoadBox = false
      let hrefArr = window.location.href.split('/');
      let SaaSClientNo = '';
      if (this.vBrandInfo.isOpenBrand) {
        // 品牌定制
        SaaSClientNo = hrefArr[3];
      } else {
        SaaSClientNo = '';
      }
      this.versionsHint = title;
      this.isShowMibileLogin = true;
      this.getGetBrandDownUrl(SaaSClientNo);// 获取下载版本
      console.log(this.vBrandInfo, 'vBrandInfo');
    },
    // 登录页面 获取 电脑 电视 手机下载地址
    getGetBrandDownUrl (OLAPKey) {
      GetBrandDownUrl(OLAPKey).then(result => {
        if (this.versionsHint == '电脑端') {
          this.DesktopAPPTVDownUrl = result.data.DesktopDownUrl;
        } else if (this.versionsHint == '手机端') {
          this.DesktopAPPTVDownUrl = result.data.AppDownUrl;
        } else if (this.versionsHint == '电视端') {
          this.DesktopAPPTVDownUrl = this.vBrandInfo.isOpenBrand ? result.data.TVDownUrl : result.data.NewTVDownUrl;
        }
        console.log(result.data, 'GetBrandDownUrl', this.versionsHint, this.DesktopAPPTVDownUrl);
      }, error => {
        layer.alert(error.msg);
      });
    },
    doPortDownload (type) {
      console.log(type, '下载');
      console.log('DesktopAPPTVDownUrl', this.DesktopAPPTVDownUrl)

      if (type == 'App') {
        this.isShowAppQRcodeImg = true;
        this.$nextTick(() => {
          if (document.getElementById('signQRcode')) {
            // document.getElementById('signQRcode').innerHTML = '';
            // let qrcode = new QRCode(document.getElementById('signQRcode'), {
            //   width: 256,  // 二维码宽度
            //   height: 256, // 二维码高度
            //   correctLevel: QRCode.CorrectLevel.L // 容错级别，
            // });
            // console.log('aaaa');
            // qrcode.clear(); // 清除二维码
            // qrcode.makeCode('http://www.zhixuerj.com/download/app/zx.apk');
            this.signCodeValue = this.DesktopAPPTVDownUrl;
          }
        });
      } else if (type == 'Desktop') {
        let url = this.DesktopAPPTVDownUrl;
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        console.log(link.href);
        link.download = ''; // 重命名文件
        link.click();
        URL.revokeObjectURL(url); // 释放内存
      } else if (type == 'MB') {
        let url = this.DesktopAPPTVDownUrl;
        const link = document.createElement('a'); // 创建a标签
        link.href = url;
        console.log(link.href);
        link.download = ''; // 重命名文件
        link.click();
        URL.revokeObjectURL(url); // 释放内存
      }
    },
    // App品牌定制下载
    doAppBrandDownUrl (type) {
      let hrefArr = window.location.href.split('/');
      GetBrandDownUrl(hrefArr[3]).then(result => {
        this.isShowAppQRcodeImg = true;
        this.$nextTick(() => {
          if (document.getElementById('signQRcode')) {
            this.signCodeValue = result.data.AppDownUrl;
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    closeDownLoadBox () {
      this.isShowDownLoadBox = false
    },
    closeMibileLogin () {
      this.isShowMibileLogin = false;
    },
    closeAppQRcodeImg () {
      this.isShowAppQRcodeImg = false;
    },
    vHasModulePower (item) {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        item.ModulePowerKey
      );
    },
    showSetting (e) {
      console.log("showSetting", e)
      console.log("showSetting", e.clientY)
      this.isShowSetting = true
      document.documentElement.scrollTop = e.clientY - 78

    },
    cancelSetting () {
      this.isShowSetting = false
    },
    updateSetting () {
      this.cancelSetting()
      this.getSettingInfo()
    },
    getSettingInfo () {
      GetAgentSetting().then(result => {
        let obj = result.data.find(o => {
          return o.SetKey == 99
        })
        console.log(obj, 'objobj')
        if (obj && obj.SetValue) {
          let newList = JSON.parse(JSON.parse(obj.SetValue))
          this.quickList = []
          let initMenuList = this.$initJson.allMenuList
          newList.forEach(o => {
            let locaItem = initMenuList.find(item => {
              return o.name == item.name
            })
            if (locaItem) {
              this.quickList.push(locaItem)
            }
          })
        }
        console.log("this.quickList", this.quickList)
        console.log("GetAgentSetting", result.data)
      })
    },
    goRouter (item) {
      console.log("goRouter", item);
      if (this.vHasModulePower(item)) {
        if (item.routerName == "other") {
          if (
            !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 6)
          ) {
            layer.alert("未开通追销功能")
          } else {
            let obj = this.$utils.parseJson(item);
            let locaItem = this.vMendianInfo.SaaSClientSetting.find(o => {
              return o.SetTypeKey == 17;
            });
            if (locaItem && locaItem.SetContent == "2.0" && locaItem.SetKey == 1) {
              // 追销2.0
              obj.routerName = "recruitStudentsWorkbenches";
              obj.ModulePowerKey = 0
              if (Number(this.$store.getters.token.IsAdviserKey) == 0) {
                layer.alert("该员工非销售")
                return false
              }
            } else if (locaItem.SetKey == 1) {
              obj.routerName = "upsellManagement";
              obj.ModulePowerKey = 125
            } else {
              layer.alert("未开通追销功能")
              return false
            }
            console.log("obj", obj)
            if (this.vHasModulePower(obj)) {
              this.$bus.emit("needOpenRouter", obj);
            } else {
              this.$utils.CheckModulePowerToTips(obj.ModulePowerKey);
            }
          }

        } else {
          if (item.routerName == 'teacherAttenClass' && this.$store.getters.token.IsTeacherKey != 1) {
            layer.alert("您不是老师，不可进入该页面")
          } else {
            this.$bus.emit("needOpenRouter", item);
          }
          // this.$bus.emit("needOpenRouter", item);
        }
        // 无权限
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    seeRadio () { }
  }
};
</script>
<style scoped>
.middle_content {
  /* height: 74px; */
  padding: 20px;
  width: 100%;
  border: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.quick_options_content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.homepage_workbench {
  margin-top: 20px;
  justify-content: space-between;
}
.homepage_workbench_item {
  height: 112px;
  border-radius: 7px;
  color: white;
  flex: 1;
  margin-right: 15px;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  padding-left: 25px;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgb(255 255 255 / 0);
}
.homepage_workbench_item:hover {
  border-radius: 8px;
  border: 1px solid #2d98d9;
  box-shadow: 0px 4px 5px 0px #3570f040;
  box-shadow: 0px 0px 6px 0px #e64024;
}
.homepage_workbench_item_img {
  position: absolute;
  /* width: 50px;
    height: 50px; */
  right: 5px;
  bottom: 10px;
}
.homepage_workbench_item_img img {
  width: 100%;
}
.homepage_workbench_item_one {
  background: linear-gradient(90deg, #3570f0 0%, #659aff 100%);
  box-shadow: 0px 4px 5px 0px #3570f040;
}
.homepage_workbench_item_two {
  background: linear-gradient(90deg, #41c7a0 0%, #55d37a 100%);
  box-shadow: 0px 4px 5px 0px #41c7a040;
}
.homepage_workbench_item_three {
  background: linear-gradient(90deg, #fe9423 0%, #ffb86b 100%);
  box-shadow: 0px 4px 5px 0px #fe942340;
}
.homepage_workbench_item_four {
  background: linear-gradient(90deg, #ff7056 0%, #fea28d 100%);
  box-shadow: 0px 4px 5px 0px #ff705640;
}
.homepage_workbench_item .big_text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.flex_row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.quick_options_title {
  /* color: #E64024; */
  color: #333333;
  font-size: 20px;
  font-weight: 500;
}
.quick_options_tips {
  font-size: 12px;
  color: #999;
  margin-left: 10px;
}

.flex_row span {
  display: inline-block;
  margin-right: 10px;
}
.step_num {
  border: 1px solid #e64024;
  border-radius: 50%;
  color: #e64024;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 20px;
}
.red_next {
  width: 30px;
  height: 30px;
  border: none !important;
  background: url('../../../../public/image/home_page_red_next.png') center
    no-repeat;
  background-size: 29px;
}
.see_radio_btn {
  width: 118px;
  height: 32px;
  border-radius: 100px;
  /* margin-left: 15px; */
  background: #e64024;
  line-height: 32px;
  position: relative;
  color: white;
  padding-left: 33px;
}
.see_radio_btn::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: none !important;
  position: absolute;
  left: 9px;
  top: 6px;
  background: url('../../../../public/image/see_radiu.png') center no-repeat;
  background-size: 16px;
}
.homepage_download,
.homepage_options {
  position: relative;
  width: 118px;
  height: 33px;
  padding-left: 35px;
  padding-top: 8px;
}
.homepage_download:hover,
.homepage_options:hover {
  border-radius: 4px;
  background: rgb(45 152 217 / 10%);
}
.homepage_download::before {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  left: 10px;
  top: 5px;
  background: url('../../../../public/image/homepage_download.png') center
    no-repeat;
  background-size: 17px;
}
.homepage_options::before {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  left: 10px;
  top: 5px;
  background: url('../../../../public/image/homepage_opitions.png') center
    no-repeat;
  background-size: 19px;
}
.quick_content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.tc {
  text-align: initial !important;
}
.download_package_one,
.download_package_two {
  width: 93%;
  margin: auto;
}
.zero_one,
.zero_two {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #ebedf0;
}
.download_text {
  display: block;
  margin-top: -20px;
  margin-left: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.download_MB_text {
  margin-left: 10px;
  margin-top: 2px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  width: 335px;
  display: block;
  float: right;
}
.download_PC {
  width: 100%;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 8px;
  height: 53px;
  text-align: center;
  line-height: 53px;
  margin-top: 15px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3498db;
  margin-bottom: 40px;
}
.download_APP {
  width: 100%;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 8px;
  height: 72px;
  margin-top: 15px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
}
.contact_service {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-bottom: 20px;
  margin-top: 10px;
}
.APP_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 15px;
  padding-top: 12px;
}
.app_port_download {
  right: 54px !important;
  width: 40px !important;
  height: 26px !important;
  top: 50px !important;
}
.desktop_port_download {
  top: 48px !important;
}
.passwordTitle {
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
#qrcode_vue {
  position: absolute;
  margin-left: 38rem;
  margin-top: -3rem;
}
#qrcode_vue >>> canvas {
  width: 75rem !important;
  height: 75rem !important;
}
.l_w_hide {
  top: 39rem;
  width: 2.3rem;
  height: 6rem;
  left: 2.7rem;
  background: #fff;
  position: absolute;
}

.download_content {
  width: 100%;
  padding: 20px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 35px;
}
.download_content_item {
  width: 290px;
  height: 300px;

  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.download_content_item_bg {
  background: url('../../../../public/image/system_bg.png') center no-repeat;
  width: 290px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(204 204 204 / 0%);
  border-radius: 10px;
  flex-direction: column;
}
.download_content_item_bg .download_img_box {
  width: 70%;
  height: auto;
}
.download_text_desk {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(54, 54, 54);
  cursor: pointer;
}
.download_text_desk:hover {
  color: #3498db !important;
  border-bottom: 1px solid #3498db !important;
}
</style>
