<template>
  <div class="courseware_reveal_view_banner">
    <div v-if="isSSP">
      <div class="courseware_reveal_view"
           :style="{ transform: vScale, top: vTop, left: vLeft }">
        <!-- 上课头部 上课模式-->
        <courseware-class-header v-if="viewType == 1"
                                 :dataInfo="dataInfo"
                                 :ScheduleCourseID="coursewareId"
                                 ref="coursewareClassHeader"
                                 :courseInfo="courseInfo"
                                 :studentArrangeList="studentArrangeList"
                                 @reback="reback"
                                 @showPopup="showAtten"></courseware-class-header>
        <!-- 预览头部 备课模式-->
        <courseware-preview-header v-if="viewType == 2"
                                   :dataInfo="dataInfo"
                                   ref="coursewarePreviewHeader"
                                   @showPopupEvaluation="showPopupEvaluation"
                                   @showPopupStep="showPopupStep"
                                   @reback="reback"></courseware-preview-header>
        <div v-if="!dataInfo.Title || (vListLength == 0 && (!dataInfo.HomeWork || !dataInfo.HomeWork.id))"
             class="container_nodata">
          请先添加附件
        </div>
        <div v-else
             class="container">
          <courseware-list :dataInfo="dataInfo"
                           ref="coursewareList"
                           @selectAttach="selectAttach"
                           @showHomeWork="showHomeWork"></courseware-list>

          <courseware-content v-if="!isShowAllView"
                              :dataInfo="showInfo"
                              :dataInfoLength="vListLength"
                              :watermarkInfo="watermarkInfo"
                              @upInfo="upAttachInfo"
                              @downInfo="downAttachInfo"
                              @showAllView="showAllView"></courseware-content>
        </div>
        <!-- <Tips v-if="viewType == 2"
              :class="funErr()"></Tips> -->
        <div class="literature">由 治学软件 提供技术支持</div>
      </div>
      <all-view v-if="isShowAllView"
                :dataInfo="showInfo"
                :dataInfoLength="vListLength"
                :watermarkInfo="watermarkInfo"
                :viewType="viewType"
                @upInfo="upAttachInfo"
                @downInfo="downAttachInfo"
                @hiddenAllView="hiddenAllView"></all-view>
      <popup-view v-if="isShowHomeWork"
                  @closePopup="closePopup"
                  :title="'课后作业'">
        <div class="index_JXT_ul">
          <!-- 作业内容 -->
          <new-home-task-browse-item :dataInfo="dataInfo.HomeWork">

          </new-home-task-browse-item>
        </div>
      </popup-view>
      <popup-view v-if="isShowAtten"
                  @closePopup="closePopupAtten"
                  :title="'学生考勤'">
        <student-attendance :courseInfo="courseInfo"
                            :studentArrangeList="studentArrangeList"></student-attendance>
      </popup-view>
      <popup-view v-if="isShowTeachingStep"
                  @closePopup="closePopupStep"
                  :title="'教学步骤'">
        <div class="course_essay_label">

          <teaching-step-view :dataInfo="dataInfo"></teaching-step-view>
        </div>
      </popup-view>

      <popup-view v-if="isShowEvaluation"
                  @closePopup="closePopupEvaluation"
                  :title="'课评话术'">
        <evaluation :dataList="dataInfo.Evaluation">
        </evaluation>
      </popup-view>

      <dialog-factory ref="coursewareTeachingDialogFactory"
                      :key="'coursewareTeaching'"
                      :routerName="'coursewareTeaching'"></dialog-factory>
    </div>
    <div v-if="!isSSP"
         class="ssp_tips">
      <div class="home_nav_back_icon button"
           @click.stop="reback"></div>
      <div class="ssp_tips_txt">
        <span class="ssp_tips_txt_loading">正在加载课件……</span><br>
        <span class="ssp_tips_txt_ext"
              v-if="sspError==''">如果长时间加载失败，请退出重进</span>
        <span class="ssp_tips_txt_ext"
              v-if="sspError!=''">{{sspError}}</span>
      </div>
    </div>
  </div>
</template>
<script>
// import Tips from './courseware-content/tips.vue';
import dialogFactory from '../common/dialog-factory';
import allView from './all-view';
import coursewareClassHeader from './courseware-class-header/index';
import coursewarePreviewHeader from './courseware-preview-header/index';
import coursewareList from './courseware-list/index';
import coursewareContent from './courseware-content/index';
import popupView from './popup-view';
import newHomeTaskBrowseItem from '../home-school-communication/home-task-page/new-home-task-browse-item';
import studentAttendance from './courseware-class-header/student-attendance';
import evaluation from './courseware-preview-header/evaluation';
import teachingStepView from './courseware-preview-header/teaching-step-view';
import {
  TeacherGetCoursewareDetail,
  GetCoursewareDetails,
  SelfCourseware,
  CWSelfCourseware,
  AuthorizationCourseware,
  GetSecurityWatermark,
  GetSaaSClientSet,
  TeacherPrepareLessonsGetSecurityWatermark,
  getTeacheCourseDetail,
  getScheduleCourseOnStudentList,
  cwsExitGiveLecture,
  cwsExitPrepareLessons,
  ExitGiveLecture,
  webvExitPrepareLessons
} from '../../API/workbench';
import { md5 } from '../../API/md5';
export default {
  data () {
    return {
      currentIndex: 0, // 防盗机制定时器数据
      timer: null, // 防盗机制定时器
      isShowAllView: false,
      DetailsID: 0,
      isShowHomeWork: false,  // 显示作业
      isShowAtten: false,
      isShowTeachingStep: false,
      isShowEvaluation: false,
      coursewareInfoType: '',
      viewType: 2,
      dataInfo: {},
      showInfo: {},
      coursewareId: 0,
      authCoursewareID: 0,
      courseInfo: {
        CommentStudentList: [],
        UnCommentStudentList: [],
        HomeWorkStudentList: [],
        TeachingPlan: {}
      },
      studentArrangeList: [],
      windowResizeTimeout: null,
      watermarkInfo: [
        {
          MainDemoName: '是否开启老师水印',
          OLAPKey: '',
          SetContent: '',
          SetKey: '0',
          SetKeyValue: '',
          SetTypeKey: '',
          SetTypeKeyValue: ''
        },
        {
          MainDemoName: '是否开启门店水印',
          OLAPKey: '',
          SetContent: '',
          SetKey: '0',
          SetKeyValue: '',
          SetTypeKey: '',
          SetTypeKeyValue: ''
        },
        {
          MainDemoName: '是否开启品牌水印',
          OLAPKey: '',
          SetContent: '',
          SetKey: '0',
          SetKeyValue: '',
          SetTypeKey: '',
          SetTypeKeyValue: ''
        }
      ],
      isSSP: false, // 启动防截屏程序,
      sspError: '', // 启动防截屏程序报错
      sspCheckHandler: null // 防截屏确认定时器，默认30秒一次。
    };
  },
  computed: {
    vScale () {
      if (
        document.documentElement.clientHeight / 1080 >
        document.documentElement.clientWidth / 1920
      ) {
        return 'scale(' + document.documentElement.clientWidth / 1920 + ')';
      } else {
        return 'scale(' + document.documentElement.clientHeight / 1080 + ')';
      }
    },
    vTop () {
      if ((1080 - document.documentElement.clientHeight) / 2 > 0) {
        return '-' + (1080 - document.documentElement.clientHeight) / 2 + 'px';
      } else {
        return (
          Math.abs((1080 - document.documentElement.clientHeight) / 2) + 'px'
        );
      }
    },
    vLeft () {
      if ((1920 - document.documentElement.clientWidth) / 2 > 0) {
        return '-' + (1920 - document.documentElement.clientWidth) / 2 + 'px';
      } else {
        return (
          Math.abs((1920 - document.documentElement.clientWidth) / 2) + 'px'
        );
      }
    },
    vListLength () {
      if (this.dataInfo.newCoursewareAttach) {
        return this.dataInfo.newCoursewareAttach.length;
      } else {
        return 0;
      }
    },
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) {
        // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    allView,
    coursewareClassHeader,
    coursewarePreviewHeader,
    coursewareList,
    coursewareContent,
    dialogFactory,
    popupView,
    newHomeTaskBrowseItem,
    studentAttendance,
    evaluation,
    teachingStepView
    // Tips
  },
  created () {
    if (this.$route.query.type) {
      this.viewType = this.$route.query.type;
    }
    if (this.$route.query.coursewareId) {
      this.coursewareId = this.$route.query.coursewareId;
      console.log(this.$route.query.coursewareId, '课件ID');
    }
    if (this.$route.query.authCoursewareID) {
      this.authCoursewareID = this.$route.query.authCoursewareID;
      console.log(this.$route.query.authCoursewareID, '授权课件ID');
    }
    if (this.$route.query.DetailsID) {
      this.DetailsID = this.$route.query.DetailsID;
      console.log(this.$route.query.DetailsID, '备课模式');
    }
    if (this.$route.query.coursewareInfoType) {
      this.coursewareInfoType = this.$route.query.coursewareInfoType;
    }
    // if (this.$route.query.type != 1) {
    //   // 防盗机制的定时器
    //   this.timer = setInterval(() => {
    //     this.currentIndex++;
    //     // console.log(this.currentIndex);
    //   }, 1000);
    // }
    this.$bus.on(this, 'wsquitAttendClassPreview', (data) => {
      layer.alert('该课已被强制结束！');
      setTimeout(() => {
        this.$router.back(-1);
      }, 500);
    });
    document.body.style.overflow = 'hidden';
    this.getWatermarkInfo();

    if (this.$zxClient.IsClient) {
      if (this.startSSP()) {
        this.getDataInfo();
        if (this.viewType == 1) {
          this.getCourseInfo();
        }
      }
    }
  },
  destroyed () {
    document.body.style.overflow = 'visible';
    // clearInterval(this.timer);
    // this.timer = null;
    if (this.sspCheckHandler) {
      clearInterval(this.sspCheckHandler);
      this.sspCheckHandler = null;
    }
    if (this.$zxClient.IsClient) {
      console.log('StopSSP');
      this.$zxClient.StopSSP();
      this.isSSP = false;
    }
  },
  mounted () {
    // setTimeout(() => { //重复
    //   if (this.$zxClient.IsClient) {
    //     this.getDataInfo();
    //   }
    // }, 1000);
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'coursewareTeaching') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
      if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
        this.getCourseInfo();
        this.loadStudentArrangeList();
      }
    });
    window.addEventListener('resize', this.windowResize);
  },
  methods: {
    startSSP () {
      let r = this.$zxClient.StartSSP();

      this.isSSP = r == 'OK';
      if (!this.isSSP) {
        this.sspError = r == 'NO' ? '环境异常，无法启动课件' : r;
      } else {
        if (this.sspCheckHandler) {
          clearInterval(this.sspCheckHandler);
        }
        this.sspCheckHandler = setInterval(() => {
          this.checkSSP();
        }, 30000);
      }
      console.log('StartSSP', this.isSSP, r);
      return this.isSSP;
    },
    checkSSP () {
      let r = this.$zxClient.CheckSSP();
      this.isSSP = r == 'OK';
      if (!this.isSSP) {
        this.sspError = r == 'NO' ? '环境异常，无法启动课件' : r;
      }
    },
    windowResize () {
      if (this.windowResizeTimeout) {
        clearTimeout(this.windowResizeTimeout);
        this.windowResizeTimeout = null;
      }
      this.windowResizeTimeout = setTimeout(() => {
        if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
          this.showAllView();
        } else {
          this.hiddenAllView();
        }
      }, 300);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursewareTeachingDialogFactory) {
        this.$refs.coursewareTeachingDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    reback () {
      if (this.$store.getters.SaaSClientEditionKey == 6) {
        if (this.viewType == 1) {
          this.cwsAttendClassPostpone();
        } else if (this.viewType == 2) {
          this.cwsExitTheLessons();
        }
      } else if (this.$store.getters.SaaSClientEditionKey <= 3) {
        if (this.$store.getters.AcademicMdCwType == 2) {
          this.$router.back(-1);
          return;
        }
        if (this.viewType == 1) {
          this.academicExitTheLessons();
        } else if (this.viewType == 2) {
          this.webvExitTheLessons();
        }
      } else {
        console.log('退出');
        this.$router.back(-1);
      }
    },
    // 课件门店退出(上课延期)
    cwsAttendClassPostpone () {
      cwsExitGiveLecture(this.courseInfo.ScheduleCourseKey, this.dataInfo.Authorized.OLAPKey).then(result => {
        console.log(result.data, '课件门店上课延期成功');
        this.$router.back(-1);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 备课退出
    cwsExitTheLessons () {
      console.log(this.dataInfo.Authorized.OLAPKey, '退出备课');
      cwsExitPrepareLessons(this.dataInfo.Authorized.OLAPKey).then(result => {
        console.log(result.data, '课件门店退出备课成功');
        this.$router.back(-1);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 教务门店上课退出
    academicExitTheLessons () {
      ExitGiveLecture(this.courseInfo.ScheduleCourseKey, this.dataInfo.Authorized.OLAPKey).then(result => {
        console.log(result.data, '教务门店上课退出');
        this.$router.back(-1);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 教务门店备课退出
    webvExitTheLessons () {
      console.log(this.dataInfo.Authorized.OLAPKey, '退出备课');
      webvExitPrepareLessons(this.dataInfo.Authorized.OLAPKey).then(result => {
        console.log(result.data, '课件门店退出备课成功');
        this.$router.back(-1);
      }, error => {
        layer.alert(error.msg);
      });
    },
    showHomeWork () {
      this.isShowHomeWork = true;
    },
    closePopup () {
      this.isShowHomeWork = false;
    },
    showAtten () {
      this.loadStudentArrangeList();
      this.isShowAtten = true;
    },
    closePopupAtten () {
      this.isShowAtten = false;
    },
    showPopupStep () {
      this.isShowTeachingStep = true;
    },
    closePopupStep () {
      this.isShowTeachingStep = false;
    },
    showPopupEvaluation () {
      this.isShowEvaluation = true;
    },
    closePopupEvaluation () {
      this.isShowEvaluation = false;
    },
    enterFullscreen () {
      this.$zxClient.FullScreen();
    },
    exitFullscreen () {
      this.$zxClient.UnFullScreen();
    },
    showAllView () {
      this.enterFullscreen();
      this.isShowAllView = true;
    },
    hiddenAllView () {
      this.exitFullscreen();
      this.isShowAllView = false;
    },
    upAttachInfo (i) {
      this.selectAttach(this.dataInfo.newCoursewareAttach[i - 2]);
    },
    downAttachInfo (i) {
      this.selectAttach(this.dataInfo.newCoursewareAttach[i]);
    },
    selectAttach (data) {
      this.dataInfo.newCoursewareAttach.forEach(o => {
        this.$set(o, 'isSelect', false);
      });
      console.log(data, this.dataInfo, 'selectAttach');
      this.$set(data, 'isSelect', true);
      this.$set(data, 'currentPage', 1);// bug-13712
      this.showInfo = data;
      if (this.viewType == 1) {
        this.isShowAtten = false;
      } else {
        this.isShowTeachingStep = false;
        this.isShowEvaluation = false;
      }
    },
    getCourseInfo (callBack) {
      getTeacheCourseDetail(this.coursewareId || this.courseInfo.ScheduleCourseKey).then(
        res => {
          if (res.code == 0) {
            if (res.data.ClassTime) {
              res.data.week = this.$utils.getWeekByTwo(res.data.ClassTime);
            }
            this.courseInfo = res.data;
            console.log('getCourseDetailed11', this.courseInfo);
            if (typeof callBack == 'function') {
              callBack(this.courseInfo);
            }
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    // 获取学员排课表
    loadStudentArrangeList (callBack) {
      getScheduleCourseOnStudentList(this.coursewareId || this.courseInfo.ScheduleCourseKey).then(
        result => {
          this.studentArrangeList = [];
          if (result.data && result.data.length > 0) {
            result.data.forEach(obj => {
              obj.isSelected = false;
              obj.unSelected = false;
              obj.OLAPKey = obj.StudentKey;
              this.studentArrangeList.push(obj);
            });
          }
          if (callBack) {
            callBack();
          }
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    getWatermarkInfo () {
      if (this.vTypeKey) {
        GetSaaSClientSet().then((res) => {
          // if (res.data.length > 0) {
          //   this.watermarkInfo = res.data;
          // }
          if (res.data.length > 0) {
            this.watermarkInfo[0] = res.data[7];
            this.watermarkInfo[1] = res.data[8];
            this.watermarkInfo[2] = res.data[9];
          }

          console.log(res.data, 'this.watermarkInfo');
        });
      } else {
        let IPAN = (this.$store.getters.SaaSClientEditionKey == 6 && this.viewType == 2 ? TeacherPrepareLessonsGetSecurityWatermark : GetSecurityWatermark);
        IPAN().then((res) => {
          if (res.data.length > 0) {
            this.watermarkInfo = res.data;
          } else {
            this.watermarkInfo[0].SetKey = 1;
            this.watermarkInfo[0].SetContent = this.$store.getters.token.UserName;
            this.watermarkInfo[1].SetKey = 1;
            this.watermarkInfo[1].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
            this.watermarkInfo[2].SetKey = 1;
            this.watermarkInfo[2].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
          }
          if (this.coursewareInfoType == 'ZZ') {
            this.watermarkInfo[2].SetContent = this.$store.getters.SaaSClientInfo.MainDemoName;
          }
          console.log(this.coursewareInfoType);
          console.log(this.watermarkInfo, 'this.watermarkInfo');
        });
      }
    },
    getDataInfo () {
      let data = {
        DeviceTypeKey: '2',
        DeviceTypeKeyValue: '桌面端',
        DeviceCode: this.$zxClient.GetMCode(),
        Longitude: '',
        Latitude: '',
        IsSettle: 1,
        PositioningOffset: '',
        Sign: md5(this.$utils.setStringmd5('2', this.$zxClient.GetMCode(), 'KJ'))
      };
      let IPAName = '';
      if (this.viewType == 1) { // 2
        IPAName = TeacherGetCoursewareDetail(this.coursewareId, data);
      } else if (this.viewType == 2) {
        if (this.coursewareId != 0 && this.DetailsID != 0) {
          IPAName = GetCoursewareDetails(this.coursewareId, data);
        } else if (this.authCoursewareID > 0) {
          IPAName = AuthorizationCourseware(this.coursewareId, this.authCoursewareID, data);
        } else {
          let type = this.$store.getters.SaaSClientInfo.EditionKey;
          if (type == 6) {
            IPAName = CWSelfCourseware(this.coursewareId, data);
          } else if (type == 5) {
            IPAName = SelfCourseware(this.coursewareId, data);
          } else {
            IPAName = SelfCourseware(this.coursewareId, data);
          }
        }
      }
      IPAName.then((res) => {
        this.dataInfo = res.data;
        this.doSelectAttachFun();
      }, (err) => {
        layer.confirm(err.msg, {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                this.reback();
              }
            }
          ]
        });
      });
    },
    doSelectAttachFun () {
      this.$set(this.dataInfo, 'newCoursewareAttach', []);
      this.dataInfo.newCoursewareAttach = this.dataInfo.CoursewareAttach.filter(obj => {
        return obj.TypeKey == 1;
      });
      if (this.dataInfo.newCoursewareAttach) {
        let i = 1;
        this.dataInfo.newCoursewareAttach.forEach((e) => {
          this.$set(e, 'ShowOrder', i);
          this.$set(e, 'isSelect', false);
          i++;
        });
      }
      if (this.dataInfo.newCoursewareAttach && this.dataInfo.newCoursewareAttach.length > 0) {
        this.selectAttach(this.dataInfo.newCoursewareAttach[0]);
      }
    }
    // funErr () {
    //   if (this.currentIndex > 100 && this.currentIndex < 200) {
    //     return 'tips1';
    //   } else if (this.currentIndex >= 200 && this.currentIndex < 300) {
    //     return 'tips2';
    //   } else if (this.currentIndex == 300) {
    //     this.currentIndex = 0;
    //   } else {
    //     return 'tips';
    //   }
    // }
  }
};
</script>
<style scoped>
.ssp_tips {
  background-color: #150b35;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ssp_tips span {
  margin: auto;
  color: white;
  font-size: 32px;
  opacity: 0.6;
}
.ssp_tips_txt_ext {
  font-size: 16px !important;
  line-height: 40px;
}
.home_nav_back_icon {
  width: 100px;
  height: 100px;
  background: url(../../../public/image/back.png) no-repeat center center;
  position: absolute;
  top: 8px;
  left: 20px;
}
.tips {
  position: absolute;
  left: 0;
  top: 250px;
  right: 0;
  z-index: 999;
}

.tips1 {
  position: absolute;
  left: 20px;
  top: 450px;
  right: 20px;
  z-index: 999;
}

.tips2 {
  position: absolute;
  left: 20px;
  top: 700px;
  right: 20px;
  z-index: 999;
}
</style>