<template>
  <div class="WKT_slideshow_upload_box hover_border_blue"
       @click="onAddBoxClick">
    <div class="WKT_slideshow_upload_noImg">
      <div class="upload_icon">+</div>
      <div>{{titleName}}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    titleName: {//  标题 （选填）
      type: String,
      default: ''
    }
  },
  methods: {
    onAddBoxClick () {
      this.$emit('onAddBoxClick');
    }
  }

};
</script>

<style>
</style>