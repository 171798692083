<template>
  <div class="form_info_detail">
    <!-- 搜索选择层 -->

    <input-search-contain-btn v-model="searchObj.searchText"
                              :placeholder="'请输入姓名、姓名首字母、手机号'"
                              @onSearchEvent="searchTextSearch"></input-search-contain-btn>

    <!-- 搜索结果 -->
    <div class="search_result"
         style="height:84%">
      <div class=" search_result_content_for_student"
           style="max-height:100%;overflow-y:auto;"
           @scroll="scrollTOP">
        <!-- <div class="apply_course_search_box">
          <div>
            <single-course-item v-for="item in courseList" 
                                 :key="item.OLAPKey"
                                 :courseItem="item"
                                 @selectedItem="selectedItem"></single-course-item>
          </div>
        </div> -->
        <div class="search_result_list">
          <new-tick-item v-for="item in courseList"
                         :key="item.OLAPKey"
                         :tickItem="item"
                         @selectedItem="selectedItem"></new-tick-item>
          <div v-if="loading"
               class="students_loading tc">
            <span class="el-icon-loading"></span>
            <span class="loading_style">加载中...</span>
          </div>
          <div v-else-if="courseList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>

        <!-- <div class="general_statement_bottom" v-if="noMore" style="background:white">
                已显示全部
        </div> -->
      </div>
    </div>
  </div>

</template>
<script>
import singleCourseItem from './single-course-item';
import newTickItem from './new-tick-item';
import { getSearchPageForStayClass } from '../../../../API/workbench';
export default {
  props: {
    formTitle: String, // 自定义标题名
    switchItem: [String, Number, Boolean], // 自定义跳转，不跳转路由的返回.switchItem为任意值
    addTitle: String
  },
  data () {
    return {
      courseList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: '',
        ID: 0
      },
      // searchObj.searchText: '',
      // searchObj.pageSize: 20,     // 每次搜索人数.
      // searchObj.pageIndex: 0,      // 每次查询页数
      isEnd: false,   // 判断是否已请求完成.
      loading: false,
      noMore: false // 没有更多数据加载了

    };
  },
  components: {
    singleCourseItem, newTickItem
  },
  watch: {
    // searchObj.searchText () {
    //   if (this.searchObj.searchText == '') {
    //     this.courseList.length = 0;
    //     this.searchObj.pageSize = 20;
    //     this.searchObj.pageIndex = 0;
    //     this.getcourseList();
    //   } else {
    //     this.courseList.length = 0;
    //     this.textSeachergetcourseList();
    //   }
    // }
  },
  created () {
    this.getcourseList();
  },
  methods: {
    // 点击搜索
    searchTextSearch () {
      this.searchObj.searchText = this.searchObj.searchText.replace(/ /g, '');
      this.searchObj.pageIndex = 0;
      this.searchObj.pageSize = 0;
      this.noMore = false;
      this.getcourseList();
    },
    doAfterSelectionItem (item) {
      this.searchObj.searchText = '';
      this.$emit('selectedItem', item);
      // this.$router.push({name: 'courseIDApply', query: {TableID: item.TableID}});
    },
    // 自定义返回或取消事件
    returnEvent () {
      // if (this.switchItem) {      // 不跳转路由的返回.switchItem为任意值.
      //   this.$emit('return');
      // } else {
      //   this.$router.back(-1);
      // }
      this.$router.go(-1);
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.noMore) {
        console.log('scrollTOP', this.noMore);
        this.getcourseList();
      }
    },
    textSeachergetcourseList () {                      // 根据搜索条件获取学生.
      let that = this;
      getSearchPageForStayClass(that.searchObj).then(result => {
        that.courseList.length = 0;
        result.data.PageDataList.forEach(obj => {
          obj.isActive = false;
          obj.NumCount = Number(obj.NumCount);
          obj.ToBeArranged = Number(obj.ToBeArranged);
          that.courseList.push(obj);
        });
        that.searchObj.pageIndex++;
      }).catch(error => {
        console.log(error);
      });
    },
    getcourseList () {
      this.loading = true;
      if (!this.isEnd) {
        getSearchPageForStayClass(this.searchObj).then(result => {
          this.isEnd = true;
          if (result.data) {
            // 切换学生类型时 (首页)需清空数据
            if (this.searchObj.pageIndex == 0) {
              this.courseList = [];
            }
            result.data.PageDataList.forEach(o => {
              o.isActive = false;
              o.NumCount = Number(o.NumCount);
              o.ToBeArranged = Number(o.ToBeArranged);
              let hasFind = false;
              if (this.courseList.length > 0) {
                hasFind = this.courseList.findIndex(p => p.TableID == o.TableID) >= 0;
              }
              if (!hasFind) {
                this.courseList.push(o);
              }
            });
            if (result.data.PageDataList.length < this.searchObj.pageSize) {
              this.noMore = true;
            }
            // this.totalNumForStudent = result.data.Total;
            // this.searchObj.pageIndex++;
          }
          this.isEnd = false;
          this.loading = false;
          this.searchObj.pageIndex++;
        }).catch(error => {
          this.isEnd = false;
          this.loading = false;
          console.log(error);
        });
      }
    },
    // 选择学生
    selectedItem (item) {
      this.courseList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.verifyStudent(item);
      this.scrollBackTop();
    },
    // 验证学生
    verifyStudent (item) {
      console.log(this.switchItem, 'item');
      if (item.IsConfirmKey == 0 && this.switchItem !== 1) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
      } else {
        this.searchObj.searchText = '';
        this.$emit('selectedItem', item);
      }
    },
    scrollBackTop () {
      this.searchObj.searchText = '';
      document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_content_for_student')[1]) {
        document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.searchObj.searchText = '';
    }
  },
  computed: {

  }
};
</script>
<style scoped>
.search_result_content {
  margin-top: 0px !important;
}
</style>


