<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'渠道名'" :readonly="false" :required="true" v-model="name"></input-text>
      <input-text :formTitle="'手机号'" :readonly="false" :required="true" v-model="tel"></input-text>
      <input-text :formTitle="'备注'" :readonly="false" :required="false" v-model="remark"></input-text>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true" @confirmClick="submit" @cancelClick="cancel"></save-cancel-btn-group>
  </div>
</template>

<script>
import {AddCustomerChannel} from '../../../API/workbench';
export default {
  data () {
    return {
      name:'',
      tel:'',
      remark:''
    };
  },
  components: {
  },
  props: {
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    submit () {
      let that = this;
      if (!that.name) {
        layer.alert('请填写渠道名');
        return;
      }
      if (!that.tel) {
        layer.alert('请填写手机号');
        return;
      }
      let params = {
        MainDemoName: that.name,
        MobileNumber: that.tel,
        Remark: that.remark
      };
      AddCustomerChannel(params).then(result => {
        if (result.code == 0) {
          that.$emit('afterSuccess');
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style scoped>
</style>