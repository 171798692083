<template>
  <div class="credit_management_popup">
    <div class="statistics-box"
         style="width: 322px;margin: 20px">
      <statistics-box :dataList="statisticsData"
                      @clickEvent="clcikStatictisItem"></statistics-box>
    </div>
    <div class="popup_title_padding view_title_flex">
      <input-search-contain-btn v-model="searchObj.searchText"
                                :placeholder="'请输入老师名、姓名首字母、手机号'"
                                @clearSearch="clearSearchCondition"
                                @onSearchEvent="search"></input-search-contain-btn>
      <div class="mendian_backstage_search">
        <div class="btn_hover_bg_blue search_btn"
             style="margin: 0"
             @click.stop="addItem">
          新增
        </div>
      </div>
    </div>
    <div class="credit_management_table">
      <table-list class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog :title="vAddFormTitle"
                   :visible.sync="showAddForm"
                   :before-close="closeDialog">
      <add-teacher :TeacherId="vSeletedItemKey"
                   :vIfDefaultAdmin="vIfDefaultAdmin"
                   @afterSuccess="updateData"
                   @cancelClick="closeDialog"></add-teacher>
    </custom-dialog>
    <custom-dialog title="修改密码"
                   :visible.sync="showEditPWForm"
                   :before-close="closeDialog">
      <edit-pass-word :TeacherId="vSeletedItemKey"
                      @cancelClick="closeDialog"></edit-pass-word>
    </custom-dialog>
  </div>
</template>
<script>
import addTeacher from '../../form/add-teacher';
import statisticsBox from '../../../../report/common/statistics-box';
import { CWSSearchPageTeacherList, CWSTeacherLeave, CWSTeacherEnable } from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
import editPassWord from './edit-pass-word';
export default {
  data () {
    return {
      statisticsData: {
        TeacherNotLeave: {
          title: '在职老师数',
          value: 0,
          type: 1,
          isSelection: true
        },
        TeacherLeave: {
          title: '离职老师数',
          value: 0,
          type: 0,
          isSelection: false
        }
      },
      seletedItem: null, // 选中单个
      showAddForm: false,  // 新增老师修改老师表单显示
      showEditPWForm: false, // 修改密码
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        TeacherType: '1'
      },
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '姓名',
          prop: 'MainDemoName',
          width: 100,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '手机号',
          prop: 'Mobile',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '性别',
          prop: 'SexKeyValue',
          width: 88,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '头像',
          prop: 'XMLIcon',
          width: 88,
          sortable: false,
          align: 'center',
          type: 'headimg-item'
        },
        {
          label: '课件包数',
          prop: 'CoursewareListCount',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '本月上课课次',
          prop: 'ThisMonthCourseCount',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '本月上课时长',
          prop: 'ThisMonthCourseTime',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '本月消耗流量',
          prop: 'ThisMonthFlow',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        // {
        //   label: "本月已预览课件",
        //   prop: "ThisMonthPrepareCourseware",
        //   width: 120,
        //   sortable: false,
        //   align: "left",
        //   type: "numberItem",
        // },
        {
          label: '操作',
          prop: 'option',
          type: 'hidden-btn',
          minWidth: 180,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  isHide: (rowData) => {
                    return rowData.OLAPKey == 0 || this.searchObj.TeacherType == 0;
                  },
                  click: (rowData) => {
                    this.goDetail(rowData);
                  }
                }
              },
              {
                name: '修改',
                extend: {
                  isHide: (rowData) => {
                    return rowData.OLAPKey == 0;
                  },
                  click: (rowData) => {
                    this.editItem(rowData);
                  }
                }
              },
              {
                name: '修改密码',
                extend: {
                  isHide: (rowData) => {
                    return rowData.OLAPKey == 0;
                  },
                  click: (rowData) => {
                    this.editPassWord(rowData);
                  }
                }
              },
              {
                name: '启用',
                extend: {
                  isHide: (rowData) => {
                    return this.searchObj.TeacherType == 1;
                  },
                  click: (rowData) => {
                    this.enableItem(rowData);
                  }
                }
              },
              {
                name: '停用',
                extend: {
                  isHide: (rowData) => {
                    if (this.searchObj.TeacherType != 1) {
                      return true;
                    } else if (rowData.IfDefaultAdmin == 1) {
                      return true;
                    }
                  },
                  click: (rowData) => {
                    this.stopItem(rowData);
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  props: {},
  components: {
    inputSearchContainBtn,
    tableList,
    statisticsBox,
    addTeacher,
    editPassWord
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.SexKeyValue = o.SexKeyValue || (o.SexKey == 3 ? '女' : '男');
        o.ThisMonthFlow = this.$utils.flowFormate(o.ThisMonthFlow);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vAddFormTitle () {
      return !this.seletedItem ? '新增老师' : '修改老师';
    },
    vSeletedItemKey () {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    },
    vIfDefaultAdmin () {
      if (!this.seletedItem) {
        return false;
      } else {
        return this.seletedItem.IfDefaultAdmin == 1;
      }
    }
  },

  created () {
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this, ['editPassWord', 'cwsteacherDetail']);
  },
  methods: {
    // 查看详情
    goDetail (item) {
      this.seletedItem = item;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '老师详情',
        moduleName: 'cwsteacherDetail',
        data: {
          teacherKey: this.seletedItem.OLAPKey,   // 班级详情适用， 班级Key
          className: 'teacher_detail_dialog'
        },
        callBack: {
          afterSuccess: () => { this.getDataList(); }
        }
      });
    },
    // 修改密码
    editPassWord (item) {
      this.seletedItem = item;
      this.showEditPWForm = true;
      // let itemObj = {
      //   routerName: this.$route.name,
      //   moduleName: 'editPassWord',
      //   name: '修改密码',
      //   data: { OLAPKey: this.seletedItem.OLAPKey },
      //   callBack: {
      //     afterSuccess: (data) => {
      //       // this.$emit('afterSuccess', data);
      //     }
      //   }
      // };
      // this.$dialog.open(this, itemObj);
    },
    // 修改单个
    editItem (item) {
      this.seletedItem = item;
      this.showAddForm = true;
    },
    // 新增按钮
    addItem () {
      this.seletedItem = null;
      this.showAddForm = true;
    },
    closeDialog () {
      this.showEditPWForm = false;
      this.showAddForm = false;
    },
    updateData () {
      this.getDataList();
    },
    // 重置查询条件
    initParamsForQuery () {
      this.dataReportList = [];
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
    },
    // 点击分类
    clcikStatictisItem (item) {
      this.initParamsForQuery();
      this.searchObj.TeacherType = item.type;
      this.getDataList();
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    // 启用
    enableItem (item) {
      this.seletedItem = item;
      layer.confirm('请问是否启用该老师', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CWSTeacherEnable(this.seletedItem.OLAPKey).then((result) => {
                this.updateData();
              });
              layer.close();
            }
          }
        ]
      });
    },
    // 停用
    stopItem (item) {
      this.seletedItem = item;
      layer.confirm('停用该老师，将会同时停止该老师的课件授权，班级关系等，请确认是否继续停用', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CWSTeacherLeave(this.seletedItem.OLAPKey).then((result) => {
                // layer.alert("启用成功")
                this.updateData();
              });
              layer.close();
            }
          }
        ]
      });
    },
    getDataList () {
      this.dataReportList = [];
      CWSSearchPageTeacherList(this.searchObj).then(
        (res) => {
          console.log('CWSSearchPageTeacherList', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.statisticsData.TeacherLeave.value = Number(res.data.TeacherLeaveNum);
          this.statisticsData.TeacherNotLeave.value = Number(res.data.TeacherNotLeaveNum);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>