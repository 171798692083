<template>
  <!-- 欠还款统计列表 -->
  <div>

    <div style="background-color: #fff">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="parameter.searchText"
                                :placeholder="'请输入学生名、手机号'"
                                @onSearchEvent="doTextSearchClick"></input-search-contain-btn>
      <div class="new_table_list_box">
        <div class="new_table_list_thead">
          <div style="width:100px;"
               class="new_table_list_th style_gray tl">姓名</div>
          <div style="width: 70px;"
               class="new_table_list_th style_yellow tc">当前欠款</div>
          <div style="width: 70px;"
               class="new_table_list_th style_red tc">总欠款</div>
          <div style="width:70px;"
               class="new_table_list_th style_green tc">总还款</div>
          <div style="width: 100px;"
               class="new_table_list_th style_blue tl">最近欠款</div>
        </div>
        <div class="new_table_list_tbody"
             style="max-height:600px;overflow-y: auto;cursor: pointer"
             @scroll="scrollTOP">
          <div class="new_table_list_tr"
               v-for="(item,index) in debtInfoStatisticsList"
               :key="index"
               @click="doDebtDetailsClick(item)">
            <div style="width: 100px;"
                 class="new_table_list_td tl">
              <div class="font_name">
                <span>{{item.StudentKeyValue}}</span>
              </div>
              <div class="font_phone">
                <span>{{$utils.phoneModulePower(item.MobileSupport)}}</span>
              </div>
            </div>
            <div style="width:70px;"
                 class="new_table_list_td tc">
              <span style="color: #ffb018">{{$utils.newAmountType(item.DebtAmount||0,2)}}</span>
            </div>
            <div style="width:70px;"
                 class="new_table_list_td tc">
              <span class="">{{$utils.newAmountType(item.HisDebtAmount||0,2)}}</span>
            </div>
            <div style="width:70px;"
                 class="new_table_list_td tc">
              <div>
                <!-- 总还款-->
                <span class="">{{$utils.newAmountType(item.TotalRepaymentAmount||0,2)}}</span>
              </div>
              <div>
                <!-- 待审 -->
                <span class="font_red"
                      v-if="Number(item.CheckPendingAmount)>0">待审{{$utils.newAmountType(item.CheckPendingAmount||0,2)}}</span>
              </div>
            </div>
            <div style="width: 100px;"
                 class="new_table_list_td tl">
              <div>
                <!-- 日期 -->
                <span>{{mdate(item.GenerateTime)}}</span>
              </div>
            </div>
            <div class="new_table_list_arrow"></div>
          </div>
          <div class="new_table_list_bottom"
               v-if="loading">
            <span class="loading_btn">加载中...</span>
          </div>
          <div v-else-if="debtInfoStatisticsList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            </div>
          </div>
          <div class="new_table_list_bottom"
               v-else-if="noMore">
            <span class="font_gray">已显示全部</span>
          </div>

        </div>
      </div>
    </div>

  </div>
</template> 

<script>
import { MessageSearchDebtRepayment, getStudentRelatedMsg } from '../../../API/workbench';
export default {
  data () {
    return {
      parameter: {
        pageIndex: 0,	// 整型	可选	0	数据源：GET	 页码，0为第一页
        pageSize: 20,	// 整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        searchText: '',	// 字符串	可选		数据源：GET	   搜索条件：模糊匹配姓名，姓名首字母,手机号
        orderBy: 'GenerateTime', // 字符串	可选		数据源：GET	 排序字段
        sequence: 'desc'	// 字符串	可选		数据源：GET	  排序顺序
      },
      debtInfoStatisticsList: [], // 欠未款统计列表
      isShowModule: false, // 控制显示表单
      loading: false,
      noMore: false // 没有更多数据加载了
    };
  },
  props: [

  ],
  components: {
  },
  created () {
    this.loadData();
  },
  mounted () {
    this.$dialog.register(this, ['paymentrepayment']);
  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    closeModule () {
      this.isShowModule = false;
      this.parameter.pageIndex = 0;
      this.parameter.searchText = '';
      this.$emit('closeDialog');
    },
    // 还款成功后更新
    upStudentFileInfoClick (studentKey) {
      console.log(studentKey, '还款成功后更新');
      this.doTextSearchClick();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;

      if ((ETarget.scrollTop >= ETarget.scrollHeight - ETarget.clientHeight) && !this.noMore) {
        this.loadData();
      }
    },
    mdate (date) {
      let newDate = date.slice(0, 10);
      return newDate;
    },
    // mTime (time) {
    //   let newDate = time.slice(10, 16);
    //   return newDate;
    // },
    doTextSearchClick () {
      this.parameter.pageIndex = 0;
      this.noMore = false;
      this.loadData();
    },
    // 去还款
    doDebtDetailsClick (item) {
      // if (this.vHasModulePower()) {
      // if (Number(item.DebtAmount) > 0) {
      // 加载学生详情（选中的学生 信息不够用）
      getStudentRelatedMsg(item.StudentKey).then(res => {
        console.log(res.data);
        if (res.code == 0) {
          this.$dialog.open(this, {
            name: '客户还款',
            routerName: this.$route.name,
            moduleName: 'paymentrepayment',
            data: { dataInfo: res.data, menuType: 0 },
            callBack: { afterSucces: () => { this.upStudentFileInfoClick(); } }
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
      // }
      // } else {
      //   this.$utils.CheckModulePowerToTips(42);
      // }
    },

    loadData () {
      this.isShowModule = true;
      console.log(this.parameter, '日期');
      this.loading = true;
      MessageSearchDebtRepayment(this.parameter).then(result => {
        // this.debtInfoStatisticsList = result.data.PageDataList;
        if (result.data) {
          if (this.parameter.pageIndex == 0) {
            this.debtInfoStatisticsList = [];
          }
          result.data.PageDataList.forEach(o => {
            o.isActive = false;
            let hasFind = false;
            if (this.debtInfoStatisticsList.length > 0 && !this.parameter.searchText) {
              hasFind = this.debtInfoStatisticsList.findIndex(p => p.StudentKey == o.StudentKey) >= 0;
            }
            if (!hasFind) {
              this.debtInfoStatisticsList.push(o);
            }
          });
          if (result.data.PageDataList.length < this.parameter.pageSize) {
            this.noMore = true;
          }
        }
        this.loading = false;
        this.parameter.pageIndex++;
        // console.log(result.data.PageDataList, '欠未款统计列表');
      }, error => {
        this.loading = false;
        layer.alert(error.msg);
      });
    }
  }

};
</script>

  <style>
/*  */
</style>