<template>
  <div class="credit_management_popup">
    <div class="popup_title_padding view_title_flex"
         style="justify-content: inherit">
      <!-- <div class="">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'日期范围'"
                    @changeDate="changeDate"></date-range>
      </div> -->
      <!-- <div>
        <span class="span-classpa">选择课包</span>
        <el-cascader ref="cascader"
                     style="height: 36px;width: 300px;"
                     v-model="selectedOptions"
                     :options="BigCounselorList"
                     :props='props'
                     @change="BigCounselor"
                     clearable></el-cascader>
      </div>
      <div class="search_item"
           style="margin-left: 15px;">
        <el-select-option :selectTitle="'选择校区'"
                          :dataSourceList="SchoolList"
                          :selectionKey="searchObj.SchoolKey"
                          @changeEvent="chgSchoolKey"></el-select-option>
      </div> -->
    </div>
    <div class="credit_management_table">
      <table-list class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import elSelectOption from '../../../../report/common/el-select-option';
import { HQSearchPageGiveLectureLogList, CommonGetAuthorizationBigCoursewarePackageList, CommonGetAuthorizationCoursewarePackageList, CommonGetAuthorizationCoursewareList } from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
export default {
  components: {
    inputSearchContainBtn,
    tableList,
    elSelectOption
  },
  data () {
    return {
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空则默认为课程名
        sequence: '', //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
        startTime: '', //	字符串	可选		数据源：GET	起始日期
        endTime: '', //	字符串	可选	0	数据源：GET	截止日期
        SchoolKey: '', //	整型	可选	0	数据源：GET	校区
        BigCoursewarePackageKey: '', //	整型	可选	0	数据源：GET	大课包
        CoursewarePackageKey: '', //	整型	可选	0	数据源：GET	子课包
        CoursewareKey: '' //	整型	可选	0	数据源：GET	课件key
      },
      selectedOptions: '',
      options: [],
      props: {
        value: 'OLAPKey',
        label: 'MainDemoName',
        children: 'children',
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level;
          if (level === 1) {
            console.log(node, 'node');
            const id = node.value;
            CommonGetAuthorizationCoursewarePackageList(id).then(res => {
              const { code, msg } = res;
              const list = res.data;
              console.log(list, 'SelectCityForProvince');
              if (code === 0) {
                const nodes = list;
                nodes.forEach(item => {
                  item.children = [];
                });
                resolve(nodes);
              }
            });
          }
          if (level === 2) {
            const id = node.value;
            console.log(node, 'node');
            console.log(this, 'this.BigCounselorList');
            CommonGetAuthorizationCoursewareList(id).then(res => {
              const { code, msg } = res;
              const list = res.data;
              console.log(list, '课件');
              if (code === 0) {
                const nodes = list;
                nodes.forEach(item => {
                  item.leaf = level + 1;
                });
                resolve(nodes);
              }
            });
          }
          if (level > 2) {
            resolve([]);
          }
        }
      },
      SchoolList: [],
      BigCounselorList: [
        {
          OLAPKey: 123,
          MainDemoName: '444',
          children: [
            {
              OLAPKey: 1231,
              MainDemoName: '1223',
              children: [{
                OLAPKey: 1213,
                MainDemoName: '1223'
              }]
            }]
        }
      ],
      dataReportList: [],
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '校区',
          prop: 'SchoolKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '预览日期',
          prop: 'GenerateTime',
          width: 100,
          sortable: false,
          align: 'left',
          className: '',
          type: 'date-item'
        },

        {
          label: '老师',
          prop: 'TeacherKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '开始时间',
          prop: 'StartTime',
          width: 90,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '结束时间',
          prop: 'EndTime',
          width: 90,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '预览时长',
          prop: 'DurationCountText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '大课包',
          prop: 'BigCoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '子课包',
          prop: 'CoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课件',
          prop: 'CoursewareKeyValue',
          width: 130,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '流量',
          prop: 'FluxNum',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          StartTime: '',
          EndTime: '',
          BigCoursewarePackageKey: '',
          SchoolKey: ''
        };
      }
    }
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  watch: {

  },
  created () {
    // this.getDataListforBig();
  },
  mounted () {
    console.log('dataInfo', this.dataInfo);
    this.searchObj.SchoolKey = this.dataInfo.SchoolKey || '';
    this.searchObj.BigCoursewarePackageKey = this.dataInfo.BigCoursewarePackageKey || '';
    this.searchObj.startTime = this.dataInfo.StartTime || '';
    this.searchObj.endTime = this.dataInfo.EndTime || '';
    this.selectedOptions = this.dataInfo.BigCoursewarePackageKey || '';
    console.log(this.searchObj, 'this.searchObj.EndTime ');
    this.getDataList();
  },
  methods: {
    getDataListforBig () {
      CommonGetAuthorizationBigCoursewarePackageList().then(result => {
        this.BigCounselorList = result.data;
      });
    },
    chgSchoolKey (item) {
      this.searchObj.SchoolKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    BigCounselor (item) {
      this.searchObj.BigCoursewarePackageKey = item[0];
      this.searchObj.CoursewarePackageKey = item[1];
      this.searchObj.CoursewareKey = item[2];
      this.$refs.cascader.dropDownVisible = false;
      this.getDataList();
    },

    changeDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    updateData () {
      this.getDataList();
    },
    search () {
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    getDataList () {
      HQSearchPageGiveLectureLogList(this.searchObj).then((res) => {
        console.log('GetGiveLectureStat', res.data);
        this.totalNum = res.data.Total;
        this.dataReportList = res.data.PageDataList;
        this.dataReportList.forEach(o => {
          o.StartTime = o.StartTime ? o.StartTime.slice(10, 19) : '';
          o.EndTime = o.EndTime ? o.EndTime.slice(10, 19) : '';
          o.DurationCountText = Number(o.DurationCount) + '分钟';
          o.FluxNum = Number(o.FluxNum) != 0 ? this.$utils.flowFormate(o.FluxNum) : '-';
        });
      }, (err) => {
        layer.alert(err.msg);
      }
      );
    }
  }
};
</script>
<style scoped>
.span-classpa {
  margin-right: 5px;
  color: #666;
  white-space: nowrap;
  text-align: center;
}
</style>