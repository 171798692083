<template>
  <!-- 合并学生 -->
  <div>
    <div :style="vMergePageShow?'':'padding-bottom: 100px'"
         style="background: white"
         class="popup_dialog_box_content clearfix">
      <div class="set_class_time_title"
           style="padding: 15px 15px; margin: 0;">
        学生一{{studentKey?' (注册)':''}}
        <span class="form_info_field">
          <input-form-tip :tips="'被合并的学生'"></input-form-tip>
        </span>
      </div>
      <div class="verify_student_lable"
           v-if="JSON.stringify(studentData)=='{}'"
           @click="onStudentOneSelect(0)">
        <div class="verify_student_img fl">
          <img src="../../../../../public/image/search_white_icon@2x.png"
               alt="">
        </div>
        <div class="verify_student_info fr">
          <div class="verify_student_select">请选择学生</div>
        </div>
      </div>
      <div class="verify_student_lable"
           v-else>
        <div v-if="studentKey&&studentData.OLAPKey">
          <div class="verify_student_img fl">
            <heads-img :imgUrl="studentData.CusHeadImg"
                       :dataInfo="studentData"></heads-img>
          </div>
          <div class="verify_student_info fl">
            <div class="verify_student_name">{{studentData.CustKeyValue}}
              <span class="verify_student_phone">(学生名：{{studentData.MainDemoName}})</span>
            </div>
            <div class="verify_student_phone">{{$utils.phoneModulePower(studentData.MobileSupport)}}
              <span :class="Number(studentData.SubscribeKey)>0?'td_WX_open':'td_WX_close'"></span>
            </div>
          </div>
        </div>
        <div v-else>
          <student-lable :studentID="studentData.OLAPKey"
                         :styleInfo="'background-color: #f6f8f9;border-radius: 8px'"
                         :dropDownBoxLeft="297"
                         :showExamine="false"
                         :showMenu="true"
                         :IsConfirmBut="false"
                         :switchItem="1"
                         @alterStudent="onChangeStudentOne"></student-lable>
        </div>
      </div>
      <div class="set_class_time_title"
           style="padding: 15px 15px; margin: 0;">
        学生二
        <span class="form_info_field">
          <input-form-tip :tips="'将保留的学生'"></input-form-tip>
        </span>
      </div>
      <div class="verify_student_lable"
           v-if="JSON.stringify(selectedStudentInfo)=='{}'"
           @click="onStudentTwoSelect(1)">
        <div class="verify_student_img fl">
          <img src="../../../../../public/image/search_white_icon@2x.png"
               alt="">
        </div>
        <div class="verify_student_info fr">
          <div class="verify_student_select">请选择学生</div>
        </div>
      </div>
      <div class="verify_student_lable"
           v-else>
        <student-lable :studentID="selectedStudentInfo.OLAPKey"
                       :styleInfo="'background-color: #f6f8f9;border-radius: 8px'"
                       :dropDownBoxLeft="297"
                       :showExamine="false"
                       :showMenu="true"
                       :IsConfirmKey="1"
                       @alterStudent="onChangeStudentTwo"></student-lable>
      </div>
      <div v-if="vMergePageShow">
        <merge-page :studentOneInfo="studentData"
                    :studentTwoInfo="selectedStudentInfo"
                    :studentKey="studentKey"
                    @closePage="closePage"></merge-page>
      </div>
    </div>

    <!-- 学生列表 -->
    <custom-dialog title="选择学生"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isStudentListShow"
                   :before-close="studentFromHide">
      <student-list :switchItem="1"
                    :IsConfirmKey="studentSelect"
                    :type="'merge'"
                    :isShowAddBtn="true"
                    @selectedItem="selectedItem"></student-list>
    </custom-dialog>
  </div>
</template>
<script>
import mergePage from './merge-page';
import studentList from '../../../common/student-list';
import { getStudentRelatedMsg } from '../../../../API/workbench';
export default {
  data () {
    return {
      // studentKey: 0, // 学生id 合并 合并
      studentData: {}, // 验证的学生信息（学生一）
      selectedStudentInfo: {}, // 选择合并的学生信息（学生二）
      studentSelect: 0, // 默认学生，0 为学生一
      isStudentListShow: false
    };
  },
  props: {
    // （学生ID）
    studentKey: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    studentList,
    mergePage,
    studentLable: () => import('../../../common/student-lable') // 异步注册组件
  },
  created () {
    console.log(this.studentKey, 'this.studentKey');
    if (this.studentKey) {
      this.getstudentData(this.studentKey);
    }
  },
  mounted () {

  },
  watch: {
  },
  computed: {
    vMergePageShow () {
      if (JSON.stringify(this.studentData) != '{}' && JSON.stringify(this.selectedStudentInfo) != '{}') {
        return true;
      } else {
        return false;
      }
    }

  },
  methods: {
    // 外部进入修改课单 - 需要一个学生ID （学生一）
    getstudentData (studentKey) {
      console.log(this.studentKey, 'this.studentKey123');
      getStudentRelatedMsg(studentKey).then(result => {
        this.studentSelect = 1;
        this.isStudentListShow = true;
        this.studentData = result.data;
      }, error => {
        layer.alert(error.msg);
      }
      );
    },
    onStudentOneSelect (type) {
      this.studentSelect = type;
      this.isStudentListShow = true;
    },
    onStudentTwoSelect (type) {
      this.studentSelect = type;
      this.isStudentListShow = true;
    },
    onChangeStudentOne (item) {
      getStudentRelatedMsg(item.OLAPKey).then(result => {
        this.studentData = result.data;
      });
    },
    onChangeStudentTwo (item) {
      getStudentRelatedMsg(item.OLAPKey).then(result => {
        this.selectedStudentInfo = result.data;
      });
    },
    selectedItem (item) {
      console.log(item, '选择学生');
      if (item) {

        getStudentRelatedMsg(item.OLAPKey).then(result => {
          switch (this.studentSelect) {
            case 0:
              this.studentData = result.data;
              break;
            case 1:
              this.selectedStudentInfo = result.data;
              break;
            default:
              break;
          }
          this.studentFromHide();
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 关闭-选择的合并学生列表
    studentFromHide () {
      this.isStudentListShow = false;
    },
    closePage () {
      this.studentDat = {};// 验证的学生信息（学生一）
      this.selectedStudentInfo = {}; // 选择合并的学生信息（学生二）
      this.$emit('closePage');
    }
  }
};
</script>
<style>
.verify_student_lable .student_label {
  background-color: #f6f8f9;
  margin-left: -0.2rem;
  padding: 0 0.2rem;
}
.verify_student_phone .td_WX_open {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(../../../../../public/image/subscribe_icon.png) no-repeat
    center center;
  background-size: 22px;
  display: inline-block;
}
.verify_student_phone .td_WX_close {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(../../../../../public/image/subscribe_icon_gray.png) no-repeat
    center center;
  background-size: 22px;
  display: inline-block;
}
</style>


