import { render, staticRenderFns } from "./double-type-echart.vue?vue&type=template&id=461fa45c"
import script from "./double-type-echart.vue?vue&type=script&lang=js"
export * from "./double-type-echart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports