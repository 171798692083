<template>
  <div>
    <div class="general_summarizing_student">
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="changeSaveDate"></date-range>
        <div class="table_select_box"
             style="margin-right: 20px; min-width: 304px">
          <div class="table_select_title">发生类型</div>
          <el-select :value="vTypeKeyValue"
                     filterable
                     placeholder="请选择"
                     style="width: 239px"
                     ref="resetText"
                     @change="selectType">
            <el-option v-for="item in typeList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入学生名、备注'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
          <!-- <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @loadTableData="getDataList"></list>
    </div>
  </div>
</template>
<script>
import list from '../../../../common/table-list/index';
import applyAcountItem from './../apply-acount-item';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { MessageSearchOwePaymentsReport } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        TypeKey: 0,
        CustKey: 0,
        TypeKeyValue: '全部',
        IsExportKey: 0
      },
      typeList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '还款',
          OLAPKey: 2
        },
        {
          MainDemoName: '欠款',
          OLAPKey: 1
        }
      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '发生日期',
          prop: 'BillTime',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '发生类型',
          prop: 'TypeKeyValue',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '金额',
          prop: 'amount',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '经办人',
          prop: 'BillerKeyValue',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        }

      ],
      dataReportList: []
    };
  },
  components: {
    list,
    applyAcountItem,
    inputSearchContainBtn
  },
  props: {
    dataInfo: Object
  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
  },
  computed: {
    vTypeKeyValue () {
      return this.searchObj.TypeKey == 0
        ? '全部'
        : this.searchObj.TypeKeyValue;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // if (o.BillTime) {
        //   o.BillTimeCopy = o.BillTime.substring(5, 16);
        // }
        // o.GenerateTime = o.GenerateTime.substring(5);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '笔，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    }

  },
  mounted () {
    if (this.dataInfo) {
      // this.searchObj.searchText = this.dataInfo.StudentKeyValue;
      this.searchObj.CustKey = this.dataInfo.CustomerKey;
      this.searchObj.startTime = this.dataInfo.startTime;
      this.searchObj.endTime = this.dataInfo.endTime;
      this.searchObj.TypeKey = this.dataInfo.TypeKey;
      this.searchObj.TypeKeyValue = this.dataInfo.TypeKeyValue;
    }
    this.getDataList();
  },
  methods: {
    selectType (item) {
      console.log(item, 'selectType');
      this.searchObj.TypeKey = item.OLAPKey;
      this.searchObj.TypeKeyValue = item.MainDemoName;
      this.searchData();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime; ;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.AccountTypeKey = '';
      this.searchObj.CustKey = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    searchData () {
      this.searchObj.CustKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '历史记录',
          clist: [
            { title: '发生日期', cName: 'BillTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '发生类型', cName: 'TypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '金额', cName: 'amount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'BillerKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchOwePaymentsReport(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchOwePaymentsReport(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchInComePayReportForKDSY');
      }).catch(error => {
        console.log('MessageSearchInComePayReportForKDSY', error.msg);
      });
    }
  }
};
</script>