<template>
  <div class="recording_data_tr">
    <div class="recording_data_td"
         style="width: 125px">
      <div>{{vDate}}</div>
      <!-- <div>{{vHour}}</div> -->
    </div>
    <div class="recording_data_td"
         style="width: 150px">
      <div>{{item.CreatorKeyValue||'系统'}}</div>
      <div :title="item.OccurReasonName">{{item.OccurReasonName}}</div>
    </div>
    <div class="recording_data_td"
         style="width: 120px">
      <div>{{item.StudentKeyValue}}</div>
    </div>
    <div class="recording_data_td"
         style="width: 120px">
      <div class="pr">
        <div>{{item.DeductTypeKeyValue}}</div>
        <div>{{setDeductCount(item.DeductCount)}}{{vUnitKeyValue}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    vDate () {
      if (this.item && this.item.SetUpTime) {
        return this.item.SetUpTime.substring(0, 10);
      }
    },
    vHour () {
      if (this.item && this.item.SetUpTime) {
        return this.item.SetUpTime.substring(10, 16);
      }
    },
    vUnitKeyValue () {
      if (this.item.UnitKey == 5) {
        return '课时';
      } else {
        return '天';
      }
    }
  },
  methods: {
    setDeductCount (val) {
      val = Number(val) || 0;
      if (val < 0) {
        return '增加' + Math.abs(val);
      } else if (val > 0) {
        return '减少' + Math.abs(val);
      } else {
        return 0;
      }
    }
  }
};
</script>

