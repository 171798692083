<template>
  <div class="index_view_menu box_withe_radius pr"
       :style="{ height:vHeight+'px'}">
    <div class="index_title pr">
      招商加盟管理
      <!-- <div class="index_menu_button" 
           @click.stop="goRouter(recommendManage)">
        云课网的线索
        <div class="index_menu_button_num" v-if="Number(this.dataInfo.YkPendingAcceptedNum)>0">
          {{ Number(this.dataInfo.YkPendingAcceptedNum) }}
        </div>
      </div> -->
      <div class="index_menu_button_02 button"
           :class="showAll?'button_top':'button_up'"
           @click.stop="changeShowAll">
        {{showAll?'收起':'展开'}}
      </div>
    </div>

    <div class="chain_workbench_file_new pr menu_content"
         v-if="showAll">
      <div class="menu_content_list pr"
           v-for="(item, key) in menuList"
           :key="key"
           @click.stop="goRouter(item)">
        <div class="menu_content_list_num">{{ Number(item.num) }}</div>
        <div class="menu_content_list_title">{{ item.name }}</div>

      </div>
    </div>

  </div>
</template>
<script>

import { HQGetCRMStat } from '../../../API/workbench'
export default {
  data () {
    return {
      menuList: [
        {
          name: '新增待分配',
          value: '新增待分配',
          num: 0,
          keyvalue: 2,
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          ModulePowerKey: 135,
          moduleName: '',
          data: {
            type: 'newInvestor',
            StatusKey: '2',
            PaymentScheduleKey: '',
          },
        },
        {
          name: '跟进中',
          value: '跟进中',
          num: 0,
          keyvalue: 2,
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          ModulePowerKey: 135,
          moduleName: '',
          data: {
            type: 'following',
            StatusKey: '2',
            PaymentScheduleKey: '',
          },
        },
        {
          name: '已预约',
          value: '已预约',
          num: 0,
          keyvalue: 2,
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          ModulePowerKey: 135,
          moduleName: '',
          data: {
            type: 'following',
            StatusKey: '3',
            PaymentScheduleKey: '',
          },
        },
        {
          name: '已考察',
          value: '已考察',
          num: 0,
          keyvalue: 2,
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          ModulePowerKey: 135,
          moduleName: '',
          data: {
            type: 'following',
            StatusKey: '4',
            PaymentScheduleKey: '',
          },
        },
        {
          name: '已签约未付款',
          value: '已签约未付款',
          num: 0,
          keyvalue: 2,
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          ModulePowerKey: 135,
          moduleName: '',
          data: {
            type: 'signBill',
            StatusKey: '',
            PaymentScheduleKey: '1',
          },
        },
        {
          name: '已付定金',
          value: '已付定金',
          num: 0,
          keyvalue: 2,
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          ModulePowerKey: 135,
          moduleName: '',
          data: {
            type: 'signBill',
            StatusKey: '',
            PaymentScheduleKey: '3',
          },
        },
        {
          name: '本月已付全款',
          value: '本月已付全款',
          num: 0,
          keyvalue: 2,
          routerName: 'crmJoinSignManage',
          routertitle: '加盟签单管理',
          auth: true,
          ModulePowerKey: 132,
          moduleName: '',
          data: {
            type: '4',
          },
        },
      ],
      recommendManage: {
        name: '推荐管理',
        routerName: 'recommendManage',
        routertitle: '推荐管理',
        ModulePowerKey: 137,
        moduleName: '',
      },
      dataInfo: [],
      showAll: false
    }
  },
  created () {
    // this.getDataInfo();
  },
  components: {

  },
  computed: {

    vHeight () {
      let height = 60;
      if (this.showAll) {
        height = 159
      }
      return height
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList
      } else {
        return []
      }
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo
    },
  },
  methods: {
    changeShowAll () {
      this.showAll = !this.showAll
      if (this.showAll) {
        this.getDataInfo()
      }
    },
    isHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey)
    },
    getDataInfo () {
      HQGetCRMStat().then((result) => {
        console.log(result.data, 'HQGetCRMStat')
        this.dataInfo = result.data
        this.menuList[0].num = Number(this.dataInfo.newNum)
        this.menuList[1].num = Number(this.dataInfo.followUpNum)
        this.menuList[2].num = Number(this.dataInfo.theReservationNum)
        this.menuList[3].num = Number(this.dataInfo.inspectionNum)
        this.menuList[4].num = Number(this.dataInfo.signTheSingularNum)
        this.menuList[5].num = Number(this.dataInfo.payADestinyNum)
        this.menuList[6].num = Number(this.dataInfo.CurrentMonthPayOnNum)
      })
    },
    goRouter (item) {
      console.log('clickAfter', item)
      if (this.isHasModulePower(item.ModulePowerKey)) {
        if (item.routerName == 'recommendManage') {
          if (this.vMendianInfo.IsSignYKKey != 1) {
            layer.alert('请先与云课网签约')
            return false
          }
        }
        if (item.routerName == 'crmJoinSignManage') {
          item.data.StartTime = this.$utils.formatDateStr(
            Date.new(Date.new().setDate(1)).toLocaleDateString(),
            'yyyy-MM-dd'
          )
          item.data.EndTime = this.$utils.formatDateToLine(
            Date.new(Date.new().getFullYear(), Date.new().getMonth() + 1, 0)
          )
        }
        this.openCustomDialogByFactoryDialog(item)
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey)
      }
    },
    openCustomDialogByFactoryDialog (item) {
      if (item.moduleName == '') {
        this.$bus.emit('needOpenRouter', item)
      } else {
        this.$emit('openCustomDialogByFactoryDialog', item)
      }
    },
  },
}
</script>
<style scoped>
.index_view_menu .index_title .index_menu_button_02 {
  width: 70px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 100px;
  color: black;
  line-height: 35px;
  position: absolute;
  right: -1px;
  top: 13px;
  font-size: 14px;
  padding-left: 15px;
}
.index_menu_button_02.button_top::after {
  content: '';
  display: block;
  position: absolute;
  /* left: -10px; */
  right: 8px;
  top: 13px;
  width: 13px;
  height: 8px;
  background: url('../../../../public/image/select_icon_bottom@2x.png');
  background-size: 13px;
}
.index_menu_button_02.button_up::after {
  content: '';
  display: block;
  position: absolute;
  /* left: -10px; */
  right: 8px;
  top: 13px;
  width: 13px;
  height: 6px;
  background: url('../../../../public/image/select_icon_top@2x.png');
  background-size: 13px;
}
.cs_manager_myclew .index_view_menu {
  width: 866px;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 8px;
}
.cs_manager_myclew .index_view_menu {
  width: 866px;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 8px;
  margin-top: 20px;
}
.cs_manager_myclew .menu_content .menu_content_list {
  width: 130px;
}
</style>