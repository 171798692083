<template>
  <div class="scroll_type">
    <div class="spring_frame_portrait">
      <student-item v-for="item in studentArrangeList"
                    :key="item.StudentArrangeKey"
                    :studentItem="item"
                    :isBatchAttendance="isBatchAttendance"
                    @selectedStudent="selectedStudent"
                    :isCourseEnd="vIsCourseEnd"></student-item>
      <!-- <div class="spring_frame_portrait_back button">
        <div class="spring_frame_portrait_back_item">
          <div class="spring_frame_portrait_back_item_add">+</div>
        </div>
        <div class="spring_frame_portrait_back_name"></div>
      </div> -->
    </div>
    <div class="spring_frame_bottom">
      签到 {{Number(courseInfo.Attence3)||0}}人 丨请假 {{Number(courseInfo.Attence4)||0}}人 丨课消 {{Number(courseInfo.DeductCount)||0}}课时
    </div>
    <attendance-popup v-if="showStudentAtten"
                      :studentItem="curSelectedStudentInfo"
                      @closePopup="closePopup"
                      @clickAtten="setAtten"></attendance-popup>
  </div>
</template>
<script>
import studentItem from './student-item';
import attendancePopup from './attendance-popup';
import { mainTeasetAttendance, mainTeakqTruancy, mainTeakqLeave, mainTeakqChange } from '../../../../API/workbench';

export default {
  data () {
    return {
      curSelectedStudentInfo: null,
      isBatchAttendance: false,  // 是否批量安排.
      showStudentAtten: false
    };
  },
  props: {
    courseInfo: Object,
    studentArrangeList: Array
  },
  created () {
    console.log('dataInfo', this.dataInfo);
  },
  computed: {
    vIsCourseEnd () {
      return this.courseInfo.StatusKey == 4;
    }
  },
  components: {
    studentItem, attendancePopup
  },
  methods: {
    selectedStudent (item) {
      this.curSelectedStudentInfo = item;
      this.$nextTick(o => {
        this.showStudentAtten = true;
      });
    },
    closePopup () {
      this.showStudentAtten = false;
    },
    // 设置考勤
    setAtten (type) {
      switch (type) {
        case this.$initJson.attendanceType.cancel:
          this.changeAttendanceStatus(this.$initJson.attendanceType.cancel);
          break;
        case this.$initJson.attendanceType.attendance:
          if (
            this.curSelectedStudentInfo.AttenceStatusKey !=
            this.$initJson.attendanceType.cancel
          ) {
            this.changeAttendanceStatus(this.$initJson.attendanceType.attendance);
          } else {
            this.toSetAttendance(
              this.curSelectedStudentInfo.IsClassHourLackKey
            );
          }
          break;
        case this.$initJson.attendanceType.leave:
          if (
            this.curSelectedStudentInfo.AttenceStatusKey !=
            this.$initJson.attendanceType.cancel
          ) {
            this.changeAttendanceStatus(this.$initJson.attendanceType.leave);
          } else {
            this.changeToLeave();
          }
          break;
        case this.$initJson.attendanceType.absence:
          if (
            this.curSelectedStudentInfo.AttenceStatusKey !=
            this.$initJson.attendanceType.cancel
          ) {
            this.changeAttendanceStatus(this.$initJson.attendanceType.absence);
          } else {
            this.changeToAbsence();
          }
          break;

        default:
          break;
      }
    },
    // 请假
    changeToLeave () {
      this.toLeave(0);
    },
    toLeave (IsClassHourLackKey) {
      // 调用请假接口.
      mainTeakqLeave(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey // 第一次请求IsClassHourLackKey默认为0, 由服务器返回报错是否为课时不足, 再进行操作
      ).then(
        (result) => {
          Object.assign(this.curSelectedStudentInfo, result.data);
          this.courseInfo.WKQRS -= 1;
          this.courseInfo.QJRS += 1;
          layer.alert('请假成功');
          this.closePopup();
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.courseInfo.StatusKey
          );

          this.curSelectedStudentInfo.isSelected = false;
        },
        (err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式继续请假？', {
              btn: [
                {
                  name: '确认',
                  callBack: function () {
                    that.toLeave(1);
                    layer.close();
                  }
                }
              ]
            });
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }
            layer.alert(tips);
            this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
          } else {
            layer.alert(err.msg);
          }
        }
      );
    },
    // 旷课
    changeToAbsence () {
      this.toAbsence(0);
    },
    // 调用旷课接口
    toAbsence (IsClassHourLackKey) {
      console.log('toAbsence', this.courseInfo);
      mainTeakqTruancy(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey // 第一次请求IsClassHourLackKey默认为0, 由服务器返回报错是否为课时不足, 再进行操作
      )
        .then((result) => {
          Object.assign(this.curSelectedStudentInfo, result.data);
          this.courseInfo.WKQRS -= 1;
          this.courseInfo.KKRS += 1;
          layer.alert('设置旷课成功');
          this.closePopup();
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.courseInfo.StatusKey
          );

          this.curSelectedStudentInfo.isSelected = false;
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            // 60001为课时不足.  课时不足才提示.
            layer.confirm(this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式继续旷课？', {
              btn: [
                {
                  name: '确认',
                  callBack: function () {
                    that.toAbsence(1);
                    layer.close();
                  }
                }
              ]
            });
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }

            layer.alert(tips);
            this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 变更考勤（已考勤的变更成另一种状态）
    changeAttendanceStatus (type, attendanceReason, ISClassHourLackKey) {
      this.curSelectedStudentInfo.ISClassHourLackKey = ISClassHourLackKey || 0;
      mainTeakqChange(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        this.curSelectedStudentInfo.ISClassHourLackKey,
        type,
        this.curSelectedStudentInfo.StudentArrangeKey,
        attendanceReason
      )
        .then((result) => {
          if (result.code === 0) {
            console.log('kqChange', this.$utils.parseJson(result.data));
            Object.assign(this.curSelectedStudentInfo, result.data);
          }
          this.closePopup();
          this.curSelectedStudentInfo.isSelected = false;
          layer.alert('变更考勤成功');
          // this.closeAttendanceReason();
          this.$bus.emit(
            'afterArrangeScheduleCourse',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$bus.emit(
            'AfterChangeAttendanceStatus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey
          );
          this.$emit('updateStudentFileEvent');
          this.$emit(
            'changeAttendanceStutus',
            this.curSelectedStudentInfo.ScheduleCourseKey,
            this.curSelectedStudentInfo.StudentKey,
            this.courseInfo.StatusKey
          );
        })
        .catch((err) => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式变更考勤？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      that.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.changeAttendanceStatus(type, attendanceReason, 1);
                      layer.close();
                    }
                  }

                ]
              });
          } else if (err.code == 60008 || err.code == 60009) {
            let tips = '';
            if (err.code == 60008) {
              tips = '学生考勤状态不符，请确认考勤状态后再考勤';
            } else {
              tips = '学生安排不符，请确认安排状态后再考勤';
            }

            layer.alert(tips);
            this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 签到接口 GOTO
    toSetAttendance (IsClassHourLackKey, callBack) {
      mainTeasetAttendance(this.curSelectedStudentInfo.StudentKey, this.curSelectedStudentInfo.ScheduleCourseKey, IsClassHourLackKey).then(result => {
        this.$bus.emit('afterArrangeScheduleCourse', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey);
        this.$bus.emit('AfterChangeAttendanceStatus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey);
        this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
        if (callBack) {
          callBack();
        }
        this.closePopup();
        Object.assign(this.curSelectedStudentInfo, result.data);
        this.curSelectedStudentInfo.isSelected = false;
      }, (err) => {
        let that = this;
        if (err.code == 60001) {
          layer.confirm(this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式继续签到？', {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: function () {
                  that.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
                  layer.close();
                }
              },
              {
                name: '确认',
                callBack: function () {
                  that.toSetAttendance(1, callBack);
                  layer.close();
                }
              }
            ]
          });
        } else if (err.code == 60008 || err.code == 60009) {
          let tips = '';
          if (err.code == 60008) {
            tips = '学生考勤状态不符，请确认考勤状态后再考勤';
          } else {
            tips = '学生安排不符，请确认安排状态后再考勤';
          }
          layer.alert(tips);
          this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.courseInfo.StatusKey);
        } else {
          layer.alert(err.msg);
          if (callBack) {
            callBack();
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.scroll_type{
  display: flex;
  flex-direction: column;
  position: absolute;
    top: 115px;
    left: 0;
    right: 0;
    bottom: 0;

}
.scroll_type .spring_frame_portrait{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.scroll_type .spring_frame_bottom{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
</style>