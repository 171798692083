<template>
  <!-- 预加载 -->
  <div class="">
    <div class="monitoring_content_nodata_txt">预加载完成</div>
  </div>
</template>
<script>
import { clearUserInfo } from '../../assets/login';
export default {
  name: 'prestrainPage',
  data () {
    return {
    };
  },
  mounted () {
    console.log('prestrainPage mounted');
    if (_uni) {
      console.log('_uni ready');
      _uni.postMessage({
        data: {
          action: 'message',
          eventTypeKey: 1, // 1-预加载完成；2-课件加载完成；3-课件预览手动退出；4-课件预览自动退出
          eventTypeKeyValue: '预加载完成',
          data: {}
        }
      });
    } else {
      console.log('_uni no ready');
      document.addEventListener('UniAppJSBridgeReady', function () {
        console.log('UniAppJSBridgeReady');
        uni.postMessage({
          data: {
            action: 'message',
            eventTypeKey: 1, // 1-预加载完成；2-课件加载完成；3-课件预览手动退出；4-课件预览自动退出
            eventTypeKeyValue: '预加载完成',
            data: {}
          }
        });
      });
    }
    clearUserInfo();
  },
  methods: {

  }
};
</script>

<style>
.el-select .el-input--suffix {
  border: none;
  background-color: transparent;
  color: #49b5f3;
}
</style>
