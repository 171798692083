<template>
  <div class="credit_management_popup">
    <div class="popup_title_padding view_title_flex"
         style="justify-content: space-between">
      <!-- <div class="">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'日期范围'"
                    @changeDate="changeDate"></date-range>
      </div> -->
      <!-- <div>
        <span class="span-classpa">选择课包</span>
        <el-cascader ref="cascader"
                     style="height: 36px;width: 300px;"
                     v-model="selectedOptions"
                     :options="BigCounselorList"
                     :props='props'
                     @change="BigCounselor"
                     clearable></el-cascader>
      </div>-->
      <!-- <div class="search_item"
           style="margin-left: 15px;">
        <el-select-option :selectTitle="'选择老师'"
                          :dataSourceList="TeacherList"
                          :selectionKey="searchObj.TeacherKey"
                          @changeEvent="chgTeacherKey"></el-select-option>
      </div> -->
      <!-- 类型 -->
      <div class="table_select_box"
           style="margin-left: 15px;">
        <div class="table_select_title">类型</div>
        <el-select v-model="selectedTypeAccount"
                   filterable
                   placeholder="必选"
                   @change="chgSearchValue"
                   value-key="OLAPKey">
          <el-option v-for="item in typeList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <div class="search_item">
        <div class="import_investor btn_hover_bg_blue"
             @click="exportClick">导出</div>
      </div>
    </div>
    <div class="credit_management_table">
      <table-list class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import elSelectOption from '../../../../report/common/el-select-option';
import { HQSearchPageGiveLectureLogList, CommonGetAuthorizationBigCoursewarePackageList, CommonGetAuthorizationCoursewarePackageList, CommonGetAuthorizationCoursewareList, HQGetTecherListForHQ } from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
export default {
  components: {
    inputSearchContainBtn,
    tableList,
    elSelectOption
  },
  data () {
    return {
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空则默认为课程名
        sequence: '', //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
        startTime: '', //	字符串	可选		数据源：GET	起始日期
        endTime: '', //	字符串	可选	0	数据源：GET	截止日期
        SchoolKey: '', //	整型	可选	0	数据源：GET	校区
        BigCoursewarePackageKey: '', //	整型	可选	0	数据源：GET	大课包
        CoursewarePackageKey: '', //	整型	可选	0	数据源：GET	子课包
        CoursewareKey: '', //	整型	可选	0	数据源：GET	课件key
        TeacherKey: 0,
        ClassTypeKey: 0
      },
      selectedTypeAccount: '全部',
      typeList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        // {
        //   OLAPKey: 1,
        //   MainDemoName: '选班上课'
        // },
        {
          OLAPKey: 2,
          MainDemoName: '上课'
        },
        {
          OLAPKey: 3,
          MainDemoName: '备课'
        }
      ],
      selectedOptions: '',
      options: [],
      props: {
        value: 'OLAPKey',
        label: 'MainDemoName',
        children: 'children',
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level;
          if (level === 1) {
            console.log(node, 'node');
            const id = node.value;
            CommonGetAuthorizationCoursewarePackageList(id).then(res => {
              const { code, msg } = res;
              const list = res.data;
              console.log(list, 'SelectCityForProvince');
              if (code === 0) {
                const nodes = list;
                nodes.forEach(item => {
                  item.children = [];
                });
                resolve(nodes);
              }
            });
          }
          if (level === 2) {
            const id = node.value;
            console.log(node, 'node');
            console.log(this, 'this.BigCounselorList');
            CommonGetAuthorizationCoursewareList(id).then(res => {
              const { code, msg } = res;
              const list = res.data;
              console.log(list, '课件');
              if (code === 0) {
                const nodes = list;
                nodes.forEach(item => {
                  item.leaf = level + 1;
                });
                resolve(nodes);
              }
            });
          }
          if (level > 2) {
            resolve([]);
          }
        }
      },
      TeacherList: [

      ],
      BigCounselorList: [
        {
          OLAPKey: 123,
          MainDemoName: '444',
          children: [
            {
              OLAPKey: 1231,
              MainDemoName: '1223',
              children: [{
                OLAPKey: 1213,
                MainDemoName: '1223'
              }]
            }]
        }
      ],
      dataReportList: [],
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '校区',
          prop: 'CampusKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '上课日期',
          prop: 'ClassTime',
          width: 100,
          sortable: false,
          align: 'left',
          className: '',
          type: 'date-item'
        },

        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '开始时间',
          prop: 'BeginClassTime',
          width: 140,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '结束时间',
          prop: 'EndClassTime',
          width: 140,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '课件使用时长',
          prop: 'DurationCount',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '大课包',
          prop: 'BigCoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '子课包',
          prop: 'CoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课件',
          prop: 'CoursewareKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '流量',
          prop: 'Flow',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '类型',
          prop: 'ClassTypeKeyValue',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          StartTime: '',
          EndTime: '',
          BigCoursewarePackageKey: '',
          SchoolKey: ''
        };
      }
    }
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  watch: {

  },
  created () {
    // this.getDataListforBig();
  },
  mounted () {
    console.log('dataInfo', this.dataInfo);
    this.searchObj.SchoolKey = this.dataInfo.SchoolKey || '';
    this.searchObj.BigCoursewarePackageKey = this.dataInfo.BigCoursewarePackageKey || '';
    this.searchObj.startTime = this.dataInfo.StartTime || '';
    this.searchObj.endTime = this.dataInfo.EndTime || '';
    this.selectedOptions = this.dataInfo.BigCoursewarePackageKey || '';
    this.searchObj.ClientKey = this.dataInfo.ClientKey || '';
    console.log(this.searchObj, 'this.searchObj.EndTime ');
    this.getTeacherList();
    this.getDataList();
  },
  methods: {
    // 选择类型
    chgSearchValue (item) {
      console.log(item, 'item');
      this.selectedTypeAccount = item.MainDemoName;
      this.searchObj.ClassTypeKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataListforBig () {
      CommonGetAuthorizationBigCoursewarePackageList().then(result => {
        this.BigCounselorList = result.data;
      });
    },
    getTeacherList () {
      HQGetTecherListForHQ(this.searchObj.ClientKey).then(result => {
        console.log('result', result.data);
        this.TeacherList = result.data;
        this.TeacherList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      }, error => {
        layer.msg(error.msg);
      });
    },
    chgTeacherKey (item) {
      this.searchObj.TeacherKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    BigCounselor (item) {
      this.searchObj.BigCoursewarePackageKey = item[0];
      this.searchObj.CoursewarePackageKey = item[1];
      this.searchObj.CoursewareKey = item[2];
      this.$refs.cascader.dropDownVisible = false;
      this.getDataList();
    },

    changeDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    updateData () {
      this.getDataList();
    },
    search () {
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    getDataList () {
      // this.dataReportList.push({
      //   SchoolKey: '001',//	整型	校区
      //   SchoolKeyValue: '校区校区校区-校区',//	字符串
      //   GenerateTime: '2022-05-17 00:00:00.000',//	字符串	备课日期
      //   TeacherKey: '5',//	整型	老师
      //   TeacherKeyValue: '整老师',//	字符串
      //   StartTime: '2022-05-17 18:35:00.000',//	字符串	备课起始时间
      //   EndTime: '2022-05-17 18:30:00.000',//	字符串	备课结束时间
      //   DurationCount: '1200',//	整型	备课时长
      //   BigCoursewarePackageKey: '',//	整型	大课包
      //   BigCoursewarePackageKeyValue: '大课包',//	字符串
      //   CoursewarePackageKey: '',//	整型	子课包
      //   CoursewarePackageKeyValue: '子课包',//	字符串
      //   CoursewareKey: '',//	整型	课件
      //   CoursewareKeyValue: '	课件',//
      // });
      HQSearchPageGiveLectureLogList(this.searchObj).then((res) => {
        console.log('GetGiveLectureStat', res.data);
        this.totalNum = res.data.Total;
        this.dataReportList = res.data.PageDataList;
        this.dataReportList.forEach(o => {
          o.BeginClassTime = o.BeginClassTime ? o.BeginClassTime.slice(10, 19) : '';
          o.EndClassTime = o.EndClassTime ? o.EndClassTime.slice(10, 19) : '';
          o.DurationCount = Number(o.DurationCount) + '分钟';
          o.Flow = Number(o.Flow) != 0 ? this.$utils.flowFormate(o.Flow) : '-';
        });
      }, (err) => {
        layer.alert(err.msg);
      }
      );
    },
    // 导出
    exportClick () {
      if (this.dataReportList.length > 0) {
        var obj = this.$utils.parseJson(this.searchObj);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '上课记录',
          clist: [
            { title: '校区', cName: 'CampusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '上课日期', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '老师', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '开始时间', cName: 'BeginClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '结束时间', cName: 'EndClassTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '课件使用时长', cName: 'DurationCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '大课包', cName: 'BigCoursewarePackageKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '子课包', cName: 'CoursewarePackageKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课件', cName: 'CoursewareKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '流量', cName: 'Flow', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '类型', cName: 'ClassTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        HQSearchPageGiveLectureLogList(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    }
  }
};
</script>
<style scoped>
.span-classpa {
  margin-right: 5px;
  color: #666;
  white-space: nowrap;
  text-align: center;
}
</style>