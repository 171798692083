<template>
  <div class="compile_view_box">
    <div class="compile_view">
      <!-- <ishow-editor ref="ishowEditor"
                    :className="'ishow_editor_show'"
                    :dataInfo="dataInfo"
                    :coverInfo="coverInfo"
                    :PosterInfo="PosterInfo"
                    :CouponInfo="CouponInfo"
                    :scaleWidth="scaleWidth"
                    :scaleHeight="scaleHeight"
                    @pageJsonData="getPageJsonData"></ishow-editor>  -->

      <activity-coupon ref="activityCoupon"
                       :scaleWidth="scaleWidth"
                       :scaleHeight="scaleHeight"
                       :className="'ishow_editor_show'"
                       :posterInfo="PosterInfo"
                       :couponInfo="CouponInfo"
                       :CDNURL="$store.getters.CDNURL"
                       :screenWidth="'640'"></activity-coupon>
    </div>
    <div class="WKT_index_phone_btn">
      <div class="btn_top"
           @click.stop="preScrollEvent"></div>
      <div class="btn_bottom"
           @click.stop="nextScrollEvent"></div>
    </div>
  </div>
</template>
<script>
// import ishowEditor from './ishow-editor';
import activityCoupon from 'activity-coupon';
export default {
  data () {
    return {

    };
  },
  components: {
    // ishowEditor,
    activityCoupon
  },
  props: {
    dataInfo: Object,
    coverInfo: Object,
    PosterInfo: Object,
    CouponInfo: Object,
    scaleWidth: {
      type: Number,
      default: 375
    },
    scaleHeight: {
      type: Number,
      default: 640
    }
  },
  created () {

  },
  mounted () {
    // 关闭时触发.
    this.$bus.on(this, 'closeEditorDialog', (data) => {
      console.log('closeEditorDialogActivityCoupon', this.$refs.activityCoupon.audioApi);
      if (this.$refs.activityCoupon.audioApi && !this.$refs.activityCoupon.isPause) {
        this.$refs.activityCoupon.audioApi.stop();
      }
    });
  },
  methods: {
    initGetPageJsonByID (ID) {
      this.$refs.ishowEditor.getPageJsonByID(ID);
    },
    getPageJsonData (item) {
      this.$emit('pageJsonData', item);
    },
    preScrollEvent (e) {
      let scrollTop = this.$el.querySelector('.ishow_editor_show').scrollTop;
      let scrollHeight = this.$el.querySelector('.ishow_editor_show').scrollHeight;
      let clientHeight = this.$el.querySelector('.ishow_editor_show').clientHeight;
      if (scrollTop > 0 && (scrollTop <= (scrollHeight - clientHeight) / 2)) {
        this.$el.querySelector('.ishow_editor_show').scrollTop = 0;
      } else if (scrollTop > 0 && (scrollTop > (scrollHeight - clientHeight) / 2)) {
        this.$el.querySelector('.ishow_editor_show').scrollTop = (scrollHeight - clientHeight) / 2;
      }
    },
    nextScrollEvent (e) {
      let scrollTop = this.$el.querySelector('.ishow_editor_show').scrollTop;
      let clientHeight = this.$el.querySelector('.ishow_editor_show').clientHeight;
      this.$el.querySelector('.ishow_editor_show').scrollTop = (clientHeight / 2) + scrollTop;
      console.log(this.$el.querySelector('.ishow_editor_show').scrollTop, 'scrollTopscrollTop');
    }
  }
};
</script>

