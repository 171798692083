
<template>
  <div class="from_list"
       :class="{ form_info_required: required }">
    <div class="from_list_title">
      {{formTitle}}
    </div>
    <div class="from_list_content">
      <div class="from_list_date from_list_input"
           :class="inputClass">
        <div class="form_info_value_input">
          <el-date-picker :popper-class="popperClass"
                          v-model="dateTime"
                          type="date"
                          :placeholder="formPlaceholder||'必选'"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          ref="datePicker"
                          :picker-options="pickerOptions"
                          @change="changeEvent($event)"
                          @focus="focusEvent($event)"
                          @blur="closedPicker"
                          :class="isShowWeek?'input_week':''"></el-date-picker>
        </div>

      </div>
      <div class="font_gray pr"
           style="left: 165px;"
           v-if="tipsTitle">{{tipsTitle}}</div>
    </div>
  </div>

</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'inputDate',
  data () {
    let that = this;
    return {
      dateTime: this.value,
      pickerOptions: {
        disabledDate (time) {
          if (that.minDate && that.maxDate) {
            return time.getTime() < (new Date(that.minDate).getTime() - (24 * 60 * 60 * 1000)) || time.getTime() > new Date(that.maxDate).getTime();
          } else if (that.minDate) {
            return (time.getTime() < new Date(that.minDate).getTime() - (24 * 60 * 60 * 1000));
          } else if (that.maxDate) {
            return time.getTime() > new Date(that.maxDate).getTime();
          } else {
            return '';
          }
        }
      }
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    inputClass: String,
    value: String, // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    formPlaceholder: String,
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    minDate: String, // 最小日期
    maxDate: String, // 最大日期
    popperClass: String,
    tipsTitle: String,        // 提示.
    isShowWeek: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    helpDescription: String // 表单说明提示
  },
  created () {
  },
  computed: {
    vWeekKeyValue () {
      if (this.dateTime) {
        return this.$utils.getWeekByTwo(this.dateTime);
      } else {
        return '';
      }
    }
  },
  methods: {
    changeEvent (value) {
      console.log(value, 'changeEvent');
      this.$emit('changeDate', this.dateTime);
      this.$emit('input', this.dateTime);
    },
    focusEvent () {
      this.$emit('focusEvent');
    },
    pickerblur () {
      console.log('pickerblur', this.$refs.datePicker);
      this.closedPicker();
      this.$refs.datePicker.blur();
    },
    closedPicker () {
      this.$emit('blurEvent');
    },
    resetDate () {
      console.log('resetDate');
      this.dateTime = '';
    }
  },
  watch: {
    value (n, o) {
      this.dateTime = n;
    }
  }
};
</script>

<style>
.new_input_date_week {
  top: 13px;
  right: 26px;
  line-height: 20px;
  position: absolute;
  color: #3498db;
}
</style>

