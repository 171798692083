<template>
  <div class="form_info_detail form_info_serach_box">
    <!-- 搜索选择层 -->
    <div class="defult_dialog_searchform">
      <!-- 搜索框 -->
      <div class="search_form">
        <input type="text"
               class="search_input"
               placeholder=""
               v-model="studenSearchText">
        <span class="remove_btn"
              v-show="studenSearchText.length > 0"
              @click.stop="clearSearText"></span>
      </div>
    </div>
    <!-- 搜索结果 -->
    <div class="search_result">
      <div class="search_result_content">
        <div class="search_result_list">
          <!-- active 选中当前 -->
          <single-selection-item v-for="(item,index) in vstudentList"
                                 :key="index"
                                 :isTestStudentItem="item"
                                 v-on:selectedItem="selectedItem"></single-selection-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import singleSelectionItem from '../../../common/single-selection-item';
import { messageSearchPageForStudent } from '../../../../API/workbench.js';
export default {
  name: 'singleSelectionList',
  props: ['isShowSearchInput'], // 显示是否需要本地搜索 singleSelectionDatasource
  components: {
    singleSelectionItem
  },
  data () {
    return {
      // searchResultSingleDialogVisible: false,
      // 测试学生列表 数据
      studentList: [],
      studenSearchText: ''

    };
  },
  watch: {
    // studentList(){
    //     this.isTestStudentList = this.studentList
    // }
  },
  created () {
    this.getStudentList();
  },
  methods: {
    getStudentList () {
      let pageIndex = 0;   // 每页记录数，0：表示查询所有
      let pageSize = 0;    // 页码，0为第一页
      let searchText = '';   //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名
      messageSearchPageForStudent(pageIndex, pageSize, searchText).then(result => {
        this.studentList = result.data.PageDataList;
      }).catch(error => {
        console.log(error);
      });
    },
    selectedItem (item) {
      this.$emit('selectedItem', item);
    },
    clearSearText () {
      this.studenSearchText = '';
    }
  },
  computed: {
    vstudentList () {
      let newClassList = [];
      // const PinyinMatch = require('pinyin-match');// 根据字母搜索
      if (this.studenSearchText != '') {
        this.studentList.forEach(o => {
          // var m = PinyinMatch.match(o.MainDemoName, this.studenSearchText);
          if (((o.MainDemoName && o.MainDemoName.indexOf(this.studenSearchText) >= 0) ||
            (o.MobileSupport && o.MobileSupport.indexOf(this.studenSearchText) >= 0) ||
            (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(this.studenSearchText.toUpperCase()) >= 0))
          ) {
            newClassList.push(o);
            console.log(newClassList, '搜索');
          };
        });
      } else {
        newClassList = this.studentList;
      }

      return newClassList;
    }

  }
};
</script>

