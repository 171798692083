<template>
  <div class="student_info_list"
       @click="studentInfoClick">
    <div class="flex">
      <div class="list_img"
           :class="vClass">
        <span v-if="!vStudentPhoto"
              class="text_box">{{
          vStudentInitial
        }}</span>
        <img class="img_box"
             :src="vStudentPhoto"
             mode="">
      </div>
      <div class="">
        <div class="list_name">
          <span class="">{{ studentInfo.MainDemoName }}</span>
          <!-- <span class="list_phone">{{studentInfo.CustomerPhoneName}}</span> -->
        </div>
        <div class="list_text">
          <span v-if="studentInfo.CurrentClassHourCount > 0"
                class="">剩余课时
            <span class="font_margin">{{
              Number(studentInfo.CurrentClassHourCount)
            }}</span></span>
          <span v-if="
              studentInfo.CurrentClassHourCount > 0 &&
                (studentInfo.YearCardMaxDayCount > 0 ||
                  studentInfo.DebtCount > 0)
            ">丨</span>
          <span v-if="studentInfo.YearCardMaxDayCount > 0">年卡剩余
            <span class="font_margin">{{ Number(studentInfo.YearCardMaxDayCount) }}天</span></span>
          <span v-if="
              studentInfo.YearCardMaxDayCount > 0 && studentInfo.DebtCount > 0
            ">丨</span>
          <span v-if="studentInfo.DebtCount && studentInfo.DebtCount > 0">
            欠
            <span class="font_margin font_red">{{
              Number(studentInfo.DebtCount || 0)
            }}</span>
            <span class="font_margin">课时</span>
          </span>

          <span v-if="
              studentInfo.DebtCount == 0 &&
                studentInfo.CurrentClassHourCount == 0 &&
                studentInfo.YearCardMaxDayCount == 0
            "
                class="font_red">课时耗尽</span>
        </div>
      </div>
    </div>
    <!-- <div v-if="studentInfo.xykdList.length>0||studentInfo.DebtCourseList.length>0">
			<div class="list_text" v-for="item in studentInfo.xykdList" :key="item.TableID">
				<span class="text_box" style="padding-right: 10rpx;">{{item.ApplyCorName}}</span>
				<span v-if="item.UnitKey==5"><span class="font_black">{{$utils.mMoneyType(item.CurrentCount,1)}}</span>课时</span>
				<span v-else>剩余<span class="font_black">{{$utils.mMoneyType(item.PeriodCount,1)}}</span>天</span>
			</div>
			<div class="list_text" v-for="(item,index) in studentInfo.DebtCourseList" :key="index+1">
				<span class="text_box" style="padding-right: 10rpx;">{{item.CourseNameKeyValue}}</span><span class="font_red">欠{{$utils.mMoneyType(item.PeriodCount,1)}}</span>课时
			</div>
		</div>
		<div v-else>
			<div class="font_red" v-if="this.studentInfo.IsActiveKey > 0">
				课时耗尽
			</div>
		</div> -->
    <!-- 	<div class="list_type" :class="vStudentStateClassName" v-if="type=='all'">
			{{vStudentStateText}}
		</div> -->
    <div class="flex">
      <div class="list_WX"
           :class="Number(studentInfo.SubscribeKey > 0) ? '' : 'type_gray'"
           @click.stop="toWX">
      </div>
      <div class="list_phone"
           :class="studentInfo.CustomerPhoneName.length == 0 ? 'type_gray' : ''"
           @click.stop="makePhone">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  props: {
    studentInfo: Object, // 在校学生信息
    type: String,
  },
  computed: {
    vStudentStateText () {
      if (this.studentInfo.IsActiveKey == 1) {
        return "在校";
      } else if (
        this.studentInfo.IsActiveKey == 0 &&
        this.studentInfo.StudentTypeKey == 3
      ) {
        //
        return "线索";
      } else if (this.studentInfo.IsActiveKey == 0) {
        return "离校";
      }
    },
    vStudentStateClassName () {
      if (this.studentInfo.IsActiveKey == 1) {
        return "type_blue";
      } else if (
        this.studentInfo.IsActiveKey == 0 &&
        this.studentInfo.StudentTypeKey == 3
      ) {
        //
        return "type_yellow";
      } else if (this.studentInfo.IsActiveKey == 0) {
        return ""; // 默认灰色
      }
    },
    vStudentInitial () {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.studentInfo.MainDemoName)) {
        return this.studentInfo.MainDemoName.substr(
          this.studentInfo.MainDemoName.length - 1,
          1
        );
      } else {
        return this.studentInfo.MainDemoName.substr(0, 1);
      }
    },
    vStudentPhoto () {
      if (this.studentInfo.HeadImgSupport) {
        if (this.studentInfo.HeadImgSupport.indexOf("http") > -1) {
          return this.studentInfo.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + this.studentInfo.HeadImgSupport;
        }
      } else {
        return "";
      }
    },
    vClass () {
      let n = Math.floor(1 + Math.random() * (9 - 1));
      return "bg_" + n;
    },
  },
  methods: {
    studentInfoClick () {
      let item = {
        routerName: this.$route.name,
        name: '学生档案',
        moduleName: 'studentFile',
        data: { studentID: this.studentInfo.StudentKey }
      };
      this.$dialog.open(this, item);

      // this.$emit('studentInfoClick', this.parameter);
    },
    toWX () {
      // if (
      //   this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 124)
      // ) {
      //   if (this.studentInfo.CustomerPhoneName.length > 0) {
      //     // #ifdef APP-PLUS
      //     uni.setClipboardData({
      //       data: this.studentInfo.CustomerPhoneName,
      //       success: () => {
      //         console.log("success");
      //       },
      //     });
      //     plus.runtime.openURL("weixin://");
      //     // #endif
      //   }
      // } else {
      //   this.$utils.CheckModulePowerToTips(124);
      // }
    },
    makePhone () {
      // this.$common.execUNIAPI(() => {
      //   if (
      //     this.$common.CheckModulePower(this.$store.getters.RolePowerList, 124)
      //   ) {
      //     if (this.studentInfo.CustomerPhoneName.length > 0) {
      //       uni.makePhoneCall({
      //         phoneNumber: this.studentInfo.CustomerPhoneName,
      //       });
      //     }
      //   } else {
      //     this.$common.CheckModulePowerToTips(124);
      //   }
      // }, null, null, 'android.permission.CALL_PHONE');
    },
  },
};
</script>

<style scoped>
.font_margin {
  margin-left: 3px;
}

.list_name .list_WX,
.list_name .list_phone {
  display: inline-block;
}

.font_red {
  color: #ff6c67;
}

.student_info_list {
  min-height: 54px;
  padding: 15px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: none;
}

/* .student_info_list::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 100rpx;
  right: 0;
  height: 1px;
  background-color: #ececec;
} */

.student_info_list:last-child::after {
  background-color: #fff;
}

.student_info_list .list_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 12px;
  text-align: center;
  line-height: 38px;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
}

.student_info_list .list_img.bg_1 {
  background-color: #bad7df;
}

.student_info_list .list_img.bg_2 {
  background-color: #769fcd;
}

.student_info_list .list_img.bg_3 {
  background-color: #99ddcc;
}

.student_info_list .list_img.bg_4 {
  background-color: #fbafaf;
}

.student_info_list .list_img.bg_5 {
  background-color: #f2c6b4;
}

.student_info_list .list_img.bg_6 {
  background-color: #b9bbdf;
}

.student_info_list .list_img.bg_7 {
  background-color: #99e1e5;
}

.student_info_list .list_img.bg_8 {
  background-color: #c1c0b9;
}

.student_info_list .list_img .img_box {
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.student_info_list .list_name {
  font-size: 16px;
  line-height: 20px;
  margin-right: 9px;
  margin-bottom: 2px;
  max-width: 100px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.student_info_list .list_text {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  margin-bottom: 2px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student_info_list .list_text .text_box {
  display: inline-block;
  max-width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
  padding-right: 10px;
}

.list_name .list_WX,
.list_name .list_phone {
  display: inline-block;
}

.list_name .list_WX,
.student_info_list .list_WX {
  width: 22px;
  height: 22px;
  background: url('../../../../../public/image/wx_icon.png') no-repeat;
  background-size: 22px;
  position: static;
}

.list_name .list_WX.type_gray,
.list_WX.type_gray {
  background: url('../../../../../public/image/wx_icon_no.png') no-repeat;
}

.list_name .list_phone,
.student_info_list .list_phone {
  width: 22px;
  height: 22px;
  margin-left: 16px;
  margin-right: 15px;

  background: url('../../../../../public/image/student_phone_icon.png')
    no-repeat;
  background-size: 20px;
}

.list_name .list_phone.type_gray,
.student_info_list .list_phone.type_gray {
  background: url('../../../../../public/image/student_phone_icon_gray.png')
    no-repeat;
  background-size: 20px;
}

.student_info_list {
  min-height: 54px;
  padding: 15px 0;
  border-bottom: 1px solid #ececec;
  position: relative;
}

.student_info_list:last-child {
  border-bottom: none;
}

.student_info_list .list_name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-right: 9px;
  margin-bottom: 2px;
  max-width: 100px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student_info_list .list_phone {
  font-size: 12px;
  line-height: 20px;
  color: #999;
}

.student_info_list .list_text {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  margin-bottom: 2px;
}

.student_info_list .list_WX {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 0;
  top: 15px;
  background: url('../../../../../public/image/wx_icon.png') no-repeat;
  background-size: 18px;
}

.student_info_list .list_type {
  position: absolute;
  right: 30px;
  top: 15px;
  width: 38px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #c3cad9;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  color: #909399;
  background-color: #e7eaef;
}

.student_info_list .list_type.type_blue {
  color: #1989fa;
  background-color: #d1e7fe;
  border-color: #8cc4fc;
}

.student_info_list .list_type.type_yellow {
  color: #ff8c39;
  background-color: #fffbe6;
  border-color: #ffe58f;
}

.list_name .list_WX,
.school_student_ul .student_info_list .list_WX {
  width: 22px;
  height: 22px;
  background: url('../../../../../public/image/wx_icon.png') no-repeat;
  background-size: 22px;
  position: static;
}

.list_name .list_WX.type_gray,
.student_info_list .list_WX.type_gray {
  background: url('../../../../../public/image/wx_icon_no.png') no-repeat;
  background-size: 22px;
}

.list_name .list_phone,
.school_student_ul .student_info_list .list_phone {
  width: 22px;
  height: 22px;
  margin-left: 16px;
  margin-right: 15px;

  /* background-image: url(/public/img/student_phone_icon.png); */
  background: url('../../../../../public/image/student_phone_icon.png')
    no-repeat;
  background-size: 22px;
}
</style>