<template>

  <div class="student_choose_all"
       style="font-size: 12px"
       v-if="isBatchAttendance">
    <div class="student_choose_all_tip">
      <span class="point_red">签到学生选中后，点"确认签到"。不签到学生，点学生反选。</span>
    </div>
    <div class="select_course_title_right choose_all_btngroup form_footer_btn_group">
      <div @click.stop="cancleBatchAttendance()"
           class="btn_white btn_hover_white">取消</div>
      <div @click.stop="confirmBatchAttendance()"
           class="btn_blue  btn_hover_bg_blue">确认签到</div>
    </div>
  </div>
</template>   
<script>
export default {
  name: 'selectAllTip',
  data () {
    return {

    };
  },
  props: ['isBatchAttendance'],
  methods: {
    // 取消批量考勤
    cancleBatchAttendance () {
      this.$emit('cancleBatchAttendance');
    },
    // 确认批量考勤
    confirmBatchAttendance () {
      this.$emit('confirmBatchAttendance');
    }
  }

};
</script>

