<template>
  <div class="chain_workbench_warning border_bottom pr scroll_type">
    <div class="warning_title">
      智能预警
      <div class="warning_btn"
           v-if="dataList.length>0"
           @click="changesearchObj"></div>
    </div>
    <div class="warning_ul "
         @scroll="scrollTOP"
         v-if="dataList.length>0">
      <item v-for="(item,key) in dataList"
            :key="key"
            :keyIndex="key"
            :item="item"
            @seletedItem="seletedItemAfter"></item>
    </div>
    <div v-if="dataList.length==0"
         class="course_block_nodata">
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <div class="module_content_menuList"
         :style="{'top':topValue+'px','left':'68.5%'}"
         v-show="isShowdropDownBox"
         @mouseover.stop="isShowdropDownBox=true">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="vMenuList"
                     @clickOptionForAlertDialog="clickAfter"></drop-down-box>
    </div>
    <custom-dialog :title="'续期'"
                   :visible.sync="isShowCampusRenewalForm"
                   :before-close="closeCampusRenewalFormPopup">
      <renewal-form :campusInfo="campusInfo"
                    @close="closeCampusRenewalFormPopup"
                    @afterSuccess="updateData"></renewal-form>
    </custom-dialog>
    <custom-dialog :title="'申请校区端口'"
                   :visible.sync="isShowApplyPortFormPopup"
                   :before-close="closePopup">
      <apply-port-form @close="closePopup"
                       @afterSuccess="updateData"></apply-port-form>
    </custom-dialog>
    <custom-dialog :title="'课件续费'"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closePopup">
      <authorization-courseware-form :coursewareId="Number(seletdItem.DataKey)"
                                     :campusID="seletdItem.SchoolKey"
                                     @close="closePopup"
                                     @afterSuccess="updateData"></authorization-courseware-form>
    </custom-dialog>
  </div>
</template>
<script>
import {
  SearchPageForSmartEarlyWarning,
  IgnoreEarlyWarning,
  AuthorizationCoursewareDisable,
  AuthorizationCoursewareEnable,
  RecoveryAuthorize,
  LockAuthorize
} from '../../../API/workbench';
import authorizationCoursewareForm from '../../head-quarters/headquarters-management/authorization-courseware/form/authorization-courseware-form';
import applyPortForm from '../../head-quarters/headquarters-management/campus-management/port-apply-record-list/apply-port-form';
import renewalForm from '../../head-quarters/headquarters-management/campus-management/form/new-renewal-form';
import dropDownBox from '../../common/drop-down-box';
import item from './item';
export default {
  data () {
    return {
      searchObj: {
        PageSize: 10,
        PageIndex: 0
      },
      dataList: [
        // { TypeKey: 10,
        //   TypeKeyValue: '续费预警1', // 10-续费预警;11-排课警戒;12-课件续费;13-端口不足
        //   DataKey: '111', //	字符串	数据ID
        //   Title: '111' },
        // { TypeKey: 11,
        //   TypeKeyValue: '排课警戒2', // 10-续费预警;11-排课警戒;12-课件续费;13-端口不足
        //   DataKey: '222', //	字符串	数据ID
        //   Title: '222' },
        // { TypeKey: 10,
        //   TypeKeyValue: '续费预警3', // 10-续费预警;11-排课警戒;12-课件续费;13-端口不足
        //   DataKey: '333', //	字符串	数据ID
        //   Title: '333' },
        // { TypeKey: 12,
        //   TypeKeyValue: '课件续费', // 10-续费预警;11-排课警戒;12-课件续费;13-端口不足
        //   DataKey: '444', //	字符串	数据ID
        //   Title: '444' },
        // { TypeKey: 13,
        //   TypeKeyValue: '端口不足', // 10-续费预警;11-排课警戒;12-课件续费;13-端口不足
        //   DataKey: '555', //	字符串	数据ID
        //   Title: '555' },
        // { TypeKey: 11,
        //   TypeKeyValue: '排课警戒6', // 10-续费预警;11-排课警戒;12-课件续费;13-端口不足
        //   DataKey: '666', //	字符串	数据ID
        //   Title: '666' }
      ],
      isShowCampusRenewalForm: false,
      showAll: true,
      seletdItem: {
        DataKey: 0
      },
      menuType: 10,
      isShowApplyPortFormPopup: false, // 申请校区端口
      isShowCoursewareFormPopup: false, // 课件续费详情
      menuList10: [
        {
          name: '续费',
          type: 'renew',
          isShow: true,
          ModulePowerKey: 109
        },
        {
          name: '忽略',
          type: 'igore',
          isShow: true,
          ModulePowerKey: 109
        }
      ],
      menuList11: [
        {
          name: '锁定课件',
          type: 'stop',
          isShow: true,
          ModulePowerKey: 108
        },
        {
          name: '恢复课件',
          type: 'open',
          isShow: true,
          ModulePowerKey: 108
        },
        {
          name: '忽略',
          type: 'igore',
          isShow: true,
          ModulePowerKey: 108
        }
      ],
      menuList12: [

        {
          name: '续费',
          type: 'renewCourseWare',
          isShow: true,
          ModulePowerKey: 108
        },
        {
          name: '忽略',
          type: 'igore',
          isShow: true,
          ModulePowerKey: 108
        }
      ],
      menuList13: [
        {
          name: '申请校区端口',
          type: 'recharge',
          isShow: true,
          ModulePowerKey: 109
        }
      ],
      isShowdropDownBox: false,
      newIndex: -1,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      reason: { reason: '' },
      campusInfo: {}

    };
  },
  components: {
    item, dropDownBox, renewalForm, applyPortForm, authorizationCoursewareForm
  },
  props: {

  },
  created () {
    this.getDataList();
  },
  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vMenuList () {
      let newArr = [];
      switch (this.menuType) {
        case 10:    // 续费预警
          newArr = this.menuList10;
          break;
        case 11:    // 排课警戒
          if (this.seletdItem.StatusKey == 1) {
            this.menuList11[0].isShow = false;
            this.menuList11[1].isShow = true;
          } else {
            this.menuList11[0].isShow = true;
            this.menuList11[1].isShow = false;
          }
          newArr = this.menuList11;
          break;
        case 12:    // 课件续费
          newArr = this.menuList12;
          break;
        case 13:    // -端口不足
          newArr = this.menuList13;
          break;
        default:
          break;
      }
      return newArr;
    }
  },
  mounted () {
    document.querySelector('body').addEventListener('click', e => {
      this.isShowdropDownBox = false;
    }, false);
  },
  methods: {
    changesearchObj () {
      this.searchObj.PageSize = 99;
      this.getDataList();
    },
    closePopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowApplyPortFormPopup = false;
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowdropDownBox = false;
      }
    },
    getDataList () {
      SearchPageForSmartEarlyWarning(this.searchObj).then(result => {
        this.dataList = result.data.PageDataList;
        console.log('SearchPageForToDo', result.data);
      }).catch(error => {
        console.log(error, 'errorSearchPageForToDo');
      });
    },
    seletedItemAfter (item, key) {
      this.dataList.forEach(o => {
        if (o.DataKey == item.DataKey) {
          o.isShow = true;
        } else {
          o.isShow = false;
        }
      });
      this.seletdItem = item;
      switch (Number(item.TypeKey)) {
        case 10:    // 续费预警
          this.menuType = 10;
          break;
        case 11:    // 排课警戒
          this.menuType = 11;
          break;
        case 12:    // 课件续费
          this.menuType = 12;
          break;
        case 13:    // -端口不足
          this.menuType = 13;
          break;
        default:
          break;
      }
      this.$nextTick(o => {
        this.isShowdropDownBox = true;
        this.doOneTopValueCount(key);
        console.log(this.seletdItem, 'seletedItemAfter');
      });
    },
    // 排课警戒停用
    arrangeCourseStop (DataKey) {
      LockAuthorize(this.seletdItem.SchoolKey, this.seletdItem.DataKey).then(result => {
        this.updateData();
      });
    },
    // 排课警戒恢复
    arrangeCourseOpen () {
      RecoveryAuthorize(this.seletdItem.SchoolKey, this.seletdItem.DataKey).then(result => {
        this.updateData();
      });
    },
    // 续费
    renewClick () {
      console.log(this.vToken, 'renewClickvToken');
      this.campusInfo = {
        OLAPKey: this.seletdItem.DataKey,
        MainDemoName: this.seletdItem.Title,
        OperatorKey: this.vToken.UserID,
        OperatorKeyValue: this.vToken.UserName
      };
      this.isShowCampusRenewalForm = true;
    },
    // 关闭
    closeCampusRenewalFormPopup () {
      this.isShowCampusRenewalForm = false;
    },
    updateData () {
      this.closePopup();
      this.getDataList();
      this.$emit('updateData');
    },
    clickAfter (item) {
      this.dataList.forEach(o => {
        o.isShow = false;
      });
      this.isShowdropDownBox = false;
      switch (item.type) {
        case 'renew':
          this.renewClick();
          break;
        case 'igore':
          this.ignoreData();
          break;
        case 'detail':

          break;
        case 'pass':
          break;
        case 'recharge':
          this.isShowApplyPortFormPopup = true;
          break;
        case 'stop':
          this.arrangeCourseStop(this.seletdItem.DataKey);
          break;
        case 'open':
          this.arrangeCourseOpen(this.seletdItem.DataKey);
          break;
        case 'renewCourseWare':
          this.isShowCoursewareFormPopup = true;
          break;
        default:
          break;
      }
    },
    ignoreData () {
      IgnoreEarlyWarning(this.seletdItem.DataKey, this.seletdItem.TypeKey, this.reason, this.seletdItem.SchoolKey).then(result => {
        console.log('IgnoreEarlyWarning', result.data);
        this.getDataList();
        this.$emit('updateData');
        this.$bus.emit('updateCampusCourseware', this.seletdItem);
      });
    },
    // 计算TOP值
    doOneTopValueCount (index) {
      console.log(index, 'index', this.toScrollTop);
      this.newIndex = index;
      this.topValue = 0;
      let oneTopValue = 44;
      if (this.toScrollTop > 0) {
        this.topValue = oneTopValue * this.newIndex - this.toScrollTop + this.topValue + 85;
      } else {
        this.topValue = this.topValue + (oneTopValue * this.newIndex) + 85;
      }
    }
  }
};
</script>

