<template>
  <div class="courseware_bag_record_list">
    <div class="courseware_bag_head_box"
         v-for="(box,key) in boxInfoList"
         :key="key">
      <div v-for="(item,key) in box.dataList"
           :key="key"
           :style="item.width>0?'width:'+ item.width+'px':''"
           :class="item.className">
        <div v-if="!item.type">{{item.tltle}}
          <span :title="item.tltle=='授权范围:'?textValue(dataInfo,item.value):''">{{textValue(dataInfo,item.value)}}</span>
        </div>
        <div v-else-if="item.type=='btn'"
             @click="doClick(dataInfo)">{{dataInfo.isOpen?'收起':'展开'}}
          <span :class="dataInfo.isOpen?'top_btn':'bottom_btn'"></span>
        </div>
      </div>
    </div>
    <!-- 记录 -->
    <div v-if="dataInfo.isOpen"
         class="chg_btn_class">
      <div v-if="recordInfoList.length>0">
        <div class="monthly_bill_text">月度账单</div>
        <div class="monthly_bill_record_list"
             v-for="(obj,key) in recordInfoList"
             :key="key">
          <div style="width: 85px;margin-right: -16px;"
               class="record_box date_icon">{{obj.GenerateTime.slice(2,4)}}年{{obj.GenerateTime.slice(5,7)}}月</div>
          <div style="width: 380px"
               class="record_box">
            <span class="">上课节次：</span>
            <span>{{Number(obj.CourseCount)}}节</span>
            <span class="font_gray9">丨 上课时长：</span>
            <span>{{Number(obj.CourseDurationCount)}}分钟</span>
          </div>
          <!-- <div style="width: 160px;"
               class="record_box">
            <span class="lessons_icon">预览时长：</span>
            <span>{{Number(obj.PrepareLessonsDurationCount)}}分钟</span>
          </div> -->
          <div style="width: 80px;color: #3498DB;cursor: pointer;margin: 0;margin-left: 115px;"
               class="record_box"
               @click="doPrepareLessons(obj)">上课记录</div>
          <!-- <div style="width: 90px;color: #3498DB;cursor: pointer;margin: 0;"
               class="record_box"
               @click="doAttendClass(obj)">上课记录</div> -->
        </div>
      </div>
      <div v-else>
        <div class="cw_content_nodata_txt"
             style="height: 100px;margin: 0px;background-size: 100px;padding-top: 100px;padding-bottom: 20px;">还没有月度账单哦~</div>
      </div>
    </div>
  </div>
</template>
<script>

import { HQGetAuthCoursewarePackageMonthStat } from '../../../../API/workbench';
export default {
  components: {

  },
  data () {
    return {
      recordInfoList: []
    };
  },
  props: {
    dataInfo: Object,
    boxInfoList: Array,
    authDetailsInfo: Object
  },
  created () {
    // this.isShowCoursewareAction();
  },
  computed: {

  },
  methods: {
    doClick () {
      this.dataInfo.isOpen = !this.dataInfo.isOpen;
      if (this.dataInfo.isOpen) {
        this.isShowCoursewareAction(this.dataInfo);
      }
    },
    isShowCoursewareAction () {
      console.log(this.dataInfo, '展开记录');
      HQGetAuthCoursewarePackageMonthStat(this.dataInfo.OLAPKey).then(result => {
        console.log(result.data, '获取授权大课包下的子课包List');
        this.recordInfoList = result.data;
        // let item = {
        //   GenerateTime: '2022-05-20 00:00', //	整型 时间
        //   CourseCount: '63', //	字符串	已上课次
        //   CourseDurationCount: '300', //	字符串	上课时长
        //   PrepareLessonsDurationCount: '500'//	整型	预览时长
        // };
        // this.recordInfoList = [];
        // this.recordInfoList.push(item, item, item);
      }, error => {
        layer.alert(error.msg);
      });
    },
    textValue (data, value) {
      console.log(data, value, '显示授权信息');
      return data[value];
    },
    // 预览
    doPrepareLessons (item) {
      console.log(item, '预览');
      this.$dialog.open(this, {
        name: '上课记录',
        routerName: this.$route.name,
        moduleName: 'prepareLessonsRecord',
        data: {
          dataInfo: {
            StartTime: item.GenerateTime.slice(0, 7) + '-01',
            EndTime: this.$utils.getday(item.GenerateTime),
            SchoolKey: this.authDetailsInfo.SchoolKey,
            BigCoursewarePackageKey: this.authDetailsInfo.OLAPKey,
            ClientKey: item.ClientKey
          }
        }
      });
    },
    // 上课
    doAttendClass (item) {
      console.log(item, '上课');
      this.$dialog.open(this, {
        name: '上课记录',
        routerName: this.$route.name,
        moduleName: 'attendClassRecord',
        data: {
          dataInfo: {
            StartTime: item.GenerateTime.slice(0, 7) + '-01',
            EndTime: this.$utils.getday(item.GenerateTime),
            SchoolKey: this.authDetailsInfo.SchoolKey,
            BigCoursewarePackageKey: this.authDetailsInfo.OLAPKey
          }
        }
      });
    }
  }
};
</script>
