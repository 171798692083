<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit clearfix">
        <div class="text">透视转发家长</div>
        <single-year-picker :type="'month'" @chgDate="chgDate"></single-year-picker>
        <div class="setup" @click="setup">设置</div>
      </div>
      <echart-pie :echartID="'echart3'" ref="echart3" :dataList="echartList"></echart-pie>
    </div>
    <div class="listbox">
      <div class="roomlist clearfix">
        <div class="block" :class="search.TypeKey==1 ? 'cur' : ''" @click="roomClick(1)">
          <div>高转发</div>
          <p>{{echartList[0].value}}人</p>
        </div>
        <div class="block" :class="search.TypeKey==2 ? 'cur' : ''" @click="roomClick(2)">
          <div>中转发</div>
          <p>{{echartList[1].value}}人</p>
        </div>
        <div class="block" :class="search.TypeKey==3 ? 'cur' : ''" @click="roomClick(3)">
          <div>低转发</div>
          <p>{{echartList[2].value}}人</p>
        </div>
        <div class="block" :class="search.TypeKey==4 ? 'cur' : ''" @click="roomClick(4)">
          <div>未转发</div>
          <p>{{echartList[3].value}}人</p>
        </div>
      </div>
      <div class="formbox">
        <el-input v-model="search.searchText" placeholder="搜索学生" prefix-icon="el-icon-search"></el-input>
        <a class="btn btn_1" @click="searchClick">搜索</a>
        <a class="btn btn_2" @click="resetClick">清除条件</a>
        <a class="btn btn_3" @click="downClick">导出</a>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list" :tableData="tableData" :totalNum="totalNum" :PageCount="PageCount" :tableColumns="tableColumns" :queryParams="search" :footerContent="vFooterContent" @loadTableData="getList"></table-list>
      </div>
    </div>
    <custom-dialog title="设置" width="500px" :visible.sync="isShowSetup" :before-close="closeSetup">
      <parent-share-setup @afterSuccess="successSetup" @cancelClick="closeSetup"></parent-share-setup>
    </custom-dialog>
  </div>
</template>

<script>
import {GetChangeParentData,GetChangeParentList} from '../../../API/workbench';
import echartPie from './echart-pie';
import tableList from '../../common/table-list/index';
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import parentShareSetup from './parent-share-setup';
export default {
  data () {
    return {
      dateStart:'',
      dateEnd:'',
      echartList:[
        {
          value: 0,
          name: '高转发',
          itemStyle: {color:'#519CF7'}
        },
        {
          value: 0,
          name: '中转发',
          itemStyle: {color:'#FE9647'}
        },
        {
          value: 0,
          name: '低转发',
          itemStyle: {color:'#FF6C67'}
        },
        {
          value: 0,
          name: '未转发',
          itemStyle: {color:'#C7C9D1'}
        }
      ],
      search:{
        Month:'',
        TypeKey:1,
        searchText:'',
        pageIndex:0,
        pageSize:5,
        IsExportKey:0
      },
      isShowSetup:false,
      tableData:[],
      totalNum:0,
      PageCount:0,
      tableColumns:[
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 200,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 200,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '已读条数',
          prop: 'NumCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '转发条数',
          prop: 'ChangeCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '转发率',
          prop: 'ChangeLv',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    echartPie,
    tableList,
    singleYearPicker,
    parentShareSetup
  },
  created () {
  },
  mounted () {
    this.dateStart = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.dateEnd = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
    this.search.Month = this.dateStart.replace('-01','');
    this.getData();
    this.getList();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    setup () {
      this.isShowSetup = true;
    },
    closeSetup () {
      this.isShowSetup = false;
    },
    successSetup () {
      this.isShowSetup = false;
      this.search.pageIndex = 0;
      this.getData();
      this.getList();
    },
    chgDate (val, startTime, endTime) {
      this.dateStart = startTime.split('/').join('-');
      this.dateEnd = endTime.split('/').join('-');
      this.search.Month = this.dateStart.replace('-01','');
      this.getData();
      this.getList();
    },
    draw () {
      this.$refs.echart3.echartInit();
    },
    roomClick (key) {
      let that = this;
      that.search.TypeKey = key;
      this.search.pageIndex = 0;
      this.getList();
    },
    searchClick () {
      this.search.pageIndex = 0;
      this.getList();
    },
    resetClick () {
      this.search.searchText = '';
      this.search.pageIndex = 0;
      this.getList();
    },
    downClick () {
      if(this.totalNum==0){
        layer.alert('没有数据可导出');
        return
      }
      let that = this;
      let search = {
        Month:that.search.Month,
        TypeKey:that.search.TypeKey,
        searchText:that.search.searchText,
        pageIndex:that.search.pageIndex,
        pageSize:that.search.pageSize,
        IsExportKey:1
      };
      let jsonFormate = {
        SheetTitle: '透视转发家长',
        clist: [
          { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '已读条数', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '转发条数', cName: 'ChangeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '转发率', cName: 'ChangeLv', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
        ]
      };
      GetChangeParentList(search, jsonFormate).then(result => {
        if (result.code == 0) {
          window.location.href = that.$store.getters.APIURL + result.data.url;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getData () {
      let that = this;
      GetChangeParentData(that.search.Month).then(result => {
        if (result.code == 0) {
          that.echartList[0].value = result.data.HighChangeParent;
          that.echartList[1].value = result.data.MediumChangeParent;
          that.echartList[2].value = result.data.LowChangeParent;
          that.echartList[3].value = result.data.UnreadChangeParent;
          that.draw();
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getList () {
      let that = this;
      GetChangeParentList(that.search).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].ChangeLv = Number(list[i].ChangeLv) + '%';
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
</style>