<template>
  <!-- active 选中当前 -->
  <div class="single_result single_class_info"
       @click.stop="selectedItem(item)"
       :class="{active:item.isActive}">
    <div class="single_result_info_detail  ">
      <div class="single_class_name">
        <span>{{item.MainDemoName}}</span>
        <span v-if="item.CurrentPeoCount">（{{Number(item.CurrentPeoCount)}}人）</span>
      </div>
      
      <!-- <div class="single_class_member">
        <span class="">报名学生：</span>
        <span class="">{{item.studentNumberValue}}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'addressItem',
  data () {
    return {
    };
  },
  props: ['item', 'dataList'], // 单选数据项 需绑定到界面
  created () {
    // console.log(this.isTestClassItem.isdanceValue, "单选数据项")
  },
  methods: {
    selectedItem (item) {
      this.dataList.forEach(p => {
        if (p.OLAPKey == item.OLAPKey) {
          p.isActive = !p.isActive;
        } else {
          p.isActive = false;
        }
      });
      this.$emit('sendItem', item);
      // this.$emit("closeIsShow")
    }
  }
};
</script>
<style >
.single_class_info {
  position: relative;
}
</style>