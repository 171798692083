<template>
  <!-- 已上架的优惠券 -->
  <div class="discount_coupon_manage_ul">
    <coupon-item v-for="(item,index) in couponList"
                 :key="index"
                 :dataInfo="item"
                 :dataIndex="index"
                 class="discount_coupon_manage_list"
                 @doAlreadyReceivedClick="doAlreadyReceivedClick"
                 @doHaveBeenUsedClick="doHaveBeenUsedClick"
                 @sendClick="sendClick"
                 @editClick="editClick"
                 @moreClick="moreClick"></coupon-item>
  </div>

</template>
<script>
import couponItem from './coupon-item';
export default {
  components: { couponItem },
  data () {
    return {

    };
  },
  props: {
    couponList: Array // 上架券列表
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doAlreadyReceivedClick (data) {
      this.$emit('doAlreadyReceivedClick', data);
    },
    doHaveBeenUsedClick (data) {
      this.$emit('doHaveBeenUsedClick', data);
    },
    sendClick (data) {
      this.$emit('sendClick', data);
    },
    editClick (data) {
      this.$emit('editClick', data);
    },
    moreClick (data) {
      this.$emit('moreClick', data);
    }
  }
};
</script>

