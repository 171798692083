<template>
  <div class="operation_procedure_box clearfix">
    <div class="operation_procedure_title fl">
      <div>操作</div>
      <div>流程</div>
    </div>
    <div class="operation_procedure_content fl">
      <div class="operation_procedure_progress">
        <!-- 进度条 -->
        <div :style="{width: vProgress}"
             class="progress_color"></div>
        <step-item v-for="(item,index) in processList"
                   :key="index"
                   :item="item"
                   @onClickEvent="clickEvent"></step-item>
      </div>

    </div>
  </div>
</template>

<script>
import stepItem from './item';
export default {
  data () {
    return {

    };
  },
  props: {
    processList: {
      type: Array,
      required: true,
      default: () => {
        return [{
          titile: '步骤一',
          stepName: '1',
          status: 'undo', // undo:未做, doing:正在做，done：已做,
          calssName: ''
        }];
      }
    }
  },
  components: {
    stepItem
  },
  computed: {
    vProgress () {
      let progress = 0;
      let findIndex = this.processList.findIndex(obj => { return obj.status == 'doing'; });
      if (findIndex < 0) {
        progress = 0;
      } else if (findIndex == 0) {
        progress = 25 + '%';
      } else if (findIndex >= 1 && findIndex < this.processList.length - 1) {
        progress = (Math.round(findIndex / (this.processList.length - 1) * 10000) / 100) + 25 + '%';
      } else {
        progress = (Math.round(findIndex / (this.processList.length - 1) * 10000) / 100) + '%';
      }
      return progress;
    }
  },
  methods: {
    clickEvent (item) {
      this.$emit('onClickEvent', item);
    }
  }

};
</script>

<style>
</style>