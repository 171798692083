<template>
  <div class="attract_investment_manage_signbill">
    <search-box :paramsForQuery="paramsForQuery"
                :batchAllotList="batchAllotList"
                :tableData="investorList"
                @searchClick="searchClick"
                @clearSearchParams="clearSearchParams"></search-box>
    <div class="table_list_content multi_header_teble">
      <table-list ref="tableListRef"
                  :key="'signBillList'"
                  :tableData="investorList"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="paramsForQuery"
                  @loadTableData="loadSigningInvestorList"></table-list>
      <!-- :selectable="false" -->
    </div>
    <custom-dialog :title="'作废原因'"
                   :visible.sync="isShowMessagePopup"
                   :before-close="hideMessagePopup">
      <remerk-popup :placeholder="'请输入作废原因'"
                    @onClickClose="hideMessagePopup"
                    @onClickConfirm="rejectRecommend"></remerk-popup>
    </custom-dialog>
  </div>
</template>

<script>
import tableList from '../../../../common/table-list'; // 下拉选择框
import searchBox from './search-box';
import remerkPopup from '../../../../report/crm-join-sign-manage/remerk-popup';
import {
  SearchPageHasSignTheBillInvestment,
  HQCancellation
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '签单日期',
          prop: 'ApplicantTime',
          width: 120,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          type: 'text-item',
          width: 200
        },
        {
          label: '投资人',
          prop: 'MainDemoName',
          type: 'text-item',
          width: 120
        },
        {
          label: '招商顾问',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '合同金额',
          prop: 'ContractAmount',
          type: 'number-item',
          width: 120
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 100,
          sortable: 'custom'
        },
        {
          label: '合同变更条款',
          prop: 'AmendingClause',
          width: 300,
          type: 'text-item'
        },
        {
          label: '订金',
          prop: 'EarnestAmount',
          type: 'number-item',
          width: 120
        },
        {
          label: '付款进度',
          prop: 'PaymentScheduleKeyValue',
          type: 'text-item',
          width: 100,
          extend: {
            setClassName: (data) => {
              // 1-已签单;2-付定中;3-已付定;4-尾款中;5-全款中;6-已全款
              let className = '';
              if (
                data.PaymentScheduleKey == 1 ||
                data.PaymentScheduleKey == 2
              ) {
                className = 'status_color_yellow';
              } else if (
                data.PaymentScheduleKey == 3 ||
                data.PaymentScheduleKey == 5 ||
                data.PaymentScheduleKey == 4
              ) {
                className = 'status_color_grass_green';
              } else if (data.PaymentScheduleKey == 6) {
                className = 'status_color_green';
              }
              return className;
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'left',
          width: 200,
          extend: {
            tableBtnGroup: [
              // 1-已签单;2-付定中;3-已付定;4-尾款中;5-全款中;6-已全款
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.lookUpDetail(rowData);
                  }
                }
              },
              {
                name: '付款',
                extend: {
                  click: (rowData) => {
                    if (
                      rowData.PaymentScheduleKey == 2 ||
                      rowData.PaymentScheduleKey == 4 ||
                      rowData.PaymentScheduleKey == 5 ||
                      rowData.PaymentScheduleKey == 6
                    ) {
                      layer.alert(
                        '当前处于' +
                        rowData.PaymentScheduleKeyValue +
                        '不可继续付款!'
                      );
                    } else {
                      this.paymentClick(rowData);
                    }
                  },
                  isHide: (rowData) => {
                    console.log('isHide', rowData.PaymentScheduleKey);
                    if (
                      rowData.PaymentScheduleKey == 2 ||
                      rowData.PaymentScheduleKey == 4 ||
                      rowData.PaymentScheduleKey == 5 ||
                      rowData.PaymentScheduleKey == 6
                    ) {
                      console.log('isHide:true', rowData.PaymentScheduleKey);
                      return true;
                    } else {
                      return false;
                    }
                  },
                  setClassName: (rowData) => {
                    // 1-已签单;2-付定中;3-已付定;4-尾款中;5-全款中;6-已全款
                    if (
                      rowData.PaymentScheduleKey == 2 ||
                      rowData.PaymentScheduleKey == 4 ||
                      rowData.PaymentScheduleKey == 5 ||
                      rowData.PaymentScheduleKey == 6
                    ) {
                      return 'status_spot_gray';
                    } else {
                      return '';
                    }
                  }
                }
              },
              {
                name: '作废',
                extend: {
                  click: (rowData) => {
                    if (rowData.PaymentScheduleKey != 1) {
                      layer.alert(rowData.PaymentScheduleKeyValue + '不可作废!');
                    } else {
                      this.openMessagePopup(rowData);
                    }
                  },
                  isHide: (rowData) => {
                    console.log('rowData.StatusKey', rowData.PaymentScheduleKey);
                    return rowData.PaymentScheduleKey != 1;
                  }
                }
              }
            ]
          }
        }
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: '', // 排序顺序
        CounselorKey: 0, // 投资顾问
        PaymentScheduleKey: 0, // 付款进度
        SourceTypeKey: 0, // 来源
        FranchiseTypeKey: 0, // 加盟方式1-单店;2-课程
        IsExportKey: 0, // 是否导出：0-否;1-是;如果为是pageIndex传0,pageSize传0
        jsonFormate: null
      },
      investorList: [], // 投资人列表

      totalNum: 0,
      defaultSort: {},
      batchAllotList: [], // 选择的投资人列表
      isBatchDistribute: false,
      selectInfo: {},
      isShowMessagePopup: false
    };
  },
  props: {
    curSelectedPrams: Object
  },
  components: {
    tableList,
    searchBox,
    remerkPopup
  },
  watch: {
    curSelectedPrams (nData, oData) {
      nData = nData || {};
      if (
        Object.keys(nData).length > 0 &&
        nData.PaymentScheduleKey != this.paramsForQuery.PaymentScheduleKey
      ) {
        this.paramsForQuery.PaymentScheduleKey = nData.PaymentScheduleKey;
        this.loadSigningInvestorList();
      }
    }
  },
  created () {
    if (
      this.curSelectedPrams &&
      Object.keys(this.curSelectedPrams).length > 0
    ) {
      this.paramsForQuery.PaymentScheduleKey =
        this.curSelectedPrams.PaymentScheduleKey;
    }
    this.loadSigningInvestorList();
  },
  mounted () {
    this.$dialog.register(this, ['crmPaymentForm', 'crmSignContractForm']);
  },
  methods: {
    // 获取已签单投资人列表
    loadSigningInvestorList () {
      SearchPageHasSignTheBillInvestment(this.paramsForQuery).then((result) => {
        console.log('获取已签单投资人列表', result.data.PageDataList);
        result.data.PageDataList.forEach((obj) => { });
        this.investorList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 点击搜索
    searchClick (queryParams) {
      this.paramsForQuery = queryParams;
      this.loadSigningInvestorList();
    },
    // 清除搜索条件
    clearSearchParams () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.paramsForQuery.CounselorKey = 0;
      this.paramsForQuery.PaymentScheduleKey = 0;
      this.paramsForQuery.SourceTypeKey = 0;
      this.paramsForQuery.FranchiseTypeKey = 0;
      this.loadSigningInvestorList();
    },
    // 付款
    paymentClick (item) {
      this.$dialog.open(this, {
        name: '付款申请', // 新增传付款申请， 审核传付款审核
        routerName: this.$route.name,
        moduleName: 'crmPaymentForm',
        data: { OLAPKey: item.OLAPKey, isReadonly: false, paymentType: 'add' }, // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
        callBack: {
          afterSucces: () => {
            this.loadSigningInvestorList();
          }
        }
      });
    },
    openMessagePopup (item) {
      this.selectInfo = item;
      this.isShowMessagePopup = true;
    },
    hideMessagePopup () {
      this.isShowMessagePopup = false;
    },
    // 作废
    rejectRecommend (text) {
      HQCancellation(this.selectInfo.AttractInvestSignContractKey, text).then(
        (res) => {
          this.loadSigningInvestorList();
          this.hideMessagePopup();
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    lookUpDetail (data) {
      console.log('lookUpDetail', data);
      // let dataitem = {
      //   OLAPKey: data.OLAPKey,
      // }
      // this.$dialog.open(this, {
      //   name: '签单详情',
      //   routerName: this.$route.name,
      //   moduleName: 'crmSignContractForm',
      //   data: { dataInfo: dataitem, isReadonly: true, showBtn: false },
      //   callBack: { afterSuccess: () => {} },
      // })
      this.$emit('showInvestorDetails', data);
    }
  }
};
</script>

<style >
.investor_manage_view {
  justify-content: space-between;
}
.recycle_bin {
  position: absolute;
  right: 20px;
  top: 30px;
  height: 40px;
  width: 80px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db url(../../../../../../public/image/recycled_icon.png)
    no-repeat 8px;
}

.attract_investment_manage_signbill .status_color_yellow,
.attract_investment_manage_signbill .status_color_grass_green,
.attract_investment_manage_signbill .status_color_green,
.attract_investment_manage_signbill .status_color_grass_yellow {
  position: absolute;
  width: 65px;
  height: 22px;
  border-radius: 2px;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  line-height: 22px;
  text-align: center;
  color: #333333;
  margin-left: 10px;
}
.attract_investment_manage_signbill .status_color_yellow {
  background: rgba(252, 239, 228);
}
.attract_investment_manage_signbill .status_color_grass_green {
  background: rgba(220, 245, 237);
}
.attract_investment_manage_signbill .status_color_green {
  background: rgba(120, 209, 181);
}
.attract_investment_manage_signbill .status_color_grass_yellow {
  background: #f9d7d9;
}
</style>