<template>
  <div class="margin_left_right_div">
    <div class="workbench_view template_minOrmax_width_div">
      <div class="workbench_view_box">
        <div class="workbench_view_left">
          <!-- 考勤日报  今日签到 -->
          <attendance-date @showDialog="showDialog"
                           @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog">
            <jz-quick-menu v-if="!isSysVersionTY"
                           :realtimeInfo="realtimeInfo"
                           @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></jz-quick-menu>
            <ty-quick-menu v-if="isSysVersionTY"
                           :realtimeInfo="realtimeInfo"
                           @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></ty-quick-menu>

          </attendance-date>

          <!-- 智能预警 -->
          <!-- <div class="workbench_view_warning massive_bg">
          <div class="workbench_view_title">
            <span>智能预警</span>
          </div>
          <div class="workbench_view_warning_content">
            <div class="warning_content_list"
                 v-for="(item,key) in vWarmingMenuList"
                 :key="key">
              <div class="list_box"
                   @click="toWarmingDetail(item)">
                <div class="list_box_top fl">
                  <span>{{item.name}}{{item.type!=5?'(人)':'(个)'}}</span>
                </div>
                <div class="list_box_bottom fr">
                  <span class="list_number">{{item.num}}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
          <!-- 直播课 -->
          <div class="workbench_view_live massive_bg"
               v-if="false">
            <div class="workbench_view_title">
              <span>直播课</span>
            </div>
            <div class="workbench_view_live_content clearfix">
              <div class="live_img fl">
                <img src="../../../public/image/live_img.png"
                     alt />
              </div>
              <div class="live_right fl">
                <div class="live_title">拓客治学的直播课名称</div>
                <div class="live_teacher">主讲老师：腾哥</div>
                <div class="live_text">拓客治学的直播课内容说明拓客治学的直播课内容说明 拓客治学的直播课内容说明。</div>
                <div class="live_date">首播时间：2020年1月16日</div>
              </div>
            </div>
          </div>
        </div>
        <div class="workbench_view_right">
          <div class="workbench_view_user massive_bg"
               @click="doAfterClickFunctionBtn(mine)">
            <div class="user_img">
              <!-- <img :src="headImg"
                 alt
                 :onerror="defaultImg" /> -->
              <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                                      :staffInfo="staffInfo"
                                      :formTitle="'员工头像:'"
                                      :imgHeight="300"
                                      :imgWidth="300"
                                      @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
            </div>
            <div class="user_name">
              嗨！{{staffInfo.MainDemoName}}
            </div>
            <div class="user_post">
              {{staffInfo.PostKeyValue||''}}
            </div>
            <div class="user_course user_course_text"
                 v-if="latelyCourseInfo.CourseNameKey>0">
              <span class="font_gray">最近的课：</span>
              {{vToday}} {{latelyCourseInfo.SClassTimeName}} {{latelyCourseInfo.CourseNameKeyValue}}
            </div>
            <div class="user_course"
                 v-else>
              <span class="font_gray">当前没有课</span>
            </div>
            <div class="user_btn"></div>
          </div>
          <todo-list ref="todoList"></todo-list>
          <!-- <instructions></instructions> -->
          <div class="workbench_view_news massive_bg">
            <div class="workbench_view_title clearfix form_info_field">
              <span>智能预警
                <input-form-tip :tips="'根据考勤，自动统计课时不足，欠课时等预警情况，点击可查看详情。'"></input-form-tip>
              </span>
            </div>
            <div class="workbench_view_news_content">
              <div class="news_list form_info_field"
                   v-for="(item,key) in vWarmingMenuList"
                   :key="key"
                   @click="toWarmingDetail(item)">
                <div class="help_list_inquiry clearfix">
                  <span>{{item.name}}{{item.type!=5?'(人)':'(个)'}}

                  </span>
                  <span class="news_list_btn">{{item.num}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <lastNew></lastNew> -->
        </div>
      </div>
      <div>
        <dialog-factory ref="indexViewDialogFactory"
                        :key="'indexView'"
                        :routerName="'indexView'"></dialog-factory>
        <dialog-factory-common ref="indexViewDialogFactoryCommon"></dialog-factory-common>
      </div>
      <div>
        <added-shortcut-start-setting ref="shortcutStartSetting"></added-shortcut-start-setting>
      </div>
      <div class="index_view_drawer"
           :class="isInstructions?'open':''">
        <!-- :class="'open'" -->
        <div class="drawer_open_btn">
          <div class="open_btn_speedy"
               v-if="$utils.getSaaSClientSet(57) == 1"
               @click.stop="doShortcutStartSettingClick">
            快捷启用系统
          </div>
          <!-- <div class="open_btn_instructions"
             @click.stop="showInstructions">
          使用问答
        </div> -->
        </div>
        <!-- 使用问答. -->
        <!-- @mouseout="closeInstructions" -->
        <div class="instructions_content"
             v-if="isInstructions"
             @click.stop="closeInstructions">
          <div class="instructions_content_header">目录</div>
          <new-instructions></new-instructions>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import attendanceDate from './attendance-date';
import coursesign from './course-sign';
import todoList from './todo-list';
import instructions from './instructions';
import lastNew from './last-new';
import dialogFactory from '../common/dialog-factory';
import jzQuickMenu from './jz-quick-menu';
import tyQuickMenu from './ty-quick-menu';
import newInstructions from './new-instructions';
import addedShortcutStartSetting from '../base-module/basis-setting-module/added-shortcut-start-setting/index';
import {
  getIndexReportRealtimeData,
  getToDoStatistics,
  GetTheEmployeeByID,
  GetTheEmployeeTeachingPercentage
} from '../../API/workbench';
export default {
  name: 'indexView',
  data () {
    return {
      isShow: false,
      realtimeInfo: {},
      isInstructions: false, // 使用问答
      warmingMenuList: [], // 预警统计列表
      WarmingStatisical: {},
      token: {},
      staffTeaching: {}, // 教学业绩
      latelyCourseInfo: {}, // 最后一节课
      staffInfo: {},
      mine: {
        moduleName: 'mine'
      },
      isShowNewCourseType: false,
      abnormalSign: false,  // 异常签到入口
      isAllShow: true,
      isShowBat: true
    };
  },
  components: {
    attendanceDate,
    coursesign,
    todoList,
    instructions,
    lastNew,
    dialogFactory,
    newInstructions,
    addedShortcutStartSetting,
    jzQuickMenu,
    tyQuickMenu
  },
  created () {
    this.token = this.$store.getters.token;
    this.warmingMenuList = this.$initJson.toDoWarningList;
    console.log('$utils.formatDateToLine', this.$utils.formatDateToLine(Date.new()));
    this.loadData();
  },
  mounted () {
    this.registerBusEvent();
  },
  activated () {
    let moduleName = this.$route.params.moduleName;
    if (moduleName) {
      this.openCustomDialogByFactoryDialog(this.$route.params);
    }
  },
  computed: {
    isSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vWarmingMenuList () {
      // let newArr = [];
      for (var i = 0; i < this.warmingMenuList.length; i++) {
        if (this.vHasModulePower(54)) {
          switch (String(this.warmingMenuList[i].type)) {
            case '1':
              this.warmingMenuList[i].num = this.WarmingStatisical.CountRenewalNum;
              break;
            case '2':
              this.WarmingStatisical.DebtClassHourInfo = this.WarmingStatisical.DebtClassHourInfo || {};
              this.warmingMenuList[i].num = this.WarmingStatisical.DebtClassHourInfo.CountDebtClassHourNum;
              break;
            case '3':
              this.warmingMenuList[i].num = this.WarmingStatisical.CountLeaveSchoolNum;
              break;
            case '4':
              this.warmingMenuList[i].num = this.WarmingStatisical.ArrangeWarningNum;
              break;
            case '5':
              this.warmingMenuList[i].num = this.WarmingStatisical.ClassScheduleNum;
              break;
            case '6':
              this.WarmingStatisical.DebtAmountInfo =
                this.WarmingStatisical.DebtAmountInfo || {};
              this.warmingMenuList[i].num = this.WarmingStatisical.DebtAmountInfo.DebtAmountNum;
              break;
            default:
              break;
          }
        } else {
          this.warmingMenuList[i].num = 0;
        }
      }
      return this.warmingMenuList;
    },
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vMendianCreateDateTime () {
      let presentDate = this.$utils.formatDateToLine(Date.new()); // 当天日期
      let newCreateDateTime = this.$store.getters.SaaSClientInfo.CreateDateTime;
      let getDateDiffDay = this.$utils.DateDiffDay(newCreateDateTime, presentDate);
      // console.log(getDateDiffDay, '门店开启日期');
      if (getDateDiffDay >= 0 && getDateDiffDay <= 30) {
        return true;
      } else {
        return false;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },

    vToday () {
      if (this.latelyCourseInfo.CourseNameKey) {
        return (
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'MM') +
          '月' +
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'dd') +
          '日 ' +
          ' ' +
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'ddddd')
        );
      }
    }
  },
  methods: {
    loadData () {
      // this.getRealtimeData();
      this.getWarmingStatisical();
      this.getStaffInfo(this.token.UserID);
      this.getStaffTeachingPercentage(this.token.UserID);
    },
    // 进入快捷启用
    doShortcutStartSettingClick () {
      if (this.$refs.shortcutStartSetting) {
        this.$refs.shortcutStartSetting.showShortcutStartSetting = true;
        this.$refs.shortcutStartSetting.loadData();
      }
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      this.staffInfo.XMLIcon = ImgURl;
    },
    getRealtimeData () {
      getIndexReportRealtimeData().then(result => {
        console.log(result, 'getRealtimeData:result');
        this.realtimeInfo = result.data;
        // 门店信息加上在校学生数 其他模块免得在调用接口
        let saasClientInfo = this.$store.getters.SaaSClientInfo;
        saasClientInfo.inSchollStudentNum = result.data.EnrolledNum;
        this.$store.commit('setSaaSClientInfo', saasClientInfo);
      });
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log('getStaffInfo', result.data);
        })
        .catch(error => {
          console.log('getStaffInfo', error);
        });
    },
    // 获取员工教学信息
    getStaffTeachingPercentage (OLAPKey) {
      GetTheEmployeeTeachingPercentage(OLAPKey).then(result => {
        console.log('获取员工教学信息', result.data);
        this.staffTeaching = result.data;
        this.latelyCourseInfo = result.data.Today.RecentClass || {};
      });
    },
    // 获取预警统计.
    getWarmingStatisical () {
      getToDoStatistics()
        .then(result => {
          this.WarmingStatisical = result.data;
          this.$store.commit('setWarmingStatisical', this.WarmingStatisical);
        })
        .catch(error => {
          console.log('getWarmingStatisical', error);
        });
    },
    // 异常预警点击
    toWarmingDetail (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        item.callBack = {
          afterSucces: () => {
            this.getWarmingStatisical();
          }
        };
        console.log(item, 'item');
        this.doAfterClickFunctionBtn(item);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    showDialog (val) {
      this.openCustomDialogByFactoryDialog({ moduleName: val });
    },
    doAfterClickFunctionBtn (item) {
      this.openCustomDialogByFactoryDialog({ moduleName: item.moduleName, callBack: item.callBack });
    },

    goRouter (item) {
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog(item);
      } else {
        if (item.routerName == 'studentManagementReport') {
          item.data = {
            headSummaryData: { title: '待排人数', className: 'font_blue', value: 0, type: 'Num_ToBeArranged', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }     // 待排课学生
          };
        }
        console.log('item', item);
        this.$bus.emit('needOpenRouter', item);
      }
    },
    closeAbnormalSign () {
      this.abnormalSign = false;
    },

    registerBusEvent () {
      this.$bus.on(this, 'switchHeaderBarToIndexView', () => {
        this.loadData();
        this.$refs.todoList.loadData();
      });
      this.$bus.on(this, 'wsTodoList', (data) => {
        console.log('getTodoStatisticsByStaff');
        this.getWarmingStatisical();
      });
      this.$bus.on(this, 'updateWarmingStatisical', (data) => {
        console.log('updateWarmingStatisical');
        this.getWarmingStatisical();
      });

      this.$bus.on(this, 'wsAfterUpdateAgentInfo', (data) => {
        this.staffInfo = data;
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'indexView') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'indexView') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.indexViewDialogFactory) {
        this.$refs.indexViewDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.indexViewDialogFactoryCommon) {
        this.$refs.indexViewDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    batchAddStudent () {
      this.openCustomDialogByFactoryDialog({ moduleName: 'batchAddOldStudent' });
    },
    // 显示使用问答.
    showInstructions () {
      this.isInstructions = !this.isInstructions;
      console.log(this.isInstruction, 'showInstructions');
    },
    // 关闭使用问答.
    closeInstructions () {
      this.isInstructions = false;
    }

  }
};
</script>

