<template>
  <div v-if="userStudentList&&userStudentList.length>0">
    <div class="student_phone_alter">
      <div class="student_phone_alter_title">
        手机号({{phoneNumber}})下的学生
      </div>
      <div class="student_phone_alter_text">
        该手机号下的学生手机号将一起修改。
      </div>
      <div class="student_phone_alter_ul">
        <div class="student_list"
             v-for="(item,key) in userStudentList"
             :key="key">
          <div class="student_photo"
               style="padding-left:0">
            <img :src="headImg(item)"
                 alt="">
          </div>
          <div class="student_name">
            {{item.MainDemoName}}
          </div>
          <div class="student_sex"
               :class="Number(item.SexKey)==2?'boy':'girl'"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'amendSingleItem',
  props: {
    phoneNumber: [String, Number], // 手机号码
    userStudentList: Array // 用户信息
  },

  data () {
    return {

    };
  },
  components: {},
  computed: {

  },
  created () {

  },
  methods: {

    headImg (item) {
      if (item.HeadImgSupport) {
        if (item.HeadImgSupport.indexOf('http') > -1) {
          return item.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + item.HeadImgSupport;
        }
      } else {
        if (Number(item.SexKey) == 2) {
          return (require('../../../../../public/image/deflut_studentPhoto_boy.png'));
        } else {
          return (require('../../../../../public/image/deflut_studentPhoto_gril.png'));
        }
      }
    }
  }
};
</script>

  <style>
</style>