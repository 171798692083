<template>
  <div class="search_result_cq search_result single_result_detail">
    <div class="search_result_content content_marginTop_30">
      <div class="search_result_list single_shadow content_marginRL ">
        <el-tree class="filter-tree"
                 :data="treeDate"
                 @check="firstEvent"
                 :show-checkbox="true"
                 :check-strictly="true"
                 :check-on-click-node="true"
                 :default-checked-keys="defaultCheckedKeys"
                 :default-expanded-keys="defaultExpandedKeys"
                 node-key="id"
                 :props="defaultProps"
                 :filter-node-method="filterNode"
                 ref="tree"></el-tree>
      </div>
    </div>
    <!-- <div class="content_marginRL coures_type_result form_info_line content_marginTop_8 single_shadow">
      <div class="form_info_field">
        <span>课名</span>
      </div>
      <div class="form_info_value">
        <span class="coures_type_name">
          <span>{{selectionValues}}</span>
        </span>
      </div>
    </div> -->

    <div class="content_marginRL coures_type_result form_info_line content_marginTop_8 single_shadow"
         style="padding: 0px 30px;"
         v-if="isRemarksShow?isRemarksShow:false">
      <input-text :formTitle="'处理原因'"
                  style="width: 100%;"
                  :readonly="false"
                  :formPlaceholder="'选填'"
                  :required="false"
                  v-model="courseRemarks"></input-text>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmSelectedValue"
                           @cancelClick="closeDialog"></save-cancel-btn-group>
  </div>
</template>
<script>
export default {
  name: 'mutilSelectTree',
  data () {
    return {
      treeDate: [],
      defaultCheckedKeys: [], // 默认选中节点
      defaultExpandedKeys: ['2'], // 默认展开节点
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectionValues: '',
      selectionKeys: '',
      selectedItems: { selectedIDs: [], selectedNames: [], levelPath: [] },
      courseRemarks: '' // 处理原因
    };
  },
  props: ['sourceDate', 'selectionDate', 'isRemarksShow'], // 单选数据项 需绑定到界面
  created () {
    this.initTreeDate();
  },
  computed: {
    selectionIDS () {
      if (this.selectionDate) {
        this.defaultCheckedKeys = this.selectionDate.IDs.split(',');
        this.defaultExpandedKeys = this.selectionDate.IDs ? this.selectionDate.IDs.split(',') : ['2'];
        this.selectionValues = this.selectionDate.Names;
      } else {
        if (this.$refs.tree) {
          this.$refs.tree.setCheckedKeys([]);
        }
        this.defaultCheckedKeys = [];
      }
      return this.selectionDate.IDs;
    }
  },
  watch: {
    sourceDate () {
      this.initTreeDate();
    },
    selectionDate () {
      // console.log(this.sourceDate);
      console.log(this.selectionDate, 'selectionDate');
    },
    selectionIDS: {
      deep: true,
      handler (nv, ov) {
        console.log(this.selectionDate);
        if (this.selectionDate) {
          if (nv != ov) {
            this.$refs.tree.setCheckedKeys([]);
          }
        }
      }
    }
  },
  methods: {
    confirmSelectedValue (callBack) {
      let selectionIDs = this.$refs.tree.getCheckedKeys();
      var checkedNodes = [];
      getCheckedIDSFromNode(this.treeDate, checkedNodes);
      // 通过递归 获取选中节点
      function getCheckedIDSFromNode (nodes, checkedNodes) {
        for (var i = 0; i < nodes.length; i++) {
          var hasChecked = false;
          // 若父级选中 直接获取父级ID
          if (
            selectionIDs.findIndex(o => {
              return o == nodes[i].id;
            }) > -1
          ) {
            checkedNodes.push(nodes[i]);
            hasChecked = true;
          }
          // 若父级未选中 在遍历子级ID是否选中
          if (nodes[i].children.length > 0 && !hasChecked) {
            getCheckedIDSFromNode(nodes[i].children, checkedNodes);
          }
        }
      }
      checkedNodes.forEach(obj => {
        this.selectedItems.selectedIDs.push(obj.id);
        this.selectedItems.selectedNames.push(obj.label);
        this.selectedItems.levelPath.push(obj.levelPath);
      });
      this.$emit('buttonClickEvent', this.selectedItems, callBack, this.courseRemarks
      );
    },
    closeDialog () {
      this.$emit('cancleClickForMutilSelected');
    },
    filterNode (value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    },
    // 选中项
    firstEvent (node, data) {
      var labvalojb = node; // 暂存选中节点
      console.log(node, data, 'firstEvent');
      const selectedPackageNodes = this.$refs.tree.getCheckedNodes();
      const that = this;
      this.selectionValues = '';
      this.selectionKeys = '';
      selectedPackageNodes.forEach(obj => {
        let findIndex = that.defaultCheckedKeys.indexOf(obj.id);
        if (findIndex < 0) {
          that.defaultCheckedKeys.push(obj.id);
        } else {
          that.defaultCheckedKeys.splice(findIndex, 1);
        }
        that.selectionValues = that.selectionValues == '' ? obj.label : that.selectionValues + ',' + obj.label;
        that.selectionKeys = that.selectionKeys == '' ? obj.id : that.selectionKeys + ',' + obj.id;
      });
      this.$refs.tree.setCheckedKeys([]); // 删除所有选中节点
      this.$refs.tree.setCheckedNodes([labvalojb]); // 选中已选中节点
      this.$emit('firstSelectTreeEvent', that.selectionKeys, that.selectionValues);
    },
    initTreeDate () {
      var initDate = this.$utils.parseJson(this.sourceDate);
      // 定义一个空数组
      var hash = [];
      var idField = 'OLAPKey';
      var parentField = 'FatherKey';
      var textField = 'MainDemoName';
      var typeKey = 'TypeKey';
      if (!initDate) {
        return hash;
      }
      for (var i = 0; i < initDate.length; i++) {
        var dataitem = initDate[i];
        var item = {
          id: dataitem[idField],
          parentId: dataitem[parentField],
          label: dataitem[textField],
          children: dataitem,
          typeKey: dataitem[typeKey]
        };
        // 以id为数组下标，获取值
        hash[item.id] = hash[item.id] || [];
        hash[item.parentId] = hash[item.parentId] || [];
        item.children = hash[item.id];
        hash[item.parentId].push(item);
      }
      // this.treeDate = hash[2] || [];
      this.treeDate = hash[0] || [];
    }
  }
};
</script>


