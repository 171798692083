<template>
  <div class="package_setting_fixed_bottom">
    <div>
      <div>
        <span class="font_black">{{btnInfo.type}}</span>
        <span class="font_yellow">￥{{btnInfo.count}}</span>
      </div>
      <div class="font_gray">
        {{btnInfo.explain}}
      </div>
    </div>
    <div class="btn_hover_bg_blue">
      <zx-button :btnClass="'combo_conflict_btn btn_hover_bg_blue'"
                 :btnStype="' '"
                 :btnText="btnInfo.btnName"
                 :isSingleSubmit="true"
                 :singleSubmitType="'dynamic'"
                 @click="doSubmitClick"></zx-button>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
import zxButton from '../../../../common/zx-button';
export default {
  components: { zxButton },
  data () {
    return {
    };
  },
  props: {
    btnInfo: {
      style: Object,
      default: function () {
        return {
          type: '类型',
          count: '数量',
          explain: '说明',
          btnName: '按钮名称'
        };
      }
    }

  },
  computed: {

  },
  created () {
  },
  methods: {
    // 确认
    doSubmitClick (callBack) {
      this.$emit('doSubmitClick', callBack);
    }
  }
};
</script>
<style>
</style>


