<template>
  <div class="right_title" v-if="coursewareInfo.MainDemoName">
    <div class="flex_row">
      <div class="title_name">
        {{ coursewareInfo.MainDemoName }}
      </div>
      <div class="" style="margin-right: 20px;" v-if="publishLogInfo.PublishTime">
        <span class="font_gray"
          >最新发布:{{ publishLogInfo.PublishTime }}
          {{ publishLogInfo.OperationKeyValue }}</span
        >
        <span :class="vPublishStatus"
              v-if="publishLogInfo.PublishTime">{{vshowPublishBtn}}</span>
      </div>
    </div>
    <div class="flex_row">
      <div class="flex_row">
        <span class="header_option_box_item">
          全部课件
          <span class="font_black"
            >{{ Number(coursewareInfo.CoursewareNum) || 0 }}课次</span
          >
        </span>
        <span class="header_option_box_item">
          占用空间
          <span class="font_black">{{
            this.$utils.capacityFormate(
              Number(coursewareInfo.OccupiedCapacity) || 0
            )
          }}</span>
        </span>

          <span
            class="status_type_orange"
            v-if="
              coursewareInfo.isPublishInKey == 1 && coursewareInfo.TypeKey == 0
            "
            >待审批</span
          >
          <span
            class="status_type_yellow"
            v-else-if="
              coursewareInfo.isPublishInKey == 0 &&
              coursewareInfo.PublishStatusKey == 1 &&
              coursewareInfo.IterationPublishStatusKey == 0 &&
              coursewareInfo.TypeKey == 0
            "
            >待发布</span
          >
          <span
            class="status_type_red"
            v-else-if="
              coursewareInfo.isPublishInKey == 0 &&
              coursewareInfo.PublishStatusKey == 0 &&
              coursewareInfo.TypeKey == 0
            "
            >未发布</span
          >
          <!-- <span
            class="status_type_red"
            v-if="
              Number(coursewareInfo.NotReleaseNum)>0
            "
            >有更新</span
          > -->
          <!-- <span :class="dataInfo.isOpt?'top_opt':'down_opt'"
                    v-if="dataInfo.TypeKey==2"></span> -->

      </div>
      <div class="flex_row">
        <div class="btn_hover_bg_blue title_btn button"
           :class="publishLogInfo.PublishStatus==0?'norole':''"
           @click="release"
           v-if="coursewareLength>0">{{vPublishBtn}} </div>
       
      </div>
    </div>
    <!-- <div class="title_text">
      课件：{{coursewareInfo.CoursewareNum}} &nbsp;&nbsp;| &nbsp;&nbsp; {{!coursewareInfo.OccupiedCapacity?'0B':this.$utils.capacityFormate(coursewareInfo.OccupiedCapacity)}}
    </div> -->

    <!-- <div v-if="publishLogInfo&&coursewareInfo.MainDemoName&&$zxClient.IsClient&& !coursewareInfo.type">
      <div class="btn_hover_bg_blue title_btn button"
           :class="publishLogInfo.PublishStatus==0?'norole':''"
           @click="release"
           v-if="coursewareLength>0">{{vPublishBtn}} </div>
      <div class="title_type">
        <span class="font_gray">{{publishLogInfo.PublishTime}} {{publishLogInfo.OperationKeyValue}}</span>
        <span :class="vPublishStatus"
              v-if="publishLogInfo.PublishTime">{{vshowPublishBtn}}</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import {
  CoursewarePackageManageFirstPublish, // 首次发布
  CoursewarePackageManageUpdatePublish, // 发布更新-总部
  CoursewarePackageManageUpdateWithdraw, // 撤回-总部
  CWSCoursewarePackageManageFirstPublish,
  CWSCoursewarePackageManageUpdatePublish,
  CWSCoursewarePackageManageUpdateWithdraw,
  hqCoursewarePackageManageFirstPublish, // 首次发布-总部
  hqCoursewarePackageManageUpdatePublish, // 发布更新-总部
  hqCoursewarePackageManageUpdateWithdraw, // 撤回-总部
} from "../../../API/workbench";
export default {
  data() {
    return {
      publishLogInfo: null,
    };
  },
  props: {
    coursewareInfo: Object,
    logInfo: {
      type: Object,
      default: {},
    },
    coursewareLength: Number,
  },
  created() {
    console.log("coursewareInfo",this.coursewareInfo)
    if (this.logInfo.PublishStatus) {
      this.publishLogInfo = this.logInfo;
    }
  },
  watch: {
    logInfo: {
      handler(n, o) {
        console.log(n, "发布按钮22");
        this.publishLogInfo = n;
      },
      // deep: true,
      immediate: true,
    },
  },
  computed: {
    vTypeKey() {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) {
        // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vPublishBtn() {
      if (Number(this.publishLogInfo.PublishStatus) == 2) {
        return "撤回";
      } else {
        return "发布";
      }
    },
    // 发布状态（2：发布中 1：可以发布 0：不能发布（按钮灰色））
    vPublishStatus() {
      if (this.publishLogInfo.PublishStatus == 2) {
        return "font_yellow";
      } else {
        return "font_green";
      }
    },
    vshowPublishBtn() {
      if (this.publishLogInfo.PublishStatus == 2) {
        return "发布中";
      } else {
        return "发布";
      }
    },
  },
  methods: {
    release() {
      if (this.publishLogInfo.PublishStatus == 0 || !this.$zxClient.IsClient) {
        // 不能发布
        return;
      }
      let apiFN = "";
      // PublishStatusKey	整型	发布状态(0-未发布;1-已发布)
      // isPublishInKey	整型	是否发布中(0-否;1-是)
      if (this.coursewareInfo.isPublishInKey == 1) {
        // 撤回
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSCoursewarePackageManageUpdateWithdraw;
        } else if (type == 5) {
          apiFN = hqCoursewarePackageManageUpdateWithdraw;
        } else {
          apiFN = CoursewarePackageManageUpdateWithdraw;
        }
        apiFN(this.coursewareInfo.ID).then(
          (result) => {
            this.coursewareInfo.isPublishInKey = 0;
            layer.alert("撤回成功");
            this.$emit("afterSuccess");
            console.log(result.data, "发布成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        );
      } else if (
        this.coursewareInfo.PublishStatusKey == 0 &&
        this.coursewareInfo.isPublishInKey == 0
      ) {
        // 发布
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSCoursewarePackageManageFirstPublish;
        } else if (type == 5) {
          apiFN = hqCoursewarePackageManageFirstPublish;
        } else {
          apiFN = CoursewarePackageManageFirstPublish;
        }
        apiFN(this.coursewareInfo.ID).then(
          (result) => {
            this.coursewareInfo.isPublishInKey = 1;
            layer.alert("发布成功");
            this.$emit("afterSuccess");
            console.log(result.data, "发布成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        );
      } else if (
        this.coursewareInfo.PublishStatusKey == 1 &&
        this.coursewareInfo.isPublishInKey == 0
      ) {
        // 更新
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSCoursewarePackageManageUpdatePublish;
        } else if (type == 5) {
          apiFN = hqCoursewarePackageManageUpdatePublish;
        } else {
          apiFN = CoursewarePackageManageUpdatePublish;
        }
        apiFN(this.coursewareInfo.ID).then(
          (result) => {
            this.coursewareInfo.isPublishInKey = 1;
            layer.alert("发布成功");
            this.$emit("afterSuccess");
            console.log(result.data, "发布成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.right_title {
  display: flex;
  flex-direction: column;
}
.flex_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  align-items: center;
  height: 36px;
}
.header_option_box_item {
  display: inline-block;
  border-radius: 3px;
  background: rgba(217, 223, 228, 0.4);
  color: #999;
  font-size: 14px;
  height: 24px;
  text-align: center;
  margin-right: 16px;
  padding: 0 10px;
  line-height: 24px;
}

.status_type_yellow {
   width: 66px;
  height: 24px;
  border-radius: 5px;
  background: #ffeed0;
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.status_type_orange {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: rgba(255, 122, 0, 0.2);
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.status_type_red {
  width: 66px;
  height: 24px;
  border-radius: 5px;
   background: rgba(248, 213, 213, 1);
  color: rgba(217, 45, 45, 1);
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
</style>