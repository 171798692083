<template>
  <div class="form_info_detail">
    <div class="defult_dialog_searchBar content_marginTop_30 content_marginRL">
      <div class="searchBar_content">
        <div class="search_type">{{searchInitJson.name}}</div>
        <div class="search_content"
             @click.stop="searchClick">
          <span>请搜索</span>
          <div class="add_class_info"
               :class="{norole:!vHasModulePower}"
               v-if="searchInitJson.isShowAddBtn"
               @click.stop="addClick"
               :title='message'>
          </div>
        </div>

        <div class="course_student_attendance_operation module_content_menuList"
             :style="{ top: 50 + 'px', left: 140 + 'px' }"
             v-if="isShowItem">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="staffMenu"
                         @clickOptionForAlertDialog="onItemClick"></drop-down-box>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import dropDownBox from './drop-down-box';
export default {
  name: 'searchBox',
  data () {
    return {
      searchFormDialogVisible: true,
      searchResultSingleDialogVisible: true,
      message: this.butMessage, // 是否提示文字，不给默认不提示
      buttonShow: this.classButton, // 确定取消按钮,默认为false
      staffMenu: [
      {
            type: 1,
            name: '学生',
            eventName: 'edit',
            isShow: true
           
          },
          {
            type: 2,
            name: '线索',
            eventName: 'reinstated',
            isShow: true
          }
      ], // 操作菜单项
      isShowItem: false
    };
  },
  props: ['searchInitJson', 'classButton', 'butMessage','isShowSeachItem'],

  computed: {
    vPowerCode () {       // 根据传入的目标学生或目标班级判断新增时的权限.
      let powerCode = 0;
      if (this.searchInitJson) {
        if (this.searchInitJson.name.indexOf('学生') != -1) {
          powerCode = 48;
        } else if (this.searchInitJson.name.indexOf('班级') != -1) {
          powerCode = 45;
        }
      }
      return powerCode;
    },
    vHasModulePower () {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, this.vPowerCode);
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
  },
  components:{
    dropDownBox
  },
  mounted () {
      // 点击别处时弹框自动收回
      document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowItem = false;
      },
      false
    );
  },
  created () {

  },
  methods: {
    // 点击搜索 通知父组件
    searchClick () {
      let locaItem = this.vMendianInfo.SaaSClientSetting.find(o => {
              return o.SetTypeKey == 17&&o.SetKey==1;
            });
      if(locaItem&&locaItem.SetContent == '2.0'&&this.isShowSeachItem == 1){
        this.isShowItem=true;
        }else{
          this.$emit('searchClick');
        }
    },
    onItemClick(e){
        this.$emit('searchClick',e.type );
        this.isShowItem=false;
    },
    selectedStudent (item) {
      this.$emit('onSelectedStudent', item);
    },
    addClick () {
      if (this.vHasModulePower) {
        this.$emit('addClick');
      } else {
        this.$utils.CheckModulePowerToTips(this.vPowerCode);
      }
    },
    // 确定按钮
    ClickSubmitEvent () {
      layer.alert('请选择班级再确认');
    },
    // 取消按钮
    cancleBtnClick () {
      this.$emit('CloseDialog');
    }
  }
};
</script>

  <style>
.addClass {
  position: absolute;
  right: -12px;
  top: -8px;
  width: 59px;
  height: 30px;
  color: white;
}
.addClass:hover {
  color: lightblue;
}
</style>

