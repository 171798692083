<template>
  <!-- 评论内容-->
  <div class="JXT_dynamic_comment">
    <!-- 评论头像-->
    <div class="JXT_dynamic_comment_overview"
         v-if="dataInfo.PraiseList.length>0"
         @click="doClickImageList">
      <browse-remark-head-img-item v-for="(item,index) in dataInfo.PraiseList"
                                   :key="index"
                                   :imageMaxIndex="index"
                                   :imageHeadItem="item"></browse-remark-head-img-item>
      <div class="overview_list"
           v-if="dataInfo.PraiseList.length>9">. . .</div>
      <div class="overview_btn">
      </div>
    </div>
    <!-- 评论区-->
    <div v-if="dataInfo.ReplyList.length>0">
      <reply-content :replyInfo="dataInfo.ReplyList" 
                     :IsAllSchool="IsAllSchool"
                     @toggleReply="toggleReply"></reply-content>
    </div>
  </div>
</template> 

<script>
import browseRemarkHeadImgItem from '../common-component/browse-box/browse-remark-head-img-item';
import replyContent from './reply-content/index';
export default {
  components: {
    browseRemarkHeadImgItem,
    replyContent
  },
  data () {
    return {
      replyStutas: true
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: {}
    },
    IsAllSchool: {
      type: Number,
      default: 2
    } // 是否切换全校模式，模式 1-全校;2-普通
  },
  computed: {

  },
  methods: {
    toggleReply (item, replyType) {
      this.$emit('toggleReply', item, replyType);
    },
    replyCompleted () {
      this.replyStutas = false;
    },
    // 显示点赞的头像列表
    doClickImageList () {
      console.log('显示点赞的头像列表');
      this.$emit('doClickImageList');
    }

  }
};
</script>

<style>
</style>