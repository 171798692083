<template>
  <!-- 设置详情按钮弹窗 -->
  <div class="staff_manage_list"
       :class="{ abnormal_dimission_icon: item.IsLeaveKey == 0 }"
       v-if="vShowDimissionStaff">
    <div class="list_img fl">
      <img :src="staffImg(item.XMLIcon)"
           :onerror="defaultImg"
           style="border-radius: 50px" />
      <!-- 上传 -->
      <input type="file"
             title=""
             name="file"
             ref="uploading"
             @change="selectImg($event, item)"
             class="img-logo_YG"
             data-type="userIconImg" />
    </div>
    <div class="fl"
         @click.stop="doYaunGongClick(item,index,$event)">
      <div class="list_name text_blue"
           v-if="item.SexKey == 2">
        <span>{{ item.MainDemoName }}</span>
      </div>
      <div v-else-if="item.SexKey == 3"
           class="list_name text_red">
        <span>{{ item.MainDemoName }}</span>
      </div>
      <div v-else
           class="list_name">
        <span>{{ item.MainDemoName }}</span>
      </div>
      <div class="list_phone">{{ item.Mobile }}
        <img :src="vWxImg"
             alt=""
             class="list_wxicon"
             :title="vTitle">
      </div>
      <div class="list_job">{{ item.PostKeyValue }}</div>
    </div>
  </div>
</template> 

<script>
export default {
  props: [
    'item', // 菜单项
    'index', // 当前菜单操作的数据对象
    'isShowDimissionStaff'
  ],
  data () {
    return {
    };
  },

  created () {
  },
  components: {

  },
  watch: {

  },
  computed: {
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vShowDimissionStaff () {
      if (this.isShowDimissionStaff) {
        return Number(this.item.IsLeaveKey) == 1;
      } else {
        return Number(this.item.IsLeaveKey) == 1 || Number(this.item.IsLeaveKey) == 0;
      }
    },
    vWxImg () {
      if (this.item.SubscribeKey == '0') {
        return '';
      } else {
        return '../../../../../public/image/wx_icon.png';
      }
    },
    vTitle () {
      if (this.item.SubscribeKey == '0') {
        return '未关注';
      } else {
        return '已关注';
      }
    }
  },
  methods: {
    staffImg (Img) {
      if (!Img) {
        Img = '';
      }
      if (Img.indexOf('http') > -1) {
        return Img;
      } else {
        return this.$store.getters.CDNURL + Img;
      }
    },
    selectImg (e, item) {
      this.$emit('selectImg', e, item, () => {
        this.$refs.uploading.value = '';
      });
      this.setInputValue();
    },
    // 选择员工项
    doYaunGongClick (item, index, e) {
      this.$emit('doYaunGongClick', item, index, e);
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      console.log(document.getElementsByClassName('img-logo_YG'), "document.getElementsByClassName('img-logo_YG')");
      if (document.getElementsByClassName('img-logo_YG')[this.index]) {
        var loadInput = document.getElementsByClassName('img-logo_YG')[this.index];
        loadInput.value = '';
      }
    }
  }
};
</script>

  <style>
.img-logo_YG {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}
</style>