<template>
  <div :id="echartID" class="echartbox"></div>
</template>

<script>
export default {
  name: 'echartPie',
  data () {
    return {
      echart: null
    };
  },
  props: {
    echartID: String,
    dataList: Array
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      var option = {
        grid: {
          left: '0%',
          right: '10%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          min: 0,
          max: 100,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        yAxis: {
          type: 'category',
          data: ['转发率', '已读率', '发送率']
        },
        series: this.dataList,
        legend: {
          orient: 'horizontal',
          left: 'center',
          bottom: 10,
          itemGap: 20,
          itemWidth: 20,
          itemHeight: 20,
          textStyle: {
            fontSize: 14,
            lineHeight: 20
          },
          selectedMode: false
        }
      };
      this.echart.setOption(option);
    }

  }
};
</script>