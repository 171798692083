<template>
  <div class="">
    <div class="student_management_engineer">
        <teacher-report-list 
            :seletedItem="seletedItem" 
            ref="teacherReportList">
        </teacher-report-list>
    </div>
    <div>
      <dialog-factory ref="studentManagementEngineerWorkbench"
                      :routerName="'studentManagementEngineerWorkbench'"></dialog-factory>
      <dialog-factory-common ref="studentManagementEngineerWorkbenchFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import teacherReportList from './teacher-report-list'
export default {
  name: 'studentManagementEngineerWorkbench',
  data () {
    return {
      generationTime: '',
      seletedID:0,
      seletedItem:null,
      teacherList:[
      ]
    };
  },
  components: {
    dialogFactory,
    teacherReportList

  },
  created () {
    let obj={
      OLAPKey:this.$store.getters.token.UserID,
      MainDemoName:this.$store.getters.token.UserName,
    }
    this.seletedItem=obj
    // this.getTeacherList()
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'studentManagementEngineerWorkbench') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'studentManagementEngineerWorkbench') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });

  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentManagementEngineerWorkbench) {
        this.$refs.studentManagementEngineerWorkbench.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentManagementEngineerWorkbenchFactoryCommon) {
        this.$refs.studentManagementEngineerWorkbenchFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
 
 
  }
};
</script>
<style scoped>
.student_management_engineer{
    min-height: 880px;
      margin:  0px 20px !important;
    height: 100%;
    background: white;
    margin: auto;
    /* padding-top: 20px; */
    display: flex;
    flex-direction: row;
}
@media screen and (min-width: 1921px) {
  .student_management_engineer {
      max-width: 1920px;
      margin: auto !important;
 
   }
 }
</style>
