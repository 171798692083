<template>
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      <div class="title_name">
        <span>课程</span>
        <!-- <div class="title_edit">

        </div> -->
      </div>
      <div class="title_btn btn_hover_bg_blue"
           @click.stop="showHistory">
        历史课程
      </div>
    </div>
    <div class="WKT_index_from_content">
      <div class="WKT_course_info">
        <enable-item v-for="(item,key) in enableList"
                     :key="key"
                     :item="item"
                     :index="key"
                     :dataList="enableList"
                     @moveIndex="moveIndex"
                     @edit="edit"
                     @remove="remove"></enable-item>
        <div class="WKT_course_info_list">
          <div class="WKT_information_block "
               >
            <div class="WKT_slideshow_upload_box hover_border_blue" @click.stop="showCourseNameListDialog">
              <div class="WKT_slideshow_upload_noImg">
                <div class="upload_icon">+</div>
                <div>添加课程</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出层 -->
    <custom-dialog :title="addTitle"
                   width="410px"
                   :visible.sync="isAddComboShow"
                   :before-close="doAddComboCloseClick">
      <add ref="add"
           :dataList="enableList"
           :item="seletedItem"
           @addAfter="onStartComboClick"
           @close="doAddComboCloseClick"
           @showCourseNameListDialog="showCourseNameListDialog"></add>
    </custom-dialog>
    <custom-dialog title="选择课程"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeCourseNameList">
      <single-selection-list :dataSourceList="courseNameList"
                             :selectedKey="seletedItem.CourseKey"
                             :placeholder="'请选择课程'"
                             :required="true"
                             @onSelectedItem="doAfterSelectionCourseName"></single-selection-list>
    </custom-dialog>
    <!-- <custom-dialog :title="'修改名称'"
                   width="436px"
                   :visible.sync="isEditComboShow"
                   :before-close="doEditComboCloseClick">
      <edit :item="seletedItem"
            @editAfter="onStartComboClick"
            @close="doEditComboCloseClick"></edit>
    </custom-dialog> -->
    <custom-dialog :title="'历史课程'"
                   width="436px"
                   :visible.sync="isHistoryComboShow"
                   :before-close="doHistoryComboCloseClick">
      <combo-history-list @enableAfter="onStartComboClick"></combo-history-list>
    </custom-dialog>
  </div>
</template>

<script>
import enableItem from './enable-item';
import edit from './combo-list/edit';
import add from './combo-list/add';
import comboHistoryList from './combo-list/combo-history-list';
import { SelectEnableCourse, MoveCourse, StopCourse, getCourseTypeNameListX } from '../../../API/workbench';
export default {
  data () {
    return {
      // 查询套餐
      searchObj: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      enableList: [],
      courseNameList: [],
      seletedItem: {},
      isHistoryComboShow: false, // 历史套餐列表显示
      isEditComboShow: false,   // 修改显示
      isAddComboShow: false,   // 新增显示
      isShowCourseNameList: false,
      addTitle: ''
    };
  },
  components: {
    enableItem, comboHistoryList, edit, add
  },
  props: {

  },
  created () {
    this.getCourseNameList();
    this.getEnableStarStudents(this.searchObj);
  },
  methods: {
    getEnableStarStudents (searchObj) {
      this.enableList = [];
      SelectEnableCourse(searchObj).then(result => {
        this.enableList = result.data;
        console.log('SelectEnableStarStudents', result.data);
      });
    },
    // 修改
    edit (item) {
      this.seletedItem = this.$utils.parseJson(item);
      this.addTitle = '课程设置';
      this.isAddComboShow = true;
      console.log('edit', item);
    },
    // 删除
    remove (item) {
      layer.confirm('确实是否删除该课程', {
        btn: [{
          name: '取消',
          callBack: () => {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: () => {
            StopCourse(item.id).then(result => {
              layer.alert('删除成功');
              this.getEnableStarStudents(this.searchObj);
            }).catch(error => {
              console.log('StopStarStudents', error);
            });
            console.log('remove', item);
            layer.close();
          }
        }]
      });
    },
    moveIndex (item, index) {
      MoveCourse(item.id, index).then(result => {
        console.log('MoveStarStudents', result);
        this.getEnableStarStudents(this.searchObj);
      }).catch(error => {
        console.log('MoveStarStudentsERR', error);
      });
    },
    showHistory () {
      this.isHistoryComboShow = true;
    },
    // 关闭课程列表
    closeCourseNameList () {
      this.isShowCourseNameList = false;
    },
    // 打开课程
    showCourseNameListDialog () {
      if (!this.isAddComboShow) {
        this.seletedItem = {};
      }
      this.isShowCourseNameList = true;
    },
    // 选中列表
    doAfterSelectionCourseName (item) {
      console.log(item, 'doAfterSelectionCourseName');
      this.seletedItem.CourseKeyValue = item.MainDemoName;
      this.seletedItem.CourseKey = item.OLAPKey;
      this.addTitle = '课程设置';
      this.closeCourseNameList();
      this.isAddComboShow = true;
      this.$nextTick(o => {
        this.$refs.add.doAfterSelectionCourseName(this.seletedItem);
      });
    },

    // 关闭历史列表
    doHistoryComboCloseClick () {
      this.isHistoryComboShow = false;
    },
    // 启用修改新增都触发.
    onStartComboClick () {
      this.getEnableStarStudents(this.searchObj);
    },
    doEditComboCloseClick () {
      this.isEditComboShow = false;
    },
    doAddComboCloseClick () {
      this.seletedItem = {};
      this.isAddComboShow = false;
    },
    getCourseNameList () {
      getCourseTypeNameListX('MainDemoName', 'asc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
      });
    }
  }
};
</script>

<style>
</style>