<template>
  <div class="attendance_course_info_list">
    <div class="list_left">
      <div class="list_date">
        {{vGenerateTime}} @{{item.CreatorKeyValue}}
      </div>
      <div class="list_time no_hidden">
        备注: {{item.RemarkSupport}}
      </div>
    </div>
    <div class="list_number">
      <span class="list_text">{{vDeductCountType}}</span>
      <span class="list_number">{{Number(item.DeductCount)>0?'-':'+'}}{{Math.abs(Number(item.DeductCount))}}{{vUnitKey}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  created () {

  },
  computed: {
    vGenerateTime () {
      return this.$utils.formatDateStr(this.item.GenerateTime, 'yyyy-M-dd hh:mm');
    },
    vUnitKey () {
      if (this.item.ClassUnitKey != 5) {
        return '天';
      } else {
        return '';
      }
    },
    // DeductTypeKeyValue	整型	4-过期;5-延期;6-年卡;7-调整;8-转出;9-转入;11-退课;12-导入
    vDeductCountType () {
      var text = '';
      switch (Number(this.item.DeductTypeKey)) {
        case 4:
          text = '课时过期';
          break;
        case 5:
          text = '课时延期';
          break;
        case 6:
          text = '课时过期';
          break;
        case 7:
          if (this.item.ClassUnitKey != 5) {
            if (Number(this.item.DeductCount) < 0) {
              return '课时延期';
            } else {
              return '课时过期';
            }
          } else {
            text = '调整课时';
          }
          break;
        case 8:
          text = '转出';
          break;
        case 9:
          text = '转入';
          break;
        case 11:
          text = '退课退款';
          break;
        case 12:
          text = '导入';
          break;
        default:
          break;
      }
      return text;
    }
  },
  methods: {
    goRouter () {
      console.log(this.item, 'goRouter');
    }
  }
};
</script>

<style>

</style>
