<template>
  <div class="submit_tip">
    <div>
      <div class="submit_type_img"></div>
      <div class="submit_type_text">提交成功</div>
      <div>
        <span>请通知财务审核。</span> 
      </div>
    </div>
    <div class="submit_tip_btn">
      <el-button round
                 class=" btn_light_blue btn_hover_bg_blue"
                 @click.stop="closedsucceedPayment">完成</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'succeedPayment',
  data () {
    return {

    };
  },
  methods: {
    closedsucceedPayment () {
      this.$emit('closedsucceedPayment', false);
    }
  }
};
</script>
