<template>
    <div class="home_page">
        <!--  :style="{'transform': 'scaleY('+scaleY+')'}" -->
        <div class="home_page_left_contont" >
            <!-- $utils.getSaaSClientSet(57) == 1 -->
            <quick-enable v-if="showQuickEnable&&$utils.getSaaSClientSet(57) == 1" @closeQuickEnable="hiddenQuickEnable"></quick-enable>
            <quick-options></quick-options>
            <premium-recommendation></premium-recommendation>
        </div>
        <right-content></right-content>
        <div>
            <dialog-factory ref="homePageDialogFactory"
                            :key="'homePage'"
                            :routerName="'homePage'"></dialog-factory>
            <dialog-factory-common ref="homePageDialogFactoryCommon"></dialog-factory-common>
        </div>
    </div>
</template>
<script>
import dialogFactory from '../common/dialog-factory';
import rightContent from './right-content'
import quickEnable from './components/quick-enable'
import quickOptions from './components/quick-options'
import premiumRecommendation from './components/premium-recommendation'
import { SyncIfOpenQuickStart } from "../../API/workbench";
export default {
    name: "homePage",
    data(){
        return{
            showQuickEnable:true,
            scaleY:1,
            screenWidth:0
        }
    },
    components:{
        quickEnable,rightContent,quickOptions,premiumRecommendation,dialogFactory
    },
    created(){

    },
    mounted(){
        console.log("document.body.clientWidth22",document.body.clientWidth)
        let screenWidth=document.body.clientWidth
        this.scaleY=screenWidth<=1360?1:((screenWidth-432)/(1360-432))
            if(this.scaleY>1.2){
                this.scaleY=1.2
            }
        this.$bus.on(this, 'screenOnResize', (screenWidth) => {
            console.log("screenOnResize",screenWidth)
            this.scaleY=screenWidth<=1360?1:((screenWidth-432)/(1360-432))
            if(this.scaleY>1.2){
                this.scaleY=1.2
            }
            //432 //padding+员工宽度
        });
    
        this.registerBusEvent()
    },
    activated() {
        let moduleName = this.$route.params.moduleName;
        if (moduleName) {
            this.openCustomDialogByFactoryDialog(this.$route.params);
        }
    },
    computed:{
       
    },
    methods:{
        hiddenQuickEnable(){
            this.showQuickEnable=false
            SyncIfOpenQuickStart(0).then(result => {
                console.log(result.data);
                let item57 = {
                SetKey: 0,
                SetKeyValue: '否',
                SetContent: '',
                SetTypeKey: 57
                };
                this.$store.getters.SaaSClientInfo.SaaSClientSetting = this.$utils.setSaaSClientSet(57, item57);
                // this.$emit('doElSwitch', result.data);
            }, error => {
                layer.alert(error.msg);
            });
        },
        registerBusEvent () {
            this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
                console.log("openCustomDialog",dialogInfo.routerName)
                if (dialogInfo.routerName == 'homePage') {
                this.openCustomDialogByFactoryDialog(dialogInfo);
                }
            });
            this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
                      console.log("openCommonCustomDialog",dialogInfo.routerName)
                if (dialogInfo.routerName == 'homePage') {
                this.openCommonCustomDialogByFactoryDialog(dialogInfo);
                }
            });
        },
        openCustomDialogByFactoryDialog (dialogInfo) {
            if (this.$refs.homePageDialogFactory) {
                this.$refs.homePageDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
            }
        },
        openCommonCustomDialogByFactoryDialog (dialogInfo) {
            if (this.$refs.homePageDialogFactoryCommon) {
                this.$refs.homePageDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
            }
        },
    }
}
</script>
<style >
#homePage {
  width: 100%;
     height: auto;
    min-height: 100%;
  display: flex;
  padding-bottom: 20px;
}
.home_page{
    margin:0 auto;
    max-width: 1850px;
    min-width: 1296px;
    background: white;
    height: auto;
    flex: 1;
    /* margin-bottom: 35px; */
    padding: 30px;
    display: flex;
    flex-direction: row;
    transform-origin: top left;
}
.home_page_left_contont{
    flex: 1;
    /* background: yellow; */
    padding:0 30px;
    transform-origin: top left;
}
</style>