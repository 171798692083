<template>
  <!-- 授权管理 -->
  <div class="flow_packet_report">
    <!-- 头部 -->
    <prepare-lessons-head :titleList="authorizationHead"
                          @chgTitleList="chgTitleList"></prepare-lessons-head>
    <div class="flow_packet_total">
      <!-- v-if="vmdInfo.EditionKey==5" -->
      <div class="mendian_backstage_search">
        <div class=""
             style="margin-right:10px;color:#999;font-size:12px">剩余流量总计</div>
        <div class=""
             style="margin-right:30px;font-size:20px">{{GeneralFluxCount}}</div>
        <div class="btn_hover_bg_blue search_btn "
             style="line-height: 24px;height: 24px;width: 50px;margin-top: 6px;"
             @click.stop="toBuy">购买</div>
      </div>
    </div>
    <div class="course_statistics_table_search flex">
      <date-range ref="saveDate"
                  style="align-items: center;margin-right: 20px;"
                  :searchObj="searchObj"
                  :fromTitle="'购买时间'"
                  @changeDate="changeSaveDate"></date-range>
      <div class="table_select_box"
           v-if="vmdInfo.EditionKey!=5"
           style="margin-right: 20px; min-width: 192px">
        <div class="table_select_title">流量包类型</div>
        <el-select :value="vTypeKeyValue"
                   placeholder="请选择"
                   style="width: 115px"
                   ref="resetText"
                   @change="selectFluxPackageType">
          <el-option v-for="item in TypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="width_small course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <!-- <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :vFooterTotal="vFooterTotal"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getDataList"></list> -->
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="vTableCourseColumns"
                  :key="tableKey"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import prepareLessonsHead from '../../head-quarters/campus-prepare-lessons-examine/common/prepare-lessons-head';
import {
  WEBVGetFluxPackageForList, CWSGetFluxPackageForList, HQGetFluxPackageForList
} from '../../../API/workbench';
// import dialogFactory from '../../common/dialog-factory/index';
export default {
  components: {
    prepareLessonsHead,
    tableList,
    inputSearchContainBtn
    // coursewareDetails
  },
  data () {
    return {
      authorizationHead: [
        { name: '有效流量包', key: 1, isSelected: true },
        { name: '无效流量包', key: 0, isSelected: false }
      ],
      TypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '通用',
          value: 1
        },
        {
          label: '定向',
          value: 2
        },

      ],
      // 查询参数
      searchObj: {
        startTime: '',//this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        endTime: '',//this.$utils.getCurrentMonthLast(), //	字符串	必须		数据源：GET	结束时间
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        sequence: 'DESC',
        orderBy: '',
        IsFluxPackage: 1, //1 有效流量包 0 无效流量包
        FluxPackageType: 0,   //1通用 2定向
        FluxPackageTypeValue: '全部'
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      GeneralFluxCount: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '编号',
          prop: 'FPNumer',
          minWidth: 160,
          align: 'left',
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '流量包类型',
          prop: 'TypeKeyValue',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '购买时间',
          prop: 'GenerateTime',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '失效时间',
          prop: 'ExpiredTime',
          sortable: 'custom',
          minWidth: 140,
          width: ' ',
          type: 'textItem'

        },
        {
          label: '购买数量',
          prop: 'NumCount',
          minWidth: 88,
          sortable: 'custom',
          align: 'left',
          type: "textItem"
        },
        {
          label: '剩余数量',
          prop: 'ResidueCount',
          minWidth: 88,
          sortable: 'custom',
          align: 'left',
          type: "textItem"
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 68,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '明细',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 0
              }

            ]
          }
        }
      ],
      HQtableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '编号',
          prop: 'FPNumer',
          minWidth: 160,
          align: 'left',
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '购买时间',
          prop: 'GenerateTime',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '失效时间',
          prop: 'ExpiredTime',
          sortable: 'custom',
          minWidth: 140,
          width: ' ',
          type: 'textItem'

        },
        {
          label: '购买数量',
          prop: 'NumCount',
          minWidth: 88,
          sortable: 'custom',
          align: 'left',
          type: "textItem"
        },
        {
          label: '剩余数量',
          prop: 'ResidueCount',
          minWidth: 88,
          sortable: 'custom',
          align: 'left',
          type: "textItem"
        },
        {
          label: '购买人',
          prop: 'OperatorKeyValue',
          minWidth: 88,
          sortable: 'custom',
          align: 'left',
          type: "textItem"
        },
        {
          label: '购买金额',
          prop: 'RecableAmount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'amountItem',
          decimalPlace: 2,
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 68,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '明细',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 0
              }

            ]
          }
        }
      ],
    };
  },
  created () {
    this.getDataList()
  },
  mounted () {
    this.$dialog.register(this,
      [
        'flowPacketDetailReport',
        'rechargeTheBalance',
        'HQflowPacketReport'
      ]);
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.GenerateTime = o.GenerateTime.substring(0, 10)
        o.ExpiredTime = o.ExpiredTime.substring(0, 10)
        o.NumCount = this.$utils.flowFormate(o.NumCount)
        o.ResidueCount = this.$utils.flowFormate(o.ResidueCount)
        o.TypeKeyValue = o.TypeKey == 2 ? o.SaaSClientKeyValue : '通用流量';
        newArr.push(o);
      });
      return newArr;
    },
    vTableCourseColumns () {
      if (this.vmdInfo.EditionKey == 5) {
        return this.HQtableCourseColumns
      } else {
        return this.tableCourseColumns
      }
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo
    },
    vTypeKeyValue () {
      return this.searchObj.FluxPackageType == 0
        ? "全部"
        : this.searchObj.FluxPackageTypeValue;
    },
  },
  methods: {
    getDataList () {
      let fn = ''
      // // 2标准 3旗舰 4高级 5总部 6课件
      console.log("this.vmdInfo", this.vmdInfo)
      if (this.vmdInfo.EditionKey == 6) {
        fn = CWSGetFluxPackageForList
      } else if (this.vmdInfo.EditionKey == 5) {
        fn = HQGetFluxPackageForList
      } else {
        fn = WEBVGetFluxPackageForList
      }
      fn(this.searchObj).then(result => {
        console.log(result.data, 'WEBVGetFluxPackageForList')
        this.dataReportList = result.data.PageDataList
        this.totalNum = result.data.Total;
        this.GeneralFluxCount = this.$utils.flowFormate(result.data.GeneralFluxCount)
      }).catch(error => {
        console.log('errorWEBVGetFluxPackageForList', error.msg);
      })
    },
    clearSearchCondition () {
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.FluxPackageTypeValue = '全部'
      this.searchObj.FluxPackageType = '0'
      this.searchObj.pageIndex = 0
      this.search()
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    search () {
      this.searchObj.pageIndex = 0
      this.getDataList()
    },
    selectFluxPackageType (item) {
      this.searchObj.FluxPackageTypeValue = item.label
      this.searchObj.FluxPackageType = item.value
      this.searchObj.pageIndex = 0
      this.search()
    },
    // 导航栏切换
    chgTitleList (item) {
      console.log(item, this.authorizationHead, '导航栏切换');
      // this.searchObj.startTime = this.$utils.getCurrentMonthFirst();
      // this.searchObj.endTime = this.$utils.getCurrentMonthLast();
      this.searchObj.pageIndex = 0
      this.searchObj.IsFluxPackage = item.key
      this.search()
    },
    // table 按钮点击
    doAfterTableBtnClick (item, eventName, rowAtrribute) {
      console.log(
        item,
      );
      item.FlowPackagesKey = item.OLAPKey
      item.FlowPackagesKeyValue = item.FPNumer
      let obj = {
        FlowPackagesKey: item.OLAPKey,
        FlowPackagesKeyValue: item.FPNumer
      }
      if (this.vmdInfo.EditionKey == 5) {
        //总部
        this.$dialog.open(this, {
          name: '流量包明细',
          routerName: this.$route.name,
          moduleName: 'HQflowPacketReport',
          ModulePowerKey: '',
          data: { dataInfo: item },
          callBack: {
            afterSuccess: (data) => { this.getDataList(); }
          }
        });
        // layer.alert("该功能还在开发阶段。")
      } else {
        this.$dialog.open(this, {
          name: '流量明细',
          routerName: this.$route.name,
          moduleName: 'flowPacketDetailReport',
          ModulePowerKey: '',
          data: { dataInfo: obj },
          callBack: {
            afterSuccess: (data) => { this.getDataList(); }
          }
        });
      }
    },
    toBuy () {
      let titleName = '流量充值';
      let obj = {
        putData: {
          type: '3',
          title: '购买通用流量',
          placeholder: '',
          buttomName: '确认充值',
          scTitle: '付款方式',
          isShowBottom: false
        }
      };
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: titleName,
        moduleName: 'rechargeTheBalance',
        data: obj,
        callBack: {
          afterSuccess: (data) => { this.getDataList(); }
        }
      });
    }
  }
};
</script>

<style>
.flow_packet_report {
  height: 728px;
}
.flow_packet_report .course_statistics_search .search_box {
  min-width: 240px !important;
  width: 240px;
}
.flow_packet_report .course_statistics_table_search {
  padding: 20px 20px;
}
.flow_packet_report .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.flow_packet_report .table_fixed_nav_list {
  margin: 0 20px;
  padding: 0px 2px;
  font-size: 16px;
}
.flow_packet_report .course_statistics_title {
  padding: 0;
}
.flow_packet_report .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.flow_packet_report .table_select_box .el-select {
  width: 200px;
}
.flow_packet_report
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.flow_packet_report .cw_summarizing .top_content_list .list_content .list_num {
  text-overflow: clip;
}
.flow_packet_total {
  position: absolute;
  right: 20px;
  top: 65px;
  line-height: 35px;
  padding-left: 40px;
  background: url('../../../../public/image/flow.png') left center no-repeat;
  background-size: 30px;
}
.flow_packet_report .el-table .el-table__cell {
  padding: 0;
}
</style>

