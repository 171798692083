<template>
  <div class="recording_data_tr">
    <div class="recording_data_td"
         style="width: 125px">
      <div>{{vDate}}</div>
      <!-- <div>{{vHour}}</div> -->
    </div>
    <div class="recording_data_td"
         style="width: 130px">
      <div >已上{{Number(item.HavedClass)}}节课</div>
    </div>
    <div class="recording_data_td"
         style="width: 150px">
      <div>{{Number(item.TotalPerson)}}/{{Number(item.Attence3)}}</div>

    </div>
    <div class="recording_data_td"
         style="width: 110px">
      <div class="pr">{{Number(item.Total_ClassCount)}}课时</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    vDate () {
      if (this.item && this.item.SetUpTime) {
        return this.item.SetUpTime.substring(0, 10);
      }
    }

  }
};
</script>

