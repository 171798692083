<template>
  <!-- 门店督学统计汇总-列表 -->
  <div>
    <campus-supervise-school-statistics></campus-supervise-school-statistics>
    <div>
      <dialog-factory ref="mendainSuperviseSchoolStatistics"
                      :routerName="'mendainSuperviseSchoolStatistics'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import campusSuperviseSchoolStatistics from '../../head-quarters/campus-supervise-school-statistics/index';
import dialogFactory from '../../common/dialog-factory';
export default {
  // name: 'mendainSuperviseSchoolStatistics',
  data () {
    return {

    };
  },
  components: {
    campusSuperviseSchoolStatistics,
    dialogFactory
  },
  created () {
    this.registerBusEvent();
  },
  methods: {
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'mendainSuperviseSchoolStatistics') {
          if (this.$refs.mendainSuperviseSchoolStatistics) {
            this.$refs.mendainSuperviseSchoolStatistics.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
          }
        }
      });
    }
  },
  computed: {
    // vTypeKey () {
    //   if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  }
};
</script>

<style>
</style>

