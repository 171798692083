<template>
  <!-- 逾期未备列表 -->
  <div class=" table_list_content summarizing_list">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <condition-filtration-box :queryParams="queryParams"
                                @clearSearchParams="clearExtendSearchParams"
                                @searchValue="search"></condition-filtration-box>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="mendianList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getSearchPageOverdueNotPrepareLessons"></table-list>
    </div>
  </div>
</template>
<script>
import { hqSearchPageOverdueNotPrepareLessons } from '../../../../API/workbench.js';
import conditionFiltrationBox from '../common/condition-filtration-box';
import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        SchoolKey: '', //	整型	可选		数据源：GET	校区id
        SchoolKeyValue: ''
      },
      mendianList: [], // 门店列表
      tableColumns: [
        {
          label: '校区',
          prop: 'SaaSClientName',
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '老师',
          prop: 'TeacherName',
          width: 160,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '上课时间',
          prop: 'newClassTimeText',
          align: 'left',
          width: 240,
          sortable: false,
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '班名',
          prop: 'GradeClassKeyValue',
          width: 320,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '课名',
          prop: 'CourseNameKeyValue',
          width: 320,
          align: 'left',
          type: 'text-item',
          sortable: false
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    conditionFiltrationBox,
    tableList
  },
  computed: {

  },
  created () {
    this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
  },
  methods: {
    // 逾期未备列表
    getSearchPageOverdueNotPrepareLessons (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      hqSearchPageOverdueNotPrepareLessons(queryParams).then(result => {
        console.log('逾期未备列表', result.data);
        this.totalNum = result.data.Total; // 分页数
        this.mendianList = result.data.PageDataList || [];
        if (this.mendianList.length > 0) {
          this.mendianList.forEach(o => {
            o.newClassTimeText = o.ClassTime.slice(5, 10) + ' ' + o.WeekKeyValue + o.ClassTimeName;
          });
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
    },
    // 清除条件
    clearExtendSearchParams () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.queryParams.SchoolKey = '';
      this.queryParams.StartDate = '';
      this.queryParams.EndDate = '';
      this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
    }
  }
};
</script>
<style>
</style>

