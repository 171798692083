<template>
  <div class="label_bottom_content_material_other_attach">
    <div >
      <span class="other_attach_title">可下载附件</span>
    </div>
    <div class="other_attach_list">
      <other-attch-item v-for="(item,key) in dataList"
                              :key="key"
                              :item="item"
                              @clickEvent="clickEvent"></other-attch-item>
    </div>
  </div>
</template>
<script>
import otherAttchItem from './other-attch-item';
export default {
  data () {
    return {

    };
  },
  props: {
    dataList: Array
  },
  components: {
    otherAttchItem
  },
  methods:{
    clickEvent(item){
      this.$emit('clickEvent',item);
    }
  }
};
</script>
<style>
  .label_bottom_content_material_other_attach{
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    background: linear-gradient(0deg, #F9FBFD, #F9FBFD), #F5F5F5;
  }
  .other_attach_title{
    font-size: 18px;
    line-height: 18px;
    color: #333;
  }
  .other_attach_list{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
   
  }
   .other_attach_list .other_attach_item:last-child{
    border-bottom: none;
  }
</style>
