<template>
  <div class="top_content course_statistics_title general_summarizing_student box_shadow_border_div"
       style="flex-direction: column;padding-top:20px;padding-bottom: 30px;margin-bottom: 15px;">
    <div class="table_select_box"
         style="margin-bottom:10px"
         v-if="$store.getters.SaaSClientEditionKey == 5">
      <div class="table_select_title">选择校区</div>
      <el-select :value="vschoolItem"
                 style="width: 180px;"
                 filterable
                 placeholder="必选"
                 @change="chooseSchoolItem"
                 value-key="OLAPKey">
        <el-option v-for="item in schoolList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="header_title title_date"
         v-if="showTableList">
      <span class="header_title_span">
        {{title}}
      </span>
      <div class="title_date_select"
           v-if="!unShowDate">
        <div class="el-select">
          <div class="el-input el-input--small el-input--suffix is-focus">
            <single-year-picker @chgDate="chgDate"
                                :type="'month'"
                                :maxDate="vMaxDate"
                                ref="monthPicker"
                                :minDate="vMinDate"></single-year-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="header_content"
         v-if="showTableList">
      <div class="header_content_item"
           @click.stop="changeType(1)"
           :class="indexType==1?'opt':''">
        <div class="header_content_item_text_top font_gray">
          结转总额
        </div>
        <div class="header_content_item_text_middle"
             :style="setSizeByLength(dataInfo.SettlementsNum)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.SettlementsNum))}}
          <span class="middle_unit">元</span>
        </div>
      </div>
      <div class="header_content_unit">
        =
      </div>
      <div class="header_content_item"
           @click.stop="changeType(2)"
           :class="indexType==2?'opt':''">
        <div class="header_content_item_text_top font_gray">
          结转课消
        </div>
        <div class="header_content_item_text_middle"
             :style="setSizeByLength(dataInfo.SettlementsClassHourAmount)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.SettlementsClassHourAmount))}}
          <span class="middle_unit">元</span>
        </div>
        <div class="header_content_item_text_bottom">
          消耗课时: {{Number(dataInfo.SettlementsClassHourCount)}}
        </div>
      </div>
      <div class="header_content_unit">
        +
      </div>
      <div class="header_content_item"
           @click.stop="changeType(3)"
           :class="indexType==3?'opt':''">
        <div class="header_content_item_text_top font_gray">
          结转天数
        </div>
        <div class="header_content_item_text_middle"
             :style="setSizeByLength(dataInfo.SettlementsDayAmount)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.SettlementsDayAmount))}}
          <span class="middle_unit">元</span>
        </div>
        <div class="header_content_item_text_bottom">
          消耗天数: {{Number(dataInfo.SettlementsDayCount)}}
        </div>
      </div>
      <div class="header_content_unit">
        +
      </div>
      <div class="header_content_item"
           @click.stop="changeType(4)"
           :class="indexType==4?'opt':''">
        <div class="header_content_item_text_top font_gray">
          异常结转
        </div>
        <div class="header_content_item_text_middle font_red"
             :style="setSizeByLength(dataInfo.ExceptionalAmount)">
          ￥{{$utils.setMoneyformatToParseInt(Number(dataInfo.ExceptionalAmount))}}
          <span class="middle_unit font_gray">元</span>
        </div>
        <div class="header_content_item_text_bottom">
          共 {{Number(dataInfo.ExceptionalDay)}}单
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';

export default {
  data () {
    return {

    };
  },
  components: {
    singleYearPicker
  },
  props: {

    searchObj: Object,
    dataInfo: Object,
    title: String,
    indexType: [Number, String],
    unShowDate: {
      type: Boolean,
      default: false
    },
    schoolList: Array,
    showTableList: Boolean,
    schoolItem: {
      type: Object,
      default: () => {
        return {
          MainDemoName: '',
          OLAPKey: 0
        };
      }
    }
  },
  computed: {
    vschoolItem () {
      return this.schoolItem.MainDemoName;
    },
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    }

  },
  methods: {
    chooseSchoolItem (item) {
      this.$emit('chooseSchoolItem', item);
    },
    // 根据字数设定字体大小
    setSizeByLength (num) {
      num = num || 0;
      let count = 5;
      let size = parseInt(num).toString().length <= count ? 'font-size:34px' : 'font-size:24px;padding:10px 0px';
      return size;
    },
    changeType (TypeKey) {
      this.$emit('changeType', TypeKey);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.$emit('chgDate', val, startTime, endTime);
      // this.getReportList();
    }
  }

};
</script>
<style scoped>
.header_title_span {
  color: black;
  font-size: 20px;
}
.header_content {
  width: 1139px;
  height: 168px;
  border-radius: 8px;
  background: linear-gradient(
      90deg,
      rgba(226, 234, 253, 0.5) 0%,
      rgba(236, 237, 255, 0.5) 100%
    ),
    linear-gradient(90deg, #ffffff -0.99%, rgba(255, 255, 255, 0) 100%);
  margin-top: 20px;
  padding-left: 25px;
  display: flex;
  justify-content: row;
  align-items: center;
}
.header_content_item {
  width: 215px;
  height: 124px;
  border-radius: 8px;
  /* border: 1px; */
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}
.header_content_item:hover {
  background: linear-gradient(
      90deg,
      rgba(226, 234, 253, 0.5) 0%,
      rgba(236, 237, 255, 0.5) 100%
    ),
    linear-gradient(90deg, #ffffff -0.99%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(45, 152, 217, 1);
  color: rgba(45, 152, 217, 1);
}
.header_content_item.opt {
  color: rgba(45, 152, 217, 1);
  border: 1px solid rgba(45, 152, 217, 1);

  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}
.header_content_unit {
  width: 40px;
  height: 40px;
  background: linear-gradient(0deg, #0075ff, #0075ff),
    linear-gradient(0deg, #ffffff, #ffffff);
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  color: white;
  font-size: 40px;
  line-height: 37px;
}
.header_content_item_text_top {
  font-size: 12px;
}
.header_content_item_text_middle {
  font-size: 34px;
  padding: 5px 0px;
}

.middle_unit {
  font-size: 14px;
}
.header_content_item_text_bottom {
  width: 153px;
  height: 26px;
  line-height: 26px;
  border-radius: 100px;
  text-align: center;
}
</style>