<template>
  <!-- 修改APPID -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow" >
        <!-- 内容 -->
        <div>
          <input-text :formTitle="'APPID'"
                      :formPlaceholder="'APPID'"
                      class="no_border_bottom"
                      :required="true"
                      v-model="appID"></input-text>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                            :singleSubmitType="'dynamic'"
                            @confirmClick="confirmClick"
                            @cancelClick="cancelClick"></save-cancel-btn-group>
   
  </div>

</template> 

<script>
import { UpdatePreMiniProgramPublishAppID } from '../../../API/workbench';
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    templateID: [String, Number]
  },
  data () {
    return {
      appID: ''
    };
  },

  components: {

  },

  created () {
    this.appID = this.value;
  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      if (!this.appID.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入APPID');
        if (callBack) {
          callBack();
        }
        return false;
      }
      console.log('confirmClick', this.templateID, this.appID);
      UpdatePreMiniProgramPublishAppID(this.appID, this.templateID).then(
        (res) => {
          layer.alert('设置成功');
          this.$emit('cancelClick');
          this.$emit('input', this.appID);
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          console.log(err);
        }
      );
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
</style>