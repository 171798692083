<template>
  <div class="WX_setting_manage_list">
    <div class="list_img">
      <img :src="vheadimgurl"
           :onerror="vDefaultheadimgurl">
    </div>
    <div class="list_info">
      <div class="flex">
        <div class="list_name">
          {{item.username}}
        </div>
        <div class="list_date">
          {{item.add_time}}
        </div>
        <el-tooltip popper-class=""
                    effect="dark"
                    visible-arrow="false"
                    placement="top"
                    v-if="item.ad_time">
          <div slot="content"
               v-html="item.adtext"></div>
          <div class="list_type"
               v-if="item.ad_time">{{item.ad_time}} 已回复</div>
        </el-tooltip>
        <div class="list_type"
             v-if="!item.ad_time">

        </div>
        <div class="list_btn"
             @click.stop="reply">
          回复
        </div>
      </div>
      <div class="list_text">
        {{item.text}}
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vheadimgurl () {
      if (this.item && this.item.headimgurl) {
        if (this.item.headimgurl.indexOf('http') == -1) {
          return this.$store.getters.CDNURL + this.item.headimgurl;
        } else {
          return this.item.headimgurl;
        }
      } else {
        return '';
      }
    },
    vDefaultheadimgurl () {
      return 'this.src="' + require('../../../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    reply () {
      this.$emit('reply', this.item);
    }
  }
};
</script>
