<template>
  <!-- 长假调课 -->
  <div>
    <module-description :textTips="'将两天的课互相交换调整到对应日期。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s?__biz=MzU2NzUyODg1NQ==&mid=2247484633&idx=1&sn=4271d1271af5c2c7b677bcec94a81d12&chksm=fc9a9821cbed11373c452215a636ebccf7d36d3d6409b04abd3b546e0531872a77c4ac488dde#rd'"></module-description>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'调课范围'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    v-model="classInfo.MainDemoName"
                    @showEldilog="chgAdjustScope()"></input-dialog>
      <div :class="{'required_position':startWeek.length > 0}">
        <input-date v-model="startDate"
                    :required="true"
                    :elementId="'adjustADate'"
                    :formTitle="'调课日期A'"
                    :helpDescription="''"
                    :minDate="minDate"
                    :formPlaceholder="'请选择'"
                    @changeDate="startDateEvent">
        </input-date>
        <span class="week_select">{{startWeek}}</span>
      </div>
      <div :class="{'required_position':endWeek.length > 0}">
        <input-date v-model="endDate"
                    :required="true"
                    :elementId="'adjustBDate'"
                    :formTitle="'调课日期B'"
                    :helpDescription="''"
                    :minDate="minDate"
                    :formPlaceholder="'请选择'"
                    @changeDate="endDateEvent">
        </input-date>
        <span class="week_select">{{endWeek}}</span>
      </div>

      <div class="pr">
        <input-switch v-model="IsNotify"
                      :formTitle="'是否发送家校通知'"
                      style="padding-right: 23px;"
                      :readonly="false"
                      :required="false"></input-switch>
      </div>
      <input-text :formTitle="'处理原因'"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  :required="false"
                  v-model="Remarks"></input-text>

      <!-- 按钮组 -->
    </div>
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="affirmSave"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import { ExchangeScheduleCourseForVacation, SearchClass } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      startDate: '', // 开始日期的初始值
      endDate: '', // 结束日期
      startWeek: '', // 开始周
      endWeek: '', // 结束周
      Remarks: '', // 处理原因--备注
      classInfo: { MainDemoName: '全校', OLAPKey: '0' },
      IsNotify: 0,	// 		0 不通知 1通知
      minDate: Date.new().getFullYear() + '-' + (Date.new().getMonth() + 1) + '-' + (Date.new().getDate() + 1)
    };
  },
  created () {

  },
  methods: {
    // 停课范围
    chgAdjustScope () {
      SearchClass().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择班级',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.classInfo.OLAPKey, dataSourceList: result.data, required: false },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data);
              if (data.OLAPKey > 0) {
                this.classInfo.OLAPKey = data.OLAPKey;
                this.classInfo.MainDemoName = data.MainDemoName;
              } else {
                this.classInfo.OLAPKey = 0;
                this.classInfo.MainDemoName = '全校';
              }
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选择开始停课 周期
    startDateEvent (date) {
      this.$nextTick(() => {
        if (date) {
          date = date.replace(/\./g, '/');
          this.startDate = date;
          this.startWeek = this.getWeekVal(date);
        } else {
          this.startWeek = '';
        }
      });
    },
    // 选择结束停课 周期
    endDateEvent (date) {
      this.$nextTick(() => {
        if (date) {
          date = date.replace(/\./g, '/');
          this.endDate = date;
          this.endWeek = this.getWeekVal(date);
        } else {
          this.endWeek = '';
        }
      });
    },
    // 获取周
    getWeekVal (date) {
      let weekVal = '周' + '日一二三四五六'.charAt(Date.new(
        date.slice(0, 4),
        date.slice(5, 7) - 1,
        date.slice(8, 10)
      ).getDay());
      return weekVal;
    },
    // 确定按钮事件触发
    affirmSave (callBack, IsNotEndKey) {
      let flag = false;
      IsNotEndKey = IsNotEndKey == undefined ? 1 : IsNotEndKey;
      if (!this.startDate || !this.endDate) {
        layer.alert('请检查是否完善日期');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      ExchangeScheduleCourseForVacation(this.startDate, this.endDate, this.Remarks, this.classInfo.OLAPKey, this.IsNotify).then(result => {
        layer.alert('调课成功');
        this.cancel();
        this.$bus.emit('AfterHolidaysStopCourse', this.startDate, this.endDate); // 停课成功后通知到考勤管理模块刷新数据（传递的遍历只做标示，无具体意义）
      }, error => {
        if (error.code == 70003) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.affirmSave(callBack, 0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消按钮事件触发
    cancel () {
      // 初始化停课天数
      this.sumDays = 0;
      this.Remarks = '';
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped>
.required_position {
  height: 47px;
}
.week_select {
  position: relative;
  left: 320px;
  top: -31px;
  color: #3498db;
}
</style>

