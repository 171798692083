<template>
<div class="bg_white" style="margin-bottom: 7px;">
  <div class="form_info_box pr" @click.stop="seletedItem">
    <div class="info_name">
      <div>
        {{ item.CourseKeyValue || item.IncidentalsKeyValue }}
      </div>
      <div class="font_gray" v-if="item.Unit!='终身'">
        {{ Number(item.UnitPriceAmount)+'元' }}{{Number(item.QuantityCount)>0?'X'+Number(item.QuantityCount)+item.Unit:''}}
      </div>
      <div class="font_gray" v-else>
        终身
      </div>
    </div>
    <div class="info_number" style="position: absolute;right: 20px;top: 15px;">
      {{ Number(item.TotalAmount) }}
    </div>
    <div
      class="course_student_attendance_operation module_content_menuList"
      :style="{ top: '35px', left: '248px' }"
      v-if="isShowOption">
      <div class="top_bg"></div>
      <drop-down-box
        :optionJsonList="typeList"
        @clickOptionForAlertDialog="clickAfter"
      ></drop-down-box>
    </div>
  </div>
</div>
</template>
<script>
import dropDownBox from "../../../../common/drop-down-box";
export default {
  data() {
    return {
      isShowOption: false,
      typeList: [
        {
          name: "修改",
          type: 1,
          isShow: true,
        },
        {
          name: "删除",
          type: 2,
          isShow: true,
        },
      ],
    };
  },
  components:{
    dropDownBox
  },
  props: {
    item: Object,
    keyIndex:Number,
    isReadonly:Boolean
  },
  mounted() {
    document.querySelector("body").addEventListener(
      "click",
      (e) => {
        this.isShowOption = false;
      },
      false
    );
  },
  methods: {
    clickAfter(item) {
      this.isShowOption=false
      console.log(this.item,'clickAfter')
      if(item.type==1){
         this.$emit("seletedItem", this.item,this.keyIndex);
      }else{
         this.$emit("deletedItem", this.item,this.keyIndex);
      }
    },
    seletedItem() {
      if(!this.isReadonly){
        this.isShowOption =true
      }
      console.log("seletedItem",this.isShowOption)
      // this.$emit("seletedItem", this.item);
    },
  },
};
</script>