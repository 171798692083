<template>
  <div style="height: 100%">
    <custom-dialog :title="formTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAddStudent"
                   :before-close="doShowPopupHideClick">
      <function-btn :item="addbtn"
                    class="button"
                    @clickItem="addOldStudent"
                    :className="'defult_dialog_header_btn fr'">
        <span class="btn_add">+</span>录入老生
      </function-btn>
      <div class="students_list">
        <!-- 搜索选择层 -->
        <div class="single_shadow">
          <div>
            <!-- 搜索框 -->
            <input-search-contain-btn v-model="studenSearchText"
                                      :placeholder="'请输入姓名、姓名首字母、手机号'"
                                      @onSearchEvent="searchTextSearch"></input-search-contain-btn>
          </div>
          <div class="students_list_box clearfix">
            <div class="students_list_left fl">
              <div class="left_title">
                <span>已排学生</span>
                <span class="font_blue">{{ dataStudentList.length }}</span>
              </div>
              <div class="left_ul">
                <div v-for="(item, index) in VDataStudentList"
                     :key="index"
                     :class="{ dot: item.dot }"
                     class="left_li">
                  <span>{{ item.MainDemoName || item.StudentKeyValue }}</span>
                  <div @click.stop="deleteStudent(item)"
                       class="left_li_remove pa"></div>
                </div>
              </div>
            </div>
            <div class="students_list_right fl">
              <div class="right_title flex">
                <div class="right_title_name">
                  在校学生
                </div>
                <div class="right_title_text">排课<br />备注</div>
              </div>
              <div @scroll="scrollTOP"
                   v-if="studentList.length != 0"
                   class="right_content">
                <div v-for="(item, index) in vStudentList"
                     :key="index"
                     @click.stop="optStudent(item)">
                  <div v-if="!item.isOpt"
                       class="right_list flex">
                    <div class="right_list_name">
                      <span>{{ item.MainDemoName }}</span>
                    </div>
                    <div @click.stop="alterArrangeSupport(item)"
                         class="right_list_text">
                      <span class="right_list_text_font">{{
                        item.ArrangeSupport
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="loading"
                   class="students_loading">
                <span class="el-icon-loading"></span>
                <span class="loading_style">加载中...</span>
              </div>
              <div v-else-if="showNoData || studentList.length == 0"
                   class="students_list_right fl">
                <div class="monitoring_content_nodata">
                  <div v-if="showNoData "
                       class="monitoring_content_nodata_txt">
                    亲，当前没有任何数据哦~
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="complete"
             class="confirm_btn btn_fixed_bottom">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               style="magin: 0 auto">
            完成并返回
          </div>
        </div>
        <custom-dialog :title="'学生：' + selectedStudentInfo.MainDemoName"
                       :visible.sync="isAlterMessagePopup"
                       :before-close="hideAlterMessagePopup">
          <div class="form_info_detail micro_message">
            <div class="micro_message_edit content_marginRL single_shadow">
              <div class="message_edit_input">
                <textarea cols="30"
                          rows="10"
                          maxlength="100"
                          placeholder="请输入排课备注"
                          v-model="alterMessageText"></textarea>
              </div>
            </div>
            <!-- 按钮组 -->
            <div class="footer_btn_group form_footer_btn_group">
              <el-button round
                         class="btn_light_red btn_hover_white"
                         @click.stop="hideAlterMessagePopup">取消</el-button>
              <el-button round
                         class="btn_light_blue btn_hover_bg_blue"
                         @click.stop="submitStudentArrangeSupport">确认</el-button>
            </div>
          </div>
        </custom-dialog>
      </div>
    </custom-dialog>
    <custom-dialog title="录入老生"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAddOldStudentDialog"
                   :before-close="closeAddOldStudentDialog">
      <add-old-student @addSuccess="doAfterAddStudentSuccess"
                       :isChildComponent="true"
                       @closeDialog="closeAddOldStudentDialog"></add-old-student>
    </custom-dialog>
  </div>
</template>
<script>
import {
  messageSearchPageForStudent,
  EditArrangeSupport
} from '../../../../../API/workbench.js';
import addOldStudent from '../../../../pop-up-menu/exception-handle/add-old-student';
export default {
  data () {
    return {
      deleteCount: 1, // 手动防抖
      studenSearchText: '',
      showNoData: false,
      pageSize: 20, // 每次搜索人数.
      pageIndex: 0, // 每次查询页数
      studentList: [],
      optStundentInfo: {},
      courseStudentNumber: null,
      allStudentNumber: null,
      isAllstudent: 0,
      isPopup: false,
      isStundentCourse: false,
      loading: false,
      noMore: false, // 没有更多数据加载了
      dataStudentList: [], // 选中的选择数据
      isAlterMessagePopup: false,
      selectedStudentInfo: {},
      alterMessageText: '',
      isShowAddStudent: false,
      isShowAddOldStudentDialog: false,
      isAdding: false, // 防止重复点击
      isDelete: false,
      addbtn: {
        name: '',
        type: 'blls',
        ModulePowerKey: 49
      }
    };
  },
  props: {
    formTitle: String, // 自定义标题名（必填）
    ParentOperation: Boolean // 是否由父组件主导
  },
  components: {
    addOldStudent
  },
  watch: {
    isShowAddStudent (n, o) {
      if (!n) {
        this.studentList = [];
        this.pageIndex = 0;
      } else {
        this.getStudentList();
      }
    }
  },
  computed: {
    vMendianVersioneTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vStudentList () {
      this.studentList.forEach((e) => {
        let flag = false;
        this.dataStudentList.forEach((i) => {
          if (i.StudentKey == e.OLAPKey) {
            flag = true;
          }
        });
        e.isOpt = flag;
      });
      let arr = this.studentList.filter((e) => {
        return e.isOpt == false;
      });
      if (arr.length <= 10 && !this.noMore) {
        this.getStudentList();
      }
      if (arr.length == 0) {
        this.showNoData = true;
      } else {
        this.showNoData = false;
      }
      return this.studentList;
    },
    VDataStudentList () {
      console.log(this.dataStudentList, '1122');
      let arr = JSON.parse(JSON.stringify(this.dataStudentList));
      arr = arr.reverse();
      return arr;
    }
  },
  created () { },
  methods: {
    cardCourse (item) {
      if (item.HasTermCard > 0) {
        return '有';
      } else if (Number(item.CurrentClassHourCount) > 0) {
        return Number(item.AvailableNum);
      } else {
        return 0;
      }
    },
    checkIsClassHourLackKey (item) {
      console.log(item, 'checkIsClassHourLackKey');
      if (!this.classHoureCount) {
        return false;
      } else if (item.HasTermCard > 0) {
        return false;
      } else if (Number(item.AvailableNum) >= this.classHoureCount) {
        return false;
      } else {
        return true;
      }
    },
    pushDataStudentList (data) {
      this.dataStudentList = JSON.parse(JSON.stringify(data));
      this.dataStudentList.forEach((e) => {
        e.dot = false;
      });
    },
    addDataStudentList (studentInfo) {
      this.isAdding = false;
      studentInfo.dot = true;
    },
    refreshDataStundentList () {
      if (typeof this.$parent.loadStudentArrangeList == 'function') {
        this.$parent.loadStudentArrangeList(() => {
          this.dataStudentList = JSON.parse(
            JSON.stringify(this.$parent.studentArrangeList)
          );
        });
      }
    },
    hiddenPopup () {
      this.isPopup = false;
    },
    showStundentCourse (item) {
      if (this.isAllstudent == 0) {
        this.selectedStudentInfo = item;
        this.isStundentCourse = true;
      }
    },
    doShowPopupHideClick () {
      this.isShowAddStudent = false;
      this.pageIndex = 0;
      this.studenSearchText = '';
      this.$emit('completeData', this.dataStudentList);
    },
    doAfterAddStudentSuccess (studentInfo) {
      this.isShowAddOldStudentDialog = false;
      if (studentInfo.OLAPKey) {
        studentInfo.StudentKey = studentInfo.OLAPKey;
        this.pageIndex = 0;
        if (!this.vMendianVersioneTY) {
          this.getStudentNumber();
        }
        this.searchTextSearch();
        this.doAddStudent(studentInfo, 1);
      }
    },
    addOldStudent () {
      this.isShowAddOldStudentDialog = true;
    },
    closeAddOldStudentDialog () {
      this.isShowAddOldStudentDialog = false;
    },
    // 完成多选
    complete () {
      this.pageIndex = 0;
      this.studentList = [];
      this.studenSearchText = '';
      this.isShowAddStudent = false;
      this.$emit('completeData', this.dataStudentList);
    },
    // 选中学生
    optStudent (item) {
      let that = this;

      if (item.IsConfirmKey == 0) {
        layer.alert('该学生未验证，请先前往学生档案进行验证');
        return false;
      }

      let hasFind = false;
      if (this.dataStudentList.length > 0) {
        hasFind =
          this.dataStudentList.findIndex((p) => p.OLAPKey == item.OLAPKey) >= 0;
      }
      if (!hasFind) {
        item.StudentKey = item.OLAPKey;
        let isClassHourLackKey = this.checkIsClassHourLackKey(item);
        if (isClassHourLackKey) {
          that.doAddStudent(item, 1);
        } else {
          that.doAddStudent(item, this.classHoureCount ? 0 : 1);
        }
      } else {
        console.log(item);
        layer.alert('请勿重复安排学生');
      }
    },
    // 添加学生
    doAddStudent (item, isAllowClassHourLackKey) {
      item.dot = true;
      this.dataStudentList.push(item);
      this.$emit(
        'onAddStudent',
        item,
        () => {

        },
        isAllowClassHourLackKey,
        () => {
          console.log(isAllowClassHourLackKey);
          var index = this.dataStudentList.indexOf(item);
          if (index > -1) {
            item.dot = false;
            this.dataStudentList.splice(index, 1);
          }
        }
      );
    },
    // 删除多选数据
    deleteStudent (item) {
      console.log('this.deleteCount', this.deleteCount);
      if (this.deleteCount != 1) {
        console.log('手动防抖成功');
        return;
      }
      this.deleteCount = 2;
      console.log('this.deleteCount', this.deleteCount);
      let index = this.dataStudentList.findIndex(p => p.OLAPKey == item.OLAPKey);
      if (index < 0) {
        return false;
      }
      if (this.isDelete == true) {
        return false;
      }
      if (this.ParentOperation) {
        this.isDelete = true;
        setTimeout(() => {
          this.isDelete = false;
        }, 100);
        let hasArrangeList = this.$utils.parseJson(this.dataStudentList);
        this.$emit('onDeleteStudent', this.dataStudentList[index], index, () => {
          this.dataStudentList.splice(index, 1);
        });
        setTimeout(() => {
          this.deleteCount = 1;
        }, 500);
      } else {
        this.isDelete = true;
        setTimeout(() => {
          this.isDelete = false;
        }, 100);
        let hasArrangeList = this.$utils.parseJson(this.dataStudentList);
        this.$emit('onDeleteStudent', this.dataStudentList[index], index, () => {
          this.dataStudentList.splice(index, 1);
        });
        setTimeout(() => {
          this.deleteCount = 1;
        }, 500);
      }
    },
    clearCriteria (type) {
      this.isAllstudent = type;
      this.pageIndex = 0;
      this.getStudentList();
    },
    alterArrangeSupport (item) {
      this.selectedStudentInfo = item;
      this.alterMessageText = this.selectedStudentInfo.ArrangeSupport;
      this.isAlterMessagePopup = true;
    },
    hideAlterMessagePopup () {
      this.isAlterMessagePopup = false;
    },
    submitStudentArrangeSupport () {
      EditArrangeSupport(
        this.selectedStudentInfo.OLAPKey,
        this.alterMessageText
      ).then(
        (res) => {
          this.selectedStudentInfo.ArrangeSupport = this.alterMessageText;
          this.hideAlterMessagePopup();
        },
        (err) => {
          this.hideAlterMessagePopup();
          layer.alert(err.msg);
        }
      );
    },
    // 点击搜索
    searchTextSearch () {
      this.studenSearchText = this.studenSearchText.replace(/ /g, '');
      this.pageIndex = 0;
      this.noMore = false;
      this.getStudentList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore
      ) {
        this.getStudentList();
      }
    },
    // 学生查询翻页
    getStudentList (callback) {
      if (this.loading) {
        return false;
      } else {
        this.loading = true;
      }
      if (this.pageIndex == 0) {
        this.studentList = [];
      }
      messageSearchPageForStudent(
        this.pageIndex,
        this.pageSize,
        this.studenSearchText,
        '',
        '',
        1
      )
        .then((result) => {
          if (result.data) {
            console.log(result.data, 'result.data-学生查询翻页1');
            // 切换学生类型时 (首页)需清空数据
            if (this.studentList.length < result.data.Total) {
              result.data.PageDataList.forEach((e) => {
                this.studentList.push(e);
              });
              this.pageIndex++;
            }
            if (result.data.PageDataList.length < this.pageSize) {
              this.noMore = true;
            } else {
              this.noMore = false;
            }
            this.loading = false;
          }
          if (callback) {
            callback();
          }
          console.log(this.studentList, 'result.data-学生查询翻页');
        })
        .catch((error) => {
          console.log(error.msg);
        });
    }
  }
};
</script>