<template>
  <div class="all_view">
    <div class="bg_white"
         style="background-color: #fff; border-radius: 8px; padding-top: 20px">
      <div class="
          course_statistics_table_search course_statistics_title
          pr
          course_statistics_search
        ">
        <input-search-contain-btn v-model="paramsForQuery.searchText"
                                  :placeholder="'请输入版本号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="searchClick"></input-search-contain-btn>
        <div class="btn_box flex">
          <div @click="batchUploadCode"
               class="btn_hover_bg_blue">
            批量上传代码
          </div>
          <div @click="batchSubmitAudit"
               class="btn_hover_bg_blue">
            批量提交审核
          </div>
          <div @click="batchUndoCodeAudit"
               class="btn_hover_bg_blue">
            批量审核撤回
          </div>
          <div @click="batchRelease"
               class="btn_hover_bg_blue">批量发布</div>
        </div>
      </div>
      <div class="
          course_statistics_table_box
          table_list_content
          follow_clue_list
          prepare_publish_template
        ">
        <table-list class="summarizing_list"
                    ref="tableListRef"
                    :isShowCheckBox="false"
                    :defaultSort="defaultSort"
                    :tableData="tableData"
                    :tableColumns="tableColumns"
                    :totalNum="totalNum"
                    :queryParams="paramsForQuery"
                    :footerContent="vFooterTotal"
                    @changeCheckBoxFun="clickCheckBox"
                    @loadTableData="loadTableData"></table-list>
      </div>
    </div>

    <custom-dialog :title="'发布记录'"
                   :visible.sync="isShowPublishRecord"
                   :before-close="hidePublishRecord">
      <publish-record :selectInfo="selectMiniProgramInfo"></publish-record>
    </custom-dialog>

    <custom-dialog :title="'小程序详情'"
                   :visible.sync="isShowMiniProgramDetails"
                   :before-close="hideMiniProgramDetails">
      <mini-program-details :selectInfo="selectMiniProgramInfo"></mini-program-details>
    </custom-dialog>

    <!-- <dialog-factory-common
      ref="miniProgramTemplateVersionList"
    ></dialog-factory-common> -->
  </div>
</template>

<script>
import tableList from '../common/table-list/index';
import inputSearchContainBtn from '../report/common/input-search-contain-btn';
import publishRecord from './publish-record';
import miniProgramDetails from './mini-program-details';
import {
  SearchPageMiniProgram,
  MiniProgramBatchRelease,
  MiniProgramBatchSubmitAudit,
  MiniProgramBatchUndoCodeAudit,
  MiniProgramBatchUploadCode
} from '../../API/workbench';
export default {
  components: {
    tableList,
    inputSearchContainBtn,
    publishRecord,
    miniProgramDetails
  },
  data () {
    return {
      isShow: false,
      paramsForQuery: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      tableColumns: [
        {
          label: '门店ID',
          prop: 'SaaSClientKey',
          width: 100,
          type: 'text-item'
        },
        {
          label: '门店名称',
          prop: 'SaaSClientKeyValue',
          width: 200,
          type: 'text-item'
        },
        {
          label: '小程序appid',
          prop: 'MiniProgramAppID',
          width: 200,
          type: 'text-item'
        },
        {
          label: '公众号appid',
          prop: 'OfficialAccountsAppID',
          width: 200,
          type: 'text-item'
        },
        {
          label: '当前版本',
          prop: 'CurrentVersion',
          width: 100,
          type: 'text-item'
        },
        {
          label: '发布中版本',
          prop: 'PublishVersion',
          width: 100,
          type: 'text-item'
        },
        {
          label: '发布异常',
          prop: 'PublishAbnormal',
          width: 450,
          type: 'text-item'
        },
        {
          label: '发布状态',
          prop: 'PublishStatusKeyValue',
          width: 200,
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: '',
          extend: {
            tableBtnGroup: [
              {
                name: '详请',
                extend: {
                  click: (rowData) => {
                    this.openMiniProgramDetails(rowData);
                  }
                }
              },
              {
                name: '发布记录',
                extend: {
                  click: (rowData) => {
                    this.openPublishRecord(rowData);
                  }
                }
              },
              {
                name: '重新审核',
                extend: {
                  isHide: (rowData) => {
                    return rowData.PublishStatusKey != 4;
                  },
                  click: (rowData) => { }
                }
              }
            ]
          }
        }
      ],
      tableData: [],
      totalNum: 0,
      defaultSort: {},
      batchAllotList: [],
      selectMiniProgramInfo: {},
      isShowPublishRecord: false,
      isShowMiniProgramDetails: false
    };
  },
  computed: {
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  created () {
    // query: {MiniProgramAppID: data.MiniProgramAppID} 预发布列表打开 会传相应参数
    this.loadTableData();
  },
  methods: {
    batchUploadCode () {
      MiniProgramBatchUploadCode().then(
        (res) => {
          layer.alert('批量上传代码成功');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    batchSubmitAudit () {
      MiniProgramBatchSubmitAudit().then(
        (res) => {
          layer.alert('批量提交审核成功成功');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    batchUndoCodeAudit () {
      MiniProgramBatchUndoCodeAudit().then(
        (res) => {
          layer.alert('批量审核撤回成功');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    batchRelease () {
      MiniProgramBatchRelease().then(
        (res) => {
          layer.alert('批量发布成功');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    openMiniProgramDetails (item) {
      this.selectMiniProgramInfo = item;
      this.isShowMiniProgramDetails = true;
    },
    openPublishRecord (item) {
      this.selectMiniProgramInfo = item;
      this.isShowPublishRecord = true;
    },
    hideMiniProgramDetails () {
      this.isShowMiniProgramDetails = false;
      this.selectMiniProgramInfo = {};
    },
    hidePublishRecord () {
      this.isShowPublishRecord = false;
      this.selectMiniProgramInfo = {};
    },
    // 批量分配 选中一行
    clickCheckBox (lists, rowData) {
      let arr = lists.filter((item) => {
        return item.Number > 0;
      });
      this.batchAllotList = arr;
    },
    // 加载表单数据
    loadTableData () {
      SearchPageMiniProgram(this.paramsForQuery).then((result) => {
        this.tableData = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 查询
    searchClick () {
      this.loadTableData();
    },
    // 清除查询条件
    clearSearchCondition () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.searchClick();
    }
  }
};
</script>

<style scoped>
.btn_hover_bg_blue {
  width: 106px;
  line-height: 34px;
  height: 36px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  padding: 0 10px;
}

.btn_box {
  width: 600px;
  justify-content: flex-end;
}
</style>