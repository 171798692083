<template>
  <div class="bottom_week_list">
    {{item.titleName}}
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {

  }
};
</script>

<style>
</style>