<template>
  <!-- 课件门店 左边工作台 -->
  <div class="new_courseware_left_info">
    <div class="new_courseware_md_info">
      <md-info-box></md-info-box>
    </div>

    <!-- <div class="courseware_staff_info pr">
      <staff-info-box @doEditStaff="doEditStaff"></staff-info-box>
      <todo-info-list @updateData="updateData" ref="todoInfo"
                      v-if="vRoleKey"></todo-info-list>
    </div> -->

  </div>
</template>
<script>
import mdInfoBox from './md-info-box/index';
export default {
  data () {
    return {
      mendianInfo: this.$store.getters.SaaSClientInfo
    };
  },
  components: {
    mdInfoBox
  },
  computed: {
    vRoleKey () {
      return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    }
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
  },
  mounted () {

  },
  methods: {
    doEditMdInfo () {
      this.isShowMDform = true;
    },
    doEditStaff () {
      this.$dialog.open(this, {
        name: '修改密码',
        routerName: this.$route.name,
        moduleName: 'editPassWord',
        callBack: { afterSucces: (data) => { } }
      });
    },
    updateData () {

    }
  }
};
</script>

<style>
.new_courseware_left_info {
  position: relative;
}
</style>
