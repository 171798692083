<template>
  <div class="attend-class-list">
    <div class="credit_management_popup">
      <div class="popup_title_padding view_title_flex"
           style="justify-content: inherit;padding-top:0px">
        <div class="">
          <date-range ref="saveDate"
                      :searchObj="searchObj"
                      :fromTitle="'日期范围'"
                      @changeDate="changeDate"></date-range>
        </div>
        <div>
          <span class="span-classpa">选择课包</span>
          <el-cascader ref="cascader"
                       style="height: 36px; width: 300px"
                       v-model="selectedOptions"
                       :options="BigCounselorList"
                       :props="props"
                       @change="BigCounselor"
                       clearable
                       placeholder="全部"></el-cascader>
        </div>
        <div class="search_item attend_icon"
             style="margin-left: 15px">
          <!-- <el-select-option
            :selectTitle="'选择校区'"
            :dataSourceList="SchoolList"
            :selectionKey="searchObj.CampusKey"
            @changeEvent="chgSchoolKey"
          ></el-select-option> -->
          <input-dialog :formTitle="'校区名'"
                        :formPlaceholder="'请选择'"
                        :readonly="false"
                        v-model="campusName"
                        @showEldilog="selectSaaClient"></input-dialog>

          <div class="input_icon_attend"></div>
        </div>
        <!-- 类型 -->
        <div class="table_select_box"
             style="margin-left: 15px;">
          <div class="table_select_title">类型</div>
          <el-select v-model="selectedTypeAccount"
                     filterable
                     placeholder="必选"
                     @change="chgSearchValue"
                     value-key="OLAPKey">
            <el-option v-for="item in typeList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="credit_management_table">
        <table-list class="summarizing_list table_list_content"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    @loadTableData="getDataList"></table-list>
      </div>
    </div>

    <custom-dialog :title="'校区选择'"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <campus-form :campusListTypeKey="2"
                   :isReadonly="false"
                   :fromType="dataInfo.fromType"
                   :campusChooseKey="searchObj.CampusKey"
                   @close="closeCoursewareFormPopup"
                   @onSelectedItem="onSelectedItem"></campus-form>
    </custom-dialog>
  </div>
</template>
<script>
import elSelectOption from '../../../../report/common/el-select-option';
import campusForm from './campus-form.vue';
import {
  getGiveLectureRecordGiveLectureStat,
  getCoursewarePackageListForHQCascadeSelector,
  getCoursewareListForHQCascadeSelector,
  GetCampusListForHQ
} from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
export default {
  components: {
    inputSearchContainBtn,
    tableList,
    elSelectOption,
    campusForm
  },
  data () {
    return {
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空则默认为课程名
        sequence: '', //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
        startTime: '', //	字符串	可选		数据源：GET	起始日期
        endTime: '', //	字符串	可选	0	数据源：GET	截止日期
        CampusKey: 0, //	整型	可选	0	数据源：GET	校区
        BigCoursewarePackageKey: '', //	整型	可选	0	数据源：GET	大课包
        CoursewarePackageKey: '', //	整型	可选	0	数据源：GET	子课包
        CoursewareKey: '', //	整型	可选	0	数据源：GET	课件key
        ClassTypeKey: 0
      },
      selectedTypeAccount: '全部',
      typeList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        // {
        //   OLAPKey: 1,
        //   MainDemoName: '选班上课'
        // },
        {
          OLAPKey: 2,
          MainDemoName: '上课'
        },
        {
          OLAPKey: 3,
          MainDemoName: '备课'
        }
      ],
      campusName: '全部',
      selectedOptions: '',
      options: [],
      isShowCoursewareFormPopup: false,
      props: {
        value: 'OLAPKey',
        label: 'MainDemoName',
        children: 'children',
        checkStrictly: true,
        expandTrigger: 'hover',
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level;
          console.log(node, 'node');
          if (level > 0) {
            const id = node.value;
            console.log(node.data.TypeKey, 'node.data.type');
            console.log(node.data.TypeKey == '0', 'node.data.TypeKey == "0"');
            if (node.data.TypeKey == '0') {
              getCoursewareListForHQCascadeSelector(id).then((res) => {
                const { code, msg } = res;
                const list = res.data;
                console.log(list, 'SelectCityForProvince');
                if (code === 0) {
                  const nodes = list;
                  nodes.forEach((item) => {
                    item.leaf = level + 5;
                  });
                  resolve(nodes);
                }
              });
            } else {
              getCoursewarePackageListForHQCascadeSelector(id).then((res) => {
                const { code, msg } = res;
                const list = res.data;
                console.log(list, 'SelectCityForProvince');
                if (code === 0) {
                  const nodes = list;
                  nodes.forEach((item) => {
                    item.children = [];
                  });
                  resolve(nodes);
                }
              });
            }
          }
        }
      },
      SchoolList: [],
      BigCounselorList: [
        {
          OLAPKey: 123,
          MainDemoName: '444',
          children: [
            {
              OLAPKey: 1231,
              MainDemoName: '1223',
              children: [
                {
                  OLAPKey: 1213,
                  MainDemoName: '1223'
                }
              ]
            }
          ]
        }
      ],
      dataReportList: [],
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '类型',
          prop: 'ClassTypeKeyValue',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '校区',
          prop: 'CampusKeyValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '上课日期',
          prop: 'ClassTime',
          width: 100,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem'
        },

        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '上课时间',
          prop: 'BeginClassTime',
          width: 90,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '下课时间',
          prop: 'EndClassTime',
          width: 90,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '课件使用时长',
          prop: 'DurationCountText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '大课包',
          prop: 'BigCoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '子课包',
          prop: 'CoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课件',
          prop: 'CoursewareKeyValue',
          width: 130,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '流量',
          prop: 'Flow',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          StartTime: '',
          EndTime: '',
          BigCoursewarePackageKey: '',
          SchoolKey: '',
          SchoolKeyValue: '',
          fromType: ''
        };
      }
    }
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  watch: {},
  created () {
    this.$dialog.register(this, [
      'campusForm'
    ]);
  },
  mounted () {
    console.log('dataInfo', this.dataInfo);
    this.searchObj.CampusKey = this.dataInfo.SchoolKey || '';
    this.campusName = this.dataInfo.SchoolKeyValue || '全部';
    this.searchObj.BigCoursewarePackageKey =
      this.dataInfo.BigCoursewarePackageKey || '';
    this.selectedOptions = this.dataInfo.BigCoursewarePackageKey || '';
    if (this.dataInfo.StartTime) {
      this.atTimeView(this.dataInfo.StartTime);
    } else {
      this.atTimeView();
    }
    console.log(this.searchObj, 'this.searchObj.EndTime ');
    this.getDataList();
    this.getDataListforBig();
    // this.getCampusListForHQList();
  },
  methods: {
    // 选择类型
    chgSearchValue (item) {
      console.log(item, 'item');
      this.selectedTypeAccount = item.MainDemoName;
      this.searchObj.ClassTypeKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataListforBig () {
      getCoursewarePackageListForHQCascadeSelector(0).then((result) => {
        this.BigCounselorList = result.data;
      });
    },

    // getCampusListForHQList() {
    //   GetCampusListForHQ().then((result) => {
    //     this.SchoolList = result.data;
    //     this.SchoolList.unshift({
    //       MainDemoName: "全部",
    //       OLAPKey: 0,
    //     });
    //   });
    // },
    chgSchoolKey (item) {
      this.searchObj.CampusKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    BigCounselor (item) {
      this.searchObj.BigCoursewarePackageKey = 0;
      this.searchObj.Catalogue = 0;
      this.searchObj.CoursewareKey = 0;
      this.searchObj.CoursewarePackageKey = 0;
      this.searchObj.pageIndex = 0;
      let data = this.$refs['cascader'].getCheckedNodes();
      if (data.length == 0) {
        this.$refs.cascader.dropDownVisible = false;
        this.getDataList();
      } else {
        if (data[0].level == 1) {
          this.searchObj.BigCoursewarePackageKey = data[0].value;
        } else if (data[0].level == 2) {
          if (data[0].data.TypeKey == '2') {
            this.searchObj.Catalogue = data[0].value;
          } else {
            this.searchObj.CoursewarePackageKey = data[0].value;
          }
        } else if (data[0].level == 3) {
          if (data[0].data.TypeKey) {
            this.searchObj.CoursewarePackageKey = data[0].value;
          } else {
            this.searchObj.CoursewareKey = data[0].value;
          }
        } else if (data[0].level == 4) {
          this.searchObj.CoursewareKey = data[0].value;
        }
        this.$refs.cascader.dropDownVisible = false;
        this.getDataList();
      }
    },

    changeDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    updateData () {
      this.getDataList();
    },
    search () {
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    getDataList () {
      getGiveLectureRecordGiveLectureStat(this.searchObj).then(
        (res) => {
          console.log('GetGiveLectureStat', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.dataReportList.forEach((o) => {
            o.BeginClassTime = o.BeginClassTime
              ? o.BeginClassTime.slice(10, 16)
              : '';
            o.EndClassTime = o.EndClassTime ? o.EndClassTime.slice(10, 16) : '';
            let data2 = new Date(o.ClassTime);
            o.ClassTime =
              this.getTimeOut(data2.getFullYear()) +
              '-' +
              this.getTimeOut(data2.getMonth() + 1) +
              '-' +
              this.getTimeOut(data2.getDate());
            o.DurationCountText = Number(o.DurationCount) + '分钟';
            o.Flow = Number(o.Flow) != 0 ? this.$utils.flowFormate(o.Flow) : '-';
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    atTimeView (defaultDate) {
      let dataDate = defaultDate || Date.new();
      var date = Date.new(dataDate);
      let year = date.getFullYear(dataDate);
      let month = date.getMonth(dataDate) + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let yearView = year;
      let monthView = month;
      this.searchObj.startTime = yearView + '-' + monthView + '-' + '01';
      // 结束日期
      this.searchObj.endTime =
        yearView + '-' + monthView + '-' + this.mGetDate(yearView, monthView);
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    },
    getTimeOut (date) {
      return date > 9 ? date : '0' + date;
    },

    selectSaaClient () {
      this.isShowCoursewareFormPopup = true;
    },
    closeCoursewareFormPopup () {
      this.isShowCoursewareFormPopup = false;
    },
    onSelectedItem (item) {
      console.log(item, 'item');
      this.searchObj.CampusKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.campusName = item.MainDemoName;
      this.getDataList();
    }
  }
};
</script>
<style scoped>
.span-classpa {
  margin-right: 10px;
  color: #666;
  white-space: nowrap;
  text-align: center;
  display: block;
  float: left;
  margin-top: 11px;
}
.attend-class-list {
  width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
  background: #fff;
  border-radius: 8px;
}
.attend-class-list >>> .form_info_field {
  line-height: 35px;
}
.form_info_line {
  padding: 0px;
  min-height: 36px;
}
.attend-class-list >>> .form_info_value.form_info_select input {
  color: #333333;
  margin-top: 2px;
}

.attend-class-list >>> .form_info_value {
  border: 1px solid #ececec;
}
.attend-class-list >>> .form_info_value input {
  text-align: left;
}
.attend-class-list >>> .form_info_line {
  border-bottom: 0px solid #ececec;
}
.attend-class-list >>> .el-input__inne {
  color: #333333;
}
.attend-class-list >>> .el-table__row {
  height: 60px;
}
.input_icon_attend {
  width: 15px;
  height: 15px;
  background: url(/public/image/bottom_icon@2x.png) no-repeat 50%;
  background-size: 10px;
  position: absolute;
  left: 222px;
  cursor: pointer;
  top: 11px;
}
.attend_icon {
  position: relative;
}
/* WebKit browsers */
/deep/ input::-webkit-input-placeholder {
  color: #333333 !important;
}
/* Mozilla Firefox 4 to 18 */
/deep/ input:-moz-placeholder {
  color: #333333 !important;
}
/* Mozilla Firefox 19+ */
/deep/ input::-moz-placeholder {
  color: #333333 !important;
}
/* Internet Explorer 10+ */
/deep/ input:-ms-input-placeholder {
  color: #333333 !important;
}
.table_select_box >>> .el-select {
  width: 145px !important;
}
.summarizing_list >>> .el-table__row {
  height: 44px !important;
}
</style>