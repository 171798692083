<template>
  <!-- 通用列表 norole-->
  <div class="table_list_content introduce_list campusManagement_list" style="border-radius: 8px;">
    <div class="headquarters_info_summarizing">
      <div style="display: flex;" class="headquarters_info_search pr">
        <div class="select">
          <div class="port">
            <elSelectOption :selectTitle="'端口类型'" @changeEvent="choosePortType" :selectionKey="params.portTypeKey"
              :dataSourceList="PortTypeLsit">
            </elSelectOption>
          </div>
          <div class="campus">
            <elSelectOption :selectTitle="'校区类型'" @changeEvent="chooseType" :selectionKey="params.campusTypeKey"
              :dataSourceList="campusTypeList">
            </elSelectOption>
          </div>
        </div>
        <input-search-contain-btn v-model="params.searchText" :width="'280px'" :placeholder="'请输入'"
          @onSearchEvent="search" @clearSearch="clear"></input-search-contain-btn>

        <div class="summarizing_number_right_btn" style="top: 15px;">
          <function-btn :item="{ name: '端口使用记录', ModulePowerKey: '' }"
            :className="'el-button btn_light_blue btn_hover_bg_blue'" @clickItem="addCampus">
          </function-btn>
          <!-- <function-btn v-if="vJMPower" :item="{ name: '添加加盟校区', ModulePowerKey: '' }"
          :className="'el-button btn_light_blue btn_hover_bg_blue'" @clickItem="addCampus('JM')">
        </function-btn> -->
        </div>
      </div>

      <table-list ref="tableListRef" :tableData="tableData" :position="''" :tableColumns="tableColumns" :rowKey="rowKey"
        :totalNum="totalNum" :defaultSort="defaultSort" :queryParams="params" :footerContent="'共' + totalNum + '条数据'"
        @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
  // import {
  //   GetSaaSClientSet
  // } from '../../../../API/workbench.js';
  import elSelectOption from "../../../report/common/el-select-option.vue"; // 下拉选择框
  import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
  import tableList from '../../../common/table-list/index';
  export default {
    data() {
      return {
        // getSaaSClientSet: [],
        PortTypeLsit: [
          // 端口类型
          {
            OLAPKey: 0,
            MainDemoName: "全部"
          },
          {
            OLAPKey: 1,
            MainDemoName: "教务"
          },
          {
            OLAPKey: 2,
            MainDemoName: "课件"
          },
        ],
        campusTypeList: [
          // 校区类型
          {
            OLAPKey: 0,
            MainDemoName: "全部"
          },
          {
            OLAPKey: 1,
            MainDemoName: "直营店"
          },
          {
            OLAPKey: 2,
            MainDemoName: "加盟店"
          },
          {
            OLAPKey: 3,
            MainDemoName: "课程合作"
          },
        ],
        campus: "", // 校区类型
      };
    },
    props: {
      tableData: Array, // 列表数据
      tableColumns: Array, // table column,
      rowKey: [String, Number], // 显示详情对应绑定的ID
      totalNum: Number, // 分页总共条数
      params: Object,
      defaultSort: {
        type: Object,
        default: () => {
          return {
            prop: '',
            order: 'ascending'
          };
        }
      }
    },
    components: {
      inputSearchContainBtn,
      tableList,
      elSelectOption
    },
    watch: {

    },
    created() {
      // this.getMenDianSet();
    },
    computed: {

    },
    methods: {
      // 添加
      addCampus(type) {
        this.$emit('openIsShowPortApplyRecordListPopup');
      },
      // 搜索
      search() {
        console.log('搜索', this.params);
        this.params.pageIndex = 0;
        this.params.IsExpired30Key = '';
        // this.initParams();
        // this.$refs.tableListRef.expandRowKeys = [];
        this.$emit('loadTableData', this.params);
      },
      // 清除
      clear() {
        console.log('清除', this.params);
        this.params.pageIndex = 0;
        this.params.IsExpired30Key = '';
        this.initParams();
        // this.$refs.tableListRef.expandRowKeys = [];
        this.$emit('loadTableData', this.params);
      },
      //选择端口状态
      choosePortType(item) {
        this.params.portTypeKey = item.OLAPKey;
        this.search();
      },
      //选择校区状态
      chooseType(item) {
        this.params.campusTypeKey = item.OLAPKey;
        this.search();
      },
      initParams() {
        this.params.pageIndex = 0;
        this.params.orderBy = '';
        this.params.searchText = '';
        // this.params.IsActiveKey = ''; //	整型	可选		数据源：GET	是否活跃校区
        // this.params.IsStopKey = ''; //	整型	可选		数据源：GET	是否停用
        // this.params.IsExpiredKey = ''; //	整型	可选		数据源：GET	是否已过期
        this.params.IsExpired30Key = ''; //	整型	可选		数据源：GET	是否30天内过期
        this.params.portTypeKey = ''; // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
        this.params.campusTypeKey = ''; // 整型	可选		数据源：GET	校区类型: 1-直营店; 2-加盟店;3-课程合作
      },
      // 排序
      loadTableData(queryParams) {
        console.log('排序', queryParams);
        this.$emit('loadTableData', queryParams);
      }
    }
  };

</script>
<style scoped>
  .select {
    display: flex;
  }

  .port {
    display: flex;
    align-items: center;
    margin-right: 20px
  }

  .campus {
    display: flex;
    align-items: center;
  }

</style>
