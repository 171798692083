<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">报名缴费月报</div>
        <single-year-picker @chgDate="chgYear"></single-year-picker>
        <div class="title_month fr">
          <span class="this_month">本年</span>
          <span class="past_month">上一年</span>
        </div>
      </div>
      <div>
        <doubleTypeEchart :echartID="'dual_histogram'"
                          :reqList="monthList"
                          :nowDataList="nowList"
                          :preDataList="oldList"
                          :echartType="'category'"
                          ref="echart"></doubleTypeEchart>
      </div>
      <div>
        <a @click="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <div class=" statistics_echarts_right fl ">
      <div class="apply_payment_box_title ">
        {{vFormTitle}}报名统计
        <span>{{vYear}}年</span>
      </div>
      <div class="box_dot dot_green ">
        报名金额
      </div>
      <div class="apply_payment_box_number ">
        <span>￥</span>{{nowTotalRecableAmount}}
      </div>
      <div class="apply_payment_box_singular box_dot dot_blue ">
        单数
        <span>{{totalCount}}</span> 单
      </div>
      <div class="box_contrast"
           v-if="nowTotalRecableAmount-lastYearTotalRecableAmount<0">
        <img class="fl "
             src="../../../../public/image/contrast_down.png"
             alt=" ">
        <div class="box_contrast_data fl ">
          <div class="data_number"><span v-if="increaseRate=='--'?false:true">-</span>{{increaseRate}}</div>
          <div>环比下降</div>
        </div>
      </div>
      <div class="box_contrast "
           v-else>
        <img class="fl "
             src="../../../../public/image/contrast_up.png"
             alt=" ">
        <div class="box_contrast_data fl ">
          <div class="data_number">{{increaseRate}}</div>
          <div>环比增长</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import doubleTypeEchart from '../echart-common/double-type-echart';
import singleYearPicker from '../date-picker-common/single-year-picker';
import { getKDSYStatisticsDataByMonth } from '../../../API/workbench';
export default {
  name: 'applyMonthStatisticsReport',
  props: { elTitle: String },
  data () {
    return {
      seletedDate: Date.new(),
      startTime: '',
      endTime: '',
      nowList: [],    // 选中月数据显示的内容
      oldList: [],    // 上个月数据显示的内容
      nowDataList: [],
      oldDataList: [],
      monthList: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    };
  },
  components: {
    doubleTypeEchart, singleYearPicker
  },
  computed: {
    nowTotalRecableAmount () {
      let RecableAmount = 0;
      this.nowDataList.forEach(o => {
        RecableAmount += Number(o.RecableAmount);
      });
      return (Math.round(RecableAmount * 100) / 100).toFixed(2) || 0;
    },
    totalCount () {
      let count = 0;
      this.nowDataList.forEach(o => {
        count += Number(o.Number);
      });
      return count;
    },
    vYear () {
      return this.seletedDate.getFullYear();
    },
    lastYearTotalRecableAmount () {
      let RecableAmount = 0;
      this.oldDataList.forEach(o => {
        RecableAmount += Number(o.RecableAmount);
      });
      return (Math.round(RecableAmount * 100) / 100).toFixed(2) || 0;
    },
    // 环比增长率=（本期的某个指标的值-上一期这个指标的值）/上一期这个指标的值*100%。
    increaseRate () {
      if (this.lastYearTotalRecableAmount == 0 && this.nowTotalRecableAmount > 0) {
        return '100%';
      } else if (this.lastYearTotalRecableAmount >= 0 && this.nowTotalRecableAmount == 0) {
        return '---';
      } else {
        return parseInt(Math.abs((this.nowTotalRecableAmount - this.lastYearTotalRecableAmount) / this.lastYearTotalRecableAmount) * 100) + '%';
      }
    },
    // 判断显示本年本月.
    vFormTitle () {
      if (this.seletedDate.getFullYear() == Date.new().getFullYear()) { return '本年'; }
    }
  },
  mounted () {
    this.startTime = Date.new().getFullYear() + '/01/01';
    this.endTime = Date.new().getFullYear() + '/12/31';
    this.initGetData(this.getLastKDSYDataByMonth);
  },
  methods: {
    showDetail () {
      this.$emit('showDetail', '报名缴费月报', this.startTime, this.endTime);
    },
    // 监听时间插件改变值触发
    chgYear (val, startTime, endTime) {
      this.seletedDate = val;
      this.startTime = this.seletedDate.getFullYear() + '/01/01';
      this.endTime = this.seletedDate.getFullYear() + '/12/31';
      this.initGetData(this.getLastKDSYDataByMonth);
    },
    // 绑定数据在echart里
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.RecableAmount).toFixed(2));
        } else {
          nowList.push('0.00');
        }
      });
      return nowList;
    },
    // 获取上一年数据   因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getOldList () {
      let oldList = [];
      this.monthList.forEach(p => {
        let item = this.oldDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          oldList.push(Number(item.RecableAmount).toFixed(2));
        } else {
          oldList.push('0.00');
        }
      });
      return oldList;
    },
    // 初始化获取数据.
    initGetData (callback) {
      this.getKDSYDataByMonth(this.startTime, this.endTime, callback);
    },
    // 按月获取报名缴费报表数据
    getKDSYDataByMonth (startTime, endTime, callback) {
      getKDSYStatisticsDataByMonth(startTime, endTime).then(result => {
        this.nowDataList = result.data.DataList || [];
        this.nowList = this.getNowList();
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取上一个月的信息.
    getLastKDSYDataByMonth () {
      let year = Date.new(this.seletedDate).getFullYear();
      let startTime = (year - 1) + '/01/01';
      let endTime = (year - 1) + '/12/31';
      this.oldDataList.length = 0;
      getKDSYStatisticsDataByMonth(startTime, endTime).then(result => {
        this.oldDataList = result.data.DataList || [];
        this.oldList = this.getOldList();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }, r => {
        console.log(r);
      });
    }
  }
};
</script>

