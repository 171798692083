<template>
  <div class="weixin_setting_form form_info_color clerafix">
    <div class="form_title pr">
      <span style="width:80px">公众号</span>
      <span class="font_blue"
            v-if="false">配置说明</span>
      <!-- 公众号状态 -->
      <span class="form_icon"
            :class="mendianWKTInfo.wechat_set_status=='正常'?'correct':'error'"
            v-if="mendianWKTInfo.wechat_set_status">{{mendianWKTInfo.wechat_set_status}}</span>
      <el-switch v-if="false"
                 class="pa"
                 :readonly="isReadonly"
                 v-model="isValue"></el-switch>
    </div>
    <div class="mendian_setting_content form_info_edit">

      <input-form :formTitle="'AppID'"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.appID">
      </input-form>

      <input-form :formTitle="'AppSecret'"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.appsecret">
      </input-form>

      <input-form :formTitle="'token'"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.token">
      </input-form>

      <input-form :formTitle="'EncodingAESKey'"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.aeskey">
      </input-form>

    </div>
    <div class="form_title border_top pr">
      <span style="width:80px">支付</span>
      <span class="font_blue"
            v-if="false">配置说明</span>
      <!-- 支付状态 -->
      <span class="form_icon"
            :class="mendianWKTInfo.pay_set_status=='正常'?' correct':'error'"
            v-if="mendianWKTInfo.pay_set_status">{{mendianWKTInfo.pay_set_status}}</span>
      <el-switch v-if="false"
                 class="pa"
                 :readonly="isReadonly"
                 v-model="isValue"></el-switch>
    </div>
    <div class="mendian_setting_content form_info_edit">
      <input-form :formTitle="'商户号'"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.partnerNo">
      </input-form>

      <input-form :formTitle="'密钥'"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.pay_secret">
      </input-form>

      <form-select :formTitle="'红包'"
                   class="font_size_L"
                   :readonly="isReadonly"
                   :formPlaceholder="' '"
                   :dataItem="changeType(mendianWKTInfo.is_open_redpaper)"
                   :dataList="selectData"
                   :value="changeType(mendianWKTInfo.is_open_redpaper)"
                   @choseItem="showRedPacketDialog"></form-select>

      <div class="form_info_line "
           style="padding-top:15px">
        <div class="form_info_field_init ">
          <span>apiclient_cert.pem</span>
        </div>
        <div class="form_info_value form_info_select pr">
          <input type="file"
                 name="file"
                 title=""
                 class="apiclient"
                 @change="uploadingFile($event,'apiclient_cert')"
                 data-type="userIconImg">
          <span class="redPaper1">{{mendianWKTInfo.file_apiclient_cert||'请选择文件'}}</span>
        </div>
      </div>

      <div class="form_info_line no_border_bottom"
           style="padding-top:15px">
        <div class="form_info_field_init ">
          <span>apiclient_key.pem</span>
        </div>
        <div class="form_info_value form_info_select pr">
          <input type="file"
                 name="file"
                 title=""
                 class="apiclient"
                 @change="uploadingFile($event,'apiclient_key')"
                 data-type="userIconImg">
          <span class="redPaper1">{{mendianWKTInfo.file_apiclient_key||'请选择文件'}}</span>
        </div>
      </div>
    </div>
    <div class="form_title border_top pr">
      <span style="width:80px">小程序</span>
      <span class="font_blue"
            v-if="false">配置说明</span>
      <!-- 小程序状态 -->
      <span class="form_icon"
            :class="mendianWKTInfo.progm_set_satus=='正常'?'correct':'error'"
            v-if="mendianWKTInfo.progm_set_satus">{{mendianWKTInfo.progm_set_satus}}</span>
      <el-switch v-if="false"
                 class="pa"
                 :readonly="isReadonly"
                 v-model="isValue"></el-switch>
    </div>
    <div class="mendian_setting_content form_info_edit">
      <input-form :formTitle="'AppID'"
                  class="no_border_bottom"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly"
                  v-model="mendianWKTInfo.progm_appid">
      </input-form>
    </div>

    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmEdit"
                           @cancelClick="cancelEdit"></save-cancel-btn-group>
  </div>
</template>

<script>
import formSelect from '../../../form-item/form-select';
import { UploadFiles, GetWeChatOfficialAccountsConfig, WeChatOfficialAccountsConfigSub } from '../../../../API/workbench';
export default {
  data () {
    return {
      newInputText: '', // 默认值
      mendianWKTInfo: {}, // 微信公众号配置信息
      isValue: false,
      selectData: [
        { key: 1, value: '开启' },
        { key: 0, value: '关闭' }
      ]
    };
  },
  props: {
    isReadonly: {
      // 是否只读
      type: Boolean,
      default: false
    }
    // mendianWKTInfo: {
    //   // 门店信息
    //   type: Object,
    //   default: false
    // }
  },
  components: {
    formSelect
  },

  created () {
    this.getWeChatSettingInfo();
  },
  mounted () {

  },
  activated () {

  },
  watch: {
    'this.mendianWKTInfo.file_apiclient_cert': {
      handler (c, o) {
        var reg = /(pem)$/;
        if (!reg.test(c)) {
          this.mendianWKTInfo.file_apiclient_cert = '';
        }
      }
    }
  },
  computed: {

  },
  methods: {
    getWeChatSettingInfo () {
      GetWeChatOfficialAccountsConfig().then(result => {
        console.log(result.data, 'result.data');
        var reg = /(pem)$/;
        if (!reg.test(result.data.file_apiclient_cert)) {
          this.mendianWKTInfo.file_apiclient_cert = '';
        }
        this.mendianWKTInfo = result.data;
      });
    },
    changeType (key) {
      if (Number(key) == 1) {
        return '开启';
      } else if (Number(key) == 0) {
        return '关闭';
      }
    },
    showRedPacketDialog (item) {
      console.log(item);
      this.mendianWKTInfo.is_open_redpaper = item.key;
    },
    doApiclientCertSelect () {

    },
    doApiclientKeySelect () {

    },
    confirmEdit () {
      WeChatOfficialAccountsConfigSub(this.mendianWKTInfo).then(result => {
        layer.alert('修改成功');
        this.$emit('confirmEdit');
        this.$emit('cancelEdit');
      });
    },
    cancelEdit () {
      this.$emit('cancelEdit');
    },
    uploadingFile (e, type) {
      console.log(e.target.files[0], '什么鬼');
      // 文件对象
      var form = e.target.files[0];
      var formData = new FormData();
      // 文件对象
      formData.append('file', form);
      // 防止文件取消时
      if (form) {
        const index1 = form.name.lastIndexOf('.');
        const index2 = form.name.length;
        const suffix = form.name.substring(index1 + 1, index2); // 后缀名  this.$store.getters,
        const reg = /(pem)$/;

        if (reg.test(suffix)) {
          UploadFiles(7, '', formData).then(result => {
            console.log(result.data.url, '撒的发生的');
            if (type == 'apiclient_cert') {
              this.mendianWKTInfo.file_apiclient_cert = result.data.url;
            }
            if (type == 'apiclient_key') {
              this.mendianWKTInfo.file_apiclient_key = result.data.url;
            }
          }, err => {
            layer.alert(err.msg);
          });
        } else {
          layer.alert('不支持此类型的文件上传');
        }
      }
    }
  }

};
</script>

<style scoped>
.apiclient {
  position: relative;
  z-index: 9999;
  opacity: 0;
}
.redPaper1 {
  display: inline-block;
  position: absolute;
  z-index: 9;
  right: 26px;
  top: 0;
  /* top: 4px; */
  width: 200px;
  height: 30px;
  line-height: 30px;
  color: #49b5f3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info_file-upload {
  /* position: relative;
  top: -1.2rem;
  opacity: 0;
  width: 12rem;
  height: 12rem;
  background: red */
}
.Hong_Bao {
  margin-right: 50px;
}
</style>
