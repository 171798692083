<template>
  <div class="student_file_course_list student_file_course_block cp"
       :class="vStatusKeyClass"
       @click.stop="goRouter">
    <!-- 课时卡 -->
    <div v-if="item.UnitKey==5">
      <div class="list_name"
           v-if="item.TaoCanKey>0&&showTaoCan">
        <span class="text_box">{{item.TaoCanKeyValue}}</span>
        <span v-if="item.ApplyTypeKeyValue"
              class="list_name_type">{{vApplyTypeKeyValue}}</span>
      </div>

      <div class="list_text text_top"
           v-if="item.TaoCanKey>0&&showTaoCan">
        <span>{{item.ApplyCorName}}</span>
      </div>
      <div class="list_name"
           v-if="item.TaoCanKey<=0&&!showChild">
        <span class="text_box">{{item.ApplyCorName}}</span>
        <span v-if="item.ApplyTypeKeyValue"
              class="list_name_type">{{vApplyTypeKeyValue}}</span>
      </div>

      <div class="list_text text_top"
           v-if="showChild">
        {{item.ApplyCorName}}
      </div>
      <div class="list_text">
        {{Number(item.UnitPrice)}}元X{{Number(item.NumCount)}}{{item.UnitKeyValue}}
        <span v-if="item.isTransfer==1"
              style="margin-left: 10px;"> 转让</span>
        <span v-if="item.Transferor"
              style="margin-left: 10px;"> {{item.Transferor}} 转让</span>
        <span class="font_red"
              v-if="item.StatusKey==5">退 {{vDueCount}}课时</span>
        <span class="font_red"
              v-else-if="Number(item.PeriodCount)==0&&ExpireCount>0">过期 {{ExpireCount}}课时</span>
        <span class="font_red"
              v-else-if="Number(item.StatusKey)==6&&Number(item.TransferCount)!=0">转让 {{Math.abs(item.TransferCount)}}课时
        </span>
      </div>
      <div class="list_text">{{formatDate(item.StartTime,item.ExpireTime)}}</div>
    </div>
    <!-- 年月日卡 -->
    <div v-else>
      <div class="list_name"
           v-if="item.TaoCanKey>0&&showTaoCan">
        <span class="text_box">{{item.TaoCanKeyValue}}</span>
        <span v-if="item.ApplyTypeKeyValue"
              class="list_name_type">{{vApplyTypeKeyValue}}</span>
      </div>
      <div class="list_text text_top"
           v-if="item.TaoCanKey>0&&showTaoCan">
        <span>{{item.ApplyCorName}}</span>
      </div>
      <div class="list_name"
           v-if="item.TaoCanKey<=0&&!showChild">
        <span class="text_box">{{item.ApplyCorName}}</span>
        <span v-if="item.ApplyTypeKeyValue"
              class="list_name_type">{{vApplyTypeKeyValue}}</span>
      </div>
      <div class="list_text text_top"
           v-if="showChild">
        {{item.ApplyCorName}}
      </div>
      <div class="list_text">
        {{item.UnitKeyValue}}卡 | {{Number(item.NumCount)}}{{item.UnitKeyValue}}
        <span v-if="item.isTransfer==1"
              style="margin-left: 10px;"> 转让</span>
        <span v-if="item.Transferor"
              style="margin-left: 10px;"> {{item.Transferor}} 转让</span>
      </div>
      <div class="list_text">{{formatDate(item.StartTime,item.ExpireTime)}}</div>
    </div>

    <div class="list_right"
         v-if="vshowArprogress">
      <div class="list_right_arprogress">
        <div class="pr">
          <!-- 正常进度显示 -->
          <el-progress type="circle"
                       :show-text="false"
                       :width="100"
                       :stroke-width="8"
                       :stroke-linecap="'butt'"
                       :percentage="vRatePercentage"
                       :color="'#F14E46'"></el-progress>
          <!-- 灰色背景显示 -->
          <el-progress type="circle"
                       :show-text="false"
                       :width="100"
                       :stroke-width="8"
                       :stroke-linecap="'butt'"
                       :percentage="50"
                       class="background_circle"
                       :color="'rgb(229, 233, 242)'"></el-progress>
        </div>
      </div>
      <div class="list_right_text">
        <div class="list_number">
          {{vRate>0?vRate+'%':0}}
        </div>
        <div class="list_course"
             v-if="item.UnitKey==5">
          <span v-if="item.CurrentCount>0"> 剩{{Number(item.CurrentCount)||0}}课时</span>
        </div>
        <div class="list_course"
             v-else>
          <span v-if="item.PeriodCount>0">剩{{(Number(item.PeriodCount))||0}}天</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    showTaoCan: {
      type: Boolean,
      default: false
    },
    showChild: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    vStatusKeyClass () { // 2正常3锁定4退课中5已退6已转让',
      // console.log('StatusKey', this.item);
      if (this.item.StatusKey == 5) { // 退课
        return 'course_type02';
      } else if (this.item.StatusKey == 6) { // 转让
        return 'course_type04';
      } else if (this.item.CurrentCount == 0 && Number(this.item.ExpireCount) == 0 && this.item.UnitKey == 5) { // 已用完
        return 'course_type03';
      } else if (this.item.PeriodCount == 0) { // 到期
        return 'course_type01';
      } else {
        return '';
      }
    },
    vshowArprogress () { // 2正常3锁定4退课中5已退6已转让',
      // console.log('StatusKey', this.item.StatusKey);
      if (this.item.StatusKey == 5) { // 退课
        return false;
      } else if (this.item.StatusKey == 6) { // 转让
        return false;
      } else if (this.item.CurrentCount == 0 && Number(this.item.ExpireCount) == 0 && this.item.UnitKey == 5) { // 已用完
        return false;
      } else if (this.item.PeriodCount == 0) { // 到期
        return false;
      } else {
        return true;
      }
    },
    vRatePercentage () {
      if (this.vRate >= 0) {
        let percentage = this.vRate / 2;
        return percentage;
      } else {
        return 0;
      }
    },
    vRate () {
      if (this.item.UnitKey == 5) {
        if (Number(this.item.NumCount) != Number(this.item.CurrentCount)) {
          var num = (Number(this.item.CurrentCount) / Number(this.item.NumCount)) * 100;
          return Math.round((num > 100 ? 100 : num));
        } else if (Number(this.item.NumCount) > 0 && Number(this.item.CurrentCount) > 0 && Number(this.item
          .NumCount) == Number(this.item.CurrentCount)) {
          return 100;
        } else {
          return 0;
        }
      } else {
        var countNum = 0;
        if (this.item.UnitKey == 2) {
          countNum = Number(this.item.PeriodCount) / (Number(this.item.NumCount) * 365);
        } else if (this.item.UnitKey == 3) {
          countNum = Number(this.item.PeriodCount) / (Number(this.item.NumCount) * 30);
        } else {
          countNum = Number(this.item.NumCount);
        }
        console.log('countNum', countNum, Number(this.item.PeriodCount), Number(this.item.NumCount));
        countNum = countNum > 1 ? 1 : countNum;
        return parseInt(countNum * 100);
      }
    },
    vsumRate () {
      return Number(parseInt(this.vRate / 2)) || 0;
    },
    vApplyTypeKeyValue () {
      if (this.item.ApplyTypeKey == 1) {
        return '新报';
      } else if (this.item.ApplyTypeKey == 3) {
        return '扩科';
      } else {
        return this.item.ApplyTypeKeyValue;
      }
    },
    // 过期
    ExpireCount () {
      return Number(this.item.ExpireCount);
    },
    // 退课数量
    vDueCount () {
      return Number(Number(this.item.DropDeductCount).toFixed(1));
      return Number((Number(this.item.NumCount) + Number(this.item.AdjustCount) - Number(this.item.SpendCount)).toFixed(1));
    }
  },
  methods: {
    goRouter () {
      console.log(this.item, 'goRouter	');
      this.$emit('doAttenCourseSpendDetails', this.item);
    },
    formatDate (start, end) {
      let str = '';
      if (start && start.indexOf('1900-01-01') < 0) {
        str = this.$utils.formatDateToYYYYMMDD(start) + ' - ' + this.$utils.formatDateToYYYYMMDD(end);
      } else {
        str = this.$utils.formatDateToYYYYMMDD(end) + '到期';
      }
      return str;
    }
  }
};
</script>

<style scoped>
.student_file_course_list {
  min-height: 89px;
  padding-left: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
}
.student_file_course_list .list_name {
  width: 100%;
  line-height: 21px;
  font-size: 16px;
  font-weight: bold;
  padding-right: 94px;
  margin-bottom: 4px;
}
.student_file_course_list .list_name .text_box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 193px;
  display: inline-block;
  vertical-align: top;
}
.student_file_course_list .list_name_type {
  display: inline-block;
  vertical-align: top;
  width: 35px;
  text-align: center;
  line-height: 17px;
  font-size: 11px;
  color: #ff8c39;
  background-color: #fffbe6;
  margin-left: 9px;
  margin-top: 2px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid #ffe58f;
  font-weight: normal;
}
.student_file_course_list .list_text {
  font-size: 12px;
  line-height: 16px;
  color: #999;
  width: 100%;
  padding-right: 94px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 325px;
}
.course_type01 {
  background: url(../../../../../../public/image/course_type01.png) no-repeat
    right bottom;
  background-size: 68px;
}
.course_type02 {
  background: url(../../../../../../public/image/course_type02.png) no-repeat
    right bottom;
  background-size: 68px;
}
.course_type03 {
  background: url(../../../../../../public/image/course_type03.png) no-repeat
    right bottom;
  background-size: 68px;
}
.course_type04 {
  background: url(../../../../../../public/image/course_type04.png) no-repeat
    right bottom;
  background-size: 68px;
}
/* 进度显示 */
.student_file_course_list .list_right {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  height: 68px;
  overflow: hidden;
}
.student_file_course_list .list_right_arprogress {
  transform: rotate(-90deg);
}
.student_file_course_list .list_right_text {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.student_file_course_list .list_right_text .list_number {
  padding-top: 22px;
  font-size: 18px;
  line-height: 18px;
  min-height: 40px;
  color: #ff6c67;
  font-weight: bold;
  text-align: center;
}
.student_file_course_list .list_right_text .list_course {
  line-height: 15px;
  color: #999;
  text-align: center;
  margin-top: 2px;
  font-size: 14px;
  padding-top: 0px;
}
</style>

<style>
.student_file_course_list .list_right_arprogress .el-progress-circle {
  padding: 10px;
}
.student_file_course_list
  .list_right_arprogress
  .el-progress-circle
  .el-progress-circle__track {
  display: none;
}
.background_circle {
  z-index: -1;
  position: absolute !important;
  left: 0px;
}
.percentage_circle {
  /* position: absolute;
  left: 0px; */
}
</style>
