<template>
<div>
  <el-container class="page">
    <el-main class="page_content">
      <div class="teacher_statistics clearfix">
        <div class="apply_payment_details fl">
          <div class="massive_bg  apply_payment_details_box">
            <div class="class_statistics_details_content">
              <p class="box_dot dot_green">{{teacherData.totalPersonInServers||0}}</p>
              <p>所有老师</p>
            </div>
            <div class="apply_payment_box_singular box_dot dot_blue">
              包含
              <span>{{teacherData.totalPersonInPartTime||0}}</span> 名兼职老师
            </div>
          </div>
          <div class="teacher_statistics_box massive_bg apply_payment_details_box "
               v-if="isShowMore"
               :style="{height:'auto'}">
            <teacherItem v-for="item in teacherData.DataList"
                         :key="item.OLAPKey"
                         :item="item"></teacherItem>
            <div class="teacher_statistics_list">
              <a @click="chgIsShowMore"
                 class="class_statistics_details_btn">
                收起
              </a>
            </div>

          </div>
          <div class="teacher_statistics_box massive_bg apply_payment_details_box "
               v-else>
            <teacherItem v-for="item in vTeacherList"
                         :key="item.OLAPKey"
                         :item="item"></teacherItem>
            <a @click="chgIsShowMore"
               v-if="teacherData.DataList.length>=7"
               class="class_statistics_details_btn">
              更多老师
            </a>
          </div>

        </div>
        <div class="apply_payment_echarts fl">
          <classSpendForTeacher></classSpendForTeacher>
          <attenForTeacher></attenForTeacher>
        </div>
      </div>
    </el-main>
  </el-container>
  <div>
    <dialog-factory ref="dialogFactory"></dialog-factory>
  </div>
</div>
</template>
<script>
import { getTeacherStatistics } from '../../../API/workbench';
import teacherItem from './teacher-item';
import classSpendForTeacher from './class-spend-for-teacher';
import attenForTeacher from './atten-for-teacher';
import dialogFactory from '../../common/dialog-factory';
export default {
  data () {
    return {
      teacherData: {
        DataList: []
      },
      isShowMore: false
    };
  },
  components: {
    teacherItem, classSpendForTeacher, attenForTeacher, dialogFactory
  },
  computed: {
    vTeacherList () {
      let newArr = [];
      if (this.teacherData.DataList.length > 0) {
        for (var i = 0; i < this.teacherData.DataList.length; i++) {
          if (i <= 5) {
            newArr.push(this.teacherData.DataList[i]);
          }
        }
      }
      return newArr;
    }
  },
  mounted () {
    this.getDataList();
  },
  methods: {
    chgIsShowMore () {
      this.isShowMore = !this.isShowMore;
    },
    getDataList () {
      getTeacherStatistics().then(result => {
        if (result.data.DataList.length > 0) {
          result.data.DataList.sort((x, y) => {
            return Date.new(y.StartTime) - Date.new(x.StartTime);
          });
        }
        this.teacherData = result.data;

        console.log(result.data, 'getTeacherStatistics老师');
      });
    }
  }
};
</script>

