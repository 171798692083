<template>
  <!-- 购买日期 -->
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:122px">
    <div v-if="vIsEdit"
         style="cursor: pointer;">
      <input type="text"
             v-if="!payTime&&!isFocus&&rowType=='add'"
             :placeholder="rowType=='add'?'购买日期':''"
             @focus="focusWatermarkEvent($event)">

      <div class="mendian_backstage_search"
           v-if="rowData.TextPayTime"
           @click.stop="rowData.TextPayTime = ''">
        <input type="text"
               ref="payTime"
               class="text_right"
               :class="{text_error:vPayTimeError}"
               @keydown="keydownEvent($event)"
               @blur="blurEvent"
               @focus="focusEvent($event)"
               v-model="rowData.TextPayTime">
        <span class="remove_btn"
              style="right:10px"
              v-if="rowData.TextPayTime != ''"
              @click.stop="rowTextTimeClick"></span>
      </div>
      <div v-else>
        <input type="date"
               min="2010-01-01"
               max="2100-01-01"
               ref="payTime"
               v-model="payTime"
               :style="(payTime||isFocus||vPayTimeError)?'opacity: 1;':'opacity: 0;position: absolute;'"
               :class="{text_error:vPayTimeError}"
               @keydown="keydownEvent($event)"
               @focus="focusEvent($event)"
               @blur="blurEvent">
        <div class="date_calendar"
             :class="{icon_left:vPayTimeError}"
             :style="(payTime||isFocus||vPayTimeError)?'opacity: 1;':'opacity: 0'">
          <input-date v-if="isDateShow"
                      v-model="payTime"
                      :formTitle="''"
                      :formPlaceholder="' '"
                      :minDate="'2010-01-01'"
                      :maxDate="'2100-01-01'"
                      :popperClass="'table_date_popup'"
                      @changeDate="changeDay"
                      @focusEvent="inputDatefocusEvent"></input-date>
        </div>
      </div>
    </div>
    <!-- $utils.formatDateToLine(Date.new()) -->
  </div>
</template>


<script>

export default {
  data () {
    return {
      type: '',
      payTime: '', // 购买时间
      isFocus: false,
      isDateShow: true // 是否显示日期插件
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object
  },
  computed: {
    vPayTimeError () {
      let flag = false;
      let findItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 5; });
      if (findItem) {
        flag = true;
      }
      return flag;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    'rowData.PayTime': {
      handler (n, o) {
        this.payTime = n;
      }
    },
    rowData: {
      handler (n, o) {
        if (n.focusField == 'PayTime' && n.optioningRow) {
          this.rowData.focusField = 'PayTime';
          this.isFocus = true;
          if (!this.payTime) {
            this.$refs.payTime.style.opacity = 1;
          }
          this.$refs.payTime.focus();
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  created () {
    this.payTime = this.rowData.PayTime;
  },
  methods: {
    // 水印聚焦
    focusWatermarkEvent () {
      this.isFocus = true;
      if (this.$refs.payTime) {
        this.$refs.payTime.focus();
        this.$refs.payTime.style.opacity = 1;
      }
    },
    // 鼠标聚焦
    focusEvent (e) {
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'PayTime';
      this.isFocus = true;
      this.$emit('onFocusEvent', 'PayTime');
      if (!this.payTime) {
        this.$refs.payTime.style.opacity = 1;
      }
    },
    // 键盘事件
    keydownEvent (e) {
      console.log(e.keyCode, '键盘事件');
      this.isDateShow = false;
      switch (e.keyCode) {
        case 9: // Tab
          this.rowData.focusField = 'ExpireTime';
          break;
        case 13: // 回车
          this.rowData.focusField = 'ExpireTime';
          break;
        case 32: // 禁止空格，防止input-date控件自带的日历显示
          e.preventDefault();
          break;
        case 37: // 左键
          this.rowData.focusField = 'ApplyClassKeyValue';
          break;
        case 38: // 向上键
          this.$emit('doUpwardMove', this.rowData, 'PayTime');
          // }
          break;
        case 39: // 右键
          this.rowData.focusField = 'ExpireTime';
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'PayTime');
          break;
        default:
          break;
      }
      if (this.payTime && (Number(this.payTime.charAt(0)) !== 2 || this.payTime > '2100-12-29')) {
        this.payTime = '';
        this.rowData.PayTime = this.payTime;
        console.log(this.payTime, 'this.PayTime');
      }
    },
    // 失去焦点事件 要验证学生名 是否存在
    blurEvent () {
      this.rowData.focusField = '';
      this.isFocus = false;
      if (!this.payTime) {
        this.$refs.payTime.style.opacity = 0;
      } else {
        this.isDateShow = true;
        this.$refs.payTime.style.opacity = 1;
      }
      if (this.rowData.PayTime != this.payTime) {
        this.rowData.PayTime = this.payTime;
      }
      // this.rowData.TextPayTime = this.rowData.PayTime;
      this.verificationExpireTime();
      this.rowData.updateTime = Date.new();
      if (this.rowType == 'add') {
        this.$refs.payTime.blur();
      }
      setTimeout(() => {
        this.isDateShow = true;
      }, 20);
      this.$emit('onBlurEvent', this.rowData, 'PayTime');
      if (!this.rowData.PayTime) {
        this.rowData.TextPayTime = '';
      }
    },
    // 手动清除异常日期
    rowTextTimeClick () {
      this.isFocus = true;
      this.rowData.TextPayTime = '';
      this.rowData.focusField = 'PayTime';
      this.$refs.payTime.style.opacity = 1;
      this.$refs.payTime.focus();
      // this.verificationExpireTime();
      this.$emit('onBlurEvent', this.rowData, 'PayTime');
      this.$emit('onFocusEvent', 'PayTime');
      console.log(this.rowData, 'this.PayTime');
    },
    changeDay (date) {
      this.payTime = date;
      this.rowData.PayTime = this.payTime;
      this.isDateShow = true;
      this.rowData.focusField = 'ExpireTime';
      this.verificationExpireTime();
    },
    inputDatefocusEvent () {
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'PayTime';
    },
    // 异常TargetField：5-》 1：购买日期格式错误，2：购买日期不能超过今天
    verificationExpireTime () {
      let pastDateErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 5 && obj.TypeKey == 2; });
      let formatErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 5 && obj.TypeKey == 1; });
      // 1：购买日期格式错误
      if (formatErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(formatErrorIndex, 1);
      }
      if (this.rowData.PayTime) {
        // 2：购买日期不能超过今天
        let curDate = this.$utils.formatDateToLine(Date.new());
        if (this.rowData.PayTime && (Date.new(this.rowData.PayTime).getTime() > Date.new(curDate).getTime())) {
          if (pastDateErrorIndex < 0) {
            let pastItem = { TargetField: 5, TypeKey: 2, TypeKeyValue: '购买日期不能超过今天' };
            this.rowData.ExceptionList.push(pastItem);
          }
        } else {
          if (pastDateErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(pastDateErrorIndex, 1);
          }
        }
      } else {
        if (pastDateErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(pastDateErrorIndex, 1);
        }
      }
      // 在读课程未选择
      if (!this.rowData.ApplyClassKeyValue) {
        let unSelectedErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
        if (this.rowData.PayTime && unSelectedErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 3, TypeKeyValue: '在读课程未选择' });
        } else {
          if (!this.rowData.PayTime && !this.rowData.ExpireTime && !this.rowData.ActualPayAmount && !this.rowData.NumCount && !this.rowData.CurrentCount && unSelectedErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(unSelectedErrorIndex, 1);
          }
        }
      }
    }
  }
};
</script>

<style>
.form_info_select .el-input--prefix .el-input__inner {
  border: none;
  background-color: transparent;
}
::-webkit-datetime-edit {
  /* background: red */
}
/* – 控制编辑区域的 */

::-webkit-datetime-edit-fields-wrapper {
  /* display: none; */
  /* opacity: 0; */
}
/* – 控制年月日这个区域的 */

::-webkit-datetime-edit-text {
  opacity: 0.2;
  /* color: #c30909; */
}
/* – 这是控制年月日之间的斜线或短横线的 */

::-webkit-datetime-edit-month-field {
  /* opacity: 0.3; */
}
/* – 控制月份 */

::-webkit-datetime-edit-day-field {
  /* opacity: 0.2; */
  /* background: url(../../../../../../public/image/course_header_icon01.png) no-repeat 10%; */
}
/* – 控制具体日子 */

::-webkit-datetime-edit-year-field {
  /* opacity: 0.1; */
}
/* – 控制年文字, 如2017四个字母占据的那片地方 */

::-webkit-inner-spin-button {
  display: none;
}
/* – 这是控制上下小箭头的 */

::-webkit-calendar-picker-indicator {
  display: none;
}
/* – 这是控制下拉小箭头的 */

::-webkit-clear-button {
  display: none;
}
/* –这是控制清除按钮的 */

/* ::-webkit-calendar-picker-indicator {
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px #fff, 0 1px #eee;
  background-color: #eee;
  background-image: -webkit-linear-gradient(top, #f0f0f0, #e6e6e6);
  color: #666;
  opacity: 0;
} */
</style>