<template>
  <div>
    <div class="search_popup">
      <div class="">
        <div class="student_detail_pr">
          <student-lable ref="studentlable"
                         class="content_marginRL content_marginTop_30"
                         :studentID="studentInfo.OLAPKey"
                         :unshowChgStudent="true"></student-lable>
          <div class="student_detail_content single_shadow content_marginRL btn_marginBottom_30 ">
            <div v-if="!vMendianVersioneTY && courseProjectInfo.DataList">
              <div class="content_title icon_01">
                课时计划
              </div>
              <div v-if="courseProjectInfo.Sum_ArrangedCount != 0 || courseProjectInfo.Sum_ToBeArranged != 0"
                   class="content_box flex">
                <div style="flex:2.5">
                  <div class="font_gray">在读课程</div>
                  <div>{{courseInfo.MainDemoName}}</div>
                </div>
                <div style="flex:1"
                     class="tc">
                  <div class="font_gray">可用课时</div>
                  <div>{{Number(courseProjectInfo.Sum_CurrentCount)}}</div>
                </div>
                <div style="flex:1"
                     class="tc">
                  <div class="font_gray">当前安排</div>
                  <div>{{Number(courseProjectInfo.Sum_ArrangedCount)}}</div>
                </div>
                <div style="flex:1"
                     class="tc">
                  <div class="font_gray">等待安排</div>
                  <div>{{Number(courseProjectInfo.Sum_ToBeArranged)}}</div>
                </div>
              </div>
              <div v-else
                   class="course_block_nodata">
                <div class="monitoring_content_nodata">
                  <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
                </div>
              </div>
            </div>
            <div v-if="attendanceCourseList.length != 0">
              <div class="content_title icon_02">
                已排入课
                <div @click="toStundentInfoCourse"
                     class="font_blue fr">更多</div>
              </div>
              <div class="content_ul">
                <div class="content_list"
                     v-for="(item,index) in attendanceCourseList"
                     :key="index">
                  {{item.week}} {{item.BeginClassTime}} {{item.ClassTimeDate}} {{item.CourseNameKeyValue}} {{item.MTeacherKeyValue}}
                  <div @click="closeCourse(item)"
                       class="close fr"></div>
                </div>
              </div>

            </div>
            <div @click="toSearch()"
                 class="student_detail_btn">
              排课
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import studentLable from '../../common/student-lable';
import { GetTheStudentTicketPlanListByClassType, SearchStudentHasArrangeSupport, GetTheClassType, kqDelete } from '../../../API/workbench';

export default {
  data () {
    return {
      courseProjectInfo: {},
      attendanceCourseList: [],
      courseInfo: {}
    };
  },
  props: {
    studentInfo: Object
  },
  created () {
  },
  components: {
    studentLable
  },
  computed: {
    vMendianVersioneTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  methods: {
    closeCourse (item) {
      if (this.studentInfo.OLAPKey > 0 && item.CourseId > 0) {
        kqDelete(this,this.studentInfo.OLAPKey, item.CourseId).then(
        res => {
          if (res.code == 0) {
            console.log(res, '取消课成功');
            layer.alert('取消课成功');
            this.$emit('deleteCourse');
            this.getCourseInfo(this.courseInfo.OLAPKey);
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
      }
    },
    getCourseInfo (courseId) {
      this.attendanceCourseList = [];
      if (!this.courseInfo.OLAPKey) {
        GetTheClassType(courseId).then(res => {
          this.courseInfo = res.data;
        }, err => {
          layer.alert(err.msg);
        });
      }
      GetTheStudentTicketPlanListByClassType(this.studentInfo.OLAPKey, courseId).then(res => {
        this.courseProjectInfo = res.data;
      });
      SearchStudentHasArrangeSupport(this.studentInfo.OLAPKey, courseId).then(res => {
        res.data.forEach(e => {
          if (this.attendanceCourseList.length < 3) {
            if (e.StatusKey == 2 || e.StatusKey == 3) {
              e.week = this.$utils.getWeekByTwo(e.ClassTime);
              e.ClassTimeDate = e.ClassTime.split('-')[1] + '/' + e.ClassTime.split('-')[2];
              this.attendanceCourseList.push(e);
            }
          }
        });
      });
    },
    alterArrangeSupport (studentInfo) {
      this.$parent.optStundentInfo = studentInfo;
    },
    hideBoxClick () {
      this.$emit('hiddenPopup');
    },
    toStundentInfoCourse () {
      this.$refs.studentlable.showStudentFile(true, this.courseInfo);
    },
    toSearch () {
      if (!this.studentInfo) {
        return false;
      }
      let moduleInfo = {};
      moduleInfo = {
        moduleName: 'studentArrangeCourseByCourseName',
        name: '按课程排课',
        routerName: this.$route.name,
        data: {
          studentID: this.studentInfo.OLAPKey,
          courseNameID: this.courseInfo.OLAPKey,
          courseName: this.courseInfo.MainDemoName
        }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    }
  }
};
</script>