<template>
  <div style="width: 100%;">
    <div>
        <student-lable v-if="isLoadSuccess"
                       class="student_lable single_shadow content_marginRL content_marginTop_30"
                       :studentID="studentID"
                       @updateStudentInfo="doAfterUpdateStudentInfo"
                       @alterStudent="alterStudent"
                       :showMenu="true"></student-lable>
        <div class="apply_course_content"
             style="min-height:523px"
             v-if="isLoadSuccess">
          <!-- 统计数据 -->
          <div class="apply_course_details_list">
            <div class="list_left">
              <div class="list_left_title clearfix">
                <div class="list_left_title_icon fl icon_yellow">课</div>
                <div class="fl"
                     style="font-size:18px">{{curSelectedCourseInfo.MainDemoName}}</div>
              </div>
              <div class="list_left_course">
                适用课单 <span class="font_black">{{Number($utils.transformDecimal(courseBillObj.TicketNum,0))}}</span> 笔 | 消耗 <span class="font_black">{{Number($utils.transformDecimal(courseBillObj.Sum_SpendCount,1))}}</span> 课时 | 当前报课<span class="font_blue"> {{applyCount}} </span>课时
                <!-- 剩余 <span class="font_black">{{Number(tickItem.PeriodCount)}}</span> 天 <span class="font_black">{{Number(tickItem.CurrentCount)}}</span> 课时 -->
              </div>
            </div>
            <div class="list_btn"
                 @click.stop="switchCourseName">
            </div>
          </div>
          <div v-if="courseBillObj.dataList.length > 0"
               class="apply_course_ul single_shadow"
               style="max-height:445px">
            <div class="apply_course_ul_box">
              <div class="apply_course_ul_title">
                <span>适用课单</span>
                <span class="font_slogan">点报课分解套餐课时到课程，退报课时返还待报课</span>
              </div>
              <courseBillItem v-for="(item,index) in courseBillObj.dataList"
                              :key="index"
                              :number="index"
                              :item="item"
                              :tickItem="curSelectedCourseInfo"
                              :studentItem="curSelectedStudent"
                              :courseBillInfo="courseBillInfo"
                              @clickMoreOption="clickMoreOption(item)"
                              @applyCourse="showApplyCourse"
                              @refundCourse="showrRefundCourse"></courseBillItem>
            </div>
          </div>
          <div v-else
               class="course_block_nodata border_top">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>

        </div>
        <!-- 提交按钮组 -->
        <!-- <save-cancel-btn-group v-if="courseBillObj.dataList.length > 0"
          style="margin-bottom:20px;"
          :isSingleSubmit="true"
          :singleSubmitType="'dynamic'"
          @confirmClick="confirm"
          @cancelClick="closeDialog"
        ></save-cancel-btn-group> -->
        <!-- <div class="btn_fixed_bottom" v-if="courseBillObj.dataList.length > 0">
          <div class="btn_fixed_white btn_hover_white" @click="closeDialog()" >取消</div>
          <div class="btn_fixed_blue btn_hover_bg_blue" @click="confirm()">
              <div :class="showTotalApplyCount?'btn_footer_confirm':''" >确认</div> 
              <div class="btn_footer_info" v-if="showTotalApplyCount">当前报课{{vTotalApplyCount}}
              
              </div>
          </div>
        </div> -->
      <!-- </custom-dialog> -->
    </div>
    <custom-dialog title="选择课程"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeDialogForCourseNameList">
      <div>
        <single-selection-list :isShowSearchInput="false"
                               :required="true"
                               :dataSourceList="courseNameList"
                               @onSelectedItem="doAfterSelectedCourseName"></single-selection-list>
      </div>
    </custom-dialog>
    <!-- 报课弹窗 -->
    <apply-course-dialog :planItem="selectedPlanItem"
                         :tickItem="curSelectedCourseInfo"
                         ref="applycourse"
                         @confirm="confirmAfter"></apply-course-dialog>
    <!-- 退报课弹窗 -->
    <refund-course-dialog :planItem="selectedPlanItem"
                          :tickItem="curSelectedCourseInfo"
                          ref="refundCourse"
                          @confirm="confirmAfter"></refund-course-dialog>
   
  </div>
</template>
<script>
import studentLable from '../../common/student-lable';
import courseBillItem from './course-bill-item';
import applyCourseDialog from './apply-course-dialog/apply-course';
import refundCourseDialog from './apply-course-dialog/refund-course';
import {
  getStudentRelatedMsg,
  GetTheTicketPlanByApplyClassKey,
  GetTheCourseDetails,
  submitTicketPlan,
  GetTheClassType,
  SelectTheClassType
} from '../../../API/workbench';
export default {
  data () {
    return {
      studentID: 0, // 当前选中学生ID
      applyClassKey: 0, // 当前课名
      courseNameList: [], // 课名列表
      curSelectedCourseInfo: '', // 当前课名
      curSelectedStudent: {}, // 选中学生
      courseBillObj: { dataList: [] }, // 指定学生指定课名的课单列表
      otherApplyCourseList: null, // 课单包含的其它报课计划
      otherApplyList: [],
      isLoadSuccess: false, // 是否加载成功
      isShowMain: false, // 显示操作栏
      isShowCourseNameList: false, // 是否显示课名
      courseBillInfo: {},       // 为了实现course-bill-item里面的值.
      selectedPlanItem: {}
    };
  },
  props: {
    studentKey: [String, Number],
    applyClassID: [String, Number]
  },
  components: {
    studentLable,
    courseBillItem,
    applyCourseDialog,
    refundCourseDialog
  },
  created () {
    if (this.studentKey) {
      this.openDialogByOtherModule(this.studentKey, this.applyClassID);
    }
  },
  mounted () { },
  watch: {},
  computed: {
    // 当前报课
    applyCount () {
      let count = Number(this.courseBillObj.Sum_CurrentCount) > 0 ? Number(this.courseBillObj.Sum_CurrentCount) : 0;
      return Number(this.$utils.transformDecimal(count, 1));
    },
    vTotalApplyCount () {       // 总报课时.
      let count = 0;
      this.courseBillObj.dataList.forEach(o => {
        count += Number(o.activeCount);
      });
      return this.$utils.saveHowMuchDecimal(count, 1);
    },
    showTotalApplyCount () {
      if (this.courseBillObj.dataList.length == 1) {
        return !!this.courseBillObj.dataList[0].isEdit;
      } else {
        return true;
      }
    },
    // 超报课数量
    vSum_SuperQuote () {
      let applyCount = 0;
      this.otherApplyCourseList.forEach(o => {       // 获取所有报课课程报课数量总值.
        o.forEach(p => {
          if (p.Apply < 0) {
            applyCount += Math.abs(p.Apply) + Number(p.activeCount);
          } else {
            applyCount += Number(p.activeCount);
          }
        });
      });

      if (applyCount - Number(this.selectedTickItem.CurrentCount) > 0) {
        return applyCount - Number(this.selectedTickItem.CurrentCount);
      } else {
        return 0;
      }
    },
    // 计算最低值
    vWaitApplyCount () {
      let num = 0;
      this.courseBillObj.dataList.forEach(o => {
        num += this.saveDecimal(Number(o.Sum_PlanCount - o.Sum_SpendCount) + Number(o.WaitForPlanCount));
      });
      console.log(this.courseBillObj.WaitForPlanTotal, 'vWaitApplyCount', num);
      return this.transformDecimal(num, 1);
      // return this.saveDecimal(Number(this.item.Sum_PlanCount - this.item.Sum_SpendCount) + Number(this.item.WaitForPlanCount));
    }
  },
  methods: {
    // 保留小数位数
    saveDecimal (value) {
      var x = String(value).indexOf('.') + 1;   // 小数点的位置
      var y = String(value).length - (x + 1);  // 小数的位数
      if (x >= 0 && y > 1) {
        return Number(value.toFixed(1));
      } else {
        return value;
      }
    },
    openDialogByOtherModule (studentID, applyClassKey) {
      this.studentID = studentID;
      this.applyClassKey = applyClassKey;

      this.loadCourseNameList();
      this.loadCourseInfo();
      this.loadStudentDetailInfo();
      this.isShowMain = true;
    },
    // 获取学生个人信息
    loadStudentDetailInfo () {
      getStudentRelatedMsg(this.studentID)
        .then(result => {
          this.curSelectedStudent = result.data;
        }, err => {
          layer.alert(err.msg);
        });
    },
    // 获取课名信息
    loadCourseInfo () {
      GetTheClassType(this.applyClassKey)
        .then(result => {
          this.curSelectedCourseInfo = result.data;
          console.log(this.curSelectedCourseInfo, 'GetTheClassType');
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 返回该学生指定课名的所有可用课单，及其下的报课计划
    getTheTicketPlanByApplyClassKey () {
      GetTheTicketPlanByApplyClassKey(this.studentID, this.applyClassKey)
        .then(result => {
          result.data.dataList.forEach(obj => {
            obj.changeNum = 0;
            obj.activeCount = Number(obj.Sum_PlanCount) - Number(obj.Sum_SpendCount) > 0 ? Number(obj.Sum_PlanCount) - Number(obj.Sum_SpendCount) : 0;
            let applyCorIDs = obj.ApplyCorIDsName.split(',');
            if (applyCorIDs.length > 1) {
              obj.isEdit = true;
            } else {
              // 全部
              if (applyCorIDs[0] == 2) {
                obj.isEdit = true;
              } else {
                // 是否是课名
                let isCourseName = this.courseNameList.find(o => {
                  return applyCorIDs[0] == o.OLAPKey;
                });
                // 找到就是课名
                if (isCourseName) {
                  obj.isEdit = false;
                } else {
                  obj.isEdit = true;
                }
              }
            }
          });
          this.courseBillObj = result.data;

          this.isLoadSuccess = true;
          this.getTheCourseDetails();
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 获取指定课单的所有报课计划
    getTheCourseDetails () {
      this.otherApplyCourseList = [];
      this.otherApplyList = [];

      this.courseBillObj.dataList.forEach(obj => {
        GetTheCourseDetails(obj.TableID)
          .then(result => {
            let newArr = [];
            let dataArray = [];
            let numPlanCount = 0;
            result.data.forEach(obj => {
              obj.Apply = Number(obj.Apply);
              obj.SpendCount = Number(obj.SpendCount);
              obj.PlanCount = Number(obj.PlanCount);
              let item = {
                PlanCount: (obj.Apply < 0 ? 0 : obj.Apply) + obj.SpendCount,
                ApplyClassKey: obj.ApplyClassTypeKey,
                ApplyClassKeyValue: obj.ApplyClassTypeKeyValue
              };
              numPlanCount = numPlanCount + Number(obj.PlanCount);
              newArr.push(item);
              dataArray.push(obj);
              if (obj.ApplyClassTypeKey == this.applyClassKey) {
                this.courseBillInfo = obj;
              }
            });
            this.otherApplyCourseList.push({
              id: obj.TableID,
              list: newArr,
              dataArray: dataArray,
              numPlanCount: numPlanCount
            });
          })
          .catch(error => {
            layer.alert(error.msg);
          });
      });
    },
    // 确定.
    confirm (content, callBack) {
      content = content || '';
      let needSubmitData = [];
      let findItem = null;
      let allApplyCount = 0;
      // 变更后的才提交

      this.courseBillObj.dataList.forEach(obj => {
        if (obj.changeNum != 0 && obj.TableID == this.selectedPlanItem.TableID) {
          // if (Number(obj.Sum_PlanCount - obj.Sum_SpendCount < 0) || (obj.changeNum != 0 && obj.activeCount != obj.Sum_PlanCount)) {
          // 找到变更课单 下 的所有报课计划
          findItem = this.otherApplyCourseList.find(item => {
            return item.id == obj.TableID;
          });
          // 找到变更的报课计划 并修改报课数
          let findIndex = findItem.list.findIndex(o => {
            return o.ApplyClassKey == this.applyClassKey;
          });
          // findItem.list[findIndex].PlanCount = Number(obj.Sum_PlanCount - obj.Sum_SpendCount < 0) ? obj.changeNum + Number(obj.Sum_PlanCount) : obj.activeCount;
          // findItem.list[findIndex].PlanCount =
          //   Number(obj.changeNum) + Number(obj.Sum_PlanCount);
          // 原已报课总数量-原报课计划数量+调整后的报课计划数量
          let findCourseItem = null;
          findItem.dataArray.forEach(p => {
            allApplyCount += p.Apply < 0 ? 0 : p.Apply;
            if (p.ApplyClassTypeKey == this.applyClassKey) {
              findCourseItem = p;       // 知道当前课单的报课计划中的指定课名项
            }
          });
          if (findCourseItem) {       // 修正数据.
            console.log('findCourseItem', findCourseItem);
            findItem.list[findIndex].PlanCount =
              Number(obj.changeNum) + (findCourseItem.Apply < 0 ? 0 : findCourseItem.Apply) + findCourseItem.SpendCount;
          }
          // 剩余数量-调整数量+总报课数量/
          let sumPlan = Number(obj.CurrentCount) - (Number(obj.changeNum) + allApplyCount);
          let waitPlanCount = sumPlan < 0 ? 0 : sumPlan;
          let complexItem = {
            PlanCount: Number(Number(waitPlanCount).toFixed(1)),
            ApplyClassKey: 0,
            ApplyClassKeyValue: '复合'
          };
          findItem.list.push(complexItem);
          needSubmitData.push(findItem);
        }
      });

      submitTicketPlan(findItem.id, findItem.list).then(result => {
        if (result.code == 0) {
          // this.isConfirmed = true;
          layer.alert(content);
          this.$bus.emit('AfterCourseNameApplyCourse', result.data);
          this.getTheTicketPlanByApplyClassKey();
        } else {
          layer.alert(result.msg);
        }
      }).catch(error => {
        // this.isConfirmed = true;
        this.getTheTicketPlanByApplyClassKey();
        layer.alert(error.msg);
      });
    },
    alterStudent (item) {
      this.curSelectedStudent = item;
      this.studentID = item.OLAPKey;
      this.getTheTicketPlanByApplyClassKey();
    },
    // 点击课单 更多操作
    clickMoreOption (item) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        routertitle: '学生管理',
        moduleName: 'studentApplyCourse',
        name: '学生报课',
        data: { studentApplyCourseParams: { studentID: this.studentID, taoCanID: item.TableID } }
      });
      this.$emit('closeDialog');
    },
    // 切换课名
    switchCourseName () {
      this.isShowCourseNameList = true;
    },
    // 选中课名
    doAfterSelectedCourseName (item) {
      this.applyClassKey = item.OLAPKey;
      this.curSelectedCourseInfo = item;
      this.getTheTicketPlanByApplyClassKey();
      this.closeDialogForCourseNameList();
    },
    // 获取课名列表
    loadCourseNameList () {
      SelectTheClassType().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          if (obj.StateKey != 3 && obj.TypeKey == 2) {
            // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
            this.courseNameList.push(obj);
          }
        });
        // 要根据课名列表来判断课单对应的报课计划是否为单课
        this.getTheTicketPlanByApplyClassKey();
      });
    },
    // 报课弹窗
    showApplyCourse (item) {
      this.selectedPlanItem = item;
      this.$refs.applycourse.chgisShowMain();
    },
    // 退报课弹窗
    showrRefundCourse (item) {
      this.selectedPlanItem = item;
      this.$refs.refundCourse.chgisShowMain();
    },
    // 报课
    confirmAfter (item, content) {
      let locaIndex = this.courseBillObj.dataList.findIndex(o => {
        return o.TableID == item.TableID;
      });
      if (locaIndex >= 0) {
        this.courseBillObj.dataList[locaIndex] = this.$utils.parseJson(item);
      }
      this.confirm(content);
    },
    closeDialog () {
      this.isShowMain = false;
      this.$emit('closeDialog');
    },
    // 关闭 课名列表
    closeDialogForCourseNameList () {
      this.isShowCourseNameList = false;
    },
    doAfterUpdateStudentInfo (id) {
      this.studentID = id;
      this.loadStudentDetailInfo();
    },
    transformDecimal (value, point) {
      return value == '' ? value : Number(value).toFixed(point);
    }
  }
};
</script>
