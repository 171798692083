<template>
  <div class="attract_investment_manage_following">
    <search-box :isBatchDistribute="isBatchDistribute"
                :paramsForQuery="paramsForQuery"
                :batchAllotList="batchAllotList"
                :tableData="investorList"
                @searchClick="searchClick"
                @clearSearchParams="clearSearchParams"
                @enableBatchAllot="enableBatchAllot"
                @doBatchAllot="distributeAdvisor"
                @cancleBatchDistribute="cancleBatchDistribute"></search-box>
    <div class="table_list_content">
      <table-list ref="tableListRef"
                  :tableData="investorList"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="paramsForQuery"
                  :isShowCheckBox="true"
                  @changeCheckBoxFun="clickCheckBox"
                  @loadTableData="loadInvestorList"></table-list>
      <!-- :selectable="false" -->
    </div>
  </div>
</template>

<script>
import tableList from '../../../../common/table-list'; // 下拉选择框
import searchBox from './search-box';
import { SearchPageBeFollowingUpInvestment } from '../../../../../API/workbench';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '招商顾问',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          align: 'center',
          width: 90,
          extend: {
            investorID: 'OLAPKey',
            className: 'font_blue',
            click: (rowData) => {
              this.distributeAdvisor(rowData);
            }
          }
        },
        {
          label: '投资人',
          prop: 'MainDemoName',
          width: 90,
          type: 'text-item'
        },
        {
          label: '意向级别',
          prop: 'IntentionKeyValue',
          width: 60,
          type: 'text-item',
          columnClass: 'font_dual',
          sortable: 'custom'
        },
        {
          label: '签单进度',
          prop: 'StatusKeyValue',
          type: 'text-item',
          align: 'center',
          width: 85,
          extend: {
            setClassName: (data) => {
              // 签单进度0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款;-1-已拒绝
              let className = '';
              if (
                data.StatusKey == 0 ||
                data.StatusKey == 1 ||
                data.StatusKey == 2 ||
                data.StatusKey == 3 ||
                data.StatusKey == 4
              ) {
                className = 'status_color_yellow';
              } else if (data.StatusKey == 5 || data.StatusKey == 6) {
                className = 'status_color_grass_green';
              } else if (data.StatusKey == 7) {
                className = 'status_color_green';
              } else if (data.StatusKey == -1) {
                className = 'status_color_grass_yellow';
              }
              return className;
            }
          }
        },
        {
          label: '跟进记录',
          prop: 'Content',
          type: 'follow-up-record-column',
          width: 300,
          extend: {
            FollowTime: 'FollowTime' // 最近跟进时间
          }
        },
        {
          label: '下次跟进',
          prop: 'NextFollowTime',
          type: 'next-follow-time-column',
          align: 'center',
          width: 85
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 120,
          type: 'text-item'
        },
        {
          label: '计划城市',
          prop: 'CityKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '招商预算',
          prop: 'budgetAmount',
          type: 'text-item',
          width: 90,
          sortable: 'custom'
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          type: 'text-item',
          align: 'center',
          width: 90
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 90,
          sortable: 'custom'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 150,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.lookUpDetail(rowData);
                  }
                }
              },
              {
                name: '分配',
                extend: {
                  click: (rowData) => {
                    this.distributeAdvisor(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: '', // 排序顺序
        entryTimeStart: '', // 开始录入时间
        entryTimeEnd: '', // 结束录入时间
        CounselorKey: 0, // 投资顾问
        IntentionKey: 0, // 意向级别1-S;2-A;3-B;4-C
        SourceTypeKey: 0, // 来源
        StatusKey: '', // 签单进度0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款;-1-已拒绝
        IsExportKey: 0, // 是否导出：0-否;1-是;如果为是pageIndex传0,pageSize传0
        jsonFormate: null
      },
      investorList: [], // 投资人列表

      totalNum: 0,
      defaultSort: {},
      batchAllotList: [], // 选择的投资人列表
      isBatchDistribute: false
    };
  },
  props: {
    curSelectedPrams: Object
  },
  components: {
    tableList,
    searchBox
  },
  watch: {
    curSelectedPrams (nData, oData) {
      nData = nData || {};
      if (
        Object.keys(nData).length > 0 &&
        nData.StatusKey != this.paramsForQuery.StatusKey
      ) {
        this.paramsForQuery.StatusKey = nData.StatusKey;
        this.loadInvestorList();
      }
    }
  },
  created () {
    console.log('this.curSelectedPrams', this.curSelectedPrams);
    if (
      this.curSelectedPrams &&
      Object.keys(this.curSelectedPrams).length > 0
    ) {
      this.paramsForQuery.StatusKey = this.curSelectedPrams.StatusKey;
    }
    this.loadInvestorList();
  },
  methods: {
    // 获取跟进中投资人列表
    loadInvestorList () {
      SearchPageBeFollowingUpInvestment(this.paramsForQuery).then((result) => {
        // console.log('跟进中投资人总列表', result);
        console.log('获取跟进中投资人列表', result.data.PageDataList);
        result.data.PageDataList.forEach((obj) => {
          obj.CityKeyValue = obj.ProvinceKeyValue + '·' + obj.CityKeyValue;
          obj.budgetAmount =
            this.$utils.mMoneyType(obj.StratBudgetAmount) +
            '-' +
            this.$utils.mMoneyType(obj.EndBudgetAmount) +
            '万';
        });
        this.investorList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 点击搜索
    searchClick (queryParams) {
      this.paramsForQuery = queryParams;
      this.loadInvestorList();
    },
    // 清除搜索条件
    clearSearchParams () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.paramsForQuery.entryTimeStart = '';
      this.paramsForQuery.entryTimeEnd = '';
      this.paramsForQuery.CounselorKey = 0;
      this.paramsForQuery.IntentionKey = 0;
      this.paramsForQuery.SourceTypeKey = 0;
      this.paramsForQuery.StatusKey = '';
      this.loadInvestorList();
    },
    // 查看详情
    lookUpDetail (rowData) {
      this.$emit('showInvestorDetails', rowData);
    },
    // 启用批量分配
    enableBatchAllot () {
      this.isBatchDistribute = true;
    },
    // 批量分配 选中一行
    clickCheckBox (lists, rowData) {
      if (!this.isBatchDistribute && lists.length > 0) {
        this.isBatchDistribute = true;
      } else if (lists.length == 0) {
        this.isBatchDistribute = false;
      }
      let arr = lists.filter((item) => {
        return item.OLAPKey > 0;
      });
      this.batchAllotList = arr;
    },
    // 取消批量分配
    cancleBatchDistribute () {
      this.isBatchDistribute = false;
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.batchAllotList = [];
    },
    // 分配投资人
    distributeAdvisor (rowData, type) {
      let item = {
        routerName: this.$route.name,
        moduleName: 'distributeInvestmentAdvisor',
        name: '分配顾问',
        data: { investorIDs: rowData.OLAPKey },
        callBack: {
          afterSuccess: (data) => {
            this.loadInvestorList();
            if (type == 'batch') {
              this.cancleBatchDistribute();
            }
          }
        }
      };
      this.$dialog.open(this, item);
    },
    // 批量分配后
    doAfterBatchDistribute (data) {
      this.batchAllotList.forEach((obj) => {
        obj.CounselorKey = data.OLAPKey;
        obj.CounselorKeyValue = data.name;
      });
      this.cancleBatchDistribute();
    }
  }
};
</script>

<style >
.investor_manage_view {
  justify-content: space-between;
}
.recycle_bin {
  position: absolute;
  right: 20px;
  top: 30px;
  height: 40px;
  width: 80px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db url(../../../../../../public/image/recycled_icon.png)
    no-repeat 8px;
}
.attract_investment_manage_following .status_color_yellow,
.attract_investment_manage_following .status_color_grass_green,
.attract_investment_manage_following .status_color_green,
.attract_investment_manage_following .status_color_grass_yellow {
  position: absolute;
  width: 65px;
  height: 22px;
  border-radius: 2px;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  line-height: 22px;
  text-align: center;
  color: #333333;
  margin-left: 10px;
}
.attract_investment_manage_following .status_color_yellow {
  background: rgba(252, 239, 228);
}
.attract_investment_manage_following .status_color_grass_green {
  background: rgba(220, 245, 237);
}
.attract_investment_manage_following .status_color_green {
  background: rgba(120, 209, 181);
}
.attract_investment_manage_following .status_color_grass_yellow {
  background: #f9d7d9;
}
</style>