<template>
  <!-- 客户管理 -->
  <div class="customer_management massive_bg">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin">
        <div @click="showMendainListClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='mendainList'}">售前跟单</div>

        <div @click="showHistoryListClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='recordList'}">售后跟单</div>
      </div>
    </div>
    <div>
      <!-- 售前跟单 -->
      <div v-if="showManagement=='mendainList'">
        <pre-sales-documentary-list :listType="showManagement"></pre-sales-documentary-list>
      </div>
      <!-- 售后跟单 -->
      <div v-else-if="showManagement=='recordList'">
        <after-sale-documentary-list :listType="showManagement"></after-sale-documentary-list>
      </div>
    </div>
    <div>
      <dialog-factory ref="customerManagementDialogFactory"
                      :routerName="'customerManagement'"></dialog-factory>
      <dialog-factory-common ref="customerManagement"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import preSalesDocumentaryList from './pre-sales-documentary-list/index';
import afterSaleDocumentaryList from './after-sale-documentary-list/index';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'customerManagement',
  data () {
    return {
      showManagement: 'mendainList'
    };
  },
  components: {
    dialogFactory,
    preSalesDocumentaryList,
    afterSaleDocumentaryList
  },
  created () {
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'customerManagement') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      console.log('openCommonCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'customerManagement') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    // 切换门店列表
    showMendainListClick () {
      this.showManagement = 'mendainList';
    },
    // 切换管理历史记录列表
    showHistoryListClick () {
      this.showManagement = 'recordList';
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.customerManagementDialogFactory) {
        this.$refs.customerManagementDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.customerManagement) {
        this.$refs.customerManagement.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  },
  computed: {}
};
</script>
