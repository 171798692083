<template>
  <div class="subject_ul">
    <div class="mult_info_select_type mult_info_ul">
      <div class="mult_info_list part_type cp"
           :class="{'top_icon':isShowAll,'bottom_icon':!isShowAll,'opt':!isShowAll}">
        <div class="mult_info_list_text"
             :class="{'select_btn':subjectDataList.length>0}"
             @click="selectedItemClick(allList)">
          <span class="text_box all_type">全部</span>
          <div class="mult_info_list_right"
               v-if="subjectDataList.length>0"
               @click.stop="doSubjectItemClick(allList)"></div>
        </div>
      </div>
      <div class="subject_list"
           v-if="subjectDataList.length>0 &&isShowAll">
        <items v-for="(item,index) in subjectDataList"
               :key="index"
               :item="item"
               @editSubjectItemClick="selectedItemClick"></items>

      </div>
    </div>
  </div>
</template>

<script>
import items from './items.vue';
export default {
  components: {
    items
  },
  data () {
    return {
      isShowAll: true,
      allList: {
        OLAPKey: 2,
        MainDemoName: '全部',
        FatherKey: 0,
        FatherKeyValue: '',
        isSelected: false
      },
      subjectDataList: []
    };
  },
  props: {
    dataInfo: { // 表单信息
      type: Object,
      default: {}
    },
    subjectList: { // 科目列表
      type: Array,
      default: []
    }
  },
  created () {
    this.getSuperiorSubjectList();
  },
  computed: {

  },
  methods: {
    getSuperiorSubjectList () {
      this.subjectDataList = this.$utils.parseJson(this.subjectList); // 复制一份数据
      this.subjectDataList.forEach(o => {
        o.isOpt = false;
        this.$set(o, 'isSelected', false);
        if (Number(this.dataInfo.FatherKey) == Number(o.OLAPKey)) {
          o.isOpt = true;
          this.$set(o, 'isSelected', true);
          console.log(o, 'o');
        }
        o.childSubject.forEach(p => {
          this.$set(p, 'isSelected', false);
          if (Number(this.dataInfo.FatherKey) == Number(p.OLAPKey)) {
            this.$set(p, 'isSelected', true);
            o.isOpt = true;
          }
        });
      });
    },
    className (item) {
      var iconClass = '';
      var optClass = '';
      if (item.isOpt) {
        iconClass = 'top_icon';
      } else {
        iconClass = 'bottom_icon';
      }
      if (item.isSelected) {
        optClass = 'opt';
      } else {
        optClass = '';
      }
      console.log(iconClass + optClass, item, 'className');
      return iconClass + optClass;
    },
    // 全部
    doAllSubjectItemClick () {
      this.isShowAll = !this.isShowAll;
    },
    // 展开收起
    doSubjectItemClick (item) {
      if (item.FatherKey == 2 && item.childSubject.length > 0) {
        item.isOpt = !item.isOpt;
      } else if (item.FatherKey == 0) { // 全部
        this.isShowAll = !this.isShowAll;
      }
      console.log(item, '展开收起');
    },
    // 选中
    selectedItemClick (item) {
      item.isSelected = !item.isSelected;
      if (item.isSelected) {
        this.$emit('selectedItem', item);
      }
    }
  }
};
</script>

<style>
</style>
