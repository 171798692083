<template>
  <div class="swiper-slide courseware_packge_list_item"
       @click.stop="seletedItem">
    <div class="courseware_packge_list_item_bg"></div>
    <img :src="vImg"
         alt="">
    <div class="courseware_packge_list_item_bottom">
      {{item.MainDemoName}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },

  computed: {
    vImg () {
      if (this.item.AttachTypeKey == '1') {
        if (this.item.AttachViewUrl.indexOf('data:image') != -1) {
          return this.item.AttachViewUrl;
        } else {
          if (this.item.CoverUrl && this.item.CoverUrl.length > 1) {
            return this.$store.getters.CDNURL + this.item.CoverUrl;
          } else {
            return (
              this.$store.getters.PCWebURL +
              this.item.AttachViewUrl +
              '?sign=' +
              this.$store.getters.signData.Sign
            );
          }
        }
      } else if (this.item.AttachTypeKey == '2') {
        if (this.item.CoverUrl) {
          return this.$store.getters.CDNURL + this.item.CoverUrl;
        } else {
          return require('../../../../public/image/courseware_list_type01.png');
        }
      } else if (this.item.AttachTypeKey == '3') {
        return require('../../../../public/image/courseware_list_type02.png');
      } else if (this.item.AttachTypeKey == '4') {
        if (this.item.CoverUrl) {
          return this.$store.getters.CDNURL + this.item.CoverUrl;
        } else {
          return require('../../../../public/image/courseware_list_type03.png');
        }
      } else if (this.item.AttachTypeKey == '5') {
        return require('../../../../public/image/courseware_pdf_cover.png');
      }
    }
  },
  methods: {
    seletedItem () {
      if (this.$zxClient && this.$zxClient.IsClient) {
        console.log("seletedItem", this.item)
        this.$emit("seletedItem", this.item)
      } else {
        layer.alert('请用桌面端打开程序', {
          mode: 'teachingMode'
        });
      }
    }
  }
};
</script>
<style scoped>
.courseware_packge_list_item {
  width: 156.25rem !important;
  height: 117.19rem;
  margin-right: 12.5rem !important;
  position: relative;
  /* border: 0.78rem solid rgba(255, 255, 255, 0.4); */
  border-radius: 7.03rem;
  overflow: hidden;
  display: inline-block;
  background: #a9a9a9;
}
.courseware_packge_list_item:hover {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #2afea9 0.01%, #1c4ef4 102.48%);
  border: 1.17rem solid #ffffff;
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  border-radius: 7.03rem;
  cursor: pointer;
}
.courseware_packge_list_item .courseware_packge_list_item_bg {
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.courseware_packge_list_item img {
  width: 100%;
  /* height: 87%; */
  /* background: linear-gradient(225.87deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 90.8%);
        opacity: 0.35; */
}
.courseware_packge_list_item_bottom {
  width: 100%;
  height: 31.6rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1.17rem 2.34rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 4.68rem;
  position: absolute;
  bottom: 0px;
  font-weight: 400;
  font-size: 9.36rem;
  line-height: 31.6rem;
  color: #ffffff;
  /* text-align: center; */
  padding-left: 8.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>