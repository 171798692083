<template>
  <!-- 历史套餐-->
  <div>
    <input-search-immediately v-model="searchText"
                       :placeholder="'请输入学生名'"
                       :styleText="'right: 25px;'"
                       :inputStyle="'width: 396px;margin:20px 0;'"
                       @clearSearch="clearSearText"></input-search-immediately>
  <div class="WKT_discount_package_popup_ul">
    
    <div class="WKT_information_teacher_list">
      <combo-history-item v-for="(item,index) in vGetHistoryComboList"
                          :key="index"
                          :item="item"
                          @enable="enableAfter"></combo-history-item>
    </div>
    <div v-if="vGetHistoryComboList.length==0">
      <div class="monitoring_content_nodata"
           style="margin-top:160px">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import comboHistoryItem from './combo-history-item';
import { SelectDisEnableStarStudents, EnableStarStudents } from '../../../../API/workbench';
export default {
  name: 'singleSelectionItem',
  components: {
    comboHistoryItem
  },
  data () {
    return {
      searchObj: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: 'desc', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      searchText: '',
      historyComboList: []
    };
  },
  created () {
    this.getDisEnableList();
  },
  computed: {
    vGetHistoryComboList () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      this.historyComboList.forEach(o => {
        if (o.StarStudentsName && (o.StarStudentsName.toLowerCase().indexOf(trueSearchText) >= 0 || (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))) {
          newList.push(o);
        }
      });
      return newList;
    }
  },
  methods: {
    getDisEnableList () {
      SelectDisEnableStarStudents(this.searchObj).then(result => {
        this.historyComboList = result.data.PageDataList;
        console.log(result, 'SelectDisEnableStarStudents');
      }).catch(error => {
        console.log('SelectDisEnableStarStudentsERROR', error);
      });
    },
    enableAfter (item) {
      EnableStarStudents(item.id).then(result => {
        layer.alert('启用成功');
        this.getDisEnableList();
        this.$emit('enableAfter');
      });
    },
    selectedItem () {
      this.$emit('selectedItem', this.selectItem);
    },
    // 清除按钮
    clearSearText () {
      this.searchText = '';
    }
  }
};
</script>

