<template>
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add','select_type':vIsEdit}"
       style="width:60px"
       @click.stop="clickSexName">
    <div v-if="!isShowSelection&&rowType!='add'"
         class="paddingRight">{{rowData.SexKeyValue}}</div>
    <div v-if="isShowSelection||rowType=='add'">
      <el-select v-model="rowData.SexKeyValue"
                 filterable
                 :placeholder="rowType=='add'?'性别':''"
                 ref="sexKeyValue"
                 @keydown.native="keydownEvent($event)"
                 @blur="blurEvent"
                 @focus="focusEvent($event)"
                 @change="changeEvent">
        <el-option v-for="item in sexArr"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      sexArr: [
        { name: '女', id: 3 },
        { name: '男', id: 2 }
      ],
      isFocus: false,
      isShowSelection: false
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object,
    scrollTopVal: Number
  },
  computed: {
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    rowData: {
      handler (n, o) {
        if (n.focusField == 'SexKeyValue' && n.optioningRow) {
          this.rowData.focusField = 'SexKeyValue';
          this.isFocus = true;
          if (this.isShowSelection) {
            this.$nextTick(() => {
              if (this.$refs.sexKeyValue) {
                this.$refs.sexKeyValue.focus();
              }
            });
          } else {
            this.isShowSelection = true;
            this.$nextTick(() => {
              if (this.$refs.sexKeyValue) {
                this.$refs.sexKeyValue.focus();
              }
            });
          }
        }
      },
      // immediate: true,
      deep: true // 表示开启深度监听
    },
    scrollTopVal: {
      handler (n, o) {
        if (n > 0) {
          this.isShowSelection = false;
          this.isFocus = false;
        }
      },
      immediate: true
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  created () {
  },
  methods: {
    clickSexName () {
      if (this.vIsEdit) {
        this.isShowSelection = true;
        this.$nextTick(() => {
          this.rowData.focusField = 'SexKeyValue';
          this.rowData.optioningRow = true;
        });
      }
    },
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'SexKeyValue';
      this.$emit('onFocusEvent', 'SexKeyValue');
      setTimeout(() => {
        let elScrollbarElms = document.getElementsByClassName('el-scrollbar');
        if (elScrollbarElms.length > 0) {
          let item = this.sexArr.find(obj => { return obj.name.indexOf(e.target.value) >= 0; });
          if (!item) {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'none';
            }
          } else {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'block';
            }
          }
        }
      }, 50);
    },
    // 键盘事件
    keydownEvent (e) {
      switch (e.keyCode) {
        case 9: // Tab
        case 13: // 回车
        case 39: // 右键
          this.rowData.focusField = 'CustomerPhoneName';
          break;
        case 37: // 左键
          this.rowData.optioningRow = true;
          this.rowData.focusField = 'StudentKeyValue';
          break;
        default:
          break;
      }
      setTimeout(() => {
        let elScrollbarElms = document.getElementsByClassName('el-scrollbar');
        if (elScrollbarElms.length > 0) {
          let item = this.sexArr.find(obj => { return obj.name.indexOf(e.target.value) >= 0; });
          if (!item) {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'none';
            }
          } else {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'block';
            }
          }
        }
      }, 50);
    },
    blurEvent (e) {
      this.verificationSexKeyValue();
      setTimeout(() => {
        this.isFocus = false;
        this.isShowSelection = false;
        if (this.rowType == 'add') {
          this.$refs.sexKeyValue.blur();
        }
      }, 100);
      this.rowData.focusField = '';
      this.$emit('onBlurEvent', this.rowData, 'SexKeyValue');
      this.rowData.updateTime = Date.new();
    },
    changeEvent (id) {
      let item = this.sexArr.find(obj => { return obj.id == id; });
      this.isFocus = false;
      this.rowData.SexKey = item.id;
      this.rowData.SexKeyValue = item.name;
      this.verificationSexKeyValue();
      this.rowData.updateTime = Date.new();
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'CustomerPhoneName';
      this.isShowSelection = false;
    },
    // 验证性别错误 异常记录
    verificationSexKeyValue () {
      let sexErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 2 && obj.TypeKey == 1; });
      // 性别等于 空 或者 性别不等于 男 女
      if (!this.rowData.SexKeyValue || (this.rowData.SexKeyValue && this.rowData.SexKeyValue != '男' && this.rowData.SexKeyValue != '女')) {
        if (sexErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 2, TypeKey: 1, TypeKeyValue: '未知性别' });
          this.rowData.isShowCorrect = false;
        }
      } else {
        if (sexErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(sexErrorIndex, 1);
        }
      }
    }
  }
};
</script>

<style>
</style>