<template>
  <div>
    <div class="customer_management_from form_info_list popup_height_from">
      <input-text :formTitle="'申请日期'"
                  :readonly="true"
                  :formPlaceholder="'请输入申请日期'"
                  class=""
                  v-model="dataInfo.ApplyTime"></input-text>
      <input-text :formTitle="'申请人'"
                  :readonly="true"
                  :formPlaceholder="'请输入申请人'"
                  class=""
                  v-model="dataInfo.ApplicantKeyValue"></input-text>
      <input-text :formTitle="'机构名称'"
                  :readonly="true"
                  :formPlaceholder="'请输入机构名称'"
                  class=""
                  v-model="dataInfo.SaaSClientNameKeyValue"></input-text>
      <input-text :formTitle="'机构ID'"
                  :readonly="true"
                  :formPlaceholder="'请输入机构ID'"
                  class=""
                  v-model="dataInfo.support20"></input-text>
      <input-text :formTitle="'开户日期'"
                  :readonly="true"
                  :formPlaceholder="'请输入开户日期'"
                  class=""
                  v-model="dataInfo.ApplyTime"></input-text>
      <div class="from_input_list">
        <div class="from_input_title form_info_required">
          清除数据
        </div>
        <div class="from_list_select flex"
             style="align-items: center;">
          <el-checkbox v-model="business"
                       :true-label="1"
                       :false-label="0"
                       :disabled="vIsEdit"
                       @change="seletedbusiness">业务</el-checkbox>
          <el-checkbox v-model="cleanCustor"
                       :true-label="2"
                       :false-label="0"
                       :disabled="business==0||vIsEdit"
                       @change="seletedcleanCustor">客户</el-checkbox>
          <el-checkbox v-model="cleanClassCount"
                       :true-label="3"
                       :false-label="0"
                       :disabled="cleanCustor==0||vIsEdit"
                       @change="seletedcleanClassCount">课次</el-checkbox>
          <el-checkbox v-model="cleanInitialValue"
                       :true-label="4"
                       :false-label="0"
                       :disabled="cleanClassCount==0||vIsEdit"
                       @change="seletedInitialValue">基础资料</el-checkbox>
          <!-- <el-radio v-model="dataInfo.type"
                  label="click">发送消息</el-radio>
        <el-radio v-model="dataInfo.type"
                  label="view">跳转网页</el-radio>
        <el-radio v-model="dataInfo.type"
                  label="miniprogram">跳转小程序</el-radio> -->
        </div>
      </div>
      <input-text :formTitle="'申请原因'"
                  :readonly="vIsEdit"
                  :required="true"
                  :formPlaceholder="'请输入'"
                  class=""
                  v-model="dataInfo.ReasonScript"></input-text>
    </div>

    <div v-if="!vIsEdit">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submitAdd"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
    <div v-else>
      <save-cancel-btn-group :cancelBtnText="'撤销'"
                             :isSingleSubmit="true"
                             :btnText="'提交审核'"
                             :fixedBtn="true"
                             @confirmClick="submitAudit"
                             @cancelClick="revocation"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'清数据'"
                   class=" "
                   :visible.sync="showAuthCode"
                   :before-close="closeShowAuthCode">
      <auth-code :dataInfo="dataInfo"
                 @close="closeShowAuthCode"
                 @afterSuccess="afterSuccess"></auth-code>
    </custom-dialog>
  </div>
</template>
<script>
import authCode from './auth-code';
import { AddApply, GetApplyDetails, CancelApply } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        'ApplyTime': this.$utils.formatDateToLine(Date.new()), // '申请时间',
        'ApplicantKey': '', // '申请人',
        'ApplicantKeyValue': '', // '申请人',
        'SaaSClientNameKey': '', // '机构Key',
        'SaaSClientNameKeyValue': '', // '机构',
        'SaaSClientStatusKey': '', // '机构状态0-未开通;1-临时开通;2-正式开通;3-关停',
        'SaaSClientStatusKeyValue': '', // '机构状态',
        'MobileSupport': '', // '手机号',
        'ContactsName': '', // '联系人',
        'ThreadKey': '', // '线索key',
        'TypeKey': '4', // '1-开户;2-付款;3-返介绍费;4-清数据',
        'TypeKeyValue': '清数据', // '1-开户;2-付款;3-返介绍费;4-清数据',
        'ReasonScript': '', // '申请原因',
        'PayMent': null,
        'DataBaseKey': '',
        //  {// 付款申请
        //   'PaymentTime': '', // '付款时间',
        //   'IsOpenAcademicKey': '', // '是否开通教务',
        //   'IsOpenWKTKey': '', // '是否开通WKT',
        //   'TypeKey': '', // '1-首付;2-续费;3-加功能;4-还尾款',
        //   'TypeKeyValue': '', // '1-首付;2-续费;3-加功能;4-还尾款',
        //   'ContractAmount': '', // '合同金额',
        //   'ActuallyAmount': '', // '实付金额',
        //   'BalanceAmount': '', // '尾款',
        //   'AccessorySupport': '', // '合同附件',
        //   'AmendScript': '', // '修改条款',
        //   'PayCutSupport': '', // '付款截图',
        //   'ExpireDateTime': '', // '门店有效期',
        //   'RenewCount': '' // '续费年数'
        // },
        'ClearSaaSClien':
        {// 清数据申请
          'ClearScopeSupport': '', // '清理范围',
          'ClearScopeIDSName': '',
          'ClearSaaSClientKey': '', // '清除数据门店',
          'ClearSaaSClientKeyValue': '' // '清除数据门店'
        },
        'CashBack': null,
        'DredgeTime': ''
        // {// 返介绍费
        //   'CashSaaSClientKey': '', // '返现人门店',
        //   'CashSaaSClientKeyValue': '', // '返现人门店',
        //   'CashBackAmount': '', // '返现金额',
        //   'MobileSupport': '', // '返现人手机号',
        //   'CashBackKey': '', // '返现人',
        //   'CashBackKeyValue': '' // '返现人'
        // }
      },
      showAuthCode: false,
      business: 0,     // 是否清除业务
      cleanCustor: 0, // 是否清除客户
      cleanClassCount: 0, // 是否清除课次
      cleanInitialValue: 0 // 是否清除资料
    };
  },
  components: {
    authCode
  },
  props: {
    seletedItem: Object
  },
  created () {
    if (this.seletedItem && this.seletedItem.ClearAuditKey > 0) {
      console.log(this.seletedItem, 'this.seletedItem');
      this.getDetailInfo(this.seletedItem.ClearAuditKey);
    } else {
      this.initAddData();
    }
    console.log(this.dataInfo, 'dataInfo');
  },
  computed: {
    vIsEdit () {
      if (this.seletedItem && this.seletedItem.ClearAuditKey > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    initAddData () {
      console.log(this.$store.getters.token, 'initAddData');
      this.dataInfo.DredgeTime = this.seletedItem.DredgeTime;
      this.dataInfo.ApplicantKey = this.$store.getters.token.UserID;
      this.dataInfo.ApplicantKeyValue = this.$store.getters.token.UserName;
      this.dataInfo.support20 = this.seletedItem.support20; // '机构Key',
      this.dataInfo.SaaSClientNameKey = this.seletedItem.SaaSClientNameKey; // '机构Key',
      this.dataInfo.SaaSClientNameKeyValue = this.seletedItem.SaaSClientNameKeyValue; // '机构',
      this.dataInfo.MobileSupport = this.seletedItem.MobileSupport; // '手机号',
      this.dataInfo.ContactsName = this.seletedItem.ContactsName; // '联系人',
      this.dataInfo.ThreadKey = this.seletedItem.OLAPKey; // '线索key',
      this.dataInfo.ClearSaaSClien.ClearSaaSClientKey = this.seletedItem.SaaSClientNameKey;
      this.dataInfo.ClearSaaSClien.ClearSaaSClientKeyValue = this.seletedItem.SaaSClientNameKeyValue;
    },
    getDetailInfo (TableID) {
      GetApplyDetails(TableID).then(result => {
        console.log('GetApplyDetails', result.data);
        this.dataInfo = result.data;
        this.initClean();
      });
    },
    initClean () {
      this.business = this.dataInfo.ClearSaaSClien.ClearScopeIDSName.indexOf('1') != -1 ? 1 : 0;    // 是否清除业务
      this.cleanCustor = this.dataInfo.ClearSaaSClien.ClearScopeIDSName.indexOf('2') != -1 ? 2 : 0; // 是否清除客户
      this.cleanClassCount = this.dataInfo.ClearSaaSClien.ClearScopeIDSName.indexOf('3') != -1 ? 3 : 0; // 是否清除课次
      this.cleanInitialValue = this.dataInfo.ClearSaaSClien.ClearScopeIDSName.indexOf('4') != -1 ? 4 : 0; // 是否清除课次 // 是否清除资料
    },
    // 提交审核
    submitAudit (callback) {
      console.log('submit', this.dataInfo);
      this.showAuthCode = true;
      callback();
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.dataInfo);
      let isPass = true;
      if (this.dataInfo.ReasonScript == '') {
        layer.alert('请输入原因');
        isPass = false;
      }
      if (!isPass) {
        if (callback) {
          callback();
        }
        return false;
      }
      AddApply(this.dataInfo).then(result => {
        layer.alert('新增成功');
        this.$emit('afterSuccess');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        if (callback) {
          callback();
        }
        console.log('errEditDefaultReply', error);
      });
    },
    // 撤销
    revocation () {
      layer.confirm('请问是否要撤销该申请', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelApply(this.dataInfo.TableID).then(result => {
              this.$emit('afterSuccess');
              this.doAfterClickCancelBtn();
            }).catch(error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    closeShowAuthCode () {
      this.showAuthCode = false;
    },
    afterSuccess () {
      this.showAuthCode = false;
      this.$nextTick(() => {
        this.$emit('afterSuccess');
        this.$emit('close');
      });
    },
    seletedbusiness (val) {
      if (!val) {
        this.cleanCustor = 0;
        this.cleanClassCount = 0;
        this.cleanInitialValue = 0;
        this.dataInfo.ClearSaaSClien.ClearScopeIDSName = '';
        this.dataInfo.ClearSaaSClien.ClearScopeSupport = '';
      } else {
        this.dataInfo.ClearSaaSClien.ClearScopeIDSName += val + ',';
        this.dataInfo.ClearSaaSClien.ClearScopeSupport += '业务,';
      }
    },
    seletedcleanCustor (val) {
      if (!val) {
        this.cleanClassCount = 0;
        this.cleanInitialValue = 0;
      }
      this.seletedChange(val, '2', '客户');
    },
    seletedcleanClassCount (val) {
      if (!val) {
        this.cleanInitialValue = 0;
      }
      this.seletedChange(val, '3', '课次');
    },
    seletedInitialValue (val) {
      this.seletedChange(val, '4', '基础资料');
    },
    seletedChange (val, type, title) {
      console.log(val, type, 'seletedChange', this.dataInfo.ClearSaaSClien.ClearScopeIDSName, this.dataInfo.ClearSaaSClien.ClearScopeSupport);
      if (val > 0) {
        if (this.dataInfo.ClearSaaSClien.ClearScopeIDSName.indexOf(val) == -1) {
          this.dataInfo.ClearSaaSClien.ClearScopeIDSName += val + ',';
          this.dataInfo.ClearSaaSClien.ClearScopeSupport += title + ',';
        }
      } else {
        let locaIndex = this.dataInfo.ClearSaaSClien.ClearScopeIDSName.indexOf(type);
        let stringlocaIndex = this.dataInfo.ClearSaaSClien.ClearScopeSupport.indexOf(title);
        if (locaIndex != -1) {
          this.dataInfo.ClearSaaSClien.ClearScopeIDSName = this.dataInfo.ClearSaaSClien.ClearScopeIDSName.substr(0, locaIndex);// + this.dataInfo.ClearSaaSClien.ClearScopeIDSName.substr(locaIndex + 2);
          this.dataInfo.ClearSaaSClien.ClearScopeSupport = this.dataInfo.ClearSaaSClien.ClearScopeSupport.substr(0, stringlocaIndex); // + this.dataInfo.ClearSaaSClien.ClearScopeSupport.substr(stringlocaIndex + title.length + 1);
        }
      }
      console.log('ClearScopeIDSName', this.dataInfo.ClearSaaSClien.ClearScopeIDSName, 'seletedChange', this.dataInfo.ClearSaaSClien.ClearScopeSupport);
    }
  }
};
</script>
<style>
.from_list_select .el-checkbox {
  margin-right: 0px;
}
</style>

