<template>
  <div class="from_list"
       :class="required?'from_list_required':''">
    <div class="from_list_title"
         v-if="titleName">{{titleName}}</div>
    <div class="from_list_content">
      <div class="from_list_input from_list_input_small">
        <input type="number"
               :value="vInputValue"
               @input="inputEvent($event.target.value,$event)"
               @change="changeEvent($event.target.value,$event)"
               >
        <div class="from_list_unit"
             v-if="unitName">{{unitName}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      inputValue: '',
      defaultValue: 0, // 默认值,
    };
  },
  props: {
    titleName: {//  标题 （选填）
      type: String,
      default: ''
    },
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    decimalPlaces: {//  保留小数位 （选填）
      type: Number,
      default: 2
    },
    maxLength: {
      type: Number,
      default: 12
    },
    unitName: {//  单位 （选填）
      type: String,
      default: ''
    },
    required: {//  是否必填 （选填）
      type: Boolean,
      default: false
    }
  },
  created () {
    this.setInputValue(this.value);
  },
  watch: {
    value (c, o) {
      this.setInputValue(c);
    }
  },
  computed:{
    vInputValue: {
      get () {
        if (this.inputValue == '0' || this.inputValue) {
          return this.$utils.mMoneyType(this.inputValue, this.vDecimalPlaces);
        } else {
          return '';
        }
      },
      set (n, o) {
        this.inputValue = n;
      }
    },
     vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return 0;
      } else {
        return this.decimalPlaces || this.defaultDecimalPlaces;
      }
    }
  },
  methods: {
    setInputValue (v) {
      this.inputValue = this.$utils.mMoneyType(v, this.vDecimalPlaces);
      this.inputValue = this.inputValue || this.defaultValue;
    },
    inputEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputValue = val;
      this.$emit('inputChange', val);
      // e.target.value = this.inputValue;
      // this.$emit('input', this.inputValue);
      // if (!this.$utils.isIntermediateValue(this.inputValue)) {
        
      // }
    },
    changeEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      this.inputValue = this.$utils.mMoneyType(val, this.vDecimalPlaces);
      this.inputValue =
        this.inputValue > Number(this.max) ? Number(this.max) : this.inputValue;
      this.inputValue =
        this.inputValue < Number(this.min) ? Number(this.min) : this.inputValue;
      e.target.value = this.inputValue;

      this.$emit('change', Number(this.inputValue));
      this.$emit('input', Number(this.inputValue));
      this.$emit('inputChange', Number(this.inputValue));
      this.$emit('changeEvent', Number(this.inputValue));
    }
  }

};
</script>

<style>
</style>