<template>
  <div class="WKT_activity_my_list">
    <div class="list_img">
      <img :src="CDNUrl + dataInfo.pic" alt="" />
    </div>
    <div class="list_info flex_1">
      <div class="list_info_name">{{ dataInfo.active_title }}</div>
      <div class="list_info_text">
        <div class="list_info_date">
          <div class="list_info_title">活动截止日期：</div>
          <div class="list_info_font">{{ dataInfo.activity_time_end }}</div>
        </div>
        <div v-if="dataInfo.type == 4 || dataInfo.type == 2" class="list_info_date">
          <div class="list_info_title">活动售价：</div>
          <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.buy_amount) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，扣减 <span class="font_black">{{Number(dataInfo.di_xian_jin)}}</span>元）</div>
        </div>
        <div v-else-if="dataInfo.taocanid > 0" class="list_info_date">
          <div class="list_info_title">活动售价：</div>
          <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，到店 <span class="font_black">{{Number(dataInfo.you_hu_money) - Number(dataInfo.buy_amount)}}</span>元）</div>
        </div>
        <div v-else-if="dataInfo.activityType == 1" class="list_info_date">
          <div class="list_info_title">活动售价：</div>
          <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">0</span>元，到店 <span class="font_black">{{Number(dataInfo.you_hu_money)}}</span>元）</div>
        </div>
        <div v-else-if="dataInfo.activityType == 2" class="list_info_date">
          <div class="list_info_title">活动售价：</div>
          <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，到店 <span class="font_black">{{Number(dataInfo.you_hu_money) -Number(dataInfo.buy_amount)}}</span>元）</div>
        </div>
        <div v-else-if="dataInfo.activityType == 3" class="list_info_date">
          <div class="list_info_title">活动售价：</div>
          <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，到店 <span class="font_black">0</span>元）</div>
        </div>
        <div v-if="dataInfo.only_newuser == 1" class="last_box text_list">
          <div class="list_info_font">限新生参加</div>
        </div>
      </div>
      <div class="list_info_text">
        <div class="text_list">
          <div class="list_info_title">使用模板：</div>
          <el-tooltip popper-class="max_width" effect="dark" :content="dataInfo.SceneKeyValue + '|' + dataInfo.activityTitle" :disabled="disabledType1" placement="top">
            <div class="list_info_font" style="max-width:240px"><span ref="text_01">{{ dataInfo.SceneKeyValue }} | {{ dataInfo.activityTitle }}</span></div>
          </el-tooltip>
        </div>
        <div class="text_list">
          <div class="list_info_title">玩法类型：</div>
          <el-tooltip popper-class="max_width" effect="dark" :content="vActivityType + '|' + dataInfo.title" :disabled="disabledType2" placement="top">
            <div class="list_info_font" style="max-width:540px"><span ref="text_02">{{ vActivityType }} | {{ dataInfo.title }}</span></div>
          </el-tooltip>
        </div>
      </div>
      <!-- <div v-if="dataInfo.type == 4" class="list_info_amount">
        <div class="flex">
          <div class="list_info_title">抵扣金额：</div>
          <div class="font_yellow">{{Number(dataInfo.di_xian_jin)}}</div>
        </div>
      </div>
      <div v-else-if="dataInfo.type == 2" class="list_info_amount">
        <div class="flex">
          <div class="list_info_title">扣减金额：</div>
          <div class="font_yellow">{{Number(dataInfo.di_xian_jin)}}</div>
        </div>
      </div>
      <div v-else class="list_info_amount">
        <div class="flex">
          <div class="list_info_title">优惠价：</div>
          <div class="font_yellow">{{vAmountInfo}}</div>
        </div>
        <div class="flex">
          <div class="list_info_title">原价：</div>
          <div class="font_black">{{Number(dataInfo.packgePrice)}}</div>
        </div>
      </div> -->
      <div class="list_info_ul">
        <activity-list-block-first
          :dataInfo="dataInfo"
          @clickItem="openActivitiesStatisticsList"
        ></activity-list-block-first>
        <!-- <activity-list-block
          v-for="(item, index) in vInfoBlockList"
          :key="index"
          :dataInfo="item"
        ></activity-list-block> -->
      </div>
      <div class="list_info_right_box">
        <div class="flex">
          <div
            v-if="isActivityTimeEnd"
            class="WKT_btn_edit right_btn"
            @click.stop="toEdit"
          ></div>
          <div @click="checkActivity" class="right_btn_check right_btn"></div>
          <div
            v-if="isActivityTimeEnd"
            @click="disableActivity"
            class="right_btn_delete right_btn"
          ></div>
        </div>
        <div v-if="isActivityTimeEnd" class="right_type type_green">进行中</div>
        <div v-else class="right_type">已结束</div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from '../../../../js/bus.js';
import activityListBlock from './activity-list-block';
import activityListBlockFirst from './activity-list-block-first';
import {
  GetActivitiesPlayByID,
  TerminationActivity
} from '../../../../API/workbench.js';
export default {
  data () {
    return {
      disabledType1: true,
      disabledType2: true,
      infoList: [
        {
          name: '老师分销统计',
          dataInfo: {}
        },
        {
          name: '家长分销统计',
          dataInfo: {}
        },
        {
          name: '家长推广统计',
          dataInfo: {}
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    CDNUrl () {
      return this.$store.getters.CDNURL;
    },
    isActivityTimeEnd () {
      let dateTime = Date.new(this.dataInfo.activity_time_end);
      dateTime = dateTime.setDate(dateTime.getDate());
      dateTime = dateTime + (3600000 * 24 - 1);
      if (Date.new().getTime() > dateTime || this.dataInfo.status == 0) {
        return false;
      } else {
        return true;
      }
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vInfoBlockList () {
      this.infoList[0].dataInfo = this.dataInfo.AgentAward || {};
      this.infoList[1].dataInfo = this.dataInfo.DistributionAward || {};
      this.infoList[2].dataInfo = this.dataInfo.ExtendAward || {};
      return this.infoList;
    },
    vAmountInfo () {
      if (this.dataInfo.packgePrice == 0) {
        return 0;
      } else {
        return this.dataInfo.you_hu_money;
      }
    },
    vActivityType () {
      if (this.dataInfo.activityType == 1) {
        return '0元报名';
      } else if (this.dataInfo.activityType == 2) {
        return '线上付定金';
      } else if (this.dataInfo.activityType == 3) {
        return '线上全款';
      } else {
        return '';
      }
    }
  },
  components: {
    activityListBlock,
    activityListBlockFirst
  },
  mounted () {
    this.mountedFunction();
  },
  methods: {
    openActivitiesStatisticsList () {
       if(this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule,7)){
              if (this.$utils.CheckModulePower(this.vRolePowerList, 55)) {
                this.$router.push({
                  name: 'activityMonitoringPlatform',
                  query: {
                    couponID: this.dataInfo.id
                  }
                });
              } else {
                this.$utils.CheckModulePowerToTips(55);
              }

          }else{
            this.$utils.CheckOpenSettingToTips(7);
          }
     
      // this.$emit('openActivitiesStatisticsList',this.dataInfo)
    },
    getActivitiesPlay (id, callback) {
      GetActivitiesPlayByID(id).then(res => {
        res.data.posterTemplateID = 0;
        if (callback) {
          callback(res.data);
        }
        console.log(res, 'GetActivitiesPlayByID');
      }, err => {
        console.log(err.msg);
      });
    },
    toEdit () {
      console.log('toEdit', this.dataInfo);
      this.getActivitiesPlay(this.dataInfo.playID, (data) => {
        data.id = this.dataInfo.id;
        let moduleInfo = {
          name: this.dataInfo.title,
          moduleName: 'oldIshowEditorDialog',
          routerName: this.$route.name,
          data: { dataInfo: data, coverInfo: {} },
          callBack: { afterSuccess: () => {
            console.log('changeType');
            this.$emit('refreshActivityInfo', this.dataInfo);
          }
          }
        };
        bus.emit('openCustomDialog', moduleInfo);
      });
    },
    mountedFunction () {
      if (this.$refs.text_01.offsetWidth > 230) {
        this.disabledType1 = false;
      }
      if (this.$refs.text_02.offsetWidth > 530) {
        this.disabledType2 = false;
      }
    },
    checkActivity () {
      this.$emit('showActivityDetailsPopup', this.dataInfo);
    },
    disableActivity () {
      let that = this;
      layer.confirm('是否结束活动？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: function () {
              TerminationActivity(that.dataInfo.id).then(
                (res) => {
                  that.dataInfo.status = '0';
                },
                (err) => {
                  console.log(err.msg);
                }
              );
              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>