<template>
  <div>
    <!-- 总部版本 -->
    <!-- <div class="course_mandate_top">
      <div class="top_right_ul">
        <summarizing-single-item v-for="(item,key,index) in headSummaryData" :sumData="item" :key="index"
          @onCilckEvent="doAfterCilckSummaryData"></summarizing-single-item>
      </div>
    </div> -->
    <portDetails ref="portDetails"
                 :params="params"
                 @loadTableData="loadAfterTableData"></portDetails>
    <div style="width: 100%;background: white;">
       <div class="general_summarizing_student_navUl pr" style="background: white;margin: 0;">
        <div
          class="title_list"
          @click="tableSwitch(1)"
          :class="{ opt: tableType == 1 }"
        >
          在用端口
        </div>
        <div
          class="title_list"
          @click="tableSwitch(2)"
          :class="{ opt: tableType == 2 }"
        >
          耗尽端口
        </div>
        <div
          class="title_list font_blue" style="position:absolute;right:20px;font-size: 16px;"
          @click="renewClick(null,'bantch')"
        >
          批量续端口
        </div>
      </div>
    </div>
    <div class="table_list_content">
      <list @openIsShowPortApplyRecordListPopup="openIsShowPortApplyRecordListPopup"
            ref="campusManagement"
            class="summarizing_list"
            :tableData="vdataReportList"
            :tableColumns="tableColumns"
            :totalNum="totalNum"
            :rowKey="'Number'"
            :params="params"
            :defaultSort="defaultSort"
            @loadTableData="loadAfterTableData"></list>
    </div>
    <!-- @addCampus="addCampus" -->

    <custom-dialog :title="titleName+'直营校区'"
                   :visible.sync="isShowChainCampusFormPopup"
                   :before-close="closeChainCampusFormPopup">
      <retail-campus-edit-form :CampusKey="Number(campusEditIndo.OLAPKey)"
                               :isReadonly="titleName=='查看'?true:false"
                                :titleName="titleName"
                               @close="closeChainCampusFormPopup"
                               @afterSuccess="getSearchPageForCampus"></retail-campus-edit-form>
    </custom-dialog>

    <custom-dialog :title="titleName+'加盟校区'"
                   :visible.sync="isShowParticipateCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <franchisee-campus-edit-form :CampusKey="Number(campusEditIndo.OLAPKey)"
                                   :isShowInput="true"
                                    :titleName="titleName"
                                   :isReadonly="titleName=='查看'?true:false"
                                   @close="closeParticipateCampusFormPopup"
                                   @afterSuccess="getSearchPageForCampus"></franchisee-campus-edit-form>
    </custom-dialog>

    <custom-dialog :title="'续端口'"
                   :visible.sync="isShowCampusRenewalForm"
                   :before-close="closeCampusRenewalFormPopup">
      <renewal-form :campusInfo="campusEditIndo"
                    :schoolReadonly="schoolReadonly"
                    @close="closeCampusRenewalFormPopup"
                    @afterSuccess="getSearchPageForCampus"></renewal-form>
    </custom-dialog>

    <custom-dialog :title="'流量设置'"
                   :visible.sync="isShowFlowSetUP"
                   :before-close="closeFlowSetUPFormPopup">
      <flowset-form :campusInfo="campusEditIndo"
                    @close="closeFlowSetUPFormPopup"
                    @afterSuccess="getSearchPageForCampus"></flowset-form>
    </custom-dialog>

    <custom-dialog :title="'端口使用记录'"
                   width="1200px"
                   :visible.sync="isShowPortApplyRecordListPopup"
                   :before-close="closePortApplyRecordListPopup">
      <port-apply-record-list></port-apply-record-list>
    </custom-dialog>

    <div>
      <dialog-factory ref="campusManagementDialogFactory"
                      :routerName="'campusManagement'"></dialog-factory>
    </div>

  </div>
</template>

<script>
import {
  GetSystemPortConsumeOptionList,
  CampusManagementDisable,
  CampusManagementEnable
} from '../../../../API/workbench.js';
import retailCampusEditForm from '../retail-management/form/retail-campus-edit-form';
import franchiseeCampusEditForm from '../franchisee-management/form/franchisee-campus-edit-form';
import flowsetForm from './form/flowset-form';
import renewalForm from './form/new-renewal-form';
import portDetails from './PortDetails.vue';
import portApplyRecordList from './port-apply-record-list/index';
import summarizingSingleItem from '../common/summarizing-single-item';
import list from './table';
import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'campusManagement',
  components: {
    summarizingSingleItem,
    list,
    portDetails,
    retailCampusEditForm,
    franchiseeCampusEditForm,
    renewalForm,
    portApplyRecordList,
    dialogFactory,
    flowsetForm
  },
  data () {
    return {
      getDate: new Date(),
      isShowChainCampusFormPopup: false,
      isShowParticipateCampusFormPopup: false,
      isShowCampusRenewalForm: false,
      isShowPortApplyRecordListPopup: false,
      isShowFlowSetUP: false, // 是否显示流量设置
      tableType:1,
      schoolReadonly:false,
      // isShowUsageRecordListPopup: false,
      // 校区管理
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母  姓名、姓名首字母、客户手机号
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序顺序
        // IsActiveKey: '', //	整型	可选		数据源：GET	是否活跃校区
        // IsStopKey: '', //	整型	可选		数据源：GET	是否停用
        // IsExpiredKey: '', //	整型	可选		数据源：GET	是否已过期
        // IsExpired30Key: '', //	整型	可选		数据源：GET	是否30天内过期
        portTypeKey: '', // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
        campusTypeKey: '', // 整型	可选		数据源：GET	校区类型: 1-直营店; 2-加盟店;3-课程合作
        IsExpired30Key: ''
      },
      campusmdList: [], // 校区列表
      tableColumns: [{
        label: '序号',
        prop: 'Number',
        width: 50,
        sortable: false,
   
        align: 'left',
        type: 'tableIndex',
        className: 'table_index_gray'
      },
      {
        label: '首次启用日期',
        width: 120,
        prop: 'OpenTime',
        type: 'date-item',
   
        sortable: 'custom'
      },
      {
        label: '截至日期',
        width: 120,
        prop: 'ExpiredTime',
        type: 'date-item',
      
        sortable: 'custom'
      },
      {
        label: '已用/总共',
        width: 140,
        prop: 'UsageDayContent',
        type: 'text-item',
     
        sortable: false
      },
      {
        label: '剩余',
        width: 100,
        prop: 'RemainingDay',
        type: 'text-item',
   
        sortable: false
      },
      {
        label: '端口类型',
        prop: 'portTypeKeyValue',
        width: 120,
        align: 'left',
   
        type: 'text-item',
        sortable: false 
      },
      {
        label: '使用校区',
        width: 200,
        prop: 'MainDemoName',
        type: 'md-name',
        sortable: false,

        extend: {
          mendianID: 'SaaSClientNO',
          click: (rowData) => {
            this.viewClick(rowData);
          }
        }
      },
      {
        label: '校区类型',
        prop: 'TypeKeyValue',
        width: 95,
        align: 'left',
        type: 'text-item',
        sortable: false
      },
      {
        label: '端口状态',
        width: 120,
        prop: 'StatusTypeKeyValue',
        type: 'text-item',
        sortable: false
      },
      {
        label: '操作',
        prop: 'option',
        minWidth: 100,
        type: 'table-btn',
        align: 'center',
        // fixed: 'right',
        extend: {
          tableBtnGroup: [
            {
            name: '修改',
            extend: {
              isHide: (rowData) => {
                if (rowData.StatusTypeKey !=1) {
                  return true;
                } else if (rowData.StatusKey == 0) {
                  return true;
                } else {
                  return rowData.TypeKey == 3;
                }
              },
              click: (rowData) => {
                this.editClick(rowData);
              },
              ModulePowerKey: ''
            }
          },
          {
            name: '停用',
            extend: {
              isHide: (rowData) => {
                // if(rowData.StatusKey==0){
                //   return true
                // } else{

                return this.tableType==2;
                // }
              },
              // IsInquiryHint: 'inquiryBtn',
              // setClassName: (rowData) => {
              //   if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
              //     return rowData.inquiryBtn;
              //   }
              // },
              click: (rowData, callBack) => {
                // if (callBack) {
                //   callBack({
                //     IsInquiryHint: 'inquiryBtn',
                //     InquiryClassName: 'table_btn_gray'
                //   });
                // }
                this.switchClick(rowData, callBack);
              },
              ModulePowerKey: ''
            }
          },
          {
            name: '续端口',
            extend: {
              click: (rowData) => {
                this.renewClick(rowData);
              },
              ModulePowerKey: ''
            }
          }
          ]
        },
      }
      ],
      
      totalNum: 0,
      defaultSort: {
        prop: 'studentInfo',
        order: 'ascending'
      },
      campusEditIndo: {},
      titleName: '添加'
    };
  },
  computed:{
    vdataReportList () {
      let newArr = [];
      this.campusmdList.forEach(o => {
        o.UsageDayContent=Number(o.UsageDay)+'/'+Number(o.TotalDay)+'天'
        o.RemainingDay=Number(o.RemainingDay)==0?'-':Number(Number(o.RemainingDay).toFixed(2))+'天'
        newArr.push(o);
      });
      console.log("newArr",newArr)
      return newArr;
    },
  },
  created () {
    this.registerBusEvent();
  },
  activated () {
    this.getSearchPageForCampus();
  },
  methods: {
    tableSwitch(type){
      this.tableType=type
       this.params.pageIndex = 0;
        this.params.orderBy = '';
        this.params.searchText = '';
        // this.params.IsActiveKey = ''; //	整型	可选		数据源：GET	是否活跃校区
        // this.params.IsStopKey = ''; //	整型	可选		数据源：GET	是否停用
        // this.params.IsExpiredKey = ''; //	整型	可选		数据源：GET	是否已过期
        this.params.IsExpired30Key = ''; //	整型	可选		数据源：GET	是否30天内过期
        this.params.portTypeKey = ''; // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
        this.params.campusTypeKey = ''; // 整型	可选		数据源：GET	校区类型: 1-直营店; 2-加盟店;3-课程合作
       this.getSearchPageForCampus();
    },
    // 校区管理-分页查询
    getSearchPageForCampus () {
      console.log(this.params, '提交接口的数据');
      GetSystemPortConsumeOptionList(this.params,this.tableType).then(result => {
        console.log(result, '分页查询系统端口列表');
        this.campusmdList = [];
        this.totalNum = result.data.Total;
        this.campusmdList = result.data.PageDataList;
        this.$refs.portDetails.getGetPortStatistics();
        let newDate = [''];
        this.campusmdList.forEach(o => {
          if (o.AddressAlterTime) {
            newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(o.AddressAlterTime);
          }
          o.AddressAlterTimeText = newDate[0];
          newDate = [''];
          if (o.TypeKey == 3) {
            o.TypeKeyValue = '课程合作';
          }
          if (o.CurrentFluxObjectKey == 1 && o.NextFluxObjectKey != 2) {
            o.CurrentFluxObjectKeyValue = '总部负担';
          } else if (o.CurrentFluxObjectKey == 1 && o.NextFluxObjectKey == 2) {
            if ((this.getDate.getMonth() + 2) == 13) {
              o.CurrentFluxObjectKeyValue = '总部负担,1月1号起门店负担';
            } else {
              o.CurrentFluxObjectKeyValue = '总部负担,' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
            }
          } else if (o.CurrentFluxObjectKey == 2 && o.NextFluxObjectKey != 1) {
            o.CurrentFluxObjectKeyValue = '门店负担';
          } else if (o.CurrentFluxObjectKey == 2 && o.NextFluxObjectKey == 1) {
            if ((this.getDate.getMonth() + 2) == 13) {
              o.CurrentFluxObjectKeyValue = '门店负担,1月1号起总部负担';
            } else {
              o.CurrentFluxObjectKeyValue = '门店负担,' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
            }
          } else if (o.CurrentFluxObjectKey == 0 && o.NextFluxObjectKey == 1) {
            if ((this.getDate.getMonth() + 2) == 13) {
              o.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起总部负担';
            } else {
              o.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
            }
          } else if (o.CurrentFluxObjectKey == 0 && o.NextFluxObjectKey == 2) {
            if ((this.getDate.getMonth() + 2) == 13) {
              o.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起门店负担';
            } else {
              o.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
            }
          } else {
            o.CurrentFluxObjectKeyValue = '未设置';
          }
        });
        // console.log(result, '校区管理-分页查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 汇总排序
    doAfterCilckSummaryData (item) {
      console.log('点击汇总数据排序', item);
      if (item.type == 'CurrentPortalNum') {
        this.isShowPortApplyRecordListPopup = true;
      } else {
        this.params.searchText = '';
        this.params.pageIndex = 0;
        this.params[item.type] = 1;
        this.getSearchPageForCampus();
      }
    },
    // 条件查询
    loadAfterTableData (queryParams) {
      console.log(queryParams, '条件查询');
      // queryParams.orderBy = (queryParams.orderBy == 'SysStatusKeyValue' ? 'SysStatusKey' : queryParams.orderBy);
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      this.getSearchPageForCampus();
    },
    // // 新增
    // addCampus(type) {
    //   this.titleName = '添加';
    //   this.campusEditIndo = {};
    //   if (type == 'ZY') { // 添加直营校区
    //     // this.isShowChainCampusFormPopup = true;
    //   } else { // 添加加盟校区
    //     // this.isShowParticipateCampusFormPopup = true;
    //   }
    // },
    // 修改
    editClick (rowData) {
      this.titleName = '修改';
      this.campusEditIndo = rowData;
      console.log(rowData, '校区管理-修改');

      if (rowData.TypeKey == 2) { // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
       this.isShowParticipateCampusFormPopup = true;
      } else {
         this.isShowChainCampusFormPopup = true;
      }
    },
    // 查看
    viewClick (rowData) {
      this.titleName = '查看';
      this.campusEditIndo = rowData;
      console.log(rowData, '校区管理-修改');
      console.log(this.campusEditIndo.TypeKey, 'this.campusEditIndo');
      if (rowData.TypeKey ==2) { // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
        this.isShowParticipateCampusFormPopup = true;
      } else {
        this.isShowChainCampusFormPopup = true;
      }
    },
    // 流量设置
    flowSetClick (rowData) {
      this.titleName = '查看';
      this.campusEditIndo = rowData;
      console.log(rowData, '校区管理-修改');
      console.log(this.campusEditIndo.TypeKey, 'this.campusEditIndo');
      this.isShowFlowSetUP = true;
    },
    // 续费
    renewClick (rowData,type) {
      console.log(rowData, '续费');
      if(type){
          this.campusEditIndo = {
            OLAPKey: 0,  // 校区ID
            MainDemoName: '',
            IsOpenChannelKey: 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: 1, // "是否开通教务  0-否;1-是",
            ExpireTime: ''   // 到期日
          };
          this.schoolReadonly=false
          this.isShowCampusRenewalForm = true;
      }else{

        let NowTime = new Date();
        let PortExpiredTime = new Date(rowData.PortExpiredTime);
        // 续费教务时，如门店教务还在有效期内
        if ((rowData.portTypeKey == 1 && rowData.RenewOwnKey != 1) && (PortExpiredTime > NowTime && rowData.SysStatusKey != 1)) {
          layer.confirm('该门店教务功能自费，不可进行该操作，如有问题请与专属客服联系', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                }
              }
            ]
          });
        } else {
          this.campusEditIndo = {
            OLAPKey: rowData.OLAPKey,  // 校区ID
            MainDemoName: rowData.MainDemoName,
            IsOpenChannelKey: rowData.portTypeKey == 2 ? 1 : 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: rowData.portTypeKey == 1 ? 1 : 0, // "是否开通教务  0-否;1-是",
            ExpireTime: rowData.ExpiredTime   // 到期日
          };
          this.schoolReadonly=true
          this.isShowCampusRenewalForm = true;
        }
      }
    },
    // 停用/启用
    switchClick (rowData, callBack) {
      let name = rowData.StatusKey == 0 ? '启用' : '停用';
      console.log(rowData, 'rowData');
      // 教务 门店自费时
      if (rowData.portTypeKey == 1 && rowData.RenewOwnKey != 1 && name == '停用') {
        layer.confirm('该门店教务功能自费，不可进行该操作，如有问题请与专属客服联系', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
              }
            }
          ]
        });
      } else {
        layer.confirm('请确定是否' + name + '?', {
          btn: [{
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({
                  IsInquiryHint: 'inquiryBtn',
                  InquiryClassName: ''
                });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (rowData.StatusKey == 0) {
                CampusManagementEnable(rowData.OLAPKey, rowData.portTypeKey).then(result => {
                  this.getSearchPageForCampus();
                  if (callBack) {
                    callBack({
                      IsInquiryHint: 'inquiryBtn',
                      InquiryClassName: ''
                    });
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              } else {
                CampusManagementDisable(rowData.OLAPKey, rowData.portTypeKey).then(result => {
                  this.getSearchPageForCampus();
                  if (callBack) {
                    callBack({
                      IsInquiryHint: 'inquiryBtn',
                      InquiryClassName: ''
                    });
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              }
              layer.close();
            }
          }
          ]
        });
      }
    },
    // 打开
    openIsShowPortApplyRecordListPopup () {
      this.isShowPortApplyRecordListPopup = true;
    },
    // 关闭
    closeChainCampusFormPopup () {
      this.isShowChainCampusFormPopup = false;
    },
    // 关闭
    closeParticipateCampusFormPopup () {
      this.isShowParticipateCampusFormPopup = false;
    },
    // 关闭
    closeCampusRenewalFormPopup () {
      this.isShowCampusRenewalForm = false;
    },
    // 关闭流量设置
    closeFlowSetUPFormPopup () {
      this.isShowFlowSetUP = false;
    },
    // 关闭
    closePortApplyRecordListPopup () {
      this.isShowPortApplyRecordListPopup = false;
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'campusManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.campusManagementDialogFactory) {
        this.$refs.campusManagementDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo
          .data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  },
};
</script>
