<template>
  <div class="loop_couser_info_list"
       :class="{opt:courseInfo.isActive}"
       @click.stop="clickOneRuleItem($event)">
    <div class="list_date">
      <span>{{vStartTime}}</span>
      <div class="list_date_icon"></div>
    </div>
    <div class="list_btn"></div>
    <div class="list_text_box">
      <div class="list_title">{{courseInfo.StartTime}} 至
        <span v-if="IsNotEndKeyAndLast">不结束</span>
        <span v-else>{{courseInfo.EndTime}}
          <span class="font_number">{{$utils.mAmountType(courseInfo.ClassCount,0)}}</span>节</span>
      </div>
      <div class="list_text">
        <div class="list_text_l">
          <span class="font_gray">老师:</span>
          <span>{{courseInfo.MTeacherKeyValue}}</span>
        </div>
        <div class="list_text_r">
          <span class="font_gray">教室:</span>
          <span>{{courseInfo.classRoomName}}</span>
        </div>
      </div>
      <div class="list_text">
        <div class="list_text_l">
          <span class="font_gray">课消:</span>
          <span>{{$utils.mAmountType(courseInfo.PeriodCount,1)}}</span>
        </div>
        <div class="list_text_r">
          <span class="font_gray">计划满员数:</span>
          <span>{{$utils.mAmountType(courseInfo.FullPeopleCount,0)}}</span>
        </div>
      </div>
      <div class="list_text">
        <div class="list_text_l"
             v-if="vIsShowChargeLeave">
          <span class="font_gray">请假扣课时:</span>
          <span>{{vLeaveText}}</span>
        </div>
        <div class="list_text_r"
             v-if="vIsShowChargeTruant">
          <span class="font_gray">旷课扣课时:</span>
          <span>{{vChargesText}}</span>
        </div>
      </div>
      <div class="list_text">
        <div class="list_text_r">
          <span class="font_gray">亏损预警人数:</span>
          <span>{{courseInfo.WarnLossCount?$utils.mAmountType(courseInfo.WarnLossCount,0):0}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseInfo: {
      type: Object,
      required: true
    },
    IsNotEndKeyAndLast: Boolean
  },
  computed: {
    vStartTime () {
      let startTime = Date.new(this.courseInfo.StartTime);
      let monthName = startTime.getMonth() + 1;
      let dayName = startTime.getDate();
      return (monthName < 10 ? '0' + monthName : monthName) + '-' + (dayName < 10 ? '0' + dayName : dayName);
    },
    vLeaveText () {
      if (this.courseInfo.IsleaveKey == 0) {
        return '否';
      } else {
        return '是';
      }
    },
    vChargesText () {
      if (this.courseInfo.IsChargesKey == 0) {
        return '否';
      } else {
        return '是';
      }
    },
    // 2不扣课时 3扣课时 4建课设置
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0;
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    }
  },
  created () {
    console.log(this.courseInfo, 'courseInfo');
  },
  methods: {
    clickOneRuleItem (e) {
      this.$emit('clickOneRuleItem', this.courseInfo, e);
    }
  }
};
</script>

<style>
</style>