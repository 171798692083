<template>
  <div class="popup_dialog"
       v-if="visible">
    <!-- 弹出框 -->
    <div class="popup_dialog_box"
         :style="popupDialogBoxStyle">
      <!-- 内容 -->
      <div class="popup_dialog_box_content clearfix">
        <slot></slot>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  data () {
    return {
      popupDialogBoxStyle: null
    };
  },
  props: {
    title: String, // 标题
    dialogParamList: Array, // 打开弹窗所带的参数
    curModuleName: { // 当前所打开弹窗的ModuleName
      type: String,
      default: ''
    },
    showPosition: { // 弹窗位置（偏上 居中 偏下）
      type: String,
      default: 'top'
    },
    visible: {// 显示隐藏
      type: Boolean,
      default: false
    },
    beforeClose: Function, // 关闭后的回调
    width: {
      type: String,
      default: '410px'
    },
    appendToBody: {// 弹窗添加到Body
      type: Boolean,
      default: false
    },
    moduleDescription: {
      type: String,
      default: ''
    },
    isOpenFactoryDialogCommon: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.visible) {
      this.initPopupDialogBoxStyle();
    }
  },
  mounted () {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
  watch: {
    visible (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
        this.$nextTick(() => {
          this.appendDialogToElement();
        });
      } else {
        this.hide();
      }
    },
    width (n, o) {
      if (n != o && this.popupDialogBoxStyle) {
        this.popupDialogBoxStyle.width = n;
      }
    }
  },
  methods: {
    initPopupDialogBoxStyle () {
      let top = 0;
      this.$store.commit('setCurOpenDialogName', this.title);
      this.$nextTick(() => {
        this.popupDialogBoxStyle = {};
        let dialogHeight = this.$el.querySelector('.popup_dialog_box').offsetHeight;
        if (this.showPosition == 'visibleMiddle') {
          top = document.documentElement.scrollTop + document.documentElement.clientHeight / 2 - dialogHeight / 2;
        } else if (this.showPosition == 'top') {
          top = document.documentElement.scrollTop + Number(this.top) + 70;
        } else if (this.showPosition == 'bottom') {
        } else if (this.showPosition == 'clickNearby') {
        }
        if (this.upTop == 0) {
          this.popupDialogBoxStyle.top = parseInt(top) + 'px';
        } else {
          this.popupDialogBoxStyle.top = parseInt(top) - Number(this.upTop) + 'px';
        }
      });
    },
    // 把弹窗添加到指定元素上
    appendDialogToElement () {
      let curRouterElement = document.getElementById(this.$route.name);
      if (curRouterElement.getElementsByClassName('custom-dialog-factory')[0]) {
        curRouterElement.getElementsByClassName('custom-dialog-factory')[0].appendChild(this.$el);
      }
    },
    hide () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (shadeDiv) {
        // 关闭时 把zIndex设置为零 下次打开重计算
        if (this.popupDialogBoxStyle) {
          this.popupDialogBoxStyle.zIndex = 0;
        }
      }
    },

    destroyed () {
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
};
</script>
<style scoped>
.popup_dialog_box{
  width: 100%;
  height: 100%;
  background-color: #f0f8ff;
  z-index: 9999;
  left: 0;
  bottom: 0;
  top: 0;
  transform: translate(0,0);
  /* bug-13716 */
  position: fixed; 
}
</style>