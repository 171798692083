<template>
  <div class="class_statistics_echarts_attendance massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="statistics_echarts_left_title">{{formTitle}}</div>
      <div class="statistics_echarts_left_btn">
        <div class="statistics_echarts_btn_switchover fl">
          <div class="fl"
               :class="{opt:isNow}"
               @click.stop="setTime(0)">本月</div>
          <div class="fl"
               :class="{opt:isThreeMonth}"
               @click.stop="setTime(1)">近3月</div>
          <div class="fl"
               :class="{opt:isYear}"
               @click.stop="setTime(2)">本年</div>
          <div class="fl"
               :class="{opt:isAuto}">自定义</div>
        </div>
        <div class="statistics_echarts_btn_date fr">
          <span>日期范围 :</span>
          <div class="date_scope">
            <el-date-picker v-model="startTime"
                            prefix-icon="none"
                            clear-icon="none"
                            type="month"
                            @change="chgStartTime"
                            placeholder="必选"></el-date-picker>
            <span>~</span>
            <el-date-picker v-model="endTime"
                            prefix-icon="none"
                            clear-icon="none"
                            type="month"
                            @change="chgEndTime"
                            placeholder="必选"></el-date-picker>
          </div>
        </div>
      </div>
      <div class="statistics_echarts_left_btn_content">
        <div :id="vId"
             class="echarts_l"></div>
      </div>
    </div>
    <classStatistics :lastInfo="statisticsLastInfo"
                     :statisticsList="statisticsList"
                     :type="type"></classStatistics>
  </div>
</template>
<script>
import classStatistics from './class-statistics';
import {
  getSummarizeAttendanceForGrade,
  getSummarizeClassCountForGrade
} from '../../API/workbench';
export default {
  name: 'statisticalChart',
  data () {
    return {
      // 当前月份第一天.
      startTime: Date.new(Date.new().setDate(1)).toLocaleDateString(),
      // 当前月份最后一天.
      // endTime: Date.new().getFullYear() + '/' + (Date.new().getMonth() + 1) + '/' + Date.new(Date.new().getFullYear(), Date.new().getMonth() + 1, 0).getDate(),
      endTime: this.setChoseEndTime(Date.new()),
      statisticsLastInfo: {},
      statisticsList: [],
      isNow: true, // 绑定样式显示
      isThreeMonth: false, // 绑定样式显示
      isYear: false, // 绑定样式显示
      isAuto: false // 绑定样式显示
    };
  },
  props: {
    type: String,
    formTitle: String,
    echartID: String
  },
  components: {
    classStatistics
  },
  computed: {
    // 绑定ID.
    vId () {
      return this.echartID;
    }
    // 显示统计的类目
  },
  mounted () {
    this.initGetData(this.initEChart);
  },
  methods: {
    // 初始化.
    initEChart () {
      // 出勤率排名图表                                //this.vId是计算属性
      var attendanceChart = echarts.init(document.getElementById(this.vId));

      // 指定图表的配置项和数据
      var attendanceOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: '30',
          left: '4%',
          right: '4%',
          bottom: '15%',
          containLabel: true
        },
        xAxis: {
          data: this.getDataToFoce(),
          axisLabel: {
            textStyle: {
              color: '#c0c4cc'
            },
            rotate: -45
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          z: 10
        },
        yAxis: {
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#f0f0f2']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['#f7f7f7', '#fff']
            }
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            // For shadow
            type: 'bar',
            itemStyle: {
              normal: {
                color: 'rgba(255,255,255,0.05)'
              }
            },
            barGap: '-100%',
            barCategoryGap: '40%'
            // data: [250]
          },
          {
            type: 'bar',
            itemStyle: {
              normal: {
                barBorderWidth: 1,
                barBorderRadius: [4, 4, 4, 4],
                color: 'rgba(73,181,243,0.6)',
                barBorderColor: 'rgba(73,181,243,1)',
                abel: {
                  show: true,
                  textStyle: {
                    color: '#27727B'
                  }
                }
              }
            },
            data: this.getDataToCount()
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      attendanceChart.setOption(attendanceOption);
    },
    // 绑定数据中的名字
    getDataToFoce () {
      var newArr = [];
      this.statisticsList.forEach(o => {
        newArr.push(o.MainDemoName);
      });
      return newArr;
    },
    getDataToCount () {
      var newArr = [];
      this.statisticsList.forEach(o => {
        if (this.type == 'attenType') {
          // 判断调用组件的是否考勤.
          newArr.push(parseInt(o.AttendanceRate));
        } else {
          newArr.push(Number(o.ClassCount));
        }
      });
      return newArr;
    },
    // 初始化获取数据并视callback是否存在触发后续事件.
    initGetData (callback) {
      if (this.type == 'attenType') {
        this.getAttenStatisticalList(this.startTime, this.endTime, callback);
      } else {
        this.getClassCountStatisicalList(
          this.startTime,
          this.endTime,
          callback
        );
      }
    },
    // 给startTime绑定事件
    chgStartTime () {
      this.startTime = this.startTime.toLocaleDateString();
      this.isNow = false;
      this.isThreeMonth = false;
      this.isYear = false;
      this.isAuto = true;
      if (Date.new(this.startTime) < Date.new(this.endTime)) {
        this.$emit('chgdate', this.startTime, this.endTime);
        this.initGetData(this.initEChart);
      } else {
        layer.alert('开始时间不能大于结束时间');
      }
    },
    // 给EndTime绑定事件
    chgEndTime () {
      this.endTime = this.setChoseEndTime(this.endTime);
      this.isNow = false;
      this.isThreeMonth = false;
      this.isYear = false;
      this.isAuto = true;
      console.log(this.endTime);
      if (Date.new(this.startTime) < Date.new(this.endTime)) {
        this.initGetData(this.initEChart);
      } else {
        layer.alert('开始时间不能大于结束时间');
      }
    },
    // 设置时间.
    setTime (type) {
      switch (type) {
        case 0: // 本月
          this.isNow = true;
          this.isThreeMonth = false;
          this.isYear = false;
          this.isAuto = false;
          this.startTime = Date.new(Date.new().setDate(1)).toLocaleDateString();
          this.endTime = this.setChoseEndTime(Date.new());
          this.initGetData(this.initEChart);
          break;
        case 1: // 近3月
          this.isNow = false;
          this.isThreeMonth = true;
          this.isYear = false;
          this.isAuto = false;
          let Year = Date.new().getFullYear();
          let month = Date.new().getMonth() + 1;
          this.startTime = Date.new(
            Date.new(Year, month - 2, -1).setDate(1)
          ).toLocaleDateString(); // 获取两个月前的第一天.
          console.log(this.startTime);
          this.endTime = this.setChoseEndTime(Date.new());
          this.initGetData(this.initEChart);
          break;
        case 2: // 本年
          this.isNow = false;
          this.isThreeMonth = false;
          this.isYear = true;
          this.isAuto = false;
          this.startTime = Date.new().getFullYear() + '/1/1';
          this.endTime = this.setChoseEndTime(Date.new());
          this.initGetData(this.initEChart);
          break;
        default:
          break;
      }
    },
    // 封装获取选择的月份 及最后一天.timer 为时间类型.
    setChoseEndTime (timer) {
      let Year = timer.getFullYear();
      let Month = timer.getMonth() + 1;
      let EndDate = Date.new(Year, Month, 0).getDate();
      return Year + '/' + Month + '/' + EndDate;
    },
    // 获取考勤图片统计
    getAttenStatisticalList (startTime, endTime, callback) {
      this.statisticsList.length = 0;
      getSummarizeAttendanceForGrade(startTime, endTime).then(result => {
        console.log(result.data, 'getAttenStatisticalList');
        this.statisticsLastInfo = result.data.TheLastGrade;
        this.statisticsList = result.data.Top15GradeList || [];
        if (callback) {
          callback();
        }
      });
    },
    getClassCountStatisicalList (startTime, endTime, callback) {
      this.statisticsList.length = 0;
      getSummarizeClassCountForGrade(startTime, endTime).then(result => {
        console.log(result.data, 'getClassCountStatisicalList');
        this.statisticsLastInfo = result.data.TheLastGrade;
        this.statisticsList = result.data.Top15GradeList || [];
        if (callback) {
          callback();
        }
      });
    }
  }
};
</script>
