<template>

  <div class="form_info_detail">
    <!-- <div class="defult_dialog_header"
          v-if="formTitle">
        <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
              @click.stop="returnEvent"></span>
        <span class="defult_dialog_header_font">{{formTitle}}</span>
      </div> -->
    <div class="class_common_list_search"><input type="text"
             v-model="searchText">
      <!-- <div class="btn_hover_bg_blue search_btn ">搜索</div> -->
    </div>
    <div class="search_result btn_marginBottom_30 single_shadow content_marginRL content_marginTop_30 overflow_hidden">
      <div class="search_result_content search_result_content_for_student">
        <div class="search_result_list">
          <div class="apply_course_details_list button"
               :style="!hideBorder?'border-bottom: 1px solid #ececec;':''"
               v-for="(tickItem,key) in vDataList"
               :key="key"
               @click="chgTickItem(tickItem)">
            <div class="list_left">
              <div class="list_left_title clearfix"
                   v-if="Number(tickItem.TaoCanKey)>0">
                <div class="list_left_title_icon fl">{{Number(tickItem.TaoCanKey)>0?'套餐':'非套餐'}}</div>
                <div class="fl">{{vTaoCanKeyValue(tickItem)}}</div>
              </div>
              <div class="list_left_range"
                   v-if="Number(tickItem.TaoCanKey)>0">
                <span class="font_black">适用:</span>
                {{tickItem.ApplyCorName}}
              </div>
              <div class="list_left_title clearfix"
                   v-else>
                <div class="fl">{{tickItem.ApplyCorName}}</div>
              </div>
              <div class="list_left_course">
                <span class="font_black">{{vGenerateTime(tickItem)}}</span> 购买 <span class="font_black">{{Number(tickItem.NumCount)}}</span> 课时 | 消耗<span class="font_black"> {{Number(tickItem.expendCount)||0}} </span>课时
                <!-- 剩余 <span class="font_black">{{Number(tickItem.PeriodCount)}}</span> 天 <span class="font_black">{{Number(tickItem.CurrentCount)}}</span> 课时 -->
              </div>
              <div class="list_left_course">
                可用<span class="font_black"> {{Number(tickItem.CurrentCount)}}</span> 课时 | 当前报课<span class="font_blue"> {{$utils.saveDecimal(Number(tickItem.HasBingCount))}} </span>课时
              </div>
            </div>
            <div class="list_right"
                 v-if="Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)>0">
              <div class="font_block">待报课时</div>
              <div class="font_red font_big">{{Number(tickItem.CurrentCount)>Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)?Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged):Number(tickItem.CurrentCount)}}</div>

            </div>
            <div class="list_right"
                 v-else-if="Number(tickItem.Sum_SuperQuote)>0">
              <div class="font_block">超报课</div>
              <div class="font_red font_big">{{Number(tickItem.Sum_SuperQuote)}}</div>
            </div>
            <div class="list_right"
                 v-else>
              <div class="font_block">待报课时</div>
              <div class="font_green font_big">0</div>

            </div>
            <!-- <div v-if="!hideBorder">
                <div class="list_right" v-if="Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)>0">
                  <div class="font_red font_big">{{Number(tickItem.CurrentCount)>Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged)?Number(tickItem.WaitForPlanCount||tickItem.ToBeArranged):Number(tickItem.CurrentCount)}}</div>
                  <div class="">待报课</div>
                </div>
                <div class="list_right" v-else-if="Number(tickItem.Sum_SuperQuote)>0">
                  <div class="font_red font_big">{{Number(tickItem.Sum_SuperQuote)}}</div>
                  <div class="font_red">未报上课</div>
                </div>
                <div class="list_right"  v-else>
                  <div class="font_green_icon"></div>
                  <div class="font_green">正常</div>
                </div>
              </div>
              <div class="list_right" v-else>
                  <div class="font_black font_big">{{Number(tickItem.HasBingCount)}}</div>
                  <div class="">报课</div>
              </div> -->
          </div>
          <div v-if="vDataList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 绿色类名 no_warning -->
    <!-- <div class="warning_text_box" v-if="Number(tickItem.CurrentCount)>0">
        未报课签到消耗{{Number(tickItem.CurrentCount)}}课时
      </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      searchText: ''
    };
  },
  props: ['formTitle', 'dataList', 'hideBorder'],
  computed: {
    vDataList () {
      let newArr = [];
      console.log('searchText', this.searchText);
      this.dataList.forEach(o => {
        o.TaoCanKeyValue = o.TaoCanKeyValue || '';
        o.ApplyCorName = o.ApplyCorName || '';
        // console.log(o.TaoCanKeyValue, o.ApplyCorName, 'o.TaoCanKeyValue');
        if (this.searchText == '') {
          newArr.push(o);
        } else {
          if (o.TaoCanKeyValue.indexOf(this.searchText) != -1 || o.ApplyCorName.indexOf(this.searchText) != -1) {
            newArr.push(o);
          }
        }
      });

      return newArr;
    }
  },
  methods: {
    chgTickItem (tickItem) {
      this.$emit('chgTickItem', tickItem);
    },
    // 自定义返回或取消事件
    returnEvent () {             // 不跳转路由的返回.switchItem为任意值.
      this.$emit('return');
    },
    vGenerateTime (tickItem) {
      if (tickItem && tickItem.GenerateTime) {
        return tickItem.GenerateTime.substring(0, 10);
        // let GenerateTime = tickItem.GenerateTime.substring(0, 10);
        // return (Date.new(GenerateTime).getMonth() + 1) + '月' + Date.new(GenerateTime).getDate() + '日';
      }
    },
    vTaoCanKeyValue (tickItem) {
      if (tickItem && Number(tickItem.TaoCanKey) > 0) {
        return tickItem.TaoCanKeyValue;
      } else {
        return '';
      }
    }

  }
};
</script>