<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">课消金额统计月报</div>
        <single-year-picker @chgDate="chgDate"></single-year-picker>
        <div class="title_month fr">
          <span class="this_month">本年</span>
          <span class="past_month">上一年</span>
        </div>
      </div>
      <div>
        <doubleTypeEchart :echartID="'dual_histogram4'"
                          :reqList="monthList"
                          :nowDataList="nowList"
                          :preDataList="oldList"
                          :echartType="'category'"
                          ref="echart"></doubleTypeEchart>
      </div>
      <div>
        <a @click.stop="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <rateItem :formTitle="'课消金额'"
              :secondTitle="'签到人次'"
              :nowDataList="nowDataList"
              :oldDataList="oldDataList"
              :seletedDate="seletedDate"
              :type="'year'"></rateItem>
  </div>
</template>
<script>
import singleYearPicker from '../date-picker-common/single-year-picker';
import doubleTypeEchart from '../echart-common/double-type-echart';
import rateItem from './rate-item';
import { getClassCountUsedAmountReportByMonth } from '../../../API/workbench';
export default {
  name: 'classAmonutDayReport',
  data () {
    return {
      seletedDate: Date.new(),
      startTime: null,
      endTime: null,
      monthList: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      nowList: [],    // 选中月数据显示的内容
      oldList: [],    // 上个月数据显示的内容
      nowDataList: [],     // 选中当月数据源
      oldDataList: []      // 选中的上个月数据源
    };
  },
  components: {
    singleYearPicker, doubleTypeEchart, rateItem
  },
  mounted () {
    this.startTime = Date.new().getFullYear() + '/01/01';
    this.endTime = Date.new().getFullYear() + '/12/31';
    this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    // this.initGetMonthData(this.getLastKDSYDataByMonth);
  },
  methods: {
    showDetail () {
      this.$emit('showDetail', '月报', this.startTime, this.endTime);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.startTime = startTime;
      this.endTime = endTime;
      console.log(this.monthList, '最长', startTime, endTime);
      this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    },
    // 获取天数数组
    getDaysOfMonth (monthDate) {
      monthDate = monthDate || Date.new(Date.new().setDate(1));
      let dayList = [];
      let selectedMonth = Date.new(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
      let dayMany = selectedMonth.getDate();
      for (let i = 1; i <= dayMany; i++) {
        dayList.push(i);
      }
      return dayList;
    },
    // 获取选中月数据.
    getNowKQDataByDay (startTime, endTime, callback) {
      getClassCountUsedAmountReportByMonth(startTime, endTime).then(result => {
        this.nowDataList = result.data.DataList;
        console.log('getClassCountSpendReportByDay', this.nowDataList);
        this.nowList = this.getNowList();
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取选中月的上个月数据.
    getLastKQDataByDay () {
      let year = Date.new(this.startTime).getFullYear();    // 纯年
      let startTime = (year - 1) + '/01/01';
      let endTime = (year - 1) + '/12/31';
      getClassCountUsedAmountReportByMonth(startTime, endTime).then(result => {
        this.oldDataList = result.data.DataList;
        this.oldList = this.getOldList(Date.new(this.seletedDate));
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }, r => {
        console.log(r);
      });
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      // let monthList = this.getDaysOfMonth(this.seletedDate);
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.totalDeductAmount).toFixed(2));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    },
    // 获取上一年数据   因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getOldList (timeDate) {
      let oldList = [];
      this.monthList.forEach(p => {
        let item = this.oldDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          oldList.push(Number(item.totalDeductAmount).toFixed(2));
        } else {
          oldList.push('0');
        }
      });
      return oldList;
    }

  }
};
</script>

