<template>
  <div>
    <div class="report_margin_list">
      <list ref="inventoryDetail"
            :tableData="inventoryDetailList"
            :paramsInfo="paramsInfo"
            :tableColumns="tableColumns"
            :totalNum="totalNum"
            :rowKey="'Number'"
            :defaultSort="defaultSort"
            @chgSearchValue="searchGoodsData"
            @doExportInfoClick="doExportInfoClick"
            @doBtnClick="doBtnClick"></list>
    </div>
    <!-- 出入库表单 -->
    <custom-dialog :title="vTitle"
                   :visible.sync="isShowGoodsForm"
                   :before-close="closeDialog">
      <goods-leave-or-enter-form :TypeKey="settingBtnInfo.TypeKey"
                                 @cancelClick="closeDialog"
                                 @afterSuccessCallBack="searchGoodsData"></goods-leave-or-enter-form>
    </custom-dialog>
    <!-- 物品设置模块 -->
    <custom-dialog title="物品设置"
                   :width="'972px'"
                   :visible.sync="isShowGoodsSetting"
                   :before-close="closeDialog">
      <goods-setting @afterSuccessCallBack="searchGoodsData"></goods-setting>
    </custom-dialog>
    <!-- 物品调整表单-->
    <custom-dialog title="库存调整"
                   :visible.sync="isShowInventoryAdjustForm"
                   :before-close="closeDialog">
      <inventory-adjust-form :dataInfo="selectedInfo"
                             @cancelClick="closeDialog"
                             @afterSuccessCallBack="searchGoodsData"></inventory-adjust-form>
    </custom-dialog>
  </div>

</template>
<script>
import {
  MessageSearchInventory
} from '../../../../API/workbench.js';

import list from './detail-table';
import goodsLeaveOrEnterForm from './goods-leave-or-enter-form';
import goodsSetting from './goods-setting/index';
import inventoryAdjustForm from './inventory-adjust-form';
export default {
  data () {
    return {
      isShowGoodsForm: false, // 出入库表单
      isShowGoodsSetting: false, // 物品设置模块
      isShowInventoryAdjustForm: false, // 物品调整表单
      settingBtnInfo: {}, // 出入库类型
      selectedInfo: {}, // 明细数据
      // 库存明细查询
      paramsInfo: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'OLAPKey', //	字符串	可选		数据源：GET	排序字段
        sequence: 'desc', //	字符串	可选		数据源：GET	排序顺序
        GoodsTypeKey: '', // 物品类型key
        GoodsTypeKeyValue: '', // 物品类型key
        InStock: 1, // 库存是否大于0 1-是;0-否
        IsExportKey: 0 // 是否导出：0-否;1-是;如果为是pageIndex传0
      },
      inventoryDetailList: [], // 物品明细列表
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'table-index',
          className: 'table_index_gray',
          width: 68,
          sortable: false
        },
        {
          label: '物品类型',
          prop: 'GoodsTypeKeyValue',
          type: 'text-item',
          sortable: 'custom',
          align: 'left'
        },
        {
          label: '物品名称',
          prop: 'MainDemoName',
          type: 'text-item',
          sortable: 'custom',
          align: 'left'
        },
        {
          label: '当前库存',
          prop: 'CurrentInventoryCount',
          type: 'amount-item',
          align: 'left',
          sortable: 'custom'
        },
        {
          label: '平均进货单价',
          prop: 'AvgUnitPriceAmount',
          type: 'amount-item',
          align: 'left',
          sortable: 'custom',
          decimalPlace: 2
        },
        {
          label: '库存总价值',
          prop: 'TotalValueAmount',
          type: 'amount-item',
          align: 'left',
          sortable: 'custom',
          decimalPlace: 2
        },
        {
          label: '销售指导价',
          prop: 'SalesPriceAmount',
          type: 'text-item',
          sortable: 'custom',
          align: 'left'
        },
        {
          label: '出入库记录',
          prop: 'LogCount',
          type: 'amount-item',
          align: 'left',
          sortable: 'custom',
          extend: {
            click: (rowData) => {
              this.$emit('showInventoryRecord', { name: '出入库记录', key: 'KCJL', opt: true }, rowData);
            }, // 出入库记录
            className: 'class_hour_can_click font_border_blue'
          }
        },
        {
          label: '库存调整',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '调整',
                extend: {
                  click: (rowData) => { this.doAfterTableBtnClick(rowData); },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      PageCount: 0,
      totalNum: 0,
      // summary: {
      //   needSummariesColumns: [],
      //   isShowSummary: false,
      //   summaryName: '合计',
      //   summaryData: []
      // },
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list,
    goodsLeaveOrEnterForm,
    goodsSetting,
    inventoryAdjustForm
  },
  computed: {
    vTitle () {
      if (this.settingBtnInfo.nameValue == 'JHRK') {
        return '进货入库';
      } else {
        return '物品出库';
      }
    }
  },
  created () {
    this.getMessageSearchInventory();
  },
  watch: {

  },
  mounted () {

  },
  methods: {
    // 获取库存明细
    getMessageSearchInventory () {
      MessageSearchInventory(this.paramsInfo).then(result => {
        console.log(result.data, '获取库存明细');
        this.totalNum = result.data.Total;
        this.inventoryDetailList = [];
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.SalesPriceAmount = o.SalesPriceAmount != 0 ? Number(o.SalesPriceAmount) : '-';
          });
          this.inventoryDetailList = result.data.PageDataList;
        }
      }).finally(() => {
        if (this.$refs.inventoryDetail) {
          this.$refs.inventoryDetail.isLoading = false;
        }
      });
    },
    // 导出库存明细
    doExportInfoClick () {
      if (this.inventoryDetailList.length > 0) {
        let obj = this.$utils.parseJson(this.paramsInfo);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '库存明细表',
          clist: [
            { title: '物品类型', cName: 'GoodsTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '物品名称', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '当前库存', cName: 'CurrentInventoryCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '平均进货单价', cName: 'AvgUnitPriceAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '库存总价值', cName: 'TotalValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '销售指导价', cName: 'SalesPriceAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出入库记录', cName: 'LogCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInventory(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    },
    // （出库、入库、设置）表单显示
    doBtnClick (item) {
      console.log(item, '（出库、入库、设置）表单显示');
      if (item.nameValue == 'WPSZ') { // 物品设置
        this.isShowGoodsSetting = true;
      } else { // 进货出入库
        this.settingBtnInfo = item;
        this.isShowGoodsForm = true;
      }
    },
    //  关闭弹窗
    closeDialog () {
      this.isShowGoodsForm = false;
      this.isShowGoodsSetting = false;
      this.isShowInventoryAdjustForm = false;
    },
    // 物品设置成功后的刷新
    searchGoodsData (params) {
      console.log(params, '选择搜索类型');
      if (params) {
        this.paramsInfo = params;
      }
      this.getMessageSearchInventory();
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
      this.selectedInfo = rowData;
      this.isShowInventoryAdjustForm = true;
    }

  }
};
</script>

