<template>
  <!-- 已处理 -->
  <div class="repair_course_view">
    <div class="repair_wait_info">
      <div class=""
           style="display: flex;     padding: 20px;">
        <div class="course_statistics_title">
          <div class="table_select_box">
            <div class="table_select_title">缺勤日期</div>
            <div class="title_date_select">
              <div class="el-select">

                <single-year-picker @chgDate="changeSaveDate"
                                    :defaultDate="StartDate"
                                    ref="monthPicker"
                                    :minDate="vMinDate"
                                    :maxDate="$utils.formatDateToLine(Date.new())"
                                    :isShowRepairCourse="true"
                                    :type="'month'"></single-year-picker>
              </div>
            </div>
          </div>
        </div>

        <input-search-contain-btn v-model="queryParams.searchText"
                                  :placeholder="'搜索学生、老师'"
                                  @onSearchEvent="searchTextSearch"
                                  @clearSearch="clearSearchCondition"></input-search-contain-btn>
      </div>
      <div class="credit_management_table">
        <table-list class="summarizing_list table_list_content"
                    :tableData="dataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :queryParams="queryParams"
                    :footerContent="vFooterTotal"
                    @loadTableData="getMessageSearchPageMakeUpCourseStudensProcessingRecord"></table-list>
      </div>
    </div>
    <custom-dialog title="补课处理方案"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRepairCourseProgramme"
                   :before-close="closeRepairCourseProgrammePopup">
      <repair-programme-setting :formTitle="'补课处理方案'"
                                :repairProgrammObj="repairProgrammObj"
                                @afterSuccess="closeRepairCourseProgrammePopup">
      </repair-programme-setting>
    </custom-dialog>
    <custom-dialog title="学生补课处理详情"
                   width="1200px"
                   :visible.sync="isShowDetail"
                   :before-close="closeDetail">
      <repair-student-detail :studentobj="dataDetail"></repair-student-detail>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageMakeUpCourseStudensProcessingRecord, RevokeDispose } from '../../../../API/workbench.js';
import tableList from '../../../common/table-list/index';
import repairProgrammeSetting from '../repair-programme-setting';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import repairStudentDetail from '../repair-student-detail/index.vue';
export default {
  data () {
    return {
      // 补课详情
      isShowDetail: false,
      dataDetail: {
        key: '',
        name: '',
        tel: '',
        img: '',
        course: '',
        StartTime: '',
        EndTime: ''
      },
      StartDate: '',
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'), //	整型	必须
        EndTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)), //	整型	必须
        ClassTypeKey: '', // 课程ID
        MakeupResultKey: '2' // 补课处理结果 1-待处理 2-已处理 不传是全部
      },
      dataReportList: [],
      isShowRepairCourseProgramme: false, // 补课处理方案
      repairProgrammObj: {}, // 补课处理方案
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '缺勤日期',
          prop: 'absenteeismTime',
          width: 100,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '学生名',
          prop: 'StudentNameKeyValue',
          width: 110,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openDetail(rowData);
            }
          }
        },
        {
          label: '周几',
          prop: 'WeekKeyValue',
          width: 80,
          sortable: false,
          align: 'center',
          type: 'textItem'
        },
        {
          label: '时间',
          prop: 'ClassTimeName',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          width: 110,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '考勤',
          prop: 'AttenceStatusKeyV',
          width: 70,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '耗课时',
          prop: 'PeriodCount',
          width: 70,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '补课处理方案',
          prop: 'handleProgrammeValue',
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.goRepairProgramme(rowData);
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 120,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '撤销处理',
                extend: {
                  click: (rowData) => { this.revokeRepairCourseEvent(rowData); }
                }
              }

            ]
          }
        }
      ]
    };
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = myDate.getMonth() + 2;
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr, this.$utils.formatDateToLine(Date.new()));
      return dateStr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  components: {
    tableList,
    inputSearchContainBtn,
    singleYearPicker,
    repairProgrammeSetting,
    repairStudentDetail
  },
  created () {
    this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
  },
  methods: {
    revokeRepairCourseEvent (rowData) {
      let ScheduleCourseKey = '';
      let MakeupCourseKey = '';
      if (rowData.DealTypeKey == 5) {
        MakeupCourseKey = rowData.ScheduleCourseKey;
      } else {
        ScheduleCourseKey = rowData.ScheduleCourseKey;
      }
      layer.confirm('确定撤销处理吗?', {
        btn: [{
          name: '确认',
          callBack: () => {
            layer.close();
            RevokeDispose(rowData.StudentNameKey, ScheduleCourseKey, MakeupCourseKey, '').then(result => {
              this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
              layer.alert('撤销成功');
            }, error => {
              layer.alert(error.msg);
            });
          }
        }]
      });
    },
    // 学生补课详情
    openDetail (obj, StartTime, EndTime) {
      this.dataDetail.key = obj.StudentNameKey;
      this.dataDetail.name = obj.StudentNameKeyValue;
      this.dataDetail.tel = obj.CustomerPhoneName;
      this.dataDetail.img = obj.HeadImgSupport;
      this.dataDetail.course = obj.CourseNameKey;
      this.dataDetail.start = this.queryParams.StartTime;
      this.dataDetail.end = this.queryParams.EndTime;
      this.isShowDetail = true;
      console.log(this.dataDetail, 'this.dataDetail');
    },
    closeDetail () {
      this.isShowDetail = false;
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    // 关闭补课处理方案
    closeRepairCourseProgrammePopup () {
      this.isShowRepairCourseProgramme = false;
    },
    // 补课处理方案
    goRepairProgramme (rowData) {
      this.repairProgrammObj = rowData;
      console.log('goRepairProgramme this.repairProgrammObj', this.repairProgrammObj);
      if (rowData.handleProgrammeValue != '-') {
        this.isShowRepairCourseProgramme = true;
      }
    },
    // 选择时间
    changeSaveDate (dataInfo, startTime, endTime) {
      this.queryParams.StartTime = startTime;
      this.queryParams.EndTime = endTime;
      this.queryParams.pageIndex = 0;
      console.log(dataInfo, startTime, endTime, 'dataInfo, startTime, endTime', this.searchData);
      // this.searchTextSearch(this.searchData[this.timeIndex].searchText, this.timeIndex, startTime);
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    searchTextSearch (val) {
      console.log(val, 'val', this.queryParams);
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    clearSearchCondition () {
      this.queryParams = {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        StartTime: this.queryParams.StartTime, //	整型	必须
        EndTime: this.queryParams.EndTime, //	整型	必须
        ClassTypeKey: '', // 课程ID
        MakeupResultKey: '2' // 补课处理结果 1-待处理 2-已处理 不传是全部
      };
      // this.StartDate = '';
      // this.$refs.monthPicker.cleanTime();
      this.getMessageSearchPageMakeUpCourseStudensProcessingRecord();
    },
    getMessageSearchPageMakeUpCourseStudensProcessingRecord () {
      MessageSearchPageMakeUpCourseStudensProcessingRecord(this.queryParams).then(result => {
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        result.data.PageDataList.forEach(o => {
          o.PeriodCount = Number(o.PeriodCount) ? Number(o.PeriodCount) : '0';
          o.AttenceStatusKeyV = Number(o.AttenceStatusKey) == 5 ? "<span class='attencea_red'>" + o.AttenceStatusKeyValue + '</span>'
            : Number(o.AttenceStatusKey) == 4 ? "<span class='attencea_yellow'>" + o.AttenceStatusKeyValue + '</span>' : o.AttenceStatusKeyValue;

          // 补课处理方案    不处理   已补课  退还课时   补扣课时
          if (o.DealTypeKey == '0') {
            // 待处理
            o.handleProgrammeValue = '-';
          } else if (o.DealTypeKey == '1') {
            // 不处理
            o.handleProgrammeValue = '不补课';
          } else if (o.DealTypeKey == '2') {
            // 补课
            if (o.MakeupAttenceStatusKey == '2') {
              // 已排课
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : '<span>') + '已排课</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            } else if (o.MakeupAttenceStatusKey == '3') {
              // 已补课
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span class='attencea_green'> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : "<span class='attencea_green'>") + '已补课</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            } else if (o.MakeupAttenceStatusKey == '4') {
              // 已请假
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span class='attencea_yellow'> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : "<span class='attencea_yellow'>") + '请假</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            } else if (o.MakeupAttenceStatusKey == '5') {
              // 已旷课
              o.handleProgrammeValue = (o.MakeUpCount > 1 ? "<span class='attencea_red'> <span class='round_num'>" + o.MakeUpCount + '</span>&nbsp;&nbsp;' : "<span class='attencea_red'>") + '旷课</span>&nbsp;&nbsp;|&nbsp;&nbsp;' +
                o.MakeUpClassTime + '&nbsp;&nbsp;' + o.MakeUpWeekKeyValue + '&nbsp;&nbsp;' + o.MakeUpClassTimeName + '&nbsp;&nbsp;' + o.MakeupGradeClassKeyValue + '&nbsp;&nbsp;' + o.MakeupTeacherKeyValue;
            }
          } else if (o.DealTypeKey == '3') {
            // 退还课时
            o.handleProgrammeValue = '<span>退还课时</span>&nbsp;&nbsp;|&nbsp;&nbsp;' + Number(o.ClassHourCount) + '课时&nbsp;&nbsp; → &nbsp;&nbsp;' + (Number(o.ClassHourCount) - Number(o.MakeupClassHourCount)) + '课时';
          } else if (o.DealTypeKey == '4') {
            // 补扣课时
            o.handleProgrammeValue = '<span>补扣课时</span>&nbsp;&nbsp;|&nbsp;&nbsp;' + Number(o.ClassHourCount) + '课时&nbsp;&nbsp; → &nbsp;&nbsp;' + Number(o.MakeupClassHourCount) + '课时';
          }
        });
        console.log(result.data, '获取课类课名列表 MessageSearchPageMakeUpCourseStudensProcessingRecord');
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>
<style scoped>
.repair_course_view {
  width: 100%;
  background: #fff;
}
.table_select_box .el-select >>> .el-input__inner {
  border: none !important;
  width: 200px;
}
.title_date_select {
  width: 200px !important;
}
.course_statistics_title {
  padding: 0 !important;
  margin-right: 40px;
}
.course_search_div .mendian_backstage_search {
  margin-top: 20px;
  margin-left: 40px;
}
.summarizing_list >>> .el-table .attencea_green {
  color: rgb(115, 174, 72);
}
.summarizing_list >>> .el-table .attencea_red {
  color: rgb(255, 0, 0);
}
.summarizing_list >>> .el-table .attencea_yellow {
  color: rgb(237, 125, 49);
}
.summarizing_list >>> .el-table .round_num {
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
}
</style>