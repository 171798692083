<template>
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add','select_type':vIsEdit, text_error:vApplyClassKeyValueError}"
       style="width:159px;"
       @click.stop="clickCourseName">
    <div v-if="!isShowSelection&&rowType!='add'"
         class="paddingRight">{{rowData.ApplyClassKeyValue}}</div>
    <div v-if="isShowSelection||rowType=='add'">
      <el-select filterable
                 v-model="rowData.ApplyClassKeyValue"
                 :placeholder="rowType=='add'?'在读课程':''"
                 ref="applyClassKeyValue"
                 :title='rowData.ApplyClassKeyValue&&rowData.ApplyClassKeyValue.length>9?rowData.ApplyClassKeyValue:""'
                 style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;height"
                 @keydown.native="keydownEvent($event)"
                 @blur="blurEvent($event)"
                 @change="changeEvent"
                 @focus="focusEvent($event)"
                 @clear="clearEvent">
        <el-option v-for="item in vEnableCourseNameList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item.OLAPKey">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isFocus: false, // 聚焦效果
      isShowSelection: false, // 选择显示元素
      OLAPKey: this.rowType == 'add' ? '' : this.rowData.ApplyClassKeyValue
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object,
    scrollTopVal: Number
  },
  computed: {
    vCourseNameList () {
      return this.$store.getters.courseNameList || [];
    },
    vEnableCourseNameList () {
      let enableCourseNameList = [];
      if (this.vCourseNameList.length > 0) {
        enableCourseNameList = this.vCourseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
      }
      return enableCourseNameList;
    },
    vApplyClassKeyValueError () {
      let flag = false;
      let findItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 4; });
      if (findItem) {
        flag = true;
      }
      return flag;
    },

    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    rowData: {
      handler (n, o) {
        if (n.focusField == 'ApplyClassKeyValue' && n.optioningRow) {
          this.rowData.focusField = 'ApplyClassKeyValue';
          this.isFocus = true;
          if (!this.isShowSelection) { // 点击触发
            this.isShowSelection = true;
          }
          this.$nextTick(() => {
            if (this.$refs.applyClassKeyValue) {
              this.$refs.applyClassKeyValue.focus();
            }
          });
        }
      },
      deep: true // 表示开启深度监听
    },
    scrollTopVal: {
      handler (n, o) {
        if (n > 0) {
          this.isShowSelection = false;
          this.isFocus = false;
          // this.$refs.applyClassKeyValue.blur();
        }
      },
      immediate: true
    },
    vCourseNameList (n, o) {
      console.log('watch，vCourseNameList', this.CourseNameList);
     // 课程名称有变更，变更的时候会先清空，在重新获取
      if (this.rowData.guid && this.rowData.ApplyClassKeyValue) {
        this.checkCourNameStatus();
      }
    }

  },
  created () {
    this.loadCourseNameList();
  },
  mounted () {
    if (this.rowData.guid && this.rowData.ApplyClassKeyValue) {
      this.checkCourNameStatus();
    }
  },
  methods: {
    clearEvent () {
      this.rowData.ApplyClassKey = 0;
      this.rowData.ApplyClassKeyValue = '';
      console.log('clearEvent', this.rowData.ApplyClassKeyValue);
    },
    // 获取课名列表
    loadCourseNameList () {
      this.$basicData.loadCourseNameList();
    },
    // 验证课程状态（由其他人停用或新增）
    checkCourNameStatus () {
      // 异常TargetField：4-》 1：在读课程不存在，2：在读课程已停用，3：在读课程未选择
      let existCourseData = this.vCourseNameList.find(obj => { return obj.MainDemoName == this.rowData.ApplyClassKeyValue && obj.TypeKey == 2; });
      if (existCourseData) {
        this.rowData.ApplyClassKey = existCourseData.OLAPKey;
        // 1：在读课程不存在
        let courseUnExistErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 1; });
        if (courseUnExistErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(courseUnExistErrorIndex, 1);
          this.rowData.updateTime = Date.new();
        }
        // 2：在读课程已停用
        let stopCourseNameErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 2; });
        if (existCourseData.StateKey != 2 && stopCourseNameErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 2, TypeKeyValue: '在读课程已停用' });
          this.rowData.updateTime = Date.new();
        } else if (existCourseData.StateKey == 2 && stopCourseNameErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(stopCourseNameErrorIndex, 1);
          this.rowData.updateTime = Date.new();
        }
      } else {
        let stopCourseNameErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 2; });
        if (stopCourseNameErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(stopCourseNameErrorIndex, 1);
          this.rowData.updateTime = Date.new();
        }
        // 1：在读课程不存在
        let courseUnExistErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 1; });
        if (this.rowData.ApplyClassKeyValue && courseUnExistErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 1, TypeKeyValue: '在读课程不存在' });
          this.rowData.updateTime = Date.new();
        }
      }
    },
    clickCourseName () {
      if (this.vIsEdit) {
        this.isShowSelection = true;
        this.$nextTick(() => {
          this.rowData.focusField = 'ApplyClassKeyValue';
          this.rowData.optioningRow = true;
        });
      }
    },
    // 鼠标聚焦
    focusEvent (e) {
      if (e) {
        this.isFocus = true;
        this.rowData.optioningRow = true;
        this.rowData.focusField = 'ApplyClassKeyValue';
        this.$emit('onFocusEvent', 'ApplyClassKeyValue');
      }
      setTimeout(() => {
        let elScrollbarElms = document.getElementsByClassName('el-scrollbar');
        if (elScrollbarElms.length > 0) {
          let item = this.vCourseNameList.find(obj => { return obj.MainDemoName.indexOf(e.target.value) >= 0; });
          if (!item) {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'none';
            }
          } else {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'block';
            }
          }
        }
      }, 50);
    },
    // 键盘事件
    keydownEvent (e) {
      switch (e.keyCode) {
        case 9: // Tab
        case 13: // 回车
          this.rowData.focusField = 'PayTime';
          break;
        case 37: // 左键
          if (!e.target.value) {
            this.rowData.ApplyClassKeyValue = e.target.value;
            this.rowData.ApplyClassKey = '';
          }
          this.rowData.focusField = 'CustomerPhoneName';
          break;
        case 39: // 右键
          if (!e.target.value) {
            this.rowData.ApplyClassKeyValue = e.target.value;
            this.rowData.ApplyClassKey = '';
          }
          this.rowData.focusField = 'PayTime';
          break;
        default:
          break;
      }
      setTimeout(() => {
        let elScrollbarElms = document.getElementsByClassName('el-scrollbar');
        if (elScrollbarElms.length > 0) {
          let item = this.vCourseNameList.find(obj => { return obj.MainDemoName.indexOf(e.target.value) >= 0; });
          if (!item) {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'none';
            }
          } else {
            for (let i = 0; i < elScrollbarElms.length; i++) {
              const elementItem = elScrollbarElms[i];
              elementItem.style.display = 'block';
            }
          }
        }
      }, 50);
    },
    blurEvent (e) {
      if (!e.target.value) {
        this.rowData.ApplyClassKeyValue = e.target.value;
        this.rowData.ApplyClassKey = '';
        this.OLAPKey = '';
      }
      this.rowData.updateTime = Date.new();
      this.verificationApplyClassKeyValue();
      setTimeout(() => {
        this.isFocus = false;
        this.isShowSelection = false;
        if (this.rowType == 'add') {
          this.$refs.applyClassKeyValue.blur();
        }
      }, 100);
      this.rowData.focusField = '';
      this.$emit('onBlurEvent', this.rowData, 'ApplyClassKeyValue');
    },
    changeEvent (id) {
      this.isFocus = false;
      let item = this.vCourseNameList.find(obj => { return obj.OLAPKey == id; });
      this.changeCourseItem = item;
      if (!item.OLAPKey) {
        this.rowData.ApplyClassKeyValue = '';
      } else {
        this.rowData.ApplyClassKeyValue = item.MainDemoName;
      }
      this.rowData.ApplyClassKey = item.OLAPKey || 0;
      this.rowData.updateTime = Date.new();
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'PayTime';
      this.verificationApplyClassKeyValue();
      this.$emit('onDropdownBlurEvent', this.rowData, 'ApplyClassKeyValue');
      this.isShowSelection = false;
      console.log('changeEvent');
    },
    // 异常TargetField：4-》 1：在读课程不存在，2：在读课程已停用，3：在读课程未选择
    verificationApplyClassKeyValue () {
      let courseUnExistErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 1; });
      let stopCourseNameErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 2; });
      let nonSelectedCourseNameErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
      let existCourse = this.vCourseNameList.find(obj => { return obj.MainDemoName == this.rowData.ApplyClassKeyValue; });
      if (this.rowData.ApplyClassKeyValue) {
        // 1：在读课程不存在
        if (!existCourse) {
          if (courseUnExistErrorIndex < 0 && stopCourseNameErrorIndex < 0) {
            this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 1, TypeKeyValue: '在读课程不存在' });
          }
        } else {
          if (courseUnExistErrorIndex >= 0) {
            this.rowData.ApplyClassKey = existCourse.OLAPKey;
            this.rowData.ExceptionList.splice(courseUnExistErrorIndex, 1);
          }
        }
        if (nonSelectedCourseNameErrorIndex >= 0) { // 在读课程未选择
          this.rowData.ExceptionList.splice(nonSelectedCourseNameErrorIndex, 1);
        }
        if (stopCourseNameErrorIndex >= 0 && existCourse.StateKey == 2) { // 在读课程已停用
          this.rowData.ExceptionList.splice(stopCourseNameErrorIndex, 1);
        }
      } else {
        if (courseUnExistErrorIndex >= 0) {
          this.rowData.ApplyClassKey = 0;
          this.rowData.ExceptionList.splice(courseUnExistErrorIndex, 1);
        }
        // 在读课程未选择
        if (!this.rowData.ApplyClassKeyValue) {
          let unSelectedErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
          if ((this.rowData.PayTime || this.rowData.ExpireTime || this.rowData.ActualPayAmount || this.rowData.NumCount || this.rowData.CurrentCount) && unSelectedErrorIndex < 0) {
            this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 3, TypeKeyValue: '在读课程未选择' });
          } else {
            if (!this.rowData.PayTime && !this.rowData.ExpireTime && !this.rowData.ActualPayAmount && !this.rowData.NumCount && !this.rowData.CurrentCount && unSelectedErrorIndex >= 0) {
              this.rowData.ExceptionList.splice(unSelectedErrorIndex, 1);
            }
          }
          let emptyCurrentCountErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 5; });
          if (emptyCurrentCountErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(emptyCurrentCountErrorIndex, 1);
          }
        }
      }
    }
  }
};
</script>

<style>
</style>