<template>
  <!-- 职务 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'职务名称'"
                  :maxLength="14"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>

      <input-select :formTitle="'所属部门'"
                    :id="'id'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="departmentList"
                    :selectedInfo="departmentListInfo"
                    @selectedItem="chgDepartment"></input-select>

    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonly"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>

<script>
import {
  hqMessageSearchPageForDepartment,
  hqAddStation,
  hqEditStation
} from '../../../../API/workbench.js';
export default {
  data () {
    return {
      departmentList: [], // 所属部门列表
      departmentListInfo: {}, // 所属部门信息
      isload: false, // 加载数据完成
      // 提交数据对象
      dataJson: {
        OLAPKey: '',
        MainDemoName: '', // -- 职务名称
        FatherKey: 0, // --所属部门id
        FatherKeyValue: '全部', // -- 所属部门
        RemarksSupport: '' // --备注
      },
      params: {
        pageIndex: 0, // 每页记录数，0：表示查询所有
        pageSize: 0, // 页码，0为第一页
        searchText: '', //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
        orderBy: '', // 排序字段
        sequence: '' // 排序字段
      }
    };
  },
  props: {
    dataInfo: Object, // 职务信息
    isReadonly: Boolean// 是否只读
  },
  created () {
    this.dataJson.FatherKey = this.dataInfo.FatherKey;
    this.dataJson.FatherKeyValue = this.dataInfo.FatherKeyValue;
    if (this.dataInfo.OLAPKey) {
      Object.assign(this.dataJson, this.dataInfo);
    }
    this.departmentListInfo.id = this.dataJson.FatherKey;
    this.departmentListInfo.name = this.dataJson.FatherKeyValue;
    this.getInquirePort(this.parameter);
    console.log(this.dataInfo, '表单信息2');
  },
  computed: {

  },
  methods: {
    // 返回该门店所有部门
    getInquirePort () {
      hqMessageSearchPageForDepartment(this.params).then(res => {
        console.log(res.data.PageDataList, '所有部门');
        this.departmentList = res.data.PageDataList.filter(o => {
          o.id = o.OLAPKey;
          o.name = o.MainDemoName;
          return Number(o.StatusKey) === 1;
        });
        this.isload = true;
      });
    },
    // 选择所属部门
    chgDepartment (item) {
      console.log(item, '选择所属部门');
      this.dataJson.FatherKey = item.id;
      this.dataJson.FatherKeyValue = item.name;
    },
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (this.dataJson.MainDemoName) {
        this.dataJson.MainDemoName = this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.dataJson.MainDemoName) {
        layer.alert('请输入名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.dataJson.OLAPKey) {
        this.editOperation(callBack);
      } else {
        this.addOperation(callBack);
      }
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 新增职务
    addOperation (callBack) {
      hqAddStation(this.dataJson).then(result => {
        console.log(result, '职务新增');
        this.$emit('afterSuccess', 'Add', result.data);
        layer.alert('新增成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 修改职务
    editOperation (callBack) {
      hqEditStation(this.dataJson).then(result => {
        console.log(result, '职务修改');
        this.$emit('afterSuccess', 'Edit', result.data);
        layer.alert('修改成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    }
  }
};
</script>

<style>
</style>
