<template>
	<div class="form_info_block_list" :class="dataKey==item.key?'opt':''" @click.stop="seletedItem">
		{{item.value}}
	</div>
</template>

<script>
	export default {
	  data () {
    return {
	
    };
  },
	  props: {
	    item: Object,
	    dataKey: [Number, String]
  },
	  methods: {
	    seletedItem () {
	      this.$emit('seletedItem', this.item);
    }
  }
	};
</script>

<style>
</style>
