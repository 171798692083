<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-number :formTitle="'高转发 ≥'" :min="0" :max="100" :readonly="false" :required="true" v-model="high"></input-number>
      <input-number :formTitle="'低转发 ≤'" :min="0" :max="100" :readonly="false" :required="true" v-model="low"></input-number>
      <input-text :formTitle="'中转发'" :readonly="true" :required="false" :value="formatMiddle()"></input-text>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true" @confirmClick="submit" @cancelClick="cancel"></save-cancel-btn-group>
  </div>
</template>

<script>
import {GetSaaSClientMsg,ModifySetting} from '../../../API/workbench';
export default {
  data () {
    return {
      high:0,
      low:0
    };
  },
  components: {
  },
  props: {
  },
  created () {
    let that = this;
    GetSaaSClientMsg().then(result => {
      if (result.code == 0) {
        let list = result.data.SaaSClientSetting;
        for (let i = 0; i < list.length; i++) {
          if (list[i].SetTypeKey==115) {
            that.high = list[i].SetContent;
          }
          if (list[i].SetTypeKey==116) {
            that.low = list[i].SetContent;
          }
        }
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    formatMiddle () {
      return this.low + '% - ' + this.high + '%';
    },
    submit () {
      let that = this;
      if (!that.high) {
        layer.alert('请填写高转发率');
        return;
      }
      if (!that.low) {
        layer.alert('请填写低转发率');
        return;
      }
      let params1 = {
        SetKey: 1,
        SetKeyValue: '是',
        SetContent: that.high,
        SetTypeKey: 115
      };
      let params2 = {
        SetKey: 1,
        SetKeyValue: '是',
        SetContent: that.low,
        SetTypeKey: 116
      };
      ModifySetting(params1).then(result => {
        if (result.code == 0) {
          ModifySetting(params2).then(result2 => {
            if (result2.code == 0) {
              that.$emit('afterSuccess');
            } else {
              console.log(result2.msg);
            }
          }).catch(error => {
            console.log(error);
          });
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style scoped>
.form_info_list >>> .form_info_required .form_info_value:after{position:absolute;content:'%';height:30px;line-height:30px;color:#3498db;right:0;top:0;}
</style>