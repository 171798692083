<template>
  <div class="compile_from_box">
    <div class="compile_from_title">
      <menu-item v-for="(item,key) in vMenuList"
                 :key="key"
                 :item="item"
                 @changeType="changeType"></menu-item>
      <!--  @changeType="changeType"-->
    </div>
    <div class="compile_from_content">
      <!-- 通用表头 -->
      <div class="from_title"
           v-show="viewType==0&&dataInfo">
        <span>{{dataInfo.title}}</span>
        <span class="font_gray">{{dataInfo.remark}}</span>
      </div>
      <!-- 基础设置 -->
      <div v-show="viewType==0&&dataInfo">
        <basics-setting ref="basicsSetting"
                        :dataInfo="dataInfo"
                        :CouponInfo="CouponInfo"
                        :menuItem="menuList[0]"
                        :settingInfo="this.vSettingInfo"
                        :PosterInfo="PosterInfo"
                        @nextStep="nextStep"
                        @preStep="preStep"></basics-setting>
      </div>
      <!-- 玩法设置 -->
      <div v-show="viewType==1&&dataInfo">
        <play-setting ref="playSetting"
                      :dataInfo="dataInfo"
                      :CouponInfo="CouponInfo"
                      :menuItem="menuList[1]"
                      :settingInfo="this.vSettingInfo"
                      :PosterInfo="PosterInfo"
                      :courseTreeList="courseTreeList"
                      @updateCourseList="loadDataList"
                      @nextStep="nextStep"
                      @preStep="preStep"></play-setting>
      </div>
      <!-- 家长分销 -->
      <!-- <div v-show="viewType==2&&dataInfo">
        <parent-promote-setting :dataInfo="dataInfo"
                                :CouponInfo="CouponInfo"
                                :menuItem="menuList[2]"
                                :settingInfo="this.vSettingInfo"
                                :PosterInfo="PosterInfo"
                                @nextStep="nextStep"
                                @preStep="preStep"></parent-promote-setting>
      </div> -->
      <!-- 分销设置 -->
      <div v-show="viewType==3&&dataInfo">
        <sales-setting ref="salesSetting"
                       :dataInfo="dataInfo"
                       :CouponInfo="CouponInfo"
                       :menuItem="menuList[3]"
                       :settingInfo="this.vSettingInfo"
                       :PosterInfo="PosterInfo"
                       :courseTreeList="courseTreeList"
                       @updateCourseList="loadDataList"
                       @nextStep="nextStep"
                       @preStep="preStep"></sales-setting>
      </div>
      <!-- 内容设置 -->
      <div v-show="viewType==4&&dataInfo">
        <content-setting ref="contentSetting"
                         :dataInfo="dataInfo"
                         :CouponInfo="CouponInfo"
                         :menuItem="menuList[4]"
                         :settingInfo="this.vSettingInfo"
                         :coverInfo="coverInfo"
                         :PosterInfo="PosterInfo"
                         :contentSetting="contentSetting"
                         @verifyAllData="verifyAllData"
                         @setscorllTop="setscorllTop"
                         @success="success"
                         @nextStep="nextStep"
                         @preStep="preStep"></content-setting>
      </div>
    </div>
  </div>
</template>
<script>
import basicsSetting from './setting-component/basics-setting';
import playSetting from './setting-component/play-setting';
import parentPromoteSetting from './setting-component/parent-promote-setting';
import salesSetting from './setting-component/sales-setting';
import contentSetting from './setting-component/content-setting';
import menuItem from './setting-component/menu-item';
import { getCourseTypeNameList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      viewType: 0,
      coursList: [],
      courseTreeList: [],
      menuList: [
        { name: '基础设置', isShow: true, type: 0, isActive: true },
        { name: '玩法设置', isShow: true, type: 1, isActive: false },
        { name: '推广设置', isShow: false, type: 2, isActive: false },
        { name: '分销设置', isShow: true, type: 3, isActive: false },
        { name: '内容设置', isShow: true, type: 4, isActive: false }
      ]

    };
  },
  props: {
    dataInfo: Object,     // 玩法详情及设置.
    coverInfo: Object,
    CouponInfo: Object, // 券绑定需要提交的信息
    PosterInfo: Object,    // 模板信息
    contentSetting: Object
  },
  computed: {
    vSettingInfo () {
      let settingInfo = '';
      if (this.dataInfo) {
        settingInfo = JSON.parse(this.dataInfo.activitiesSetting);
      }
      return settingInfo;
    },
    vMenuList () {
      let newArr = [];
      if (this.vSettingInfo) {
        console.log(this.vSettingInfo, '所有设置');
        console.log(this.vSettingInfo.virtualSoftHitsSetting, '虚拟人数设置');
        console.log(this.vSettingInfo.groupSetting, '拼团设置');
        console.log(this.vSettingInfo.parentsPromotionSetting, '家长推广设置');
        console.log(this.vSettingInfo.staffDistributionSetting, '员工分销设置');
        console.log(this.vSettingInfo.parentsDistributionSetting, '家长分销设置');
        console.log(this.dataInfo.if_fenxiang, this.CouponInfo.activitiesRelevance);
        this.menuList[1].isShow = this.vSettingInfo.virtualSoftHitsSetting.isOpen || this.vSettingInfo.groupSetting.isOpen || this.vSettingInfo.parentsPromotionSetting.isOpen || this.CouponInfo.if_fenxiang != 2;
        // this.menuList[2].isShow = this.vSettingInfo.parentsPromotionSetting.isOpen;
        this.menuList[3].isShow = (this.vSettingInfo.staffDistributionSetting.isOpen || this.vSettingInfo.parentsDistributionSetting.isOpen);
      }
      this.menuList.forEach(o => {
        if (o.isShow) {
          newArr.push(o);
        }
      });
      return newArr;
    }
  },
  components: {
    basicsSetting, playSetting, parentPromoteSetting, salesSetting, contentSetting, menuItem
  },
  created () {
    this.loadDataList();
    // console.log(this.vSettingInfo, 'setting-view-created');
  },
  mounted () {
    this.initCouponInfo();
  },
  methods: {
    initCouponInfo () {
      // 打开参团设置,游戏玩法显示.
      this.CouponInfo.programme_type = this.dataInfo.programmeType;
      if (this.vSettingInfo.groupSetting.isOpen) {
        this.CouponInfo.groupSetting.target_1_A.strategy_type = 4;
        this.CouponInfo.groupSetting.target_1_B.strategy_type = 4;
        this.CouponInfo.groupSetting.target_2_A.strategy_type = 4;
        this.CouponInfo.groupSetting.target_2_B.strategy_type = 4;
        this.CouponInfo.groupSetting.target_3_A.strategy_type = 4;
        this.CouponInfo.groupSetting.target_3_B.strategy_type = 4;
        if (this.vSettingInfo.groupSetting.settlementType.defaultValue == 1) {
          this.CouponInfo.groupSetting.target_type = 1;
          this.CouponInfo.groupSetting.target_1_A.strategy_sub_type = 1;
          this.CouponInfo.groupSetting.target_1_B.strategy_sub_type = 1;
          this.CouponInfo.groupSetting.target_2_A.strategy_sub_type = 1;
          this.CouponInfo.groupSetting.target_2_B.strategy_sub_type = 1;
          this.CouponInfo.groupSetting.target_3_A.strategy_sub_type = 1;
          this.CouponInfo.groupSetting.target_3_B.strategy_sub_type = 1;
        } else {
          this.CouponInfo.groupSetting.target_type = 2;
          this.CouponInfo.groupSetting.target_1_A.strategy_sub_type = 2;
          this.CouponInfo.groupSetting.target_1_B.strategy_sub_type = 2;
          this.CouponInfo.groupSetting.target_2_A.strategy_sub_type = 2;
          this.CouponInfo.groupSetting.target_2_B.strategy_sub_type = 2;
          this.CouponInfo.groupSetting.target_3_A.strategy_sub_type = 2;
          this.CouponInfo.groupSetting.target_3_B.strategy_sub_type = 2;
        }
        //  if (this.groupStepNum == 1) {
        //   this.CouponInfo.groupSetting.target_2_A = null;
        //   this.CouponInfo.groupSetting.target_2_B = null;
        //   this.CouponInfo.groupSetting.target_3_A = null;
        //   this.CouponInfo.groupSetting.target_3_B = null;
        // } else if (this.groupStepNum == 2) {
        //   this.CouponInfo.groupSetting.target_3_A = null;
        //   this.CouponInfo.groupSetting.target_3_B = null;
        // } else {
        // }
      } else {
        this.CouponInfo.groupSetting.target_1_A = null;
        this.CouponInfo.groupSetting.target_1_B = null;
        this.CouponInfo.groupSetting.target_2_A = null;
        this.CouponInfo.groupSetting.target_2_B = null;
        this.CouponInfo.groupSetting.target_3_A = null;
        this.CouponInfo.groupSetting.target_3_B = null;
      }
      if (this.vSettingInfo.parentsPromotionSetting.isOpen) {
        this.CouponInfo.promotion_parent_open = 1;
      }
      if (this.vSettingInfo.staffDistributionSetting.isOpen) {
        this.CouponInfo.commission_agent_open = 1;
      }
      // 家长分销开关
      if (this.vSettingInfo.parentsDistributionSetting.isOpen) {
        this.CouponInfo.promotion_resale_parent_open = 1;
        // 允许自己报名
        if (this.vSettingInfo.parentsDistributionSetting.enrollOnOff) {
          this.CouponInfo.promotion_resale_parent_canbuy = this.vSettingInfo.parentsDistributionSetting.enrollOnOff.defaultValue;
        }
      }
      // 打开虚拟人数
      if (this.vSettingInfo.virtualSoftHitsSetting.isOpen) {
        this.CouponInfo.virtual_get_person_setting = this.CouponInfo.virtual_get_person_setting || { 'bgeinDate': '', 'endDate': '', 'bgeinTime': '', 'endTime': '', 'perDayNum': 0 };
      }
      // 基础设置中初始化时显示满减还是代金还是特价.
      this.CouponInfo.type = this.CouponInfo.programme_type != 1 ? '2' : '3';
      this.CouponInfo.type = this.dataInfo.type == 3 ? '3' : this.CouponInfo.type;
      // this.CouponInfo.programme_type = this.dataInfo.programmeType;
      this.CouponInfo.playID = this.dataInfo.id;
      this.CouponInfo.playType = this.dataInfo.type;
      this.CouponInfo.coverTemplateID = this.coverInfo ? this.coverInfo.id : this.CouponInfo.coverTemplateID;
      // this.CouponInfo.if_fenxiang = this.dataInfo.activitiesRelevance;
      if (this.dataInfo.activitiesRelevance == 1) {     // 普通券
        this.CouponInfo.if_fenxiang = 2;
      } else if (this.dataInfo.activitiesRelevance == 2) {    // 转介绍
        this.CouponInfo.if_fenxiang = 1;
      } else {                                        // 裂变
        this.CouponInfo.if_fenxiang = 3;
      }
      // 根据拼团设置初始化.
      console.log('this.CouponInfo.if_fenxiang', this.CouponInfo, this.dataInfo);
    },
    changeType (item) {
      // console.log(item, 'isActive');
      // let content = this.$el.querySelector('.compile_from_content');
      // content.scrollTop = content.scrollHeight - content.offsetHeight;
      // console.log(content.scrollHeight, 'content', content.offsetHeight);

      this.vMenuList.forEach(o => {
        if (o.type == item.type) {
          o.isActive = true;
          this.viewType = item.type;
        } else {
          o.isActive = false;
        }
      });
    },
    verifyAllData (callback, callback2) {
      let isFalse = false;
      let falseType = 4;
      this.vMenuList.forEach(o => {
        if (isFalse) {
          return false;
        }
        if (o.type == 0) {
          if (!this.$refs.basicsSetting.verifyData()) {
            isFalse = true;
            falseType = o.type;
            return false;
          }
        } else if (o.type == 1) {
          if (!this.$refs.playSetting.verifyData()) {
            isFalse = true;
            falseType = o.type;
            return false;
          }
        } else if (o.type == 2) {
          // 以前的家长分销.
        } else if (o.type == 3) {
          if (!this.$refs.salesSetting.verifyData()) {
            isFalse = true;
            falseType = o.type;
            return false;
          }
        }
        console.log(isFalse, 'verifyAllData', o.type);

        //  else if (o.type == 4) {
        //   if (!this.$refs.contentSetting.verifyData()) {
        //     isFalse = true;
        //     falseType = o.type;
        //     return false;
        //   }
        // }
      });
      if (!isFalse) {
        callback();
      } else {
        let item = this.vMenuList.find(o => {
          return o.type == falseType;
        });
        callback2();
        this.changeType(item);
        return false;
      }
    },
    preStep (item) {
      let locaIndex = this.vMenuList.findIndex(o => {
        return o.type == item.type;
      });
      this.changeType(this.vMenuList[locaIndex - 1]);
    },
    nextStep (item) {
      console.log(this.CouponInfo, 'nextStepnextStep');
      let locaIndex = this.vMenuList.findIndex(o => {
        return o.type == item.type;
      });
      this.changeType(this.vMenuList[locaIndex + 1]);
    },
    setscorllTop (type) {
      this.$emit('setscorllTop', type);
    },
    // 新增成功
    success () {
      this.$emit('success');
    },
    // 修改时初始化选中的套餐.
    initGetPackage (CouponInfo) {
      this.$refs.basicsSetting.initGetPackage(CouponInfo);
      console.log('initGetPackage');
      this.$nextTick(o => {
        this.$refs.salesSetting.initData(CouponInfo);
      });
    },
    // 获取课类课名列表
    loadDataList () {
      getCourseTypeNameList().then(result => {
        this.coursList = [];
        this.courseTreeList = [];
        result.data.forEach(obj => {
          // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
          if (obj.StateKey == 2 && obj.TypeKey == 2) {
            this.coursList.push(obj);
          }
          if (Number(obj.StateKey) == 2) {
            obj.UnitPrice = this.$utils.mAmountType(Number(obj.UnitPrice), 1);
            this.courseTreeList.push(obj);
          }
        });
        this.courseTreeList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部课程',
          TypeKey: '2',
          TypeKeyValue: '课类',
          LevelPath: '0.2.',
          FatherKey: '0',
          FatherKeyValue: '',
          StateKey: 2,
          StateKeyValue: '启用',
          UnitPrice: '0'
        });
        console.log(this.courseTreeList, 'this. this.courseTreeList');
      });
    }
  }

};
</script>
