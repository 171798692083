<template>
  <div class="from_list from_list_required">
    <div class="from_list_title">关联套餐</div>
    <div class="from_list_content">
      <div class="WKT_discount_package_blcok">
        <div class="WKT_discount_package_list"
             style="cursor: pointer;">
          <div class="WKT_discount_package_blcok type_blue"
               @click="onSelectPackage">
            <div class="package_blcok_top">
              <div class="package_blcok_name">
                {{comboItem.PackageName}}
              </div>
              <div class="package_blcok_text pr"
                   v-if="vIdoneityItem">
                <span class="package_blcok_text_name">适用：</span>{{vIdoneityItem.ApplyCorName||vIdoneityItem.ProjectKeyValue}}
              </div>
              <div class="package_blcok_text pr"
                   v-if="vIdoneityItem">
                <div v-if="vIdoneityItem.UnitKey==5||vIdoneityItem.UnitName">
                  <span class="package_blcok_text_name">数量：</span>{{$utils.mAmountType(vIdoneityItem.UnitPrice,2)}}元x{{$utils.mAmountType(vIdoneityItem.NumCount,1)}}{{vIdoneityItem.UnitKeyValue||vIdoneityItem.UnitName}}
                </div>
                <div v-else>
                  <span class="package_blcok_text_name">数量：</span>{{$utils.mAmountType(vIdoneityItem.NumCount,1)}}{{vUnitName}}
                </div>
              </div>
              <div v-if="visMoreShow">
                <div class="package_blcok_text">......</div>
                <div class="package_blcok_text">更多</div>
              </div>
            </div>
            <div class="package_blcok_bottom">
              <div class="package_blcok_date"
                   v-if="vShowCreateTime">
                课时有效期
                <span class="font_bold">{{vShowCreateTime}}</span>{{vUnitKeyValue}}
              </div>
              <div class="package_blcok_details"
                   @click.stop="doLookComboDetailsClick">
                查看详情
              </div>
            </div>
            <div class="package_blcok_cost">
              <div class="">
                <span class="package_blcok_unit">￥</span>
                <span class="package_blcok_number">{{$utils.mAmountType(comboItem.SalePrice,2)}}</span>
              </div>
              <div class="down_money text_center_line"
                   v-if="Number(comboItem.AfterDisPreAmount)>0">￥{{$utils.mAmountType(comboItem.TotalAmount,2)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  data () {
    return {

    };
  },
  props: {
    comboItem: Object // 券信息
  },
  created () {
    console.log(this.comboItem, '套餐信息');
  },
  watch: {

  },
  computed: {
    // 有效期
    vShowCreateTime () {
      const findItem = this.comboItem.xykdList.find(o => { return o.UnitKey == 5; });
      if (findItem) {
        if (this.comboItem.ExpireTime) {
          // let newStartTime = this.comboItem.StartTime.replace(/\-/g, '.');
          // let newExpireTime = this.comboItem.ExpireTime.replace(/\-/g, '.');
          // return newStartTime + ' - ' + newExpireTime;
          return this.comboItem.ExpireTime;
        } else {
          return this.$utils.mAmountType(this.comboItem.PeriodCount, 0);
        }
      } else {
        return false;
      }
    },
    // 单位显示
    vUnitKeyValue () {
      if (this.comboItem.ExpireTime || !this.comboItem.UnitKeyValue) {
        return '';
      } else {
        if (this.comboItem.UnitKeyValue == '月') {
          return '个月';
        } else {
          return this.comboItem.UnitKeyValue;
        }
      }
    },
    vIdoneityItem () {
      if (this.comboItem.xykdList.length > 0) {
        return this.comboItem.xykdList[0]; // 适用课单 显示第一个
      } else if (this.comboItem.incidentalsList.length > 0) {
        return this.comboItem.incidentalsList[0];// 适用杂费 显示第一个
      } else {
        return '';
      }
    },
    vUnitName () {
      if (this.vIdoneityItem.UnitKeyValue == '月' || this.vIdoneityItem.UnitName == '月') {
        return '个月';
      } else {
        return this.vIdoneityItem.UnitKeyValue || this.vIdoneityItem.UnitName;
      }
    },
    // 是否显示更多...提示
    visMoreShow () {
      if (this.comboItem.incidentalsList.length > 0 && this.comboItem.xykdList.length > 0) {
        return true;
      } else if (this.comboItem.incidentalsList.length > 1) {
        return true;
      } else if (this.comboItem.xykdList.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 选择套餐
    onSelectPackage (Item) {
      console.log('选择套餐');
      this.$emit('onSelectPackage', Item);
    },
    doLookComboDetailsClick () {
      this.$emit('doLookComboDetailsClick', this.comboItem);
    }

  }
};
</script>

