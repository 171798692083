import { render, staticRenderFns } from "./class-content-head.vue?vue&type=template&id=f89842be&scoped=true"
import script from "./class-content-head.vue?vue&type=script&lang=js"
export * from "./class-content-head.vue?vue&type=script&lang=js"
import style0 from "./class-content-head.vue?vue&type=style&index=0&id=f89842be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f89842be",
  null
  
)

export default component.exports