<template>
  <div class="content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30 overflow_hidden">
    <input-search-contain-btn v-if="!isApplicationRecord"
                              v-model="searchData.searchText"
                              :placeholder="'请输入姓名、姓名首字母、手机号'"
                              @onSearchEvent="search"></input-search-contain-btn>
    <div style="margin: 0 0 10px 20px;">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
    </div>

    <div class="recording_data_table"
         v-if="dataList.length>0"
         :style="{'margin-top':isApplicationRecord?'20px':'0px',}">
      <div class="new_table_list_thead clearfix">
        <div class="new_table_list_th style_gray tl"
             style="width: 125px;">
          <div>报名时间</div>
        </div>
        <div class="new_table_list_th style_yellow tl"
             style="width: 120px;">
          <div>学生/手机号</div>
        </div>
        <div class="new_table_list_th style_red tc"
             style="width: 100px;">
          <div>实收金额</div>
        </div>
        <div class="new_table_list_th style_green tl"
             style="width: 98px;">
          <div>经办人</div>
        </div>
      </div>
      <div class="recording_data_tbody"
           @scroll="scrollTOP">
        <div class="new_table_list_tr next_icon"
             v-for="(item,key) in dataList"
             @click="goRouter(item)"
             :key="key">
          <div class="new_table_list_td tl"
               style="width: 125px;">
            <div>{{vDate(item)}}</div>
            <div>{{vHour(item)}}</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 120px;">
            <div :class="{'opt':item.IsConfirmKey==1}">{{item.StudentKeyValue}}</div>
            <div>{{$utils.phoneModulePower(item.CustomerPhoneName)||'无手机号'}}</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 100px;">
            <div>{{Number(item.ActualColAmount)}}元</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 98px;">
            <div>{{item.BillerKeyValue}}</div>
          </div>
          <div class="new_table_list_arrow"></div>
        </div>
        <div class="new_table_list_bottom"
             v-if="isLoadEnd">
          <span class="loading_btn">加载中...</span>
        </div>
        <div class="new_table_list_bottom"
             v-if="noMore">
          <span class="font_gray">已显示全部</span>
        </div>
      </div>
    </div>
    <div class="course_block_nodata"
         style="padding-bottom:20px"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
  <!-- </custom-dialog>
  </div> -->
</template>
<script>
import { searchPageApplyRecord, RelevancyBillCollectMoney } from '../../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 20, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: '', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
        startTime: '', //	整型	必须
        endTime: '', //	整型	必须
      },
      // 上课记录日期查询
      searchObj: {
        startTime: '',
        endTime: '',
      },
      dataList: [],
      isLoadEnd: false, // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      noMore: false,
      isShowMainForm: false // 显示
    };
  },
  components: {},
  props: {
    threadInfo: Object,
    isApplicationRecord: {// 搜索框水印
      type: Boolean,
      default: false
    },
    dataInfo: Object,
  },
  created () {
    this.searchData.searchText = this.isApplicationRecord ? this.threadInfo.StudentKeyValue : '';
    if (this.dataInfo && this.dataInfo.startTime) {
      this.searchObj.startTime = this.dataInfo.startTime;
      this.searchObj.endTime = this.dataInfo.endTime;
      this.searchData.startTime = this.dataInfo.startTime;
      this.searchData.endTime = this.dataInfo.endTime;
    }
    console.log(this.threadInfo, 'threadInfo isApplicationRecord', this.isApplicationRecord, 'this.dataInfo', this.dataInfo);
    this.doShowPopupShowClick();
  },
  mounted () {
    this.$dialog.register(this, ['signuppayment', 'signuppaymentSuccess']);
  },
  computed: {},
  watch: {
    'searchData.searchText': {
      deep: true,
      handler: function (c, o) {
        if (!c) {
          this.search();
        }
      }
    }
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchData.startTime = dataInfo.startTime;
      this.searchData.endTime = dataInfo.endTime;
      this.searchData.pageIndex = 0;
      this.dataList = [];
      this.doShowPopupShowClick();
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.searchData.pageIndex = 0;
      this.searchData.searchText = '';
      this.dataList = [];
      // this.oweClasshourWarning.Total=0
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      // document.body.scrollTop = 0;
      this.getApplyRecordData(this.searchData);
    },
    getApplyRecordData (searchData) {
      this.isLoadEnd = true;
      searchPageApplyRecord(searchData).then(result => {
        console.log(result.data, 'getPotentialCustomerData');
        result.data.PageDataList.forEach(o => {
          this.dataList.push(o);
        });
        if (result.data.PageDataList.length == this.searchData.pageSize) {
          this.searchData.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      });
    },
    vDate (item) {
      if (item && item.BillTime) {
        return item.BillTime.substring(0, 10);
      }
    },
    vHour (item) {
      if (item && item.BillTime) {
        return item.BillTime.substring(10, 16);
      }
    },
    // 点击搜索按钮.
    search (text, isClear) {
      if (isClear == 'isClear') {
        return false;
      }
      this.noMore = false;
      this.searchData.pageIndex = 0;
      if (this.searchData.searchText == '') {
        this.searchData.pageSize = 20;
      } else {
        this.searchData.pageSize = 0;
      }
      this.dataList = [];
      if (!this.isLoadEnd) {
        this.getApplyRecordData(this.searchData);
      }
    },
    // 滚动加载更多
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.searchData.searchText && !this.noMore &&
        !this.isLoadEnd) {
        console.log('scrollTOP', this.noMore);
        this.getApplyRecordData(this.searchData);
      }
    },
    goRouter (item) {
      if (this.isApplicationRecord) {
        layer.confirm('是否关联？', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                RelevancyBillCollectMoney(item.TableID, this.threadInfo.StudentSignUpKey, this.threadInfo.StudentThreadKey).then(result => {
                  layer.close();
                  layer.alert('关联成功');
                  this.$emit('afterSuccess');
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
          ]
        });
      } else {
        this.$dialog.open(this, {
          name: '报名收款',
          routerName: this.$route.name,
          moduleName: 'signuppayment',
          moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
          data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, isReadOnly: true } },
          callBack: { afterSucces: () => { this.reload(); } }
        });
      }
    }
  }
};
</script>

