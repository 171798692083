<template>
  <div>
    <div class="body_text"
         v-if="!noShowContent">
      <!-- :class="!unfoldShow?'no_all':''" -->
      <div v-html="$utils.translationSchoolText(dataInfo.Content,'',dataInfo.isShowTag)"></div>
      <!-- <div class="body_text_btn"
           @click="unfoldBtn"
           v-if="!unfoldShow">展开</div> -->
    </div>

    <div :style="vMinHeight">
      <!-- 音频 -->
      <div v-if="vIsShowAudio">
        <audio-show :audioUrl="vAudioUrl"
                    :recorderTime="vAudioTime"></audio-show>
      </div>
      <!-- 视频 -->
      <div class="body_video_box bulk_assessment_students_image video_type"
           v-if="vIsShowVideo">
        <video-list :attachList="dataInfo.AttachList"
                    :isCanEdit="isCanEdit"></video-list>
      </div>

      <!-- 图片 -->
      <div>
        <div class="body_img_box "
             :class="vIsShowVideo?'img_left':''">
          <!-- :class="{dual_img:vImgShowList.length==4&&isShowAllImg, one_img:(vImgShowList.length==1&&IsOneImgShow)}" -->
          <browse-view-img v-for="(item,index) in vImgShowList"
                           :key="index"
                           :keyIndex="index"
                           :imgUrl="item.AttachUrl"
                           :vIsShowVideo="vIsShowVideo"
                           @onClickImg="toPreviewImg"></browse-view-img>
          <!-- <div class="view_photo_img body_img_list"
               v-if="vThirdImg">
            <img class="img_box"
                 :src="vThirdImg"
                 alt="">
            <div class="more_icon"
                 @click.stop="viewAllImg">+{{vImgList.length-3}}</div>
          </div> -->
        </div>
        <custom-dialog :title="'预览图片'"
                       :visible.sync="isImageViewItemShow"
                       :before-close="toClosePreviewImg">
          <div class="home_work_img">
            <preview-img :imgList="vPreviewImgData"
                         :showIndex="imgShowIndex"
                         :previewText="dataInfo.Content"
                         @onClosePreviewImg="toClosePreviewImg"></preview-img>
          </div>
        </custom-dialog>
        <!-- <div v-if="isImageViewItemShow">
          <preview-img :imgList="vPreviewImgData"
                       :showIndex="imgShowIndex"
                       :previewText="dataInfo.Content"
                       @onClosePreviewImg="toClosePreviewImg"></preview-img>
        </div> -->
      </div>

      <!-- 音频 -->

    </div>
  </div>
</template>

<script>
import browseViewImg from './browse-view-img';
import previewImg from '../preview-img/index';
import audioShow from '../audio-show';
import videoList from '../release-content/video-list/index';
// 浏览项内容 （文本 视频 图片 语音）

// 注意 图片的1张和4张 展开和收起
export default {
  data () {
    return {
      imageViewItem: {}, // 图片预览项
      imgShowIndex: 0,
      isCanEdit: false,
      isImageViewItemShow: false, // 是否开启预览图片
      unfoldShow: false, // 显示文本
      isShowAllImg: false // 显示图片
    };
  },
  props: {
    isViewImage: {
      type: Boolean, // 是否支持预览图片
      default: true
    },
    dataInfo: {
      type: Object, // 附件对象
      default: function () {
        return {
          // 文本
          // 视频
          // 图片
          // 语音
        };
      }
    },
    isUnfoldShow: {
      type: Boolean, // 默认文本超出隐藏
      default: true
    },
    isUnfoldShowAllImg: {
      type: Boolean, // false：超出3张隐藏
      default: false
    },
    IsOneImgShow: {
      type: Boolean, // 默认文本超出隐藏
      default: false
    },
    noShowContent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    browseViewImg,
    previewImg,
    audioShow,
    videoList
  },
  watch: {
    'dataInfo.Content': {
      handler (n, o) {
        if (this.isUnfoldShow) {
          if (this.$utils.strLength(this.dataInfo.Content) > 84) {
            this.unfoldShow = false;
          } else {
            this.unfoldShow = true;
          }
        } else {
          this.unfoldShow = true;
        }
      },
      // deep: true
      immediate: true
    },
    'isUnfoldShowAllImg': {
      handler (n, o) {
        this.isShowAllImg = n;
      },
      immediate: true
    }

  },
  computed: {
    vMinHeight () {
      if (this.dataInfo.AttachList && this.dataInfo.AttachList.length > 0) {
        return 'min-height: 100px;';
      } else {
        return '';
      }
    },
    // 显示文本
    vContent () {
      let newContent = this.$utils.myReplace(this.dataInfo.Content, '\n', `<br>`);
      var n = (newContent.split('<br>')).length - 1;
      if (this.isUnfoldShow && n && n > 1) {
        this.unfoldShow = false;
      }
      console.log(newContent, 'newContent');
      return newContent;
    },
    vImgShowList () { // 显示的图片
      let imgList = this.dataInfo.AttachList.filter(obj => { return obj.AttachTypeKey == 1 && obj.AttachUrl; });
      if (!this.isShowAllImg) {
        if (imgList.length > 3) {
          // imgList = imgList.splice(0, 2);
        }
      }
      return imgList;
    },
    vImgList () { // 图片数量
      return this.dataInfo.AttachList.filter(obj => { return obj.AttachTypeKey == 1; });
    },
    vThirdImg () { // 未展开 图片超过3张 第三张图片显示效果
      let imgUrl = '';
      // if (this.isUnfoldShow) {
      if (!this.isShowAllImg && this.vImgList.length > 3) {
        imgUrl = this.$store.getters.CDNURL + this.vImgList[2].AttachUrl;
      }
      // }
      return imgUrl;
    },
    vAudioUrl () {
      let audioObj = this.dataInfo.AttachList.find(obj => { return obj.AttachTypeKey == 3; });

      let url = '';
      if (audioObj) {
        url = audioObj.AttachUrl;
      }
      if (url) {
        return url;
      }
    },
    vVideoUrl () {
      let audioObj = this.dataInfo.AttachList.find(obj => { return obj.AttachTypeKey == 2; });

      let url = '';
      if (audioObj) {
        url = audioObj.AttachUrl;
      }
      if (url) {
        return url;
      }
    },
    vIsShowVideo () {
      let flag = false;
      if (this.vVideoUrl) {
        flag = true;
      }
      return flag;
    },
    vIsShowAudio () {
      let flag = false;
      if (this.vAudioUrl) {
        flag = true;
      }
      return flag;
    },
    vPreviewImgData () {
      let urlList = [];
      this.vImgList.forEach(obj => {
        let imgData = {
          imgUrl: obj.AttachUrl,
          text: ''
        };
        urlList.push(imgData);
      });
      return urlList;
    },
    vAudioTime () {
      const audioObj = this.dataInfo.AttachList.find(obj => {
        return obj.AttachTypeKey == 3;
      });
      let time = '';
      if (audioObj) {
        time = audioObj.AudioSize;
      }
      console.log(audioObj, 'audioObj12312312431431');
      if (time) {
        return Number(time);
      } else {
        return 0;
      }
    }
  },
  created () {
    console.log(this.dataInfo.Content, 'this.dataInfo.Content');
  },
  mounted () {
  },
  methods: {

    mHeadImg (url) {
      if (url) {
        if (url.indexOf('http') > -1) {
          return url;
        } else {
          return this.$store.getters.CDNURL + url;
        }
      } else {
        return '';
      }
    },
    // 展开文本
    unfoldBtn () {
      this.unfoldShow = true;// 显示全部文本
    },
    // 展开图片
    viewAllImg () {
      if (this.isViewImage) {
        // this.isShowAllImg = true;// 显示全部图片
        this.toPreviewImg(this.vImgList[2].AttachUrl);// 直接预览该图片
      } else {
        this.$emit('noPreviewImgClick');
      }
    },
    // 预览图片
    toPreviewImg (imgUrl) {
      if (this.isViewImage) {
        let imgIndex = this.vImgList.findIndex(obj => { return obj.AttachUrl == imgUrl; });
        if (imgIndex >= 0) {
          this.imgShowIndex = imgIndex;
        }
        this.isImageViewItemShow = true;
      } else {
        this.$emit('noPreviewImgClick');
      }
    },
    // 关闭预览图片
    toClosePreviewImg () {
      this.isImageViewItemShow = false;
      this.imgShowIndex = 0;
    }

    // 播放视频

  }
};
</script>

<style>
</style>