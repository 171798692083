<template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL">
      <input-dialog :formTitle="'选择员工'"
                    :formPlaceholder="'必选'"
                    :required="true"
                    @showEldilog="showSaleDialog"
                    v-model="seletedSale.SalesmanKeyValue"></input-dialog>

    </div>
    <div>
      <save-cancel-btn-group :btnText="'确定'"
                             @confirmClick="comfired"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { getInJobSalesList, DoAllocationThread } from '../../../../API/workbench';
export default {
  data () {
    return {
      seletedSale: {
        SalesmanKey: 0,
        SalesmanKeyValue: ''
      },
      dataList: [],
      showSaleList: false
    };
  },
  props: {
    studentArray: Array,   // 选中的学生
    isRecycle: {   // 是否回收站中分配
      type: Number,
      default: 0
    },
    dataInfo: Object     // 选中的单个信息

  },
  created () {

  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
    this.getSaleList();
  },
  methods: {
    doAfterSeletedItem (item) {
      this.seletedSale.SalesmanKey = item.OLAPKey;
      this.seletedSale.SalesmanKeyValue = item.MainDemoName;
    },
    showSaleDialog () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择员工',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.seletedSale.SalesmanKey, dataSourceList: this.dataList, required: true, addModuleName: 'yuangong-setting' },
        // addModuleTitleName: '添加老师', ModulePowerKey: 44
        callBack: {
          onSelectedItem: (data) => { this.doAfterSeletedItem(data); }
        }
      });
    },
    getSaleList () {
      getInJobSalesList().then(result => {
        this.dataList = result.data;
      });
    },
    comfired (callBack) {
      if (this.seletedSale.SalesmanKey == 0) {
        layer.alert('请选择顾问');
        if (callBack) {
          callBack();
        }
        return false;
      } else {
        let IsAllocation = 0;//	 是否强制分配 1是0否
        console.log(this.studentArray, 'this.studentArray');
        var studentList = JSON.stringify(this.studentArray);
        DoAllocationThread(studentList, this.seletedSale, IsAllocation, this.isRecycle).then(result => {
          layer.alert('分配成功');
          if (callBack) {
            callBack();
          }
          this.$emit('updateData');
          this.cancelClick();
        }, err => {
          if (err.code == 99999) {
            layer.confirm(err.msg, {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    IsAllocation = 1;
                    DoAllocationThread(studentList, this.seletedSale, IsAllocation, this.isRecycle).then(result => {
                      if (callBack) {
                        callBack();
                      }
                      this.$emit('updateData');
                      this.cancelClick();
                      layer.close();
                    }, err => {
                      layer.alert(err.msg);
                    });
                  }
                }
              ]
            });
          } else {
            layer.alert(err.msg);
          }
        });
      }
    },
    cancelClick () {
      this.$emit('closeDialog');
    }
  }
};
</script>
