<template>
  <div style="display: flex;flex: 1; padding: 20px;flex-direction: column;">
    <div style="padding: 10px;font-size: 16px;height: 50px;display: flex;align-items: center;"> <img src="../../../../../public/image/qbkb.png"
           style="width: 28px;height: 28px;margin-right: 5px;" /><span>全部课件</span></div>
    <div class="contentBox">
      <div class="contentItem"
           v-for="item in data">
        <coursewareContentItem :item="item"
                               @openDetails="openDetails"></coursewareContentItem>
      </div>
    </div>
    <custom-dialog :title="'大课包授权详情'"
                   :visible.sync="showDetails"
                   :before-close="closeDetailsPopup">
      <bigcourseware-details :ClientKey="ClientKey"
                             :bigKey="bigKey"
                             @close="closeDetailsPopup"
                             @afterSuccess="getDataDetails"></bigcourseware-details>
    </custom-dialog>
  </div>
</template>
<script>
import { GetAuthorizeBigCoursePackage } from '../../../../API/workbench.js';
import coursewareContentItem from './courseware-content-item.vue';
import bigcoursewareDetails from './bigcourseware-details.vue';
export default {
  data () {
    return {
      bigKey: 0,
      showDetails: false,
      data: []
    };
  },
  components: {
    coursewareContentItem,
    bigcoursewareDetails
  },
  props: {
    ClientKey: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
  },
  created () {
  },

  mounted () {
    this.getDataDetails();
  },

  methods: {
    getDataDetails () {
      GetAuthorizeBigCoursePackage(this.ClientKey).then(res => {
        console.log('校区授权课包res', res.data);
        this.data = res.data;
      });
    },
    closeDetailsPopup () {
      this.showDetails = false;
    },
    openDetails (key) {
      this.bigKey = key;
      this.showDetails = true;
    }
  }
};
</script>
<style scoped>
.contentBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.contentItem {
  width: 420px;
  height: 340px;
  background: #ececec;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px;
  /* margin-left: 10px; */
  /* margin-right: 5px; */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 11px;
  margin-left: 11px;
}
.item_img {
  width: 100%;
  height: 180px;
  background: cadetblue;
}
.item_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
}
</style>