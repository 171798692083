<template>
  <!-- 报名分析  -->
  <div class="statistics_bottom_dual">
    <div class="chain_mendian_center_right">
      <div class="right_title">
        <div class="right_title_text">
          <div>报名分析</div>
        </div>
        <div class="right_title_date">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix">
              <single-year-picker @chgDate="chgDate"
                                  :isDisableNow="isDisableNow"
                                  :defaultDate="defaultDate"
                                  :type="'year'"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="right_title_echarts">
        <div class="echarts_box"
             v-show="totalAmount>0">
          <circleEchart :echartID="'circle_echart'"
                        :reqList="monthList"
                        :nowDataList="nowList"
                        :tipstitle="vtipstitle"
                        ref="echart"></circleEchart>
          <div class="center_tips">
            <div class="font_gray">报名总金额</div>
            <span :style="vFontText">{{mTotalAmount(totalAmount)}}</span>
            <div style="font-size: 20px;margin-top: 8px;">{{studentNum}}人次</div>
          </div>

        </div>
        <div v-show="totalAmount==0"
             style="width:100%;height:100%">
          <div class="monitoring_content_nodata"
               style="margin-top: 20px;">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import circleEchart from './circle-echart';
import singleYearPicker from '../../../../base-module/date-picker-common/single-year-picker';
import {
  GetApplyAmountForGradeStudent
} from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      //  班级学生报名分析 student-apply-report
      studentApplyDate: {
        startTime: Date.new().getFullYear() + '-' + '01' + '-' + '01',
        endTime: Date.new().getFullYear() + '-' + '12' + '-' + '31',
        GradeID: ''//		整型	必须		数据源：GET	 班级id
      },
      nowDataList: [], // 班级学生报名分析列表
      monthList: [],
      nowList: [],
      totalAmount: 0, // 报名总金额
      studentNum: 0, // 报名总人数
      defaultDate: Date.new(Date.new().setDate((Date.new().getDate() - -1))),
      isDisableNow: true,          // 是否禁用今天以后的日期
      echartColor: ['#5A8DF6', '#62DAAB', '#F56E53', '#657798', '#EEB91D']
    };
  },
  props: {
    classKey: [Number, String] // 列表数据

  },
  components: {
    singleYearPicker,
    circleEchart
  },
  created () {

  },
  watch: {
    'classKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0) {
          this.studentApplyDate.GradeID = n;
          this.$nextTick(() => {
            this.getGetApplyAmountForGradeStudent();
          });
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  mounted () {
    this.getGetApplyAmountForGradeStudent();
  },
  methods: {
    chgDate (val, startTime, endTime) {
      console.log(val, startTime, endTime, 'chgDate');
      this.studentApplyDate.startTime = startTime.replace(/\//g, '-');
      this.studentApplyDate.endTime = endTime.replace(/\//g, '-'); ;
      this.getGetApplyAmountForGradeStudent();
    },
    getGetApplyAmountForGradeStudent () {
      if (Number(this.classKey) > 0) {
        this.studentApplyDate.GradeID = this.classKey;
        GetApplyAmountForGradeStudent(this.studentApplyDate).then(result => {
          console.log(result.data, '班级学生报名分析列表');
          this.nowDataList = result.data;
          this.nowList = this.setClassAmountList();
          // this.totalAmount = Math.round(Number(result.data.newlyBorn.RecableAmount)) + Math.round(Number(result.data.renew.RecableAmount));
          this.totalAmount = Number(Number(result.data.newlyBorn.RecableAmount) + Number(result.data.renew.RecableAmount)).toFixed(0);
          console.log(this.totalAmount, 'this.totalAmount');
          this.studentNum = Number(result.data.newlyBorn.StudentNum) + Number(result.data.renew.StudentNum);
          this.$nextTick(() => {
            if (this.totalAmount > 0) {
              this.$refs.echart.echartInit();
            }
          });
        }).catch(error => {
          console.log('ErroretAttenReport', error);
        });
      }
    },
    setClassAmountList () {
      let nowList = [];
      let obj = {
        value: Number(this.nowDataList.newlyBorn.RecableAmount) > 0 ? Number(this.nowDataList.newlyBorn.RecableAmount) : null,
        name: '新生   ' + Number(this.nowDataList.newlyBorn.StudentNum) + '人次  ' + this.mTotalAmount(Number(this.nowDataList.newlyBorn.RecableAmount).toFixed(0)),
        itemStyle: {
          color: '#3498db'
        }
      };
      nowList.push(obj);

      let obj2 = {
        value: Number(this.nowDataList.renew.RecableAmount) > 0 ? Number(this.nowDataList.renew.RecableAmount) : null,
        name: '续费   ' + Number(this.nowDataList.renew.StudentNum) + '人次  ' + this.mTotalAmount(Number(this.nowDataList.renew.RecableAmount).toFixed(0)),
        itemStyle: {
          color: '#F56E53'
        }
      };
      nowList.push(obj2);
      return nowList;
    },
    mTotalAmount (totalAmount) {
      var amount = (totalAmount.toString().indexOf('.') !== -1) ? totalAmount.toLocaleString() : totalAmount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      return amount + '元';
    }
  },
  computed: {
    vFontText () {
      // this.totalAmount = 6666;
      if (Number(this.totalAmount) > 99999999) {
        return 'font-size: 16px;';
      } else if (Number(this.totalAmount) > 9999999) {
        return 'font-size: 18px;';
      } else if (Number(this.totalAmount) > 999999) {
        return 'font-size: 20px;';
      } else if (Number(this.totalAmount) > 99999) {
        return 'font-size: 22px;';
      } else if (Number(this.totalAmount) > 9999) {
        return 'font-size: 26px;';
      } else {
        return '';
      }
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vtipstitle () {
      let month = this.$utils.formatDateStr(this.studentApplyDate.startTime, 'yyyy');
      return month + '年';
    }
  }
};
</script>
