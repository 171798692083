<template>
  <div>
    <!-- <custom-dialog title="异常课时消耗"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick"> -->
    <!-- <div class="defult_dialog_header">
        <span
          class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
          @click.stop="$router.go(-1)"
        ></span>
        <span class="defult_dialog_header_font">异常课时消耗</span>
        <div class="defult_dialog_header_btn fr" @click="goRouter()">
          <span>上课课时消耗</span>
        </div>
      </div> -->
    <!-- <div class="defult_dialog_header_btn" @click="goOther"><span>课时消耗</span></div> -->
    <div class="content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30 overflow_hidden">
      <div class="course_consumption_header clearfix">
        <div class="fl"
             style="width:50%"
             :class="{'opt':activeType==1}"
             @click="activeType=1">
          <span>明细表</span>
        </div>
        <div class="fl"
             style="width:50%"
             :class="{'opt':activeType==2}"
             @click="activeType=2">
          <span>日统计表</span>
        </div>
        <function-btn :item="abnormalchconsumebtn"
                      :className="''"
                      @clickItem="goOther">
          <div class="defult_dialog_header_btn"><span>上课课时消耗</span></div>
        </function-btn>
        <!-- <div class="fr" @click="goOther"><span></span></div> -->
      </div>
      <input-search-contain-btn v-if="activeType==1"
                                v-model="searchData.searchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                @onSearchEvent="search"></input-search-contain-btn>

      <div v-if="dataList.length > 0 &&activeType==1"
           class="recording_data_table">
        <div class="recording_data_thead clearfix"
             v-if="activeType==1">
          <div class="recording_data_th"
               style="width: 125px;">
            <div>发生时间</div>
          </div>
          <div class="recording_data_th"
               style="width: 150px">
            <div>录入人/说明</div>
          </div>
          <div class="recording_data_th"
               style="width: 100px">
            <div>学生</div>
          </div>
          <div class="recording_data_th"
               style="width: 120px">
            <div>异常/课消</div>
          </div>
        </div>

        <!-- 课时消耗明细 -->
        <div class="recording_data_tbody"
             @scroll="scrollTOP"
             v-if="activeType==1">
          <detail-item class="recording_data_tr"
                       v-for="(item,key) in dataList"
                       :key="key"
                       :item="item"></detail-item>
          <div class="all_list_bottom">
            <span v-if="isLoadEnd"
                  class="loading">加载中...</span>
            <span v-else
                  class="complete">已显示全部</span>
          </div>
        </div>
        <!-- 课时消耗日统计 -->
      </div>
      <div v-if="dataList.length == 0 &&activeType==1"
           style="height: 503px;">
        <div class="course_block_nodata ">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
      <div v-if="dayReportList.length > 0 &&activeType==2"
           class="recording_data_table">
        <div class="recording_data_thead clearfix"
             v-if="activeType==2">
          <div class="recording_data_th"
               style="width: 150px;">
            <div>发生日期</div>
          </div>
          <div class="recording_data_th"
               style="width: 90px;">
            <div>次数</div>
          </div>
          <div class="recording_data_th"
               style="width: 120px;">
            <div>异常增课时</div>
          </div>
          <div class="recording_data_th"
               style="width: 120px;">
            <div>异常减课时</div>
          </div>
          <div class="recording_data_th"
               style="width: 120px;">
            <div>异常课消</div>
          </div>
        </div>
        <div class="recording_data_tbody"
             @scroll="scrollTOPDataForDay"
             v-if="activeType==2">
          <day-statisctics-item class=""
                                v-for="(item,key) in dayReportList"
                                :key="key"
                                :item="item"></day-statisctics-item>
          <div class="all_list_bottom">
            <span v-if="isLoadEnd"
                  class="loading">加载中...</span>
            <span v-else
                  class="complete">已显示全部</span>
          </div>
        </div>
      </div>
      <div v-if="dayReportList.length == 0 &&activeType==2"
           style="height: 503px;">
        <div class="course_block_nodata ">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
    <!-- </custom-dialog> -->
  </div>
</template>
<script>
import {
  getAbnormalClassConsumeReportForDay,
  getAbnormalClassConsumeDetail
} from '../../../API/workbench';
import detailItem from './detail-item';
import dayStatiscticsItem from './day-statisctics-item';

export default {
  data () {
    return {
      searchData: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 20, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: '', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
        typeKey: '',
        DeductTypeKey: '',
        startime: '',
        endtime: ''
      },
      dataList: [], // 详细列表
      dayReportList: [], // 日 统计数据
      activeType: 1,
      startTime: Date.new().toLocaleDateString().replace(/\//g, '-'),
      endTime: Date.new().toLocaleDateString().replace(/\//g, '-'),
      isLoadEnd: false, // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      menDianInfo: {},
      isShowMainForm: false, // 显示
      noMore: false,
      forDaynoMore: false,    // 日统计已显示全部
      abnormalchconsumebtn: {
        name: '',
        type: 'tjxs',
        ModulePowerKey: 60
      }
    };
  },
  components: {
    detailItem,
    dayStatiscticsItem
  },
  created () {
    this.doShowPopupShowClick();
    // this.menDianInfo = this.$utils.parseJson(this.$store.getters.SaaSClientInfo);
  },
  mounted () {
    // console.log("SaaSClientInfo",this.$store.getters)
    // console.log("this.SaaSClientInfo", this.menDianInfo);
    // this.getClassCountDetail(this.searchData);
    // this.startTime = this.getStartTime(this.endTime);
    // // if (Date.new(this.startTime) < Date.new(this.menDianInfo.CreateDateTime)) {
    // //   this.startTime = this.menDianInfo.CreateDateTime.substring(0, 10);
    // // }
    // this.getClassCountDataForDay(this.startTime, this.endTime);
  },
  computed: {},
  // watch: {
  //   'searchData.searchText': {
  //     deep: true,
  //     handler: function (c, o) {
  //       if (!c) {
  //         this.dataList = [];
  //         this.searchData.pageIndex = 0;
  //         this.searchData.pageSize = 20;
  //         this.getClassCountDetail(this.searchData);
  //       }
  //     }
  //   }
  // },
  methods: {
    // 主表单开关
    doShowPopupHideClick () {
      this.activeType = 1;
      this.searchData.pageIndex = 0;
      this.searchData.searchText = '';
      this.dataList = [];
      this.dayReportList = [];
      this.isLoadEnd = false;
      this.noMore = false;
      this.forDaynoMore = false;
      // this.oweClasshourWarning.Total=0
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      this.isLoadEnd = false;
      this.noMore = false;
      this.forDaynoMore = false;
      // document.body.scrollTop = 0;
      this.startTime = Date.new().toLocaleDateString().replace(/\//g, '-');
      this.endTime = Date.new().toLocaleDateString().replace(/\//g, '-');
      this.getClassCountDetail(this.searchData);
      this.startTime = this.getStartTime(this.endTime);
      this.getClassCountDataForDay(this.startTime, this.endTime);
    },
    goOther () {
      // this.doShowPopupHideClick();
      this.$emit('closeDialog');
      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: 'classstatisticsrecord'
        // callBack: { doafterRegisterCustomer: (data) => { this.addSuccessEvent(data); } }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 消耗课时详细查询
    getClassCountDetail (searchData) {
      this.isLoadEnd = true;
      getAbnormalClassConsumeDetail(searchData).then(result => {
        result.data.PageDataList.forEach(o => {
          this.dataList.push(o);
        });
        this.isLoadEnd = false;
        if (result.data.PageDataList.length < searchData.pageSize) {
          this.noMore = true;
        }
        this.searchData.pageIndex++;
      });
    },
    // 消耗课时按日查询
    getClassCountDataForDay (startTime, endTime) {
      this.isLoadEnd = true;
      getAbnormalClassConsumeReportForDay(startTime, endTime).then(result => {
        result.data.DataList.sort((x, y) => {
          return Date.new(y.SetUpTime) - Date.new(x.SetUpTime);
        });
        this.menDianInfo = this.$utils.parseJson(this.$store.getters.SaaSClientInfo);

        result.data.DataList.forEach(o => {
          if (
            Date.new(o.SetUpTime) >=
            Date.new(this.menDianInfo.CreateDateTime.substring(0, 10))
          ) {
            this.dayReportList.push(o);
          } else {
            this.forDaynoMore = true;
          }
        });
        this.isLoadEnd = false;
      });
    },
    // 滚动加载更多
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.isLoadEnd && !this.noMore

      ) {
        // console.log('scrollTOP', this.noMore);

        this.getClassCountDetail(this.searchData);
      }
    },
    // 滚动加载事件.
    scrollTOPDataForDay (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.isLoadEnd && !this.forDaynoMore
      ) {
        this.endTime = this.getEndTime(
          this.dayReportList[this.dayReportList.length - 1].SetUpTime
        );

        this.startTime = this.getStartTime(this.endTime);
        this.getClassCountDataForDay(this.startTime, this.endTime);
      }
    },
    // 点击搜索按钮.
    search () {
      this.searchData.pageIndex = 0;
      this.searchData.pageSize = 20;
      this.noMore = false;
      this.dataList = [];
      this.getClassCountDetail(this.searchData);
    },
    getEndTime (endTime) {
      let dateTime = Date.new(endTime).getTime() - 86400000;
      return (
        Date.new(dateTime).getFullYear() +
        '/' +
        (Date.new(dateTime).getMonth() + 1) +
        '/' +
        Date.new(dateTime).getDate()
      );
    },
    getStartTime (endTime) {
      let month = Date.new(endTime).getMonth(); // 上个月.
      let year = Date.new(endTime).getFullYear();
      if (month < 10) {
        month = '0' + month;
      }
      if (Number(month) == 0) {
        month = 12;
        year--;
      }
      return year + '/' + month + '/01';
    },
    goRouter () {
      this.$router.push({ name: 'classStatisticsRecord' });
    }
  }
};
</script>

