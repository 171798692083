<!-- 数量 -->
<template>
 <el-table-column  :fixed="columnData.fixed||false"
                              :label="columnData.label"
                              :prop="columnData.prop"
                              :width="columnData.width"
                              :min-width="columnData.minWidth"
                              :sortable="columnData.sortable"
                              :show-overflow-tooltip="vShowOverflowTooltip"
                              :align="columnData.align||'center'"
                              :sort-orders="['ascending', 'descending']"
                              :label-class-name="columnData.columnClass"> 
  <template slot-scope="scope">
    <span @click="clickEvent(scope.row,$event)">
      <span :class="[columnData.extend&&columnData.extend.className,columnData.extend&&typeof(columnData.extend.setClassName)=='function'&&columnData.extend.setClassName(scope.row)]">
        {{formatData(scope.row)}}
      </span>
    </span>
  </template>
</el-table-column>
</template>
<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            click: () => {} // 表示该列可点击，点击需要的其它操作直接写在回调函数里
          }
        };
      }
    }
  },
  computed: {
    vShowOverflowTooltip () {
      if (typeof this.columnData.showOverflowTooltip == 'boolean') {
        return this.columnData.showOverflowTooltip;
      } else {
        return true;
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData, e) {
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
          // 普通点击事件 直接执行回调
        this.columnData.extend.click(rowData);
      }
    },
    // 保留小数位数
    formatData (rowData) {
      if (!rowData.isEmpertyRow) {
        if (rowData[this.columnData.prop] === '') {
          return rowData[this.columnData.prop];
        } else if (rowData[this.columnData.prop] == 0) {
          return '-';
        } else {
          return this.$utils.mAmountType(rowData[this.columnData.prop], 0);
        }
      } else {
        return '';
      }
    }
  }
};
</script>

<style>

</style>