<template>
  <div :class="{JXT_fixed_btn_box:isFloatBtn}"
       @click="doClick">
    <div class="JXT_list_btn"
         :class="btnClass">{{btnText}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lastClickTime: null,
      isSubmiting: false
    };
  },
  props: {
    btnText: {
      type: String,
      default: '确认'
    },
    isSingleSubmit: {
      type: Boolean,
      default: false
    }, // 是否启用单次点击,
    singleSubmitType: {
      type: String,
      default: 'static'
    }, // static（根据两次点击的时间间隔）, dynamic（提交完成后 手动更改参数）
    btnClass: {
      type: String,
      default: 'btn_hover_bg_blue'
    }, // 自定义搜索名
    isFloatBtn: {// 是否为悬浮按钮
      type: Boolean,
      default: false
    }
  },
  methods: {
    doClick () {
      if (!this.isSingleSubmit && this.checkCanClick()) {
        this.$emit('click', () => { this.isSubmiting = false; });
      }
    },
    checkCanClick () {
      if (this.singleSubmitType == 'static') {
        let now = Date.new();
        let r = now - (this.lastClickTime || 0) > 500;
        if (r) {
          this.lastClickTime = now;
        }
        return r;
      } else {
        let r = !this.isSubmiting;
        this.isSubmiting = true;
        console.log('checkCanClick', r);
        return r;
      }
    }
  }
};
</script>

<style>
</style>