<template>
  <div class="courseware_reveal_all_view">
    <!-- <div v-if="viewType == 2"
         class="all_preview_tips">
      预览模式，{{timeoutNumber}}秒后退出全屏
    </div> -->
    <div class="content_top_btn">
      <div v-if="dataInfoLength > 1&&viewType!=3"
           class="btn_icon icon_left"
           :class="{ type_gray: dataInfo.ShowOrder == 1 }"
           @click="upInfo"></div>
      <div v-if="dataInfoLength > 1&&viewType!=3"
           class="btn_icon icon_right"
           :class="{ type_gray: dataInfo.ShowOrder == dataInfoLength }"
           @click="downInfo"></div>
      <div class="btn_icon icon_all"
           @click="hiddenAllView"></div>
    </div>
    <image-view v-if="dataInfo.AttachTypeKey == '1'"
                :dataInfo="dataInfo"
                :watermark="vWatermarkMendian"></image-view>

    <video-view v-if="dataInfo.AttachTypeKey == '2'"
                :dataInfo="dataInfo"
                :watermark="vWatermarkMendian"></video-view>
    <div v-if="dataInfo.AttachTypeKey == '3'"
         class="courseware_reveal_all_audio">
      <audio-view :dataInfo="dataInfo"
                  :watermark="vWatermarkMendian"></audio-view>
    </div>
    <all-ppt-view v-if="dataInfo.AttachTypeKey == '4'"
                  :dataInfo="dataInfo"
                  :watermark="vWatermarkMendian"></all-ppt-view>
    <all-pdf-view v-if="dataInfo.AttachTypeKey == '5'"
                  :dataInfo="dataInfo"
                  :watermark="vWatermarkMendian"
                  :type="viewType"></all-pdf-view>
    <watermark-view :textInfo="vWatermarkBrand"></watermark-view>
    <!-- <Tips v-if="viewType == 2" :class="funErr()"></Tips> -->
  </div>
</template>
<script>
// import Tips from './courseware-content/tips.vue';
import audioView from './courseware-content/audio-view';
import imageView from './courseware-content/image-view';
import allPptView from './all-ppt-view';
import allPdfView from './all-pdf-view';
import videoView from './courseware-content/video-view';
import watermarkView from './courseware-content/watermark-view';
export default {
  data () {
    return {
      timeoutNumber: 60,
      timeoutObj: null,
      currentIndex: 0, // 防盗机制定时器数据
      timer: null, // 防盗机制定时器
    };
  },
  components: {
    audioView,
    imageView,
    allPptView,
    allPdfView,
    videoView,
    watermarkView,
    // Tips
  },
  computed: {
    vWatermarkMendian () {
      if (this.watermarkInfo[0].SetKey && this.watermarkInfo[0].SetKey == 1 && this.watermarkInfo[1].SetKey == 1) {
        return (
          this.$store.getters.SaaSClientInfo.MainDemoName +
          ' ' +
          this.$store.getters.token.UserName
        );
      } else if (this.watermarkInfo[0].SetKey == 1) {
        return this.$store.getters.token.UserName;
      } else if (this.watermarkInfo[1].SetKey == 1) {
        return this.$store.getters.SaaSClientInfo.MainDemoName;
      } else {
        return '';
      }
    },
    vWatermarkBrand () {
      if (this.watermarkInfo[2].SetKey && this.watermarkInfo[2].SetKey == 1) {
        return this.watermarkInfo[2].SetContent;
      } else {
        return '';
      }
    }
  },
  props: {
    dataInfo: Object,
    dataInfoLength: Number,
    watermarkInfo: Array,
    viewType: [String, Number]
  },
  created () {
    // if (this.viewType == 2) {
    //   this.countdownStart();
    // }
    // if (this.$route.query.type != 1) {
    //   // 防盗机制的定时器
    //   this.timer = setInterval(() => {
    //     this.currentIndex++;
    //     // console.log(this.currentIndex);
    //   }, 1000);
    // }
  },
  methods: {
    countdownStart () {
      clearInterval(this.timeoutObj);
      this.timeoutObj = null;
      this.timeoutObj = setInterval(() => {
        this.timeoutNumber--;
        if (this.timeoutNumber == 0) {
          clearInterval(this.timeoutObj);
          this.timeoutObj = null;
          this.hiddenAllView();
        }
      }, 1000);
    },
    hiddenAllView () {
      this.$emit('hiddenAllView');
    },
    upInfo () {
      if (this.dataInfo.ShowOrder != 1) {
        this.$emit('upInfo', this.dataInfo.ShowOrder);
      }
    },
    downInfo () {
      if (this.dataInfo.ShowOrder < this.dataInfoLength) {
        this.$emit('downInfo', this.dataInfo.ShowOrder);
      }
    },
    // funErr () {
    //   if (this.currentIndex > 100 && this.currentIndex < 200) {
    //     return 'tips1';
    //   } else if (this.currentIndex >= 200 && this.currentIndex < 300) {
    //     return 'tips2';
    //   } else if (this.currentIndex == 300) {
    //     this.currentIndex = 0;
    //   } else {
    //     return 'tips';
    //   }
    // }
  }
};
</script>
<style>
.all_preview_tips {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: rgba(0, 0, 0, 0.36);
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 0 5px;
  border-radius: 2px;
  z-index: 2;
}
.tips {
  position: absolute;
  left: 0;
  top: 250px;
  right: 0;
  z-index: 999;
}

.tips1 {
  position: absolute;
  left: 20px;
  top: 450px;
  right: 20px;
  z-index: 999;
}

.tips2 {
  position: absolute;
  left: 20px;
  top: 700px;
  right: 20px;
  z-index: 999;
}
</style>