<template>
  <div class="">
    <div class="up_sell_record_from_title"
         style="padding-left:20px">
      <span>试课时间</span>
    </div>
    <!-- <select-class-time-rang :formInfoLine="false"
                            :required="false"
                            class="from_box_margin"
                            @onSelectTimeRange="selectTimeRange"
                            @onDelTimeRange="delTimeRange"></select-class-time-rang> -->
    <month-chart ref="monthChart"
                 style="margin-top:-7px"
                 :isBut="true"
                 :monthInfo="vMonthInfo"
                 :courseDateList="courseTableData.DateList"
                 @clickDayAfter="clickDayAfter"
                 @getMonthInfo="getMonthInfo"
                 @continuePress="continuePress"
                 @continueClick="continueClick"
                 @headerMenu="serachPopup"></month-chart>
    <div class="popup_dialog_tips_box"
         v-if="monthInfo.length>0">
      <div class="tips_text"><span class="month_chart_tips_icon"></span>表示已建课日期</div>
    </div>
    <div class="abnormal_sign_popup">
      <div class=""
           style="line-height:48px;">
        <span class="sign_popup_type">当日课程</span>
        <span>(共<span class="font_blue">{{vSeletedDateCourseList.length}}</span>节)</span>
      </div>
      <div class="arrange_course_ul  sign_popup_ul"
           style="min-height:0px">
        <course-item v-for="(item,key) in vSeletedDateCourseList"
                     :key="key"
                     :courseItem="item"
                     @clickCourseItem="clickCourseItemAfter"></course-item>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           @confirmClick="submit"
                           @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    <!-- <common-button-group @buttonClickEvent="submit"
                         @closeDialog="doAfterClickCancelBtn"></common-button-group> -->
    <!-- {{vSeletedDateCourseList}} -->
  </div>
</template>

<script>
import monthChart from './month-chart';
import courseItem from './course-item';
// import setClassTimeRang from '../../../common/set-class-time-rang';
// import selectClassTimeRang from '../../../common/select-class-time-rang';
import { GetCourseListForAddCourse, GetScheduleCourseListByClassType, SearchStudentHasArrangeSupport, ArrangeTrialClass } from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      monthInfo: [], // 月视图数据
      // courseDateList: [],     // 选中日期.
      timeRangeList: [],      // 时间段列表
      seletedTimeInfo: null,    // 选中时间段信息.
      isShowAddTimeRange: false,   // 显示新增时间.
      seletedDate: this.$utils.getCurrentMonthFirst(),
      seletedCourseInfo: null
    };
  },
  props: {
    courseTableData: {
      type: Object
    },
    studentInfo: {
      type: Object
    }
  },
  computed: {
    vMonthInfo () {
      let newArr = [];
      this.monthInfo.forEach(o => {
        // if (o.StatusKey == 2) {  //未开始的课
        // newArr.push(o);
        // }
        if ((Date.new(o.ClassTime).getTime() >= Date.new(Date.new().toLocaleDateString()).getTime() - (86400000 * 59))) {  // 大于过去59天的课
          newArr.push(o);
        }
      });
      return newArr;
    },
    vSeletedDateCourseList () {
      let newArr = [];
      let chooseDate = this.courseTableData.DateList;
      this.vMonthInfo.forEach(o => {
        if (chooseDate.length > 0) {
          // if (o.ClassTime == chooseDate[0] && o.StatusKey == 2) {
          //   newArr.push(o);
          // }
          if (o.ClassTime == chooseDate[0] && (Date.new(o.ClassTime).getTime() >= Date.new(Date.new().toLocaleDateString()).getTime() - (86400000 * 59))) {
            newArr.push(o);
          }
        }
      });
      console.log(newArr, 'vSeletedDateCourseList');
      return newArr;
    }
  },
  components: {
    monthChart, courseItem
    // setClassTimeRang,
    // selectClassTimeRang
  },
  created () {
    console.log(this.courseTableData, 'courseTableData');
  },
  methods: {
    // 选中不同天初始化
    clickDayAfter () {
      this.monthInfo.forEach(o => {
        o.isActive = false;
      });
      this.seletedCourseInfo = null;
    },
    // 选中课.
    clickCourseItemAfter (item) {
      console.log(item, 'clickCourseItemAfter', this.seletedCourseInfo);
      this.monthInfo.forEach(o => {
        if (item.OLAPKey == o.OLAPKey) {
          if (!o.alreadyJoin) {      // 假如未加入过课, 可变更高亮选中.
            o.isActive = !o.isActive;
            this.seletedCourseInfo = o.isActive ? o : null;
          } else {
            // o.isActive = true;
          }
        } else {
          o.isActive = false;
        }
      });
    },
    getMonthInfo (dateStr) {
      console.log(dateStr, 'getMonthInfo');
      this.seletedDate = dateStr;
      // if (this.seletedTimeInfo) {
      this.selectTimeRange(this.seletedTimeInfo);
      // }
    },
    // 外部调用
    getTimeRangeCourseData () {
      console.log('getTimeRangeCourseData', this.seletedTimeInfo);
      // if (this.seletedTimeInfo) {
      this.selectTimeRange(this.seletedTimeInfo);
      // }
    },
    // 选中时间段查询
    selectTimeRange (item) {
      // this.seletedTimeInfo = item;
      let newData = {
        CourseKey: this.courseTableData.ClassSetKey,
        StartTime: this.$utils.formatDateStr(Date.new(Date.new(Date.new(this.seletedDate).setMonth((Date.new(this.seletedDate).getMonth() - 1))).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(Date.new(this.seletedDate).setMonth((Date.new(this.seletedDate).getMonth() + 1)))), 'yyyy-MM-dd')
      };
      let seletedDateList = this.$utils.parseJson(this.courseTableData.DateList);
      seletedDateList.sort((x, y) => {
        return Date.new(x) - Date.new(y);
      });
      if (seletedDateList.length > 0) {
        let lastDate = Date.new(seletedDateList[seletedDateList.length - 1]) > Date.new(newData.EndTime);
        let startDate = Date.new(seletedDateList[0]) < Date.new(newData.StartTime);
        newData.EndTime = lastDate ? seletedDateList[seletedDateList.length - 1] : newData.EndTime;
        newData.StartTime = startDate ? seletedDateList[0] : newData.StartTime;
      }
      this.$emit('selectTimeRange', this.seletedTimeInfo);
      // this.getCourseListForAddCourse(newData);
      this.getCourseList(newData.StartTime, newData.EndTime);
    },
    // 删除时间段
    delTimeRange (item) {
      this.seletedTimeInfo = item;
      this.$emit('selectTimeRange', this.seletedTimeInfo);
      // 清除当前选中时间段获取的课信息
    },
    // 获取课列表信息
    getCourseListForAddCourse (data) {
      GetCourseListForAddCourse(data).then(result => {
        this.monthInfo = result.data;
        this.monthInfo.forEach(o => {       // 获取到数据时判断是否已经排课的日期被选中, 如果被选中则去除.
          let locaIndex = this.courseTableData.DateList.findIndex(p => {
            return p == o.ClassTime;
          });
          if (locaIndex >= 0) {
            this.courseTableData.DateList.splice(locaIndex, 1);
          }
        });
        console.log('getCourseListForAddCourse', result.data, this.courseTableData.DateList);
      }).catch(error => {
        console.log(error, 'errorGetCourseListForAddCourse');
      });
    },
    // 获取课程
    getCourseList (firstDate, lastDate) {
      console.log(this.courseTableData.ClassSetKey, 'getCourseList');
      let obj = {
        startTime: firstDate,
        endTime: lastDate,
        classTypeKey: this.courseTableData.ClassSetKey
      };
      GetScheduleCourseListByClassType(obj).then(res => {
        if (res.code == 0) {
          // console.log(res.data, '课程List');
          this.getStudentHasArrangeSupport(firstDate, lastDate, this.courseTableData.ClassSetKey, this.courseTableData.ClassSetKey, res.data);
        }
        console.log(res.data, '课程List');
      }, err => {
        layer.alert(err.msg);
      }
      );
    },
    getStudentHasArrangeSupport (firstDate, lastDate, courseID, classID, data) {
      SearchStudentHasArrangeSupport(this.studentInfo.OLAPKey, courseID, firstDate, lastDate, 0).then(res2 => {
        this.monthInfo = [];
        data.forEach(e => {
          e.isActive = false;
          e.alreadyJoin = false;
          res2.data.forEach(o => {
            if (e.OLAPKey == o.CourseId) {
              e.alreadyJoin = true;
              // e.isActive = true;
            }
          });
          // if (e.StatusKey == 2 || e.StatusKey == 3) { // 要求只显示 进行中和未开始的课，已完结和已停的课不显示(2-未开始;3-进行中;4-已完结;5-已中止;6-已取消)
          this.monthInfo.push(e);
          // }
        });
        console.log(res2.data, 'SearchStudentHasArrangeSupport');
        console.log(data, '学生已排课查询1');
        console.log(this.monthInfo, '查询数据2');
      }, err2 => {
        layer.alert(err2.msg);
      });
    },
    continuePress () {

    },
    continueClick () {

    },
    serachPopup () {

    },
    reset () {
      this.courseTableData.DateList = [];
    },
    submit (callback) {
      if (!this.seletedCourseInfo) {
        layer.alert('请选择你要安排的试课课程');
        if (callback) {
          callback();
        }
        return false;
      }
      this.$emit('summit', this.seletedCourseInfo, callback);
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }

};
</script>

<style>
</style>