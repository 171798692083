<template>
  <div class="growth_time_list_imgBox dynamic_content_body">
    <div class="body_img_box"
         :class="{dual_img:imgList.length == 2}">
      <div class="view_photo_img body_img_list"
           :class="{'one_img growth_time_list_swiper':imgList.length==1,}"
           v-for="(Item,index) in vImgShowList"
           :key="index">
        <img :src="mImgUrl(Item.AttachUrl)"
             v-if="Item.AttachTypeKey==1"
             alt=""
             class="img_box"
             :class="{'obj_c':imgList.length==1}"
             @click.stop="doImgViewClick(Item)">
        <img v-if="Item.AttachTypeKey==2"
             :src="mImgUrl(Item.VideoCoverUrl)"
             @click.stop="showVideo(Item)"
             class="img_box"
             :class="{'obj_c':imgList.length==1}" />
        <img :src="require('../../../../../../../public/image/video_plays.png')"
             class="videoIcon"
             v-if="Item.AttachTypeKey == '2'"
             @click.stop="showVideo(Item)">
      </div>
    </div>
    <custom-dialog :title="'视频播放'"
                   width="1000px"
                   :visible.sync="isShowPlayView"
                   :before-close="closePalyViewPopup">
      <playView :dataInfo="showItem"
                :isReadonly="false"
                @close="closePalyViewPopup"></playView>
    </custom-dialog>
  </div>
</template>

<script>
import playView from '../../../../../courseware-management/courseware-editor/courseware-view/play-view'
export default {
  data () {
    return {
      isShowPlayView: false,
      showItem: {}
    };
  },
  props: {
    imgList: Array
  },
  components: {
    // imgItem
    playView
  },
  computed: {
    vImgShowList () { // 显示的图片
      console.log(this.imgList, 'imgList');
      var showList = this.$utils.parseJson(this.imgList);

      showList = showList.filter(obj => {
        return obj.AttachTypeKey == 1 || obj.AttachTypeKey == 2
      })
      showList.sort(function (a, b) {
        return b.AttachTypeKey - a.AttachTypeKey
      })
      if (showList.length > 3) {
        showList = showList.splice(0, 3);
      }
      return showList;
    },
  },
  methods: {
    mImgUrl (AttachUrl) {
      return this.$store.getters.CDNURL + AttachUrl;
    },
    // 预览
    doImgViewClick (item) {
      console.log('预览');
      this.$emit('doImgViewClick', item, this.imgList);
    },
    closePalyViewPopup () {
      this.isShowPlayView = false
    },
    showVideo (item) {
      this.showItem = {
        AttachViewUrl: this.$store.getters.CDNURL + item.AttachUrl,
        AttachTypeKey: 2,
        CoverUrl: this.$store.getters.CDNURL + item.VideoCoverUrl
      }
      this.isShowPlayView = true
    }
  }
};
</script>

<style scoped>
.growth_time_list_imgBox.dynamic_content_body
  .body_img_box.dual_img
  .body_img_list.view_photo_img {
  width: 157px;
  height: 157px;
}
.obj_c {
  object-fit: contain !important;
}
.videoIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  /* align-items: center; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  cursor: pointer;
}
.img_box {
  position: relative;
  cursor: pointer;
}
</style>
