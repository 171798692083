<template>
  <div style="border-radius:8px;;background-color:#fff;width:100%">
    <div class=" "
         style="margin-top:0;position: relative;">
      <div class="course_class_summarizing clearfix">
        <div class="course_class_summarizing_left">
          <div class="summarizing_number_left">
            <span>全部班级</span>
            <span class="font_number">({{applyGradeNameList.length}})</span>
          </div>
          <div class="left_search_box"
               style="position: relative;">
            <div class="left_search ">
              <input type="text"
                     placeholder="搜索班级"
                     v-model="searchTextForCourseName" />
              <span v-show="searchTextForCourseName"
                    class="home_remove_btn"
                    @click="searchTextForCourseName=''"></span>

            </div>
          </div>
          <div class="left_content">
            <class-item v-for="(item,index) in vApplyGradeNameList"
                        :key="index"
                        dir="ltr"
                        :courseItem="item"
                        @clickCourse="doAfterClickClass"></class-item>
            <div v-if="vApplyGradeNameList.length==0"
                 class="course_block_nodata"
                 style="background-size:100px">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
        <div v-if="curSelectedClassInfo.OLAPKey"
             class="course_class_summarizing_right">
          <div class="right_top clearfix">
            <div class="right_list_title">
              <div class="right_list_title_icon icon_green fl">班</div>
              <div class="fl">{{curSelectedClassInfo.MainDemoName}}</div>
            </div>
          </div>
          <!-- 班级类型 -->
          <div class="course_class_box">
            <div class="course_class_box_content"
                 v-for="(item,index) in courseClassBoxContent"
                 :key="index"
                 :class="{'opt':item.isShow}"
                 @click="doCourseClassBoxClick(item)">{{item.title}}
              <span :style="item.isShow?'color:#fff':'color: #333333;font-weight: 400'">({{item.count}})</span>
            </div>
          </div>
          <!-- 班内学生 -->
          <div v-if="courseClassBoxContent[0].isShow==true">
            <div class="right_table">

              <div class="right_table_box clearfix">
                <div class="summarizing_number_left fl"
                     style="padding-left:0;line-height:50px">
                  <span>班内学生</span>
                  <span class="font_number">({{this.courseClassBoxContent[0].count}})</span>
                </div>
                <div class="content_search content_search_dual  fr">
                  <div style="float:left;margin-right: 10px;">
                    <input-search-contain-btn v-model="paramsForStudent.searchText"
                                              :placeholder="'请输入姓名、姓名首字母、手机号'"
                                              :width="'280px'"
                                              @onSearchEvent="searchStudent"
                                              @clearSearch="clearSearchCondition"></input-search-contain-btn>
                  </div>
                  <function-btn :item="arrangebtn"
                                @clickItem="addToClass"
                                :className="'right_top_btn btn_hover_bg_blue'">
                    <div :class="vHasModulePower(arrangebtn.ModulePowerKey)?'':'bg_gray'">学生进班</div>
                  </function-btn>
                </div>

              </div>
              <!-- 班内学生总览 -->
              <div v-if="isLoading">
                <div class="table_list_content summarizing_list">
                  <div class="single_result_detail">
                    <table-list :tableData="studentList"
                                :tableColumns="tableClassColumns"
                                :rowKey="'Number'"
                                :totalNum="totalNum"
                                :defaultSort="defaultSort"
                                :queryParams="paramsForStudent"
                                @loadTableData="getMessageSearchPageForGradeStudent"></table-list>
                  </div>
                </div>
              </div>
              <div class="screening_condition_number fl"
                   style="margin-left: 436px;margin-top: 30px"
                   v-else>
                <img src="../../../../../public/image/screening_condition_number_icon02.gif"
                     alt> 正在获取数据 , 请稍等...
              </div>
            </div>
          </div>
          <!-- 在读课程 -->
          <div v-else-if="courseClassBoxContent[1].isShow==true">
            <div v-if="courseNameListInClass.length>0">
              <div class="flex">
                <div class="right_top_box box_left"
                     style="margin-left:20px">
                  <div style="padding:0;width: 100%">
                    <select-course-item class="drop-down-item"
                                        :courseList="courseNameListInClass"
                                        :courseItem="courseItem"
                                        @selectedCourse="doSelectedCourseItem"></select-course-item>
                  </div>
                </div>
                <!-- 汇总数据 -->
                <div class="right_top_box box_right fr">
                  <summary-data-item v-for="(item,key,index) in summaryDataForHour"
                                     :sumData="item"
                                     :key="index"
                                     @onCilckEvent="afterCilckSummaryData"></summary-data-item>
                </div>
              </div>
              <div class="right_table">
                <div class="right_table_box clearfix">
                  <div class="summarizing_number_left fl"
                       style="padding-left:0;line-height:50px">
                    <span>在读学生</span>
                    <!-- 班内学生/班外学生 -->
                    <span v-if="JSON.stringify(courseItem) != '{}'">
                      <span class="font_number"
                            :class="{opt:IsInGradeClassKeyType==1}"
                            style="font-size: 14px;margin: 0px 10px;cursor: pointer"
                            @click="isClassInterior(1)">班内学生
                        <span style="color: #3498DB;">({{courseItem.Num_InGrade}})</span>
                      </span>
                      <span class="font_number"
                            style="font-size: 14px;">|</span>
                      <span class="font_number"
                            :class="{opt:IsInGradeClassKeyType==2}"
                            style="font-size: 14px;cursor: pointer"
                            @click="isClassInterior(2)">班外学生
                        <span style="color: #3498DB;">({{courseItem.Num_OutGrade}})</span>
                      </span>
                    </span>
                  </div>
                  <div class="content_search content_search_dual fr">
                    <input-search-contain-btn v-model="paramsForStudent.searchText"
                                              :placeholder="'搜索学生'"
                                              :width="'286px'"
                                              @onSearchEvent="searchStudent"
                                              @clearSearch="clearSearchCondition"></input-search-contain-btn>
                  </div>
                </div>
                <div v-if="isLoading">
                  <!-- 在读课程总览 -->
                  <div class="table_list_content summarizing_list">
                    <div class="single_result_detail">
                      <table-list :tableData="studentList"
                                  :tableColumns="tableCourseColumns"
                                  :rowKey="'Number'"
                                  :totalNum="totalNum"
                                  :defaultSort="defaultSort"
                                  :queryParams="paramsForStudent"
                                  @loadTableData="getMessageSearchTicketPlanPageForGradeApplyClassStudent"></table-list>
                    </div>
                  </div>
                </div>
                <div class="screening_condition_number fl"
                     style="margin-left: 436px;margin-top: 30px"
                     v-else>
                  <img src="../../../../../public/image/screening_condition_number_icon02.gif"
                       alt> 正在获取数据 , 请稍等...
                </div>
              </div>
            </div>
            <div v-else>
              <div class="monitoring_content_nodata"
                   style="margin-top: 220px;">
                <div class="monitoring_content_nodata_txt">
                  <div class="nodata_add_but"
                       @click="newCourseTable">没有课了，去建立课表吧</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 循环课-->
          <div>
            <custom-dialog title="周循环课"
                           class="student_info_dialog defult_dialog"
                           :visible.sync="isCircleCourseTable"
                           :before-close="closeCircleCourseTableDialog">
              <circle-course-table :classNameKey="curSelectedClassInfo.OLAPKey"
                                   :className="curSelectedClassInfo.MainDemoName"
                                   @openCustomDialog="openCustomDialogByFactoryDialog"
                                   @updateRuleCourseBlock="afterUpdateRuleCourseBlock"></circle-course-table>
            </custom-dialog>
          </div>
        </div>
        <div v-else
             class="course_class_summarizing_right">
          <div class="course_block_nodata"
               style="margin-top:250px;background-position:center 70px;background-size:100px">
            <div style="padding-top: 150px;"
                 class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <dialog-factory ref="classGradeApplycourseDialogFactory"
                      :routerName="'classGradeApplycourse'"></dialog-factory>

      <dialog-factory-common ref="classGradeApplycourseDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  SumarizehGradeTicketPlan,
  MessageSearchTicketPlanPageForGradeApplyClass,
  SearchStructedRule,
  studentAddClass,
  MessageSearchPageForGradeStudent,
  SummariseGradeApplyClassTicketPlan
} from '../../../../API/workbench.js';

import inputSearchContainBtn from '../../common/input-search-contain-btn';
import list from '../../table';
import classItem from './class-item';
import circleCourseTable from './circle-course-table';
import dialogFactory from '../../../common/dialog-factory';
import studentCoursesArranging from '../../../pop-up-menu/student-courses-arranging/index';
import studentsCoursesArranging from '../../../pop-up-menu/students-courses-arranging/index';
import summaryDataItem from '../../summary-data/item';
import selectCourseItem from './select-course-item';
import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      classGradeKey: 0,
      applyGradeNameList: [], // 班级列表（有学生报课的课名）
      curSelectedClassInfo: {}, // 当前选中班级
      // 查询选中的班级的在读学生信息
      paramsForStudent: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 20, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'StudentKeyValue', // 排序字段
        sequence: 'asc', // 排序字段
        ApplyClassKey: 0, // 整型	必须 课名ID
        GradeClassKey: 0, // 整型	必须	0	数据源：GET	班级ID
        SpecialFilter: 0, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
        QueryID: 0,	// 整型	可选	0	数据源：GET	学生ID
        IsInGradeClassKey: '', // 是否班内：0-否;1-是;空-不生效
        noMore: false // 没有更多数据加载了
      },
      IsInGradeClassKeyType: '',
      courseNameListInClass: [],  // 班级下可选课名列表
      //  班级下的课/选择项
      courseItem: {},
      studentList: [], // 按学生 的 学生列表
      arrangebtn: {
        name: '',
        ModulePowerKey: 51
      },
      isLoading: false,
      // 在读课程
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: 100,
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            gradeClassKey: 'IsInGradeClassKey',
            click: () => { }
          }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          sortable: 'custom',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '在读课程',
          prop: 'ApplyClassKeyValue',
          type: 'reading-courses',
          extend: {
            splitSymbol: ','
          }
        },
        {
          label: '当前报课',
          prop: 'Sum_CurrentCount',
          width: 84,
          sortable: 'custom',
          type: 'class-hour',
          bottomWarnProp: 'Sum_CurrentCount_Warn',
          isBottomWarn: true
        },
        {
          label: '当前排课',
          prop: 'ClassCount',
          width: 84,
          sortable: 'custom',
          type: 'class-hour',
          decimalPlace: 1
        },
        {
          label: '待排课时',
          prop: 'Sum_ToBeArranged',
          width: 84,
          sortable: 'custom',
          type: 'class-hour',
          bottomWarnProp: 'Sum_ToBeArranged_Warn',
          isBottomWarn: true,
          extend: {
            click: (rowData) => { this.doArrangeCourseBtnClick(rowData); }
          }
        },
        {
          label: '待报课时',
          prop: 'Total_ToBePlaned',
          width: 84,
          sortable: 'custom',
          type: 'class-hour',
          bottomWarnProp: 'Total_ToBePlaned_Warn',
          isBottomWarn: true,
          extend: {
            className: 'font_red font_border_blue',
            click: (rowData) => { this.doCourseApplyCourseBtnClick(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '报课',
                extend: {
                  click: (rowData) => { this.doCourseApplyCourseBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              },
              {
                name: '排课',
                extend: {
                  click: (rowData) => { this.doArrangeCourseBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              }
            ]
          }
        }
      ],
      // 班内学生 明细总览 table 列
      tableClassColumns: [
        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: 100,
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          sortable: 'custom',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '在读课程',
          prop: 'ApplyClassKeyValue',
          type: 'reading-courses',
          extend: {
            splitSymbol: ','
          }
        },
        {
          label: '可用课时',
          prop: 'Total_CurrentCount',
          width: 84,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'class-hour',
          decimalPlace: 1,
          bottomWarnProp: 'Total_CurrentCount_Warn',
          isBottomWarn: true
        },
        {
          label: '当前排课',
          prop: 'Sum_ArrangedCount',
          width: 84,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'class-hour',
          decimalPlace: 1
        },
        {
          label: '待排课时',
          prop: 'Sum_ToBeArranged',
          width: 84,
          type: 'class-hour',
          decimalPlace: 1,
          bottomWarnProp: 'Sum_ToBeArranged_Warn',
          isBottomWarn: true,
          extend: {
            click: (rowData) => { this.doArrangeCourseBtnClick(rowData); }
          }
        },
        {
          label: '待报课时',
          prop: 'Total_ToBePlaned',
          width: 84,
          type: 'class-hour',
          decimalPlace: 1,
          bottomWarnProp: 'Total_ToBePlaned_Warn',
          isBottomWarn: true,
          extend: {
            className: 'font_red font_border_blue',
            click: (rowData) => { this.doStudentApplyCourseBtnClick(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '报课',
                extend: {
                  click: (rowData) => { this.doStudentApplyCourseBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              },
              {
                name: '排课',
                extend: {
                  click: (rowData) => { this.doArrangeCourseBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              },
              {
                name: '退班',
                extend: {
                  click: (rowData) => { this.doReturnAClassBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              }
            ]
          }
        }
      ],
      courseClassBoxContent: [
        { title: '班内学生', index: 2, count: 0, isShow: true },
        { title: '在读课程', index: 1, count: 0, isShow: false },
        { title: '周循环课', index: 0, count: 0 }
      ],
      PageCount: 0,
      totalNum: 0,
      searchTextForCourseName: '',
      isCircleCourseTable: false, // 显示周循环课弹窗
      summaryDataForHour: {
        inSchoolStudent: { title: '在读学生', opt: '在读学生', className: 'font_blue', value: 0, type: 'inSchoolStudent', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: true },
        SumCurrentCount: { title: '当前报课', className: '', value: 0, dataType: 'number', decimalPlace: 1 },
        NumToBeArranged: { title: '待排人数', className: 'font_blue', value: 0, type: 'NumToBeArranged', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        NumPlanCount: { title: '待报人数', className: 'font_blue', value: 0, type: 'NumPlanCount', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        NumNotEnought: { title: '课时不足', className: 'font_blue ', value: 0, type: 'NumNotEnought', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }
      },
      curSelectedSummaryData: {},
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      tableKey: '0'
    };
  },
  components: {
    dialogFactory,
    list,
    studentCoursesArranging,
    studentsCoursesArranging,
    classItem,
    selectCourseItem,
    summaryDataItem,
    circleCourseTable,
    inputSearchContainBtn,
    tableList
  },
  created () {
    this.classGradeKey = this.$route.params.dataKey;
    this.curSelectedSummaryData = this.summaryDataForHour.inSchoolStudent;
    this.getAllClassList('upload');
  },
  mounted () {
    this.registerBusEvent();
  },
  watch: {
    'curSelectedClassInfo.OLAPKey': {
      handler (n, o) {
        if (n > 0 && n != o) {
          this.tableKey = n;
        }
      },
      deep: true
    },
    'paramsForStudent.SpecialFilter': {
      handler (n, o) {
        if (n == 0 && n != o && !this.IsInGradeClassKeyType) {
          console.log('SpecialFilter');
          this.curSelectedSummaryData.isSelected = false;
          this.summaryDataForHour.inSchoolStudent.isSelected = true;
          this.curSelectedSummaryData = this.summaryDataForHour.inSchoolStudent;
        }
      },
      deep: true
    }
  },
  computed: {
    vApplyGradeNameList () {
      let newList = [];
      if (this.searchTextForCourseName) {
        let trueSearchText = this.searchTextForCourseName.toLowerCase();
        this.applyGradeNameList.forEach(obj => {
          if (obj.MainDemoName.toLowerCase().indexOf(trueSearchText) > -1) {
            newList.push(obj);
          }
        });
      } else {
        newList = this.applyGradeNameList;
      }
      return newList;
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    // 获取全部班级统计
    getAllClassList (up) {
      SumarizehGradeTicketPlan().then(result => {
        if (result.data.length > 0) {
          let findItem = null;
          this.applyGradeNameList = result.data;
          if (JSON.stringify(this.curSelectedClassInfo) == '{}') {
            if (this.classGradeKey) {
              findItem = this.applyGradeNameList.find(o => {
                return o.OLAPKey == this.classGradeKey;
              });
              if (!findItem) {
                findItem = result.data[0];
              }
            } else {
              findItem = result.data[0];
            }
          } else {
            findItem = this.applyGradeNameList.find(o => { return (o.OLAPKey == this.curSelectedClassInfo.OLAPKey); });
          }
          this.curSelectedClassInfo = findItem;
          this.$set(this.curSelectedClassInfo, 'isActive', true);
          this.paramsForStudent.GradeClassKey = this.curSelectedClassInfo.OLAPKey;
          this.paramsForStudent.pageSize = 10;
          if (up == 'upload') {
            this.isLoading = false;
            SummariseGradeApplyClassTicketPlan(this.curSelectedClassInfo.OLAPKey).then(result => {
              if (result.data.length > 0) {
                this.paramsForStudent.ApplyClassKey = result.data[0].ApplyClassKey;// 课程ID
                this.courseNameListInClass = result.data;
                this.courseClassBoxContent[1].count = this.courseNameListInClass.length;
                this.getMessageSearchPageForGradeStudent();// 班内学生
                this.getCourseNameListInClassList();// 周循环课节数
              }
            });
          } else if (up == true) {
            this.upForStudentList();
          }
        }
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        this.isLoading = true;
      });
    },
    // 选择班级排课类型
    doCourseClassBoxClick (item) {
      if (item.index > 0) {
        this.courseClassBoxContent.forEach(o => {
          if (item.title == o.title) {
            o.isShow = true;
          } else {
            o.isShow = false;
          }
        });
        this.IsInGradeClassKeyType = '';
        this.paramsForStudent.SpecialFilter = 0;
        this.paramsForStudent.searchText = '';
        this.paramsForStudent.pageIndex = 0;
        this.paramsForStudent.orderBy = 'StudentKeyValue';
        this.upForStudentList();
      } else {
        console.log('周循环课');
        this.isCircleCourseTable = true;
      }
    },
    // 刷新学生列表
    upForStudentList (queryParams) {
      this.studentList = [];
      if (this.courseClassBoxContent[0].isShow) {
        this.getMessageSearchPageForGradeStudent();// 班内学生
      } else if (this.courseClassBoxContent[1].isShow) {
        this.summaryDataForHour.inSchoolStudent.isSelected = true;// 在读学生
        this.getCourseNameListInClass();// 在读课程
      }
    },
    // 点击班级
    doAfterClickClass (classItem) {
      if (this.$utils.isObjEqual(this.curSelectedClassInfo, classItem)) {
        return false;
      }
      console.log(classItem, '点击班级');
      this.$set(this.curSelectedClassInfo, 'isActive', false);
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.GradeClassKey = classItem.OLAPKey;
      this.paramsForStudent.ApplyClassKey = 0;
      this.IsInGradeClassKeyType = '';
      this.curSelectedClassInfo = classItem;
      this.courseClassBoxContent.forEach(o => {
        if (this.courseClassBoxContent[0].title == o.title) {
          o.isShow = true;
        } else {
          o.isShow = false;
        }
      });
      this.courseClassBoxContent[0].count = 0;
      this.courseClassBoxContent[1].count = 0;
      this.courseClassBoxContent[2].count = 0;
      SummariseGradeApplyClassTicketPlan(this.curSelectedClassInfo.OLAPKey).then(result => {
        console.log(result.data, 'result.data.length');
        if (result.data.length > 0) {
          this.paramsForStudent.ApplyClassKey = result.data[0].ApplyClassKey;// 课程ID
          this.courseNameListInClass = result.data;
          this.courseClassBoxContent[1].count = this.courseNameListInClass.length;
        }
        this.getMessageSearchPageForGradeStudent();// 班内学生
        this.getCourseNameListInClassList();// 周循环课节数
      });
    },
    // 选择班级的课程
    doSelectedCourseItem (course) {
      console.log();
      if (course) {
        this.paramsForStudent.SpecialFilter = 0;
        this.paramsForStudent.pageIndex = 0;
        this.paramsForStudent.searchText = '';
        this.courseItem = course;
        this.summaryDataForHour.inSchoolStudent.isSelected = true;// 在读学生
        this.IsInGradeClassKeyType = '';
        this.initSummaryData(this.courseItem);
      }
    },
    // // 生成该班级下的所有在读课程
    getCourseNameListInClass (dataList) {
      if (this.curSelectedClassInfo.OLAPKey) {
        SummariseGradeApplyClassTicketPlan(this.curSelectedClassInfo.OLAPKey).then(result => {
          console.log(result.data, '在读课程');
          this.courseNameListInClass = [];
          if (result.data.length > 0) {
            this.courseNameListInClass = result.data;
            this.courseClassBoxContent[1].count = result.data.length;
            this.courseItem = result.data[0];
            this.initSummaryData(this.courseItem);
          } else {
            this.studentList = [];
            this.paramsForStudent.ApplyClassKey = 0;
            this.courseItem = {};
            this.isLoading = true;
          }
        });
      } else {
        console.log('缺失班级ID');
      }
    },
    // 课程汇总信息
    initSummaryData (courseSummaryInfo) {
      console.log(courseSummaryInfo, '课程汇总信息');
      this.paramsForStudent.ApplyClassKey = courseSummaryInfo.ApplyClassKey;// 课程ID
      this.summaryDataForHour.inSchoolStudent.value = courseSummaryInfo.Total_Student;// 在读学生
      this.summaryDataForHour.SumCurrentCount.value = courseSummaryInfo.Sum_CurrentCount;// 当前报课
      this.summaryDataForHour.NumToBeArranged.value = courseSummaryInfo.Num_ToBeArranged;// 待排人数
      this.summaryDataForHour.NumNotEnought.value = courseSummaryInfo.Num_NotEnought;// 课时不足
      this.summaryDataForHour.NumPlanCount.value = courseSummaryInfo.Num_PlanCount;// 待报人数
      this.getMessageSearchTicketPlanPageForGradeApplyClassStudent();// 在读学生
      this.getCourseNameListInClassList();// 周循环课节数
    },
    // 查询是否班内
    isClassInterior (type) {
      this.summaryDataForHour.inSchoolStudent.isSelected = false;// 在读学生
      this.summaryDataForHour.SumCurrentCount.isSelected = false;// 当前报课
      this.summaryDataForHour.NumToBeArranged.isSelected = false;// 待排人数
      this.summaryDataForHour.NumNotEnought.isSelected = false;// 课时不足
      this.summaryDataForHour.NumPlanCount.isSelected = false;// 待报人数
      this.IsInGradeClassKeyType = type;
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.getMessageSearchTicketPlanPageForGradeApplyClassStudent();// 在读学生
    },
    // 查询选中班级下的学生(班内学生)
    getMessageSearchPageForGradeStudent () {
      this.paramsForStudent.orderBy = (this.paramsForStudent.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.paramsForStudent.orderBy);
      MessageSearchPageForGradeStudent(this.paramsForStudent).then(result => {
        this.studentList = result.data.PageDataList;
        console.log(this.studentList, '班内学生');
        this.totalNum = result.data.Total;
        if (this.studentList.length > 0) {
          result.data.PageDataList.forEach(o => {
            if (Number(o.Sum_SuperQuote) > 0) {
              o.Total_ToBePlaned_Warn = '超上';
              o.Total_ToBePlaned_Pop_Tips = '超上' + Math.abs(o.Sum_SuperQuote) + '课时';
            }
            if (Number(o.Sum_Overplace) > 0) {
              o.Sum_ToBeArranged_Warn = '超排';
              o.Sum_ToBeArranged_Pop_Tips = '超排' + Math.abs(o.Sum_Overplace) + '课时';
            }
            if (Number(o.Total_CurrentCount) <= 5 && Number(o.Total_CurrentCount) > 0) {
              o.Total_CurrentCount_Warn = '不足';
              // o.Sum_CurrentCount_Pop_Tips = '不足' + Math.abs(o.Sum_CurrentCount) + '课时';
            }
          });
        }
        if (this.paramsForStudent.SpecialFilter == 0 && this.paramsForStudent.searchText == '') {
          this.courseClassBoxContent[0].count = this.totalNum;
        }
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        this.isLoading = true;
      });
    },
    // 班级课程报课翻页详情（班级课程下所有学生）
    getMessageSearchTicketPlanPageForGradeApplyClassStudent () {
      this.paramsForStudent.orderBy = (this.paramsForStudent.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.paramsForStudent.orderBy);
      this.paramsForStudent.IsInGradeClassKey = (this.IsInGradeClassKeyType == 2 ? 0 : this.IsInGradeClassKeyType);
      console.log(this.paramsForStudent, 'this.paramsForStudent');
      MessageSearchTicketPlanPageForGradeApplyClass(this.paramsForStudent).then(result => {
        this.studentList = result.data.PageDataList || result.data;
        console.log(this.studentList, 'this.paramsForStudent');
        this.totalNum = result.data.Total;
        if (this.studentList.length > 0) {
          this.studentList.forEach(o => {
            o.sourceData = o.ApplyClassKeyValue.split(',');
            let info = o.StudentKeyValue + ',' + (o.IsInGradeClassKey == 0 ? '未进班' : '');
            o.studentInfo = info.split(',');

            if (Number(o.Sum_SuperQuote) > 0) {
              o.Total_ToBePlaned_Warn = '超上';
              o.Total_ToBePlaned_Pop_Tips = '超上' + Math.abs(o.Sum_SuperQuote) + '课时';
            }
            if (Number(o.Sum_Overplace) > 0) {
              o.Sum_ToBeArranged_Warn = '超排';
              o.Sum_ToBeArranged_Pop_Tips = '超排' + Math.abs(o.Sum_Overplace) + '课时';
            }
            if (Number(o.Sum_CurrentCount) <= 5 && Number(o.Sum_CurrentCount) > 0) {
              o.Sum_CurrentCount_Warn = '不足';
              // o.Sum_CurrentCount_Pop_Tips = '不足' + Math.abs(o.Sum_CurrentCount) + '课时';
            }
          });
        }
        if (this.paramsForStudent.SpecialFilter == 0 && this.IsInGradeClassKeyType === '' && this.paramsForStudent.searchText === '') {
          // this.courseClassBoxContent[1].count = this.totalNum;
          // this.courseClassBoxContent[1].count = this.courseNameListInClass.length || 0;
        }
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        this.isLoading = true;
      });
    },
    // 关闭周循环课
    closeCircleCourseTableDialog () {
      this.isCircleCourseTable = false;
    },
    // 获取周循环课节数
    getCourseNameListInClassList () {
      if (this.curSelectedClassInfo.OLAPKey) {
        SearchStructedRule('', this.curSelectedClassInfo.OLAPKey).then(result => {
          console.log(result.data, '获取周循环课节数');
          this.courseClassBoxContent[2].count = result.data.length;
        });
      } else {
        console.log(this.curSelectedClassInfo.OLAPKey, '缺失班级ID');
      }
    },
    // 对 循环课进行操作后 刷新
    afterUpdateRuleCourseBlock (classGradeID) {
      this.getCourseNameListInClassList();
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    // 退班
    doReturnAClassBtnClick (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      console.log(rowData, '按钮点击');
      let moduleInfo = {
        moduleName: 'quitOrEnterClassForm',
        name: '学生退班',
        routerName: this.$route.name,
        routertitle: '',
        data: {
          dataInfo: { studentID: this.studentKey, classNameID: this.curSelectedClassInfo.OLAPKey, classTitle: '退班' }
        }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    // 排课
    doArrangeCourseBtnClick (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      // if (Number(rowData.Sum_ToBeArranged) > 0) {
      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: 'studentArrangeCourseByClass',
        name: '跟班级排课',
        dataKey: this.studentKey,
        data: {
          studentID: this.studentKey,
          classNameID: this.curSelectedClassInfo.OLAPKey,
          className: this.curSelectedClassInfo.MainDemoName
        },
        callBack: { afterCancelAllArrangeSuccess: () => { this.getAllClassList(true); } }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
      // }
    },
    // 学生报课
    doStudentApplyCourseBtnClick (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: 'studentApplyCourse',
        name: '学生报课',
        data: { studentApplyCourseParams: { studentID: this.studentKey } }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    // 课程报课
    doCourseApplyCourseBtnClick (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: 'courseApplycourse',
        name: '课程报课',
        data: { studentKey: this.studentKey, applyClassID: this.paramsForStudent.ApplyClassKey }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    // 搜索
    searchStudent () {
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.pageIndex = 0;
      if (this.courseClassBoxContent[0].isShow) {
        this.getMessageSearchPageForGradeStudent();// 班内学生
      } else if (this.courseClassBoxContent[1].isShow) {
        this.summaryDataForHour.inSchoolStudent.isSelected = true;// 在读学生
        this.initSummaryData(this.courseItem);
      }
    },
    // 清除搜索条件
    clearSearchCondition () {
      console.log('清除搜索条件');
      this.paramsForStudent.SpecialFilter = 0;
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.IsInGradeClassKeyType = '';
      // this.upForStudentList();
      if (this.courseClassBoxContent[0].isShow) {
        this.getMessageSearchPageForGradeStudent();// 班内学生
      } else if (this.courseClassBoxContent[1].isShow) {
        this.summaryDataForHour.inSchoolStudent.isSelected = true;// 在读学生
        this.initSummaryData(this.courseItem);
      }
    },
    // 加入班级
    addToClass () {
      this.$dialog.open(this, {
        name: '加入班级',
        routerName: this.$route.name,
        routertitle: '',
        ModulePowerKey: 51,
        moduleName: 'addStudentToBJ',
        data: { dataInfo: { studentInfo: null, classInfo: this.curSelectedClassInfo } }
        // callBack: { upClassListData: (OLAPKey) => { this.getStudentInClass(OLAPKey); } }
      });
    },
    //  多学生安排 成功
    doAfterArrangeOrApplyCourseSuccess () {
      this.getAllClassList();
    },
    afterCilckSummaryData (item) {
      if (!item.value || item.value == 0 || item.title == '当前报课') {
        return false;
      }
      this.curSelectedSummaryData.isSelected = false;
      item.isSelected = true;
      this.curSelectedSummaryData = item;
      switch (item.type) {
        case 'inSchoolStudent':
          this.paramsForStudent.SpecialFilter = '0';
          break;
        case 'NumToBeArranged':
          this.paramsForStudent.SpecialFilter = '1';
          break;
        case 'NumPlanCount':
          this.paramsForStudent.SpecialFilter = '2';
          break;
        case 'NumNotEnought':
          this.paramsForStudent.SpecialFilter = '3';
          break;
        default:
          break;
      }
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.searchText = '';
      this.IsInGradeClassKeyType = '';
      this.getMessageSearchTicketPlanPageForGradeApplyClassStudent();
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        if (studentKey > 0) {
          this.getAllClassList();
        }
      });
      // 加入班级、退出班级后
      this.$bus.on(this, 'afterQuitOrAddClass', (classID, studentKey, type) => {
        console.log(classID, studentKey, '加入班级后');
        this.getAllClassList(true);
      });
      // 刷新排课数据
      this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
        this.getAllClassList(true);
      });
      this.$bus.on(this, 'AfterStudentApplyCourse', applyCourseInfo => {
        this.getAllClassList(true);
      });
      this.$bus.on(this, 'AfterArrangeForSingleStudent', (type, courseID, studentID) => {
        this.getAllClassList(true);
      });
      this.$bus.on(this, 'AfterCourseNameApplyCourse', () => {
        this.getAllClassList(true);
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'classGradeApplycourse') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'classGradeApplycourse') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      console.log('新建课 成功后000');
      // 新建课 成功后
      this.$bus.on(this, 'AfterNewCourseTable', (type, data) => {
        this.getAllClassList(true);
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classGradeApplycourseDialogFactory) {
        this.$refs.classGradeApplycourseDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classGradeApplycourseDialogFactoryCommon) {
        this.$refs.classGradeApplycourseDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    // 新建课表
    newCourseTable () {
      let item = {
        routerName: 'classGradeApplycourse',
        routertitle: '班级排课',
        moduleName: 'newCircleCourseTable',
        data: { dataInfo: { BJPKIDKey: this.curSelectedClassInfo.OLAPKey, BJPKIDKeyValue: this.curSelectedClassInfo.MainDemoName } }
      };
      this.openCustomDialogByFactoryDialog(item);
    }
  }
};
</script>
<style>
.drop-down-item {
  /* background-color: #f2f2f2; */
  height: 100%;
  line-height: 36px;
  padding: 0;
  border-radius: 8px;
}

.drop-down-item .el-input {
  height: 36px;
  line-height: 36px;
}

.drop-down-item input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}

.drop-down-item .el-icon-arrow-up:before {
  content: '\E78F';
  color: #b7b7b7;
}
</style>
