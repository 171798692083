<template>
  <!-- 加盟店表单 -->
  <div>
    <div class="form_info_edit form_info_list" style="padding-bottom: 200px;">

      <div>
        <input-switch v-model="PreparingCheck" :formTitle="'备课检查'" :showInputValue="true" :className="'no_border'"
          :required="false"></input-switch>
        <div style="color:#999;border-bottom: 1px solid #ececec;"></div>
      </div>
      <div>
        <input-switch v-model="inspection" :formTitle="'课评统计'" :showInputValue="false" :className="'no_border'"
          :required="false"></input-switch>
        <div style="border-bottom: 1px solid #ececec;color:#999">

        </div>
      </div>
      <div>
        <input-switch v-model="homeworkCheck" :formTitle="'作业统计'" :showInputValue="false" :className="'no_border'"
          :required="false"></input-switch>
        <div style="border-bottom: 1px solid #ececec;color:#999">

        </div>
      </div>
      <!-- <input-text :formTitle="'校区名'"
                  :required="true"
                  :readonly="isReadonly ||vIsEdit"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CampusName"></input-text> -->

      <!-- <input-number-alphabet :formTitle="'门店ID'"
                             :required="true"
                             :readonly="isReadonly ||vIsEdit"
                             :formPlaceholder="'请输入'"
                             :formInputHint="vCurrentMendianId"
                             v-model="dataInfo.SaaSClientID"></input-number-alphabet> -->

      <!-- <input-text :formTitle="'负责人'"
                  :required="true"
                  :readonly="isReadonly"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.Principal"></input-text> -->

      <!-- <input-phone :formTitle="'手机号'"
                   :formPlaceholder="'请输入'"
                   :required="true"
                   :readonly="isReadonly"
                   v-model="dataInfo.MobilePhone"></input-phone> -->


      <input-dialog :formTitle="'督学专员'" :formPlaceholder="'请选择'" v-model="closeObj.InspectorsKeyValue"
        @showEldilog="changeSourceType()"></input-dialog>

      <!-- <input-select :formTitle="'督学专员'"
                    :id="'type'"
                    :name="'name'"
                    :required="false"
                    :readonly="isReadonly ||vIsEdit"
                    :dataList="sourceType"
                    :selectedInfo="vSourceTypeInfo"
                    @selectedItem="changeSourceType"></input-select> -->

      <!-- <input-search-cities :formTitle="'所在地区'"
                           :required="false"
                           :readonly="isReadonly ||vIsEdit"
                           :selectedInfo="locationInfo"
                           @selectedItem="changeRegion"></input-search-cities> -->

      <!-- <input-text :formTitle="'详细地址'"
                  class="site_icon_type"
                  :formPlaceholder="'请输入'"
                  :readonly="isReadonly ||vIsEdit"
                  :required="false"
                  v-model="dataInfo.Address"></input-text> -->

      <!-- <input-number v-if="!CampusKey"
                    :min="0"
                    :formTitle="'开通时长（年）'"
                    :formPlaceholder="'请输入'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly ||vIsEdit"
                    v-model="dataInfo.DeductCount"></input-number> -->

      <!-- <input-number v-if="!CampusKey"
                    :min="0"
                    :formTitle="'扣除端口数（个）'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="true"
                    :className="'font_black'"
                    v-model="dataInfo.DeductCount"></input-number> -->

      <!-- <input-dialog :formTitle="'招商顾问'"
                    :formPlaceholder="'请输入'"
                    :required="false"
                    :readonly="isReadonly ||vIsEdit"
                    v-model="dataInfo.CounselorKeyValue"
                    @showEldilog="selectCounselor"></input-dialog> -->

      <!-- <input-date v-model="dataInfo.LeagueTime"
                  :formTitle="'加盟日期'"
                  :readonly="isReadonly ||vIsEdit"
                  :elementId="'BirthdayTime'"
                  :required="false"
                  :formPlaceholder="'选填'"></input-date> -->
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'" :isSingleSubmit="true" :fixedBtn="true" @confirmClick="submit"
        @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
  import { AddFranchisee, ModifyFranchisee, GetCampusDetails, GetOnJobEmployees, GetSaaSClientSet, closeSuperintendentSet, getLivingCommunity, MessageSearchPageForEmployee } from '../../../../../API/workbench';
  export default {
    data () {
      return {
        // 直营类型
        sourceType: [],
        locationInfo: [], // 地址
        dataInfo: {
          CampusName: '', // 校区名
          SaaSClientID: '', // 门店ID
          Principal: '', // 负责人
          MobilePhone: '', // 手机号
          TypeKey: '', // 加盟类型
          TypeKeyValue: '', // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
          ProvinceKey: '', // 省
          ProvinceKeyValue: '',
          CityKey: '', // 市
          CityKeyValue: '',
          AreaKey: '', // 区
          AreaKeyValue: '',
          Address: '', // 详细地址
          CounselorKey: '', // 招商顾问
          CounselorKeyValue: '',
          DeductCount: 1, // 扣除端口数
          LeagueTime: this.$utils.formatDateToLine(Date.new()) // 加盟日期
        },
        closeObj: {
          'InspectorsKey': '0', // 督学专员
          'InspectorsKeyValue': '',
          'IsEnableHomeWorkCheckKey': '0', // 是否开启作业检查
          'IsEnableHomeWorkCheckKeyValue': '关闭', // 0-未开启;1-开启
          'IsEnableReviewCheckKey': '0', // 是否开启点评检查
          'IsEnableReviewCheckKeyValue': '关闭', // 0-未开启;1-开启
          'IsEnablePreparingCheckKey': '0', // 是否开启备课检查
          'IsEnablePreparingCheckKeyValue': '关闭'   // 0-未开启;1-开启
        },
        homeworkCheck: 0,    // 作业检查
        PreparingCheck: 0,   // 备课检查 Preparing to check
        inspection: 0,  // 点评检查 inspection
           // 查询员工
        staffParameter: {
          pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
          pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
          searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
          orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
          sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
          IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
        }
      };
    },
    components: {

    },
    props: {
      CampusKey: {
        type: Number,
        default: 0
      },
      isReadonly: Boolean,
      campusEditIndo: Object
    },
    created () {
      console.log(this.CampusKey, 'CampusKey44444');
      if (this.CampusKey > 0) {
        this.getDetailInfo();
      } else {
        this.getMenDianSet();
      }
      if (this.campusEditIndo) {
        console.log(this.campusEditIndo, 'campusEditIndo');
        setTimeout(() => {
          this.homeworkCheck = this.campusEditIndo.IsEnableHomeWorkCheckKey;
          this.PreparingCheck = this.campusEditIndo.IsEnablePreparingCheckKey;
          this.inspection = this.campusEditIndo.IsEnableReviewCheckKey;
          console.log(this.homeworkCheck, this.PreparingCheck, this.inspection, '选中的条目');
          this.dataInfo = this.campusEditIndo;
          this.closeObj.InspectorsKeyValue = this.campusEditIndo.InspectorsKeyValue;
          this.closeObj.InspectorsKey = this.campusEditIndo.InspectorsKey;
        }, 300);
      }
    },
    mounted () {
      this.$dialog.register(this, ['singleSelectionList']);
    },
    computed: {
      vIsEdit () {
        if (this.CampusKey > 0) {
          return true;
        } else {
          return false;
        }
      },
      // 当前门店id
      vCurrentMendianId () {
        console.log(this.$store.getters.SaaSClientInfo, '当前门店id');
        return this.$store.getters.SaaSClientInfo.SaaSClientNO + '-';
      }
      // 直营类型
      // vSourceTypeInfo() {
      //   return {
      //     type: this.dataInfo.TypeKey,
      //     name: this.dataInfo.TypeKeyValue
      //   };
      // }
    },
    methods: {
      // 获取门店设置
      getMenDianSet () {
        GetSaaSClientSet().then(result => {
          result.data.forEach(o => {
            if (o.SetTypeKey == 4 && o.SetKey == 1) { // 是否显示课件加盟
              this.sourceType.push({ type: 3, name: '课件加盟' });
            }
            if (o.SetTypeKey == 5 && o.SetKey == 1) { // 是否显示整店加盟
              this.sourceType.push({ type: 4, name: '整店加盟' });
            }
          });
          this.dataInfo.TypeKey = (this.sourceType[0].type == 3 ? 3 : 4);
          this.dataInfo.TypeKeyValue = (this.sourceType[0].type == 3 ? '课件加盟' : '整店加盟');
        });
      },
      // 督学专员
      changeSourceType () {
        MessageSearchPageForEmployee(this.staffParameter).then(result => {
          let arr = [];
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach(element => {
              if (element.IsLeaveKey == '1') {
                arr.push(element);
              }
            });
          }
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '督学专员',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.closeObj.InspectorsKey,
              dataSourceList: arr,
              required: false,
              addModuleName: 'yuangong-setting',
              addModuleTitleName: '添加老师',
              ModulePowerKey: 0
            },
            callBack: {
              onSelectedItem: (data) => {
                // this.receiveAddress(data);
                console.log(data, '选中');
                // this.dataInfo.TypeKey = data.OLAPKey;
                this.closeObj.InspectorsKeyValue = data.MainDemoName;
                this.closeObj.InspectorsKey = data.OLAPKey;
              }
            }
          });
        }, err => {
          layer.alert(err.msg);
        });
        // console.log(obj, '选择来源方式');
        // this.dataInfo.TypeKey = obj.type;
        // this.dataInfo.TypeKeyValue = obj.name;
      },
      // 选择省市区
      changeRegion (keys, values) {
        this.dataInfo.ProvinceKey = keys[0] || '';// 省
        this.dataInfo.ProvinceKeyValue = values[0] || ''; // 省
        this.dataInfo.CityKey = keys[1] || '';// 市
        this.dataInfo.CityKeyValue = values[1] || ''; // 市
        this.dataInfo.AreaKey = keys[2] || ''; // 区/县
        this.dataInfo.AreaKeyValue = values[2] || ''; // 区/县
        console.log(keys, values, '省市区');
      },
      // 选择招商顾问
      selectCounselor () {
        GetOnJobEmployees().then(result => {
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择招商顾问',
            moduleName: 'singleSelectionList',
            data: { selectedKey: this.dataInfo.CounselorKey, dataSourceList: result.data, required: false, ModulePowerKey: 0 },
            callBack: {
              onSelectedItem: (data) => {
                console.log(data, '选择招商顾问');
                this.dataInfo.CounselorKey = data.OLAPKey;
                this.dataInfo.CounselorKeyValue = data.MainDemoName;
              }
            }
          });
        }, error => {
          layer.alert(error.msg);
        });
      },
      initAddData () {
        console.log(this.$store.getters.token, 'initAddData');
      },
      // 获取校区详情信息
      getDetailInfo () {
        GetCampusDetails(this.CampusKey).then(result => {
          console.log('获取校区详情信息', result.data);
          this.locationInfo = [result.data.ProvinceKey, result.data.CityKey, result.data.AreaKey];
          Object.assign(this.dataInfo, result.data);
          this.dataInfo.CampusName = result.data.MainDemoName;
          this.dataInfo.SaaSClientID = result.data.SaaSClientNO;
          this.dataInfo.Principal = result.data.PrincipalName;
          // this.dataInfo.LeagueTime = this.dataInfo.LeagueTime.slice(0, 10);
          console.log('地址', this.locationInfo);
        }, error => {
          layer.alert(error.msg);
        });
      },
      // 提交
      submit (callback) {
        console.log('submit', this.dataInfo);
        console.log(this.homeworkCheck, this.PreparingCheck, this.inspection, '提交----选中的条目');

        let res = this.verificationInfo();
        if (!res) {
          if (callback) {
            callback();
          }
          return false;
        }
        this.closeObj.IsEnableHomeWorkCheckKey = this.homeworkCheck;
        this.closeObj.IsEnableReviewCheckKey = this.inspection;
        this.closeObj.IsEnablePreparingCheckKey = this.PreparingCheck;
        if (this.homeworkCheck == 0) {
          this.closeObj.IsEnableHomeWorkCheckKeyValue = '关闭';
        } else {
          this.closeObj.IsEnableHomeWorkCheckKeyValue = '开启';
        }
        if (this.PreparingCheck == 0) {
          this.closeObj.IsEnableReviewCheckKeyValue = '关闭';
        } else {
          this.closeObj.IsEnableReviewCheckKeyValue = '开启';
        }
        if (this.inspection == 0) {
          this.closeObj.IsEnablePreparingCheckKeyValue = '关闭';
        } else {
          this.closeObj.IsEnablePreparingCheckKeyValue = '开启';
        }
          // this.dataInfo.SaaSClientID = this.vCurrentMendianId + this.dataInfo.SaaSClientID;
        console.log(this.CampusKey, this.closeObj, '提交的参数');
        closeSuperintendentSet({SchoolKey: this.CampusKey}, this.closeObj).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      },
      // 验证客户信息
      verificationInfo () {
        console.log(this.dataInfo, 'this.dataInfo---提交');
        // if (this.dataInfo.CampusName) {
        //   this.dataInfo.CampusName = this.dataInfo.CampusName.replace(/(^\s*)|(\s*$)/g, '');
        // }
        let flag = true;
        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

        // if (!this.dataInfo.CampusName) {
        //   layer.alert('请输入校区名');
        //   flag = false;
        // } else if (!this.dataInfo.SaaSClientID) {
        //   layer.alert('请输入大于0以上门店ID');
        //   flag = false;
        // } else if (!this.dataInfo.Principal) {
        //   layer.alert('请输入负责人');
        //   flag = false;
        // } else if (!this.dataInfo.MobilePhone) {
        //   layer.alert('请输入手机号');
        //   flag = false;
        // } else if (!myreg.test(this.dataInfo.MobilePhone)) {
        //   layer.alert('手机号输入错误');
        //   flag = false;
        // } else if (!this.dataInfo.DeductCount) {
        //   layer.alert('请输入开通时长（年）');
        //   flag = false;
        // }
        return flag;
      },
      cancel () {
        this.$emit('close');
      }
    }
  };
</script>