<template>
  <!-- 补课管理 通用设置 修改-->
  <div class="saturationCourse_us">
    <div class="content_marginRL content_marginTop_30">
      <div class="saturation_course_content">
        <div class="saturation_course_top">
          <div class="index_title pr form_info_required">
            <span>{{ this.updateTitle }}</span>
            <input class="input_num"
                   placeholder="请输入"
                   type="text"
                   v-model="updateContent" />

          </div>
        </div>
      </div>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>

</template> 

<script>
import { MakeUpNoRelevance } from '../../../../API/workbench.js';
export default {
  props: {
    dateInfo: Object,
    updateTitle: String
  },
  data () {
    return {
      updateContent: ''
    };
  },

  components: {
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.updateContent = this.dateInfo.NoRelevancyContent;
    console.log(this.updateTitle, 'updateText', this.dateInfo, this.updateContent);
  },
  mounted () {
  },
  methods: {
    confirmClick () {
      console.log(this.updateContent, this.dateInfo);
      let flag = true;
      if (!this.updateContent) {
        layer.alert('不关联内容不能为空');
        flag = false;
        return flag;
      }
      layer.confirm('确定不关联处理吗?', {
        btn: [{
          name: '确认',
          callBack: () => {
            layer.close();
            MakeUpNoRelevance(this.dateInfo.OLAPKey, this.updateContent).then(result => {
              layer.alert('不关联成功');
              this.$emit('updataCourseInfo', this.dateInfo, this.updateContent);
            }, error => {
              layer.alert(error.msg);
            });
          }
        }]
      });
    },
    cancelClick () {
      this.$emit('afterSuccess');
    }
  }
};
</script>

<style scoped>
/*  */
.saturationCourse_us {
  height: 120px;
}
.saturation_course_content {
  width: 387px;
  margin: 0 auto;
  height: 52px;
}
.index_title {
  font-size: 14px;
  line-height: 50px;
  display: flex;
  width: 380px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(236, 236, 236, 1) !important;
  padding-left: 0px !important;
}
.right_surFlow {
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3498db;
}
.input_num {
  border: 1px solid #ececec;
  height: 40px;
  border-radius: 4px;
  padding-left: 10px;
  color: #3498db;
  margin-top: 5px;
}
.form_info_required::before {
  content: '*';
  left: -8px !important;
  top: 19px !important;
}
</style>