<!-- 课包排序 -->
<template>
  <div class="max_courseware_manage old_max_courseware_manage"
       :class="newMaxCoursewareList.length>=9?'coursepackage_sort_mange':''">
    <!-- 提示 -->
    <div class="head_tips_div"> 课包展示排序，影响老师显示课包的顺序，含授权课件与已发布的自研课件。</div>
    <div class="courseware_item">
      <draggable v-model="newMaxCoursewareList"
                 class="big_courseware_list"
                 :options="{group:{name: 'site'},sort: false}"
                 animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 handle=".mover"
                 v-bind="{sort: false,store: false,}"
                 @start="onStart"
                 @end="onEnd"
                 :move="onMove"
                 @add="onAdd">
        <transition-group class="draggable">
          <list-item v-for="item in newMaxCoursewareList"
                     :key="item.BigCWPKey"
                     :listLength="newMaxCoursewareList.length +1"
                     :ShowOrder="Number(item.SortNO)"
                     :dataInfo="item"
                     :isDrag="isDrag"
                     @moveStart="moveStart"
                     @stopMove="stopMove"
                     @selectEvent="selectEvent"
                     @doListSort="doListSort"></list-item>
          <div v-for="i in 20"
               :key="i"
               class="makeweight"></div>
        </transition-group>
      </draggable>
      <div v-if="newMaxCoursewareList.length==0"
           style="position:relative">
        <div class="courseware_manage_right"
             style="position: absolute; left: 2px;top: -25px; width: 1320px;">
          <div class="right_nodata"
               style="top: 270px;">还没有课件哦~</div>
        </div>
      </div>
    </div>
    <div>
      <dialog-factory ref="coursePackageSortDialog"
                      :key="'coursePackageSort'"
                      :routerName="'coursePackageSort'"></dialog-factory>
      <dialog-factory-common ref="coursePackageSortCommonDialog"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../common/dialog-factory';
import listItem from './list-item';
import draggable from 'vuedraggable';
import { CWSGetBigCoursewarePackageForTeacherSort, WEBVGetBigCoursewarePackageForTeacherSort, CWSGetCoursewarePackageSort, WEBVGetgetCoursewarePackageSort } from '../../API/workbench';

export default {
  name: 'coursePackageSort',
  components: {
    listItem,
    dialogFactory,
    draggable
  },
  data () {
    return {
      newMaxCoursewareList: [], // 大课包数据列表

      ShowOrderList: [], // 排序
      isDrag: false,
      moveItem: null
    };
  },
  props: {
  },
  computed: {

  },
  watch: {
  },
  created () {
    this.getBigCoursewarePackageForTeacherSort();
    this.registerBusEvent();
  },
  mounted () {
  },
  methods: {
    stopMove () {
      this.isDrag = false;
      console.log('stopMove');
    },
    moveStart (item) {
      this.isDrag = true;
      this.moveItem = item;
      console.log('moveStart', this.moveItem);
    },
    onMove (e) {
      console.log('onMove');
      // 移入时判断是否可移入
      // return this.$parent.draggRuleStudentMove(e)
      return this.isDrag == true;
    },
    // 开始拖拽事件
    onStart (e) {
      // this.draggableItem = this.newMaxCoursewareList[e.oldIndex];

    },
    onAdd (e) {
      this.isDrag = false;
    },
    // 拖拽结束事件
    onEnd (e) {
      console.log('onEnd to', e);
      if (this.isDrag) {
        this.isDrag = false;
        console.log('onEnd moveItem', this.moveItem, e.newIndex + 1);
        console.log('this.new', this.newMaxCoursewareList);
        this.setSortList(this.moveItem, e.newIndex + 1);
        // this.$emit("setSortList",this.moveItem,e.newIndex+1)
      }
    },
    // 课包排序
    setSortList (item, keyIndex) {
      let fn = '';
      if (this.$store.getters.token.SaaSClientEditionKey == 6) {
        fn = CWSGetCoursewarePackageSort;
      } else {
        fn = WEBVGetgetCoursewarePackageSort;
      }

      console.log('this.oldList', this.oldList);
      this.ShowOrderList = [];
      let number = 0;
      this.newMaxCoursewareList.forEach((o) => {
        number = number + 1;
        this.ShowOrderList.push({
          OLAPKey: o.OLAPKey,
          Sort: number
        });
      });

      console.log('ShowOrderList', this.ShowOrderList);
      fn(this.ShowOrderList).then(result => {
        this.getBigCoursewarePackageForTeacherSort();
      }, error => {
        layer.alert(error.msg);
      });
    },
    getBigCoursewarePackageForTeacherSort () {
      this.ShowOrderList = [];
      let fn = '';
      if (this.$store.getters.token.SaaSClientEditionKey == 6) {
        fn = CWSGetBigCoursewarePackageForTeacherSort;
      } else {
        fn = WEBVGetBigCoursewarePackageForTeacherSort;
      }
      fn().then(result => {
        this.newMaxCoursewareList = result.data;
        result.data.forEach((o) => {
          this.ShowOrderList.push({
            OLAPKey: o.OLAPKey,
            Sort: Number(o.SortNO)
          });
        });

        console.log('获取课包排序', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 默认初始化,列表第一项选中高亮
    selectedDefaultCourseware (dataList) {
      if (dataList.length > 0) {
        dataList.forEach(o => {
          this.$set(o, 'isSelected', false);
          this.$set(o, 'isShowBtn', false);
        });
      }
    },
    // 选择课件包
    selectEvent (dataInfo) {
      console.log(dataInfo, '点击事件');
      this.newMaxCoursewareList.forEach(o => {
        this.$set(o, 'isSelected', false);
        if (Number(dataInfo.ID) === Number(o.ID)) {
          this.$set(o, 'isSelected', true);
        }
      });
      this.$parent.isLoadData = true;
      this.$emit('selectEvent', dataInfo);
    },
    // 课包排序
    doListSort (sortType, data, ShowOrder) {
      let fn = '';
      if (this.$store.getters.token.SaaSClientEditionKey == 6) {
        fn = CWSGetCoursewarePackageSort;
      } else {
        fn = WEBVGetgetCoursewarePackageSort;
      }
      let changeToSort = (sortType == 'Top' ? Number(ShowOrder) - 1 : Number(ShowOrder) + 1);
      this.ShowOrderList.forEach((o, index) => {
        if (o.OLAPKey == data.OLAPKey) {
          o.Sort = changeToSort;
        }
        if (sortType == 'Top' && (changeToSort - 1) == index) {
          o.Sort = changeToSort + 1;
        }
        if (sortType == 'Bottom' && (changeToSort - 1) == index) {
          o.Sort = changeToSort - 1;
        }
      });
      console.log('obj', data, this.ShowOrderList);
      fn(this.ShowOrderList).then(result => {
        this.getBigCoursewarePackageForTeacherSort();
      }, error => {
        layer.alert(error.msg);
      });
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'coursePackageSort') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'coursePackageSort') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursePackageSortDialog) {
        this.$refs.coursePackageSortDialog.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursePackageSortCommonDialog) {
        this.$refs.coursePackageSortCommonDialog.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }

  }
};
</script>

<style scoped>
.old_max_courseware_manage {
  width: 1325px;
  height: 100%;
  border-radius: 8px;
  margin: 0px auto;
  min-height: 850px;
  padding-left: 0px;
}
.coursepackage_sort_mange {
  min-height: 950px !important;
}
.max_courseware_manage_fiexd {
  width: 100% !important;
}
.courseware_manage_titles {
  width: 100%;
  position: fixed;
  top: 68px;
  /* left: 12.3%; */
  z-index: 2;
  /* margin-left: -21px !important; */
}

.courseware_item {
  padding-top: 30px;
  width: 1350px;
  margin-left: -22px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nullTip {
  z-index: 1;
}
.nullTips {
  top: -4px !important;
  left: 14px !important;
}
.head_tips_div {
  width: 1320px;
  height: 35px;
  background: #fffbe6;
  border: 1px solid #ffe694;
  border-radius: 2px;
  line-height: 35px;
  margin-top: 25px;
  padding-left: 20px;
  color: #fea409;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.big_courseware_list .draggable {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
}
.makeweight {
  width: 335px;
  height: 0;
  margin: 0 0px;
}
</style>
