<template>
  <div class="massive_bg apply_payment_details_box">
    <div class="apply_payment_box_title">
      {{formTitle}} <span>{{SetUpTime}}</span>
    </div>
    <div class="apply_payment_box_singular box_dot"
         :class="[dotColor]">
      新增客户线索<span>{{dataInfo}}</span>
    </div>
    <a @click.stop="showDetail"
       href="javascript:void(0);"
       class="class_statistics_details_btn">查看详情</a>
  </div>
</template>
<script>
export default {
  name: 'dayClew',
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    dataInfo: Number,           // 数据源
    dotColor: String
  },
  computed: {
    SetUpTime () {
      if (this.formTitle == '今天') {
        return this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd');
      } else if (this.formTitle == '昨天') {
        var day1 = Date.new();
        day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
        return this.$utils.formatDatet(day1, 'yyyy-MM-dd');
      } else if (this.formTitle == '本月') {
        return this.$utils.formatDatet(Date.new(), 'yyyy年') + this.$utils.formatDatet(Date.new(), 'MM月');
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>

