<template>
  <!-- 文本  'FieldTypeKey':'字段类型1整数;2文本;3选项;4日期;5表' -->
  <div>
    <input v-if="Number(item.FieldTypeKey) == 2"
           type="text"
           :class="inputClass"
           :style="inputStyle"
           :placeholder="vPlaceholder"
           :maxlength="vMaxlength"
           v-model="item.DefaultValue">

    <input v-else-if="Number(item.FieldTypeKey) == 1"
           type="number"
           :class="inputClass"
           :style="inputStyle"
           :placeholder="vPlaceholder"
           :maxlength="vMaxlength"
           v-model="item.DefaultValue"
           onkeyup="this.value=this.value.replace(/[^\d]/g, '').replace(/[^\d]/g, '').replace(/^0{1,}/g, '')">
  </div>

</template> 
<script>

export default {
  data () {
    return {

    };
  },
  props: {
    inputClass: String, // 类目项
    inputStyle: String,
    item: Object // 字段项
  },
  created () {
    // console.log('单行文本:', this.categoryItem, this.item);
  },
  computed: {
    // 水印提示
    vPlaceholder () {
      if (Number(this.item.FieldTypeKey) == 2) {
        return '请输入内容';
      } else {
        return '';
      }
    },
    // 字符串长度
    vMaxlength () {
      if (Number(this.item.FieldTypeKey) == 1) {
        return 12;
      } else if (Number(this.item.FieldTypeKey) == 2) {
        return 100;
      }
    }
  },
  methods: {

  }
};
</script>

<style>
</style>