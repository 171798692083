<template>
  <div class="sign_popup_list"
       @click.stop="clickCourseItem"
       :class="[courseItem.isActive?'opt':'',vAlreadyJoin]">
    <div class="list_course_info"
         >
      <div>{{courseItem.BeginClassTime+'-'+courseItem.EndClassTime}}</div>
      <div class="list_course_text">{{courseItem.CourseNameKeyValue}} | {{courseItem.MTeacherKeyValue}}</div>
      <div class="list_course_text">
        {{courseItem.GradeClassKeyValue}} {{courseItem.classRoomKeyName?'|':''}} {{courseItem.classRoomKeyName}} | 上课消耗: {{Number(courseItem.PeriodCount)}}课时
        <!-- {{setClassTime(courseItem)}} -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['courseItem'],
  computed: {
    vAlreadyJoin () {
      return this.courseItem.alreadyJoin ? 'has_join' : '';
    }
  },
  methods: {
    // 设置开始结束时间.
    setClassTime (item) {
      return (
        item.BeginClassTime.substring(10).substring(0, 6) +
        ' -' +
        item.EndClassTime.substring(10).substring(0, 6)
      );
    },
    clickCourseItem () {
      this.$emit('clickCourseItem', this.courseItem);
    }
  }
};
</script>

