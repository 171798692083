<template>
  <!-- 跟单记录 -->
  <div class="all_workbench_center_record box_withe_radius">
    <div class="all_workbench_title "
         :class="followRecordInfo.isActive?'down_icon':'up_icon'"
         @click.stop="changeType">
      <div class="title_text">跟单记录（{{followRecordInfo.FollowUpRecordCount||0}}）</div>
      <div class="title_right flex">
        <div class="title_right_text"
             v-if="vNextFollowTime">
          <span class="font_gray">下次跟进: </span>
          <span>{{vNextFollowTime}}</span>
        </div>
      </div>
    </div>
    <div class="all_workbench_center_record_ul"
         v-if="followRecordInfo.isActive">
      <record-item v-for="(item,key) in followRecordInfo.FollowUpRecord"
                   :key="key"
                   :item="item"></record-item>

    </div>
  </div>
</template>

<script>
import recordItem from './record-item';
export default {
  data () {
    return {

    };
  },
  components: {
    recordItem
  },
  props: {
    followRecordInfo: Object,
    dataInfo: Object

  },
  computed: {
    vNextFollowTime () {
      this.followRecordInfo.NextFollowTime = this.followRecordInfo.NextFollowTime || '';
      return this.followRecordInfo.NextFollowTime.substring(5, 10);
    }
  },
  methods: {
    changeType () {
      console.log('changeType', this.followRecordInfo.isActive);
      this.followRecordInfo.isActive = !this.followRecordInfo.isActive;
    }
  }
};
</script>

<style>
.all_workbench_center_form {
  width: 370px;
  height: 489px;
  margin-bottom: 20px;
}

.all_workbench_center_info.box_withe_radius {
  width: 370px;
  min-height: 698px;
  padding-bottom: 20px;
}

.all_workbench_center_info.box_withe_radius .info_content {
  padding-left: 24px;
}

.all_workbench_title {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 20px;
  height: 60px;
  border-bottom: 1px solid #ececec;
}

.all_workbench_title.down_icon {
  border-bottom: none;
}

.all_workbench_title .title_text {
  font-size: 18px;
  line-height: 24px;
}

.all_workbench_title.down_icon .title_text {
  padding-left: 20px;
  background: url(../../../../../../../public/image/bottom_icon@2x.png)
    no-repeat left center;
  background-size: 12px;
}

.all_workbench_title.up_icon .title_text {
  padding-left: 20px;
  background: url(../../../../../../../public/image/up_icon@2x.png) no-repeat
    left center;
  background-size: 12px;
}

.all_workbench_title .title_right_btn {
  width: 56px;
  height: 35px;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  background: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  margin-left: 20px;
}

.all_workbench_title .title_right_text {
  font-size: 14px;
  line-height: 35px;
}

.all_workbench_title .title_right_text .font_gray {
  color: #999;
}

.all_workbench_title .title_type {
  min-width: 84px;
  line-height: 30px;
  margin-top: 2.5px;
  padding-left: 34px;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 4px;
  margin-left: 12px;
  padding-right: 7px;
  background-repeat: no-repeat;
  background-position: 11px center;
  background-image: url(../../../../../../../public/image/lable_type_tick.png);
  margin-right: 10px;
}

.all_workbench_center_record_ul {
  padding-top: 32px;
  padding-bottom: 1px;
  position: relative;
}

.all_workbench_center_record_list {
  margin-bottom: 30px;
  /* display: flex; */
  padding-left: 58px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.all_workbench_center_record_list .list_date {
  width: 160px;
  color: #666666;
}

.all_workbench_center_record_list .list_name {
  min-width: 80px;
  padding-left: 15px;
  margin-right: 30px;
  position: relative;
}

.all_workbench_center_record_list .list_name::after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #333;
}

.all_workbench_center_record_list .list_text {
  width: 480px;
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  margin-top: 10px;
}

.all_workbench_center_record_ul::after {
  content: '';
  position: absolute;
  left: 30px;
  width: 0;
  top: 40px;
  bottom: 60px;
  border-left: 1px dashed #999;
}

.all_workbench_center_record_list::after {
  content: '';
  position: absolute;
  left: 24px;
  top: 3px;
  width: 14px;
  height: 14px;
  background: #ffffff;
  border: 2px solid #999999;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0 10px 10px 5px #fff;
  z-index: 2;
}

.all_workbench_center_record_list:first-child:after {
  border-color: #3498db;
}
</style>